import { CalendarDay } from './CalendarDay';
import { DayOfWeekName } from './DayOfWeekName';

export interface ICalendarWeek {
    firstDayInWeek : CalendarDay;
    numberOfDaysInMonth : number;
}
export class CalendarWeek {
    private firstDayInWeek : CalendarDay;
    private numberOfDaysInMonth : number;
    constructor(args : { firstDayInWeek : CalendarDay; numberOfDaysInMonth : number }) {
        this.firstDayInWeek = args.firstDayInWeek;
        this.numberOfDaysInMonth = args.numberOfDaysInMonth;
    }
    public getNumericalDayOfMonthFromDayOfWeekName = (dayOfWeekName : DayOfWeekName) : number | null => {
        const firstDayInWeekDayName = this.firstDayInWeek.getDayOfWeekName();
        const firstDayInWeekDayOfMonth = this.firstDayInWeek.getNumericalDayOfMonth();
        if (firstDayInWeekDayName === dayOfWeekName) {
            return firstDayInWeekDayOfMonth;
        }
        const differenceBetweenDays : number = dayOfWeekName - firstDayInWeekDayName;
        if (differenceBetweenDays < 0 || (firstDayInWeekDayOfMonth + differenceBetweenDays) > this.numberOfDaysInMonth) {
            return null;
        }
        return firstDayInWeekDayOfMonth + differenceBetweenDays;
    }
    public getFirstDayInWeek = () : CalendarDay => this.firstDayInWeek;
    public getNumberOfDaysInMonth = () : number => this.numberOfDaysInMonth;
}
