import { SalesItem } from 'api/SalesItem/model/SalesItem';
import { UserAccountIdAndTimestamp } from 'api/UserAccount/model/UserAccountIdAndTimestamp';

export class SalesItemWithMetadata {
    constructor(
        private readonly salesItem : SalesItem,
        private readonly creationMetadata : UserAccountIdAndTimestamp,
        private readonly lastEditedMetadata : UserAccountIdAndTimestamp | null,
        private readonly deletionMetadata : UserAccountIdAndTimestamp | null,
        private readonly isArchived : boolean,
    ) {}

    public getSalesItem() : SalesItem {
        return this.salesItem;
    }

    public getCreationMetadata() : UserAccountIdAndTimestamp {
        return this.creationMetadata;
    }

    public getLastEditedMetadata() : UserAccountIdAndTimestamp | null {
        return this.lastEditedMetadata;
    }

    public getDeletionMetadata() : UserAccountIdAndTimestamp | null {
        return this.deletionMetadata;
    }

    public getIsArchived() : boolean {
        return this.isArchived;
    }
}
