import React from 'react';

export interface IProductRowPanelProps {
    isCollapsible : boolean;
    isCollapsed : boolean;
    panelTitle : string;
    numberOfProducts : number;
    setIsCollapsed : ((isCollapsed : boolean) => void) | null;
}

export class ProductRowPanel extends React.Component<IProductRowPanelProps, object> {
    public render() {
        const {
            panelTitle,
            numberOfProducts,
            isCollapsible,
            isCollapsed,
        } = this.props;
        return (
            <div className="product-row-panel">
                <div className="panel-header" onClick={ this.onHeaderClick } >
                    <p>
                        { `${ panelTitle }` }
                        <span className="results">
                            { ` ${ numberOfProducts } results` }
                        </span>
                        { isCollapsible && <span className={ 'bevicon ' + (isCollapsed ? 'bevico-unfold-more' : 'bevico-unfold-less') }/> }
                    </p>
                </div>
                { !(isCollapsible && isCollapsed) &&
                    <div className="panel-body">
                        { this.props.children }
                    </div>
                }
            </div>
        );
    }

    private readonly onHeaderClick = () => {
        const {
            isCollapsible,
            isCollapsed,
            setIsCollapsed,
        } = this.props;
        if (isCollapsible && setIsCollapsed) {
            setIsCollapsed(!isCollapsed);
        }
    }
}
