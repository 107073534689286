import { MassUnit } from 'api/Product/model/MassUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { Unit } from 'api/Product/model/Unit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

const convertMassQuantityToKg = (quantityInUnit : QuantityInUnit<MassUnit>) : QuantityInUnit<MassUnit.KILOGRAM> => {
    let quantity : number;
    switch (quantityInUnit.getUnit()) {
        case MassUnit.KILOGRAM:
            quantity = quantityInUnit.getQuantity();
            break;
        case MassUnit.GRAM:
            quantity = quantityInUnit.getQuantity() * 0.001;
            break;
        case MassUnit.POUND:
            quantity = quantityInUnit.getQuantity() * 0.453592;
            break;
        case MassUnit.DRY_OUNCE:
            quantity = quantityInUnit.getQuantity() * 0.0283495;
            break;
        default:
            throw new RuntimeException('unexpected mass unit');
    }

    return new QuantityInUnit(quantity, MassUnit.KILOGRAM);
};

const convertVolumeQuantityToMl = (quantityInUnit : QuantityInUnit<VolumeUnit>) : QuantityInUnit<VolumeUnit.MILLILITER> => {
    let quantity : number;
    switch (quantityInUnit.getUnit()) {
        case VolumeUnit.LITER:
            quantity =  quantityInUnit.getQuantity() * 1000.0;
            break;
        case VolumeUnit.CENTILITER:
            quantity =  quantityInUnit.getQuantity() * 10.0;
            break;
        case VolumeUnit.MILLILITER:
            quantity =  quantityInUnit.getQuantity();
            break;
        case VolumeUnit.GALLON:
            quantity =  quantityInUnit.getQuantity() * 3785.41;
            break;
        case VolumeUnit.QUART:
            quantity =  quantityInUnit.getQuantity() * 946.353;
            break;
        case VolumeUnit.PINT:
            quantity =  quantityInUnit.getQuantity() * 473.176;
            break;
        case VolumeUnit.OUNCE:
            quantity =  quantityInUnit.getQuantity() * 29.5735;
            break;
        case VolumeUnit.BAR_SPOON:
            quantity =  quantityInUnit.getQuantity() * 4.92892;
            break;
        case VolumeUnit.DASH:
            quantity =  quantityInUnit.getQuantity() * 0.616115;
            break;
        default:
            throw new RuntimeException('unexpected volume unit');
    }

    return new QuantityInUnit(quantity, VolumeUnit.MILLILITER);
};

const isMassQuantity = (quantityInUnit : QuantityInUnit<Unit>) : quantityInUnit is QuantityInUnit<MassUnit>  => {
    return MassUnit.isMassUnit(quantityInUnit.getUnit());
};

const isVolumeQuantity = (quantityInUnit : QuantityInUnit<Unit>) : quantityInUnit is QuantityInUnit<VolumeUnit>  => {
    return VolumeUnit.isVolumeUnit(quantityInUnit.getUnit());
};

const convertUnitQuantity = <T extends MassUnit | VolumeUnit>(quantityInUnit : QuantityInUnit<MassUnit | VolumeUnit>, unit : T) : QuantityInUnit<T> => {
    if (quantityInUnit.getUnit() === unit) {
        return quantityInUnit as QuantityInUnit<T>;
    }

    if (isMassQuantity(quantityInUnit) && MassUnit.isMassUnit(unit)) {
        return new QuantityInUnit(
            convertMassQuantityToKg(quantityInUnit).getQuantity() / convertMassQuantityToKg(new QuantityInUnit(1, unit)).getQuantity(),
            unit
        );
    } else if (isVolumeQuantity(quantityInUnit) && VolumeUnit.isVolumeUnit(unit)) {
        return new QuantityInUnit(
            convertVolumeQuantityToMl(quantityInUnit).getQuantity() / convertVolumeQuantityToMl(new QuantityInUnit(1, unit)).getQuantity(),
            unit
        );
    }

    throw new RuntimeException('Could not convert between provided units');
};

export const UnitUtils = {
    convertUnitQuantity,
};
