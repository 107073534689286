import moment from 'moment-timezone';

import { ToastIntegrationState } from 'api/Integration/model/ToastIntegrationState';
import { PlateIQIntegrationState } from '../model/PlateIQIntegrationState';

interface IToastIntegrationStateJSONObject {
    toast_restaurant_id : null | string;
    is_accessible : boolean;
    cache_data_effective_date : null | string;
}

interface IPlateIQIntegrationStateJSONOObject {
    plate_iq_restaurant_id : number | null;
    earliest_invoice_to_sync_date : string | null;
    gl_codes_to_sync : Array<string> | null;
    most_recent_sync_attempt_date : string | null;
}

export class IntegrationJSONToObjectSerializer {
    public getToastIntegrationState(jsonObject : IToastIntegrationStateJSONObject) {
        return new ToastIntegrationState(jsonObject.toast_restaurant_id, jsonObject.is_accessible, jsonObject.cache_data_effective_date ? moment.utc(jsonObject.cache_data_effective_date) : null);
    }

    public getPlateIQIntegrationState(jsonObject : IPlateIQIntegrationStateJSONOObject) {
        return new PlateIQIntegrationState(
            jsonObject.plate_iq_restaurant_id,
            jsonObject.earliest_invoice_to_sync_date ? moment.utc(jsonObject.earliest_invoice_to_sync_date) : null,
            jsonObject.gl_codes_to_sync ? new Set(jsonObject.gl_codes_to_sync) : null,
            jsonObject.most_recent_sync_attempt_date ? moment.utc(jsonObject.most_recent_sync_attempt_date) : null,
        );
    }
}
