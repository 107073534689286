//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';



var UserInfoExceptions = {};
export default UserInfoExceptions;
UserInfoExceptions.FirstNameInvalidStringLengthException = function(args) {
};
Thrift.inherits(UserInfoExceptions.FirstNameInvalidStringLengthException, Thrift.TException);
UserInfoExceptions.FirstNameInvalidStringLengthException.prototype.name = 'FirstNameInvalidStringLengthException';
UserInfoExceptions.FirstNameInvalidStringLengthException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserInfoExceptions.FirstNameInvalidStringLengthException.prototype.write = function(output) {
  output.writeStructBegin('FirstNameInvalidStringLengthException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserInfoExceptions.LastNameInvalidStringLengthException = function(args) {
};
Thrift.inherits(UserInfoExceptions.LastNameInvalidStringLengthException, Thrift.TException);
UserInfoExceptions.LastNameInvalidStringLengthException.prototype.name = 'LastNameInvalidStringLengthException';
UserInfoExceptions.LastNameInvalidStringLengthException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserInfoExceptions.LastNameInvalidStringLengthException.prototype.write = function(output) {
  output.writeStructBegin('LastNameInvalidStringLengthException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserInfoExceptions.PhoneNumberInvalidStringLengthException = function(args) {
};
Thrift.inherits(UserInfoExceptions.PhoneNumberInvalidStringLengthException, Thrift.TException);
UserInfoExceptions.PhoneNumberInvalidStringLengthException.prototype.name = 'PhoneNumberInvalidStringLengthException';
UserInfoExceptions.PhoneNumberInvalidStringLengthException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserInfoExceptions.PhoneNumberInvalidStringLengthException.prototype.write = function(output) {
  output.writeStructBegin('PhoneNumberInvalidStringLengthException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserInfoExceptions.ZipCodeInvalidStringLengthException = function(args) {
};
Thrift.inherits(UserInfoExceptions.ZipCodeInvalidStringLengthException, Thrift.TException);
UserInfoExceptions.ZipCodeInvalidStringLengthException.prototype.name = 'ZipCodeInvalidStringLengthException';
UserInfoExceptions.ZipCodeInvalidStringLengthException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserInfoExceptions.ZipCodeInvalidStringLengthException.prototype.write = function(output) {
  output.writeStructBegin('ZipCodeInvalidStringLengthException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserInfoExceptions.BarNameInvalidStringLengthException = function(args) {
};
Thrift.inherits(UserInfoExceptions.BarNameInvalidStringLengthException, Thrift.TException);
UserInfoExceptions.BarNameInvalidStringLengthException.prototype.name = 'BarNameInvalidStringLengthException';
UserInfoExceptions.BarNameInvalidStringLengthException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserInfoExceptions.BarNameInvalidStringLengthException.prototype.write = function(output) {
  output.writeStructBegin('BarNameInvalidStringLengthException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserInfoExceptions.BarConnectionInvalidStringLengthException = function(args) {
};
Thrift.inherits(UserInfoExceptions.BarConnectionInvalidStringLengthException, Thrift.TException);
UserInfoExceptions.BarConnectionInvalidStringLengthException.prototype.name = 'BarConnectionInvalidStringLengthException';
UserInfoExceptions.BarConnectionInvalidStringLengthException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserInfoExceptions.BarConnectionInvalidStringLengthException.prototype.write = function(output) {
  output.writeStructBegin('BarConnectionInvalidStringLengthException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserInfoExceptions.EmailAddressInvalidStringLengthException = function(args) {
};
Thrift.inherits(UserInfoExceptions.EmailAddressInvalidStringLengthException, Thrift.TException);
UserInfoExceptions.EmailAddressInvalidStringLengthException.prototype.name = 'EmailAddressInvalidStringLengthException';
UserInfoExceptions.EmailAddressInvalidStringLengthException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserInfoExceptions.EmailAddressInvalidStringLengthException.prototype.write = function(output) {
  output.writeStructBegin('EmailAddressInvalidStringLengthException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserInfoExceptions.InvalidZipCodeException = function(args) {
};
Thrift.inherits(UserInfoExceptions.InvalidZipCodeException, Thrift.TException);
UserInfoExceptions.InvalidZipCodeException.prototype.name = 'InvalidZipCodeException';
UserInfoExceptions.InvalidZipCodeException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserInfoExceptions.InvalidZipCodeException.prototype.write = function(output) {
  output.writeStructBegin('InvalidZipCodeException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

