import { Action } from 'redux';

import { ActionTypes, FileUploadActionInterfaces } from './actions';

export type IFileUploadInstance = Array<File | null>; // null means deleted file
export interface IFileUploadState {
    [key : string] : IFileUploadInstance;
}

const fileUploadInitialState : IFileUploadState = {
};

const reduceSetFile = (
    state : IFileUploadState,
    action : FileUploadActionInterfaces.ISetFile
) : IFileUploadState => {
    const fileUploadInstance = state[action.payload.id] || [];
    return {
        ...state,
        [action.payload.id]: [
            ...fileUploadInstance.slice(0, action.payload.index),
            action.payload.file,
            ...fileUploadInstance.slice(action.payload.index + 1),
        ],
    };
};

const reduceResetFileList = (
    state : IFileUploadState,
    action : FileUploadActionInterfaces.IResetFileList
) : IFileUploadState => {
    const newState = {
        ...state
    };
    delete newState[action.payload.id];
    return newState;
};

export const FileUploadReducers = (
    state : IFileUploadState = fileUploadInitialState,
    action : Action,
) : IFileUploadState => {
    switch (action.type) {
        case ActionTypes.SET_FILE:
            return reduceSetFile(state, action as FileUploadActionInterfaces.ISetFile);
        case ActionTypes.RESET_FILE_LIST:
            return reduceResetFileList(state, action as FileUploadActionInterfaces.IResetFileList);
        default:
            return state;
    }
};
