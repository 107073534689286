import React from 'react';

import { IValuesByDataIdAndCategoryName } from 'shared/models/Charts/IValuesByDataIdAndCategoryName';
import { YAxisLabel } from './YAxisLabel';

/**
 * IYAxisLabelContainer properties
 */
export interface IYAxisLabelContainer {
    /** function to handle mouse over events */
    mouseOverEvent : ((keyName : string) => void) | null;
    /** function to handle mouse out events  */
    mouseOutEvent : ((keyName : string) => void) | null;
    /** values for each data group (y-axis label), broken down by category */
    valuesByDataIdAndCategoryName : IValuesByDataIdAndCategoryName;
    /** pixel height for rows. Note: this does not necessarily === height of the actual bars, but should always be >= height of bar */
    rowHeight : number;
    /** padding between bars. used for centering label text on the actual bar, not just the row. Should always be Row Height - Bar Height */
    paddingBetweenBars : number | null;
    /** function to format the numbers (shown on print styles) */
    formatNumbers : ((value : number) => string | number) | null;
}

/**
 * Form YAxisLabelContainer
 */
export class YAxisLabelContainer extends React.Component<IYAxisLabelContainer, object> {

    public render() {
        const {
            mouseOverEvent,
            mouseOutEvent,
            valuesByDataIdAndCategoryName,
            rowHeight,
            paddingBetweenBars,
            formatNumbers,
        } = this.props;

        const yLabels = Object.keys(valuesByDataIdAndCategoryName).map((keyName : string, index : number) => {
            return (
                <div
                    className="bar-chart-y-axis-label-row"
                    key={ index }
                >
                    <YAxisLabel
                        labelText={ keyName }
                        handleMouseOut={ mouseOutEvent }
                        handleMouseOver={ mouseOverEvent }
                        labelHeight={ paddingBetweenBars ? (rowHeight - paddingBetweenBars) : rowHeight }
                        valuesByCategoryName={ valuesByDataIdAndCategoryName[keyName] }
                        formatNumbers={ formatNumbers }
                    />
                    { paddingBetweenBars &&
                        <div
                            className="bar-chart-y-axis-label-padding"
                            style={ { height: paddingBetweenBars + 'px' } }
                        />
                    }
                </div>
            );
        });

        return (
            <div className="col-xs-12 y-axis-labels">
                { yLabels }
            </div>
        );

    }

}
