import React from 'react';

import { CheckBoxTriState } from 'shared/models/CheckBoxTriState';

import { CheckBox } from 'shared/components/CheckBox';
import { Flex } from 'shared/components/FlexLayout/Flex';
import { IColumnSorting, SortableColumnHeader } from 'shared/components/SortableColumnHeader';
import { getCurrencySymbol } from 'shared/models/Currency';

export interface ISalesInputTableHeaderProps {
    hasCheckbox : boolean;
    selectAllState : CheckBoxTriState;
    onToggleSelect : (isSelected : boolean) => void;
    onSortChange : (sortBy : string) => void;
    sorting : IColumnSorting;
}

export class SalesInputTableHeader extends React.Component<ISalesInputTableHeaderProps, object> {
    private readonly currencySymbol = getCurrencySymbol();

    public render() {
        const {
            sorting,
            onSortChange,
            selectAllState,
            hasCheckbox
        } = this.props;

        return (
            <Flex direction="row" className="sales-input-table-header light-table-header">
                <Flex direction="column" className="checkbox-container" grow={ 1 } justify="center" align="center">
                    { hasCheckbox &&
                        <CheckBox
                            checkBoxTriState={ selectAllState }
                            isDisabled={ false }
                            onClick={ this.handleOnChange }
                            label={ null }
                        />
                    }
                </Flex>
                <Flex direction="column" className="flex-px-2" grow={ 6 } justify="center">
                    <SortableColumnHeader
                        classes="cell-ptb-sm ellipsis-out flex-child"
                        columnId="name"
                        sorting={ sorting }
                        onSortChange={ onSortChange }
                    >
                        POS Item Name
                    </SortableColumnHeader>
                </Flex>
                <Flex direction="column" className="flex-px-2" grow={ 2 } justify="center" align="center">
                    <SortableColumnHeader
                        classes="cell-ptb-sm ellipsis-out flex-child text-center"
                        columnId="sales_price"
                        sorting={ sorting }
                        onSortChange={ onSortChange }
                    >
                        Sales Price
                    </SortableColumnHeader>
                </Flex>
                <Flex direction="column" className="flex-px-2 section-start base-sold-header" grow={ 2 } justify="center" align="center">
                    <SortableColumnHeader
                        classes="cell-ptb-sm ellipsis-out flex-child text-center"
                        columnId="quantity_sold"
                        sorting={ sorting }
                        onSortChange={ onSortChange }
                    >
                        Base Sold
                    </SortableColumnHeader>
                </Flex>
                <Flex direction="row" className="flex-px-2 unit-adj-header" grow={ 2 } justify="center" align="center">
                    <SortableColumnHeader
                        classes="cell-ptb-sm ellipsis-out flex-child text-center"
                        columnId="quantity_adjustment"
                        sorting={ sorting }
                        onSortChange={ onSortChange }
                    >
                        Unit Adj.
                    </SortableColumnHeader>
                </Flex>
                <Flex direction="row" className="flex-px-2 section-end total-sold-header" grow={ 2 } justify="center" align="center">
                    <SortableColumnHeader
                        classes="cell-ptb-sm ellipsis-out flex-child text-center"
                        columnId="total_sold"
                        sorting={ sorting }
                        onSortChange={ onSortChange }
                    >
                        Total Sold
                    </SortableColumnHeader>
                </Flex>
                <Flex direction="column" className="flex-px-2" grow={ 2 } justify="center" align="center">
                    <SortableColumnHeader
                        classes="cell-ptb-sm ellipsis-out flex-child text-center"
                        columnId="dollar_adjustment"
                        sorting={ sorting }
                        onSortChange={ onSortChange }
                    >
                        { this.currencySymbol } Adj.
                    </SortableColumnHeader>
                </Flex>
                <Flex direction="column" className="flex-px-2 static-table-header" grow={ 4 } justify="center" align="center">
                    <div className="flex-child text-center">
                        Map Item
                    </div>
                </Flex>
            </Flex>
        );
    }

    private readonly handleOnChange = () => {
        this.props.onToggleSelect(this.props.selectAllState === CheckBoxTriState.Unchecked);
    }
}
