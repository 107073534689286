import React from 'react';

import { Dialog } from 'shared/components/Dialog';

export interface IItemInProgressDialog {
    handleContinue : () => void;
    handleReturnToTransfer : () => void;
}
export class ItemInProgressDialog extends React.Component<IItemInProgressDialog, object> {
    public render() {
        const {
            handleContinue,
            handleReturnToTransfer,
        } = this.props;

        return (
            <Dialog
                className="item-in-progress-dialog"
                buttons={
                    [
                        {
                            classes: '',
                            children: 'Cancel',
                            isDisabled: false,
                            isLoading: false,
                            onClick: handleReturnToTransfer,
                        },
                        {
                            classes: '',
                            children: 'Continue',
                            isDisabled: false,
                            isLoading: false,
                            onClick: handleContinue,
                        },
                    ]
                }
                headerText="Item in Progress"
            >
                <p>There is currently an item in progress that hasn’t been added to your transfer list, are you sure you want to continue?</p>
                <p>Click <b>Cancel</b> to finish adding the item to your list or <b>Continue</b> to proceed without adding the item to your transfer.</p>
            </Dialog>
        );
    }
}
