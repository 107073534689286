import { LocationId } from 'api/Location/model/LocationId';

export type invitationType = 'retailer' | 'group';

export class InviteInfo {
    private readonly userJoinedEstablishment : boolean;
    private readonly invitedByUserName : string;
    private readonly inviteType : invitationType;
    private readonly invitedToId : LocationId;
    private readonly invitedToName : string;

    constructor(
        userJoinedEstablishment : boolean,
        invitedByUserName : string,
        inviteType : invitationType,
        invitedToId : string,
        invitedToName : string
    ) {
        this.userJoinedEstablishment = userJoinedEstablishment;
        this.invitedByUserName = invitedByUserName;
        this.inviteType = inviteType;
        this.invitedToId = new LocationId(invitedToId);
        this.invitedToName = invitedToName;
    }

    public hasUserJoinedEstablishment = () : boolean => {
        return this.userJoinedEstablishment;
    }

    public getInvitedByUserName = () : string => {
        return this.invitedByUserName;
    }

    public getInvitationType = () : invitationType => {
        return this.inviteType;
    }

    // this could be a reatiler id OR a group id (check inviteType!)
    public getInvitedToId = () : LocationId => {
        return this.invitedToId;
    }

    public getInvitedToName = () : string => {
        return this.invitedToName;
    }
}
