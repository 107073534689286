import { StringValueMap } from 'api/Core/StringValueMap';
import { ProductJSONToObjectSerializer } from 'api/Product/serializer/ProductJSONToObjectSerializer';
import { CatalogItem } from 'api/Search/model/CatalogItem';
import { CatalogItemId } from 'api/Search/model/CatalogItemId';
import { ICatalogSearchResult } from 'api/Search/model/ICatalogSearchResult';
import { ICatalogSearchResultJsonObject } from 'api/Search/serializer/ICatalogSearchResultJsonObject';

export class CatalogJSONToObjectSerializer {
    constructor (private readonly productJSONToObjectSerializer : ProductJSONToObjectSerializer) {}

    public getCatalogSearchResult(catalogSearchResultJsonObject : ICatalogSearchResultJsonObject) : ICatalogSearchResult {
        const catalogItemsById = new StringValueMap<CatalogItemId, CatalogItem>();
        catalogSearchResultJsonObject.catalog_entry_ids.forEach((id : string) => {
            const catalogItemJsonObject = catalogSearchResultJsonObject.catalog_entries_by_id[id];
            catalogItemsById.set(
                new CatalogItemId(id),
                new CatalogItem(
                    catalogItemJsonObject.brand,
                    catalogItemJsonObject.name,
                    catalogItemJsonObject.product_category_id,
                    catalogItemJsonObject.product_type,
                    catalogItemJsonObject.options.map((option) => {
                        return { packaging : this.productJSONToObjectSerializer.getPackaging(option.package) };
                    })
                )
            );
        });

        return {
            totalMatchCount : catalogSearchResultJsonObject.total_matched,
            resultCountByFilterIdAndTerm : catalogSearchResultJsonObject.filter_count_by_filter_id_and_term,
            sortedCatalogItemsIds : catalogSearchResultJsonObject.catalog_entry_ids.map((value) => new CatalogItemId(value)),
            catalogItemsById,
        };
    }
}
