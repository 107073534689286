import moment from 'moment-timezone';

import { InvalidHourException } from './exceptions/InvalidHourException';
import { InvalidMinuteException } from './exceptions/InvalidMinuteException';

import { DateTime, Timezone } from '../../models/DateTime';
import { PeriodOfDay } from '../../models/PeriodOfDay';

import { IHourInputValidatorArgs, IMinuteInputValidatorArgs, InputValidators, IValidationResult } from '../../validators/InputValidators';

export const BevSpotTimePickerUtils = {
    addTwelveHours: (dateTime : DateTime) : DateTime => {
        const dateTimeAsUTCMoment : moment.Moment = BevSpotTimePickerUtils.convertDateTimeToUTCMoment(dateTime);
        const momentWithNewHour : moment.Moment = dateTimeAsUTCMoment.add({ hours: 12 });
        const dateTimeWithNewHour : DateTime = new DateTime({
            format: dateTime.format,
            timezone: dateTime.timezone,
            utcTime: momentWithNewHour.format(dateTime.format),
        });
        return dateTimeWithNewHour;
    },
    convertDateTimeToMoment: (dateTime : DateTime) : moment.Moment => {
        const dateTimeAsMoment = BevSpotTimePickerUtils.convertDateTimeToUTCMoment(dateTime);
        return dateTimeAsMoment.tz(Timezone[dateTime.timezone]);
    },
    convertDateTimeToUTCMoment: (dateTime : DateTime) : moment.Moment => {
        return moment.tz(dateTime.utcTime, dateTime.format, Timezone[Timezone.UTC]);
    },
    decrementHour: (dateTime : DateTime) : DateTime => {
        const dateTimeAsUTCMoment : moment.Moment = BevSpotTimePickerUtils.convertDateTimeToUTCMoment(dateTime);
        const momentWithDecrementedHour : moment.Moment = dateTimeAsUTCMoment.subtract({ hours: 1 });
        const dateTimeWithDecrementedHour : DateTime = new DateTime({
            format: dateTime.format,
            timezone: dateTime.timezone,
            utcTime: momentWithDecrementedHour.format(dateTime.format),
        });
        return dateTimeWithDecrementedHour;
    },
    decrementMinute: (dateTime : DateTime) : DateTime => {
        const dateTimeAsUTCMoment : moment.Moment = BevSpotTimePickerUtils.convertDateTimeToUTCMoment(dateTime);
        const momentWithDecrementedMinute : moment.Moment = dateTimeAsUTCMoment.subtract({ minutes: 1 });
        const dateTimeWithDecrementedMinute : DateTime = new DateTime({
            format: dateTime.format,
            timezone: dateTime.timezone,
            utcTime: momentWithDecrementedMinute.format(dateTime.format),
        });
        return dateTimeWithDecrementedMinute;
    },
    getPeriodOfDayFromDateTime: (dateTime : DateTime) : PeriodOfDay => {
        const dateTimeAsMoment = BevSpotTimePickerUtils.convertDateTimeToMoment(dateTime);
        return parseInt(PeriodOfDay[dateTimeAsMoment.format('A') as any], 10) as PeriodOfDay;
    },
    getHourAsStringFromDateTime: (dateTime : DateTime) : string => {
        const dateTimeAsMoment : moment.Moment = BevSpotTimePickerUtils.convertDateTimeToMoment(dateTime);
        return dateTimeAsMoment.format('h');
    },
    getMinuteAsStringFromDatetime: (dateTime : DateTime) : string => {
        const dateTimeAsMoment : moment.Moment = BevSpotTimePickerUtils.convertDateTimeToMoment(dateTime);
        return dateTimeAsMoment.format('mm');
    },
    incrementHour: (dateTime : DateTime) : DateTime => {
        const dateTimeAsUTCMoment : moment.Moment = BevSpotTimePickerUtils.convertDateTimeToUTCMoment(dateTime);
        const momentWithIncrementedHour : moment.Moment = dateTimeAsUTCMoment.add({ hours: 1 });
        const dateTimeWithIncrementedHour : DateTime = new DateTime({
            format: dateTime.format,
            timezone: dateTime.timezone,
            utcTime: momentWithIncrementedHour.format(dateTime.format),
        });
        return dateTimeWithIncrementedHour;
    },
    incrementMinute: (dateTime : DateTime) : DateTime => {
        const dateTimeAsUTCMoment : moment.Moment = BevSpotTimePickerUtils.convertDateTimeToUTCMoment(dateTime);
        const momentWithIncrementedMinute : moment.Moment = dateTimeAsUTCMoment.add({ minutes: 1 });
        const dateTimeWithIncrementedMinute : DateTime = new DateTime({
            format: dateTime.format,
            timezone: dateTime.timezone,
            utcTime: momentWithIncrementedMinute.format(dateTime.format),
        });
        return dateTimeWithIncrementedMinute;
    },
    setHour: (dateTime : DateTime, newHour : number) : DateTime => {
        if (newHour > 12 || newHour < 1) {
            throw new InvalidHourException();
        }
        const periodOfDay : PeriodOfDay = BevSpotTimePickerUtils.getPeriodOfDayFromDateTime(dateTime);
        const dateTimeAsMoment : moment.Moment = BevSpotTimePickerUtils.convertDateTimeToMoment(dateTime);
        let momentWithNewHour : moment.Moment = dateTimeAsMoment.hour(newHour);
        if (periodOfDay === PeriodOfDay.PM && newHour < 12) {
            momentWithNewHour = dateTimeAsMoment.hour(newHour + 12);
        }
        if (periodOfDay === PeriodOfDay.AM && newHour === 12) {
            momentWithNewHour = dateTimeAsMoment.hour(0);
        }
        const dateTimeWithNewHour : DateTime = new DateTime({
            format: dateTime.format,
            timezone: dateTime.timezone,
            utcTime: momentWithNewHour.tz(Timezone[Timezone.UTC]).format(dateTime.format),
        });
        return dateTimeWithNewHour;
    },
    setMinute: (dateTime : DateTime, newMinute : number) => {
            if (newMinute > 59 || newMinute < 0) {
                throw new InvalidMinuteException();
            }
            const dateTimeAsUTCMoment : moment.Moment = BevSpotTimePickerUtils.convertDateTimeToUTCMoment(dateTime);
            const momentWithNewMinute : moment.Moment = dateTimeAsUTCMoment.minute(newMinute);
            const dateTimeWithNewMinute : DateTime = new DateTime({
                format: dateTime.format,
                timezone: dateTime.timezone,
                utcTime: momentWithNewMinute.format(dateTime.format),
            });
            return dateTimeWithNewMinute;
    },
    subtractTwelveHours: (dateTime : DateTime) => {
        const dateTimeAsUTCMoment : moment.Moment = BevSpotTimePickerUtils.convertDateTimeToUTCMoment(dateTime);
        const momentWithNewHour : moment.Moment = dateTimeAsUTCMoment.subtract({ hours: 12 });
        const dateTimeWithNewHour : DateTime = new DateTime({
            format: dateTime.format,
            timezone: dateTime.timezone,
            utcTime: momentWithNewHour.format(dateTime.format),
        });
        return dateTimeWithNewHour;
    },
    validateHour: (hourValue : string) : IValidationResult => {
        const validationArgs : IHourInputValidatorArgs = { value: hourValue };
        const validationResult : IValidationResult = InputValidators.hourInputValidator(validationArgs);
        return validationResult;
    },
    validateMinute: (minuteValue : string) : IValidationResult => {
        const validationArgs : IMinuteInputValidatorArgs = { value: minuteValue };
        const validationResult : IValidationResult = InputValidators.minuteInputValidator(validationArgs);
        return validationResult;
    },
};
