import { StringValueMap } from 'api/Core/StringValueMap';
import { ToastIntegrationState } from 'api/Integration/model/ToastIntegrationState';
import { IToastRestaurant } from 'api/Integration/model/ToastRestaurant';
import { LocationId } from 'api/Location/model/LocationId';
import { AjaxUtils, IRestApiRejection } from 'shared/utils/ajaxUtils';
import { IToastIntegrationService } from '../interfaces/IToastIntegrationService';
import { IntegrationJSONToObjectSerializer } from '../serializer/IntegrationJSONToObjectSerializer';

export class ToastIntegrationServiceImpl implements IToastIntegrationService {
    constructor(
        private readonly integrationJSONToObjectSerializer : IntegrationJSONToObjectSerializer
    ) { }

    public getToastIntegrationState(
        locationId : LocationId
    ) {
        const queryParameters = {
            retailer_id: locationId.getValue(),
        };
        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:toast_integration'), queryParameters)
        .then((data) => {
            const deserializedData : ToastIntegrationState = this.integrationJSONToObjectSerializer.getToastIntegrationState(data);
            return Promise.resolve(deserializedData);
        })
        .catch((rejection : IRestApiRejection) => {
            return Promise.reject(rejection);
        });
    }

    public getToastAccessToken(
        locationId : LocationId
    ) : Promise<string | null> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
        };

        return AjaxUtils.ajaxGet(url('admin_tools:toast_access_token', null, queryParameters.retailer_id, null))
        .then((accessToken) => {
            return Promise.resolve(accessToken);
        })
        .catch((rejection : IRestApiRejection) => {
            return Promise.reject(rejection);
        });
    }

    public getAccessibleToastRestaurantsById() : Promise<{[toastRestaurantId : string] : IToastRestaurant}> {
        return AjaxUtils.ajaxGet(urlWithoutRetailerId('customer_success:get_accessible_toast_restaurants_by_id'));
    }

    public getMappedToastRestaurantIdByRetailerId() : Promise<StringValueMap<LocationId, string>> {
        return AjaxUtils.ajaxGet(urlWithoutRetailerId('customer_success:get_mapped_toast_restaurant_id_by_retailer_id'))
        .then((response) => {
            const data = new StringValueMap<LocationId, string>();
            Object.keys(response).forEach((retailerId) => {
                data.set(new LocationId(retailerId), response[retailerId]);
            });
            return data;
        });
    }

    public mapToastRestaurantId(toastRestaurantId : string | null, locationId : LocationId) : Promise<void> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
            toast_restaurant_id: toastRestaurantId || ''
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('customer_success:map_toast_restaurant_id'), queryParameters);
    }
}
