import { StringValueSet } from 'api/Core/StringValueSet';
import { DistributorRepId } from 'api/Distributor/model/DistributorRepId';

export class CartDistributorInfo {

    constructor (
        private readonly isSavedForLater : boolean,
        private readonly retailerNote : string,
        private readonly includedDistributorReps : StringValueSet<DistributorRepId>,
        private readonly purchaseOrderNumber : string,
    ) {}

    public getIsSavedForLater() : boolean {
        return this.isSavedForLater;
    }

    public getRetailerNote() : string {
        return this.retailerNote;
    }

    public getIncludedDistributorReps() : StringValueSet<DistributorRepId> {
        return this.includedDistributorReps;
    }

    public getPurchaseOrderNumber() : string {
        return this.purchaseOrderNumber;
    }
}
