import { FintechLocationId } from 'api/Integration/model/FintechLocationId';
import { FtpCredentials } from 'api/Integration/model/FtpCredentials';
import { Moment } from 'moment-timezone';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export class FintechIntegrationState {
    constructor(
        private readonly fintechLocationId : null | FintechLocationId,
        private readonly customFtpCredentials : null | FtpCredentials,
        private readonly lastAttemptTime : null | Moment,
        private readonly lastImportedFileDateUTC : null | Moment,
    ) {
        if (!!lastImportedFileDateUTC && !lastImportedFileDateUTC.isUTC()) {
            throw new RuntimeException('lastImportedFileDateUTC is not in UTC: ' + lastImportedFileDateUTC);
        }
    }

    public getFintechLocationId() : FintechLocationId | null {
        return this.fintechLocationId;
    }

    public getCustomFtpCredentials() : FtpCredentials | null {
        return this.customFtpCredentials;
    }

    public getLastAttemptTime() : Moment | null {
        return this.lastAttemptTime;
    }

    public getLastImportedFileDateUTC() : Moment | null {
        return this.lastImportedFileDateUTC;
    }
}
