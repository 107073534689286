import React from 'react';

import { Button } from 'shared/components/Button';

export interface IAddItemActionButtonProps {
    buttonText : string;
    numberOfSelectedItems : number;
    addItemsToEntity() : void;
}
export class AddItemActionButton extends React.Component<IAddItemActionButtonProps, object> {
    public shouldComponentUpdate(nextProps : IAddItemActionButtonProps) {
        return (
            nextProps.numberOfSelectedItems !== this.props.numberOfSelectedItems ||
            nextProps.buttonText !== this.props.buttonText
        );
    }
    public render() {
        const {
            buttonText,
            numberOfSelectedItems,
        } = this.props;

        return (
            <div className="add-item-action-button">
                <Button
                    buttonClassName="primary"
                    isDisabled={ numberOfSelectedItems === 0 }
                    isLoading={ false }
                    onClick={ this.onClick }
                >
                    { buttonText }
                </Button>
            </div>
        );
    }

    private readonly onClick = () => {
        this.props.addItemsToEntity();
    }

}
