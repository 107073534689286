import moment from 'moment-timezone';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import CoreTimeModel from 'gen-thrift/core_time_Model_types';

/** generated via `var tzs = {}; require('moment-timezone').tz.names().forEach(function (tz, i) { tzs[tz] = i; tzs[i] = tz; }); tzs;` */
export enum Timezone {
    'Africa/Abidjan' = 0,
    'Africa/Accra' = 1,
    'Africa/Addis_Ababa' = 2,
    'Africa/Algiers' = 3,
    'Africa/Asmara' = 4,
    'Africa/Asmera' = 5,
    'Africa/Bamako' = 6,
    'Africa/Bangui' = 7,
    'Africa/Banjul' = 8,
    'Africa/Bissau' = 9,
    'Africa/Blantyre' = 10,
    'Africa/Brazzaville' = 11,
    'Africa/Bujumbura' = 12,
    'Africa/Cairo' = 13,
    'Africa/Casablanca' = 14,
    'Africa/Ceuta' = 15,
    'Africa/Conakry' = 16,
    'Africa/Dakar' = 17,
    'Africa/Dar_es_Salaam' = 18,
    'Africa/Djibouti' = 19,
    'Africa/Douala' = 20,
    'Africa/El_Aaiun' = 21,
    'Africa/Freetown' = 22,
    'Africa/Gaborone' = 23,
    'Africa/Harare' = 24,
    'Africa/Johannesburg' = 25,
    'Africa/Juba' = 26,
    'Africa/Kampala' = 27,
    'Africa/Khartoum' = 28,
    'Africa/Kigali' = 29,
    'Africa/Kinshasa' = 30,
    'Africa/Lagos' = 31,
    'Africa/Libreville' = 32,
    'Africa/Lome' = 33,
    'Africa/Luanda' = 34,
    'Africa/Lubumbashi' = 35,
    'Africa/Lusaka' = 36,
    'Africa/Malabo' = 37,
    'Africa/Maputo' = 38,
    'Africa/Maseru' = 39,
    'Africa/Mbabane' = 40,
    'Africa/Mogadishu' = 41,
    'Africa/Monrovia' = 42,
    'Africa/Nairobi' = 43,
    'Africa/Ndjamena' = 44,
    'Africa/Niamey' = 45,
    'Africa/Nouakchott' = 46,
    'Africa/Ouagadougou' = 47,
    'Africa/Porto-Novo' = 48,
    'Africa/Sao_Tome' = 49,
    'Africa/Timbuktu' = 50,
    'Africa/Tripoli' = 51,
    'Africa/Tunis' = 52,
    'Africa/Windhoek' = 53,
    'America/Adak' = 54,
    'America/Anchorage' = 55,
    'America/Anguilla' = 56,
    'America/Antigua' = 57,
    'America/Araguaina' = 58,
    'America/Argentina/Buenos_Aires' = 59,
    'America/Argentina/Catamarca' = 60,
    'America/Argentina/ComodRivadavia' = 61,
    'America/Argentina/Cordoba' = 62,
    'America/Argentina/Jujuy' = 63,
    'America/Argentina/La_Rioja' = 64,
    'America/Argentina/Mendoza' = 65,
    'America/Argentina/Rio_Gallegos' = 66,
    'America/Argentina/Salta' = 67,
    'America/Argentina/San_Juan' = 68,
    'America/Argentina/San_Luis' = 69,
    'America/Argentina/Tucuman' = 70,
    'America/Argentina/Ushuaia' = 71,
    'America/Aruba' = 72,
    'America/Asuncion' = 73,
    'America/Atikokan' = 74,
    'America/Atka' = 75,
    'America/Bahia' = 76,
    'America/Bahia_Banderas' = 77,
    'America/Barbados' = 78,
    'America/Belem' = 79,
    'America/Belize' = 80,
    'America/Blanc-Sablon' = 81,
    'America/Boa_Vista' = 82,
    'America/Bogota' = 83,
    'America/Boise' = 84,
    'America/Buenos_Aires' = 85,
    'America/Cambridge_Bay' = 86,
    'America/Campo_Grande' = 87,
    'America/Cancun' = 88,
    'America/Caracas' = 89,
    'America/Catamarca' = 90,
    'America/Cayenne' = 91,
    'America/Cayman' = 92,
    'America/Chicago' = 93,
    'America/Chihuahua' = 94,
    'America/Coral_Harbour' = 95,
    'America/Cordoba' = 96,
    'America/Costa_Rica' = 97,
    'America/Creston' = 98,
    'America/Cuiaba' = 99,
    'America/Curacao' = 100,
    'America/Danmarkshavn' = 101,
    'America/Dawson' = 102,
    'America/Dawson_Creek' = 103,
    'America/Denver' = 104,
    'America/Detroit' = 105,
    'America/Dominica' = 106,
    'America/Edmonton' = 107,
    'America/Eirunepe' = 108,
    'America/El_Salvador' = 109,
    'America/Ensenada' = 110,
    'America/Fort_Nelson' = 111,
    'America/Fort_Wayne' = 112,
    'America/Fortaleza' = 113,
    'America/Glace_Bay' = 114,
    'America/Godthab' = 115,
    'America/Goose_Bay' = 116,
    'America/Grand_Turk' = 117,
    'America/Grenada' = 118,
    'America/Guadeloupe' = 119,
    'America/Guatemala' = 120,
    'America/Guayaquil' = 121,
    'America/Guyana' = 122,
    'America/Halifax' = 123,
    'America/Havana' = 124,
    'America/Hermosillo' = 125,
    'America/Indiana/Indianapolis' = 126,
    'America/Indiana/Knox' = 127,
    'America/Indiana/Marengo' = 128,
    'America/Indiana/Petersburg' = 129,
    'America/Indiana/Tell_City' = 130,
    'America/Indiana/Vevay' = 131,
    'America/Indiana/Vincennes' = 132,
    'America/Indiana/Winamac' = 133,
    'America/Indianapolis' = 134,
    'America/Inuvik' = 135,
    'America/Iqaluit' = 136,
    'America/Jamaica' = 137,
    'America/Jujuy' = 138,
    'America/Juneau' = 139,
    'America/Kentucky/Louisville' = 140,
    'America/Kentucky/Monticello' = 141,
    'America/Knox_IN' = 142,
    'America/Kralendijk' = 143,
    'America/La_Paz' = 144,
    'America/Lima' = 145,
    'America/Los_Angeles' = 146,
    'America/Louisville' = 147,
    'America/Lower_Princes' = 148,
    'America/Maceio' = 149,
    'America/Managua' = 150,
    'America/Manaus' = 151,
    'America/Marigot' = 152,
    'America/Martinique' = 153,
    'America/Matamoros' = 154,
    'America/Mazatlan' = 155,
    'America/Mendoza' = 156,
    'America/Menominee' = 157,
    'America/Merida' = 158,
    'America/Metlakatla' = 159,
    'America/Mexico_City' = 160,
    'America/Miquelon' = 161,
    'America/Moncton' = 162,
    'America/Monterrey' = 163,
    'America/Montevideo' = 164,
    'America/Montreal' = 165,
    'America/Montserrat' = 166,
    'America/Nassau' = 167,
    'America/New_York' = 168,
    'America/Nipigon' = 169,
    'America/Nome' = 170,
    'America/Noronha' = 171,
    'America/North_Dakota/Beulah' = 172,
    'America/North_Dakota/Center' = 173,
    'America/North_Dakota/New_Salem' = 174,
    'America/Ojinaga' = 175,
    'America/Panama' = 176,
    'America/Pangnirtung' = 177,
    'America/Paramaribo' = 178,
    'America/Phoenix' = 179,
    'America/Port-au-Prince' = 180,
    'America/Port_of_Spain' = 181,
    'America/Porto_Acre' = 182,
    'America/Porto_Velho' = 183,
    'America/Puerto_Rico' = 184,
    'America/Rainy_River' = 185,
    'America/Rankin_Inlet' = 186,
    'America/Recife' = 187,
    'America/Regina' = 188,
    'America/Resolute' = 189,
    'America/Rio_Branco' = 190,
    'America/Rosario' = 191,
    'America/Santa_Isabel' = 192,
    'America/Santarem' = 193,
    'America/Santiago' = 194,
    'America/Santo_Domingo' = 195,
    'America/Sao_Paulo' = 196,
    'America/Scoresbysund' = 197,
    'America/Shiprock' = 198,
    'America/Sitka' = 199,
    'America/St_Barthelemy' = 200,
    'America/St_Johns' = 201,
    'America/St_Kitts' = 202,
    'America/St_Lucia' = 203,
    'America/St_Thomas' = 204,
    'America/St_Vincent' = 205,
    'America/Swift_Current' = 206,
    'America/Tegucigalpa' = 207,
    'America/Thule' = 208,
    'America/Thunder_Bay' = 209,
    'America/Tijuana' = 210,
    'America/Toronto' = 211,
    'America/Tortola' = 212,
    'America/Vancouver' = 213,
    'America/Virgin' = 214,
    'America/Whitehorse' = 215,
    'America/Winnipeg' = 216,
    'America/Yakutat' = 217,
    'America/Yellowknife' = 218,
    'Antarctica/Casey' = 219,
    'Antarctica/Davis' = 220,
    'Antarctica/DumontDUrville' = 221,
    'Antarctica/Macquarie' = 222,
    'Antarctica/Mawson' = 223,
    'Antarctica/McMurdo' = 224,
    'Antarctica/Palmer' = 225,
    'Antarctica/Rothera' = 226,
    'Antarctica/South_Pole' = 227,
    'Antarctica/Syowa' = 228,
    'Antarctica/Troll' = 229,
    'Antarctica/Vostok' = 230,
    'Arctic/Longyearbyen' = 231,
    'Asia/Aden' = 232,
    'Asia/Almaty' = 233,
    'Asia/Amman' = 234,
    'Asia/Anadyr' = 235,
    'Asia/Aqtau' = 236,
    'Asia/Aqtobe' = 237,
    'Asia/Ashgabat' = 238,
    'Asia/Ashkhabad' = 239,
    'Asia/Baghdad' = 240,
    'Asia/Bahrain' = 241,
    'Asia/Baku' = 242,
    'Asia/Bangkok' = 243,
    'Asia/Barnaul' = 244,
    'Asia/Beirut' = 245,
    'Asia/Bishkek' = 246,
    'Asia/Brunei' = 247,
    'Asia/Calcutta' = 248,
    'Asia/Chita' = 249,
    'Asia/Choibalsan' = 250,
    'Asia/Chongqing' = 251,
    'Asia/Chungking' = 252,
    'Asia/Colombo' = 253,
    'Asia/Dacca' = 254,
    'Asia/Damascus' = 255,
    'Asia/Dhaka' = 256,
    'Asia/Dili' = 257,
    'Asia/Dubai' = 258,
    'Asia/Dushanbe' = 259,
    'Asia/Gaza' = 260,
    'Asia/Harbin' = 261,
    'Asia/Hebron' = 262,
    'Asia/Ho_Chi_Minh' = 263,
    'Asia/Hong_Kong' = 264,
    'Asia/Hovd' = 265,
    'Asia/Irkutsk' = 266,
    'Asia/Istanbul' = 267,
    'Asia/Jakarta' = 268,
    'Asia/Jayapura' = 269,
    'Asia/Jerusalem' = 270,
    'Asia/Kabul' = 271,
    'Asia/Kamchatka' = 272,
    'Asia/Karachi' = 273,
    'Asia/Kashgar' = 274,
    'Asia/Kathmandu' = 275,
    'Asia/Katmandu' = 276,
    'Asia/Khandyga' = 277,
    'Asia/Kolkata' = 278,
    'Asia/Krasnoyarsk' = 279,
    'Asia/Kuala_Lumpur' = 280,
    'Asia/Kuching' = 281,
    'Asia/Kuwait' = 282,
    'Asia/Macao' = 283,
    'Asia/Macau' = 284,
    'Asia/Magadan' = 285,
    'Asia/Makassar' = 286,
    'Asia/Manila' = 287,
    'Asia/Muscat' = 288,
    'Asia/Nicosia' = 289,
    'Asia/Novokuznetsk' = 290,
    'Asia/Novosibirsk' = 291,
    'Asia/Omsk' = 292,
    'Asia/Oral' = 293,
    'Asia/Phnom_Penh' = 294,
    'Asia/Pontianak' = 295,
    'Asia/Pyongyang' = 296,
    'Asia/Qatar' = 297,
    'Asia/Qyzylorda' = 298,
    'Asia/Rangoon' = 299,
    'Asia/Riyadh' = 300,
    'Asia/Saigon' = 301,
    'Asia/Sakhalin' = 302,
    'Asia/Samarkand' = 303,
    'Asia/Seoul' = 304,
    'Asia/Shanghai' = 305,
    'Asia/Singapore' = 306,
    'Asia/Srednekolymsk' = 307,
    'Asia/Taipei' = 308,
    'Asia/Tashkent' = 309,
    'Asia/Tbilisi' = 310,
    'Asia/Tehran' = 311,
    'Asia/Tel_Aviv' = 312,
    'Asia/Thimbu' = 313,
    'Asia/Thimphu' = 314,
    'Asia/Tokyo' = 315,
    'Asia/Tomsk' = 316,
    'Asia/Ujung_Pandang' = 317,
    'Asia/Ulaanbaatar' = 318,
    'Asia/Ulan_Bator' = 319,
    'Asia/Urumqi' = 320,
    'Asia/Ust-Nera' = 321,
    'Asia/Vientiane' = 322,
    'Asia/Vladivostok' = 323,
    'Asia/Yakutsk' = 324,
    'Asia/Yekaterinburg' = 325,
    'Asia/Yerevan' = 326,
    'Atlantic/Azores' = 327,
    'Atlantic/Bermuda' = 328,
    'Atlantic/Canary' = 329,
    'Atlantic/Cape_Verde' = 330,
    'Atlantic/Faeroe' = 331,
    'Atlantic/Faroe' = 332,
    'Atlantic/Jan_Mayen' = 333,
    'Atlantic/Madeira' = 334,
    'Atlantic/Reykjavik' = 335,
    'Atlantic/South_Georgia' = 336,
    'Atlantic/St_Helena' = 337,
    'Atlantic/Stanley' = 338,
    'Australia/ACT' = 339,
    'Australia/Adelaide' = 340,
    'Australia/Brisbane' = 341,
    'Australia/Broken_Hill' = 342,
    'Australia/Canberra' = 343,
    'Australia/Currie' = 344,
    'Australia/Darwin' = 345,
    'Australia/Eucla' = 346,
    'Australia/Hobart' = 347,
    'Australia/LHI' = 348,
    'Australia/Lindeman' = 349,
    'Australia/Lord_Howe' = 350,
    'Australia/Melbourne' = 351,
    'Australia/NSW' = 352,
    'Australia/North' = 353,
    'Australia/Perth' = 354,
    'Australia/Queensland' = 355,
    'Australia/South' = 356,
    'Australia/Sydney' = 357,
    'Australia/Tasmania' = 358,
    'Australia/Victoria' = 359,
    'Australia/West' = 360,
    'Australia/Yancowinna' = 361,
    'Brazil/Acre' = 362,
    'Brazil/DeNoronha' = 363,
    'Brazil/East' = 364,
    'Brazil/West' = 365,
    CET = 366,
    CST6CDT = 367,
    'Canada/Atlantic' = 368,
    'Canada/Central' = 369,
    'Canada/East-Saskatchewan' = 370,
    'Canada/Eastern' = 371,
    'Canada/Mountain' = 372,
    'Canada/Newfoundland' = 373,
    'Canada/Pacific' = 374,
    'Canada/Saskatchewan' = 375,
    'Canada/Yukon' = 376,
    'Chile/Continental' = 377,
    'Chile/EasterIsland' = 378,
    Cuba = 379,
    EET = 380,
    EST = 381,
    EST5EDT = 382,
    Egypt = 383,
    Eire = 384,
    'Etc/GMT' = 385,
    'Etc/GMT+0' = 386,
    'Etc/GMT+1' = 387,
    'Etc/GMT+10' = 388,
    'Etc/GMT+11' = 389,
    'Etc/GMT+12' = 390,
    'Etc/GMT+2' = 391,
    'Etc/GMT+3' = 392,
    'Etc/GMT+4' = 393,
    'Etc/GMT+5' = 394,
    'Etc/GMT+6' = 395,
    'Etc/GMT+7' = 396,
    'Etc/GMT+8' = 397,
    'Etc/GMT+9' = 398,
    'Etc/GMT-0' = 399,
    'Etc/GMT-1' = 400,
    'Etc/GMT-10' = 401,
    'Etc/GMT-11' = 402,
    'Etc/GMT-12' = 403,
    'Etc/GMT-13' = 404,
    'Etc/GMT-14' = 405,
    'Etc/GMT-2' = 406,
    'Etc/GMT-3' = 407,
    'Etc/GMT-4' = 408,
    'Etc/GMT-5' = 409,
    'Etc/GMT-6' = 410,
    'Etc/GMT-7' = 411,
    'Etc/GMT-8' = 412,
    'Etc/GMT-9' = 413,
    'Etc/GMT0' = 414,
    'Etc/Greenwich' = 415,
    'Etc/UCT' = 416,
    'Etc/UTC' = 417,
    'Etc/Universal' = 418,
    'Etc/Zulu' = 419,
    'Europe/Amsterdam' = 420,
    'Europe/Andorra' = 421,
    'Europe/Astrakhan' = 422,
    'Europe/Athens' = 423,
    'Europe/Belfast' = 424,
    'Europe/Belgrade' = 425,
    'Europe/Berlin' = 426,
    'Europe/Bratislava' = 427,
    'Europe/Brussels' = 428,
    'Europe/Bucharest' = 429,
    'Europe/Budapest' = 430,
    'Europe/Busingen' = 431,
    'Europe/Chisinau' = 432,
    'Europe/Copenhagen' = 433,
    'Europe/Dublin' = 434,
    'Europe/Gibraltar' = 435,
    'Europe/Guernsey' = 436,
    'Europe/Helsinki' = 437,
    'Europe/Isle_of_Man' = 438,
    'Europe/Istanbul' = 439,
    'Europe/Jersey' = 440,
    'Europe/Kaliningrad' = 441,
    'Europe/Kiev' = 442,
    'Europe/Kirov' = 443,
    'Europe/Lisbon' = 444,
    'Europe/Ljubljana' = 445,
    'Europe/London' = 446,
    'Europe/Luxembourg' = 447,
    'Europe/Madrid' = 448,
    'Europe/Malta' = 449,
    'Europe/Mariehamn' = 450,
    'Europe/Minsk' = 451,
    'Europe/Monaco' = 452,
    'Europe/Moscow' = 453,
    'Europe/Nicosia' = 454,
    'Europe/Oslo' = 455,
    'Europe/Paris' = 456,
    'Europe/Podgorica' = 457,
    'Europe/Prague' = 458,
    'Europe/Riga' = 459,
    'Europe/Rome' = 460,
    'Europe/Samara' = 461,
    'Europe/San_Marino' = 462,
    'Europe/Sarajevo' = 463,
    'Europe/Simferopol' = 464,
    'Europe/Skopje' = 465,
    'Europe/Sofia' = 466,
    'Europe/Stockholm' = 467,
    'Europe/Tallinn' = 468,
    'Europe/Tirane' = 469,
    'Europe/Tiraspol' = 470,
    'Europe/Ulyanovsk' = 471,
    'Europe/Uzhgorod' = 472,
    'Europe/Vaduz' = 473,
    'Europe/Vatican' = 474,
    'Europe/Vienna' = 475,
    'Europe/Vilnius' = 476,
    'Europe/Volgograd' = 477,
    'Europe/Warsaw' = 478,
    'Europe/Zagreb' = 479,
    'Europe/Zaporozhye' = 480,
    'Europe/Zurich' = 481,
    GB = 482,
    'GB-Eire' = 483,
    GMT = 484,
    'GMT+0' = 485,
    'GMT-0' = 486,
    GMT0 = 487,
    Greenwich = 488,
    HST = 489,
    Hongkong = 490,
    Iceland = 491,
    'Indian/Antananarivo' = 492,
    'Indian/Chagos' = 493,
    'Indian/Christmas' = 494,
    'Indian/Cocos' = 495,
    'Indian/Comoro' = 496,
    'Indian/Kerguelen' = 497,
    'Indian/Mahe' = 498,
    'Indian/Maldives' = 499,
    'Indian/Mauritius' = 500,
    'Indian/Mayotte' = 501,
    'Indian/Reunion' = 502,
    Iran = 503,
    Israel = 504,
    Jamaica = 505,
    Japan = 506,
    Kwajalein = 507,
    Libya = 508,
    MET = 509,
    MST = 510,
    MST7MDT = 511,
    'Mexico/BajaNorte' = 512,
    'Mexico/BajaSur' = 513,
    'Mexico/General' = 514,
    NZ = 515,
    'NZ-CHAT' = 516,
    Navajo = 517,
    PRC = 518,
    PST8PDT = 519,
    'Pacific/Apia' = 520,
    'Pacific/Auckland' = 521,
    'Pacific/Bougainville' = 522,
    'Pacific/Chatham' = 523,
    'Pacific/Chuuk' = 524,
    'Pacific/Easter' = 525,
    'Pacific/Efate' = 526,
    'Pacific/Enderbury' = 527,
    'Pacific/Fakaofo' = 528,
    'Pacific/Fiji' = 529,
    'Pacific/Funafuti' = 530,
    'Pacific/Galapagos' = 531,
    'Pacific/Gambier' = 532,
    'Pacific/Guadalcanal' = 533,
    'Pacific/Guam' = 534,
    'Pacific/Honolulu' = 535,
    'Pacific/Johnston' = 536,
    'Pacific/Kiritimati' = 537,
    'Pacific/Kosrae' = 538,
    'Pacific/Kwajalein' = 539,
    'Pacific/Majuro' = 540,
    'Pacific/Marquesas' = 541,
    'Pacific/Midway' = 542,
    'Pacific/Nauru' = 543,
    'Pacific/Niue' = 544,
    'Pacific/Norfolk' = 545,
    'Pacific/Noumea' = 546,
    'Pacific/Pago_Pago' = 547,
    'Pacific/Palau' = 548,
    'Pacific/Pitcairn' = 549,
    'Pacific/Pohnpei' = 550,
    'Pacific/Ponape' = 551,
    'Pacific/Port_Moresby' = 552,
    'Pacific/Rarotonga' = 553,
    'Pacific/Saipan' = 554,
    'Pacific/Samoa' = 555,
    'Pacific/Tahiti' = 556,
    'Pacific/Tarawa' = 557,
    'Pacific/Tongatapu' = 558,
    'Pacific/Truk' = 559,
    'Pacific/Wake' = 560,
    'Pacific/Wallis' = 561,
    'Pacific/Yap' = 562,
    Poland = 563,
    Portugal = 564,
    ROC = 565,
    ROK = 566,
    Singapore = 567,
    Turkey = 568,
    UCT = 569,
    'US/Alaska' = 570,
    'US/Aleutian' = 571,
    'US/Arizona' = 572,
    'US/Central' = 573,
    'US/East-Indiana' = 574,
    'US/Eastern' = 575,
    'US/Hawaii' = 576,
    'US/Indiana-Starke' = 577,
    'US/Michigan' = 578,
    'US/Mountain' = 579,
    'US/Pacific' = 580,
    'US/Pacific-New' = 581,
    'US/Samoa' = 582,
    UTC = 583,
    Universal = 584,
    'W-SU' = 585,
    WET = 586,
    Zulu = 587
}

export class DateTime {
    public static now() : DateTime {
        const dateTimeFormat = 'MM/DD/YYYY hh:mma z';   // MINUTE precision!!
        let timezone : Timezone;
        if (window.GLOBAL_RETAILER_TIME_ZONE) {
            timezone = parseInt(Timezone[window.GLOBAL_RETAILER_TIME_ZONE as any], 10);
        } else {
            timezone = Timezone.UTC;
        }

        return new DateTime({
            format: dateTimeFormat,
            timezone,
            utcTime: moment().tz(Timezone[Timezone.UTC]).format(dateTimeFormat),
        });
    }

    public utcTime : string;
    public timezone : Timezone;
    public format : string;

    constructor(args : {
        utcTime : string | CoreTimeModel.TimestampWithMillisecondPrecision;
        timezone : Timezone;
        format : string;
    }) {
        if (args.timezone === null || typeof Timezone[args.timezone] === 'undefined') {
            throw new RuntimeException('Timezone does not exist');
        }

        this.timezone = args.timezone;
        this.format = args.format;

        if (args.utcTime instanceof CoreTimeModel.TimestampWithMillisecondPrecision) {
            this.utcTime = moment.tz(args.utcTime.timeSinceUnixEpoch.value, Timezone[Timezone.UTC]).format(this.format);
        } else {
            this.utcTime = args.utcTime;
        }
    }

    public getValue(format? : string) : string {
        // Moment.js is no longer in active development and so is no longer recommended to be used (https://momentjs.com/docs/#/-project-status/)
        return moment.tz(this.utcTime, this.format, Timezone[Timezone.UTC]).tz(Timezone[this.timezone]).format(format || this.format);
    }

    public getValueInUTC(format? : string) : string {
        return moment.tz(this.utcTime, this.format, Timezone[Timezone.UTC]).format(format || this.format);
    }

    public getValueInTimezone(timezone : Timezone, format? : string) : string {
        return moment.tz(this.utcTime, this.format, Timezone[Timezone.UTC]).tz(Timezone[timezone]).format(format || this.format);
    }

    public toMilliseconds() : number {
        return +moment.tz(this.utcTime, this.format, Timezone[Timezone.UTC]).tz(Timezone[this.timezone]);
    }

    public toTimestampWithMillisecondPrecision() : CoreTimeModel.TimestampWithMillisecondPrecision {
        return new CoreTimeModel.TimestampWithMillisecondPrecision({
            timeSinceUnixEpoch: new CoreTimeModel.Milliseconds({
                value: this.toMilliseconds(),
            }),
        });
    }

    public toMoment() : moment.Moment {
        return moment.tz(this.utcTime, this.format, Timezone[Timezone.UTC]).tz(Timezone[this.timezone]);
    }
}

export interface IDateRange {
    startDateTime : DateTime;
    endDateTime : DateTime;
}
