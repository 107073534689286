import React from 'react';

import { CheckBoxTriState } from 'shared/models/CheckBoxTriState';

import { CheckBox } from 'shared/components/CheckBox';
import { IColumnSorting, SortableColumnHeader } from 'shared/components/SortableColumnHeader';

export interface IUnassignedItemsTableHeaderProps {
    selectAllState : CheckBoxTriState;
    onToggleSelect : (isSelected : boolean) => void;
    onSortChange : (sortBy : string) => void;
    sorting : IColumnSorting;
}

export class UnassignedItemsTableHeader extends React.Component<IUnassignedItemsTableHeaderProps, object> {

    public render() {
        const {
            sorting,
            onSortChange
        } = this.props;

        return (
            <div className="unassigned-items-table-header light-table-header">
                <div className="col-row cell-plr-sm">
                    <div className="cell col-xs-8 col-sm-4">
                        <div className="col-row">
                            <div className="cell col-xs-1 cell-ptb">
                                <CheckBox
                                    checkBoxTriState={ this.props.selectAllState }
                                    isDisabled={ false }
                                    onClick={ this.handleOnChange }
                                    label={ null }
                                />
                            </div>
                            <SortableColumnHeader
                                classes="col-sm-11 cell-ptb cell-plr ellipsis-out"
                                columnId="ITEM_NAME"
                                sorting={ sorting }
                                onSortChange={ onSortChange }
                            >
                                Item
                            </SortableColumnHeader>
                        </div>
                    </div>
                    <SortableColumnHeader
                        classes="col-xs-12 col-sm-5 col-lg-6 cell-plr-sm"
                        columnId="STORAGE_AREAS"
                        sorting={ sorting }
                        onSortChange={ onSortChange }
                    >
                        Storage Areas
                    </SortableColumnHeader>
                    <div className="col-xs-4 col-sm-3 col-lg-2 hidden-xs"/>
                </div>
            </div>
        );
    }

    private readonly handleOnChange = () => {
        this.props.onToggleSelect(this.props.selectAllState === CheckBoxTriState.Unchecked);
    }
}
