import PublicContentModel from 'gen-thrift/public_content_Model_types';
import PublicContentViewEventService from 'gen-thrift/PublicContentViewEventService';
import UnauthenticatedUserSessionModel from 'gen-thrift/unauthenticated_user_session_Model_types';

import { PublicContentTypeEnum } from 'api/PublicContent/model/PublicContentTypeEnum';
import { PublicContentUrl } from 'api/PublicContent/model/PublicContentUrl';
import { PublicContentViewEventTypeEnum } from 'api/PublicContent/model/PublicContentViewEventTypeEnum';
import { UserPublicContent } from 'api/PublicContent/model/UserPublicContent';
import { PublicContentObjectToThriftSerializer } from 'api/PublicContent/serializer/PublicContentObjectToThriftSerializer';
import { PublicContentThriftToObjectSerializer } from 'api/PublicContent/serializer/PublicContentThriftToObjectSerializer';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { UserAccountObjectToThriftSerializer } from 'api/UserAccount/serializer/UserAccountObjectToThriftSerializer';
import { IPublicContentViewEventService } from '../interfaces/IPublicContentViewEventService';

export class PublicContentViewEventServiceImpl implements IPublicContentViewEventService {

    private publicContentViewEventServiceClient : PublicContentViewEventService.PublicContentViewEventServiceClient;
    private userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer;
    private publicContentObjectToThriftSerializer : PublicContentObjectToThriftSerializer;
    private publicContentThriftToObjectSerializer : PublicContentThriftToObjectSerializer;

    constructor(
        publicContentViewEventServiceClient : PublicContentViewEventService.PublicContentViewEventServiceClient,
        userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer,
        publicContentObjectToThriftSerializer : PublicContentObjectToThriftSerializer,
        publicContentThriftToObjectSerializer : PublicContentThriftToObjectSerializer,
    ) {
        this.publicContentViewEventServiceClient = publicContentViewEventServiceClient;
        this.userAccountObjectToThriftSerializer = userAccountObjectToThriftSerializer;
        this.publicContentObjectToThriftSerializer = publicContentObjectToThriftSerializer;
        this.publicContentThriftToObjectSerializer = publicContentThriftToObjectSerializer;
    }

    // core_api_Exceptions.RateLimitExceededException
    // core_api_Exceptions.ServiceTemporarilyUnavailableException
    // unauthenticated_user_session_Exceptions.UnknownUnauthenticatedUserSessionIdException
    // public_content_Exceptions.InvalidArgumentsToCreateViewEventException
    public createPublicContentViewEventForUnauthenticatedSession(
        unauthenticatedUserSessionId : UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId,
        eventType : PublicContentViewEventTypeEnum,
        publicContentUrl : PublicContentUrl,
        publicContentUrlParameters : { [k : string] : Array<string> },
        referrer : string,
    ) : Promise<void> {

        return new Promise<void>((resolve, reject) => {
            this.publicContentViewEventServiceClient.createPublicContentViewEventForUnauthenticatedSession(
                unauthenticatedUserSessionId,
                this.publicContentObjectToThriftSerializer.getThriftPublicContentViewEventType(eventType),
                this.publicContentObjectToThriftSerializer.getThriftPublicContentUrl(publicContentUrl),
                publicContentUrlParameters,
                referrer,
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve();
                }
            );
        });
    }

    // core_api_Exceptions.RateLimitExceededException
    // core_api_Exceptions.ServiceTemporarilyUnavailableException
    // accounts_Exceptions.UnknownSessionIdentifierException
    // public_content_Exceptions.InvalidArgumentsToCreateViewEventException
    public createPublicContentViewEventForAuthenticatedSession(
        userSessionId : UserSessionId,
        eventType : PublicContentViewEventTypeEnum,
        publicContentUrl : PublicContentUrl,
        publicContentUrlParameters : { [k : string] : Array<string> },
        referrer : string,
    ) : Promise<void> {

        return new Promise<void>((resolve, reject) => {
            this.publicContentViewEventServiceClient.createPublicContentViewEventForAuthenticatedSession(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.publicContentObjectToThriftSerializer.getThriftPublicContentViewEventType(eventType),
                this.publicContentObjectToThriftSerializer.getThriftPublicContentUrl(publicContentUrl),
                publicContentUrlParameters,
                referrer,
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve();
                }
            );
        });
    }

    // core_api_Exceptions.RateLimitExceededException
    // core_api_Exceptions.ServiceTemporarilyUnavailableException
    // accounts_Exceptions.UnknownSessionIdentifierException
    // public_content_Exceptions.InvalidArgumentAsPublicContentTypeException
    public getUserPublicContentForAuthenticatedSession(
        userSessionId : UserSessionId,
        publicContentType : PublicContentTypeEnum
    ) : Promise<UserPublicContent> {

        return new Promise<UserPublicContent>((resolve, reject) => {
            this.publicContentViewEventServiceClient.getUserPublicContentForAuthenticatedSession(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.publicContentObjectToThriftSerializer.getThriftPublicContentType(publicContentType),
                (result : PublicContentModel.UserPublicContent | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    const userPublicContent : UserPublicContent = this.publicContentThriftToObjectSerializer.getUserPublicContent(result);
                    return resolve(userPublicContent);
                }
            );
        });
    }
}
