import React from 'react';

import { Dialog } from 'shared/components/Dialog';

export interface IDiscardProgressDialog {
    isCreatingNewReport : boolean;
    handleDiscardProgress : () => void;
    handleReturnToTransfer : () => void;
}

export class DiscardProgressDialog extends React.PureComponent<IDiscardProgressDialog, object> {
    public render() {
        const {
            isCreatingNewReport,
            handleDiscardProgress,
            handleReturnToTransfer,
        } = this.props;

        let information : JSX.Element | null = null;
        if (isCreatingNewReport) {
            information = (<p>Click <b>Cancel</b> to continue creating this report or <b>Discard Progress</b> to return to your inventory history and discard this report.</p>);
        } else {
            information = (<p>Click <b>Cancel</b> to continue modifying this report or <b>Discard Progress</b> to return to your report summary and discard your changes.</p>);
        }

        return (
            <Dialog
                className="disacrd-progress-dialog"
                buttons={
                    [
                        {
                            classes: '',
                            children: 'Cancel',
                            isDisabled: false,
                            isLoading: false,
                            onClick: handleReturnToTransfer,
                        },
                        {
                            classes: '',
                            children: 'Discard Progress',
                            isDisabled: false,
                            isLoading: false,
                            onClick: handleDiscardProgress,
                        },
                    ]
                }
                headerText="Discard Progress?"
            >
                <p>By continuing you will discard your progress on this report.</p>
                { information }
            </Dialog>
        );
    }
}
