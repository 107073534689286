export class BankAccount {
    constructor (
        private readonly accountHolderName : string,
        private readonly accountHolderType : string,
        private readonly bankName : string,
        private readonly lastFourDigits : string, // string because it might start with 0
        private readonly status : string
    ) { }

    public getAccountHolderName() : string {
        return this.accountHolderName;
    }

    public getAccountHolderType() : string {
        return this.accountHolderType;
    }

    public getBankName() : string {
        return this.bankName;
    }

    public getLastFourDigits() : string {
        return this.lastFourDigits;
    }

    public getStatus() : string {
        return this.status;
    }
}
