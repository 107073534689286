import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { Product } from 'api/Product/model/Product';
import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductId } from 'api/Product/model/ProductId';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemWithMetadata } from 'api/SalesItem/model/SalesItemWithMetadata';
import { SalesItemUtils } from 'api/SalesItem/utils/SalesItemUtils';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

// This felt weird to put in the API Utils, so it's living here for now -- this is just an optimization since
// calculating cost for sales items can be complex and we don't want to have to do it more times than necessary
// in the future we'd ideally have a more robust caching strategy for things like this
// (particularly because we might want something like this for sales items on many different pages)
let lastSalesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata> | undefined;
let lastProductsById : StringValueMap<ProductId, Product> | undefined;
let lastProductCostsById : StringValueMap<ProductId, ProductCost> | undefined;
const costBySalesItemId = new StringValueMap<SalesItemId, number | null>();
export const getCachedSalesItemCostsForIds = (
    salesItemIds : StringValueSet<SalesItemId>,
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>,
    productsById : StringValueMap<ProductId, Product>,
    productCostsById : StringValueMap<ProductId, ProductCost>
) : StringValueMap<SalesItemId, number | null> => {

    // future optimization: can just compare relevant sales item info in sales items by id to see if we need to recalculate (ingredients, yield, serving size)
    if (productCostsById !== lastProductCostsById || productsById !== lastProductsById || salesItemsById !== lastSalesItemsById) {
        costBySalesItemId.clear();
        lastProductCostsById = productCostsById;
        lastProductsById = productsById;
        lastSalesItemsById = salesItemsById;
    }

    const costsBySalesItemIdForIds = new StringValueMap<SalesItemId, number | null>();
    salesItemIds.forEach((salesItemId) => {
        const cachedValue = costBySalesItemId.get(salesItemId);
        if (cachedValue) {
            costsBySalesItemIdForIds.set(salesItemId, cachedValue);
        } else {
            const salesItemWithMetadata = salesItemsById.get(salesItemId);
            if (typeof salesItemWithMetadata === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            const salesItemCost = SalesItemUtils.calculateCostOfSalesItem(
                salesItemId, salesItemWithMetadata.getSalesItem(),
                salesItemsById, productsById, productCostsById,
                costBySalesItemId, new StringValueSet());

            costsBySalesItemIdForIds.set(salesItemId, salesItemCost);
        }
    });

    return costsBySalesItemIdForIds;
};
