import { IAction, IActionCreatorsMapObject } from '../../../models/IAction';

import { ErrorNotificationTypeEnum } from '../models/ErrorNotificationTypeEnum';

export const ActionTypes = {
    SET_ERROR_NOTIFICATION_TYPE : 'ERROR_NOTIFICATION_MODAL/SET_ERROR_NOTIFICATION_TYPE',
};

export namespace ActionInterfaces {
    export interface ISetErrorNotificationType extends IAction {
        payload : {
            errorNotificationType : ErrorNotificationTypeEnum,
        };
    }

    export interface IErrorNotificationDialogActionCreatorsMapObject extends IActionCreatorsMapObject {
        setErrorNotificationType : (errorNotificationType : ErrorNotificationTypeEnum) => ISetErrorNotificationType;
    }
}

const setErrorNotificationType = (
    errorNotificationType : ErrorNotificationTypeEnum
) : ActionInterfaces.ISetErrorNotificationType => ({
    payload : {
        errorNotificationType,
    },
    type : ActionTypes.SET_ERROR_NOTIFICATION_TYPE,
});

export const ErrorNotificationDialogActions : ActionInterfaces.IErrorNotificationDialogActionCreatorsMapObject = {
    setErrorNotificationType,
};
