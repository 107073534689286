import React from 'react';

import 'shared/components/Dropdown/DropdownMenu.scss';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export type OptionValue = string;
export interface IOption {
    icon : string | null;
    label : string;
    value : OptionValue;
    disabled? : boolean;
}

type DropdownMenuStyleTypes = 'outline' | 'floating' | 'underline' | 'drink-price-tool';

export interface IDropdownMenuProps {
    styleType : DropdownMenuStyleTypes;
    options : Array<IOption>;

    dropdownOptionsShown : boolean;
    selectedOptionValue : OptionValue | null;
    placeholderText : string | null;

    onOptionClick : (option : IOption) => void;
    onClick : () => void;
    onBlur : () => void;
}

export interface IDropdownMenuState {
    isHovered : boolean;
}

export class DropdownMenu extends React.Component<IDropdownMenuProps, IDropdownMenuState> {
    private dropdownMenuComponentDiv : any | null;

    public constructor(props : IDropdownMenuProps) {
        super(props);
        this.state = {
            isHovered : false
        };
    }

    public render() {
        const {
            styleType,
            onClick,
            onOptionClick,
            options,
            dropdownOptionsShown,
            selectedOptionValue,
            onBlur,
        } = this.props;

        const placeholderText = this.props.placeholderText ? this.props.placeholderText : 'Select an option';

        const optionValuesMap = new Map<OptionValue, IOption>(
            options.map((option : IOption) : [OptionValue, IOption] => [option.value, option] as [OptionValue, IOption])
        );

        if (optionValuesMap.size !== options.length) {
            throw new RuntimeException('you can only have unique option values');
        }

        let selectedOption : IOption | undefined;
        if (selectedOptionValue !== null) {
            selectedOption = optionValuesMap.get(selectedOptionValue);
            if (selectedOption === undefined || !optionValuesMap.has(selectedOption.value)) {
                throw new RuntimeException(`the selected option value, "${ selectedOptionValue }", is not in the set of options`);
            }
        }

        const dropdownMenuComponentRef = (div : any) => {
            this.dropdownMenuComponentDiv = div;
        };

        const onDropdownMenuComponentBlur = () => {
            if (!this.state.isHovered) {
                onBlur();
            }
        };

        const optionElements : Array<JSX.Element> = options.map((option : IOption, index : number) => {
            const selectOption = () => {
                if (!option.disabled) {
                    onOptionClick(option);
                }
            };

            const optionClasses = ['dropdown-menu-component-option'];
            if (option.disabled) {
                optionClasses.push('disabled-option');
            }
            if (selectedOption && option.value === selectedOption.value) {
                optionClasses.push('selected-option');
            }

            return (
                <div
                    className={ optionClasses.join(' ') }
                    onClick={ selectOption }
                    onMouseEnter={ this.onMouseEnter }
                    onMouseLeave={ this.onMouseLeave }
                    key={ index }
                >
                    { option.icon &&
                        <span className={ `bevicon ${option.icon}` } />
                    }
                    { option.label }
                </div>
            );
        });

        return (
            <div
                className={ 'dropdown-menu-component ' + styleType }
                tabIndex={ 0 }
                ref={ dropdownMenuComponentRef }
                onBlur={ onDropdownMenuComponentBlur }
            >
                <div className="dropdown-menu-component-button ellipsis-out" onClick={ onClick }>
                    { selectedOption && selectedOption.icon &&
                        <span className={ `bevicon ${selectedOption.icon}` } />
                    }
                    { selectedOption ? selectedOption.label : placeholderText }
                    <span className="dropdown-menu-component-arrow bevicon bevico-arrow_drop_down"/>
                    <hr/>
                </div>
                <div className={ 'dropdown-menu-component-options ' + (dropdownOptionsShown ? '' : 'dropdown-menu-component-options-hidden') }>
                    { optionElements }
                </div>
            </div>
        );
    }

    private readonly onMouseEnter = () => {
        this.setState({
            isHovered: true,
        });
    }

    private readonly onMouseLeave = () => {
        this.setState({
            isHovered: false,
        });
    }
}
