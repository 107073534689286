import React from 'react';

import { MobileSearchBar } from 'shared/components/MobileSearchBar/MobileSearchBar';
import { RowOptimizationWrapper } from 'shared/components/RowOptimizationWrapper/RowOptimizationWrapper';
import { SearchBar, SearchBarTheme } from 'shared/components/SearchBar/SearchBar';
import { ISearchBarState } from 'shared/models/ISearchBarState';

// TODO: refactor the css on add item header + order detail so that this component has some shared css

export interface ISearchBarWithSuggestedOptionsProps<T> {
    searchBar : ISearchBarState;
    searchBarTheme : SearchBarTheme;
    searchBarPlaceholderText : string | null;
    searchSuggestionListIsShown : boolean;

    labelNamesAndSearchSuggestions : Array<[string | null, Array<T>]>;
    highlightedSearchSuggestion : T | null;

    dropdownRowClassName : string;
    dropdownContainerClassName : string | null;

    moreOptionsHeader : JSX.Element | null;

    moreOptionsFooter : JSX.Element | null;

    searchBarIsMobileComponent : boolean; // this is normally handled through media queries, but in this case we want the parent to determine this

    setSearchSuggestionListIsShown : (isShown : boolean) => void;
    createSuggestionOptionElement : (suggestedOptionValue : T) => JSX.Element;

    onSearchBarEnterClick : (searchTerm : string) => void;
    onClearSearchTerm : () => void;
    onSearchTermValueChange : (searchTerm : string) => void;

    onOptionClick : (suggestedOptionValue : T) => void;

    setHighlightedSearchSuggestionMouse : (suggestedOptionValue : T | null) => void;
    setHighlightedSearchSuggestionKeyboard : (suggestedOptionValue : T | null) => void;

    closeMobileSearchBar : () => void;
}

export class SearchBarWithSuggestedOptions<T> extends React.Component<ISearchBarWithSuggestedOptionsProps<T>, object> {
    private dropdownMenuComponentDiv : HTMLDivElement | undefined;
    private dropdownOptionsDiv : HTMLDivElement | undefined;
    private lastClientMouseX : number | null = null;
    private lastClientMouseY : number | null = null;

    public componentDidMount() {
        window.addEventListener('click', this.onWindowClick);
        document.addEventListener('keydown', this.onDocumentKeyDown);
    }

    public componentWillUnmount() {
        window.removeEventListener('click', this.onWindowClick);
        document.removeEventListener('keydown', this.onDocumentKeyDown);
    }

    public render() {
        const {
            searchBar,
            searchBarIsMobileComponent,
            closeMobileSearchBar,
            labelNamesAndSearchSuggestions,
            setHighlightedSearchSuggestionMouse,
            searchSuggestionListIsShown,
            highlightedSearchSuggestion,
            createSuggestionOptionElement,
            moreOptionsFooter,
            moreOptionsHeader,
            searchBarPlaceholderText,
            onSearchBarEnterClick,
            onClearSearchTerm,
            onOptionClick,
            onSearchTermValueChange,
            dropdownRowClassName,
            searchBarTheme
        } = this.props;

        let searchSuggestionOptions : Array<JSX.Element | null> = [];
        let numberOfSuggestions : number = 0;
        labelNamesAndSearchSuggestions.forEach((labelNameAndSuggestions, labelNameAndSuggestionsIndex) => {
            const labelName = labelNameAndSuggestions[0];
            const suggestions = labelNameAndSuggestions[1];

            numberOfSuggestions += suggestions.length;

            const labelNameElement = labelName === null ? null : (
                <div
                    key={ labelName }
                    className="product-select-dropdown-label"
                >
                    { `${ labelName } (${ suggestions.length })` }
                </div>
            );

            const rowFactory = (index : number) => {
                const suggestion = suggestions[index];

                const onClick = () => {
                    onOptionClick(suggestion);
                };

                const onMouseMove = (event : React.MouseEvent<HTMLDivElement>) => {
                    if (highlightedSearchSuggestion !== suggestion && (this.lastClientMouseX !== event.clientX || this.lastClientMouseY !== event.clientY)) {
                        setHighlightedSearchSuggestionMouse(suggestion);
                        this.lastClientMouseX = event.clientX;
                        this.lastClientMouseY = event.clientY;
                    }
                };

                const onMouseLeave = (event : React.MouseEvent<HTMLDivElement>) => {
                    if (this.lastClientMouseX !== event.clientX || this.lastClientMouseY !== event.clientY) {
                        setHighlightedSearchSuggestionMouse(null);
                    }
                };

                return (
                    <div
                        key={ `${ labelName }_${ index }` }
                        className={ `suggested-option-row ${ dropdownRowClassName } ${ highlightedSearchSuggestion === suggestion ? 'highlighted' : '' }` }
                        onClick={ onClick }
                        onMouseMove={ onMouseMove }
                        onMouseLeave={ onMouseLeave }
                    >
                        { createSuggestionOptionElement(suggestion) }
                    </div>
                );
            };

            const suggestionsForGroup = (
                <RowOptimizationWrapper
                    key={ `${ labelName }_${ labelNameAndSuggestionsIndex }` }
                    numRows={ suggestions.length }
                    rowFactory={ rowFactory }
                    getRowContainer={ this.getDropdownOptionsDiv }
                    isOpen={ true }
                />
            );

            searchSuggestionOptions = searchSuggestionOptions.concat(labelNameElement, suggestionsForGroup);
        });

        const searchSuggestionDropdown = (
            <div className={ this.generateThemeClasses() }>
                <div className="dropdown-menu-component-options" ref={ this.dropdownOptionsRef }>
                    { searchSuggestionOptions }
                    { moreOptionsFooter !== null &&
                        <hr/>
                    }
                    { moreOptionsFooter !== null &&
                        <div className="more-options">
                            { moreOptionsFooter }
                        </div>
                    }
                </div>
            </div>
        );

        return (
            <div>
            { moreOptionsHeader !== null &&
                (
                <div className="header-button">
                    { moreOptionsHeader }
                </div>
                )
            }
            <div className="search-bar-with-suggested-options">
                <div ref={ this.dropdownMenuRef }>
                    { !searchBarIsMobileComponent &&
                        <SearchBar
                            theme={ searchBarTheme }
                            placeholderText={ searchBarPlaceholderText }
                            value={ searchBar.searchTerm }
                            isDisabled={ searchBar.isDisabled }
                            isFocused={ searchBar.isFocused }
                            clearSearchBar={ onClearSearchTerm }
                            handleOnChange={ onSearchTermValueChange }
                            handleEnterClick={ onSearchBarEnterClick }
                            handleBlur={ this.onSearchBarBlur }
                            handleFocus={ this.onSearchBarFocus }
                        />
                    }
                    { searchBarIsMobileComponent &&
                        <MobileSearchBar
                            placeholderText={ searchBarPlaceholderText }
                            value={ searchBar.searchTerm }
                            isDisabled={ searchBar.isDisabled }
                            isFocused={ searchBar.isFocused }
                            clearSearchBar={ onClearSearchTerm }
                            handleOnChange={ onSearchTermValueChange }
                            handleEnterClick={ onSearchBarEnterClick }
                            handleBlur={ this.onSearchBarBlur }
                            handleFocus={ this.onSearchBarFocus }
                            handleOnHideSearchBar={ closeMobileSearchBar }
                        />
                    }

                    { searchSuggestionListIsShown && (numberOfSuggestions > 0 || moreOptionsFooter) &&
                        searchSuggestionDropdown
                    }
                </div>
            </div>
            </div>
        );
    }

    private readonly dropdownMenuRef = (element : HTMLDivElement) => {
        this.dropdownMenuComponentDiv = element;
    }

    private readonly dropdownOptionsRef = (element : HTMLDivElement) => {
        this.dropdownOptionsDiv = element;
    }

    private getDropdownOptionsDiv = () => {
        return this.dropdownOptionsDiv;
    }

    private readonly onWindowClick = (event : Event) : void => {
        if (!this.dropdownMenuComponentDiv || !this.dropdownMenuComponentDiv.contains((event.target as Element))) {
            this.props.setSearchSuggestionListIsShown(false);
            event.stopPropagation();
        }
    }

    private readonly onSearchBarFocus = () => {
        this.props.setSearchSuggestionListIsShown(true);
    }

    private readonly onSearchBarBlur = () => undefined; // do nothing for now

    private readonly onDocumentKeyDown = (event : KeyboardEvent) => {
        const {
            labelNamesAndSearchSuggestions,
            highlightedSearchSuggestion,
            setHighlightedSearchSuggestionKeyboard,
            searchSuggestionListIsShown,
        } = this.props;

        let sortedSearchSuggestions : Array<T> = [];
        labelNamesAndSearchSuggestions.forEach((labelNameAndSortedSuggestions) => {
            sortedSearchSuggestions = sortedSearchSuggestions.concat(labelNameAndSortedSuggestions[1]);
        });

        if (searchSuggestionListIsShown && (sortedSearchSuggestions.length > 0)) {
            const keyCode = event.which;
            let pressedUp : boolean;
            if (keyCode === 38) { // up key
                event.preventDefault();
                event.stopPropagation();
                pressedUp = true;
            } else if (keyCode === 40) { // down key
                event.preventDefault();
                event.stopPropagation();
                pressedUp = false;
            } else {
                return;
            }

            let newHighlightedSearchSuggestion : T | null;
            if (highlightedSearchSuggestion === null) {
                if (pressedUp) {
                    newHighlightedSearchSuggestion = sortedSearchSuggestions[sortedSearchSuggestions.length - 1];
                } else {
                    newHighlightedSearchSuggestion = sortedSearchSuggestions[0];
                }
            } else {
                const highlightedOptionIndex = sortedSearchSuggestions.indexOf(highlightedSearchSuggestion);
                if (pressedUp) {
                    if (highlightedOptionIndex === 0) {
                        newHighlightedSearchSuggestion = null;
                    } else {
                        newHighlightedSearchSuggestion = sortedSearchSuggestions[highlightedOptionIndex - 1];
                    }
                } else {
                    if (highlightedOptionIndex === sortedSearchSuggestions.length - 1) {
                        newHighlightedSearchSuggestion = null;
                    } else {
                        newHighlightedSearchSuggestion = sortedSearchSuggestions[highlightedOptionIndex + 1];
                    }
                }
            }

            setHighlightedSearchSuggestionKeyboard(newHighlightedSearchSuggestion);
        }
    }

    private generateThemeClasses() {
        const {
            dropdownContainerClassName,
            searchBarTheme,
        } = this.props;

        let themeClasses : string = dropdownContainerClassName !== null ? dropdownContainerClassName : 'dropdown-menu-component';

        if (searchBarTheme === SearchBarTheme.Basic) {
            themeClasses += ' theme-basic';
        }

        return themeClasses;
    }
}
