import { PackagingId } from 'api/Product/model/PackagingId';
import { Unit } from 'api/Product/model/Unit';

export class QuantityInUnit<T extends Unit | PackagingId> {
    constructor(
        private readonly quantity : number,
        public readonly unit : T
    ) {
    }

    public getQuantity() {
        return this.quantity;
    }

    public getUnit() : T {
        return this.unit;
    }

    public equals(other : any) : boolean {
        if (other instanceof QuantityInUnit) {
            if (this.unit instanceof PackagingId) {
                return (
                    this.quantity === other.getQuantity() &&
                    this.unit.equals(other.getUnit())
                );
            } else {
                return (
                    this.quantity === other.getQuantity() &&
                    this.unit === other.getUnit()
                );
            }
        }

        return false;
    }
}
