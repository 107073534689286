//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';
import ProductModel from './product_Model_types';
import DistributorModel from './distributor_Model_types';



var ProductDistributorModel = {};
export default ProductDistributorModel;
ProductDistributorModel.ProductPackagingDistributorAssoc = function(args) {
  this.productId = null;
  this.productQuantityUnit = null;
  this.distributorId = null;
  this.sku = null;
  this.price = null;
  this.deposit = null;
  if (args) {
    if (args.productId !== undefined && args.productId !== null) {
      this.productId = new ProductModel.ProductId(args.productId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productId is unset!');
    }
    if (args.productQuantityUnit !== undefined && args.productQuantityUnit !== null) {
      this.productQuantityUnit = new ProductModel.ProductQuantityUnit(args.productQuantityUnit);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productQuantityUnit is unset!');
    }
    if (args.distributorId !== undefined && args.distributorId !== null) {
      this.distributorId = new DistributorModel.DistributorId(args.distributorId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field distributorId is unset!');
    }
    if (args.sku !== undefined && args.sku !== null) {
      this.sku = args.sku;
    }
    if (args.price !== undefined && args.price !== null) {
      this.price = new ProductModel.ProductCost(args.price);
    }
    if (args.deposit !== undefined && args.deposit !== null) {
      this.deposit = new ProductModel.ProductCost(args.deposit);
    }
  }
};
ProductDistributorModel.ProductPackagingDistributorAssoc.prototype = {};
ProductDistributorModel.ProductPackagingDistributorAssoc.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.productId = new ProductModel.ProductId();
        this.productId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.productQuantityUnit = new ProductModel.ProductQuantityUnit();
        this.productQuantityUnit.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.distributorId = new DistributorModel.DistributorId();
        this.distributorId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.sku = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.price = new ProductModel.ProductCost();
        this.price.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.deposit = new ProductModel.ProductCost();
        this.deposit.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductDistributorModel.ProductPackagingDistributorAssoc.prototype.write = function(output) {
  output.writeStructBegin('ProductPackagingDistributorAssoc');
  if (this.productId !== null && this.productId !== undefined) {
    output.writeFieldBegin('productId', Thrift.Type.STRUCT, 1);
    this.productId.write(output);
    output.writeFieldEnd();
  }
  if (this.productQuantityUnit !== null && this.productQuantityUnit !== undefined) {
    output.writeFieldBegin('productQuantityUnit', Thrift.Type.STRUCT, 2);
    this.productQuantityUnit.write(output);
    output.writeFieldEnd();
  }
  if (this.distributorId !== null && this.distributorId !== undefined) {
    output.writeFieldBegin('distributorId', Thrift.Type.STRUCT, 3);
    this.distributorId.write(output);
    output.writeFieldEnd();
  }
  if (this.sku !== null && this.sku !== undefined) {
    output.writeFieldBegin('sku', Thrift.Type.STRING, 4);
    output.writeString(this.sku);
    output.writeFieldEnd();
  }
  if (this.price !== null && this.price !== undefined) {
    output.writeFieldBegin('price', Thrift.Type.STRUCT, 5);
    this.price.write(output);
    output.writeFieldEnd();
  }
  if (this.deposit !== null && this.deposit !== undefined) {
    output.writeFieldBegin('deposit', Thrift.Type.STRUCT, 6);
    this.deposit.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

