import React from 'react';

import { FormatMonetaryValueWithCents } from 'shared/utils/FormatMonetaryValue';

import { StringUtils } from 'shared/utils/stringUtils';
import '../css/LinkedItem.scss';

export interface ILinkedItemProps {
    itemName : string;
    serving : number;
    salesPrice : number;
}

export class LinkedItem extends React.Component<ILinkedItemProps, object> {
    public render() {
        const {
            itemName,
            serving,
            salesPrice,
        } = this.props;

        return (
            <li className="linked-item-container">
                <span className="linked-item">
                    <span className="linked-item-name">{ itemName }</span>
                    <span className="linked-item-info">{ StringUtils.pluralizeNumber('serving', serving) }/{ FormatMonetaryValueWithCents(salesPrice) }</span>
                </span>
            </li>
        );
    }
}
