import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IAction, IActionCreatorsMapObject } from 'shared/models/IAction';
import { IActiveFormState } from './reducers';

export const ActionTypes = {
    SET_ACTIVE_FORM : 'SIGN_UP_OR_SIGN_IN/SET_ACTIVE_FORM',
    SET_FORM_IS_SHOWN : 'SIGN_UP_OR_SIGN_IN/SET_FORM_IS_SHOWN',
};

export namespace SignUpOptionsFormActionInterfaces {
    export interface ISetActiveForm extends IAction {
        payload : {
            form : IActiveFormState;
        };
    }

    export interface ISetFormIsShown extends IAction {
        payload : {
            formIsShown : boolean;
        };
    }

    export interface ISignUpOptionsFormActionCreatorsMapObject extends IActionCreatorsMapObject {
        onSetActiveForm : (
            form : IActiveFormState,
        ) => ThunkAction<void, void, void>;

        onSetFormIsShown : (
            formIsShown : boolean
        ) => ThunkAction<void, void, void>;
    }
}

const setActiveForm = (
    form : IActiveFormState
) : SignUpOptionsFormActionInterfaces.ISetActiveForm => ({
    payload : {
        form,
    },
    type : ActionTypes.SET_ACTIVE_FORM,
});

const setIsShown = (
    formIsShown : boolean
) : SignUpOptionsFormActionInterfaces.ISetFormIsShown => ({
    payload : {
        formIsShown,
    },
    type : ActionTypes.SET_FORM_IS_SHOWN,
});

const onSetActiveForm = (
    form : IActiveFormState,
) : ThunkAction<void, void, void> => {
    return (dispatch : Dispatch<{}>, getState : () => void, extraArguments : void) : void => {
        dispatch(setActiveForm(form));
    };
};

const onSetFormIsShown = (
    formIsShown : boolean,
) : ThunkAction<void, void, void> => {
    return (dispatch : Dispatch<{}>, getState : () => void, extraArguments : void) : void => {
        dispatch(setIsShown(formIsShown));
    };
};

export const SignUpOptionsFormActions : SignUpOptionsFormActionInterfaces.ISignUpOptionsFormActionCreatorsMapObject = {
    onSetActiveForm,
    onSetFormIsShown,
};
