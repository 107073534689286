import { IntegrationState } from 'api/Integration/model/IntegrationState';
import { IPosLocation } from 'api/Integration/model/PosLocation';
import { Action } from 'redux';
import {
    ActionTypes,
    KSeriesIntegrationActionInterfaces,
} from 'shared/components/PosIntegration/KSeriesIntegrationRowActions';

export interface IKSeriesIntegrationModalState {
    isLoading : boolean;
    locationSelectorIsShown : boolean;
    kSeriesLocations : Array<IPosLocation>;
    error : string | null;
    accessToken : string | null;
}

export interface IKSeriesIntegrationRowState {
    isLoading : boolean;
    modalIsShown : boolean;
    existingIntegration : IntegrationState | null;
    modal : IKSeriesIntegrationModalState;
}

const initialState : IKSeriesIntegrationRowState = {
    isLoading : false,
    existingIntegration : null,
    modalIsShown : false,
    modal : {
        isLoading : false,
        locationSelectorIsShown: false,
        kSeriesLocations: [],
        error: null,
        accessToken: null,
    }
};

const reduceSetModalIsLoading = (
    state : IKSeriesIntegrationRowState,
    action : KSeriesIntegrationActionInterfaces.ISetIsLoading
) : IKSeriesIntegrationRowState => {
    return {
        ...state,
        modal : {
            ...state.modal,
            isLoading : action.payload.isLoading,
        }
    };
};

const reduceSetModalShown = (
    state : IKSeriesIntegrationRowState,
    action : KSeriesIntegrationActionInterfaces.ISetModalShown
) : IKSeriesIntegrationRowState => {
    return {
        ...state,
        modalIsShown : action.payload.isShown,
    };
};

const reduceSetIsLoading = (
    state : IKSeriesIntegrationRowState,
    action : KSeriesIntegrationActionInterfaces.ISetIsLoading
) : IKSeriesIntegrationRowState => {
    return {
        ...state,
        isLoading : action.payload.isLoading,
    };
};

const reduceSetLocationSelectorShown = (
    state : IKSeriesIntegrationRowState,
    action : KSeriesIntegrationActionInterfaces.ISetLocationSelectorShown
) : IKSeriesIntegrationRowState => {
    return { ...state, modal: { ...state.modal, locationSelectorIsShown : action.payload.isShown } };
};

const reduceSetModalError = (
    state : IKSeriesIntegrationRowState,
    action : KSeriesIntegrationActionInterfaces.ISetModalError
) : IKSeriesIntegrationRowState => {
    return {
        ...state,
        modal: {
            ...state.modal,
            error: action.payload.message
        }
    };
};

const reduceSetKSeriesLocations = (
    state : IKSeriesIntegrationRowState,
    action : KSeriesIntegrationActionInterfaces.ISetKSeriesLocations
) : IKSeriesIntegrationRowState => {
    return { ...state, modal: { ...state.modal, kSeriesLocations : action.payload.kSeriesLocations } };
};

const reduceSetExistingIntegrationState = (
    state : IKSeriesIntegrationRowState,
    action : KSeriesIntegrationActionInterfaces.ISetExistingIntegrationState
) : IKSeriesIntegrationRowState => {
    return {
        ...state,
        existingIntegration: action.payload.integrationState,
    };
};

const reduceSetModalAccessToken = (
    state : IKSeriesIntegrationRowState,
    action : KSeriesIntegrationActionInterfaces.ISetModalAccessToken
) : IKSeriesIntegrationRowState => {
    return {
        ...state,
        modal: {
            ...state.modal,
            accessToken: action.payload.accessToken
        }
    };
};

export const KSeriesIntegrationRowReducers = (
    state : IKSeriesIntegrationRowState = initialState,
    action : Action,
) : IKSeriesIntegrationRowState => {
    switch (action.type) {
        case ActionTypes.SET_IS_LOADING: {
            return reduceSetIsLoading(state, action as KSeriesIntegrationActionInterfaces.ISetIsLoading);
        }
        case ActionTypes.SET_EXISTING_INTEGRATION_STATE: {
            return reduceSetExistingIntegrationState(state, action as KSeriesIntegrationActionInterfaces.ISetExistingIntegrationState);
        }
        case ActionTypes.SET_MODAL_SHOWN: {
            return reduceSetModalShown(state, action as KSeriesIntegrationActionInterfaces.ISetModalShown);
        }
        case ActionTypes.SET_MODAL_IS_LOADING: {
            return reduceSetModalIsLoading(state, action as KSeriesIntegrationActionInterfaces.ISetIsLoading);
        }
        case ActionTypes.SET_K_SERIES_LOCATION_SELECTOR_SHOWN: {
            return reduceSetLocationSelectorShown(state, action as KSeriesIntegrationActionInterfaces.ISetLocationSelectorShown);
        }
        case ActionTypes.SET_K_SERIES_LOCATIONS: {
            return reduceSetKSeriesLocations(state, action as KSeriesIntegrationActionInterfaces.ISetKSeriesLocations);
        }
        case ActionTypes.SET_MODAL_ERROR: {
            return reduceSetModalError(state, action as KSeriesIntegrationActionInterfaces.ISetModalError);
        }
        case ActionTypes.SET_MODAL_ACCESS_TOKEN: {
            return reduceSetModalAccessToken(state, action as KSeriesIntegrationActionInterfaces.ISetModalAccessToken);
        }
        default: {
            return state;
        }
    }
};
