import { IRecordNewOrderViewForm, RecordOrderFormFieldName } from './RecordNewOrderReducers';

import { IValidationResult, Validation } from 'shared/validators/validators';

const validateRecordNewOrderFormValueByFieldName = (
    fieldName : RecordOrderFormFieldName,
    value : string,
) : IValidationResult => {
    let isValid : null | boolean = null;
    let errorMessage = '';

    // input type specific
    switch (fieldName) {
        case 'invoiceNumber':
            isValid = true;
            errorMessage = '';
            break;
        case 'invoiceTotal':
            if (value === '') {
                isValid = true;
                errorMessage = '';
            } else {
                isValid = Validation.validateNumber(value);
                errorMessage = isValid ? '' : 'Invalid invoice total';
            }
            break;
        default:
            if (isValid === null) {
                throw new Error('no validation implemented for fieldname ' + fieldName);
            }
    }

    return {
        isValid,
        errorMessage,
    };
};

const validateRecordNewOrderForm = (
    recordNewOrderForm : IRecordNewOrderViewForm,
    invoiceUploadFiles : Array<File | null>,
) : boolean => {
    let formIsValid = true;

    Object.keys(recordNewOrderForm.validationInputByFieldName).forEach((key) => {
        const fieldName = key as RecordOrderFormFieldName;
        const value = (recordNewOrderForm.validationInputByFieldName)[fieldName].value;

        const validationResult = RecordNewOrderFormUtils.validateRecordNewOrderFormValueByFieldName(fieldName, value);

        const { isValid } = validationResult;
        formIsValid = formIsValid && isValid;
    });

    if (!formIsValid) {
        return false;
    }

    if (recordNewOrderForm.currentDistributorOption === null) {
        return false;
    }

    if (recordNewOrderForm.shouldSubmitUploadForProcessing) {
        let hasAttachedFiles = false;
        invoiceUploadFiles.forEach((file) => {
            hasAttachedFiles = hasAttachedFiles || (file !== null);
        });

        if (!hasAttachedFiles) {
            return false;
        }
    }

    return true;
};

export const RecordNewOrderFormUtils = {
    validateRecordNewOrderFormValueByFieldName,
    validateRecordNewOrderForm,
};
