import React from 'react';
import MediaQuery from 'react-responsive';

import { Button } from 'shared/components/Button';
import { MAX_MOBILE_WIDTH, MIN_TABLET_WIDTH } from 'shared/constants';

import '../css/AddItemModalHeader.scss';

export interface IAddItemModalHeaderProps {
    showCreateItemModal() : void;
    closeAddItemModal() : void;
}

export class AddItemModalHeader extends React.Component<IAddItemModalHeaderProps, object> {
    public render() {
        const {
            closeAddItemModal,
            showCreateItemModal,
        } = this.props;

        return (
            <div className="add-item-modal-header dark">
                <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                    <div className="col-row">
                        <div className="col-xs-6">
                            <span className="dark">Add items{ window.GLOBAL_FEATURE_ACCESS.catalog && ' from catalog' }...</span>
                        </div>
                        <div className="col-xs-6 no-pad">
                            <div className="modal-header-secondary right">
                                <Button
                                    buttonClassName="flat"
                                    isDisabled={ false }
                                    isLoading={ false }
                                    onClick={ showCreateItemModal }
                                >
                                    <span className="flex-text-with-icon middle">
                                        <span className="bevicon bevico-add-circle icon-left"/>
                                        <span>Create New Item</span>
                                    </span>
                                </Button>
                                <Button
                                    buttonClassName="bevicon bevico-close modal-close flat"
                                    isDisabled={ false }
                                    isLoading={ false }
                                    onClick={ closeAddItemModal }
                                />
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={ MAX_MOBILE_WIDTH }>
                    <div className="modal-header-secondary">
                        <Button
                            buttonClassName="flat"
                            isDisabled={ false }
                            isLoading={ false }
                            onClick={ showCreateItemModal }
                        >
                            <span className="flex-text-with-icon middle">
                                <span className="bevicon bevico-add-circle icon-left"/>
                                <span>Create New Item</span>
                            </span>
                        </Button>
                        <Button
                            buttonClassName="bevicon bevico-close modal-close flat"
                            isDisabled={ false }
                            isLoading={ false }
                            onClick={ closeAddItemModal }
                        />
                    </div>
                </MediaQuery>
            </div>
        );
    }
}
