import { ProductQuickAdd } from 'api/Onboarding/model/ProductQuickAdd';

export const defaultProductQuickAddRowFilter = (
    productQuickAddItem : ProductQuickAdd,
    cleanedFilterTerm : string | null,
) : boolean => {
    if (cleanedFilterTerm === null || cleanedFilterTerm === '') {
        return true;
    }

    const filterTokens = cleanedFilterTerm.toLowerCase().split(' ');
    for (let i = 0; i < filterTokens.length; i++) {
        const name = productQuickAddItem.getValue().toLowerCase();
        if (name.indexOf(filterTokens[i]) < 0) {
            return false;
        }
    }

    return true;
};
