import { Action } from 'redux';

import { ActionTypes } from '../actions/addItemModalActions';

import { ISetShownAction } from 'shared/models/IAction';

export interface IAddItemModalState {
    isShown : boolean;
}

const initialState : IAddItemModalState = {
    isShown: false,
};

const reduceSetIsShown = (
    state : IAddItemModalState,
    action : ISetShownAction
) : IAddItemModalState => {
    const newState = { ...state };
    newState.isShown = action.payload.isShown;
    return newState;
};

export const AddItemModalReducers = (
    state : IAddItemModalState = initialState,
    action : Action
) : IAddItemModalState => {
    switch (action.type) {
        case ActionTypes.SET_IS_SHOWN:
            return reduceSetIsShown(
                state,
                action as ISetShownAction);
        default:
            return state;
    }
};
