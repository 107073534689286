import React from 'react';

import { Product } from 'api/Product/model/Product';
import { ISearchBarState } from 'shared/models/ISearchBarState';
import { IModalButton, IModalClasses } from 'shared/models/Modal';

import { StringValueMap } from 'api/Core/StringValueMap';
import { ProductId } from 'api/Product/model/ProductId';
import { ModalWithHeaderAndFooter } from 'shared/components/ModalWithHeaderAndFooter';
import { UnmatchedProductMapper } from './UnmatchedProductMapper';

import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { Validation } from 'shared/validators/validators';

export interface IUnmatchedProductModal {
    readonly isLoading : boolean;
    readonly locationName : string;
    readonly partnerLocationName : string;
    readonly itemNumber : number;
    readonly count : number;
    readonly currentUnmappedProductForm : {
        productId : ProductId;
        productQuantityUnit : ProductQuantityUnit;
    };
    readonly searchBar : ISearchBarState;
    readonly searchBarActions : {
        handleBlur(searchTerm : string) : void;
        handleEnterClick(searchTerm : string) : void;
        handleOnChange(searchTerm : string) : void;
    };
    readonly modalActions : {
        handleFooterLeftButton() : void;
        handleNextButton() : void;
        handlePreviousButton() : void;
    };
    readonly searchResults : Array<ProductId>;
    readonly selectedProductForm : {
        productId : ProductId | null;
        quantity : {
            value : string;
            isValid : boolean;
            errorMessage : string;
        };
        productQuantityUnit : ProductQuantityUnit;
    };
    readonly productsById : StringValueMap<ProductId, Product>;
    readonly addProductToPartnerLocation : ((productId : ProductId) => Promise<any>) | null;
    readonly clearSearchBar : () => void;
    readonly setSelectedProductForm : (productId : ProductId | null, quantity : string, productQuantityUnit : ProductQuantityUnit) => void;
}

export class UnmatchedProductModal extends React.Component<IUnmatchedProductModal, object> {

    public render() {
        const {
            isLoading,
            locationName,
            partnerLocationName,
            itemNumber,
            count,
            clearSearchBar,
            searchBar,
            searchBarActions,
            modalActions,
            searchResults,
            selectedProductForm,
            currentUnmappedProductForm,
            addProductToPartnerLocation,
            setSelectedProductForm,
            productsById,
        } = this.props;

        const modalClasses : IModalClasses = {
            bodyClasses: null,
            footerClasses: null,
            headerClasses: 'red',
        };
        const footerLeftButton : IModalButton = {
            onClick: modalActions.handleFooterLeftButton,
            classes: 'flat',
            isDisabled: false,
            isLoading: false,
            children: 'Back',
        };
        const footerLabel = (
            <span><strong>{ itemNumber }</strong> of { count }</span>
        );

        const buttons : Array<IModalButton> = [{
            onClick: modalActions.handlePreviousButton,
            classes: 'primary-action',
            isDisabled: itemNumber === 1,
            isLoading: false,
            children: 'Prev',
        }];

        let selectedProductFormIsValid = false;
        let selectedProduct : Product | null = null;
        if (selectedProductForm.productId !== null) {
            selectedProduct = productsById.getRequired(selectedProductForm.productId);

            if (Validation.validateNonNegativeNumber(selectedProductForm.quantity.value) && (parseFloat(selectedProductForm.quantity.value) > 0)) {
                if (PackagingUtils.isProductQuantityUnitCompatibleWithPackagings(selectedProduct.getPackagingsAndMappings(), selectedProductForm.productQuantityUnit)) {
                    selectedProductFormIsValid = true;
                }
            }
        }

        const currentUnmappedProduct = productsById.getRequired(currentUnmappedProductForm.productId);
        const currentUnmappedProductFormIsValid = PackagingUtils.isProductQuantityUnitCompatibleWithPackagings(currentUnmappedProduct.getPackagingsAndMappings(), currentUnmappedProductForm.productQuantityUnit);

        const isNextReady = selectedProductFormIsValid && currentUnmappedProductFormIsValid;
        const rightButtonClasses = isNextReady ? 'success-action' : 'primary-action';

        buttons.push({
            onClick: () => modalActions.handleNextButton(),
            classes: rightButtonClasses,
            isDisabled: !isNextReady,
            isLoading: false,
            children: (itemNumber === count) ? 'Finish' : 'Next',
        });

        return (
            <ModalWithHeaderAndFooter
                className=""
                closeButtonShown={ false }
                onClose={ null }
                buttons={ buttons }
                footerLeftButton={ footerLeftButton }
                footerLabel={ footerLabel }
                headerText={ `${count} Unmatched Transfer Items` }
                modalClasses={ modalClasses }
            >
                { isLoading &&
                    <div className="product-mapper-overlay">
                        <div className="loading-icon-wrapper">
                            <span className="bevicon bevico-spinner2 make-it-spin"/>
                        </div>
                    </div>
                }
                <p className="unmatched-product-caption">Find matching inventory items in the location that you're <strong>transferring with</strong> below.</p>
                <UnmatchedProductMapper
                    locationName={ locationName }
                    partnerLocationName={ partnerLocationName }
                    itemNumber={ itemNumber }
                    count={ count }
                    currentProductForm={ currentUnmappedProductForm }
                    productsById={ productsById }
                    searchBar={ searchBar }
                    searchBarActions={ searchBarActions }
                    searchResults={ searchResults }
                    selectedProductForm={ selectedProductForm }
                    addProductToPartnerLocation={ addProductToPartnerLocation }
                    clearSearchBar={ clearSearchBar }
                    setSelectedProductForm={ setSelectedProductForm }
                />
            </ModalWithHeaderAndFooter>
        );
    }
}
