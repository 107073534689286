import React from 'react';

import { IFileUploadInstance } from './reducers';

import './FileUpload.scss';

export interface IFileUploadProps {
    id : string; // for distinguishing file events from different FileUpload components
    onFileInputChange : (id : string, index : number, file : File | null) => void;
    fileUploadState : IFileUploadInstance;
    maxFileUploadCount : number;
    buttonText : string | JSX.Element | null;
    buttonClassName? : string;
    acceptMultipleFiles? : boolean;
}

export class FileUpload extends React.Component<IFileUploadProps, object> {
    private tagIdentifier : string;

    constructor(props : IFileUploadProps) {
        super(props);

        this.tagIdentifier = 'FileUpload-' + (+new Date()) + '-' + Math.floor(Math.random() * 999999);
    }

    public render() {
        const {
            id,
            buttonText,
            onFileInputChange,
            fileUploadState,
            buttonClassName,
            acceptMultipleFiles,
            maxFileUploadCount,
        } = this.props;

        const getOnChangeHandlerAtIndex = (i : number) => {
            return (event : React.FormEvent<HTMLInputElement>) => {
                const selectedFiles = event.currentTarget.files;
                if (selectedFiles !== null) {
                    for (let j = 0; j < selectedFiles.length; j++) {
                        onFileInputChange(id, i + j, selectedFiles[j]);
                    }
                }
            };
        };

        const fileInputs : Array<JSX.Element> = [];

        for (let i = 0; i < fileUploadState.length; i++) {
            const file = fileUploadState[i];
            if (file !== null) {
                const onRemove = () => {
                    onFileInputChange(id, i, null);
                };

                // The file input here does is not currently shown due to styling.
                fileInputs.push(
                    <span key={ i } className="file-upload-name">
                        <span className="file-name ellipsis-out">{ file.name }</span>
                        <input multiple={ acceptMultipleFiles ? true : false } type="file" onChange={ getOnChangeHandlerAtIndex(i) } />
                        <button onClick={ onRemove } className="btn bevspot-button right flat">
                            <span className="button-text-container">remove</span>
                        </button>
                    </span>
                );
            }
        }

        const chooseFileButtonClasses = `btn bevspot-button upload-file ${ (typeof buttonClassName !== 'undefined') ? buttonClassName : 'primary' }`;

        const addFileIndex = fileUploadState.length;
        if (fileInputs.length < maxFileUploadCount) {
            fileInputs.push(
                <div key={ addFileIndex } className="add-file" >
                    <input multiple={ acceptMultipleFiles ? true : false } type="file" name="file" id={ this.tagIdentifier } onChange={ getOnChangeHandlerAtIndex(addFileIndex) } />
                    { fileInputs.length ?
                        <label htmlFor={ this.tagIdentifier } className="btn bevspot-button flat another"><span className="bevicon bevico-add"/> Add another</label> :
                        <label htmlFor={ this.tagIdentifier } className={ chooseFileButtonClasses }>{ buttonText !== null ? buttonText : 'Choose a file...' }</label>
                    }
                </div>
            );
        }

        return (
            <div className="file-upload">
                { fileInputs }
            </div>
        );
    }
}
