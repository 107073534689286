import { DeliveryId } from 'api/Ordering/model/DeliveryId';
import { PrepEventId } from 'api/PrepEvent/model/PrepEventId';
import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { TransferId } from 'api/Transfer/model/TransferId';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';
import { ProductId } from 'api/Product/model/ProductId';

export class UsageCalculationParameters {

    constructor(
        private readonly unitCountsByProductIdByStorageAreaIdForStartingInventory : StringValueMap<ProductId, StringValueMap<StorageAreaId, QuantityInUnit<ProductQuantityUnit>>>,
        private readonly unitCountsByProductIdByStorageAreaIdForEndingInventory : StringValueMap<ProductId, StringValueMap<StorageAreaId, QuantityInUnit<ProductQuantityUnit>>>,
        private readonly unitCountsByProductIdByDeliveryId : StringValueMap<ProductId, StringValueMap<DeliveryId, QuantityInUnit<ProductQuantityUnit>>>,
        private readonly unitCountsByProductIdByTransferId : StringValueMap<ProductId, StringValueMap<TransferId, QuantityInUnit<ProductQuantityUnit>>>,
        private readonly inputUnitCountsByProductIdByPrepEventId : StringValueMap<ProductId, StringValueMap<PrepEventId, QuantityInUnit<ProductQuantityUnit>>>,
        private readonly outputUnitCountsByProductIdByPrepEventId : StringValueMap<ProductId, StringValueMap<PrepEventId, QuantityInUnit<ProductQuantityUnit>>>,
        private readonly unitCostsByProductId : StringValueMap<ProductId, ProductCost>
    ) { }

    public getUnitCountsByProductIdByStorageAreaIdForStartingInventory() : StringValueMap<ProductId, StringValueMap<StorageAreaId, QuantityInUnit<ProductQuantityUnit>>> {
        return this.unitCountsByProductIdByStorageAreaIdForStartingInventory;
    }

    public getUnitCountsByProductIdByStorageAreaIdForEndingInventory() : StringValueMap<ProductId, StringValueMap<StorageAreaId, QuantityInUnit<ProductQuantityUnit>>> {
        return this.unitCountsByProductIdByStorageAreaIdForEndingInventory;
    }

    public getUnitCountsByProductIdByDeliveryId() : StringValueMap<ProductId, StringValueMap<DeliveryId, QuantityInUnit<ProductQuantityUnit>>> {
        return this.unitCountsByProductIdByDeliveryId;
    }

    public getUnitCountsByProductIdByTransferId() : StringValueMap<ProductId, StringValueMap<TransferId, QuantityInUnit<ProductQuantityUnit>>> {
        return this.unitCountsByProductIdByTransferId;
    }

    public getInputUnitCountsByProductIdByPrepEventId() : StringValueMap<ProductId, StringValueMap<PrepEventId, QuantityInUnit<ProductQuantityUnit>>> {
        return this.inputUnitCountsByProductIdByPrepEventId;
    }

    public getOutputUnitCountsByProductIdByPrepEventId() : StringValueMap<ProductId, StringValueMap<PrepEventId, QuantityInUnit<ProductQuantityUnit>>> {
        return this.outputUnitCountsByProductIdByPrepEventId;
    }

    public getUnitCostsByProductId() : StringValueMap<ProductId, ProductCost> {
        return this.unitCostsByProductId;
    }
}
