import React from 'react';

import { PositionablePopup, PositionablePopupSide } from 'shared/components/PositionablePopup/PositionablePopup';
import './Popover.scss';

const ANCHOR_MOUSE_ENTER_DELAY_TIME = 400;

export interface IPopoverProps {
    preferredPositionArray : Array<PositionablePopupSide>;
    anchorContent : React.ReactNode;
    popoverContent : React.ReactNode;
    className : string;
    showOnHover : boolean;
    popoverContentIsShown : boolean;
    setPopoverContentIsShown : (isShown : boolean) => void;
}

export class Popover extends React.Component<IPopoverProps, object> {

    private showTimerHandle = -1;
    private shownViaClick = false;
    private outerDiv : HTMLDivElement | undefined;

    public render() {
        const {
            popoverContentIsShown,
            anchorContent,
            popoverContent,
            preferredPositionArray
        } = this.props;

        return (
            <div
                className={ 'bev-popover ' + this.props.className }
                ref={ this.outerDivRef }
                onMouseEnter={ this.props.showOnHover ? this.onOuterDivMouseEnter : undefined }
                onMouseLeave={ this.props.showOnHover ? this.onOuterDivMouseLeave : undefined }
            >
                <div onClick={ this.onAnchorClick } className="anchor-container">
                    { anchorContent }
                </div>
                { popoverContentIsShown &&
                    <div className="popover-contents" onClick={ this.onPopoverClick }>
                        <PositionablePopup
                            className=""
                            preferredPositionArray={ preferredPositionArray }
                        >
                            { popoverContent }
                        </PositionablePopup>
                    </div>
                }
            </div>
        );
    }

    private outerDivRef = (outerDiv : HTMLDivElement) => {
        this.outerDiv = outerDiv;
    }

    private onOuterDivMouseEnter = () => {
        this.showTimerHandle = window.setTimeout(() => {
            this.showPopup();
            this.showTimerHandle = -1;
        }, ANCHOR_MOUSE_ENTER_DELAY_TIME);
    }

    private onOuterDivMouseLeave = () => {
        if (!this.shownViaClick) {
            this.cancelShowTimer();
            this.hidePopup();
        }
    }

    private onAnchorClick = () => {
        const {
            popoverContentIsShown,
        } = this.props;

        this.cancelShowTimer();

        if (popoverContentIsShown) {
            if (this.shownViaClick) {
                this.hidePopup();
            } else {
                this.shownViaClick = true;
            }
        } else {
            this.shownViaClick = true;
            this.showPopup();
        }
    }

    private onPopoverClick = () => {
        this.shownViaClick = true;
    }

    private onWindowClick = (event : MouseEvent) => {
        const {
            popoverContentIsShown
        } = this.props;

        this.cancelShowTimer();
        const outerDiv = this.outerDiv;

        if (popoverContentIsShown && outerDiv) {
            if (!outerDiv.contains(event.target as Node)) {
                this.hidePopup();
            }
        }
    }

    private showPopup = () => {
        this.props.setPopoverContentIsShown(true);
        window.addEventListener('click', this.onWindowClick, true);
    }

    private hidePopup = () => {
        this.props.setPopoverContentIsShown(false);
        this.shownViaClick = false;
        window.removeEventListener('click', this.onWindowClick, true);
    }

    private cancelShowTimer = () => {
        if (this.showTimerHandle) {
            clearTimeout(this.showTimerHandle);
            this.showTimerHandle = -1;
        }
    }
}
