import { PosItemId } from './PosItemId';

export class PosItem {
    constructor(
        private readonly posItemId : PosItemId, // match the backend with the id in the object
        private readonly name : string,
        private readonly menuGroup : string,
    ) {}

    public getPosItemId() : PosItemId {
        return this.posItemId;
    }

    public getName() : string {
        return this.name;
    }

    public getMenuGroup() : string {
        return this.menuGroup;
    }
}
