import CorePrimitivesModel from 'gen-thrift/core_primitives_Model_types';
import UnauthenticatedUserSessionModel from 'gen-thrift/unauthenticated_user_session_Model_types';
import UserEmailAddressVerificationService from 'gen-thrift/UserEmailAddressVerificationService';

import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserEmailAddressVerificationCredential } from 'api/UserAccount/model/UserEmailAddressVerificationCredential';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { UserAccountObjectToThriftSerializer } from 'api/UserAccount/serializer/UserAccountObjectToThriftSerializer';

import { IAccountEmailAddressVerifier } from '../interfaces/IAccountEmailAddressVerifier';
import { IAccountSessionReader } from '../interfaces/IAccountSessionReader';

export class UserEmailAddressVerifierImpl implements IAccountEmailAddressVerifier {
    private userEmailAddressVerificationServiceClient : UserEmailAddressVerificationService.UserEmailAddressVerificationServiceClient;
    private userSessionReader : IAccountSessionReader<UserSessionId, UserAccountId>;
    private userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer;

    constructor(
            userEmailAddressVerificationServiceClient : UserEmailAddressVerificationService.UserEmailAddressVerificationServiceClient,
            userSessionReader : IAccountSessionReader<UserSessionId, UserAccountId>,
            userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer
        ) {

        this.userEmailAddressVerificationServiceClient = userEmailAddressVerificationServiceClient;
        this.userSessionReader = userSessionReader;
        this.userAccountObjectToThriftSerializer = userAccountObjectToThriftSerializer;
    }

    public sendEmailAddressVerificationEmail(
        emailAddress : CorePrimitivesModel.EmailAddress
    ) : Promise<void> {
        return new Promise<void> ((resolve, reject) => {
            this.userEmailAddressVerificationServiceClient.sendEmailAddressVerificationEmail(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(this.userSessionReader.getSessionId()),
                emailAddress,
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve();
                }
            );
        });
    }

    public associateEmailAddressForEmailAddressVerificationCredential(
        unauthenticatedUserSessionId : UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId,
        emailAddressVerificationCredential : UserEmailAddressVerificationCredential
    ) : Promise<void> {
        return new Promise<void> ((resolve, reject) => {
            this.userEmailAddressVerificationServiceClient.associateEmailAddressForEmailAddressVerificationCredential(
                unauthenticatedUserSessionId,
                this.userAccountObjectToThriftSerializer.getThriftUserEmailAddressVerificationCredential(emailAddressVerificationCredential),
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve();
                }
            );
        });
    }
}
