//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';
import DistributorModel from './distributor_Model_types';
import MoneyModel from './money_Model_types';
import CoreMathModel from './core_math_Model_types';
import ProductAmountModel from './product_amount_Model_types';



var ProductModel = {};
export default ProductModel;
ProductModel.ProductId = function(args) {
  this.value = null;
  if (args) {
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field value is unset!');
    }
  }
};
ProductModel.ProductId.prototype = {};
ProductModel.ProductId.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductModel.ProductId.prototype.write = function(output) {
  output.writeStructBegin('ProductId');
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 1);
    output.writeString(this.value);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductModel.ProductIdAndDistributorId = function(args) {
  this.productId = null;
  this.distributorId = null;
  if (args) {
    if (args.productId !== undefined && args.productId !== null) {
      this.productId = new ProductModel.ProductId(args.productId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productId is unset!');
    }
    if (args.distributorId !== undefined && args.distributorId !== null) {
      this.distributorId = new DistributorModel.DistributorId(args.distributorId);
    }
  }
};
ProductModel.ProductIdAndDistributorId.prototype = {};
ProductModel.ProductIdAndDistributorId.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.productId = new ProductModel.ProductId();
        this.productId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.distributorId = new DistributorModel.DistributorId();
        this.distributorId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductModel.ProductIdAndDistributorId.prototype.write = function(output) {
  output.writeStructBegin('ProductIdAndDistributorId');
  if (this.productId !== null && this.productId !== undefined) {
    output.writeFieldBegin('productId', Thrift.Type.STRUCT, 1);
    this.productId.write(output);
    output.writeFieldEnd();
  }
  if (this.distributorId !== null && this.distributorId !== undefined) {
    output.writeFieldBegin('distributorId', Thrift.Type.STRUCT, 2);
    this.distributorId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductModel.PackagingId = function(args) {
  this.value = null;
  if (args) {
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field value is unset!');
    }
  }
};
ProductModel.PackagingId.prototype = {};
ProductModel.PackagingId.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductModel.PackagingId.prototype.write = function(output) {
  output.writeStructBegin('PackagingId');
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 1);
    output.writeString(this.value);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductModel.UnitOfMeasure = function(args) {
  this.unitOfMass = null;
  this.unitOfVolume = null;
  if (args) {
    if (args.unitOfMass !== undefined && args.unitOfMass !== null) {
      this.unitOfMass = args.unitOfMass;
    }
    if (args.unitOfVolume !== undefined && args.unitOfVolume !== null) {
      this.unitOfVolume = args.unitOfVolume;
    }
  }
};
ProductModel.UnitOfMeasure.prototype = {};
ProductModel.UnitOfMeasure.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.unitOfMass = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.unitOfVolume = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductModel.UnitOfMeasure.prototype.write = function(output) {
  output.writeStructBegin('UnitOfMeasure');
  if (this.unitOfMass !== null && this.unitOfMass !== undefined) {
    output.writeFieldBegin('unitOfMass', Thrift.Type.I32, 1);
    output.writeI32(this.unitOfMass);
    output.writeFieldEnd();
  }
  if (this.unitOfVolume !== null && this.unitOfVolume !== undefined) {
    output.writeFieldBegin('unitOfVolume', Thrift.Type.I32, 2);
    output.writeI32(this.unitOfVolume);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductModel.ProductQuantityUnit = function(args) {
  this.unitOfMass = null;
  this.unitOfVolume = null;
  this.packagingId = null;
  if (args) {
    if (args.unitOfMass !== undefined && args.unitOfMass !== null) {
      this.unitOfMass = args.unitOfMass;
    }
    if (args.unitOfVolume !== undefined && args.unitOfVolume !== null) {
      this.unitOfVolume = args.unitOfVolume;
    }
    if (args.packagingId !== undefined && args.packagingId !== null) {
      this.packagingId = new ProductModel.PackagingId(args.packagingId);
    }
  }
};
ProductModel.ProductQuantityUnit.prototype = {};
ProductModel.ProductQuantityUnit.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.unitOfMass = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.unitOfVolume = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.packagingId = new ProductModel.PackagingId();
        this.packagingId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductModel.ProductQuantityUnit.prototype.write = function(output) {
  output.writeStructBegin('ProductQuantityUnit');
  if (this.unitOfMass !== null && this.unitOfMass !== undefined) {
    output.writeFieldBegin('unitOfMass', Thrift.Type.I32, 1);
    output.writeI32(this.unitOfMass);
    output.writeFieldEnd();
  }
  if (this.unitOfVolume !== null && this.unitOfVolume !== undefined) {
    output.writeFieldBegin('unitOfVolume', Thrift.Type.I32, 2);
    output.writeI32(this.unitOfVolume);
    output.writeFieldEnd();
  }
  if (this.packagingId !== null && this.packagingId !== undefined) {
    output.writeFieldBegin('packagingId', Thrift.Type.STRUCT, 3);
    this.packagingId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductModel.QuantityOfProduct = function(args) {
  this.quantity = null;
  this.productQuantityUnit = null;
  if (args) {
    if (args.quantity !== undefined && args.quantity !== null) {
      this.quantity = new CoreMathModel.Decimal(args.quantity);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field quantity is unset!');
    }
    if (args.productQuantityUnit !== undefined && args.productQuantityUnit !== null) {
      this.productQuantityUnit = new ProductModel.ProductQuantityUnit(args.productQuantityUnit);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productQuantityUnit is unset!');
    }
  }
};
ProductModel.QuantityOfProduct.prototype = {};
ProductModel.QuantityOfProduct.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.quantity = new CoreMathModel.Decimal();
        this.quantity.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.productQuantityUnit = new ProductModel.ProductQuantityUnit();
        this.productQuantityUnit.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductModel.QuantityOfProduct.prototype.write = function(output) {
  output.writeStructBegin('QuantityOfProduct');
  if (this.quantity !== null && this.quantity !== undefined) {
    output.writeFieldBegin('quantity', Thrift.Type.STRUCT, 1);
    this.quantity.write(output);
    output.writeFieldEnd();
  }
  if (this.productQuantityUnit !== null && this.productQuantityUnit !== undefined) {
    output.writeFieldBegin('productQuantityUnit', Thrift.Type.STRUCT, 2);
    this.productQuantityUnit.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductModel.ProductIdAndQuantityOfProduct = function(args) {
  this.productId = null;
  this.quantityOfProduct = null;
  if (args) {
    if (args.productId !== undefined && args.productId !== null) {
      this.productId = new ProductModel.ProductId(args.productId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productId is unset!');
    }
    if (args.quantityOfProduct !== undefined && args.quantityOfProduct !== null) {
      this.quantityOfProduct = new ProductModel.QuantityOfProduct(args.quantityOfProduct);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field quantityOfProduct is unset!');
    }
  }
};
ProductModel.ProductIdAndQuantityOfProduct.prototype = {};
ProductModel.ProductIdAndQuantityOfProduct.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.productId = new ProductModel.ProductId();
        this.productId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.quantityOfProduct = new ProductModel.QuantityOfProduct();
        this.quantityOfProduct.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductModel.ProductIdAndQuantityOfProduct.prototype.write = function(output) {
  output.writeStructBegin('ProductIdAndQuantityOfProduct');
  if (this.productId !== null && this.productId !== undefined) {
    output.writeFieldBegin('productId', Thrift.Type.STRUCT, 1);
    this.productId.write(output);
    output.writeFieldEnd();
  }
  if (this.quantityOfProduct !== null && this.quantityOfProduct !== undefined) {
    output.writeFieldBegin('quantityOfProduct', Thrift.Type.STRUCT, 2);
    this.quantityOfProduct.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductModel.ProductIdAndOptionalQuantityOfProduct = function(args) {
  this.productId = null;
  this.quantityOfProduct = null;
  if (args) {
    if (args.productId !== undefined && args.productId !== null) {
      this.productId = new ProductModel.ProductId(args.productId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productId is unset!');
    }
    if (args.quantityOfProduct !== undefined && args.quantityOfProduct !== null) {
      this.quantityOfProduct = new ProductModel.QuantityOfProduct(args.quantityOfProduct);
    }
  }
};
ProductModel.ProductIdAndOptionalQuantityOfProduct.prototype = {};
ProductModel.ProductIdAndOptionalQuantityOfProduct.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.productId = new ProductModel.ProductId();
        this.productId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.quantityOfProduct = new ProductModel.QuantityOfProduct();
        this.quantityOfProduct.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductModel.ProductIdAndOptionalQuantityOfProduct.prototype.write = function(output) {
  output.writeStructBegin('ProductIdAndOptionalQuantityOfProduct');
  if (this.productId !== null && this.productId !== undefined) {
    output.writeFieldBegin('productId', Thrift.Type.STRUCT, 1);
    this.productId.write(output);
    output.writeFieldEnd();
  }
  if (this.quantityOfProduct !== null && this.quantityOfProduct !== undefined) {
    output.writeFieldBegin('quantityOfProduct', Thrift.Type.STRUCT, 2);
    this.quantityOfProduct.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductModel.ProductCost = function(args) {
  this.monetaryValue = null;
  this.productQuantityUnit = null;
  if (args) {
    if (args.monetaryValue !== undefined && args.monetaryValue !== null) {
      this.monetaryValue = new MoneyModel.MonetaryValue(args.monetaryValue);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field monetaryValue is unset!');
    }
    if (args.productQuantityUnit !== undefined && args.productQuantityUnit !== null) {
      this.productQuantityUnit = new ProductModel.ProductQuantityUnit(args.productQuantityUnit);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productQuantityUnit is unset!');
    }
  }
};
ProductModel.ProductCost.prototype = {};
ProductModel.ProductCost.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.monetaryValue = new MoneyModel.MonetaryValue();
        this.monetaryValue.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.productQuantityUnit = new ProductModel.ProductQuantityUnit();
        this.productQuantityUnit.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductModel.ProductCost.prototype.write = function(output) {
  output.writeStructBegin('ProductCost');
  if (this.monetaryValue !== null && this.monetaryValue !== undefined) {
    output.writeFieldBegin('monetaryValue', Thrift.Type.STRUCT, 1);
    this.monetaryValue.write(output);
    output.writeFieldEnd();
  }
  if (this.productQuantityUnit !== null && this.productQuantityUnit !== undefined) {
    output.writeFieldBegin('productQuantityUnit', Thrift.Type.STRUCT, 2);
    this.productQuantityUnit.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductModel.ProductIdAndCost = function(args) {
  this.productId = null;
  this.productCost = null;
  if (args) {
    if (args.productId !== undefined && args.productId !== null) {
      this.productId = new ProductModel.ProductId(args.productId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productId is unset!');
    }
    if (args.productCost !== undefined && args.productCost !== null) {
      this.productCost = new ProductModel.ProductCost(args.productCost);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productCost is unset!');
    }
  }
};
ProductModel.ProductIdAndCost.prototype = {};
ProductModel.ProductIdAndCost.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.productId = new ProductModel.ProductId();
        this.productId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.productCost = new ProductModel.ProductCost();
        this.productCost.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductModel.ProductIdAndCost.prototype.write = function(output) {
  output.writeStructBegin('ProductIdAndCost');
  if (this.productId !== null && this.productId !== undefined) {
    output.writeFieldBegin('productId', Thrift.Type.STRUCT, 1);
    this.productId.write(output);
    output.writeFieldEnd();
  }
  if (this.productCost !== null && this.productCost !== undefined) {
    output.writeFieldBegin('productCost', Thrift.Type.STRUCT, 2);
    this.productCost.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

