import React from 'react';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';

import { Dialog } from 'shared/components/Dialog';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export interface IDeleteProductsConfirmationDialogProps {
    readonly productIds : StringValueSet<ProductId>;
    readonly productsById : StringValueMap<ProductId, Product>;
    readonly productIdentificationString? : string; // for situations that do not have products by id
    readonly deleteActionInformationText : string | null;
    readonly onDeleteItemsClick : () => void;
    readonly onCancelClick : () => void;
}

export class DeleteProductsConfirmationDialog extends React.Component<IDeleteProductsConfirmationDialogProps, object> {
    public render() {
        const {
            productIds,
            productsById,
            onDeleteItemsClick,
            onCancelClick,
            deleteActionInformationText,
            productIdentificationString
        } = this.props;

        let identificationString : string;

        if (productIdentificationString) {
            identificationString = productIdentificationString;
        } else if (productIds.size === 1) {
            const productId = Array.from(productIds.values())[0];
            const product = productsById.get(productId);
            if (typeof product === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            identificationString = `${ product.getBrand() } ${ product.getName() }`.trim();
        } else {
            identificationString = `${ productIds.size } items`;
        }

        return (
            <Dialog
                className="delete-items-confirmation-dialog"
                buttons={ [
                    {
                        onClick: onCancelClick,
                        classes: 'flat',
                        isDisabled: false,
                        isLoading: false,
                        children: 'Cancel',
                    },
                    {
                        onClick: onDeleteItemsClick,
                        classes: 'flat danger',
                        isDisabled: false,
                        isLoading: false,
                        children: `Delete Item${ (productIds.size > 1) ? 's' : ''}`,
                    },
                ] }
                headerText={ `Delete Item${ (productIds.size > 1) ? 's' : ''}` } // TODO Copy
            >
                <p>
                    { `Are you sure you want to delete "${ identificationString }?"` }
                </p>
                <p className="danger dark">
                    <strong>{ `${ deleteActionInformationText ? deleteActionInformationText : 'Deleted items will be removed across your account.' }` }</strong><br/>
                    Historical counts and orders will not be affected. You can restore accidentally deleted items
                    from the Deleted Items page.
                </p>
            </Dialog>
        );
    }
}
