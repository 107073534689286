import SalesItemModel from 'gen-thrift/sales_item_Model_types';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { LocationThriftToObjectSerializer } from 'api/Location/serializer/LocationThriftToObjectSerializer';
import { MassUnit } from 'api/Product/model/MassUnit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import { ProductThriftToObjectSerializer } from 'api/Product/serializer/ProductThriftToObjectSerializer';
import { SalesItem } from 'api/SalesItem/model/SalesItem';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemWithMetadata } from 'api/SalesItem/model/SalesItemWithMetadata';
import { SalesQuantityAndUnit } from 'api/SalesItem/model/SalesQuantityAndUnit';
import { UserAccountThriftToObjectSerializer } from 'api/UserAccount/serializer/UserAccountThriftToObjectSerializer';
import { decimalToNumber } from 'shared/utils/decimalUtils';

export class SalesItemThriftToObjectSerializer {
    constructor(
        private userAccountThriftToObjectSerializer : UserAccountThriftToObjectSerializer,
        private productThriftToObjectSerializer : ProductThriftToObjectSerializer,
        private locationThriftToObjectSerializer : LocationThriftToObjectSerializer
    ) {}

    public getSalesItemIds(thriftIds : Array<SalesItemModel.SalesItemId>) : StringValueSet<SalesItemId> {
        const salesItemIds = new StringValueSet<SalesItemId>();
        thriftIds.forEach((thriftId) => {
            salesItemIds.add(this.getSalesItemId(thriftId));
        });
        return salesItemIds;
    }

    public getSalesItemId(thriftId : SalesItemModel.SalesItemId) : SalesItemId {
        return new SalesItemId(thriftId.value);
    }

    public getSalesItemWithMetadata(thriftSalesItemWithMetadata : SalesItemModel.SalesItemWithMetadata) {
        return new SalesItemWithMetadata(
            new SalesItem(
                thriftSalesItemWithMetadata.salesItem.name,
                this.locationThriftToObjectSerializer.getLocationId(thriftSalesItemWithMetadata.salesItem.locationId),
                thriftSalesItemWithMetadata.salesItem.menuGroup,
                thriftSalesItemWithMetadata.salesItem.posId,
                thriftSalesItemWithMetadata.salesItem.note,
                thriftSalesItemWithMetadata.salesItem.needsAttentionCategory ? thriftSalesItemWithMetadata.salesItem.needsAttentionCategory : null,
                this.productThriftToObjectSerializer.getDollarCost(thriftSalesItemWithMetadata.salesItem.salesPrice),
                this.productThriftToObjectSerializer.getDollarCost(thriftSalesItemWithMetadata.salesItem.miscellaneousCost),
                this.productThriftToObjectSerializer.getQuantityOfProductByProductId(thriftSalesItemWithMetadata.salesItem.componentQuantityOfProductByProductId),
                this.getSalesItemQuantityById(thriftSalesItemWithMetadata.salesItem.componentServingsBySalesItemId),
                this.getSalesQuantityAndUnit(thriftSalesItemWithMetadata.salesItem.itemYield),
                this.getSalesQuantityAndUnit(thriftSalesItemWithMetadata.salesItem.servingSize),
                thriftSalesItemWithMetadata.salesItem.salesItemCustomUnitName
            ),
            this.userAccountThriftToObjectSerializer.getUserAccountIdentifierAndTimestamp(thriftSalesItemWithMetadata.creationMetadata),
            thriftSalesItemWithMetadata.lastEditedMetadata ? this.userAccountThriftToObjectSerializer.getUserAccountIdentifierAndTimestamp(thriftSalesItemWithMetadata.lastEditedMetadata) : null,
            thriftSalesItemWithMetadata.deletionMetadata ? this.userAccountThriftToObjectSerializer.getUserAccountIdentifierAndTimestamp(thriftSalesItemWithMetadata.deletionMetadata) : null,
            thriftSalesItemWithMetadata.isArchived
        );
    }

    public getSalesItemIdMap(thriftSalesItemIdMapping : Array<SalesItemModel.SalesItemIdMapping>) : StringValueMap<SalesItemId, SalesItemId> {
        const newSalesItemIdByOldSalesItemId = new StringValueMap<SalesItemId, SalesItemId>();
        thriftSalesItemIdMapping.forEach((thriftSalesItemIdMap) => {
            newSalesItemIdByOldSalesItemId.set(
                this.getSalesItemId(thriftSalesItemIdMap.oldSalesItemId),
                this.getSalesItemId(thriftSalesItemIdMap.newSalesItemId));
        });
        return newSalesItemIdByOldSalesItemId;
    }

    private getSalesItemQuantityById(thriftSalesItemIdAndQuantityMap : Array<SalesItemModel.SalesItemIdAndQuantity>) : StringValueMap<SalesItemId, number> {
        const salesItemQuantityById : StringValueMap<SalesItemId, number> = new StringValueMap();
        thriftSalesItemIdAndQuantityMap.forEach((thriftSalesIdAndQuantity) => {
            salesItemQuantityById.set(
                this.getSalesItemId(thriftSalesIdAndQuantity.salesItemId),
                decimalToNumber(thriftSalesIdAndQuantity.quantity));
        });
        return salesItemQuantityById;
    }

    private getSalesQuantityAndUnit(thriftSalesQuantityAndUnit : SalesItemModel.SalesQuantityAndUnit) : SalesQuantityAndUnit {
        let unit : VolumeUnit | MassUnit | null;
        if (thriftSalesQuantityAndUnit.unit === null) {
            unit = null;
        } else {
            unit = this.productThriftToObjectSerializer.getMeasurableUnit(thriftSalesQuantityAndUnit.unit);
        }

        return new SalesQuantityAndUnit(
            decimalToNumber(thriftSalesQuantityAndUnit.quantity),
            unit
        );
    }

}
