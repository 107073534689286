import React from 'react';

import { DateTime } from '../../models/DateTime';
import { PeriodOfDay } from '../../models/PeriodOfDay';

import { BevSpotTimePickerUtils } from './utils';

export interface IBevSpotTimePickerPeriodOfDaySelector {
    dateTime : DateTime;
    setSelectedDateTime : (selectedDateTime : DateTime) => void;
}
export class BevSpotTimePickerPeriodOfDaySelector extends React.Component<IBevSpotTimePickerPeriodOfDaySelector, object> {
    public render() {
        const selectedPeriodOfDay : PeriodOfDay = parseInt(PeriodOfDay[this.props.dateTime.getValue('A') as any], 10) as PeriodOfDay;
        return (
            <div className="bevspot-time-picker-period-of-day-selector col-row">
                <div className="cell col-xs-6 text-center">
                    <div className={ selectedPeriodOfDay === PeriodOfDay.AM ? 'period-of-day selected' : 'period-of-day' } onClick={ this.subtractTwelveHours }>AM</div>
                </div>
                <div className="cell col-xs-6 text-center">
                    <div className={ selectedPeriodOfDay === PeriodOfDay.PM ? 'period-of-day selected' : 'period-of-day' } onClick={ this.addTwelveHours }>PM</div>
                </div>
            </div>
        );
    }
    private addTwelveHours = () : void => {
        const selectedPeriodOfDay : PeriodOfDay = parseInt(PeriodOfDay[this.props.dateTime.getValue('A') as any], 10) as PeriodOfDay;
        if (selectedPeriodOfDay === PeriodOfDay.AM) {
            const dateTimeWithNewHour : DateTime = BevSpotTimePickerUtils.addTwelveHours(this.props.dateTime);
            this.props.setSelectedDateTime(dateTimeWithNewHour);
        }
    }
    private subtractTwelveHours = () : void => {
        const selectedPeriodOfDay : PeriodOfDay = parseInt(PeriodOfDay[this.props.dateTime.getValue('A') as any], 10) as PeriodOfDay;
        if (selectedPeriodOfDay === PeriodOfDay.PM) {
            const dateTimeWithNewHour : DateTime = BevSpotTimePickerUtils.subtractTwelveHours(this.props.dateTime);
            this.props.setSelectedDateTime(dateTimeWithNewHour);
        }
    }
}
