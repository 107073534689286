import * as _ from 'lodash';
import { Action } from 'redux';

import { ActionTypes, ThanksForRegisteringActionInterfaces } from './actions';

export interface IThanksForRegisteringState {
    isResendingEmail : boolean;
    isEmailResent : boolean;
}

const initialThanksForRegisteringState : IThanksForRegisteringState = {
    isResendingEmail : false,
    isEmailResent : false,
};

const reduceSetIsResendingEmail = (
    state : IThanksForRegisteringState,
    action : ThanksForRegisteringActionInterfaces.ISetIsResendingEmail
) : IThanksForRegisteringState => {
    const updates = {
        isResendingEmail : action.payload.isResendingEmail,
    };

    return _.merge({}, state, updates);
};

const reduceSetIsEmailResent = (
    state : IThanksForRegisteringState,
    action : ThanksForRegisteringActionInterfaces.ISetIsEmailResent
) : IThanksForRegisteringState => {
    const updates = {
        isEmailResent: action.payload.isEmailResent,
    };

    return _.merge({}, state, updates);
};

export const ThanksForRegisteringReducers = (
    state : IThanksForRegisteringState = initialThanksForRegisteringState,
    action : Action,
) : IThanksForRegisteringState => {
    switch (action.type) {
        case ActionTypes.SET_IS_RESENDING_EMAIL:
            return reduceSetIsResendingEmail(
                state,
                action as ThanksForRegisteringActionInterfaces.ISetIsResendingEmail
            );
        case ActionTypes.SET_IS_EMAIL_RESENT:
            return reduceSetIsEmailResent(
                state,
                action as ThanksForRegisteringActionInterfaces.ISetIsEmailResent
            );
        default:
            return state;
    }
};
