import React from 'react';

import { IModalButton } from '../models/Modal';
import { Dialog } from './Dialog';

export interface IErrorDialog {
    isRecoverable : boolean;
    detailMessage : string | null;
    handleRecoveryButton() : void;
}

export class ErrorDialog extends React.Component<IErrorDialog, object> {
    public render() {
        const {
            isRecoverable,
            detailMessage,
            handleRecoveryButton,
        } = this.props;

        const buttons : Array<IModalButton> = [{
            classes: '',
            children: 'Back',
            isDisabled: false,
            isLoading: false,
            onClick: handleRecoveryButton,
        }];
        let errorMessage : JSX.Element;

        if (isRecoverable) {
            errorMessage = <p>BevSpot encountered a temporary issue and could not continue. Please try again later.</p>;
        } else {
            buttons.push({
                classes: '',
                children: 'Reload Page',
                isDisabled: false,
                isLoading: false,
                onClick: () => window.location.reload(),
            });
            errorMessage = <p>BevSpot encountered a problem and must reload. We are sorry for the inconvenience.</p>;
        }

        return (
            <Dialog
                className="error-dialog"
                buttons={ buttons }
                headerText="Something Went Wrong"
            >
                <div className="cell-ptb-xl">
                    { errorMessage }
                    { detailMessage !== null &&
                        <p>{ detailMessage }</p>
                    }
                </div>
            </Dialog>
        );
    }
}
