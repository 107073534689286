// import NumberGreaterThanMaxIntegerException from '../exceptions/NumberGreaterThanMaxIntegerException';
// import NumberLessThanMinIntegerException from '../exceptions/NumberLessThanMinIntegerException';

export class Integer {
    public readonly value : number;
    constructor(value : number) {
        const integerValue : number = Math.floor(value);

        // if (integerValue > Number.MAX_SAFE_INTEGER) {
        //     throw NumberGreaterThanMaxIntegerException;
        // }

        // if (integerValue < Number.MIN_SAFE_INTEGER) {
        //     throw NumberLessThanMinIntegerException;
        // }

        this.value = integerValue;
    }
}
