import moment from 'moment-timezone';

const getIsDateWithinDataHistoryLimit = (date : moment.Moment) : boolean => {
    return window.GLOBAL_RETAILER_DATA_HISTORY_LIMIT_IN_DAYS === null ? true : moment.utc().diff(date, 'days') <= window.GLOBAL_RETAILER_DATA_HISTORY_LIMIT_IN_DAYS;
};

const getOldestAllowedDateForDataHistoryLimit = () : moment.Moment | null => {
    return window.GLOBAL_RETAILER_DATA_HISTORY_LIMIT_IN_DAYS === null ? null : moment.utc().subtract(window.GLOBAL_RETAILER_DATA_HISTORY_LIMIT_IN_DAYS, 'days');
};

export const DataHistoryLimitUtils = {
    getIsDateWithinDataHistoryLimit,
    getOldestAllowedDateForDataHistoryLimit,
};
