import { Product } from 'api/Product/model/Product';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import React from 'react';

import moment from 'moment-timezone';

import { StringValueMap } from 'api/Core/StringValueMap';
import { Delivery } from 'api/Ordering/model/Delivery';
import { DeliveryId } from 'api/Ordering/model/DeliveryId';
import { PrepEvent } from 'api/PrepEvent/model/PrepEvent';
import { PrepEventId } from 'api/PrepEvent/model/PrepEventId';
import { TransferId } from 'api/Transfer/model/TransferId';
import { TransferReportWithoutCost } from 'api/Transfer/model/TransferReportWithoutCost';

import { Popover } from 'shared/components/Popover/Popover';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import './DeliveriesAndTransfersPopover.scss';

const DATE_RANGE_DATE_FORMAT = 'MMM. DD YYYY';

export interface IDeliveriesAndTransfersAndPrepsPopoverProps {
    product : Product;
    unitCountByDeliveryId : StringValueMap<DeliveryId, QuantityInUnit<ProductQuantityUnit>> | undefined | null;
    deliveriesByDeliveryId : StringValueMap<DeliveryId, Delivery>;
    unitCountByTransferId : StringValueMap<TransferId, QuantityInUnit<ProductQuantityUnit>> | undefined | null;
    transferReportWithoutCostsByTransferId : StringValueMap<TransferId, TransferReportWithoutCost>;
    inputUnitCountByPrepEventId : StringValueMap<PrepEventId, QuantityInUnit<ProductQuantityUnit>> | undefined;
    outputUnitCountByPrepEventId : StringValueMap<PrepEventId, QuantityInUnit<ProductQuantityUnit>> | undefined;
    prepEventsByPrepEventId : StringValueMap<PrepEventId, PrepEvent>;
    deliveriesAndTransfersAndPrepsCount : QuantityInUnit<ProductQuantityUnit>;
    deliveriesAndTransfersPopoverIsShown : boolean;
    setDeliveriesAndTransfersPopoverIsShown : (isShown : boolean) => void;
}

export class DeliveriesAndTransfersAndPrepsPopover extends React.Component<IDeliveriesAndTransfersAndPrepsPopoverProps, object> {

    constructor(props : IDeliveriesAndTransfersAndPrepsPopoverProps) {
        super(props);
    }

    public render() {
        const {
            product,
            unitCountByDeliveryId,
            deliveriesByDeliveryId,
            unitCountByTransferId,
            transferReportWithoutCostsByTransferId,
            deliveriesAndTransfersAndPrepsCount,
            deliveriesAndTransfersPopoverIsShown,
            setDeliveriesAndTransfersPopoverIsShown,
            inputUnitCountByPrepEventId,
            outputUnitCountByPrepEventId,
            prepEventsByPrepEventId,
        } = this.props;

        const deliveries : Array<JSX.Element> = [];
        const preferredReportingUnit = product.getPreferredReportingUnit();
        const packagingsAndMappings = product.getPackagingsAndMappings();

        if (unitCountByDeliveryId) {
            const sortedDeliveryIds = Array.from(unitCountByDeliveryId.keys()).sort((deliveryIdA, deliveryIdB) => {
                const deliveryA = deliveriesByDeliveryId.get(deliveryIdA);
                const deliveryB = deliveriesByDeliveryId.get(deliveryIdB);
                if (typeof deliveryA === 'undefined' || typeof deliveryB === 'undefined') {
                    throw new RuntimeException('unexpected');
                }
                return deliveryA.getDateDeliveredUTC().diff(deliveryB.getDateDeliveredUTC());
            });

            sortedDeliveryIds.forEach((deliveryId : DeliveryId) => {
                const count = unitCountByDeliveryId.get(deliveryId);
                const delivery = deliveriesByDeliveryId.get(deliveryId);
                if (typeof delivery !== 'undefined') {
                    const linkToOrderDetail = '/ordering/record/detail/r/' + window.GLOBAL_RETAILER_ID + '/#/order/' + deliveryId.getValue();
                    const countDisplay = count ? PackagingUtils.convertProductQuantityToUnit(packagingsAndMappings, count, preferredReportingUnit).getQuantity().toFixed(2) : undefined;
                    deliveries.push((
                        <div className="row-container" key={ deliveryId.getValue() }>
                            <a href={ linkToOrderDetail }><small className="row-date">{ delivery.getDateDeliveredUTC().tz(window.GLOBAL_RETAILER_TIME_ZONE).format(DATE_RANGE_DATE_FORMAT) }</small></a>
                            <span className="text-right">
                                <span className="unit-count cell-plr-xs">{ countDisplay }</span>
                            </span>
                        </div>
                    ));
                }
            });
        }

        const transfersIn : Array<JSX.Element> = [];
        const transfersOut : Array<JSX.Element> = [];

        if (unitCountByTransferId) {
            const sortedTransferIds = Array.from(unitCountByTransferId.keys()).sort((transferIdA, transferIdB) => {
                const transferA = transferReportWithoutCostsByTransferId.get(transferIdA);
                const transferB = transferReportWithoutCostsByTransferId.get(transferIdB);
                if (typeof transferA === 'undefined' || typeof transferB === 'undefined') {
                    throw new RuntimeException('unexpected');
                }
                return transferA.getTransferReportData().getTime().timeSinceUnixEpoch.value - transferB.getTransferReportData().getTime().timeSinceUnixEpoch.value;
            });

            sortedTransferIds.forEach((transferId : TransferId) => {
                const count = unitCountByTransferId.get(transferId);
                const linkToTransferReport = `/inventory/transfer_report/${ transferId.getValue() }/r/${ window.GLOBAL_RETAILER_ID }/`;
                if (transferReportWithoutCostsByTransferId) {
                    const transferReport = transferReportWithoutCostsByTransferId.get(transferId);

                    if (typeof transferReport !== 'undefined') {
                        const transferReportData = transferReport.getTransferReportData();

                        if (typeof transferReportData !== 'undefined') {
                            const transferDate = moment.utc(transferReportData.getTime().timeSinceUnixEpoch.value);
                            const countDisplay = count ? PackagingUtils.convertProductQuantityToUnit(packagingsAndMappings, count, preferredReportingUnit).getQuantity().toFixed(2) : undefined;
                            if (transferReportData.getDirection() === 0) {
                                transfersIn.push((
                                    <div className="row-container">
                                        <a href={ linkToTransferReport } ><small className="row-date">{ transferDate.tz(window.GLOBAL_RETAILER_TIME_ZONE).format(DATE_RANGE_DATE_FORMAT) }</small></a>
                                        <span className="text-right">
                                            <span className="unit-count cell-plr-xs">{ countDisplay }</span>
                                        </span>
                                    </div>
                                ));
                            } else {
                                transfersOut.push((
                                    <div className="row-container">
                                        <a href={ linkToTransferReport } ><small className="row-date">{ transferDate.tz(window.GLOBAL_RETAILER_TIME_ZONE).format(DATE_RANGE_DATE_FORMAT) }</small></a>
                                        <span className="text-right">
                                            <span className="unit-count cell-plr-xs">{ countDisplay }</span>
                                        </span>
                                    </div>
                                ));
                            }
                        }
                    }
                }
            });
        }

        const prepEventInputs : Array<JSX.Element> = [];
        const prepEventOutputs : Array<JSX.Element> = [];
        if (inputUnitCountByPrepEventId || outputUnitCountByPrepEventId) {
            const sortedPrepEventIds = Array.from(prepEventsByPrepEventId.keys()).sort((prepEventIdA, prepEventIdB) => {
                const prepEventA = prepEventsByPrepEventId.get(prepEventIdA);
                const prepEventB = prepEventsByPrepEventId.get(prepEventIdB);
                if (typeof prepEventA === 'undefined' || typeof prepEventB === 'undefined') {
                    throw new RuntimeException('unexpected');
                }
                return prepEventA.getPrepEventTime().diff(prepEventB.getPrepEventTime());
            });
            sortedPrepEventIds.forEach((prepEventId) => {
                const inputUnitCount = inputUnitCountByPrepEventId ? inputUnitCountByPrepEventId.get(prepEventId) : undefined;
                const outputUnitCount = outputUnitCountByPrepEventId ? outputUnitCountByPrepEventId.get(prepEventId) : undefined;
                const prepEvent = prepEventsByPrepEventId.get(prepEventId);

                if (typeof prepEvent !== 'undefined') {
                    const linkToPrepEvent = `/inventory/prep_record/${ prepEventId.getValue() }/r/${ window.GLOBAL_RETAILER_ID }`;

                    const prepEventDate = prepEvent.getPrepEventTime().clone();
                    const prepEventDateString = prepEventDate.tz(window.GLOBAL_RETAILER_TIME_ZONE).format(DATE_RANGE_DATE_FORMAT);

                    if (inputUnitCount) {
                        const inputUnitCountInPreferredReportingUnit = PackagingUtils.convertProductQuantityToUnit(packagingsAndMappings, inputUnitCount, preferredReportingUnit);
                        const inputForDisplay = inputUnitCountInPreferredReportingUnit.getQuantity() * -1;

                        prepEventInputs.push((
                            <div className="row-container" key={ prepEventId.getValue() + '_input' }>
                                <a href={ linkToPrepEvent } ><small className="row-date">{ prepEventDateString }</small></a>
                                <span className="text-right">
                                    <span className="unit-count cell-plr-xs">{ inputForDisplay.toFixed(2) }</span>
                                </span>
                            </div>
                        ));
                    }

                    if (outputUnitCount) {
                        const outputUnitCountInPreferredReportingUnit = PackagingUtils.convertProductQuantityToUnit(packagingsAndMappings, outputUnitCount, preferredReportingUnit);
                        prepEventOutputs.push((
                            <div className="row-container" key={ prepEventId.getValue() + '_output' }>
                                <a href={ linkToPrepEvent }><small className="row-date">{ prepEventDateString }</small></a>
                                <span className="text-right">
                                    <span className="unit-count cell-plr-xs">{ outputUnitCountInPreferredReportingUnit.getQuantity().toFixed(2) }</span>
                                </span>
                            </div>
                        ));
                    }
                }
            });
        }
        const deliveriesAndTransfersAndPrepsCountInPreferredReportingUnit = PackagingUtils.convertProductQuantityToUnit(packagingsAndMappings, deliveriesAndTransfersAndPrepsCount, preferredReportingUnit).getQuantity();
        return (
            <Popover
                className="deliveries-and-transfers-popover"
                preferredPositionArray={ ['below', 'above', 'left', 'right'] }
                showOnHover={ true }
                popoverContentIsShown={ deliveriesAndTransfersPopoverIsShown }
                setPopoverContentIsShown={ setDeliveriesAndTransfersPopoverIsShown }
                anchorContent={ (
                    <span className="deliveries-and-transfers-amount">
                        { deliveriesAndTransfersAndPrepsCountInPreferredReportingUnit.toFixed(2) }
                    </span>
                ) }
                popoverContent={ (
                    <div className="popover-breakdown-container">
                        <div className="popover-title">
                            <strong>Deliveries, Transfers, and Preps</strong>
                        </div>
                        <hr className="rule-light"/>
                        <ul className="price-breakdown-list">
                            { deliveries.length > 0 &&
                                <li>
                                    <strong>Deliveries</strong>
                                    { deliveries }
                                </li>
                            }
                            { transfersIn.length > 0 &&
                                <li>
                                    <strong>Transfers In</strong>
                                    { transfersIn }
                                </li>
                            }
                            { transfersOut.length > 0 &&
                                <li>
                                    <strong>Transfers Out</strong>
                                    { transfersOut }
                                </li>
                            }
                            { prepEventInputs.length > 0 &&
                                <li>
                                    <strong>Prep Inputs</strong>
                                    { prepEventInputs }
                                </li>
                            }
                            { prepEventOutputs.length > 0 &&
                                <li>
                                    <strong>Prep Outputs</strong>
                                    { prepEventOutputs }
                                </li>
                            }
                        </ul>
                        <hr className="dark-rule"/>
                        <div className="price-breakdown-total-line">
                            <strong>Total</strong>
                            <span>
                                { deliveriesAndTransfersAndPrepsCount && deliveriesAndTransfersAndPrepsCountInPreferredReportingUnit.toFixed(2) }
                            </span>
                        </div>
                    </div>
                ) }
            />
        );
    }
}
