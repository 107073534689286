import { IDistributorNameMappingService } from 'api/Distributor/interfaces/IDistributorNameMappingService';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { DistributorObjectToJSONSerializer } from 'api/Distributor/serializer/DistributorObjectToJSONSerializer';
import { LocationId } from 'api/Location/model/LocationId';
import { DistributorNameMapping } from 'api/Ordering/model/DistributorNameMapping';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { AjaxUtils } from 'shared/utils/ajaxUtils';

export class DistributorNameMappingServiceImpl implements IDistributorNameMappingService {
    constructor (
        private readonly distributorObjectToJSONSerializer : DistributorObjectToJSONSerializer,
    ) { }

    public getMappedDistributorIdsByNameForRetailer (
        userSessionId : UserSessionId,
        locationId : LocationId
    ) : Promise<Map<string, DistributorId>> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
        };
        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:distributor_name_mappings'), queryParameters)
        .then((response) => {
            const distributorNameMappings : Map<string, DistributorId> = new Map();
            for (const distributorNameMapping of Object.keys(response)) {
                distributorNameMappings.set(distributorNameMapping, new DistributorId(response[distributorNameMapping]));
            }
            return distributorNameMappings;
        })
        .catch((error : Error) => {
            return Promise.reject(error);
        });
    }

    public saveDistributorNameMapping (
        userSessionId : UserSessionId,
        distributorNameMapping : DistributorNameMapping
    ) : Promise<void> {
        const queryParameters = {
            retailer_id: distributorNameMapping.getRetailerId().getValue(),
        };
        return AjaxUtils.ajaxPut(urlWithoutRetailerId('api:distributor_name_mappings'), this.distributorObjectToJSONSerializer.getMappingJSON(distributorNameMapping), queryParameters)
        .then((response) => {
            return Promise.resolve();
        })
        .catch((error : Error) => {
            return Promise.reject(error);
        });
    }

}
