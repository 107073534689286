import DistributorModel from 'gen-thrift/distributor_Model_types';

import { GroupId } from 'api/Location/model/GroupId';
import { LocationId } from 'api/Location/model/LocationId';
import { Scope } from 'api/Location/model/Scope';

export class ScopeThriftToObjectSerializer {
    public getScope(scope : DistributorModel.Scope) : Scope {
        switch (scope.type) {
            case DistributorModel.ScopeType.GLOBAL:
                if (scope.id !== null) {
                    throw new Error('scope id unexpectedly not null');
                }
                return Scope.createGlobalScope();
            case DistributorModel.ScopeType.GROUP:
                if (scope.id === null) {
                    throw new Error('scope id unexpectedly null');
                }
                return Scope.createGroupScope(new GroupId(scope.id));
            case DistributorModel.ScopeType.RETAILER:
                if (scope.id === null) {
                    throw new Error('scope id unexpectedly null');
                }
                return Scope.createRetailerScope(new LocationId(scope.id));
            default:
                throw new Error('unexpected scope type : ' + scope.type);
        }
    }
}
