export class DeliveryRepInfo {

    constructor (
        private readonly name : string,
        private readonly emailAddress : string,
        private readonly phoneNumber : string,
        private readonly emailWasSentForOrder : boolean,
        private readonly smsWasSentForOrder : boolean,
        private readonly csvWasAttachedToOrder : boolean,
    ) {}

    public getName() : string {
        return this.name;
    }

    public getEmailAddress() : string {
        return this.emailAddress;
    }

    public getPhoneNumber() : string {
        return this.phoneNumber;
    }

    public getEmailWasSentForOrder() : boolean {
        return this.emailWasSentForOrder;
    }

    public getSmsWasSentForOrder() : boolean {
        return this.smsWasSentForOrder;
    }

    public getCsvWasAttachedToOrder() : boolean {
        return this.csvWasAttachedToOrder;
    }
}
