import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IModalButton } from 'shared/models/Modal';

import { BevSpotDatePicker } from '../BevSpotDatePicker/BevSpotDatePicker';
import { BevSpotTimePicker, IBevSpotTimePickerFormValues } from '../BevSpotTimePicker/BevSpotTimePicker';
import { Button } from '../Button';
import { BevspotDateTimePickerHeader } from './BevSpotDateTimePickerHeader';

import { IBevSpotDateTimePickerState } from '../../components/BevSpotDateTimePicker/IBevSpotDateTimePickerState';
import { IDateTimePickerStateById } from '../../components/BevSpotDateTimePicker/reducers';

import { DateTimePickerActions } from './actions';

import { DateTime } from '../../models/DateTime';
import { IFormActions } from '../../models/IFormActions';
import { MonthOfYear } from '../../models/MonthOfYear';

import { BevSpotDatePickerUtils } from '../BevSpotDatePicker/utils';
import { BevSpotTimePickerUtils } from '../BevSpotTimePicker/utils';

export enum BevSpotDateTimePickerType {
    EMBEDDED = 0,
    POPUP = 1,
}

export interface IConnectedBevSpotDateTimePicker {
    buttons : Array<IModalButton>;
    initialDateTime : DateTime;
    setDateTime : (dateTime : DateTime) => void;
    type : BevSpotDateTimePickerType;
    uniqueId : string;
    shouldShowTime : boolean;
}

export interface IBevSpotDateTimePicker extends IConnectedBevSpotDateTimePicker {
    formValues : IBevSpotTimePickerFormValues;
    selectedDateTime : DateTime;
    visibleMonth : MonthOfYear;
    visibleYear : number;
}

export interface IBoundBevSpotDateTimePicker extends IBevSpotDateTimePicker {
    dispatch : Dispatch<object>;
}

export class BevSpotDateTimePicker extends React.Component<IBoundBevSpotDateTimePicker, object> {
    public componentWillMount() {
        const {
            dispatch,
            initialDateTime,
            uniqueId,
        } = this.props;

        const defaultDateTimePickerState : IBevSpotDateTimePickerState = {
            formValues: {
                hour: {
                    errorMessage: null,
                    isValid: true,
                    value: BevSpotTimePickerUtils.getHourAsStringFromDateTime(initialDateTime),
                },
                minute: {
                    errorMessage: null,
                    isValid: true,
                    value: BevSpotTimePickerUtils.getMinuteAsStringFromDatetime(initialDateTime),
                },
            },
            selectedDateTime: initialDateTime,
            visibleMonth: BevSpotDatePickerUtils.getMonthAsNumberFromDateTime(initialDateTime),
            visibleYear: BevSpotDatePickerUtils.getYearAsNumberFromDateTime(initialDateTime),
        };

        dispatch(DateTimePickerActions.initializeDatetimePickerState(uniqueId, defaultDateTimePickerState));
    }

    public render() {
        const {
            dispatch,
            buttons,
            formValues,
            selectedDateTime,
            setDateTime,
            type,
            visibleMonth,
            visibleYear,
            uniqueId,
            shouldShowTime,
        } = this.props;

        const formActions : { hour : IFormActions; minute : IFormActions } = {
            hour: {
                setErrorMessage: (errorMessage : string | null) => {
                    dispatch(DateTimePickerActions.setHourErrorMessage(uniqueId, errorMessage));
                },
                setIsValid: (isValid : boolean) => {
                    dispatch(DateTimePickerActions.setHourIsValid(uniqueId, isValid));
                },
                setFormValue: (value : string | null) => {
                    dispatch(DateTimePickerActions.setHourFormValue(uniqueId, value));
                },
            },
            minute: {
                setErrorMessage: (errorMessage : string | null) => {
                    dispatch(DateTimePickerActions.setMinuteErrorMessage(uniqueId, errorMessage));
                },
                setIsValid: (isValid : boolean) => {
                    dispatch(DateTimePickerActions.setMinuteIsValid(uniqueId, isValid));
                },
                setFormValue: (value : string | null) => {
                    dispatch(DateTimePickerActions.setMinuteFormValue(uniqueId, value));
                },
            },
        };
        const handleSetSelectedDateTime = (newDateTime : DateTime) => {
            setDateTime(newDateTime);
            dispatch(DateTimePickerActions.setSelectedDateTime(uniqueId, newDateTime));
        };
        const handleSetVisibleMonth = (newVisibleMonth : MonthOfYear) => {
            dispatch(DateTimePickerActions.setVisibleMonth(uniqueId, newVisibleMonth));
        };
        const handleSetVisibleYear = (newVisibleYear : number) => {
            dispatch(DateTimePickerActions.setVisibleYear(uniqueId, newVisibleYear));
        };
        const buttonElements : Array<JSX.Element> = buttons.map((button : IModalButton, index : number) => (
            <Button
                key={ index }

                buttonClassName={ button.classes }
                isDisabled={ button.isDisabled }
                isLoading={ button.isLoading }
                onClick={ button.onClick }
            >
                { button.children }
            </Button>
        ));

        const dateTimePickerType = type === BevSpotDateTimePickerType.POPUP ? 'pop-up' : 'embedded';

        return this.props.selectedDateTime !== undefined ? (
            <div className={ `bevspot-datetime-picker ${ dateTimePickerType }` }>
                <BevspotDateTimePickerHeader selectedDateTime={ selectedDateTime } />
                <div className="bevspot-datetime-picker-caret"/>
                <div className="bevspot-datetime-picker-body">
                    <div className="col-row">
                        <div className="cell-plr-sm col-xs-12 col-sm-8 text-center date-picker-side">
                            <BevSpotDatePicker
                                selectedDateTime={ selectedDateTime }
                                setSelectedDateTime={ handleSetSelectedDateTime }
                                setVisibleMonth={ handleSetVisibleMonth }
                                setVisibleYear={ handleSetVisibleYear }
                                visibleMonth={ visibleMonth }
                                visibleYear={ visibleYear }
                            />
                        </div>
                        { shouldShowTime &&
                            <div className="cell-plr-sm col-xs-12 col-sm-4 text-center">
                                <BevSpotTimePicker
                                    selectedDateTime={ selectedDateTime }
                                    formActions={ formActions }
                                    formValues={ formValues }
                                    setSelectedDateTime={ handleSetSelectedDateTime }
                                />
                            </div>
                        }
                    </div>
                </div>
                <div className="bevspot-datetime-picker-footer">
                    <div className="col-row text-right">
                        <div className="col-xs-12">
                            { buttonElements }
                        </div>
                    </div>
                </div>
            </div>
        ) : null;
    }
}

interface IState {
    dateTimePickerStateById : IDateTimePickerStateById;
}

const mapStateToProps = (state : IState, ownProps : IConnectedBevSpotDateTimePicker) : IBevSpotDateTimePicker => {
    const stateProps : any = state.dateTimePickerStateById[ownProps.uniqueId] || {};
    /**
     * @todo clean up to make sure no overwriting
     */
    const dateTimePickerInstance : IBevSpotDateTimePicker = {
        ...ownProps,
        ...stateProps
    };

    return dateTimePickerInstance;
};

export const ConnectedBevSpotDateTimePicker = connect<IBevSpotDateTimePicker, object, IConnectedBevSpotDateTimePicker, IState>(mapStateToProps)(BevSpotDateTimePicker);
