import React from 'react';

// Talk to Cheezy before trying to use this component
// This isn't quite a general animation component. It specifically takes props required by the RowOptimizationWrapper.
interface IHeightTransitionGroupAnimationDivProps {
    divWrapperRef : (divWrapper : HTMLDivElement) => void;
    style : {height : string, paddingTop : string};
}

const ANIMATION_LENGTH_IN_SECONDS = 0.2;

export class HeightTransitionGroupAnimationDiv extends React.Component<IHeightTransitionGroupAnimationDivProps, object> {

    private animationDiv : HTMLDivElement | null;

    constructor(props : IHeightTransitionGroupAnimationDivProps) {
        super(props);

        this.animationDiv = null;
    }

    // TODO Handle animationDiv getting yanked from the page
    public componentWillEnter(doneCallback : () => void) {
        if (this.animationDiv === null) {
            return;
        }
        this.animationDiv.style.display = 'none';
        window.requestAnimationFrame(() => {
            if (this.animationDiv === null) {
                return;
            }
            this.animationDiv.style.overflow = 'hidden';
            this.animationDiv.style.transition = `all ${ ANIMATION_LENGTH_IN_SECONDS.toFixed(1) }s ease-in`;
            this.animationDiv.style.paddingTop = '0px';
            this.animationDiv.style.height = '0px';
            window.requestAnimationFrame(() => {
                if (this.animationDiv === null) {
                    return;
                }
                this.animationDiv.style.display = 'block';
                window.requestAnimationFrame(() => {
                    if (this.animationDiv === null) {
                        return;
                    }
                    this.animationDiv.style.height = this.props.style.height;
                    this.animationDiv.style.paddingTop = this.props.style.paddingTop;
                    setTimeout(doneCallback, ANIMATION_LENGTH_IN_SECONDS * 1000);
                });
            });
        });
    }

    // TODO Handle animationDiv getting yanked from the page
    public componentDidEnter() {
        window.requestAnimationFrame(() => {
            if (this.animationDiv === null) {
                return;
            }
            this.animationDiv.style.transition = 'none';
            this.animationDiv.style.overflow = 'visible';
        });
    }

    // TODO Handle animationDiv getting yanked from the page
    public componentWillLeave(doneCallback : () => void) {
        window.requestAnimationFrame(() => {
            if (this.animationDiv === null) {
                return;
            }
            this.animationDiv.style.overflow = 'hidden';
            this.animationDiv.style.transition = `all ${ ANIMATION_LENGTH_IN_SECONDS.toFixed(1) }s ease-out`;
            window.requestAnimationFrame(() => {
                if (this.animationDiv === null) {
                    return;
                }
                this.animationDiv.style.height = '0px';
                this.animationDiv.style.paddingTop = `0px`;
                setTimeout(doneCallback, ANIMATION_LENGTH_IN_SECONDS * 1000);
            });
        });
    }

    public render() {
        // TODO Figure out why "transform: translateZ(0);" hardware acceleration isn't working on Edge
        return (
            <div
                ref={ this.animationDivRef }
                style={ this.props.style }
            >
                { this.props.children }
            </div>
        );
    }

    private animationDivRef = (animationDiv : HTMLDivElement) => {
        this.animationDiv = animationDiv;
        this.props.divWrapperRef(animationDiv);
    }
}
