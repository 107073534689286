import { IDistributorRepresentative } from 'api/Distributor/interfaces/IDistributorRepresentative';

export class RetailerDistributorRelationship {
    constructor (
        private readonly accountId : string,
        private readonly reps : Array<IDistributorRepresentative>
    ) {}

    public getAccountId() {
        return this.accountId;
    }

    public getReps() {
        return this.reps;
    }
}
