import React from 'react';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import './Pagination.scss';

export interface IPaginationProps {
    currentPage : number;
    numberOfPages : number;
    setCurrentPage : (pageNumber : number) => void;
}

export class Pagination extends React.Component<IPaginationProps, {}> {

    public render() {
        const {
            currentPage,
            numberOfPages,
        } = this.props;

        if (currentPage < 1) {
            throw new RuntimeException();
        }

        if (numberOfPages < 1) {
            throw new RuntimeException();
        }

        if (currentPage > numberOfPages) {
            throw new RuntimeException();
        }

        return (
            <div className="pagination">
                <div
                    className={ `previous-page ${ (this.props.currentPage === 1) ? 'disabled' : '' }` }
                    onClick={ this.onPreviousPage }
                >
                    <span className="bevicon bevico-arrow-back" />
                </div>
                <div className="pagination-numbers custom-pagination">
                    { (currentPage !== 1) ? this.getPaginationLink(1) : null }

                    { (currentPage === 5) ? this.getPaginationLink(2) : null }

                    { (currentPage > 5) ? (<span className="previous-ellipsis">...</span>) : null }

                    { (currentPage > 3) ? this.getPaginationLink(currentPage - 2) : null }

                    { (currentPage > 2) ? this.getPaginationLink(currentPage - 1) : null }

                    { this.getPaginationLink(currentPage) }

                    { (currentPage < numberOfPages - 1) ? this.getPaginationLink(currentPage + 1) : null }

                    { (currentPage < numberOfPages - 2) ? this.getPaginationLink(currentPage + 2) : null }

                    { (currentPage < numberOfPages - 4) ? (<span className="next-ellipsis">...</span>) : null }

                    { (currentPage === numberOfPages - 4) ? this.getPaginationLink(numberOfPages - 1) : null }

                    { (currentPage !== numberOfPages) ? this.getPaginationLink(numberOfPages) : null }
                </div>
                <div
                    className={ `next-page ${ (this.props.currentPage === this.props.numberOfPages) ? 'disabled' : '' }` }
                    onClick={ this.onNextPage }
                >
                    <span className="bevicon bevico-arrow-forward" />
                </div>
            </div>
        );
    }

    private getPaginationLink = (pageNumber : number) : JSX.Element => {
        const {
            currentPage,
        } = this.props;

        const onPageNumberClick = () => {
            this.onPageNumberClick(pageNumber);
        };

        return (
            <div
                className={ `pagination-number ${ (pageNumber === currentPage) ? 'active' : '' }` }
                onClick={ onPageNumberClick }
            >
                { pageNumber }
            </div>
        );
    }

    private readonly onPreviousPage = () => {
        const {
            currentPage,
            setCurrentPage
        } = this.props;

        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    private readonly onNextPage = () => {
        const {
            currentPage,
            numberOfPages,
            setCurrentPage
        } = this.props;

        if (currentPage !== numberOfPages) {
            setCurrentPage(currentPage + 1);
        }
    }

    private readonly onPageNumberClick = (pageNumber : number) => {
        const {
            currentPage,
        } = this.props;

        if (pageNumber !== currentPage) {
            this.props.setCurrentPage(pageNumber);
        }
    }
}
