import moment from 'moment-timezone';
import React from 'react';

import {
    ISalesItemForm,
    ISalesItemFormData,
} from '../reducers/reducers';

import '../css/CreateOrEditSalesItemApp.scss';

export interface ILastEditedInformation {
    salesItemFormInfo : ISalesItemForm;
    salesItemFormData : ISalesItemFormData;
}

export class LastEditedInformation extends React.Component<ILastEditedInformation, object> {

    public render() {
        const {
            salesItemFormInfo,
            salesItemFormData,
        } = this.props;

        const lastEditedUser = salesItemFormData !== null && salesItemFormInfo.lastEditedMetadata !== null ? salesItemFormData.namesByUserAccountId.get(salesItemFormInfo.lastEditedMetadata.getUserAccountId()) : undefined;

        return (
            <React.Fragment>
                { (salesItemFormInfo.lastEditedMetadata !== null || lastEditedUser !== null) &&
                    <p className="last-edited-container">
                        { salesItemFormInfo.lastEditedMetadata !== null &&
                            <small>
                                <em className="last-edited">
                                    Last Edited
                                    { ` ${ moment(salesItemFormInfo.lastEditedMetadata.getTimestamp().timeSinceUnixEpoch.value).format('ddd. MMM. D, YYYY h:mma') } ` }
                                </em>
                            </small>
                        }
                        { typeof lastEditedUser !== 'undefined' &&
                            <small>
                                <em className="editor">
                                    by { `${ lastEditedUser.firstName } ${ lastEditedUser.lastName }` }
                                </em>
                            </small>
                        }
                    </p>
                }
            </React.Fragment>
        );
    }
}
