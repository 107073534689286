import { StringValueMap } from 'api/Core/StringValueMap';
import { IVendorIntegrationService } from 'api/Integration/interfaces/IVendorIntegrationService';
import { FintechIntegrationState } from 'api/Integration/model/FintechIntegrationState';
import { FintechLocationId } from 'api/Integration/model/FintechLocationId';
import { FtpCredentials } from 'api/Integration/model/FtpCredentials';
import { IFintechIntegrationStateJson } from 'api/Integration/model/IFintechIntegrationStateJson';
import { IStediIntegrationStateJson } from 'api/Integration/model/IStediIntegrationStateJson';
import { StediIntegrationId } from 'api/Integration/model/StediIntegrationId';
import { StediIntegrationState } from 'api/Integration/model/StediIntegrationState';
import { StediLocationReference } from 'api/Integration/model/StediLocationReference';
import { LocationId } from 'api/Location/model/LocationId';
import { InvoiceUploadId } from 'api/Ordering/model/InvoiceUploadId';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import moment from 'moment-timezone';
import { StediTradingPartner } from 'shared/models/IntegrationStatus';
import { AjaxUtils, IRestApiRejection } from 'shared/utils/ajaxUtils';

export class VendorIntegrationServiceImpl implements IVendorIntegrationService {
    public getFintechIntegrationState(userSessionId : UserSessionId, locationId : LocationId) : Promise<FintechIntegrationState> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
        };
        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:fintech_integration'), queryParameters)
        .then((response : Array<IFintechIntegrationStateJson>) => {
            if (response.length > 0) {
                const data = response[0];   // should be at most 1
                return Promise.resolve(
                    new FintechIntegrationState(
                        new FintechLocationId(data.fintech_location_id.account_id, data.fintech_location_id.store_number),
                        !!data.custom_ftp_credentials ? new FtpCredentials(data.custom_ftp_credentials.username, data.custom_ftp_credentials.password) : null,
                        !!data.last_import_attempt_time ? moment.utc(data.last_import_attempt_time) : null,
                        !! data.last_imported_file_date ? moment.utc(data.last_imported_file_date) : null,
                    )
                );
            }
            return new FintechIntegrationState(null, null, null, null);
        })
        .catch((rejection : IRestApiRejection) => {
            return Promise.reject(rejection);
        });
    }

    public getStediIntegrationStates(userSessionId : UserSessionId, locationId : LocationId) {
        const queryParameters = {
            retailer_id: locationId.getValue(),
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:stedi_integrations'), queryParameters)
        .then((data : {[stediIntegrationId: string]: IStediIntegrationStateJson}) => {
            const result : StringValueMap<StediIntegrationId, StediIntegrationState> = new StringValueMap<StediIntegrationId, StediIntegrationState>();
            Object.keys(data).forEach(((key) => {
                const integrationStateJson = data[key];
                result.set(
                    new StediIntegrationId(key),
                    new StediIntegrationState(
                        integrationStateJson.stedi_trading_partner as StediTradingPartner,
                        integrationStateJson.stedi_location_reference ? new StediLocationReference(
                            integrationStateJson.stedi_location_reference.location_id,
                            integrationStateJson.stedi_location_reference.vendor_id,
                            integrationStateJson.stedi_location_reference.group_id
                        ) : null,
                        integrationStateJson.last_invoice_info ? {
                            dateReceived: integrationStateJson.last_invoice_info.date_received ? moment.utc(integrationStateJson.last_invoice_info.date_received) : null,
                            invoiceDate: integrationStateJson.last_invoice_info.invoice_date ? moment.utc(integrationStateJson.last_invoice_info.invoice_date) : null,
                            invoiceUploadId: integrationStateJson.last_invoice_info.invoice_upload_id ? new InvoiceUploadId(integrationStateJson.last_invoice_info.invoice_upload_id) : null
                        } : null
                    )
                );
            }));
            return result;
        })
        .catch((rejection : IRestApiRejection) => {
            return Promise.reject(rejection);
        });
    }
}
