import { IntegrationState } from 'api/Integration/model/IntegrationState';
import { OmnivoreSupportedPosType } from 'api/Integration/model/IntegrationType';
import moment from 'moment-timezone';

/***
 * Omnivore is not a POS system itself. It provides cloud API for various non-cloud POS such as Micros.
 * The actual underlying POS type doesn't affect any reporting functionality, but is important for user
 * and business to know and is part of the connection data.
 * Missing posType means no connection data exists.
 */
export class OmnivoreIntegrationState extends IntegrationState {
    constructor(
        public readonly posType : OmnivoreSupportedPosType | null,
        externalMerchantId : string | null,
        isAccessible : boolean,
        cacheDataEffectiveDate : moment.Moment | null
    ) {
        super(externalMerchantId, isAccessible, cacheDataEffectiveDate);
    }
}
