import React from 'react';
import {
    ConnectDragPreview,
    ConnectDragSource,
    ConnectDropTarget,
    DragSource,
    DragSourceCollector,
    DragSourceConnector,
    DragSourceMonitor,
    DragSourceSpec,
    DropTarget,
    DropTargetCollector,
    DropTargetConnector,
    DropTargetMonitor,
    DropTargetSpec,
} from 'react-dnd';
import { Preview } from 'react-dnd-multi-backend';

import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';

import { ValidationInput } from 'shared/components/ValidationInput';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import 'apps/InventoryCount/css/ManageStorageAreaListItem.scss';

export interface IManageStorageAreaListItemProps {
    readonly storageAreaId : StorageAreaId;
    readonly storageAreaName : string;
    readonly isFocusedRow : boolean;
    readonly handleNameChange : (storageAreaName : string) => void;
    readonly handleRemoveButtonClick : () => void;
    readonly onDragHover : (dragStorageAreaId : StorageAreaId, hoverStorageAreaId : StorageAreaId) => void;
    readonly onDrop : (dragStorageAreaId : StorageAreaId) => void;
    readonly onDragCancel : () => void;
}

export interface IDraggableManageStorageAreaListItemProps extends IManageStorageAreaListItemProps {
    readonly isDragging? : boolean;
    readonly isOverDragTarget? : boolean;
    readonly connectDragSource? : ConnectDragSource;
    readonly connectDragPreview? : ConnectDragPreview;
    readonly connectDropTarget? : ConnectDropTarget;
}

class ManageStorageAreaListItemWithoutDragAndDrop extends React.Component<IDraggableManageStorageAreaListItemProps, object> {
    private readonly classNamePrefix = 'manage-storage-area-list-item';

    public render() {
        const {
            storageAreaId,
            storageAreaName,
            isFocusedRow,
            handleRemoveButtonClick,
            connectDragSource,
            connectDropTarget,
            connectDragPreview,
            isDragging,
            isOverDragTarget,
        } = this.props;

        // We always expect these props to be present but need to make them optional to accomadate the typings from React DND
        if ((typeof isDragging === 'undefined') || (typeof connectDragSource === 'undefined') || (typeof connectDragPreview === 'undefined') || (typeof connectDropTarget === 'undefined')) {
            throw new RuntimeException('unexpected');
        }

        const opacity = isDragging ? 0 : 1;

        const outsideOfDropTarget = isDragging && !isOverDragTarget;

        const getManageStorageAreaListItemContents = () : Array<JSX.Element> => {
            const manageStorageAreaListItemContents = [];

            manageStorageAreaListItemContents.push(
                connectDragSource(
                    <div className={ `${ outsideOfDropTarget ? 'outside-of-drop-target' : `${ this.classNamePrefix }-handle` }` }>
                        <span className="bevicon bevico-drag-handle-vertical" />
                    </div>
                )
            );

            manageStorageAreaListItemContents.push(
                <ValidationInput
                    type="text"
                    label={ null }
                    hintText="Name this Storage Area"
                    value={ storageAreaName }
                    autoFocus={ false }
                    autoComplete={ null }
                    isValid={ true }
                    isDisabled={ false }
                    errorMessage=""
                    inputClassName=""
                    handleEnterClick={ this.doNothing }
                    handleChange={ this.handleInputChange }
                    handleBlur={ this.doNothing }
                    handleFocus={ null }
                />
            );

            manageStorageAreaListItemContents.push(
                <div
                    className={ `${ this.classNamePrefix }-remove-button` }
                    onClick={ handleRemoveButtonClick }
                >
                    <span className="bevicon bevico-remove-inverse" />
                </div>
            );

            return manageStorageAreaListItemContents;
        };

        const generatePreview = (type : string, item : any, style : React.CSSProperties) : JSX.Element => {
            return (
                <div
                    key="preview"
                    className={ `manage-storage-area-list-item ${ isFocusedRow ? 'focused-row' : ''} ${ outsideOfDropTarget ? 'outside-of-drop-target' : ''}` }
                    style={ { ...style, zIndex : 200 } }
                >
                    { getManageStorageAreaListItemContents() }
                </div>
            );
        };

        const manageStorageAreaListItem = connectDropTarget(connectDragPreview(
            <div
                key={ storageAreaId.getValue() }
                className={ `manage-storage-area-list-item ${ isFocusedRow ? 'focused-row' : ''} ${ outsideOfDropTarget ? 'outside-of-drop-target' : ''}` }
                style={ { opacity } }
            >
                { getManageStorageAreaListItemContents() }
            </div>
        ));

        if (isDragging) {
            return (
                <div
                    key={ storageAreaId.getValue() }
                >
                    { manageStorageAreaListItem }
                    <Preview generator={ generatePreview }/>
                </div>
            );
        }

        return manageStorageAreaListItem;
    }

    private readonly handleInputChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const {
            handleNameChange,
        } = this.props;

        const inputElement : HTMLInputElement = event.currentTarget;
        handleNameChange(inputElement.value);
    }

    private readonly doNothing = () => null;
}

const dragSource : DragSourceSpec<IManageStorageAreaListItemProps> = {
    beginDrag(props : IManageStorageAreaListItemProps) {
        return {
            storageAreaId: props.storageAreaId,
            onDragCancel: props.onDragCancel,
        };
    },
    endDrag(props : IManageStorageAreaListItemProps, monitor : DragSourceMonitor) {
        const onDragCancel : () => void = (monitor.getItem() as any).onDragCancel;
        const didDrop = monitor.didDrop();

        if (!didDrop) {
            onDragCancel();
        }
    },
};

const sourceCollect : DragSourceCollector = (connect : DragSourceConnector, monitor : DragSourceMonitor) => {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview : connect.dragPreview(),
        isDragging: monitor.isDragging(),
    };
};

const dropTarget : DropTargetSpec<IManageStorageAreaListItemProps> = {
    drop(props : IManageStorageAreaListItemProps, monitor : DropTargetMonitor) {
        props.onDrop(props.storageAreaId);
    },
    hover(props : IManageStorageAreaListItemProps, monitor : DropTargetMonitor) {
        const dragStorageAreaId = (monitor.getItem() as any).storageAreaId;
        const hoverStorageAreaId = props.storageAreaId;

        if (dragStorageAreaId.equals(hoverStorageAreaId)) {
          return;
        }

        props.onDragHover(dragStorageAreaId, hoverStorageAreaId);
    }
};

const targetCollect : DropTargetCollector = (connect : DropTargetConnector, monitor : DropTargetMonitor) => {
    return {
        connectDropTarget: connect.dropTarget(),
        isOverDragTarget: monitor.isOver(),
    };
};

const ITEM_TYPE = 'ManageStorageAreaListItem';
export const ManageStorageAreaListItem = DropTarget(ITEM_TYPE, dropTarget, targetCollect)(DragSource(ITEM_TYPE, dragSource, sourceCollect)(ManageStorageAreaListItemWithoutDragAndDrop));
