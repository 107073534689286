import CoreTimeModel from 'gen-thrift/core_time_Model_types';
// TODO Use different time model

import { PublicContentUrl } from './PublicContentUrl';
import { PublicContentViewEventTypeEnum } from './PublicContentViewEventTypeEnum';

export class PublicContentViewEvent {
    private eventTime : CoreTimeModel.TimestampWithMillisecondPrecision;
    private referrer : string;
    private eventType : PublicContentViewEventTypeEnum;
    private publicContentUrlParameters : { [k : string] : Array<string> };
    private publicContentUrl : PublicContentUrl;

    constructor(
        eventTime : CoreTimeModel.TimestampWithMillisecondPrecision,
        referrer : string,
        eventType : PublicContentViewEventTypeEnum,
        publicContentUrlParameters : { [k : string] : Array<string> },
        publicContentUrl : PublicContentUrl,
    ) {
        this.eventTime = eventTime;
        this.referrer = referrer;
        this.eventType = eventType;
        this.publicContentUrlParameters = publicContentUrlParameters;
        this.publicContentUrl = publicContentUrl;
    }

    public getEventTime() : CoreTimeModel.TimestampWithMillisecondPrecision {
        return this.eventTime;
    }

    public getReferrer() : string {
        return this.referrer;
    }

    public getEventType() : PublicContentViewEventTypeEnum {
        return this.eventType;
    }

    public getPublicContentUrlParameters() : { [k : string] : Array<string> } {
        return this.publicContentUrlParameters;
    }

    public getPublicContentUrl() : PublicContentUrl {
        return this.publicContentUrl;
    }
}
