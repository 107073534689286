import React from 'react';

import { InventoryCount } from 'api/InventoryCount/model/InventoryCount';
import { InventoryCountId } from 'api/InventoryCount/model/InventoryCountId';
import { InventoryCountMetadata } from 'api/InventoryCount/model/InventoryCountMetadata';
import { ProductCount } from 'api/InventoryCount/model/ProductCount';
import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { productCountUtils } from 'api/Product/utils/productCountUtils';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { decimalToNumber } from 'shared/utils/decimalUtils';
import { numberUtils } from 'shared/utils/numberUtils';

export interface IItemCardHistoryInventory {
    productId : ProductId;
    product : Product;
    inventoryCountId : InventoryCountId;
    inventoryCount : InventoryCount;
    inventoryCountMetadata : InventoryCountMetadata;
}

interface IStorageAreaRowData {
    storageAreaId : StorageAreaId;
    productCount : ProductCount | null;
}

export class ItemCardHistoryInventory extends React.Component<IItemCardHistoryInventory, object> {
    public render() {
        const {
            productId,
            product,
            inventoryCountId,
            inventoryCount,
            inventoryCountMetadata,
        } = this.props;

        const storageAreaRowData = new Array<IStorageAreaRowData>();
        const inventoryConfiguration = inventoryCount.getInventoryConfiguration();
        const sortedStorageAreaIds = inventoryConfiguration.getSortedStorageAreaIds();
        const productIdListsByStorageAreaId = inventoryConfiguration.getSortedProductIdListsByStorageAreaId();
        const allProductCounts = new Array<ProductCount>();
        sortedStorageAreaIds.forEach((storageAreaId : StorageAreaId) => {
            const productIdListForStorageAreaId = productIdListsByStorageAreaId.get(storageAreaId);
            if (typeof productIdListForStorageAreaId === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            const productCountEventsByProductId = inventoryCount.getProductCountEventsByProductIdByStorageAreaId().get(storageAreaId);
            if (typeof productCountEventsByProductId === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            const productCountEvent = productCountEventsByProductId.get(productId);

            if (typeof productCountEvent === 'undefined') {
                const productIsInStorageArea = productIdListForStorageAreaId.find((productIdInStorageArea) : boolean => {
                    return productIdInStorageArea.equals(productId);
                });

                if (productIsInStorageArea) {
                    storageAreaRowData.push({
                        storageAreaId,
                        productCount: null,
                    });
                }
            } else {
                const productCount = productCountUtils.resolveProductCountUnit(productCountEvent.getProductCount(), product.getPackagingsAndMappings().getMappings());
                allProductCounts.push(productCount);
                storageAreaRowData.push({
                    storageAreaId,
                    productCount
                });
            }
        });

        const packagingsAndMappings = product.getPackagingsAndMappings();
        let totalProductCount = productCountUtils.sumProductCounts(allProductCounts, packagingsAndMappings);
        totalProductCount = productCountUtils.convertProductCountUnit(totalProductCount, product.getPreferredReportingUnit(), product.getPackagingsAndMappings());
        const totalCountDecimal = totalProductCount.getCount();
        const totalCount = totalCountDecimal ? decimalToNumber(totalCountDecimal) : 0;
        let totalProductCountUnit = totalProductCount.getProductQuantityUnit();
        // If there are no product counts at all, the above value will default to CASE.  Instead we want a default of CONTAINER so as to stay consistent with rows below.
        if (allProductCounts.length === 0) {
            totalProductCountUnit = PackagingUtils.getContainerPackagingId(packagingsAndMappings.getPackaging());
        }
        const totalPackagingString = PackagingUtils.getPackagingDisplayTextForProductQuantityUnit(packagingsAndMappings, totalProductCountUnit, numberUtils.isPlural(totalCount));

        const finalizedStatus = inventoryCountMetadata.getFinalizationMetadata() ? 'Finalized' : 'Draft';
        const isPartial = inventoryCountMetadata.getInventoryCountType().getValue() === 'partial';

        const inventoryLink = `/inventory/detail/view/${ inventoryCountId.getValue() }/r/${ window.GLOBAL_RETAILER_ID }`;

        return (
            <div className="activity-item item-history-inventory" key={ inventoryCountId.getValue() }>
                <div className="top-row">
                    <span className="bevicon bevico-assignment" />
                    <div className="title">
                        <a className="link" href={ inventoryLink }>Inventory</a>
                    </div>
                    { isPartial &&
                        <div className="activity-status partial">Partial</div>
                    }
                    <div className="activity-status">{ finalizedStatus }</div>
                </div>
                <div className="storage-area-row total-row">
                    <div className="item-quantity">
                        { totalCount } { totalPackagingString }
                    </div>
                    <div className="storage-area-name">
                        Total Count
                    </div>
                </div>
                { storageAreaRowData.map((rowData : IStorageAreaRowData) => {

                    let productCount = 0;
                    if (rowData.productCount) {
                        const productCountDecimal = rowData.productCount.getCount();
                        if (productCountDecimal) {
                            productCount = decimalToNumber(productCountDecimal);
                        }
                    }

                    let packagingUnit : ProductQuantityUnit = PackagingUtils.getContainerPackagingId(packagingsAndMappings.getPackaging());
                    if (rowData.productCount) {
                        packagingUnit = rowData.productCount.getProductQuantityUnit();
                    }
                    const packagingString = PackagingUtils.getPackagingDisplayTextForProductQuantityUnit(packagingsAndMappings, packagingUnit, numberUtils.isPlural(productCount));

                    const storageArea = inventoryConfiguration.getStorageAreasById().get(rowData.storageAreaId);
                    if (typeof storageArea === 'undefined') {
                        throw new RuntimeException('unexpected');
                    }

                    return (
                        <div key={ rowData.storageAreaId.getValue() } className="storage-area-row">
                            <div className="item-quantity">
                                { productCount } { packagingString }
                            </div>
                            <div className="storage-area-name">
                                { storageArea.getName() }
                            </div>
                        </div>
                    );
                }) }
            </div>
        );
    }
}
