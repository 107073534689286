import { PublicContentUrl } from 'api/PublicContent/model/PublicContentUrl';

interface IQueryParametersMap {
    [k : string] : Array<string>;
}

export const URLStringUtils = {
    // Cheezy 04/05/2017
    // Adapted from http://stackoverflow.com/a/21553982
    getLocation: (
        urlString : string
    ) => {
        const match = urlString.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);

        return match && {
            protocol: match[1],
            host: match[2],
            hostname: match[3],
            port: match[4],
            pathname: match[5],
            search: match[6],
            hash: match[7]
        };
    },

    // Cheezy 04/05/2017
    // Adapted from https://github.com/unshiftio/querystringify
    parseQueryString: (
        queryString : string
    ) : IQueryParametersMap => {
        const parser = /([^=?&]+)=?([^&]*)/g;
        const result : { [k : string] : Array<string> } = {};

        function decode(input : string) {
            return decodeURIComponent(input.replace(/\+/g, ' '));
        }

        let part : any = parser.exec(queryString);
        while (part) {
            const key = decode(part[1]);

            if (result[key]) {
                result[key] = result[key].concat([decode(part[2])]);
            } else {
                result[key] = [decode(part[2])];
            }
            part = parser.exec(queryString);
        }

        return result;
    },

    // https://stackoverflow.com/a/9870540/3100850
    getQueryParameterFromUrl: (parameter : string) : string => {
        return decodeURIComponent(
            window.location.search.replace(
                new RegExp(
                    '^(?:.*[&\\?]' +
                    encodeURIComponent(parameter).replace(/[\.\+\*]/g, '\\$&') +
                    '(?:\\=([^&]*))?)?.*$',
                'i'), '$1'));
    },

    // Cheezy 04/05/2017
    // Adapted from https://github.com/unshiftio/querystringify
    queryStringify: (
        queryParameters : IQueryParametersMap,
        excludePrefix? : boolean,
    ) : string => {
        const pairs : Array<string> = [];

        Object.keys(queryParameters).forEach((key : string) => {
            queryParameters[key].forEach((value : string) => {
                pairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
            });
        });

        const prefix = '?';
        return pairs.length ? (!!excludePrefix ? pairs.join('&') : prefix + pairs.join('&')) : '';
    },

    getUrlString: (
        publicContentUrl : PublicContentUrl
    ) : string => {
        return 'https://' + publicContentUrl.getHostname() + publicContentUrl.getPath();
    },

    replaceOrRemoveURLParameter: (
        urlString : string,
        key : string,
        value : string | null,
    ) : string => {
        const tempArray = urlString.split('?');
        const paramString = tempArray[1];

        let returnUrl = tempArray[0];
        let delimiter = '?';
        if (paramString) {
            const params = paramString.split('&');
            params.forEach((param, i) => {
                if (param.split('=')[0].toLowerCase() !== key.toLowerCase()) {
                    returnUrl += delimiter + param;
                    delimiter = '&';
                }
            });
        }

        if (!(value === null)) {
            returnUrl += delimiter + key + '=' + value;
        }

        return returnUrl;
    },
};
