import { CartItemId } from 'api/Cart/model/CartItemId';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { ProductDistributorAssociationIdentifier } from 'api/Product/model/ProductDistributorAssociationIdentifier';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';

export class CartItem {
    private productDistributorAssociationIdentifier : ProductDistributorAssociationIdentifier;

    constructor (
        private readonly cartItemId : CartItemId,
        private readonly productId : ProductId,
        private readonly distributorId : DistributorId | null,
        private readonly productQuantityUnit : ProductQuantityUnit,
        private readonly quantityInUnits : number,
    ) {
        this.productDistributorAssociationIdentifier = new ProductDistributorAssociationIdentifier(productId, productQuantityUnit, distributorId);
    }

    public getCartItemId() : CartItemId {
        return this.cartItemId;
    }

    public getProductId() : ProductId {
        return this.productId;
    }

    public getDistributorId() : DistributorId | null {
        return this.distributorId;
    }

    public getProductQuantityUnit() : ProductQuantityUnit {
        return this.productQuantityUnit;
    }

    public getQuantityInUnits() : number {
        return this.quantityInUnits;
    }

    public getProductDistributorAssociationIdentifier() : ProductDistributorAssociationIdentifier {
        return this.productDistributorAssociationIdentifier;
    }

    public equals(other : any) : boolean {
        if (!(other instanceof CartItem)) {
            return false;
        }
        const thisDistributor = this.getDistributorId();
        const otherDistributor = other.getDistributorId();

        if ((thisDistributor !== null && !thisDistributor.equals(otherDistributor)) ||
            (otherDistributor !== null && !otherDistributor.equals(thisDistributor))
        ) {
            return false;
        }

        return this.getProductId().equals(other.getProductId()) &&
            this.getCartItemId().equals(other.getCartItemId()) &&
            this.getQuantityInUnits() === other.getQuantityInUnits() &&
            PackagingUtils.productQuantityUnitsAreEqual(this.getProductQuantityUnit(), other.getProductQuantityUnit());
    }
}
