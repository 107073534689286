import { DateTime } from 'shared/models/DateTime';
import { IAction, IActionCreatorsMapObject } from 'shared/models/IAction';
import { MonthOfYear } from 'shared/models/MonthOfYear';
import { IBevSpotDateTimePickerState } from './IBevSpotDateTimePickerState';

export const ActionTypes = {
    INITIALIZE_DATE_TIME_PICKER: 'DATE_TIME_PICKER/INITIALIZE_DATE_TIME_PICKER',
    SET_HOUR_ERROR_MESSAGE: 'DATE_TIME_PICKER/SET_HOUR_ERROR_MESSAGE',
    SET_HOUR_IS_VALID: 'DATE_TIME_PICKER/SET_HOUR_IS_VALID',
    SET_HOUR_FORM_VALUE: 'DATE_TIME_PICKER/SET_HOUR_FORM_VALUE',
    SET_MINUTE_ERROR_MESSAGE: 'DATE_TIME_PICKER/SET_MINUTE_ERROR_MESSAGE',
    SET_MINUTE_IS_VALID: 'DATE_TIME_PICKER/SET_MINUTE_IS_VALID',
    SET_MINUTE_FORM_VALUE: 'DATE_TIME_PICKER/SET_MINUTE_FORM_VALUE',
    SET_SELECTED_DATE_TIME: 'DATE_TIME_PICKER/SET_SELECTED_DATE_TIME',
    SET_VISIBLE_MONTH: 'DATE_TIME_PICKER/SET_VISIBLE_MONTH',
    SET_VISIBLE_YEAR: 'DATE_TIME_PICKER/SET_VISIBLE_YEAR',
};
export namespace ActionInterfaces {
    export interface IInitializeDatetimePickerState extends IAction {
        payload : {
            dateTimePickerId : string
            initialState : IBevSpotDateTimePickerState
        };
    }
    export interface ISetErrorMessage extends IAction {
        payload : {
            dateTimePickerId : string
            errorMessage : string | null
        };
    }
    export interface ISetIsValid extends IAction {
        payload : {
            dateTimePickerId : string
            isValid : boolean
        };
    }
    export interface ISetFormValue extends IAction {
        payload : {
            dateTimePickerId : string
            value : string | null
        };
    }
    export interface ISetSelectedDateTime extends IAction {
        payload : {
            dateTimePickerId : string
            selectedDateTime : DateTime
        };
    }
    export interface ISetVisibleMonth extends IAction {
        payload : {
            dateTimePickerId : string
            visibleMonth : MonthOfYear
        };
    }
    export interface ISetVisibleYear extends IAction {
        payload : {
            dateTimePickerId : string
            visibleYear : number
        };
    }
    export interface IDateTimePickerActionCreators extends IActionCreatorsMapObject {
        initializeDatetimePickerState(dateTimePickerId : string, initialState : IBevSpotDateTimePickerState) : IInitializeDatetimePickerState;
        setHourErrorMessage(dateTimePickerId : string, errorMessage : string | null) : ISetErrorMessage;
        setHourIsValid(dateTimePickerId : string, isValid : boolean) : ISetIsValid;
        setHourFormValue(dateTimePickerId : string, value : string | null) : ISetFormValue;
        setMinuteErrorMessage(dateTimePickerId : string, errorMessage : string | null) : ISetErrorMessage;
        setMinuteIsValid(dateTimePickerId : string, isValid : boolean) : ISetIsValid;
        setMinuteFormValue(dateTimePickerId : string, value : string | null) : ISetFormValue;
        setSelectedDateTime(dateTimePickerId : string, selectedDateTime : DateTime) : ISetSelectedDateTime;
        setVisibleMonth(dateTimePickerId : string, visibleMonth : MonthOfYear) : ISetVisibleMonth;
        setVisibleYear(dateTimePickerId : string, visibleYear : number) : ISetVisibleYear;
    }
}
const initializeDatetimePickerState = (dateTimePickerId : string, initialState : IBevSpotDateTimePickerState) => ({
    payload: {
        dateTimePickerId,
        initialState,
    },
    type: ActionTypes.INITIALIZE_DATE_TIME_PICKER,
});
const setHourErrorMessage = (dateTimePickerId : string, errorMessage : string | null) : ActionInterfaces.ISetErrorMessage => ({
    payload: {
        dateTimePickerId,
        errorMessage,
    },
    type: ActionTypes.SET_HOUR_ERROR_MESSAGE,
});
const setHourIsValid = (dateTimePickerId : string, isValid : boolean) : ActionInterfaces.ISetIsValid => ({
    payload: {
        dateTimePickerId,
        isValid,
    },
    type: ActionTypes.SET_HOUR_IS_VALID,
});
const setHourFormValue = (dateTimePickerId : string, value : string | null) : ActionInterfaces.ISetFormValue => ({
    payload: {
        dateTimePickerId,
        value,
    },
    type: ActionTypes.SET_HOUR_FORM_VALUE,
});
const setMinuteErrorMessage = (dateTimePickerId : string, errorMessage : string | null) : ActionInterfaces.ISetErrorMessage => ({
    payload: {
        dateTimePickerId,
        errorMessage,
    },
    type: ActionTypes.SET_MINUTE_ERROR_MESSAGE,
});
const setMinuteIsValid = (dateTimePickerId : string, isValid : boolean) : ActionInterfaces.ISetIsValid => ({
    payload: {
        dateTimePickerId,
        isValid,
    },
    type: ActionTypes.SET_MINUTE_IS_VALID,
});
const setMinuteFormValue = (dateTimePickerId : string, value : string | null) : ActionInterfaces.ISetFormValue => ({
    payload: {
        dateTimePickerId,
        value,
    },
    type: ActionTypes.SET_MINUTE_FORM_VALUE,
});
const setSelectedDateTime = (dateTimePickerId : string, selectedDateTime : DateTime) : ActionInterfaces.ISetSelectedDateTime => ({
    payload: {
        dateTimePickerId,
        selectedDateTime,
    },
    type: ActionTypes.SET_SELECTED_DATE_TIME,
});
const setVisibleMonth = (dateTimePickerId : string, visibleMonth : MonthOfYear) => ({
    payload: {
        dateTimePickerId,
        visibleMonth,
    },
    type: ActionTypes.SET_VISIBLE_MONTH,
});
const setVisibleYear = (dateTimePickerId : string, visibleYear : number) => ({
    payload: {
        dateTimePickerId,
        visibleYear,
    },
    type: ActionTypes.SET_VISIBLE_YEAR,
});
export const DateTimePickerActions : ActionInterfaces.IDateTimePickerActionCreators = {
    initializeDatetimePickerState,
    setHourErrorMessage,
    setHourIsValid,
    setHourFormValue,
    setMinuteErrorMessage,
    setMinuteIsValid,
    setMinuteFormValue,
    setSelectedDateTime,
    setVisibleMonth,
    setVisibleYear,
};
