import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';

import { IAction, IActionCreatorsMapObject } from 'shared/models/IAction';

export const ActionTypes = {
    SET_COUNT : 'EDIT_PAR_DIALOG/SET_COUNT',
    SET_PRODUCT_QUANTITY_UNIT : 'EDIT_PAR_DIALOG/SET_PRODUCT_QUANTITY_UNIT',
};

export namespace ActionInterfaces {
    export interface ISetCount extends IAction {
        payload : {
            count : number | null
        };
    }

    export interface ISetProductQuantityUnit extends IAction {
        payload : {
            productQuantityUnit : ProductQuantityUnit
        };
    }

    export interface IInventoryCountActionCreatorsMapObject extends IActionCreatorsMapObject {
        setCount : (
            count : number | null,
        ) => ISetCount;
        setProductQuantityUnit : (
            productQuantityUnit : ProductQuantityUnit,
        ) => ISetProductQuantityUnit;
    }
}

export type ISetCountActionCreator = (count : number | null) => ActionInterfaces.ISetCount;
export type ISetProductQuantityUnitActionCreator = (unit : ProductQuantityUnit) => ActionInterfaces.ISetProductQuantityUnit;

const setCount : ISetCountActionCreator = (
    count : number | null
) : ActionInterfaces.ISetCount => ({
    payload: {
        count
    },
    type: ActionTypes.SET_COUNT
});

const setProductQuantityUnit : ISetProductQuantityUnitActionCreator = (
    productQuantityUnit : ProductQuantityUnit
) : ActionInterfaces.ISetProductQuantityUnit => ({
    payload: {
        productQuantityUnit
    },
    type: ActionTypes.SET_PRODUCT_QUANTITY_UNIT
});

export const EditParDialogActions = {
    setCount,
    setProductQuantityUnit,
};
