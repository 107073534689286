import { StringValueSet } from 'api/Core/StringValueSet';
import { IEstablishmentService } from 'api/Establishment/interfaces/IEstablishmentService';
import { EstablishmentId } from 'api/Establishment/model/EstablishmentId';
import { LocationId } from 'api/Location/model/LocationId';
import { AjaxUtils } from 'shared/utils/ajaxUtils';

export class EstablishmentServiceImpl implements IEstablishmentService {
    public createEstablishment(
        name : string,
    ) : Promise<EstablishmentId> {
        const queryParameters = {
            name,
        };

        return AjaxUtils.ajaxPut(urlWithoutRetailerId('api:establishment'), null, queryParameters)
        .then((response) => {
            return Promise.resolve(new EstablishmentId(response.establishment_id));
        })
        .catch((error : Error) => {
            return Promise.reject(error);
        });
    }

    public getLocationIdsInEstablishment(
        establishmentId : EstablishmentId
    ) : Promise<StringValueSet<LocationId>> {
        const queryParameters = {
            establishment_id: establishmentId.getValue(),
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:retailers_by_establishment'), queryParameters)
        .then((response) => {
            const locationIds = new StringValueSet<LocationId>();
            response.forEach((locationId : string) => {
                locationIds.add(new LocationId(locationId));
            });
            return Promise.resolve(locationIds);
        })
        .catch((error : Error) => {
            return Promise.reject(error);
        });
    }
}
