import * as d3 from 'd3';

interface IDomainRange {
    minimum : number;
    maximum : number;
}

export class LinearScale {
    public scaleLinear : d3.ScaleLinear<number, number>;

    constructor(
        scaleLinear : d3.ScaleLinear<number, number>
    ) {
        this.scaleLinear = scaleLinear;
    }

    /* d3 linear.ticks() defaults to 10 tick marks, and returns "approximately" the number of ticks when given a count.
        This accounts for breaking ranges into reasonable tick marks, with clean numbers, so d3 will not
        always give the exact number of tick marks passed in as 'count'
    */
    public getTicks(count : number | null) : Array<number> {
        if (count) {
            return this.scaleLinear.ticks(count);
        }
        return this.scaleLinear.ticks();
    }

    public getDomainValues() : IDomainRange {
        const domainValues : Array<number> = this.scaleLinear.domain();

        if (domainValues.length !== 2) {
            throw Error('domain returned invalid result');
        }
        const domainRange : IDomainRange = {
            minimum: domainValues[0],
            maximum: domainValues[1],
        };

        return domainRange;
    }

}
