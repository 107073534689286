import { IUserInfoReader } from '../interfaces/IUserInfoReader';
import { UserInfo } from '../models/UserInfo';

export class UserInfoManagerImpl implements IUserInfoReader {
    public getUserInfo() : UserInfo {
        // Based on project @ amiunique.org

        const getCanvasData = () => {
            // Gets base64 encoded canvas pixel data
            // Takes advantage of how rendering may be different on
            // different machines / browsers
            // text and backdrops are used to force use of
            // anti-aliasing and font rendering -- which
            // are expected to be especially decent candidates
            // for difference in implementation / rounding on
            // pixels.
            let canvasData : string;
            try {
                const canvas = document.createElement('canvas');
                canvas.height = 20;
                canvas.width = 180;

                const canvasContext = canvas.getContext('2d');

                if (canvasContext !== null) {
                    canvas.style.display = 'inline';
                    canvasContext.textBaseline = 'alphabetic';
                    canvasContext.fillStyle = '#f60';
                    canvasContext.fillRect(125, 8, 42, 10);
                    canvasContext.fillStyle = '#069';
                    canvasContext.font = '11pt no-real-font-123';
                    canvasContext.fillText('Cwm fjodbnkglphs vxtquiz,\ud83d\ude03', -10, 15);
                    canvasContext.fillStyle = 'rgba(102, 204, 0, 0.7)';
                    canvasContext.font = '18pt Arial';
                    canvasContext.fillText('Cwm vext quiz, \ud83d\ude03', -4, 25);
                    canvasData = canvas.toDataURL();
                } else {
                    canvasData = 'Not supported';
                }
            } catch (e) {
                canvasData = 'Not supported';
            }

            return { canvasData };
        };

        const getWebGLData = () => {
            // Gets information tied to webGL usage
            // Outputs a vendor (manufacturer)
            // and a renderer (cpu graphics or GPU) utilised
            // by the browser
            let webGLVendor : string;
            let webGLRenderer : string;
            try {
                const canvas = document.createElement('canvas');
                const ctx : any = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');

                if (ctx.getSupportedExtensions().indexOf('WEBGL_debug_renderer_info') >= 0) {
                    webGLVendor = ctx.getParameter(ctx.getExtension('WEBGL_debug_renderer_info').UNMASKED_VENDOR_WEBGL);
                    webGLRenderer = ctx.getParameter(ctx.getExtension('WEBGL_debug_renderer_info').UNMASKED_RENDERER_WEBGL);
                } else {
                    webGLVendor = 'Not supported';
                    webGLRenderer = 'Not supported';
                }
            } catch (e) {
                webGLVendor = 'Not supported';
                webGLRenderer = 'Not supported';
            }

            return {
                webGLVendor,
                webGLRenderer,
            };
        };

        const getPluginData = () => {
            // Gets a list of plugins made available to
            // the browser. Ex: Adobe Acrobat.
            // Doesn't work on seriously old versions of
            // IE
            let pluginStr = '';
            try {
                const plugins = window.navigator.plugins;
                const pluginList = [];

                for (let i = 0; i < plugins.length; i++) {
                    pluginList[i] = plugins[i].name + '; ' +
                        plugins[i].description + '; ' +
                        plugins[i].filename + '. ';
                }

                pluginList.sort();

                for (let i = 0; i < plugins.length; i++) {
                    pluginStr += 'Plugin ' + i + ': ' + pluginList[i];
                }

                // Clip to reasonable length
                pluginStr = pluginStr;

            } catch (e) {
                // do nothing
            }

            return { plugins: pluginStr };
        };

        const getStorageData = () => {
            // Test if local / session storage
            // is allowed by the browser

            let localStorageAllowed : string;
            let sessionStorageAllowed : string;

            const localStorageAllowedCheck : string = 'localStorageAllowedCheck';

            // Random garbage location is just to avoid possibility
            // of collision with future storage attempts
            try {
                localStorage[localStorageAllowedCheck] = 'test';
                sessionStorage[localStorageAllowedCheck] = 'test';
            } catch (e) {
                // do nothing
            }

            try {
                if (localStorage[localStorageAllowedCheck] === 'test') {
                    localStorageAllowed = 'yes';
                } else {
                    localStorageAllowed = 'no';
                }
            } catch (e) {
                localStorageAllowed = 'no';
            }

            try {
                if (sessionStorage[localStorageAllowedCheck] === 'test') {
                    sessionStorageAllowed = 'yes';
                } else {
                    sessionStorageAllowed = 'no';
                }
            } catch (e) {
                sessionStorageAllowed = 'no';
            }

            if (localStorageAllowed) {
                localStorage.removeItem(localStorageAllowedCheck);
            }

            return {
                localStorageAllowed,
                sessionStorageAllowed,
            };
        };

        const getCookieAllowed = () => {
            // Test if cookies are enabled
            const cookieAllowed = window.navigator.cookieEnabled ? 'yes' : 'no';
            return { cookieAllowed };
        };

        const getPlatformData = () => {
            // Find out the OS of the device
            return { platform: String(window.navigator.platform) };
        };

        const getDoNotTrackData = () => {
            // Get doNotTrack browser attribute
            let doNotTrack : string;
            const navigator : any = window.navigator;

            if (navigator.doNotTrack !== null && navigator.doNotTrack !== 'unspecified') {
                if (navigator.doNotTrack === '1' || navigator.doNotTrack === 'yes') {
                    doNotTrack = 'yes';
                } else {
                    doNotTrack = 'no';
                }
            } else {
                doNotTrack = 'NC';
            }

            return { doNotTrack };
        };

        const getTimezoneData = () => {
            // Find out the timezone of the machine
            return { timezone: String(new Date().getTimezoneOffset()) };
        };

        const getResolutionData = () => {
            // Get screen resolution
            return { resolution: window.screen.width + 'x' + window.screen.height + 'x' + window.screen.colorDepth };
        };

        const truncateString = (value : string) : string => {
            return value.substring(0, 4096);
        };

        return {
            canvasData: truncateString(getCanvasData().canvasData),
            webGLVendor: truncateString(getWebGLData().webGLVendor),
            webGLRenderer: truncateString(getWebGLData().webGLRenderer),
            plugins: truncateString(getPluginData().plugins),
            localStorageAllowed: truncateString(getStorageData().localStorageAllowed),
            sessionStorageAllowed: truncateString(getStorageData().sessionStorageAllowed),
            cookieAllowed: truncateString(getCookieAllowed().cookieAllowed),
            platform: truncateString(getPlatformData().platform),
            doNotTrack: truncateString(getDoNotTrackData().doNotTrack),
            timezone: truncateString(getTimezoneData().timezone),
            resolution: truncateString(getResolutionData().resolution),
        };
    }
}
