import React from 'react';

import { StringValueMap } from 'api/Core/StringValueMap';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { BevSpotDateTimePickerType } from 'shared/components/BevSpotDateTimePicker/BevSpotDateTimePicker';
import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { PopupDateTimePicker } from 'shared/components/PopupDateTimePicker';
import { Select2DropdownMenu } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { DateTime } from 'shared/models/DateTime';
import { IFormValue } from 'shared/models/FormValue';
import { OrderedProductAmountList } from '../models/OrderedProductAmountList';
import { TransferListPanelMobileHeader } from './TransferListPanelMobileHeader';
import { TransferListTable } from './TransferListTable';

interface ITransferListPanelMobile {
    shouldUpdate : boolean;
    title : string;
    orderedProductAmountList : OrderedProductAmountList;
    productsById : StringValueMap<ProductId, Product>;
    note : string | null;
    typeInput : null | {
        sortedOptions : Array<IOption>;
        currentOption : IOption;
        onOptionSelect : (optionValue : string) => void;
        onCreateCustomOption : ((optionText : string) => void) | null;
    };
    dateTimePicker : {
        dateTimePickerShown : boolean;
        setDateTimePickerShown : (isShown : boolean) => void;
        initialDateTime : DateTime;
        setDateTime : (dateTime : DateTime) => void;
    };
    orderedProductAmountQuantityByListElementIdForm : { [listElementId : string] : IFormValue<string> };
    panelIsOpen : boolean;
    setQuantity(addedItemId : string, quantity : string) : void;
    validateQuantity(addedItemId : string, quantity : string) : void;
    removeRow(addedItemId : string, productId : ProductId) : void;
    setNote(note : string) : void;
    setPanelIsOpen(open : boolean) : void;
}
export class TransferListPanelMobile extends React.Component<ITransferListPanelMobile, object> {
    constructor(props : ITransferListPanelMobile) {
        super(props);

        this.handleNoteChange = this.handleNoteChange.bind(this);
    }

    public shouldComponentUpdate(nextProps : ITransferListPanelMobile) {
        return nextProps.shouldUpdate;
    }

    public render() {
        const {
            title,
            productsById,
            orderedProductAmountList,
            orderedProductAmountQuantityByListElementIdForm,
            panelIsOpen,
            validateQuantity,
            note,
            typeInput,
            setQuantity,
            removeRow,
            setPanelIsOpen,
            dateTimePicker,
        } = this.props;

        const productAmountIds = orderedProductAmountList.getListElementIds();
        const productAmountCount = productAmountIds.length; // TODO verify no quantity = null anymore...
        return (
            <div className={ panelIsOpen ? ' transfer-list-panel-mobile transfer-list-panel-mobile-open' : 'transfer-list-panel-mobile' }>
                <TransferListPanelMobileHeader
                    title={ title }
                    count={ productAmountCount }
                    panelIsOpen={ panelIsOpen }
                    setPanelIsOpen={ setPanelIsOpen }
                />
                {
                    panelIsOpen &&
                    <div className="col-intable transfer-list-panel search-result-list-table panel-expanded">
                        <TransferListTable
                            productsById={ productsById }
                            orderedProductAmountList={ orderedProductAmountList }
                            orderedProductAmountQuantityByListElementIdForm={ orderedProductAmountQuantityByListElementIdForm }
                            validateQuantity={ validateQuantity }
                            setQuantity={ setQuantity }
                            removeRow={ removeRow }
                        />
                        <div className="note-row ctable-row">
                            <div className="col-row">
                                <div className="cell col-xs-2 cell-ptb-md">
                                    <span className="note-row-label">Date</span>
                                </div>
                                <div className="cell col-xs-10 cell-ptb-md">
                                    <PopupDateTimePicker
                                        dateTimePickerIsShown={ dateTimePicker.dateTimePickerShown }
                                        setDateTimePickerIsShown={ dateTimePicker.setDateTimePickerShown }
                                        dateTimePickerProps={ {
                                            buttons: [],
                                            initialDateTime: dateTimePicker.initialDateTime,
                                            setDateTime: dateTimePicker.setDateTime,
                                            type: BevSpotDateTimePickerType.POPUP,
                                            uniqueId: 'create-transfer-report',
                                            shouldShowTime: true,
                                        } }
                                    />
                                </div>
                            </div>
                            { typeInput &&
                                <div className="col-row">
                                    <div className="cell col-xs-2 cell-ptb-md">
                                        <span className="note-row-label">Type</span>
                                    </div>
                                    <div className="cell col-xs-10 cell-ptb-md">
                                        <Select2DropdownMenu
                                            isSearchable={ true }
                                            selectedOptionHeaderShown={ true }
                                            sortedOptionsAndLabelName={ [[null, typeInput.sortedOptions]] }
                                            onOptionSelect={ typeInput.onOptionSelect }
                                            selectedOption={ typeInput.currentOption }
                                            placeholderText=""
                                            hasCreateCustomOption={ true }
                                            createCustomOption={ typeInput.onCreateCustomOption }
                                            customOptionGroupLabel={ null }
                                            buttonShouldDisplaySelectedLabel={ true }
                                            emptyState={ null }
                                            createCustomOptionButtonLabel={ null }
                                        />
                                    </div>
                                </div>
                            }
                            <div className="col-row">
                                <div className="cell col-xs-2 cell-ptb-md">
                                    <span className="note-row-label">Note</span>
                                </div>
                                <div className="cell col-xs-10 cell-ptb-md">
                                    <textarea
                                        defaultValue={ note === null ? '' : note }
                                        onBlur={ this.handleNoteChange }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }

    private handleNoteChange(event : React.FocusEvent<HTMLTextAreaElement>) : void {
        const input = event.currentTarget;
        return this.props.setNote(input.value);
    }
}
