//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';



var DrinkPriceToolExceptions = {};
export default DrinkPriceToolExceptions;
DrinkPriceToolExceptions.UnknownDrinkIdException = function(args) {
};
Thrift.inherits(DrinkPriceToolExceptions.UnknownDrinkIdException, Thrift.TException);
DrinkPriceToolExceptions.UnknownDrinkIdException.prototype.name = 'UnknownDrinkIdException';
DrinkPriceToolExceptions.UnknownDrinkIdException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolExceptions.UnknownDrinkIdException.prototype.write = function(output) {
  output.writeStructBegin('UnknownDrinkIdException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolExceptions.EmptyArgumentException = function(args) {
};
Thrift.inherits(DrinkPriceToolExceptions.EmptyArgumentException, Thrift.TException);
DrinkPriceToolExceptions.EmptyArgumentException.prototype.name = 'EmptyArgumentException';
DrinkPriceToolExceptions.EmptyArgumentException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolExceptions.EmptyArgumentException.prototype.write = function(output) {
  output.writeStructBegin('EmptyArgumentException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolExceptions.ArgumentArraysOfUnevenLengthException = function(args) {
};
Thrift.inherits(DrinkPriceToolExceptions.ArgumentArraysOfUnevenLengthException, Thrift.TException);
DrinkPriceToolExceptions.ArgumentArraysOfUnevenLengthException.prototype.name = 'ArgumentArraysOfUnevenLengthException';
DrinkPriceToolExceptions.ArgumentArraysOfUnevenLengthException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolExceptions.ArgumentArraysOfUnevenLengthException.prototype.write = function(output) {
  output.writeStructBegin('ArgumentArraysOfUnevenLengthException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

