import moment from 'moment-timezone';

import { AccountType } from 'api/Location/model/AccountType';
import { FreeTrialStatus } from 'api/Location/model/FreeTrialStatus';
import { FreeTrialType } from 'api/Location/model/FreeTrialType';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

type ActionItemNamesJSON = 'inventories' | 'orders' | 'sales_items';

interface IFreeTrialStatusJSON {
    is_in_free_trial : boolean;
    free_trial_type : 'action' | 'time';
    end_date : string | null;
    max_action_item_count_by_name : { [action in ActionItemNamesJSON] : number | null };
    current_action_item_count_by_name : { [action in ActionItemNamesJSON] : number };
}

export class LocationJSONToObjectSerializer {

    public getAccountType(
        accountTypeValue : string
    ) : AccountType {
        switch (accountTypeValue) {
            case 'food':
                return AccountType.FOOD;
            case 'beverage':
                return AccountType.BEVERAGE;
            default:
                throw new RuntimeException(`unexpected accountType: ${ accountTypeValue }`);
        }
    }

    public getFreeTrialStatus(
        freeTrialStatusObject : IFreeTrialStatusJSON,
    ) : FreeTrialStatus {
        let freeTrialType : FreeTrialType;
        switch (freeTrialStatusObject.free_trial_type) {
            case 'action':
                freeTrialType = FreeTrialType.ACTION;
                break;
            case 'time':
                freeTrialType = FreeTrialType.TIME;
                break;
            default:
                throw new RuntimeException(`unexpected freeTrialType: ${ freeTrialStatusObject.free_trial_type }`);
        }

        return new FreeTrialStatus(
            freeTrialStatusObject.is_in_free_trial,
            freeTrialType,
            freeTrialStatusObject.end_date ? moment.utc(freeTrialStatusObject.end_date) : null,
            {
                inventories: freeTrialStatusObject.max_action_item_count_by_name.inventories,
                orders: freeTrialStatusObject.max_action_item_count_by_name.orders,
                salesItems: freeTrialStatusObject.max_action_item_count_by_name.sales_items,
            },
            {
                inventories: freeTrialStatusObject.current_action_item_count_by_name.inventories,
                orders: freeTrialStatusObject.current_action_item_count_by_name.orders,
                salesItems: freeTrialStatusObject.current_action_item_count_by_name.sales_items,
            },
        );
    }
}
