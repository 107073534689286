import { IPOSIntegrationService } from 'api/Integration/interfaces/IPOSIntegrationService';
import { OmnivoreSupportedPosType } from 'api/Integration/model/IntegrationType';
import { OmnivoreIntegrationState } from 'api/Integration/model/OmnivoreIntegrationState';
import { LocationId } from 'api/Location/model/LocationId';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { ThunkAction } from 'redux-thunk';
import { IOmnivoreIntegrationState } from 'shared/components/PosIntegration/OmnivoreIntegrationReducers';
import { IAccountSessionReader } from 'shared/lib/account/interfaces/IAccountSessionReader';
import { integrationService } from 'shared/lib/manager';
import { IAction } from 'shared/models/IAction';

export interface IOmnivoreIntegrationStore {
    omnivoreIntegrationState : IOmnivoreIntegrationState;
}

export const ActionTypes = {
    SET_INTEGRATION_STATE: 'OMNIVORE_INTEGRATION/SET_INTEGRATION_STATE',
    SET_MODAL_ACCESS_TOKEN: 'OMNIVORE_INTEGRATION/SET_MODAL_ACCESS_TOKEN',
};

export namespace OmnivoreIntegrationActionInterfaces {
    export interface ISetIntegrationState extends IAction {
        payload : {
            integrationState : OmnivoreIntegrationState;
        };
    }

    export interface ISetModalAccessToken extends IAction {
        payload : {
            accessToken : string | null
        };
    }

    export interface IExtraArgs {
        services : IServices;
    }

    export interface IServices {
        userSessionReader : IAccountSessionReader<UserSessionId, UserAccountId>;
        integrationService : IPOSIntegrationService;
    }}

const setIntegrationState = (
    integrationState : OmnivoreIntegrationState
) : OmnivoreIntegrationActionInterfaces.ISetIntegrationState => ({
    payload : {
        integrationState,
    },
    type: ActionTypes.SET_INTEGRATION_STATE,
});

const setModalAccessToken = (
    accessToken : string | null
) : OmnivoreIntegrationActionInterfaces.ISetModalAccessToken => ({
    payload : {
        accessToken,
    },
    type : ActionTypes.SET_MODAL_ACCESS_TOKEN
});

const loadOmnivoreIntegrationState = (
    locationId : LocationId,
) : ThunkAction<Promise<OmnivoreIntegrationState>, IOmnivoreIntegrationStore, OmnivoreIntegrationActionInterfaces.IExtraArgs> => {
    return ((dispatch, getState, extraArgument) => {
        return extraArgument.services.integrationService.getOmnivoreIntegrationState(
            extraArgument.services.userSessionReader.getSessionId(),
            locationId
        ).then((integrationState) => {
            dispatch(setIntegrationState(integrationState));
            return integrationState;
        });
    });
};

const addIntegration = (
    locationId : LocationId,
    omnivoreLocationId : string,
    posType : OmnivoreSupportedPosType,
) : ThunkAction<Promise<void | Error>, IOmnivoreIntegrationStore, OmnivoreIntegrationActionInterfaces.IExtraArgs> => {
    return ((dispatch, getState, extraArgument) => {
        return extraArgument.services.integrationService.addOmnivoreIntegration(
            extraArgument.services.userSessionReader.getSessionId(),
            locationId,
            omnivoreLocationId,
            posType
        ).then(() => {
            dispatch(loadOmnivoreIntegrationState(locationId));
        });
    });
};

const removeIntegration = (
    locationId : LocationId
) : ThunkAction<Promise<void>, IOmnivoreIntegrationStore, OmnivoreIntegrationActionInterfaces.IExtraArgs> => {
    return ((dispatch, getState, extraArgument) => {
        return extraArgument.services.integrationService.removeIntegration(
            'omnivore',
            extraArgument.services.userSessionReader.getSessionId(),
            locationId,
        )
        .then(() => {
            dispatch(loadOmnivoreIntegrationState(locationId));
        });
    });
};

const retrieveAccessToken = (
    locationId : LocationId,
) : ThunkAction<Promise<void>, IOmnivoreIntegrationStore, OmnivoreIntegrationActionInterfaces.IExtraArgs> => {
    return ((dispatch, getState, extraArguments) => {
        const session = extraArguments.services.userSessionReader.getSessionId();

        return integrationService.retrieveAccessToken(
            session,
            locationId,
            "omnivore"
        ).then((accessToken : string | null) => {
            if (accessToken == null || accessToken === "") {
                accessToken = "Access token not found.";
            }

            dispatch(setModalAccessToken(accessToken));
        }).catch((error) => {
            throw error;
        });
    });
};

export const OmnivoreIntegrationActions = {
    loadOmnivoreIntegrationState,
    addIntegration,
    removeIntegration,
    retrieveAccessToken,
    setModalAccessToken,
};
