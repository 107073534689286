import React from 'react';

import { AxisPosition } from 'shared/models/Charts/Axes/AxisPosition';
import { IAxis } from 'shared/models/Charts/Axes/IAxis';
import { IAxisLine } from 'shared/models/Charts/Axes/IAxisLine';
import { IBoundingBox } from 'shared/models/Charts/IBoundingBox';

import { Axis } from 'shared/components/Charts/Axes/Axis';

import { utils } from 'shared/components/Charts/utils';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export interface IYAxisProps {
    readonly yAxis : IAxis;
    readonly xAxisHeightInPixels : number;
    readonly hasAxisTickOnBottomEdge : boolean;
    readonly hasAxisTickOnTopEdge : boolean;
    readonly boundingBox : IBoundingBox;
}

export class YAxis extends React.Component<IYAxisProps, object> {
    public render() {
        const {
            yAxis,
            xAxisHeightInPixels,
            hasAxisTickOnBottomEdge,
            hasAxisTickOnTopEdge,
            boundingBox,
        } = this.props;

        // This assumes the xAxis is on the bottom

        utils.ensureBoundingBoxIsValid(boundingBox);

        if (yAxis.axisPosition !== AxisPosition.LEFT && yAxis.axisPosition !== AxisPosition.RIGHT) {
            throw new RuntimeException('unexpected yAxis.axisPosition value');
        }

        if (yAxis.axisPosition === AxisPosition.RIGHT) {
            throw new RuntimeException('not implemented');
        }

        const numberOfAxisTicks = yAxis.axisTicks.length;
        const widthInPixels : number = yAxis.axisLength;

        let y : number = 0;
        let heightInPixels : number = boundingBox.heightInPixels - xAxisHeightInPixels;
        if (numberOfAxisTicks > 1) {
            const numberOfAxisTicksOutsideOfHeight = (hasAxisTickOnBottomEdge ? .5 : 0) + (hasAxisTickOnTopEdge ? .5 : 0);

            if (numberOfAxisTicksOutsideOfHeight > 0) {
                const heightOfAxisTick : number = heightInPixels / (numberOfAxisTicks - numberOfAxisTicksOutsideOfHeight);

                if (hasAxisTickOnTopEdge) {
                    y = -(heightOfAxisTick / 2);
                }

                heightInPixels = heightOfAxisTick * numberOfAxisTicks;
            }
        }

        let axisLine : IAxisLine | null;
        if (yAxis.showAxisLine) {
            axisLine = {
                startsAtTick: hasAxisTickOnBottomEdge,
                endsAtTick: hasAxisTickOnTopEdge,
            };
        } else {
            axisLine = null;
        }

        return (
            <svg
                y={ y }
                height={ heightInPixels }
                width={ widthInPixels }
            >
                <Axis
                    axisPosition={ yAxis.axisPosition }
                    axisTicks={ yAxis.axisTicks }
                    labelValue={ yAxis.labelValue }
                    axisLine={ axisLine }
                    boundingBox={ {
                        heightInPixels,
                        widthInPixels,
                    } }
                />
            </svg>
        );
    }
}
