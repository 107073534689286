import React from 'react';

interface IHubspotMeetingSchedulerProps {
    readonly onMeetingScheduled : () => void;
    additionalFields : boolean;
}

export class HubspotMeetingScheduler extends React.Component<IHubspotMeetingSchedulerProps, object> {
    private lastElement : HTMLElement | null = null;

    public render() {
        this.lastElement = document.getElementById(`${ this.props.additionalFields ? 'hubspot-meetings-sales-iframe-container' : 'hubspot-meetings-iframe-container' }`);

        if (!this.lastElement) {
            setTimeout(() => {
                this.forceUpdate();
            }, 250);
            return null;
        }

        return (
            <div className="hubspot-meeting-scheduler" dangerouslySetInnerHTML={ { __html: this.lastElement.innerHTML } }/>
        );
    }

    public componentDidMount() {
        window.addEventListener('message', this.windowMessageListener);
    }

    public componentWillUnmount() {
        window.removeEventListener('message', this.windowMessageListener);
    }

    private windowMessageListener = (event : MessageEvent) => {
        if (event.origin !== 'https://meet.bevspot.com') {
            return;
        }

        if (event.data.meetingBookSucceeded) {
            this.props.onMeetingScheduled();
            // Listener for Observer call for meetingBookSucceeded in js/bevspot/base.js
        }
    }
}
