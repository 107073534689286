export class BottleBreakdown {
    constructor (
        private readonly startCount : number,
        private readonly deliveries : number,
        private readonly transfers : number,
        private readonly prepInputs : number,
        private readonly prepOutputs : number,
        private readonly breakages : number,
        private readonly sales : number,
    ) { }

    public getStartCount() : number {
        return this.startCount;
    }

    public getDeliveries() : number {
        return this.deliveries;
    }

    public getTransfers() : number {
        return this.transfers;
    }

    public getPrepInputs() : number {
        return this.prepInputs;
    }

    public getPrepOutputs() : number {
        return this.prepOutputs;
    }

    public getBreakages() : number {
        return this.breakages;
    }

    public getSales() : number {
        return this.sales;
    }

}
