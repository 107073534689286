import { IntegrationState } from 'api/Integration/model/IntegrationState';
import { OmnivoreSupportedPosType } from 'api/Integration/model/IntegrationType';
import { OmnivoreIntegrationState } from 'api/Integration/model/OmnivoreIntegrationState';
import { ToastIntegrationState } from 'api/Integration/model/ToastIntegrationState';
import { IBreadcrumbCredentialData } from 'shared/components/BreadcrumbIntegrationModal/models';
import { getBreadcrumbIntegrationStatus } from 'shared/components/BreadcrumbIntegrationModal/utils';
import { REPORT_DATETIME_FORMAT } from 'shared/constants';
import { IntegrationStatus, IntegrationType, PosIntegrationType } from 'shared/models/IntegrationStatus';
import moment = require('moment-timezone');

export const getIntegrationStatus = (integrationState : null | IntegrationState) : IntegrationStatus => {
    if (integrationState === null) {
        return IntegrationStatus.UNCONNECTED;
    }
    if (!integrationState.getExternalMerchantId()) {
        return IntegrationStatus.UNCONNECTED;
    } else {
        if (!integrationState.getIsAccessible()) {
            return IntegrationStatus.NEEDS_ATTENTION;
        } else {
            return IntegrationStatus.CONNECTED;
        }
    }
};

// reinforces getIntegrationStatus to be implemented and used when new integration types are added
export const getIntegrationStatusByType = (
    breadcrumbIntegrationState : IBreadcrumbCredentialData | null,
    toastIntegrationState : ToastIntegrationState | null,
    lightspeedIntegrationState : IntegrationState | null,
    squareIntegrationState : IntegrationState | null,
    omnivoreIntegrationState : OmnivoreIntegrationState | null,
    cloverIntegrationState : IntegrationState | null,
    kSeriesIntegrationState : IntegrationState | null,
    spotOnIntegrationState : IntegrationState | null,
    heartlandIntegrationState : IntegrationState | null,
) : { [type in PosIntegrationType | OmnivoreSupportedPosType]: IntegrationStatus } => {
    const omnivoreSupportedPosIntegrationStatus : { [k in OmnivoreSupportedPosType] : IntegrationStatus } = {
        aloha: IntegrationStatus.UNCONNECTED,
        brink: IntegrationStatus.UNCONNECTED,
        dinerware: IntegrationStatus.UNCONNECTED,
        micros: IntegrationStatus.UNCONNECTED,
        positouch: IntegrationStatus.UNCONNECTED,
        squirrel: IntegrationStatus.UNCONNECTED,
        xpient: IntegrationStatus.UNCONNECTED,
        lavu: IntegrationStatus.UNCONNECTED,
        maitreD: IntegrationStatus.UNCONNECTED
    };
    if (omnivoreIntegrationState !== null && omnivoreIntegrationState.posType) {
        omnivoreSupportedPosIntegrationStatus[omnivoreIntegrationState.posType] = getIntegrationStatus(omnivoreIntegrationState);
    }

    return {
        breadcrumb: getBreadcrumbIntegrationStatus(breadcrumbIntegrationState),
        toast: getIntegrationStatus(toastIntegrationState),
        lightspeed: getIntegrationStatus(lightspeedIntegrationState),
        square: getIntegrationStatus(squareIntegrationState),
        omnivore: getIntegrationStatus(omnivoreIntegrationState),   // not used (but not wrong...)
        clover: getIntegrationStatus(cloverIntegrationState),
        kSeries: getIntegrationStatus(kSeriesIntegrationState),
        spotOn: getIntegrationStatus(spotOnIntegrationState),
        heartland: getIntegrationStatus(heartlandIntegrationState),
        ...omnivoreSupportedPosIntegrationStatus,
    };
};

// This is more strict than backend which currently allows 1 year's worth of POS history for most POS (some have less due to API constraints).
// See PosHistoryCacheUtils.java, MongoToastCacheService.py, BreadcrumbCacheService.py
export const getPosHistoryLimitDays = (
    integrationType : PosIntegrationType | OmnivoreSupportedPosType
) : number => {
    if (integrationType === 'breadcrumb') {
        return 45;
    }
    return 365;
};

export const getIntegrationIconImagePath = (integrationType : IntegrationType | OmnivoreSupportedPosType) : string => {
    let integrationIconImagePath : string;
    switch (integrationType) {
        case 'fintech':
            integrationIconImagePath = '/static/img/integrations/Fintech-Logo.svg';
            break;
        case 'lightspeed': 
        case 'kSeries': {
            integrationIconImagePath = '/static/img/integrations/Lightspeed_logo.svg';
            break;
        }
        case 'sysco':
            integrationIconImagePath = '/static/img/integrations/' + integrationType + '.svg';
            break;
        default: {
            integrationIconImagePath = '/static/img/integrations/' + integrationType + '.png';
        }
    }

    return integrationIconImagePath;
};

export const getPosCacheLastUpdatedMessage = (lastSalesCacheUpdateTime : moment.Moment | null) : string =>  {
    return `POS Data last updated at ${lastSalesCacheUpdateTime ? lastSalesCacheUpdateTime.tz(window.GLOBAL_RETAILER_TIME_ZONE).format(REPORT_DATETIME_FORMAT) : '--' }`;
};
