//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


import Thrift from 'thrift';
import AccountsExceptions from './accounts_Exceptions_types';
import UserAccountsModel from './accounts_user_Model_types';
import CoreApiExceptions from './core_api_Exceptions_types';
import DistributorExceptions from './distributor_Exceptions_types';
import DistributorModel from './distributor_Model_types';
import ProductExceptions from './product_Exceptions_types';
import ProductModel from './product_Model_types';
import LocationModel from './location_Model_types';
import LocationExceptions from './location_Exceptions_types';

var ProductService = {};
export default ProductService;
//HELPER FUNCTIONS AND STRUCTURES

ProductService.ProductService_retrieveDistributorIdsAssociatedWithProductIds_args = function(args) {
  this.sessionId = null;
  this.productIds = null;
  if (args) {
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = new UserAccountsModel.UserSessionIdentifier(args.sessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionId is unset!');
    }
    if (args.productIds !== undefined && args.productIds !== null) {
      this.productIds = Thrift.copyList(args.productIds, [ProductModel.ProductId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productIds is unset!');
    }
  }
};
ProductService.ProductService_retrieveDistributorIdsAssociatedWithProductIds_args.prototype = {};
ProductService.ProductService_retrieveDistributorIdsAssociatedWithProductIds_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionId = new UserAccountsModel.UserSessionIdentifier();
        this.sessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.productIds = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new ProductModel.ProductId();
          elem6.read(input);
          this.productIds.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductService.ProductService_retrieveDistributorIdsAssociatedWithProductIds_args.prototype.write = function(output) {
  output.writeStructBegin('ProductService_retrieveDistributorIdsAssociatedWithProductIds_args');
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRUCT, 1);
    this.sessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.productIds !== null && this.productIds !== undefined) {
    output.writeFieldBegin('productIds', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.productIds.length);
    for (var iter7 in this.productIds)
    {
      if (this.productIds.hasOwnProperty(iter7))
      {
        iter7 = this.productIds[iter7];
        iter7.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductService.ProductService_retrieveDistributorIdsAssociatedWithProductIds_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.unknownProductIdException = null;
  this.duplicateProductIdException = null;
  this.accountDoesNotHaveAccessToProductException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof ProductExceptions.DuplicateProductIdException) {
    this.duplicateProductIdException = args;
    return;
  }
  if (args instanceof ProductExceptions.AccountDoesNotHaveAccessToProductException) {
    this.accountDoesNotHaveAccessToProductException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [ProductModel.ProductIdAndDistributorId]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.duplicateProductIdException !== undefined && args.duplicateProductIdException !== null) {
      this.duplicateProductIdException = args.duplicateProductIdException;
    }
    if (args.accountDoesNotHaveAccessToProductException !== undefined && args.accountDoesNotHaveAccessToProductException !== null) {
      this.accountDoesNotHaveAccessToProductException = args.accountDoesNotHaveAccessToProductException;
    }
  }
};
ProductService.ProductService_retrieveDistributorIdsAssociatedWithProductIds_result.prototype = {};
ProductService.ProductService_retrieveDistributorIdsAssociatedWithProductIds_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size8 = 0;
        var _rtmp312;
        this.success = [];
        var _etype11 = 0;
        _rtmp312 = input.readListBegin();
        _etype11 = _rtmp312.etype;
        _size8 = _rtmp312.size;
        for (var _i13 = 0; _i13 < _size8; ++_i13)
        {
          var elem14 = null;
          elem14 = new ProductModel.ProductIdAndDistributorId();
          elem14.read(input);
          this.success.push(elem14);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.duplicateProductIdException = new ProductExceptions.DuplicateProductIdException();
        this.duplicateProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHaveAccessToProductException = new ProductExceptions.AccountDoesNotHaveAccessToProductException();
        this.accountDoesNotHaveAccessToProductException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductService.ProductService_retrieveDistributorIdsAssociatedWithProductIds_result.prototype.write = function(output) {
  output.writeStructBegin('ProductService_retrieveDistributorIdsAssociatedWithProductIds_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter15 in this.success)
    {
      if (this.success.hasOwnProperty(iter15))
      {
        iter15 = this.success[iter15];
        iter15.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 4);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.duplicateProductIdException !== null && this.duplicateProductIdException !== undefined) {
    output.writeFieldBegin('duplicateProductIdException', Thrift.Type.STRUCT, 5);
    this.duplicateProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHaveAccessToProductException !== null && this.accountDoesNotHaveAccessToProductException !== undefined) {
    output.writeFieldBegin('accountDoesNotHaveAccessToProductException', Thrift.Type.STRUCT, 6);
    this.accountDoesNotHaveAccessToProductException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductService.ProductService_associateDistributorIdWithProductId_args = function(args) {
  this.sessionId = null;
  this.productId = null;
  this.distributorId = null;
  if (args) {
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = new UserAccountsModel.UserSessionIdentifier(args.sessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionId is unset!');
    }
    if (args.productId !== undefined && args.productId !== null) {
      this.productId = new ProductModel.ProductId(args.productId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productId is unset!');
    }
    if (args.distributorId !== undefined && args.distributorId !== null) {
      this.distributorId = new DistributorModel.DistributorId(args.distributorId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field distributorId is unset!');
    }
  }
};
ProductService.ProductService_associateDistributorIdWithProductId_args.prototype = {};
ProductService.ProductService_associateDistributorIdWithProductId_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionId = new UserAccountsModel.UserSessionIdentifier();
        this.sessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.productId = new ProductModel.ProductId();
        this.productId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.distributorId = new DistributorModel.DistributorId();
        this.distributorId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductService.ProductService_associateDistributorIdWithProductId_args.prototype.write = function(output) {
  output.writeStructBegin('ProductService_associateDistributorIdWithProductId_args');
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRUCT, 1);
    this.sessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.productId !== null && this.productId !== undefined) {
    output.writeFieldBegin('productId', Thrift.Type.STRUCT, 2);
    this.productId.write(output);
    output.writeFieldEnd();
  }
  if (this.distributorId !== null && this.distributorId !== undefined) {
    output.writeFieldBegin('distributorId', Thrift.Type.STRUCT, 3);
    this.distributorId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductService.ProductService_associateDistributorIdWithProductId_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.unknownProductIdException = null;
  this.accountDoesNotHaveAccessToProductException = null;
  this.unknownDistributorIdException = null;
  this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException = null;
  this.productIdAlreadyHasAssociationWithThisDistributorException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof ProductExceptions.AccountDoesNotHaveAccessToProductException) {
    this.accountDoesNotHaveAccessToProductException = args;
    return;
  }
  if (args instanceof DistributorExceptions.UnknownDistributorIdException) {
    this.unknownDistributorIdException = args;
    return;
  }
  if (args instanceof ProductExceptions.LocationAssociatedWithProductIdDoesNotHaveAccessToDistributorException) {
    this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException = args;
    return;
  }
  if (args instanceof ProductExceptions.ProductIdAlreadyHasAssociationWithThisDistributorIdException) {
    this.productIdAlreadyHasAssociationWithThisDistributorException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.accountDoesNotHaveAccessToProductException !== undefined && args.accountDoesNotHaveAccessToProductException !== null) {
      this.accountDoesNotHaveAccessToProductException = args.accountDoesNotHaveAccessToProductException;
    }
    if (args.unknownDistributorIdException !== undefined && args.unknownDistributorIdException !== null) {
      this.unknownDistributorIdException = args.unknownDistributorIdException;
    }
    if (args.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException !== undefined && args.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException !== null) {
      this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException = args.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException;
    }
    if (args.productIdAlreadyHasAssociationWithThisDistributorException !== undefined && args.productIdAlreadyHasAssociationWithThisDistributorException !== null) {
      this.productIdAlreadyHasAssociationWithThisDistributorException = args.productIdAlreadyHasAssociationWithThisDistributorException;
    }
  }
};
ProductService.ProductService_associateDistributorIdWithProductId_result.prototype = {};
ProductService.ProductService_associateDistributorIdWithProductId_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHaveAccessToProductException = new ProductExceptions.AccountDoesNotHaveAccessToProductException();
        this.accountDoesNotHaveAccessToProductException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownDistributorIdException = new DistributorExceptions.UnknownDistributorIdException();
        this.unknownDistributorIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException = new ProductExceptions.LocationAssociatedWithProductIdDoesNotHaveAccessToDistributorException();
        this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.productIdAlreadyHasAssociationWithThisDistributorException = new ProductExceptions.ProductIdAlreadyHasAssociationWithThisDistributorIdException();
        this.productIdAlreadyHasAssociationWithThisDistributorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductService.ProductService_associateDistributorIdWithProductId_result.prototype.write = function(output) {
  output.writeStructBegin('ProductService_associateDistributorIdWithProductId_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 4);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHaveAccessToProductException !== null && this.accountDoesNotHaveAccessToProductException !== undefined) {
    output.writeFieldBegin('accountDoesNotHaveAccessToProductException', Thrift.Type.STRUCT, 5);
    this.accountDoesNotHaveAccessToProductException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownDistributorIdException !== null && this.unknownDistributorIdException !== undefined) {
    output.writeFieldBegin('unknownDistributorIdException', Thrift.Type.STRUCT, 6);
    this.unknownDistributorIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException !== null && this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException !== undefined) {
    output.writeFieldBegin('locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException', Thrift.Type.STRUCT, 7);
    this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException.write(output);
    output.writeFieldEnd();
  }
  if (this.productIdAlreadyHasAssociationWithThisDistributorException !== null && this.productIdAlreadyHasAssociationWithThisDistributorException !== undefined) {
    output.writeFieldBegin('productIdAlreadyHasAssociationWithThisDistributorException', Thrift.Type.STRUCT, 8);
    this.productIdAlreadyHasAssociationWithThisDistributorException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductService.ProductService_disassociateDistributorIdFromProductId_args = function(args) {
  this.sessionId = null;
  this.productId = null;
  this.distributorId = null;
  if (args) {
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = new UserAccountsModel.UserSessionIdentifier(args.sessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionId is unset!');
    }
    if (args.productId !== undefined && args.productId !== null) {
      this.productId = new ProductModel.ProductId(args.productId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productId is unset!');
    }
    if (args.distributorId !== undefined && args.distributorId !== null) {
      this.distributorId = new DistributorModel.DistributorId(args.distributorId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field distributorId is unset!');
    }
  }
};
ProductService.ProductService_disassociateDistributorIdFromProductId_args.prototype = {};
ProductService.ProductService_disassociateDistributorIdFromProductId_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionId = new UserAccountsModel.UserSessionIdentifier();
        this.sessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.productId = new ProductModel.ProductId();
        this.productId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.distributorId = new DistributorModel.DistributorId();
        this.distributorId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductService.ProductService_disassociateDistributorIdFromProductId_args.prototype.write = function(output) {
  output.writeStructBegin('ProductService_disassociateDistributorIdFromProductId_args');
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRUCT, 1);
    this.sessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.productId !== null && this.productId !== undefined) {
    output.writeFieldBegin('productId', Thrift.Type.STRUCT, 2);
    this.productId.write(output);
    output.writeFieldEnd();
  }
  if (this.distributorId !== null && this.distributorId !== undefined) {
    output.writeFieldBegin('distributorId', Thrift.Type.STRUCT, 3);
    this.distributorId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductService.ProductService_disassociateDistributorIdFromProductId_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.unknownProductIdException = null;
  this.accountDoesNotHaveAccessToProductException = null;
  this.unknownDistributorIdException = null;
  this.productIdDoesNotHaveAssociationWithThisDistributorException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof ProductExceptions.AccountDoesNotHaveAccessToProductException) {
    this.accountDoesNotHaveAccessToProductException = args;
    return;
  }
  if (args instanceof DistributorExceptions.UnknownDistributorIdException) {
    this.unknownDistributorIdException = args;
    return;
  }
  if (args instanceof ProductExceptions.ProductIdDoesNotHaveAssociationWithThisDistributorIdException) {
    this.productIdDoesNotHaveAssociationWithThisDistributorException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.accountDoesNotHaveAccessToProductException !== undefined && args.accountDoesNotHaveAccessToProductException !== null) {
      this.accountDoesNotHaveAccessToProductException = args.accountDoesNotHaveAccessToProductException;
    }
    if (args.unknownDistributorIdException !== undefined && args.unknownDistributorIdException !== null) {
      this.unknownDistributorIdException = args.unknownDistributorIdException;
    }
    if (args.productIdDoesNotHaveAssociationWithThisDistributorException !== undefined && args.productIdDoesNotHaveAssociationWithThisDistributorException !== null) {
      this.productIdDoesNotHaveAssociationWithThisDistributorException = args.productIdDoesNotHaveAssociationWithThisDistributorException;
    }
  }
};
ProductService.ProductService_disassociateDistributorIdFromProductId_result.prototype = {};
ProductService.ProductService_disassociateDistributorIdFromProductId_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHaveAccessToProductException = new ProductExceptions.AccountDoesNotHaveAccessToProductException();
        this.accountDoesNotHaveAccessToProductException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownDistributorIdException = new DistributorExceptions.UnknownDistributorIdException();
        this.unknownDistributorIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.productIdDoesNotHaveAssociationWithThisDistributorException = new ProductExceptions.ProductIdDoesNotHaveAssociationWithThisDistributorIdException();
        this.productIdDoesNotHaveAssociationWithThisDistributorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductService.ProductService_disassociateDistributorIdFromProductId_result.prototype.write = function(output) {
  output.writeStructBegin('ProductService_disassociateDistributorIdFromProductId_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 4);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHaveAccessToProductException !== null && this.accountDoesNotHaveAccessToProductException !== undefined) {
    output.writeFieldBegin('accountDoesNotHaveAccessToProductException', Thrift.Type.STRUCT, 5);
    this.accountDoesNotHaveAccessToProductException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownDistributorIdException !== null && this.unknownDistributorIdException !== undefined) {
    output.writeFieldBegin('unknownDistributorIdException', Thrift.Type.STRUCT, 6);
    this.unknownDistributorIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.productIdDoesNotHaveAssociationWithThisDistributorException !== null && this.productIdDoesNotHaveAssociationWithThisDistributorException !== undefined) {
    output.writeFieldBegin('productIdDoesNotHaveAssociationWithThisDistributorException', Thrift.Type.STRUCT, 7);
    this.productIdDoesNotHaveAssociationWithThisDistributorException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductService.ProductService_associateDistributorIdWithProductIds_args = function(args) {
  this.sessionId = null;
  this.productIds = null;
  this.distributorId = null;
  if (args) {
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = new UserAccountsModel.UserSessionIdentifier(args.sessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionId is unset!');
    }
    if (args.productIds !== undefined && args.productIds !== null) {
      this.productIds = Thrift.copyList(args.productIds, [ProductModel.ProductId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productIds is unset!');
    }
    if (args.distributorId !== undefined && args.distributorId !== null) {
      this.distributorId = new DistributorModel.DistributorId(args.distributorId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field distributorId is unset!');
    }
  }
};
ProductService.ProductService_associateDistributorIdWithProductIds_args.prototype = {};
ProductService.ProductService_associateDistributorIdWithProductIds_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionId = new UserAccountsModel.UserSessionIdentifier();
        this.sessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size16 = 0;
        var _rtmp320;
        this.productIds = [];
        var _etype19 = 0;
        _rtmp320 = input.readListBegin();
        _etype19 = _rtmp320.etype;
        _size16 = _rtmp320.size;
        for (var _i21 = 0; _i21 < _size16; ++_i21)
        {
          var elem22 = null;
          elem22 = new ProductModel.ProductId();
          elem22.read(input);
          this.productIds.push(elem22);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.distributorId = new DistributorModel.DistributorId();
        this.distributorId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductService.ProductService_associateDistributorIdWithProductIds_args.prototype.write = function(output) {
  output.writeStructBegin('ProductService_associateDistributorIdWithProductIds_args');
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRUCT, 1);
    this.sessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.productIds !== null && this.productIds !== undefined) {
    output.writeFieldBegin('productIds', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.productIds.length);
    for (var iter23 in this.productIds)
    {
      if (this.productIds.hasOwnProperty(iter23))
      {
        iter23 = this.productIds[iter23];
        iter23.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.distributorId !== null && this.distributorId !== undefined) {
    output.writeFieldBegin('distributorId', Thrift.Type.STRUCT, 3);
    this.distributorId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductService.ProductService_associateDistributorIdWithProductIds_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.unknownProductIdException = null;
  this.accountDoesNotHaveAccessToProductException = null;
  this.unknownDistributorIdException = null;
  this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException = null;
  this.duplicateProductIdException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof ProductExceptions.AccountDoesNotHaveAccessToProductException) {
    this.accountDoesNotHaveAccessToProductException = args;
    return;
  }
  if (args instanceof DistributorExceptions.UnknownDistributorIdException) {
    this.unknownDistributorIdException = args;
    return;
  }
  if (args instanceof ProductExceptions.LocationAssociatedWithProductIdDoesNotHaveAccessToDistributorException) {
    this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException = args;
    return;
  }
  if (args instanceof ProductExceptions.DuplicateProductIdException) {
    this.duplicateProductIdException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.accountDoesNotHaveAccessToProductException !== undefined && args.accountDoesNotHaveAccessToProductException !== null) {
      this.accountDoesNotHaveAccessToProductException = args.accountDoesNotHaveAccessToProductException;
    }
    if (args.unknownDistributorIdException !== undefined && args.unknownDistributorIdException !== null) {
      this.unknownDistributorIdException = args.unknownDistributorIdException;
    }
    if (args.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException !== undefined && args.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException !== null) {
      this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException = args.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException;
    }
    if (args.duplicateProductIdException !== undefined && args.duplicateProductIdException !== null) {
      this.duplicateProductIdException = args.duplicateProductIdException;
    }
  }
};
ProductService.ProductService_associateDistributorIdWithProductIds_result.prototype = {};
ProductService.ProductService_associateDistributorIdWithProductIds_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHaveAccessToProductException = new ProductExceptions.AccountDoesNotHaveAccessToProductException();
        this.accountDoesNotHaveAccessToProductException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownDistributorIdException = new DistributorExceptions.UnknownDistributorIdException();
        this.unknownDistributorIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException = new ProductExceptions.LocationAssociatedWithProductIdDoesNotHaveAccessToDistributorException();
        this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.duplicateProductIdException = new ProductExceptions.DuplicateProductIdException();
        this.duplicateProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductService.ProductService_associateDistributorIdWithProductIds_result.prototype.write = function(output) {
  output.writeStructBegin('ProductService_associateDistributorIdWithProductIds_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 4);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHaveAccessToProductException !== null && this.accountDoesNotHaveAccessToProductException !== undefined) {
    output.writeFieldBegin('accountDoesNotHaveAccessToProductException', Thrift.Type.STRUCT, 5);
    this.accountDoesNotHaveAccessToProductException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownDistributorIdException !== null && this.unknownDistributorIdException !== undefined) {
    output.writeFieldBegin('unknownDistributorIdException', Thrift.Type.STRUCT, 6);
    this.unknownDistributorIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException !== null && this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException !== undefined) {
    output.writeFieldBegin('locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException', Thrift.Type.STRUCT, 7);
    this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException.write(output);
    output.writeFieldEnd();
  }
  if (this.duplicateProductIdException !== null && this.duplicateProductIdException !== undefined) {
    output.writeFieldBegin('duplicateProductIdException', Thrift.Type.STRUCT, 8);
    this.duplicateProductIdException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductService.ProductService_disassociateDistributorIdFromProductIds_args = function(args) {
  this.sessionId = null;
  this.productIds = null;
  if (args) {
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = new UserAccountsModel.UserSessionIdentifier(args.sessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionId is unset!');
    }
    if (args.productIds !== undefined && args.productIds !== null) {
      this.productIds = Thrift.copyList(args.productIds, [ProductModel.ProductId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productIds is unset!');
    }
  }
};
ProductService.ProductService_disassociateDistributorIdFromProductIds_args.prototype = {};
ProductService.ProductService_disassociateDistributorIdFromProductIds_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionId = new UserAccountsModel.UserSessionIdentifier();
        this.sessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size24 = 0;
        var _rtmp328;
        this.productIds = [];
        var _etype27 = 0;
        _rtmp328 = input.readListBegin();
        _etype27 = _rtmp328.etype;
        _size24 = _rtmp328.size;
        for (var _i29 = 0; _i29 < _size24; ++_i29)
        {
          var elem30 = null;
          elem30 = new ProductModel.ProductId();
          elem30.read(input);
          this.productIds.push(elem30);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductService.ProductService_disassociateDistributorIdFromProductIds_args.prototype.write = function(output) {
  output.writeStructBegin('ProductService_disassociateDistributorIdFromProductIds_args');
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRUCT, 1);
    this.sessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.productIds !== null && this.productIds !== undefined) {
    output.writeFieldBegin('productIds', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.productIds.length);
    for (var iter31 in this.productIds)
    {
      if (this.productIds.hasOwnProperty(iter31))
      {
        iter31 = this.productIds[iter31];
        iter31.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductService.ProductService_disassociateDistributorIdFromProductIds_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.unknownProductIdException = null;
  this.accountDoesNotHaveAccessToProductException = null;
  this.duplicateProductIdException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof ProductExceptions.AccountDoesNotHaveAccessToProductException) {
    this.accountDoesNotHaveAccessToProductException = args;
    return;
  }
  if (args instanceof ProductExceptions.DuplicateProductIdException) {
    this.duplicateProductIdException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.accountDoesNotHaveAccessToProductException !== undefined && args.accountDoesNotHaveAccessToProductException !== null) {
      this.accountDoesNotHaveAccessToProductException = args.accountDoesNotHaveAccessToProductException;
    }
    if (args.duplicateProductIdException !== undefined && args.duplicateProductIdException !== null) {
      this.duplicateProductIdException = args.duplicateProductIdException;
    }
  }
};
ProductService.ProductService_disassociateDistributorIdFromProductIds_result.prototype = {};
ProductService.ProductService_disassociateDistributorIdFromProductIds_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHaveAccessToProductException = new ProductExceptions.AccountDoesNotHaveAccessToProductException();
        this.accountDoesNotHaveAccessToProductException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.duplicateProductIdException = new ProductExceptions.DuplicateProductIdException();
        this.duplicateProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductService.ProductService_disassociateDistributorIdFromProductIds_result.prototype.write = function(output) {
  output.writeStructBegin('ProductService_disassociateDistributorIdFromProductIds_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 4);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHaveAccessToProductException !== null && this.accountDoesNotHaveAccessToProductException !== undefined) {
    output.writeFieldBegin('accountDoesNotHaveAccessToProductException', Thrift.Type.STRUCT, 5);
    this.accountDoesNotHaveAccessToProductException.write(output);
    output.writeFieldEnd();
  }
  if (this.duplicateProductIdException !== null && this.duplicateProductIdException !== undefined) {
    output.writeFieldBegin('duplicateProductIdException', Thrift.Type.STRUCT, 6);
    this.duplicateProductIdException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductService.ProductServiceClient = function(input, output) {
    this.input = input;
    this.output = (!output) ? input : output;
    this.seqid = 0;
};
ProductService.ProductServiceClient.prototype = {};
ProductService.ProductServiceClient.prototype.retrieveDistributorIdsAssociatedWithProductIds = function(sessionId, productIds, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_retrieveDistributorIdsAssociatedWithProductIds(pendingError, sessionId, productIds, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_retrieveDistributorIdsAssociatedWithProductIds();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

ProductService.ProductServiceClient.prototype.send_retrieveDistributorIdsAssociatedWithProductIds = function(pendingError, sessionId, productIds, callback) {
  this.output.writeMessageBegin('retrieveDistributorIdsAssociatedWithProductIds', Thrift.MessageType.CALL, this.seqid);
  var args = new ProductService.ProductService_retrieveDistributorIdsAssociatedWithProductIds_args();
  args.sessionId = sessionId;
  args.productIds = productIds;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_retrieveDistributorIdsAssociatedWithProductIds() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

ProductService.ProductServiceClient.prototype.recv_retrieveDistributorIdsAssociatedWithProductIds = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new ProductService.ProductService_retrieveDistributorIdsAssociatedWithProductIds_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.duplicateProductIdException) {
    throw result.duplicateProductIdException;
  }
  if (null !== result.accountDoesNotHaveAccessToProductException) {
    throw result.accountDoesNotHaveAccessToProductException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'retrieveDistributorIdsAssociatedWithProductIds failed: unknown result';
};
ProductService.ProductServiceClient.prototype.associateDistributorIdWithProductId = function(sessionId, productId, distributorId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_associateDistributorIdWithProductId(pendingError, sessionId, productId, distributorId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_associateDistributorIdWithProductId();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

ProductService.ProductServiceClient.prototype.send_associateDistributorIdWithProductId = function(pendingError, sessionId, productId, distributorId, callback) {
  this.output.writeMessageBegin('associateDistributorIdWithProductId', Thrift.MessageType.CALL, this.seqid);
  var args = new ProductService.ProductService_associateDistributorIdWithProductId_args();
  args.sessionId = sessionId;
  args.productId = productId;
  args.distributorId = distributorId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_associateDistributorIdWithProductId() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

ProductService.ProductServiceClient.prototype.recv_associateDistributorIdWithProductId = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new ProductService.ProductService_associateDistributorIdWithProductId_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.accountDoesNotHaveAccessToProductException) {
    throw result.accountDoesNotHaveAccessToProductException;
  }
  if (null !== result.unknownDistributorIdException) {
    throw result.unknownDistributorIdException;
  }
  if (null !== result.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException) {
    throw result.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException;
  }
  if (null !== result.productIdAlreadyHasAssociationWithThisDistributorException) {
    throw result.productIdAlreadyHasAssociationWithThisDistributorException;
  }
  return;
};
ProductService.ProductServiceClient.prototype.disassociateDistributorIdFromProductId = function(sessionId, productId, distributorId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_disassociateDistributorIdFromProductId(pendingError, sessionId, productId, distributorId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_disassociateDistributorIdFromProductId();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

ProductService.ProductServiceClient.prototype.send_disassociateDistributorIdFromProductId = function(pendingError, sessionId, productId, distributorId, callback) {
  this.output.writeMessageBegin('disassociateDistributorIdFromProductId', Thrift.MessageType.CALL, this.seqid);
  var args = new ProductService.ProductService_disassociateDistributorIdFromProductId_args();
  args.sessionId = sessionId;
  args.productId = productId;
  args.distributorId = distributorId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_disassociateDistributorIdFromProductId() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

ProductService.ProductServiceClient.prototype.recv_disassociateDistributorIdFromProductId = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new ProductService.ProductService_disassociateDistributorIdFromProductId_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.accountDoesNotHaveAccessToProductException) {
    throw result.accountDoesNotHaveAccessToProductException;
  }
  if (null !== result.unknownDistributorIdException) {
    throw result.unknownDistributorIdException;
  }
  if (null !== result.productIdDoesNotHaveAssociationWithThisDistributorException) {
    throw result.productIdDoesNotHaveAssociationWithThisDistributorException;
  }
  return;
};
ProductService.ProductServiceClient.prototype.associateDistributorIdWithProductIds = function(sessionId, productIds, distributorId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_associateDistributorIdWithProductIds(pendingError, sessionId, productIds, distributorId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_associateDistributorIdWithProductIds();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

ProductService.ProductServiceClient.prototype.send_associateDistributorIdWithProductIds = function(pendingError, sessionId, productIds, distributorId, callback) {
  this.output.writeMessageBegin('associateDistributorIdWithProductIds', Thrift.MessageType.CALL, this.seqid);
  var args = new ProductService.ProductService_associateDistributorIdWithProductIds_args();
  args.sessionId = sessionId;
  args.productIds = productIds;
  args.distributorId = distributorId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_associateDistributorIdWithProductIds() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

ProductService.ProductServiceClient.prototype.recv_associateDistributorIdWithProductIds = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new ProductService.ProductService_associateDistributorIdWithProductIds_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.accountDoesNotHaveAccessToProductException) {
    throw result.accountDoesNotHaveAccessToProductException;
  }
  if (null !== result.unknownDistributorIdException) {
    throw result.unknownDistributorIdException;
  }
  if (null !== result.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException) {
    throw result.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException;
  }
  if (null !== result.duplicateProductIdException) {
    throw result.duplicateProductIdException;
  }
  return;
};
ProductService.ProductServiceClient.prototype.disassociateDistributorIdFromProductIds = function(sessionId, productIds, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_disassociateDistributorIdFromProductIds(pendingError, sessionId, productIds, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_disassociateDistributorIdFromProductIds();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

ProductService.ProductServiceClient.prototype.send_disassociateDistributorIdFromProductIds = function(pendingError, sessionId, productIds, callback) {
  this.output.writeMessageBegin('disassociateDistributorIdFromProductIds', Thrift.MessageType.CALL, this.seqid);
  var args = new ProductService.ProductService_disassociateDistributorIdFromProductIds_args();
  args.sessionId = sessionId;
  args.productIds = productIds;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_disassociateDistributorIdFromProductIds() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

ProductService.ProductServiceClient.prototype.recv_disassociateDistributorIdFromProductIds = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new ProductService.ProductService_disassociateDistributorIdFromProductIds_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.accountDoesNotHaveAccessToProductException) {
    throw result.accountDoesNotHaveAccessToProductException;
  }
  if (null !== result.duplicateProductIdException) {
    throw result.duplicateProductIdException;
  }
  return;
};
