import DistributorModel from 'gen-thrift/distributor_Model_types';

import { Scope, ScopeType } from 'api/Location/model/Scope';

export class ScopeObjectToThriftSerializer {
    public getThriftScope(
        scope : Scope
    ) : DistributorModel.Scope {
        const id = scope.getId();
        const type = scope.getType();
        switch (type) {
            case ScopeType.GLOBAL:
                if (id !== null) {
                    throw new Error('scope id unexpectedly not null');
                }
                return new DistributorModel.Scope({
                    type : DistributorModel.ScopeType.GLOBAL,
                    id : null
                });
            case ScopeType.GROUP:
                if (id === null) {
                    throw new Error('scope id unexpectedly null');
                }
                return new DistributorModel.Scope({
                    type : DistributorModel.ScopeType.GROUP,
                    id : id.getValue()
                });
            case ScopeType.RETAILER:
                if (id === null) {
                    throw new Error('scope id unexpectedly null');
                }
                return new DistributorModel.Scope({
                    type : DistributorModel.ScopeType.RETAILER,
                    id : id.getValue()
                });
            default:
                throw new Error('unexpected scope type : ' + type);
        }
    }

}
