import moment from 'moment-timezone';

import { DateTime, Timezone } from '../../models/DateTime';
import { DayOfWeekName } from '../../models/DayOfWeekName';
import { MonthOfYear } from '../../models/MonthOfYear';

export const BevSpotDatePickerUtils = {
    convertDateTimeToMoment: (dateTime : DateTime) : moment.Moment => {
        const dateTimeAsMoment = BevSpotDatePickerUtils.convertDateTimeToUTCMoment(dateTime);
        return dateTimeAsMoment.tz(Timezone[dateTime.timezone]);
    },
    convertDateTimeToUTCMoment: (dateTime : DateTime) : moment.Moment => {
        return moment.tz(dateTime.utcTime, dateTime.format, Timezone[Timezone.UTC]);
    },
    decrementMonth: (monthOfYear : MonthOfYear) : MonthOfYear => {
        let newMonthOfYear : MonthOfYear = (monthOfYear - 1) as MonthOfYear;
        if (newMonthOfYear === -1) {
            newMonthOfYear = MonthOfYear.DECEMBER;
        }
        return newMonthOfYear;
    },
    getDayOfWeekNameOfFirstDayInMonthForGivenYear: (month : MonthOfYear, year : number) : DayOfWeekName => {
        const monthAndYearAsString : string = `${ year }-${ month + 1 }-01`;
        const monthAsMoment = moment(monthAndYearAsString, 'YYYY-M-DD');
        const dayOfWeekName : DayOfWeekName = parseInt(monthAsMoment.format('d'), 10) as DayOfWeekName;
        return dayOfWeekName;
    },
    getDayOfWeekNames: () : Array<DayOfWeekName> => {
        return [
            DayOfWeekName.SUNDAY,
            DayOfWeekName.MONDAY,
            DayOfWeekName.TUESDAY,
            DayOfWeekName.WEDNESDAY,
            DayOfWeekName.THURSDAY,
            DayOfWeekName.FRIDAY,
            DayOfWeekName.SATURDAY,
        ];
    },
    getMonthAsNumberFromDateTime: (dateTime : DateTime) : number => {
        const dateTimeAsMoment = BevSpotDatePickerUtils.convertDateTimeToMoment(dateTime);
        return dateTimeAsMoment.month();
    },
    getMonthAsStringFromMonthOfYear: (monthOfYear : MonthOfYear) : string => {
        const momentFromMonthOfYear : moment.Moment = moment().month(monthOfYear);
        return momentFromMonthOfYear.format('MMMM');
    },
    getNumberOfDaysInMonthForGivenYear: (month : MonthOfYear, year : number) : number => {
        /**
         * Ben Leichter (December 16, 2016)
         * We have to add 1 to the month because the MonthOfYear enum starts at 0.
         * It's written that way because when getting and setting the month on a moment
         * with the '.month()' function, the month is zero-indexed.
         * However, when creating a moment from a datetime string, the month starts at one.
         *
         * TLDR: shoot me
         */
        const monthAndYearAsString : string = `${ year }-${ month + 1 }`;
        const monthAsMoment = moment(monthAndYearAsString, 'YYYY-M');

        return monthAsMoment.daysInMonth();
    },
    getNumberOfWeeksInMonth: (firstDayOfMonthDayOfWeekName : DayOfWeekName, numberOfDaysInMonth : number) : number => {
        return Math.ceil((numberOfDaysInMonth + firstDayOfMonthDayOfWeekName) / 7);
    },
    getNumericalDayOfMonthForDateTime: (dateTime : DateTime) : number => {
        const dateTimeAsMoment = BevSpotDatePickerUtils.convertDateTimeToMoment(dateTime);
        return dateTimeAsMoment.date();
    },
    getYearAsNumberFromDateTime: (dateTime : DateTime) : number => {
        const dateTimeAsMoment = BevSpotDatePickerUtils.convertDateTimeToMoment(dateTime);
        return dateTimeAsMoment.year();
    },
    getYearAsStringFromDateTime: (dateTime : DateTime) : string => {
        const dateTimeAsMoment = BevSpotDatePickerUtils.convertDateTimeToMoment(dateTime);
        return dateTimeAsMoment.format('YYYY');
    },
    incrementMonth: (monthOfYear : MonthOfYear) : MonthOfYear => {
        let newMonthOfYear : MonthOfYear = (monthOfYear + 1) as MonthOfYear;
        if (newMonthOfYear === 12) {
            newMonthOfYear = MonthOfYear.JANUARY;
        }
        return newMonthOfYear;
    },
    updateDateTimeWithYearMonthAndDay: (dateTime : DateTime, newNumericalDayOfMonth : number, newMonthAsNumber : number, newYear : number) : DateTime => {
        const dateTimeAsMoment = BevSpotDatePickerUtils.convertDateTimeToMoment(dateTime);
        dateTimeAsMoment.year(newYear);
        dateTimeAsMoment.month(newMonthAsNumber);
        dateTimeAsMoment.date(newNumericalDayOfMonth);
        const updatedDateTime : DateTime = new DateTime({
            format: dateTime.format,
            timezone: dateTime.timezone,
            utcTime: dateTimeAsMoment.utc().format(dateTime.format),
        });
        return updatedDateTime;
    },
};
