import { UserAccountIdAndTimestamp } from 'api/UserAccount/model/UserAccountIdAndTimestamp';

export class ReportMetadata {

    constructor (
        private readonly creationMetadata : UserAccountIdAndTimestamp,
        private readonly updateMetadata : UserAccountIdAndTimestamp | null,
        private readonly perspectiveIsOriginator : boolean,
    ) {}

    public getCreationMetadata() : UserAccountIdAndTimestamp {
        return this.creationMetadata;
    }

    public getUpdateMetadata() : UserAccountIdAndTimestamp | null {
        return this.updateMetadata;
    }

    public getPerspectiveIsOriginator() : boolean {
        return this.perspectiveIsOriginator;
    }
}
