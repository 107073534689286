import { LocationId } from 'api/Location/model/LocationId';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';

import { IRetailerPopulationFromInvoiceUploadService } from 'api/Onboarding/interfaces/IRetailerPopulationFromInvoiceUploadService';
import { IRetailerPopulationFromInvoiceUpload } from 'api/Onboarding/model/IRetailerPopulationFromInvoiceUpload';
import { RetailerPopulationFromInvoiceUploadJSONToObjectSerializer } from 'api/Onboarding/serializer/RetailerPopulationFromInvoiceUploadJSONToObjectSerializer';

import { AjaxUtils } from 'shared/utils/ajaxUtils';

export class RetailerPopulationFromInvoiceUploadServiceImpl implements IRetailerPopulationFromInvoiceUploadService {
    constructor(
        private readonly retailerPopulationFromInvoiceUploadJSONToObjectSerializer : RetailerPopulationFromInvoiceUploadJSONToObjectSerializer,
    ) {}

    public uploadInvoices(
        userSessionId : UserSessionId,
        locationId : LocationId,
        files : Array<File>,
    ) : Promise<void> {
        const formData : FormData = new FormData();
        files.forEach((file : File) => {
            formData.append(file.name, file);
        });

        const queryParameters = {
            retailer_id: locationId.getValue(),
        };

        return AjaxUtils.ajaxPostForm(urlWithoutRetailerId('api:retailer_population_from_invoice_upload'), formData, queryParameters);
    }

    public getRetailerPopulationFromInvoiceUploadForLocation(
        locationId : LocationId
    ) : Promise<Array<IRetailerPopulationFromInvoiceUpload>> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:retailer_population_from_invoice_upload'), queryParameters)
        .then((response : Array<any>) => {
            return response.map((retailerPopulationFromInvoiceUploadJSON) => {
                return this.retailerPopulationFromInvoiceUploadJSONToObjectSerializer.getFromJSON(retailerPopulationFromInvoiceUploadJSON);
            });
        });
    }
}
