import { ProductQuickAdd } from 'api/Onboarding/model/ProductQuickAdd';
import { OldPackaging } from 'api/Product/model/OldPackaging';
import { IOldPackagingJSONObject } from 'api/Product/serializer/IProductJSONObject';
import { oldPackagingUtils } from 'api/Product/utils/oldPackagingUtils';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

interface IProductQuickAddOptionJSONObject {
    package : IOldPackagingJSONObject;
    suggested_case_price : {iso_4217_currency_code : string, value : number};
    suggested_deposit : {iso_4217_currency_code : string, value : number};
}

interface IProductQuickAddJSONObject {
    brand : string;
    name : string;
    options : Array<IProductQuickAddOptionJSONObject>;
    product_category_id : string;
    product_type : string;
    sku : string;
    note : string;
    retailer_id : string;
}

export class ProductQuickAddJSONToObjectSerializer {

    public getOldPackaging(
        packageObject : IOldPackagingJSONObject,
    ) : OldPackaging {
        return new OldPackaging(
            (packageObject.content === null) ? null : this.getOldPackaging(packageObject.content),
            packageObject.quantity_of_content,
            oldPackagingUtils.getUnitFromValue(packageObject.unit)
        );
    }

    public getProductQuickAddData(
        productQuickAddJSONData : Array<IProductQuickAddJSONObject>
    ) : Array<ProductQuickAdd> {
        const productQuickAddObjData : Array<ProductQuickAdd> = [];
        productQuickAddJSONData.forEach((jsonObject : IProductQuickAddJSONObject) => {
            productQuickAddObjData.push(this.getProductQuickAdd(jsonObject));
        });
        return productQuickAddObjData;
    }

    private getProductQuickAdd(
        productObject : IProductQuickAddJSONObject
    ) : ProductQuickAdd {

        const getOptionalPrice = (jsonPrice : {iso_4217_currency_code : string, value : number} | undefined) : number => {
            // Substitute zero if the price is missing from the json doc.
            return (typeof jsonPrice === 'undefined') ? 0 : getRequiredPrice(jsonPrice);
        };

        const getRequiredPrice = (jsonPrice : {iso_4217_currency_code : string, value : number}) : number => {
            if (jsonPrice.iso_4217_currency_code !== 'USD') {
                throw new RuntimeException('unexpected currency code');
            }
            return jsonPrice.value;
        };

        const options = productObject.options.map((jsonObject : IProductQuickAddOptionJSONObject) => ({
            packaging: this.getOldPackaging(jsonObject.package),
            suggestedCasePriceInDollars: getRequiredPrice(jsonObject.suggested_case_price),
            suggestedDepositInDollars: getOptionalPrice(jsonObject.suggested_deposit),
        }));

        return new ProductQuickAdd(
            productObject.brand ? productObject.brand : '',
            productObject.name,
            options,
            productObject.product_category_id,
            'BevSpot Item', // productObject.product_type ? productObject.product_type : '', // APP2-7
            productObject.sku ? productObject.sku : '',
            productObject.note ? productObject.note : '',
        );
    }
}
