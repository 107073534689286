import AdminSalesItemService from 'gen-thrift/AdminSalesItemService';
import SalesItemModel from 'gen-thrift/sales_item_Model_types';
import SalesItemService from 'gen-thrift/SalesItemService';

import { MomentObjectToThriftSerializer } from 'api/Core/serializer/MomentObjectToThriftSerializer';
import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { LocationId } from 'api/Location/model/LocationId';
import { LocationObjectToThriftSerializer } from 'api/Location/serializer/LocationObjectToThriftSerializer';
import { ItemLevelSalesReportConfigurationId } from 'api/Reports/model/ItemLevelSalesReportConfigurationId';
import { ItemLevelSalesReportConfigurationSerializer } from 'api/Reports/serializer/ItemLevelSalesReportConfigurationSerializer';
import { IAdminSalesItemService } from 'api/SalesItem/interfaces/IAdminSalesItemService';
import { ISalesItemService } from 'api/SalesItem/interfaces/ISalesItemService';
import { SalesItem } from 'api/SalesItem/model/SalesItem';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemWithMetadata } from 'api/SalesItem/model/SalesItemWithMetadata';
import { SalesItemObjectToThriftSerializer } from 'api/SalesItem/serializer/SalesItemObjectToThriftSerializer';
import { SalesItemThriftToObjectSerializer } from 'api/SalesItem/serializer/SalesItemThriftToObjectSerializer';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { UserAccountObjectToThriftSerializer } from 'api/UserAccount/serializer/UserAccountObjectToThriftSerializer';
import { IDeleteSalesItemsResult } from 'apps/SalesItemDeleter/models';
import { DateTime } from 'shared/models/DateTime';
import { fileReaderUtils } from 'shared/utils/fileReaderUtils';

export class SalesItemServiceImpl implements ISalesItemService, IAdminSalesItemService {
    constructor(
        private readonly thriftClient : SalesItemService.SalesItemServiceClient,
        private readonly thriftAdminClient : AdminSalesItemService.AdminSalesItemServiceClient,
        private readonly userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer,
        private readonly locationObjectToThriftSerializer : LocationObjectToThriftSerializer,
        private readonly momentObjectToThriftSerializer : MomentObjectToThriftSerializer,
        private readonly salesItemThriftToObjectSerializer : SalesItemThriftToObjectSerializer,
        private readonly salesItemObjectToThriftSerializer : SalesItemObjectToThriftSerializer,
        private readonly itemLevelSalesReportConfigurationSerializer : ItemLevelSalesReportConfigurationSerializer
    ) {}

    public getRelevantSalesItemsForIds(userSessionId : UserSessionId, salesItemIds : StringValueSet<SalesItemId>) : Promise<StringValueMap<SalesItemId, SalesItemWithMetadata>> {
        return new Promise<StringValueMap<SalesItemId, SalesItemWithMetadata>>((resolve, reject) => {
            this.thriftClient.getRelevantSalesItemsForIds(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.salesItemObjectToThriftSerializer.getThriftSalesItemIdList(salesItemIds),
                (result : Array<SalesItemModel.SalesItemAndId> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    const salesItemsById = new StringValueMap<SalesItemId, SalesItemWithMetadata>();
                    result.forEach((thriftSalesItemAndId) => {
                        salesItemsById.set(
                            this.salesItemThriftToObjectSerializer.getSalesItemId(thriftSalesItemAndId.salesItemId),
                            this.salesItemThriftToObjectSerializer.getSalesItemWithMetadata(thriftSalesItemAndId.salesItemWithMetadata)
                        );
                    });
                    return resolve(salesItemsById);
                }
            );
        });
    }
    public getNonDeletedSalesItemIdsForLocation(userSessionId : UserSessionId, locationId : LocationId) : Promise<StringValueSet<SalesItemId>> {
        return new Promise<StringValueSet<SalesItemId>>((resolve, reject) => {
            this.thriftClient.getNonDeletedSalesItemIds(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                (result : Array<SalesItemModel.SalesItemId> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    return resolve(this.salesItemThriftToObjectSerializer.getSalesItemIds(result));
                }
            );
        });
    }

    public getDeletedSalesItemIdsForLocation(userSessionId : UserSessionId, locationId : LocationId) : Promise<StringValueSet<SalesItemId>> {
        return new Promise<StringValueSet<SalesItemId>>((resolve, reject) => {
            this.thriftClient.getDeletedSalesItemIds(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                (result : Array<SalesItemModel.SalesItemId> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    return resolve(this.salesItemThriftToObjectSerializer.getSalesItemIds(result));
                }
            );
        });
    }

    public createSalesItem(userSessionId : UserSessionId, salesItem : SalesItem) : Promise<SalesItemId> {
        return new Promise<SalesItemId>((resolve, reject) => {
            this.thriftClient.createSalesItem(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.salesItemObjectToThriftSerializer.getThriftSalesItem(salesItem),
                (result : SalesItemModel.SalesItemId | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    return resolve(this.salesItemThriftToObjectSerializer.getSalesItemId(result));
                }
            );
        });
    }

    public setSalesItemActiveState(userSessionId : UserSessionId, salesItemIds : StringValueSet<SalesItemId>, isActive : boolean) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.thriftClient.setSalesItemActiveState(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.salesItemObjectToThriftSerializer.getThriftSalesItemIdList(salesItemIds),
                isActive,
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    return resolve();
                }
            );
        });
    }

    public updateSalesItemForSingleReport(
        userSessionId : UserSessionId,
        salesItemId : SalesItemId,
        salesItem : SalesItem,
        itemLevelSalesReportConfigurationId : ItemLevelSalesReportConfigurationId
    ) : Promise<StringValueMap<SalesItemId, SalesItemId>> {
        return new Promise<StringValueMap<SalesItemId, SalesItemId>>((resolve, reject) => {
            this.thriftClient.updateSalesItemForSingleReport(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.salesItemObjectToThriftSerializer.getThriftSalesItemId(salesItemId),
                this.salesItemObjectToThriftSerializer.getThriftSalesItem(salesItem),
                this.itemLevelSalesReportConfigurationSerializer.getThriftItemLevelSalesReportConfigurationId(itemLevelSalesReportConfigurationId),
                (result : Array<SalesItemModel.SalesItemIdMapping> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    return resolve(this.salesItemThriftToObjectSerializer.getSalesItemIdMap(result));
                }
            );
        });
    }

    public updateSalesItemForCurrentAndFutureReports(
        userSessionId : UserSessionId,
        salesItemId : SalesItemId,
        salesItem : SalesItem,
        itemLevelSalesReportConfigurationId : ItemLevelSalesReportConfigurationId | null
    ) : Promise<StringValueMap<SalesItemId, SalesItemId>> {
        return new Promise<StringValueMap<SalesItemId, SalesItemId>>((resolve, reject) => {
            this.thriftClient.updateSalesItemForCurrentAndFutureReports(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.salesItemObjectToThriftSerializer.getThriftSalesItemId(salesItemId),
                this.salesItemObjectToThriftSerializer.getThriftSalesItem(salesItem),
                itemLevelSalesReportConfigurationId ? this.itemLevelSalesReportConfigurationSerializer.getThriftItemLevelSalesReportConfigurationId(itemLevelSalesReportConfigurationId) : null,
                (result : Array<SalesItemModel.SalesItemIdMapping> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    return resolve(this.salesItemThriftToObjectSerializer.getSalesItemIdMap(result));
                }
            );
        });
    }

    public updateSalesItemForAllTime(
        userSessionId : UserSessionId,
        salesItemId : SalesItemId,
        salesItem : SalesItem
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.thriftClient.updateSalesItemForAllTime(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.salesItemObjectToThriftSerializer.getThriftSalesItemId(salesItemId),
                this.salesItemObjectToThriftSerializer.getThriftSalesItem(salesItem),
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    return resolve();
                }
            );
        });
    }

    public deleteSalesItems(userSessionId : UserSessionId, salesItemIds : StringValueSet<SalesItemId>) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.thriftClient.deleteSalesItems(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.salesItemObjectToThriftSerializer.getThriftSalesItemIdList(salesItemIds),
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    return resolve();
                }
            );
        });
    }
    public undeleteSalesItems(userSessionId : UserSessionId, salesItemIds : StringValueSet<SalesItemId>) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.thriftClient.undeleteSalesItems(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.salesItemObjectToThriftSerializer.getThriftSalesItemIdList(salesItemIds),
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    return resolve();
                }
            );
        });
    }

    // ADMIN SALES ITEM SERVICE -- this does not use real serialization, just want to be compatible with the admin SalesDeleter tool
    // (could be refactored/removed at some point when POS becomes more self-service)
    public adminRemoveSalesItemsForLocation(
        userSessionId : UserSessionId,
        retailerId : LocationId,
        createdBeforeTime : DateTime | null,
        createdAfterTime : DateTime | null,
        createByMe : boolean,
        checkOnly : boolean
    ) : Promise<IDeleteSalesItemsResult> {
        return new Promise<IDeleteSalesItemsResult>((resolve, reject) => {
            this.thriftAdminClient.adminRemoveSalesItemsForLocation(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(retailerId),
                createdAfterTime ? this.momentObjectToThriftSerializer.getThriftTimestampFromMoment(createdAfterTime.toMoment()) : null,
                createdBeforeTime ? this.momentObjectToThriftSerializer.getThriftTimestampFromMoment(createdBeforeTime.toMoment()) : null,
                createByMe,
                !checkOnly,
                (result : SalesItemModel.AdminRemoveSalesItemsResponse | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    const matchedSalesItemsById : {[key : string] : string} = {};
                    result.matchedSalesItemInfoById.forEach((salesItemAndId) => {
                        const salesItemInfo = salesItemAndId.salesItemWithMetadata.salesItem.name + ' (' + salesItemAndId.salesItemWithMetadata.salesItem.posId + ')';
                        matchedSalesItemsById[salesItemAndId.salesItemId.value] = salesItemInfo;
                    });

                    const hasRecipeSalesItemIds : Array<string> = [];
                    result.hasComponentItemsIds.forEach((salesItemIdObj) => {
                        hasRecipeSalesItemIds.push(salesItemIdObj.value);
                    });

                    const usedInAnotherSalesItemIds : Array<string> = [];
                    result.usedInOtherSalesItemsIds.forEach((salesItemIdObj) => {
                        usedInAnotherSalesItemIds.push(salesItemIdObj.value);
                    });

                    const referencedSalesItemsByReportId : {[key : string] : Array<string>} = {};
                    result.referencedItemsByReportId.forEach((reportIdAndSalesItemIds) => {
                        const salesIdValues : Array<string> = reportIdAndSalesItemIds.salesItemIds.map((salesItemIdObj) => salesItemIdObj.value);
                        referencedSalesItemsByReportId[reportIdAndSalesItemIds.reportId.value] = salesIdValues;
                    });

                    return resolve({
                        canDelete: result.canDelete,
                        deleteSuccess: result.deleteSuccess,
                        matchedSalesItemsById,
                        hasRecipeSalesItemIds,
                        referencedSalesItemsByReportId,
                        usedInAnotherSalesItemIds
                    });
                }
            );
        });
    }

    public uploadSalesItemImageAndGetUrl(userSessionId : UserSessionId, locationId: LocationId, salesItemId : SalesItemId, f: File) {
        return new Promise<string>((resolve, reject) => {
            return fileReaderUtils.getBinaryStringFromFile(f)
            .then((binary: string) => {
                this.thriftClient.uploadSalesItemImageAndGetUrl(
                    this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                    this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                    this.salesItemObjectToThriftSerializer.getThriftSalesItemId(salesItemId),
                    binary,
                    (result : string | Error) => {
                        if (result instanceof Error) {
                            return reject(result);
                        }
                        return resolve(result);
                    }
                );
            });
        });
    }

    public getSalesItemImageUploadUrls(userSessionId : UserSessionId, locationId: LocationId, salesItemIds : StringValueSet<SalesItemId>) : Promise<StringValueMap<SalesItemId, string>> {
        return new Promise((resolve, reject) => {
            this.thriftClient.getSalesItemImageUploadUrls(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                this.salesItemObjectToThriftSerializer.getThriftSalesItemIdList(salesItemIds),
                (result : Array<SalesItemModel.SalesItemImageUploadUrl> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    const map = new StringValueMap<SalesItemId, string>();
                    result.forEach((v : SalesItemModel.SalesItemImageUploadUrl) => {
                        map.set(
                            this.salesItemThriftToObjectSerializer.getSalesItemId(v.salesItemId),
                            v.url
                        );
                    });
                    return resolve(map);
                }
            );
        });
    }

    public deleteSalesItemImageUpload(userSessionId : UserSessionId, locationId : LocationId, salesItemId : SalesItemId) : Promise<void> {
        return new Promise((resolve, reject) => {
            this.thriftClient.deleteSalesItemImageUpload(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                this.salesItemObjectToThriftSerializer.getThriftSalesItemId(salesItemId),
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve();
                }
            );
        });
    }
}
