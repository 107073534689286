import React from 'react';

import { Button } from 'shared/components/Button';
import { Observer } from 'shared/utils/observer';

import './DataHistoryLimitReached.scss';

export interface IDataHistoryLimitReachedProps {
    readonly className : string;
    // consider being able to pass in custom message?
}

export class DataHistoryLimitReached extends React.Component<IDataHistoryLimitReachedProps, object> {
    public render() {
        const {
            className,
        } = this.props;

        return (
            <div className={ `data-history-limit-reached ${ className }` }>
                <div className="data-history-limit-reached-content">
                    <div className="bevicon bevico-lock"/>
                    <div className="text-body">
                        <p>
                            <strong>There's more to see. <span>Upgrade to unlock your full history and get the full story.</span></strong>
                        </p>
                        <Button
                            buttonClassName=""
                            isDisabled={ false }
                            isLoading={ false }
                            onClick={ this.onGoToPlansAndBilling }
                        >
                            Learn More
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    private readonly onGoToPlansAndBilling = () =>  {
        Observer.observeAction('data_history_limit_learn_more_clicked', {});

        const linkToPlansAndBilling = '/account/billing/r/' + window.GLOBAL_RETAILER_ID;
        window.open(linkToPlansAndBilling, '_self');
    }
}
