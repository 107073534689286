import { MassUnit } from 'api/Product/model/MassUnit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';

export class SalesQuantityAndUnit {
    constructor(
        private readonly quantity : number,
        private readonly unit : VolumeUnit | MassUnit | null
    ) {}

    public getQuantity() : number {
        return this.quantity;
    }

    public getUnit() : VolumeUnit | MassUnit | null {
        return this.unit;
    }

    public equals(other : any) : boolean {
        if (!(other instanceof SalesQuantityAndUnit)) {
            return false;
        }

        return (other.getQuantity() === this.quantity && other.getUnit() === this.unit);
    }
}
