//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


import Thrift from 'thrift';
import CorePrimitivesModel from './core_primitives_Model_types';
import CoreApiExceptions from './core_api_Exceptions_types';
import AccountsModel from './accounts_Model_types';
import UserAccountsModel from './accounts_user_Model_types';
import AccountsExceptions from './accounts_Exceptions_types';
import UnauthenticatedUserSessionModel from './unauthenticated_user_session_Model_types';
import UnauthenticatedUserSessionExceptions from './unauthenticated_user_session_Exceptions_types';
import UserInfoExceptions from './user_info_Exceptions_types';

var UserAccountService = {};
export default UserAccountService;
//HELPER FUNCTIONS AND STRUCTURES

UserAccountService.UserSessionService_createSessionForAuthenticatedAccount_args = function(args) {
  this.access_token = null;
  this.id_token = null;
  this.unauthenticatedUserSessionId = null;
  if (args) {
    if (args.access_token !== undefined && args.access_token !== null) {
      this.access_token = args.access_token;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field access_token is unset!');
    }
    if (args.id_token !== undefined && args.id_token !== null) {
      this.id_token = args.id_token;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field id_token is unset!');
    }
    if (args.unauthenticatedUserSessionId !== undefined && args.unauthenticatedUserSessionId !== null) {
      this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId(args.unauthenticatedUserSessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field unauthenticatedUserSessionId is unset!');
    }
  }
};
UserAccountService.UserSessionService_createSessionForAuthenticatedAccount_args.prototype = {};
UserAccountService.UserSessionService_createSessionForAuthenticatedAccount_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.access_token = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.id_token = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId();
        this.unauthenticatedUserSessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserAccountService.UserSessionService_createSessionForAuthenticatedAccount_args.prototype.write = function(output) {
  output.writeStructBegin('UserSessionService_createSessionForAuthenticatedAccount_args');
  if (this.access_token !== null && this.access_token !== undefined) {
    output.writeFieldBegin('access_token', Thrift.Type.STRING, 1);
    output.writeString(this.access_token);
    output.writeFieldEnd();
  }
  if (this.id_token !== null && this.id_token !== undefined) {
    output.writeFieldBegin('id_token', Thrift.Type.STRING, 2);
    output.writeString(this.id_token);
    output.writeFieldEnd();
  }
  if (this.unauthenticatedUserSessionId !== null && this.unauthenticatedUserSessionId !== undefined) {
    output.writeFieldBegin('unauthenticatedUserSessionId', Thrift.Type.STRUCT, 3);
    this.unauthenticatedUserSessionId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserAccountService.UserSessionService_createSessionForAuthenticatedAccount_result = function(args) {
  this.success = null;
  this.serviceTemporarilyUnavailableException = null;
  this.rateLimitExceededException = null;
  this.unknownCredentialsException = null;
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownCredentialsException) {
    this.unknownCredentialsException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new UserAccountsModel.UserSessionIdentifier(args.success);
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.unknownCredentialsException !== undefined && args.unknownCredentialsException !== null) {
      this.unknownCredentialsException = args.unknownCredentialsException;
    }
  }
};
UserAccountService.UserSessionService_createSessionForAuthenticatedAccount_result.prototype = {};
UserAccountService.UserSessionService_createSessionForAuthenticatedAccount_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new UserAccountsModel.UserSessionIdentifier();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownCredentialsException = new AccountsExceptions.UnknownCredentialsException();
        this.unknownCredentialsException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserAccountService.UserSessionService_createSessionForAuthenticatedAccount_result.prototype.write = function(output) {
  output.writeStructBegin('UserSessionService_createSessionForAuthenticatedAccount_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 1);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 2);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownCredentialsException !== null && this.unknownCredentialsException !== undefined) {
    output.writeFieldBegin('unknownCredentialsException', Thrift.Type.STRUCT, 3);
    this.unknownCredentialsException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserAccountService.UserSessionService_terminateSession_args = function(args) {
  this.sessionIdentifier = null;
  if (args) {
    if (args.sessionIdentifier !== undefined && args.sessionIdentifier !== null) {
      this.sessionIdentifier = new UserAccountsModel.UserSessionIdentifier(args.sessionIdentifier);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionIdentifier is unset!');
    }
  }
};
UserAccountService.UserSessionService_terminateSession_args.prototype = {};
UserAccountService.UserSessionService_terminateSession_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionIdentifier = new UserAccountsModel.UserSessionIdentifier();
        this.sessionIdentifier.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserAccountService.UserSessionService_terminateSession_args.prototype.write = function(output) {
  output.writeStructBegin('UserSessionService_terminateSession_args');
  if (this.sessionIdentifier !== null && this.sessionIdentifier !== undefined) {
    output.writeFieldBegin('sessionIdentifier', Thrift.Type.STRUCT, 1);
    this.sessionIdentifier.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserAccountService.UserSessionService_terminateSession_result = function(args) {
  this.serviceTemporarilyUnavailableException = null;
  this.rateLimitExceededException = null;
  this.unknownSessionIdentifierException = null;
  this.sessionAlreadyTerminatedException = null;
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.SessionAlreadyTerminatedException) {
    this.sessionAlreadyTerminatedException = args;
    return;
  }
  if (args) {
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.sessionAlreadyTerminatedException !== undefined && args.sessionAlreadyTerminatedException !== null) {
      this.sessionAlreadyTerminatedException = args.sessionAlreadyTerminatedException;
    }
  }
};
UserAccountService.UserSessionService_terminateSession_result.prototype = {};
UserAccountService.UserSessionService_terminateSession_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionAlreadyTerminatedException = new AccountsExceptions.SessionAlreadyTerminatedException();
        this.sessionAlreadyTerminatedException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserAccountService.UserSessionService_terminateSession_result.prototype.write = function(output) {
  output.writeStructBegin('UserSessionService_terminateSession_result');
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 1);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 2);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.sessionAlreadyTerminatedException !== null && this.sessionAlreadyTerminatedException !== undefined) {
    output.writeFieldBegin('sessionAlreadyTerminatedException', Thrift.Type.STRUCT, 4);
    this.sessionAlreadyTerminatedException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserAccountService.UserSessionService_getAccountIdentifierForSessionIdentifier_args = function(args) {
  this.sessionIdentifier = null;
  if (args) {
    if (args.sessionIdentifier !== undefined && args.sessionIdentifier !== null) {
      this.sessionIdentifier = new UserAccountsModel.UserSessionIdentifier(args.sessionIdentifier);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionIdentifier is unset!');
    }
  }
};
UserAccountService.UserSessionService_getAccountIdentifierForSessionIdentifier_args.prototype = {};
UserAccountService.UserSessionService_getAccountIdentifierForSessionIdentifier_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionIdentifier = new UserAccountsModel.UserSessionIdentifier();
        this.sessionIdentifier.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserAccountService.UserSessionService_getAccountIdentifierForSessionIdentifier_args.prototype.write = function(output) {
  output.writeStructBegin('UserSessionService_getAccountIdentifierForSessionIdentifier_args');
  if (this.sessionIdentifier !== null && this.sessionIdentifier !== undefined) {
    output.writeFieldBegin('sessionIdentifier', Thrift.Type.STRUCT, 1);
    this.sessionIdentifier.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserAccountService.UserSessionService_getAccountIdentifierForSessionIdentifier_result = function(args) {
  this.success = null;
  this.serviceTemporarilyUnavailableException = null;
  this.rateLimitExceededException = null;
  this.unknownSessionIdentifierException = null;
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new UserAccountsModel.UserAccountIdentifier(args.success);
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
  }
};
UserAccountService.UserSessionService_getAccountIdentifierForSessionIdentifier_result.prototype = {};
UserAccountService.UserSessionService_getAccountIdentifierForSessionIdentifier_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new UserAccountsModel.UserAccountIdentifier();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserAccountService.UserSessionService_getAccountIdentifierForSessionIdentifier_result.prototype.write = function(output) {
  output.writeStructBegin('UserSessionService_getAccountIdentifierForSessionIdentifier_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 1);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 2);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserAccountService.UserSessionServiceClient = function(input, output) {
    this.input = input;
    this.output = (!output) ? input : output;
    this.seqid = 0;
};
UserAccountService.UserSessionServiceClient.prototype = {};
UserAccountService.UserSessionServiceClient.prototype.createSessionForAuthenticatedAccount = function(access_token, id_token, unauthenticatedUserSessionId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_createSessionForAuthenticatedAccount(pendingError, access_token, id_token, unauthenticatedUserSessionId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_createSessionForAuthenticatedAccount();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

UserAccountService.UserSessionServiceClient.prototype.send_createSessionForAuthenticatedAccount = function(pendingError, access_token, id_token, unauthenticatedUserSessionId, callback) {
  this.output.writeMessageBegin('createSessionForAuthenticatedAccount', Thrift.MessageType.CALL, this.seqid);
  var args = new UserAccountService.UserSessionService_createSessionForAuthenticatedAccount_args();
  args.access_token = access_token;
  args.id_token = id_token;
  args.unauthenticatedUserSessionId = unauthenticatedUserSessionId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_createSessionForAuthenticatedAccount() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

UserAccountService.UserSessionServiceClient.prototype.recv_createSessionForAuthenticatedAccount = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new UserAccountService.UserSessionService_createSessionForAuthenticatedAccount_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.unknownCredentialsException) {
    throw result.unknownCredentialsException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'createSessionForAuthenticatedAccount failed: unknown result';
};
UserAccountService.UserSessionServiceClient.prototype.terminateSession = function(sessionIdentifier, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_terminateSession(pendingError, sessionIdentifier, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_terminateSession();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

UserAccountService.UserSessionServiceClient.prototype.send_terminateSession = function(pendingError, sessionIdentifier, callback) {
  this.output.writeMessageBegin('terminateSession', Thrift.MessageType.CALL, this.seqid);
  var args = new UserAccountService.UserSessionService_terminateSession_args();
  args.sessionIdentifier = sessionIdentifier;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_terminateSession() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

UserAccountService.UserSessionServiceClient.prototype.recv_terminateSession = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new UserAccountService.UserSessionService_terminateSession_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.sessionAlreadyTerminatedException) {
    throw result.sessionAlreadyTerminatedException;
  }
  return;
};
UserAccountService.UserSessionServiceClient.prototype.getAccountIdentifierForSessionIdentifier = function(sessionIdentifier, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getAccountIdentifierForSessionIdentifier(pendingError, sessionIdentifier, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getAccountIdentifierForSessionIdentifier();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

UserAccountService.UserSessionServiceClient.prototype.send_getAccountIdentifierForSessionIdentifier = function(pendingError, sessionIdentifier, callback) {
  this.output.writeMessageBegin('getAccountIdentifierForSessionIdentifier', Thrift.MessageType.CALL, this.seqid);
  var args = new UserAccountService.UserSessionService_getAccountIdentifierForSessionIdentifier_args();
  args.sessionIdentifier = sessionIdentifier;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getAccountIdentifierForSessionIdentifier() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

UserAccountService.UserSessionServiceClient.prototype.recv_getAccountIdentifierForSessionIdentifier = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new UserAccountService.UserSessionService_getAccountIdentifierForSessionIdentifier_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getAccountIdentifierForSessionIdentifier failed: unknown result';
};
