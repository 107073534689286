//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';
import CoreMathModel from './core_math_Model_types';
import SalesItemModel from './sales_item_Model_types';



var SalesReportModel = {};
export default SalesReportModel;
SalesReportModel.SalesEntry = function(args) {
  this.price = null;
  this.quantity = null;
  this.quantityAdjustment = null;
  this.salesAdjustment = null;
  if (args) {
    if (args.price !== undefined && args.price !== null) {
      this.price = new CoreMathModel.Decimal(args.price);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field price is unset!');
    }
    if (args.quantity !== undefined && args.quantity !== null) {
      this.quantity = new CoreMathModel.Decimal(args.quantity);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field quantity is unset!');
    }
    if (args.quantityAdjustment !== undefined && args.quantityAdjustment !== null) {
      this.quantityAdjustment = new CoreMathModel.Decimal(args.quantityAdjustment);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field quantityAdjustment is unset!');
    }
    if (args.salesAdjustment !== undefined && args.salesAdjustment !== null) {
      this.salesAdjustment = new CoreMathModel.Decimal(args.salesAdjustment);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field salesAdjustment is unset!');
    }
  }
};
SalesReportModel.SalesEntry.prototype = {};
SalesReportModel.SalesEntry.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.price = new CoreMathModel.Decimal();
        this.price.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.quantity = new CoreMathModel.Decimal();
        this.quantity.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.quantityAdjustment = new CoreMathModel.Decimal();
        this.quantityAdjustment.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.salesAdjustment = new CoreMathModel.Decimal();
        this.salesAdjustment.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SalesReportModel.SalesEntry.prototype.write = function(output) {
  output.writeStructBegin('SalesEntry');
  if (this.price !== null && this.price !== undefined) {
    output.writeFieldBegin('price', Thrift.Type.STRUCT, 1);
    this.price.write(output);
    output.writeFieldEnd();
  }
  if (this.quantity !== null && this.quantity !== undefined) {
    output.writeFieldBegin('quantity', Thrift.Type.STRUCT, 2);
    this.quantity.write(output);
    output.writeFieldEnd();
  }
  if (this.quantityAdjustment !== null && this.quantityAdjustment !== undefined) {
    output.writeFieldBegin('quantityAdjustment', Thrift.Type.STRUCT, 3);
    this.quantityAdjustment.write(output);
    output.writeFieldEnd();
  }
  if (this.salesAdjustment !== null && this.salesAdjustment !== undefined) {
    output.writeFieldBegin('salesAdjustment', Thrift.Type.STRUCT, 4);
    this.salesAdjustment.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SalesReportModel.SalesItemIdAndSalesEntry = function(args) {
  this.salesItemId = null;
  this.salesEntry = null;
  if (args) {
    if (args.salesItemId !== undefined && args.salesItemId !== null) {
      this.salesItemId = new SalesItemModel.SalesItemId(args.salesItemId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field salesItemId is unset!');
    }
    if (args.salesEntry !== undefined && args.salesEntry !== null) {
      this.salesEntry = new SalesReportModel.SalesEntry(args.salesEntry);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field salesEntry is unset!');
    }
  }
};
SalesReportModel.SalesItemIdAndSalesEntry.prototype = {};
SalesReportModel.SalesItemIdAndSalesEntry.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.salesItemId = new SalesItemModel.SalesItemId();
        this.salesItemId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.salesEntry = new SalesReportModel.SalesEntry();
        this.salesEntry.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SalesReportModel.SalesItemIdAndSalesEntry.prototype.write = function(output) {
  output.writeStructBegin('SalesItemIdAndSalesEntry');
  if (this.salesItemId !== null && this.salesItemId !== undefined) {
    output.writeFieldBegin('salesItemId', Thrift.Type.STRUCT, 1);
    this.salesItemId.write(output);
    output.writeFieldEnd();
  }
  if (this.salesEntry !== null && this.salesEntry !== undefined) {
    output.writeFieldBegin('salesEntry', Thrift.Type.STRUCT, 2);
    this.salesEntry.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

