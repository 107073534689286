import * as moment from 'moment-timezone';

import { InvoiceUploadId } from 'api/Ordering/model/InvoiceUploadId';
import { InvoiceProcessingResult } from 'api/Ordering/model/InvoiceProcessingResult';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export class InvoiceProcessingData {
    constructor (
        private readonly requestUserId : UserAccountId | null,
        private readonly requestTime : moment.Moment | null,
        private readonly processedInvoiceUploadIdsByChildInvoiceExternalId : { [k: string] : null | InvoiceUploadId },
        private readonly result : InvoiceProcessingResult | null,
        private readonly resultTime : moment.Moment | null,
        private readonly reviewUserId : UserAccountId | null,
        private readonly reviewTime : moment.Moment | null,
    ) {
        if ((requestUserId === null) !== (requestTime === null)) {
            throw new RuntimeException('"requestUserId" and "requestTime" must exist together');
        }

        if ((result === null) !== (resultTime === null)) {
            throw new RuntimeException('"result" and "resultTime" must exist together');
        }

        if ((reviewUserId === null) !== (reviewTime === null)) {
            throw new RuntimeException('"result" and "resultTime" must exist together');
        }

        if ((result === null) && (reviewUserId !== null)) {
            throw new RuntimeException('"reviewUserId" unexpectedly exists without "result"');
        }
    }

    public getRequestUserId() : UserAccountId | null {
        return this.requestUserId;
    }

    public getRequestTime() : moment.Moment | null {
        return this.requestTime;
    }

    public getResult() : InvoiceProcessingResult | null {
        return this.result;
    }

    public getResultTime() : moment.Moment | null {
        return this.resultTime;
    }

    public getReviewUserId() : UserAccountId | null {
        return this.reviewUserId;
    }

    public getReviewTime() : moment.Moment | null {
        return this.reviewTime;
    }

    public getProcessedInvoiceUploadIdsByChildInvoiceExternalId() : { [k: string] : null | InvoiceUploadId } {
        return this.processedInvoiceUploadIdsByChildInvoiceExternalId;
    }
}
