import moment from 'moment-timezone';

import { AxisPosition } from 'shared/models/Charts/Axes/AxisPosition';
import { DataAxisType } from 'shared/models/Charts/Axes/DataAxisType';
import { IAxisTick } from 'shared/models/Charts/Axes/IAxisTick';
import { IValuesByCategoryNameWithAdditionalData } from 'shared/models/Charts/IValuesByCategoryNameWithAdditionalData';
import { IValueWithAdditionalData } from 'shared/models/Charts/IValueWithAdditionalData';

import { IGridLineData, utils as chartUtils } from 'shared/components/Charts/utils';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import {
    axisLabelLengthInPixels,
    axisTickLabelDistanceFromLineInPixels,
    axisTickLabelHeightInPixels,
    axisTickLineLengthInPixels,
    maxAxisLengthInPixels,
} from 'shared/components/Charts/Axes/constants';

const calculateDataAxisTicksForValuesWithAdditionalData = (
    dataAxisType : DataAxisType,
    valuesWithAdditionalData : ReadonlyArray<IValueWithAdditionalData>,
    axisWidthInPixels : number,
) : ReadonlyArray<IAxisTick> => {
    const dataArray : ReadonlyArray<string> = valuesWithAdditionalData.map(
        (valueWithAdditionalData : IValueWithAdditionalData) => {
            return valueWithAdditionalData.data;
        }
    );

    return calculateDataAxisTicks(
        dataAxisType,
        dataArray,
        axisWidthInPixels);
};

const calculateDataAxisTicksForValuesByCategoryNameWithAdditionalData = (
    dataAxisType : DataAxisType,
    arrayOfValuesByCategoryNameWithAdditionalData : ReadonlyArray<IValuesByCategoryNameWithAdditionalData>,
    axisWidthInPixels : number,
) : ReadonlyArray<IAxisTick> => {
    const dataArray : ReadonlyArray<string> = arrayOfValuesByCategoryNameWithAdditionalData.map(
        (valuesByCategoryNameWithAdditionalData : IValuesByCategoryNameWithAdditionalData) => {
            return valuesByCategoryNameWithAdditionalData.data;
        }
    );

    return calculateDataAxisTicks(
        dataAxisType,
        dataArray,
        axisWidthInPixels);
};

const calculateValueAxisTicks = (
    gridLineData : IGridLineData,
    valueAxisTickLabelFormatter : (value : number) => string,
) : ReadonlyArray<IAxisTick> => {
    const {
        numberOfGridLines,
        valueOfGapBetweenGridLines,
        numberOfGapsBelowZero,
    } = gridLineData;

    const axisTicks : Array<IAxisTick> = [];
    const minValue : number = -valueOfGapBetweenGridLines * numberOfGapsBelowZero;

    for (let index = 0; index < numberOfGridLines + 1; index++) {
        const labelValue : string = valueAxisTickLabelFormatter(minValue + (valueOfGapBetweenGridLines * index));
        const showTickLine = false;

        axisTicks.push({
            labelValue,
            showTickLine,
        });
    }

    return axisTicks;
};

const calculateAxisLength = (
    axisPosition : AxisPosition,
    axisTicks : ReadonlyArray<IAxisTick>,
    axisLabelValue : string,
) : number => {

    let axisLength : number;
    switch (axisPosition) {
        case AxisPosition.LEFT:
        case AxisPosition.RIGHT: {
            const maxLabelValueWidth : number = calculateMaxLabelValueWidth(axisTicks);
            axisLength = maxLabelValueWidth + axisTickLabelDistanceFromLineInPixels + axisTickLineLengthInPixels;
            break;
        }
        case AxisPosition.TOP:
        case AxisPosition.BOTTOM: {
            axisLength = axisTickLabelDistanceFromLineInPixels + axisTickLabelHeightInPixels + axisTickLineLengthInPixels;

            break;
        }
        default: {
            throw new RuntimeException('orientation is unexpected value');
        }
    }

    if (axisLabelValue.length > 0) {
        axisLength += axisLabelLengthInPixels;
    }

    return Math.min(axisLength, maxAxisLengthInPixels);
};

////////////////////////////////
// Private
////////////////////////////////
const calculateDataAxisTicks = (
    dataAxisType : DataAxisType,
    dataArray : ReadonlyArray<string>,
    axisWidthInPixels : number,
) : ReadonlyArray<IAxisTick> => {
    switch (dataAxisType) {
        case DataAxisType.DATE:
            return calculateDataAxisTicksForDateType(
                dataArray,
                axisWidthInPixels);
        default:
            throw new RuntimeException('unexpected value for dataAxisType');
    }
};

const calculateDataAxisTicksForDateType = (
    dataArray : ReadonlyArray<string>,
    axisWidthInPixels : number,
) : ReadonlyArray<IAxisTick> => {
    const maxNumberOfLabelsToDisplayForMonthAndDayFormat = Math.floor(axisWidthInPixels / getWidthOfLabelWithPaddingInPixels(4));
    const maxNumberOfLabelsToDisplayForMondayFormat = Math.floor(axisWidthInPixels / getWidthOfLabelWithPaddingInPixels(6));

    const numberOfDays = dataArray.length;

    let labelFunction : (date : moment.Moment) => string;
    let numberOfTicksBetweenShownLabels : number;
    let showAllTicks : boolean;

    if (numberOfDays < maxNumberOfLabelsToDisplayForMonthAndDayFormat) { // day
        labelFunction = getLabelForEveryDay;
        numberOfTicksBetweenShownLabels = 0;
        showAllTicks = true;

    } else if (numberOfDays / 2 < maxNumberOfLabelsToDisplayForMonthAndDayFormat) { // day + skip every other
        labelFunction = getLabelForEveryDay;
        numberOfTicksBetweenShownLabels = 1;
        showAllTicks = true;

    } else if (numberOfDays / 3 < maxNumberOfLabelsToDisplayForMonthAndDayFormat) { // day + skip 2 between labels
        labelFunction = getLabelForEveryDay;
        numberOfTicksBetweenShownLabels = 2;
        showAllTicks = true;

    } else if (numberOfDays / 7 < maxNumberOfLabelsToDisplayForMondayFormat) { // week
        labelFunction = getLabelForEveryMonday;
        numberOfTicksBetweenShownLabels = 0;
        showAllTicks = false;

    } else if (numberOfDays / 30 < maxNumberOfLabelsToDisplayForMonthAndDayFormat) { // month
        labelFunction = getLabelForFirstDayOfEveryMonth;
        numberOfTicksBetweenShownLabels = 0;
        showAllTicks = false;

    } else { // quarter
        labelFunction = getLabelForFirstDayOfEveryQuarter;
        numberOfTicksBetweenShownLabels = 0;
        showAllTicks = false;
    }

    let numberOfTicksSinceLastLabel = numberOfTicksBetweenShownLabels;
    return dataArray.map((dateValue : string) => {
        let labelValue : string;
        if (numberOfTicksSinceLastLabel === numberOfTicksBetweenShownLabels) {
            labelValue = labelFunction(moment(dateValue));
            numberOfTicksSinceLastLabel = 0;
        } else {
            labelValue = '';
            numberOfTicksSinceLastLabel++;
        }

        let showTickLine : boolean;
        if (showAllTicks) {
            showTickLine = true;
        } else {
            showTickLine = labelValue.length > 0;
        }

        return {
            labelValue,
            showTickLine,
        };
    });
};

const getWidthOfLabelWithPaddingInPixels = (labelLength : number) : number => {
    const approximateWidthOfCharacterInPixels = 9;
    const labelPaddingInPixels = 15;

    return labelLength * approximateWidthOfCharacterInPixels + (labelPaddingInPixels * 2);
};

const calculateMaxLabelValueWidth = (
    axisTicks : ReadonlyArray<IAxisTick>,
) : number => {
    if (axisTicks.length === 0) {
        return 0;
    }

    return Math.max.apply(Number,
        axisTicks.map((axisTick : IAxisTick) => {
            return chartUtils.estimateTextWidth(axisTick.labelValue);
        })
    );
};

////////////////////////////////
// Date Formatters
////////////////////////////////
const getLabelForEveryDay = (
    date : moment.Moment,
) : string => {
    return date.format('M/D');
};

const getLabelForEveryMonday = (
    date : moment.Moment,
) : string => {
    const dayOfWeek : number = date.day();

    if (dayOfWeek === 1) {
        return date.format('[M] M/D');
    }

    return '';
};

const getLabelForFirstDayOfEveryMonth = (
    date : moment.Moment,
) : string => {
    const dayOfMonth : number = date.date();

    if (dayOfMonth === 1) {
        return date.format('M/D');
    }

    return '';
};

const getLabelForFirstDayOfEveryQuarter = (
    date : moment.Moment,
) : string => {
    const dayOfMonth : number = date.date();
    const monthOfYear : number = date.month();

    if (dayOfMonth === 1 && monthOfYear % 3 === 0) {
        return date.format('M/D');
    }

    return '';
};

////////////////////////////////
export const utils = {
    calculateDataAxisTicksForValuesWithAdditionalData,
    calculateDataAxisTicksForValuesByCategoryNameWithAdditionalData,
    calculateValueAxisTicks,
    calculateAxisLength,
};
