import { StringValueMap } from 'api/Core/StringValueMap';
import { PrepEvent } from 'api/PrepEvent/model/PrepEvent';
import { PrepEventId } from 'api/PrepEvent/model/PrepEventId';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { GroupByOption } from 'shared/models/GroupByOption';
import { SortDirection } from 'shared/models/SortDirection';

import { IColumnSorting } from 'shared/components/SortableColumnHeader';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { cleanPhrase, SortingFilteringAndGroupingUtil } from 'shared/utils/sortingFilteringAndGroupingUtils';

interface IPrepEventSortingUtilExtraArgs {
    prepEventsById : StringValueMap<PrepEventId, PrepEvent>;
    productsById : StringValueMap<ProductId, Product>;
}

const isTokenInString = (str : string, token : string) : boolean => {
    return (str.indexOf(token) > -1);
};

// for now, can search by: type, ingredient names, prepared item names
// what about category of the products?
export const createPrepEventStringForFilter = (prepEvent : PrepEvent, productsById : StringValueMap<ProductId, Product>) : string => {
    const typeLabel = prepEvent.getType();

    const outputProductIds = Array.from(prepEvent.getOutputQuantityOfProductByProductId().keys());
    const inputAndOutputProductIds = Array.from(prepEvent.getInputQuantityOfProductByProductId().keys()).concat(outputProductIds);
    const productStrings : Array<string> = inputAndOutputProductIds.map((productId) => {
        const product = productsById.get(productId);
        if (typeof product === 'undefined') {
            throw new RuntimeException('unexpected');
        }

        return cleanPhrase(product.getBrand()) + ' ' + cleanPhrase(product.getName());
    });

    const prepEventStringArray = [typeLabel].concat(productStrings);

    return prepEventStringArray.join(' ');
};

const prepEventFilterStringCache = new StringValueMap<PrepEventId, string>();
let lastPrepEventsById = new StringValueMap<PrepEventId, PrepEvent>();

const resetCaches = (newPrepEventsById : StringValueMap<PrepEventId, PrepEvent>) => {
    lastPrepEventsById = newPrepEventsById;
    prepEventFilterStringCache.clear();
};

const cachedCreatePrepEventStringForFilter = (
    prepEventId : PrepEventId,
    prepEventsById : StringValueMap<PrepEventId, PrepEvent>,
    productsById : StringValueMap<ProductId, Product>,
) : string => {

    if (prepEventsById !== lastPrepEventsById) {
        resetCaches(prepEventsById);
    }

    if (prepEventFilterStringCache.has(prepEventId)) {
        const cacheValue = prepEventFilterStringCache.get(prepEventId);

        if (typeof cacheValue === 'undefined') {
            throw new RuntimeException('unexpected');
        }

        return cacheValue;
    }

    const prepEvent = prepEventsById.get(prepEventId);
    if (typeof prepEvent === 'undefined') {
        throw new RuntimeException('unexpected');
    }

    const prepEventStringForFilter = createPrepEventStringForFilter(prepEvent, productsById);
    prepEventFilterStringCache.set(prepEventId, prepEventStringForFilter);

    return prepEventStringForFilter;
};

const rowIdComparator = (prepEventId1 : PrepEventId, prepEventId2 : PrepEventId, columnSort : IColumnSorting, extraArgs : IPrepEventSortingUtilExtraArgs) : number => {
    const direction = columnSort.direction;
    const sortedBy = columnSort.sortedBy;

    const prepEvent1 = extraArgs.prepEventsById.get(prepEventId1);
    const prepEvent2 = extraArgs.prepEventsById.get(prepEventId2);

    if ((typeof prepEvent1 === 'undefined') || (typeof prepEvent2 === 'undefined')) {
        throw new RuntimeException('unexpected');
    }

    let comparisonResult : number;
    switch (sortedBy) {
        case 'date':
            comparisonResult = prepEvent2.getPrepEventTime().diff(prepEvent1.getPrepEventTime());
            break;
        default:
            throw new RuntimeException('unhandled sort field');
    }

    if (direction === SortDirection.DESCENDING) {
        return comparisonResult * -1;
    }

    return comparisonResult;
};

const rowIdFilterFunction = (prepEventId : PrepEventId, cleanedFilterTerm : string | null, extraArgs : IPrepEventSortingUtilExtraArgs) : boolean => {
    if (cleanedFilterTerm === null || cleanedFilterTerm === '') {
        return true;
    }

    const prepEventStringForSearch = cachedCreatePrepEventStringForFilter(prepEventId, extraArgs.prepEventsById, extraArgs.productsById);
    const filterTokens = cleanedFilterTerm.split(' ');

    for (let i = 0; i < filterTokens.length; i++) {
        if (!isTokenInString(prepEventStringForSearch, filterTokens[i])) {
            return false;
        }
    }

    return true;
};

const getGroupNameForRowId = (prepEventId : PrepEventId, groupByOption : GroupByOption, extraArgs : IPrepEventSortingUtilExtraArgs) : string => {
    return 'All Items'; // not implemented for now, because no groupings in the table
};

const groupComparator = (groupName1 : string, groupName2 : string, groupByOption : GroupByOption, extraArgs : IPrepEventSortingUtilExtraArgs) : number => {
    // should not matter
    return 0;
};

const intermediateResultCacheIsValid = (rowIds : Array<PrepEventId>, lastRowIds : Array<PrepEventId>, extraArgs : IPrepEventSortingUtilExtraArgs, lastExtraArgs : IPrepEventSortingUtilExtraArgs) : boolean => {
    return (extraArgs.prepEventsById === lastExtraArgs.prepEventsById) &&
            (extraArgs.productsById === lastExtraArgs.productsById);
};

export const PrepEventsSortingUtil = new SortingFilteringAndGroupingUtil(
    rowIdComparator,
    rowIdFilterFunction,
    getGroupNameForRowId,
    groupComparator,
    intermediateResultCacheIsValid
);
