//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';
import CoreTimeModel from './core_time_Model_types';
import UserAccountsModel from './accounts_user_Model_types';
import LocationModel from './location_Model_types';
import ProductModel from './product_Model_types';



var ProductMappingModel = {};
export default ProductMappingModel;
ProductMappingModel.ProductMatchAlgorithm = {
  '0' : 'MANUAL',
  'MANUAL' : 0,
  '1' : 'AUTOMATCH0',
  'AUTOMATCH0' : 1,
  '2' : 'AUTOMATCH1',
  'AUTOMATCH1' : 2
};
ProductMappingModel.InterLocationProductQuantityUnitMapping = function(args) {
  this.sourceLocationIdentifier = null;
  this.sourceProductId = null;
  this.sourceQuantityOfProduct = null;
  this.targetLocationIdentifier = null;
  this.targetProductId = null;
  this.targetQuantityOfProduct = null;
  if (args) {
    if (args.sourceLocationIdentifier !== undefined && args.sourceLocationIdentifier !== null) {
      this.sourceLocationIdentifier = new LocationModel.LocationIdentifier(args.sourceLocationIdentifier);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sourceLocationIdentifier is unset!');
    }
    if (args.sourceProductId !== undefined && args.sourceProductId !== null) {
      this.sourceProductId = new ProductModel.ProductId(args.sourceProductId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sourceProductId is unset!');
    }
    if (args.sourceQuantityOfProduct !== undefined && args.sourceQuantityOfProduct !== null) {
      this.sourceQuantityOfProduct = new ProductModel.QuantityOfProduct(args.sourceQuantityOfProduct);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sourceQuantityOfProduct is unset!');
    }
    if (args.targetLocationIdentifier !== undefined && args.targetLocationIdentifier !== null) {
      this.targetLocationIdentifier = new LocationModel.LocationIdentifier(args.targetLocationIdentifier);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field targetLocationIdentifier is unset!');
    }
    if (args.targetProductId !== undefined && args.targetProductId !== null) {
      this.targetProductId = new ProductModel.ProductId(args.targetProductId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field targetProductId is unset!');
    }
    if (args.targetQuantityOfProduct !== undefined && args.targetQuantityOfProduct !== null) {
      this.targetQuantityOfProduct = new ProductModel.QuantityOfProduct(args.targetQuantityOfProduct);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field targetQuantityOfProduct is unset!');
    }
  }
};
ProductMappingModel.InterLocationProductQuantityUnitMapping.prototype = {};
ProductMappingModel.InterLocationProductQuantityUnitMapping.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sourceLocationIdentifier = new LocationModel.LocationIdentifier();
        this.sourceLocationIdentifier.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.sourceProductId = new ProductModel.ProductId();
        this.sourceProductId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.sourceQuantityOfProduct = new ProductModel.QuantityOfProduct();
        this.sourceQuantityOfProduct.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.targetLocationIdentifier = new LocationModel.LocationIdentifier();
        this.targetLocationIdentifier.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.targetProductId = new ProductModel.ProductId();
        this.targetProductId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.targetQuantityOfProduct = new ProductModel.QuantityOfProduct();
        this.targetQuantityOfProduct.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductMappingModel.InterLocationProductQuantityUnitMapping.prototype.write = function(output) {
  output.writeStructBegin('InterLocationProductQuantityUnitMapping');
  if (this.sourceLocationIdentifier !== null && this.sourceLocationIdentifier !== undefined) {
    output.writeFieldBegin('sourceLocationIdentifier', Thrift.Type.STRUCT, 1);
    this.sourceLocationIdentifier.write(output);
    output.writeFieldEnd();
  }
  if (this.sourceProductId !== null && this.sourceProductId !== undefined) {
    output.writeFieldBegin('sourceProductId', Thrift.Type.STRUCT, 2);
    this.sourceProductId.write(output);
    output.writeFieldEnd();
  }
  if (this.sourceQuantityOfProduct !== null && this.sourceQuantityOfProduct !== undefined) {
    output.writeFieldBegin('sourceQuantityOfProduct', Thrift.Type.STRUCT, 3);
    this.sourceQuantityOfProduct.write(output);
    output.writeFieldEnd();
  }
  if (this.targetLocationIdentifier !== null && this.targetLocationIdentifier !== undefined) {
    output.writeFieldBegin('targetLocationIdentifier', Thrift.Type.STRUCT, 4);
    this.targetLocationIdentifier.write(output);
    output.writeFieldEnd();
  }
  if (this.targetProductId !== null && this.targetProductId !== undefined) {
    output.writeFieldBegin('targetProductId', Thrift.Type.STRUCT, 5);
    this.targetProductId.write(output);
    output.writeFieldEnd();
  }
  if (this.targetQuantityOfProduct !== null && this.targetQuantityOfProduct !== undefined) {
    output.writeFieldBegin('targetQuantityOfProduct', Thrift.Type.STRUCT, 6);
    this.targetQuantityOfProduct.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductMappingModel.ProductQuantityUnitMappingUpdateEvent = function(args) {
  this.sourceProductId = null;
  this.sourceQuantityOfProduct = null;
  this.targetProductId = null;
  this.targetQuantityOfProduct = null;
  this.updateTime = null;
  this.actor = null;
  this.matchAlgorithm = null;
  if (args) {
    if (args.sourceProductId !== undefined && args.sourceProductId !== null) {
      this.sourceProductId = new ProductModel.ProductId(args.sourceProductId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sourceProductId is unset!');
    }
    if (args.sourceQuantityOfProduct !== undefined && args.sourceQuantityOfProduct !== null) {
      this.sourceQuantityOfProduct = new ProductModel.QuantityOfProduct(args.sourceQuantityOfProduct);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sourceQuantityOfProduct is unset!');
    }
    if (args.targetProductId !== undefined && args.targetProductId !== null) {
      this.targetProductId = new ProductModel.ProductId(args.targetProductId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field targetProductId is unset!');
    }
    if (args.targetQuantityOfProduct !== undefined && args.targetQuantityOfProduct !== null) {
      this.targetQuantityOfProduct = new ProductModel.QuantityOfProduct(args.targetQuantityOfProduct);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field targetQuantityOfProduct is unset!');
    }
    if (args.updateTime !== undefined && args.updateTime !== null) {
      this.updateTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.updateTime);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field updateTime is unset!');
    }
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserAccountIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.matchAlgorithm !== undefined && args.matchAlgorithm !== null) {
      this.matchAlgorithm = args.matchAlgorithm;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field matchAlgorithm is unset!');
    }
  }
};
ProductMappingModel.ProductQuantityUnitMappingUpdateEvent.prototype = {};
ProductMappingModel.ProductQuantityUnitMappingUpdateEvent.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sourceProductId = new ProductModel.ProductId();
        this.sourceProductId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.sourceQuantityOfProduct = new ProductModel.QuantityOfProduct();
        this.sourceQuantityOfProduct.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.targetProductId = new ProductModel.ProductId();
        this.targetProductId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.targetQuantityOfProduct = new ProductModel.QuantityOfProduct();
        this.targetQuantityOfProduct.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.updateTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.updateTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserAccountIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.I32) {
        this.matchAlgorithm = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductMappingModel.ProductQuantityUnitMappingUpdateEvent.prototype.write = function(output) {
  output.writeStructBegin('ProductQuantityUnitMappingUpdateEvent');
  if (this.sourceProductId !== null && this.sourceProductId !== undefined) {
    output.writeFieldBegin('sourceProductId', Thrift.Type.STRUCT, 1);
    this.sourceProductId.write(output);
    output.writeFieldEnd();
  }
  if (this.sourceQuantityOfProduct !== null && this.sourceQuantityOfProduct !== undefined) {
    output.writeFieldBegin('sourceQuantityOfProduct', Thrift.Type.STRUCT, 2);
    this.sourceQuantityOfProduct.write(output);
    output.writeFieldEnd();
  }
  if (this.targetProductId !== null && this.targetProductId !== undefined) {
    output.writeFieldBegin('targetProductId', Thrift.Type.STRUCT, 3);
    this.targetProductId.write(output);
    output.writeFieldEnd();
  }
  if (this.targetQuantityOfProduct !== null && this.targetQuantityOfProduct !== undefined) {
    output.writeFieldBegin('targetQuantityOfProduct', Thrift.Type.STRUCT, 4);
    this.targetQuantityOfProduct.write(output);
    output.writeFieldEnd();
  }
  if (this.updateTime !== null && this.updateTime !== undefined) {
    output.writeFieldBegin('updateTime', Thrift.Type.STRUCT, 5);
    this.updateTime.write(output);
    output.writeFieldEnd();
  }
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 6);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.matchAlgorithm !== null && this.matchAlgorithm !== undefined) {
    output.writeFieldBegin('matchAlgorithm', Thrift.Type.I32, 7);
    output.writeI32(this.matchAlgorithm);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductMappingModel.InterLocationProductMapping = function(args) {
  this.sourceLocationIdentifier = null;
  this.sourceProductId = null;
  this.targetLocationIdentifier = null;
  this.targetProductId = null;
  if (args) {
    if (args.sourceLocationIdentifier !== undefined && args.sourceLocationIdentifier !== null) {
      this.sourceLocationIdentifier = new LocationModel.LocationIdentifier(args.sourceLocationIdentifier);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sourceLocationIdentifier is unset!');
    }
    if (args.sourceProductId !== undefined && args.sourceProductId !== null) {
      this.sourceProductId = new ProductModel.ProductId(args.sourceProductId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sourceProductId is unset!');
    }
    if (args.targetLocationIdentifier !== undefined && args.targetLocationIdentifier !== null) {
      this.targetLocationIdentifier = new LocationModel.LocationIdentifier(args.targetLocationIdentifier);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field targetLocationIdentifier is unset!');
    }
    if (args.targetProductId !== undefined && args.targetProductId !== null) {
      this.targetProductId = new ProductModel.ProductId(args.targetProductId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field targetProductId is unset!');
    }
  }
};
ProductMappingModel.InterLocationProductMapping.prototype = {};
ProductMappingModel.InterLocationProductMapping.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sourceLocationIdentifier = new LocationModel.LocationIdentifier();
        this.sourceLocationIdentifier.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.sourceProductId = new ProductModel.ProductId();
        this.sourceProductId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.targetLocationIdentifier = new LocationModel.LocationIdentifier();
        this.targetLocationIdentifier.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.targetProductId = new ProductModel.ProductId();
        this.targetProductId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductMappingModel.InterLocationProductMapping.prototype.write = function(output) {
  output.writeStructBegin('InterLocationProductMapping');
  if (this.sourceLocationIdentifier !== null && this.sourceLocationIdentifier !== undefined) {
    output.writeFieldBegin('sourceLocationIdentifier', Thrift.Type.STRUCT, 1);
    this.sourceLocationIdentifier.write(output);
    output.writeFieldEnd();
  }
  if (this.sourceProductId !== null && this.sourceProductId !== undefined) {
    output.writeFieldBegin('sourceProductId', Thrift.Type.STRUCT, 2);
    this.sourceProductId.write(output);
    output.writeFieldEnd();
  }
  if (this.targetLocationIdentifier !== null && this.targetLocationIdentifier !== undefined) {
    output.writeFieldBegin('targetLocationIdentifier', Thrift.Type.STRUCT, 3);
    this.targetLocationIdentifier.write(output);
    output.writeFieldEnd();
  }
  if (this.targetProductId !== null && this.targetProductId !== undefined) {
    output.writeFieldBegin('targetProductId', Thrift.Type.STRUCT, 4);
    this.targetProductId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductMappingModel.MappingUpdateEvent = function(args) {
  this.sourceProductId = null;
  this.targetProductId = null;
  this.updateTime = null;
  this.actor = null;
  this.matchAlgorithm = null;
  if (args) {
    if (args.sourceProductId !== undefined && args.sourceProductId !== null) {
      this.sourceProductId = new ProductModel.ProductId(args.sourceProductId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sourceProductId is unset!');
    }
    if (args.targetProductId !== undefined && args.targetProductId !== null) {
      this.targetProductId = new ProductModel.ProductId(args.targetProductId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field targetProductId is unset!');
    }
    if (args.updateTime !== undefined && args.updateTime !== null) {
      this.updateTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.updateTime);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field updateTime is unset!');
    }
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserAccountIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.matchAlgorithm !== undefined && args.matchAlgorithm !== null) {
      this.matchAlgorithm = args.matchAlgorithm;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field matchAlgorithm is unset!');
    }
  }
};
ProductMappingModel.MappingUpdateEvent.prototype = {};
ProductMappingModel.MappingUpdateEvent.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sourceProductId = new ProductModel.ProductId();
        this.sourceProductId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.targetProductId = new ProductModel.ProductId();
        this.targetProductId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.updateTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.updateTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserAccountIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I32) {
        this.matchAlgorithm = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductMappingModel.MappingUpdateEvent.prototype.write = function(output) {
  output.writeStructBegin('MappingUpdateEvent');
  if (this.sourceProductId !== null && this.sourceProductId !== undefined) {
    output.writeFieldBegin('sourceProductId', Thrift.Type.STRUCT, 1);
    this.sourceProductId.write(output);
    output.writeFieldEnd();
  }
  if (this.targetProductId !== null && this.targetProductId !== undefined) {
    output.writeFieldBegin('targetProductId', Thrift.Type.STRUCT, 2);
    this.targetProductId.write(output);
    output.writeFieldEnd();
  }
  if (this.updateTime !== null && this.updateTime !== undefined) {
    output.writeFieldBegin('updateTime', Thrift.Type.STRUCT, 3);
    this.updateTime.write(output);
    output.writeFieldEnd();
  }
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 4);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.matchAlgorithm !== null && this.matchAlgorithm !== undefined) {
    output.writeFieldBegin('matchAlgorithm', Thrift.Type.I32, 5);
    output.writeI32(this.matchAlgorithm);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

