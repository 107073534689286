import moment from 'moment-timezone';

import { SubscriptionId } from './SubscriptionId';

export class BillingInvoice {
    constructor (
        private readonly subscriptionId : SubscriptionId | null,
        private readonly amountDue : number,
        private readonly total : number,
        private readonly subtotal : number,
        private readonly date : moment.Moment,
        private readonly nextPaymentAttemptDate : moment.Moment | null,
        private readonly isPaid : boolean,
    ) { }

    public getSubscriptionId() : SubscriptionId | null {
        return this.subscriptionId;
    }

    public getAmountDue() : number {
        return this.amountDue;
    }

    public getTotal() : number {
        return this.total;
    }

    public getSubtotal() : number {
        return this.subtotal;
    }

    public getInvoiceDate() : moment.Moment {
        return this.date;
    }

    public getNextPaymentAttemptDate() : moment.Moment | null {
        return this.nextPaymentAttemptDate;
    }

    public getIsPaid() : boolean {
        return this.isPaid;
    }
}
