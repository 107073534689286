import { ProductAmount } from 'api/Product/model/ProductAmount';
import { ProductId } from 'api/Product/model/ProductId';
import { ICreateInventoryTransferModalForm } from 'apps/InventoryTransfer/models/CreateInventoryTransferModalForm';
import { OrderedProductAmountList } from 'apps/InventoryTransfer/models/OrderedProductAmountList';
import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { ISearchBarState } from 'shared/models/ISearchBarState';

export const NULL_BREAKAGE_TYPE_LABEL = 'Loss';
export const DEFAULT_BREAKAGE_TYPE_LABELS = ['Breakage', 'Comp', NULL_BREAKAGE_TYPE_LABEL, 'Spill', 'Spoilage'];    // ordered

export interface IRecordBreakageState {
    originalAddedProductAmounts : OrderedProductAmountList | null;
    addedProductAmounts : OrderedProductAmountList;
    form : ICreateInventoryTransferModalForm;
    note : string | null;
    type : string | null;
    typeOptions : Array<IOption>;
    productAmountToBeAdded : ProductAmount | null;
    searchBar : ISearchBarState;
    searchResults : Array<ProductId>;
    displayedSearchResults : Array<ProductId>;
    itemInProgressDialogShown : boolean;
    emptyTransferListDialogShown : boolean;
    discardProgressDialogShown : boolean;
    mobilePanelIsOpen : boolean;
    isDirty : boolean;
    isLoading : boolean;
}
