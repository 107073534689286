import { BillingAddOn, DEFAULT_PRICE_PER_INVOICE, IBillingAddOnService } from 'api/Billing/interfaces/IBillingAddOnService';
import { LocationId } from 'api/Location/model/LocationId';
import { AjaxUtils } from 'shared/utils/ajaxUtils';

export class BillingAddOnServiceImpl implements IBillingAddOnService {

    public getPricePerInvoiceForInvoiceProcessingForRetailer(locationId : LocationId) : Promise<number> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
            billing_info: 'price_per_invoice'
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:billing_add_ons'), queryParameters)
        .then((response : { price_per_invoice : number | null }) => {
            return response.price_per_invoice || DEFAULT_PRICE_PER_INVOICE;
        });
    }

    public enableAddOnsForRetailer(
        locationId : LocationId,
        addOnsToEnable : Set<BillingAddOn>,
        pricePerInvoice : number,
    ) : Promise<void> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
        };

        const requestBody = {
            item_level_sales: addOnsToEnable.has('itemLevelSales'),
            invoice_processing: addOnsToEnable.has('invoiceProcessing'),
            price_per_invoice: pricePerInvoice,
        };

        return AjaxUtils.ajaxPut(urlWithoutRetailerId('api:billing_add_ons'), requestBody, queryParameters);
    }
}
