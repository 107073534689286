import React from 'react';
import MediaQuery from 'react-responsive';

import { Button, IButtonProps } from 'shared/components/Button';
import { MAX_TABLET_WIDTH, MIN_DESKTOP_WIDTH } from 'shared/constants';
import '../css/StepProgressBar.scss';

export interface IStepProgressBarButtonProps extends IButtonProps {
    buttonLabel : string;
    labelShortName : string | null;
}

export interface IStepProgressBar {
    orderedSteps : Array<IStepProgressBarButtonProps>;
    currentStepIndex : number;
}

export class StepProgressBar extends React.Component<IStepProgressBar, object> {

    public render() {
        const {
            orderedSteps,
            currentStepIndex
        } = this.props;

        // modeled in part after the cart-progress bar on the ordering pages
        return (
            <div className="step-progress-bar col-xs-12 text-center">
                { this.createSteps(orderedSteps, currentStepIndex) }
            </div>
        );
    }

    private createSteps = (orderedSteps : Array<IStepProgressBarButtonProps>, currentStepIndex : number) => {
        const stepComponents : Array<JSX.Element> = [];

        const numberSteps = orderedSteps.length;

        orderedSteps.forEach((stepButtonProps : IStepProgressBarButtonProps, index : number) => {

            const classNamesArray : Array<string> = [stepButtonProps.buttonClassName, 'flat'];

            if (index === 0) {
                classNamesArray.push('first-step');
            } else if (index === (numberSteps - 1)) {
                classNamesArray.push('last-step');
            }

            if (index < currentStepIndex) {
                classNamesArray.push('completed');
            } else if (index === currentStepIndex) {
                classNamesArray.push('current');
            }

            const className = classNamesArray.join(' ');

            // ensure that user cannot jump ahead 2 steps
            const isDisabled = (index > currentStepIndex + 1) ? true : stepButtonProps.isDisabled;

            const mobileButtonLabel = stepButtonProps.labelShortName !== null ? stepButtonProps.labelShortName : stepButtonProps.buttonLabel;

            stepComponents.push(
                <Button
                    buttonClassName={ className }
                    isDisabled={ isDisabled }
                    isLoading={ stepButtonProps.isLoading }
                    onClick={ stepButtonProps.onClick }
                    key={ index }
                >
                    <MediaQuery minWidth={ MIN_DESKTOP_WIDTH }>
                        <span className="step-text">{ stepButtonProps.buttonLabel }</span>
                    </MediaQuery>
                    <MediaQuery maxWidth={ MAX_TABLET_WIDTH }>
                        <span className="step-text">{ mobileButtonLabel }</span>
                    </MediaQuery>
                    { index > 0 &&
                        <span className="progress-bar"/>
                    }
                </Button>
            );

        });

        return stepComponents;
    }

}
