import AccountsUserModel from 'gen-thrift/accounts_user_Model_types';

import { UserAccountId } from '../model/UserAccountId';
import { UserAccountIdAndTimestamp } from '../model/UserAccountIdAndTimestamp';
import { UserEmailAddressVerificationCredential } from '../model/UserEmailAddressVerificationCredential';
import { UserPasswordSettingCredential } from '../model/UserPasswordSettingCredential';
import { UserSessionId } from '../model/UserSessionId';

export class UserAccountObjectToThriftSerializer {

    public getThriftUserAccountId(
        userAccountId : UserAccountId
        ) : AccountsUserModel.UserAccountIdentifier {

        return new AccountsUserModel.UserAccountIdentifier({
            value: userAccountId.getValue(),
        });
    }

    public getThriftUserEmailAddressVerificationCredential(
        userEmailAddressVerificationCredential : UserEmailAddressVerificationCredential
        ) : AccountsUserModel.UserEmailAddressVerificationCredential {

        return new AccountsUserModel.UserEmailAddressVerificationCredential({
            value: userEmailAddressVerificationCredential.getValue(),
        });
    }

    public getThriftUserPasswordSettingCredential(
        userPasswordSettingCredential : UserPasswordSettingCredential
        ) : AccountsUserModel.UserPasswordSettingCredential {

        return new AccountsUserModel.UserPasswordSettingCredential({
            value: userPasswordSettingCredential.getValue(),
        });
    }

    public getThriftUserSessionId(
        userSessionId : UserSessionId
        ) : AccountsUserModel.UserSessionIdentifier {

        return new AccountsUserModel.UserSessionIdentifier({
            value: userSessionId.getValue(),
        });
    }

    public getThriftUserAccountIdentifierAndTimestamp(
        userAccountIdAndTimestamp : UserAccountIdAndTimestamp
    ) : AccountsUserModel.UserAccountIdentifierAndTimestamp {
        return new AccountsUserModel.UserAccountIdentifierAndTimestamp({
            userAccountIdentifier: this.getThriftUserAccountId(userAccountIdAndTimestamp.getUserAccountId()),
            timestamp: userAccountIdAndTimestamp.getTimestamp()
        });
    }

}
