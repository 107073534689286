export class StorageArea {

    constructor (
        private readonly name : string,
        private readonly note : string,
    ) { }

    public getName() : string {
        return this.name;
    }

    public getNote() : string {
        return this.note;
    }
}
