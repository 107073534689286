import React from 'react';
import { SearchBarTheme } from 'shared/components/SearchBar/SearchBar';
import { SearchBarWithSuggestedOptions } from 'shared/components/SearchBar/SearchBarWithSuggestedOptions';

export interface IAddItemSearchBarProps {
    searchBarIsMobileComponent : boolean;
    searchTerm : string | null;
    searchSuggestionListIsShown : boolean;
    searchSuggestions : Array<string>;
    highlightedSearchSuggestion : string | null;
    isSearchBarFocused : boolean;
    setSearchSuggestionListIsShown(isShown : boolean) : void;
    setSearchTerm(searchTerm : string | null) : void;
    updateSearchResults(searchTerm : string) : void;
    updateSearchSuggestions() : void;
    setHighlightedSearchSuggestion(searchSuggestion : string | null) : void;
}

export class AddItemSearchBar extends React.Component<IAddItemSearchBarProps, object> {
    public render() {

        const {
            searchBarIsMobileComponent,
            searchTerm,
            isSearchBarFocused,
            searchSuggestionListIsShown,
            searchSuggestions,
            highlightedSearchSuggestion,
            setSearchSuggestionListIsShown,
        } = this.props;

        const placeholderText = 'Search by brand, name, container, etc...';

        return (
            <SearchBarWithSuggestedOptions
                searchBar={ {
                    searchTerm,
                    isDisabled: false,
                    isFocused: isSearchBarFocused
                } }
                searchBarTheme={ SearchBarTheme.Boxed }
                searchBarPlaceholderText={ placeholderText }
                searchSuggestionListIsShown={ searchSuggestionListIsShown }
                labelNamesAndSearchSuggestions={ [[null, searchSuggestions]] }
                highlightedSearchSuggestion={ highlightedSearchSuggestion }
                moreOptionsHeader={ null }
                moreOptionsFooter={ null }
                setSearchSuggestionListIsShown={ setSearchSuggestionListIsShown }
                createSuggestionOptionElement={ this.createSuggestionOptionRow }
                onSearchBarEnterClick={ this.onSearchBarEnterClick }
                onSearchTermValueChange={ this.onSearchTermValueChange }
                onClearSearchTerm={ this.onClearSearchTerm }
                onOptionClick={ this.onSuggestionOptionClick }
                setHighlightedSearchSuggestionKeyboard={ this.setHighlightedSearchSuggestionKeyboard }
                setHighlightedSearchSuggestionMouse={ this.setHighlightedSearchSuggestionMouse }
                closeMobileSearchBar={ this.doNothing }
                dropdownRowClassName="dropdown-menu-component-option"
                dropdownContainerClassName={ null }
                searchBarIsMobileComponent={ searchBarIsMobileComponent }
            />
        );
    }

    private readonly doNothing = () => null;

    private readonly setHighlightedSearchSuggestionKeyboard = (newHighlightedSearchSuggestion : string | null) => {
        this.props.setHighlightedSearchSuggestion(newHighlightedSearchSuggestion);
        this.props.setSearchTerm(newHighlightedSearchSuggestion);
    }

    private readonly setHighlightedSearchSuggestionMouse = (newHighlightedSearchSuggestion : string | null) => {
        this.props.setHighlightedSearchSuggestion(newHighlightedSearchSuggestion);
    }

    private readonly onClearSearchTerm = () => {
        this.onSearchTermValueChange('');
        this.props.updateSearchResults('');
    }

    private readonly onSearchTermValueChange = (searchTerm : string) => {
        this.props.setSearchTerm(searchTerm);
        this.props.updateSearchSuggestions();
    }

    private readonly onSearchBarEnterClick = () => {
        const {
            searchTerm,
            setSearchSuggestionListIsShown,
            updateSearchResults,
        } = this.props;
        setSearchSuggestionListIsShown(false);
        updateSearchResults(searchTerm === null ? '' : searchTerm);
    }

    private readonly onSuggestionOptionClick = (suggestion : string) => {
        const {
            setSearchTerm,
            updateSearchResults,
            setSearchSuggestionListIsShown
        } = this.props;

        setSearchTerm(suggestion);
        updateSearchResults(suggestion);
        setSearchSuggestionListIsShown(false);
    }

    private readonly createSuggestionOptionRow = (suggestion : string) => {
        return <span>{ suggestion }</span>;
    }
}
