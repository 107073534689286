import * as React from 'react';

import { StringValueMap } from 'api/Core/StringValueMap';
import { Distributor } from 'api/Distributor/model/Distributor';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { Delivery } from 'api/Ordering/model/Delivery';
import { DeliveryUtils } from 'api/Ordering/utils/DeliveryUtils';

import './DeliveryOptionRow.scss';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { FormatMonetaryValueWithCents } from 'shared/utils/FormatMonetaryValue';

export interface IDeliveryOptionRowProps {
    readonly delivery : Delivery;
    readonly distributorsById : StringValueMap<DistributorId, Distributor>;
    readonly isHighlighted : boolean;
}

export class DeliveryOptionRow extends React.Component<IDeliveryOptionRowProps, object> {
    public render() {
        const {
            delivery,
            distributorsById,
            isHighlighted,
        } = this.props;

        const deliveryDateForDisplay = delivery.getDateDeliveredUTC().clone().tz(window.GLOBAL_RETAILER_TIME_ZONE);

        const distributorId = delivery.getDistributorId();
        let distributor = null;
        if (distributorId) {
            distributor = distributorsById.get(distributorId);

            if (typeof distributor === 'undefined') {
                throw new RuntimeException('undefined');
            }
        }

        const distributorDisplayString = distributor === null ? 'Other' : distributor.getName();

        return (
            <div className={ `delivery-option-row ${ isHighlighted ? 'highlighted' : '' }` }>
                <div className="delivery-option-distributor">
                    <strong>{ distributorDisplayString }</strong>
                    <span className="ellipsis-out">{ delivery.getInvoiceNumber() ? <span> { delivery.getInvoiceNumber() }</span> : '' }</span>
                </div>
                <div className="delivery-option-date">
                    <small><b>Delivered on:</b> { deliveryDateForDisplay.format('MMM. D, \'YY h:mm a') }</small>
                </div>
                <div className="delivery-option-total">
                    <small>{ delivery.getDeliveryLineItems().length } Line Items <b>{ FormatMonetaryValueWithCents(DeliveryUtils.getTotalCostOfDelivery(delivery)) }</b></small>
                </div>
            </div>
        );
    }
}
