import React from 'react';

import { Button } from 'shared/components/Button';

export interface ISalesItemFlagAlertProps {
    selectedFlagValue : string | null;
    onMarkAsResolvedClick : () => void;
}

export class SalesItemFlagAlert extends React.Component<ISalesItemFlagAlertProps, object> {
    public render() {
        const {
            selectedFlagValue,
            onMarkAsResolvedClick,
        } = this.props;

        return (
            <div className="sales-item-form-section sales-item-flag-alert">
                <div className="flex-text-with-icon middle">
                    <span className="bevicon bevico-flag icon-left"/>
                    <span>{ selectedFlagValue }</span>
                </div>
                <Button
                    buttonClassName="primary medium"
                    isDisabled={ false }
                    isLoading={ false }
                    onClick={ onMarkAsResolvedClick }
                >
                    Mark as resolved
                </Button>
            </div>
        );
    }
}
