export class Category {
    constructor(
        private readonly name : string,
        private readonly glCode : string,
        private readonly isDeleted : boolean,
        private readonly hash : string,
    ) {}

    public getName() : string {
        return this.name;
    }

    public getGlCode() : string {
        return this.glCode;
    }

    public getHash() : string {
        return this.hash;
    }

    public getIsDeleted() : boolean {
        return this.isDeleted;
    }

    public equals(other : any) : boolean {
        if (!(other instanceof Category)) {
            return false;
        }

        return ((this.getName() === other.getName()) &&
            (this.getGlCode() === other.getGlCode()) &&
            (this.getHash() === other.getHash()) &&
            (this.getIsDeleted() === other.getIsDeleted()));
    }
}
