import React from 'react';
import * as ReactDOM from 'react-dom';

import { Dialog } from 'shared/components/Dialog';
import { IModalButton } from 'shared/models/Modal';

import '../css/ProductModificationConfirmationDialog.scss';

export interface IProductModificationConfirmationDialogProps {
    readonly onCreateNewClick : () => void;
    readonly onModifyExistingClick : () => void;
    readonly onCancelClick : () => void;
}

export class ProductModificationConfirmationDialog extends React.Component<IProductModificationConfirmationDialogProps, object> {

    public render() {
        const {
            onCreateNewClick,
            onModifyExistingClick,
            onCancelClick,
        } = this.props;

        const buttons : Array<IModalButton> = [
            {
                onClick: onCancelClick,
                classes: 'primary flat large close-dialog-action-button',
                isDisabled: false,
                isLoading: false,
                children: 'Cancel',
            },
            {
                onClick: onModifyExistingClick,
                classes: 'primary flat large',
                isDisabled: false,
                isLoading: false,
                children: 'Modify Existing',
            },
            {
                onClick: onCreateNewClick,
                classes: 'primary flat large',
                isDisabled: false,
                isLoading: false,
                children: 'Create new',
            },
        ];

        return ReactDOM.createPortal(
            <Dialog
                className="product-modification-confirmation-dialog"
                buttons={ buttons }
                headerText="Item Package Modified"
            >
                Modifying an existing item’s package will affect historical records. Are you sure you want to modify the existing item?
            </Dialog>,
            document.body
        );
    }
}
