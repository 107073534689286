import { MonthOfYear } from 'shared/models/MonthOfYear';

export class CreditCard {
    constructor (
        private readonly brand : string,
        private readonly lastFourDigits : string, // string because it might start with 0
        private readonly expirationMonth : MonthOfYear,
        private readonly expirationYear : number,
        private readonly cardHolderName : string,
    ) { }

    public getBrand() : string {
        return this.brand;
    }

    public getLastFourDigits() : string {
        return this.lastFourDigits;
    }

    public getExpirationMonth() : MonthOfYear {
        return this.expirationMonth;
    }

    public getExpirationYear() : number {
        return this.expirationYear;
    }

    public getCardHolderName() : string {
        return this.cardHolderName;
    }
}
