import React from 'react';

import '../css/Pill.scss';

export interface IPillProps {
    readonly label : string;
    readonly onRemoveClick : () => void;
}

export class Pill extends React.Component<IPillProps, object> {
    private readonly classNamePrefix = 'pill';

    public render() {
        const className = `${ this.classNamePrefix }-container`;

        return (
            <div className="pill-wrapper">
                <div
                    className={ className }
                >
                    { this.createLabel() }
                    { this.createRemoveButton() }
                </div>
            </div>
        );
    }

    private readonly createLabel = () : JSX.Element => {
        const {
            label,
        } = this.props;

        const className = `${ this.classNamePrefix }-label`;

        return (
            <label
                className={ className }
            >
                { label }
            </label>
        );
    }

    private readonly createRemoveButton = () : JSX.Element => {
        const {
            onRemoveClick,
        } = this.props;

        const className = `${ this.classNamePrefix }-remove-button bevicon bevico-remove-inverse`;

        return (
            <a
                className={ className }
                onClick={ onRemoveClick }
            />
        );
    }
}
