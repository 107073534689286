import ProductDistributorModel from 'gen-thrift/product_distributor_Model_types';
import ThriftProductDistributorService from 'gen-thrift/ProductDistributorService';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { ProductDistributorAssociation } from 'api/Product/model/ProductDistributorAssociation';
import { ProductDistributorAssociationIdentifier } from 'api/Product/model/ProductDistributorAssociationIdentifier';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';

import { DistributorObjectToThriftSerializer } from 'api/Distributor/serializer/DistributorObjectToThriftSerializer';
import { ProductObjectToThriftSerializer } from 'api/Product/serializer/ProductObjectToThriftSerializer';
import { ProductDistributorAssociationObjectToThriftSerializer } from 'api/Product/serializer/ProductDistributorAssociationObjectToThriftSerializer';
import { ProductDistributorAssociationThriftToObjectSerializer } from 'api/Product/serializer/ProductDistributorAssociationThriftToObjectSerializer';
import { UserAccountObjectToThriftSerializer } from 'api/UserAccount/serializer/UserAccountObjectToThriftSerializer';
import { IProductDistributorAssociationService } from '../interfaces/IProductDistributorAssociationService';

export class ProductDistributorAssociationServiceImpl implements IProductDistributorAssociationService {

    constructor(
        private readonly thriftProductDistributorServiceClient : ThriftProductDistributorService.ProductDistributorServiceClient,
        private readonly productObjectToThriftSerializer : ProductObjectToThriftSerializer,
        private readonly distributorObjectToThriftSerializer : DistributorObjectToThriftSerializer,
        private readonly userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer,
        private readonly productDistributorAssociationObjectToThriftSerializer : ProductDistributorAssociationObjectToThriftSerializer,
        private readonly productDistributorAssociationThriftToObjectSerializer : ProductDistributorAssociationThriftToObjectSerializer,
    ) { }

    public getProductDistributorAssociationsForProductIds (
        userSessionId : UserSessionId,
        productIds : StringValueSet<ProductId>
    ) : Promise<StringValueMap<ProductId, StringValueMap<ProductDistributorAssociationIdentifier, ProductDistributorAssociation>>> {
        return new Promise<StringValueMap<ProductId, StringValueMap<ProductDistributorAssociationIdentifier, ProductDistributorAssociation>>>((resolve, reject) => {
            this.thriftProductDistributorServiceClient.getProductPackagingDistributorAssociationsForProductIds(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.productObjectToThriftSerializer.getThriftProductIdsFromSet(productIds),
                (result : Array<ProductDistributorModel.ProductPackagingDistributorAssoc> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    } else {
                        const distributorAssociationsByProductId = new StringValueMap<ProductId, StringValueMap<ProductDistributorAssociationIdentifier, ProductDistributorAssociation>>();
                        productIds.forEach((productId) => {
                            distributorAssociationsByProductId.set(productId, new StringValueMap());
                        });

                        result.forEach((thriftProductDistributorAssociation) => {
                            const productDistributorAssociation = this.productDistributorAssociationThriftToObjectSerializer.getProductDistributorAssociation(thriftProductDistributorAssociation);
                            const productId = productDistributorAssociation.getProductId();

                            const distributorAssociationsByIdentifier = distributorAssociationsByProductId.getRequired(productId);
                            distributorAssociationsByIdentifier.set(ProductDistributorAssociationIdentifier.fromProductDistributorAssociation(productDistributorAssociation), productDistributorAssociation);

                            distributorAssociationsByProductId.set(
                                productId,
                                distributorAssociationsByIdentifier,
                            );
                        });

                        return resolve(distributorAssociationsByProductId);
                    }
                }
            );
        });
    }

    public createOrUpdateProductDistributorAssociations(
        userSessionId : UserSessionId,
        productDistributorAssociations : Array<ProductDistributorAssociation>
    ) : Promise<void> {
        if (productDistributorAssociations.length === 0) {
            return Promise.resolve();
        }
        return new Promise<void>((resolve, reject) => {
            this.thriftProductDistributorServiceClient.createOrUpdateDistributorProductPackagingAssocs(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.productDistributorAssociationObjectToThriftSerializer.getThriftProductDistributorAssociationsFromArray(productDistributorAssociations),
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    } else {
                        return resolve();
                    }
                }
            );
        });
    }

    public disassociateProductDistributorAssociations(
        userSessionId : UserSessionId,
        productDistributorAssociations : Array<ProductDistributorAssociation>
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.thriftProductDistributorServiceClient.disassociateDistributorProductPackagingAssocs(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.productDistributorAssociationObjectToThriftSerializer.getThriftProductDistributorAssociationsFromArray(productDistributorAssociations),
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    } else {
                        return resolve();
                    }
                }
            );
        });
    }

    public disassociateDistributorFromProductQuantityUnits(
        userSessionId : UserSessionId,
        productId : ProductId,
        productQuantityUnits : Array<ProductQuantityUnit>,
        distributorId : DistributorId
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.thriftProductDistributorServiceClient.disassociateDistributorFromProductPackagings(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.productObjectToThriftSerializer.getThriftProductId(productId),
                productQuantityUnits.map((productQuantityUnit : ProductQuantityUnit) => {
                    return this.productObjectToThriftSerializer.getThriftProductQuantityUnit(productQuantityUnit);
                }),
                this.distributorObjectToThriftSerializer.getThriftDistributorId(distributorId),
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    } else {
                        return resolve();
                    }
                }
            );
        });
    }

    public disassociateDistributorFromProducts(
        userSessionId : UserSessionId,
        productIds : StringValueSet<ProductId>,
        distributorId : DistributorId
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.thriftProductDistributorServiceClient.disassociateDistributorFromProducts(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.productObjectToThriftSerializer.getThriftProductIdsFromSet(productIds),
                this.distributorObjectToThriftSerializer.getThriftDistributorId(distributorId),
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    } else {
                        return resolve();
                    }
                }
            );
        });
    }

    public disassociateAllDistributorsFromProducts(
        userSessionId : UserSessionId,
        productIds : StringValueSet<ProductId>
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.thriftProductDistributorServiceClient.disassociateAllDistributorsFromProducts(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.productObjectToThriftSerializer.getThriftProductIdsFromSet(productIds),
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    } else {
                        return resolve();
                    }
                }
            );
        });
    }
}
