export class HexColor {
    public hexString : string;

    constructor(hexString : string) {
        // Hex Color must start with #, contain 6 following characters 0-9 or A-F
        const regExp : RegExp = new RegExp('^#[0-9a-fA-F]{6}$');

        const isValid : boolean = regExp.test(hexString);

        if (!isValid) {
            throw Error('invalid hex color code');
        }

        this.hexString = hexString;
    }

    public getValue() : string {
        return this.hexString;
    }
}
