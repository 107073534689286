import { IntegrationState } from 'api/Integration/model/IntegrationState';
import { IPosLocation } from 'api/Integration/model/PosLocation';
import { Action } from 'redux';
import {
    ActionTypes,
    SquareIntegrationActionInterfaces
} from 'shared/components/PosIntegration/SquareIntegrationRowActions';

export interface ISquareIntegrationModalState {
    isLoading : boolean;
    locationSelectorIsShown : boolean;
    squareLocations : Array<IPosLocation>;
    error : string | null;
    accessToken : string | null;
}

export interface ISquareIntegrationRowState {
    isLoading : boolean;
    modalIsShown : boolean;
    existingIntegration : IntegrationState | null;
    modal : ISquareIntegrationModalState;
}

const initialState : ISquareIntegrationRowState = {
    isLoading : false,
    existingIntegration : null,
    modalIsShown : false,
    modal : {
        isLoading : false,
        locationSelectorIsShown: false,
        squareLocations: [],
        error: null,
        accessToken: null
    }
};

const reduceSetModalIsLoading = (
    state : ISquareIntegrationRowState,
    action : SquareIntegrationActionInterfaces.ISetIsLoading
) : ISquareIntegrationRowState => {
    return {
        ...state,
        modal : {
            ...state.modal,
            isLoading : action.payload.isLoading,
        }
    };
};

const reduceSetModalShown = (
    state : ISquareIntegrationRowState,
    action : SquareIntegrationActionInterfaces.ISetModalShown
) : ISquareIntegrationRowState => {
    return {
        ...state,
        modalIsShown : action.payload.isShown,
    };
};

const reduceSetIsLoading = (
    state : ISquareIntegrationRowState,
    action : SquareIntegrationActionInterfaces.ISetIsLoading
) : ISquareIntegrationRowState => {
    return {
        ...state,
        isLoading : action.payload.isLoading,
    };
};

const reduceSetLocationSelectorShown = (
    state : ISquareIntegrationRowState,
    action : SquareIntegrationActionInterfaces.ISetLocationSelectorShown
) : ISquareIntegrationRowState => {
    return { ...state, modal: { ...state.modal, locationSelectorIsShown : action.payload.isShown } };
};

const reduceSetModalError = (
    state : ISquareIntegrationRowState,
    action : SquareIntegrationActionInterfaces.ISetModalError
) : ISquareIntegrationRowState => {
    return { ...state, modal: { ...state.modal, error : action.payload.message } };
};

const reduceSetSquareLocations = (
    state : ISquareIntegrationRowState,
    action : SquareIntegrationActionInterfaces.ISetSquareLocations
) : ISquareIntegrationRowState => {
    return { ...state, modal: { ...state.modal, squareLocations : action.payload.squareLocations } };
};

const reduceSetExistingIntegrationState = (
    state : ISquareIntegrationRowState,
    action : SquareIntegrationActionInterfaces.ISetExistingIntegrationState
) : ISquareIntegrationRowState => {
    return {
        ...state,
        existingIntegration : action.payload.integrationState,
    };
};

const reduceSetModalAccessToken = (
    state : ISquareIntegrationRowState,
    action : SquareIntegrationActionInterfaces.ISetModalAccessToken
) : ISquareIntegrationRowState => {
    return {
        ...state,
        modal: {
            ...state.modal,
            accessToken: action.payload.accessToken
        }
    };
};

export const SquareIntegrationRowReducers = (
    state : ISquareIntegrationRowState = initialState,
    action : Action,
) : ISquareIntegrationRowState => {
    switch (action.type) {
        case ActionTypes.SET_IS_LOADING:
            return reduceSetIsLoading(state, action as SquareIntegrationActionInterfaces.ISetIsLoading);
        case ActionTypes.SET_EXISTING_INTEGRATION_STATE:
            return reduceSetExistingIntegrationState(state, action as SquareIntegrationActionInterfaces.ISetExistingIntegrationState);
        case ActionTypes.SET_MODAL_SHOWN:
            return reduceSetModalShown(state, action as SquareIntegrationActionInterfaces.ISetModalShown);
        case ActionTypes.SET_MODAL_IS_LOADING:
            return reduceSetModalIsLoading(state, action as SquareIntegrationActionInterfaces.ISetIsLoading);
        case ActionTypes.SET_SQUARE_LOCATION_SELECTOR_SHOWN:
            return reduceSetLocationSelectorShown(state, action as SquareIntegrationActionInterfaces.ISetLocationSelectorShown);
        case ActionTypes.SET_SQUARE_LOCATIONS:
            return reduceSetSquareLocations(state, action as SquareIntegrationActionInterfaces.ISetSquareLocations);
        case ActionTypes.SET_MODAL_ERROR:
            return reduceSetModalError(state, action as SquareIntegrationActionInterfaces.ISetModalError);
        case ActionTypes.SET_MODAL_ACCESS_TOKEN: {
            return reduceSetModalAccessToken(state, action as SquareIntegrationActionInterfaces.ISetModalAccessToken);
        }
        default:
            return state;
    }
};
