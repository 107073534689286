import React from 'react';

import { PosItemId } from 'api/SalesData/model/PosItemId';
import { Button } from 'shared/components/Button';
import { Flex } from 'shared/components/FlexLayout/Flex';

interface IOmittedItemRowProps {
    className : string;
    rowId : PosItemId;
    name : string;
    posId : string;
    restoreDisabled : boolean;

    onRestore : () => void;
}

export class OmittedItemRow extends React.Component<IOmittedItemRowProps, object> {

    public render() {
        const {
            name,
            posId,
            className,
            restoreDisabled,
            onRestore
        } = this.props;

        return (
            <Flex direction="row" className={ `omitted-item-row light-table-row ${ className } flex-pl-1` }>
                <Flex direction="row" align="stretch">
                    <Flex direction="column" className="flex-py-1 flex-px-2" grow={ 4 } justify="center">
                        <Flex direction="row" className="ellipsis-out" align="center"><span className="sub-label">POS ID:</span>&nbsp;{ posId }</Flex>
                    </Flex>
                    <Flex direction="column" grow={ 4 } justify="center" className="flex-px-2">
                        { name }
                    </Flex>
                    <Flex direction="column" grow={ 4 } justify="center" align="center" className="flex-px-2">
                        <Button
                            buttonClassName="flat primary"
                            isDisabled={ restoreDisabled }
                            isLoading={ false }
                            onClick={ onRestore }
                        >
                            Restore
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        );
    }
}
