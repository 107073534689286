import { GridLineType } from 'shared/models/Charts/GridLineType';
import { IBoundingBox } from 'shared/models/Charts/IBoundingBox';
import { ICoordinate } from 'shared/models/Charts/ICoordinate';
import { IGridLine } from 'shared/models/Charts/IGridLine';
import { IRange } from 'shared/models/Charts/IRange';
import { IValuesByCategoryNameWithAdditionalData } from 'shared/models/Charts/IValuesByCategoryNameWithAdditionalData';
import { IValueWithAdditionalData } from 'shared/models/Charts/IValueWithAdditionalData';
import { HexColor } from 'shared/models/HexColor';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import { MIN_DESKTOP_WIDTH } from 'shared/constants';

import {
    idealNumberOfGridLines,
    minTooltipDistanceFromScreenEdgeInPixels,
} from 'shared/components/Charts/constants';

export interface IGridLineData {
    readonly numberOfGridLines : number;
    readonly valueOfGapBetweenGridLines : number;
    readonly numberOfGapsBelowZero : number;
}

const ensureBoundingBoxIsValid = (
    boundingBox : IBoundingBox,
) : void => {
    if (boundingBox.heightInPixels <= 0) {
        throw new RuntimeException('heightInPixels must be positive');
    }

    if (boundingBox.widthInPixels <= 0) {
        throw new RuntimeException('widthInPixels must be positive');
    }
};

const ensureValueIsWithinRange = (
    value : number,
    range : IRange,
) : void => {
    if (value < range.minValue) {
        throw new RuntimeException('value must not be less than minValue');
    }

    if (value > range.maxValue) {
        throw new RuntimeException('value must not be greater than maxValue');
    }
};

////////////////////////////////
// SVG
////////////////////////////////
const getParent = (
    svgElement : SVGElement | HTMLElement,
) : HTMLElement => {
    const parentElement : HTMLElement = svgElement.parentElement || (svgElement.parentNode as HTMLElement);

    if (!parentElement) {
        throw new RuntimeException('no parentElement found');
    }

    return parentElement;
};

const getTranslationTransformation = (
    x : number,
    y : number,
) : string => {
    return 'translate(' + x + ', ' + y + ')';
};

////////////////////////////////
// DOM
////////////////////////////////
const getRightScreenEdgeInPixels = () : number => {
    return window.pageXOffset + window.innerWidth;
};

const getBottomScreenEdgeInPixels = () : number => {
    return window.pageYOffset + window.innerHeight;
};

const screenHasDesktopWidth = () : boolean => {
    return !(window.matchMedia('(max-width: ' + MIN_DESKTOP_WIDTH + 'px)').matches);
};

const estimateTextWidth = (
    text : string,
) : number => {
    const canvasContext = document.createElement('canvas').getContext('2d');

    if (canvasContext === null) {
        return 4 * text.length;
    }

    canvasContext.font = '16px Arial'; // TODO: what should go here?
    return Math.round(canvasContext.measureText(text).width);
};

const getOffsetParentOffset = (
    element : HTMLElement,
) : any => {
    const offset = {
        top: 0,
        left: 0,
    };

    let parent : HTMLElement = element.offsetParent as HTMLElement;
    while (parent !== null) {
        offset.top += parent.offsetTop;
        offset.left += parent.offsetLeft;

        parent = parent.offsetParent as HTMLElement;
    }

    return offset;
};

////////////////////////////////
// Chart
////////////////////////////////
const calculateMaxValueForArrayOfValuesWithAdditionalData = (
    valuesWithAdditionalData : ReadonlyArray<IValueWithAdditionalData>,
) : number => {
    return Math.max.apply(Number,
        valuesWithAdditionalData.map(
            (valueWithAdditionalData : IValueWithAdditionalData) => {
                return valueWithAdditionalData.value;
            }
        )
    );
};

const calculateValueRangeForArrayOfValuesByCategoryNameWithAdditionalData = (
    arrayOfValuesByCategoryNameWithAdditionalData : ReadonlyArray<IValuesByCategoryNameWithAdditionalData>,
) : IRange => {
    const minValue : number = calculateMinValueForArrayOfValuesByCategoryNameWithAdditionalData(arrayOfValuesByCategoryNameWithAdditionalData);
    const maxValue : number = calculateMaxValueForArrayOfValuesByCategoryNameWithAdditionalData(arrayOfValuesByCategoryNameWithAdditionalData);

    return {
        minValue,
        maxValue,
    };
};

const calculateGridLineData = (
    valueRange : IRange,
) : IGridLineData => {
    const rangeValue : number = valueRange.maxValue - valueRange.minValue;
    const possibleGapBetweenGridLines = rangeValue / idealNumberOfGridLines;

    let possibleGapValues : ReadonlyArray<number>;
    {
        const powerOfTen : number = calculatePowerOfTen(possibleGapBetweenGridLines);

        possibleGapValues = [1, 2, 5, 10].map((value : number) => {
            return value * Math.pow(10, powerOfTen);
        });
    }

    const possibleNumbersOfGridLines : ReadonlyArray<number> = possibleGapValues.map(
        (value : number) => {
            return Math.ceil(rangeValue / value);
        }
    );

    let valueOfGapBetweenGridLines : number = possibleGapValues[0];
    const numberOfGridLines = possibleNumbersOfGridLines.reduce(
        (previousValue : number, currentValue : number, indexOfCurrentValue : number) => {
            const differenceBetweenCurrentAndIdealValue = Math.abs(currentValue - idealNumberOfGridLines);
            const differenceBetweenPreviousAndIdealValue = Math.abs(previousValue - idealNumberOfGridLines);

            if (differenceBetweenCurrentAndIdealValue < differenceBetweenPreviousAndIdealValue) {
                valueOfGapBetweenGridLines = possibleGapValues[indexOfCurrentValue];
                return currentValue;
            }

            return previousValue;
        }
    );

    let numberOfGapsBelowZero : number;
    if (valueRange.minValue < 0) {
        numberOfGapsBelowZero = Math.ceil(Math.abs(valueRange.minValue / valueOfGapBetweenGridLines));
    } else {
        numberOfGapsBelowZero = 0; // TODO
    }

    return {
        numberOfGridLines,
        valueOfGapBetweenGridLines,
        numberOfGapsBelowZero,
    };
};

const calculateGridLines = (
    gridLineData : IGridLineData,
) : ReadonlyArray<IGridLine> => {
    const {
        numberOfGridLines,
        numberOfGapsBelowZero,
    } = gridLineData;

    const gridLines : Array<IGridLine> = [];

    for (let i = 0; i < numberOfGridLines; i++) {
        let lineType : GridLineType;
        if (i === numberOfGapsBelowZero - 1) {
            lineType = GridLineType.THICK_LINE;
        } else {
            lineType = GridLineType.THIN_LINE;
        }

        gridLines.push({
            fillColor: null,
            lineType,
        });
    }

    return gridLines;
};

const calculateFillLinesForValuesWithAdditionalData = (
    valuesWithAdditionalData : ReadonlyArray<IValueWithAdditionalData>,
    gridFillColorCalculator : (data : string) => HexColor | null,
) : ReadonlyArray<IGridLine> => {
    const dataArray : ReadonlyArray<string> = valuesWithAdditionalData.map(
        (valueWithAdditionalData : IValueWithAdditionalData) => {
            return valueWithAdditionalData.data;
        }
    );

    return calculateFillLines(dataArray, gridFillColorCalculator);
};

const calculateFillLinesForValuesByCategoryNameWithAdditionalData = (
    arrayOfValuesByCategoryNameWithAdditionalData : ReadonlyArray<IValuesByCategoryNameWithAdditionalData>,
    gridFillColorCalculator : (data : string) => HexColor | null,
) : ReadonlyArray<IGridLine> => {
    // TODO test
    const dataArray : ReadonlyArray<string> = arrayOfValuesByCategoryNameWithAdditionalData.map(
        (valuesByCategoryNameWithAdditionalData : IValuesByCategoryNameWithAdditionalData) => {
            return valuesByCategoryNameWithAdditionalData.data;
        }
    );

    return calculateFillLines(dataArray, gridFillColorCalculator);
};

const calculateValueRange = (gridLineData : IGridLineData, valueRange : IRange) : IRange => {
    const {
        numberOfGridLines,
        valueOfGapBetweenGridLines,
        numberOfGapsBelowZero,
    } = gridLineData;

    let minValue : number = -valueOfGapBetweenGridLines * numberOfGapsBelowZero;
    let maxValue : number = minValue + (valueOfGapBetweenGridLines * (numberOfGridLines + .5));

    // failsafe in case the range calculation isn't accounting for ranges offset into negatives
    if (maxValue < valueRange.maxValue) {
        const offset = Math.ceil(valueRange.maxValue - maxValue);
        maxValue += offset;
        minValue += offset;
    } else if (minValue > valueRange.minValue) {
        const offset = Math.ceil(minValue - valueRange.minValue);
        maxValue -= offset;
        minValue -= offset;
    }

    return {
        minValue,
        maxValue,
    };
};

const calculateMousePositionRelativeToChartOrigin = (
    mousePosition : ICoordinate,
    boundingRectangle : ClientRect,
) : ICoordinate => {
    return {
        x: mousePosition.x - (boundingRectangle.left + window.pageXOffset),
        y: mousePosition.y - (boundingRectangle.top + window.pageYOffset),
    };
};

const getTooltipPositionBasedOnMousePosition = (
    mousePosition : number,
    offset : number,
    tooltipLength : number,
    screenEdge : number,
) : number => {
    let tooltipPosition : number = mousePosition - offset;

    let numberOfPixelsOffscreen : number;
    {
        const tooltipEdge : number = mousePosition + tooltipLength;
        const adjustedScreenEdge : number = screenEdge - minTooltipDistanceFromScreenEdgeInPixels;

        numberOfPixelsOffscreen = tooltipEdge - adjustedScreenEdge;
    }

    if (numberOfPixelsOffscreen > 0) {
        tooltipPosition -= numberOfPixelsOffscreen;
    }

    return tooltipPosition;
};

const getTooltipPositionOnEitherSideOfHoveredObject = (
    defaultSideOfHoveredObjectPosition : number,
    otherSideOfHoveredObjectPosition : number,
    offset : number,
    tooltipLength : number,
    screenEdge : number,
    scrollOffset : number,
) : number => {
    const adjustedDefaultSideOfHoveredObjectPosition : number = defaultSideOfHoveredObjectPosition + minTooltipDistanceFromScreenEdgeInPixels + scrollOffset;
    const adjustedOtherSideOfHoveredObjectPosition : number = otherSideOfHoveredObjectPosition - (tooltipLength + minTooltipDistanceFromScreenEdgeInPixels) + scrollOffset;

    let numberOfPixelsOffscreen : number;
    {
        const tooltipEdge : number = adjustedDefaultSideOfHoveredObjectPosition + tooltipLength;
        const adjustedScreenEdge : number = screenEdge - minTooltipDistanceFromScreenEdgeInPixels;

        numberOfPixelsOffscreen = tooltipEdge - adjustedScreenEdge;
    }

    if (numberOfPixelsOffscreen > 0) {
        return adjustedOtherSideOfHoveredObjectPosition - offset;
    }

    return adjustedDefaultSideOfHoveredObjectPosition - offset;
};

////////////////////////////////
// Private
////////////////////////////////
const calculatePowerOfTen = (
    value : number,
) : number => {
    return Math.floor(Math.log(value) * Math.LOG10E);
};

const calculateFillLines = (
    dataArray : ReadonlyArray<string>,
    gridFillColorCalculator : (data : string) => HexColor | null,
) : ReadonlyArray<IGridLine> => {
    return dataArray.map(
        (data : string) => {
            const fillColor : HexColor | null = gridFillColorCalculator(data);

            return {
                fillColor,
                lineType: null,
            };
        }
    );
};

const calculateMinValueForArrayOfValuesByCategoryNameWithAdditionalData = (
    arrayOfValuesByCategoryNameWithAdditionalData : ReadonlyArray<IValuesByCategoryNameWithAdditionalData>,
) : number => {
    return Math.min.apply(Number,
        arrayOfValuesByCategoryNameWithAdditionalData.map(
            (valuesByCategoryNameWithAdditionalData : IValuesByCategoryNameWithAdditionalData) => {
                const valuesByCategoryName = valuesByCategoryNameWithAdditionalData.valuesByCategoryName;

                return Math.min.apply(Number,
                    Object.keys(valuesByCategoryName).map(
                        (categoryName : string) => {
                            return valuesByCategoryName[categoryName];
                        }
                    )
                    .filter((value) : value is number => value !== null)
                );
            }
        )
    );
};

const calculateMaxValueForArrayOfValuesByCategoryNameWithAdditionalData = (
    arrayOfValuesByCategoryNameWithAdditionalData : ReadonlyArray<IValuesByCategoryNameWithAdditionalData>,
) : number => {
    return Math.max.apply(Number,
        arrayOfValuesByCategoryNameWithAdditionalData.map(
            (valuesByCategoryNameWithAdditionalData : IValuesByCategoryNameWithAdditionalData) => {
                const valuesByCategoryName = valuesByCategoryNameWithAdditionalData.valuesByCategoryName;

                return Math.max.apply(Number,
                    Object.keys(valuesByCategoryName).map(
                        (categoryName : string) => {
                            return valuesByCategoryName[categoryName];
                        }
                    )
                    .filter((value) : value is number => value !== null)
                );
            }
        )
    );
};

export const utils = {
    ensureBoundingBoxIsValid,
    ensureValueIsWithinRange,

    // svg
    getParent,
    getTranslationTransformation,

    // dom
    getRightScreenEdgeInPixels,
    getBottomScreenEdgeInPixels,
    screenHasDesktopWidth,
    estimateTextWidth,
    getOffsetParentOffset,

    // chart
    calculateMaxValueForArrayOfValuesWithAdditionalData,
    calculateValueRangeForArrayOfValuesByCategoryNameWithAdditionalData,
    calculateGridLineData,
    calculateGridLines,
    calculateFillLinesForValuesWithAdditionalData,
    calculateFillLinesForValuesByCategoryNameWithAdditionalData,
    calculateValueRange,
    calculateMousePositionRelativeToChartOrigin,
    getTooltipPositionBasedOnMousePosition,
    getTooltipPositionOnEitherSideOfHoveredObject,
};
