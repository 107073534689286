import React from 'react';

import './TableControls.scss';

export interface ITableControlsProps {
    // silence is golden.
}

export class TableControls extends React.Component<ITableControlsProps, object> {
    public render() {
        return (
            <div className="table-controls">
                { this.props.children }
            </div>
        );
    }
}
