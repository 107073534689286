import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { UsageCalculationParameters } from './UsageCalculationParameters';

import { StringValueMap } from 'api/Core/StringValueMap';

export class UsageData {

    constructor(
        private readonly usageCalculationParameters : UsageCalculationParameters,
        private readonly usageByProductId : StringValueMap<ProductId, {unitUsage : QuantityInUnit<ProductQuantityUnit>, dollarUsage : number}>
    ) { }

    public getUsageCalculationParameters() : UsageCalculationParameters {
        return this.usageCalculationParameters;
    }

    public getUsageByProductId() : StringValueMap<ProductId, {unitUsage : QuantityInUnit<ProductQuantityUnit>, dollarUsage : number}> {
        return this.usageByProductId;
    }
}
