import React from 'react';

import { ProductId } from 'api/Product/model/ProductId';
import { CatalogItemId } from 'api/Search/model/CatalogItemId';
import { ICatalogItemOption } from 'api/Search/model/ICatalogItemOption';
import { AddItemSearchBar } from 'shared/components/AddItem/components/AddItemSearchBar';
import { CatalogRow } from 'shared/components/AddItem/components/CatalogRow';
import { AddItemModalComponent, CatalogItemComponent, IAddItemState, ICatalogItemOptionRowId } from 'shared/components/AddItem/reducers/addItemReducers';
import { utils } from 'shared/components/AddItem/utils';
import { Button } from 'shared/components/Button';
import { IPricedProductPackageRowId } from '../reducers/addItemReducers';
import { AddItemActionButton } from './AddItemActionButton';
import { CollapsableProductRow } from './CollapsableProductRow';
import { ItemsSelectedMenu } from './ItemsSelectedMenu';
import { ProductRowPanel } from './ProductRowPanel';

import { Dialog } from 'shared/components/Dialog';
import { LoadingCover } from 'shared/components/LoadingCover';
import { Pagination } from 'shared/components/Pagination/Pagination';
import { SnackBar } from 'shared/components/SnackBar';
import { IResultCount } from 'shared/models/IResultCount';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import '../css/AddItemView.scss';

const ILLUSTRATION_URL = '/static/img/empty_state/illustration-1.png';

export interface IAddItemViewProps {
    addItemState : IAddItemState;
    dismissSnackBar : () => void;
    openCreateItemModal : () => void;
    addItemsToEntity : () => void;
    setItemSelectedMenuIsOpen : (isOpen : boolean) => void;
    setProductRowExpanded : (productId : ProductId, isExpanded : boolean) => void;
    setProductPackageRowSelected : (rowId : IPricedProductPackageRowId, isSelected : boolean) => void;
    setPageOffset : (offset : number) => void;
    setComponentShown : (componentName : AddItemModalComponent, isShown : boolean) => void;
    setCatalogItemComponentIsShown : (catalogItemId : CatalogItemId, component : CatalogItemComponent, isShown : boolean) => void;
    addCustomCatalogItemOption : (option : ICatalogItemOption, catalogItemId : CatalogItemId) => void;
    setCatalogRowSelected : (rowId : ICatalogItemOptionRowId, isSelected : boolean) => void;
    onExitWithoutSaving : () => void;
    onSaveChangesAndExit : () => void;

    searchTerm : string | null;
    searchSuggestionListIsShown : boolean;
    searchSuggestions : Array<string>;
    highlightedSearchSuggestion : string | null;
    isSearchBarShown : boolean;
    isSearchBarFocused : boolean;
    setSearchSuggestionListIsShown(isShown : boolean) : void;
    setSearchTerm(searchTerm : string | null) : void;
    updateSearchResults(searchTerm : string) : void;
    updateSearchSuggestions() : void;
    setHighlightedSearchSuggestion(searchSuggestion : string | null) : void;
}

export class AddItemView extends React.Component<IAddItemViewProps, object> {

    private static formatNumber(value : number) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    public render() {
        const {
            dismissSnackBar,
            addItemsToEntity,
            setItemSelectedMenuIsOpen,
            setProductRowExpanded,
            setProductPackageRowSelected,
            setPageOffset,
            openCreateItemModal,
            onExitWithoutSaving,
            onSaveChangesAndExit,
            isSearchBarShown,
            searchTerm,
            searchSuggestionListIsShown,
            searchSuggestions,
            highlightedSearchSuggestion,
            isSearchBarFocused,
            setSearchSuggestionListIsShown,
            setSearchTerm,
            updateSearchResults,
            updateSearchSuggestions,
            setHighlightedSearchSuggestion,
        } = this.props;

        const {
            addItemData,
            isShownByComponentName,
            addItemsToEntityButtonText,
            displayedCatalogItemIds,
            isItemSelectedMenuExpanded,
            displayedProductIds,
            pageOffset,
            resultsPerPage,
            selections,
            pricedProductInfoByProductIdValue,
            catalogItemsById,
            activeSearchTerm,
            productsAddedSnackbar,
            isLoadingSearchResults,
            isLoading,
            totalNumberOfCatalogItems,
            totalNumberOfProducts,
            expandedProductIdRows,
        } = this.props.addItemState;

        if (!addItemData) {
            return null;
        }

        const catalogAccess = window.GLOBAL_FEATURE_ACCESS.catalog;

        let searchResultsView : JSX.Element | null = null;
        let searchResultsPlaceholder : JSX.Element | null = null;

        if (!isSearchBarShown) {
            const createProductRows = (productIds : Array<ProductId>) => {
                return productIds.map((productId) => {
                    const pricedProductInfo = pricedProductInfoByProductIdValue[productId.getValue()];
                    if (typeof pricedProductInfo === 'undefined') {
                        throw new RuntimeException('priced product info not found in pricedProductInfoByProductIdValue');
                    }

                    const productPackages = Object.keys(pricedProductInfo.productPackageRowInfoByAttribute).map((productRowAttribute) => pricedProductInfo.productPackageRowInfoByAttribute[ productRowAttribute ]);
                    const product = addItemData.productsById.getRequired(productId);

                    return (
                        <CollapsableProductRow
                            key={ productId.getValue() }
                            productId={ productId }
                            product={ product }
                            productPackages={ productPackages }
                            distributorsById={ addItemData.distributorsById }
                            isExpanded={ expandedProductIdRows.has(productId) }
                            onToggleExpand={ setProductRowExpanded }
                            onToggleSelect={ setProductPackageRowSelected }
                        />
                    );
                });
            };

            const totalResults = totalNumberOfProducts + totalNumberOfCatalogItems;
            const numberOfPages = Math.ceil(totalResults / resultsPerPage);
            const resultCount : IResultCount = {
                pageOffset,
                resultsPerPage,
                totalResults
            };
            const currentPageResultStart = resultCount.pageOffset * resultCount.resultsPerPage;
            const theoreticalPageEndIndex = currentPageResultStart + resultCount.resultsPerPage;
            const truePageEndIndex = theoreticalPageEndIndex > resultCount.totalResults ? resultCount.totalResults : theoreticalPageEndIndex;
            const pageResultRange = AddItemView.formatNumber(truePageEndIndex > 0 ? currentPageResultStart + 1 : 0) + ' - ' + AddItemView.formatNumber(truePageEndIndex);
            const formattedTotalResults = AddItemView.formatNumber(resultCount.totalResults);

            searchResultsView = (
                <div>
                    <AddItemSearchBar
                        searchBarIsMobileComponent={ false }
                        searchTerm={ searchTerm }
                        searchSuggestionListIsShown={ searchSuggestionListIsShown }
                        searchSuggestions={ searchSuggestions }
                        highlightedSearchSuggestion={ highlightedSearchSuggestion }
                        isSearchBarFocused={ isSearchBarFocused }
                        setSearchSuggestionListIsShown={ setSearchSuggestionListIsShown }
                        setSearchTerm={ setSearchTerm }
                        updateSearchResults={ updateSearchResults }
                        updateSearchSuggestions={ updateSearchSuggestions }
                        setHighlightedSearchSuggestion={ setHighlightedSearchSuggestion }
                    />
                    <div className="result-count-container">
                        <span className="result-page-counter">
                            <span className="result-pages">{ pageResultRange } </span>
                            of { formattedTotalResults } Results { (activeSearchTerm !== null && activeSearchTerm !== '') && <span className="search-term"> for "<span className="search-term-text">{ activeSearchTerm }</span>"</span> }
                        </span>
                    </div>
                    <div className="product-table">
                        <div className="product-table-rows">
                            { (!isLoadingSearchResults && displayedProductIds.length > 0) &&
                                <div className={ window.GLOBAL_RETAILER_ACCOUNT_TYPE === 'beverage' ? 'product-results-in-my-items' : '' }>
                                    <ProductRowPanel
                                        isCollapsible={ displayedCatalogItemIds.length > 0 && window.GLOBAL_RETAILER_ACCOUNT_TYPE === 'beverage' }
                                        isCollapsed={ !isShownByComponentName.collapsibleProductSearchResults }
                                        setIsCollapsed={ this.setProductSearchResultsIsCollapsed }
                                        panelTitle={ window.GLOBAL_RETAILER_ACCOUNT_TYPE === 'beverage' ? 'Already In My Items' : 'My Items' }
                                        numberOfProducts={ displayedProductIds.length }
                                    >
                                        { createProductRows(displayedProductIds) }
                                    </ProductRowPanel>
                                </div>
                            }
                            { (!isLoadingSearchResults && displayedCatalogItemIds.length > 0) &&
                                <ProductRowPanel
                                    isCollapsible={ false }
                                    isCollapsed={ false }
                                    setIsCollapsed={ null }
                                    panelTitle="Catalog"
                                    numberOfProducts={ displayedCatalogItemIds.length }
                                >
                                    { this.createCatalogRows() }
                                </ProductRowPanel>
                            }
                            { (!isLoadingSearchResults && displayedCatalogItemIds.length === 0 && displayedProductIds.length === 0) &&
                                <div className="no-matching-search-results">
                                    <h5>
                                        Sorry, there aren't any items that match "{ activeSearchTerm }"
                                    </h5>
                                    <p>
                                        Check your spelling and applied filters, search for another term, or <span className="create-custom-item-text" onClick={ openCreateItemModal }>create a custom item</span>.
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                    { (numberOfPages > 1) &&
                        <div className="pagination-wrapper">
                            <Pagination
                                currentPage={ pageOffset + 1 }
                                numberOfPages={ numberOfPages }
                                setCurrentPage={ setPageOffset }
                            />
                        </div>
                    }
                </div>
            );
        } else {
            searchResultsPlaceholder = (
                <React.Fragment>
                    <div className="container cell-ptb-xl">
                        <h3 className="normal">Use the search bar to add items from our catalog.</h3>
                        <AddItemSearchBar
                            searchBarIsMobileComponent={ false }
                            searchTerm={ searchTerm }
                            searchSuggestionListIsShown={ searchSuggestionListIsShown }
                            searchSuggestions={ searchSuggestions }
                            highlightedSearchSuggestion={ highlightedSearchSuggestion }
                            isSearchBarFocused={ isSearchBarFocused }
                            setSearchSuggestionListIsShown={ setSearchSuggestionListIsShown }
                            setSearchTerm={ setSearchTerm }
                            updateSearchResults={ updateSearchResults }
                            updateSearchSuggestions={ updateSearchSuggestions }
                            setHighlightedSearchSuggestion={ setHighlightedSearchSuggestion }
                        />
                    </div>
                    { !isShownByComponentName.emptyState ? null : (
                        <div className="catalog-intro-card">
                            <div className="row cell-plr">
                                <div className="cell-ptb col-xs-12 col-sm-7">
                                    { catalogAccess &&
                                    <div>
                                        <h3>Find items you stock with the BevSpot Catalog</h3>
                                        <p className="intro">Browse by category or search for specific items to add to your account to use in ordering, inventory, and more</p>
                                    </div>
                                    }
                                    { !catalogAccess &&
                                    <div>
                                        <h3>Looks like you don't have any items in your account yet!</h3>
                                        <p className="intro">Add items manually or upload an invoice to import your items into BevSpot.</p>
                                        <Button buttonClassName="primary" isDisabled={ false } isLoading={ false } onClick={ openCreateItemModal }>Create an Item</Button>
                                    </div>
                                    }
                                    <p className="cell-ptb-lg">
                                        <a data-elevio-article="205">
                                            <span className="bevicon bevico-ic_play_circle_filled cell cell-pr-xs"/>
                                            Watch our tutorial
                                        </a>
                                        <span>&nbsp;about how to add your items to BevSpot and what you can do with them</span>
                                    </p>
                                </div>
                                <div className="catalog-intro-card-illustration-container cell-ptb col-xs-12 col-sm-5">
                                    <img src={ ILLUSTRATION_URL } className="catalog-intro-card-illustration"/>
                                </div>
                            </div>
                        </div>
                    ) }
                </React.Fragment>
            );
        }

        const filterSectionIsLoading = isLoadingSearchResults && searchResultsView === null;    // when showing empty/landing state, loading state is applied to the filter section only

        return (
            <div className="add-item-view">
                { (isLoading || isLoadingSearchResults) && !filterSectionIsLoading &&
                    <LoadingCover
                        className="overlay-lighter"
                        hasLoadingIcon={ true }
                    />
                }
                <div className="add-item-body">
                    <div className="add-item-main cell-p-xxxl">
                        { searchResultsView }
                        { searchResultsPlaceholder }
                    </div>
                </div>
                <div className="add-item-footer">
                    <ItemsSelectedMenu
                        distributorsById={ addItemData.distributorsById }
                        selections={ selections }
                        pricedProductInfoByProductIdValue={ pricedProductInfoByProductIdValue }
                        productsById={ addItemData.productsById }
                        catalogItemsById={ catalogItemsById }
                        isExpanded={ isItemSelectedMenuExpanded }
                        onToggleMenuExpanded={ setItemSelectedMenuIsOpen }
                        removeItem={ this.onRemoveItem }
                    />
                    <AddItemActionButton
                        buttonText={ addItemsToEntityButtonText }
                        numberOfSelectedItems={ selections.length }
                        addItemsToEntity={ addItemsToEntity }
                    />
                </div>
                <SnackBar
                    isShown={ productsAddedSnackbar.isShown }
                    message={ productsAddedSnackbar.numberEdited + ' Items Added to ' + addItemsToEntityButtonText }
                    callToActionLabel={ null }
                    handleCloseButton={ dismissSnackBar }
                />
                { isShownByComponentName.unsavedChangesDialog &&
                    <Dialog
                        className="unsaved-add-item-changes-dialog"
                        buttons={ [
                            {
                                onClick: onExitWithoutSaving,
                                classes: 'flat',
                                isDisabled: false,
                                isLoading: false,
                                children: 'Exit Without Saving',
                            },
                            {
                                onClick: onSaveChangesAndExit,
                                classes: 'flat primary',
                                isDisabled: false,
                                isLoading: false,
                                children: addItemsToEntityButtonText,
                            },
                        ] }
                        headerText="Your selected items aren't saved."
                    />
                }
            </div>
        );
    }

    private readonly createCatalogRows = () => {
        const {
            displayedCatalogItemIds,
            catalogItemsById,
            selections,
            addedCatalogItemSelections,
            isShownByCatalogItemIdByComponentName,
        } = this.props.addItemState;
        return displayedCatalogItemIds.map((catalogItemId) => {
            const catalogItem = catalogItemsById.get(catalogItemId);
            if (typeof catalogItem === 'undefined') {
                throw new RuntimeException('unknown catalog item id: ' + catalogItemId.getValue());
            }

            const disabledOptions = addedCatalogItemSelections
                .filter((selection) => {
                    return selection.catalogItemId.equals(catalogItemId);
                })
                .map((selection) => {
                    return selection.optionIndex;
                });

            const selectedOptionIndices : Array<number> = [];
            selections.forEach((selection) => {
                if (utils.isCatalogItemOptionRowId(selection) && selection.catalogItemId.equals(catalogItemId)) {
                    selectedOptionIndices.push(selection.optionIndex);
                }
            });

            const setOptionIsSelected = (optionIndex : number, isSelected : boolean) => this.props.setCatalogRowSelected({ catalogItemId, optionIndex }, isSelected);
            const setDropdownIsOpen = (isOpen : boolean) => this.props.setCatalogItemComponentIsShown(catalogItemId, 'optionDropdown', isOpen);
            const setPackagingFormIsShown = (isShown : boolean) => this.props.setCatalogItemComponentIsShown(catalogItemId, 'packagingForm', isShown);
            const addCustomCatalogItemOption = (option : ICatalogItemOption) => this.props.addCustomCatalogItemOption(option, catalogItemId);

            return (
                <CatalogRow
                    key={ catalogItemId.getValue() }
                    catalogItem={ catalogItem }
                    disabledOptionIndices={ new Set(disabledOptions) }
                    selectedOptionIndices={ new Set(selectedOptionIndices) }
                    optionDropdownIsOpen={ isShownByCatalogItemIdByComponentName.optionDropdown.get(catalogItemId) || false }
                    packagingFormIsShown={ isShownByCatalogItemIdByComponentName.packagingForm.get(catalogItemId) || false }
                    setOptionDropdownIsOpen={ setDropdownIsOpen }
                    setPackagingFormIsShown={ setPackagingFormIsShown }
                    setOptionIsSelected={ setOptionIsSelected }
                    addCustomCatalogItemOption={ addCustomCatalogItemOption }
                />
            );
        });
    }

    private readonly setProductSearchResultsIsCollapsed = (isCollapsed : boolean) => {
        this.props.setComponentShown('collapsibleProductSearchResults', !isCollapsed);
    }

    private readonly onRemoveItem = (selection : IPricedProductPackageRowId | ICatalogItemOptionRowId) => {
        if (utils.isCatalogItemOptionRowId(selection)) {
            this.props.setCatalogRowSelected(selection, false);
        } else {
            this.props.setProductPackageRowSelected(selection, false);
        }
    }
}
