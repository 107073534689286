import AccountsUserModel from 'gen-thrift/accounts_user_Model_types';
import CorePrimitivesModel from 'gen-thrift/core_primitives_Model_types';
import UnauthenticatedUserSessionModel from 'gen-thrift/unauthenticated_user_session_Model_types';
import UserPasswordService from 'gen-thrift/UserPasswordService';

import { UserPasswordSettingCredential } from 'api/UserAccount/model/UserPasswordSettingCredential';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';

import { IUnauthenticatedUserSessionReader } from '../../unauthenticated_user_session/interfaces/IUnauthenticatedUserSessionReader';
import { IAccountPasswordSetter } from '../interfaces/IAccountPasswordSetter';
import { IAccountSessionWriter } from '../interfaces/IAccountSessionWriter';

import { UserAccountObjectToThriftSerializer } from 'api/UserAccount/serializer/UserAccountObjectToThriftSerializer';
import { UserAccountThriftToObjectSerializer } from 'api/UserAccount/serializer/UserAccountThriftToObjectSerializer';

export class UserPasswordManagerImpl implements IAccountPasswordSetter<UserPasswordSettingCredential> {
    private userPasswordServiceClient : UserPasswordService.UserPasswordServiceClient;
    private unauthenticatedUserSessionReader : IUnauthenticatedUserSessionReader;
    private accountSessionWriter : IAccountSessionWriter<UserSessionId>;
    private userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer;
    private userAccountThriftToObjectSerializer : UserAccountThriftToObjectSerializer;

    constructor(
            userPasswordServiceClient : UserPasswordService.UserPasswordServiceClient,
            unauthenticatedUserSessionReader : IUnauthenticatedUserSessionReader,
            accountSessionWriter : IAccountSessionWriter<UserSessionId>,
            userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer,
            userAccountThriftToObjectSerializer : UserAccountThriftToObjectSerializer) {

        this.userPasswordServiceClient = userPasswordServiceClient;
        this.unauthenticatedUserSessionReader = unauthenticatedUserSessionReader;
        this.accountSessionWriter = accountSessionWriter;
        this.userAccountObjectToThriftSerializer = userAccountObjectToThriftSerializer;
        this.userAccountThriftToObjectSerializer = userAccountThriftToObjectSerializer;
    }

    ////////////////////////////////
    // IAccountPasswordSetter
    ////////////////////////////////
    public sendPasswordSettingEmail(
        emailAddress : CorePrimitivesModel.EmailAddress
    ) : Promise<void> {
        return new Promise<void> ((resolve, reject) => {
            this.unauthenticatedUserSessionReader.getOrCreateUnauthenticatedUserSessionId()
            .then((sessionId : UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId) => {
                this.userPasswordServiceClient.sendPasswordSettingCredentialToEmailAddress(
                    emailAddress,
                    sessionId,
                    (result : void | Error) => {
                        if (result instanceof Error) {
                            return reject(result);
                        }

                        return resolve();
                    }
                );
            });
        });
    }

    public ensurePasswordSettingCredentialIsValid(
        passwordSettingCredential : UserPasswordSettingCredential
    ) : Promise<void> {
        return new Promise<void> ((resolve, reject) => {
            this.userPasswordServiceClient.ensurePasswordSettingCredentialIsValid(
                this.userAccountObjectToThriftSerializer.getThriftUserPasswordSettingCredential(passwordSettingCredential),
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    return resolve();
                }
            );
        });
    }

    public setAccountPasswordAndCreateSession(
        passwordSettingCredential : UserPasswordSettingCredential,
        newPassword : CorePrimitivesModel.UnencryptedPassword
    ) : Promise<UserSessionId> {
        return new Promise<UserSessionId> ((resolve, reject) => {
            this.unauthenticatedUserSessionReader.getOrCreateUnauthenticatedUserSessionId()
            .then((sessionId : UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId) => {
                this.userPasswordServiceClient.setAccountPasswordAndCreateSession(
                    this.userAccountObjectToThriftSerializer.getThriftUserPasswordSettingCredential(passwordSettingCredential),
                    newPassword,
                    sessionId,
                    (result : AccountsUserModel.UserSessionIdentifier | Error) => {
                        if (result instanceof Error) {
                            return reject(result);
                        }

                        const userSessionId : UserSessionId = this.userAccountThriftToObjectSerializer.getUserSessionId(result);

                        this.accountSessionWriter.setSessionId(userSessionId);
                        return resolve(userSessionId);
                    }
                );
            });
        });
    }
}
