import { ExtraDistributorProperties } from 'api/Distributor/model/ExtraDistributorProperties';
import { Scope } from 'api/Location/model/Scope';

export class Distributor {

    public static copy(other : Distributor | null) : Distributor | null {
        if (other === null) { return null; }
        return new Distributor(
            other.getOwner(),
            other.getName(),
            other.getShortName(),
            ExtraDistributorProperties.copy(other.getExtraDistributorProperties()),
        );
    }

    constructor (
        private owner : Scope,
        private name : string,
        private shortName : string,
        private extraDistributorProperties : ExtraDistributorProperties | null = null,
    ) { }

    public getName() {
        return this.name;
    }

    public setName(name : string) {
        this.name = name;
    }

    public getShortName() {
        return this.shortName;
    }

    public getOwner() {
        return this.owner;
    }

    public getExtraDistributorProperties() : ExtraDistributorProperties {
        if (this.extraDistributorProperties === null) {
            return ExtraDistributorProperties.createBlank();
        } else {
            return this.extraDistributorProperties;
        }
    }

    public setExtraDistributorProperties(extraDistributorProperties : ExtraDistributorProperties | null) {
        this.extraDistributorProperties = extraDistributorProperties;
    }

    public getRestFormData(formData : FormData) : FormData {
        const extra = this.getExtraDistributorProperties();
        if (extra !== null) {
            formData = extra.getRestFormData(formData);
        }
        return formData;
    }
}
