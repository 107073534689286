//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';
import LocationModel from './location_Model_types';
import ProductModel from './product_Model_types';
import CartModel from './cart_Model_types';
import OrderModel from './order_Model_types';



var DistributorDependencyModel = {};
export default DistributorDependencyModel;
DistributorDependencyModel.DistributorDependentObjectSet = function(args) {
  this.deliveryIds = null;
  this.productIds = null;
  this.retailerIds = null;
  this.cartIds = null;
  this.cartItemsByCartId = null;
  if (args) {
    if (args.deliveryIds !== undefined && args.deliveryIds !== null) {
      this.deliveryIds = Thrift.copyList(args.deliveryIds, [OrderModel.DeliveryId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field deliveryIds is unset!');
    }
    if (args.productIds !== undefined && args.productIds !== null) {
      this.productIds = Thrift.copyList(args.productIds, [ProductModel.ProductId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productIds is unset!');
    }
    if (args.retailerIds !== undefined && args.retailerIds !== null) {
      this.retailerIds = Thrift.copyList(args.retailerIds, [LocationModel.LocationIdentifier]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field retailerIds is unset!');
    }
    if (args.cartIds !== undefined && args.cartIds !== null) {
      this.cartIds = Thrift.copyList(args.cartIds, [LocationModel.LocationIdentifier]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field cartIds is unset!');
    }
    if (args.cartItemsByCartId !== undefined && args.cartItemsByCartId !== null) {
      this.cartItemsByCartId = Thrift.copyList(args.cartItemsByCartId, [OrderModel.CartIdAndCartItems]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field cartItemsByCartId is unset!');
    }
  }
};
DistributorDependencyModel.DistributorDependentObjectSet.prototype = {};
DistributorDependencyModel.DistributorDependentObjectSet.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.SET) {
        var _size0 = 0;
        var _rtmp34;
        this.deliveryIds = [];
        var _etype3 = 0;
        _rtmp34 = input.readSetBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new OrderModel.DeliveryId();
          elem6.read(input);
          this.deliveryIds.push(elem6);
        }
        input.readSetEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.SET) {
        var _size7 = 0;
        var _rtmp311;
        this.productIds = [];
        var _etype10 = 0;
        _rtmp311 = input.readSetBegin();
        _etype10 = _rtmp311.etype;
        _size7 = _rtmp311.size;
        for (var _i12 = 0; _i12 < _size7; ++_i12)
        {
          var elem13 = null;
          elem13 = new ProductModel.ProductId();
          elem13.read(input);
          this.productIds.push(elem13);
        }
        input.readSetEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.SET) {
        var _size14 = 0;
        var _rtmp318;
        this.retailerIds = [];
        var _etype17 = 0;
        _rtmp318 = input.readSetBegin();
        _etype17 = _rtmp318.etype;
        _size14 = _rtmp318.size;
        for (var _i19 = 0; _i19 < _size14; ++_i19)
        {
          var elem20 = null;
          elem20 = new LocationModel.LocationIdentifier();
          elem20.read(input);
          this.retailerIds.push(elem20);
        }
        input.readSetEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.SET) {
        var _size21 = 0;
        var _rtmp325;
        this.cartIds = [];
        var _etype24 = 0;
        _rtmp325 = input.readSetBegin();
        _etype24 = _rtmp325.etype;
        _size21 = _rtmp325.size;
        for (var _i26 = 0; _i26 < _size21; ++_i26)
        {
          var elem27 = null;
          elem27 = new LocationModel.LocationIdentifier();
          elem27.read(input);
          this.cartIds.push(elem27);
        }
        input.readSetEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.SET) {
        var _size28 = 0;
        var _rtmp332;
        this.cartItemsByCartId = [];
        var _etype31 = 0;
        _rtmp332 = input.readSetBegin();
        _etype31 = _rtmp332.etype;
        _size28 = _rtmp332.size;
        for (var _i33 = 0; _i33 < _size28; ++_i33)
        {
          var elem34 = null;
          elem34 = new OrderModel.CartIdAndCartItems();
          elem34.read(input);
          this.cartItemsByCartId.push(elem34);
        }
        input.readSetEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorDependencyModel.DistributorDependentObjectSet.prototype.write = function(output) {
  output.writeStructBegin('DistributorDependentObjectSet');
  if (this.deliveryIds !== null && this.deliveryIds !== undefined) {
    output.writeFieldBegin('deliveryIds', Thrift.Type.SET, 1);
    output.writeSetBegin(Thrift.Type.STRUCT, this.deliveryIds.length);
    for (var iter35 in this.deliveryIds)
    {
      if (this.deliveryIds.hasOwnProperty(iter35))
      {
        iter35 = this.deliveryIds[iter35];
        iter35.write(output);
      }
    }
    output.writeSetEnd();
    output.writeFieldEnd();
  }
  if (this.productIds !== null && this.productIds !== undefined) {
    output.writeFieldBegin('productIds', Thrift.Type.SET, 2);
    output.writeSetBegin(Thrift.Type.STRUCT, this.productIds.length);
    for (var iter36 in this.productIds)
    {
      if (this.productIds.hasOwnProperty(iter36))
      {
        iter36 = this.productIds[iter36];
        iter36.write(output);
      }
    }
    output.writeSetEnd();
    output.writeFieldEnd();
  }
  if (this.retailerIds !== null && this.retailerIds !== undefined) {
    output.writeFieldBegin('retailerIds', Thrift.Type.SET, 3);
    output.writeSetBegin(Thrift.Type.STRUCT, this.retailerIds.length);
    for (var iter37 in this.retailerIds)
    {
      if (this.retailerIds.hasOwnProperty(iter37))
      {
        iter37 = this.retailerIds[iter37];
        iter37.write(output);
      }
    }
    output.writeSetEnd();
    output.writeFieldEnd();
  }
  if (this.cartIds !== null && this.cartIds !== undefined) {
    output.writeFieldBegin('cartIds', Thrift.Type.SET, 4);
    output.writeSetBegin(Thrift.Type.STRUCT, this.cartIds.length);
    for (var iter38 in this.cartIds)
    {
      if (this.cartIds.hasOwnProperty(iter38))
      {
        iter38 = this.cartIds[iter38];
        iter38.write(output);
      }
    }
    output.writeSetEnd();
    output.writeFieldEnd();
  }
  if (this.cartItemsByCartId !== null && this.cartItemsByCartId !== undefined) {
    output.writeFieldBegin('cartItemsByCartId', Thrift.Type.SET, 5);
    output.writeSetBegin(Thrift.Type.STRUCT, this.cartItemsByCartId.length);
    for (var iter39 in this.cartItemsByCartId)
    {
      if (this.cartItemsByCartId.hasOwnProperty(iter39))
      {
        iter39 = this.cartItemsByCartId[iter39];
        iter39.write(output);
      }
    }
    output.writeSetEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

