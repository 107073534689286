import { StringValueMap } from 'api/Core/StringValueMap';
import { Distributor } from 'api/Distributor/model/Distributor';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { Delivery } from 'api/Ordering/model/Delivery';
import { DeliveryId } from 'api/Ordering/model/DeliveryId';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { cleanPhrase } from 'shared/utils/sortingFilteringAndGroupingUtils';

/*
    Commonly used filtering/grouping/sorting logic for deliveries.
*/

const createDeliveryStringForFilter = (delivery : Delivery, distributorForDelivery : Distributor | null) : string => {
    const invoiceNumber = delivery.getInvoiceNumber();
    const purchaseOrderNumber = window.GLOBAL_FEATURE_ACCESS.purchase_order_numbers ? delivery.getPurchaseOrderNumber() : '';

    const cleanedInvoiceNumber = cleanPhrase((invoiceNumber === null) ? '' : invoiceNumber);
    const cleanedPurchaseOrderNumber = cleanPhrase((purchaseOrderNumber === null) ? '' : purchaseOrderNumber);
    const cleanedDistributorName = cleanPhrase((distributorForDelivery === null) ? 'Other' : distributorForDelivery.getName());

    return [
        cleanedInvoiceNumber,
        cleanedPurchaseOrderNumber,
        cleanedDistributorName
    ].join(' ');
};

const deliveryFilterStringCache = new StringValueMap<DeliveryId, string>();
let lastDeliveriesById = new StringValueMap<DeliveryId, Delivery>();
let lastDistributorsById = new StringValueMap<DistributorId, Distributor>();

const cachedCreateDeliveryStringForFilter = (
    deliveryId : DeliveryId,
    deliveriesById : StringValueMap<DeliveryId, Delivery>,
    distributorsById : StringValueMap<DistributorId, Distributor>
) : string => {

    if ((deliveriesById !== lastDeliveriesById) || (distributorsById !== lastDistributorsById)) {
        lastDeliveriesById = deliveriesById;
        lastDistributorsById = distributorsById;
        deliveryFilterStringCache.clear();
    }

    if (deliveryFilterStringCache.has(deliveryId)) {
        const cacheValue = deliveryFilterStringCache.get(deliveryId);

        if (typeof cacheValue === 'undefined') {
            throw new RuntimeException('unexpected');
        }

        return cacheValue;
    }

    const delivery = deliveriesById.get(deliveryId);
    if (typeof delivery === 'undefined') {
        throw new RuntimeException('unexpected');
    }

    const distributorIdForDelivery = delivery.getDistributorId();
    const distributor = distributorIdForDelivery === null ? null : distributorsById.get(distributorIdForDelivery);

    if (typeof distributor === 'undefined') {
        throw new RuntimeException('unexpected');
    }

    const deliveryStringForFilter = deliverySortingFilteringAndGroupingUtils.createDeliveryStringForFilter(delivery, distributor);
    deliveryFilterStringCache.set(deliveryId, deliveryStringForFilter);

    return deliveryStringForFilter;
};

export const defaultDeliveryRowFilter = (
    deliveryId : DeliveryId,
    cleanedFilterTerm : string | null,
    deliveriesById : StringValueMap<DeliveryId, Delivery>,
    distributorsById : StringValueMap<DistributorId, Distributor>
) : boolean => {
    if (cleanedFilterTerm === null || cleanedFilterTerm === '') {
        return true;
    }

    const deliveryStringForSearch = cachedCreateDeliveryStringForFilter(deliveryId, deliveriesById, distributorsById);
    const filterTokens = cleanedFilterTerm.split(' ');

    for (let i = 0; i < filterTokens.length; i++) {
        if (deliveryStringForSearch.indexOf(filterTokens[i]) < 0) {
            return false;
        }
    }

    return true;
};

export const deliverySortingFilteringAndGroupingUtils = {
    createDeliveryStringForFilter,
    defaultDeliveryRowFilter,
};
