export enum EmailType {
    ORDER_PLACED_NOTIFICATION,
    SALES_REPORT_PROCESSED_NOTIFICATION,
    STRIPE_CHARGE_NOTIFICATION,
    RETAILER_DAILY_SUMMARY_EMAIL,
    RETAILER_WEEKLY_SUMMARY_EMAIL,
}
export type EmailTypeString = 'ORDER_PLACED_NOTIFICATION' | 'SALES_REPORT_PROCESSED_NOTIFICATION' | 'RETAILER_DAILY_SUMMARY_EMAIL' | 'STRIPE_CHARGE_NOTIFICATION' | 'RETAILER_WEEKLY_SUMMARY_EMAIL';

export enum SubscriptionState {
    SUBSCRIPTION_NOT_PERMITTED,
    SUBSCRIPTION_ON,
    SUBSCRIPTION_OFF,
}
export type SubscriptionStateString = 'SUBSCRIPTION_OFF' | 'SUBSCRIPTION_ON' | 'SUBSCRIPTION_NOT_PERMITTED';

export type ISubsriptionStateByEmailTypeString = {
    [emailTypeString in EmailTypeString] : SubscriptionState;
};

export enum DailySummaryEmailConfigurationOption {
    INCLUDE_PRICE_SETTING,
    INCLUDE_NEW_PRODUCT_ORDER,
    INCLUDE_FINALIZED_INVENTORY_CHANGE,
}
export type DailySummaryEmailConfigurationOptionString = 'INCLUDE_PRICE_SETTING' | 'INCLUDE_NEW_PRODUCT_ORDER' | 'INCLUDE_FINALIZED_INVENTORY_CHANGE';
export type IDailySummaryEmailConfiguration = {
    [dailySummaryEmailConfigurationOptionString in DailySummaryEmailConfigurationOptionString] : boolean;
};

export interface IUserRetailerEmailSubscriptionData {
    subscriptionStateByEmailTypeString : ISubsriptionStateByEmailTypeString;
    dailySummaryEmailConfiguration : IDailySummaryEmailConfiguration;    // Currently this is the only configurable subscription.
}

export interface IUserRetailerEmailSubscriptionDisplayData {
    retailerName : string;
    subscriptionData : IUserRetailerEmailSubscriptionData;
}

export interface IUserEmailSubscriptionDisplayDataByRetailerId {
    [retailerId : string] : IUserRetailerEmailSubscriptionDisplayData;
}
