import { DistributorId } from 'api/Distributor/model/DistributorId';
import { Packaging } from 'api/Product/model/Packaging';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { productUtils } from 'api/Product/utils/productUtils';

import {
    ICatalogItemOptionRowId, 
    IPricedProductPackageRowId,
    IProductPackageRowInfo,
    IProductPackageRowInfoByAttribute
} from 'shared/components/AddItem/reducers/addItemReducers';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export const utils = {
    isCatalogItemOptionRowId : (
        rowId : ICatalogItemOptionRowId | IPricedProductPackageRowId
    ) : rowId is ICatalogItemOptionRowId => {
        return rowId.hasOwnProperty('catalogItemId');
    },
};

export const serializeProductPackaging = (packaging : Packaging) => {
    let packageIdValue : string = (packaging.getUnit() || '') + '-' + (packaging.getQuantityOfContent() || '');

    const content = packaging.getContent();
    if (content !== null) {
        packageIdValue += '>' + (content.getUnit() || '') + '-' + (content.getQuantityOfContent() || '');

        const contentContent = content.getContent();
        if (contentContent !== null) {
            packageIdValue += '>' + (contentContent.getUnit() || '') + '-' + (contentContent.getQuantityOfContent() || '');
        }
    }

    return packageIdValue;
};

export const serializeIProductPackageRowInfoAttributes = (productPackageRow : IProductPackageRowInfo) => {
    // assume the package rows for an item have distinct packages (dey betta do??)
    return serializeProductPackaging(productPackageRow.packaging);
};

export const iteratePackageRows = (
    productPackageRowInfoByAttribute : IProductPackageRowInfoByAttribute,
    productId : ProductId,
    product : Product,
    packaging : Packaging,
    distributorId : DistributorId | null,
    doIterateProductPackages : boolean
) => {
    const productQuantityUnit = packaging.getPackagingId() || packaging.getUnit();
    if (productQuantityUnit === null) {
        throw new RuntimeException('unexpected');
    }
    const price = productUtils.convertPriceToProductQuantityUnit(product, productQuantityUnit);

    const packageRowInfo : IProductPackageRowInfo = {
        productId,
        isDisabled: false,
        isSelected: false,
        packaging,
        distributorId,
        price,
    };
    const attribute = serializeIProductPackageRowInfoAttributes(packageRowInfo);
    productPackageRowInfoByAttribute[attribute] = packageRowInfo;

    const content = packaging.getContent();
    if (doIterateProductPackages && (content !== null) && (packaging.getQuantityOfContent() !== null) && (content.getContent() !== null)) {
        iteratePackageRows(productPackageRowInfoByAttribute, productId, product, content, distributorId, doIterateProductPackages);
    }
};
