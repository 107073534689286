import moment from 'moment-timezone';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { InventoryCountId } from 'api/InventoryCount/model/InventoryCountId';
import { ProductId } from 'api/Product/model/ProductId';
import { SalesEntry } from 'api/Reports/model/SalesEntry';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';

export class ItemLevelSalesReportConfiguration {
    constructor (
        private readonly creationTime : moment.Moment,
        private readonly creatorUserId : UserAccountId,
        private readonly dateProcessed : moment.Moment | null,
        private readonly startingInventoryId : InventoryCountId,
        private readonly endingInventoryId : InventoryCountId,
        private readonly salesBySalesItemId : StringValueMap<SalesItemId, SalesEntry>,
        private readonly isProcessing : boolean,
        private readonly trackedProductIdSet : StringValueSet<ProductId>,
        private readonly trackSalesItemsWithNoComponents : boolean,
        private readonly usageBottleAdjustmentsByProductId : StringValueMap<ProductId, number>, // THIS IS DEPRECATED, BUT NEED TO MAKE OLD REPORTING NUMBERS LINE UP
    ) {}

    public getCreationTime() : moment.Moment {
        return this.creationTime;
    }

    public getCreatorUserId() : UserAccountId {
        return this.creatorUserId;
    }

    public getDateProcessed() : moment.Moment | null {
        return this.dateProcessed;
    }

    public getStartingInventoryId() : InventoryCountId {
        return this.startingInventoryId;
    }

    public getEndingInventoryId() : InventoryCountId {
        return this.endingInventoryId;
    }

    public getSalesBySalesItemId() : StringValueMap<SalesItemId, SalesEntry> {
        return this.salesBySalesItemId;
    }

    public getIsProcessing() : boolean {
        return this.isProcessing;
    }

    public getTrackedProductIdSet() : StringValueSet<ProductId> {
        return this.trackedProductIdSet;
    }

    public getTrackSalesItemsWithNoComponents() : boolean {
        return this.trackSalesItemsWithNoComponents;
    }

    public getUsageBottleAdjustmentsByProductId() : StringValueMap<ProductId, number> {
        return this.usageBottleAdjustmentsByProductId;
    }
}
