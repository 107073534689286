import React from 'react';

import 'shared/css/LoadingCover.scss';
import 'shared/css/MakeItSpin.scss';

export interface ILoadingCoverProps {
    className : string;
    hasLoadingIcon : boolean;
}
export class LoadingCover extends React.Component<ILoadingCoverProps, object> {
    public render() {
        const {
            className,
            hasLoadingIcon
        } = this.props;

        return (
            <div className={ 'loading-cover ' + className }>
                { hasLoadingIcon &&
                    <span className="bevicon bevico-spinner8 make-it-spin loading-cover-icon" />
                }
            </div>
        );
    }
}
