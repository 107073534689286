import { LocationId } from 'api/Location/model/LocationId';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';

import { IInventorySheetsService } from '../interfaces/IInventorySheetsService';

import { AjaxUtils } from 'shared/utils/ajaxUtils';

export class InventorySheetsServiceImpl implements IInventorySheetsService {

    public uploadInventorySheets(
        userSessionId : UserSessionId,
        locationId : LocationId,
        files : Array<File>,
        wasSuccessfullyParsedByProductUploader : boolean,
    ) : Promise<void> {
        const formData : FormData = new FormData();
        files.forEach((file : File) => {
            formData.append(file.name, file);
        });
        formData.append('successfully_parsed', wasSuccessfullyParsedByProductUploader.toString());

        return AjaxUtils.ajaxPostForm(url('account:upload_inventory_sheets', null, locationId.getValue(), null), formData);
    }
}
