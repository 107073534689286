import { IWelcomeQuestionResponse } from 'api/Location/model/IWelcomeQuestionResponse';
import { WelcomeQuestion } from 'api/Location/model/WelcomeQuestion';

export class WelcomeQuestionnaireResponseObjectToJsonSerializer {
    public getWelcomeQuestionnaireResponseJson(
        responseByWelcomeQuestion : {[question in WelcomeQuestion]: IWelcomeQuestionResponse}
        ) : {[question in WelcomeQuestion]: {is_selected_by_option : {[optionValue : string] : boolean}, custom_option : string | null }} {
        return {
            user_role : {
                is_selected_by_option : responseByWelcomeQuestion.user_role.isSelectedByOptionValue,
                custom_option : responseByWelcomeQuestion.user_role.customOptionValue,
            },
            user_motivation : {
                is_selected_by_option : responseByWelcomeQuestion.user_motivation.isSelectedByOptionValue,
                custom_option : responseByWelcomeQuestion.user_motivation.customOptionValue,
            },
            user_inventory_day: {
                is_selected_by_option : responseByWelcomeQuestion.user_inventory_day.isSelectedByOptionValue,
                custom_option : responseByWelcomeQuestion.user_inventory_day.customOptionValue,
            },
            user_inventory_frequency: {
                is_selected_by_option : responseByWelcomeQuestion.user_inventory_frequency.isSelectedByOptionValue,
                custom_option : responseByWelcomeQuestion.user_inventory_frequency.customOptionValue,
            },
            user_inventory_taker: {
                is_selected_by_option : responseByWelcomeQuestion.user_inventory_taker.isSelectedByOptionValue,
                custom_option : responseByWelcomeQuestion.user_inventory_taker.customOptionValue,
            }
        };
    }
}
