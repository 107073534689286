import { StringValueMap } from 'api/Core/StringValueMap';

import { StorageArea } from 'api/InventoryCount/model/StorageArea';
import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';
import { ProductId } from 'api/Product/model/ProductId';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export class InventoryConfiguration {

    constructor (
        private readonly sortedStorageAreaIds : Array<StorageAreaId>,
        private readonly storageAreasById : StringValueMap<StorageAreaId, StorageArea>,
        private readonly sortedProductIdListsByStorageAreaId : StringValueMap<StorageAreaId, Array<ProductId>>,
    ) {
        if (sortedStorageAreaIds.length !== storageAreasById.size) {
            throw new RuntimeException('sortedStorageAreaIds and storageAreasById must be the same length');
        }

        if (sortedStorageAreaIds.length !== sortedProductIdListsByStorageAreaId.size) {
            throw new RuntimeException('sortedStorageAreaIds and sortedProductIdListsByStorageAreaId must be the same length');
        }

        sortedStorageAreaIds.forEach((storageAreaId) => {
            if (!storageAreasById.has(storageAreaId)) {
                throw new RuntimeException(`storageAreaId: '${ storageAreaId.getValue() }' not found in storageAreasById`);
            }

            if (!sortedProductIdListsByStorageAreaId.has(storageAreaId)) {
                throw new RuntimeException(`storageAreaId: '${ storageAreaId.getValue() }' not found in sortedProductIdListsByStorageAreaId`);
            }
        });
    }

    public getSortedStorageAreaIds() : Array<StorageAreaId> {
        return this.sortedStorageAreaIds;
    }

    public getStorageAreasById() : StringValueMap<StorageAreaId, StorageArea> {
        return this.storageAreasById;
    }

    public getSortedProductIdListsByStorageAreaId() : StringValueMap<StorageAreaId, Array<ProductId>> {
        return this.sortedProductIdListsByStorageAreaId;
    }
}
