import React from 'react';

import { Button } from 'shared/components/Button';
import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { ExpandingNote, ExpandingNoteTheme } from 'shared/components/ExpandingNote';
import { Select2DropdownMenuTheme } from 'shared/components/Select2Dropdown/GenericSelect2DropdownMenu';
import { Select2DropdownMenu } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { ValidationInput, ValidationInputTheme } from 'shared/components/ValidationInput';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemWithMetadata } from 'api/SalesItem/model/SalesItemWithMetadata';

import { CreateOrEditSalesItemFormUtils } from '../utils/CreateOrEditSalesItemFormUtils';

import { LinkedItemsPopover } from './LinkedItemsPopover';

import {
    ISalesItemForm,
    SalesItemFormFieldName,
    SalesItemFormValidationByFieldName,
} from '../reducers/reducers';

import '../css/SalesItemInformation.scss';

export interface ISalesItemInformationProps {
    showOrHideSalesInformationFields : () => void;
    onSalesItemInfoFormFieldChange : (fieldName : SalesItemFormFieldName, value : string) => void;
    onSalesItemInfoFormFieldEnterOrBlur : (fieldName : SalesItemFormFieldName, valur : string) => void;
    validationInputDataByFieldName : SalesItemFormValidationByFieldName;
    menuGroupInput : {
        sortedOptions : Array<IOption>;
        onOptionSelect : (optionValue : string) => void;
        onCreateCustomOption : ((optionText : string) => void);
    };
    salesItemFormInfo : ISalesItemForm;
    salesItemInformationFieldsAreShown : boolean;
    linkedItems : StringValueSet<SalesItemId>;
    linkedItemsPopoverShown : boolean;
    setLinkedItemsPopoverIsShown : (isShown : boolean) => void;
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>;
}

export class SalesItemInformation extends React.Component<ISalesItemInformationProps, object> {

    public render() {
        const {
            showOrHideSalesInformationFields,
            validationInputDataByFieldName,
            menuGroupInput,
            salesItemFormInfo,
            salesItemInformationFieldsAreShown,
            linkedItems,
            linkedItemsPopoverShown,
            setLinkedItemsPopoverIsShown,
            salesItemsById,
        } = this.props;

        const {
            salesItemInfoForm
        } = salesItemFormInfo;

        const menuGroupValue = salesItemInfoForm.menuGroup.value.length > 0 ? salesItemInfoForm.menuGroup.value : null;
        const menuGroupSelectedOption = CreateOrEditSalesItemFormUtils.getMenuGroupOptionByValue(menuGroupValue, menuGroupInput.sortedOptions);

        return (
            <div className="sales-item-information sales-item-form-section">
                <div className="item-metadata-container sales-item-form-subsection">
                    <div className="item-name-container">
                        <ValidationInput
                            theme={ ValidationInputTheme.Basic }
                            type="text"
                            step={ undefined }
                            label={ null }
                            hintText="Name your recipe"
                            value={ validationInputDataByFieldName.salesItemName.value }
                            autoFocus={ false }
                            autoComplete={ null }
                            isValid={ validationInputDataByFieldName.salesItemName.isValid }
                            isDisabled={ false }
                            errorMessage={ validationInputDataByFieldName.salesItemName.errorMessage }
                            inputClassName="item-name-input-field"
                            handleEnterClick={ this.handleOnNameEnterOrBlur }
                            handleChange={ this.handleOnNameChange }
                            handleBlur={ this.handleOnNameEnterOrBlur } // TODO: show next section on first experience
                            handleFocus={ this.doNothing }
                        />
                        { linkedItems.size > 0 &&
                            <LinkedItemsPopover
                                linkedItemsPopoverShown={ linkedItemsPopoverShown }
                                handleSetLinkedItemsPopoverIsShown={ setLinkedItemsPopoverIsShown }
                                linkedItems={ linkedItems }
                                salesItemsById={ salesItemsById }
                            />
                        }
                        <Button
                            buttonClassName="show-more-item-options-btn primary flat link medium"
                            isDisabled={ false }
                            isLoading={ false }
                            onClick={ showOrHideSalesInformationFields }
                        >
                            { salesItemInformationFieldsAreShown ? 'Hide' : 'Show' } more item options
                        </Button>
                    </div>
                </div>

                { salesItemInformationFieldsAreShown &&
                    <div className="sales-item-form-subsection">
                        <div className="menu-dropdown-container">
                            <div className="label">Menu Group</div>
                            <Select2DropdownMenu
                                theme={ Select2DropdownMenuTheme.Basic }
                                onOptionSelect={ menuGroupInput.onOptionSelect }
                                sortedOptionsAndLabelName={ [[ null, menuGroupInput.sortedOptions ]] }
                                selectedOption={ menuGroupSelectedOption }
                                placeholderText="Select..."
                                hasCreateCustomOption={ true }
                                createCustomOption={ menuGroupInput.onCreateCustomOption }
                                buttonShouldDisplaySelectedLabel={ true }
                                emptyState={ null }
                                isSearchable={ true }
                                selectedOptionHeaderShown={ true }
                                customOptionGroupLabel={ null }
                                createCustomOptionButtonLabel={ null }
                            />
                        </div>
                        <div className="pos-input-field-container">
                            <div className="label">POS ID</div>
                            <ValidationInput
                                theme={ ValidationInputTheme.Basic }
                                type="text"
                                step={ undefined }
                                label={ null }
                                hintText=""
                                value={ validationInputDataByFieldName.posId.value }
                                autoFocus={ false }
                                autoComplete={ null }
                                isValid={ validationInputDataByFieldName.posId.isValid }
                                isDisabled={ false }
                                errorMessage={ validationInputDataByFieldName.posId.errorMessage }
                                inputClassName="pos-input-field"
                                handleEnterClick={ null }
                                handleChange={ this.handleOnPosIdChange }
                                handleBlur={ this.doNothing }
                                handleFocus={ this.doNothing }
                            />
                        </div>
                        <div className="notes-container">
                            <div className="label">Notes</div>
                            <ExpandingNote
                                theme={ ExpandingNoteTheme.Basic }
                                onChange={ this.handleOnNoteChange }
                                onBlur={ this.doNothing }
                                noteContent={ validationInputDataByFieldName.note.value }
                                placeholder=""
                                isDisabled={ false }
                            />
                        </div>
                    </div>
                }
            </div>
        );
    }

    private readonly doNothing = () => {
        // pass
    }

    private readonly handleOnNameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.onSalesItemInfoFormFieldChange('salesItemName', event.currentTarget.value);
    }

    private readonly handleOnNameEnterOrBlur = (event : React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
        this.props.onSalesItemInfoFormFieldEnterOrBlur('salesItemName', event.currentTarget.value);
    }

    private readonly handleOnPosIdChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.onSalesItemInfoFormFieldChange('posId', event.currentTarget.value);
    }

    private readonly handleOnNoteChange = (event : React.ChangeEvent<HTMLTextAreaElement>) => {
        this.props.onSalesItemInfoFormFieldChange('note', event.currentTarget.value);
    }
}
