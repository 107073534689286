import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';

export class ProductAmount {

    constructor (
        private readonly productId : ProductId,
        private readonly quantityInUnit : QuantityInUnit<ProductQuantityUnit>,
    ) {}

    public getProductId() : ProductId {
        return this.productId;
    }

    public getQuantityInUnit() : QuantityInUnit<ProductQuantityUnit> {
        return this.quantityInUnit;
    }

    public equals(other : any) : boolean {
        if (!(other instanceof ProductAmount)) {
            return false;
        }

        return this.productId.equals(other.getProductId()) && this.quantityInUnit.equals(other.getQuantityInUnit());
    }
}
