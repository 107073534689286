import { InventoryReportType } from '../models/InventoryReportType';

export const getInventoryTransferOrBreakageReportIdFromURL = (reportType : InventoryReportType) : string => {
    const path : Array<string> = window.location.pathname.split('/');
    const reportId : string = path[path.indexOf(InventoryReportType[reportType]) + 1];
    if (!reportId) {
        throw new Error('Report Id not found in URL');
    }
    return reportId;
};
