import React, { Fragment } from 'react';
import MediaQuery from 'react-responsive';

import { IIngredientInformation } from 'api/SalesItem/utils/SalesItemDisplayUtils';
import { Popover } from 'shared/components/Popover/Popover';
import { MAX_MOBILE_WIDTH, MIN_TABLET_WIDTH } from 'shared/constants';
import { FormatMonetaryValueWithCents } from 'shared/utils/FormatMonetaryValue';
import { numberUtils } from 'shared/utils/numberUtils';
import { Flex } from '../FlexLayout/Flex';
import { PositionablePopupSide } from '../PositionablePopup/PositionablePopup';

import './IngredientsBreakdownPopover.scss';

export interface IIngredientsBreakdownPopoverProps {
    anchorContent : JSX.Element;
    ingredientsInformation : Array<IIngredientInformation>;
    popoverIsShown : boolean;
    setPopoverShown : (isShown : boolean) => void;
    preferredPositionArray? : Array<PositionablePopupSide>;
}

export class IngredientsBreakdownPopover extends React.Component<IIngredientsBreakdownPopoverProps, object> {

    public render() {
        const {
            anchorContent,
            popoverIsShown,
            setPopoverShown,
            preferredPositionArray,
            ingredientsInformation
        } = this.props;

        return (
            <Popover
                className="ingredients-popover"
                showOnHover={ true }
                preferredPositionArray={ preferredPositionArray || ['below'] }
                popoverContentIsShown={ popoverIsShown }
                setPopoverContentIsShown={ setPopoverShown }
                anchorContent={ anchorContent }
                popoverContent={ (
                    <Flex direction="column" className="flex-p-1 ingredients-table" justify="center">
                        <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                            <Flex direction="row" className="row-item-container">
                                <Flex direction="row" grow={ 4 }>
                                    <span className="popover-header-text">Component</span>
                                </Flex>
                                <Flex direction="row" grow={ 3 } justify="center">
                                    <span className="popover-header-text">Qty/Serving</span>
                                </Flex>
                                <Flex direction="row" grow={ 3 } justify="center">
                                    <span className="popover-header-text">Cost/Serving</span>
                                </Flex>
                            </Flex>
                        </MediaQuery>
                        { ingredientsInformation.map((ingredientInformation : IIngredientInformation) => {
                            return (
                                <Fragment key={ ingredientInformation.ingredientLabel }>
                                    <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                                        <Flex direction="row" className="row-item-container">
                                            <Flex direction="row" grow={ 4 }>
                                                <span>{ ingredientInformation.ingredientLabel }</span>
                                            </Flex>
                                            <Flex direction="row" grow={ 3 } justify="center">
                                                <span>{ numberUtils.FormatToMaximumTwoDecimalPlaces(ingredientInformation.quantityAmount) } { ingredientInformation.quantityUnitLabel }</span>
                                            </Flex>
                                            <Flex direction="row" grow={ 3 } justify="center">
                                                <span>{ ingredientInformation.costOfIngredient !== null ? FormatMonetaryValueWithCents(ingredientInformation.costOfIngredient) : 'N/A' }</span>
                                            </Flex>
                                        </Flex>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={ MAX_MOBILE_WIDTH }>
                                        <Flex direction="column" justify="space-between" className="item-container">
                                            <Flex direction="row" justify="space-between" className="row-item-container">
                                                <span>{ ingredientInformation.ingredientLabel }</span>
                                            </Flex>
                                            <Flex direction="row" justify="space-between" className="row-item-container">
                                                <span className="popover-header-text">Qty/Serving</span>
                                                <span>{ numberUtils.FormatToMaximumTwoDecimalPlaces(ingredientInformation.quantityAmount) } { ingredientInformation.quantityUnitLabel }</span>
                                            </Flex>
                                            <Flex direction="row" justify="space-between" className="row-item-container">
                                            <span className="popover-header-text">Cost/Serving</span>
                                                <span>{ ingredientInformation.costOfIngredient !== null ? FormatMonetaryValueWithCents(ingredientInformation.costOfIngredient) : 'N/A' }</span>
                                            </Flex>
                                        </Flex>
                                    </MediaQuery>
                                </Fragment>
                            );
                        }) }
                    </Flex>
                ) }
            />
        );
    }
}
