import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { StorageArea } from 'api/InventoryCount/model/StorageArea';
import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { IColumnSorting } from 'shared/components/SortableColumnHeader';
import { CheckBoxTriState } from 'shared/models/CheckBoxTriState';
import { GroupByOption } from 'shared/models/GroupByOption';

import { GroupByDropdown } from 'shared/components/GroupByDropdown';
import { AddToStorageAreasDropdown, IAddToStorageAreasDropdownProps } from '../AddToStorageAreasDropdown';
import { UnassignedItemRow } from './UnassignedItemRow';
import { UnassignedItemsTableHeader } from './UnassignedItemsTableHeader';

import { IUnassignedItemsStore, UnassignedItemsActions } from 'apps/InventoryCount/actions/UnassignedItemsActions';
import { ComponentName, IUnassignedItemsState } from 'apps/InventoryCount/reducers/UnassignedItemsReducers';

import { RowGroupHeader } from 'shared/components/LightTable/RowGroupHeader';
import { RowOptimizationWrapper } from 'shared/components/RowOptimizationWrapper/RowOptimizationWrapper';
import { StickyHeaderWrapper } from 'shared/components/StickyHeaderWrapper/StickyHeaderWrapper';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { Flex } from 'shared/components/FlexLayout/Flex';
import { TableControls } from 'shared/components/LightTable/TableControls';
import '../../css/UnassignedItemsView.scss';
import {
    getClickWillCollapseAll
} from '../../utils/StorageAreaSortingUtil';
import { CollapseAllButton } from 'shared/components/CollapseAllButton';
import { ContextMenu } from 'shared/components/ContextMenu';

export type DropdownRow = ProductId | 'ALL';

export interface IUnassignedItemsViewProps {
    readonly productIdsToDisplay : StringValueSet<ProductId>;
    readonly productsById : StringValueMap<ProductId, Product>;
    readonly activeProductIds : StringValueSet<ProductId>;
    readonly deletedProductIds : StringValueSet<ProductId>;
    readonly sortedStorageAreaIds : ReadonlyArray<StorageAreaId>;
    readonly storageAreasById : StringValueMap<StorageAreaId, StorageArea>;
    readonly storageAreaIdSetByProductId : StringValueMap<ProductId, StringValueSet<StorageAreaId>>;

    // search bar state (always null if no search bar on the page)
    readonly searchTerm : string | null;

    readonly header : string | null;
    readonly initialSelectedProductIds : StringValueSet<ProductId>;
    readonly isOffline : boolean;

    readonly addProductsToStorageAreas : (productIds : Array<ProductId>, storageAreaIds : StringValueSet<StorageAreaId>) => void;
    readonly removeProductsFromStorageAreas : (productIds : StringValueSet<ProductId>, storageAreaIds : StringValueSet<StorageAreaId>) => void;
    readonly createNewStorageArea : (storageAreaName : string) => Promise<StorageAreaId | void>;
    readonly onSetProductsAsInactiveClick : (productIds : StringValueSet<ProductId>) => void;
    readonly onDeleteProductsClick : (productIds : StringValueSet<ProductId>) => void;
    readonly shownMoreOptionsProductIds : StringValueSet<ProductId>;
    readonly visibleDropdownRow : DropdownRow | null;
    readonly isShownByComponentName : { [componentName in ComponentName] : boolean };
    readonly selectedProductIds : StringValueSet<ProductId>;
    readonly sorting : IColumnSorting;
    readonly activeGroupByOption : GroupByOption;
    readonly sortedGroupNamesToDisplay : Array<string>;
    readonly sortedProductIdsToDisplayByGroupName : {[groupName : string] : Array<ProductId>};
    readonly panelIsOpenByGroupNameFromUserInput : {[groupName : string] : boolean};
    readonly panelIsOpenByGroupNameFromFiltering : {[groupName : string] : boolean};
    readonly showGroupByDropdown : boolean; // unfortunate hack because <ConnectedUnassignedItemsView/> is being used for different things in different places. bad.
    readonly defaultPanelIsOpen : boolean;
    readonly showExpandedMobileCountSummary : () => void;
}

export interface IBoundUnassignedItemsViewProps extends IUnassignedItemsViewProps {
    readonly dispatch : Dispatch<IUnassignedItemsStore>;
}

export class UnassignedItemsView extends React.Component<IBoundUnassignedItemsViewProps, object> {

    constructor(props : IBoundUnassignedItemsViewProps) {
        super(props);

        const {
            dispatch,
            productIdsToDisplay,
            productsById,
            storageAreaIdSetByProductId,
            storageAreasById,
            initialSelectedProductIds,
            searchTerm,
        } = this.props;

        // do intitial sort, group, filter
        dispatch(UnassignedItemsActions.setSortingFilteringAndGroupingOnPropsChange(productIdsToDisplay, productsById, storageAreaIdSetByProductId, storageAreasById, searchTerm));

        // set selected
        dispatch(UnassignedItemsActions.setSelectedProductIds(initialSelectedProductIds));
    }

    public componentWillReceiveProps(nextProps : IBoundUnassignedItemsViewProps) {
        if (this.props.productsById !== nextProps.productsById ||
            this.props.storageAreaIdSetByProductId !== nextProps.storageAreaIdSetByProductId ||
            this.props.storageAreasById !== nextProps.storageAreasById ||
            this.props.productIdsToDisplay !== nextProps.productIdsToDisplay) {
                this.props.dispatch(UnassignedItemsActions.setSortingFilteringAndGroupingOnPropsChange(
                    nextProps.productIdsToDisplay,
                    nextProps.productsById,
                    nextProps.storageAreaIdSetByProductId,
                    nextProps.storageAreasById,
                    nextProps.searchTerm));
        }else if (this.props.searchTerm !== nextProps.searchTerm) { // previous case handles setting search term
            this.props.dispatch(UnassignedItemsActions.onSearchTermChange(nextProps.searchTerm));
        }
        return true;
    }

    public render() {
        const {
            sortedProductIdsToDisplayByGroupName,
            sortedStorageAreaIds,
            storageAreasById,
            isShownByComponentName,
            storageAreaIdSetByProductId,
            visibleDropdownRow,
            header,
            selectedProductIds,
            sorting,
            sortedGroupNamesToDisplay,
            activeGroupByOption,
            productIdsToDisplay,
            panelIsOpenByGroupNameFromUserInput,
            panelIsOpenByGroupNameFromFiltering,
            createNewStorageArea,
            isOffline,
            searchTerm,
            showGroupByDropdown,
            defaultPanelIsOpen,
            activeProductIds,
            deletedProductIds,
            productsById
        } = this.props;

        const selectedProductCountByStorageAreaId : StringValueMap<StorageAreaId, number> = this.getSelectedProductCountByStorageAreaId();

        const checkBoxStatesByStorageAreaId = new StringValueMap<StorageAreaId, CheckBoxTriState>();
        if (visibleDropdownRow === null) {
            sortedStorageAreaIds.forEach((storageAreaId) => {
                checkBoxStatesByStorageAreaId.set(storageAreaId, CheckBoxTriState.Unchecked);
            });
        } else {
            if (visibleDropdownRow instanceof ProductId) {
                const storageAreaIdSet : StringValueSet<StorageAreaId> | undefined = storageAreaIdSetByProductId.get(visibleDropdownRow);
                if (typeof storageAreaIdSet === 'undefined') {
                    throw new RuntimeException('unexpected');
                }

                sortedStorageAreaIds.forEach((storageAreaId : StorageAreaId) => {
                    const checkBoxState : CheckBoxTriState = storageAreaIdSet.has(storageAreaId) ?
                        CheckBoxTriState.Checked :
                        CheckBoxTriState.Unchecked;

                    checkBoxStatesByStorageAreaId.set(
                        storageAreaId,
                        checkBoxState);
                });
            } else { // All
                sortedStorageAreaIds.forEach((storageAreaId : StorageAreaId) => {
                    const productCount : number | undefined = selectedProductCountByStorageAreaId.get(storageAreaId);
                    if (typeof productCount === 'undefined') {
                        throw new RuntimeException('unexpected');
                    }

                    let checkBoxState : CheckBoxTriState;
                    if (productCount > 0 && productCount === selectedProductIds.size) {
                        checkBoxState = CheckBoxTriState.Checked;
                    } else if (productCount === 0) {
                        checkBoxState = CheckBoxTriState.Unchecked;
                    } else {
                        checkBoxState = CheckBoxTriState.Indeterminate;
                    }

                    checkBoxStatesByStorageAreaId.set(
                        storageAreaId,
                        checkBoxState);
                });
            }
        }

        const setShowDropdown = (showDropdownForRow : boolean) => {
            return this.setShowDropdownForRow(showDropdownForRow, 'ALL');
        };

        const onDropdownCancelClick = () => {
            return this.onDropdownCancelClickForRow('ALL');
        };

        const onDropdownSaveClick = (addStorageAreaIds : StringValueSet<StorageAreaId>, removeStorageAreaIds : StringValueSet<StorageAreaId>) => {
            return this.onDropdownSaveClickForRow(addStorageAreaIds, removeStorageAreaIds, 'ALL');
        };

        const onDropdownBlur = (addStorageAreaIds : StringValueSet<StorageAreaId>, removeStorageAreaIds : StringValueSet<StorageAreaId>) => {
            return this.onDropdownSaveClickForRow(addStorageAreaIds, removeStorageAreaIds, 'ALL');
        };

        const checkBoxStateCanBeIndeterminateByStorageAreaId = new StringValueMap<StorageAreaId, boolean>();
        sortedStorageAreaIds.forEach((storageAreaId) => {
            const productCount : number | undefined = selectedProductCountByStorageAreaId.get(storageAreaId);
            if (typeof productCount === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            const canProductBeIndeterminate = (productCount > 0) && (productCount < selectedProductIds.size);

            checkBoxStateCanBeIndeterminateByStorageAreaId.set(storageAreaId, canProductBeIndeterminate);
        });

        let selectAllState : CheckBoxTriState;
        if (selectedProductIds.size === 0) {
            selectAllState = CheckBoxTriState.Unchecked;
        } else if (selectedProductIds.size === productIdsToDisplay.size) {
            selectAllState = CheckBoxTriState.Checked;
        } else {
            selectAllState = CheckBoxTriState.Indeterminate;
        }

        const isOpenByGroupName : {[groupName : string] : boolean} = {};
        sortedGroupNamesToDisplay.forEach((groupName : string) => {
            let panelIsOpenFromUserInput = panelIsOpenByGroupNameFromUserInput[groupName];
            if (typeof panelIsOpenFromUserInput === 'undefined') {
                panelIsOpenFromUserInput = defaultPanelIsOpen;
            }
            const isOpen = panelIsOpenByGroupNameFromFiltering[groupName] || panelIsOpenFromUserInput;

            isOpenByGroupName[groupName] = isOpen;
        });

        const onSelectAllClick = () => {
            if (selectAllState === CheckBoxTriState.Unchecked) {
                this.props.dispatch(UnassignedItemsActions.setSelectedProductIds(productIdsToDisplay));
            } else {
                this.props.dispatch(UnassignedItemsActions.setSelectedProductIds(new StringValueSet<ProductId>()));
            }
        };

        const totalVisibleItems = Object.keys(sortedProductIdsToDisplayByGroupName).reduce(
            (previous, productId) => {
                const value = sortedProductIdsToDisplayByGroupName[productId].length;
                return value + previous;
            },
            0.0
        );

        const nextClickWillCollapseAll = getClickWillCollapseAll(isOpenByGroupName);

        return (
            <div
                className="unassigned-items-view light-table"
            >
                <div className="unassigned-items-view-header-container">
                    <div className="unassigned-items-view-header row">
                        <div className="inventory-count-header no-pad">
                            <TableControls>
                                <Flex direction="row" wrap="wrap" inline={ true } align="end" className="flex-px-1">
                                    <h3>{ header }</h3>
                                </Flex>
                                <Flex direction="row" inline={ true } justify="end" align="center" className="table-controls-secondary">
                                        <AddToStorageAreasDropdown
                                            sortedStorageAreaIds={ sortedStorageAreaIds }
                                            storageAreasById={ storageAreasById }
                                            savedCheckBoxStatesByStorageAreaId={ checkBoxStatesByStorageAreaId }
                                            toggleDropdownButtonText="Assign Selected"
                                            showDropdown={ visibleDropdownRow === 'ALL' }
                                            setShowDropdown={ setShowDropdown }
                                            onDropdownCancelClick={ onDropdownCancelClick }
                                            onDropdownSaveClick={ onDropdownSaveClick }
                                            onDropdownBlur={ onDropdownBlur }
                                            onCreateNewStorageArea={ createNewStorageArea }
                                            isOffline={ isOffline }
                                        />
                                        <ContextMenu
                                            items={ [
                                                {
                                                    label: 'Archive Selected Items',
                                                    iconClass: 'bevicon bevico-archive',
                                                    handler: this.onSetSelectedProductsAsInactiveClick,
                                                    isDisabled: (activeProductIds.size === 0),
                                                },
                                                {
                                                    label: 'Delete Selected Items',
                                                    iconClass: 'bevicon bevico-delete',
                                                    handler: this.onDeleteSelectedProductsClick,
                                                    isDisabled: (deletedProductIds.size === productsById.size),
                                                },
                                            ] }
                                            menuIsShown={ isShownByComponentName.allProductsMoreOptions }
                                            setMenuIsShown={ this.setAllProductsMoreOptionsIsShown }
                                        />
                                    </Flex>
                            </TableControls>
                        </div>
                        <div className="table-controls-header">
                            <TableControls>
                                <Flex direction="row" wrap="wrap" inline={ true } align="end" className="flex-px-1">
                                    { showGroupByDropdown &&
                                        <GroupByDropdown
                                            dropdownOptionsShown={ isShownByComponentName.groupByOptionsDropdown }
                                            selectedOption={ activeGroupByOption }
                                            setDropdownOptionsShown={ this.setGroupByDropdownOptionsShown }
                                            setSelectedOption={ this.setGroupBySelectedOption }
                                            availableGroupByOptions={ [GroupByOption.ALL_ITEMS, GroupByOption.CATEGORY, GroupByOption.ITEM_TYPE, GroupByOption.LAST_EDITED] }
                                        />
                                    }
                                </Flex>
                                <Flex direction="row" wrap="wrap" inline={ true } justify="end" align="center" className="table-controls-secondary">
                                    <CollapseAllButton
                                        nextClickWillCollapseAll={ nextClickWillCollapseAll }
                                        onClick={ this.onCollapseAllButtonClick(nextClickWillCollapseAll) }
                                    />
                                </Flex>
                            </TableControls>
                        </div>
                    </div>
                </div>
                { this.props.children }
                <StickyHeaderWrapper>
                    <UnassignedItemsTableHeader
                        selectAllState={ selectAllState }
                        onToggleSelect={ onSelectAllClick }
                        onSortChange={ this.onSortChange }
                        sorting={ sorting }
                    />
                </StickyHeaderWrapper>
                { this.createGroupPanels(visibleDropdownRow, checkBoxStatesByStorageAreaId, sortedGroupNamesToDisplay, sortedProductIdsToDisplayByGroupName, isOpenByGroupName) }
                { (totalVisibleItems === 0) && searchTerm !== null && searchTerm.length > 0 &&
                    <div className="empty-state">
                        <h4>No items match your search</h4>
                        <p>Clear your search or try a different term</p>
                    </div>
                }
            </div>
        );
    }

    private readonly onCollapseAllButtonClick = (nextClickWillCollapseAll : boolean) => {
        return () => {
            this.props.dispatch(UnassignedItemsActions.onCollapseAllClick(nextClickWillCollapseAll));
        };
    }

    private readonly createGroupPanels = (
        currentDropdownRow : DropdownRow | null,
        savedCheckBoxStatesByStorageAreaId : StringValueMap<StorageAreaId, CheckBoxTriState>,
        orderedGroupNames : Array<string>,
        sortedProductIdsToDisplayByGroupName : {[groupName : string] : Array<ProductId>},
        isOpenByGroupName : {[groupName : string] : boolean},
    ) => {
        return orderedGroupNames.map((groupName : string, index : number) => {

            const productIdsForGroup = sortedProductIdsToDisplayByGroupName[groupName];

            const rowFactory = this.createUnassignedItemsRowFactoryForGroup(
                currentDropdownRow,
                savedCheckBoxStatesByStorageAreaId,
                productIdsForGroup
            );
            let rowContainer : HTMLDivElement;
            const containerBodyRefFunction = (containerBody : HTMLDivElement) => {
                rowContainer = containerBody;
            };

            const getRowContainer = () => {
                return rowContainer;
            };

            const groupIsOpen = isOpenByGroupName[groupName];

            return (
                <div className="storage-area-manager-group-container light-table-group-container" key={ groupName }>
                    <StickyHeaderWrapper>
                        { this.getRowGroupHeader(groupName, productIdsForGroup, this.props.selectedProductIds, productIdsForGroup.length, groupIsOpen) }
                    </StickyHeaderWrapper>
                    <div ref={ containerBodyRefFunction }>
                        <RowOptimizationWrapper
                            numRows={ productIdsForGroup.length }
                            rowFactory={ rowFactory }
                            getRowContainer={ getRowContainer }
                            isOpen={ groupIsOpen }
                        />
                    </div>
                </div>
            );
        });
    }

    private readonly createUnassignedItemsRowFactoryForGroup = (
        currentDropdownRow : DropdownRow | null,
        savedCheckBoxStatesByStorageAreaId : StringValueMap<StorageAreaId, CheckBoxTriState>,
        sortedProductIds : Array<ProductId>,
    ) => {
        const {
            productsById,
            activeProductIds,
            deletedProductIds,
            sortedStorageAreaIds,
            storageAreasById,
            storageAreaIdSetByProductId,
            onSetProductsAsInactiveClick,
            onDeleteProductsClick,
            shownMoreOptionsProductIds,
            selectedProductIds,
            createNewStorageArea,
            isOffline
        } = this.props;

        return (i : number) => {
            const productId = sortedProductIds[i];

            const product : Product | undefined = productsById.get(productId);
            if (typeof product === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            const assignedStorageAreaIds : StringValueSet<StorageAreaId> | undefined = storageAreaIdSetByProductId.get(productId);
            if (typeof assignedStorageAreaIds === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            const onRemoveStorageAreaClick = (storageAreaId : StorageAreaId) => {
                return this.onRemoveStorageAreaClickForPill(storageAreaId, productId);
            };

            const showDropdown : boolean = (currentDropdownRow instanceof ProductId) ? productId.equals(currentDropdownRow) : false;

            const setShowDropdown = (showDropdownForRow : boolean) => {
                return this.setShowDropdownForRow(showDropdownForRow, productId);
            };

            const onDropdownCancelClick = () => {
                return this.onDropdownCancelClickForRow(productId);
            };

            const onDropdownSaveClick = (addStorageAreaIds : StringValueSet<StorageAreaId>, removeStorageAreaIds : StringValueSet<StorageAreaId>) => {
                return this.onDropdownSaveClickForRow(addStorageAreaIds, removeStorageAreaIds, productId);
            };

            const onDropdownBlur = (addStorageAreaIds : StringValueSet<StorageAreaId>, removeStorageAreaIds : StringValueSet<StorageAreaId>) => {
                return this.onDropdownSaveClickForRow(addStorageAreaIds, removeStorageAreaIds, productId);
            };

            const addToStorageAreasDropdownProps : IAddToStorageAreasDropdownProps = {
                sortedStorageAreaIds,
                storageAreasById,
                savedCheckBoxStatesByStorageAreaId,
                toggleDropdownButtonText: 'Assign Area',
                showDropdown,
                setShowDropdown,
                onDropdownCancelClick,
                onDropdownSaveClick,
                onDropdownBlur,
                onCreateNewStorageArea: createNewStorageArea,
                isOffline,
            };

            const isActive = activeProductIds.has(productId);
            const isDeleted = deletedProductIds.has(productId);
            const menuIsShown = shownMoreOptionsProductIds.has(productId);
            const rowIsSelected = selectedProductIds.has(productId);

            const setUnassignedItemRowMenuIsShown = (isShown : boolean) : void => {
                return this.setMenuIsShownForRow(productId, isShown);
            };

            const onSetAsInactiveClick = () : void => { // TODO handle select thing when on this?
                return onSetProductsAsInactiveClick(new StringValueSet([productId]));
            };

            const onDeleteClick = () : void => { // TODO handle select thing when on this?
                return onDeleteProductsClick(new StringValueSet([productId]));
            };

            const setIsSelected = (isSelected : boolean) : void => {
                return this.setProductRowIsSelected(productId, isSelected);
            };

            return (
                <UnassignedItemRow
                    key={ productId.getValue() }
                    product={ product }
                    isActive={ isActive }
                    isDeleted={ isDeleted }
                    assignedStorageAreaIds={ assignedStorageAreaIds }
                    onRemoveStorageAreaClick={ onRemoveStorageAreaClick }
                    addToStorageAreasDropdownProps={ addToStorageAreasDropdownProps }
                    menuIsShown={ menuIsShown }
                    setMenuIsShown={ setUnassignedItemRowMenuIsShown }
                    onSetProductAsInactiveClick={ onSetAsInactiveClick }
                    onDeleteProductClick={ onDeleteClick }
                    isSelected={ rowIsSelected }
                    setIsSelected={ setIsSelected }
                />
            );
        };
    }

    private readonly getRowGroupHeader = (
        groupName : string,
        productIds : Array<ProductId>,
        selectedProductIds : StringValueSet<ProductId>,
        unfilteredGroupSize : number,
        isOpen : boolean,
    ) : JSX.Element => {

        let selectAllState : CheckBoxTriState;
        let selectedCount = 0;
        productIds.forEach((productId : ProductId) => {
            if (selectedProductIds.has(productId)) {
                selectedCount += 1;
            }
        });

        if (selectedCount === 0) {
            selectAllState = CheckBoxTriState.Unchecked;
        } else if (selectedCount === productIds.length) {
            selectAllState = CheckBoxTriState.Checked;
        } else {
            selectAllState = CheckBoxTriState.Indeterminate;
        }

        const onSelectAllClick = () => {
            if (selectAllState === CheckBoxTriState.Unchecked) {
                const newSelectedProductIds = new StringValueSet(Array.from(selectedProductIds.values()).concat(productIds));
                this.props.dispatch(UnassignedItemsActions.setSelectedProductIds(newSelectedProductIds));
            } else {
                const newSelectedProductIdsArray = Array.from(selectedProductIds.values()).filter((productId) => productIds.indexOf(productId) === -1);
                const newSelectedProductIds = new StringValueSet(newSelectedProductIdsArray);
                this.props.dispatch(UnassignedItemsActions.setSelectedProductIds(newSelectedProductIds));
            }
        };

        const onClick = () => {
            this.props.dispatch(UnassignedItemsActions.setPanelIsOpenFromUserInputForGroupNames(new Set([groupName]), !isOpen));
        };

        return (
            <RowGroupHeader
                selectAllState={ selectAllState }
                onSelectAllClick={ onSelectAllClick }
                groupName={ groupName }
                isOpen={ isOpen }
                visibleItemCount={ productIds.length }
                totalItemCount={ unfilteredGroupSize }
                onClick={ onClick }
                showExpandArrow={ true }
            />
        );
    }

    private readonly setShowDropdownForRow = (showDropdown : boolean, dropdownRow : DropdownRow) => {
        const {
            storageAreaIdSetByProductId,
            dispatch,
        } = this.props;

        if (storageAreaIdSetByProductId === null) {
            throw new RuntimeException('unexpected');
        }

        if (showDropdown) {
            if (!this.isEqualToCurrentDropdownRow(dropdownRow)) {
                dispatch(UnassignedItemsActions.setVisibleDropdownRow(dropdownRow));
            }
        } else if (this.isEqualToCurrentDropdownRow(dropdownRow)) {
            dispatch(UnassignedItemsActions.setVisibleDropdownRow(null));
        }
    }

    private readonly onSetSelectedProductsAsInactiveClick = () => {
        this.props.onSetProductsAsInactiveClick(this.props.selectedProductIds);
        this.props.dispatch(UnassignedItemsActions.setSelectedProductIds(new StringValueSet())); // unselect all // TODO this is bad if they hit cancel...
    }

    private readonly onDeleteSelectedProductsClick = () => {
        this.props.onDeleteProductsClick(this.props.selectedProductIds);
        this.props.dispatch(UnassignedItemsActions.setSelectedProductIds(new StringValueSet())); // unselect all // TODO this is bad if they hit cancel...
    }

    private readonly onRemoveStorageAreaClickForPill = (storageAreaId : StorageAreaId, productId : ProductId) => {
        const {
            removeProductsFromStorageAreas,
        } = this.props;
        removeProductsFromStorageAreas(new StringValueSet([productId]), new StringValueSet([storageAreaId]));
    }

    private readonly setMenuIsShownForRow = (productId : ProductId, isShown : boolean) => {
        this.props.dispatch(UnassignedItemsActions.setMoreOptionsIsShownForProductIds(new StringValueSet([productId]), isShown));
    }

    private readonly onDropdownCancelClickForRow = (dropdownRow : DropdownRow) => {
        const {
            dispatch,
        } = this.props;

        dispatch(UnassignedItemsActions.setVisibleDropdownRow(null));
    }

    private readonly onDropdownSaveClickForRow = (addStorageAreaIds : StringValueSet<StorageAreaId>, removeStorageAreaIds : StringValueSet<StorageAreaId>, dropdownRow : DropdownRow) => {
        const {
            activeProductIds,
            deletedProductIds,
            storageAreaIdSetByProductId,
            addProductsToStorageAreas,
            removeProductsFromStorageAreas,
            visibleDropdownRow,
            selectedProductIds,
            dispatch,
        } = this.props;

        if ((visibleDropdownRow === null) || (storageAreaIdSetByProductId === null)) {
            throw new RuntimeException('unexpected');
        }

        if (!this.isEqualToCurrentDropdownRow(dropdownRow)) {
            throw new RuntimeException('unexpected: can only operate on currentDropdownRow');
        }

        if (dropdownRow instanceof ProductId) {
            if (addStorageAreaIds.size > 0) {
                addProductsToStorageAreas([dropdownRow], addStorageAreaIds);
            }

            if (removeStorageAreaIds.size > 0) {
                removeProductsFromStorageAreas(new StringValueSet([dropdownRow]), removeStorageAreaIds);
            }
        } else { // All
            const activeSelectedProductIds = Array.from(selectedProductIds.values()).filter((productId) => {
                return !deletedProductIds.has(productId) && activeProductIds.has(productId);
            });

            if (removeStorageAreaIds.size > 0) {
                removeProductsFromStorageAreas(new StringValueSet(activeSelectedProductIds), removeStorageAreaIds);
            }

            if (addStorageAreaIds.size > 0) {
                addProductsToStorageAreas(activeSelectedProductIds, addStorageAreaIds);
            }

        }

        dispatch(UnassignedItemsActions.setVisibleDropdownRow(null));
    }

    private readonly isEqualToCurrentDropdownRow = (dropdownRow : DropdownRow) => {
        const {
            visibleDropdownRow,
        } = this.props;

        if (visibleDropdownRow === null) {
            return false;
        }

        if (visibleDropdownRow instanceof ProductId && dropdownRow instanceof ProductId) {
            return visibleDropdownRow.equals(dropdownRow);
        }

        return visibleDropdownRow === dropdownRow;
    }

    private readonly setAllProductsMoreOptionsIsShown = (isShown : boolean) => {
        this.props.dispatch(UnassignedItemsActions.setComponentIsShown('allProductsMoreOptions', isShown));
    }

    private readonly getSelectedProductCountByStorageAreaId = () : StringValueMap<StorageAreaId, number> => {
        const {
            sortedStorageAreaIds,
            storageAreaIdSetByProductId,
            selectedProductIds,
        } = this.props;

        if (storageAreaIdSetByProductId === null) {
            throw new RuntimeException('unexpected');
        }

        const productCountByStorageAreaId : StringValueMap<StorageAreaId, number> = new StringValueMap();
        sortedStorageAreaIds.forEach((storageAreaId : StorageAreaId) => {
            productCountByStorageAreaId.set(storageAreaId, 0);
        });

        storageAreaIdSetByProductId.forEach(
            (storageAreaIdSet : StringValueSet<StorageAreaId>, productId : ProductId) => {
                if (selectedProductIds.has(productId)) {
                    storageAreaIdSet.forEach((storageAreaId : StorageAreaId) => {
                        const productCount : number | undefined = productCountByStorageAreaId.get(storageAreaId);
                        if (typeof productCount === 'undefined') {
                            throw new RuntimeException('unexpected');
                        }

                        productCountByStorageAreaId.set(
                            storageAreaId,
                            productCount + 1);
                    });
                }
            }
        );

        return productCountByStorageAreaId;
    }

    private readonly setProductRowIsSelected = (productId : ProductId, isSelected : boolean) => {
        const {
            selectedProductIds
        } = this.props;

        const newSelectedProductIds : StringValueSet<ProductId> = new StringValueSet(selectedProductIds);
        if (isSelected) {
            newSelectedProductIds.add(productId);
        } else {
            newSelectedProductIds.delete(productId);
        }

        this.props.dispatch(UnassignedItemsActions.setSelectedProductIds(newSelectedProductIds));
    }

    private readonly setGroupByDropdownOptionsShown = (isShown : boolean) => {
        this.props.dispatch(UnassignedItemsActions.setComponentIsShown('groupByOptionsDropdown', isShown));
    }

    private readonly setGroupBySelectedOption = (groupByOption : GroupByOption) => {
        this.props.dispatch(UnassignedItemsActions.onSelectActiveGroupByOption(groupByOption, this.props.productsById, this.props.storageAreaIdSetByProductId)); // TODO need a thunk action for grouping !
    }

    private readonly onSortChange = (sortBy : string) => {
        const {
            sorting
        } = this.props;

        this.props.dispatch(UnassignedItemsActions.onSelectActiveColumnSort({
            sortedBy: sortBy,
            direction: (sortBy === sorting.sortedBy) ? sorting.direction * -1 : sorting.direction,
        }));
    }
}

export interface IConnectedUnassignedItemsViewProps {
    readonly productIdsToDisplay : StringValueSet<ProductId>;
    readonly productsById : StringValueMap<ProductId, Product>;
    readonly activeProductIds : StringValueSet<ProductId>;
    readonly deletedProductIds : StringValueSet<ProductId>;
    readonly sortedStorageAreaIds : ReadonlyArray<StorageAreaId>;
    readonly storageAreasById : StringValueMap<StorageAreaId, StorageArea>;
    readonly storageAreaIdSetByProductId : StringValueMap<ProductId, StringValueSet<StorageAreaId>>;
    readonly searchTerm : string | null;

    readonly header : string | null;
    readonly initialSelectedProductIds : StringValueSet<ProductId>;
    readonly isOffline : boolean;

    readonly addProductsToStorageAreas : (productIds : Array<ProductId>, storageAreaIds : StringValueSet<StorageAreaId>) => void;
    readonly removeProductsFromStorageAreas : (productId : StringValueSet<ProductId>, storageAreaIds : StringValueSet<StorageAreaId>) => void;
    readonly onSetProductsAsInactiveClick : (productIds : StringValueSet<ProductId>) => void;
    readonly onDeleteProductsClick : (productId : StringValueSet<ProductId>) => void;
    readonly createNewStorageArea : (newStorageAreaName : string) => Promise<StorageAreaId | void>;
    readonly showGroupByDropdown : boolean; // unfortunate hack because <ConnectedUnassignedItemsView/> is being used for different things in different places. bad.
    readonly showExpandedMobileCountSummary : () => void;
}

interface IState {
    unassignedItemsState : IUnassignedItemsState;
}

const mapStateToProps = (
    state : IState,
    props : IConnectedUnassignedItemsViewProps,
) : IUnassignedItemsViewProps => {
    return {
        productIdsToDisplay: props.productIdsToDisplay,
        productsById: props.productsById,
        activeProductIds: props.activeProductIds,
        deletedProductIds: props.deletedProductIds,
        sortedStorageAreaIds: props.sortedStorageAreaIds,
        storageAreasById: props.storageAreasById,
        storageAreaIdSetByProductId: props.storageAreaIdSetByProductId,
        header: props.header,
        initialSelectedProductIds: props.initialSelectedProductIds,
        searchTerm: props.searchTerm,
        addProductsToStorageAreas: props.addProductsToStorageAreas,
        removeProductsFromStorageAreas: props.removeProductsFromStorageAreas,
        createNewStorageArea: props.createNewStorageArea,
        onSetProductsAsInactiveClick: props.onSetProductsAsInactiveClick,
        onDeleteProductsClick: props.onDeleteProductsClick,
        isOffline : props.isOffline,
        showExpandedMobileCountSummary : props.showExpandedMobileCountSummary,

        visibleDropdownRow: state.unassignedItemsState.visibleDropdownRow,
        shownMoreOptionsProductIds: state.unassignedItemsState.shownMoreOptionsProductIds,
        isShownByComponentName: state.unassignedItemsState.isShownByComponentName,
        selectedProductIds: state.unassignedItemsState.selectedProductIds,
        sorting: state.unassignedItemsState.tableSorting,
        sortedGroupNamesToDisplay: state.unassignedItemsState.sortedGroupNamesToDisplay,
        sortedProductIdsToDisplayByGroupName: state.unassignedItemsState.sortedProductIdsToDisplayByGroupName,
        activeGroupByOption: state.unassignedItemsState.activeGroupByOption,
        panelIsOpenByGroupNameFromUserInput: state.unassignedItemsState.panelIsOpenByGroupNameFromUserInput,
        panelIsOpenByGroupNameFromFiltering: state.unassignedItemsState.panelIsOpenByGroupNameFromFiltering,
        showGroupByDropdown: props.showGroupByDropdown,
        defaultPanelIsOpen: state.unassignedItemsState.defaultPanelIsOpen,
    };
};

export const ConnectedUnassignedItemsView = connect<IUnassignedItemsViewProps, object, IConnectedUnassignedItemsViewProps, IState>(mapStateToProps)(UnassignedItemsView);
