import React from 'react';

import { IBoundingBox } from 'shared/models/Charts/IBoundingBox';
import { ICoordinate } from 'shared/models/Charts/ICoordinate';
import { Orientation } from 'shared/models/Charts/Orientation';
import { TextPosition } from 'shared/models/Charts/TextPosition';

import { utils } from 'shared/components/Charts/utils';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import {
    axisTickLabelDistanceFromLineInPixels,
    axisTickLabelHeightInPixels,
    axisTickLineLengthInPixels,
} from 'shared/components/Charts/Axes/constants';

import {
    mouseLineColor,
    mouseLineLabelBackgroundColor,
    mouseLineLabelPaddingInPixels,
    mouseLineOpacity,
    mouseLineStrokeDasharray,
} from 'shared/components/Charts/constants';

export interface IMouseLineProps {
    readonly orientation : Orientation;
    readonly labelValue : string;
    readonly mousePosition : ICoordinate;
    readonly axisLength : number;
    readonly boundingBox : IBoundingBox;
}

export class MouseLine extends React.Component<IMouseLineProps, object> {
    private classNamePrefix : string = '';

    public render() {
        const {
            labelValue,
            axisLength,
            boundingBox,
        } = this.props;

        utils.ensureBoundingBoxIsValid(boundingBox);
        this.determineClassNamePrefix();

        if (axisLength === 0 && labelValue.length > 0) {
            throw new RuntimeException('labelValue is non-empty string, but will not show');
        }

        const className : string = this.classNamePrefix + '-container';

        return (
            <g
                className={ className }
                style={ {
                    pointerEvents: 'none',
                } }
            >
                { this.createLine() }
                { this.createLabel() }
            </g>
        );
    }

    private determineClassNamePrefix = () : void => {
        const {
            orientation,
        } = this.props;

        const className = '-mouse-line';
        switch (orientation) {
            case Orientation.HORIZONTAL: {
                this.classNamePrefix = 'horizontal' + className;
                break;
            }
            case Orientation.VERTICAL: {
                this.classNamePrefix = 'vertical' + className;
                break;
            }
            default: {
                throw new RuntimeException('orientation is unexpected value');
            }
        }
    }

    private createLine = () : JSX.Element => {
        const {
            orientation,
            mousePosition,
            axisLength,
            boundingBox,
        } = this.props;

        const className : string = this.classNamePrefix + '-line';

        let x1 : number;
        let y1 : number;
        let x2 : number;
        let y2 : number;
        switch (orientation) {
            case Orientation.HORIZONTAL: {
                x1 = axisLength;
                x2 = boundingBox.widthInPixels;

                y1 = y2 = mousePosition.y;

                break;
            }
            case Orientation.VERTICAL: {
                x1 = x2 = mousePosition.x;

                y1 = 0;
                y2 = boundingBox.heightInPixels - axisLength;

                break;
            }
            default: {
                throw new RuntimeException('orientation is unexpected value');
            }
        }

        return (
            <line
                className={ className }

                x1={ x1 }
                y1={ y1 }
                x2={ x2 }
                y2={ y2 }

                stroke={ mouseLineColor.getValue() }
                strokeDasharray={ mouseLineStrokeDasharray }
                opacity={ mouseLineOpacity }
            />
        );
    }

    private createLabel = () : JSX.Element | void => {
        const {
            orientation,
            labelValue,
            mousePosition,
            axisLength,
            boundingBox,
        } = this.props;

        if (axisLength === 0) {
            return;
        }

        const className : string = this.classNamePrefix + '-label';

        const textWidth = utils.estimateTextWidth(labelValue) + (mouseLineLabelPaddingInPixels * 2);
        const textHeight = axisTickLabelHeightInPixels + (mouseLineLabelPaddingInPixels * 2);

        let x : number;
        let y : number;
        let textPosition : TextPosition;
        let transform : string;
        switch (orientation) {
            case Orientation.HORIZONTAL: {
                x = axisLength - (axisTickLineLengthInPixels + axisTickLabelDistanceFromLineInPixels);
                y = mousePosition.y + (axisTickLabelHeightInPixels / 2);
                textPosition = 'end';

                transform = utils.getTranslationTransformation(
                    x + mouseLineLabelPaddingInPixels,
                    y + mouseLineLabelPaddingInPixels);

                transform += 'rotate(180, 0, 0)';

                break;
            }
            case Orientation.VERTICAL: {
                x = mousePosition.x;
                y = (boundingBox.heightInPixels - axisLength) + (axisTickLineLengthInPixels + axisTickLabelDistanceFromLineInPixels + axisTickLabelHeightInPixels);
                textPosition = 'middle';

                transform = utils.getTranslationTransformation(
                    x - (textWidth / 2),
                    y - axisTickLabelHeightInPixels - mouseLineLabelPaddingInPixels);

                break;
            }
            default: {
                throw new RuntimeException('orientation is unexpected value');
            }
        }

        return (
            <g
                className={ className }
            >
                <rect
                    width={ textWidth }
                    height={ textHeight }
                    transform={ transform }

                    fill={ mouseLineLabelBackgroundColor.getValue() }
                />

                <text
                    x={ x }
                    y={ y }
                    textAnchor={ textPosition }
                >
                    { labelValue }
                </text>
            </g>
        );
    }
}
