export interface IJSONDistributorRep {
    name? : string;
    email? : string;
    to_email? : boolean;
    attach_csv? : boolean;
    phone? : string;
    to_sms? : boolean;
}

export class DistributorRep {

    public static createFromJSONObject(JSONObject : IJSONDistributorRep) : DistributorRep {
        const result = new DistributorRep(
            DistributorRep.procDefault(JSONObject.name, ''),
            DistributorRep.procDefault(JSONObject.email, ''),
            DistributorRep.procDefault(JSONObject.to_email, false),
            DistributorRep.procDefault(JSONObject.attach_csv, false),
            DistributorRep.procDefault(JSONObject.phone, ''),
            DistributorRep.procDefault(JSONObject.to_sms, false),
        );

        return result;
    }

    private static procDefault<T>(value : T | undefined, defaultValue : T) : T {
        if (typeof value === 'undefined' || value === null) {
            return defaultValue;
        } else {
            return value;
        }
    }

    constructor(
        readonly name : string, // Required
        readonly email : string, // Required
        readonly emailNotifications : boolean, // Always set to true for now
        readonly attachCsv : boolean,
        readonly cell : string,
        readonly smsNotifications : boolean,
    ) { }

    public getJSONObject = () : IJSONDistributorRep => ({
        name: this.name,
        email: this.email,
        to_email: this.emailNotifications,
        attach_csv: this.attachCsv,
        phone: this.cell,
        to_sms: this.smsNotifications,
    })

    public getRestFormData = (formData : FormData) : FormData => {
        formData.append('name', this.name);
        if (this.email !== null) { formData.append('email', this.email); }
        formData.append('to_email', (this.emailNotifications) ? 'true' : 'false');
        formData.append('attach_csv', (this.attachCsv) ? 'true' : 'false');
        if (this.cell !== null) { formData.append('phone', this.cell); }
        formData.append('to_sms', (this.smsNotifications) ? 'true' : 'false');

        return formData;
    }
}
