import React from 'react';

import { ProductId } from 'api/Product/model/ProductId';
import { Packaging } from 'api/Product/model/Packaging';
import { oldPackagingUtils } from 'api/Product/utils/oldPackagingUtils';

import { CheckBoxTriState } from 'shared/models/CheckBoxTriState';
import { getCurrencySymbol } from 'shared/models/Currency';
import { CheckBox } from 'shared/components/CheckBox';

import '../css/CollapsableProductRow.scss';

export interface IProductPackageRowProps {
    productPackageRowInfoAttribute : string;
    productId : ProductId;
    packaging : Packaging;
    distributorName : string | null;
    price : number | null;
    productLength : number;
    isDisabled : boolean;
    isExpanded : boolean;
    isSelected : boolean;
    onToggleExpand : (productIdValue : string, isExpanded : boolean) => void;
    onToggleSelect : (productPackageRowInfoAttribute : string, isSelected : boolean) => void;
}

export class ProductPackageRow extends React.Component<IProductPackageRowProps, object> {
    private readonly currencySymbol = getCurrencySymbol();
    private readonly handleOnChange : (event : React.FormEvent<HTMLElement>) => void;

    constructor(props : IProductPackageRowProps) {
        super(props);

        this.handleOnChange = this.handleOnChangeGenerator();
    }

    public render() {
        const {
            packaging,
            price,
            distributorName,
            isDisabled,
            isSelected,
        } = this.props;

        return (
            <div className={ 'product-package-row' + (isDisabled ? ' product-row-disabled' : '') }>
                <div className="col-row">
                    <div className="cell col-xs-2 col-sm-1">
                        { isDisabled &&
                            <span className="bevicon bevico-check_circle" />
                        }
                        { !isDisabled &&
                            <CheckBox
                                checkBoxTriState={ isSelected ? CheckBoxTriState.Checked : CheckBoxTriState.Unchecked }
                                isDisabled={ isDisabled }
                                onClick={ this.handleOnChange }
                                label={ null }
                            />
                        }
                    </div>
                    <div className="cell col-xs-5 col-sm-6 ellipsis-out">
                        <span className="product-info">
                            { oldPackagingUtils.getDisplayTextForPackaging(oldPackagingUtils.getOldPackagingFromPackaging(packaging), true) }
                        </span>
                    </div>
                    { price !== null &&
                        <div className="cell col-xs-5 col-sm-5">
                            <div className="distributor-info">
                                <div className="distributor-product-price ellipsis-out">{ this.currencySymbol + price.toFixed(2) }</div>
                                <div className="distributor-name ellipsis-out">Vendor: { distributorName }</div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }

    private handleOnChangeGenerator() {
        return (event : React.FormEvent<HTMLElement>) => {
            this.props.onToggleSelect(this.props.productPackageRowInfoAttribute, !this.props.isSelected);
        };
    }
}
