import React from 'react';

import { Button } from './Button';

import 'shared/css/ContextMenu.scss';

export interface IContextMenuItem {
    label : string;
    iconClass : string | null;
    iconIsAfterLabel? : boolean;
    handler : () => void;
    isDisabled : boolean;
}

export interface IContextMenuProps {
    items : Array<IContextMenuItem>;
    menuIsShown : boolean;
    menuButtonIconClass? : string;
    menuButtonChildren? : Array<JSX.Element>;
    setMenuIsShown : (isShown : boolean) => void;
}

export class ContextMenu extends React.Component<IContextMenuProps, object> {
    private contextMenuComponentDiv : HTMLDivElement | undefined;

    constructor(props : IContextMenuProps) {
        super(props);
    }

    public componentDidMount() {
        // 6/20/17 can't test that this properly triggers the function with karma/sinon
        window.addEventListener('click', this.handleOnOutsideComponentClick);
    }

    public componentWillUnmount() {
        window.removeEventListener('click', this.handleOnOutsideComponentClick);
    }

    public render() {
        const {
            items,
            menuIsShown,
            menuButtonIconClass,
            menuButtonChildren,
        } = this.props;

        const menuRows = items.map((item, index) => (
            <li
                key={ `${ index }-${ item.label }` }
                className={ `list-item ${ item.isDisabled ? 'disabled' : '' }` }
            >
                <a
                    onClick={ item.isDisabled ? undefined : item.handler }
                >
                    { !item.iconIsAfterLabel &&
                        <span className={ 'context-menu-row-icon ' + (item.iconClass === null ? '' : item.iconClass) }/>
                    }
                    <span className="context-menu-row-label">
                        { item.label }
                    </span>
                    { item.iconIsAfterLabel &&
                        <span className={ 'context-menu-row-icon ' + (item.iconClass === null ? '' : item.iconClass) }/>
                    }
                </a>
            </li>
        ));
        return (
            <div className={ 'context-menu' + (menuIsShown ? ' active' : '') } ref={ this.contextMenuComponentDivRef }>
                <Button
                    buttonClassName={ `${ menuButtonIconClass ? menuButtonIconClass : 'icon no-pad flat menu-trigger bevicon bevico-more-vert' }` }
                    isDisabled={ false }
                    isLoading={ false }
                    onClick={ this.handleOpenMenu }
                >
                    { menuButtonChildren }
                </Button>
                { menuIsShown &&
                    <ul className="list">
                        { menuRows }
                    </ul>
                }
            </div>
        );
    }

    private handleOpenMenu = () => {
        this.props.setMenuIsShown(!this.props.menuIsShown);
    }

    private handleOnOutsideComponentClick = (event : UIEvent) => {
        if (this.props.menuIsShown && (!this.contextMenuComponentDiv || !this.contextMenuComponentDiv.contains((event.target as Element)))) {
            this.props.setMenuIsShown(false);
            event.stopPropagation();
        }
    }

    private contextMenuComponentDivRef = (element : HTMLDivElement) => {
        this.contextMenuComponentDiv = element;
    }
}
