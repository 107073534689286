import React from 'react';

import { IOption } from '../Dropdown/DropdownMenu';
import { Select2DropdownOption } from './Select2DropdownOption';

import './Select2DropdownMenu.scss'; // this is basically copied from BulkEditDropdownMenu.scss

import { GenericSelect2DropdownMenu, Select2DropdownMenuTheme } from 'shared/components/Select2Dropdown/GenericSelect2DropdownMenu';

export type OptionsAndLabelNameTuples = Array<[string | null, Array<IOption>]>; // null means no label

export const makeOptionFromValue = (value : string) : IOption => {
    return {
        value,
        label: value,
        icon: null,
    };
};

type IOptionComparator = (a : IOption, b : IOption) => number;
type OptionalComparatorFunction = undefined | null | ((a : string, b : string) => number);

const defaultComparator = (comparator : OptionalComparatorFunction) : IOptionComparator => {
    if (typeof comparator === 'undefined' || comparator === null) {
        return (a : IOption, b : IOption) : number => (a.value.toLocaleLowerCase() > b.value.toLocaleLowerCase()) ? 1 : -1;
    }

    return (a : IOption, b : IOption) : number => comparator(a.value, b.value);
};

const labelComparator = (comparator : OptionalComparatorFunction) : IOptionComparator => {
    if (typeof comparator === 'undefined' || comparator === null) {
        return (a : IOption, b : IOption) : number => (a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase()) ? 1 : -1;
    }

    return (a : IOption, b : IOption) : number => comparator(a.label, b.label);
};

export const createOptionsAndLabelNameTuplesFromValueArray = (
    valueList : Array<string>,
    group : string | null
) : OptionsAndLabelNameTuples => {
    return [
        [group, valueList.map(makeOptionFromValue)],
    ];
};

export const createSortedOptionsAndLablNameTuplesFromOptionArray = (
    unsortedList : Array<IOption>,
    group : string | null,
    compareFct? : (a : string, b : string) => number,
) : OptionsAndLabelNameTuples => {
    return [[
        group,
        unsortedList.sort(defaultComparator(compareFct)),
    ]];
};

export const addToSortedOptionList = (
    prevList : OptionsAndLabelNameTuples,
    newOption : string,
    group : string | null,
    compareFct? : (a : string, b : string) => number
) : OptionsAndLabelNameTuples => {

    // Do deep copy of structure Array<[string | null, Array<IOption>]>
    const newList : OptionsAndLabelNameTuples = [];
    prevList.forEach((tuple : [string | null, Array<IOption>]) => newList.push([tuple[0], [...tuple[1]]]));

    let newOptionList : Array<IOption>;

    // See if the group is present in the current list, if so use it.
    const groupIndex = newList.findIndex((tuple) => tuple[0] === group);
    if (groupIndex !== -1) {
        newOptionList = newList[groupIndex][1];
    } else {
        newOptionList = new Array<IOption>();
        newList.push([group, newOptionList]);
    }

    // Make sure that option is not already in the list. If so, then just return the old list.
    if (newOptionList.filter((option) => option.value === newOption).length > 0) {
        return prevList;
    }

    // Otherwise copy the old list add, the new option, and re-sort it.
    newOptionList.push(makeOptionFromValue(newOption));
    newOptionList.sort(defaultComparator(compareFct));

    return newList;
};

export const addToSortedLabelOptionList = (
    prevList : OptionsAndLabelNameTuples,
    newOption : string,
    group : string | null,
    compareFct? : (a : string, b : string) => number
) : OptionsAndLabelNameTuples => {

    // Do deep copy of structure Array<[string | null, Array<IOption>]>
    const newList : OptionsAndLabelNameTuples = [];
    prevList.forEach((tuple : [string | null, Array<IOption>]) => newList.push([tuple[0], [...tuple[1]]]));

    let newOptionList : Array<IOption>;

    // See if the group is present in the current list, if so use it.
    const groupIndex = newList.findIndex((tuple) => tuple[0] === group);
    if (groupIndex !== -1) {
        newOptionList = newList[groupIndex][1];
    } else {
        newOptionList = new Array<IOption>();
        newList.push([group, newOptionList]);
    }

    // Make sure that option is not already in the list. If so, then just return the old list.
    if (newOptionList.filter((option) => option.value === newOption).length > 0) {
        return prevList;
    }

    // Otherwise copy the old list add, the new option, and re-sort it.
    newOptionList.push(makeOptionFromValue(newOption));
    newOptionList.sort(labelComparator(compareFct));

    return newList;
};

export interface ISelect2DropdownMenuProps {
    theme? : Select2DropdownMenuTheme;
    isSearchable : boolean;
    selectedOptionHeaderShown : boolean;
    sortedOptionsAndLabelName : OptionsAndLabelNameTuples;
    selectedOption : IOption | null;
    onOptionSelect : (optionValue : string) => void;
    placeholderText : string | null;
    buttonShouldDisplaySelectedLabel : boolean;
    emptyState : JSX.Element | null;
    maxOptionsDisplayedPerGroup? : number;
    openDirection? : 'up' | 'down';
    isDisabled? : boolean;
    isTitleCase? : boolean;
    isOpenDefault? : boolean;
    // isOpen : boolean;
    // handleDropdownOpenClose : (dropdownIsOpen : boolean) => void;
    onSearchValueChanged? : (newValue : string | null) => void;

    hasCreateCustomOption : boolean;
    createCustomOption : ((optionText : string) => void) | null;
    createCustomOptionButtonLabel : string | null;
    customOptionGroupLabel : string | null;  // specifies which group the create button should be in - assumes only one such group exists but WILL render the button wherever the group label is found in sortedOptionsAndLabelName!!
}

export interface ISelect2DropdownMenuState {
    isOpen : boolean;
}

export class Select2DropdownMenu extends React.Component<ISelect2DropdownMenuProps, ISelect2DropdownMenuState> {

    constructor(props : ISelect2DropdownMenuProps) {
        super(props);

        this.state = {
            isOpen: this.props.isOpenDefault || false,
        };
    }

    public render() {
        const {
            theme,
            isSearchable,
            selectedOptionHeaderShown,
            openDirection,
            selectedOption,
            emptyState,
            placeholderText,
            isTitleCase,
            hasCreateCustomOption,
            customOptionGroupLabel,
            buttonShouldDisplaySelectedLabel,
            sortedOptionsAndLabelName,
            createCustomOption,
            createCustomOptionButtonLabel,
            maxOptionsDisplayedPerGroup,
            onSearchValueChanged,
        } = this.props;

        return (
            <GenericSelect2DropdownMenu
                theme={ theme }
                isSearchable={ isSearchable }
                selectedOptionHeaderShown={ selectedOptionHeaderShown }
                openDirection={ openDirection }
                selectedOption={ selectedOption }
                emptyState={ emptyState }
                placeholderText={ placeholderText }
                isTitleCase={ isTitleCase }
                hasCreateCustomOption={ hasCreateCustomOption }
                buttonShouldDisplaySelectedLabel={ buttonShouldDisplaySelectedLabel }
                sortedOptionsAndLabelName={ sortedOptionsAndLabelName }
                getOptionElementsForGroup={ this.getOptionElementsForGroup }
                createCustomOption={ createCustomOption }
                getSelectedOptionElementForHeader={ this.getSelectedOptionForHeader }
                footerElement={ null } // TODOfuture: could pass in as prop
                isOpen={ this.state.isOpen }
                handleDropdownOpenClose={ this.handleSetDropdownIsOpen }
                createCustomOptionIsLoading={ false } // no use case for this being true right now, TODOfuture: can pass in as prop
                customOptionGroupLabel={ customOptionGroupLabel }
                createCustomOptionButtonLabel={ createCustomOptionButtonLabel }
                maxOptionsDisplayedPerGroup={ maxOptionsDisplayedPerGroup }
                onSearchValueChanged={ onSearchValueChanged }
            />
        );

    }

    private readonly handleSetDropdownIsOpen = (isOpen : boolean) => {
        if (!this.props.isDisabled) {
            this.setState({
                isOpen,
            });
        }
    }

    private readonly getSelectedOptionForHeader = (selectedOption : IOption) => {
        return (
            <Select2DropdownOption // put the selected option at the top
                option={ selectedOption }
                onOptionSelect={ this.handleOnSelectOption }
                isSelected={ true }
            />
        );
    }

    private readonly getOptionElementsForGroup = (filteredOptions : Array<IOption>, selectedOption : IOption | null) : Array<JSX.Element | null> => {
        const {
            selectedOptionHeaderShown,
        } = this.props;

        const optionElements : Array<JSX.Element | null> = filteredOptions.map((option : IOption, index : number) => {
            const isSelectedOption = selectedOption !== null && option.value === selectedOption.value;

            // omit the currently selected option from option list if `selectedOptionHeaderShown`
            return selectedOptionHeaderShown && isSelectedOption ? null : (
               <Select2DropdownOption
                    option={ option }
                    onOptionSelect={ this.handleOnSelectOption }
                    isSelected={ isSelectedOption }
                    key={ index }
               />
            );
        });
        return optionElements;
    }

    // so dirty.... but bc the components that call this come from here, must do this
    private readonly handleOnSelectOption = (optionValue : string) => {
        // leave open if you re-select current value
        // in the future we might want to make this behavior dependant on a prop
        if (this.props.selectedOption === null || optionValue !== this.props.selectedOption.value) {
            this.setState({
                isOpen: false
            });

            this.props.onOptionSelect(optionValue);
        }
    }
}
