import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { LocationId } from 'api/Location/model/LocationId';
import { IProductUploadService } from 'api/Onboarding/interfaces/IProductUploadService';
import { IProductUploadDefaults } from 'api/Onboarding/model/IProductUploadDefaults';
import { ProductUploadFields } from 'api/Onboarding/model/ProductUploadFields';
import { ProductUploadJSONToObjectSerializer } from 'api/Onboarding/serializer/ProductUploadJSONToObjectSerializer';
import { ProductId } from 'api/Product/model/ProductId';

import { AjaxUtils } from 'shared/utils/ajaxUtils';

export class ProductUploadServiceImpl implements IProductUploadService {
    constructor (
        private readonly productUploadJSONToObjectSerializer : ProductUploadJSONToObjectSerializer,
    ) { }

    public getMostRecentProductUploadDefaultsForLocation = (locationId : LocationId) : Promise<StringValueMap<ProductId, IProductUploadDefaults>> => {
        const queryParameters = {
            retailer_id: locationId.getValue(),
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:product_upload'), queryParameters)
        .then((response : any) => {
            const mostRecentUpload = Object.keys(response).length === 0 ? null : response.defaults_by_product_id;
            if (mostRecentUpload === null) {
                return new StringValueMap<ProductId, IProductUploadDefaults>(); // or throw error?
            }

            return this.productUploadJSONToObjectSerializer.getDefaultsByProductIdFromJSON(mostRecentUpload);
        });
    }

    public createProductsFromUpload(
        locationId : LocationId,
        productObjects : Array<{ [bevspotColumn in ProductUploadFields] : string | null }>
    ) : Promise<StringValueSet<ProductId>> {

        const requestBody = {
            upload_items: productObjects,
        };

        const queryParameters = {
            retailer_id: locationId.getValue(),
        };

        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:product_upload'), requestBody, queryParameters)
        .then((response) => {
            const productIdValues : Array<string> = response.product_id_set;
            const productIdSet = new StringValueSet<ProductId>();
            productIdValues.forEach((value) => {
                productIdSet.add(new ProductId(value));
            });
            return Promise.resolve(productIdSet);
        });
    }
}
