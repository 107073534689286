import moment from 'moment-timezone';

/* Screen Sizes */
// This should be kept equal to Screen Sizes in python/assets/constants
export const MIN_LARGE_DESKTOP_WIDTH : number = 1200;
export const MAX_DESKTOP_WIDTH : number = 1199;
export const MIN_DESKTOP_WIDTH : number = 993;
export const MAX_TABLET_WIDTH : number = 992;
export const MIN_TABLET_WIDTH : number = 768;
export const MAX_MOBILE_WIDTH : number = 767;
export const MIN_MOBILE_WIDTH : number = 500;

export const NULL_DISTRIBUTOR_ID : string = '_null_distributor_id';
export const DISTRIBUTOR_ID_FOR_OTHER : string = '_distributor_id_for_other';
export const NULL_DISTRIBUTOR_DISPLAY_NAME = 'Other';
export const NULL_RETAILER_ID : string = '_null_retailer_id';

export const NOTIFICATION_TIMEOUT_IN_MILLISECONDS : number = 5000;

export const SLIDE_IN_MODAL_WIDTH : number = 540;

export const EARLIEST_LOWER_BOUND_DATE = moment(0).tz('UTC');

// This should be kept equal to FARTHEST_UPPER_BOUND_DATE in python/assets/constants
export const FARTHEST_UPPER_BOUND_DATE = moment('2500-01-01T00:00:00').tz('UTC');

// This should be kept equal to python/utils/api_utils UrlParameters._get_datetime
// (would be great to be replaced by a shared time serializer once we are confident that date format is standardized across different APIs)
export const DJANGO_API_UTC_TIMESTAMP_FORMAT = 'YYYY-MM-DD HH:mm:ss';

// This is the date format defined for DateTimeField in main/forms.py
// Should eventually be deleted when the whole backend is refactored to use the same date format
export const DJANGO_DATE_TIME_FIELD_FORMAT = 'YYYY MM DD HH mm ZZ';

export const MAX_VALID_UNIX_TIME_MILLIS : number = 8640000000000000;

// We use many date formats across the app. These are not exhaustive.
export const REPORT_DATETIME_FORMAT = 'MM/DD/YY hh:mma z';
export const INVENTORY_DATE_FORMAT = 'MMM. DD YYYY';
export const POPUP_DATETIME_PICKER_DATETIME_FORMAT = 'MMM. DD YYYY, h:mm a';
export const INVENTORY_DROPDOWN_DATE_FORMAT = 'ddd, MMM. DD YYYY';

export const SUPPORT_EMAIL = 'support@bevspot.com';
export const SUPPORT_PHONE = '(617)-658-3123';

// This should be kept in sync with supported_invoice_upload_file_extensions in
// handle_record_invoice_upload_post_request() in InvoiceUploadRequestHandler.py
export const SUPPORTED_INVOICE_UPLOAD_FILE_EXTENSIONS = ['3fr', 'arw', 'avif', 'bmp', 'cr2', 'cr3', 'crw', 'dcr', 'dng',
    'eps', 'erf', 'gif', 'heic', 'heif', 'icns', 'ico', 'jfif', 'jpeg', 'jpg', 'mos', 'mrw', 'nef', 'odd', 'odg', 'orf',
    'pdf', 'pef', 'png', 'ppm', 'ps', 'psd', 'raf', 'raw', 'rw2', 'tif', 'tiff', 'webp', 'x3f', 'xcf', 'xps'];
