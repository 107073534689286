import {
    IBreadcrumbCredentialData,
} from 'shared/components/BreadcrumbIntegrationModal/models';

import { IntegrationStatus } from 'shared/models/IntegrationStatus';

export const getBreadcrumbIntegrationStatus = (credentialData : IBreadcrumbCredentialData | null) : IntegrationStatus => {
    if (credentialData === null) {
        return IntegrationStatus.UNCONNECTED;
    }
    return credentialData.isValid ? IntegrationStatus.CONNECTED : IntegrationStatus.NEEDS_ATTENTION;
};
