//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


import Thrift from 'thrift';
import CoreTimeModel from './core_time_Model_types';
import LocationModel from './location_Model_types';
import ProductModel from './product_Model_types';
import SalesItemModel from './sales_item_Model_types';
import SalesItemExceptions from './sales_item_Exceptions_types';
import LocationExceptions from './location_Exceptions_types';
import UserAccountsModel from './accounts_user_Model_types';
import AccountsExceptions from './accounts_Exceptions_types';
import CoreApiExceptions from './core_api_Exceptions_types';
import MoneyModel from './money_Model_types';

var SalesItemService = {};
export default SalesItemService;
//HELPER FUNCTIONS AND STRUCTURES

SalesItemService.AdminSalesItemService_adminRemoveSalesItemsForLocation_args = function(args) {
  this.userSessionIdentifier = null;
  this.locationIdentifier = null;
  this.createdAfter = null;
  this.createdBefore = null;
  this.createdByRequesterOnly = null;
  this.applyDeletion = null;
  if (args) {
    if (args.userSessionIdentifier !== undefined && args.userSessionIdentifier !== null) {
      this.userSessionIdentifier = new UserAccountsModel.UserSessionIdentifier(args.userSessionIdentifier);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field userSessionIdentifier is unset!');
    }
    if (args.locationIdentifier !== undefined && args.locationIdentifier !== null) {
      this.locationIdentifier = new LocationModel.LocationIdentifier(args.locationIdentifier);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationIdentifier is unset!');
    }
    if (args.createdAfter !== undefined && args.createdAfter !== null) {
      this.createdAfter = new CoreTimeModel.TimestampWithMillisecondPrecision(args.createdAfter);
    }
    if (args.createdBefore !== undefined && args.createdBefore !== null) {
      this.createdBefore = new CoreTimeModel.TimestampWithMillisecondPrecision(args.createdBefore);
    }
    if (args.createdByRequesterOnly !== undefined && args.createdByRequesterOnly !== null) {
      this.createdByRequesterOnly = args.createdByRequesterOnly;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field createdByRequesterOnly is unset!');
    }
    if (args.applyDeletion !== undefined && args.applyDeletion !== null) {
      this.applyDeletion = args.applyDeletion;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field applyDeletion is unset!');
    }
  }
};
SalesItemService.AdminSalesItemService_adminRemoveSalesItemsForLocation_args.prototype = {};
SalesItemService.AdminSalesItemService_adminRemoveSalesItemsForLocation_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.userSessionIdentifier = new UserAccountsModel.UserSessionIdentifier();
        this.userSessionIdentifier.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationIdentifier = new LocationModel.LocationIdentifier();
        this.locationIdentifier.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.createdAfter = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.createdAfter.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.createdBefore = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.createdBefore.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.BOOL) {
        this.createdByRequesterOnly = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.BOOL) {
        this.applyDeletion = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SalesItemService.AdminSalesItemService_adminRemoveSalesItemsForLocation_args.prototype.write = function(output) {
  output.writeStructBegin('AdminSalesItemService_adminRemoveSalesItemsForLocation_args');
  if (this.userSessionIdentifier !== null && this.userSessionIdentifier !== undefined) {
    output.writeFieldBegin('userSessionIdentifier', Thrift.Type.STRUCT, 1);
    this.userSessionIdentifier.write(output);
    output.writeFieldEnd();
  }
  if (this.locationIdentifier !== null && this.locationIdentifier !== undefined) {
    output.writeFieldBegin('locationIdentifier', Thrift.Type.STRUCT, 2);
    this.locationIdentifier.write(output);
    output.writeFieldEnd();
  }
  if (this.createdAfter !== null && this.createdAfter !== undefined) {
    output.writeFieldBegin('createdAfter', Thrift.Type.STRUCT, 3);
    this.createdAfter.write(output);
    output.writeFieldEnd();
  }
  if (this.createdBefore !== null && this.createdBefore !== undefined) {
    output.writeFieldBegin('createdBefore', Thrift.Type.STRUCT, 4);
    this.createdBefore.write(output);
    output.writeFieldEnd();
  }
  if (this.createdByRequesterOnly !== null && this.createdByRequesterOnly !== undefined) {
    output.writeFieldBegin('createdByRequesterOnly', Thrift.Type.BOOL, 5);
    output.writeBool(this.createdByRequesterOnly);
    output.writeFieldEnd();
  }
  if (this.applyDeletion !== null && this.applyDeletion !== undefined) {
    output.writeFieldBegin('applyDeletion', Thrift.Type.BOOL, 6);
    output.writeBool(this.applyDeletion);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SalesItemService.AdminSalesItemService_adminRemoveSalesItemsForLocation_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new SalesItemModel.AdminRemoveSalesItemsResponse(args.success);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
  }
};
SalesItemService.AdminSalesItemService_adminRemoveSalesItemsForLocation_result.prototype = {};
SalesItemService.AdminSalesItemService_adminRemoveSalesItemsForLocation_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new SalesItemModel.AdminRemoveSalesItemsResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SalesItemService.AdminSalesItemService_adminRemoveSalesItemsForLocation_result.prototype.write = function(output) {
  output.writeStructBegin('AdminSalesItemService_adminRemoveSalesItemsForLocation_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SalesItemService.AdminSalesItemServiceClient = function(input, output) {
    this.input = input;
    this.output = (!output) ? input : output;
    this.seqid = 0;
};
SalesItemService.AdminSalesItemServiceClient.prototype = {};
SalesItemService.AdminSalesItemServiceClient.prototype.adminRemoveSalesItemsForLocation = function(userSessionIdentifier, locationIdentifier, createdAfter, createdBefore, createdByRequesterOnly, applyDeletion, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_adminRemoveSalesItemsForLocation(pendingError, userSessionIdentifier, locationIdentifier, createdAfter, createdBefore, createdByRequesterOnly, applyDeletion, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_adminRemoveSalesItemsForLocation();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

SalesItemService.AdminSalesItemServiceClient.prototype.send_adminRemoveSalesItemsForLocation = function(pendingError, userSessionIdentifier, locationIdentifier, createdAfter, createdBefore, createdByRequesterOnly, applyDeletion, callback) {
  this.output.writeMessageBegin('adminRemoveSalesItemsForLocation', Thrift.MessageType.CALL, this.seqid);
  var args = new SalesItemService.AdminSalesItemService_adminRemoveSalesItemsForLocation_args();
  args.userSessionIdentifier = userSessionIdentifier;
  args.locationIdentifier = locationIdentifier;
  args.createdAfter = createdAfter;
  args.createdBefore = createdBefore;
  args.createdByRequesterOnly = createdByRequesterOnly;
  args.applyDeletion = applyDeletion;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_adminRemoveSalesItemsForLocation() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

SalesItemService.AdminSalesItemServiceClient.prototype.recv_adminRemoveSalesItemsForLocation = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new SalesItemService.AdminSalesItemService_adminRemoveSalesItemsForLocation_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'adminRemoveSalesItemsForLocation failed: unknown result';
};
