import React from 'react';

import '../css/RadioButton.scss';
import { IInputLabel, LabelOrientation } from '../models/InputLabel';

export enum RadioButtonTheme {
    Default = 1,
    Muted = 2,
}

export interface IRadioButtonProps {
    isDisabled : boolean;
    isChecked : boolean;
    label : IInputLabel | null;
    onClick : (event : React.MouseEvent<HTMLElement>) => void;
    theme : RadioButtonTheme;
}

// modeled after CheckBox component
export class RadioButton extends React.Component<IRadioButtonProps, object> {
    private handleOnClick : (event : React.MouseEvent<HTMLElement>) => void;

    constructor(props : IRadioButtonProps) {
        super(props);

        this.handleOnClick = this.handleOnClickGenerator();
    }
    public render() {
        const {
            isDisabled,
            isChecked,
            label,
        } = this.props;

        let labelElement : JSX.Element | null = null;
        if (label !== null) {
            const labelOrientationClass = label.labelOrientation === LabelOrientation.LEFT ? 'left' : 'right';
            labelElement = (
                <p
                    className={ `label ${ labelOrientationClass }` }
                    onClick={ this.handleOnClick }
                >
                    { label.value }
                </p>
            );
        }

        const disabledClass = isDisabled ? 'disabled' : '';
        const radioButtonClass = isChecked ? 'bevico-radio-button-on' : 'bevico-radio-button-off'; // TODO @design change if necessary
        const themeClasses = this.generateThemeClasses();

        return (
            <span className={ themeClasses }>
                { labelElement }
                <span
                    className={ `bevicon ${ radioButtonClass } ${ disabledClass }` }
                    onClick={ this.handleOnClick }
                />
            </span>
        );
    }
    private handleOnClickGenerator() {
        return (event : React.MouseEvent<HTMLElement>) => {
            if (!this.props.isDisabled) {
                this.props.onClick(event);
            }
        };
    }
    private generateThemeClasses() {
        let themeClasses : string = 'radio-button';

        if (this.props.theme === RadioButtonTheme.Muted) {
            themeClasses += ' muted';
        }
        return themeClasses;
    }
}
