//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


import Thrift from 'thrift';
import CoreTimeModel from './core_time_Model_types';
import LocationModel from './location_Model_types';
import ProductModel from './product_Model_types';
import PrepEventModel from './prep_event_Model_types';
import PrepEventExceptions from './prep_event_Exceptions_types';
import LocationExceptions from './location_Exceptions_types';
import UserAccountsModel from './accounts_user_Model_types';
import AccountsExceptions from './accounts_Exceptions_types';
import CoreApiExceptions from './core_api_Exceptions_types';

var PrepEventService = {};
export default PrepEventService;
//HELPER FUNCTIONS AND STRUCTURES

PrepEventService.PrepEventService_getPrepEventsById_args = function(args) {
  this.actor = null;
  this.prepEventIdSet = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.prepEventIdSet !== undefined && args.prepEventIdSet !== null) {
      this.prepEventIdSet = Thrift.copyList(args.prepEventIdSet, [PrepEventModel.PrepEventId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field prepEventIdSet is unset!');
    }
  }
};
PrepEventService.PrepEventService_getPrepEventsById_args.prototype = {};
PrepEventService.PrepEventService_getPrepEventsById_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.prepEventIdSet = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new PrepEventModel.PrepEventId();
          elem6.read(input);
          this.prepEventIdSet.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PrepEventService.PrepEventService_getPrepEventsById_args.prototype.write = function(output) {
  output.writeStructBegin('PrepEventService_getPrepEventsById_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.prepEventIdSet !== null && this.prepEventIdSet !== undefined) {
    output.writeFieldBegin('prepEventIdSet', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.prepEventIdSet.length);
    for (var iter7 in this.prepEventIdSet)
    {
      if (this.prepEventIdSet.hasOwnProperty(iter7))
      {
        iter7 = this.prepEventIdSet[iter7];
        iter7.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PrepEventService.PrepEventService_getPrepEventsById_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownPrepEventIdException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof PrepEventExceptions.UnknownPrepEventIdException) {
    this.unknownPrepEventIdException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [PrepEventModel.PrepEventAndId]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownPrepEventIdException !== undefined && args.unknownPrepEventIdException !== null) {
      this.unknownPrepEventIdException = args.unknownPrepEventIdException;
    }
  }
};
PrepEventService.PrepEventService_getPrepEventsById_result.prototype = {};
PrepEventService.PrepEventService_getPrepEventsById_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size8 = 0;
        var _rtmp312;
        this.success = [];
        var _etype11 = 0;
        _rtmp312 = input.readListBegin();
        _etype11 = _rtmp312.etype;
        _size8 = _rtmp312.size;
        for (var _i13 = 0; _i13 < _size8; ++_i13)
        {
          var elem14 = null;
          elem14 = new PrepEventModel.PrepEventAndId();
          elem14.read(input);
          this.success.push(elem14);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownPrepEventIdException = new PrepEventExceptions.UnknownPrepEventIdException();
        this.unknownPrepEventIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PrepEventService.PrepEventService_getPrepEventsById_result.prototype.write = function(output) {
  output.writeStructBegin('PrepEventService_getPrepEventsById_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter15 in this.success)
    {
      if (this.success.hasOwnProperty(iter15))
      {
        iter15 = this.success[iter15];
        iter15.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownPrepEventIdException !== null && this.unknownPrepEventIdException !== undefined) {
    output.writeFieldBegin('unknownPrepEventIdException', Thrift.Type.STRUCT, 5);
    this.unknownPrepEventIdException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PrepEventService.PrepEventService_getPrepEventIdsForLocationInPeriod_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.periodStartInclusive = null;
  this.periodEndExclusive = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.periodStartInclusive !== undefined && args.periodStartInclusive !== null) {
      this.periodStartInclusive = new CoreTimeModel.TimestampWithMillisecondPrecision(args.periodStartInclusive);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field periodStartInclusive is unset!');
    }
    if (args.periodEndExclusive !== undefined && args.periodEndExclusive !== null) {
      this.periodEndExclusive = new CoreTimeModel.TimestampWithMillisecondPrecision(args.periodEndExclusive);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field periodEndExclusive is unset!');
    }
  }
};
PrepEventService.PrepEventService_getPrepEventIdsForLocationInPeriod_args.prototype = {};
PrepEventService.PrepEventService_getPrepEventIdsForLocationInPeriod_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.periodStartInclusive = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.periodStartInclusive.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.periodEndExclusive = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.periodEndExclusive.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PrepEventService.PrepEventService_getPrepEventIdsForLocationInPeriod_args.prototype.write = function(output) {
  output.writeStructBegin('PrepEventService_getPrepEventIdsForLocationInPeriod_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.periodStartInclusive !== null && this.periodStartInclusive !== undefined) {
    output.writeFieldBegin('periodStartInclusive', Thrift.Type.STRUCT, 3);
    this.periodStartInclusive.write(output);
    output.writeFieldEnd();
  }
  if (this.periodEndExclusive !== null && this.periodEndExclusive !== undefined) {
    output.writeFieldBegin('periodEndExclusive', Thrift.Type.STRUCT, 4);
    this.periodEndExclusive.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PrepEventService.PrepEventService_getPrepEventIdsForLocationInPeriod_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [PrepEventModel.PrepEventId]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
  }
};
PrepEventService.PrepEventService_getPrepEventIdsForLocationInPeriod_result.prototype = {};
PrepEventService.PrepEventService_getPrepEventIdsForLocationInPeriod_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size16 = 0;
        var _rtmp320;
        this.success = [];
        var _etype19 = 0;
        _rtmp320 = input.readListBegin();
        _etype19 = _rtmp320.etype;
        _size16 = _rtmp320.size;
        for (var _i21 = 0; _i21 < _size16; ++_i21)
        {
          var elem22 = null;
          elem22 = new PrepEventModel.PrepEventId();
          elem22.read(input);
          this.success.push(elem22);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PrepEventService.PrepEventService_getPrepEventIdsForLocationInPeriod_result.prototype.write = function(output) {
  output.writeStructBegin('PrepEventService_getPrepEventIdsForLocationInPeriod_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter23 in this.success)
    {
      if (this.success.hasOwnProperty(iter23))
      {
        iter23 = this.success[iter23];
        iter23.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PrepEventService.PrepEventService_getPrepEventOutputProductIdsForLocation_args = function(args) {
  this.actor = null;
  this.locationId = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
  }
};
PrepEventService.PrepEventService_getPrepEventOutputProductIdsForLocation_args.prototype = {};
PrepEventService.PrepEventService_getPrepEventOutputProductIdsForLocation_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PrepEventService.PrepEventService_getPrepEventOutputProductIdsForLocation_args.prototype.write = function(output) {
  output.writeStructBegin('PrepEventService_getPrepEventOutputProductIdsForLocation_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PrepEventService.PrepEventService_getPrepEventOutputProductIdsForLocation_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [ProductModel.ProductId]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
  }
};
PrepEventService.PrepEventService_getPrepEventOutputProductIdsForLocation_result.prototype = {};
PrepEventService.PrepEventService_getPrepEventOutputProductIdsForLocation_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size24 = 0;
        var _rtmp328;
        this.success = [];
        var _etype27 = 0;
        _rtmp328 = input.readListBegin();
        _etype27 = _rtmp328.etype;
        _size24 = _rtmp328.size;
        for (var _i29 = 0; _i29 < _size24; ++_i29)
        {
          var elem30 = null;
          elem30 = new ProductModel.ProductId();
          elem30.read(input);
          this.success.push(elem30);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PrepEventService.PrepEventService_getPrepEventOutputProductIdsForLocation_result.prototype.write = function(output) {
  output.writeStructBegin('PrepEventService_getPrepEventOutputProductIdsForLocation_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter31 in this.success)
    {
      if (this.success.hasOwnProperty(iter31))
      {
        iter31 = this.success[iter31];
        iter31.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PrepEventService.PrepEventService_createPrepEvent_args = function(args) {
  this.prepEventTime = null;
  this.locationId = null;
  this.actor = null;
  this.note = null;
  this.type = null;
  this.inputQuantityOfProductByProductId = null;
  this.outputQuantityOfProductByProductId = null;
  this.outputPriceByProductId = null;
  if (args) {
    if (args.prepEventTime !== undefined && args.prepEventTime !== null) {
      this.prepEventTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.prepEventTime);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field prepEventTime is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.note !== undefined && args.note !== null) {
      this.note = args.note;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field note is unset!');
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field type is unset!');
    }
    if (args.inputQuantityOfProductByProductId !== undefined && args.inputQuantityOfProductByProductId !== null) {
      this.inputQuantityOfProductByProductId = Thrift.copyList(args.inputQuantityOfProductByProductId, [ProductModel.ProductIdAndQuantityOfProduct]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inputQuantityOfProductByProductId is unset!');
    }
    if (args.outputQuantityOfProductByProductId !== undefined && args.outputQuantityOfProductByProductId !== null) {
      this.outputQuantityOfProductByProductId = Thrift.copyList(args.outputQuantityOfProductByProductId, [ProductModel.ProductIdAndQuantityOfProduct]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field outputQuantityOfProductByProductId is unset!');
    }
    if (args.outputPriceByProductId !== undefined && args.outputPriceByProductId !== null) {
      this.outputPriceByProductId = Thrift.copyList(args.outputPriceByProductId, [ProductModel.ProductIdAndCost]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field outputPriceByProductId is unset!');
    }
  }
};
PrepEventService.PrepEventService_createPrepEvent_args.prototype = {};
PrepEventService.PrepEventService_createPrepEvent_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.prepEventTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.prepEventTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.note = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.type = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.LIST) {
        var _size32 = 0;
        var _rtmp336;
        this.inputQuantityOfProductByProductId = [];
        var _etype35 = 0;
        _rtmp336 = input.readListBegin();
        _etype35 = _rtmp336.etype;
        _size32 = _rtmp336.size;
        for (var _i37 = 0; _i37 < _size32; ++_i37)
        {
          var elem38 = null;
          elem38 = new ProductModel.ProductIdAndQuantityOfProduct();
          elem38.read(input);
          this.inputQuantityOfProductByProductId.push(elem38);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.LIST) {
        var _size39 = 0;
        var _rtmp343;
        this.outputQuantityOfProductByProductId = [];
        var _etype42 = 0;
        _rtmp343 = input.readListBegin();
        _etype42 = _rtmp343.etype;
        _size39 = _rtmp343.size;
        for (var _i44 = 0; _i44 < _size39; ++_i44)
        {
          var elem45 = null;
          elem45 = new ProductModel.ProductIdAndQuantityOfProduct();
          elem45.read(input);
          this.outputQuantityOfProductByProductId.push(elem45);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.LIST) {
        var _size46 = 0;
        var _rtmp350;
        this.outputPriceByProductId = [];
        var _etype49 = 0;
        _rtmp350 = input.readListBegin();
        _etype49 = _rtmp350.etype;
        _size46 = _rtmp350.size;
        for (var _i51 = 0; _i51 < _size46; ++_i51)
        {
          var elem52 = null;
          elem52 = new ProductModel.ProductIdAndCost();
          elem52.read(input);
          this.outputPriceByProductId.push(elem52);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PrepEventService.PrepEventService_createPrepEvent_args.prototype.write = function(output) {
  output.writeStructBegin('PrepEventService_createPrepEvent_args');
  if (this.prepEventTime !== null && this.prepEventTime !== undefined) {
    output.writeFieldBegin('prepEventTime', Thrift.Type.STRUCT, 1);
    this.prepEventTime.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 3);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.note !== null && this.note !== undefined) {
    output.writeFieldBegin('note', Thrift.Type.STRING, 4);
    output.writeString(this.note);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.STRING, 5);
    output.writeString(this.type);
    output.writeFieldEnd();
  }
  if (this.inputQuantityOfProductByProductId !== null && this.inputQuantityOfProductByProductId !== undefined) {
    output.writeFieldBegin('inputQuantityOfProductByProductId', Thrift.Type.LIST, 6);
    output.writeListBegin(Thrift.Type.STRUCT, this.inputQuantityOfProductByProductId.length);
    for (var iter53 in this.inputQuantityOfProductByProductId)
    {
      if (this.inputQuantityOfProductByProductId.hasOwnProperty(iter53))
      {
        iter53 = this.inputQuantityOfProductByProductId[iter53];
        iter53.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.outputQuantityOfProductByProductId !== null && this.outputQuantityOfProductByProductId !== undefined) {
    output.writeFieldBegin('outputQuantityOfProductByProductId', Thrift.Type.LIST, 7);
    output.writeListBegin(Thrift.Type.STRUCT, this.outputQuantityOfProductByProductId.length);
    for (var iter54 in this.outputQuantityOfProductByProductId)
    {
      if (this.outputQuantityOfProductByProductId.hasOwnProperty(iter54))
      {
        iter54 = this.outputQuantityOfProductByProductId[iter54];
        iter54.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.outputPriceByProductId !== null && this.outputPriceByProductId !== undefined) {
    output.writeFieldBegin('outputPriceByProductId', Thrift.Type.LIST, 8);
    output.writeListBegin(Thrift.Type.STRUCT, this.outputPriceByProductId.length);
    for (var iter55 in this.outputPriceByProductId)
    {
      if (this.outputPriceByProductId.hasOwnProperty(iter55))
      {
        iter55 = this.outputPriceByProductId[iter55];
        iter55.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PrepEventService.PrepEventService_createPrepEvent_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new PrepEventModel.PrepEventId(args.success);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
  }
};
PrepEventService.PrepEventService_createPrepEvent_result.prototype = {};
PrepEventService.PrepEventService_createPrepEvent_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new PrepEventModel.PrepEventId();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PrepEventService.PrepEventService_createPrepEvent_result.prototype.write = function(output) {
  output.writeStructBegin('PrepEventService_createPrepEvent_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PrepEventService.PrepEventService_deletePrepEvent_args = function(args) {
  this.actor = null;
  this.prepEventId = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.prepEventId !== undefined && args.prepEventId !== null) {
      this.prepEventId = new PrepEventModel.PrepEventId(args.prepEventId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field prepEventId is unset!');
    }
  }
};
PrepEventService.PrepEventService_deletePrepEvent_args.prototype = {};
PrepEventService.PrepEventService_deletePrepEvent_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.prepEventId = new PrepEventModel.PrepEventId();
        this.prepEventId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PrepEventService.PrepEventService_deletePrepEvent_args.prototype.write = function(output) {
  output.writeStructBegin('PrepEventService_deletePrepEvent_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.prepEventId !== null && this.prepEventId !== undefined) {
    output.writeFieldBegin('prepEventId', Thrift.Type.STRUCT, 2);
    this.prepEventId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PrepEventService.PrepEventService_deletePrepEvent_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownPrepEventIdException = null;
  this.prepEventIsDeletedException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof PrepEventExceptions.UnknownPrepEventIdException) {
    this.unknownPrepEventIdException = args;
    return;
  }
  if (args instanceof PrepEventExceptions.PrepEventIsDeletedException) {
    this.prepEventIsDeletedException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownPrepEventIdException !== undefined && args.unknownPrepEventIdException !== null) {
      this.unknownPrepEventIdException = args.unknownPrepEventIdException;
    }
    if (args.prepEventIsDeletedException !== undefined && args.prepEventIsDeletedException !== null) {
      this.prepEventIsDeletedException = args.prepEventIsDeletedException;
    }
  }
};
PrepEventService.PrepEventService_deletePrepEvent_result.prototype = {};
PrepEventService.PrepEventService_deletePrepEvent_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownPrepEventIdException = new PrepEventExceptions.UnknownPrepEventIdException();
        this.unknownPrepEventIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.prepEventIsDeletedException = new PrepEventExceptions.PrepEventIsDeletedException();
        this.prepEventIsDeletedException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PrepEventService.PrepEventService_deletePrepEvent_result.prototype.write = function(output) {
  output.writeStructBegin('PrepEventService_deletePrepEvent_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownPrepEventIdException !== null && this.unknownPrepEventIdException !== undefined) {
    output.writeFieldBegin('unknownPrepEventIdException', Thrift.Type.STRUCT, 5);
    this.unknownPrepEventIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.prepEventIsDeletedException !== null && this.prepEventIsDeletedException !== undefined) {
    output.writeFieldBegin('prepEventIsDeletedException', Thrift.Type.STRUCT, 6);
    this.prepEventIsDeletedException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PrepEventService.PrepEventService_updatePrepEvent_args = function(args) {
  this.actor = null;
  this.prepEventId = null;
  this.prepEventTime = null;
  this.note = null;
  this.type = null;
  this.inputQuantityOfProductByProductId = null;
  this.outputQuantityOfProductByProductId = null;
  this.outputPriceByProductId = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.prepEventId !== undefined && args.prepEventId !== null) {
      this.prepEventId = new PrepEventModel.PrepEventId(args.prepEventId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field prepEventId is unset!');
    }
    if (args.prepEventTime !== undefined && args.prepEventTime !== null) {
      this.prepEventTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.prepEventTime);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field prepEventTime is unset!');
    }
    if (args.note !== undefined && args.note !== null) {
      this.note = args.note;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field note is unset!');
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field type is unset!');
    }
    if (args.inputQuantityOfProductByProductId !== undefined && args.inputQuantityOfProductByProductId !== null) {
      this.inputQuantityOfProductByProductId = Thrift.copyList(args.inputQuantityOfProductByProductId, [ProductModel.ProductIdAndQuantityOfProduct]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inputQuantityOfProductByProductId is unset!');
    }
    if (args.outputQuantityOfProductByProductId !== undefined && args.outputQuantityOfProductByProductId !== null) {
      this.outputQuantityOfProductByProductId = Thrift.copyList(args.outputQuantityOfProductByProductId, [ProductModel.ProductIdAndQuantityOfProduct]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field outputQuantityOfProductByProductId is unset!');
    }
    if (args.outputPriceByProductId !== undefined && args.outputPriceByProductId !== null) {
      this.outputPriceByProductId = Thrift.copyList(args.outputPriceByProductId, [ProductModel.ProductIdAndCost]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field outputPriceByProductId is unset!');
    }
  }
};
PrepEventService.PrepEventService_updatePrepEvent_args.prototype = {};
PrepEventService.PrepEventService_updatePrepEvent_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.prepEventId = new PrepEventModel.PrepEventId();
        this.prepEventId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.prepEventTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.prepEventTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.note = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.type = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.LIST) {
        var _size56 = 0;
        var _rtmp360;
        this.inputQuantityOfProductByProductId = [];
        var _etype59 = 0;
        _rtmp360 = input.readListBegin();
        _etype59 = _rtmp360.etype;
        _size56 = _rtmp360.size;
        for (var _i61 = 0; _i61 < _size56; ++_i61)
        {
          var elem62 = null;
          elem62 = new ProductModel.ProductIdAndQuantityOfProduct();
          elem62.read(input);
          this.inputQuantityOfProductByProductId.push(elem62);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.LIST) {
        var _size63 = 0;
        var _rtmp367;
        this.outputQuantityOfProductByProductId = [];
        var _etype66 = 0;
        _rtmp367 = input.readListBegin();
        _etype66 = _rtmp367.etype;
        _size63 = _rtmp367.size;
        for (var _i68 = 0; _i68 < _size63; ++_i68)
        {
          var elem69 = null;
          elem69 = new ProductModel.ProductIdAndQuantityOfProduct();
          elem69.read(input);
          this.outputQuantityOfProductByProductId.push(elem69);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.LIST) {
        var _size70 = 0;
        var _rtmp374;
        this.outputPriceByProductId = [];
        var _etype73 = 0;
        _rtmp374 = input.readListBegin();
        _etype73 = _rtmp374.etype;
        _size70 = _rtmp374.size;
        for (var _i75 = 0; _i75 < _size70; ++_i75)
        {
          var elem76 = null;
          elem76 = new ProductModel.ProductIdAndCost();
          elem76.read(input);
          this.outputPriceByProductId.push(elem76);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PrepEventService.PrepEventService_updatePrepEvent_args.prototype.write = function(output) {
  output.writeStructBegin('PrepEventService_updatePrepEvent_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.prepEventId !== null && this.prepEventId !== undefined) {
    output.writeFieldBegin('prepEventId', Thrift.Type.STRUCT, 2);
    this.prepEventId.write(output);
    output.writeFieldEnd();
  }
  if (this.prepEventTime !== null && this.prepEventTime !== undefined) {
    output.writeFieldBegin('prepEventTime', Thrift.Type.STRUCT, 3);
    this.prepEventTime.write(output);
    output.writeFieldEnd();
  }
  if (this.note !== null && this.note !== undefined) {
    output.writeFieldBegin('note', Thrift.Type.STRING, 4);
    output.writeString(this.note);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.STRING, 5);
    output.writeString(this.type);
    output.writeFieldEnd();
  }
  if (this.inputQuantityOfProductByProductId !== null && this.inputQuantityOfProductByProductId !== undefined) {
    output.writeFieldBegin('inputQuantityOfProductByProductId', Thrift.Type.LIST, 6);
    output.writeListBegin(Thrift.Type.STRUCT, this.inputQuantityOfProductByProductId.length);
    for (var iter77 in this.inputQuantityOfProductByProductId)
    {
      if (this.inputQuantityOfProductByProductId.hasOwnProperty(iter77))
      {
        iter77 = this.inputQuantityOfProductByProductId[iter77];
        iter77.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.outputQuantityOfProductByProductId !== null && this.outputQuantityOfProductByProductId !== undefined) {
    output.writeFieldBegin('outputQuantityOfProductByProductId', Thrift.Type.LIST, 7);
    output.writeListBegin(Thrift.Type.STRUCT, this.outputQuantityOfProductByProductId.length);
    for (var iter78 in this.outputQuantityOfProductByProductId)
    {
      if (this.outputQuantityOfProductByProductId.hasOwnProperty(iter78))
      {
        iter78 = this.outputQuantityOfProductByProductId[iter78];
        iter78.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.outputPriceByProductId !== null && this.outputPriceByProductId !== undefined) {
    output.writeFieldBegin('outputPriceByProductId', Thrift.Type.LIST, 8);
    output.writeListBegin(Thrift.Type.STRUCT, this.outputPriceByProductId.length);
    for (var iter79 in this.outputPriceByProductId)
    {
      if (this.outputPriceByProductId.hasOwnProperty(iter79))
      {
        iter79 = this.outputPriceByProductId[iter79];
        iter79.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PrepEventService.PrepEventService_updatePrepEvent_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownPrepEventIdException = null;
  this.prepEventIsDeletedException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof PrepEventExceptions.UnknownPrepEventIdException) {
    this.unknownPrepEventIdException = args;
    return;
  }
  if (args instanceof PrepEventExceptions.PrepEventIsDeletedException) {
    this.prepEventIsDeletedException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownPrepEventIdException !== undefined && args.unknownPrepEventIdException !== null) {
      this.unknownPrepEventIdException = args.unknownPrepEventIdException;
    }
    if (args.prepEventIsDeletedException !== undefined && args.prepEventIsDeletedException !== null) {
      this.prepEventIsDeletedException = args.prepEventIsDeletedException;
    }
  }
};
PrepEventService.PrepEventService_updatePrepEvent_result.prototype = {};
PrepEventService.PrepEventService_updatePrepEvent_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownPrepEventIdException = new PrepEventExceptions.UnknownPrepEventIdException();
        this.unknownPrepEventIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.prepEventIsDeletedException = new PrepEventExceptions.PrepEventIsDeletedException();
        this.prepEventIsDeletedException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PrepEventService.PrepEventService_updatePrepEvent_result.prototype.write = function(output) {
  output.writeStructBegin('PrepEventService_updatePrepEvent_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownPrepEventIdException !== null && this.unknownPrepEventIdException !== undefined) {
    output.writeFieldBegin('unknownPrepEventIdException', Thrift.Type.STRUCT, 5);
    this.unknownPrepEventIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.prepEventIsDeletedException !== null && this.prepEventIsDeletedException !== undefined) {
    output.writeFieldBegin('prepEventIsDeletedException', Thrift.Type.STRUCT, 6);
    this.prepEventIsDeletedException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PrepEventService.PrepEventServiceClient = function(input, output) {
    this.input = input;
    this.output = (!output) ? input : output;
    this.seqid = 0;
};
PrepEventService.PrepEventServiceClient.prototype = {};
PrepEventService.PrepEventServiceClient.prototype.getPrepEventsById = function(actor, prepEventIdSet, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getPrepEventsById(pendingError, actor, prepEventIdSet, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getPrepEventsById();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

PrepEventService.PrepEventServiceClient.prototype.send_getPrepEventsById = function(pendingError, actor, prepEventIdSet, callback) {
  this.output.writeMessageBegin('getPrepEventsById', Thrift.MessageType.CALL, this.seqid);
  var args = new PrepEventService.PrepEventService_getPrepEventsById_args();
  args.actor = actor;
  args.prepEventIdSet = prepEventIdSet;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getPrepEventsById() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

PrepEventService.PrepEventServiceClient.prototype.recv_getPrepEventsById = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new PrepEventService.PrepEventService_getPrepEventsById_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownPrepEventIdException) {
    throw result.unknownPrepEventIdException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getPrepEventsById failed: unknown result';
};
PrepEventService.PrepEventServiceClient.prototype.getPrepEventIdsForLocationInPeriod = function(actor, locationId, periodStartInclusive, periodEndExclusive, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getPrepEventIdsForLocationInPeriod(pendingError, actor, locationId, periodStartInclusive, periodEndExclusive, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getPrepEventIdsForLocationInPeriod();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

PrepEventService.PrepEventServiceClient.prototype.send_getPrepEventIdsForLocationInPeriod = function(pendingError, actor, locationId, periodStartInclusive, periodEndExclusive, callback) {
  this.output.writeMessageBegin('getPrepEventIdsForLocationInPeriod', Thrift.MessageType.CALL, this.seqid);
  var args = new PrepEventService.PrepEventService_getPrepEventIdsForLocationInPeriod_args();
  args.actor = actor;
  args.locationId = locationId;
  args.periodStartInclusive = periodStartInclusive;
  args.periodEndExclusive = periodEndExclusive;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getPrepEventIdsForLocationInPeriod() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

PrepEventService.PrepEventServiceClient.prototype.recv_getPrepEventIdsForLocationInPeriod = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new PrepEventService.PrepEventService_getPrepEventIdsForLocationInPeriod_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getPrepEventIdsForLocationInPeriod failed: unknown result';
};
PrepEventService.PrepEventServiceClient.prototype.getPrepEventOutputProductIdsForLocation = function(actor, locationId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getPrepEventOutputProductIdsForLocation(pendingError, actor, locationId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getPrepEventOutputProductIdsForLocation();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

PrepEventService.PrepEventServiceClient.prototype.send_getPrepEventOutputProductIdsForLocation = function(pendingError, actor, locationId, callback) {
  this.output.writeMessageBegin('getPrepEventOutputProductIdsForLocation', Thrift.MessageType.CALL, this.seqid);
  var args = new PrepEventService.PrepEventService_getPrepEventOutputProductIdsForLocation_args();
  args.actor = actor;
  args.locationId = locationId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getPrepEventOutputProductIdsForLocation() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

PrepEventService.PrepEventServiceClient.prototype.recv_getPrepEventOutputProductIdsForLocation = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new PrepEventService.PrepEventService_getPrepEventOutputProductIdsForLocation_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getPrepEventOutputProductIdsForLocation failed: unknown result';
};
PrepEventService.PrepEventServiceClient.prototype.createPrepEvent = function(prepEventTime, locationId, actor, note, type, inputQuantityOfProductByProductId, outputQuantityOfProductByProductId, outputPriceByProductId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_createPrepEvent(pendingError, prepEventTime, locationId, actor, note, type, inputQuantityOfProductByProductId, outputQuantityOfProductByProductId, outputPriceByProductId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_createPrepEvent();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

PrepEventService.PrepEventServiceClient.prototype.send_createPrepEvent = function(pendingError, prepEventTime, locationId, actor, note, type, inputQuantityOfProductByProductId, outputQuantityOfProductByProductId, outputPriceByProductId, callback) {
  this.output.writeMessageBegin('createPrepEvent', Thrift.MessageType.CALL, this.seqid);
  var args = new PrepEventService.PrepEventService_createPrepEvent_args();
  args.prepEventTime = prepEventTime;
  args.locationId = locationId;
  args.actor = actor;
  args.note = note;
  args.type = type;
  args.inputQuantityOfProductByProductId = inputQuantityOfProductByProductId;
  args.outputQuantityOfProductByProductId = outputQuantityOfProductByProductId;
  args.outputPriceByProductId = outputPriceByProductId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_createPrepEvent() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

PrepEventService.PrepEventServiceClient.prototype.recv_createPrepEvent = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new PrepEventService.PrepEventService_createPrepEvent_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'createPrepEvent failed: unknown result';
};
PrepEventService.PrepEventServiceClient.prototype.deletePrepEvent = function(actor, prepEventId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_deletePrepEvent(pendingError, actor, prepEventId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_deletePrepEvent();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

PrepEventService.PrepEventServiceClient.prototype.send_deletePrepEvent = function(pendingError, actor, prepEventId, callback) {
  this.output.writeMessageBegin('deletePrepEvent', Thrift.MessageType.CALL, this.seqid);
  var args = new PrepEventService.PrepEventService_deletePrepEvent_args();
  args.actor = actor;
  args.prepEventId = prepEventId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_deletePrepEvent() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

PrepEventService.PrepEventServiceClient.prototype.recv_deletePrepEvent = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new PrepEventService.PrepEventService_deletePrepEvent_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownPrepEventIdException) {
    throw result.unknownPrepEventIdException;
  }
  if (null !== result.prepEventIsDeletedException) {
    throw result.prepEventIsDeletedException;
  }
  return;
};
PrepEventService.PrepEventServiceClient.prototype.updatePrepEvent = function(actor, prepEventId, prepEventTime, note, type, inputQuantityOfProductByProductId, outputQuantityOfProductByProductId, outputPriceByProductId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_updatePrepEvent(pendingError, actor, prepEventId, prepEventTime, note, type, inputQuantityOfProductByProductId, outputQuantityOfProductByProductId, outputPriceByProductId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_updatePrepEvent();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

PrepEventService.PrepEventServiceClient.prototype.send_updatePrepEvent = function(pendingError, actor, prepEventId, prepEventTime, note, type, inputQuantityOfProductByProductId, outputQuantityOfProductByProductId, outputPriceByProductId, callback) {
  this.output.writeMessageBegin('updatePrepEvent', Thrift.MessageType.CALL, this.seqid);
  var args = new PrepEventService.PrepEventService_updatePrepEvent_args();
  args.actor = actor;
  args.prepEventId = prepEventId;
  args.prepEventTime = prepEventTime;
  args.note = note;
  args.type = type;
  args.inputQuantityOfProductByProductId = inputQuantityOfProductByProductId;
  args.outputQuantityOfProductByProductId = outputQuantityOfProductByProductId;
  args.outputPriceByProductId = outputPriceByProductId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_updatePrepEvent() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

PrepEventService.PrepEventServiceClient.prototype.recv_updatePrepEvent = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new PrepEventService.PrepEventService_updatePrepEvent_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownPrepEventIdException) {
    throw result.unknownPrepEventIdException;
  }
  if (null !== result.prepEventIsDeletedException) {
    throw result.prepEventIsDeletedException;
  }
  return;
};
