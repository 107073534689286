import { IUserAccountIdAndTimestampJSONObject } from 'api/UserAccount/model/UserAccountIdAndTimestamp';
import { UserAccountUtils } from 'api/UserAccount/utils/UserAccountUtils';

import { LocationId } from 'api/Location/model/LocationId';
import { Delivery } from 'api/Ordering/model/Delivery';
import { IDeliveryJSONObject, ITaxJSON } from 'api/Ordering/serializer/IDeliveryJSONObject';
import { ProductObjectToJSONSerializer } from 'api/Product/serializer/ProductObjectToJSONSerializer';

import { DJANGO_API_UTC_TIMESTAMP_FORMAT, NULL_DISTRIBUTOR_ID } from 'shared/constants';

export class OrderingObjectToJSONSerializer {

    constructor(
        private readonly productObjectToJSONSerializer : ProductObjectToJSONSerializer,
    ) {}

    public getJSONDeliveryFromDelivery(
        locationId : LocationId,
        delivery : Delivery,
        deliveryHash : string,
    ) : IDeliveryJSONObject {
        const distributorId = delivery.getDistributorId();

        const deliveryConfirmationUserId = delivery.getDeliveryConfirmationUserId();
        const deliveryConfirmationEventTime = delivery.getDeliveryConfirmationEventTimeUTC();
        const deliveryTime = delivery.getDateDeliveredUTC();

        let deliveryReport = null;
        let predictedDeliveryTime = null;
        if (deliveryConfirmationUserId && deliveryConfirmationEventTime) {
            deliveryReport = {
                time_delivered: deliveryTime.utc().format(DJANGO_API_UTC_TIMESTAMP_FORMAT),
                time_reported: deliveryConfirmationEventTime.utc().format(DJANGO_API_UTC_TIMESTAMP_FORMAT),
                reporter_user_id: deliveryConfirmationUserId.getValue(),
            };
        } else {
            predictedDeliveryTime = deliveryTime.utc().format(DJANGO_API_UTC_TIMESTAMP_FORMAT);
        }

        const lineItems = delivery.getDeliveryLineItems().map((lineItem) => {
            const invoiceUploadId = lineItem.getInvoiceUploadId();

            return {
                product_id: lineItem.getProductId().getValue(),
                product_quantity_unit: this.productObjectToJSONSerializer.getProductQuantityUnit(lineItem.getProductQuantityUnit()),
                unit_quantity: lineItem.getQuantityInUnits(),
                unit_price: {
                    value: lineItem.getPricePerUnit(),
                    iso_4217_currency_code: 'USD',
                },
                unit_deposit: {
                    value: lineItem.getDepositPerUnit(),
                    iso_4217_currency_code: 'USD',
                },
                discount: {
                    value: lineItem.getDiscount(),
                    iso_4217_currency_code: 'USD',
                },
                tax: {
                    value: lineItem.getTax(),
                    iso_4217_currency_code: 'USD',
                },
                other_adjustments: {
                    value: lineItem.getOtherAdjustments(),
                    iso_4217_currency_code: 'USD',
                },
                invoice_upload_id: invoiceUploadId ? invoiceUploadId.getValue() : null,
                invoice_upload_line_item_id: lineItem.getInvoiceUploadLineItemId(),
            };
        });

        const priceAdjustmentJSONByProductCategoryIdValue = {};
        delivery.getPriceAdjustmentsByProductCategoryId().forEach((priceAdjustments, productCategoryId) => {
            (priceAdjustmentJSONByProductCategoryIdValue as any)[productCategoryId] = priceAdjustments.map((priceAdjustment) => {
                const adjustmentId = priceAdjustment.getStandardAdjustmentId();
                return {
                    price: {
                        value: priceAdjustment.getAmountInDollars(),
                        iso_4217_currency_code: 'USD',
                    },
                    note: priceAdjustment.getNote(),
                    standard_adjustment_id: adjustmentId === null ? null : adjustmentId.getValue(),
                    gl_code: priceAdjustment.getGLCode()
                };
            });
        });

        const uncategorizedPriceAdjustments = delivery.getUncategorizedPriceAdjustments().map((priceAdjustment) => {
            const adjustmentId = priceAdjustment.getStandardAdjustmentId();
            return {
                price: {
                    value: priceAdjustment.getAmountInDollars(),
                    iso_4217_currency_code: 'USD',
                },
                note: priceAdjustment.getNote(),
                standard_adjustment_id: adjustmentId === null ? null : adjustmentId.getValue(),
                gl_code: priceAdjustment.getGLCode()
            };
        });

        const distributorReps = delivery.getDistributorReps().map((distributorRep) => {
            return {
                name: distributorRep.getName(),
                email_address: distributorRep.getEmailAddress(),
                phone_number: distributorRep.getPhoneNumber(),
                bevspot_sent_email_to_email_address: distributorRep.getEmailWasSentForOrder(),
                bevspot_sent_sms_to_phone_number: distributorRep.getSmsWasSentForOrder(),
                attach_csv: distributorRep.getCsvWasAttachedToOrder(),
            };
        });

        const taxes : Array<ITaxJSON> = [];
        delivery.getTaxesByName().forEach((value, name) => {
            taxes.push({
                name,
                price: {
                    value,
                    iso_4217_currency_code: 'USD',
                },
            });
        });

        const hiddenInvoiceUploadLineItemIdsByInvoiceUploadIdJSON : {[ invoiceUploadIdValue : string] : Array<string>} = {};
        delivery.getHiddenInvoiceUploadLineItemIdsByInvoiceUploadId().forEach((hiddenInvoiceUploadLineItemIds, invoiceUploadId) => {
            hiddenInvoiceUploadLineItemIdsByInvoiceUploadIdJSON[invoiceUploadId.getValue()] = Array.from(hiddenInvoiceUploadLineItemIds.values());
        });

        const deliveryApprovalEvent = delivery.getApprovalEvent();

        let approvalEvent : IUserAccountIdAndTimestampJSONObject | null;
        if (deliveryApprovalEvent === null) {
            approvalEvent = null;
        } else {
            approvalEvent = UserAccountUtils.getUserAccountIdAndTimestampJSONFromObject(deliveryApprovalEvent);
        }

        const deliveryReadyForApprovalEvent = delivery.getReadyForApprovalEvent();

        let readyForApprovalEvent : IUserAccountIdAndTimestampJSONObject | null;
        if (deliveryReadyForApprovalEvent === null) {
            readyForApprovalEvent = null;
        } else {
            readyForApprovalEvent = UserAccountUtils.getUserAccountIdAndTimestampJSONFromObject(deliveryReadyForApprovalEvent);
        }

        const deliveryLastUpdateEvent = delivery.getLastUpdateEvent();

        let lastUpdateEvent : IUserAccountIdAndTimestampJSONObject | null;
        if (deliveryLastUpdateEvent === null) {
            lastUpdateEvent = null;
        } else {
            lastUpdateEvent = UserAccountUtils.getUserAccountIdAndTimestampJSONFromObject(deliveryLastUpdateEvent);
        }

        return {
            distributor_id: distributorId ? distributorId.getValue() : NULL_DISTRIBUTOR_ID,
            retailer_id: locationId.getValue(),
            time_placed: delivery.getDatePlacedUTC().utc().format(DJANGO_API_UTC_TIMESTAMP_FORMAT),
            time_recorded: delivery.getDateRecordedUTC().utc().format(DJANGO_API_UTC_TIMESTAMP_FORMAT),
            predicted_delivery_time: predictedDeliveryTime,
            line_items: lineItems,
            uncategorized_price_adjustments: uncategorizedPriceAdjustments,
            price_adjustments_by_product_category_id: priceAdjustmentJSONByProductCategoryIdValue,
            taxes,
            note_to_self: delivery.getNoteToSelf(),
            note_to_rep: delivery.getNoteToRep(),
            purchase_order_number: delivery.getPurchaseOrderNumber(),
            distributor_reps: distributorReps,
            invoice_number: delivery.getInvoiceNumber(),
            recorder_name: delivery.getRecordedByName(),
            delivery_report: deliveryReport,
            user_defined_total: delivery.getUserDefinedTotal(),
            order_hash: deliveryHash,
            hidden_invoice_upload_line_item_ids_by_invoice_upload_id: hiddenInvoiceUploadLineItemIdsByInvoiceUploadIdJSON,
            approval_event: approvalEvent,
            ready_for_approval_event: readyForApprovalEvent,
            last_update_event: lastUpdateEvent,
        };
    }
}
