import { DistributorAdjustmentId } from 'api/Distributor/model/DistributorAdjustmentId';

export class PriceAdjustment {

    constructor (
        private readonly note : string,
        private readonly amountInDollars : number,
        private readonly standardAdjustmentId : DistributorAdjustmentId | null,
        private readonly glCode : string | null,
    ) {}

    public getAmountInDollars() : number {
        return this.amountInDollars;
    }

    public getNote() : string {
        return this.note;
    }

    public getStandardAdjustmentId() : DistributorAdjustmentId | null {
        return this.standardAdjustmentId;
    }

    public getGLCode() : string | null {
        return this.glCode;
    }
}
