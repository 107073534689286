import React from 'react';

import { StringValueMap } from 'api/Core/StringValueMap';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { BevSpotDateTimePickerType } from 'shared/components/BevSpotDateTimePicker/BevSpotDateTimePicker';
import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { PopupDateTimePicker } from 'shared/components/PopupDateTimePicker';
import { Select2DropdownMenu } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { DateTime } from 'shared/models/DateTime';
import { IFormValue } from 'shared/models/FormValue';
import { OrderedProductAmountList } from '../models/OrderedProductAmountList';
import { TransferListTable } from './TransferListTable';

interface ITransferListPanel {
    shouldUpdate : boolean;
    title : string;
    orderedProductAmountList : OrderedProductAmountList;
    productsById : StringValueMap<ProductId, Product>;
    note : string | null;
    typeInput : null | {
        sortedOptions : Array<IOption>;
        currentOption : IOption;
        onOptionSelect : (optionValue : string) => void;
        onCreateCustomOption : ((optionText : string) => void) | null;
    };
    dateTimePicker : {
        dateTimePickerShown : boolean;
        setDateTimePickerShown : (isShown : boolean) => void;
        initialDateTime : DateTime;
        setDateTime : (dateTime : DateTime) => void;
    };
    orderedProductAmountQuantityByListElementIdForm : { [listElementId : string] : IFormValue<string> };
    setQuantity(addedItemId : string, quantity : string) : void;
    validateQuantity(addedItemId : string, quantity : string) : void;
    removeRow(addedItemId : string, productId : ProductId) : void;
    setNote(note : string) : void;
}
export class TransferListPanel extends React.Component<ITransferListPanel, object> {
    constructor(props : ITransferListPanel) {
        super(props);

        this.handleNoteChange = this.handleNoteChange.bind(this);
    }

    public shouldComponentUpdate(nextProps : ITransferListPanel) {
        return nextProps.shouldUpdate;
    }

    public render() {
        const {
            title,
            productsById,
            orderedProductAmountList,
            orderedProductAmountQuantityByListElementIdForm,
            validateQuantity,
            note,
            dateTimePicker,
            typeInput,
            setQuantity,
            removeRow,
        } = this.props;

        return (
            <div className="col-intable transfer-list-panel search-result-list-table">
                <h4>{ title }</h4>
                <TransferListTable
                    productsById={ productsById }
                    orderedProductAmountList={ orderedProductAmountList }
                    orderedProductAmountQuantityByListElementIdForm={ orderedProductAmountQuantityByListElementIdForm }
                    validateQuantity={ validateQuantity }
                    setQuantity={ setQuantity }
                    removeRow={ removeRow }
                />
                <div className="note-row ctable-row">
                    <div className="col-row">
                        <div className="cell col-xs-2 cell-ptb-md"><span>Date</span></div>
                        <div className="cell col-xs-10 cell-ptb-md">
                            <PopupDateTimePicker
                                preferredPositionArray={ ['left', 'above'] }
                                dateTimePickerIsShown={ dateTimePicker.dateTimePickerShown }
                                setDateTimePickerIsShown={ dateTimePicker.setDateTimePickerShown }
                                dateTimePickerProps={ {
                                    buttons: [],
                                    initialDateTime: dateTimePicker.initialDateTime,
                                    setDateTime: dateTimePicker.setDateTime,
                                    type: BevSpotDateTimePickerType.POPUP,
                                    uniqueId: 'create-transfer-report',
                                    shouldShowTime: true,
                                } }
                            />
                        </div>
                    </div>
                    { typeInput &&
                        <div className="col-row">
                            <div className="cell col-xs-2 cell-ptb-md">
                                <span>Type</span>
                            </div>
                            <div className="cell col-xs-10 cell-ptb-md">
                                <Select2DropdownMenu
                                    openDirection="up"
                                    isSearchable={ true }
                                    selectedOptionHeaderShown={ true }
                                    sortedOptionsAndLabelName={ [[null, typeInput.sortedOptions]] }
                                    onOptionSelect={ typeInput.onOptionSelect }
                                    selectedOption={ typeInput.currentOption }
                                    placeholderText=""
                                    hasCreateCustomOption={ true }
                                    createCustomOption={ typeInput.onCreateCustomOption }
                                    customOptionGroupLabel={ null }
                                    buttonShouldDisplaySelectedLabel={ true }
                                    emptyState={ null }
                                    createCustomOptionButtonLabel={ null }
                                />
                            </div>
                        </div>
                    }
                    <div className="col-row">
                        <div className="cell col-xs-2 cell-ptb-md">
                            <span>Note</span>
                        </div>
                        <div className="cell col-xs-10 cell-ptb-md">
                            <textarea
                                defaultValue={ note === null ? '' : note }
                                onBlur={ this.handleNoteChange }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private handleNoteChange(event : React.FocusEvent<HTMLTextAreaElement>) : void {
        const input = event.currentTarget;
        return this.props.setNote(input.value);
    }
}
