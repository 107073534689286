import React from 'react';

import { HexColor } from 'shared/models/HexColor';

/**
 * StackedBarCategoryStack properties
 */
export interface IStackedBarCategoryStack {
    /** color of the rectangle/stack */
    fillColor : HexColor;
    /** key for the component (needs to be unique within the <g> group) */
    componentKey : number;
    /** id for the component (should be unique in the document) */
    componentId : string;
    /** starting x position for the stack in the svg element */
    xValue : number;
    /** starting y postition for the stack in the svg element */
    yValue : number;
    /** width of the stack */
    width : number;
    /** height of the stack */
    height : number;
    /** name of the category of the stack */
    categoryName : string;
}

/**
 * Form StackedBarCategoryStack
 */
export class StackedBarCategoryStack extends React.Component<IStackedBarCategoryStack, object> {

    public render() {
        const {
            fillColor,
            componentKey,
            componentId,
            xValue,
            yValue,
            width,
            height,
            categoryName,
        } = this.props;

        const categoryClassName = categoryName.split(' ').join('-').toLowerCase() + '-block';

        return (
            <rect
                className={ 'bar-chart-category-stack ' + categoryClassName }
                fill={ fillColor.getValue() }
                key={ componentKey }
                id={ componentId + '-' + componentKey }
                x={ xValue }
                y={ yValue }
                height={ height }
                width={ width }
                data-category={ categoryName }
            />
        );

    }

}
