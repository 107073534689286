import React from 'react';

import { CheckBoxTriState } from '../models/CheckBoxTriState';
import { IInputLabel, LabelOrientation } from '../models/InputLabel';

import '../css/CheckBox.scss';

export interface ICheckBoxProps {
    checkBoxTriState : CheckBoxTriState;
    isDisabled : boolean;
    label : IInputLabel | null;
    onClick : (event : React.MouseEvent<HTMLElement>) => void;
}

export class CheckBox extends React.Component<ICheckBoxProps, object> {
    private handleOnClick : (event : React.MouseEvent<HTMLElement>) => void;

    constructor(props : ICheckBoxProps) {
        super(props);

        this.handleOnClick = this.handleOnClickGenerator();
    }
    public render() {
        const {
            checkBoxTriState,
            isDisabled,
            label,
        } = this.props;

        const disabledClass = isDisabled ? 'disabled' : '';
        let checkBoxClass : string;

        let labelElement : JSX.Element | null = null;
        if (label !== null) {
            const labelOrientationClass = label.labelOrientation === LabelOrientation.LEFT ? 'left' : 'right';
            labelElement = (
                <p
                    className={ `label ${ labelOrientationClass } ${ disabledClass }` }
                    onClick={ this.handleOnClick }
                >
                    { label.value }
                </p>
            );
        }

        switch (checkBoxTriState) {
            case CheckBoxTriState.Checked:
                checkBoxClass = 'bevico-check-box';
                break;
            case CheckBoxTriState.Unchecked:
                checkBoxClass = 'bevico-check-box-outline-blank';
                break;
            default:
                checkBoxClass = 'bevico-check-box-indeterminate-fill';
                break;
        }

        return (
            <span className="check-box" tabIndex={ 0 } role="checkbox">
                { labelElement }
                <span
                    className={ `bevicon ${ checkBoxClass } ${ disabledClass }` }
                    onClick={ this.handleOnClick }
                />
            </span>
        );
    }
    private handleOnClickGenerator() {
        return (event : React.MouseEvent<HTMLElement>) => {
            if (!this.props.isDisabled) {
                this.props.onClick(event);
            }
        };
    }
}
