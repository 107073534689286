//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';
import LocationModel from './location_Model_types';
import CartModel from './cart_Model_types';



var OrderModel = {};
export default OrderModel;
OrderModel.DeliveryId = function(args) {
  this.value = null;
  if (args) {
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field value is unset!');
    }
  }
};
OrderModel.DeliveryId.prototype = {};
OrderModel.DeliveryId.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

OrderModel.DeliveryId.prototype.write = function(output) {
  output.writeStructBegin('DeliveryId');
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 1);
    output.writeString(this.value);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

OrderModel.CartIdAndCartItems = function(args) {
  this.cartId = null;
  this.cartItems = null;
  if (args) {
    if (args.cartId !== undefined && args.cartId !== null) {
      this.cartId = new LocationModel.LocationIdentifier(args.cartId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field cartId is unset!');
    }
    if (args.cartItems !== undefined && args.cartItems !== null) {
      this.cartItems = Thrift.copyList(args.cartItems, [CartModel.CartItem]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field cartItems is unset!');
    }
  }
};
OrderModel.CartIdAndCartItems.prototype = {};
OrderModel.CartIdAndCartItems.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.cartId = new LocationModel.LocationIdentifier();
        this.cartId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.cartItems = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new CartModel.CartItem();
          elem6.read(input);
          this.cartItems.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

OrderModel.CartIdAndCartItems.prototype.write = function(output) {
  output.writeStructBegin('CartIdAndCartItems');
  if (this.cartId !== null && this.cartId !== undefined) {
    output.writeFieldBegin('cartId', Thrift.Type.STRUCT, 1);
    this.cartId.write(output);
    output.writeFieldEnd();
  }
  if (this.cartItems !== null && this.cartItems !== undefined) {
    output.writeFieldBegin('cartItems', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.cartItems.length);
    for (var iter7 in this.cartItems)
    {
      if (this.cartItems.hasOwnProperty(iter7))
      {
        iter7 = this.cartItems[iter7];
        iter7.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

