import { MassUnit } from 'api/Product/model/MassUnit';
import { Packaging } from 'api/Product/model/Packaging';
import { PackagingId } from 'api/Product/model/PackagingId';
import { PackagingWeight } from 'api/Product/model/PackagingWeight';
import { Product } from 'api/Product/model/Product';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import { oldPackagingUtils } from 'api/Product/utils/oldPackagingUtils';

const getContentVolume = (packaging : Packaging) : QuantityInUnit<VolumeUnit> => {
    const content = packaging.getContent();
    const quantityOfContent = packaging.getQuantityOfContent();

    if ((content === null) || (quantityOfContent === null)) {
        throw new Error('unexpected null content: ' + packaging);
    }

    const contentUnit = content.getUnit();
    if (contentUnit && VolumeUnit.isVolumeUnit(contentUnit)) {
        return new QuantityInUnit(quantityOfContent, contentUnit);
    } else {
        throw new Error('content unit is not VolumeUnit: ' + contentUnit);
    }
};

// Bottle weights vary a lot and there exists no standard references.
const getDefaultBottleEmptyWeightOz = (bottleVolumeML : number) : number => {
    const marginMl = 10;

    // values based on statistics of data "Tare Weights (Includes CN Tares) (1).xlsx" which only include liquors
    if (Math.abs(bottleVolumeML - 375) < marginMl) {
        return 16.1;
    }
    if (Math.abs(bottleVolumeML - 750) < marginMl) {
        return 19;
    }
    if (Math.abs(bottleVolumeML - 1000) < marginMl) {
        return 20.8;
    }
    if (Math.abs(bottleVolumeML - 1140) < marginMl) {
        return 23;
    }

    // guesses for uncommon bottle sizes
    if (bottleVolumeML < 375) {
        return 8.0;
    }
    if (bottleVolumeML < 750) {
        return 17.5;
    }
    if (bottleVolumeML < 1000) {
        return 19.9;
    }
    if (bottleVolumeML < 1140) {
        return 21.9;
    }

    // bigger than 1140ml
    return 42.3;
};

const getDefaultBottleNetWeightKg = (bottleVolumeML : number) : number => {
    // TODO: improve guesses
    const defaultLiquidDensity = 1.0;   //  (g/ml)
    return bottleVolumeML * defaultLiquidDensity * 0.001;
};

const getPackagingWeightWithDefaultsApplied = (packaging : Packaging, packagingWeight : PackagingWeight) : PackagingWeight => {
    let emptyWeight : QuantityInUnit<MassUnit> | null = packagingWeight.getEmptyWeight();
    let fullWeight : QuantityInUnit<MassUnit> | null = packagingWeight.getFullWeight();

    if ((emptyWeight === null) || (fullWeight === null)) {
        if ((packaging.getName() === 'bottle') && (packaging.getContent() !== null) && (packaging.getQuantityOfContent() !== null)) {
            const contentVolumeML = oldPackagingUtils.getQuantityInUnitVolume(getContentVolume(packaging), VolumeUnit.MILLILITER).getQuantity();

            if (emptyWeight === null) {
                emptyWeight = new QuantityInUnit(getDefaultBottleEmptyWeightOz(contentVolumeML), MassUnit.DRY_OUNCE);
            }
        
            if ((emptyWeight !== null) && (fullWeight === null)) {
                const netWeightKg = new QuantityInUnit(getDefaultBottleNetWeightKg(contentVolumeML), MassUnit.KILOGRAM);
                const netWeightQuantity = oldPackagingUtils.getQuantityInUnitMass(netWeightKg, emptyWeight.getUnit()).getQuantity();

                fullWeight = new QuantityInUnit(netWeightQuantity + emptyWeight.getQuantity(), emptyWeight.getUnit());
            }       
        }
    }

    return new PackagingWeight(emptyWeight, fullWeight);
};

const getMeasuredFractionFromPackagingWeight = (product : Product, packagingId : PackagingId, measuredWeight : QuantityInUnit<MassUnit>) : QuantityInUnit<ProductQuantityUnit> => {
    let emptyWeight : QuantityInUnit<MassUnit> | null = null;
    let fullWeight : QuantityInUnit<MassUnit> | null = null;

    const packaging = product.getPackagingsAndMappings().getAvailablePackagingByPackagingId().get(packagingId);
    const packagingWeight = product.getWeightsByPackagingId().get(packagingId);
    if ((typeof packaging !== 'undefined') && (typeof packagingWeight !== 'undefined')) {
        const packagingWeightWithDefaultsApplied = getPackagingWeightWithDefaultsApplied(packaging, packagingWeight);
        emptyWeight = packagingWeightWithDefaultsApplied.getEmptyWeight();
        fullWeight = packagingWeightWithDefaultsApplied.getFullWeight();
    }

    if (emptyWeight !== null) {
        const emptyWeightKg = oldPackagingUtils.getMassInKg(emptyWeight).getQuantity();
        const measuredWeightKg = oldPackagingUtils.getMassInKg(measuredWeight).getQuantity();

        if (product.getPackagingsAndMappings().getAvailableMassUnits().size > 0) {
            let productWeightKg = measuredWeightKg - emptyWeightKg;

            if (fullWeight !== null) {
                productWeightKg = Math.min(productWeightKg, oldPackagingUtils.getMassInKg(fullWeight).getQuantity() - emptyWeightKg);
                productWeightKg = Math.max(productWeightKg, 0);
            }

            return new QuantityInUnit(productWeightKg, MassUnit.KILOGRAM);
        }

        if (fullWeight !== null) {
            let measuredFraction = (measuredWeightKg - emptyWeightKg) / (oldPackagingUtils.getMassInKg(fullWeight).getQuantity() - emptyWeightKg);      // todo: check float issue?
            measuredFraction = Math.min(measuredFraction, 1.0);
            measuredFraction = Math.max(measuredFraction, 0);

            return new QuantityInUnit(measuredFraction, packagingId);
        }
    }

    return new QuantityInUnit(0, packagingId);
};

export const packagingWeightUtils = {
    getPackagingWeightWithDefaultsApplied,
    getMeasuredFractionFromPackagingWeight,
};
