import { AddressFormFieldName } from './AddressForm';

import { IValidationResult, Validation } from 'shared/validators/validators';

export const validateAddressValueByFieldName = (
    fieldName : AddressFormFieldName,
    value : string,
    selectedCountryCode : string
) : IValidationResult => {
    let isValid : null | boolean = null;
    let errorMessage = '';

    // required
    switch (fieldName) {
        case 'streetAddressLine1':
        case 'city':
        case 'state':
        case 'country':
            isValid = Validation.validateRequired(value);
            if (!isValid) {
                return {
                    errorMessage,
                    isValid,
                };
            }
        default:
            break;
    }

    // input type specific
    switch (fieldName) {
        case 'zipCode':
            isValid = Validation.validateZipCode(value, selectedCountryCode);
            if (!isValid) {
                errorMessage = 'Invalid ZIP code.';
            }
            break;
        case 'streetAddressLine2':
            isValid = true;
            errorMessage = '';
            break;
        default:
            if (isValid === null) {
                throw new Error('no validation implemented for fieldname ' + fieldName);
            }
    }

    return {
        isValid,
        errorMessage,
    };
};
