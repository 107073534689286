import { Packaging } from 'api/Product/model/Packaging';
import { PackagingId } from 'api/Product/model/PackagingId';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import React from 'react';
import MediaQuery from 'react-responsive';

import { oldPackagingUtils } from 'api/Product/utils/oldPackagingUtils';
import { CatalogItem } from 'api/Search/model/CatalogItem';
import { ICatalogItemOption } from 'api/Search/model/ICatalogItemOption';
import { Button } from 'shared/components/Button';
import { CheckBox } from 'shared/components/CheckBox';
import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { PackagingForm } from 'shared/components/Product/PackagingForm';
import { GenericSelect2DropdownMenu } from 'shared/components/Select2Dropdown/GenericSelect2DropdownMenu';
import { OptionsAndLabelNameTuples } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { MIN_TABLET_WIDTH } from 'shared/constants';
import { CheckBoxTriState } from 'shared/models/CheckBoxTriState';
import { LabelOrientation } from 'shared/models/InputLabel';
import { IModalButton } from 'shared/models/Modal';

import '../css/CatalogRow.scss';

interface ICatalogRowProps {
    catalogItem : CatalogItem;
    selectedOptionIndices : Set<number>;
    disabledOptionIndices : Set<number>;
    optionDropdownIsOpen : boolean;
    packagingFormIsShown : boolean;
    setOptionDropdownIsOpen : (isOpen : boolean) => void;
    setPackagingFormIsShown : (isShown : boolean) => void;
    setOptionIsSelected : (optionIndex : number, isSelected : boolean) => void;
    addCustomCatalogItemOption : (catalogItemOption : ICatalogItemOption) => void;
}

interface ICatalogRowState {
    selectedOptionIndices : Set<number>;
}

export class CatalogRow extends React.Component<ICatalogRowProps, ICatalogRowState> {
    private packagingForm : PackagingForm | undefined;
    private packagingSelector : HTMLDivElement | undefined;

    private readonly BEER_PACKAGING = new Packaging(
        new PackagingId('defaultBeerPackagingId'),
        'bottle',
        new Packaging(null, null, null, null, VolumeUnit.OUNCE),
        12,
        null
    );

    constructor (props : ICatalogRowProps) {
        super(props);
        this.state = {
            selectedOptionIndices: new Set(),
        };
    }

    public componentDidMount() {
        window.addEventListener('click', this.onWindowClick);
    }

    public componentWillUnmount() {
        window.removeEventListener('click', this.onWindowClick);
    }

    public render() {
        const {
            catalogItem,
            selectedOptionIndices,
            optionDropdownIsOpen,
            packagingFormIsShown,
        } = this.props;

        const selectedCount = selectedOptionIndices.size;
        const selectionStatus = selectedCount > 0 ? (
            <span className="selection-status dark flex-text-with-icon middle">
                <span className="icon-left bevicon bevico-check"/>
                { selectedCount }
                <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                    <span>&nbsp;selected</span>
                </MediaQuery>
            </span>
        ) : null;

        const sortedOptionsAndLabelName = this.getSortedOptionsAndLabelName(catalogItem.getOptions());

        return (
            <div className="catalog-row collapsable-product-row col-row">
                <div className="cell col-xs-6 col-sm-7 cell-ptb">
                    <span className="product-name-and-brand-container">
                        <span className="product-brand">{ catalogItem.getBrand() }</span>
                        <span className="product-name">{ catalogItem.getName() }</span>
                    </span>
                </div>
                <div className="cell col-xs-2 col-sm-3 cell-ptb text-right">
                    { selectionStatus }
                </div>
                <div className="cell col-xs-4 col-sm-2 cell-ptb select2-dropdown-col" ref={ this.packagingSelectorRef }>
                    <GenericSelect2DropdownMenu
                        isSearchable={ true }
                        selectedOptionHeaderShown={ false }
                        selectedOption={ null } // N/A for multi-select
                        emptyState={ null }
                        placeholderText="Select"
                        hasCreateCustomOption={ false }     // using custom create button in footer
                        buttonShouldDisplaySelectedLabel={ false }
                        sortedOptionsAndLabelName={ sortedOptionsAndLabelName }
                        getOptionElementsForGroup={ this.getOptionElements }
                        createCustomOption={ this.doNothing }
                        getSelectedOptionElementForHeader={ this.doNothing } // N/A for multi-select
                        footerElement={ this.getFooter() }
                        onDropdownBlur={ this.onSubmit }
                        isOpen={ optionDropdownIsOpen }
                        handleDropdownOpenClose={ this.handleSetOptionDropdownIsOpen }
                        createCustomOptionIsLoading={ false }
                        createCustomOptionButtonLabel={ null }
                        customOptionGroupLabel={ null }
                    />
                    { packagingFormIsShown &&
                        <div className="packaging-form-container">
                            <PackagingForm
                                ref={ this.packagingFormRef }
                                initialPackaging={ (catalogItem.getProductCategoryId() === 'Beer') ? this.BEER_PACKAGING : null }
                                onFormFieldChange={ this.doNothing }
                            />
                            <div className="dropdown-modal-footer text-right">
                                <Button buttonClassName="flat secondary" isDisabled={ false } isLoading={ false } onClick={ this.onPackagingFormCancel }>CANCEL</Button>
                                <Button buttonClassName="flat secondary primary" isDisabled={ false } isLoading={ false } onClick={ this.onPackagingFormSave }>SELECT</Button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }

    private readonly getSortedOptionsAndLabelName = (catalogItemOptions : Array<ICatalogItemOption>) : OptionsAndLabelNameTuples => {
        const optionsByGroup : {[group : string] : Array<IOption>} = {};
        catalogItemOptions.forEach((catalogItemOption, index) => {
            const packaging = oldPackagingUtils.getOldPackagingFromPackaging(catalogItemOption.packaging);
            const group = oldPackagingUtils.getContainerTypeDisplayTextForPackaging(packaging);
            const label = oldPackagingUtils.getDisplayTextForPackaging(packaging, false);
            const value = String(index);    // use index as option value...
            const optionsInGroup = optionsByGroup[group] || [];
            optionsByGroup[group] = optionsInGroup.concat([{ value, label, icon: null }]);
        });

        const sortedOptionsAndLabelName : OptionsAndLabelNameTuples = [];
        Object.keys(optionsByGroup).sort().forEach((group) => {
            const sortedOptions = optionsByGroup[group].sort((a, b) => a.label.localeCompare(b.label));
            sortedOptionsAndLabelName.push([group, sortedOptions]);
        });

        return sortedOptionsAndLabelName;
    }

    private readonly getOptionElements = (options : Array<IOption>) => {
        const {
            disabledOptionIndices,
        } = this.props;
        const {
            selectedOptionIndices,
        } = this.state;

        return options.map((option) => {
            const optionIndex : number = Number(option.value);
            const isSelected = selectedOptionIndices.has(optionIndex);
            const isDisabled = disabledOptionIndices.has(optionIndex);
            const onClick = () => {
                if (isSelected) {
                    selectedOptionIndices.delete(optionIndex);
                } else {
                    selectedOptionIndices.add(optionIndex);
                }
                this.setState({
                    selectedOptionIndices,
                });
            };
            return (
                <div key={ option.value } className="col-row">
                    <CheckBox
                        checkBoxTriState={ (isDisabled || isSelected) ? CheckBoxTriState.Checked : CheckBoxTriState.Unchecked }
                        isDisabled={ isDisabled }
                        label={ { labelOrientation: LabelOrientation.RIGHT, value: option.label } }
                        onClick={ onClick }
                    />
                </div>
            );
        });
    }

    private readonly resetSelections = () => {
        this.setState({
            selectedOptionIndices: new Set(this.props.selectedOptionIndices),
        });
    }

    private readonly handleSetOptionDropdownIsOpen = (isOpen : boolean) => {
        const {
            setOptionDropdownIsOpen,
            optionDropdownIsOpen,
        } = this.props;
        if (isOpen && !optionDropdownIsOpen) {
            this.resetSelections();
        }

        setOptionDropdownIsOpen(isOpen);
    }

    private readonly onCreateNewOptionClick = (event : any) => {
        // have to do this because the button disappears BEFORE click event gets captured in onWindowClick, which no longer
        // finds the button and treats the event as clicking out of the newly opened packaging form (and closes it)...
        event.stopPropagation();

        this.props.setPackagingFormIsShown(true);
        this.props.setOptionDropdownIsOpen(false);
    }

    private readonly onCancel = () => {
        this.props.setOptionDropdownIsOpen(false);
        this.props.setPackagingFormIsShown(false);
        this.resetSelections();
    }

    private readonly onSubmit = () => {
        // only submit selection changes on current option list - does NOT reflect newly added custom option
        this.props.catalogItem.getOptions().forEach((option, optionIndex) => {
            const isSelected = this.state.selectedOptionIndices.has(optionIndex);
            if (isSelected !== this.props.selectedOptionIndices.has(optionIndex)) {
                this.props.setOptionIsSelected(optionIndex, isSelected);
            }
        });

        this.props.setOptionDropdownIsOpen(false);
        this.props.setPackagingFormIsShown(false);
    }

    private getFooter() {
        const footerButtons : ReadonlyArray<IModalButton> = [
            {
                classes: 'flat secondary',
                children: 'Cancel',
                isDisabled: false,
                isLoading: false,
                onClick: this.onCancel,
            },
            {
                classes: 'flat primary',
                children: 'Select',
                isDisabled: false,
                isLoading: false,
                onClick: this.onSubmit,
            },
        ];

        const buttons : ReadonlyArray<JSX.Element> = footerButtons.map(
            (modalButton : IModalButton, index : number) => {
                return (
                    <Button
                        key={ index }
                        buttonClassName={ modalButton.classes }
                        isDisabled={ modalButton.isDisabled }
                        isLoading={ modalButton.isLoading }
                        onClick={ modalButton.onClick }
                    >
                    { modalButton.children }
                    </Button>
                );
            }
        );

        return (
            <div className="dropdown-modal-footer">
                <div>
                    <Button
                        buttonClassName="add-custom-dropdown-option-button reset-dark-styles primary flat with-icon"
                        isDisabled={ false }
                        isLoading={ false }
                        onClick={ this.onCreateNewOptionClick }
                    >
                            <span className="button-text-container">
                                <span className="bevicon bevico-add" />
                                <span className="button-text">Create Custom Size</span>
                            </span>
                    </Button>
                </div>
                { buttons }
            </div>
        );
    }

    private readonly onPackagingFormCancel = () => {
        // not resetting packaging form - ok
        this.onCancel();
    }

    private readonly onPackagingFormSave = () => {
        if (!this.packagingForm) {
            throw Error('unexpected: packaging form not found');
        }

        const packaging = this.packagingForm.getPackaging(true);
        if (!packaging) {
            // do nothing
        } else {
            const newIndex = this.props.catalogItem.getOptions().length;
            this.props.addCustomCatalogItemOption({ packaging });
            this.props.setOptionIsSelected(newIndex, true);

            this.onSubmit();
        }
    }

    private readonly packagingFormRef = (packagingForm : PackagingForm) => {
        this.packagingForm = packagingForm;
    }

    private readonly packagingSelectorRef = (div : HTMLDivElement) => {
        this.packagingSelector = div;
    }

    private readonly onWindowClick = (event : Event) : void => {
        if (!this.packagingSelector || !this.packagingSelector.contains((event.target as Element))) {
            if (this.props.packagingFormIsShown) {
                this.onPackagingFormCancel();
            }
        }
    }

    private doNothing = () => undefined;
}
