import { Action } from 'redux';

// Components
import { OptionsAndLabelNameTuples } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { IValidationInputData } from 'shared/components/ValidationInput';

// Actions
import { ActionInterfaces, ActionTypes } from './actions';

export type DefaultPourFormFieldName = 'category' | 'quantity' | 'unit';
export type DefaultPourInfoByFormFieldName = { [formFieldName in DefaultPourFormFieldName]: IValidationInputData };

export interface IEditDefaultPourFormState {
    defaultPourForm : Array<DefaultPourInfoByFormFieldName>;
    categoryOptions : OptionsAndLabelNameTuples;
}

export const initialState : IEditDefaultPourFormState = {
    defaultPourForm: [],
    categoryOptions: [],
};

const reduceSetDefaultPourFormForIndex = (
    state : IEditDefaultPourFormState,
    action : ActionInterfaces.ISetDefaultPourFormForIndex
) : IEditDefaultPourFormState => {

    const newForm = [...state.defaultPourForm];

    const oldRowInfo = { ...newForm[action.payload.index] };
    newForm[action.payload.index] = {
        ...oldRowInfo,
        [action.payload.field]: action.payload.validationInput
    };

    return {
        ...state,
        defaultPourForm: newForm
    };
};

// needs to be updated when this code is used
const reduceAddDefaultPourCategory = (
    state : IEditDefaultPourFormState,
    action : ActionInterfaces.IAddDefaultPourCategory
) : IEditDefaultPourFormState => {

    return {
        ...state,
        defaultPourForm: action.payload.defaultPourForm
    };
};

// needs to be updated when this code is used.
const reduceRemoveDefaultPourCategoryByIndex = (
    state : IEditDefaultPourFormState,
    action : ActionInterfaces.IRemoveDefaultPourCategoryByIndex
) : IEditDefaultPourFormState => {

    const newForm = [...state.defaultPourForm];

    newForm.splice(action.payload.index, 1);

    return {
        ...state,
        defaultPourForm: newForm
    };
};

const reduceSetForm = (
    state : IEditDefaultPourFormState,
    action : ActionInterfaces.ISetForm
) : IEditDefaultPourFormState => {

    return {
        ...state,
        defaultPourForm: action.payload.defaultPourForm
    };
};

const reduceSetCategoryOptions = (
    state : IEditDefaultPourFormState,
    action : ActionInterfaces.ISetCategoryOptions
) : IEditDefaultPourFormState => {
    return {
        ...state,
        categoryOptions: action.payload.categoryOptions
    };
};

export const EditDefaultPourFormReducers = (
    state : IEditDefaultPourFormState = initialState,
    action : Action,
) : IEditDefaultPourFormState => {
    switch (action.type) {
        case ActionTypes.SET_DEFAULT_POUR_FORM_FOR_INDEX:
            return reduceSetDefaultPourFormForIndex(state, action as ActionInterfaces.ISetDefaultPourFormForIndex);
        case ActionTypes.ADD_DEFAULT_POUR_CATEGORY:
            return reduceAddDefaultPourCategory(state, action as ActionInterfaces.IAddDefaultPourCategory);
        case ActionTypes.REMOVE_DEFAULT_POUR_CATEGORY:
            return reduceRemoveDefaultPourCategoryByIndex(state, action as ActionInterfaces.IRemoveDefaultPourCategoryByIndex);
        case ActionTypes.SET_FORM:
            return reduceSetForm(state, action as ActionInterfaces.ISetForm);
        case ActionTypes.SET_CATEGORY_OPTIONS:
            return reduceSetCategoryOptions(state, action as ActionInterfaces.ISetCategoryOptions);
        default:
            return state;
    }
};
