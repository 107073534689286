import { BreakageId } from 'api/Breakage/model/BreakageId';
import { BreakageReport } from 'api/Breakage/model/BreakageReport';
import { StringValueMap } from 'api/Core/StringValueMap';
import { InventoryCountMetadata } from 'api/InventoryCount/model/InventoryCountMetadata';
import { Delivery } from 'api/Ordering/model/Delivery';
import { DeliveryId } from 'api/Ordering/model/DeliveryId';
import { PrepEvent } from 'api/PrepEvent/model/PrepEvent';
import { PrepEventId } from 'api/PrepEvent/model/PrepEventId';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { TransferDirection } from 'api/Transfer/model/TransferDirection';
import { TransferId } from 'api/Transfer/model/TransferId';
import { TransferReportWithoutCost } from 'api/Transfer/model/TransferReportWithoutCost';
import { TransferStatus, TransferUtils } from 'api/Transfer/utils/TransferUtils';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { decimalUtils, decimalToNumber, numberToDecimal } from 'shared/utils/decimalUtils';
import { UsageData } from '../model/UsageData';

const getDeliveriesAndTransfersAndPrepsCounts = (
    product : Product,
    unitCountByDeliveryId : StringValueMap<DeliveryId, QuantityInUnit<ProductQuantityUnit>> | undefined,
    unitCountByTransferId : StringValueMap<TransferId, QuantityInUnit<ProductQuantityUnit>> | undefined,
    inputUnitCountByPrepEventId : StringValueMap<PrepEventId, QuantityInUnit<ProductQuantityUnit>> | undefined,
    outputUnitCountByPrepEventId : StringValueMap<PrepEventId, QuantityInUnit<ProductQuantityUnit>> | undefined,
) => {
    let deliveriesUnitCountInPreferredReportingUnit;
    const preferredReportingUnit = product.getPreferredReportingUnit();
    if (unitCountByDeliveryId) {
        let deliveriesUnitSum = 0;
        unitCountByDeliveryId.forEach((value, key) => {
            let unitCount = value;
            if (preferredReportingUnit && !PackagingUtils.productQuantityUnitsAreEqual(preferredReportingUnit, unitCount.getUnit())) {
                unitCount = PackagingUtils.convertProductQuantityToUnit(product.getPackagingsAndMappings(), unitCount, preferredReportingUnit);
            }
            deliveriesUnitSum += unitCount.getQuantity();
        });
        deliveriesUnitCountInPreferredReportingUnit = deliveriesUnitSum;
    }

    let transfersInUnitCountInPreferredReportingUnit;
    let transfersOutUnitCountInPreferredReportingUnit;
    if (unitCountByTransferId) {
        const transferAmounts = Array.from(unitCountByTransferId.values());
        for (let i = 0; i < transferAmounts.length; i++) {
            let transferAmount = transferAmounts[i];
            if (!PackagingUtils.productQuantityUnitsAreEqual(preferredReportingUnit, transferAmount.getUnit())) {
                transferAmount = PackagingUtils.convertProductQuantityToUnit(product.getPackagingsAndMappings(), transferAmount, preferredReportingUnit);
            }
            const amount = transferAmount.getQuantity();

            if (amount > 0) {
                transfersInUnitCountInPreferredReportingUnit = (transfersInUnitCountInPreferredReportingUnit ? transfersInUnitCountInPreferredReportingUnit : 0) + amount;
            } else {
                transfersOutUnitCountInPreferredReportingUnit = (transfersOutUnitCountInPreferredReportingUnit ? transfersOutUnitCountInPreferredReportingUnit : 0) + amount;
            }
        }
    }

    let prepEventsInputUnitCountInPreferredReportingUnit : number | undefined;
    let prepEventsOutputUnitCountInPreferredReportingUnit : number | undefined;
    if (inputUnitCountByPrepEventId) {
        inputUnitCountByPrepEventId.forEach((unitCounts, prepEventId) => {
            let unitCount = unitCounts;
            if (!PackagingUtils.productQuantityUnitsAreEqual(preferredReportingUnit, unitCount.getUnit())) {
                unitCount = PackagingUtils.convertProductQuantityToUnit(product.getPackagingsAndMappings(), unitCount, preferredReportingUnit);
            }
            prepEventsInputUnitCountInPreferredReportingUnit = (prepEventsInputUnitCountInPreferredReportingUnit ? prepEventsInputUnitCountInPreferredReportingUnit : 0) + unitCount.getQuantity();
        });
    }
    if (outputUnitCountByPrepEventId) {
        outputUnitCountByPrepEventId.forEach((unitCounts, prepEventId) => {
            let unitCount = unitCounts;
            if (!PackagingUtils.productQuantityUnitsAreEqual(preferredReportingUnit, unitCount.getUnit())) {
                unitCount = PackagingUtils.convertProductQuantityToUnit(product.getPackagingsAndMappings(), unitCount, preferredReportingUnit);
            }
            prepEventsOutputUnitCountInPreferredReportingUnit = (prepEventsOutputUnitCountInPreferredReportingUnit ? prepEventsOutputUnitCountInPreferredReportingUnit : 0) + unitCount.getQuantity();
        });
    }

    let deliveriesAndTransfersAndPrepsTotalCountInPreferredReportingUnit : number | undefined;
    if ((typeof deliveriesUnitCountInPreferredReportingUnit !== 'undefined') ||
        (typeof transfersInUnitCountInPreferredReportingUnit !== 'undefined') ||
        (typeof transfersOutUnitCountInPreferredReportingUnit !== 'undefined') ||
        (typeof prepEventsInputUnitCountInPreferredReportingUnit !== 'undefined') ||
        (typeof prepEventsOutputUnitCountInPreferredReportingUnit !== 'undefined')) {
        deliveriesAndTransfersAndPrepsTotalCountInPreferredReportingUnit = (deliveriesUnitCountInPreferredReportingUnit ? deliveriesUnitCountInPreferredReportingUnit : 0) +
                                 (transfersInUnitCountInPreferredReportingUnit ? transfersInUnitCountInPreferredReportingUnit : 0) +
                                 (transfersOutUnitCountInPreferredReportingUnit ? transfersOutUnitCountInPreferredReportingUnit : 0) +
                                 (prepEventsInputUnitCountInPreferredReportingUnit ? prepEventsInputUnitCountInPreferredReportingUnit * -1 : 0) + // inputs = negative
                                 (prepEventsOutputUnitCountInPreferredReportingUnit ? prepEventsOutputUnitCountInPreferredReportingUnit : 0);
    }

    return {
        deliveriesUnitCount: (deliveriesUnitCountInPreferredReportingUnit) ? new QuantityInUnit(deliveriesUnitCountInPreferredReportingUnit, preferredReportingUnit) : undefined,
        transfersInUnitCount: (transfersInUnitCountInPreferredReportingUnit) ? new QuantityInUnit(transfersInUnitCountInPreferredReportingUnit, preferredReportingUnit) : undefined,
        transfersOutUnitCount: (transfersOutUnitCountInPreferredReportingUnit) ? new QuantityInUnit(transfersOutUnitCountInPreferredReportingUnit, preferredReportingUnit) : undefined,
        prepEventsInputUnitCount: (prepEventsInputUnitCountInPreferredReportingUnit) ? new QuantityInUnit(prepEventsInputUnitCountInPreferredReportingUnit * -1, preferredReportingUnit) : undefined,
        prepEventsOutputUnitCount: (prepEventsOutputUnitCountInPreferredReportingUnit) ? new QuantityInUnit(prepEventsOutputUnitCountInPreferredReportingUnit, preferredReportingUnit) : undefined,
        deliveriesAndTransfersAndPrepsTotalCount: (deliveriesAndTransfersAndPrepsTotalCountInPreferredReportingUnit) ? new QuantityInUnit(deliveriesAndTransfersAndPrepsTotalCountInPreferredReportingUnit, preferredReportingUnit) : undefined,
    };
};

const getUnitCountByProductIdByDeliveryId = (
    deliveriesById : StringValueMap<DeliveryId, Delivery>,
    productsById : StringValueMap<ProductId, Product>,
) : StringValueMap<ProductId, StringValueMap<DeliveryId, QuantityInUnit<ProductQuantityUnit>>> => {
    const unitCountInPreferredReportingUnitByProductIdByDeliveryId = new StringValueMap<ProductId, StringValueMap<DeliveryId, QuantityInUnit<ProductQuantityUnit>>>();

    deliveriesById.forEach((delivery, deliveryId) => {
        delivery.getDeliveryLineItems().forEach((deliveryLineItem) => {
            const productId = deliveryLineItem.getProductId();
            if (!unitCountInPreferredReportingUnitByProductIdByDeliveryId.has(productId)) {
                unitCountInPreferredReportingUnitByProductIdByDeliveryId.set(productId, new StringValueMap<DeliveryId, QuantityInUnit<ProductQuantityUnit>>());
            }

            const unitCountByDeliveryId = unitCountInPreferredReportingUnitByProductIdByDeliveryId.get(productId);
            const product = productsById.get(productId);

            if ((typeof unitCountByDeliveryId === 'undefined') || (typeof product === 'undefined')) {
                throw new RuntimeException('unexpected');
            }

            const preferredReportingUnit = product.getPreferredReportingUnit();
            const deliveryLineItemQuantityInUnit = new QuantityInUnit(deliveryLineItem.getQuantityInUnits(), deliveryLineItem.getProductQuantityUnit());
            const lineItemContainerCount = PackagingUtils.convertProductQuantityToUnit(product.getPackagingsAndMappings(), deliveryLineItemQuantityInUnit, preferredReportingUnit, productId).getQuantity();

            const oldUnitCount = unitCountByDeliveryId.get(deliveryId);
            const unitCount = lineItemContainerCount + ((typeof oldUnitCount === 'undefined') ? 0 : oldUnitCount.getQuantity());

            unitCountByDeliveryId.set(deliveryId, new QuantityInUnit(unitCount, preferredReportingUnit));
        });
    });

    return unitCountInPreferredReportingUnitByProductIdByDeliveryId;
};

const getUnitCountByProductIdByTransferId = (
    transferReportWithoutCostsById : StringValueMap<TransferId, TransferReportWithoutCost>,
    productsById : StringValueMap<ProductId, Product>,
) : StringValueMap<ProductId, StringValueMap<TransferId, QuantityInUnit<ProductQuantityUnit>>> => {
    const unitCountByProductIdByTransferId = new StringValueMap<ProductId, StringValueMap<TransferId, QuantityInUnit<ProductQuantityUnit>>>();

    transferReportWithoutCostsById.forEach((transferReport, transferId) => {
        const transferReportData = transferReport.getTransferReportData();
        if (TransferUtils.getTransferStatus(transferReport) === TransferStatus.COMPLETE) {
            transferReportData.getProductAmounts().forEach((productAmount) => {
                const productId = productAmount.getProductId();
                if (!unitCountByProductIdByTransferId.has(productId)) {
                    unitCountByProductIdByTransferId.set(productId, new StringValueMap<TransferId, QuantityInUnit<ProductQuantityUnit>>());
                }

                const unitCountByTransferId = unitCountByProductIdByTransferId.get(productId);
                const product = productsById.get(productId);

                if ((typeof unitCountByTransferId === 'undefined') || (typeof product === 'undefined')) {
                    throw new RuntimeException('unexpected');
                }

                const oldUnitCount = unitCountByTransferId.get(transferId);
                const productAmountUnitCount = PackagingUtils.convertProductQuantityToUnit(
                    product.getPackagingsAndMappings(),
                    productAmount.getQuantityInUnit(),
                    product.getPreferredReportingUnit(),
                    productId
                ).getQuantity();
                const productAmountUnitCountWithDirection = ((transferReportData.getDirection() === TransferDirection.FROM_PERSPECTIVE_TO_PARTNER_LOCATION) ? -1 : 1) * productAmountUnitCount;

                const unitCount = productAmountUnitCountWithDirection + ((typeof oldUnitCount === 'undefined') ? 0 : oldUnitCount.getQuantity());

                unitCountByTransferId.set(transferId, new QuantityInUnit(unitCount, product.getPreferredReportingUnit()));
            });
        }
    });

    return unitCountByProductIdByTransferId;
};

const getUnitCountsByProductIdByPrepEventId = (
    prepEventsById : StringValueMap<PrepEventId, PrepEvent>,
    productsById : StringValueMap<ProductId, Product>
) : { inputUnitCountByProductIdByPrepEventId : StringValueMap<ProductId, StringValueMap<PrepEventId, QuantityInUnit<ProductQuantityUnit>>>, outputUnitCountByProductIdByPrepEventId : StringValueMap<ProductId, StringValueMap<PrepEventId, QuantityInUnit<ProductQuantityUnit>>> } => {
    const inputUnitCountByProductIdByPrepEventId = new StringValueMap<ProductId, StringValueMap<PrepEventId, QuantityInUnit<ProductQuantityUnit>>>();
    const outputUnitCountByProductIdByPrepEventId = new StringValueMap<ProductId, StringValueMap<PrepEventId, QuantityInUnit<ProductQuantityUnit>>>();

    prepEventsById.forEach((prepEvent, prepEventId) => {
        const inputQuantityOfProductByProductId = prepEvent.getInputQuantityOfProductByProductId();
        const outputQuantityOfProductByProductId = prepEvent.getOutputQuantityOfProductByProductId();

        inputQuantityOfProductByProductId.forEach((quantityOfProduct, productId) => {
            if (!inputUnitCountByProductIdByPrepEventId.has(productId)) {
                inputUnitCountByProductIdByPrepEventId.set(productId, new StringValueMap<PrepEventId, QuantityInUnit<ProductQuantityUnit>>());
            }

            const unitCountByPrepEventId = inputUnitCountByProductIdByPrepEventId.get(productId);
            const product = productsById.get(productId);

            if ((typeof unitCountByPrepEventId === 'undefined') || (typeof product === 'undefined')) {
                throw new RuntimeException('unexpected');
            }

            const packagingsAndMappings = product.getPackagingsAndMappings();
            const containerUnit = product.getPreferredReportingUnit();

            const productInputQuantityUnit = PackagingUtils.convertProductQuantityToUnit(
                packagingsAndMappings,
                quantityOfProduct,
                containerUnit,
                productId
            );

            unitCountByPrepEventId.set(prepEventId, productInputQuantityUnit);
        });

        outputQuantityOfProductByProductId.forEach((quantityOfProduct, productId) => {
            if (!outputUnitCountByProductIdByPrepEventId.has(productId)) {
                outputUnitCountByProductIdByPrepEventId.set(productId, new StringValueMap<PrepEventId, QuantityInUnit<ProductQuantityUnit>>());
            }

            const unitCountByPrepEventId = outputUnitCountByProductIdByPrepEventId.get(productId);
            const product = productsById.get(productId);

            if ((typeof unitCountByPrepEventId === 'undefined') || (typeof product === 'undefined')) {
                throw new RuntimeException('unexpected');
            }

            const packagingsAndMappings = product.getPackagingsAndMappings();
            const containerUnit = product.getPreferredReportingUnit();

            const productOutputQuantityUnit = PackagingUtils.convertProductQuantityToUnit(
                packagingsAndMappings,
                quantityOfProduct,
                containerUnit,
                productId
            );

            unitCountByPrepEventId.set(prepEventId, productOutputQuantityUnit);
        });
    });

    return {
        inputUnitCountByProductIdByPrepEventId,
        outputUnitCountByProductIdByPrepEventId,
    };
};

// Breakages are not part of usage but this felt like the most reasonable util file to have getUnitCountByProductIdByBreakageId
const getUnitCountByProductIdByBreakageId = (
    breakageReportsByBreakageId : StringValueMap<BreakageId, BreakageReport>,
    productsById : StringValueMap<ProductId, Product>
) : StringValueMap<ProductId, StringValueMap<BreakageId, QuantityInUnit<ProductQuantityUnit>>> => {
    const unitCountsByProductIdByBreakageId : StringValueMap<ProductId, StringValueMap<BreakageId, QuantityInUnit<ProductQuantityUnit>>> = new StringValueMap();
    breakageReportsByBreakageId.forEach((breakageReport, breakageId) => {
        breakageReport.getBreakageReportData().getProductAmounts().forEach((productAmount) => {
            const productId = productAmount.getProductId();

            let unitCountByBreakageId : StringValueMap<BreakageId, QuantityInUnit<ProductQuantityUnit>> | undefined = unitCountsByProductIdByBreakageId.get(productId);
            if (typeof unitCountByBreakageId === 'undefined') {
                unitCountByBreakageId = new StringValueMap();
                unitCountsByProductIdByBreakageId.set(productId, unitCountByBreakageId);
            }

            const product : Product | undefined = productsById.get(productId);

            if (typeof product === 'undefined') {
                throw new RuntimeException('undefined');
            }

            const unitCount = unitCountByBreakageId.get(breakageId);
            const unitCountValue = unitCount ? unitCount.getQuantity() : 0;
            const additionalUnitCount = PackagingUtils.convertProductQuantityToUnit(
                product.getPackagingsAndMappings(),
                productAmount.getQuantityInUnit(),
                product.getPreferredReportingUnit(),
                productId
            ).getQuantity();

            unitCountByBreakageId.set(breakageId, new QuantityInUnit(unitCountValue + additionalUnitCount, product.getPreferredReportingUnit()));
        });
    });

    return unitCountsByProductIdByBreakageId;
};

const getAverageWeeklyUnitUsageByProductId = (
    startingInventoryCountMetadata : InventoryCountMetadata,
    endingInventoryCountMetadata : InventoryCountMetadata,
    usageData : UsageData
) : StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>> => {
    const millisecondsBetweenInventories = endingInventoryCountMetadata.getInventoryCountTime().timeSinceUnixEpoch.value - startingInventoryCountMetadata.getInventoryCountTime().timeSinceUnixEpoch.value;
    const daysBetweenInventories = Math.max(Math.round(millisecondsBetweenInventories / (24 * 60 * 60 * 1000)), 1);
    const multiplier = 7 / daysBetweenInventories;

    const averageWeeklyUnitUsageByProductId = new StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>>();

    usageData.getUsageByProductId().forEach((productUsageData, productId) => {
        averageWeeklyUnitUsageByProductId.set(productId, new QuantityInUnit(productUsageData.unitUsage.getQuantity() * multiplier, productUsageData.unitUsage.getUnit()));
    });

    return averageWeeklyUnitUsageByProductId;
};

export const UsageDataUtils = {
    getDeliveriesAndTransfersAndPrepsCounts,
    getUnitCountByProductIdByDeliveryId,
    getUnitCountsByProductIdByPrepEventId,
    getUnitCountByProductIdByTransferId,
    getUnitCountByProductIdByBreakageId,
    getAverageWeeklyUnitUsageByProductId,
};
