import { Action } from 'redux';

import { IUserAccountGroup } from 'api/UserAccount/model/UserAccountRetailersByGroup';

import { ActionInterfaces, ActionTypes } from './actions';
import { PageContext } from './userSessionUtils';

import { ContextTab } from './ContextTab';

export interface IUserAccountRetailer {
    id : string;
    name : string;
    accountType : 'food' | 'beverage';
    isSandbox : boolean;
}

export interface IUserAccountGroupInfo extends IUserAccountGroup {
    orderedRetailerIds : Array<IUserAccountRetailer>;
}

export interface IUserAccountRetailersByGroup {
    groups : Array<IUserAccountGroupInfo>;
    ungrouped : Array<IUserAccountRetailer>;
    sandboxes : Array<IUserAccountRetailer>;
}

export interface IUserSessionAppState {
    firstName : string | null;
    lastName : string | null;
    pageContext : PageContext;
    activeTab : ContextTab;
    availableTabs : Array<ContextTab>;
    userPopdownOpen : boolean;
    userRetailers : IUserAccountRetailersByGroup | null;
}

const initialState : IUserSessionAppState = {
    firstName: null,
    lastName: null,
    pageContext: 'DEFAULT',
    activeTab: ContextTab.HOME,
    availableTabs: [ ContextTab.HOME ],
    userPopdownOpen: false,
    userRetailers: null,
};

const reduceSetUserInfo = (
    state : IUserSessionAppState,
    action : ActionInterfaces.ISetUserInfo
) : IUserSessionAppState => {
    return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
    };
};

const reduceSetPageContext = (
    state : IUserSessionAppState,
    action : ActionInterfaces.ISetPageContext
) : IUserSessionAppState => {
    return {
        ...state,
        pageContext: action.payload.pageContext,
    };
};

const reduceSetActiveTab = (
    state : IUserSessionAppState,
    action : ActionInterfaces.ISetActiveTab
) : IUserSessionAppState => {
    return {
        ...state,
        activeTab: action.payload.activeTab,
    };
};

const reduceSetAvailableTabs = (
    state : IUserSessionAppState,
    action : ActionInterfaces.ISetAvailableTabs
) : IUserSessionAppState => {
    return {
        ...state,
        availableTabs: action.payload.availabelTabs,
    };
};

const reduceSetUserPopdownOpen = (
    state : IUserSessionAppState,
    action : ActionInterfaces.ISetUserPopdownOpen
) : IUserSessionAppState => {
    return {
        ...state,
        userPopdownOpen: action.payload.open,
    };
};

const reduceSetUserRetailers = (
    state : IUserSessionAppState,
    action : ActionInterfaces.ISetUserRetailers
) : IUserSessionAppState => {
    return {
        ...state,
        userRetailers: action.payload.userRetailers,
    };
};

export const UserSessionReducers = (
    state : IUserSessionAppState = initialState,
    action : Action
) : IUserSessionAppState => {
    switch (action.type) {
        case ActionTypes.SET_USER_INFO:
            return reduceSetUserInfo(
                state,
                action as ActionInterfaces.ISetUserInfo
            );
        case ActionTypes.SET_PAGE_CONTEXT:
            return reduceSetPageContext(
                state,
                action as ActionInterfaces.ISetPageContext
            );
        case ActionTypes.SET_ACTIVE_TAB:
            return reduceSetActiveTab(
                state,
                action as ActionInterfaces.ISetActiveTab
            );
        case ActionTypes.SET_AVAILABLE_TABS:
            return reduceSetAvailableTabs(
                state,
                action as ActionInterfaces.ISetAvailableTabs
            );
        case ActionTypes.SET_USER_POPDOWN_OPEN:
            return reduceSetUserPopdownOpen(
                state,
                action as ActionInterfaces.ISetUserPopdownOpen
            );
        case ActionTypes.SET_USER_RETAILERS:
            return reduceSetUserRetailers(
                state,
                action as ActionInterfaces.ISetUserRetailers
            );
        default:
            return state;
    }
};
