import { StringValueMap } from 'api/Core/StringValueMap';
import { MassUnit } from 'api/Product/model/MassUnit';
import { PackagingId } from 'api/Product/model/PackagingId';
import { ProductQuantityUnit} from 'api/Product/model/ProductQuantityUnit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export class Conversions {
    private massUnit : MassUnit | null = null;
    private volumeUnit : VolumeUnit | null = null;

    constructor(
        private readonly baseUnit : ProductQuantityUnit,
        private readonly packagingIdConversions : StringValueMap<PackagingId, number>,
        private readonly unitOfMeasureConversions : Map<MassUnit | VolumeUnit, number>,
    ) {
        let massUnit : MassUnit | null = null;
        let volumeUnit : VolumeUnit | null = null;

        Array.from(unitOfMeasureConversions.keys()).forEach((unit) => {
            if (MassUnit.isMassUnit(unit)) {
                if (massUnit) {
                    throw new RuntimeException('unitOfMeasureConversions should contain at most one MassUnit');
                }

                massUnit = unit;
            } else if (VolumeUnit.isVolumeUnit(unit)) {
                if (volumeUnit) {
                    throw new RuntimeException('unitOfMeasureConversions should contain at most one VolumeUnit');
                }

                volumeUnit = unit;
            }
        });

        this.massUnit = massUnit;
        this.volumeUnit = volumeUnit;
    }

    public getBaseUnit() {
        return this.baseUnit;
    }

    public getPackagingIdConversions() {
        return this.packagingIdConversions;
    }

    public getUnitOfMeasureConversions() {
        return this.unitOfMeasureConversions;
    }

    public hasMassUnit() {
        return this.massUnit !== null;
    }

    public hasVolumeUnit() {
        return this.volumeUnit !== null;
    }

    public equals(other : any) : boolean {
        if (!(other instanceof Conversions)) {
            return false;
        }

        if ((this.packagingIdConversions.size !== other.getPackagingIdConversions().size) ||
            (this.unitOfMeasureConversions.size !== other.getUnitOfMeasureConversions().size) ||
            !PackagingUtils.productQuantityUnitsAreEqual(this.baseUnit, other.getBaseUnit())) {
            return false;
        }

        let packagingIdConversionsAreEqual = true;
        this.packagingIdConversions.forEach((value, packagingId) => {
            packagingIdConversionsAreEqual = packagingIdConversionsAreEqual && (value === other.getPackagingIdConversions().get(packagingId));
        });

        let unitOfMeasureConversionsAreEqual = true;
        this.unitOfMeasureConversions.forEach((value, unit) => {
            unitOfMeasureConversionsAreEqual = unitOfMeasureConversionsAreEqual && (value === other.getUnitOfMeasureConversions().get(unit));
        });

        return packagingIdConversionsAreEqual && unitOfMeasureConversionsAreEqual;
    }
}
