import React from 'react';

import { LoadingCover } from 'shared/components/LoadingCover';

import 'shared/css/IntegrationStatusBlock.scss';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { IntegrationStatus } from 'shared/models/IntegrationStatus';

export interface IIntegrationStatusLabel {
    isLoading : boolean;
    integrationStatus : IntegrationStatus;
}

export class IntegrationStatusLabel extends React.Component<IIntegrationStatusLabel, object> {
    public render() {
        const {
            integrationStatus,
            isLoading,
        } = this.props;

        let statusString : string;
        let statusClass : string;
        let tooltipText : string | null;

        switch (integrationStatus) {
            case IntegrationStatus.CONNECTED:
                statusString = 'Connected';
                statusClass = 'connected';
                tooltipText = null;
                break;
            case IntegrationStatus.NEEDS_ATTENTION:
                statusString = 'Needs Attention';
                statusClass = 'needs-attention';
                tooltipText = 'We cannot access your POS with the information you have provided.';
                break;
            default:
                throw new RuntimeException('unexpected integration status: ' + integrationStatus);
        }

        return (
        <button className={ 'integration-status cell ' + statusClass } >
            { isLoading &&
                <LoadingCover
                    className="overlay-light"
                    hasLoadingIcon={ true }
                />
            }
            { tooltipText &&
                <div className="tip-contain">
                    <span className="btn-tooltip top">
                        { tooltipText }
                    </span>
                </div>
            }
            <div className="cell status-string">
                { statusString }
            </div>
        </button>
        );
    }
}
