import React from 'react';
import MediaQuery from 'react-responsive';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { SalesEntry } from 'api/Reports/model/SalesEntry';
import { PosItem } from 'api/SalesData/model/PosItem';
import { PosItemId } from 'api/SalesData/model/PosItemId';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemWithMetadata } from 'api/SalesItem/model/SalesItemWithMetadata';
import { SalesItemMapperGroupByOptionValue } from 'apps/SalesItemMapper/appTypes';
import { SalesItemMapperUtils } from 'apps/SalesItemMapper/utils/SalesItemMapperUtils';
import { MAX_MOBILE_WIDTH, MIN_TABLET_WIDTH } from 'shared/constants';
import { CheckBoxTriState } from 'shared/models/CheckBoxTriState';
import { ISearchBarState } from 'shared/models/ISearchBarState';
import { SortDirection } from 'shared/models/SortDirection';
import { getClickWillCollapseAll, getIsOpenByGroupName, ISortedFilteredAndGroupedResult } from 'shared/utils/sortingFilteringAndGroupingUtils';
import { CollapseAllButton } from '../CollapseAllButton';
import { IOption } from '../Dropdown/DropdownMenu';
import { Flex } from '../FlexLayout/Flex';
import { RowGroupHeader } from '../LightTable/RowGroupHeader';
import { TableControls } from '../LightTable/TableControls';
import { RowOptimizationWrapper } from '../RowOptimizationWrapper/RowOptimizationWrapper';
import { SearchBar, SearchBarTheme } from '../SearchBar/SearchBar';
import { Select2DropdownMenuTheme } from '../Select2Dropdown/GenericSelect2DropdownMenu';
import { Select2DropdownMenu } from '../Select2Dropdown/Select2DropdownMenu';
import { StickyHeaderWrapper } from '../StickyHeaderWrapper/StickyHeaderWrapper';
import { SalesEntryKey, SalesInputRow, SalesInputRowId } from './SalesInputRow';
import { SalesInputTableHeader } from './SalesInputTableHeader';

import { Button } from '../Button';
import { OutsideEventListenerContainer } from '../OutsideEventListenerContainer';
import './SalesInputTable.scss';

interface ISalesInputTable {
    readonly hasBulkSelect : boolean;
    readonly sortedFilteredGroupedResult : ISortedFilteredAndGroupedResult<SalesInputRowId>;
    readonly panelIsOpenByGroupNameFromUserInput : {[groupName : string] : boolean};
    readonly defaultPanelIsOpen : boolean;

    readonly salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>;
    readonly salesBySalesItemId : StringValueMap<SalesItemId, SalesEntry>;
    readonly unmappedSalesByPosItemId : StringValueMap<PosItemId, SalesEntry>;
    readonly posItemsByPosItemId : StringValueMap<PosItemId, PosItem>;

    readonly selectedRowIds : StringValueSet<SalesInputRowId>;
    readonly sortDirection : SortDirection;
    readonly sortByColumnId : string; // should probably move/rename
    readonly groupByOption : SalesItemMapperGroupByOptionValue;
    readonly searchBar : ISearchBarState;

    readonly mobileActionButtons? : JSX.Element; // undefined = use default

    readonly onOpenMappingTool : (rowId : SalesInputRowId) => void;
    readonly onSortChange : (sortBy : string) => void;
    readonly setSearchTerm : (searchTerm : string | null) => void;
    readonly setGroupByOption : (groupByOption : SalesItemMapperGroupByOptionValue) => void;
    readonly onCollapseAllClick : (nextClickWillCollapseAll : boolean) => void;
    readonly setIsSelectedForRowIds : (rowIds : StringValueSet<SalesInputRowId>, isSelected : boolean) => void;
    readonly setPanelIsOpenFromUserInputForGroupNames : (groupNames : Set<string>, isOpen : boolean) => void;
    readonly onUpdateSalesEntryForRowId : ((rowId : SalesInputRowId, key : SalesEntryKey, value : number | null) => Promise<void>) | null; // null for readonly
}

interface IState {
    dropdownIsOpen : boolean;
}

export class SalesInputTable extends React.Component<ISalesInputTable, IState> {
    private salesItemMapperGroupByOptions : Array<IOption>;

    constructor(props : ISalesInputTable) {
        super(props);
        this.state = {
            dropdownIsOpen: false,
        };

        this.salesItemMapperGroupByOptions = [
            SalesItemMapperUtils.getSalesItemMapperGroupByOption('All Items'),
            SalesItemMapperUtils.getSalesItemMapperGroupByOption('Mapping Status'),
            SalesItemMapperUtils.getSalesItemMapperGroupByOption('Menu Group'),
        ];
    }

    public render() {
        const {
            sortedFilteredGroupedResult,
            panelIsOpenByGroupNameFromUserInput,
            defaultPanelIsOpen,
            selectedRowIds,
            sortByColumnId,
            sortDirection,
            onSortChange,
            searchBar,
            setIsSelectedForRowIds,
            hasBulkSelect,
            mobileActionButtons
        } = this.props;

        let concatenatedRowIds : Array<SalesInputRowId> = [];
        sortedFilteredGroupedResult.sortedGroupNamesToDisplay.forEach((groupName : string) => {
            concatenatedRowIds = concatenatedRowIds.concat(sortedFilteredGroupedResult.sortedRowIdsToDisplayByGroupName[groupName]);
        });

        const isOpenByGroupName = getIsOpenByGroupName(
            sortedFilteredGroupedResult.sortedGroupNamesToDisplay,
            sortedFilteredGroupedResult.panelIsOpenByGroupNameFromFiltering,
            panelIsOpenByGroupNameFromUserInput,
            defaultPanelIsOpen);

        const nextClickWillCollapseAll = getClickWillCollapseAll(isOpenByGroupName);

        let selectAllState : CheckBoxTriState;
        const selectedCount = selectedRowIds.size;
        if (selectedCount === 0) {
            selectAllState = CheckBoxTriState.Unchecked;
        } else if (selectedCount === concatenatedRowIds.length) {
            selectAllState = CheckBoxTriState.Checked;
        } else {
            selectAllState = CheckBoxTriState.Indeterminate;
        }

        const onSelectAllClick = (isSelected : boolean) => {
            setIsSelectedForRowIds(new StringValueSet(concatenatedRowIds), isSelected);
        };

        const searchBarComponent = (
            <SearchBar
                theme={ SearchBarTheme.Basic }
                isDisabled={ searchBar.isDisabled }
                placeholderText="Search by name, pos ID, etc."
                value={ searchBar.searchTerm }
                isFocused={ searchBar.isFocused }
                handleBlur={ this.doNothing }
                handleFocus={ null }
                handleEnterClick={ this.doNothing }
                handleOnChange={ this.onSearchTermChange }
                clearSearchBar={ this.onClearSearchTerm }
            />
        );

        const onCollapseAllButtonClick = () => {
            this.props.onCollapseAllClick(nextClickWillCollapseAll);
        };
        return (
            <div className="sales-input-table-container">
                <StickyHeaderWrapper headerContainerClassName="table-control-headers-sticky">
                    <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                        <div className={ `table-controls-header` }>
                            <TableControls>
                                <Flex direction="row" grow={ 1 } shrink={ 0 } basis="auto" inline={ true } className="flex-px-3" align="center" justify="space-between">
                                    <label className="group-by-label">Group by:</label>
                                    { this.getGroupByDropdownMenu(false) }
                                    <div className="flex-child">
                                        { searchBarComponent }
                                    </div>
                                </Flex>
                                <Flex direction="row" grow={ 1 } shrink={ 1 } basis="auto" inline={ true } justify="end" className="table-controls-secondary flex-px-2">
                                    <CollapseAllButton
                                        nextClickWillCollapseAll={ nextClickWillCollapseAll }
                                        onClick={ onCollapseAllButtonClick }
                                    />
                                </Flex>
                            </TableControls>
                        </div>
                    </MediaQuery>
                    <MediaQuery maxWidth={ MAX_MOBILE_WIDTH }>
                        <Flex direction="row" grow={ 1 } className="flex-px-2 flex-pt-1 mobile-header" align="center">
                            <div className="flex-child">
                                { searchBarComponent }
                            </div>
                        </Flex>
                    </MediaQuery>
                </StickyHeaderWrapper>
                <MediaQuery maxWidth={ MAX_MOBILE_WIDTH }>
                    <Flex direction="row" className="flex-px-2 flex-pb-1 mobile-header">
                        <Flex direction="row" inline={ true }>
                            { this.state.dropdownIsOpen &&
                                <OutsideEventListenerContainer onOutsideClick={ this.onCloseMobileDropdown }>
                                    { this.getGroupByDropdownMenu(true) }
                                </OutsideEventListenerContainer>
                            }
                            <Button
                                buttonClassName="flat primary icon"
                                isDisabled={ false }
                                isLoading={ false }
                                onClick={ this.onOpenMobileDropdown }
                            >
                                <span className="bevicon bevico-filter main-icon-left"/>
                            </Button>
                            { mobileActionButtons }
                        </Flex>
                        <Flex direction="row" inline={ true } justify="end" className="table-controls-secondary">
                            <CollapseAllButton
                                nextClickWillCollapseAll={ nextClickWillCollapseAll }
                                onClick={ onCollapseAllButtonClick }
                            />
                        </Flex>
                    </Flex>
                </MediaQuery>
                <div className="sales-input-table">
                    <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                        <StickyHeaderWrapper>
                            <SalesInputTableHeader
                                selectAllState={ selectAllState }
                                onToggleSelect={ onSelectAllClick }
                                onSortChange={ onSortChange }
                                sorting={ {
                                    sortedBy: sortByColumnId,
                                    direction: sortDirection
                                } }
                                hasCheckbox={ hasBulkSelect }
                            />
                        </StickyHeaderWrapper>
                    </MediaQuery>
                    { this.getGroupPanels(isOpenByGroupName) }
                </div>
            </div>
        );
    }

    private readonly getGroupPanels = (
        isOpenByGroupName : {[groupName : string] : boolean},
    ) : Array<JSX.Element> => {
        const {
            sortedFilteredGroupedResult,
            salesBySalesItemId,
            hasBulkSelect,
            salesItemsById,
            selectedRowIds,
            onOpenMappingTool,
            setIsSelectedForRowIds,
            setPanelIsOpenFromUserInputForGroupNames,
            posItemsByPosItemId,
            unmappedSalesByPosItemId,
            onUpdateSalesEntryForRowId
        } = this.props;

        const visibleRowCount = Object.keys(sortedFilteredGroupedResult.sortedRowIdsToDisplayByGroupName)
        .reduce((count : number, groupName : string) => sortedFilteredGroupedResult.sortedRowIdsToDisplayByGroupName[groupName].length + count, 0);
        if (visibleRowCount === 0) {
            return [
                (
                    <div key={ 0 } className="empty-table transition">
                        <h4>All items filtered from view</h4>
                        <p>Clear your search or try a different term.</p>
                    </div>
                )
            ];
        }

        const groupPanels : Array<JSX.Element> = [];
        sortedFilteredGroupedResult.sortedGroupNamesToDisplay.forEach((groupName : string) => {
            const rowIds = sortedFilteredGroupedResult.sortedRowIdsToDisplayByGroupName[groupName];
            const rowFactory = (index : number) => {
                if (rowIds.length === 0) {
                    return <div/>; // for this table, we want to show empty groups
                }

                const rowId = rowIds[index];

                const rowInfo = SalesItemMapperUtils.getInfoFromSalesInputRowId(
                    rowId,
                    salesItemsById,
                    salesBySalesItemId,
                    unmappedSalesByPosItemId,
                    posItemsByPosItemId
                );

                const isSelected = selectedRowIds.has(rowId);

                return (
                    <SalesInputRow
                        className={ `${ index % 2 ? '' : 'sales-input-row-odd' }` }
                        key={ rowId.getValue() }
                        rowId={ rowId }
                        name={ rowInfo.name }
                        posId={ rowInfo.posId }
                        salesPrice={ rowInfo.salesPrice }
                        isFlagged={ rowInfo.isFlagged }
                        mappedStatus={ rowInfo.mappedStatus }
                        hasBulkSelect={ hasBulkSelect }
                        salesEntry={ rowInfo.salesEntry }
                        isSelected={ isSelected }
                        onSetRowCheckboxSelected={ this.onSetRowCheckboxSelected }
                        onEditItemClick={ onOpenMappingTool }
                        onUpdateSalesEntryForRowId={ onUpdateSalesEntryForRowId }
                    />
                );
            };

            let rowContainer : HTMLDivElement;
            const containerBodyRefFunction = (containerBody : HTMLDivElement) => {
                rowContainer = containerBody;
            };

            const getRowContainer = () => {
                return rowContainer;
            };

            const onGroupHeaderClick = () => {
                setPanelIsOpenFromUserInputForGroupNames(new Set([groupName]), !isOpen);
            };

            const onSelectGroupClick = () => {
                setIsSelectedForRowIds(new StringValueSet(rowIds), selectAllState !== CheckBoxTriState.Checked);
            };

            const isOpen = isOpenByGroupName[groupName];
            const numRows = rowIds.length;
            const totalUnfilteredCount = sortedFilteredGroupedResult.unfilteredSortedRowIdsByGroupName[groupName].length;

            let selectedCount = 0;
            rowIds.forEach((rowId) => {
                if (selectedRowIds.has(rowId)) {
                    selectedCount += 1;
                }
            });

            let selectAllState : CheckBoxTriState;
            if (selectedCount === 0) {
                selectAllState = CheckBoxTriState.Unchecked;
            } else if (selectedCount === rowIds.length) {
                selectAllState = CheckBoxTriState.Checked;
            } else {
                selectAllState = CheckBoxTriState.Indeterminate;
            }

            groupPanels.push(
                <div className="light-table-group-container" key={ groupName }>
                    <StickyHeaderWrapper>
                        <div className="group-header">
                            <RowGroupHeader
                                selectAllState={ hasBulkSelect ? selectAllState : null }
                                onSelectAllClick={ onSelectGroupClick }
                                groupName={ groupName }
                                isOpen={ isOpen }
                                visibleItemCount={ rowIds.length }
                                totalItemCount={ totalUnfilteredCount }
                                onClick={ onGroupHeaderClick }
                                showExpandArrow={ true }
                            />
                        </div>
                    </StickyHeaderWrapper>
                    <div className="group-container-body" ref={ containerBodyRefFunction }>
                        <RowOptimizationWrapper
                            numRows={ numRows }
                            rowFactory={ rowFactory }
                            getRowContainer={ getRowContainer }
                            isOpen={ isOpen }
                        />
                    </div>
                </div>
            );
        });

        return groupPanels;
    }

    private readonly getGroupByDropdownMenu = (isOpenDefault : boolean) => {
        return (
            <Select2DropdownMenu
                onOptionSelect={ this.setGroupByOption }
                sortedOptionsAndLabelName={ [[null, this.salesItemMapperGroupByOptions]] }
                selectedOption={ SalesItemMapperUtils.getSalesItemMapperGroupByOption(this.props.groupByOption) }
                placeholderText={ null }
                hasCreateCustomOption={ false }
                createCustomOption={ this.doNothing }
                buttonShouldDisplaySelectedLabel={ true }
                emptyState={ null }
                isSearchable={ false }
                selectedOptionHeaderShown={ true }
                customOptionGroupLabel={ null }
                theme={ Select2DropdownMenuTheme.Basic }
                isOpenDefault={ isOpenDefault }
                createCustomOptionButtonLabel={ null }
            />
        );
    }

    private readonly onSearchTermChange = (searchTerm : string) => {
        this.props.setSearchTerm(searchTerm);
    }
    private readonly onClearSearchTerm = () => {
        this.props.setSearchTerm(null);
    }

    private readonly setGroupByOption = (groupByOption : string) => {
        this.props.setGroupByOption(groupByOption as SalesItemMapperGroupByOptionValue);
        this.onCloseMobileDropdown();
    }

    private readonly doNothing = () => undefined;

    private readonly onSetRowCheckboxSelected = (rowId : SalesInputRowId, isSelected : boolean) => {
        this.props.setIsSelectedForRowIds(new StringValueSet([rowId]), isSelected);
    }

    private readonly onOpenMobileDropdown = (event : Event) => {
        this.setState({ dropdownIsOpen: true });
        event.stopPropagation();
    }

    private readonly onCloseMobileDropdown = () => {
        this.setState({ dropdownIsOpen: false });
    }
}
