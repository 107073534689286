//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


import Thrift from 'thrift';
import CoreApiExceptions from './core_api_Exceptions_types';
import UnauthenticatedUserSessionModel from './unauthenticated_user_session_Model_types';
import UnauthenticatedUserSessionExceptions from './unauthenticated_user_session_Exceptions_types';
import UserAccountsModel from './accounts_user_Model_types';
import AccountsExceptions from './accounts_Exceptions_types';
import PublicContentModel from './public_content_Model_types';
import PublicContentExceptions from './public_content_Exceptions_types';

var PublicContentViewEventService = {};
export default PublicContentViewEventService;
//HELPER FUNCTIONS AND STRUCTURES

PublicContentViewEventService.PublicContentViewEventService_createPublicContentViewEventForUnauthenticatedSession_args = function(args) {
  this.unauthenticatedUserSessionId = null;
  this.eventType = null;
  this.publicContentUrl = null;
  this.publicContentUrlParameters = null;
  this.referrer = null;
  if (args) {
    if (args.unauthenticatedUserSessionId !== undefined && args.unauthenticatedUserSessionId !== null) {
      this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId(args.unauthenticatedUserSessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field unauthenticatedUserSessionId is unset!');
    }
    if (args.eventType !== undefined && args.eventType !== null) {
      this.eventType = args.eventType;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field eventType is unset!');
    }
    if (args.publicContentUrl !== undefined && args.publicContentUrl !== null) {
      this.publicContentUrl = new PublicContentModel.PublicContentUrl(args.publicContentUrl);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field publicContentUrl is unset!');
    }
    if (args.publicContentUrlParameters !== undefined && args.publicContentUrlParameters !== null) {
      this.publicContentUrlParameters = Thrift.copyMap(args.publicContentUrlParameters, [Thrift.copyList, null]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field publicContentUrlParameters is unset!');
    }
    if (args.referrer !== undefined && args.referrer !== null) {
      this.referrer = args.referrer;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field referrer is unset!');
    }
  }
};
PublicContentViewEventService.PublicContentViewEventService_createPublicContentViewEventForUnauthenticatedSession_args.prototype = {};
PublicContentViewEventService.PublicContentViewEventService_createPublicContentViewEventForUnauthenticatedSession_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId();
        this.unauthenticatedUserSessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.eventType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.publicContentUrl = new PublicContentModel.PublicContentUrl();
        this.publicContentUrl.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.MAP) {
        var _size0 = 0;
        var _rtmp34;
        this.publicContentUrlParameters = {};
        var _ktype1 = 0;
        var _vtype2 = 0;
        _rtmp34 = input.readMapBegin();
        _ktype1 = _rtmp34.ktype;
        _vtype2 = _rtmp34.vtype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          if (_i5 > 0 ) {
            if (input.rstack.length > input.rpos[input.rpos.length -1] + 1) {
              input.rstack.pop();
            }
          }
          var key6 = null;
          var val7 = null;
          key6 = input.readString().value;
          var _size8 = 0;
          var _rtmp312;
          val7 = [];
          var _etype11 = 0;
          _rtmp312 = input.readListBegin();
          _etype11 = _rtmp312.etype;
          _size8 = _rtmp312.size;
          for (var _i13 = 0; _i13 < _size8; ++_i13)
          {
            var elem14 = null;
            elem14 = input.readString().value;
            val7.push(elem14);
          }
          input.readListEnd();
          this.publicContentUrlParameters[key6] = val7;
        }
        input.readMapEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.referrer = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PublicContentViewEventService.PublicContentViewEventService_createPublicContentViewEventForUnauthenticatedSession_args.prototype.write = function(output) {
  output.writeStructBegin('PublicContentViewEventService_createPublicContentViewEventForUnauthenticatedSession_args');
  if (this.unauthenticatedUserSessionId !== null && this.unauthenticatedUserSessionId !== undefined) {
    output.writeFieldBegin('unauthenticatedUserSessionId', Thrift.Type.STRUCT, 1);
    this.unauthenticatedUserSessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.eventType !== null && this.eventType !== undefined) {
    output.writeFieldBegin('eventType', Thrift.Type.I32, 2);
    output.writeI32(this.eventType);
    output.writeFieldEnd();
  }
  if (this.publicContentUrl !== null && this.publicContentUrl !== undefined) {
    output.writeFieldBegin('publicContentUrl', Thrift.Type.STRUCT, 3);
    this.publicContentUrl.write(output);
    output.writeFieldEnd();
  }
  if (this.publicContentUrlParameters !== null && this.publicContentUrlParameters !== undefined) {
    output.writeFieldBegin('publicContentUrlParameters', Thrift.Type.MAP, 4);
    output.writeMapBegin(Thrift.Type.STRING, Thrift.Type.LIST, Thrift.objectLength(this.publicContentUrlParameters));
    for (var kiter15 in this.publicContentUrlParameters)
    {
      if (this.publicContentUrlParameters.hasOwnProperty(kiter15))
      {
        var viter16 = this.publicContentUrlParameters[kiter15];
        output.writeString(kiter15);
        output.writeListBegin(Thrift.Type.STRING, viter16.length);
        for (var iter17 in viter16)
        {
          if (viter16.hasOwnProperty(iter17))
          {
            iter17 = viter16[iter17];
            output.writeString(iter17);
          }
        }
        output.writeListEnd();
      }
    }
    output.writeMapEnd();
    output.writeFieldEnd();
  }
  if (this.referrer !== null && this.referrer !== undefined) {
    output.writeFieldBegin('referrer', Thrift.Type.STRING, 5);
    output.writeString(this.referrer);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PublicContentViewEventService.PublicContentViewEventService_createPublicContentViewEventForUnauthenticatedSession_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownUnauthenticatedUserSessionIdException = null;
  this.invalidArgumentsToCreateViewEventException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException) {
    this.unknownUnauthenticatedUserSessionIdException = args;
    return;
  }
  if (args instanceof PublicContentExceptions.InvalidArgumentsToCreateViewEventException) {
    this.invalidArgumentsToCreateViewEventException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownUnauthenticatedUserSessionIdException !== undefined && args.unknownUnauthenticatedUserSessionIdException !== null) {
      this.unknownUnauthenticatedUserSessionIdException = args.unknownUnauthenticatedUserSessionIdException;
    }
    if (args.invalidArgumentsToCreateViewEventException !== undefined && args.invalidArgumentsToCreateViewEventException !== null) {
      this.invalidArgumentsToCreateViewEventException = args.invalidArgumentsToCreateViewEventException;
    }
  }
};
PublicContentViewEventService.PublicContentViewEventService_createPublicContentViewEventForUnauthenticatedSession_result.prototype = {};
PublicContentViewEventService.PublicContentViewEventService_createPublicContentViewEventForUnauthenticatedSession_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownUnauthenticatedUserSessionIdException = new UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException();
        this.unknownUnauthenticatedUserSessionIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalidArgumentsToCreateViewEventException = new PublicContentExceptions.InvalidArgumentsToCreateViewEventException();
        this.invalidArgumentsToCreateViewEventException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PublicContentViewEventService.PublicContentViewEventService_createPublicContentViewEventForUnauthenticatedSession_result.prototype.write = function(output) {
  output.writeStructBegin('PublicContentViewEventService_createPublicContentViewEventForUnauthenticatedSession_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownUnauthenticatedUserSessionIdException !== null && this.unknownUnauthenticatedUserSessionIdException !== undefined) {
    output.writeFieldBegin('unknownUnauthenticatedUserSessionIdException', Thrift.Type.STRUCT, 3);
    this.unknownUnauthenticatedUserSessionIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.invalidArgumentsToCreateViewEventException !== null && this.invalidArgumentsToCreateViewEventException !== undefined) {
    output.writeFieldBegin('invalidArgumentsToCreateViewEventException', Thrift.Type.STRUCT, 4);
    this.invalidArgumentsToCreateViewEventException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PublicContentViewEventService.PublicContentViewEventService_createPublicContentViewEventForAuthenticatedSession_args = function(args) {
  this.sessionId = null;
  this.eventType = null;
  this.publicContentUrl = null;
  this.publicContentUrlParameters = null;
  this.referrer = null;
  if (args) {
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = new UserAccountsModel.UserSessionIdentifier(args.sessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionId is unset!');
    }
    if (args.eventType !== undefined && args.eventType !== null) {
      this.eventType = args.eventType;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field eventType is unset!');
    }
    if (args.publicContentUrl !== undefined && args.publicContentUrl !== null) {
      this.publicContentUrl = new PublicContentModel.PublicContentUrl(args.publicContentUrl);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field publicContentUrl is unset!');
    }
    if (args.publicContentUrlParameters !== undefined && args.publicContentUrlParameters !== null) {
      this.publicContentUrlParameters = Thrift.copyMap(args.publicContentUrlParameters, [Thrift.copyList, null]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field publicContentUrlParameters is unset!');
    }
    if (args.referrer !== undefined && args.referrer !== null) {
      this.referrer = args.referrer;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field referrer is unset!');
    }
  }
};
PublicContentViewEventService.PublicContentViewEventService_createPublicContentViewEventForAuthenticatedSession_args.prototype = {};
PublicContentViewEventService.PublicContentViewEventService_createPublicContentViewEventForAuthenticatedSession_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionId = new UserAccountsModel.UserSessionIdentifier();
        this.sessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.eventType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.publicContentUrl = new PublicContentModel.PublicContentUrl();
        this.publicContentUrl.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.MAP) {
        var _size18 = 0;
        var _rtmp322;
        this.publicContentUrlParameters = {};
        var _ktype19 = 0;
        var _vtype20 = 0;
        _rtmp322 = input.readMapBegin();
        _ktype19 = _rtmp322.ktype;
        _vtype20 = _rtmp322.vtype;
        _size18 = _rtmp322.size;
        for (var _i23 = 0; _i23 < _size18; ++_i23)
        {
          if (_i23 > 0 ) {
            if (input.rstack.length > input.rpos[input.rpos.length -1] + 1) {
              input.rstack.pop();
            }
          }
          var key24 = null;
          var val25 = null;
          key24 = input.readString().value;
          var _size26 = 0;
          var _rtmp330;
          val25 = [];
          var _etype29 = 0;
          _rtmp330 = input.readListBegin();
          _etype29 = _rtmp330.etype;
          _size26 = _rtmp330.size;
          for (var _i31 = 0; _i31 < _size26; ++_i31)
          {
            var elem32 = null;
            elem32 = input.readString().value;
            val25.push(elem32);
          }
          input.readListEnd();
          this.publicContentUrlParameters[key24] = val25;
        }
        input.readMapEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.referrer = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PublicContentViewEventService.PublicContentViewEventService_createPublicContentViewEventForAuthenticatedSession_args.prototype.write = function(output) {
  output.writeStructBegin('PublicContentViewEventService_createPublicContentViewEventForAuthenticatedSession_args');
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRUCT, 1);
    this.sessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.eventType !== null && this.eventType !== undefined) {
    output.writeFieldBegin('eventType', Thrift.Type.I32, 2);
    output.writeI32(this.eventType);
    output.writeFieldEnd();
  }
  if (this.publicContentUrl !== null && this.publicContentUrl !== undefined) {
    output.writeFieldBegin('publicContentUrl', Thrift.Type.STRUCT, 3);
    this.publicContentUrl.write(output);
    output.writeFieldEnd();
  }
  if (this.publicContentUrlParameters !== null && this.publicContentUrlParameters !== undefined) {
    output.writeFieldBegin('publicContentUrlParameters', Thrift.Type.MAP, 4);
    output.writeMapBegin(Thrift.Type.STRING, Thrift.Type.LIST, Thrift.objectLength(this.publicContentUrlParameters));
    for (var kiter33 in this.publicContentUrlParameters)
    {
      if (this.publicContentUrlParameters.hasOwnProperty(kiter33))
      {
        var viter34 = this.publicContentUrlParameters[kiter33];
        output.writeString(kiter33);
        output.writeListBegin(Thrift.Type.STRING, viter34.length);
        for (var iter35 in viter34)
        {
          if (viter34.hasOwnProperty(iter35))
          {
            iter35 = viter34[iter35];
            output.writeString(iter35);
          }
        }
        output.writeListEnd();
      }
    }
    output.writeMapEnd();
    output.writeFieldEnd();
  }
  if (this.referrer !== null && this.referrer !== undefined) {
    output.writeFieldBegin('referrer', Thrift.Type.STRING, 5);
    output.writeString(this.referrer);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PublicContentViewEventService.PublicContentViewEventService_createPublicContentViewEventForAuthenticatedSession_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.invalidArgumentsToCreateViewEventException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof PublicContentExceptions.InvalidArgumentsToCreateViewEventException) {
    this.invalidArgumentsToCreateViewEventException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.invalidArgumentsToCreateViewEventException !== undefined && args.invalidArgumentsToCreateViewEventException !== null) {
      this.invalidArgumentsToCreateViewEventException = args.invalidArgumentsToCreateViewEventException;
    }
  }
};
PublicContentViewEventService.PublicContentViewEventService_createPublicContentViewEventForAuthenticatedSession_result.prototype = {};
PublicContentViewEventService.PublicContentViewEventService_createPublicContentViewEventForAuthenticatedSession_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalidArgumentsToCreateViewEventException = new PublicContentExceptions.InvalidArgumentsToCreateViewEventException();
        this.invalidArgumentsToCreateViewEventException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PublicContentViewEventService.PublicContentViewEventService_createPublicContentViewEventForAuthenticatedSession_result.prototype.write = function(output) {
  output.writeStructBegin('PublicContentViewEventService_createPublicContentViewEventForAuthenticatedSession_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.invalidArgumentsToCreateViewEventException !== null && this.invalidArgumentsToCreateViewEventException !== undefined) {
    output.writeFieldBegin('invalidArgumentsToCreateViewEventException', Thrift.Type.STRUCT, 4);
    this.invalidArgumentsToCreateViewEventException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PublicContentViewEventService.PublicContentViewEventService_getUserPublicContentForAuthenticatedSession_args = function(args) {
  this.sessionId = null;
  this.publicContentType = null;
  if (args) {
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = new UserAccountsModel.UserSessionIdentifier(args.sessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionId is unset!');
    }
    if (args.publicContentType !== undefined && args.publicContentType !== null) {
      this.publicContentType = args.publicContentType;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field publicContentType is unset!');
    }
  }
};
PublicContentViewEventService.PublicContentViewEventService_getUserPublicContentForAuthenticatedSession_args.prototype = {};
PublicContentViewEventService.PublicContentViewEventService_getUserPublicContentForAuthenticatedSession_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionId = new UserAccountsModel.UserSessionIdentifier();
        this.sessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.publicContentType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PublicContentViewEventService.PublicContentViewEventService_getUserPublicContentForAuthenticatedSession_args.prototype.write = function(output) {
  output.writeStructBegin('PublicContentViewEventService_getUserPublicContentForAuthenticatedSession_args');
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRUCT, 1);
    this.sessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.publicContentType !== null && this.publicContentType !== undefined) {
    output.writeFieldBegin('publicContentType', Thrift.Type.I32, 2);
    output.writeI32(this.publicContentType);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PublicContentViewEventService.PublicContentViewEventService_getUserPublicContentForAuthenticatedSession_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.invalidArgumentAsPublicContentTypeException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof PublicContentExceptions.InvalidPublicContentTypeArgumentException) {
    this.invalidArgumentAsPublicContentTypeException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new PublicContentModel.UserPublicContent(args.success);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.invalidArgumentAsPublicContentTypeException !== undefined && args.invalidArgumentAsPublicContentTypeException !== null) {
      this.invalidArgumentAsPublicContentTypeException = args.invalidArgumentAsPublicContentTypeException;
    }
  }
};
PublicContentViewEventService.PublicContentViewEventService_getUserPublicContentForAuthenticatedSession_result.prototype = {};
PublicContentViewEventService.PublicContentViewEventService_getUserPublicContentForAuthenticatedSession_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new PublicContentModel.UserPublicContent();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalidArgumentAsPublicContentTypeException = new PublicContentExceptions.InvalidPublicContentTypeArgumentException();
        this.invalidArgumentAsPublicContentTypeException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PublicContentViewEventService.PublicContentViewEventService_getUserPublicContentForAuthenticatedSession_result.prototype.write = function(output) {
  output.writeStructBegin('PublicContentViewEventService_getUserPublicContentForAuthenticatedSession_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.invalidArgumentAsPublicContentTypeException !== null && this.invalidArgumentAsPublicContentTypeException !== undefined) {
    output.writeFieldBegin('invalidArgumentAsPublicContentTypeException', Thrift.Type.STRUCT, 4);
    this.invalidArgumentAsPublicContentTypeException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PublicContentViewEventService.PublicContentViewEventServiceClient = function(input, output) {
    this.input = input;
    this.output = (!output) ? input : output;
    this.seqid = 0;
};
PublicContentViewEventService.PublicContentViewEventServiceClient.prototype = {};
PublicContentViewEventService.PublicContentViewEventServiceClient.prototype.createPublicContentViewEventForUnauthenticatedSession = function(unauthenticatedUserSessionId, eventType, publicContentUrl, publicContentUrlParameters, referrer, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_createPublicContentViewEventForUnauthenticatedSession(pendingError, unauthenticatedUserSessionId, eventType, publicContentUrl, publicContentUrlParameters, referrer, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_createPublicContentViewEventForUnauthenticatedSession();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

PublicContentViewEventService.PublicContentViewEventServiceClient.prototype.send_createPublicContentViewEventForUnauthenticatedSession = function(pendingError, unauthenticatedUserSessionId, eventType, publicContentUrl, publicContentUrlParameters, referrer, callback) {
  this.output.writeMessageBegin('createPublicContentViewEventForUnauthenticatedSession', Thrift.MessageType.CALL, this.seqid);
  var args = new PublicContentViewEventService.PublicContentViewEventService_createPublicContentViewEventForUnauthenticatedSession_args();
  args.unauthenticatedUserSessionId = unauthenticatedUserSessionId;
  args.eventType = eventType;
  args.publicContentUrl = publicContentUrl;
  args.publicContentUrlParameters = publicContentUrlParameters;
  args.referrer = referrer;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_createPublicContentViewEventForUnauthenticatedSession() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

PublicContentViewEventService.PublicContentViewEventServiceClient.prototype.recv_createPublicContentViewEventForUnauthenticatedSession = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new PublicContentViewEventService.PublicContentViewEventService_createPublicContentViewEventForUnauthenticatedSession_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownUnauthenticatedUserSessionIdException) {
    throw result.unknownUnauthenticatedUserSessionIdException;
  }
  if (null !== result.invalidArgumentsToCreateViewEventException) {
    throw result.invalidArgumentsToCreateViewEventException;
  }
  return;
};
PublicContentViewEventService.PublicContentViewEventServiceClient.prototype.createPublicContentViewEventForAuthenticatedSession = function(sessionId, eventType, publicContentUrl, publicContentUrlParameters, referrer, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_createPublicContentViewEventForAuthenticatedSession(pendingError, sessionId, eventType, publicContentUrl, publicContentUrlParameters, referrer, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_createPublicContentViewEventForAuthenticatedSession();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

PublicContentViewEventService.PublicContentViewEventServiceClient.prototype.send_createPublicContentViewEventForAuthenticatedSession = function(pendingError, sessionId, eventType, publicContentUrl, publicContentUrlParameters, referrer, callback) {
  this.output.writeMessageBegin('createPublicContentViewEventForAuthenticatedSession', Thrift.MessageType.CALL, this.seqid);
  var args = new PublicContentViewEventService.PublicContentViewEventService_createPublicContentViewEventForAuthenticatedSession_args();
  args.sessionId = sessionId;
  args.eventType = eventType;
  args.publicContentUrl = publicContentUrl;
  args.publicContentUrlParameters = publicContentUrlParameters;
  args.referrer = referrer;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_createPublicContentViewEventForAuthenticatedSession() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

PublicContentViewEventService.PublicContentViewEventServiceClient.prototype.recv_createPublicContentViewEventForAuthenticatedSession = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new PublicContentViewEventService.PublicContentViewEventService_createPublicContentViewEventForAuthenticatedSession_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.invalidArgumentsToCreateViewEventException) {
    throw result.invalidArgumentsToCreateViewEventException;
  }
  return;
};
PublicContentViewEventService.PublicContentViewEventServiceClient.prototype.getUserPublicContentForAuthenticatedSession = function(sessionId, publicContentType, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getUserPublicContentForAuthenticatedSession(pendingError, sessionId, publicContentType, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getUserPublicContentForAuthenticatedSession();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

PublicContentViewEventService.PublicContentViewEventServiceClient.prototype.send_getUserPublicContentForAuthenticatedSession = function(pendingError, sessionId, publicContentType, callback) {
  this.output.writeMessageBegin('getUserPublicContentForAuthenticatedSession', Thrift.MessageType.CALL, this.seqid);
  var args = new PublicContentViewEventService.PublicContentViewEventService_getUserPublicContentForAuthenticatedSession_args();
  args.sessionId = sessionId;
  args.publicContentType = publicContentType;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getUserPublicContentForAuthenticatedSession() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

PublicContentViewEventService.PublicContentViewEventServiceClient.prototype.recv_getUserPublicContentForAuthenticatedSession = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new PublicContentViewEventService.PublicContentViewEventService_getUserPublicContentForAuthenticatedSession_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.invalidArgumentAsPublicContentTypeException) {
    throw result.invalidArgumentAsPublicContentTypeException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getUserPublicContentForAuthenticatedSession failed: unknown result';
};
