import { PublicContentAndPublicContentViewEvent } from './PublicContentAndPublicContentViewEvent';

export class UserPublicContent {
    private recentlyViewed : Array<PublicContentAndPublicContentViewEvent>;
    private recommended : Array<PublicContentAndPublicContentViewEvent>;

    constructor(
        recentlyViewed : Array<PublicContentAndPublicContentViewEvent>,
        recommended : Array<PublicContentAndPublicContentViewEvent>,
    ) {
        this.recentlyViewed = recentlyViewed;
        this.recommended = recommended;
    }

    public getRecentlyViewed() : Array<PublicContentAndPublicContentViewEvent> {
        return this.recentlyViewed;
    }

    public getRecommended() : Array<PublicContentAndPublicContentViewEvent> {
        return this.recommended;
    }
}
