import moment from 'moment-timezone';

import { FreeTrialType } from 'api/Location/model/FreeTrialType';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

type ActionItemNames = 'inventories' | 'orders' | 'salesItems';

export type IMaxActionItemCountByName = { [action in ActionItemNames] : number | null };
export type IActionExpiredByName = { [action in ActionItemNames] : boolean };

// TODO This is not yet a complete FreeTrialStatus model.
// It just captures the existing use cases
export class FreeTrialStatus {

    constructor (
        private readonly isInFreeTrial : boolean,
        private readonly freeTrialType : FreeTrialType,
        private readonly endDate : moment.Moment | null,
        private readonly maxActionItemCountByName : IMaxActionItemCountByName,
        private readonly currentActionItemCountByName : { [action in ActionItemNames] : number },
    ) {
        if (maxActionItemCountByName === null || currentActionItemCountByName === null) {
            throw new RuntimeException('maxActionItemCountByName and currentActionItemCountByName must be set for action-based free trials');
        }

        if (isInFreeTrial && freeTrialType === FreeTrialType.ACTION) {
            if (endDate !== null) {
                throw new RuntimeException('endDate must be null for action-based free trials');
            }
        }
        if (isInFreeTrial && freeTrialType === FreeTrialType.TIME) {
            if (endDate === null) {
                throw new RuntimeException('endDate must be set for time-based free trials');
            }
        }
    }

    public getIsInFreeTrial() : boolean {
        return this.isInFreeTrial;
    }

    public getFreeTrialType() : FreeTrialType {
        return this.freeTrialType;
    }

    public getEndDate() : moment.Moment | null {
        return this.endDate;
    }

    public getMaxActionItemCountByName() : IMaxActionItemCountByName {
        return this.maxActionItemCountByName;
    }

    public getCurrentActionItemCountByName() {
        return this.currentActionItemCountByName;
    }

    // TODO Move to Utils file
    public getIsFreeTrialExpired() : boolean {
        if (!this.isInFreeTrial) {
            return false;
        }

        if (this.freeTrialType === FreeTrialType.TIME) {
            if (this.endDate === null) {
                throw new RuntimeException('unexpected');
            }

            return this.endDate.isBefore(moment.utc());
        } else if (this.freeTrialType === FreeTrialType.ACTION) {
            return (this.maxActionItemCountByName.inventories !== null && (this.maxActionItemCountByName.inventories <= this.currentActionItemCountByName.inventories)) &&
                   (this.maxActionItemCountByName.orders !== null && (this.maxActionItemCountByName.orders <= this.currentActionItemCountByName.orders)) &&
                   (this.maxActionItemCountByName.salesItems !== null && (this.maxActionItemCountByName.salesItems <= this.currentActionItemCountByName.salesItems));

        } else {
            throw new RuntimeException('unhandled free trial type: ' + this.freeTrialType);
        }
    }

    // TODO Move to Utils file
    public getNumberOfTrialActionsRemaining() : IMaxActionItemCountByName {
        return {
            inventories: (this.maxActionItemCountByName.inventories === null) ? null : Math.max(0, this.maxActionItemCountByName.inventories - this.currentActionItemCountByName.inventories),
            orders: (this.maxActionItemCountByName.orders === null) ? null : Math.max(0, this.maxActionItemCountByName.orders - this.currentActionItemCountByName.orders),
            salesItems: (this.maxActionItemCountByName.salesItems === null) ? null : Math.max(0, this.maxActionItemCountByName.salesItems - this.currentActionItemCountByName.salesItems),
        };
    }

    public getIsExpiredByAction() : IActionExpiredByName {

        if (this.getIsFreeTrialExpired()) { // Check this for time based trials
            return {
                inventories: true,
                orders: true,
                salesItems: true,
            };
        }

        const numRemaining = this.getNumberOfTrialActionsRemaining();

        return {
            inventories: numRemaining.inventories === 0,
            orders: numRemaining.orders === 0,
            salesItems: numRemaining.salesItems === 0,
        };
    }
}
