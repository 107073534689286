import WebViewer, { Core, WebViewerInstance } from '@pdftron/webviewer';
import { StringValueMap } from 'api/Core/StringValueMap';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemWithMetadata } from 'api/SalesItem/model/SalesItemWithMetadata';
import { SalesItemDisplayUtils } from 'api/SalesItem/utils/SalesItemDisplayUtils';
import { DEFAULT_SERVING_AND_YIELD_UNIT } from 'apps/CreateOrEditSalesItem/reducers/reducers';
import * as React from 'react';
import { Button } from 'shared/components/Button';
import { LoadingCover } from 'shared/components/LoadingCover';
import { SalesInputRowId } from 'shared/components/SalesInputTable/SalesInputRow';
import { numberUtils } from 'shared/utils/numberUtils';
import '../css/RecipePdfRenderer.scss';
import { Observer } from 'shared/utils/observer';

interface IRecipePdfRendererProps {
    isHidden : boolean;     // keep component mounted to avoid reloading WebView library
    isLoading : boolean;
    salesItemId : SalesInputRowId | null;
    productsById : StringValueMap<ProductId, Product> | null;
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata> | null;
    imageUploadButton : JSX.Element;
    imageUploadUrl : string | null;
    onHide : () => any;
    onReset : () => any;
}

interface IRecipePdfRendererState {
    isRendering : boolean;
}

interface IRecipeIngredientTemplateData extends Core.TemplateData {
    qty : string;
    unit: string;
    name: string;
    category: string;
}

interface IRecipeTemplateData extends Core.TemplateData {
    name : string;
    menu_group : string;
    yield_size : string;
    serving_size : string;
    notes: string;
    ingredients : Array<IRecipeIngredientTemplateData>;
    // @ts-ignore
    image?: Core.TemplateDataImage;
}

export class RecipePdfRenderer extends React.PureComponent<IRecipePdfRendererProps, IRecipePdfRendererState> {
    // supports a single template only
    private static TEMPLATE_PATH : string = "/static/recipe_pdf_template.docx";

    // key is obfuscated - no way to hide completely on client
    private static ENCODED_LICENCE_KEY = "QmV2U3BvdCwgSW5jLiAoYmV2c3BvdC5jb20pOk9FTTpCRVZTUE9UOjpCKzpBTVMoMjAyMzA2MDkpOjkzQTU2MTUyMDQ5NzA2MEFGMzYwQjEzQUU5ODI1MzcxNjA2MTdGQzlCQjM0RkQ0NUJCMTRFQ0MyQzdERDMwRDI0NEIyQjZGNUM3";

    private container : HTMLDivElement | undefined;
    private webViewerInstance : WebViewerInstance | undefined;
    private lastZoom : number | undefined;

    constructor(props : IRecipePdfRendererProps) {
        super(props);
        this.state = {
            isRendering : false
        };
    }

    public componentDidMount() {
        if (!this.container) {
            return;
        }

        // based on https://www.pdftron.com/documentation/web/get-started/npm/#3-usage
        // Due to the size of this library and security complications, part of the library is served from app server as static resources,
        // and part of it is served from s3.
        if (!this.webViewerInstance) {
            WebViewer({
                licenseKey: atob(RecipePdfRenderer.ENCODED_LICENCE_KEY),
                path: "/static/webviewer",
                config: "/static/webviewer/webviewer_config.js",
            }, this.container)
            .then((instance : WebViewerInstance) => {
                // WebViewer library will execute logic from webviewer_config.js here

                this.webViewerInstance = instance;
                instance.UI.disableElements([
                    'leftPanel',
                    'leftPanelButton',
                    'toolbarGroup-View',
                    'toolbarGroup-Insert',
                    'toolbarGroup-Measure',
                    'toolbarGroup-Forms',
                    'toolbarGroup-Annotate',
                    'toolbarGroup-Shapes',
                    'toolbarGroup-FillAndSign',
                    'signatureToolGroupButton',
                    'rubberStampToolGroupButton',
                    'fileAttachmentToolGroupButton',
                    'calloutToolGroupButton',
                    'eraserToolButton',
                    'searchButton',
                    'toggleNotesButton',
                    'selectToolButton',
                    'viewControlsButton',
                    'toolsOverlay',
                ]);

                instance.UI.setFitMode("FitPage");
                instance.UI.setToolbarGroup('toolbarGroup-View');
                instance.UI.loadDocument(RecipePdfRenderer.TEMPLATE_PATH);
                instance.Core.documentViewer.addEventListener('documentLoaded', this.renderPdf);
                instance.Core.documentViewer.addEventListener('zoomUpdated', (zoom) => {

                    if (this.lastZoom) {
                        // Don't send the zoom updated event when the recipe card first loads
                        Observer.observeAction(
                            'recipe_card_zoom_updated',
                            {
                                retailer_id: window.GLOBAL_RETAILER_ID,
                                user_id: window.GLOBAL_USER_ID,
                                sales_item_id: this.props.salesItemId === null ? '' : this.props.salesItemId.getValue(),
                                zoom_percentage: zoom
                            });
                    }
                    if (this.lastZoom !== zoom) {
                        this.lastZoom = zoom;
                    }
                });

            });
        }

    }

    public componentDidUpdate(prevProps : Readonly<IRecipePdfRendererProps>, prevState : Readonly<IRecipePdfRendererState>, prevContext : any) {
        if (
            this.props.salesItemId !== prevProps.salesItemId
            || this.props.salesItemsById !== prevProps.salesItemsById
            || this.props.productsById !== prevProps.productsById
            || this.props.imageUploadUrl !== prevProps.imageUploadUrl
            || (this.props.isHidden !== prevProps.isHidden && !this.props.isHidden)
        ) {
            this.renderPdf();
        }

    }

    public render() {
        return (
            <div className={ 'recipe-pdf-renderer' + (this.props.isHidden ? ' hidden' : '') }>
                { (this.props.isLoading || this.state.isRendering) && <LoadingCover className="" hasLoadingIcon={ true } /> }
                <div className="webviewer-container" ref={ this.setRef }/>
                <div className="footer text-right">
                    { this.props.imageUploadButton }
                    <Button
                        buttonClassName="flat danger"
                        isDisabled={ !this.props.imageUploadUrl }
                        isLoading={ false }
                        onClick={ this.props.onReset }
                    >
                        Reset
                    </Button>
                    <Button
                        buttonClassName="flat"
                        isDisabled={ false }
                        isLoading={ false }
                        onClick={ this.props.onHide }
                    >
                        Close
                    </Button>
                </div>
            </div>
        );
    }

    private readonly setRef = (e : HTMLDivElement) => {
        this.container = e;
    }

    private readonly renderPdf = () => {
        const {
            salesItemId,
            productsById,
            salesItemsById,
            imageUploadUrl,
        } = this.props;

        if (!this.webViewerInstance || !salesItemId || !salesItemsById || !productsById) {
            // nothing to render - do not update
            return;
        }

        const document = this.webViewerInstance.Core.documentViewer.getDocument();

        if (!document) {
            // template not yet loaded
            return;
        }

        const salesItem = salesItemId instanceof SalesItemId ? salesItemsById.getRequired(salesItemId).getSalesItem() : null;
        if (!salesItem) {
            // unexpected, but ok
            return;
        }

        const ingredients : Array<IRecipeIngredientTemplateData> = SalesItemDisplayUtils.getIngredientsDisplayInformationForSalesItem(
            salesItem,
            salesItemsById,
            productsById,
            null,
            null
        ).map((v) => ({
            name: v.ingredientLabel,
            qty: numberUtils.FormatToMaximumTwoDecimalPlaces(v.quantityAmount),
            unit: v.quantityUnitLabel,
            category: v.category,
        }));

        // see https://www.pdftron.com/documentation/web/guides/generate-via-template/
        const itemYieldUnit = salesItem.getItemYield().getUnit();
        const servingSizeUnit = salesItem.getServingSize().getUnit();

        const templateData : IRecipeTemplateData = {
            name: salesItem.getName(),
            menu_group: salesItem.getMenuGroup(),
            yield_size: salesItem.getItemYield().getQuantity() + ' ' + (!!itemYieldUnit ? itemYieldUnit : DEFAULT_SERVING_AND_YIELD_UNIT),
            serving_size : salesItem.getServingSize().getQuantity() + ' ' + (!!servingSizeUnit ? servingSizeUnit : DEFAULT_SERVING_AND_YIELD_UNIT),
            ingredients,
            notes: salesItem.getNote(),
        };

        if (!!imageUploadUrl) {
            templateData.image = {
                image_url : imageUploadUrl,
                width : 200,
                height : 200,
            };
        }

        // apply the template values to the currently loaded document in WebViewer
        this.setState({isRendering: true});
        document.applyTemplateValues(templateData)
        .then(() => {
            this.setState({isRendering: false});
            if (!this.props.isHidden && this.props.salesItemId) {
                Observer.observeAction(
                    'recipe_card_document_loaded',
                    {
                        retailer_id: window.GLOBAL_RETAILER_ID,
                        user_id: window.GLOBAL_USER_ID,
                        sales_item_id: this.props.salesItemId.getValue(),
                    });
            }
        });
    }
}
