import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { LocationId } from 'api/Location/model/LocationId';
import { CategoryId } from 'api/Product/model/CategoryId';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { IProductJSONObject } from 'api/Product/serializer/IProductJSONObject';
import { ProductJSONToObjectSerializer } from 'api/Product/serializer/ProductJSONToObjectSerializer';
import { ProductObjectToJSONSerializer } from 'api/Product/serializer/ProductObjectToJSONSerializer';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';

import { IProductService } from '../interfaces/IProductService';

import { AjaxUtils } from 'shared/utils/ajaxUtils';

export class ProductServiceImpl implements IProductService {

    constructor (
        private readonly productJSONToObjectSerializer : ProductJSONToObjectSerializer,
        private readonly productObjectToJSONSerializer : ProductObjectToJSONSerializer,
    ) { }

    public getProductAndProductHash (
        userSessionId : UserSessionId,
        productId : ProductId,
    ) : Promise<{ product : Product, productHash : string }> {
        const queryParameters = {
            product_id: productId.getValue(),
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:product'), queryParameters)
        .then((response) => {
            return Promise.resolve({
                product: this.productJSONToObjectSerializer.getProduct(response.product),
                productHash: response.product_hash,
            });
        })
        .catch((error : Error) => {
            return Promise.reject(error);
        });
    }

    public createProduct (
        userSessionId : UserSessionId,
        locationId : LocationId,
        product : Product,
    ) : Promise<ProductId> {
        const productJson = this.productObjectToJSONSerializer.getJSONProductFromProduct(product, locationId);

        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:product'), productJson)
        .then((response) => {
            return Promise.resolve(new ProductId(response.product_id));
        })
        .catch((error : Error) => {
            return Promise.reject(error);
        });
    }

    public createProducts (
        userSessionId : UserSessionId,
        locationId : LocationId,
        products : Array<Product>,
    ) : Promise<Array<ProductId>> {
        if (products.length === 0) {
            return Promise.resolve([]);
        }
        const json = products.map((product) => this.productObjectToJSONSerializer.getJSONProductFromProduct(product, locationId));
        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:batch_product_create'), json)
        .then((response : Array<string>) => {
            return Promise.resolve(response.map((v) => new ProductId(v)));
        })
        .catch((error : Error) => {
            return Promise.reject(error);
        });
    }

    public editProduct (
        userSessionId : UserSessionId,
        locationId : LocationId,
        productId : ProductId,
        product : Product,
        productHash : string
    ) : Promise<void> {
        return AjaxUtils.ajaxPut(urlWithoutRetailerId('api:product') + '?product_id=' + productId.getValue(), {
            product: this.productObjectToJSONSerializer.getJSONProductFromProduct(product, locationId),
            product_hash: productHash,
        })
        .then((response) => {
            return Promise.resolve();
        })
        .catch((error : Error) => {
            return Promise.reject(error);
        });
    }

    public getProductsById (
        userSessionId : UserSessionId,
        locationId : LocationId,
        productIds : StringValueSet<ProductId>,
    ) : Promise<{ productsById : StringValueMap<ProductId, Product>, productHashesById : StringValueMap<ProductId, string> }> {
        if (productIds.size === 0) {
            return Promise.resolve({ productsById: new StringValueMap<ProductId, Product>(), productHashesById : new StringValueMap<ProductId, string>() });
        }

        const queryParameters = {
            retailer_id: locationId.getValue(),
        };

        const productIdValues : Array<string> = [];
        productIds.forEach((productId : ProductId) => {
            productIdValues.push(productId.getValue());
        });
        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:batch_product_get'), productIdValues, queryParameters)
        .then((response) => {
            const productsByIdResponse = response.products_by_id;
            const productHashesByIdResponse = response.product_hashes_by_id;
            return Promise.resolve({
                productsById: this.productJSONToObjectSerializer.getProductsById(productsByIdResponse),
                productHashesById: this.productJSONToObjectSerializer.getProductHashesById(productHashesByIdResponse)
            });
        });
    }

    public getProductsWithCategoryIds (
        userSessionId : UserSessionId,
        locationId : LocationId,
        categoryIds : StringValueSet<CategoryId>,
    ) : Promise<{ productsById : StringValueMap<ProductId, Product>, productHashesById : StringValueMap<ProductId, string>, productIsDeletedById : StringValueMap<ProductId, boolean> }> {
        if (categoryIds.size === 0) {
            return Promise.resolve({
                productsById: new StringValueMap<ProductId, Product>(),
                productHashesById : new StringValueMap<ProductId, string>(),
                productIsDeletedById : new StringValueMap<ProductId, boolean>(),
            });
        }
        const queryParameters = {
            retailer_id: locationId.getValue(),
        };

        const categoryIdValues : Array<string> = [];
        categoryIds.forEach((categoryId : CategoryId) => {
            categoryIdValues.push(categoryId.getValue());
        });

        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:batch_product_by_category'), categoryIdValues, queryParameters)
        .then((response) => {
            const productsByIdResponse = response.products_by_id;
            const productHashesByIdResponse = response.product_hashes_by_id;
            const isDeletedByProductIdResponse = response.product_is_deleted_by_id;
            return Promise.resolve({
                productsById: this.productJSONToObjectSerializer.getProductsById(productsByIdResponse),
                productHashesById: this.productJSONToObjectSerializer.getProductHashesById(productHashesByIdResponse),
                productIsDeletedById : this.productJSONToObjectSerializer.getProductIsDeletedById(isDeletedByProductIdResponse),
            });
        });
    }

    public bulkUpdateProductsById (
        userSessionId : UserSessionId,
        locationId : LocationId,
        productUpdateArgsById : StringValueMap<ProductId, { product : Product, productHash : string }>,
    ) : Promise<StringValueMap<ProductId, string>> {
        if (productUpdateArgsById.size === 0) {
            return Promise.resolve(new StringValueMap<ProductId, string>());
        }

        const requestBody : {[productIdValue : string] : { new_product : IProductJSONObject, old_product_hash : string }} = {};

        productUpdateArgsById.forEach(({ product, productHash }, productId) => {
            requestBody[productId.getValue()] = {
                new_product: this.productObjectToJSONSerializer.getJSONProductFromProduct(product, locationId),
                old_product_hash: productHash,
            };
        });

        return AjaxUtils.ajaxPut(urlWithoutRetailerId('api:batch_product'), requestBody)
        .then((response) => {
            return Promise.resolve(this.productJSONToObjectSerializer.getProductHashesById(response));
        })
        .catch((error : Error) => {
            return Promise.reject(error);
        });
    }
}
