import DrinkPriceToolModel from 'gen-thrift/drink_price_tool_Model_types';
import DrinkPriceToolService from 'gen-thrift/DrinkPriceToolService';
import UnauthenticatedUserSessionModel from 'gen-thrift/unauthenticated_user_session_Model_types';

import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { UserAccountObjectToThriftSerializer } from 'api/UserAccount/serializer/UserAccountObjectToThriftSerializer';

export class DrinkPriceToolManager {

    private drinkPriceToolServiceClient : DrinkPriceToolService.DrinkPriceToolServiceClient;
    private userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer;

    constructor(
        drinkPriceToolServiceClient : DrinkPriceToolService.DrinkPriceToolServiceClient,
        userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer
    ) {
        this.drinkPriceToolServiceClient = drinkPriceToolServiceClient;
        this.userAccountObjectToThriftSerializer = userAccountObjectToThriftSerializer;
    }

    // rateLimitExceededException
    // serviceTemporarilyUnavailableException
    // unknownUnauthenticatedUserSessionIdException
    public recordPageViewAction(
        unauthenticatedUserSessionId : UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId
    ) {
        return new Promise<void> ((resolve, reject) => {
            this.drinkPriceToolServiceClient.recordPageViewAction(
                unauthenticatedUserSessionId,
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    resolve(result);
                }
            );
        });
    }

    // rateLimitExceededException
    // serviceTemporarilyUnavailableException
    // unknownUnauthenticatedUserSessionIdException
    public recordShareAction(
        unauthenticatedUserSessionId : UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId,
        shareMedium : DrinkPriceToolModel.ShareMedium
    ) {
        return new Promise<void> ((resolve, reject) => {
            this.drinkPriceToolServiceClient.recordShareAction(
                unauthenticatedUserSessionId,
                shareMedium,
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    resolve(result);
                }
            );
        });
    }

    // rateLimitExceededException
    // serviceTemporarilyUnavailableException
    // unknownUnauthenticatedUserSessionIdException
    public getDrinkIdsForUnauthenticatedUserSession(
        unauthenticatedUserSessionId : UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId
    ) {
        return new Promise<Array<DrinkPriceToolModel.DrinkId>> ((resolve, reject) => {
            this.drinkPriceToolServiceClient.getDrinkIdsForUnauthenticatedUserSession(
                unauthenticatedUserSessionId,
                (result : Array<DrinkPriceToolModel.DrinkId> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    resolve(result);
                }
            );
        });
    }

    // rateLimitExceededException
    // serviceTemporarilyUnavailableException
    // unknownUnauthenticatedUserSessionIdException
    // unknownDrinkIdException
    // emptyArgumentException
    public getDrinksByDrinkIdsForUnauthenticatedUserSession(
        unauthenticatedUserSessionId : UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId,
        drinkIds : Array<DrinkPriceToolModel.DrinkId>
    ) {
        return new Promise<Array<DrinkPriceToolModel.Drink>> ((resolve, reject) => {
            this.drinkPriceToolServiceClient.getDrinksByDrinkIdsForUnauthenticatedUserSession(
                unauthenticatedUserSessionId,
                drinkIds,
                (result : Array<DrinkPriceToolModel.Drink> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    resolve(result);
                }
            );
        });
    }

    // rateLimitExceededException
    // serviceTemporarilyUnavailableException
    // unknownUnauthenticatedUserSessionIdException
    // emptyArgumentException
    public createDrinksForUnauthenticatedUserSession(
        unauthenticatedUserSessionId : UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId,
        drinks : Array<DrinkPriceToolModel.Drink>
    ) {
        return new Promise<Array<DrinkPriceToolModel.DrinkId>> ((resolve, reject) => {
            this.drinkPriceToolServiceClient.createDrinksForUnauthenticatedUserSession(
                unauthenticatedUserSessionId,
                drinks,
                (result : Array<DrinkPriceToolModel.DrinkId> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    resolve(result);
                }
            );
        });
    }

    // rateLimitExceededException
    // serviceTemporarilyUnavailableException
    // unknownUnauthenticatedUserSessionIdException
    // unknownDrinkIdException
    // emptyArgumentException
    // argumentArraysOfUnevenLengthException
    public editDrinksByDrinkIdForUnauthenticatedUserSession(
        unauthenticatedUserSessionId : UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId,
        drinkIds : Array<DrinkPriceToolModel.DrinkId>,
        drinks : Array<DrinkPriceToolModel.Drink>
    ) {
        return new Promise<void> ((resolve, reject) => {
            this.drinkPriceToolServiceClient.editDrinksByDrinkIdForUnauthenticatedUserSession(
                unauthenticatedUserSessionId,
                drinkIds,
                drinks,
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    resolve(result);
                }
            );
        });
    }

    // rateLimitExceededException
    // serviceTemporarilyUnavailableException
    // unknownUnauthenticatedUserSessionIdException
    // unknownDrinkIdException
    // emptyArgumentException
    public deleteDrinksByDrinkIdForUnauthenticatedUserSession(
        unauthenticatedUserSessionId : UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId,
        drinkIds : Array<DrinkPriceToolModel.DrinkId>
    ) {
        return new Promise<void> ((resolve, reject) => {
            this.drinkPriceToolServiceClient.deleteDrinksByDrinkIdForUnauthenticatedUserSession(
                unauthenticatedUserSessionId,
                drinkIds,
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    resolve(result);
                }
            );
        });
    }

    // AuthenticatedUserSession Actions
    // rateLimitExceededException
    // serviceTemporarilyUnavailableException
    // unknownActorException
    public getDrinkIdsForAuthenticatedUserSession(
        userSessionId : UserSessionId,
    ) {
        return new Promise<Array<DrinkPriceToolModel.DrinkId>> ((resolve, reject) => {
            this.drinkPriceToolServiceClient.getDrinkIdsForAuthenticatedUserSession(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                (result : Array<DrinkPriceToolModel.DrinkId> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    resolve(result);
                }
            );
        });
    }

    // rateLimitExceededException
    // serviceTemporarilyUnavailableException
    // unknownActorException
    // unknownDrinkIdException
    // emptyArgumentException
    public getDrinksByDrinkIdsForAuthenticatedUserSession(
        authenticatedUserSessionId : UserSessionId,
        drinkIds : Array<DrinkPriceToolModel.DrinkId>
    ) {
        return new Promise<Array<DrinkPriceToolModel.Drink>> ((resolve, reject) => {
            this.drinkPriceToolServiceClient.getDrinksByDrinkIdsForAuthenticatedUserSession(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(authenticatedUserSessionId),
                drinkIds,
                (result : Array<DrinkPriceToolModel.Drink> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    resolve(result);
                }
            );
        });
    }

    // rateLimitExceededException
    // serviceTemporarilyUnavailableException
    // unknownActorException
    // emptyArgumentException
    public createDrinksForAuthenticatedUserSession(
        authenticatedUserSessionId : UserSessionId,
        drinks : Array<DrinkPriceToolModel.Drink>
    ) {
        return new Promise<Array<DrinkPriceToolModel.DrinkId>> ((resolve, reject) => {
            this.drinkPriceToolServiceClient.createDrinksForAuthenticatedUserSession(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(authenticatedUserSessionId),
                drinks,
                (result : Array<DrinkPriceToolModel.DrinkId> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    resolve(result);
                }
            );
        });
    }

    // rateLimitExceededException
    // serviceTemporarilyUnavailableException
    // unknownActorException
    // unknownDrinkIdException
    // emptyArgumentException
    // argumentArraysOfUnevenLengthException
    public editDrinksByDrinkIdForAuthenticatedUserSession(
        authenticatedUserSessionId : UserSessionId,
        drinkIds : Array<DrinkPriceToolModel.DrinkId>,
        drinks : Array<DrinkPriceToolModel.Drink>
    ) {
        return new Promise<void> ((resolve, reject) => {
            this.drinkPriceToolServiceClient.editDrinksByDrinkIdForAuthenticatedUserSession(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(authenticatedUserSessionId),
                drinkIds,
                drinks,
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    resolve(result);
                }
            );
        });
    }

    // rateLimitExceededException
    // serviceTemporarilyUnavailableException
    // unknownActorException
    // unknownDrinkIdException
    // emptyArgumentException
    public deleteDrinksByDrinkIdForAuthenticatedUserSession(
        authenticatedUserSessionId : UserSessionId,
        drinkIds : Array<DrinkPriceToolModel.DrinkId>
    ) {
        return new Promise<void> ((resolve, reject) => {
            this.drinkPriceToolServiceClient.deleteDrinksByDrinkIdForAuthenticatedUserSession(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(authenticatedUserSessionId),
                drinkIds,
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    resolve(result);
                }
            );
        });
    }

    // rateLimitExceededException
    // serviceTemporarilyUnavailableException
    // unknownActorException
    public getDrinkIdsToImport(
        authenticatedUserSessionId : UserSessionId,
    ) {
        return new Promise<Array<DrinkPriceToolModel.DrinkIdAndDrink>> ((resolve, reject) => {
            this.drinkPriceToolServiceClient.getDrinkIdsToImport(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(authenticatedUserSessionId),
                (result : Array<DrinkPriceToolModel.DrinkIdAndDrink> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    resolve(result);
                }
            );
        });
    }

    // rateLimitExceededException
    // serviceTemporarilyUnavailableException
    // unknownActorException
    // unknownDrinkIdException
    // emptyArgumentException
    public importDrinkIds(
        authenticatedUserSessionId : UserSessionId,
        drinkIds : Array<DrinkPriceToolModel.DrinkId>
    ) {
        return new Promise<void> ((resolve, reject) => {
            this.drinkPriceToolServiceClient.importDrinkIds(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(authenticatedUserSessionId),
                drinkIds,
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    resolve(result);
                }
            );
        });
    }
}
