import CoreTimeModel from 'gen-thrift/core_time_Model_types';
// TODO Don't use thrift time

import ProductParService from 'gen-thrift/ProductParService';
import ProductModel from 'gen-thrift/product_Model_types';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { ProductObjectToThriftSerializer } from 'api/Product/serializer/ProductObjectToThriftSerializer';
import { ProductThriftToObjectSerializer } from 'api/Product/serializer/ProductThriftToObjectSerializer';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { UserAccountObjectToThriftSerializer } from 'api/UserAccount/serializer/UserAccountObjectToThriftSerializer';

import { IProductParService } from '../interfaces/IProductParService';

export class ProductParServiceImpl implements IProductParService {
    constructor(
        private readonly  thriftClient : ProductParService.ProductParServiceClient,
        private readonly userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer,
        private readonly productObjectToThriftSerializer : ProductObjectToThriftSerializer,
        private readonly productThriftToObjectSerializer : ProductThriftToObjectSerializer,
    ) {
    }

    public setParsForProductId(
        userSessionId : UserSessionId,
        parsByProductId : StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>>,
        clientTimestamp : CoreTimeModel.TimestampWithMillisecondPrecision,
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.thriftClient.setParsByProductId(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.productObjectToThriftSerializer.getThriftProductIdAndQuantityOfProducts(parsByProductId),
                clientTimestamp,
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve();
                }
            );
        });
    }

    public getParsByProductId(
        userSessionId : UserSessionId,
        productIds : StringValueSet<ProductId>,
    ) : Promise<StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit> | null>> {
        return new Promise<StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit> | null>>((resolve, reject) => {
            this.thriftClient.getParByProductId(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.productObjectToThriftSerializer.getThriftProductIdsFromSet(productIds),
                (result : Array<ProductModel.ProductIdAndOptionalQuantityOfProduct> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    const productParsByProductId = new StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit> | null>();
                    result.forEach((productIdAndQuantityOfProduct) => {
                        if (productIdAndQuantityOfProduct.quantityOfProduct !== null) {
                            productParsByProductId.set(
                                this.productThriftToObjectSerializer.getProductId(productIdAndQuantityOfProduct.productId),
                                this.productThriftToObjectSerializer.getQuantityInUnit(productIdAndQuantityOfProduct.quantityOfProduct),
                            );
                        } else {
                            productParsByProductId.set(
                                this.productThriftToObjectSerializer.getProductId(productIdAndQuantityOfProduct.productId),
                                null,
                            );
                        }
                    });

                    return resolve(productParsByProductId);
                }
            );
        });
    }

    public unsetParForProductId(
        userSessionId : UserSessionId,
        productId : ProductId,
        clientTimestamp : CoreTimeModel.TimestampWithMillisecondPrecision
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.thriftClient.unsetParForProductId(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.productObjectToThriftSerializer.getThriftProductId(productId),
                clientTimestamp,
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve();
                }
            );
        });
    }
}
