import * as _ from 'lodash';
import React from 'react';

import { StringValueMap } from 'api/Core/StringValueMap';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { OrderedProductAmountList } from '../models/OrderedProductAmountList';

import { Button } from 'shared/components/Button';
import { SearchResultListRow } from './SearchResultListRow';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { BrandAndName } from 'shared/components/Product/BrandAndName';

interface IAddedProductAmountsByProductId {
    [productId : string] : boolean;
}
export interface ISearchResultListTable {
    shouldUpdate : boolean;
    sortedProductIds : Array<ProductId>;
    productsById : StringValueMap<ProductId, Product>;
    selectedProduct : ProductId | null; // TODO can this be changed to product id????
    selectedProductInvalidMessage : string | null;
    addedProductAmounts : OrderedProductAmountList | null;
    matchingProduct : ProductId | null;
    addProductToPartnerLocation : (() => Promise<any>) | null;
    searchTerm : string | null;
    setSelectedProduct(productId : ProductId | null) : void;
}
export class SearchResultListTable extends React.Component<ISearchResultListTable, object> {
    public shouldComponentUpdate(nextProps : ISearchResultListTable) {
        return nextProps.shouldUpdate;
    }

    public render() {
        const {
            sortedProductIds,
            selectedProduct,
            selectedProductInvalidMessage,
            addedProductAmounts,
            matchingProduct,
            setSelectedProduct,
            addProductToPartnerLocation,
            searchTerm,
            productsById,
        } = this.props;

        // build key map for already added Products to be used for disabled row detection
        const addedProductAmountsByProductId : IAddedProductAmountsByProductId = {};
        if (addedProductAmounts !== null) {
            addedProductAmounts.getListElementIds().forEach((elementId) => {
                const addedPCA = addedProductAmounts.getListElementById(elementId);
                if (addedPCA !== null) {
                    addedProductAmountsByProductId[addedPCA.getProductId().getValue()] = true;
                }
            });
        }

        const tableRows = _.map(sortedProductIds, (productId, index) => {
            // if a IProductAmount has already been added for a Product, disallow adding another one
            // TODO alb: what does the above comment mean? we don't do that?
            const product = productsById.get(productId);
            if (typeof product === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            const isSelected = selectedProduct !== null && selectedProduct.equals(productId);
            const errorMessage = isSelected ? selectedProductInvalidMessage : null;
            return (
                <SearchResultListRow
                    key={ `${index}-${productId.getValue()}` }
                    productId={ productId }
                    product={ product }
                    selected={ isSelected }
                    errorMessage={ errorMessage }
                    setSelectedProduct={ setSelectedProduct }
                />
            );
        });
        const noSearchResultsElement = (searchTerm !== null && searchTerm.length > 0 && addProductToPartnerLocation === null)  ? (
            <div className="no-search-results-message">
                <b>Don't see the item?</b>
                <br />
                <b>Please add it to your inventory before transferring it.</b>
            </div>
        ) : null;

        const matchingProductObj = matchingProduct !== null ? productsById.get(matchingProduct) || null : null;
        const matchingProductDescription = matchingProductObj !== null ? (
             <BrandAndName 
                brand={ matchingProductObj.getBrand() }
                name={ matchingProductObj.getName() }
                category={ matchingProductObj.getProductCategoryId() }
                subcategory={ matchingProductObj.getProductType() }
             />
        ) : (
            <span>your product</span>
        );
        const addToPartnerLocationElement = addProductToPartnerLocation !== null ? (
            <div className="search-result-list-empty text-center">
                <p>Don't see { matchingProductDescription } at this location?</p>
                <div className="cell-ptb">
                    <Button
                        buttonClassName="primary-action"
                        isDisabled={ false }
                        isLoading={ false }
                        onClick={ addProductToPartnerLocation }
                    >
                        ADD ITEM
                    </Button>
                </div>
            </div>
        ) : null;
        const tableBodyElement = tableRows.length > 0 ? tableRows : noSearchResultsElement;
        return (
            <div className="ctable search-result-list-table">
                <div className="search-result-list-scroll">
                    { tableBodyElement }
                    { addToPartnerLocationElement }
                </div>
            </div>
        );
    }
}
