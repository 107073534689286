import LocationModel from 'gen-thrift/location_Model_types';

export class BevSpotLocation {
    public id : LocationModel.LocationIdentifier;
    public displayName : string;

    constructor(args : {
        id : LocationModel.LocationIdentifier;
        displayName : string
    }) {
        this.id = args.id;
        this.displayName = args.displayName;
    }
}

export const OtherLocation = () => new BevSpotLocation({
    displayName: 'Other',
    id: new LocationModel.LocationIdentifier({
        value: '_other_',
    }),
});
