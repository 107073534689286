import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { ITransferReportProductDataById } from '../models/ITransferReportProductDataById';

import { ProductAmount } from 'api/Product/model/ProductAmount';
import { StringValueMap } from 'api/Core/StringValueMap';
import { Packaging } from 'api/Product/model/Packaging';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';

// TODO Cheezy check if any of these utils are still relevant

// uses new models, should use for transfer function when transfers are in new packaging
export const getTotalCostFromBreakageReportProductDataById =
    (transferReportProductDataById : ITransferReportProductDataById, productAmountsInReport : Array<ProductAmount>) : number | null => {

    let totalReportValue : number = 0;

    try {
        productAmountsInReport.forEach((item, index) => {
            const productAmount : ProductAmount = productAmountsInReport[index];
            if (productAmount.getProductId() !== null) {
                const productId = productAmount.getProductId();
                const productData = transferReportProductDataById.get(productId);
                if (typeof productData === 'undefined') {
                    throw new RuntimeException('productData unexpectedly not in productDataById');
                }

                const productPrice = productData.getPrice();

                try {
                    const bottleCount = PackagingUtils.convertProductQuantityToUnit(
                        productData.getProduct().getPackagingsAndMappings(),
                        productAmount.getQuantityInUnit(),
                        PackagingUtils.getContainerPackagingId(productData.getProduct().getPackagingsAndMappings().getPackaging()),
                        productId
                    ).getQuantity();

                    totalReportValue += (productPrice * bottleCount);
                } catch (error) {
                    throw new RuntimeException('productAmountsInReport contains product with bad packaging');
                }
            }
        });
    } catch (error) {
        return null;
    }

    return totalReportValue;
};

export const getTotalCostFromTransferReportProductDataById =
    (transferReportProductDataById : ITransferReportProductDataById, productAmountsInReport : Array<ProductAmount>) : number | null => {

    let totalReportValue : number = 0;

    try {
        productAmountsInReport.forEach((item, index) => {
            const productAmount : ProductAmount = productAmountsInReport[index];
            const productId = productAmount.getProductId();
            const productData = transferReportProductDataById.get(productId);
            if (typeof productData === 'undefined') {
                throw new RuntimeException('productData unexpectedly not in productDataById');
            }

            const product = productData.getProduct();
            const productPrice = productData.getPrice(); // TODO Cheezy what is this price?

            try {
                const bottleCount = PackagingUtils.convertProductQuantityToUnit(
                    product.getPackagingsAndMappings(),
                    productAmount.getQuantityInUnit(),
                    PackagingUtils.getContainerPackagingId(productData.getProduct().getPackagingsAndMappings().getPackaging()),
                    productId
                ).getQuantity();
                totalReportValue += (productPrice * bottleCount);
            } catch (error) {
                throw new RuntimeException('productAmountsInReport contains product with bad packaging');
            }
        });
    } catch (error) {
        return null;
    }

    return totalReportValue;
};

// could move this to PackagingUtils, but specific to transfer for now
const isPackagingEquivalentForTransfer = (packagingA : Packaging, packagingB : Packaging) => {
    let currentLevelA : Packaging | null = packagingA;
    let currentLevelB : Packaging | null = packagingB;

    while (currentLevelA !== null && currentLevelB !== null) {
        // for each level: ensure unit + quantity of content is the same

        // PTODO: when allowing naming packages + editing of transfer products, do not need to check name (TODO: should we just remove this check now?)
        const unitValueA = currentLevelA.getName() || currentLevelA.getUnit();
        const unitValueB = currentLevelB.getName() || currentLevelB.getUnit();

        const quantityA = currentLevelA.getQuantityOfContent();
        const quantityB = currentLevelB.getQuantityOfContent();

        if (quantityA !== quantityB) {
            return false;
        }

        if (unitValueA !== unitValueB) {
            return false;
        }

        currentLevelA = currentLevelA.getContent();
        currentLevelB = currentLevelB.getContent();
    }

    if (currentLevelA !== null || currentLevelB !== null) {
        return false; // both should be at base layer, otherwise not equal.
    }

    return true;
};

// sort and filter adapted from sort/filter on the old inventory transfer product model.
// TODO: refactor to use normal sorting utils
const sortProducts = (productIds : Array<ProductId>, productsById : StringValueMap<ProductId, Product>) : void => {
    productIds.sort((a, b) => {
        const productA = productsById.get(a);
        const productB = productsById.get(b);
        if (typeof productA === 'undefined' || typeof productB === 'undefined') {
            throw new RuntimeException('unexpected product not found: ' + a + ' ' + b);
        }

        const nameA = (productA.getBrand() + productA.getName()).toUpperCase();
        const nameB = (productB.getBrand() + productB.getName()).toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        return 0;
    });
};

const filterProducts = (
    searchTerm : string | null,
    productIds : Array<ProductId>,
    productsById : StringValueMap<ProductId, Product>,
    selectedProduct : ProductId | null
) : Array<ProductId> => {
    const normalSearchTerm = searchTerm === null ? '' : searchTerm.toUpperCase();
    const searchLimit = 20;
    let results : Array<ProductId> = [];
    let selectedProductAdded = false;

    if (searchTerm === null || searchTerm.length < 3) {
        results = productIds.slice(0, searchLimit);

    } else {
        productIds.some((productId) => {
            const product = productsById.get(productId);
            if (typeof product === 'undefined') {
                throw new RuntimeException('unexpected product not found');
            }

            const brand = product.getBrand().toUpperCase();
            const name = product.getName().toUpperCase();
            if (brand.indexOf(normalSearchTerm) !== -1 ||
                    name.indexOf(normalSearchTerm) !== -1 ||
                    (brand + ' ' + name).indexOf(normalSearchTerm) !== -1) {

                results.push(productId);
            }
            return results.length === searchLimit;
        });
    }

    // if the selected product isn't in the batch, add it
    if (selectedProduct !== null) {
        selectedProductAdded = results.some((productId) => {
            return selectedProduct.equals(productId);
            // TODO alb: I think that this is only called when we allow at most one line per product... but should verify :(
        });
        if (!selectedProductAdded) {
            if (results.length >= searchLimit) {
                results.pop();
            }
            results.unshift(selectedProduct);
        }
    }

    sortProducts(results, productsById);

    return results;
};

// TODO: maybe rename this file
export const transferReportProductDataUtils = {
    getTotalCostFromTransferReportProductDataById,
    isPackagingEquivalentForTransfer,
    filterProducts,
    sortProducts 
};
