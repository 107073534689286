import React from 'react';

import { BevSpotDatePickerCalendarInputRow } from './BevSpotDatePickerCalendarInputRow';

import { CalendarMonth } from '../../models/CalendarMonth';
import { CalendarWeek } from '../../models/CalendarWeek';
import { DateTime } from '../../models/DateTime';
import { MonthOfYear } from '../../models/MonthOfYear';

export interface IBevSpotDatePickerCalendarInput {
    calendarMonth : CalendarMonth;
    selectedDateTime : DateTime;
    setSelectedDateTime : (dateTime : DateTime) => void;
    visibleMonth : MonthOfYear;
    visibleYear : number;
}
export class BevSpotDatePickerCalendarInput extends React.Component<IBevSpotDatePickerCalendarInput, object> {
    public render() {
        const {
            calendarMonth,
            selectedDateTime,
            setSelectedDateTime,
            visibleMonth,
            visibleYear,
        } = this.props;
        const calendarWeeks : Array<CalendarWeek> = calendarMonth.getCalendarWeeks();
        const calendarWeekElements : Array<JSX.Element> = calendarWeeks.map((calendarWeek : CalendarWeek, index : number) => (
            <BevSpotDatePickerCalendarInputRow
                key={ index }
                calendarWeek={ calendarWeek }
                selectedDateTime={ selectedDateTime }
                setSelectedDateTime={ setSelectedDateTime }
                visibleMonth={ visibleMonth }
                visibleYear={ visibleYear }
            />
        ));
        return (
            <div className="bevspot-datepicker-calendar-input">
                <div className="days-of-week">
                    <div className="day-of-week">
                        <span className="day-of-week-letter">S</span>
                    </div>
                    <div className="day-of-week">
                        <span className="day-of-week-letter">M</span>
                    </div>
                    <div className="day-of-week">
                        <span className="day-of-week-letter">T</span>
                    </div>
                    <div className="day-of-week">
                        <span className="day-of-week-letter">W</span>
                    </div>
                    <div className="day-of-week">
                        <span className="day-of-week-letter">T</span>
                    </div>
                    <div className="day-of-week">
                        <span className="day-of-week-letter">F</span>
                    </div>
                    <div className="day-of-week">
                        <span className="day-of-week-letter">S</span>
                    </div>
                </div>
                <div className="calendar-weeks">
                    { calendarWeekElements }
                </div>
            </div>
        );
    }
}
