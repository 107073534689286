import { InvoiceProcessingResultAdjustment } from 'api/Ordering/model/InvoiceProcessingResultAdjustment';
import * as moment from 'moment-timezone';

import { InvoiceProcessingResultLineItem } from 'api/Ordering/model/InvoiceProcessingResultLineItem';
import { InvoiceProcessingResultSource } from 'api/Ordering/model/InvoiceProcessingResultSource';

export class InvoiceProcessingResult {
    constructor (
        private readonly invoiceNumber : string | null,
        private readonly date : moment.Moment | null,
        private readonly distributorName : string | null,
        private readonly lineItems : Array<InvoiceProcessingResultLineItem>,
        private readonly adjustments : Array<InvoiceProcessingResultAdjustment>,
        private readonly totalAmount : number | null,
        private readonly errorMessage : string | null,
        private readonly source : InvoiceProcessingResultSource,
    ) {}

    public getInvoiceNumber() : string | null {
        return this.invoiceNumber;
    }

    public getDate() : moment.Moment | null {
        return this.date;
    }

    public getDistributorName() : string | null {
        return this.distributorName;
    }

    public getLineItems() : Array<InvoiceProcessingResultLineItem> {
        return this.lineItems;
    }

    public getAdjustments(): Array<InvoiceProcessingResultAdjustment> {
        return this.adjustments;
    }

    public getTotalAmount() : number | null {
        return this.totalAmount;
    }

    public getErrorMessage() : string | null {
        return this.errorMessage;
    }

    public getSource() : InvoiceProcessingResultSource {
        return this.source;
    }
}
