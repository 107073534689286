import CoreMathModel from 'gen-thrift/core_math_Model_types';

import { Integer } from '../models/Integer';

export const decimalToNumber = (decimal : CoreMathModel.Decimal) : number => {
    return numTimes10ToTheExponentExact(decimal.value, new Integer(-decimal.scale));
};

// It's questionable that we even attempt to convert a calculated value into a decimal.
// This is highly likely result of inappropriate modeling where the value should be represented as a float to begin with.
export const numberToDecimal = (v : number) => {
    return numberStringToDecimal(v.toString());
};

// Requires passing appropriate scale to avoid accidental rounding.
export const numberToDecimalRoundingDown = (num : number, scale : Integer) : CoreMathModel.Decimal => {
    const value : number = new Integer(numTimes10ToTheExponentExact(num, scale)).value;
    return new CoreMathModel.Decimal({
        scale: scale.value,
        value,
    });
};

export const decimalToNumberString = (decimal : CoreMathModel.Decimal) : string => {
    return numTimes10ToTheExponentExact(decimal.value, new Integer(-decimal.scale)).toFixed(decimal.scale);
};

export const numberStringToDecimal = (numberString : string) : CoreMathModel.Decimal => {
    const decimalDigits = numberString.split('.')[1];
    const decimalScale = new Integer(decimalDigits ? decimalDigits.length : 0);

    return numberToDecimalRoundingDown(parseFloat(numberString), decimalScale);
};

const isEqual = (decimal1 : CoreMathModel.Decimal, decimal2 : CoreMathModel.Decimal) : boolean => {
    return decimalToNumberString(decimal1) === decimalToNumberString(decimal2);
};

// used to avoid floating point errors
const numTimes10ToTheExponentExact = (num : number, exponent : Integer) : number => {
    const splitNum = num.toString().split('.');

    const leftOfDecimalPoint = splitNum[0];
    const rightOfDecimalPoint = splitNum[1] || '';
    let result = '';

    // Move decimal point to the right if positive and to the left if negative
    if (exponent.value >= 0) {
        const numberOfZerosToAppend = exponent.value - rightOfDecimalPoint.length;
        if (numberOfZerosToAppend > 0) {
            result = leftOfDecimalPoint + rightOfDecimalPoint + '0'.repeat(numberOfZerosToAppend);
        } else {
            result = leftOfDecimalPoint + rightOfDecimalPoint.substring(0, exponent.value) + '.' + rightOfDecimalPoint.substring(exponent.value, rightOfDecimalPoint.length);
        }
    } else {
        const numberOfZerosAtFront = -exponent.value - leftOfDecimalPoint.length;
        if (numberOfZerosAtFront > 0) {
            result = '.' + '0'.repeat(numberOfZerosAtFront) + leftOfDecimalPoint + rightOfDecimalPoint;
        } else {
            result = leftOfDecimalPoint.substring(0, exponent.value + leftOfDecimalPoint.length) + '.' + leftOfDecimalPoint.substring(exponent.value + leftOfDecimalPoint.length, leftOfDecimalPoint.length) + rightOfDecimalPoint;
        }
    }

    // If empty string after division, return 0.
    return parseFloat(result) || 0;
};

export const decimalUtils = {
    isEqual,
    decimalToNumber,
    numberToDecimal,
    numberToDecimalRoundingDown,
    decimalToNumberString,
    numberStringToDecimal
};
