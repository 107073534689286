import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IAction, IActionCreatorsMapObject, IEmptyAction, ISetShownAction } from 'shared/models/IAction';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import { LocationId } from 'api/Location/model/LocationId';
import { IBreadcrumbCredentialData, IBreadcrumbIntegrationState } from './models';

import { DjangoApiManager } from 'shared/api/DjangoApiManager';

export interface IBreadcrumbIntegrationStore {
    breadcrumbIntegrationState : IBreadcrumbIntegrationState;
}

export const ActionTypes = {
    SET_MODAL_SHOWN: 'BREADCRUMB_INTEGRATION_MODAL/SET_MODAL_SHOWN',
    SET_EXISTING_CREDENTIAL_STATE: 'BREADCRUMB_INTEGRATION_MODAL/SET_EXISTING_CREDENTIAL_STATE',
    RESET_CREDENTIAL_FORM_ACCORDING_TO_EXISTING_CREDENTIAL: 'BREADCRUMB_INTEGRATION_MODAL/RESET_CREDENTIAL_FORM_ACCORDING_TO_EXISTING_CREDENTIAL',
    FETCH_EXISTING_CREDENTIAL_REQUEST: 'BREADCRUMB_INTEGRATION_MODAL/FETCH_EXISTING_CREDENTIAL_REQUEST',
    FETCH_EXISTING_CREDENTIAL_SUCCESS: 'BREADCRUMB_INTEGRATION_MODAL/FETCH_EXISTING_CREDENTIAL_SUCCESS',
    FETCH_EXISTING_CREDENTIAL_FAILURE: 'BREADCRUMB_INTEGRATION_MODAL/FETCH_EXISTING_CREDENTIAL_FAILURE',
    UPDATE_CREDENTIAL_REQUEST: 'BREADCRUMB_INTEGRATION_MODAL/UPDATE_CREDENTIAL_REQUEST',
    UPDATE_CREDENTIAL_SUCCESS: 'BREADCRUMB_INTEGRATION_MODAL/UPDATE_CREDENTIAL_SUCCESS',
    UPDATE_CREDENTIAL_FAILURE: 'BREADCRUMB_INTEGRATION_MODAL/UPDATE_CREDENTIAL_FAILURE',
    SET_USERNAME_FIELD_VALUE: 'BREADCRUMB_INTEGRATION_MODAL/SET_USERNAME_FIELD_VALUE',
    SET_PASSWORD_FIELD_VALUE: 'BREADCRUMB_INTEGRATION_MODAL/SET_PASSWORD_FIELD_VALUE',
    SET_USERNAME_FIELD_ERROR: 'BREADCRUMB_INTEGRATION_MODAL/SET_USERNAME_FIELD_ERROR',
    SET_PASSWORD_FIELD_ERROR: 'BREADCRUMB_INTEGRATION_MODAL/SET_PASSWORD_FIELD_ERROR',
    DELETE_CREDENTIAL_REQUEST: 'BREADCRUMB_INTEGRATION_MODAL/DELETE_CREDENTIAL_REQUEST',
    DELETE_CREDENTIAL_SUCCESS: 'BREADCRUMB_INTEGRATION_MODAL/DELETE_CREDENTIAL_SUCCESS',
    DELETE_CREDENTIAL_FAILURE: 'BREADCRUMB_INTEGRATION_MODAL/DELETE_CREDENTIAL_FAILURE',
};

export namespace BreadcrumbIntegrationActionInterfaces {
    export interface ISetModalShown extends ISetShownAction {
    }

    export interface IResetCredentialFormAccordingToExistingCredential extends IAction {
    }

    export interface ISetExistingCredentialState extends IAction {
        payload : {
            credentialData : IBreadcrumbCredentialData | null
        };
    }

    export interface IFetchExistingCredentialRequest extends IAction {
        payload : {
            locationId : LocationId;
        };
    }
    export interface IFetchExistingCredentialSuccess extends IAction {
    }
    export interface IFetchExistingCredentialFailure extends IEmptyAction {
    }

    export interface IUpdateCredentialRequest extends IAction {
        payload : {
            locationId : LocationId;
        };
    }
    export interface IUpdateCredentialSuccess extends IAction {
    }
    export interface IUpdateCredentialFailure extends IEmptyAction {
    }

    export interface IDeleteCredentialRequest extends IAction {
        payload : {
            locationId : LocationId;
        };
    }
    export interface IDeleteCredentialSuccess extends IAction {
    }
    export interface IDeleteCredentialFailure extends IEmptyAction {
    }

    export interface ISetUsernameFieldValue extends IAction {
        payload : {
            value : string;
        };
    }
    export interface ISetPasswordFieldValue extends IAction {
        payload : {
            value : string;
        };
    }
    export interface ISetUsernameFieldError extends IAction {
        payload : {
            message : string;
        };
    }
    export interface ISetPasswordFieldError extends IAction {
        payload : {
            message : string;
        };
    }

    export interface IActionCreators extends IActionCreatorsMapObject {
        setModalShown(isShown : boolean) : ISetModalShown;
        resetCredentialFormAccordingToExistingCredential() : IResetCredentialFormAccordingToExistingCredential;
        setUsernameFieldValue(value : string) : ISetUsernameFieldValue;
        setPasswordFieldValue(value : string) : ISetPasswordFieldValue;
        fetchExistingCredential(
            locationId : LocationId
        ) : ThunkAction<Promise<IBreadcrumbCredentialData | null>, IBreadcrumbIntegrationStore, {services : BreadcrumbIntegrationActionInterfaces.IServices}>;
        updateCredential(
            locationId : LocationId,
            username : string,
            password : string
        ) : ThunkAction<Promise<void>, IBreadcrumbIntegrationStore, {services : BreadcrumbIntegrationActionInterfaces.IServices}>;
        deleteCredential(
            locationId : LocationId
        ) : ThunkAction<Promise<void>, IBreadcrumbIntegrationStore, {services : BreadcrumbIntegrationActionInterfaces.IServices}>;
    }

    export interface IServices {
        djangoApiManager : DjangoApiManager;
    }
}

export const setModalShown = (
    isShown : boolean
) : BreadcrumbIntegrationActionInterfaces.ISetModalShown => ({
    payload : {
        isShown
    },
    type : ActionTypes.SET_MODAL_SHOWN
});

export const resetCredentialFormAccordingToExistingCredential = (
) : BreadcrumbIntegrationActionInterfaces.IResetCredentialFormAccordingToExistingCredential => ({
    payload : {},
    type : ActionTypes.RESET_CREDENTIAL_FORM_ACCORDING_TO_EXISTING_CREDENTIAL
});

export const setExistingCredentialState = (
    credentialData : IBreadcrumbCredentialData | null
) : BreadcrumbIntegrationActionInterfaces.ISetExistingCredentialState => ({
    payload : {
        credentialData
    },
    type : ActionTypes.SET_EXISTING_CREDENTIAL_STATE
});

export const fetchExistingCredentialRequest = (
    locationId : LocationId
) : BreadcrumbIntegrationActionInterfaces.IFetchExistingCredentialRequest => ({
    payload : {
        locationId
    },
    type : ActionTypes.FETCH_EXISTING_CREDENTIAL_REQUEST
});

export const fetchExistingCredentialSuccess = (
) : BreadcrumbIntegrationActionInterfaces.IFetchExistingCredentialSuccess => ({
    payload : {},
    type : ActionTypes.FETCH_EXISTING_CREDENTIAL_SUCCESS
});

export const fetchExistingCredentialFailure = (
) : BreadcrumbIntegrationActionInterfaces.IFetchExistingCredentialFailure => ({
    payload : {},
    type : ActionTypes.FETCH_EXISTING_CREDENTIAL_FAILURE
});

export const updateCredentialRequest = (
    locationId : LocationId
) : BreadcrumbIntegrationActionInterfaces.IUpdateCredentialRequest => ({
    payload : {
        locationId
    },
    type : ActionTypes.UPDATE_CREDENTIAL_REQUEST
});

export const updateCredentialSuccess = (
) : BreadcrumbIntegrationActionInterfaces.IUpdateCredentialSuccess => ({
    payload : {},
    type : ActionTypes.UPDATE_CREDENTIAL_SUCCESS
});

export const updateCredentialFailure = (
) : BreadcrumbIntegrationActionInterfaces.IUpdateCredentialFailure => ({
    payload : {},
    type : ActionTypes.UPDATE_CREDENTIAL_FAILURE
});

export const deleteCredentialRequest = (
    locationId : LocationId
) : BreadcrumbIntegrationActionInterfaces.IDeleteCredentialRequest => ({
    payload : {
        locationId
    },
    type : ActionTypes.DELETE_CREDENTIAL_REQUEST
});

export const deleteCredentialSuccess = (
) : BreadcrumbIntegrationActionInterfaces.IDeleteCredentialSuccess => ({
    payload : {},
    type : ActionTypes.DELETE_CREDENTIAL_SUCCESS
});

export const deleteCredentialFailure = (
) : BreadcrumbIntegrationActionInterfaces.IDeleteCredentialFailure => ({
    payload : {},
    type : ActionTypes.DELETE_CREDENTIAL_FAILURE
});

export const setUsernameFieldValue = (
    value : string
) : BreadcrumbIntegrationActionInterfaces.ISetUsernameFieldValue => ({
    payload : {
        value
    },
    type : ActionTypes.SET_USERNAME_FIELD_VALUE
});

export const setPasswordFieldValue = (
    value : string
) : BreadcrumbIntegrationActionInterfaces.ISetPasswordFieldValue => ({
    payload : {
        value
    },
    type : ActionTypes.SET_PASSWORD_FIELD_VALUE
});

export const setUsernameFieldError = (
    message : string
) : BreadcrumbIntegrationActionInterfaces.ISetUsernameFieldError => ({
    payload : {
        message
    },
    type : ActionTypes.SET_USERNAME_FIELD_ERROR
});

export const setPasswordFieldError = (
    message : string
) : BreadcrumbIntegrationActionInterfaces.ISetPasswordFieldError => ({
    payload : {
        message
    },
    type : ActionTypes.SET_PASSWORD_FIELD_ERROR
});

const fetchExistingCredential = (
    locationId : LocationId
) : ThunkAction<Promise<IBreadcrumbCredentialData | null>, IBreadcrumbIntegrationStore, {services : BreadcrumbIntegrationActionInterfaces.IServices}> => {
    return (dispatch : Dispatch<IBreadcrumbIntegrationStore>, getState : () => IBreadcrumbIntegrationStore, extraArguments : {services : BreadcrumbIntegrationActionInterfaces.IServices}) : Promise<IBreadcrumbCredentialData | null> => {
        dispatch(fetchExistingCredentialRequest(locationId));
        return extraArguments.services.djangoApiManager.getBreadcrumbCredential(locationId)
        .then((data : IBreadcrumbCredentialData | null) => {
            dispatch(fetchExistingCredentialSuccess());
            dispatch(setExistingCredentialState(data));
            dispatch(resetCredentialFormAccordingToExistingCredential());
            return Promise.resolve(data);
        })
        .catch((error : Error) => {
            throw new RuntimeException();
        });
    };
};

const updateCredential = (
    locationId : LocationId,
    username : string,
    password : string,
) : ThunkAction<Promise<void>, IBreadcrumbIntegrationStore, {services : BreadcrumbIntegrationActionInterfaces.IServices}> => {
    return (dispatch : Dispatch<IBreadcrumbIntegrationStore>, getState : () => IBreadcrumbIntegrationStore, extraArguments : {services : BreadcrumbIntegrationActionInterfaces.IServices}) : Promise<void> => {
        dispatch(updateCredentialRequest(locationId));
        return extraArguments.services.djangoApiManager.updateBreadcrumbCredential(locationId, username, password)
        .then(() => {
            dispatch(updateCredentialSuccess());
            const credentialData : IBreadcrumbCredentialData = {
                username,
                password,
                isValid : true,
            };
            dispatch(setExistingCredentialState(credentialData));
        })
        .catch((rejection) => {
            // FIXME: Type of rejection is in fact IRestApiRejection and not exposed. We need a better way of handling recoverable errors.
            if (rejection.message !== 'BreadcrumbCredentialRejectedException') {
                throw new RuntimeException(rejection.message);
            }
            dispatch(updateCredentialFailure());
            dispatch(setUsernameFieldError(''));
            dispatch(setPasswordFieldError(''));
        });
    };
};

const deleteCredential = (
    locationId : LocationId,
) : ThunkAction<Promise<void>, IBreadcrumbIntegrationStore, {services : BreadcrumbIntegrationActionInterfaces.IServices}> => {
    return (dispatch : Dispatch<IBreadcrumbIntegrationStore>, getState : () => IBreadcrumbIntegrationStore, extraArguments : {services : BreadcrumbIntegrationActionInterfaces.IServices}) : Promise<void> => {
        dispatch(deleteCredentialRequest(locationId));
        return extraArguments.services.djangoApiManager.deleteBreadcrumbCredential(locationId)
        .then(() => {
            dispatch(deleteCredentialSuccess());
            dispatch(setExistingCredentialState(null));
            dispatch(resetCredentialFormAccordingToExistingCredential());
        })
        .catch(() => {
            throw new RuntimeException();
        });
    };
};

export const BreadcrumbIntegrationModalActions = {
    setModalShown,
    resetCredentialFormAccordingToExistingCredential,
    setUsernameFieldValue,
    setPasswordFieldValue,
    fetchExistingCredential,
    updateCredential,
    deleteCredential,
};
