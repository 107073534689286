export class InvoiceProcessingResultAdjustment {
    constructor(
        private readonly _name: string | null,
        private readonly _amount: number | null,
    ) {}

    get name(): string | null {
        return this._name;
    }

    get amount(): number | null {
        return this._amount;
    }
}
