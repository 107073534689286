import { IntegrationState } from 'api/Integration/model/IntegrationState';
import { Action } from 'redux';
import {
    ActionTypes,
    LightspeedIntegrationActionInterfaces,
    LightspeedIntegrationBlockActions
} from './LightspeedIntegrationBlockActions';

export interface ILightspeedIntegrationBlockState {
    isLoading : boolean;
    existingIntegration : null | IntegrationState;
    modalIsShown : boolean;
    error : null | string;
    modalState : ILightspeedIntegrationModalState | null;
}

export interface ILightspeedIntegrationModalState {
    accessToken: string | null;
}

const initialState : ILightspeedIntegrationBlockState = {
    isLoading : false,
    existingIntegration : null,
    modalIsShown : false,
    error : null,
    modalState : null,
};

const reduceSetIsLoading = (
    state : ILightspeedIntegrationBlockState,
    action : LightspeedIntegrationActionInterfaces.ISetIsLoading
) : ILightspeedIntegrationBlockState => {
    return {
        ...state,
        isLoading : action.payload.isLoading,
    };
};

const reduceSetModalShown = (
    state : ILightspeedIntegrationBlockState,
    action : LightspeedIntegrationActionInterfaces.ISetModalShown
) : ILightspeedIntegrationBlockState => {
    return {
        ...state,
        modalIsShown : action.payload.isShown,
    };
};

const reduceSetModalAccessToken = (
    state : ILightspeedIntegrationBlockState,
    action : LightspeedIntegrationActionInterfaces.ISetModalAccessToken
) : ILightspeedIntegrationBlockState => {
    return {
        ...state,
        modalState: {
            ...state.modalState,
            accessToken: action.payload.accessToken
        }
    };
};

const reduceSetExistingIntegrationState = (
    state : ILightspeedIntegrationBlockState,
    action : LightspeedIntegrationActionInterfaces.ISetExistingIntegrationState
) : ILightspeedIntegrationBlockState => {
    return {
        ...state,
        existingIntegration : action.payload.integrationState,
    };
};

export const LightspeedIntegrationBlockReducers = (
    state : ILightspeedIntegrationBlockState = initialState,
    action : Action,
) : ILightspeedIntegrationBlockState => {
    switch (action.type) {
        case ActionTypes.SET_IS_LOADING:
            return reduceSetIsLoading(state, action as LightspeedIntegrationActionInterfaces.ISetIsLoading);
        case ActionTypes.SET_EXISTING_INTEGRATION_STATE:
            return reduceSetExistingIntegrationState(state, action as LightspeedIntegrationActionInterfaces.ISetExistingIntegrationState);
        case ActionTypes.SET_MODAL_ERROR:
            return {...state, error : (action as ReturnType<typeof LightspeedIntegrationBlockActions.setError>).payload.error};
        case ActionTypes.SET_MODAL_SHOWN:
            return reduceSetModalShown(state, action as LightspeedIntegrationActionInterfaces.ISetModalShown);
        case ActionTypes.SET_MODAL_ACCESS_TOKEN:
            return reduceSetModalAccessToken(state, action as LightspeedIntegrationActionInterfaces.ISetModalAccessToken);
        default:
            return state;
    }
};
