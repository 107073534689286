import { unitOfTime } from 'moment';
import moment from 'moment-timezone';

import { DateRangeDropdownOption } from './DateRangeDropdown';

/*
Get correct start time in retailer time zone, translated to UTC
 */
export const getStartDateFromDateRangeOptionForRetailerInUTC = (dateRangeDropdownOption : DateRangeDropdownOption, now : moment.Moment, retailerTimezone : string) => {
    let subtractAmount : number;
    let subtractUnit : unitOfTime.DurationConstructor;
    switch (dateRangeDropdownOption) {
        case DateRangeDropdownOption.LAST_7_DAYS:
            subtractAmount = 7;
            subtractUnit = 'days';
            break;
        case DateRangeDropdownOption.LAST_4_WEEKS:
            // must perform subtraction in retailer timezone to correctly deal with day light saving, etc.
            subtractAmount = 4;
            subtractUnit = 'weeks';
            break;
        case DateRangeDropdownOption.LAST_8_WEEKS:
            subtractAmount = 8;
            subtractUnit = 'weeks';
            break;
        case DateRangeDropdownOption.LAST_6_MONTHS:
            subtractAmount = 6;
            subtractUnit = 'months';
            break;
        case DateRangeDropdownOption.LAST_3_MONTHS:
            subtractAmount = 3;
            subtractUnit = 'months';
            break;
        case DateRangeDropdownOption.LAST_MONTH:
            subtractAmount = 1;
            subtractUnit = 'month';
            break;
        case DateRangeDropdownOption.LAST_YEAR:
            subtractAmount = 1;
            subtractUnit = 'year';
            break;
        default:
            throw new Error('unhandled date range option: ' + dateRangeDropdownOption);
    }
    return now.clone().tz(retailerTimezone).startOf('day').subtract(subtractAmount, subtractUnit).utc();
};

export const getStartOfDayForRetailerInUTC = (date : moment.Moment, retailerTimezone : string) : moment.Moment => {
    return date.clone().tz(retailerTimezone).startOf('day').utc();
};

export const getEndOfDayForRetailerInUTC = (date : moment.Moment, retailerTimezone : string) : moment.Moment => {
    return date.clone().tz(retailerTimezone).endOf('day').utc();
};
