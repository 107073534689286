import React from 'react';

import { IHexColorsByCategoryName } from 'shared/models/Charts/IHexColorsByCategoryName';

/**
 * YAxisLabel properties
 */
export interface IPrintStyleYAxisHeader {
    /** category values for expanded label view (on print styles) */
    categoryColorsByCategoryName : IHexColorsByCategoryName;
}

export class PrintStyleYAxisHeader extends React.Component<IPrintStyleYAxisHeader, object> {

    public render() {
        const {
            categoryColorsByCategoryName,
        } = this.props;

        const categoryKeys = Object.keys(categoryColorsByCategoryName);
        const categoryNames = categoryKeys.map((categoryName : string) => {
            return (
                <div className="y-axis-print-style-category-header" key={ categoryName }>{ categoryName }</div>
            );
        });

        return (
            <div className="y-axis-print-style-category-header-wrapper">
                <div className="y-axis-print-style-label-placeholder"/>
                { categoryNames }
                <div className="y-axis-print-style-category-header"> total </div>
            </div>
        );

    }
}
