import moment from 'moment-timezone';
import * as XLSX from 'xlsx';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { Product } from 'api/Product/model/Product';
import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductId } from 'api/Product/model/ProductId';
import { SalesItem } from 'api/SalesItem/model/SalesItem';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemWithMetadata } from 'api/SalesItem/model/SalesItemWithMetadata';
import { SalesItemDisplayUtils } from 'api/SalesItem/utils/SalesItemDisplayUtils';
import { SalesItemUtils } from 'api/SalesItem/utils/SalesItemUtils';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { CellFormat, exportUtils } from 'shared/utils/exportUtils';
import { ISortedFilteredAndGroupedResult } from 'shared/utils/sortingFilteringAndGroupingUtils';
import { IValidationResult, Validation } from 'shared/validators/validators';
import { getCachedSalesItemCostsForIds } from './CachedSalesItemCostUtils';

import {
    DEFAULT_SERVING_AND_YIELD_UNIT,
} from 'apps/CreateOrEditSalesItem/reducers/reducers';

// for now just matches old export
const exportSalesItemsListToExcel = (
    retailerName : string,
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>,
    productsById : StringValueMap<ProductId, Product>,
    productCostsById : StringValueMap<ProductId, ProductCost>,
    sortedFilteredGroupedRowData : ISortedFilteredAndGroupedResult<SalesItemId>,
) => {
    const currentDateInRetailerTimeZone = moment.utc().tz(window.GLOBAL_RETAILER_TIME_ZONE);
    const fileName = exportUtils.getStandardReportFileName(retailerName, 'sales_items', currentDateInRetailerTimeZone, null);

    const headersRow = ['Name', 'POS ID', 'Recipe', 'Sales Price', 'Cost', 'Cost %', 'Category', 'Subcategory', 'Menu Group', 'Note'];
    const itemsNeedingAttentionHeaderRow = ['Name', 'POS ID', 'Recipe', 'Sales Price', 'Cost', 'Cost %', 'Category', 'Subcategory', 'Menu Group', 'Note', 'Needs Attention Category'];

    const arrayOfArraysForSalesItemsSheet : Array<Array<string | number>> = [];
    const arrayOfArraysForItemsNeedingAttentionSheet : Array<Array<string | number>> = [ // this sheet does not have groupings, just list of items needing attention
        itemsNeedingAttentionHeaderRow
    ]; // TODO do we still want this as an export? feels like no...

    const costOfSalesItemsById = getCachedSalesItemCostsForIds(
        new StringValueSet(Array.from(salesItemsById.keys())),
        salesItemsById,
        productsById,
        productCostsById
    );

    const flattenedMapOfProductsInSalesItems = SalesItemUtils.flattenSalesItemsIntoProductPerServingComponents(salesItemsById, productsById);

    sortedFilteredGroupedRowData.sortedGroupNamesToDisplay.forEach((groupName : string) => {
        const groupNameRow = [groupName];

        arrayOfArraysForSalesItemsSheet.push(groupNameRow);
        arrayOfArraysForSalesItemsSheet.push(headersRow);

        const sortedRowIds = sortedFilteredGroupedRowData.sortedRowIdsToDisplayByGroupName[groupName];
        sortedRowIds.forEach((salesItemId) => {
            const salesItemWithMetdata = salesItemsById.get(salesItemId);
            const salesItemCost = costOfSalesItemsById.get(salesItemId);

            if (typeof salesItemWithMetdata === 'undefined' || typeof salesItemCost === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            const salesItem = salesItemWithMetdata.getSalesItem();
            const ingredientsInformation = SalesItemDisplayUtils.getIngredientsDisplayInformationForSalesItem(salesItem, salesItemsById, productsById, productCostsById, costOfSalesItemsById);
            const ingredientsString = SalesItemDisplayUtils.getIngredientsDisplayStringForSalesItem(ingredientsInformation);

            const costPercent = SalesItemUtils.getCostPercentageOfSalesItem(salesItem.getSalesPrice(), salesItemCost);

            const flattenedMapOfProductIngredients = flattenedMapOfProductsInSalesItems.getRequired(salesItemId);
            const categoryAndSubcategory = SalesItemUtils.getCategoryAndSubCategoryForSalesItem(flattenedMapOfProductIngredients, productsById);

            const row = [
                salesItem.getName(),
                salesItem.getPOSId(),
                ingredientsString,
                salesItem.getSalesPrice(),
                salesItemCost !== null ? salesItemCost : 'N/A',
                costPercent !== null ? (costPercent / 100) : 'N/A', // excel percent formatting expects percent as fraction of 100
                categoryAndSubcategory.category,
                categoryAndSubcategory.subcategory,
                salesItem.getMenuGroup(),
                salesItem.getNote()
            ];

            arrayOfArraysForSalesItemsSheet.push(row);

            const needsAttentionCategory = salesItem.getNeedsAttentionCategory();
            if (needsAttentionCategory && needsAttentionCategory.length > 0) {
                const needsAttentionRow = [...row];
                needsAttentionRow.push(needsAttentionCategory);
                arrayOfArraysForItemsNeedingAttentionSheet.push(needsAttentionRow);
            }
        });
        arrayOfArraysForSalesItemsSheet.push([]); // empty row between groups
    });

    const workSheetSalesItems = XLSX.utils.aoa_to_sheet(arrayOfArraysForSalesItemsSheet);
    exportUtils.formatNumberCellsInColumn(workSheetSalesItems, 3, CellFormat.MONETARY_VALUE);
    exportUtils.formatNumberCellsInColumn(workSheetSalesItems, 4, CellFormat.MONETARY_VALUE);
    exportUtils.formatNumberCellsInColumn(workSheetSalesItems, 5, CellFormat.PERCENT);

    const worksheetItemsNeedingAttention = XLSX.utils.aoa_to_sheet(arrayOfArraysForItemsNeedingAttentionSheet);
    exportUtils.formatNumberCellsInColumn(worksheetItemsNeedingAttention, 3, CellFormat.MONETARY_VALUE);
    exportUtils.formatNumberCellsInColumn(worksheetItemsNeedingAttention, 4, CellFormat.MONETARY_VALUE);
    exportUtils.formatNumberCellsInColumn(worksheetItemsNeedingAttention, 5, CellFormat.PERCENT);

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheetSalesItems, 'Sales Items');
    XLSX.utils.book_append_sheet(workBook, worksheetItemsNeedingAttention, 'Items Needing Attention');

    exportUtils.writeWorkBookToFile(workBook, fileName, 'xlsx');
};

const getSalesItemWorkSheet = (
    salesItemId : SalesItemId,
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>,
    productsById : StringValueMap<ProductId, Product>,
    productCostsById : StringValueMap<ProductId, ProductCost>,
    costOfSalesItemsById : StringValueMap<SalesItemId, number | null>
) : XLSX.WorkSheet => {
    const salesItemWithMetdata = salesItemsById.get(salesItemId);
    const salesItemCost = costOfSalesItemsById.get(salesItemId);

    if (typeof salesItemWithMetdata === 'undefined' || typeof salesItemCost === 'undefined') {
        throw new RuntimeException('unexpected');
    }

    const salesItem = salesItemWithMetdata.getSalesItem();
    const ingredientsInformation = SalesItemDisplayUtils.getIngredientsDisplayInformationForSalesItem(salesItem, salesItemsById, productsById, productCostsById, costOfSalesItemsById);
    const costPercent = SalesItemUtils.getCostPercentageOfSalesItem(salesItem.getSalesPrice(), salesItemCost);

    const itemYieldUnit = salesItem.getItemYield().getUnit();
    const servingSizeUnit = salesItem.getServingSize().getUnit();

    const salesItemSheet : Array<Array<string | number>> = [
        ['Name', salesItem.getName()],
        [],
        ['POS ID', salesItem.getPOSId()],
        ['Menu Group', salesItem.getMenuGroup()],
        ['Sales Price', salesItem.getSalesPrice()],
        ['Yield', salesItem.getItemYield().getQuantity(), (itemYieldUnit === null) ? DEFAULT_SERVING_AND_YIELD_UNIT : itemYieldUnit],
        ['Serving Size', salesItem.getServingSize().getQuantity(), (servingSizeUnit === null) ? DEFAULT_SERVING_AND_YIELD_UNIT : servingSizeUnit],
        ['Cost %', (costPercent !== null) ? (costPercent / 100) : 'N/A'],
        [],
        ['Ingredient Category', 'Ingredients', 'Amount', 'Unit', 'Cost'],
    ];

    ingredientsInformation.forEach((ingredientInformation) => {
        salesItemSheet.push([
            ingredientInformation.category,
            ingredientInformation.ingredientLabel,
            ingredientInformation.quantityAmount,
            ingredientInformation.quantityUnitLabel,
            ingredientInformation.costOfIngredient ? ingredientInformation.costOfIngredient : '',
        ]);
    });

    salesItemSheet.push([]);

    salesItemSheet.push(['Notes']);
    salesItem.getNote().split(/\r?\n/).forEach((line) => {
        salesItemSheet.push(['', line]);
    });

    const salesItemWorkSheet = XLSX.utils.aoa_to_sheet(salesItemSheet);
    exportUtils.formatNumberCellsInColumn(salesItemWorkSheet, 4, CellFormat.MONETARY_VALUE);
    exportUtils.formatNumberCell(salesItemWorkSheet, 1, 7, CellFormat.PERCENT);

    return salesItemWorkSheet;
};

const exportSalesItemToExcel = (
    retailerName : string,
    salesItemId : SalesItemId,
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>,
    productsById : StringValueMap<ProductId, Product>,
    productCostsById : StringValueMap<ProductId, ProductCost>
) => {
    const costOfSalesItemsById = getCachedSalesItemCostsForIds(
        new StringValueSet(Array.from(salesItemsById.keys())),
        salesItemsById,
        productsById,
        productCostsById
    );

    const salesItemWorkSheet = getSalesItemWorkSheet(
        salesItemId,
        salesItemsById,
        productsById,
        productCostsById,
        costOfSalesItemsById,
    );

    const salesItemWithMetadata = salesItemsById.get(salesItemId);
    if (typeof salesItemWithMetadata === 'undefined') {
        throw new RuntimeException('unexpected');
    }
    const salesItem = salesItemWithMetadata.getSalesItem();

    const workBook = XLSX.utils.book_new();

    const salesItemWorkSheetName = exportUtils.cleanWorksheetName(
        salesItem.getName().substring(0, exportUtils.MAX_SHEET_NAME_LENGTH)
    );

    XLSX.utils.book_append_sheet(workBook, salesItemWorkSheet, salesItemWorkSheetName);

    const currentDateInRetailerTimeZone = moment.utc().tz(window.GLOBAL_RETAILER_TIME_ZONE);
    const fileName = exportUtils.getStandardReportFileName(retailerName, salesItem.getName(), currentDateInRetailerTimeZone, null);

    exportUtils.writeWorkBookToFile(workBook, fileName, 'xlsx');
};

const exportRecipeBookToExcel = (
    retailerName : string,
    salesItemIds : StringValueSet<SalesItemId>,
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>,
    productsById : StringValueMap<ProductId, Product>,
    productCostsById : StringValueMap<ProductId, ProductCost>
) => {
    const costOfSalesItemsById = getCachedSalesItemCostsForIds(
        new StringValueSet(Array.from(salesItemsById.keys())),
        salesItemsById,
        productsById,
        productCostsById
    );

    const currentDateInRetailerTimeZone = moment.utc().tz(window.GLOBAL_RETAILER_TIME_ZONE);
    const fileName = exportUtils.getStandardReportFileName(retailerName, 'recipe_book', currentDateInRetailerTimeZone, null);

    const workBook = XLSX.utils.book_new();

    generateWorkSheetsWithUniqueNames(
        retailerName,
        salesItemIds,
        salesItemsById,
        productsById,
        productCostsById,
        costOfSalesItemsById
    ).forEach((value : XLSX.WorkSheet, key : string) => {
        XLSX.utils.book_append_sheet(workBook, value, key);
    });

    exportUtils.writeWorkBookToFile(workBook, fileName, 'xlsx');
};

const generateWorkSheetsWithUniqueNames = (
    retailerName : string,
    salesItemIds : StringValueSet<SalesItemId>,
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>,
    productsById : StringValueMap<ProductId, Product>,
    productCostsById : StringValueMap<ProductId, ProductCost>,
    costOfSalesItemsById : StringValueMap<SalesItemId, number | null>
) : Map<string, XLSX.WorkSheet> => {
    const worksheets = new Map<string, XLSX.WorkSheet>();
    const seenCleanedUpSalesItemNames = new Map<string, number>();

    salesItemIds.forEach((salesItemId) => {
        const salesItemWithMetadata = salesItemsById.get(salesItemId);
        if (typeof salesItemWithMetadata === 'undefined') {
            throw new RuntimeException('unexpected');
        }

        const salesItem = salesItemWithMetadata.getSalesItem();

        const salesItemWorkSheet = getSalesItemWorkSheet(
            salesItemId,
            salesItemsById,
            productsById,
            productCostsById,
            costOfSalesItemsById,
        );

        // Truncate the sales item name to use as the sheet name
        const truncatedSalesItemName = salesItem.getName().substring(0, exportUtils.MAX_SHEET_NAME_LENGTH);

        // Clean up the sales item name to not break any of Excel's rules around them.
        const cleanedSalesItemWorkSheetName = exportUtils.cleanWorksheetName(truncatedSalesItemName);

        const timesCleanedUpSalesItemNameHasBeenSeen = (seenCleanedUpSalesItemNames.get(cleanedSalesItemWorkSheetName) || 0) + 1;

        // Establish this cleaned-up name as the baseline name we're going to use when creating the sheet. This could
        // get modified as we make our way down the code path.
        let actualSalesItemWorkSheetName = cleanedSalesItemWorkSheetName;

        if (timesCleanedUpSalesItemNameHasBeenSeen > 1) {

            // If our sales item name is close enough to exportUtils.MAX_SHEET_NAME_LENGTH, we may have to drop some
            // characters from the end in order to fit the number we want to append and stay under that limit.

            // First, let's find out how many characters we'd need to drop if we had to, based on the number we want to append.
            const numberOfCharactersToTrimFromEndOfName = Math.trunc(timesCleanedUpSalesItemNameHasBeenSeen / 10) + 1;

            // Then, do some math to see if we even need to worry about it.
            if (numberOfCharactersToTrimFromEndOfName + cleanedSalesItemWorkSheetName.length > exportUtils.MAX_SHEET_NAME_LENGTH) {
                // Turns out, we need to worry about it - so let's drop some characters from the end.
                actualSalesItemWorkSheetName = cleanedSalesItemWorkSheetName.substring(0, exportUtils.MAX_SHEET_NAME_LENGTH - numberOfCharactersToTrimFromEndOfName);
            }

            // Regardless of whether we had to drop characters, now we compose our final worksheet name.
            actualSalesItemWorkSheetName += timesCleanedUpSalesItemNameHasBeenSeen;
        }

        // Append the sheet to the workbook.
        worksheets.set(actualSalesItemWorkSheetName, salesItemWorkSheet);
        seenCleanedUpSalesItemNames.set(cleanedSalesItemWorkSheetName, timesCleanedUpSalesItemNameHasBeenSeen);
    });

    return worksheets;
};

// TODO this should be in some other util...
const formatPercent = (value : number | null) => {
    if (value === null) {
        return '-';
    }

    const numberString = Number.isInteger(value) ? value.toString() : value.toFixed(2);
    return numberString + '%';
};

const getNotificationMessageSalesItemIdentifier = (salesItemIds : StringValueSet<SalesItemId>, salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>) : string => {
    let notificationMessageIdentifier : string;
    if (salesItemIds.size === 1) {
        const salesItemId = Array.from(salesItemIds.values())[0];
        const salesItemWithMetdata = salesItemsById.get(salesItemId);
        if (typeof salesItemWithMetdata === 'undefined') {
            throw new RuntimeException('unexpected');
        }

        notificationMessageIdentifier = `${ salesItemWithMetdata.getSalesItem().getName() } has`.trim();
    } else {
        notificationMessageIdentifier = `${ salesItemIds.size } items have`;
    }

    return notificationMessageIdentifier;
};

const validateValueByFieldName = (
    fieldName : 'salesItemName' | 'posId' | 'taxPercentage', // two from resolve duplicates, one from tax percent modal
    value : string,
) : IValidationResult => {
    let isValid : null | boolean = null;
    let errorMessage = '';

    // required
    let fieldNameText = '';
    switch (fieldName) {
        case 'salesItemName':
            fieldNameText = 'Item name';
            break;
        // case 'taxPercentage': // tax percent used to be required, but feels like it should be nullable now (we can just default to 0)
        //     fieldNameText = 'Tax Percent';
        //     break;
    }

    if (fieldNameText) {
        isValid = Validation.validateRequired(value);
        if (!isValid) {
            errorMessage = `${ fieldNameText } is required`;
            return {
                errorMessage,
                isValid,
            };
        }
    }

    // input type specific
    switch (fieldName) {
        case 'salesItemName':
            isValid = true; // if exists, is valid
            break;
        case 'posId':
            isValid = true;
            break;
        case 'taxPercentage':
            isValid = Validation.validateNonNegativeNumber(value);
            if (!isValid) {
                errorMessage = 'Must be a number greater than or equal to 0';
            }
            break;
        default:
            if (isValid === null) {
                throw new Error('no validation implemented for fieldname ' + fieldName);
            }
    }

    return {
        isValid,
        errorMessage,
    };
};

const replaceNeedsAttentionCategoryInSalesItem = (salesItemWithMetadata : SalesItemWithMetadata, newNeedsAttentionCategory : string | null) => {
    const oldSalesItem = salesItemWithMetadata.getSalesItem();
    const newSalesItem = new SalesItem(
        oldSalesItem.getName(),
        oldSalesItem.getLocationId(),
        oldSalesItem.getMenuGroup(),
        oldSalesItem.getPOSId(),
        oldSalesItem.getNote(),
        newNeedsAttentionCategory,
        oldSalesItem.getSalesPrice(),
        oldSalesItem.getMiscellaneousCost(),
        oldSalesItem.getComponentQuantityOfProductByProductId(),
        oldSalesItem.getComponentServingsBySalesItemId(),
        oldSalesItem.getItemYield(),
        oldSalesItem.getServingSize(),
        oldSalesItem.getSalesItemCustomUnitName()
    );
    return new SalesItemWithMetadata(
        newSalesItem,
        salesItemWithMetadata.getCreationMetadata(),
        salesItemWithMetadata.getLastEditedMetadata(),
        salesItemWithMetadata.getDeletionMetadata(),
        salesItemWithMetadata.getIsArchived()
    );
};

export const SalesItemManagerUtils = {
    exportSalesItemsListToExcel,
    exportSalesItemToExcel,
    exportRecipeBookToExcel,
    formatPercent,
    getNotificationMessageSalesItemIdentifier,
    validateValueByFieldName,
    replaceNeedsAttentionCategoryInSalesItem,
    generateWorkSheetsWithUniqueNames
};
