import { StediLocationReference } from 'api/Integration/model/StediLocationReference';
import { InvoiceUploadId } from 'api/Ordering/model/InvoiceUploadId';
import { StediTradingPartner } from 'shared/models/IntegrationStatus';
import moment from 'moment-timezone';

// This should stay in with python/order_service/model/StediIntegration.py
export class StediIntegrationState {
    constructor(
        private readonly integrationType : StediTradingPartner,
        private readonly stediLocationReference : StediLocationReference | null,
        private readonly lastInvoiceInfo: null | {
            dateReceived: moment.Moment | null,
            invoiceDate: moment.Moment | null,
            invoiceUploadId: InvoiceUploadId | null
        }
    ) {
    }

    public getIntegrationType() : StediTradingPartner {
        return this.integrationType;
    }

    public getStediLocationReference() : StediLocationReference | null {
        return this.stediLocationReference;
    }

    public getLastInvoiceInfo() {
        return this.lastInvoiceInfo;
    }
}
