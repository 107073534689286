import { ICategoryService } from 'api/Product/interfaces/ICategoryService';
import { Category } from 'api/Product/model/Category';
import { CategoryId } from 'api/Product/model/CategoryId';
import { CategoryUtils } from 'api/Product/utils/categoryUtils';
import { productUtils } from 'api/Product/utils/productUtils';
import { UserAccountIdAndTimestamp } from 'api/UserAccount/model/UserAccountIdAndTimestamp';
import * as _ from 'lodash';

import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { DateTime } from 'shared/models/DateTime';
import { Observer } from 'shared/utils/observer';

import { IExtraArguments } from 'shared/components/Provider';

import { batchActions, IAction, ISetShownAction, ISetShownActionCreator } from 'shared/models/IAction';

import { ICartService } from 'api/Cart/interfaces/ICartService';
import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { Distributor } from 'api/Distributor/model/Distributor';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { LocationId } from 'api/Location/model/LocationId';
import { Scope } from 'api/Location/model/Scope';
import { IProductService } from 'api/Product/interfaces/IProductService';
import { Mappings } from 'api/Product/model/Mappings';
import { PackagingsAndMappings } from 'api/Product/model/PackagingsAndMappings';
import { Price } from 'api/Product/model/Price';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { oldPackagingUtils } from 'api/Product/utils/oldPackagingUtils';
import { CatalogItem } from 'api/Search/model/CatalogItem';
import { CatalogItemId } from 'api/Search/model/CatalogItemId';
import { ICatalogItemOption } from 'api/Search/model/ICatalogItemOption';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import LocationModel from 'gen-thrift/location_Model_types';
import { iteratePackageRows, serializeProductPackaging, utils } from 'shared/components/AddItem/utils';
import { AddItemModalContextType } from '../models/AddItemModalContextType';
import {
    AddItemModalComponent,
    CatalogItemComponent,
    IAddItemState,
    IAddItemData,
    ICatalogItemOptionRowId,
    IPricedProductInfoByProductIdValue,
    IPricedProductPackageRowId,
    IProductPackageRowInfoByAttribute,
} from 'shared/components/AddItem/reducers/addItemReducers';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import { DistributorService } from 'api/Distributor/impl/DistributorService';
import { ILocationProductService } from 'api/Location/interfaces/ILocationProductService';
import { IProductDistributorService } from 'api/Product/interfaces/IProductDistributorService';
import { IProductAndCatalogSearchService } from 'api/Search/interfaces/IProductAndCatalogSearchService';
import { IUserAccountInfoReader } from 'api/UserAccount/interfaces/IUserAccountInfoReader';
import { IUserAccountInfoWriter } from 'api/UserAccount/interfaces/IUserAccountInfoWriter';
import { IAccountSessionReader } from 'shared/lib/account/interfaces/IAccountSessionReader';

import { Conversions } from 'api/Product/model/Conversions';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { CartUtils } from 'api/Cart/utils/CartUtils';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { ProductFormUtils } from 'apps/ItemCard/utils/ProductFormUtils';

export interface IAddItemResult {
    addedProductIds : StringValueSet<ProductId>;
    newProductsById : StringValueMap<ProductId, Product>;   // todo: replace with caller fetch product api call
}

export interface IAddItemProps {
    addItemState : IAddItemState;
}

export const ActionTypes = {
    SET_LOADING: 'ADD_ITEM/SET_LOADING',
    SET_LOADING_SEARCH_RESULTS: 'ADD_ITEM/SET_LOADING_SEARCH_RESULTS',
    SET_DISPLAYED_PRODUCT_IDS: 'ADD_ITEM/SET_DISPLAYED_PRODUCT_IDS',
    SET_DISPLAYED_CATALOG_ITEM_IDS: 'ADD_ITEM/SET_DISPLAYED_CATALOG_ITEM_IDS',
    SET_ADD_ITEM_DATA: 'ADD_ITEM/SET_ADD_ITEM_DATA',
    SET_ADD_ITEM_TO_ENTITY_BUTON_TEXT: 'ADD_ITEM/SET_ADD_ITEM_TO_ENTITY_BUTON_TEXT',
    ADD_PRODUCT_ROW_INFO_BY_PRICED_PRODUCT_ID: 'ADD_ITEM/ADD_PRODUCT_ROW_INFO_BY_PRICED_PRODUCT_ID',
    SET_CATALOG_ITEMS_BY_ID: 'ADD_ITEM/SET_CATALOG_ITEMS_BY_ID',
    SET_PRODUCT_ROW_EXPANDED: 'ADD_ITEM/SET_PRODUCT_ROW_EXPANDED',
    SET_PRODUCT_ROW_SELECTED: 'ADD_ITEM/SET_PRODUCT_ROW_SELECTED',
    SET_SELECTED_CATALOG_OPTIONS: 'ADD_ITEM/SET_SELECTED_CATALOG_OPTIONS',
    SET_CATALOG_ROW_SELECTED: 'ADD_ITEM/SET_CATALOG_ROW_SELECTED',
    SET_CLEAR_ALL_SELECTIONS: 'ADD_ITEM/SET_CLEAR_ALL_SELECTIONS',
    SET_ITEMS_SELECTED_MENU_IS_OPEN: 'ADD_ITEM/SET_ITEMS_SELECTED_MENU_IS_OPEN',
    SET_ACTIVE_SEARCH_TERM: 'ADD_ITEM/SET_ACTIVE_SEARCH_TERM',
    SET_SEARCH_TERM: 'ADD_ITEM/SET_SEARCH_TERM',
    SET_CONTEXT_TYPE: 'ADD_ITEM/SET_CONTEXT_TYPE',
    ADD_PRODUCT_IDS_TO_PRODUCT_IDS_IN_ENTITY: 'ADD_ITEM/ADD_PRODUCT_IDS_TO_PRODUCT_IDS_IN_ENTITY',
    REMOVE_PRODUCT_IDS_FROM_PRODUCT_IDS_IN_ENTITY: 'ADD_ITEM/REMOVE_PRODUCT_IDS_FROM_PRODUCT_IDS_IN_ENTITY',
    ADD_CATALOG_ITEMS_IN_ENTITY: 'ADD_ITEM/ADD_CATALOG_ITEMS_IN_ENTITY',
    SET_PAGE_OFFSET: 'ADD_ITEM/SET_PAGE_OFFSET',
    SET_RESULTS_PER_PAGE: 'ADD_ITEM/SET_RESULTS_PER_PAGE',
    SET_TOTAL_NUMBER_OF_CATALOG_ITEMS: 'ADD_ITEM/SET_TOTAL_NUMBER_OF_CATALOG_ITEMS',
    SET_TOTAL_NUMBER_OF_PRODUCTS: 'ADD_ITEM/SET_TOTAL_NUMBER_OF_PRODUCTS',
    SET_SELECTED_PRODUCT_IDS: 'ADD_ITEM/SET_SELECTED_PRODUCT_IDS',
    SET_NUMBER_PRODUCTS_ADDED: 'ADD_ITEM/SET_NUMBER_PRODUCTS_ADDED',
    SET_ADDED_PRODUCTS_NOTIFICATION_SHOWN: 'ADD_ITEM/SET_ADDED_PRODUCTS_NOTIFICATION_SHOWN',
    SET_PRODUCT_IDS_LAST_ADDED_TO_ENTITY: 'ADD_ITEM/SET_PRODUCT_IDS_LAST_ADDED_TO_ENTITY',
    CLEAR_PRODUCT_IDS_IN_ENTITY: 'ADD_ITEM/CLEAR_PRODUCT_IDS_IN_ENTITY',
    SET_MOBILE_SEARCH_BAR_SHOWN: 'ADD_ITEM/SET_MOBILE_SEARCH_BAR_SHOWN',
    SET_SEARCH_BAR_FOCUS: 'ADD_ITEM/SET_SEARCH_BAR_FOCUS',
    SET_SEARCH_SUGGESTIONS: 'ADD_ITEM/SET_SEARCH_SUGGESTIONS',
    SET_HIGHLIGHTED_SEARCH_SUGGESTION: 'ADD_ITEM/SET_HIGHLIGHTED_SEARCH_SUGGESTION',
    SET_COMPONENT_IS_SHOWN: 'ADD_ITEM/SET_COMPONENT_IS_SHOWN',
    SET_CATALOG_ITEM_COMPONENT_IS_SHOWN: 'ADD_ITEM/SET_CATALOG_ITEM_COMPONENT_IS_SHOWN',
    ADD_CUSTOM_CATALOG_ITEM_OPTION: 'ADD_ITEM/ADD_CUSTOM_CATALOG_ITEM_OPTION',
    SET_ON_GET_INITIAL_DATA_FROM_CONTEXT: 'SET_ON_GET_INITIAL_DATA_FROM_CONTEXT',
};

export namespace ActionInterfaces {
    export interface ISetLoading extends IAction {
        payload : {
            isLoading : boolean,
        };
    }

    export interface ISetComponentIsShownAction extends IAction {
        payload : {
            component : AddItemModalComponent;
            isShown : boolean;
        };
    }
    export type ISetComponentIsShownActionCreator = (component : AddItemModalComponent, isShown : boolean) => ISetComponentIsShownAction;

    export interface ISetProductRowExpandedAction extends IAction {
        payload : {
            productId : ProductId;
            isExpanded : boolean;
        };
    }
    export type ISetProductRowExpandedActionCreator = (productId : ProductId, isExpanded : boolean) => ISetProductRowExpandedAction;

    export interface ISetProductPackageRowSelected extends IAction {
        payload : {
            rowId : IPricedProductPackageRowId;
            isSelected : boolean;
        };
    }
    export type ISetRowSelectedActionCreator = (rowId : IPricedProductPackageRowId, isSelected : boolean) => ISetProductPackageRowSelected;

    export interface ISetCatalogOptionRowSelected extends IAction {
        payload : {
            rowId : ICatalogItemOptionRowId;
            isSelected : boolean;
        };
    }

    export interface IClearAllSelections extends IAction {
        payload : {};
    }

    export interface ISetDisplayedProductIdsAction extends IAction {
        payload : {
            productIds : Array<ProductId>;
        };
    }
    export type ISetDisplayedProductIdsActionCreator = (productIds : Array<ProductId>) => ISetDisplayedProductIdsAction;

    export interface ISetDisplayedCatalogItemIds extends IAction {
        payload : {
            catalogItemIds : Array<CatalogItemId>;
        };
    }
    export type ISetDisplayedCatalogItemIdsActionCreator = (catalogItemIds : Array<CatalogItemId>) => ISetDisplayedCatalogItemIds;

    export interface IAddProductRowInfoByPricedProductIdAction extends IAction {
        payload : {
            pricedProductInfoByProductIdValue : IPricedProductInfoByProductIdValue
        };
    }
    export type IAddProductRowInfoByPricedProductIdActionCreator = (pricedProductInfoByProductIdValue : IPricedProductInfoByProductIdValue) => IAddProductRowInfoByPricedProductIdAction;

    export interface ISetCatalogItemsById extends IAction {
        payload : {
            catalogItemsById : StringValueMap<CatalogItemId, CatalogItem>
        };
    }
    export type ISetCatalogItemsByIdActionCreator = (catalogItemsById : StringValueMap<CatalogItemId, CatalogItem>) => ISetCatalogItemsById;

    export interface ISetAddItemData extends IAction {
        payload : {
            addItemData : IAddItemData,
        };
    }
    export type ISetAddItemDataActionCreator = (addItemData : IAddItemData) => ISetAddItemData;

    export interface ISetActiveSearchTermAction extends IAction {
        payload : {
            activeSearchTerm : string | null
        };
    }
    export type ISetActiveSearchTermActionCreator = (activeSearchTerm : string | null) => ISetActiveSearchTermAction;

    export interface ISetSearchTermAction extends IAction {
        payload : {
            searchTerm : string | null;
        };
    }
    export type ISetSearchTermActionCreator = (searchTerm : string | null) => ISetSearchTermAction;

    export interface ISetAddItemToEntityButtonTextAction extends IAction {
        payload : {
            value : string
        };
    }
    export type ISetAddItemToEntityButtonTextActionCreator = (value : string) => ISetAddItemToEntityButtonTextAction;

    export interface ISetContextTypeAction extends IAction {
        payload : {
            contextType : AddItemModalContextType
        };
    }
    export type ISetContextTypeActionCreator = (contextType : AddItemModalContextType) => ISetContextTypeAction;

    export interface IAddProductIdsToProductIdsInEntityAction extends IAction {
        payload : {
            pricedProductPackageRowIdValues : Array<IPricedProductPackageRowId>;
        };
    }
    export type IAddProductIdsToProductIdsInEntityActionCreator = (pricedProductPackageRowIdValues : Array<IPricedProductPackageRowId>) => IAddProductIdsToProductIdsInEntityAction;

    export interface IAddCatalogItemsInEntity extends IAction {
        payload : {
            catalogItemSelections : Array<ICatalogItemOptionRowId>;
        };
    }
    export type IAddCatalogItemsInEntityActionCreator = (catalogItemSelections : Array<ICatalogItemOptionRowId>) => IAddCatalogItemsInEntity;

    export interface IRemoveProductIdsFromProductIdsInEntityAction extends IAction {
        payload : {
            pricedProductPackageRowIdValues : Array<IPricedProductPackageRowId>;
        };
    }
    export type IRemoveProductIdsFromProductIdsInEntityActionCreator = (pricedProductPackageRowIdValues : Array<IPricedProductPackageRowId>) => IRemoveProductIdsFromProductIdsInEntityAction;

    export interface IClearProductIdsInEntityAction extends IAction {
        payload : {};
    }
    export type IClearProductIdsInEntityActionCreator = () => IClearProductIdsInEntityAction;

    export interface ISetPageOffsetAction extends IAction {
        payload : {
            offset : number;
        };
    }
    export type ISetPageOffsetActionCreator = (offset : number) => ISetPageOffsetAction;

    export interface ISetTotalNumberOfCatalogItemsAction extends IAction {
        payload : {
            total : number;
        };
    }
    export type ISetTotalNumberOfCatalogItemsActionCreator = (total : number) => ISetTotalNumberOfCatalogItemsAction;

    export interface ISetTotalNumberOfProducts extends IAction {
        payload : {
            total : number;
        };
    }
    export type ISetTotalNumberOfProductsActionCreator = (total : number) => ISetTotalNumberOfProducts;

    export interface ISetResultsPerPageAction extends IAction {
        payload : {
            resultsPerPage : number;
        };
    }
    export type ISetResultsPerPageActionCreator = (resultsPerPage : number) => ISetResultsPerPageAction;

    export interface ISetSelectedProductIdsAction extends IAction {
        payload : {
            pricedProductPackageRowIdValues : Array<IPricedProductPackageRowId>;
        };
    }
    export type ISetSelectedProductIdsActionCreator = (pricedProductPackageRowIdValues : Array<IPricedProductPackageRowId>) => ISetSelectedProductIdsAction;

    export interface ISetNumberProductsAddedAction extends IAction {
        payload : {
            numberAdded : number;
        };
    }
    export type ISetNumberProductsAddedActionCreator = (numberAdded : number) => ISetNumberProductsAddedAction;

    export interface ISetProductsAddedNotificationShownAction extends IAction {
        payload : {
            isShown : boolean;
        };
    }
    export type ISetProductsAddedNotificationShownActionCreator = () => ISetProductsAddedNotificationShownAction;

    export interface ISetProductIdsLastAddedToEntityAction extends IAction {
        payload : {
            productIdsAdded : Array<IPricedProductPackageRowId>
        };
    }
    export type ISetProductIdsLastAddedToEntityActionCreator = (productIdsAdded : Array<IPricedProductPackageRowId>) => ISetProductIdsLastAddedToEntityAction;

    export interface ISetSearchBarIsFocusedAction extends IAction {
        payload : {
            isFocused : boolean;
        };
    }
    export type ISetSearchBarIsFocusedActionCreator = (isFocused : boolean) => ISetSearchBarIsFocusedAction;

    export interface ISetSearchSuggestions extends IAction {
        payload : {
            searchSuggestions : Array<string>,
        };
    }
    export type ISetSearchSuggestionsActionCreator = (searchSuggestions : Array<string>) => ISetSearchSuggestions;

    export interface ISetHighlightedSearchSuggestion extends IAction {
        payload : {
            searchSuggestion : string | null,
        };
    }
    export type ISetHighlightedSearchSuggestionActionCreator = (searchSuggestion : string | null) => ISetHighlightedSearchSuggestion;

    export interface IAddCustomCatalogItemOption extends IAction {
        payload : {
            catalogItemOption : ICatalogItemOption,
            catalogItemId : CatalogItemId,
        };
    }

    export interface ISetCatalogItemComponentIsShown extends IAction {
        payload : {
            catalogItemId : CatalogItemId,
            component : CatalogItemComponent,
            isShown : boolean,
        };
    }

    export interface ISetOnGetInitialDataFromContext extends  IAction {
        payload : {
            onGetInitialDataFromContext? : () => StringValueMap<ProductId, Product>;
        };
    }
    export type ISetOnGetInitialDataFromContextCreator = (
        onGetInitialDataFromContext? : () => StringValueMap<ProductId, Product>
    ) => ISetOnGetInitialDataFromContext;

    export type IFetchSearchResults = (
        retailerId : LocationModel.LocationIdentifier,
    ) => ThunkAction<Promise<void>, IAddItemProps, ActionInterfaces.IThunkServices>;

    export type IAddItemsToEntity = (
        retailerId : LocationModel.LocationIdentifier,
    ) => ThunkAction<Promise<IAddItemResult>, IAddItemProps, ActionInterfaces.IThunkServices>;

    export type IGetInitialData = () => ThunkAction<Promise<void>, IAddItemProps, ActionInterfaces.IThunkServices>;

    export type ISetPageOffsetAndFetchProducts = (
        retailerId : LocationModel.LocationIdentifier,
        pageOffset : number
    ) => ThunkAction<Promise<void>, IAddItemProps, ActionInterfaces.IThunkServices>;

    export type ISetAddedProductsNotificationShown = (
        isShown : boolean,
        numberAdded : number
    ) => ThunkAction<void, IAddItemProps, ActionInterfaces.IThunkServices>;

    export interface IServices {
        productService : IProductService;
        categoryService : ICategoryService;
        cartService : ICartService;
        userSessionReader : IAccountSessionReader<UserSessionId, UserAccountId>;
        productDistributorService : IProductDistributorService;
        distributorService : DistributorService;
        productAndCatalogSearchService : IProductAndCatalogSearchService;
        locationProductService : ILocationProductService;
        userAccountInfoReader : IUserAccountInfoReader;
        userAccountInfoWriter : IUserAccountInfoWriter;
    }

    export interface IThunkServices extends IExtraArguments {
        services : IServices;
    }
}

const setProductRowExpanded : ActionInterfaces.ISetProductRowExpandedActionCreator = (
    productId : ProductId,
    isExpanded : boolean
) : ActionInterfaces.ISetProductRowExpandedAction => ({
    payload: {
        productId,
        isExpanded,
    },
    type: ActionTypes.SET_PRODUCT_ROW_EXPANDED,
});

const setProductRowSelected : ActionInterfaces.ISetRowSelectedActionCreator = (
    rowId : IPricedProductPackageRowId,
    isSelected : boolean
) : ActionInterfaces.ISetProductPackageRowSelected => ({
    payload: {
        rowId,
        isSelected
    },
    type: ActionTypes.SET_PRODUCT_ROW_SELECTED,
});

const setCatalogRowSelected = (
    rowId : ICatalogItemOptionRowId,
    isSelected : boolean
) : ActionInterfaces.ISetCatalogOptionRowSelected => ({
    payload: {
        rowId,
        isSelected
    },
    type: ActionTypes.SET_CATALOG_ROW_SELECTED,
});

const clearAllSelections = (
) : ActionInterfaces.IClearAllSelections => ({
    payload: {},
    type: ActionTypes.SET_CLEAR_ALL_SELECTIONS,
});

const setComponentIsShown : ActionInterfaces.ISetComponentIsShownActionCreator = (
    component : AddItemModalComponent,
    isShown : boolean
) : ActionInterfaces.ISetComponentIsShownAction => ({
    payload: {
        component,
        isShown,
    },
    type: ActionTypes.SET_COMPONENT_IS_SHOWN,
});

const setItemsSelectedMenuIsOpen : ISetShownActionCreator = (
    isShown : boolean
) : ISetShownAction => ({
    payload: {
        isShown,
    },
    type: ActionTypes.SET_ITEMS_SELECTED_MENU_IS_OPEN,
});

const setLoading : ISetShownActionCreator = (
    isShown : boolean
) : ISetShownAction => ({
    payload: {
        isShown
    },
    type: ActionTypes.SET_LOADING,
});

const setIsLoadingSearchResults = (
    isLoading : boolean,
) : ActionInterfaces.ISetLoading => ({
    payload: {
        isLoading
    },
    type: ActionTypes.SET_LOADING_SEARCH_RESULTS,
});

const setDisplayedProductIds : ActionInterfaces.ISetDisplayedProductIdsActionCreator = (
    productIds : Array<ProductId>,
) : ActionInterfaces.ISetDisplayedProductIdsAction => ({
    payload: {
        productIds,
    },
    type: ActionTypes.SET_DISPLAYED_PRODUCT_IDS
});

const setDisplayedCatalogItemIds : ActionInterfaces.ISetDisplayedCatalogItemIdsActionCreator = (
    catalogItemIds : Array<CatalogItemId>,
) : ActionInterfaces.ISetDisplayedCatalogItemIds => ({
    payload: {
        catalogItemIds,
    },
    type: ActionTypes.SET_DISPLAYED_CATALOG_ITEM_IDS,
});

const addProductRowInfoByPricedProductId : ActionInterfaces.IAddProductRowInfoByPricedProductIdActionCreator = (
    pricedProductInfoByProductIdValue : IPricedProductInfoByProductIdValue
) : ActionInterfaces.IAddProductRowInfoByPricedProductIdAction => ({
    payload: {
        pricedProductInfoByProductIdValue,
    },
    type: ActionTypes.ADD_PRODUCT_ROW_INFO_BY_PRICED_PRODUCT_ID
});

const setCatalogItemsById : ActionInterfaces.ISetCatalogItemsByIdActionCreator = (
    catalogItemsById : StringValueMap<CatalogItemId, CatalogItem>
) : ActionInterfaces.ISetCatalogItemsById => ({
    payload: {
        catalogItemsById,
    },
    type: ActionTypes.SET_CATALOG_ITEMS_BY_ID,
});

const setAddItemData : ActionInterfaces.ISetAddItemDataActionCreator = (
    addItemData : IAddItemData,
) : ActionInterfaces.ISetAddItemData => ({
    payload: {
        addItemData,
    },
    type: ActionTypes.SET_ADD_ITEM_DATA,
});

const setSearchTerm : ActionInterfaces.ISetSearchTermActionCreator = (
    searchTerm : string | null
) : ActionInterfaces.ISetSearchTermAction => ({
    payload: {
        searchTerm
    },
    type: ActionTypes.SET_SEARCH_TERM,
});

const setActiveSearchTerm : ActionInterfaces.ISetActiveSearchTermActionCreator = (
    activeSearchTerm : string | null
) : ActionInterfaces.ISetActiveSearchTermAction => ({
    payload: {
        activeSearchTerm
    },
    type: ActionTypes.SET_ACTIVE_SEARCH_TERM
});

const setAddItemToEntityButtonText : ActionInterfaces.ISetAddItemToEntityButtonTextActionCreator = (
    value : string
) : ActionInterfaces.ISetAddItemToEntityButtonTextAction => ({
    payload: {
        value
    },
    type: ActionTypes.SET_ADD_ITEM_TO_ENTITY_BUTON_TEXT
});

const setContextType : ActionInterfaces.ISetContextTypeActionCreator = (
    contextType : AddItemModalContextType
) : ActionInterfaces.ISetContextTypeAction => ({
    payload: {
        contextType,
    },
    type: ActionTypes.SET_CONTEXT_TYPE,
});

const addProductIdsToProductIdsInEntity : ActionInterfaces.IAddProductIdsToProductIdsInEntityActionCreator = (
    pricedProductPackageRowIdValues : Array<IPricedProductPackageRowId>
) : ActionInterfaces.IAddProductIdsToProductIdsInEntityAction => ({
    payload: {
        pricedProductPackageRowIdValues,
    },
    type: ActionTypes.ADD_PRODUCT_IDS_TO_PRODUCT_IDS_IN_ENTITY,
});

const removeProductIdsFromProductIdsInEntity : ActionInterfaces.IRemoveProductIdsFromProductIdsInEntityActionCreator = (
    pricedProductPackageRowIdValues : Array<IPricedProductPackageRowId>
) : ActionInterfaces.IRemoveProductIdsFromProductIdsInEntityAction => ({
    payload: {
        pricedProductPackageRowIdValues,
    },
    type: ActionTypes.REMOVE_PRODUCT_IDS_FROM_PRODUCT_IDS_IN_ENTITY,
});

const addCatalogItemsInEntity : ActionInterfaces.IAddCatalogItemsInEntityActionCreator = (
    catalogItemSelections : Array<ICatalogItemOptionRowId>
) : ActionInterfaces.IAddCatalogItemsInEntity => ({
    payload: {
        catalogItemSelections,
    },
    type: ActionTypes.ADD_CATALOG_ITEMS_IN_ENTITY,
});

const clearProductIdsFromEntity : ActionInterfaces.IClearProductIdsInEntityActionCreator = (
) : ActionInterfaces.IClearProductIdsInEntityAction => ({
   payload: {},
   type: ActionTypes.CLEAR_PRODUCT_IDS_IN_ENTITY,
});

const setPageOffset : ActionInterfaces.ISetPageOffsetActionCreator = (
    offset : number
) : ActionInterfaces.ISetPageOffsetAction => ({
    payload: {
        offset
    },
    type: ActionTypes.SET_PAGE_OFFSET,
});

const setResultsPerPage : ActionInterfaces.ISetResultsPerPageActionCreator = (
    resultsPerPage : number
) : ActionInterfaces.ISetResultsPerPageAction => ({
    payload: {
        resultsPerPage,
    },
    type: ActionTypes.SET_RESULTS_PER_PAGE,
});

const setTotalNumberOfCatalogItems : ActionInterfaces.ISetTotalNumberOfCatalogItemsActionCreator = (
    total : number
) : ActionInterfaces.ISetTotalNumberOfCatalogItemsAction => ({
    payload: {
        total
    },
    type: ActionTypes.SET_TOTAL_NUMBER_OF_CATALOG_ITEMS
});

const setTotalNumberOfProducts : ActionInterfaces.ISetTotalNumberOfProductsActionCreator = (
    total : number
) : ActionInterfaces.ISetTotalNumberOfProducts => ({
    payload: {
        total
    },
    type: ActionTypes.SET_TOTAL_NUMBER_OF_PRODUCTS
});

const setSelectedProductIds : ActionInterfaces.ISetSelectedProductIdsActionCreator = (
    pricedProductPackageRowIdValues : Array<IPricedProductPackageRowId>
) : ActionInterfaces.ISetSelectedProductIdsAction => ({
    payload: {
        pricedProductPackageRowIdValues,
    },
    type: ActionTypes.SET_SELECTED_PRODUCT_IDS,
});

const setNumberProductsAdded : ActionInterfaces.ISetNumberProductsAddedActionCreator = (
    numberAdded : number,
) : ActionInterfaces.ISetNumberProductsAddedAction => ({
    payload: {
        numberAdded,
    },
    type: ActionTypes.SET_NUMBER_PRODUCTS_ADDED,
});

const showAddedProductsNotification : ActionInterfaces.ISetProductsAddedNotificationShownActionCreator = () : ActionInterfaces.ISetProductsAddedNotificationShownAction => ({
    payload: {
        isShown: true,
    },
    type: ActionTypes.SET_ADDED_PRODUCTS_NOTIFICATION_SHOWN,
});

const hideAddedProductsNotification : ActionInterfaces.ISetProductsAddedNotificationShownActionCreator = () : ActionInterfaces.ISetProductsAddedNotificationShownAction => ({
    payload: {
        isShown: false,
    },
    type: ActionTypes.SET_ADDED_PRODUCTS_NOTIFICATION_SHOWN,
});

const setProductIdsLastAddedToEntity : ActionInterfaces.ISetProductIdsLastAddedToEntityActionCreator = (
    productIdsAdded : Array<IPricedProductPackageRowId>
) : ActionInterfaces.ISetProductIdsLastAddedToEntityAction => ({
    payload: {
        productIdsAdded,
    },
    type: ActionTypes.SET_PRODUCT_IDS_LAST_ADDED_TO_ENTITY
});

const setSearchBarIsFocused : ActionInterfaces.ISetSearchBarIsFocusedActionCreator = (
    isFocused : boolean,
) : ActionInterfaces.ISetSearchBarIsFocusedAction => ({
    payload: {
        isFocused
    },
    type: ActionTypes.SET_SEARCH_BAR_FOCUS,
});

const setHighlightedSearchSuggestion : ActionInterfaces.ISetHighlightedSearchSuggestionActionCreator = (
    searchSuggestion : string | null
) : ActionInterfaces.ISetHighlightedSearchSuggestion => ({
    payload: {
        searchSuggestion,
    },
    type: ActionTypes.SET_HIGHLIGHTED_SEARCH_SUGGESTION,
});

const setSearchSuggestions : ActionInterfaces.ISetSearchSuggestionsActionCreator = (
    searchSuggestions : Array<string>
) : ActionInterfaces.ISetSearchSuggestions => ({
    payload: {
        searchSuggestions,
    },
    type: ActionTypes.SET_SEARCH_SUGGESTIONS,
});

const setCatalogItemComponentIsShown = (
    catalogItemId : CatalogItemId,
    component : CatalogItemComponent,
    isShown : boolean,
) : ActionInterfaces.ISetCatalogItemComponentIsShown => ({
    payload: {
        catalogItemId,
        component,
        isShown,
    },
    type: ActionTypes.SET_CATALOG_ITEM_COMPONENT_IS_SHOWN,
});

const addCustomCatalogItemOption = (
    catalogItemOption : ICatalogItemOption,
    catalogItemId : CatalogItemId,
) : ActionInterfaces.IAddCustomCatalogItemOption => ({
    payload: {
        catalogItemId,
        catalogItemOption,
    },
    type: ActionTypes.ADD_CUSTOM_CATALOG_ITEM_OPTION,
});

const setOnGetInitialDataFromContextCreator : ActionInterfaces.ISetOnGetInitialDataFromContextCreator = (
    onGetInitialDataFromContext? : () => StringValueMap<ProductId, Product>,
) : ActionInterfaces.ISetOnGetInitialDataFromContext => ({
    payload: {
        onGetInitialDataFromContext,
    },
    type: ActionTypes.SET_ON_GET_INITIAL_DATA_FROM_CONTEXT,
});

//// Thunks ////
const getInitialData : ActionInterfaces.IGetInitialData = (
) : (ThunkAction<Promise<void>, IAddItemProps, ActionInterfaces.IThunkServices>) => {
    return (
        dispatch : Dispatch<IAddItemProps>,
        getState : () => IAddItemProps,
        extraArguments : ActionInterfaces.IThunkServices
    ) : Promise<void> => {
        dispatch(setLoading(true));
        const state = getState().addItemState;

        const retailerId = new LocationModel.LocationIdentifier({ value: window.GLOBAL_RETAILER_ID });
        const sessionId = extraArguments.services.userSessionReader.getSessionId();
        const locationId = new LocationId(retailerId.value);
        const onGetInitialDataFromContext = state.onGetInitialDataFromContext;

        let relevantProductsById : StringValueMap<ProductId, Product>;

        let doIterateProductPackages : boolean = true;
        const generateEntityPricedProductInfoByProductIdValue = (productIds : Array<ProductId>, productsById : StringValueMap<ProductId, Product>) => {
            return extraArguments.services.productDistributorService.retrieveDistributorIdsByProductIdForProductIds(sessionId, productIds)
            .then((distributorIdsByProductId) => {
                return convertProductsToProductRowInfoByProductId(productIds, productsById, distributorIdsByProductId, doIterateProductPackages);
            });
        };

        let fetchEntityProducts : Promise<IPricedProductInfoByProductIdValue>;
        switch (state.contextType) {
            case AddItemModalContextType.ONBOARDING_SALES:
            case AddItemModalContextType.ITEM_MANAGER:
                dispatch(clearProductIdsFromEntity());
                fetchEntityProducts = extraArguments.services.locationProductService.getActiveProductIds(sessionId, locationId)
                .then((resultProductIdSet) => {
                    return extraArguments.services.productService.getProductsById(sessionId, locationId, resultProductIdSet)
                    .then((productsByIdResult) => {
                        relevantProductsById = productsByIdResult.productsById;
                        return generateEntityPricedProductInfoByProductIdValue(Array.from(resultProductIdSet.values()), productsByIdResult.productsById);
                    });
                });
                break;
            case AddItemModalContextType.INVENTORY_COUNT:
                if (!onGetInitialDataFromContext) {
                    throw new RuntimeException('AddItemActions getInitialData called without onGetInitialDataFromContext set.');
                }
                dispatch(clearProductIdsFromEntity());
                const initialDataForContext = onGetInitialDataFromContext();
                relevantProductsById = initialDataForContext;
                fetchEntityProducts = generateEntityPricedProductInfoByProductIdValue(Array.from(initialDataForContext.keys()), initialDataForContext);
                break;
            case AddItemModalContextType.CART_BUILDER:
                doIterateProductPackages = true;
                fetchEntityProducts = extraArguments.services.cartService.getCartForLocation(sessionId, locationId)
                .then((resultCart) => {
                    const resultProductIdSet = CartUtils.getProductIdsInCart(resultCart);
                    return extraArguments.services.productService.getProductsById(sessionId, locationId, resultProductIdSet)
                    .then((productsByIdResult) => {
                        relevantProductsById = productsByIdResult.productsById;
                        return generateEntityPricedProductInfoByProductIdValue(Array.from(resultProductIdSet.values()), productsByIdResult.productsById);
                    });
                });
                break;
            case AddItemModalContextType.ORDER_DETAIL:
                if (!onGetInitialDataFromContext) {
                    throw new RuntimeException('AddItemActions getInitialData called without onGetInitialDataFromContext set.');
                }
                dispatch(clearProductIdsFromEntity());
                doIterateProductPackages = false;
                const orderDetailInitialDataForContext = onGetInitialDataFromContext();
                relevantProductsById = orderDetailInitialDataForContext;
                fetchEntityProducts = generateEntityPricedProductInfoByProductIdValue(Array.from(orderDetailInitialDataForContext.keys()), orderDetailInitialDataForContext);
                break;
            default:
                throw new RuntimeException('Unknown contextType for AddItemView');
        }

        const promises = [
            Promise.all([
                extraArguments.services.distributorService.getAvailableDistributorsByIdForLocation(sessionId, locationId),
                extraArguments.services.categoryService.getCategoriesForRetailer(sessionId, locationId),
            ])
            .then(([distributorsById, categoriesById]) => {
                distributorsById.set(new DistributorId('_distributor_id_for_other'), new Distributor(Scope.createGlobalScope(), 'Other', 'Other'));
                dispatch(setAddItemData({
                    distributorsById,
                    categoriesById,
                    productsById: relevantProductsById,
                }));
            }),
            fetchEntityProducts.then((pricedProductInfoByProductIdValue) => {
                const pricedProductPackageRowIdValues : Array<IPricedProductPackageRowId> = [];
                Object.keys(pricedProductInfoByProductIdValue).forEach((productIdValue) => {
                    Object.keys(pricedProductInfoByProductIdValue[productIdValue].productPackageRowInfoByAttribute).forEach((attribute) => {
                        pricedProductPackageRowIdValues.push({
                            pricedProductIdValue: productIdValue,
                            productPackageRowInfoAttribute: attribute
                        });
                    });
                });
                dispatch(addProductIdsToProductIdsInEntity(pricedProductPackageRowIdValues));
            })
        ];

        return Promise.all(promises)
        .then(() => {
            dispatch(AddItemActions.fetchSearchResults(retailerId));  // need this to render filters even if "empty" state should display
            dispatch(setLoading(false));
        })
        .catch((error : Error) => {
            dispatch(setLoading(false));
            throw new RuntimeException(error.message);
        });
    };
};

const setPageOffsetAndFetchProducts : ActionInterfaces.ISetPageOffsetAndFetchProducts = (
    retailerId : LocationModel.LocationIdentifier,
    pageOffset : number
) : ThunkAction<Promise<void>, IAddItemProps, ActionInterfaces.IThunkServices> => {
    return (dispatch : Dispatch<IAddItemProps>, getState : () => IAddItemProps, extraArguments : ActionInterfaces.IThunkServices) : Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            dispatch(setPageOffset(pageOffset));

            /**
             * Ben Leichter (May 9, 2017)
             * We reference fetchProductAndCatalogData from the AddItemActions module in
             * order to be able to stub it in our tests. There are a lot of functions that
             * use fetchProductAndCatalogData and it resulted in a lot of duplicate testing.
             */
            return dispatch(AddItemActions.fetchSearchResults(retailerId))
            .then(() => {
                resolve();
            })
            .catch((error) => {
                reject(error);
            });
        });
    };
};

export const convertProductsToProductRowInfoByProductId = (
    productIds : Array<ProductId>,
    productsById : StringValueMap<ProductId, Product>,
    distributorIdsByProductId : StringValueMap<ProductId, DistributorId | null>,
    doIterateProductPackages : boolean
) => {
    const pricedProductInfoByProductIdValueToAdd : IPricedProductInfoByProductIdValue = {};
    productIds.forEach((productId) => {
        const product = productsById.getRequired(productId);
        const distributorId = distributorIdsByProductId.get(productId) || null;

        const id = productId.getValue();
        const productPackageRowInfoByAttribute : IProductPackageRowInfoByAttribute = pricedProductInfoByProductIdValueToAdd[id] ? pricedProductInfoByProductIdValueToAdd[id].productPackageRowInfoByAttribute : {};
        iteratePackageRows(productPackageRowInfoByAttribute, productId, product, product.getPackagingsAndMappings().getPackaging(), distributorId, doIterateProductPackages);

        pricedProductInfoByProductIdValueToAdd[productId.getValue()] = {
            productPackageRowInfoByAttribute,
        };
    });

    return pricedProductInfoByProductIdValueToAdd;
};

let latestFetchSearchResultsRequestId = 0;
const fetchSearchResults : ActionInterfaces.IFetchSearchResults = (
    retailerId : LocationModel.LocationIdentifier,
) : ThunkAction<Promise<void>, IAddItemProps, ActionInterfaces.IThunkServices> => {
    return (dispatch : Dispatch<IAddItemProps>, getState : () => IAddItemProps, extraArguments : ActionInterfaces.IThunkServices) : Promise<void> => {
        const {
            pageOffset,
            searchTerm,
            resultsPerPage,
            customOptionsByCatalogItemId,
            catalogItemsById,
            addItemData,
        } = getState().addItemState;

        dispatch(setIsLoadingSearchResults(true));
        latestFetchSearchResultsRequestId += 1;
        const currentRequestId = latestFetchSearchResultsRequestId;

        const userSessionId = extraArguments.services.userSessionReader.getSessionId();
        const locationId = new LocationId(window.GLOBAL_RETAILER_ID);

        const inclusiveStartIndex = resultsPerPage * pageOffset;

        const catalogSearchPromise = window.GLOBAL_FEATURE_ACCESS.catalog ? extraArguments.services.productAndCatalogSearchService.searchCatalogItems(
            resultsPerPage,
            inclusiveStartIndex,
            searchTerm || '',
            {},
        ) : Promise.resolve(null);

        let pricedProductInfoByProductIdValueToAdd : IPricedProductInfoByProductIdValue = {};

        const distributorsById = addItemData ? addItemData.distributorsById : new StringValueMap<DistributorId, Distributor>();

        return extraArguments.services.locationProductService.getProductIds(userSessionId, new LocationId(retailerId.value))
        .then((allProductIdsInRetailer) => {
            // show empty state if account is empty (independent of search args)
            dispatch(setComponentIsShown('emptyState', allProductIdsInRetailer.size === 0));

            return Promise.all([
                extraArguments.services.locationProductService.getProductsForLocationMatchingSearchParameters(
                    userSessionId,
                    locationId,
                    resultsPerPage,
                    inclusiveStartIndex,
                    searchTerm || '',
                    {},
                ),
                catalogSearchPromise,
            ])
            .then((response) => {
                if (currentRequestId !== latestFetchSearchResultsRequestId) {
                    return;
                }

                const myItemsResponse = response[0];
                const catalogItemsResponse = response[1];

                if (catalogItemsResponse === null) {
                    return Promise.all([
                        extraArguments.services.productDistributorService.retrieveDistributorIdsByProductIdForProductIds(userSessionId, myItemsResponse.productIds),
                        extraArguments.services.categoryService.getCategoriesForRetailer(userSessionId, locationId),
                    ])
                    .then(([distributorIdsByProductId, categoriesById]) => {
                        pricedProductInfoByProductIdValueToAdd = {
                            ...pricedProductInfoByProductIdValueToAdd,
                            ...convertProductsToProductRowInfoByProductId(myItemsResponse.productIds, myItemsResponse.productsById, distributorIdsByProductId, true),
                        };

                        const newProductsById = addItemData ? new StringValueMap(addItemData.productsById) : new StringValueMap<ProductId, Product>();
                        myItemsResponse.productsById.forEach((product, productId) => {
                            newProductsById.set(productId, product);
                        });

                        // TODO clezeaka batch calls
                        dispatch(setAddItemData({
                            distributorsById,
                            categoriesById,
                            productsById: newProductsById,
                        }));
                        dispatch(addProductRowInfoByPricedProductId(pricedProductInfoByProductIdValueToAdd));
                        dispatch(setDisplayedProductIds(myItemsResponse.productIds));
                        dispatch(setTotalNumberOfProducts(myItemsResponse.matchCount));
                        dispatch(setActiveSearchTerm(searchTerm));
                        dispatch(setIsLoadingSearchResults(false));
                    });
                } else {
                    // keep already fetched catalog items
                    const updatedCatalogItemsByIdWithCustomOptions : StringValueMap<CatalogItemId, CatalogItem> = new StringValueMap(catalogItemsById);
                    catalogItemsResponse.catalogItemsById.forEach((newCatalogItem : CatalogItem, catalogItemId : CatalogItemId) => {
                        updatedCatalogItemsByIdWithCustomOptions.set(catalogItemId, newCatalogItem);
                    });
                    customOptionsByCatalogItemId.forEach((customOptions, catalogItemId) => {
                        const catalogItem = updatedCatalogItemsByIdWithCustomOptions.get(catalogItemId);
                        if (typeof catalogItem !== 'undefined') {
                            customOptions.forEach((customOption) => catalogItem.getOptions().push(customOption));
                        }
                        // ok if current search result doesn't contain the catalog item which user has created custom options for
                    });

                    const catalogItemIds = catalogItemsResponse.sortedCatalogItemsIds;

                    return Promise.all([
                        extraArguments.services.productDistributorService.retrieveDistributorIdsByProductIdForProductIds(userSessionId, myItemsResponse.productIds),
                        extraArguments.services.categoryService.getCategoriesForRetailer(userSessionId, locationId),
                    ])
                    .then(([distributorIdsByProductId, categoriesById]) => {
                        pricedProductInfoByProductIdValueToAdd = {
                            ...pricedProductInfoByProductIdValueToAdd,
                            ...convertProductsToProductRowInfoByProductId(myItemsResponse.productIds, myItemsResponse.productsById, distributorIdsByProductId, true),
                        };

                        const newProductsById = addItemData ? new StringValueMap(addItemData.productsById) : new StringValueMap<ProductId, Product>();
                        myItemsResponse.productsById.forEach((product, productId) => {
                            newProductsById.set(productId, product);
                        });

                        // TODO clezeaka batch calls
                        dispatch(setAddItemData({
                            distributorsById,
                            categoriesById,
                            productsById: newProductsById,
                        }));
                        dispatch(addProductRowInfoByPricedProductId(pricedProductInfoByProductIdValueToAdd));
                        dispatch(setCatalogItemsById(updatedCatalogItemsByIdWithCustomOptions));
                        dispatch(setDisplayedProductIds(myItemsResponse.productIds));
                        dispatch(setTotalNumberOfCatalogItems(catalogItemsResponse.totalMatchCount));
                        dispatch(setTotalNumberOfProducts(myItemsResponse.matchCount));

                        // todolater: this is a bug - catalog items omitted from current page can not show on next page either
                        // Determine how to set the catalog item ids
                        if (searchTerm !== null && searchTerm.length > 0) {
                            if (myItemsResponse.productIds.length === 0) {
                                dispatch(setDisplayedCatalogItemIds(catalogItemIds));
                            } else if (myItemsResponse.productIds.length < 30) {
                                dispatch(setDisplayedCatalogItemIds(catalogItemIds.slice(0, (30 - myItemsResponse.productIds.length))));
                            } else {
                                dispatch(setDisplayedCatalogItemIds([]));
                            }
                        } else {
                            dispatch(setDisplayedCatalogItemIds(catalogItemIds));
                        }

                        dispatch(setActiveSearchTerm(searchTerm));
                        dispatch(setIsLoadingSearchResults(false));
                    });
                }
            })
            .catch((error) => {
                dispatch(setIsLoadingSearchResults(false));
                throw error;
            });
        });
    };
};

const setAddedProductsNotificationShown : ActionInterfaces.ISetAddedProductsNotificationShown = (
    isShown : boolean,
    numberAdded : number
) : ThunkAction<void, IAddItemProps, ActionInterfaces.IThunkServices> => {
    return (dispatch : Dispatch<IAddItemProps>, getState : () => IAddItemProps, extraArguments : ActionInterfaces.IThunkServices) : void => {
        if (isShown) {
            dispatch(setNumberProductsAdded(numberAdded));
            dispatch(showAddedProductsNotification());
            setTimeout(() => dispatch(hideAddedProductsNotification()), 5000);
        } else {
            dispatch(hideAddedProductsNotification());
        }
    };
};

const addItemsToEntity : ActionInterfaces.IAddItemsToEntity = (
    retailerId : LocationModel.LocationIdentifier,
) : ThunkAction<Promise<IAddItemResult>, IAddItemProps, ActionInterfaces.IThunkServices> => {
    return (dispatch : Dispatch<IAddItemProps>, getState : () => IAddItemProps, extraArguments : ActionInterfaces.IThunkServices) => {
        const {
            addItemData,
            contextType,
            pricedProductInfoByProductIdValue,
            catalogItemsById,
            selections,
            displayedProductIds,
            isShownByComponentName,
        } = getState().addItemState;

        const sessionId = extraArguments.services.userSessionReader.getSessionId();
        const locationId = new LocationId(window.GLOBAL_RETAILER_ID);

        dispatch(setLoading(true));

        let addProductToEntity : (productId : ProductId, productQuantityUnit : ProductQuantityUnit) => Promise<void> | void;
        let unitSensitive : boolean;
        switch (contextType) {
            case AddItemModalContextType.ONBOARDING_SALES:
            case AddItemModalContextType.ITEM_MANAGER:
                addProductToEntity = (productId, productQuantityUnit) => {
                    return extraArguments.services.locationProductService.setProductAsActive(sessionId, locationId, productId)
                    .then(() => {
                        $(document).trigger('ajaxSearchActionClicked', { productId: productId.getValue() });
                    });
                };
                unitSensitive = false;
                Observer.observeAction('add_item_modal_add_products_to_inventory', { number_of_items_to_add : selections.length });
                break;
            case AddItemModalContextType.INVENTORY_COUNT:
                addProductToEntity = ((productId, productQuantityUnit) => {
                    return extraArguments.services.locationProductService.setProductAsActive(sessionId, locationId, productId);
                });
                unitSensitive = false;
                Observer.observeAction('add_item_modal_add_products_to_inventory_count', { number_of_items_to_add : selections.length });
                break;
            case AddItemModalContextType.CART_BUILDER:
                addProductToEntity = (productId, productQuantityUnit) => {
                    return extraArguments.services.locationProductService.setProductAsActive(sessionId, locationId, productId);
                };
                unitSensitive = false;
                Observer.observeAction('add_item_modal_add_products_to_cart', { number_of_items_to_add : selections.length });
                break;
            case AddItemModalContextType.ORDER_DETAIL:
                addProductToEntity = (productId, productQuantityUnit) => {
                    // $(document).trigger('addProductToOrderDetail', [{ productId: productId.getValue(), isCase: unit === PackagingUnit.CASE }]); TODO Cheezy
                };
                unitSensitive = true;
                Observer.observeAction('add_item_modal_add_products_to_order', { number_of_items_to_add : selections.length });
                break;
            default:
                dispatch(setIsLoadingSearchResults(false));
                throw new RuntimeException('Unknown contextType for AddItemView');
        }

        const addProductsToEntity = (productIds : Array<ProductId>, productQuantityUnits : Array<ProductQuantityUnit>) => {
            return Promise.all(productIds.map((productId, index) => {
                return addProductToEntity(productId, productQuantityUnits[index]);
            }));
        };

        const selectedProductIds : Array<ProductId> = [];
        const selectedProductUnits : Array<ProductQuantityUnit> = [];   // the unit selected for the product in `selectedProductIds` at the same index...
        const selectedCatalogItemProducts : Array<Product> = [];
        const selectedCatalogItemUnits : Array<ProductQuantityUnit> = [];
        const categoriesById = addItemData ? addItemData.categoriesById : new StringValueMap<CategoryId, Category>();
        const catalogProductsWaitingForCategoryCreationByCategoryName : Map<string, Array<Product>> = new Map();
        const categoryPromises : Array<Promise<void>> = [];
        // assumes that selection list does not contain duplicates...
        selections.forEach((selection) => {
            if (utils.isCatalogItemOptionRowId(selection)) {
                const catalogItem = catalogItemsById.get(selection.catalogItemId);
                if (typeof catalogItem === 'undefined') {
                    throw new RuntimeException('unknown catalog item: ' + selection.catalogItemId);
                }
                const userAccountIdAndTimestamp = new UserAccountIdAndTimestamp(new UserAccountId('bevspot_system'), DateTime.now().toTimestampWithMillisecondPrecision());
                const selectedPackaging = catalogItem.getOptions()[selection.optionIndex].packaging;
                const selectedProductQuantityUnit = selectedPackaging.getPackagingId() || selectedPackaging.getUnit();
                if (selectedProductQuantityUnit === null) {
                    throw new RuntimeException('selectedProductQuantityUnit is unexpectedly null');
                }

                const categoryName = catalogItem.getProductCategoryId();
                const categoryId = CategoryUtils.getCategoryIdFromName(
                    categoriesById,
                    categoryName,
                );
                const productWithDummyPackaging = new Product(
                    catalogItem.getBrand(),
                    catalogItem.getName(),
                    new PackagingsAndMappings(
                        oldPackagingUtils.getPackagingDataFromPackagingArray([selectedPackaging]),
                        new Mappings(new StringValueMap(), new Map()),
                        new Conversions(PackagingUtils.getBaseUnitOfPackaging(selectedPackaging), new StringValueMap(), new Map())
                    ),
                    PackagingUtils.getContainerPackagingId(selectedPackaging),
                    new StringValueMap(),
                    catalogItem.getProductCategoryId(),
                    categoryId,
                    catalogItem.getProductType(),
                    new Price(0, oldPackagingUtils.getOldPackagingFromPackaging(selectedPackaging).getUnit()),
                    0,
                    '',
                    '',
                    '',
                    userAccountIdAndTimestamp,
                );

                if (categoryId !== null) {
                    selectedCatalogItemProducts.push(ProductFormUtils.getProductDistributorAndParForEditOrCreate(productWithDummyPackaging, null, null, null, null).product);
                } else {
                    if (catalogProductsWaitingForCategoryCreationByCategoryName.has(categoryName)) {
                        const products = catalogProductsWaitingForCategoryCreationByCategoryName.get(categoryName) || [];
                        products.push(productWithDummyPackaging);
                        catalogProductsWaitingForCategoryCreationByCategoryName.set(categoryName, products);
                    } else {
                        catalogProductsWaitingForCategoryCreationByCategoryName.set(categoryName, [productWithDummyPackaging]);
                        // For each new category, associate the new category id with all products requiring it after creation
                        categoryPromises.push(Promise.resolve(
                            extraArguments.services.categoryService.createCategory(
                                sessionId,
                                locationId,
                                new Category(
                                    catalogItem.getProductCategoryId(),
                                    '',
                                    false,
                                    ''
                                ),
                            ).then((categoryResult) => {
                                const catalogProducts = catalogProductsWaitingForCategoryCreationByCategoryName.get(categoryName) || [];
                                catalogProducts.forEach((product) => {
                                    selectedCatalogItemProducts.push(
                                        productUtils.getProductWithNewCategory(product, categoryResult.category_id, categoryName)
                                    );
                                });
                                categoriesById.set(
                                  categoryResult.category_id,
                                  new Category(
                                      catalogItem.getProductCategoryId(),
                                      '',
                                      false,
                                      categoryResult.category_hash,
                                  )
                                );
                            })
                        ));
                    }
                }

                selectedCatalogItemUnits.push(selectedProductQuantityUnit);
            } else {
                const pricedProductInfo = pricedProductInfoByProductIdValue[selection.pricedProductIdValue];
                if (typeof pricedProductInfo === 'undefined') {
                    throw new RuntimeException('pricedProduct not found in pricedProductInfoByProductIdValue');
                }
                const productPackageRowInfo = pricedProductInfo.productPackageRowInfoByAttribute[selection.productPackageRowInfoAttribute];
                if (typeof productPackageRowInfo === 'undefined') {
                    throw new RuntimeException('productPackageRowInfo not found in productPackageRowInfoByAttribute');
                }
                selectedProductIds.push(new ProductId(selection.pricedProductIdValue));

                const selectedProductQuantityUnit = productPackageRowInfo.packaging.getPackagingId() || productPackageRowInfo.packaging.getUnit();
                if (selectedProductQuantityUnit === null) {
                    throw new RuntimeException('selectedProductQuantityUnit is unexpectedly null');
                }
                selectedProductUnits.push(selectedProductQuantityUnit);
            }
        });



        return Promise.all([
            addProductsToEntity(selectedProductIds, selectedProductUnits),
            Promise.resolve(categoryPromises).then(() => {
                return Promise.resolve(extraArguments.services.productService.createProducts(sessionId, locationId, selectedCatalogItemProducts))
                .then((newProductIds) => {
                    return addProductsToEntity(newProductIds, selectedCatalogItemUnits)
                    .then(() => {
                        return newProductIds;
                    });
                });
            })
        ])
        .then((response) => {
            const newProductIdsFromCatalogItems = response[1];

            const catalogItemsToAddToEntity : Array<ICatalogItemOptionRowId> = [];
            const productIdsToAddToEntity : Array<IPricedProductPackageRowId> = [];
            selections.forEach((selection) => {
                if (utils.isCatalogItemOptionRowId(selection)) {
                    catalogItemsToAddToEntity.push(selection);
                } else {
                    productIdsToAddToEntity.push(selection);
                }
            });

            const newProductsById : StringValueMap<ProductId, Product> = new StringValueMap();  // for updating context display
            const newProductRows : IPricedProductInfoByProductIdValue = {}; // for updating search results - could be overwritten on new search if new product has not yet made into index...

            return extraArguments.services.productService.getProductsById(sessionId, locationId, new StringValueSet(newProductIdsFromCatalogItems))
            .then((productsByIdResult) => {
                productsByIdResult.productsById.forEach((product, productId) => {
                    newProductsById.set(productId, product);

                    const productPackageRowInfoByAttribute : IProductPackageRowInfoByAttribute = {}; // This gets modified
                    iteratePackageRows(productPackageRowInfoByAttribute, productId, product, product.getPackagingsAndMappings().getPackaging(), null, true);
                    // why don't need to disable rows? reducer of addProductIdsToProductIdsInEntity takes care of it! how wonderful!

                    newProductRows[productId.getValue()] = {
                        productPackageRowInfoByAttribute,
                    };

                    if (unitSensitive) {
                        // only the top-level packaging is disabled i.e. "added to entity"
                        productIdsToAddToEntity.push({
                            pricedProductIdValue: productId.getValue(),
                            productPackageRowInfoAttribute: serializeProductPackaging(product.getPackagingsAndMappings().getPackaging()),
                        });
                    } else {
                        // all sub-packagings are "added"
                        Object.keys(productPackageRowInfoByAttribute).forEach((productPackageRowInfoAttribute) => {
                            productIdsToAddToEntity.push({
                                pricedProductIdValue: productId.getValue(),
                                productPackageRowInfoAttribute,
                            });
                        });
                    }
                });

                const distributorsById = addItemData ? addItemData.distributorsById : new StringValueMap<DistributorId, Distributor>();
                const newAddItemDataProductsById = addItemData ? new StringValueMap(addItemData.productsById) : new StringValueMap<ProductId, Product>();
                newProductsById.forEach((product, productId) => {
                    newAddItemDataProductsById.set(productId, product);
                });

                dispatch(setAddItemData({
                    distributorsById,
                    categoriesById,
                    productsById: newAddItemDataProductsById,
                }));

                const actionsToDispatch : Array<IAction> = [];
                // add new rows
                actionsToDispatch.push(addProductRowInfoByPricedProductId(newProductRows));
                actionsToDispatch.push(setDisplayedProductIds(Array.from(newProductsById.keys()).concat(displayedProductIds)));
                if (!isShownByComponentName.collapsibleProductSearchResults) {
                    actionsToDispatch.push(setComponentIsShown('collapsibleProductSearchResults', true));
                }

                // update existing rows
                actionsToDispatch.push(addProductIdsToProductIdsInEntity(productIdsToAddToEntity));
                actionsToDispatch.push(addCatalogItemsInEntity(catalogItemsToAddToEntity));

                // reset state
                actionsToDispatch.push(clearAllSelections());
                actionsToDispatch.push(setItemsSelectedMenuIsOpen(false));
                actionsToDispatch.push(setLoading(false));

                // pass added items to context
                actionsToDispatch.push(setProductIdsLastAddedToEntity(productIdsToAddToEntity));

                dispatch(batchActions(actionsToDispatch));
                dispatch(setAddedProductsNotificationShown(true, selections.length));

                const addedProductIds = new StringValueSet(productIdsToAddToEntity.map((productRowId) => {
                    return new ProductId(productRowId.pricedProductIdValue);
                }));

                return {
                    addedProductIds,
                    newProductsById
                };
            });
        }).catch((error : Error) => {
            dispatch(setLoading(false));
            throw error;
        });
    };
};

let latestUpdateSearchSuggestionsRequestId = 0;
const throttledUpdateSearchSuggestions = _.throttle(
    (dispatch : Dispatch<IAddItemProps>, getState : () => IAddItemProps, extraArguments : ActionInterfaces.IThunkServices) : Promise<void> => {
        const {
            searchTerm,
        } = getState().addItemState;
        latestUpdateSearchSuggestionsRequestId += 1;
        const requestId = latestUpdateSearchSuggestionsRequestId;
        if (searchTerm === '' || searchTerm === null) {
            // clear suggestions - do not call API
            dispatch(setSearchSuggestions([]));
            dispatch(setComponentIsShown('searchSuggestionList', false));
            return Promise.resolve();
        } else {
            return extraArguments.services.productAndCatalogSearchService.getSearchSuggestions(
                new LocationId(window.GLOBAL_RETAILER_ID),
                searchTerm,
                6  // magic number used by legacy Catalog...
            ).then((searchSuggestions : Array<string>) => {
                if (requestId !== latestUpdateSearchSuggestionsRequestId) {
                    return;
                }
                dispatch(setSearchSuggestions(searchSuggestions));
                dispatch(setComponentIsShown('searchSuggestionList', true));
            });
        }
    },
    20,
    { leading : false }
);
const updateSearchSuggestions = () : ThunkAction<Promise<void>, IAddItemProps, ActionInterfaces.IThunkServices> => {
    return throttledUpdateSearchSuggestions;
};

export const AddItemActions = {
    setLoading,
    setProductRowExpanded,
    setProductRowSelected,
    setCatalogRowSelected,
    clearAllSelections,
    setItemsSelectedMenuIsOpen,
    addItemsToEntity,
    setDisplayedCatalogItemIds,
    setDisplayedProductIds,
    setIsLoadingSearchResults,
    addProductRowInfoByPricedProductId,
    setAddItemData,
    getInitialData,
    setSearchTerm,
    setActiveSearchTerm,
    setAddItemToEntityButtonText,
    setContextType,
    addProductIdsToProductIdsInEntity,
    addCatalogItemsInEntity,
    setProductIdsLastAddedToEntity,
    removeProductIdsFromProductIdsInEntity,
    clearProductIdsFromEntity,
    setPageOffset,
    setResultsPerPage,
    setTotalNumberOfCatalogItems,
    setTotalNumberOfProducts,
    setPageOffsetAndFetchProducts,
    setSelectedProductIds,
    setAddedProductsNotificationShown,
    showAddedProductsNotification,
    hideAddedProductsNotification,
    setNumberProductsAdded,
    setSearchBarIsFocused,
    updateSearchSuggestions,
    setHighlightedSearchSuggestion,
    setComponentIsShown,
    setCatalogItemOptionDropdownIsOpen: setCatalogItemComponentIsShown,
    fetchSearchResults, // exported only for testing
    addCustomCatalogItemOption,
    setCatalogItemsById,
    setSearchSuggestions,
    setOnGetInitialDataFromContextCreator,
};
