import { InventoryColumnOption } from 'api/Cart/model/InventoryColumnOption';
import { UsageColumnOption } from 'api/Cart/model/UsageColumnOption';
import { groupByOptionValue, referenceInventoryOptionValue, usageColumnOptionValue } from 'api/Cart/serializer/CartJSONToObjectSerializer';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { GroupByOption } from 'shared/models/GroupByOption';

export class CartObjectToJSONSerializer {
    public getGroupByOption(
        groupByOption : GroupByOption
    ) : groupByOptionValue {
        let value : groupByOptionValue;

        switch (groupByOption) {
            case GroupByOption.ALL_ITEMS:
                value = 'no_grouping';
                break;
            case GroupByOption.DISTRIBUTOR:
                value = 'distributor';
                break;
            case GroupByOption.CATEGORY:
                value = 'product_category_id';
                break;
            case GroupByOption.ITEM_TYPE:
                value = 'product_type';
                break;
            default:
                throw new RuntimeException(`unexpected groupByOption: ${ groupByOption }`);
        }

        return value;
    }

    public getInventoryColumnOption(
        inventoryColumnOption : InventoryColumnOption
    ) : referenceInventoryOptionValue {
        let value : referenceInventoryOptionValue;

        switch (inventoryColumnOption) {
            case InventoryColumnOption.INVENTORY_DRAFT:
                value = 'draft';
                break;
            case InventoryColumnOption.LATEST_FINALIZED_INVENTORY:
                value = 'latest';
                break;
            case InventoryColumnOption.EXPECTED_INVENTORY:
                value = 'expected';
                break;
            default:
                throw new RuntimeException(`unexpected inventoryColumnOption: ${ inventoryColumnOption }`);
        }

        return value;
    }

    public getUsageColumnOption(
        usageColumnOption : UsageColumnOption
    ) : usageColumnOptionValue {
        switch (usageColumnOption) {
            case UsageColumnOption.WEEKLY_USAGE:
                return 'weekly_usage';
            case UsageColumnOption.ACTUAL_USAGE:
                return 'actual_usage';
            case UsageColumnOption.QUANTITY_TO_ORDER:
                return 'quantity_to_order';
            default:
                return 'par';
        }
    }
}
