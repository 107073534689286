import ProductDistributorModel from 'gen-thrift/product_distributor_Model_types';

import { DistributorThriftToObjectSerializer } from 'api/Distributor/serializer/DistributorThriftToObjectSerializer';
import { ProductDistributorAssociation } from 'api/Product/model/ProductDistributorAssociation';
import { ProductThriftToObjectSerializer } from 'api/Product/serializer/ProductThriftToObjectSerializer';

export class ProductDistributorAssociationThriftToObjectSerializer {

    constructor(
        private readonly productThriftToObjectSerializer : ProductThriftToObjectSerializer,
        private readonly distributorThriftToObjectSerializer : DistributorThriftToObjectSerializer
    ) {}

    public getProductDistributorAssociation(
        productDistributorAssociation : ProductDistributorModel.ProductPackagingDistributorAssoc
    ) : ProductDistributorAssociation {
        return new ProductDistributorAssociation(
            this.productThriftToObjectSerializer.getProductId(productDistributorAssociation.productId),
            this.productThriftToObjectSerializer.getProductQuantityUnit(productDistributorAssociation.productQuantityUnit),
            this.distributorThriftToObjectSerializer.getDistributorId(productDistributorAssociation.distributorId),
            productDistributorAssociation.sku,
            productDistributorAssociation.price ? this.productThriftToObjectSerializer.getProductCost(productDistributorAssociation.price) : null,
            productDistributorAssociation.deposit ? this.productThriftToObjectSerializer.getProductCost(productDistributorAssociation.deposit) : null,
        );
    }

    ////////////////////////////////////
    // COLLECTIONS
    ////////////////////////////////////

    public getProductDistributorAssociationsFromArray(
        productDistributorAssociations : Array<ProductDistributorModel.ProductPackagingDistributorAssoc>
    ) : Array<ProductDistributorAssociation> {
        return productDistributorAssociations.map((productDistributorAssociation) => {
            return this.getProductDistributorAssociation(productDistributorAssociation);
        });
    }
}
