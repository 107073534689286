import React from 'react';

import { ValidationInput } from 'shared/components/ValidationInput';

import { DateTime } from '../../models/DateTime';
import { IFormValue } from '../../models/FormValue';

import { IValidationResult } from '../../validators/InputValidators';

import { BevSpotTimePickerUtils } from './utils';

export interface IBevSpotTimePickerMinuteInput {
    dateTime : DateTime;
    formValue : IFormValue<string>;
    setErrorMessage : (errorMessage : string | null) => void;
    setFormValue : (formValue : string, shouldUpdateHourFormValue : boolean) => void;
    setIsValid : (isValid : boolean) => void;
    setSelectedDateTime : (selectedDateTime : DateTime) => void;
}
export class BevSpotTimePickerMinuteInput extends React.Component<IBevSpotTimePickerMinuteInput, object> {
    public render() {
        const {
            formValue,
        } = this.props;
        const disabled = formValue.isValid ? null : ' disabled';
        return (
            <div className="bevspot-time-picker-minute-input">
                <div className={ 'time-picker-button increment-minute ' + disabled } onClick={ this.incrementMinute }>
                    <span className="bevicon bevico-keyboard-arrow-up"/>
                </div>
                <ValidationInput
                    type="number"
                    step={ 1 }
                    label={ null }
                    hintText={ null }
                    value={ (formValue.value !== null) ? formValue.value : '' }
                    autoFocus={ false }
                    autoComplete={ null }
                    isValid={ formValue.isValid }
                    errorMessage=""
                    inputClassName=""
                    handleBlur={ this.validateMinute }
                    handleFocus={ null }
                    handleChange={ this.setMinute }
                    handleEnterClick={ this.returnUndefined }
                    isDisabled={ false }
                />
                <div className={ 'time-picker-button decrement-minute ' + disabled } onClick={ this.decrementMinute }>
                    <span className="bevicon bevico-keyboard-arrow-down" />
                </div>
            </div>
        );
    }
    private returnUndefined = () => undefined;
    private setMinute = (event : React.ChangeEvent<HTMLInputElement>) : void => {
        const {
            formValue,
            setErrorMessage,
            setIsValid,
            setFormValue,
        } = this.props;
        const input = event.currentTarget;
        if (!formValue.isValid) {
            setErrorMessage(null);
            setIsValid(true);
        }
        setFormValue(input.value, false);
    }
    private incrementMinute = () : void => {
        const {
            dateTime,
            formValue,
            setFormValue,
            setSelectedDateTime,
        } = this.props;
        if (formValue.isValid) {
            const dateTimeWithIncrementedMinute : DateTime = BevSpotTimePickerUtils.incrementMinute(dateTime);
            setFormValue(dateTimeWithIncrementedMinute.getValue('mm'), true);
            setSelectedDateTime(dateTimeWithIncrementedMinute);
        }
    }
    private decrementMinute = () : void => {
        const {
            dateTime,
            formValue,
            setFormValue,
            setSelectedDateTime,
        } = this.props;
        if (formValue.isValid) {
            const dateTimeWithDecrementedMinute : DateTime = BevSpotTimePickerUtils.decrementMinute(dateTime);
            setFormValue(dateTimeWithDecrementedMinute.getValue('mm'), true);
            setSelectedDateTime(dateTimeWithDecrementedMinute);
        }
    }
    private validateMinute = (event : React.FocusEvent<HTMLInputElement>) : void => {
        const {
            dateTime,
            setFormValue,
            setErrorMessage,
            setIsValid,
            setSelectedDateTime,
        } = this.props;
        const input = event.currentTarget;
        const inputValue : string = input.value.length === 1 ? '0' + input.value : input.value;
        const validationResult : IValidationResult = BevSpotTimePickerUtils.validateMinute(inputValue);
        setFormValue(inputValue, false);
        if (validationResult.isValid) {
            const newMinute = parseInt(inputValue, 10);
            const dateTimeWithNewMinute : DateTime = BevSpotTimePickerUtils.setMinute(dateTime, newMinute);
            setSelectedDateTime(dateTimeWithNewMinute);
        } else {
            setErrorMessage(validationResult.errorMessage);
            setIsValid(false);
        }
    }
}
