import React from 'react';

import { IBoundingBox } from 'shared/models/Charts/IBoundingBox';
import { Shape as ShapeModel } from 'shared/models/Charts/Shape';
import { HexColor } from 'shared/models/HexColor';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export interface IShapeProps {
    readonly shape : ShapeModel;
    readonly color : HexColor;
    readonly boundingBox : IBoundingBox;
}

export class Shape extends React.Component<IShapeProps, object> {
    private readonly classNamePrefix = 'chart-shape';

    public render() {
        const className : string = `${ this.classNamePrefix }-container`;

        return (
            <div
                className={ className }
            >
                { this.createShape() }
            </div>
        );
    }

    private createShape = () : JSX.Element => {
        const {
            shape,
        } = this.props;

        switch (shape) {
            case ShapeModel.SQUARE: {
                return this.createSquare();
            }
            case ShapeModel.LINE: {
                return this.createLine();
            }
            default: {
                throw new RuntimeException('unexpected value for shape');
            }
        }
    }

    private createSquare = () : JSX.Element => {
        const {
            color,
            boundingBox,
        } = this.props;

        const className : string = this.classNamePrefix + '-square';

        return (
            <svg
                className={ className }
                height={ boundingBox.heightInPixels }
                width={ boundingBox.widthInPixels }
            >
                <rect
                    height={ boundingBox.heightInPixels }
                    width={ boundingBox.widthInPixels }
                    fill={ color.getValue() }
                />
            </svg>
        );
    }

    private createLine = () : JSX.Element => {
        const {
            color,
            boundingBox,
        } = this.props;

        const className : string = this.classNamePrefix + '-line';

        const x1 = 0;
        const x2 = boundingBox.widthInPixels;

        const y1 = boundingBox.heightInPixels / 2;
        const y2 = y1;

        return (
            <svg
                className={ className }
                height={ boundingBox.heightInPixels }
                width={ boundingBox.widthInPixels }
            >
                <line
                    x1={ x1 }
                    x2={ x2 }
                    y1={ y1 }
                    y2={ y2 }

                    stroke={ color.getValue() }
                    strokeWidth={ 2 }
                />
            </svg>
        );
    }
}
