import { LocationId } from 'api/Location/model/LocationId';
import { AjaxUtils } from 'shared/utils/ajaxUtils';
import { IPresentationCacheDataService } from '../interface/IPresentationCacheDataService';

export class PresentationCacheDataService implements IPresentationCacheDataService {
    public getPresentationCacheData(locationId : LocationId) {
        return AjaxUtils.ajaxGet(url('account:get_retailer_presentation_data', null, locationId.getValue(), null))
        .then((response) => {
            return response.retailer_presentation_data;
        });
    }
}
