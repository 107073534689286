import React from 'react';
import MediaQuery from 'react-responsive';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { ProductQuickAdd } from 'api/Onboarding/model/ProductQuickAdd';
import { Product } from 'api/Product/model/Product';
import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductId } from 'api/Product/model/ProductId';
import { oldPackagingUtils } from 'api/Product/utils/oldPackagingUtils';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemWithMetadata } from 'api/SalesItem/model/SalesItemWithMetadata';

import { Button } from 'shared/components/Button';
import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { Flex } from 'shared/components/FlexLayout/Flex';
import { SearchBarTheme } from 'shared/components/SearchBar/SearchBar';
import { Select2DropdownMenuTheme } from 'shared/components/Select2Dropdown/GenericSelect2DropdownMenu';
import { OptionsAndLabelNameTuples, Select2DropdownMenu } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { ValidationInput, ValidationInputTheme } from 'shared/components/ValidationInput';

import { AddedIngredients } from './AddedIngredients';
import { IngredientSearchBar } from './IngredientsSearchBar';
import { SubRecipeInfo } from './SubRecipeInfo';

import {
    IngredientFormFieldName,
    ISalesItemForm,
    ISalesItemFormData,
    ProductIngredientRowFormFieldName,
    SalesInformationFormFieldName,
    SalesItemIngredientRowFormFieldName
} from '../reducers/reducers';

import { getCachedSalesItemCostsForIds } from 'apps/SalesItemManager/utils/CachedSalesItemCostUtils';

import { MIN_TABLET_WIDTH } from 'shared/constants';

import '../css/IngredientInfo.scss';

interface IIngredientInfoProps {
    salesItemForm : ISalesItemForm;
    salesItemFormData : ISalesItemFormData;
    onIngredientInfoFormFieldChange : (fieldName : IngredientFormFieldName, value : string) => void;
    onProductRowFormFieldChange : (productId : ProductId, formField : ProductIngredientRowFormFieldName, value : string) => void;
    onProductRowFormFieldBlur : (productId : ProductId, formField : ProductIngredientRowFormFieldName, value : string) => void;
    onSalesItemRowFormFieldChange : (salesItemId : SalesItemId, formField : SalesItemIngredientRowFormFieldName, value : string) => void;
    onSalesItemRowFormFieldBlur : (salesItemId : SalesItemId, formField : SalesItemIngredientRowFormFieldName, value : string) => void;
    onAddIngredient : () => void;
    handleOnRemoveIngredientClick : (ingredientId : ProductId | SalesItemId) => void;
    productsById : StringValueMap<ProductId, Product>;
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>;
    setSearchTerm : (searchTerm : string | null) => void;
    setHighlightedIngredientId : (searchSuggestion : ProductId | SalesItemId | ProductQuickAdd | null) => void;
    ingredientSearchBarDropdownIsShown : boolean;
    setIngredientDropdownIsShown : (isShown : boolean) => void;
    slimProductCreateIsShown : boolean;
    createNewSalesItemFieldsAreShown : boolean;
    setSlimCreateIsShown : (isShown : boolean) => void;
    setCreateNewSalesItemFieldsAreShown : (isShown : boolean) => void;
    onSelectIngredient : (ingredientId : ProductId | SalesItemId | ProductQuickAdd | null) => void;
    onSlimCreate : (productId : ProductId) => void;
    popoverIngredientIdIsShown : (ingredientId : ProductId | SalesItemId, isShown : boolean) => void;
    onProductInfoFullDetailsClick : ((ingredientId : ProductId) => void) | null;
    popoverIngredientIds : StringValueSet<ProductId | SalesItemId>;
    productCostsByProductId : StringValueMap<ProductId, ProductCost>;
    salesItemCostOnForm : string;
    onSalesInformationFormFieldChange : (fieldName : SalesInformationFormFieldName, value : string) => void;
    onSalesInformationFormFieldBlur : (fieldName : SalesInformationFormFieldName, value : string) => void;
    subRecipeInfoFieldsAreShown : boolean;
    showOrHideSubRecipeInfoFields : () => void;
    showIngredientPriceInDropdown : boolean;
    showProTipForEmptyIngredients : boolean;
    isItemMapping : boolean;
}

export class IngredientInfo extends React.Component<IIngredientInfoProps, object> {
    public render() {
        const {
            salesItemForm,
            salesItemFormData,
            onAddIngredient,
            handleOnRemoveIngredientClick,
            productsById,
            salesItemsById,
            onIngredientInfoFormFieldChange,
            onProductRowFormFieldChange,
            onProductRowFormFieldBlur,
            onSalesItemRowFormFieldChange,
            onSalesItemRowFormFieldBlur,
            setSearchTerm,
            setHighlightedIngredientId,
            ingredientSearchBarDropdownIsShown,
            setIngredientDropdownIsShown,
            slimProductCreateIsShown,
            setSlimCreateIsShown,
            onSelectIngredient,
            onSlimCreate,
            createNewSalesItemFieldsAreShown,
            setCreateNewSalesItemFieldsAreShown,
            popoverIngredientIdIsShown,
            onProductInfoFullDetailsClick,
            popoverIngredientIds,
            productCostsByProductId,
            subRecipeInfoFieldsAreShown,
            showOrHideSubRecipeInfoFields,
            showIngredientPriceInDropdown,
            showProTipForEmptyIngredients,
            isItemMapping
        } = this.props;
        const {
            orderedIngredientItems,
            productIngredientItemsById,
            salesItemIngredientItemsById,
        } = this.props.salesItemForm;

        const ingredientValidationInputDataByFieldName = this.props.salesItemForm.ingredientForm;

        const {
            selectedIngredientId,
            labelNamesAndSortedSalesItemIdsToDisplay,
            labelNamesAndSortedProductIdsToDisplay,
            labelNamesAndSortedProductQuickAddItemsToDisplay,
            searchBar,
            highlightedIngredientId,
        } = this.props.salesItemForm.ingredientSearchBar;

        const isPlural = parseFloat(ingredientValidationInputDataByFieldName.ingredientQuantityAmount.value) !== 1;
        const productIsSelected = (selectedIngredientId instanceof ProductId);

        let unitsForDropdown : OptionsAndLabelNameTuples = [[null, []]];
        let ingredientQuantityUnitSelectedOption : IOption | null = null;
        let selectedItemName : string = '';
        if (selectedIngredientId !== null) {
            if (selectedIngredientId instanceof ProductId) {
                const product = productsById.get(selectedIngredientId);

                if (typeof product !== 'undefined') {
                    unitsForDropdown = PackagingUtils.getAvailableUnitOptionsAndLabelNames(product.getPackagingsAndMappings(), true);
                    selectedItemName = `${ product.getBrand() } ${ product.getName() }`.trim();
                }
            } else if (selectedIngredientId instanceof SalesItemId) {
                const salesItem = salesItemsById.get(selectedIngredientId);
                if (typeof salesItem !== 'undefined') {
                    selectedItemName = salesItem.getSalesItem().getName();
                }
            } else {
                selectedItemName = `${ selectedIngredientId.getBrand() } ${ selectedIngredientId.getName() }`.trim();
            }

            if (ingredientValidationInputDataByFieldName.ingredientQuantityUnit.value) {
                unitsForDropdown.forEach((unitOptionsAndLabelName) => {
                    unitOptionsAndLabelName[1].forEach((unitOption : IOption) => {
                        if (unitOption.value === ingredientValidationInputDataByFieldName.ingredientQuantityUnit.value) {
                            ingredientQuantityUnitSelectedOption = unitOption;
                        }
                    });
                });
            }
        }

        let addItemButtonIsDisabled : boolean;
        if (productIsSelected) {
           addItemButtonIsDisabled = ingredientQuantityUnitSelectedOption === null || !ingredientValidationInputDataByFieldName.ingredientQuantityAmount.isValid;
        } else {
           addItemButtonIsDisabled = !ingredientValidationInputDataByFieldName.ingredientQuantityAmount.isValid;
        }

        const subrecipeCostsById = getCachedSalesItemCostsForIds(
            new StringValueSet(Array.from(salesItemForm.salesItemIngredientItemsById.keys())),
            salesItemFormData.salesItemsById,
            salesItemFormData.productsById,
            salesItemFormData.productCostsByProductId
        );

        let selectedItemPackagingInfo : string | null = null;
        if (selectedIngredientId !== null) {
            if (selectedIngredientId instanceof ProductId) {
                const product = productsById.getRequired(selectedIngredientId);
                selectedItemPackagingInfo = oldPackagingUtils.getDisplayTextForPackaging(product.getOldPackaging(), true);
            } else if (selectedIngredientId instanceof SalesItemId) {
                const subrecipeSalesItem = salesItemsById.getRequired(selectedIngredientId).getSalesItem();
                const subrecipeServingSize = subrecipeSalesItem.getServingSize().getQuantity();
                const subrecipeServingSizeUnit = subrecipeSalesItem.getServingSize().getUnit() ? subrecipeSalesItem.getServingSize().getUnit() : subrecipeSalesItem.getSalesItemCustomUnitName();
                selectedItemPackagingInfo = `Serving Size: ${ subrecipeServingSize } ${ subrecipeServingSizeUnit }`;
            } else {
                const packagingOptions = selectedIngredientId.getOptions();
                selectedItemPackagingInfo = oldPackagingUtils.getDisplayTextForPackaging(packagingOptions[0].packaging, true);
            }
        }

        return (
            <div className="ingredient-info-container sales-item-form-section">
                <div className={ isItemMapping ? 'sales-item-form-subsection add-ingredient-container mapped-add-ingredient-container' : 'sales-item-form-subsection add-ingredient-container' }>
                    <div className="add-ingredient-dropdown">
                        <div className="label">Add ingredients</div>
                        <Flex direction="row">
                            <IngredientSearchBar
                                productsById={ productsById }
                                salesItemsById={ salesItemsById }
                                searchBarPlaceholderText="Enter ingredient name"
                                searchBarTheme={ SearchBarTheme.Basic }
                                labelNamesAndSortedSalesItemIdsToDisplay={ labelNamesAndSortedSalesItemIdsToDisplay }
                                labelNamesAndSortedProductIdsToDisplay={ labelNamesAndSortedProductIdsToDisplay }
                                labelNamesAndSortedProductQuickAddItemsToDisplay={ labelNamesAndSortedProductQuickAddItemsToDisplay }
                                searchBar={ searchBar }
                                setSearchTerm={ setSearchTerm }
                                highlightedIngredientId={ highlightedIngredientId }
                                setHighlightedIngredientId={ setHighlightedIngredientId }
                                dropdownIsShown={ ingredientSearchBarDropdownIsShown }
                                setIngredientDropdownIsShown={ setIngredientDropdownIsShown }
                                slimProductCreateIsShown={ slimProductCreateIsShown }
                                setSlimCreateIsShown={ setSlimCreateIsShown }
                                onSelectIngredient={ onSelectIngredient }
                                onSlimCreate={ onSlimCreate }
                                createNewSalesItemFieldsAreShown={ createNewSalesItemFieldsAreShown }
                                setCreateNewSalesItemFieldsAreShown={ setCreateNewSalesItemFieldsAreShown }
                                validationInputDataByFieldName={ ingredientValidationInputDataByFieldName }
                                onIngredientInfoFormFieldChange={ onIngredientInfoFormFieldChange }
                                showIngredientProductPrice={ showIngredientPriceInDropdown }
                                productCostsByProductId={ productCostsByProductId }
                            />
                            { showProTipForEmptyIngredients && orderedIngredientItems.length === 0 && (
                                // TODO - Create pro-tip component
                                <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                                    <div className="pro-tip-row">
                                        <span className="dark pro-tip-ribbon secondary-bold">
                                            <div>Pro</div>
                                            <div>Tip</div>
                                        </span>
                                        <em>Add ingredients to map this item and see cost information.</em>
                                    </div>
                                </MediaQuery>
                            ) }
                        </Flex>
                    </div>
                    { selectedIngredientId !== null &&
                        <div className="add-ingredient-name-container">
                            <div className="label">Selected Item:</div>
                            <span className="add-ingredient-name">{ selectedItemName }</span>
                        </div>
                    }
                    { (selectedIngredientId !== null || createNewSalesItemFieldsAreShown) &&
                        <React.Fragment>
                            <div className="add-ingredient-quantity">
                                <div className="label">Ingredient quantity</div>
                                <div className="ingredient-quantity-input-group input-group">
                                    <ValidationInput
                                        theme={ ValidationInputTheme.Basic }
                                        type="text"
                                        step="any"
                                        label={ null }
                                        hintText=""
                                        value={ ingredientValidationInputDataByFieldName.ingredientQuantityAmount.value }
                                        autoFocus={ true }
                                        autoComplete={ null }
                                        isValid={ ingredientValidationInputDataByFieldName.ingredientQuantityAmount.isValid }
                                        isDisabled={ false }
                                        errorMessage={ ingredientValidationInputDataByFieldName.ingredientQuantityAmount.errorMessage }
                                        inputClassName=""
                                        handleEnterClick={ this.doNothing }
                                        handleChange={ this.onIngredientQuantityAmountChange }
                                        handleBlur={ this.doNothing }
                                        handleFocus={ this.doNothing }
                                    />
                                    { productIsSelected &&
                                        <Select2DropdownMenu
                                            theme={ Select2DropdownMenuTheme.Basic }
                                            onOptionSelect={ this.handleOnSelectIngredientQuantityUnit }
                                            sortedOptionsAndLabelName={ unitsForDropdown }
                                            selectedOption={ ingredientQuantityUnitSelectedOption }
                                            placeholderText="Select Unit"
                                            hasCreateCustomOption={ false }
                                            createCustomOption={ null }
                                            buttonShouldDisplaySelectedLabel={ true }
                                            emptyState={ null }
                                            isSearchable={ false }
                                            selectedOptionHeaderShown={ false }
                                            customOptionGroupLabel={ null }
                                            createCustomOptionButtonLabel={ null }
                                        />
                                    }
                                    { !productIsSelected &&
                                        <div className="static-unit-label">Serving{ isPlural ? 's' : '' }</div>
                                    }
                                    <small className="info-label add-ingredient-packaging-info">{ selectedItemPackagingInfo }</small>
                                </div>
                            </div>
                            <Button
                                buttonClassName="full-width large emphasis"
                                isDisabled={ addItemButtonIsDisabled }
                                isLoading={ false }
                                onClick={ onAddIngredient }
                            >
                                Add Ingredient
                            </Button>
                        </React.Fragment>
                    }
                </div>

                <div className="sales-item-form-subsection ingredient-list-container">
                    { isItemMapping && orderedIngredientItems.length === 0  &&
                        <div className="ingredient-list-empty-state">
                            <p className="instructions text-center">Add ingredients above to map this item</p>
                        </div>
                    }
                    { !isItemMapping && orderedIngredientItems.length === 0  &&
                        <div className="ingredient-list-empty-state">
                            <p className="instructions text-center">Add ingredients above to build your recipe</p>
                            <img className="ingredient-list-empty-state-illustrations" src="/static/img/sales/recipe-card.svg"/>
                        </div>
                    }
                    { orderedIngredientItems.length > 0 &&
                        <AddedIngredients
                            handleOnRemoveIngredientClick={ handleOnRemoveIngredientClick }
                            orderedIngredientItems={ orderedIngredientItems }
                            productsById={ productsById }
                            salesItemsById={ salesItemsById }
                            productIngredientItemsById={ productIngredientItemsById }
                            salesItemIngredientItemsById={ salesItemIngredientItemsById }
                            onProductRowFormFieldChange={ onProductRowFormFieldChange }
                            onProductFormFieldBlur={ onProductRowFormFieldBlur }
                            onSalesItemRowFormFieldChange={ onSalesItemRowFormFieldChange }
                            onSalesItemRowFormFieldBlur={ onSalesItemRowFormFieldBlur }
                            costByProductId={ salesItemFormData.productCostsByProductId }
                            subrecipeCostsById={ subrecipeCostsById }
                            popoverIngredientIdIsShown={ popoverIngredientIdIsShown }
                            onProductInfoFullDetailsClick={ onProductInfoFullDetailsClick }
                            popoverIngredientIds={ popoverIngredientIds }
                            productCostsByProductId={ productCostsByProductId }
                        />
                    }
                    <div>
                        <Button
                            buttonClassName="show-yield-serving-size-btn primary flat link medium"
                            isDisabled={ false }
                            isLoading={ false }
                            onClick={ showOrHideSubRecipeInfoFields }
                        >
                            { subRecipeInfoFieldsAreShown ? 'Hide' : 'Show' } yield and serving size options
                        </Button>
                    </div>
                </div>
                { subRecipeInfoFieldsAreShown &&
                    <SubRecipeInfo
                        salesItemForm={ salesItemForm }
                        salesItemFormData={ salesItemFormData }
                        onIngredientInfoFormFieldChange={ onIngredientInfoFormFieldChange }
                        salesItemCostOnForm={ salesItemForm.salesInformationForm.totalCost.value }
                        salesItemPriceOnForm={ salesItemForm.salesInformationForm.salesPrice.value }
                    />
                }
            </div>
        );
    }

    private readonly doNothing = () => {
        // doing nothing on purpose
    }

    private readonly onIngredientQuantityAmountChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.onIngredientInfoFormFieldChange('ingredientQuantityAmount', event.currentTarget.value);
    }

    private readonly handleOnSelectIngredientQuantityUnit = (selectedUnitValue : string) => {
        this.props.onIngredientInfoFormFieldChange('ingredientQuantityUnit', selectedUnitValue);
    }
}
