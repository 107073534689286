import React from 'react';

import { CheckBoxTriState } from 'shared/models/CheckBoxTriState';

import { CheckBox } from 'shared/components/CheckBox';
import { IInputLabel, LabelOrientation } from 'shared/models/InputLabel';

export interface IDropdownModalOptionProps {
    readonly value : string;
    readonly checkBoxState : CheckBoxTriState;
    readonly isDisabled : boolean;
    readonly onOptionClick : () => void;
}

export class DropdownModalOption extends React.Component<IDropdownModalOptionProps, object> {
    private readonly classNamePrefix = 'dropdown-modal-option';

    public render() {
        const {
            onOptionClick,
        } = this.props;

        const className = `${ this.classNamePrefix }-container`;

        return (
            <div
                className={ className }
                onClick={ onOptionClick }
            >
                { this.createCheckBox() }
            </div>
        );
    }

    private readonly createCheckBox = () : JSX.Element => {
        const {
            value,
            checkBoxState,
            isDisabled,
        } = this.props;

        const label : IInputLabel = {
            value,
            labelOrientation: LabelOrientation.RIGHT,
        };

        return (
            <CheckBox
                checkBoxTriState={ checkBoxState }
                isDisabled={ isDisabled }
                label={ label }
                onClick={ this.onCheckBoxClick }
            />
        );
    }

    // TODO is this necessary?
    private readonly onCheckBoxClick = (event : React.MouseEvent<HTMLElement>) : void => {
        const {
            onOptionClick,
        } = this.props;

        event.stopPropagation();
        onOptionClick();
    }
}
