import moment from 'moment-timezone';

export class PlateIQIntegrationState {
    constructor (
        private readonly plateIQRestaurantId : number | null,
        private readonly earliestInvoiceToSyncDate : moment.Moment | null,
        private readonly glCodesToSync : Set<string> | null,
        private readonly mostRecentSyncAttemptDate : moment.Moment | null,
    ) { }

    public getPlateIQRestaurantId() {
        return this.plateIQRestaurantId;
    }

    public getEarliestInvoiceToSyncDate() {
        return this.earliestInvoiceToSyncDate;
    }

    public getGlCodesToSync() {
        return this.glCodesToSync;
    }

    public getMostRecentSyncAttemptDate() {
        return this.mostRecentSyncAttemptDate;
    }
}
