//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


import Thrift from 'thrift';
import CorePrimitivesModel from './core_primitives_Model_types';
import CoreApiExceptions from './core_api_Exceptions_types';
import AccountsModel from './accounts_Model_types';
import UserAccountsModel from './accounts_user_Model_types';
import AccountsExceptions from './accounts_Exceptions_types';
import UnauthenticatedUserSessionModel from './unauthenticated_user_session_Model_types';
import UnauthenticatedUserSessionExceptions from './unauthenticated_user_session_Exceptions_types';
import UserInfoExceptions from './user_info_Exceptions_types';

var UserAccountService = {};
export default UserAccountService;
//HELPER FUNCTIONS AND STRUCTURES

UserAccountService.UserEmailAddressVerificationService_sendEmailAddressVerificationEmail_args = function(args) {
  this.sessionIdentifier = null;
  this.emailAddress = null;
  if (args) {
    if (args.sessionIdentifier !== undefined && args.sessionIdentifier !== null) {
      this.sessionIdentifier = new UserAccountsModel.UserSessionIdentifier(args.sessionIdentifier);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionIdentifier is unset!');
    }
    if (args.emailAddress !== undefined && args.emailAddress !== null) {
      this.emailAddress = new CorePrimitivesModel.EmailAddress(args.emailAddress);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field emailAddress is unset!');
    }
  }
};
UserAccountService.UserEmailAddressVerificationService_sendEmailAddressVerificationEmail_args.prototype = {};
UserAccountService.UserEmailAddressVerificationService_sendEmailAddressVerificationEmail_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionIdentifier = new UserAccountsModel.UserSessionIdentifier();
        this.sessionIdentifier.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.emailAddress = new CorePrimitivesModel.EmailAddress();
        this.emailAddress.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserAccountService.UserEmailAddressVerificationService_sendEmailAddressVerificationEmail_args.prototype.write = function(output) {
  output.writeStructBegin('UserEmailAddressVerificationService_sendEmailAddressVerificationEmail_args');
  if (this.sessionIdentifier !== null && this.sessionIdentifier !== undefined) {
    output.writeFieldBegin('sessionIdentifier', Thrift.Type.STRUCT, 1);
    this.sessionIdentifier.write(output);
    output.writeFieldEnd();
  }
  if (this.emailAddress !== null && this.emailAddress !== undefined) {
    output.writeFieldBegin('emailAddress', Thrift.Type.STRUCT, 2);
    this.emailAddress.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserAccountService.UserEmailAddressVerificationService_sendEmailAddressVerificationEmail_result = function(args) {
  this.serviceTemporarilyUnavailableException = null;
  this.rateLimitExceededException = null;
  this.unknownAccountSessionException = null;
  this.emailAddressAlreadyAssociatedWithAnAccountException = null;
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownAccountSessionException) {
    this.unknownAccountSessionException = args;
    return;
  }
  if (args instanceof AccountsExceptions.EmailAddressAlreadyAssociatedWithAnAccountException) {
    this.emailAddressAlreadyAssociatedWithAnAccountException = args;
    return;
  }
  if (args) {
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.unknownAccountSessionException !== undefined && args.unknownAccountSessionException !== null) {
      this.unknownAccountSessionException = args.unknownAccountSessionException;
    }
    if (args.emailAddressAlreadyAssociatedWithAnAccountException !== undefined && args.emailAddressAlreadyAssociatedWithAnAccountException !== null) {
      this.emailAddressAlreadyAssociatedWithAnAccountException = args.emailAddressAlreadyAssociatedWithAnAccountException;
    }
  }
};
UserAccountService.UserEmailAddressVerificationService_sendEmailAddressVerificationEmail_result.prototype = {};
UserAccountService.UserEmailAddressVerificationService_sendEmailAddressVerificationEmail_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownAccountSessionException = new AccountsExceptions.UnknownAccountSessionException();
        this.unknownAccountSessionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.emailAddressAlreadyAssociatedWithAnAccountException = new AccountsExceptions.EmailAddressAlreadyAssociatedWithAnAccountException();
        this.emailAddressAlreadyAssociatedWithAnAccountException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserAccountService.UserEmailAddressVerificationService_sendEmailAddressVerificationEmail_result.prototype.write = function(output) {
  output.writeStructBegin('UserEmailAddressVerificationService_sendEmailAddressVerificationEmail_result');
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 1);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 2);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownAccountSessionException !== null && this.unknownAccountSessionException !== undefined) {
    output.writeFieldBegin('unknownAccountSessionException', Thrift.Type.STRUCT, 3);
    this.unknownAccountSessionException.write(output);
    output.writeFieldEnd();
  }
  if (this.emailAddressAlreadyAssociatedWithAnAccountException !== null && this.emailAddressAlreadyAssociatedWithAnAccountException !== undefined) {
    output.writeFieldBegin('emailAddressAlreadyAssociatedWithAnAccountException', Thrift.Type.STRUCT, 4);
    this.emailAddressAlreadyAssociatedWithAnAccountException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserAccountService.UserEmailAddressVerificationService_associateEmailAddressForEmailAddressVerificationCredential_args = function(args) {
  this.unauthenticatedUserSessionId = null;
  this.emailAddressVerificationCredential = null;
  if (args) {
    if (args.unauthenticatedUserSessionId !== undefined && args.unauthenticatedUserSessionId !== null) {
      this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId(args.unauthenticatedUserSessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field unauthenticatedUserSessionId is unset!');
    }
    if (args.emailAddressVerificationCredential !== undefined && args.emailAddressVerificationCredential !== null) {
      this.emailAddressVerificationCredential = new UserAccountsModel.UserEmailAddressVerificationCredential(args.emailAddressVerificationCredential);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field emailAddressVerificationCredential is unset!');
    }
  }
};
UserAccountService.UserEmailAddressVerificationService_associateEmailAddressForEmailAddressVerificationCredential_args.prototype = {};
UserAccountService.UserEmailAddressVerificationService_associateEmailAddressForEmailAddressVerificationCredential_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId();
        this.unauthenticatedUserSessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.emailAddressVerificationCredential = new UserAccountsModel.UserEmailAddressVerificationCredential();
        this.emailAddressVerificationCredential.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserAccountService.UserEmailAddressVerificationService_associateEmailAddressForEmailAddressVerificationCredential_args.prototype.write = function(output) {
  output.writeStructBegin('UserEmailAddressVerificationService_associateEmailAddressForEmailAddressVerificationCredential_args');
  if (this.unauthenticatedUserSessionId !== null && this.unauthenticatedUserSessionId !== undefined) {
    output.writeFieldBegin('unauthenticatedUserSessionId', Thrift.Type.STRUCT, 1);
    this.unauthenticatedUserSessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.emailAddressVerificationCredential !== null && this.emailAddressVerificationCredential !== undefined) {
    output.writeFieldBegin('emailAddressVerificationCredential', Thrift.Type.STRUCT, 2);
    this.emailAddressVerificationCredential.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserAccountService.UserEmailAddressVerificationService_associateEmailAddressForEmailAddressVerificationCredential_result = function(args) {
  this.serviceTemporarilyUnavailableException = null;
  this.rateLimitExceededException = null;
  this.unknownUnauthenticatedUserSessionIdException = null;
  this.unknownEmailAddressVerificationCredentialException = null;
  this.expiredEmailAddressVerificationCredentialException = null;
  this.emailAddressVerificationCredentialAlreadyUsedException = null;
  this.emailAddressAlreadyAssociatedWithAnAccountException = null;
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException) {
    this.unknownUnauthenticatedUserSessionIdException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownEmailAddressVerificationCredentialException) {
    this.unknownEmailAddressVerificationCredentialException = args;
    return;
  }
  if (args instanceof AccountsExceptions.ExpiredEmailAddressVerificationCredentialException) {
    this.expiredEmailAddressVerificationCredentialException = args;
    return;
  }
  if (args instanceof AccountsExceptions.EmailAddressVerificationCredentialAlreadyUsedException) {
    this.emailAddressVerificationCredentialAlreadyUsedException = args;
    return;
  }
  if (args instanceof AccountsExceptions.EmailAddressAlreadyAssociatedWithAnAccountException) {
    this.emailAddressAlreadyAssociatedWithAnAccountException = args;
    return;
  }
  if (args) {
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.unknownUnauthenticatedUserSessionIdException !== undefined && args.unknownUnauthenticatedUserSessionIdException !== null) {
      this.unknownUnauthenticatedUserSessionIdException = args.unknownUnauthenticatedUserSessionIdException;
    }
    if (args.unknownEmailAddressVerificationCredentialException !== undefined && args.unknownEmailAddressVerificationCredentialException !== null) {
      this.unknownEmailAddressVerificationCredentialException = args.unknownEmailAddressVerificationCredentialException;
    }
    if (args.expiredEmailAddressVerificationCredentialException !== undefined && args.expiredEmailAddressVerificationCredentialException !== null) {
      this.expiredEmailAddressVerificationCredentialException = args.expiredEmailAddressVerificationCredentialException;
    }
    if (args.emailAddressVerificationCredentialAlreadyUsedException !== undefined && args.emailAddressVerificationCredentialAlreadyUsedException !== null) {
      this.emailAddressVerificationCredentialAlreadyUsedException = args.emailAddressVerificationCredentialAlreadyUsedException;
    }
    if (args.emailAddressAlreadyAssociatedWithAnAccountException !== undefined && args.emailAddressAlreadyAssociatedWithAnAccountException !== null) {
      this.emailAddressAlreadyAssociatedWithAnAccountException = args.emailAddressAlreadyAssociatedWithAnAccountException;
    }
  }
};
UserAccountService.UserEmailAddressVerificationService_associateEmailAddressForEmailAddressVerificationCredential_result.prototype = {};
UserAccountService.UserEmailAddressVerificationService_associateEmailAddressForEmailAddressVerificationCredential_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownUnauthenticatedUserSessionIdException = new UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException();
        this.unknownUnauthenticatedUserSessionIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownEmailAddressVerificationCredentialException = new AccountsExceptions.UnknownEmailAddressVerificationCredentialException();
        this.unknownEmailAddressVerificationCredentialException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.expiredEmailAddressVerificationCredentialException = new AccountsExceptions.ExpiredEmailAddressVerificationCredentialException();
        this.expiredEmailAddressVerificationCredentialException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.emailAddressVerificationCredentialAlreadyUsedException = new AccountsExceptions.EmailAddressVerificationCredentialAlreadyUsedException();
        this.emailAddressVerificationCredentialAlreadyUsedException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.emailAddressAlreadyAssociatedWithAnAccountException = new AccountsExceptions.EmailAddressAlreadyAssociatedWithAnAccountException();
        this.emailAddressAlreadyAssociatedWithAnAccountException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserAccountService.UserEmailAddressVerificationService_associateEmailAddressForEmailAddressVerificationCredential_result.prototype.write = function(output) {
  output.writeStructBegin('UserEmailAddressVerificationService_associateEmailAddressForEmailAddressVerificationCredential_result');
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 1);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 2);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownUnauthenticatedUserSessionIdException !== null && this.unknownUnauthenticatedUserSessionIdException !== undefined) {
    output.writeFieldBegin('unknownUnauthenticatedUserSessionIdException', Thrift.Type.STRUCT, 3);
    this.unknownUnauthenticatedUserSessionIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownEmailAddressVerificationCredentialException !== null && this.unknownEmailAddressVerificationCredentialException !== undefined) {
    output.writeFieldBegin('unknownEmailAddressVerificationCredentialException', Thrift.Type.STRUCT, 4);
    this.unknownEmailAddressVerificationCredentialException.write(output);
    output.writeFieldEnd();
  }
  if (this.expiredEmailAddressVerificationCredentialException !== null && this.expiredEmailAddressVerificationCredentialException !== undefined) {
    output.writeFieldBegin('expiredEmailAddressVerificationCredentialException', Thrift.Type.STRUCT, 5);
    this.expiredEmailAddressVerificationCredentialException.write(output);
    output.writeFieldEnd();
  }
  if (this.emailAddressVerificationCredentialAlreadyUsedException !== null && this.emailAddressVerificationCredentialAlreadyUsedException !== undefined) {
    output.writeFieldBegin('emailAddressVerificationCredentialAlreadyUsedException', Thrift.Type.STRUCT, 6);
    this.emailAddressVerificationCredentialAlreadyUsedException.write(output);
    output.writeFieldEnd();
  }
  if (this.emailAddressAlreadyAssociatedWithAnAccountException !== null && this.emailAddressAlreadyAssociatedWithAnAccountException !== undefined) {
    output.writeFieldBegin('emailAddressAlreadyAssociatedWithAnAccountException', Thrift.Type.STRUCT, 7);
    this.emailAddressAlreadyAssociatedWithAnAccountException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserAccountService.UserEmailAddressVerificationServiceClient = function(input, output) {
    this.input = input;
    this.output = (!output) ? input : output;
    this.seqid = 0;
};
UserAccountService.UserEmailAddressVerificationServiceClient.prototype = {};
UserAccountService.UserEmailAddressVerificationServiceClient.prototype.sendEmailAddressVerificationEmail = function(sessionIdentifier, emailAddress, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_sendEmailAddressVerificationEmail(pendingError, sessionIdentifier, emailAddress, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_sendEmailAddressVerificationEmail();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

UserAccountService.UserEmailAddressVerificationServiceClient.prototype.send_sendEmailAddressVerificationEmail = function(pendingError, sessionIdentifier, emailAddress, callback) {
  this.output.writeMessageBegin('sendEmailAddressVerificationEmail', Thrift.MessageType.CALL, this.seqid);
  var args = new UserAccountService.UserEmailAddressVerificationService_sendEmailAddressVerificationEmail_args();
  args.sessionIdentifier = sessionIdentifier;
  args.emailAddress = emailAddress;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_sendEmailAddressVerificationEmail() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

UserAccountService.UserEmailAddressVerificationServiceClient.prototype.recv_sendEmailAddressVerificationEmail = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new UserAccountService.UserEmailAddressVerificationService_sendEmailAddressVerificationEmail_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.unknownAccountSessionException) {
    throw result.unknownAccountSessionException;
  }
  if (null !== result.emailAddressAlreadyAssociatedWithAnAccountException) {
    throw result.emailAddressAlreadyAssociatedWithAnAccountException;
  }
  return;
};
UserAccountService.UserEmailAddressVerificationServiceClient.prototype.associateEmailAddressForEmailAddressVerificationCredential = function(unauthenticatedUserSessionId, emailAddressVerificationCredential, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_associateEmailAddressForEmailAddressVerificationCredential(pendingError, unauthenticatedUserSessionId, emailAddressVerificationCredential, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_associateEmailAddressForEmailAddressVerificationCredential();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

UserAccountService.UserEmailAddressVerificationServiceClient.prototype.send_associateEmailAddressForEmailAddressVerificationCredential = function(pendingError, unauthenticatedUserSessionId, emailAddressVerificationCredential, callback) {
  this.output.writeMessageBegin('associateEmailAddressForEmailAddressVerificationCredential', Thrift.MessageType.CALL, this.seqid);
  var args = new UserAccountService.UserEmailAddressVerificationService_associateEmailAddressForEmailAddressVerificationCredential_args();
  args.unauthenticatedUserSessionId = unauthenticatedUserSessionId;
  args.emailAddressVerificationCredential = emailAddressVerificationCredential;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_associateEmailAddressForEmailAddressVerificationCredential() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

UserAccountService.UserEmailAddressVerificationServiceClient.prototype.recv_associateEmailAddressForEmailAddressVerificationCredential = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new UserAccountService.UserEmailAddressVerificationService_associateEmailAddressForEmailAddressVerificationCredential_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.unknownUnauthenticatedUserSessionIdException) {
    throw result.unknownUnauthenticatedUserSessionIdException;
  }
  if (null !== result.unknownEmailAddressVerificationCredentialException) {
    throw result.unknownEmailAddressVerificationCredentialException;
  }
  if (null !== result.expiredEmailAddressVerificationCredentialException) {
    throw result.expiredEmailAddressVerificationCredentialException;
  }
  if (null !== result.emailAddressVerificationCredentialAlreadyUsedException) {
    throw result.emailAddressVerificationCredentialAlreadyUsedException;
  }
  if (null !== result.emailAddressAlreadyAssociatedWithAnAccountException) {
    throw result.emailAddressAlreadyAssociatedWithAnAccountException;
  }
  return;
};
