import { StringValueMap } from 'api/Core/StringValueMap';
import { ProductId } from 'api/Product/model/ProductId';
import { InterLocationProductMapping } from 'api/Transfer/model/InterLocationProductMapping';
import { TransferReport } from 'api/Transfer/model/TransferReport';
import { TransferReportWithoutCost } from 'api/Transfer/model/TransferReportWithoutCost';

export const TransferStatus = {
    COMPLETE: 'Complete',
    CANCELLED: 'Cancelled',
    REQUESTED: 'Requested',
    SENT: 'Sent',
};

const getTransferIsRequisition = (transfer : TransferReport | TransferReportWithoutCost) => {
  return transfer.getTransferReportData().getRequestMetadata() !== null ||
      transfer.getTransferReportData().getSentMetadata() !== null ||
      transfer.getTransferReportData().getReceivedMetadata() !== null ||
      transfer.getTransferReportData().getCancellationMetadata() !== null;
};

const getTransferStatus = (transfer : TransferReport | TransferReportWithoutCost) => {
    if (transfer.getTransferReportData().getReceivedMetadata() !== null) {
        return TransferStatus.COMPLETE;
    }

    if (transfer.getTransferReportData().getCancellationMetadata() !== null) {
        return TransferStatus.CANCELLED;
    }

    if (transfer.getTransferReportData().getSentMetadata() !== null) {
        return TransferStatus.SENT;
    }

    if (transfer.getTransferReportData().getRequestMetadata() !== null) {
        return TransferStatus.REQUESTED;
    }

    return TransferStatus.COMPLETE;
};

export const TransferUtils = {
    getTransferStatus,
    getTransferIsRequisition
};
