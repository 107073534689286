import CoreTimeModel from 'gen-thrift/core_time_Model_types';

import moment from 'moment-timezone';

import { DateTime, IDateRange, Timezone } from 'shared/models/DateTime';

export const getDateTimeFromMoment = (momentValue : moment.Moment, timezone : Timezone) => {
    const dateTimeFormat = 'MM/DD/YYYY hh:mma z';   // MINUTE precision!!
    return new DateTime({
        format: dateTimeFormat,
        timezone,
        utcTime: momentValue.clone().utc().format(dateTimeFormat),
    });
};

export const getDateTimeFromTimestamp = (timestamp : CoreTimeModel.TimestampWithMillisecondPrecision, timezone : Timezone) => {
    return new DateTime({
        format: 'YYYY-MM-DD HH:mm:ss',
        timezone,
        utcTime: timestamp,
    });
};

export const getDateTimeFromISOFormatDateTimeString = (isoformattedDateTimeString : string, timezone : Timezone) : DateTime => {
    return new DateTime({
        format : 'YYYY-MM-DDTHH:mm:ssZ',
        timezone,
        utcTime : isoformattedDateTimeString,
    });
};

export const getTimezoneFromString = (timezoneString : string) : Timezone => {
    return parseInt(Timezone[timezoneString as any], 10);
};

export const compareDateTimes = (dateTime0 : DateTime, dateTime1 : DateTime) : number => {
    return dateTime0.toMilliseconds() > dateTime1.toMilliseconds() ? 1 : dateTime0.toMilliseconds() < dateTime1.toMilliseconds() ? -1 : 0;
};

export const startDateIsEarilierThanEndDate = (startDate : DateTime, endDate : DateTime) : boolean => {
    return compareDateTimes(startDate, endDate) < 0;
};

// 2017/07/26 Minshu Zhan: When needed, generalize this method to take days/weeks as arguments as well.
// TODO: use this in order history?
export const getInclusiveDateRangeForLastNMonths = (now : moment.Moment, timezoneString : string, months : number) : IDateRange => {
    const timeZone = getTimezoneFromString(timezoneString);
    return {
        endDateTime : getDateTimeFromMoment(now.clone().tz(timezoneString).endOf('day'), timeZone),
        startDateTime : getDateTimeFromMoment(now.clone().tz(timezoneString).subtract(months, 'month').startOf('day').add(1, 'day'), timeZone),
    };
};
