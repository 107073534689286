import InventoryTransferReportModel from 'gen-thrift/inventory_transfer_report_Model_types';

import { StringValueSet } from 'api/Core/StringValueSet';
import { LocationId } from 'api/Location/model/LocationId';
import { LocationThriftToObjectSerializer } from 'api/Location/serializer/LocationThriftToObjectSerializer';
import { ProductThriftToObjectSerializer } from 'api/Product/serializer/ProductThriftToObjectSerializer';
import { ReportMetadata } from 'api/Transfer/model/ReportMetadata';
import { TransferDirection } from 'api/Transfer/model/TransferDirection';
import { TransferId } from 'api/Transfer/model/TransferId';
import { TransferReport } from 'api/Transfer/model/TransferReport';
import { TransferReportWithoutCost } from 'api/Transfer/model/TransferReportWithoutCost';
import { TransferReportData } from 'api/Transfer/model/TransferReportData';
import { UserAccountIdAndTimestamp } from 'api/UserAccount/model/UserAccountIdAndTimestamp';
import { UserAccountThriftToObjectSerializer } from 'api/UserAccount/serializer/UserAccountThriftToObjectSerializer';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export class TransferThriftToObjectSerializer {

    constructor(
        private userAccountThriftToObjectSerializer : UserAccountThriftToObjectSerializer,
        private productThriftToObjectSerializer : ProductThriftToObjectSerializer,
        private locationThriftToObjectSerializer : LocationThriftToObjectSerializer,
    ) {}

    ////////////////////////////////////
    // MODELS
    ////////////////////////////////////

    public getTransferId(
        transferId : InventoryTransferReportModel.InventoryTransferReportIdentifier,
    ) : TransferId {
        return new TransferId(transferId.value);
    }

    public getTransferDirection(
        transferDirection : InventoryTransferReportModel.TransferDirection,
    ) : TransferDirection {
        switch (transferDirection) {
            case InventoryTransferReportModel.TransferDirection.FROM_PARTNER_TO_PERSPECTIVE_LOCATION:
                return TransferDirection.FROM_PARTNER_TO_PERSPECTIVE_LOCATION;
            case InventoryTransferReportModel.TransferDirection.FROM_PERSPECTIVE_TO_PARTNER_LOCATION:
                return TransferDirection.FROM_PERSPECTIVE_TO_PARTNER_LOCATION;
            default:
                throw new RuntimeException('Unknown transfer direction');
        }
    }

    public getReportMetadata(
        reportMetadata : InventoryTransferReportModel.ReportMetaData,
    ) : ReportMetadata {
        const creationMetadata = new UserAccountIdAndTimestamp(this.userAccountThriftToObjectSerializer.getUserAccountId(reportMetadata.creator), reportMetadata.creationTime);

        let updateMetadata : UserAccountIdAndTimestamp | null;
        if ((reportMetadata.lastUpdater === null) || (reportMetadata.lastUpdateTime === null)) {
            if (reportMetadata.lastUpdater !== reportMetadata.lastUpdateTime) {
                throw new RuntimeException('unexpected');
            }

            updateMetadata = null;
        } else {
            updateMetadata = new UserAccountIdAndTimestamp(this.userAccountThriftToObjectSerializer.getUserAccountId(reportMetadata.lastUpdater), reportMetadata.lastUpdateTime);
        }

        return new ReportMetadata(creationMetadata, updateMetadata, reportMetadata.perspectiveIsOriginator);
    }

    public getTransferReportData(
        transferReportData : InventoryTransferReportModel.InventoryTransferReportInfo
    ) : TransferReportData {
        const productAmounts = transferReportData.productAmounts.map((productCountAmount) => {
            return this.productThriftToObjectSerializer.getProductAmount(productCountAmount);
        });

        let requestMetadata : UserAccountIdAndTimestamp | null;
        let sentMetadata : UserAccountIdAndTimestamp | null;
        let receivedMetadata : UserAccountIdAndTimestamp | null;
        let cancellationMetadata : UserAccountIdAndTimestamp | null;

        if ((transferReportData.requestedByUser === null) || (transferReportData.requestedTime === null)) {
            if (transferReportData.requestedByUser !== transferReportData.requestedTime) {
                throw new RuntimeException('unexpected');
            }

            requestMetadata = null;
        } else {
            requestMetadata = new UserAccountIdAndTimestamp(this.userAccountThriftToObjectSerializer.getUserAccountId(transferReportData.requestedByUser), transferReportData.requestedTime);
        }

        if ((transferReportData.sentByUser === null) || (transferReportData.sentTime === null)) {
            if (transferReportData.sentByUser !== transferReportData.sentTime) {
                throw new RuntimeException('unexpected');
            }

            sentMetadata = null;
        } else {
            sentMetadata = new UserAccountIdAndTimestamp(this.userAccountThriftToObjectSerializer.getUserAccountId(transferReportData.sentByUser), transferReportData.sentTime);
        }

        if ((transferReportData.receivedTime === null) || (transferReportData.receivedByUser === null)) {
            if (transferReportData.receivedByUser !== transferReportData.receivedTime) {
                throw new RuntimeException('unexpected');
            }

            receivedMetadata = null;
        } else {
            receivedMetadata = new UserAccountIdAndTimestamp(this.userAccountThriftToObjectSerializer.getUserAccountId(transferReportData.receivedByUser), transferReportData.receivedTime);
        }

        if ((transferReportData.cancellationTime === null) || (transferReportData.cancelledByUser === null)) {
            if (transferReportData.cancelledByUser !== transferReportData.cancellationTime) {
                throw new RuntimeException('unexpected');
            }

            cancellationMetadata = null;
        } else {
            cancellationMetadata = new UserAccountIdAndTimestamp(this.userAccountThriftToObjectSerializer.getUserAccountId(transferReportData.cancelledByUser), transferReportData.cancellationTime);
        }

        return new TransferReportData(
            this.locationThriftToObjectSerializer.getLocationId(transferReportData.perspectiveLocation),
            productAmounts,
            this.getPartnerLocationFromTransferReportData(transferReportData),
            this.getTransferDirection(transferReportData.direction),
            transferReportData.transferTime,
            transferReportData.note,
            requestMetadata,
            sentMetadata,
            receivedMetadata,
            cancellationMetadata,
            transferReportData.cancellationReason
        );
    }

    public getPartnerLocationFromTransferReportData(
        reportData : InventoryTransferReportModel.InventoryTransferReportInfo
    ) : LocationId | string {
        const thriftPartnerLocation = reportData.partnerLocation;
        const thriftCustomPartnerLocationName = reportData.customPartnerLocationName;

        let partnerLocation : LocationId | string;
        if (thriftPartnerLocation === null) {
            if (thriftCustomPartnerLocationName === null) {
                throw Error('unexpected: no partner found');
            }
            partnerLocation = thriftCustomPartnerLocationName;
        } else {
            if (thriftCustomPartnerLocationName !== null) {
                throw Error('unexpected: both retailer and custom partner found');
            }
            partnerLocation = new LocationId(thriftPartnerLocation.value);
        }
        return partnerLocation;
    }

    public getTransferReportWithoutCost(
        transferReport : InventoryTransferReportModel.InventoryTransferReportAndMetadataWithoutCost
    ) : TransferReportWithoutCost {
        return new TransferReportWithoutCost(
            this.getTransferReportData(transferReport.data),
            this.getReportMetadata(transferReport.metaData),
        );
    }

    public getTransferReport(
        transferReport : InventoryTransferReportModel.InventoryTransferReportAndMetadata
    ) : TransferReport {
        return new TransferReport(
            this.getTransferReportData(transferReport.data),
            this.getReportMetadata(transferReport.metaData),
            this.productThriftToObjectSerializer.getCostByProductId(transferReport.productCostsByProductId)
        );
    }

    ////////////////////////////////////
    // COLLECTIONS
    ////////////////////////////////////

    public getTransferIds(
        transferIds : Array<InventoryTransferReportModel.InventoryTransferReportIdentifier>
    ) : StringValueSet<TransferId> {
        const transferIdSet = new StringValueSet<TransferId>();

        transferIds.forEach((transferId) => {
            transferIdSet.add(this.getTransferId(transferId));
        });

        return transferIdSet;
    }

    public getTransferReportWithoutCosts(
        transferReports : Array<InventoryTransferReportModel.InventoryTransferReportAndMetadataWithoutCost>
    ) : Array<TransferReportWithoutCost> {
        return transferReports.map((transferReport) => {
            return this.getTransferReportWithoutCost(transferReport);
        });
    }

    public getTransferReports(
        transferReports : Array<InventoryTransferReportModel.InventoryTransferReportAndMetadata>
    ) : Array<TransferReport> {
        return transferReports.map((transferReport) => {
            return this.getTransferReport(transferReport);
        });
    }
}
