import ProductDistributorModel from 'gen-thrift/product_distributor_Model_types';

import { DistributorObjectToThriftSerializer } from 'api/Distributor/serializer/DistributorObjectToThriftSerializer';
import { ProductDistributorAssociation } from 'api/Product/model/ProductDistributorAssociation';
import { ProductObjectToThriftSerializer } from 'api/Product/serializer/ProductObjectToThriftSerializer';

export class ProductDistributorAssociationObjectToThriftSerializer {

    constructor(
        private readonly productObjectToThriftSerializer : ProductObjectToThriftSerializer,
        private readonly distributorObjectToThriftSerializer : DistributorObjectToThriftSerializer
    ) {}

    public getThriftProductDistributorAssociation(
        productDistributorAssociation : ProductDistributorAssociation
    ) : ProductDistributorModel.ProductPackagingDistributorAssoc {
        const price = productDistributorAssociation.getPrice();
        const deposit = productDistributorAssociation.getDeposit();

        return new ProductDistributorModel.ProductPackagingDistributorAssoc({
            productId: this.productObjectToThriftSerializer.getThriftProductId(productDistributorAssociation.getProductId()),
            productQuantityUnit: this.productObjectToThriftSerializer.getThriftProductQuantityUnit(productDistributorAssociation.getProductQuantityUnit()),
            distributorId: this.distributorObjectToThriftSerializer.getThriftDistributorId(productDistributorAssociation.getDistributorId()),
            sku: productDistributorAssociation.getSku(),
            price: price ? this.productObjectToThriftSerializer.getThriftProductCost(price) : null,
            deposit: deposit ? this.productObjectToThriftSerializer.getThriftProductCost(deposit) : null,
        });
    }

    ////////////////////////////////////
    // COLLECTIONS
    ////////////////////////////////////

    public getThriftProductDistributorAssociationsFromArray(
        productDistributorAssociations : Array<ProductDistributorAssociation>
    ) : Array<ProductDistributorModel.ProductPackagingDistributorAssoc> {
        return productDistributorAssociations.map((productDistributorAssociation) => {
            return this.getThriftProductDistributorAssociation(productDistributorAssociation);
        });
    }
}
