import { StringValueMap } from 'api/Core/StringValueMap';
import { BottleBreakdown } from 'api/InventoryCount/model/BottleBreakdown';
import { ProductId } from 'api/Product/model/ProductId';

export class ExpectedInventory {
    constructor (
        private readonly bottleCountByProductId : StringValueMap<ProductId, number>,
        private readonly bottleBreakdownByProductId : StringValueMap<ProductId, BottleBreakdown>,
    ) { }

    public getBottleCountByProductId() : StringValueMap<ProductId, number> {
        return this.bottleCountByProductId;
    }

    public getBottleBreakdownByProductId() : StringValueMap<ProductId, BottleBreakdown> {
        return this.bottleBreakdownByProductId;
    }
}
