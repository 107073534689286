import { BaseUnit } from 'api/Product/model/BaseUnit';
import { MassUnit } from 'api/Product/model/MassUnit';
import { Price } from 'api/Product/model/Price';
import { Product } from 'api/Product/model/Product';
import { PackagingId } from 'api/Product/model/PackagingId';
import { PackagingUnit } from 'api/Product/model/PackagingUnit';
import { CategoryId } from 'api/Product/model/CategoryId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';

import { PackagingUtils } from './PackagingUtils';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

const getProductWithNewPrice = (product : Product, newPrice : Price) : Product => {
    return new Product(
        product.getBrand(),
        product.getName(),
        product.getPackagingsAndMappings(),
        product.getPreferredReportingUnit(),
        product.getWeightsByPackagingId(),
        product.getProductCategoryId(),
        product.getNewProductCategoryId(),
        product.getProductType(),
        newPrice,
        product.getDepositInDollars(),
        product.getSku(),
        product.getGLCode(),
        product.getNote(),
        product.getLastUpdateEvent(),
    );
};

const getProductWithNewSku = (product : Product, newSku : string) : Product => {
    return new Product(
        product.getBrand(),
        product.getName(),
        product.getPackagingsAndMappings(),
        product.getPreferredReportingUnit(),
        product.getWeightsByPackagingId(),
        product.getProductCategoryId(),
        product.getNewProductCategoryId(),
        product.getProductType(),
        product.getUnitPrice(),
        product.getDepositInDollars(),
        newSku,
        product.getGLCode(),
        product.getNote(),
        product.getLastUpdateEvent(),
    );
};

const getProductWithNewCategory = (product : Product, newCategoryId : CategoryId | null, newCategoryName : string) : Product => {
    return new Product(
        product.getBrand(),
        product.getName(),
        product.getPackagingsAndMappings(),
        product.getPreferredReportingUnit(),
        product.getWeightsByPackagingId(),
        newCategoryName,
        newCategoryId,
        product.getProductType(),
        product.getUnitPrice(),
        product.getDepositInDollars(),
        product.getSku(),
        product.getGLCode(),
        product.getNote(),
        product.getLastUpdateEvent(),
    );
};

const getProductWithNewGLCode = (product : Product, newGLCode : string) : Product => {
    return new Product(
        product.getBrand(),
        product.getName(),
        product.getPackagingsAndMappings(),
        product.getPreferredReportingUnit(),
        product.getWeightsByPackagingId(),
        product.getProductCategoryId(),
        product.getNewProductCategoryId(),
        product.getProductType(),
        product.getUnitPrice(),
        product.getDepositInDollars(),
        product.getSku(),
        newGLCode,
        product.getNote(),
        product.getLastUpdateEvent(),
    );
};

const getProductQuantityUnitForUnit = (product : Product, unit : MassUnit | VolumeUnit | PackagingUnit | BaseUnit) : ProductQuantityUnit => {
    const packagingsAndMappings = product.getPackagingsAndMappings();
    const packaging = packagingsAndMappings.getPackaging();
    const baseProductQuantityUnit = PackagingUtils.getBaseUnitOfPackaging(packaging);

    let productQuantityUnit : ProductQuantityUnit;
    if ((baseProductQuantityUnit instanceof PackagingId) && BaseUnit.isBaseUnit(unit)) {
        productQuantityUnit = baseProductQuantityUnit;
    } else if (unit === PackagingUnit.CASE) {
        const casePackagingId = packaging.getPackagingId();
        if (casePackagingId === null) {
            throw new RuntimeException('casePackagingId is unexpectedly null');
        }

        productQuantityUnit = casePackagingId;
    } else if (PackagingUnit.isPackagingUnit(unit)) {
        productQuantityUnit = PackagingUtils.getContainerPackagingId(packaging);
    } else if (VolumeUnit.isVolumeUnit(unit) || MassUnit.isMassUnit(unit)) {
        productQuantityUnit = unit;
    } else {
        throw new RuntimeException(`unexpected unit: ${ unit } for product: ${ product }`);
    }

    return productQuantityUnit;
};

const convertPriceToProductQuantityUnit = (product : Product, productQuantityUnit : ProductQuantityUnit) : number => {
    const packagingsAndMappings = product.getPackagingsAndMappings();

    const priceProductQuantityUnit = getProductQuantityUnitForUnit(product, product.getUnitPrice().getUnit());

    const convertedProductPrice = PackagingUtils.convertProductQuantityToUnit(
        packagingsAndMappings,
        new QuantityInUnit(1, productQuantityUnit),
        priceProductQuantityUnit,
    ).getQuantity() * product.getUnitPrice().getDollarValue();

    return convertedProductPrice;
};

const convertDepositToProductQuantityUnit = (product : Product, productQuantityUnit : ProductQuantityUnit) : number => {
    const packagingsAndMappings = product.getPackagingsAndMappings();
    const priceProductQuantityUnit = getProductQuantityUnitForUnit(product, product.getUnitPrice().getUnit());

    const convertedProductPrice = PackagingUtils.convertProductQuantityToUnit(
        packagingsAndMappings,
        new QuantityInUnit(1, productQuantityUnit),
        priceProductQuantityUnit,
    ).getQuantity() * product.getDepositInDollars();

    return convertedProductPrice;
};

export const productUtils = {
    getProductWithNewPrice,
    getProductWithNewSku,
    getProductWithNewCategory,
    getProductWithNewGLCode,
    getProductQuantityUnitForUnit,
    convertPriceToProductQuantityUnit,
    convertDepositToProductQuantityUnit,
};
