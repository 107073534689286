import { ThunkAction } from 'redux-thunk';

import { LocationId } from 'api/Location/model/LocationId';
import { IRetailerPopulationFromInvoiceUploadService } from 'api/Onboarding/interfaces/IRetailerPopulationFromInvoiceUploadService';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';

import { ComponentName, IRetailerPopulationFromInvoiceUploadState } from '../reducers/RetailerPopulationFromInvoiceUploadReducers';

import { IExtraArguments } from 'shared/components/Provider';
import { IAccountSessionReader } from 'shared/lib/account/interfaces/IAccountSessionReader';
import { IAction, IActionCreatorsMapObject } from 'shared/models/IAction';

export interface IRetailerPopulationFromInvoiceUploadStore {
    retailerPopulationFromInvoiceUploadState : IRetailerPopulationFromInvoiceUploadState;
}

export const ActionTypes = {
    SET_COMPONENT_IS_SHOWN: 'RETAILER_POPULATION_FROM_INVOICE_UPLOAD/SET_COMPONENT_IS_SHOWN',
};

export namespace RetailerPopulationFromInvoiceUploadActionInterfaces {

    export interface ISetComponentIsShown extends IAction {
        payload : {
            componentName : ComponentName;
            isShown : boolean;
        };
    }

    export interface IServices {
        userSessionReader : IAccountSessionReader<UserSessionId, UserAccountId>;
        retailerPopulationFromInvoiceUploadService : IRetailerPopulationFromInvoiceUploadService;
    }

    export interface IRetailerPopulationFromInvoiceUploadExtraArguments extends IExtraArguments {
        services : IServices;
    }

    export interface IRetailerPopulationFromInvoiceUploadActionCreatorsMapObject extends IActionCreatorsMapObject {
        setComponentIsShown : (
            componentName : ComponentName,
            isShown : boolean,
        ) => ISetComponentIsShown;
        onSubmitInvoiceUploads : (
            invoiceUploadFiles : Array<File>,
        ) => ThunkAction<Promise<void>, IRetailerPopulationFromInvoiceUploadStore, IRetailerPopulationFromInvoiceUploadExtraArguments>;
    }
}

const setComponentIsShown = (
    componentName : ComponentName,
    isShown : boolean,
) : RetailerPopulationFromInvoiceUploadActionInterfaces.ISetComponentIsShown => ({
    payload: {
        isShown,
        componentName,
    },
    type: ActionTypes.SET_COMPONENT_IS_SHOWN
});

const onSubmitInvoiceUploads = (
    invoiceUploadFiles : Array<File>,
) : ThunkAction<Promise<void>, IRetailerPopulationFromInvoiceUploadStore, RetailerPopulationFromInvoiceUploadActionInterfaces.IRetailerPopulationFromInvoiceUploadExtraArguments> => {
    return (dispatch, getState, extraArguments) : Promise<void> => {
        dispatch(setComponentIsShown('isSubmitting', true));

        const userSessionId = extraArguments.services.userSessionReader.getSessionId();
        const locationId = new LocationId(window.GLOBAL_RETAILER_ID);

        return extraArguments.services.retailerPopulationFromInvoiceUploadService.uploadInvoices(userSessionId, locationId, invoiceUploadFiles)
        .then(() => {
            dispatch(setComponentIsShown('isSubmitting', false));
        });
    };
};

export const RetailerPopulationFromInvoiceUploadActions : RetailerPopulationFromInvoiceUploadActionInterfaces.IRetailerPopulationFromInvoiceUploadActionCreatorsMapObject = {
    setComponentIsShown,
    onSubmitInvoiceUploads,
};
