import { Action, combineReducers } from 'redux';

import { ActionTypes, SignUpOptionsFormActionInterfaces } from './actions';

import { ISignInFormState, SignInFormReducers } from '../SignInForm/reducers';
import { ISignUpFormState, SignUpFormReducers } from '../SignUpForm/reducers';
import { IThanksForRegisteringState, ThanksForRegisteringReducers } from '../ThanksForRegistering/reducers';

export type ISignUpOptionsFormIsShownState = boolean;
export type IActiveFormState = 'signUpForm' | 'signUpAndCreateRetailerForm' | 'signInForm';

const signUpOptionsFormIsShownInitialState : ISignUpOptionsFormIsShownState = false;
const activeFormInitialState : IActiveFormState = 'signUpAndCreateRetailerForm';

export const formIsShownReducer = (
    state : ISignUpOptionsFormIsShownState = signUpOptionsFormIsShownInitialState,
    action : Action,
) : ISignUpOptionsFormIsShownState => {
    switch (action.type) {
        case ActionTypes.SET_FORM_IS_SHOWN:
            return (action as SignUpOptionsFormActionInterfaces.ISetFormIsShown).payload.formIsShown;
        default:
            return state;
    }
};

export const activeFormReducer = (
    state : IActiveFormState = activeFormInitialState,
    action : Action,
) : IActiveFormState => {
    switch (action.type) {
        case ActionTypes.SET_ACTIVE_FORM:
            return (action as SignUpOptionsFormActionInterfaces.ISetActiveForm).payload.form;
        default:
            return state;
    }
};

export interface ISignUpOptionsFormState { // Should mirror SignUpOptionsFormReducers
    formIsShown : ISignUpOptionsFormIsShownState;
    activeForm : IActiveFormState;
    signUpForm : ISignUpFormState;
    signInForm : ISignInFormState;
}

export const SignUpOptionsFormReducers = combineReducers({
    formIsShown: formIsShownReducer,
    activeForm: activeFormReducer,
    signUpForm: SignUpFormReducers,
    signInForm: SignInFormReducers,
});

export interface ISignUpOptionsState { // Should mirror SignUpOptionsReducers
    signUpOptionsForm : ISignUpOptionsFormState;
    thanksForRegistering : IThanksForRegisteringState;
}

export const SignUpOptionsReducers = combineReducers({
    signUpOptionsForm: SignUpOptionsFormReducers,
    thanksForRegistering: ThanksForRegisteringReducers,
});
