import moment from 'moment-timezone';

import SalesItemModel from 'gen-thrift/sales_item_Model_types';

import { StringValueMap } from 'api/Core/StringValueMap';
import { InventoryCountId } from 'api/InventoryCount/model/InventoryCountId';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';

import { StringValueSet } from 'api/Core/StringValueSet';
import { ProductId } from 'api/Product/model/ProductId';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { ItemLevelSalesReportConfiguration } from '../model/ItemLevelSalesReportConfiguration';
import { ItemLevelSalesReportConfigurationId } from '../model/ItemLevelSalesReportConfigurationId';
import { SalesEntry } from '../model/SalesEntry';

export interface IItemLevelSalesReportConfigurationJSONObject {
    usage_bottle_adjustments_by_product_id : {[productIdValue : string] : {quantity_adjustment : number | null}};
    creation_time : string;
    date_processed : string | null;
    sales_by_sales_item_id : {[salesItemId : string] : ISalesEntryJSONObject};
    creator_user_id : string;
    bounding_inventory_id_0 : string;
    bounding_inventory_id_1 : string;
    is_processing : boolean;
    retailer_id : string;
    tracked_product_id_set : Array<string>;
    track_sales_items_with_no_components : boolean;
}

interface ISalesEntryJSONObject {
    price : number | null;
    quantity : number | null;
    quantity_adjustment : number | null;
    sales_adjustment : number | null;
}

export class ItemLevelSalesReportConfigurationSerializer {
    public getItemLevelSalesReportConfigurationsById(
        reportConfigurationsByIdJSONData : {[reportConfigurationId : string] : IItemLevelSalesReportConfigurationJSONObject}
    ) : StringValueMap<ItemLevelSalesReportConfigurationId, ItemLevelSalesReportConfiguration> {
        const result = new StringValueMap<ItemLevelSalesReportConfigurationId, ItemLevelSalesReportConfiguration>();

        Object.keys(reportConfigurationsByIdJSONData).forEach((idValue) => {
            const reportConfigurationJSONObject = reportConfigurationsByIdJSONData[idValue];

            result.set(
                new ItemLevelSalesReportConfigurationId(idValue),
                this.getItemLevelSalesReportConfiguration(reportConfigurationJSONObject)
            );
        });

        return result;
    }

    public getItemLevelSalesReportConfiguration(
        reportConfigurationJSONObject : IItemLevelSalesReportConfigurationJSONObject
    ) : ItemLevelSalesReportConfiguration {

        const creationTime = moment.utc(reportConfigurationJSONObject.creation_time);

        const creatorUserId = new UserAccountId(reportConfigurationJSONObject.creator_user_id);

        const dateProcessed = reportConfigurationJSONObject.date_processed !== null ? moment.utc(reportConfigurationJSONObject.date_processed) : null;

        const startingInventoryId = new InventoryCountId(reportConfigurationJSONObject.bounding_inventory_id_0);

        const endingInventoryId = new InventoryCountId(reportConfigurationJSONObject.bounding_inventory_id_1);

        const salesBySalesItemId : StringValueMap<SalesItemId, SalesEntry> =
            this.getSalesBySalesItemId(reportConfigurationJSONObject.sales_by_sales_item_id);

        const isProcessing : boolean = reportConfigurationJSONObject.is_processing === true;

        const trackedProductIdSet = new StringValueSet<ProductId>();
        reportConfigurationJSONObject.tracked_product_id_set.forEach((productIdValue) => trackedProductIdSet.add(new ProductId(productIdValue)));

        const trackSalesItemsWithNoComponents = reportConfigurationJSONObject.track_sales_items_with_no_components === true;

        const usageBottleAdjustmentsByProductId = new StringValueMap<ProductId, number>();
        Object.keys(reportConfigurationJSONObject.usage_bottle_adjustments_by_product_id).forEach((productIdValue) => {
            const quantityAdjustment = reportConfigurationJSONObject.usage_bottle_adjustments_by_product_id[productIdValue].quantity_adjustment;
            if (quantityAdjustment) {
                usageBottleAdjustmentsByProductId.set(new ProductId(productIdValue), quantityAdjustment);
            }
        });

        return new ItemLevelSalesReportConfiguration(
            creationTime,
            creatorUserId,
            dateProcessed,
            startingInventoryId,
            endingInventoryId,
            salesBySalesItemId,
            isProcessing,
            trackedProductIdSet,
            trackSalesItemsWithNoComponents,
            usageBottleAdjustmentsByProductId
        );
    }

    public getSalesBySalesItemId(
        salesBySalesItemIdJSONObject : {[salesItemId : string] : ISalesEntryJSONObject}
    ) : StringValueMap<SalesItemId, SalesEntry> {
        const result = new StringValueMap<SalesItemId, SalesEntry>();

        Object.keys(salesBySalesItemIdJSONObject).forEach((salesItemId) => {
            const salesEntry = salesBySalesItemIdJSONObject[salesItemId];
            result.set(
                new SalesItemId(salesItemId),
                new SalesEntry(
                    salesEntry.price,
                    salesEntry.quantity,
                    salesEntry.quantity_adjustment,
                    salesEntry.sales_adjustment
                )
            );
        });

        return result;
    }

    public getThriftItemLevelSalesReportConfigurationId(itemLevelSalesReportConfigurationId : ItemLevelSalesReportConfigurationId) : SalesItemModel.ItemLevelRevenueAndCostReportConfigurationId {
        return new SalesItemModel.ItemLevelRevenueAndCostReportConfigurationId({ value: itemLevelSalesReportConfigurationId.getValue() });
    }
}
