import UserSessionService from 'gen-thrift/UserSessionService';

import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { UserAccountObjectToThriftSerializer } from 'api/UserAccount/serializer/UserAccountObjectToThriftSerializer';
import { onNewCognitoSession } from 'shared/global/refreshJWT';

import { IAccountSessionCreator } from '../interfaces/IAccountSessionCreator';
import { IAccountSessionReader } from '../interfaces/IAccountSessionReader';
import { IAccountSessionTerminator } from '../interfaces/IAccountSessionTerminator';
import { IAccountSessionWriter } from '../interfaces/IAccountSessionWriter';

import { ICookieDeleter } from '../../cookie/interfaces/ICookieDeleter';
import { ICookieReader } from '../../cookie/interfaces/ICookieReader';
import { ICookieSetter } from '../../cookie/interfaces/ICookieSetter';

import { InvalidCookieNameException } from '../../cookie/exceptions/InvalidCookieNameException';
import { UnknownCookieException } from '../../cookie/exceptions/UnknownCookieException';
import { AccountSessionNotFoundException } from '../exceptions/AccountSessionNotFoundException';

import { Auth } from 'aws-amplify';
import { Observer } from 'shared/utils/observer';

import jwtDecode from 'jwt-decode';
import { RuntimeException } from '../../general/exceptions/RuntimeException';

export class UserSessionManagerImpl
    implements
        IAccountSessionCreator<UserAccountId>,
        IAccountSessionReader<UserSessionId, UserAccountId>,
        IAccountSessionTerminator,
        IAccountSessionWriter<UserSessionId> {

    private userSessionServiceClient : UserSessionService.UserSessionServiceClient;

    private cookieReader : ICookieReader;
    private cookieSetter : ICookieSetter;
    private cookieDeleter : ICookieDeleter;
    private userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer;

    private cookieName : string;

    constructor(
            userSessionServiceClient : UserSessionService.UserSessionServiceClient,

            cookieReader : ICookieReader,
            cookieSetter : ICookieSetter,
            cookieDeleter : ICookieDeleter,
            userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer) {

        this.userSessionServiceClient = userSessionServiceClient;

        this.cookieReader = cookieReader;
        this.cookieSetter = cookieSetter;
        this.cookieDeleter = cookieDeleter;
        this.userAccountObjectToThriftSerializer = userAccountObjectToThriftSerializer;

        this.cookieName = 'userSessionIdentifier';
    }
    ////////////////////////////////
    // IAccountSessionReader
    ////////////////////////////////
    public getSessionId() : UserSessionId {
        let cookieValue : string | null;
        try {
            cookieValue = this.cookieReader.getCookie(this.cookieName);
        } catch (e) {
            if (e instanceof UnknownCookieException) {
                throw new AccountSessionNotFoundException();
            }

            if (e instanceof InvalidCookieNameException) {
                throw new RuntimeException('unexpected');
            }

            throw new RuntimeException('unexpected');
        }

        if (cookieValue === null) {
            throw new AccountSessionNotFoundException();
        }

        return new UserSessionId(cookieValue); // remove = padding
    }

    public getAccountId() : Promise<UserAccountId> {
      return new Promise<any>((resolve, reject) => {
        Auth.currentAuthenticatedUser()
        .then((user) => {
          onNewCognitoSession(user.signInUserSession);
          const userObject : any = jwtDecode(user.signInUserSession.idToken.jwtToken);
          const userAccountId = new UserAccountId(userObject['cognito:username']);
          return resolve(userAccountId);
        })
        .catch((err) => {
            return reject(`No user found': ${err}`);
        });
      });
    }

    ////////////////////////////////
    // IAccountSessionTerminator
    ////////////////////////////////
    public terminateSession() : Promise<void> {
        let userSessionIdentifier : UserSessionId;

        try {
            userSessionIdentifier = this.getSessionId();
        } catch (e) {
            if (e instanceof AccountSessionNotFoundException) {
                throw e;
            }

            throw new RuntimeException('unexpected');
        }

        return new Promise<void>((resolve, reject) => {
            this.userSessionServiceClient.terminateSession(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionIdentifier),
                (result : void | Error) => {
                    try {
                        this.cookieDeleter.deleteCookie(this.cookieName);
                        Observer.observeLogout();
                    } catch (e) {
                        // do nothing
                    }

                    if (result instanceof Error) {
                        return reject(result);
                    }

                    return resolve();
                }
            );
        });
    }

    ////////////////////////////////
    // IAccountSessionWriter
    ////////////////////////////////
    public setSessionId(sessionId : UserSessionId) : void {
        try {
            this.cookieSetter.setCookie(this.cookieName, sessionId.getValue());
        } catch (e) {
            throw new RuntimeException('unexpected');
        }
    }
}
