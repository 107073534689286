import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { DefaultPour } from 'api/Location/interfaces/ILocationSettingsService';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemWithMetadata } from 'api/SalesItem/model/SalesItemWithMetadata';
import { ISearchBarState } from 'shared/models/ISearchBarState';
import { SortDirection } from 'shared/models/SortDirection';
import { ISortedFilteredAndGroupedResult } from 'shared/utils/sortingFilteringAndGroupingUtils';

export type ComponentName = 'defaultPourDialog' | 'contextMenu';

export enum MappedStatus {
    UNMAPPED = 'UNMAPPED',
    MAPPED = 'MAPPED',
    DELETED = 'DELETED'
}
export type SalesItemMapperGroupByOptionValue = 'Mapping Status' | 'All Items' | 'Menu Group';
export type SalesItemMapperColumnSortId = 'name' | 'sales_price';

export interface ISalesItemMapperData {
    contextSalesItemIds : StringValueSet<SalesItemId>;
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>;
    productsById : StringValueMap<ProductId, Product>;
    retailerDefaultPour : DefaultPour | null;
    // currentUserAccountId : UserAccountId;
    // namesByUserAccountId : StringValueMap<UserAccountId, { firstName : string, lastName : string }>;
}
export interface ISalesItemMapperState {
    salesItemMapperData : ISalesItemMapperData | null;

    activeSalesItemEditing : SalesItemId | null;

    // UI
    isShownByComponentName : { [componentName in ComponentName] : boolean };
    selectedSalesItemIds : StringValueSet<SalesItemId>;

    // table controls
    sortDirection : SortDirection;
    sortByColumnId : SalesItemMapperColumnSortId;
    groupByOption : SalesItemMapperGroupByOptionValue;
    searchBar : ISearchBarState;

    // grouping/sorting/filter/collapse state
    sortedFilteredGroupedResult : ISortedFilteredAndGroupedResult<SalesItemId>;
    panelIsOpenByGroupNameFromUserInput : {[groupName : string] : boolean};
    defaultPanelIsOpen : boolean;
}

export const initialState : ISalesItemMapperState = {
    salesItemMapperData: null,
    activeSalesItemEditing: null,
    selectedSalesItemIds: new StringValueSet(),

    sortedFilteredGroupedResult: {
        sortedRowIdsToDisplayByGroupName: {},
        sortedGroupNamesToDisplay: [],
        panelIsOpenByGroupNameFromFiltering : {},
        unfilteredSortedRowIdsByGroupName : {},
    },
    panelIsOpenByGroupNameFromUserInput: {},
    defaultPanelIsOpen: true,

    sortDirection: SortDirection.ASCENDING,
    sortByColumnId: 'name',
    groupByOption: 'Mapping Status',
    searchBar: {
        searchTerm: null,
        isDisabled: false,
        isFocused: false,
    },

    isShownByComponentName: {
        defaultPourDialog: false,
        contextMenu: false,
    }
};
