import { LocationId } from 'api/Location/model/LocationId';

import { IPmixUploadResponse, IPmixUploadService, PmixUploadFields } from '../interfaces/IPmixUploadService';

import { AjaxUtils } from 'shared/utils/ajaxUtils';
import { ItemLevelSalesReportConfigurationId } from '../model/ItemLevelSalesReportConfigurationId';
import { ItemLevelSalesReportConfigurationSerializer } from '../serializer/ItemLevelSalesReportConfigurationSerializer';

export class PmixUploadServiceImpl implements IPmixUploadService {

    constructor (
        private readonly itemLevelSalesSerializer : ItemLevelSalesReportConfigurationSerializer,
    ) {}

    public populateSalesReportFromUpload(
        locationId : LocationId,
        reportId : ItemLevelSalesReportConfigurationId,
        pmixRows : Array<{ [bevspotColumn in PmixUploadFields] : string | null }>
    ) : Promise<IPmixUploadResponse> {

        const requestBody = {
            upload_items: pmixRows,
        };

        const queryParameters = {
            retailer_id: locationId.getValue(),
            item_level_report_id: reportId.getValue()
        };

        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:pmix_upload'), requestBody, queryParameters)
        .then((response) => {
            const salesBySalesItemIdValues = response.sales_by_sales_item_id;
            const issuesByRowNumber = response.issues_by_row_number;
            const salesBySalesItemId = this.itemLevelSalesSerializer.getSalesBySalesItemId(salesBySalesItemIdValues);
            return Promise.resolve({
                salesBySalesItemId,
                issuesByRowNumber,
            });
        });
    }

    public notifySupportPmixSheetUploaded(
        locationId : LocationId,
        reportId : ItemLevelSalesReportConfigurationId,
        file : File | null,
    ) : Promise<void> {
        const formData : FormData = new FormData();
        formData.append('item_level_revenue_and_cost_report_configuration_id', reportId.getValue());
        if (file !== null) {
            formData.append(file.name, file);
        }

        return AjaxUtils.ajaxPostForm(url('sales:reports:item_level:notify_support_upload_pmix_sheets', null, locationId.getValue(), null), formData);
    }
}
