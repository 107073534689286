import CoreMathModel from 'gen-thrift/core_math_Model_types';
// TODO Use different decimal

import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';

export class ProductCount {
    constructor (
        private readonly count : CoreMathModel.Decimal | null,
        private readonly productQuantityUnit : ProductQuantityUnit,
    ) { }

    public getCount() : CoreMathModel.Decimal | null {
        return this.count;
    }

    public getProductQuantityUnit() : ProductQuantityUnit {
        return this.productQuantityUnit;
    }
}
