import React from 'react';

import { IHexColorsByCategoryName } from 'shared/models/Charts/IHexColorsByCategoryName';
import { IValuesByCategoryName } from 'shared/models/Charts/IValuesByCategoryName';
import { HexColor } from 'shared/models/HexColor';
import { LinearScale } from 'shared/models/StackedBarChart/LinearScale';

import { StackedBarCategoryStack } from './StackedBarCategoryStack';

import { utils } from 'shared/components/StackedBarGraph/utils';

/**
 * HorizontalStackedBar properties
 */
export interface IHorizontalStackedBar {
    /** numerical values by category for a stacked bar */
    valuesByCategoryName : IValuesByCategoryName;
    /** colors for each category possible within a stacked bar */
    categoryColorsByCategoryName : IHexColorsByCategoryName;
    /** starting y-value within an svg element for the stacked bar */
    yValue : number;
    /** scaling function to get the x-value of a given element */
    xScale : LinearScale;
    /** height for the stacked bar */
    barHeight : number;
    /** defaultColor for stacks */
    defaultColor : HexColor;
    /** mouse event for hovering over the bar */
    handleMouseOver : ((groupName : string) => void) | null;
    /** mouse event for leaving hover over bar */
    handleMouseOut : ((groupName : string) => void) | null;
    /** indicates whether per-category colors should be shown, or just the default */
    showColors : boolean;
    /** name of the stacked bar's parent label (the y-axis label) */
    groupName : string;
}

/**
 * Form HorizontalStackedBar
 */
export class HorizontalStackedBar extends React.Component<IHorizontalStackedBar, object> {
    public render() {
        const {
            valuesByCategoryName,
            categoryColorsByCategoryName,
            yValue,
            xScale,
            barHeight,
            defaultColor,
            handleMouseOut,
            handleMouseOver,
            showColors,
            groupName,
        } = this.props;

        const onMouseOut = () => {
            if (handleMouseOut !== null) {
                handleMouseOut(groupName);
            }
        };
        const onMouseOver = () => {
            if (handleMouseOver !== null) {
                handleMouseOver(groupName);
            }
        };

        const series = utils.getBarStackDataFromCategoryData(valuesByCategoryName, false); // if want a stacked bar in the negative direction, need to pass in this boolean or calculate

        const stackedRectangles = (series).map((seriesData, index) => {
            const keyName : string = seriesData.key;
            const rectangleColor : HexColor = categoryColorsByCategoryName[keyName] || defaultColor;

            // scaling sometimes creates a gap between stack components because of rounding to nearest pixel -- add 1 to stackWidth (except for last stack) to handle this
            const xValue : number = utils.scaleValueLinear(seriesData[0][0], xScale);
            let stackWidth : number;
            if ((seriesData[0][1] - seriesData[0][0]) === 0) {
                stackWidth = 0;
            } else {
                stackWidth = utils.scaleValueLinear((seriesData[0][1] - seriesData[0][0]), xScale) + 1;
                if (index === (series.length - 1) && stackWidth > 1) {
                    stackWidth = stackWidth - 1;
                }
            }

            return (
                <StackedBarCategoryStack
                    fillColor={ (showColors ? rectangleColor : defaultColor) }
                    componentKey={ index }
                    componentId={ groupName }
                    xValue={ xValue }
                    yValue={ yValue }
                    height={ barHeight }
                    width={ stackWidth }
                    key={ index }
                    categoryName={ keyName }
                />
            );
        });

        return(
            <g
                className="bar-chart-stacked-bar stacked-bar-group"
                onMouseOut={ onMouseOut }
                onMouseOver={ onMouseOver }
                onMouseLeave={ onMouseOut }
                onMouseEnter={ onMouseOver }
                id={ 'stacked-bar-group-' + groupName }
                key={ groupName }
                data-group-name={ groupName }
            >
                { stackedRectangles }
            </g>
        );
    }

}
