import { BreakageId } from 'api/Breakage/model/BreakageId';
import { BreakageReport } from 'api/Breakage/model/BreakageReport';
import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { InventoryCountId } from 'api/InventoryCount/model/InventoryCountId';
import { Delivery } from 'api/Ordering/model/Delivery';
import { DeliveryId } from 'api/Ordering/model/DeliveryId';
import { PrepEvent } from 'api/PrepEvent/model/PrepEvent';
import { PrepEventId } from 'api/PrepEvent/model/PrepEventId';
import { ProductId } from 'api/Product/model/ProductId';
import { TransferId } from 'api/Transfer/model/TransferId';
import { TransferReport } from 'api/Transfer/model/TransferReport';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import { IProductHistoryData } from 'apps/ItemCard/reducers/ItemCardReducers';

export class ProductActivityOnDay {
    constructor(
        public deliveryIds = new StringValueSet<DeliveryId>(),
        public inventoryCountIds = new StringValueSet<InventoryCountId>(),
        public transferIds = new StringValueSet<TransferId>(),
        public breakageIds = new StringValueSet<BreakageId>(),
        public prepEventIds = new StringValueSet<PrepEventId>(),
        public mergeTo = new StringValueSet<ProductId>(),
        public mergeFrom = new StringValueSet<ProductId>(),
    ) {}
}

export type ProductActivityByDayByMonth = Map<string, Map<number, ProductActivityOnDay>>;

let lastSeenProductHistoryData : IProductHistoryData;
const activityDataByProductId = new StringValueMap<ProductId, ProductActivityByDayByMonth>();

export function getActivityDataForProduct(productHistoryData : IProductHistoryData, productId : ProductId) : ProductActivityByDayByMonth {

    if (typeof lastSeenProductHistoryData !== 'undefined' && lastSeenProductHistoryData === productHistoryData) {
        const cachedActivityData = activityDataByProductId.get(productId);
        if (typeof cachedActivityData !== 'undefined') {
            return cachedActivityData;
        }
    } else {
        activityDataByProductId.clear();
    }

    lastSeenProductHistoryData = productHistoryData;

    const activityByDayByMonth = new Map<string, Map<number, ProductActivityOnDay>>();

    const thisMonthStartDate = new Date();
    thisMonthStartDate.setDate(1);
    activityByDayByMonth.set(getDateString(thisMonthStartDate), new Map<number, ProductActivityOnDay>());
    for (let monthIndex = 1; monthIndex <= productHistoryData.numMonthsOfData; monthIndex++) {
        const monthStartDate = new Date();
        monthStartDate.setDate(1);
        monthStartDate.setMonth(thisMonthStartDate.getMonth() - monthIndex);
        activityByDayByMonth.set(getDateString(monthStartDate), new Map<number, ProductActivityOnDay>());
    }

    productHistoryData.inventoryCountsByInventoryId.forEach((inventoryCount, inventoryCountId) => {
        let productExistsInInventoryCount = false;
        const productIdListsByStorageAreaId = inventoryCount.getInventoryConfiguration().getSortedProductIdListsByStorageAreaId();
        for (const productIdList of Array.from(productIdListsByStorageAreaId.values())) {
            if (productIdList.find((productIdInStorageArea) => productIdInStorageArea.equals(productId))) {
                productExistsInInventoryCount = true;
                break;
            }
        }
        if (productExistsInInventoryCount) {
            const inventoryMetadata = productHistoryData.inventoryMetadataByInventoryId.get(inventoryCountId);
            if (typeof inventoryMetadata === 'undefined') {
                throw new RuntimeException('Inventory not found: ' + inventoryCountId.getValue());
            }
            const inventoryCountDate = new Date(inventoryMetadata.getInventoryCountTime().timeSinceUnixEpoch.value);
            const inventoryCountMonth = new Date(inventoryCountDate);
            inventoryCountMonth.setDate(1);
            const activityInMonth = activityByDayByMonth.get(getDateString(inventoryCountMonth));
            if (typeof activityInMonth === 'undefined') {
                throw new RuntimeException('inventory count data not found: ' + inventoryCountMonth);
            }
            let activityInDay = activityInMonth.get(inventoryCountDate.getDate());
            if (typeof activityInDay === 'undefined') {
                activityInDay = new ProductActivityOnDay();
            }
            activityInDay.inventoryCountIds.add(inventoryCountId);
            activityInMonth.set(inventoryCountDate.getDate(), activityInDay);
        }
    });

    productHistoryData.deliveriesByDeliveryId.forEach((delivery : Delivery, deliveryId : DeliveryId) => {
        let deliveryHasProduct = false;
        for (const deliveryLineItem of delivery.getDeliveryLineItems()) {
            if (deliveryLineItem.getProductId().equals(productId)) {
                deliveryHasProduct = true;
                break;
            }
        }

        if (deliveryHasProduct) {
            const deliveryDate = delivery.getDateDeliveredUTC().toDate();
            const deliveryDateMonth = new Date(deliveryDate);
            deliveryDateMonth.setDate(1);
            const activityInMonth = activityByDayByMonth.get(getDateString(deliveryDateMonth));
            if (typeof activityInMonth === 'undefined') {
                throw new RuntimeException('delivery data not found: ' + deliveryDateMonth);
            }
            let activityInDay = activityInMonth.get(deliveryDate.getDate());
            if (typeof activityInDay === 'undefined') {
                activityInDay = new ProductActivityOnDay();
            }
            activityInDay.deliveryIds.add(deliveryId);
            activityInMonth.set(deliveryDate.getDate(), activityInDay);
        }
    });

    productHistoryData.transferReportsByTransferId.forEach((transfer : TransferReport, transferId : TransferId) => {
        let transferHasProduct = false;
        for (const productAmount of transfer.getTransferReportData().getProductAmounts()) {
            if (productAmount.getProductId().equals(productId)) {
                transferHasProduct = true;
            }
        }

        if (transferHasProduct) {
            const transferDate = new Date(transfer.getTransferReportData().getTime().timeSinceUnixEpoch.value);
            const transferDateMonth = new Date(transferDate);
            transferDateMonth.setDate(1);
            const activityInMonth = activityByDayByMonth.get(getDateString(transferDateMonth));
            if (typeof activityInMonth === 'undefined') {
                throw new RuntimeException('transfer data not found: ' + transferDateMonth);
            }
            let activityInDay = activityInMonth.get(transferDate.getDate());
            if (typeof activityInDay === 'undefined') {
                activityInDay = new ProductActivityOnDay();
            }
            activityInDay.transferIds.add(transferId);
            activityInMonth.set(transferDate.getDate(), activityInDay);
        }
    });

    productHistoryData.breakageReportsByBreakageId.forEach((breakage : BreakageReport, breakageId : BreakageId) => {
        let breakageHasProduct = false;
        for (const productAmount of breakage.getBreakageReportData().getProductAmounts()) {
            if (productAmount.getProductId().equals(productId)) {
                breakageHasProduct = true;
            }
        }

        if (breakageHasProduct) {
            const breakageDate = new Date(breakage.getBreakageReportData().getTime().timeSinceUnixEpoch.value);
            const breakageDateMonth = new Date(breakageDate);
            breakageDateMonth.setDate(1);
            const activityInMonth = activityByDayByMonth.get(getDateString(breakageDateMonth));
            if (typeof activityInMonth === 'undefined') {
                throw new RuntimeException('breakage data not found: ' + breakageDateMonth);
            }
            let activityInDay = activityInMonth.get(breakageDate.getDate());
            if (typeof activityInDay === 'undefined') {
                activityInDay = new ProductActivityOnDay();
            }
            activityInDay.breakageIds.add(breakageId);
            activityInMonth.set(breakageDate.getDate(), activityInDay);
        }
    });

    productHistoryData.prepEventsByPrepEventId.forEach((prepEvent : PrepEvent, prepEventId : PrepEventId) => {
        const prepEventHasProduct = prepEvent.getOutputQuantityOfProductByProductId().has(productId) || prepEvent.getInputQuantityOfProductByProductId().has(productId);
        if (prepEventHasProduct) {
            const prepEventDate = prepEvent.getPrepEventTime().toDate();
            const prepEventDateMonth = new Date(prepEventDate);
            prepEventDateMonth.setDate(1);
            const activityInMonth = activityByDayByMonth.get(getDateString(prepEventDateMonth));
            if (typeof activityInMonth === 'undefined') {
                throw new RuntimeException('prep event data not found: ' + prepEventDateMonth);
            }
            let activityInDay = activityInMonth.get(prepEventDate.getDate());
            if (typeof activityInDay === 'undefined') {
                activityInDay = new ProductActivityOnDay();
            }
            activityInDay.prepEventIds.add(prepEventId);
            activityInMonth.set(prepEventDate.getDate(), activityInDay);
        }
    });
    const mergeEvents = productHistoryData.productMergeEventsByProductId.get(productId);
    if (mergeEvents) {
        mergeEvents.forEach((mergeEvent) => {
            const mergeEventDate = new Date(mergeEvent.getUserAccountIdAndTimestamp().getTimestamp().timeSinceUnixEpoch.value);
            const mergeEventDateMonth = new Date(mergeEventDate);
            mergeEventDateMonth.setDate(1);
            const activityInMonth = activityByDayByMonth.get(getDateString(mergeEventDateMonth));
            if (typeof activityInMonth === 'undefined') {
                // merge event is older than (now - GLOBAL_RETAILER_DATA_HISTORY_LIMIT_IN_DAYS)
                return;
            }

            const activityInDay = activityInMonth.get(mergeEventDate.getDate()) || new ProductActivityOnDay();

            if (mergeEvent.getMergedToProductId().equals(productId)) {
                const mergeProductIds = mergeEvent.getMergedFromProductIds();
                if (!mergeProductIds) {
                    throw new RuntimeException('merge product IDs not found: ' + mergeEvent.getMergedToProductId());
                }

                mergeProductIds.forEach((productId2) => {
                    activityInDay.mergeFrom.add(productId2);
                });
                activityInMonth.set(mergeEventDate.getDate(), activityInDay ? activityInDay : new ProductActivityOnDay());
            } else {
                const mergeProductId = mergeEvent.getMergedToProductId();

                activityInDay.mergeTo.add(mergeProductId);
                activityInMonth.set(mergeEventDate.getDate(), activityInDay ? activityInDay : new ProductActivityOnDay());
            }
        });
    }

    activityDataByProductId.set(productId, activityByDayByMonth);

    return activityByDayByMonth;
}

function getDateString(date : Date) : string {
    return (date.getMonth() + 1) + '/' + date.getDate() + '/' +  date.getFullYear();
}
