import React from 'react';

import { IHexColorsByCategoryName } from 'shared/models/Charts/IHexColorsByCategoryName';
import { SvgPattern } from 'shared/models/StackedBarChart/SvgPattern';

import { SvgPatternDefinitions } from './SvgPatternDefinitions';

/**
 * ChartLegend properties
 */
export interface IChartLegend {
    /** colors for each category possible within a stacked bar */
    categoryColorsByCategoryName : IHexColorsByCategoryName;
    /** height of square for the legend in pixels */
    legendSquareSize : number;
    /** possible pattern for legend square */
    svgPattern : SvgPattern;

    // Future work: give option for vertical layout (and necessary spacing?)
}

/**
 * Form ChartLegend
 */
export class ChartLegend extends React.Component<IChartLegend, object> {
    public render() {
        const {
            categoryColorsByCategoryName,
            legendSquareSize,
            svgPattern,
        } = this.props;

        const legendLabels = Object.keys(categoryColorsByCategoryName).map((keyName : string, index : number) => {
            const keyClassName = keyName.split(' ').join('-').toLowerCase() + '-block';
            return (
                <div
                    className="cell chart-legend-cell"
                    key={ index }
                >
                        <svg
                            className="chart-legend-square"
                            height={ legendSquareSize }
                            width={ legendSquareSize }
                        >
                            <SvgPatternDefinitions
                                svgPattern={ svgPattern }
                            />

                            <rect
                                className={ keyClassName }
                                fill={ categoryColorsByCategoryName[keyName].getValue() }
                                height={ legendSquareSize }
                                width={ legendSquareSize }
                            />
                        </svg>

                        <span className="chart-legend-text">{ keyName }</span>
                </div>
            );
        });

        return (
            <div className="col-row">
                { legendLabels }
            </div>
        );

    }
}
