export class UserSessionId {
    constructor(private readonly value : string) {
    }

    public getValue() : string {
        return this.value;
    }

    public equals(other : UserSessionId) : boolean {
        return this.getValue() === other.getValue();
    }
}
