import { StringValueMap } from 'api/Core/StringValueMap';
import { Distributor } from 'api/Distributor/model/Distributor';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { ProductCount } from 'api/InventoryCount/model/ProductCount';
import { ProductCountEvent } from 'api/InventoryCount/model/ProductCountEvent';

import { ProductQuickAdd } from 'api/Onboarding/model/ProductQuickAdd';
import { Product } from 'api/Product/model/Product';
import { ProductDistributorAssociation } from 'api/Product/model/ProductDistributorAssociation';
import { ProductDistributorAssociationIdentifier } from 'api/Product/model/ProductDistributorAssociationIdentifier';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { ALL_ITEMS_GROUP_NAME, GroupByOption } from 'shared/models/GroupByOption';
import { decimalToNumber } from 'shared/utils/decimalUtils';
import { cleanPhrase, } from 'shared/utils/sortingFilteringAndGroupingUtils';
import {
    getDefaultTimestampGroupName,
    getTimestampGroupComparator
} from 'shared/utils/timestampSortingFilteringAndGroupingUtils';

/*
Commonly used filtering/grouping/sorting logic for product tables.
 */

export const createProductStringForFilter = (product : Product, distributorForProduct : Distributor | null, productDistributorAssociationMap : StringValueMap<ProductDistributorAssociationIdentifier, ProductDistributorAssociation>) : string => {
    const brand = cleanPhrase(product.getBrand());
    const name = cleanPhrase(product.getName());
    const category = cleanPhrase(product.getProductCategoryId());
    const type = cleanPhrase(product.getProductType());
    const note = cleanPhrase(product.getNote()); // right now tokenizing all words in note -- may want to update behavior in the future to only search entire phrase

    const distributorName = distributorForProduct === null ? '' : cleanPhrase(distributorForProduct.getName());

    const skus = new Array<string>() ;

    if (window.GLOBAL_FEATURE_ACCESS.multi_vendor) {
        // We need to grab the SKU of each productDistributorAssociation so multi-vendor users can search for
        // them in pages with product tables, such as the Item Manager.

        // If the user ever goes back to single-vendor, these SKUs will still be added to the search string, so searching
        // for these skus will still yield results. This shouldn't be a problem, since converting to multi-vendor is
        // typically a one-way street.

        productDistributorAssociationMap.forEach((productDistributorAssociation) => {
            const sku = productDistributorAssociation.getSku();
            if (sku) {
                skus.push(cleanPhrase(sku));
            }
        });
    } else {
        skus.push(cleanPhrase(product.getSku()));
    }

    return [brand, name, category, type, skus.join(' '), note, distributorName].join(' ');
};

export const createProductDistributorAssociationStringForFilter = (product : Product, productDistributorAssociation : ProductDistributorAssociation, distributor : Distributor) : string => {
    const brand = cleanPhrase(product.getBrand());
    const name = cleanPhrase(product.getName());
    const category = cleanPhrase(product.getProductCategoryId());
    const type = cleanPhrase(product.getProductType());

    const productDistributorAssociationSku = productDistributorAssociation.getSku();
    const sku = productDistributorAssociationSku ? cleanPhrase(productDistributorAssociationSku) : '';

    const note = cleanPhrase(product.getNote()); // right now tokenizing all words in note -- may want to update behavior in the future to only search entire phrase

    const distributorName = cleanPhrase(distributor.getName());

    return [brand, name, category, type, sku, note, distributorName].join(' ');
};

const productFilterStringCache = new StringValueMap<ProductId, string>(); // TODO Cheezy
let lastProductsById = new StringValueMap<ProductId, Product>();
let lastDistributorIdsByProductId = new StringValueMap<ProductId, DistributorId | null>();
let lastDistributorsById = new StringValueMap<DistributorId, Distributor>();

const cachedCreateProductStringForFilter = (
    identifier : ProductId | ProductDistributorAssociation,
    productsById : StringValueMap<ProductId, Product>,
    distributorIdsByProductId : StringValueMap<ProductId, DistributorId | null>,
    distributorsById : StringValueMap<DistributorId, Distributor>,
    productDistributorAssociationsByProductId : StringValueMap<ProductId, StringValueMap<ProductDistributorAssociationIdentifier, ProductDistributorAssociation>>
) : string => {

    if ((productsById !== lastProductsById) || (distributorIdsByProductId !== lastDistributorIdsByProductId) || (distributorsById !== lastDistributorsById)) {
        lastProductsById = productsById;
        lastDistributorIdsByProductId = distributorIdsByProductId;
        lastDistributorsById = distributorsById;
        productFilterStringCache.clear();
    }

    if ((identifier instanceof ProductId) && productFilterStringCache.has(identifier)) { // TODO Cheezy
        const cacheValue  = productFilterStringCache.get(identifier);

        if (typeof cacheValue === 'undefined') {
            throw new RuntimeException('unexpected');
        }

        return cacheValue;
    }

    let productId : ProductId;
    let distributorId : DistributorId | null | undefined;

    if (identifier instanceof ProductId) {
        productId = identifier;
        distributorId = distributorIdsByProductId.get(productId);
    } else {
        productId = identifier.getProductId();
        distributorId = identifier.getDistributorId();
    }

    const product = productsById.get(productId);

    if ((typeof product === 'undefined') || (typeof distributorId === 'undefined')) {
        throw new RuntimeException('unexpected');
    }

    let distributor : Distributor | null | undefined;
    if (distributorId === null) {
        distributor = null;
    } else {
        distributor = distributorsById.get(distributorId);

        if (typeof distributor === 'undefined') {
            throw new RuntimeException('unexpected');
        }
    }

    let productStringForFilter : string;
    if (identifier instanceof ProductId) {
        const productDistributorAssociationMap = productDistributorAssociationsByProductId.get(productId) || new StringValueMap();
        productStringForFilter = createProductStringForFilter(product, distributor, productDistributorAssociationMap);
    } else {
        if (distributor === null) {
            throw new RuntimeException('unexpected');
        }

        productStringForFilter = createProductDistributorAssociationStringForFilter(product, identifier, distributor);
    }
   
    productFilterStringCache.set(productId, productStringForFilter);

    return productStringForFilter;
};

export const defaultProductRowFilter = (
    identifier : ProductId | ProductDistributorAssociation,
    cleanedFilterTerm : string | null,
    productsById : StringValueMap<ProductId, Product>,
    distributorIdsByProductId : StringValueMap<ProductId, DistributorId | null>,
    distributorsById : StringValueMap<DistributorId, Distributor>,
    productDistributorAssociationsByProductId : StringValueMap<ProductId, StringValueMap<ProductDistributorAssociationIdentifier, ProductDistributorAssociation>>
) : boolean => {
    if (cleanedFilterTerm === null || cleanedFilterTerm === '') {
        return true;
    }

    const productStringForSearch = cachedCreateProductStringForFilter(identifier, productsById, distributorIdsByProductId, distributorsById, productDistributorAssociationsByProductId);
    const filterTokens = cleanedFilterTerm.split(' ');

    for (let i = 0; i < filterTokens.length; i++) {
        if (productStringForSearch.indexOf(filterTokens[i]) < 0) {
            return false;
        }
    }

    return true;
};

// TODO Cheezy this should check feature flags for cart builder
// group name based on GroupByOption
export const getDefaultGroupNameForProductRow = (
    identifier : ProductId | ProductDistributorAssociation,
    groupByOption : GroupByOption,
    productsById : StringValueMap<ProductId, Product>,
    distributorIdsByProductId : StringValueMap<ProductId, DistributorId | null>,
    distributorsById : StringValueMap<DistributorId, Distributor>
) : string => {
    let productId : ProductId;
    let distributorId : DistributorId | null | undefined;

    if (identifier instanceof ProductId) {
        productId = identifier;
        distributorId = distributorIdsByProductId.get(productId);
    } else {
        productId = identifier.getProductId();
        distributorId = identifier.getDistributorId();
    }

    const product = productsById.get(productId);

    if ((typeof product === 'undefined') || (typeof distributorId === 'undefined')) {
        throw new RuntimeException('unexpected');
    }

    switch (groupByOption) {
        case GroupByOption.ALL_ITEMS:
            return ALL_ITEMS_GROUP_NAME;
        case GroupByOption.DISTRIBUTOR:
            if (distributorId === null) {
                return 'Other';
            }

            const distributor = distributorsById.get(distributorId);
            if (typeof distributor === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            return distributor.getName();
        case GroupByOption.CATEGORY:
            return product.getProductCategoryId();
        case GroupByOption.ITEM_TYPE:
            return product.getProductType();
        case GroupByOption.LAST_EDITED:
            return getDefaultTimestampGroupName(product.getLastUpdateEvent().getTimestamp());
        default:
            throw new RuntimeException('unexpected groupByOption');
    }
};

// group name comparator based on GroupByOption
export const defaultGroupNameComparator = (groupName1 : string, groupName2 : string, groupByOption : GroupByOption) : number => {
    switch (groupByOption) {
        case GroupByOption.ALL_ITEMS:
            // should not matter
            return 0;
        case GroupByOption.GL_CODE:
        case GroupByOption.DISTRIBUTOR:
        case GroupByOption.CATEGORY:
        case GroupByOption.ITEM_TYPE:
            return cleanPhrase(groupName1).localeCompare(cleanPhrase(groupName2));
        case GroupByOption.LAST_EDITED:
            return getTimestampGroupComparator(groupName1, groupName2);
        default:
            throw new RuntimeException('unexpected groupByOption');
    }
};

export const getCleanCountFromCountEvent = (productCountEvent : ProductCountEvent | undefined) : number | undefined => {
    if (typeof productCountEvent !== 'undefined') {
        const productCount = productCountEvent.getProductCount();
        return getCountValueFromProductCount(productCount);
    }

    return;
};

export const getCountValueFromProductCount = (productCount : ProductCount | undefined) : number | undefined => {
    if (typeof productCount !== 'undefined') {
        const count = productCount.getCount();
        if (count !== null) {
            return decimalToNumber(count);
        }
    }

    return;
};

export const getProductParValue = (productPar : QuantityInUnit<ProductQuantityUnit> | undefined | null) : number | undefined => {
    if ((typeof productPar !== 'undefined') && (productPar !== null)) {
        return productPar.getQuantity();
    }

    return;
};

// TODOfuture: can probably further improve performance but this seems a significant speed improvement over old code already
// trying to avoid using cleanPhrase but want to have products with no brand get sorted ignoring whitespace
export const getBrandAndNameStringForSort = (product : Product | ProductQuickAdd) : string => {
    return (product.getBrand() + ' ' + product.getName()).trim().toLowerCase();
};
