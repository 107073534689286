import * as _ from 'lodash';

import { ProductAmount } from 'api/Product/model/ProductAmount';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { OrderedItemList } from 'shared/models/OrderedItemList';

export interface IProductAmountByListElementId {
    [listElementId : string] : ProductAmount;
}
export class OrderedProductAmountList extends OrderedItemList<ProductAmount> {

    constructor(args : {
        listElementIds : Array<string>;
        itemByListElementId : IProductAmountByListElementId;
    }) {
        super(args);
    }

    public clone() : OrderedProductAmountList {
        return new OrderedProductAmountList({
            itemByListElementId: _.cloneDeep(this.itemByListElementId),
            listElementIds: this.listElementIds.slice(0),
        });
    }

    public getOrderedElements() : Array<ProductAmount> {
        return super.getOrderedElements() as Array<ProductAmount>;
    }

    public getListElementById(listElementId : string) : ProductAmount | null {
        return super.getListElementById(listElementId) as ProductAmount;
    }

    public getItemByListElementId() : IProductAmountByListElementId {
        return super.getItemByListElementId();
    }

    public addListElement(item : ProductAmount) : string {
        return super.addListElement(item);
    }

    public editListElementQuantity(listElementId : string, quantity : number) : void {
        if (this.listElementIds.indexOf(listElementId) === -1) {
            throw new Error('listElementId does not exist in listElementIds');
        }
        const productAmountToUpdate = this.itemByListElementId[listElementId];
        const item : ProductAmount = new ProductAmount(
            productAmountToUpdate.getProductId(),
            new QuantityInUnit(quantity, productAmountToUpdate.getQuantityInUnit().getUnit())
        );
        this.itemByListElementId[listElementId] = item;
    }

    public removeProductAmount(listElementIdToRemove : string, setToNull : boolean) : void {
        const listElementIds = this.listElementIds;
        const elementIndex = listElementIds.indexOf(listElementIdToRemove);
        if (elementIndex === -1) {
            throw new Error('listElementId does not exist in listElementIds');
        }
        super.removeListElement(listElementIdToRemove);
    }
}
