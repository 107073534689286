import { DistributorId } from 'api/Distributor/model/DistributorId';
import { ICategoryService } from 'api/Product/interfaces/ICategoryService';
import { Category } from 'api/Product/model/Category';
import { CategoryId } from 'api/Product/model/CategoryId';
import { productUtils } from 'api/Product/utils/productUtils';
import { UserAccountIdAndTimestamp } from 'api/UserAccount/model/UserAccountIdAndTimestamp';
import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { IDistributorService } from 'api/Distributor/interfaces/IDistributorService';
import { ILocationProductService } from 'api/Location/interfaces/ILocationProductService';
import { LocationId } from 'api/Location/model/LocationId';
import { IProductDistributorService } from 'api/Product/interfaces/IProductDistributorService';
import { IProductService } from 'api/Product/interfaces/IProductService';
import { Mappings } from 'api/Product/model/Mappings';
import { Packaging } from 'api/Product/model/Packaging';
import { PackagingsAndMappings } from 'api/Product/model/PackagingsAndMappings';
import { Price } from 'api/Product/model/Price';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { oldPackagingUtils } from 'api/Product/utils/oldPackagingUtils';
import { CategoryUtils } from 'api/Product/utils/categoryUtils';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { PackagingFormValidationInputDataByFieldName } from 'shared/components/Product/PackagingForm';

import { IExtraArguments } from 'shared/components/Provider';
import {
    OptionsAndLabelNameTuples
} from 'shared/components/Select2Dropdown/Select2DropdownMenu';

import { IAccountSessionReader } from 'shared/lib/account/interfaces/IAccountSessionReader';
import { DateTime } from 'shared/models/DateTime';

import { batchActions, IAction, IActionCreatorsMapObject } from 'shared/models/IAction';
import { IProductSlimCreateFormData, IProductSlimCreateFormState, ProductSlimCreateFormComponentName, ProductSlimCreateFormFieldName } from './ProductSlimCreateFormReducers';

// TODO should move the ProductForm to shared
import { DEFAULT_PRICE_VALUE } from 'apps/ItemCard/reducers/ItemCardReducers';
import { ProductFormUtils } from 'apps/ItemCard/utils/ProductFormUtils';
import { Conversions } from 'api/Product/model/Conversions';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';

export interface IProductSlimCreateFormStore {
    productSlimCreateFormState : IProductSlimCreateFormState;
}

export const ActionTypes = {
    SET_FORM_DATA : 'PRODUCT_SLIM_CREATE_FORM/SET_PRODUCT_FORM_DATA',
    SET_FORM_FIELD_VALIDATION_DATA : 'PRODUCT_SLIM_CREATE_FORM/SET_FORM_FIELD_VALIDATION_DATA',
    SET_FORM_VALIDATION_DATA : 'PRODUCT_SLIM_CREATE_FORM/SET_FORM_VALIDATION_DATA',
    SET_IS_SUBMITTING : 'PRODUCT_SLIM_CREATE_FORM/SET_IS_SUBMITTING',
    RESET_FORM : 'PRODUCT_SLIM_CREATE_FORM/RESET_FORM',
    SET_COMPONENT_IS_SHOWN: 'PRODUCT_SLIM_CREATE_FORM/SET_COMPONENT_IS_SHOWN',
    SET_CATEGORIES_BY_ID: 'PRODUCT_SLIM_CREATE_FORM/SET_CATEGORIES_BY_ID',
    CREATE_CUSTOM_CATEGORY : 'PRODUCT_SLIM_CREATE_FORM/CREATE_CUSTOM_CATEGORY',
};

export namespace ProductSlimCreateFormActionInterfaces {

    export interface ISetFormData extends IAction {
        payload : {
            formData : IProductSlimCreateFormData;
        };
    }

    export interface ISetFormFieldValidationData extends IAction {
        payload : {
            field : ProductSlimCreateFormFieldName;
            value : string | boolean;
            isValid : boolean;
            errorMessage : string;
        };
    }

    export interface ISetFormValidationData extends IAction {
        payload : {
            isValid : boolean;
            errorMessage : string;
        };
    }

    export interface ISetIsSubmitting extends IAction {
        payload : {
            isSubmitting : boolean;
        };
    }

    export interface IResetForm extends IAction {
        payload : {};
    }

    export interface ISetComponentIsShown extends IAction {
        payload : {
            componentName : ProductSlimCreateFormComponentName
            isShown : boolean,
        };
    }

    export interface ISetCategoriesById extends IAction {
        payload : {
            categoriesById : StringValueMap<CategoryId, Category>;
        };
    }

    export interface ICreateCustomCategory extends IAction {
        payload : {
            category : string;
        };
    }

    export interface IServices {
        userSessionReader : IAccountSessionReader<UserSessionId, UserAccountId>;
        productService : IProductService;
        locationProductService : ILocationProductService;
        distributorService : IDistributorService;
        productDistributorService : IProductDistributorService;
        categoryService : ICategoryService;
    }

    export interface IProductSlimCreateFormExtraArguments extends IExtraArguments {
        services : IServices;
    }

    export interface IProductSlimCreateFormActionCreatorsMapObject extends IActionCreatorsMapObject {
        onFormFieldChange : (
            field : ProductSlimCreateFormFieldName,
            value : string
        ) => ThunkAction<void, IProductSlimCreateFormStore, ProductSlimCreateFormActionInterfaces.IProductSlimCreateFormExtraArguments>;
        onFormFieldBlur : (
            field : ProductSlimCreateFormFieldName,
            value : string
        ) => ThunkAction<void, IProductSlimCreateFormStore, ProductSlimCreateFormActionInterfaces.IProductSlimCreateFormExtraArguments>;
        setFormFieldValidationData : (
            field : ProductSlimCreateFormFieldName,
            value : string,
            isValid : boolean,
            errorMessage : string,
        ) => ProductSlimCreateFormActionInterfaces.ISetFormValidationData;
        setFormValidationData : (
            isValid : boolean,
            errorMessage : string,
        ) => ProductSlimCreateFormActionInterfaces.ISetFormValidationData;
        resetForm : () => ProductSlimCreateFormActionInterfaces.IResetForm;
        createCustomCategory : (
            category : string,
        ) => ProductSlimCreateFormActionInterfaces.ICreateCustomCategory;
        onSubmit : (
            packaging : Packaging | null,
            shouldIncludePrice : boolean,
            shouldShowDistributorAndSkuFields : boolean,
            onSaveCallback : (productId : ProductId) => void,
        ) => ThunkAction<Promise<ProductId | void>, IProductSlimCreateFormStore, ProductSlimCreateFormActionInterfaces.IProductSlimCreateFormExtraArguments>;
        onValidateFields : (
            shouldSetFormFieldValidationData : boolean,
        ) => ThunkAction<boolean, IProductSlimCreateFormStore, ProductSlimCreateFormActionInterfaces.IProductSlimCreateFormExtraArguments>;
        setComponentIsShown : (
            componentName : ProductSlimCreateFormComponentName,
            isShown : boolean,
        ) => ISetComponentIsShown;
        setCategoriesById : (
            categoriesById : StringValueMap<CategoryId, Category>,
        ) => ISetCategoriesById;
        onPackagingChange : (
            packagingFormValidationInputDataByFieldName : PackagingFormValidationInputDataByFieldName,
        ) => ThunkAction<void, IProductSlimCreateFormStore, ProductSlimCreateFormActionInterfaces.IProductSlimCreateFormExtraArguments>;
        fetchProductSlimCreateFormData : (
        ) => ThunkAction<Promise<void>, IProductSlimCreateFormStore, ProductSlimCreateFormActionInterfaces.IProductSlimCreateFormExtraArguments>;
    }
}

const setFormData = (
    formData : IProductSlimCreateFormData,
) : ProductSlimCreateFormActionInterfaces.ISetFormData => ({
    payload : {
        formData,
    },
    type : ActionTypes.SET_FORM_DATA,
});

const setFormFieldValidationData = (
    field : ProductSlimCreateFormFieldName,
    value : string,
    isValid : boolean,
    errorMessage : string
) : ProductSlimCreateFormActionInterfaces.ISetFormFieldValidationData => ({
    payload : {
        field,
        value,
        isValid,
        errorMessage
    },
    type : ActionTypes.SET_FORM_FIELD_VALIDATION_DATA,
});

const setFormValidationData = (
    isValid : boolean,
    errorMessage : string,
) : ProductSlimCreateFormActionInterfaces.ISetFormValidationData => ({
    payload : {
        isValid,
        errorMessage
    },
    type : ActionTypes.SET_FORM_VALIDATION_DATA,
});

const setIsSubmitting = (
    isSubmitting : boolean
) : ProductSlimCreateFormActionInterfaces.ISetIsSubmitting => ({
    payload : {
        isSubmitting,
    },
    type : ActionTypes.SET_IS_SUBMITTING,
});

const resetForm = () : ProductSlimCreateFormActionInterfaces.IResetForm => ({
    payload : {},
    type: ActionTypes.RESET_FORM,
});

const setComponentIsShown = (
    componentName : ProductSlimCreateFormComponentName,
    isShown : boolean,
) : ProductSlimCreateFormActionInterfaces.ISetComponentIsShown => ({
    payload: {
        isShown,
        componentName,
    },
    type: ActionTypes.SET_COMPONENT_IS_SHOWN
});

const setCategoriesById = (
    categoriesById : StringValueMap<CategoryId, Category>,
) : ProductSlimCreateFormActionInterfaces.ISetCategoriesById => ({
    payload: {
        categoriesById
    },
    type: ActionTypes.SET_CATEGORIES_BY_ID
});

const createCustomCategory = (
    category : string,
) : ProductSlimCreateFormActionInterfaces.ICreateCustomCategory => ({
    payload : {
        category,
    },
    type: ActionTypes.CREATE_CUSTOM_CATEGORY,
});

const fetchProductSlimCreateFormData = (
) : ThunkAction<Promise<void>, IProductSlimCreateFormStore, ProductSlimCreateFormActionInterfaces.IProductSlimCreateFormExtraArguments > => {
    return (dispatch : Dispatch<IProductSlimCreateFormStore>, getState : () => IProductSlimCreateFormStore, extraArguments : ProductSlimCreateFormActionInterfaces.IProductSlimCreateFormExtraArguments) : Promise<void> => {

        const {
            userSessionReader,
            locationProductService,
            productService,
            distributorService,
            categoryService,
        } = extraArguments.services;

        const userSessionId = userSessionReader.getSessionId();
        const locationId = new LocationId(window.GLOBAL_RETAILER_ID);

        return Promise.all([
            distributorService.getAvailableDistributorsByIdForLocation(userSessionId, locationId),
            distributorService.getRetailerDistributorRelationships(locationId),
            locationProductService.getProductIds(userSessionId, locationId).then((products) =>
                productService.getProductsById(userSessionId, locationId, products)),
            categoryService.getCategoriesForRetailer(userSessionId, locationId),
        ])
        .then(([distributorsById, retailerDistributorRelationships, { productsById }, categoriesById]) => {

            const distributorIdsWithRelationship =
                new StringValueSet(Array.from(retailerDistributorRelationships.keys()));
            const distributorSortedOptionsAndLabelNames =
                ProductFormUtils.getDistributorSortedOptionsAndLabelNames(
                    distributorsById, distributorIdsWithRelationship, true);

            const categorySortedOptionsAndLabelNames : OptionsAndLabelNameTuples =
                CategoryUtils.getCategorySortedOptionsAndLabelNames(categoriesById, null);

            dispatch(batchActions([
                setFormData({
                    distributorSortedOptionsAndLabelNames,
                    categorySortedOptionsAndLabelNames,
                }),
                setCategoriesById(categoriesById),
            ]));
        });
    };
};

const onValidateFields = (
    shouldSetFormFieldValidationData : boolean,
) : ThunkAction<boolean, IProductSlimCreateFormStore, ProductSlimCreateFormActionInterfaces.IProductSlimCreateFormExtraArguments > => {
    return (dispatch : Dispatch<IProductSlimCreateFormStore>, getState : () => IProductSlimCreateFormStore, extraArguments : ProductSlimCreateFormActionInterfaces.IProductSlimCreateFormExtraArguments) : boolean => {
        const validationInputDataByFieldName = getState().productSlimCreateFormState.validationInputDataByFieldName;

        let formIsValid = true;

        Object.keys(validationInputDataByFieldName).forEach((key) => {
            const fieldName = key as ProductSlimCreateFormFieldName;

            const fieldValue = validationInputDataByFieldName[fieldName].value;

            const validationResult = ProductFormUtils.validateValueByFieldName(fieldName, fieldValue);

            const { isValid, errorMessage } = validationResult;

            formIsValid = formIsValid && isValid;

            if (shouldSetFormFieldValidationData) {
                dispatch(setFormFieldValidationData(fieldName, fieldValue, isValid, errorMessage));
            }
        });

        return formIsValid;
    };
};

const onFormFieldChange = (
    field : ProductSlimCreateFormFieldName,
    value : string,
) : ThunkAction<
    void,
    IProductSlimCreateFormStore,
    ProductSlimCreateFormActionInterfaces.IProductSlimCreateFormExtraArguments
    > => {
    return (dispatch : Dispatch<IProductSlimCreateFormStore>, getState : () => IProductSlimCreateFormStore, extraArguments : ProductSlimCreateFormActionInterfaces.IProductSlimCreateFormExtraArguments) : void => {
        dispatch(setFormValidationData(true, ''));
        const validationResult = ProductFormUtils.validateValueByFieldName(field, value);
        dispatch(setFormFieldValidationData(field, value, validationResult.isValid, validationResult.errorMessage));
    };
};

const onFormFieldBlur = (
    field : ProductSlimCreateFormFieldName,
    value : string,
) : ThunkAction<
    void,
    IProductSlimCreateFormStore,
    ProductSlimCreateFormActionInterfaces.IProductSlimCreateFormExtraArguments
    > => {
    return (dispatch : Dispatch<IProductSlimCreateFormStore>, getState : () => IProductSlimCreateFormStore, extraArguments : ProductSlimCreateFormActionInterfaces.IProductSlimCreateFormExtraArguments) : void => {
        let blurValue = value;
        if (field === 'priceAmount') {
            if (blurValue.trim() === '') {
                blurValue = DEFAULT_PRICE_VALUE;
            }
        }

        const validationResult = ProductFormUtils.validateValueByFieldName(field, blurValue);
        dispatch(setFormFieldValidationData(field, blurValue, validationResult.isValid, validationResult.errorMessage));
    };
};

const onSubmit = (
    packaging : Packaging | null,
    shouldIncludePrice : boolean,
    shouldShowDistributorAndSkuFields : boolean,
    onSaveCallback : (productId : ProductId) => void
) : ThunkAction<
        Promise<ProductId | void>,
        IProductSlimCreateFormStore,
        ProductSlimCreateFormActionInterfaces.IProductSlimCreateFormExtraArguments
    > => {
    return (dispatch : Dispatch<IProductSlimCreateFormStore>, getState : () => IProductSlimCreateFormStore, extraArguments : ProductSlimCreateFormActionInterfaces.IProductSlimCreateFormExtraArguments) => {
        const formIsValid = dispatch(onValidateFields(true));
        if (!packaging) {
            dispatch(ProductSlimCreateFormActions.setFormValidationData(false, 'Item packaging has missing or invalid information'));
            return Promise.resolve();
        } else if (!formIsValid) {
            dispatch(ProductSlimCreateFormActions.setFormValidationData(false, 'Invalid product information'));
            return Promise.resolve();
        }

        dispatch(setIsSubmitting(true));
        const validationInputDataByFieldName = getState().productSlimCreateFormState.validationInputDataByFieldName;
        const userAccountIdAndTimestamp = new UserAccountIdAndTimestamp(new UserAccountId('bevspot_system'), DateTime.now().toTimestampWithMillisecondPrecision());
        const formData = getState().productSlimCreateFormState.formData;
        if (formIsValid && formData) {
            let price : Price;
            if (shouldIncludePrice) {
                price = new Price(
                    parseFloat(validationInputDataByFieldName.priceAmount.value),
                    oldPackagingUtils.getUnitFromValue(validationInputDataByFieldName.priceUnit.value)
                );
            } else {
                price = new Price(0, oldPackagingUtils.getOldPackagingFromPackaging(packaging).getUnit());
            }
            const categoriesById = getState().productSlimCreateFormState.categoriesById;
            const categoryId = new CategoryId(validationInputDataByFieldName.categoryId.value);
            const productWithDummyPackaging = new Product(
                '',
                validationInputDataByFieldName.name.value,
                new PackagingsAndMappings(
                    oldPackagingUtils.getPackagingDataFromPackagingArray([packaging]),
                    new Mappings(new StringValueMap(), new Map()),
                    new Conversions(PackagingUtils.getBaseUnitOfPackaging(packaging), new StringValueMap(), new Map())
                ),
                PackagingUtils.getContainerPackagingId(packaging), // PTODO
                new StringValueMap(),
                validationInputDataByFieldName.categoryId.value,
                categoryId,
                '',
                price,
                0,
                shouldShowDistributorAndSkuFields ? validationInputDataByFieldName.sku.value : '',
                '',
                '',
                userAccountIdAndTimestamp,
            );

            const userSessionId = extraArguments.services.userSessionReader.getSessionId();
            const locationId = new LocationId(window.GLOBAL_RETAILER_ID);
            const categoryPromise = categoriesById.has(categoryId) ?
                Promise.resolve(categoryId) :
                extraArguments.services.categoryService.createCategory(
                    userSessionId,
                    locationId,
                    new Category(
                        validationInputDataByFieldName.categoryId.value,
                        '',
                        false,
                        ''
                    )
                ).then((result) => {
                    const newCategoriesById = new StringValueMap(categoriesById);
                    newCategoriesById.set(
                        result.category_id,
                        new Category(
                            validationInputDataByFieldName.categoryId.value,
                            '',
                            false,
                            result.category_hash
                        )
                    );
                    dispatch(setFormData({
                        ...formData,
                        categorySortedOptionsAndLabelNames: CategoryUtils.getCategorySortedOptionsAndLabelNames(newCategoriesById, null)
                    }));
                    dispatch(setCategoriesById(newCategoriesById));
                    return Promise.resolve(result.category_id);
                });
            return Promise.resolve(categoryPromise).then((newCategoryId) => {
                const product = productUtils.getProductWithNewCategory(
                    ProductFormUtils.getProductDistributorAndParForEditOrCreate(productWithDummyPackaging, null, null, null, null).product,
                    newCategoryId,
                    categoriesById.has(newCategoryId) ? categoriesById.getRequired(newCategoryId).getName() : validationInputDataByFieldName.categoryId.value
                );
                return extraArguments.services.productService.createProduct(userSessionId, locationId, product)
                .then((productId : ProductId) => {
                    let distributorAssociationPromise = Promise.resolve();
                    if (shouldShowDistributorAndSkuFields && validationInputDataByFieldName.distributorId.value) {
                        const distributorId = new DistributorId(validationInputDataByFieldName.distributorId.value);
                        distributorAssociationPromise = extraArguments.services.productDistributorService.associateDistributorIdWithProductId(userSessionId, productId, distributorId);
                    }

                    return Promise.all([
                        extraArguments.services.locationProductService.setProductAsActive(userSessionId, locationId, productId),
                        distributorAssociationPromise,
                    ])
                    .then(() => {
                        onSaveCallback(productId);
                        return Promise.resolve(productId);
                    });
                });
            }).catch((error : any) => {
                dispatch(setIsSubmitting(false));
                throw error;
            });
        } else {
            dispatch(setFormValidationData(false, 'Invalid product information'));
            dispatch(setIsSubmitting(false));
            return Promise.resolve();
        }
    };
};

const onPackagingChange = (
    packagingFormValidationInputDataByFieldName : PackagingFormValidationInputDataByFieldName,
) : ThunkAction<void, IProductSlimCreateFormStore, ProductSlimCreateFormActionInterfaces.IProductSlimCreateFormExtraArguments> => {
    return (dispatch : Dispatch<IProductSlimCreateFormStore>, getState : () => IProductSlimCreateFormStore, extraArguments : ProductSlimCreateFormActionInterfaces.IProductSlimCreateFormExtraArguments) : void => {
        dispatch(setFormValidationData(true, ''));
        const priceUnitSortedOptionsAndLabelName = ProductFormUtils.getPriceUnitIOptions(packagingFormValidationInputDataByFieldName);
        const priceUnitValue = getState().productSlimCreateFormState.validationInputDataByFieldName.priceUnit.value;

        const index = priceUnitSortedOptionsAndLabelName[0][1].findIndex((iOption) => {
            return iOption.value === priceUnitValue;
        });

        if (index === -1) {
            dispatch(onFormFieldChange('priceUnit', priceUnitSortedOptionsAndLabelName[0][1][0].value || ''));
        } else {
            dispatch(onFormFieldChange('priceUnit', priceUnitValue)); // To cause rerender
        }
    };
};

export const ProductSlimCreateFormActions : ProductSlimCreateFormActionInterfaces.IProductSlimCreateFormActionCreatorsMapObject = {
    fetchProductSlimCreateFormData,
    onFormFieldChange,
    onFormFieldBlur,
    setFormFieldValidationData,
    setFormValidationData,
    onSubmit,
    resetForm,
    createCustomCategory,
    onValidateFields,
    onPackagingChange,
    setComponentIsShown,
    setCategoriesById,
};
