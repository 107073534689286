import React from 'react';

import { MAX_TABLET_WIDTH } from 'shared/constants';

import { ContextTab } from './ContextTab';

export type PageContext = 'DEFAULT' | '/account/dashboard' | '/inventory/price_change_report' | '/inventory/usage_report' |
                            '/inventory/excess_inventory' | '/inventory/unused_inventory' |
                            '/inventory/aggregated_transfer_report' | '/inventory/aggregated_breakage_report' |
                            '/inventory/aggregated_prep_report' | '/inventory/history' | '/inventory/detail/view' |
                            '/ordering/record' | '/ordering/record/invoice_upload_manager' | '/ordering/record/detail' |
                            '/ordering/cart' | '/ordering/cart/builder' | '/ordering/cart/review' | '/sales/history' |
                            '/sales/items/overview' | '/sales/reports/category_level' | '/sales/reports/item_level/creation_options' |
                            '/sales/reports/item_level/summary' | '/sales/reports/item_level/sales_input' |
                            '/sales/reports/item_level/sales_performance' | '/sales/reports/item_level/product_variance' |
                            '/account/item_manager' | '/account/relationships' | '/account/settings' | '/account/users' |
                            '/account/billing' | '/account/integrations';

const contextTabsByPageContext : { [key in PageContext] : Array<ContextTab> } = {
    DEFAULT : [ ContextTab.HOME ],
    '/account/dashboard': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/inventory/price_change_report': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/inventory/usage_report': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/inventory/excess_inventory': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/inventory/unused_inventory': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/inventory/aggregated_transfer_report': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/inventory/aggregated_breakage_report': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/inventory/aggregated_prep_report': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/inventory/history': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/inventory/detail/view': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/ordering/record': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/ordering/cart': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/ordering/cart/builder': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/ordering/cart/review': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/ordering/record/invoice_upload_manager': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/ordering/record/detail': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/sales/history': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/sales/items/overview': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/sales/reports/category_level': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/sales/reports/item_level/creation_options': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/sales/reports/item_level/summary': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/sales/reports/item_level/sales_input': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/sales/reports/item_level/sales_performance': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/sales/reports/item_level/product_variance': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/account/item_manager': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ], // NOTE: shared with "my storage areas"!
    '/account/relationships': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/account/settings': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/account/users': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/account/billing': [ ContextTab.HOME, ContextTab.HELP, ContextTab.TUTORIAL, ContextTab.VIDEOS ],
    '/account/integrations': [ ContextTab.HOME, ContextTab.HELP ],
};

const noContentDefault = <p>No content available</p>;

export const validatePath = (path : string) => {
    return typeof contextTabsByPageContext[path as PageContext] !== 'undefined';
};

export const UserSessionUtils = {
    contextTabsByPageContext,
    getContextTabContent: (pageContext : PageContext, tab : ContextTab) => {
        switch (pageContext) {
            case '/account/dashboard':
                return getContentAccountDashboard(tab);
            case '/inventory/price_change_report':
                return getContentPriceChangeReport(tab);
            case '/inventory/usage_report':
                return getContentUsageReport(tab);
            case '/inventory/excess_inventory':
                return getContentExcessInventoryReport(tab);
            case '/inventory/unused_inventory':
                return getContentUnusedInventoryReport(tab);
            case '/inventory/aggregated_transfer_report':
                return getContentTransferReport(tab);
            case '/inventory/aggregated_breakage_report':
                return getContentLossReport(tab);
            case '/inventory/aggregated_prep_report':
                return getContentPrepReport(tab);
            case '/inventory/history':
                return getContentInventoryHistory(tab);
            case '/inventory/detail/view':
                return getContentInventoryDraft(tab);
            case '/ordering/record':
                return getContentOrderHistory(tab);
            case '/ordering/record/detail':
                return getContentOrderDetail(tab);
            case '/ordering/cart':
            case '/ordering/cart/builder':
            case '/ordering/cart/review':
                return getContentCartBuilder(tab);
            case '/ordering/record/invoice_upload_manager':
                return getContentInvoiceUploadManager(tab);
            case '/sales/history':
                return getContentSalesHistory(tab);
            case '/sales/items/overview':
                return getContentMySalesItem(tab);
            case '/sales/reports/category_level':
                return getContentCategorySalesReport(tab);
            case '/sales/reports/item_level/creation_options':
            case '/sales/reports/item_level/summary':
                return getContentItemLevelSalesReportSummary(tab);
            case '/sales/reports/item_level/sales_input':
                return getContentItemLevelSalesReportInput(tab);
            case '/sales/reports/item_level/sales_performance':
                return getContentItemLevelSalesReportPerformance(tab);
            case '/sales/reports/item_level/product_variance':
                return getContentItemLevelSalesReportVariance(tab);
            case '/account/item_manager':
                if (window.location.search.indexOf('view=storage_areas') > -1) {
                    return getContentMyStorageAreas(tab);
                } else {
                    return getContentMyItems(tab);
                }
            case '/account/relationships':
                return getContentMyVendors(tab);
            case '/account/settings':
                return getContentAccountSettings(tab);
            case '/account/users':
                return getContentUsersSettings(tab);
            case '/account/billing':
                return getContentPlansAndBilling(tab);
            case '/account/integrations':
                return getContentIntegrations(tab);
            case 'DEFAULT':
            default:
                return noContentDefault;
        }
    },
};

/** Begin Workaround for WalkMe on Mobile */

type HelpLinkId =
    'walkme-context-help-dashboard-getting-around-bevspot-trial' |
    'walkme-context-help-dashboard-getting-around-bevspot-customer' |
    'walkme-context-help-dashboard-insights' |
    'walkme-context-help-dashboard-taking-inventory' |
    'walkme-context-help-dashboard-video-dashboard-free-trial' |
    'walkme-context-help-dashboard-video-dashboard' |
    'walkme-context-help-dashboard-video-group-adding-items' |
    'walkme-context-help-dashboard-video-group-first-inventory' |
    'walkme-context-help-dashboard-video-group-first-order' |
    'walkme-context-help-price-tracker-report-run' |
    'walkme-context-help-price-tracker-video-overview' |
    'walkme-context-help-usage-report-run' |
    'walkme-context-help-usage-report-video-overview' |
    'walkme-context-help-usage-report-video-negative-usage' |
    'walkme-context-help-excess-inventory-report-run' |
    'walkme-context-help-excess-inventory-report-video-overview' |
    'walkme-context-help-unused-inventory-report-run' |
    'walkme-context-help-unused-inventory-report-video-overview' |
    'walkme-context-help-transfer-report-run' |
    'walkme-context-help-transfer-report-video-overview' |
    'walkme-context-help-loss-report-run' |
    'walkme-context-help-loss-report-video-overview' |
    'walkme-context-help-prep-report-run' |
    'walkme-context-help-prep-report-video-overview' |
    'walkme-context-help-inventory-history-first-count' |
    'walkme-context-help-inventory-history-recording-loss' |
    'walkme-context-help-inventory-history-adding-transfers' |
    'walkme-context-help-inventory-history-recording-prep-item' |
    'walkme-context-help-inventory-history-video-overview' |
    'walkme-context-help-inventory-history-video-first-inventory' |
    'walkme-context-help-inventory-history-video-recording-loss' |
    'walkme-context-help-inventory-history-video-adding-transfers' |
    'walkme-context-help-inventory-history-video-recording-prep-item' |
    'walkme-context-help-inventory-draft-counting-items' |
    'walkme-context-help-inventory-draft-storage-areas' |
    'walkme-context-help-inventory-draft-custom-sorting' |
    'walkme-context-help-inventory-draft-exporting-records' |
    'walkme-context-help-inventory-draft-video-counting-items-beverage' |
    'walkme-context-help-inventory-draft-video-counting-items-food' |
    'walkme-context-help-inventory-draft-video-storage-areas-desktop' |
    'walkme-context-help-inventory-draft-video-custom-sorting' |
    'walkme-context-help-inventory-draft-video-offline-mode' |
    'walkme-context-help-inventory-draft-video-negative-usage' |
    'walkme-context-help-inventory-draft-video-exporting-records' |
    'walkme-context-help-order-history-record-past-invoice' |
    'walkme-context-help-order-history-balancing-invoices-confirming-deliveries' |
    'walkme-context-help-order-history-exporting-records' |
    'walkme-context-help-order-history-video-overview' |
    'walkme-context-help-order-history-video-first-order' |
    'walkme-context-help-order-history-video-record-past-invoice' |
    'walkme-context-help-order-history-video-confirming-deliveries' |
    'walkme-context-help-order-history-video-exporting-records' |
    'walkme-context-help-order-detail-invoices-deliveries' |
    'walkme-context-help-order-detail-reviewing-processed-invoices' |
    'walkme-context-help-order-detail-video-confirming-deliveries' |
    'walkme-context-help-order-detail-video-reviewing-invoices' |
    'walkme-context-help-cart-builder-placing-orders' |
    'walkme-context-help-cart-builder-using-smart-cart' |
    'walkme-context-help-cart-builder-video-placing-orders' |
    'walkme-context-help-cart-builder-video-smart-cart' |
    'walkme-context-help-invoice-processing-submitting' |
    'walkme-context-help-invoice-processing-reviewing' |
    'walkme-context-help-invoice-processing-video-overview' |
    'walkme-context-help-invoice-processing-video-processing' |
    'walkme-context-help-invoice-processing-video-reviewing' |
    'walkme-context-help-invoice-processing-video-errors' |
    'walkme-context-help-sales-history-understanding' |
    'walkme-context-help-sales-history-starting-new-report' |
    'walkme-context-help-sales-history-starting-new-category-report' |
    'walkme-context-help-sales-history-video-overview' |
    'walkme-context-help-sales-history-video-first-report-setup' |
    'walkme-context-help-sales-history-video-submitting-data' |
    'walkme-context-help-sales-history-video-understanding-variance' |
    'walkme-context-help-sales-items-creating' |
    'walkme-context-help-sales-items-need-attention' |
    'walkme-context-help-sales-items-exporting' |
    'walkme-context-help-sales-items-video-creating' |
    'walkme-context-help-sales-items-video-tax-percent' |
    'walkme-context-help-sales-items-video-need-attention' |
    'walkme-context-help-sales-category-report-creating' |
    'walkme-context-help-sales-category-report-understanding' |
    'walkme-context-help-sales-category-report-exporting' |
    'walkme-context-help-sales-category-report-video-overview' |
    'walkme-context-help-sales-item-level-report-summary-creating' |
    'walkme-context-help-sales-item-level-report-summary-understanding' |
    'walkme-context-help-sales-item-level-report-summary-exporting' |
    'walkme-context-help-sales-item-level-report-summary-video-overview' |
    'walkme-context-help-sales-item-level-report-summary-video-variance' |
    'walkme-context-help-sales-item-level-report-input-understanding' |
    'walkme-context-help-sales-item-level-report-input-editing' |
    'walkme-context-help-sales-item-level-report-input-exporting' |
    'walkme-context-help-sales-item-level-report-input-video-overview' |
    'walkme-context-help-sales-item-level-report-performance-understanding' |
    'walkme-context-help-sales-item-level-report-performance-editing' |
    'walkme-context-help-sales-item-level-report-performance-exporting' |
    'walkme-context-help-sales-item-level-report-performance-video-overview' |
    'walkme-context-help-sales-item-level-report-variance-understanding' |
    'walkme-context-help-sales-item-level-report-variance-exporting' |
    'walkme-context-help-sales-item-level-report-variance-video-understanding' |
    'walkme-context-help-my-items' |
    'walkme-context-help-my-items-catalog' |
    'walkme-context-help-my-items-creating' |
    'walkme-context-help-my-items-video-overview' |
    'walkme-context-help-my-items-video-bulk-edit' |
    'walkme-context-help-my-items-video-adding-items' |
    'walkme-context-help-my-items-video-importing-items-from-invoices' |
    'walkme-context-help-my-items-video-creating-items' |
    'walkme-context-help-my-storage-areas' |
    'walkme-context-help-my-torage-areas-catalog' |
    'walkme-context-help-my-torage-areas-creating' |
    'walkme-context-help-my-storage-areas-video-overview' |
    'walkme-context-help-my-vendors' |
    'walkme-context-help-my-vendors-video-overview' |
    'walkme-context-help-account-settings' |
    'walkme-context-help-account-settings-video-overview' |
    'walkme-context-help-users-settings' |
    'walkme-context-help-users-settings-video-overview' |
    'walkme-context-help-plans-billing-setup-cc' |
    'walkme-context-help-plans-billing-setup-ach' |
    'walkme-context-help-plans-billing-video-overview';

interface IHelpLinkInfo {
    label : string;
    walkMe : {
        id : number;
        nodeId : number;
    };
}

const HELP_LINK_INFO : { [key in HelpLinkId] : IHelpLinkInfo } = {
    'walkme-context-help-dashboard-getting-around-bevspot-trial': {
        label: 'Getting Around BevSpot',
        walkMe: {
            id: 294551,
            nodeId: 2413641,
        }
    },
    'walkme-context-help-dashboard-getting-around-bevspot-customer': {
        label: 'Getting Around BevSpot',
        walkMe: {
            id: 294551,
            nodeId: 2413641,
        }
    },
    'walkme-context-help-dashboard-insights': {
        label: 'Dashboard Insights',
        walkMe: {
            id: 364607,
            nodeId: 3211304,
        }
    },
    'walkme-context-help-dashboard-taking-inventory': {
        label: 'Taking Your First Inventory',
        walkMe: {
            id: 318305,
            nodeId: 2698279,
        }
    },
    'walkme-context-help-dashboard-video-dashboard-free-trial': {
        label: 'Understanding Your BevSpot Dashboard',
        walkMe: {
            id: 364468,
            nodeId: 3210279,
        }
    },
    'walkme-context-help-dashboard-video-dashboard': {
        label: 'Understanding Your BevSpot Dashboard',
        walkMe: {
            id: 364466,
            nodeId: 3210262,
        }
    },
    'walkme-context-help-dashboard-video-group-adding-items': {
        label: 'Adding Items to Your Account',
        walkMe: {
            id: 364472,
            nodeId: 3210296,
        }
    },
    'walkme-context-help-dashboard-video-group-first-inventory': {
        label: 'Taking Your First Inventory',
        walkMe: {
            id: 364474,
            nodeId: 3210303,
        }
    },
    'walkme-context-help-dashboard-video-group-first-order': {
        label: 'Placing Your First Orders',
        walkMe: {
            id: 364476,
            nodeId: 3210319,
        }
    },
    'walkme-context-help-price-tracker-report-run': {
        label: 'Price Tracker Report Run',
        walkMe: {
            id: 315662,
            nodeId: 2670435,
        }
    },
    'walkme-context-help-price-tracker-video-overview': {
        label: 'Tracking Price Changes',
        walkMe: {
            id: 364477,
            nodeId: 3210323,
        }
    },
    'walkme-context-help-usage-report-run': {
        label: 'Usage Report Run',
        walkMe: {
            id: 232824,
            nodeId: 1807683,
        }
    },
    'walkme-context-help-usage-report-video-overview': {
        label: 'Tracking Your Product Usage',
        walkMe: {
            id: 364478,
            nodeId: 3210331,
        }
    },
    'walkme-context-help-usage-report-video-negative-usage': {
        label: 'Understanding Negative Usage',
        walkMe: {
            id: 364479,
            nodeId: 3210336,
        }
    },
    'walkme-context-help-excess-inventory-report-run': {
        label: 'Excess Inventory Report Run',
        walkMe: {
            id: 232856,
            nodeId: 1807967,
        }
    },
    'walkme-context-help-excess-inventory-report-video-overview': {
        label: 'Finding Your Excess Inventory Items',
        walkMe: {
            id: 364480,
            nodeId: 3210346,
        }
    },
    'walkme-context-help-unused-inventory-report-run': {
        label: 'Unused Inventory Report Run',
        walkMe: {
            id: 232870,
            nodeId: 1808224,
        }
    },
    'walkme-context-help-unused-inventory-report-video-overview': {
        label: 'Finding Your Unused Inventory Items',
        walkMe: {
            id: 364481,
            nodeId: 3210348,
        }
    },
    'walkme-context-help-transfer-report-run': {
        label: 'Transfer Report Run',
        walkMe: {
            id: 233067,
            nodeId: 1810136,
        }
    },
    'walkme-context-help-transfer-report-video-overview': {
        label: 'Tracking Your Transfer History',
        walkMe: {
            id: 364482,
            nodeId: 3210351,
        }
    },
    'walkme-context-help-loss-report-run': {
        label: 'Loss Report Run',
        walkMe: {
            id: 233087,
            nodeId: 1810368,
        }
    },
    'walkme-context-help-loss-report-video-overview': {
        label: 'Tracking Loss in Your Restaurant',
        walkMe: {
            id: 364483,
            nodeId: 3210356,
        }
    },
    'walkme-context-help-prep-report-run': {
        label: 'Prep Report Run',
        walkMe: {
            id: 299952,
            nodeId: 2483428,
        }
    },
    'walkme-context-help-prep-report-video-overview': {
        label: 'Reporting on Prepared Items',
        walkMe: {
            id: 364484,
            nodeId: 3210359,
        }
    },
    'walkme-context-help-inventory-history-first-count': {
        label: 'First Inventory Count',
        walkMe: {
            id: 318305,
            nodeId: 2698279,
        }
    },
    'walkme-context-help-inventory-history-recording-loss': {
        label: 'Recording Loss',
        walkMe: {
            id: 320045,
            nodeId: 2714944,
        }
    },
    'walkme-context-help-inventory-history-adding-transfers': {
        label: 'Adding Transfers',
        walkMe: {
            id: 320080,
            nodeId: 2715263,
        }
    },
    'walkme-context-help-inventory-history-recording-prep-item': {
        label: 'Recording a Prepared Item',
        walkMe: {
            id: 321290,
            nodeId: 2727861,
        }
    },
    'walkme-context-help-inventory-history-video-overview': {
        label: 'Inventory Overview',
        walkMe: {
            id: 241767,
            nodeId: 1895999,
        }
    },
    'walkme-context-help-inventory-history-video-first-inventory': {
        label: 'Taking Your First Inventory',
        walkMe: {
            id: 364474,
            nodeId: 3210303,
        }
    },
    'walkme-context-help-inventory-history-video-recording-loss': {
        label: 'How to Record Loss for Your Restaurant',
        walkMe: {
            id: 364485,
            nodeId: 3210361,
        }
    },
    'walkme-context-help-inventory-history-video-adding-transfers': {
        label: 'How to Transfer Items',
        walkMe: {
            id: 364487,
            nodeId: 3210394,
        }
    },
    'walkme-context-help-inventory-history-video-recording-prep-item': {
        label: 'Recording Your Prepared Items',
        walkMe: {
            id: 364492,
            nodeId: 3210403,
        }
    },
    'walkme-context-help-inventory-draft-counting-items': {
        label: 'Counting Items',
        walkMe: {
            id: 318368,
            nodeId: 2698702,
        }
    },
    'walkme-context-help-inventory-draft-storage-areas': {
        label: 'Storage Areas in Draft',
        walkMe: {
            id: 318413,
            nodeId: 2699361,
        }
    },
    'walkme-context-help-inventory-draft-custom-sorting': {
        label: 'Custom Sorting',
        walkMe: {
            id: 318481,
            nodeId: 2700185,
        }
    },
    'walkme-context-help-inventory-draft-exporting-records': {
        label: 'Exporting Inventory Records',
        walkMe: {
            id: 319794,
            nodeId: 2712109,
        }
    },
    'walkme-context-help-inventory-draft-video-counting-items-beverage': {
        label: 'How to Count Your Beverage Items',
        walkMe: {
            id: 364495,
            nodeId: 3210433,
        }
    },
    'walkme-context-help-inventory-draft-video-counting-items-food': {
        label: 'How to Count Your Food Items',
        walkMe: {
            id: 364496,
            nodeId: 3210438,
        }
    },
    'walkme-context-help-inventory-draft-video-storage-areas-desktop': {
        label: 'Keep Your Storage Areas Organized',
        walkMe: {
            id: 364498,
            nodeId: 3210448,
        }
    },
    'walkme-context-help-inventory-draft-video-custom-sorting': {
        label: 'Set Up Custom Sorting for Fast Counting',
        walkMe: {
            id: 364500,
            nodeId: 3210532,
        }
    },
    'walkme-context-help-inventory-draft-video-offline-mode': {
        label: 'Counting with Offline Mode',
        walkMe: {
            id: 364501,
            nodeId: 3210534,
        }
    },
    'walkme-context-help-inventory-draft-video-negative-usage': {
        label: 'Understanding Negative Usage',
        walkMe: {
            id: 364479,
            nodeId: 3210336,
        }
    },
    'walkme-context-help-inventory-draft-video-exporting-records': {
        label: 'Exporting Your Inventory Counts',
        walkMe: {
            id: 364502,
            nodeId: 3210538,
        }
    },
    'walkme-context-help-order-history-record-past-invoice': {
        label: 'Record a Past Invoice',
        walkMe: {
            id: 320714,
            nodeId: 2721711,
        }
    },
    'walkme-context-help-order-history-balancing-invoices-confirming-deliveries': {
        label: 'Balancing Invoices/Confirming Deliveries',
        walkMe: {
            id: 320886,
            nodeId: 2723299,
        }
    },
    'walkme-context-help-order-history-exporting-records': {
        label: 'Exporting Order Records',
        walkMe: {
            id: 319838,
            nodeId: 2712964,
        }
    },
    'walkme-context-help-order-history-video-overview': {
        label: 'Ordering Overview',
        walkMe: {
            id: 241769,
            nodeId: 1896004,
        }
    },
    'walkme-context-help-order-history-video-first-order': {
        label: 'Placing Your First Orders',
        walkMe: {
            id: 364476,
            nodeId: 3210319,
        }
    },
    'walkme-context-help-order-history-video-record-past-invoice': {
        label: 'How to Record a Past Invoice',
        walkMe: {
            id: 364512,
            nodeId: 3210644,
        }
    },
    'walkme-context-help-order-history-video-confirming-deliveries': {
        label: 'Confirming Your Deliveries',
        walkMe: {
            id: 364513,
            nodeId: 3210645,
        }
    },
    'walkme-context-help-order-history-video-exporting-records': {
        label: 'Export Your Order Records',
        walkMe: {
            id: 364516,
            nodeId: 3210662,
        }
    },
    'walkme-context-help-order-detail-invoices-deliveries': {
        label: 'Balancing Invoices/Confirming Deliveries',
        walkMe: {
            id: 320886,
            nodeId: 2723299,
        }
    },
    'walkme-context-help-order-detail-reviewing-processed-invoices': {
        label: 'Reviewing Processed Invoices',
        walkMe: {
            id: 394458,
            nodeId: 3562718,
        }
    },
    'walkme-context-help-order-detail-video-confirming-deliveries': {
        label: 'Confirming Your Deliveries',
        walkMe: {
            id: 364513,
            nodeId: 3210645,
        }
    },
    'walkme-context-help-order-detail-video-reviewing-invoices': {
        label: 'Reviewing Invoices for Accurate Delivery Data',
        walkMe: {
            id: 393989,
            nodeId: 3557468,
        }
    },
    'walkme-context-help-cart-builder-placing-orders': {
        label: 'Placing/Recording Orders',
        walkMe: {
            id: 320941,
            nodeId: 2724030,
        }
    },
    'walkme-context-help-cart-builder-using-smart-cart': {
        label: 'Using Smart Cart',
        walkMe: {
            id: 320004,
            nodeId: 2714591,
        }
    },
    'walkme-context-help-cart-builder-video-placing-orders': {
        label: 'Placing Your First Orders',
        walkMe: {
            id: 364476,
            nodeId: 3210319,
        }
    },
    'walkme-context-help-cart-builder-video-smart-cart': {
        label: 'Using Smart Cart for Easy Ordering',
        walkMe: {
            id: 364517,
            nodeId: 3210684,
        }
    },
    'walkme-context-help-invoice-processing-submitting': {
        label: 'Submitting Invoices for Processing',
        walkMe: {
            id: 392309,
            nodeId: 3536916,
        }
    },
    'walkme-context-help-invoice-processing-reviewing': {
        label: 'Reviewing Processed Invoices',
        walkMe: {
            id: 394458,
            nodeId: 3562718,
        }
    },
    'walkme-context-help-invoice-processing-video-overview': {
        label: 'Invoice Processing Overview',
        walkMe: {
            id: 380178,
            nodeId: 3379201,
        }
    },
    'walkme-context-help-invoice-processing-video-processing': {
        label: 'Processing Your Vendor Invoices',
        walkMe: {
            id: 393985,
            nodeId: 3557450,
        }
    },
    'walkme-context-help-invoice-processing-video-reviewing': {
        label: 'Reviewing Invoices for Accurate Delivery Data',
        walkMe: {
            id: 393989,
            nodeId: 3557468,
        }
    },
    'walkme-context-help-invoice-processing-video-errors': {
        label: 'Why Do I Have Submission Errors',
        walkMe: {
            id: 393992,
            nodeId: 3557508,
        }
    },
    'walkme-context-help-sales-history-understanding': {
        label: 'Understanding Sales History',
        walkMe: {
            id: 320114,
            nodeId: 2715533,
        }
    },
    'walkme-context-help-sales-history-starting-new-report': {
        label: 'Starting a New Sales Report',
        walkMe: {
            id: 320391,
            nodeId: 2718730,
        }
    },
    'walkme-context-help-sales-history-starting-new-category-report': {
        label: 'Creating a Category Sales Report',
        walkMe: {
            id: 320859,
            nodeId: 2723091,
        }
    },
    'walkme-context-help-sales-history-video-overview': {
        label: 'Sales Overview',
        walkMe: {
            id: 241770,
            nodeId: 1896006,
        }
    },
    'walkme-context-help-sales-history-video-first-report-setup': {
        label: 'Setting Up Your First POS Sales Report',
        walkMe: {
            id: 364522,
            nodeId: 3210716,
        }
    },
    'walkme-context-help-sales-history-video-submitting-data': {
        label: 'Submitting Your Sales Data for Processing',
        walkMe: {
            id: 364525,
            nodeId: 3210741,
        }
    },
    'walkme-context-help-sales-history-video-understanding-variance': {
        label: 'What is Variance',
        walkMe: {
            id: 364527,
            nodeId: 3210791,
        }
    },
    'walkme-context-help-sales-items-creating': {
        label: 'Creating Sales Items',
        walkMe: {
            id: 365845,
            nodeId: 3226145,
        }
    },
    'walkme-context-help-sales-items-need-attention': {
        label: 'Sales Items Needing Attention',
        walkMe: {
            id: 364629,
            nodeId: 3211642,
        }
    },
    'walkme-context-help-sales-items-exporting': {
        label: 'Exporting Sales Items',
        walkMe: {
            id: 319897,
            nodeId: 2713724,
        }
    },
    'walkme-context-help-sales-items-video-creating': {
        label: 'Creating Your Sales Items',
        walkMe: {
            id: 364529,
            nodeId: 3210793,
        }
    },
    'walkme-context-help-sales-items-video-tax-percent': {
        label: 'Editing Your Tax Percentage',
        walkMe: {
            id: 364530,
            nodeId: 3210798,
        }
    },
    'walkme-context-help-sales-items-video-need-attention': {
        label: 'Addressing Sales Items Needing Attention',
        walkMe: {
            id: 364534,
            nodeId: 3210815,
        }
    },
    'walkme-context-help-sales-category-report-creating': {
        label: 'Creating a Category Sales Report',
        walkMe: {
            id: 320859,
            nodeId: 2723091,
        }
    },
    'walkme-context-help-sales-category-report-understanding': {
        label: 'Understanding Your Category Sales Report',
        walkMe: {
            id: 320844,
            nodeId: 2722966,
        }
    },
    'walkme-context-help-sales-category-report-exporting': {
        label: 'Exporting a Category Sales Report',
        walkMe: {
            id: 364617,
            nodeId: 3211508,
        }
    },
    'walkme-context-help-sales-category-report-video-overview': {
        label: 'Creating and Reading Category Sales Reports',
        walkMe: {
            id: 364535,
            nodeId: 3210820,
        }
    },
    'walkme-context-help-sales-item-level-report-summary-creating': {
        label: 'Submitting POS Sales Numbers',
        walkMe: {
            id: 320391,
            nodeId: 2718730,
        }
    },
    'walkme-context-help-sales-item-level-report-summary-understanding': {
        label: 'Understanding Your Report Summary',
        walkMe: {
            id: 371018,
            nodeId: 3279430,
        }
    },
    'walkme-context-help-sales-item-level-report-summary-exporting': {
        label: 'Exporting an Item Level Report',
        walkMe: {
            id: 371026,
            nodeId: 3279537,
        }
    },
    'walkme-context-help-sales-item-level-report-summary-video-overview': {
        label: 'Understanding Your Sales Summary',
        walkMe: {
            id: 364539,
            nodeId: 3210823,
        }
    },
    'walkme-context-help-sales-item-level-report-summary-video-variance': {
        label: 'What is Variance',
        walkMe: {
            id: 364527,
            nodeId: 3210791,
        }
    },
    'walkme-context-help-sales-item-level-report-input-understanding': {
        label: 'Understanding Your Sales Input',
        walkMe: {
            id: 371032,
            nodeId: 3279572,
        }
    },
    'walkme-context-help-sales-item-level-report-input-editing': {
        label: 'Editing Sales Items',
        walkMe: {
            id: 320556,
            nodeId: 2720252,
        }
    },
    'walkme-context-help-sales-item-level-report-input-exporting': {
        label: 'Exporting an Item Level Report',
        walkMe: {
            id: 371026,
            nodeId: 3279537,
        }
    },
    'walkme-context-help-sales-item-level-report-input-video-overview': {
        label: 'Understanding Your Sales Input',
        walkMe: {
            id: 364541,
            nodeId: 3210824,
        }
    },
    'walkme-context-help-sales-item-level-report-performance-understanding': {
        label: 'Understanding Your Sales Performance',
        walkMe: {
            id: 371043,
            nodeId: 3279712,
        }
    },
    'walkme-context-help-sales-item-level-report-performance-editing': {
        label: 'Editing Sales Items',
        walkMe: {
            id: 320556,
            nodeId: 2720252,
        }
    },
    'walkme-context-help-sales-item-level-report-performance-exporting': {
        label: 'Exporting an Item Level Report',
        walkMe: {
            id: 371026,
            nodeId: 3279537,
        }
    },
    'walkme-context-help-sales-item-level-report-performance-video-overview': {
        label: 'Understanding Your Sales Performance',
        walkMe: {
            id: 364545,
            nodeId: 3210841,
        }
    },
    'walkme-context-help-sales-item-level-report-variance-understanding': {
        label: 'Understanding Your Product Variance',
        walkMe: {
            id: 371050,
            nodeId: 3279741,
        }
    },
    'walkme-context-help-sales-item-level-report-variance-exporting': {
        label: 'Exporting an Item Level Report',
        walkMe: {
            id: 371026,
            nodeId: 3279537,
        }
    },
    'walkme-context-help-sales-item-level-report-variance-video-understanding': {
        label: 'What is Variance',
        walkMe: {
            id: 364527,
            nodeId: 3210791,
        }
    },
    'walkme-context-help-my-items': {
        label: 'Items',
        walkMe: {
            id: 412743,
            nodeId: 3765967,
        }
    },
    'walkme-context-help-my-items-catalog': {
        label: 'Using the Catalog',
        walkMe: {
            id: 412766,
            nodeId: 3766149,
        }
    },
    'walkme-context-help-my-items-creating': {
        label: 'Creating Items',
        walkMe: {
            id: 412768,
            nodeId: 3766165,
        }
    },
    'walkme-context-help-my-items-video-overview': {
        label: 'Items Overview',
        walkMe: {
            id: 364548,
            nodeId: 3210854,
        }
    },
    'walkme-context-help-my-items-video-bulk-edit': {
        label: 'Saving Time by Bulk Editing',
        walkMe: {
            id: 364549,
            nodeId: 3210871,
        }
    },
    'walkme-context-help-my-items-video-adding-items': {
        label: 'Adding Items to Your Account',
        walkMe: {
            id: 364472,
            nodeId: 3210296,
        }
    },
    'walkme-context-help-my-items-video-importing-items-from-invoices': {
        label: 'Importing Items from Invoices',
        walkMe: {
            id: 415873,
            nodeId: 3799821,
        }
    },
    'walkme-context-help-my-items-video-creating-items': {
        label: 'How to Create Custom Items',
        walkMe: {
            id: 365321,
            nodeId: 3220301,
        }
    },
    'walkme-context-help-my-storage-areas': {
        label: 'Storage Areas',
        walkMe: {
            id: 230296,
            nodeId: 1781955,
        }
    },
    'walkme-context-help-my-torage-areas-catalog': {
        label: 'Using the Catalog',
        walkMe: {
            id: 412766,
            nodeId: 3766149,
        }
    },
    'walkme-context-help-my-torage-areas-creating': {
        label: 'Creating Items',
        walkMe: {
            id: 412768,
            nodeId: 3766165,
        }
    },
    'walkme-context-help-my-storage-areas-video-overview': {
        label: 'Staying Organized with Storage Areas',
        walkMe: {
            id: 364551,
            nodeId: 3210872,
        }
    },
    'walkme-context-help-my-vendors': {
        label: 'Vendors',
        walkMe: {
            id: 232328,
            nodeId: 1802257,
        }
    },
    'walkme-context-help-my-vendors-video-overview': {
        label: 'Managing Your Vendor Relationships',
        walkMe: {
            id: 364553,
            nodeId: 3210874,
        }
    },
    'walkme-context-help-account-settings': {
        label: 'Updating Account Settings',
        walkMe: {
            id: 315851,
            nodeId: 2672244,
        }
    },
    'walkme-context-help-account-settings-video-overview': {
        label: 'Keeping Your Account Settings Updated',
        walkMe: {
            id: 364554,
            nodeId: 3210875,
        }
    },
    'walkme-context-help-users-settings': {
        label: 'Inviting and Managing Users',
        walkMe: {
            id: 236300,
            nodeId: 2671752,
        }
    },
    'walkme-context-help-users-settings-video-overview': {
        label: 'Invite Your Team to Help in BevSpot',
        walkMe: {
            id: 364556,
            nodeId: 3210891,
        }
    },
    'walkme-context-help-plans-billing-setup-cc': {
        label: 'Setting Up Credit Card Payments',
        walkMe: {
            id: 315831,
            nodeId: 2672098,
        }
    },
    'walkme-context-help-plans-billing-setup-ach': {
        label: 'Setting Up ACH Bank Payments',
        walkMe: {
            id: 315814,
            nodeId: 2671949,
        }
    },
    'walkme-context-help-plans-billing-video-overview': {
        label: 'Keeping Your Payments Updated',
        walkMe: {
            id: 364560,
            nodeId: 3210958,
        }
    },
};

const buildWalkMeLink = (linkId : HelpLinkId) => {
    const linkInfo = HELP_LINK_INFO[linkId];
    const isMobile = window.matchMedia('(max-width: ' + MAX_TABLET_WIDTH + 'px)').matches;
    const handleClick = !isMobile ? undefined : (event : React.MouseEvent<HTMLAnchorElement>) => {
        if (isMobile && typeof window.WalkMeAPI !== 'undefined') {
            window.WalkMeAPI.startFlowById(linkInfo.walkMe.id, linkInfo.walkMe.nodeId);
        }
    };

    return <li><a id={ linkId } onClick={ handleClick }>{ linkInfo.label }</a></li>;
};

/** End Workaround for WalkMe on Mobile */

const getContentAccountDashboard = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <h5>Dashboard Overview</h5>
                    <p>
                        Your BevSpot Dashboard is the first place you will land every time you log into your BevSpot account.
                        It shows you high-level insights about your restaurant program such as sitting inventory, product
                        usage trends over time, and it’s a great place to check in with the overall efficiency of your bar or
                        kitchen.
                    </p>
                    <h5>Why is my Account History graph showing I haven’t taken an inventory yet?</h5>
                    <p>Your account dashboard will not populate your inventory data in real-time. If you want more real-time data on your inventory, go to the Usage Report or the Inventory History page.</p>
                </div>
            );
        case ContextTab.TUTORIAL:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink(window.GLOBAL_RETAILER_IS_IN_FREE_TRIAL ? 'walkme-context-help-dashboard-getting-around-bevspot-trial' : 'walkme-context-help-dashboard-getting-around-bevspot-customer') }
                    { buildWalkMeLink('walkme-context-help-dashboard-insights') }
                    { buildWalkMeLink('walkme-context-help-dashboard-taking-inventory') }
                </ul>
            );
        case ContextTab.VIDEOS:
            let dashboardVideo : JSX.Element;
            if (window.GLOBAL_RETAILER_IS_IN_FREE_TRIAL) {
                dashboardVideo = buildWalkMeLink('walkme-context-help-dashboard-video-dashboard-free-trial');
            } else {
                dashboardVideo = buildWalkMeLink('walkme-context-help-dashboard-video-dashboard');
            }
            return (
                <ul className="context-tab-body cell-p-lg">
                    { dashboardVideo }
                    { buildWalkMeLink('walkme-context-help-dashboard-video-group-adding-items') }
                    { buildWalkMeLink('walkme-context-help-dashboard-video-group-first-inventory') }
                    { buildWalkMeLink('walkme-context-help-dashboard-video-group-first-order') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentPriceChangeReport = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <h5>How can I find the history of an item?</h5>
                    <p>Click on the blue dotted line under ‘Total Spent’ to see any item’s historical breakdown.</p>
                    <h5>What are the benefits of this report?</h5>
                    <p>
                        Tracking price changes over time from your vendors will allow you to make better buying decisions,
                        work out contract pricing with your reps, and make sure you are always charging your guests the
                        right amount to maximize your profits.
                    </p>
                    <h5>How can I customize the view?</h5>
                    <p>
                        Organize this page by All Items, Category, Subcategory, or Vendor. Stack the columns to view the
                        report in several different ways. A good place to start is with the Total Spent Change and
                        Price Change % columns.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-price-tracker-report-run') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-price-tracker-video-overview') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentUsageReport = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <h5>Usage Report Overview</h5>
                    <p>
                        Usage is the amount of every/all products you deplete from stock between two inventory counts.
                        Usage in BevSpot is calculated by taking your past inventory, adding your received inventory,
                        and subtracting your ending inventory
                        <img />
                    </p>
                    <p>
                        The Usage Report will allow you to properly track your product usage. You’ll be able to see
                        exactly how much stock you are going through to ensure responsible ordering, and to know how
                        much of your usage was lost product.
                    </p>
                    <h5>What is negative usage and what does it mean?</h5>
                    <p>
                        Negative usage means unexplained growth for any item. You have more in this count than
                        you did in your last count without enough deliveries or transfers in between explaining
                        why. Since this isn’t actually possible, errors in usage should be addressed ASAP so you
                        can make edits to correct them. Inaccurate usage data can lead to incorrect and misleading
                        sales reports.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-usage-report-run') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-usage-report-video-overview') }
                    { buildWalkMeLink('walkme-context-help-usage-report-video-negative-usage') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentExcessInventoryReport = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <h5>Excess Inventory Overview</h5>
                    <p>
                        The Excess Inventory report will show you which items you may have too much of in-house based
                        on your average usage. This will allow you to visualize the issue, slow down spending on these
                        items, and even start to feature them more to get them moving out of the storage room. Products
                        that are just sitting on your shelves are not bringing in any profits, and do no good for your
                        business.
                    </p>
                    <h5>How can I reduce my Excess Inventory?</h5>
                    <ul>
                        <li>
                            Utilize the Smart Cart ordering feature in your account to make sure you aren’t ordering
                            more than you will actually need to get through the next 2-3 weeks. This will help free
                            up some cash that you can put towards other business costs.
                        </li>
                        <li>
                            Educate your staff on how to better sell these items and/or work them into daily specials
                            that feature them more prominently.
                        </li>
                    </ul>
                </div>
            );
        case ContextTab.TUTORIAL:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-excess-inventory-report-run') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-excess-inventory-report-video-overview') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentUnusedInventoryReport = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <h5>Unused Inventory Overview</h5>
                    <p>
                        The Unused Inventory report will show you all the items that have not been used between two
                        finished inventory counts. Knowing this information can help you push certain items to increase
                        your profits and cash flow.
                    </p>
                    <h5>How can I reduce my Unused Inventory?</h5>
                    <p>
                        Stop purchasing these items and put an increased focus on moving them out of your storage
                        room. Educate your staff on how to better sell these items and/or work them into daily
                        specials that feature them more prominently.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-unused-inventory-report-run') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-unused-inventory-report-video-overview') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentTransferReport = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <h5>Transfer Report Overview</h5>
                    <p>
                        The Transfer Report will allow you to properly track the transfers in or out of your account
                        over a specific date range. Being able to see this will show how much product you send
                        to/receive from other parts of your business (kitchen, marketing, etc), or with any other
                        bars/restaurants you are associated with in BevSpot.
                    </p>
                    <p>
                        Exporting this report to Excel will allow you to share this data with owners, co-workers,
                        or accountants in order to help run a more efficient business.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-transfer-report-run') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-transfer-report-video-overview') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentLossReport = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <h5>Loss Report Overview</h5>
                    <p>
                        The Loss Report will allow you to track and report on all the recorded loss in your BevSpot
                        account over a specific date range. Being able to categorize and view your loss over time will
                        give you more transparency into how efficient you are being with your products, and allow you
                        to make changes before loss becomes detrimental to your business.
                    </p>
                    <p>
                        Exporting this report to Excel will allow you to share this data with owners, co-workers, or
                        accountants in order to help run a more efficient business.
                    </p>
                    <h5>What does a loss record do?</h5>
                    <p>
                        A loss record can be used to record events like spills. A loss does not affect your usage, but
                        you can see how much of your usage is due to loss in the Usage Report.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-loss-report-run') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-loss-report-video-overview') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentPrepReport = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <h5>Prep Report Overview</h5>
                    <p>
                        The Prep Report will allow you to track and report on your prepared items to see over time when
                        certain items were made, the cost, and how much of each ingredient went into each item. This will
                        help you make good decisions about your prep habits so you don’t end up with excess prep.
                    </p>
                    <p>
                        Exporting this report to Excel will allow you to share this data with owners, co-workers,
                        or accountants in order to help run a more efficient business.
                    </p>
                    <h5>What is a prepared item?</h5>
                    <p>
                        A Prepared Item in BevSpot is something that you create from pre-existing inventory items
                        like a pre-made batch of cocktails or a homemade sauce.
                    </p>
                    <h5>How do I record a prepared item?</h5>
                    <p>
                        Prepared Items can be made from the Inventory History page.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-prep-report-run') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-prep-report-video-overview') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentInventoryHistory = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <h5>Inventory Overview</h5>
                    <p>
                        Your Inventory History will allow you to view and access any inventory, order, transfer, loss,
                        or prepared item that you’ve recorded in your account. Once you’ve finished some inventory counts,
                        the top of this page will show you some helpful data that relates to your most recent inventory,
                        and can help you maintain healthy inventory levels at your restaurant.
                    </p>
                    <h5>What is represented in the graphs?</h5>
                    <p>
                        The graph on the left shows your top stocked products by dollar value, and represents where most of
                        your inventory dollars are currently tied up. The graph to the right shows your top stocked products
                        by number of units, and generally will be an indicator of where most of your storage space is
                        currently tied up.
                    </p>
                    <h5>How do I export my inventory counts and my list of items?</h5>
                    <p>
                        Open up any finished inventory count. From the Count Summary page, click the more actions
                        button at the top of the screen (three vertical dots), and select the Export to Excel option.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-inventory-history-first-count') }
                    { buildWalkMeLink('walkme-context-help-inventory-history-recording-loss') }
                    { buildWalkMeLink('walkme-context-help-inventory-history-adding-transfers') }
                    { buildWalkMeLink('walkme-context-help-inventory-history-recording-prep-item') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-inventory-history-video-overview') }
                    { buildWalkMeLink('walkme-context-help-inventory-history-video-first-inventory') }
                    { buildWalkMeLink('walkme-context-help-inventory-history-video-recording-loss') }
                    { buildWalkMeLink('walkme-context-help-inventory-history-video-adding-transfers') }
                    { buildWalkMeLink('walkme-context-help-inventory-history-video-recording-prep-item') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentInventoryDraft = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <p>
                        With our inventory calculator, you’ll have the flexibility to count your inventory items in a
                        number of different ways. You can use the calculator to add individual units to cases, the slider
                        tool to match the level in partial bottles, and even by weight for bottles, kegs and food items.
                    </p>
                    <div>
                        <h5>How do I get my items to match the order they sit on the shelves in my restaurant?</h5>
                        <ul>
                            <li>
                                Custom Sorting in BevSpot represents a "sheet-to-shelf" organization of your items.
                                You can set it up by dragging and dropping items into the correct order that items sit
                                on yours shelves, or while you are taking an inventory count. Count an entire storage area
                                in the proper order, and then select ‘Arrange by Last Edit’ in the more actions (three dots)
                                menu at the top of the screen.
                            </li>
                        </ul>
                    </div>
                    <p>
                        If you lose your internet connection anywhere in your restaurant while taking an inventory count,
                        BevSpot will allow you to take inventory with our Offline Mode. Continue to count as normal,
                        but before you finish your inventory, or leave this count for another page in BevSpot, make sure
                        you have reconnected to your WiFi to save the work you have done.
                    </p>
                    <div>
                        <h5>What is negative usage and what does it mean?</h5>
                        <ul>
                            <li>
                                Negative usage means unexplained growth for any item. You have more in this count than you
                                did in your last count without enough deliveries or transfers in between explaining why.
                                Since this isn’t actually possible, errors in usage should be addressed ASAP so you can
                                make edits to correct them. Inaccurate usage data can lead to incorrect and misleading sales
                                reports.
                            </li>
                        </ul>
                    </div>
                </div>
            );
        case ContextTab.TUTORIAL:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-inventory-draft-counting-items') }
                    { buildWalkMeLink('walkme-context-help-inventory-draft-storage-areas') }
                    { buildWalkMeLink('walkme-context-help-inventory-draft-custom-sorting') }
                    { buildWalkMeLink('walkme-context-help-inventory-draft-exporting-records') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { window.GLOBAL_RETAILER_ACCOUNT_TYPE === 'beverage' &&
                        buildWalkMeLink('walkme-context-help-inventory-draft-video-counting-items-beverage')
                    }
                    { window.GLOBAL_RETAILER_ACCOUNT_TYPE === 'food' &&
                        buildWalkMeLink('walkme-context-help-inventory-draft-video-counting-items-food')
                    }
                    { buildWalkMeLink('walkme-context-help-inventory-draft-video-storage-areas-desktop') }
                    { buildWalkMeLink('walkme-context-help-inventory-draft-video-custom-sorting') }
                    { buildWalkMeLink('walkme-context-help-inventory-draft-video-offline-mode') }
                    { buildWalkMeLink('walkme-context-help-inventory-draft-video-negative-usage') }
                    { buildWalkMeLink('walkme-context-help-inventory-draft-video-exporting-records') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentOrderHistory = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <h5>Order History Overview</h5>
                    <p>
                        This page hosts all of your order records, and it will show you some helpful graphs that relate
                        to your ordering history. These are going to display useful info around your top ordered products,
                        and historical value of products ordered from your vendors.This is also where your weekly invoicing
                        of deliveries will take place, and where you will be able to download your order history via excel
                        to send off to your accountant for more accurate record keeping.
                    </p>
                    <h5>What does each record status indicate?</h5>
                    <ul>
                        <li>Communicated - this order was sent to your vendor rep via BevSpot.</li>
                        <li>Recorded - this order was recorded by not sent to your vendor rep via BevSpot.</li>
                        <li>Delivered - this order has been balanced with the delivery invoice and confirmed as delivered.</li>
                    </ul>
                    <h5>What is represented in the graphs?</h5>
                    <p>
                        The data in these graphs shows you total spend over time per vendor, and by brand. These numbers can
                        help you work out deals and contract pricing with your vendors to help lower costs and drive profits
                        over time.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-order-history-record-past-invoice') }
                    { buildWalkMeLink('walkme-context-help-order-history-balancing-invoices-confirming-deliveries') }
                    { buildWalkMeLink('walkme-context-help-order-history-exporting-records') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-order-history-video-overview') }
                    { buildWalkMeLink('walkme-context-help-order-history-video-first-order') }
                    { window.GLOBAL_FEATURE_ACCESS.invoice_processing &&
                        buildWalkMeLink('walkme-context-help-order-history-video-record-past-invoice')
                    }
                    { buildWalkMeLink('walkme-context-help-order-history-video-confirming-deliveries') }
                    { buildWalkMeLink('walkme-context-help-order-history-video-exporting-records') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentOrderDetail = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <h5>Order Detail</h5>
                    <p>
                        This page represents a single order from one of your vendors in BevSpot. On your order detail you can
                        upload the corresponding invoice, and reconcile your BevSpot order to match what was delivered on the
                        invoice. This is also where you’ll be reviewing any invoices that were processed for you.
                    </p>
                    <h5>Why do I have to "Find a Match" for so many items?</h5>
                    <p>
                        As you begin getting your invoices processed by BevSpot, you’ll need to match some items from your
                        account to the items on the invoice. The primary way we automatically match items for you is based
                        on the SKU number that you attach in your account. The more items you add SKUs for, the less you will
                        need to manually match going forward.
                    </p>
                    <h5>Why are there duplicate items here?</h5>
                    <p>
                        The second table only represents what was not successfully matched. Once you match the items in the top
                        table to the items in your account these "duplicates" will go away. Any remaining items are items that
                        were ordered but did not come through on the invoice.
                    </p>
                    <h5>Jump to Another Order</h5>
                    <p>
                        This will allow you to quickly navigate from order to order without having to leave this screen,
                        allowing for faster delivery confirmation every week.
                    </p>
                    <h5>Additional Adjustments</h5>
                    <p>
                        This section is where you can account for line items on your invoice such as charges, credits, or
                        taxes that you don’t want to track against your COGS.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-order-detail-invoices-deliveries') }
                    { buildWalkMeLink('walkme-context-help-order-detail-reviewing-processed-invoices') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-order-detail-video-confirming-deliveries') }
                    { buildWalkMeLink('walkme-context-help-order-detail-video-reviewing-invoices') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentCartBuilder = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <h5>Smart Cart Overview</h5>
                    <p>
                        On this page you’ll be able to see how much of any item you have in-stock (based on your most recent
                        inventory), what your average weekly usage is, or what your pars are. This will help you fill your
                        shopping cart with only what you need.
                    </p>
                    <h5>How does Smart Cart work?</h5>
                    <p>
                        Smart Cart will fill your shopping cart for you based on your pars or a variable of your average
                        weekly usage. If you are taking regular, accurate inventories in BevSpot, Smart Cart can help you
                        cut back on over-ordering, lower your sitting inventory levels, and save you hours of ordering time
                        every week.
                    </p>
                    <h5>What’s the difference between placing and recording orders?</h5>
                    <p>
                        Placing an order in BevSpot will send it to your vendor rep and log it on your
                        Order History page. Recording an order will just log it on your Order History page.
                        This will allow you to log orders that you may have communicated to your vendors outside
                        of BevSpot.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-cart-builder-placing-orders') }
                    { buildWalkMeLink('walkme-context-help-cart-builder-using-smart-cart') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-cart-builder-video-placing-orders') }
                    { buildWalkMeLink('walkme-context-help-cart-builder-video-smart-cart') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentInvoiceUploadManager = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <h5>Invoice Processing Overview</h5>
                    <p>
                        This page is where you can submit your vendor invoices for our team to process for you.
                        It will show you both the invoices that are currently being processed, and the invoices
                        that are ready for you to review. Each invoice submission costs $1.50, and takes around
                        24 hours for our team to process.
                    </p>
                    <p>
                        When your invoices are ready for you to review, you’ll be able to quickly start matching
                        them to specific items and deliveries.
                    </p>
                    <h5>Why am I seeing errors on my processed invoices?</h5>
                    <p>
                        When you get to your Invoices Ready for Review, you may notice errors at times.
                        Errors could be caused by:
                    </p>
                    <ul>
                        <li>Duplicate invoices being submitted </li>
                        <li>Unreadable or invalid files (Please attach legible .pdf, .jpg, or .png files)</li>
                    </ul>
                    <h5>Important Note </h5>
                    <p>
                        BevSpot can only process invoices from vendors. Any file that does not contain an actual invoice,
                        or one that is unreadable, will cause an error and you will still be charged for your submission.
                        We always want to make sure that the invoices you are submitting are able to be processed by our team,
                        so take a second look before clicking upload to ensure the quality of your files.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-invoice-processing-submitting') }
                    { buildWalkMeLink('walkme-context-help-invoice-processing-reviewing') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-invoice-processing-video-overview') }
                    { buildWalkMeLink('walkme-context-help-invoice-processing-video-processing') }
                    { buildWalkMeLink('walkme-context-help-invoice-processing-video-reviewing') }
                    { buildWalkMeLink('walkme-context-help-invoice-processing-video-errors') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentSalesHistory = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <h5>Sales History Overview</h5>
                    <p>
                        This page shows you can the total sales for your most recent sales period, usage from inventory,
                        pour cost, and your variance for this period. Having this information at a glance will make sure
                        you always know how profitable your bar or kitchen program is. You’ll also be able to click into
                        any previously run report, or start a new sales report from this page as well.
                    </p>
                    <h5>How can I get the correct data from my POS to submit to BevSpot for processing?</h5>
                    <p>
                        Since every POS system is different,
                        use the <a data-elevio-article="165">linked instructions</a> for
                        your POS to make sure you are submitting exactly the right information to our team for
                        prompt processing. If you don’t see your POS listed below, please reach out to our support
                        team with the chat feature in your account to see how we can get these reports to you in BevSpot.
                    </p>
                    <h5>What is variance and how is it calculated?</h5>
                    <p>
                        Variance is the difference between your sales and inventory usage data listed as a percentage
                        of your actual use from inventory. To calculate variance, take the cost of goods from what you
                        sold, and subtract your inventory usage for this sales period. Take this new number, divide it
                        by your inventory usage and that will give you your percentage of variance for this sales period.
                    </p>
                    <p>
                        Cost percentage represents what percentage of each drink or dish you sell is raw cost of goods. If it
                        costs $2 to make a drink and you sell it for $10, your cost percent is 20%. The lower your cost
                        percentage, the higher your profit margins are.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-sales-history-understanding') }
                    { buildWalkMeLink('walkme-context-help-sales-history-starting-new-report') }
                    { buildWalkMeLink('walkme-context-help-sales-history-starting-new-category-report') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-sales-history-video-overview') }
                    { buildWalkMeLink('walkme-context-help-sales-history-video-first-report-setup') }
                    { buildWalkMeLink('walkme-context-help-sales-history-video-submitting-data') }
                    { buildWalkMeLink('walkme-context-help-sales-history-video-understanding-variance') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentMySalesItem = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <h5>My Sales Items History</h5>
                    <p>
                        My Sales Items is where you store all the items you sell through your kitchen or bar program.
                        These are built by adding ingredients, POS ID, menu cost, etc, and these are the items that our
                        team will use to run your Item Level Sales reports. Having all of your sales items stored like
                        this in one place will allow you to monitor cost, and make any edits needed to always be maximizing
                        profits.
                    </p>
                    <h5>How do I edit my sales items?</h5>
                    <p>
                        To make edits on your sales items, click into an item and update any necessary fields. You’ll have
                        the ability to save these changes for all future reports, or change historical reports with these
                        edits. Being able to update your sales info easily will make sure you always have the most accurate
                        info about your business at all times.
                    </p>
                    <h5>What are items needing attention?</h5>
                    <p>
                        Items Needing Attention are sales items that our team here at BevSpot has flagged for you to edit.
                        This is usually because they don’t have enough information to accurately run sales reports for this
                        sales item. Some reasons for this are a missing recipe, ingredients not in inventory, duplicate
                        products, and more. You’ll be able to remove the flag for this item once you have made the necessary
                        updates, and your reporting data will become more valuable to you.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-sales-items-creating') }
                    { buildWalkMeLink('walkme-context-help-sales-items-need-attention') }
                    { buildWalkMeLink('walkme-context-help-sales-items-exporting') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-sales-items-video-creating') }
                    { buildWalkMeLink('walkme-context-help-sales-items-video-tax-percent') }
                    { buildWalkMeLink('walkme-context-help-sales-items-video-need-attention') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentCategorySalesReport = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <p>
                        On the Category Sales Report, you’ll see the total sales, cost and profit by category displayed on
                        pie graphs for the specified sales period. Each graph shows the proportional amounts with the actual
                        values available when you hover. You can also see the values in the table, for reference.
                    </p>
                    <p>
                        If you notice that a certain category is running at a higher cost percent than you’d like, you can
                        take a look at your Sales Items page to see if you can make some pricing changes. You can also use
                        other categories like spirits to make up for more expensive categories like draught beer.
                    </p>
                    <p>
                        If you make a mistake, or need to change the underlying sales data, you can click the button at the
                        top of the screen to go back to the configuration page to make those edits and rerun the report to
                        make it more accurate.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-sales-category-report-creating') }
                    { buildWalkMeLink('walkme-context-help-sales-category-report-understanding') }
                    { buildWalkMeLink('walkme-context-help-sales-category-report-exporting') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-sales-category-report-video-overview') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentItemLevelSalesReportSummary = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <p>
                        This is the summary page of your report, and it will show you some high-level info about your most
                        recent sales period. You’ll see the total sales, cost percentage, usage from inventory, product
                        variance, item sales performance, and sales by category.
                    </p>
                    <p>
                        With the Item Sales Performance Graph, you can filter the graph by your top/bottom 20, 50, or 100
                        performing sales items by dollar sales or unit sales. Hovering over each plot point will show some
                        underlying sales info each sales item. You want to see these sitting in the upper-right corner of
                        this graph as this will indicate you are maximizing profits for these sales items.
                    </p>
                    <p>
                        Variance is the difference between your sales and inventory usage data listed as a percentage of
                        your actual use from inventory. To calculate variance, take the cost of goods from what you sold,
                        and subtract your inventory usage for this sales period. Take this new number, divide it by your
                        inventory usage and that will give you your percentage of variance for this sales period.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-sales-item-level-report-summary-creating') }
                    { buildWalkMeLink('walkme-context-help-sales-item-level-report-summary-understanding') }
                    { buildWalkMeLink('walkme-context-help-sales-item-level-report-summary-exporting') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-sales-item-level-report-summary-video-overview') }
                    { buildWalkMeLink('walkme-context-help-sales-item-level-report-summary-video-variance') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentItemLevelSalesReportInput = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <p>
                        This page is a ramped up version of your POS Sales Data that you submitted to our Support team here
                        at BevSpot. Unlike your POS Data, this includes your pour cost and percentage for each of the sales
                        items you track in BevSpot. One great way to sort this page is by clicking on the "Net Sales by $
                        Amount" column to show the items that brought the most revenue during this sales period.
                    </p>
                    <p>
                        Click into any sales item and update any necessary fields. When saving these changes a the pop-up
                        will appear that asks how you want to save these changes. "Only Future Reports" will update this
                        sales item in all future reports, but "This and Future Reports" will update this sales item in
                        all past AND future reports.
                    </p>
                    <p>
                        There are a few valuable ways to view this data. Group by All Items, Category, Menu Group, or Item
                        Type. You can also stack the columns in different ways to create different views within each of
                        those group by options.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-sales-item-level-report-input-understanding') }
                    { buildWalkMeLink('walkme-context-help-sales-item-level-report-input-editing') }
                    { buildWalkMeLink('walkme-context-help-sales-item-level-report-input-exporting') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-sales-item-level-report-input-video-overview') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentItemLevelSalesReportPerformance = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <p>
                        The Sales Performance page is where you’ll really be able to dig deep and see which of your sales
                        items are your top performers and which are your bottom performers for this sales period by Sales,
                        Cost, and Profitability.
                    </p>
                    <p>
                        Keep an eye on your top sellers on this page. If you notice that the items you sell the most of tend
                        to be lower on the list in profitability, consider adjusting pricing or re-working the recipe a bit
                        to boost your profit margins.
                    </p>
                    <p>
                        There are a few valuable ways to view this data. Group by All Items, Category, Menu Group, or Item
                        Type. You can also stack the columns in different ways to create different views within each of
                        those group by options.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-sales-item-level-report-performance-understanding') }
                    { buildWalkMeLink('walkme-context-help-sales-item-level-report-performance-editing') }
                    { buildWalkMeLink('walkme-context-help-sales-item-level-report-performance-exporting') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-sales-item-level-report-performance-video-overview') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentItemLevelSalesReportVariance = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <p>
                        Your variance is showing you what percentage of your inventory usage can’t be accounted for in your
                        sales. In other words, your variance is showing how much of your raw cost of goods was used, but
                        didn't bring in any sales or profits during this period.
                    </p>
                    <div>
                        <p><strong>How do I modify the items included in the variance calculation?</strong></p>
                        <ul>
                            <li>
                                You can use the eyeball icon to change the inclusion status of any of your products.
                                Excluded inventory items will not affect your overall calculated pour cost or variance.
                                This is useful for items like paper goods, add-ons, and garnishes that may not be easily
                                attached to a specific recipe.
                            </li>
                        </ul>
                    </div>
                    <p>
                        There are a few valuable ways to view this data. Group by All Items, Category, Menu Group, or Item
                        Type. You can also stack the columns in different ways to create different views within each of those
                        group by options.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-sales-item-level-report-variance-understanding') }
                    { buildWalkMeLink('walkme-context-help-sales-item-level-report-variance-exporting') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-sales-item-level-report-variance-video-understanding') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentMyItems = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <h5>Items Overview</h5>
                    <p>
                        The Items page is your control hub for all the items you track in BevSpot. By clicking the
                        check-boxes next to multiple items, you will bring up the bulk-editing options at the top of
                        the screen. You’ll be able to edit Category, Subcategory, Case Size, Unit Packaging, Vendor,
                        and GL Code for multiple items at the same time. You can also Archive or Delete items in bulk.
                        This will save you lots of time when maintaining your account.
                    </p>
                    <h5>How can I add more items to my account?</h5>
                    <p>
                        Use the options at the top of this page to add new items in bulk by uploading product lists or
                        invoices. You can also search through our catalog (beverage only) to find new items, or quickly
                        create custom items to keep your account up to date.
                    </p>
                    <p>
                        <em>
                            <strong>Note:</strong> Keep an eye out to delete any duplicates added through bulk or invoice
                            upload to keep a clean account.
                        </em>
                    </p>
                    <h5>How do archived items work?</h5>
                    <p>
                        Archive seasonal items to keep your account organized and clutter-free. To view Archived items,
                        select to view by All or Archived items with the View By options. You’ll be able to re-activate any
                        item from this list at any time.
                    </p>
                    <h5>How do deleted items work?</h5>
                    <p>
                        Delete anything from your account that may be discontinued at your restaurant. To view Deleted items,
                        click the link under the View By and Group By options list. You’ll be able to restore any deleted item
                        from this list at any time.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:

            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-my-items') }
                    { window.GLOBAL_RETAILER_ACCOUNT_TYPE === 'beverage' &&
                        buildWalkMeLink('walkme-context-help-my-items-catalog')
                    }
                    { buildWalkMeLink('walkme-context-help-my-items-creating') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-my-items-video-overview') }
                    { buildWalkMeLink('walkme-context-help-my-items-video-bulk-edit') }
                    { buildWalkMeLink('walkme-context-help-my-items-video-adding-items') }
                    { buildWalkMeLink('walkme-context-help-my-items-video-importing-items-from-invoices') }
                    { buildWalkMeLink('walkme-context-help-my-items-video-creating-items') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentMyStorageAreas = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <h5>Storage Areas Overview</h5>
                    <p>
                        By clicking the check-boxes next to multiple items, you will bring up the bulk-assign them to storage
                        areas. Use the Assign Selected drop-down list to select which storage areas to assign these item to
                        before clicking Save. This will save you time when maintaining your account, and allow you to keep
                        your inventory items organized for accurate inventory counts.
                    </p>
                    <h5>How many storage areas should I have?</h5>
                    <p>
                        We recommend making your storage areas as granular as possible to make your counts more accurate.
                        General rule of thumb is to have every item appear in each storage area only once, so if any aspects
                        of your kitchen or bar mirror themselves (left well, right well, etc) it is best to make separate
                        storage areas for each.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:

            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-my-storage-areas') }
                    { window.GLOBAL_RETAILER_ACCOUNT_TYPE === 'beverage' &&
                        buildWalkMeLink('walkme-context-help-my-torage-areas-catalog')
                    }
                    { buildWalkMeLink('walkme-context-help-my-torage-areas-creating') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-my-storage-areas-video-overview') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentMyVendors = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <h5>Vendors Overview</h5>
                    <p>
                        This is where you store your vendor’s and sales rep contact info. To add a new vendor, use the search
                        bar at the top of the screen and select the vendor you are looking to add. If you can’t find your
                        vendor on this list, you’ll see the option to create a vendor in your account.
                    </p>
                    <h5>How does my vendor receive my order?</h5>
                    <p>
                        By saving your sales rep’s email and/or cell phone number, BevSpot will email or text your orders to
                        your sales reps. You’ll be able to send all your orders out at the same time to all your vendors. This
                        will save you hours every week in contacting each of your vendors one at a time.
                    </p>
                    <h5>What does the red “i” mean next to a vendor?</h5>
                    <p>
                        Your vendors will be called out with the red “i” when you don’t have a sales rep’s contact info saved to
                        the vendor. We highly recommend adding the email and the cell phone number when available for each
                        of your vendor sales reps.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:

            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-my-vendors') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-my-vendors-video-overview') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentAccountSettings = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <h5>Who should be added to my account?</h5>
                    <p>
                        You should CC any/all co-workers who need to know about orders going out or deliveries coming in.
                        This will help make your internal operations more efficient and give everybody the information they
                        need to do their job effectively.
                    </p>
                    <h5>What do the accounting preferences affect?</h5>
                    <p>
                        Since some bars and restaurants don’t run on a typical fiscal schedule, you’ll be able to customize
                        your accounting preferences to align with how you run your business. Changing this here will
                        reorganize your Order History page to reflect these updates.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:

            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-account-settings') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-account-settings-video-overview') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentUsersSettings = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <h5>Users Overview</h5>
                    <p>
                        We’ve found that our customers who invite their co-workers to join them in BevSpot run faster,
                        more efficient inventories, and see increased profits over time. Just like at your restaurant,
                        you shouldn’t be expected to do everything yourself.
                    </p>
                    <h5>Is there a user limit to my account?</h5>
                    <p>
                        Regardless of your plan, BevSpot offers <strong>unlimited users</strong> so we recommend inviting the entire team to
                        join you in your account. This will help make sure you never miss a count, delivery, or report while
                        running the business.
                    </p>
                    <h5>What options do I have if I’m the account admin?</h5>
                    <p>
                        If you’re an admin on your account, the permissions on the Users page will allow you to manage who
                        can access/utilize certain parts of your BevSpot account. By being able to streamline your team's
                        responsibilities in BevSpot, you’ll make sure nobody loses focus of the task at hand.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:

            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-users-settings') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-users-settings-video-overview') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentPlansAndBilling = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg context-tab-body-help-tab">
                    <h5>Plans and Billing Overview</h5>
                    <p>
                        BevSpot is an online software solution, so there's no need to download an native app and you're
                        always automatically up-to-date. BevSpot will work the same across smartphone, tablet, and desktop.
                    </p>
                    <h5>How long is the BevSpot Free Trial?</h5>
                    <p>
                        There is no time limit to a BevSpot free trial. You will be able to use all the core
                        functions of a BevSpot Pro account up until you finish your third inventory count and your
                        fifth order.
                    </p>
                    <h5>How many users do I get based on the plan I choose?</h5>
                    <p>
                        Regardless of your plan, BevSpot offers unlimited users so we recommend inviting the entire
                        team to join you in your account. This will help make sure you never miss a count, delivery,
                        or report while running the business.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:

            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-plans-billing-setup-cc') }
                    { buildWalkMeLink('walkme-context-help-plans-billing-setup-ach') }
                </ul>
            );
        case ContextTab.VIDEOS:
            return (
                <ul className="context-tab-body cell-p-lg">
                    { buildWalkMeLink('walkme-context-help-plans-billing-video-overview') }
                </ul>
            );
        default:
            return noContentDefault;
    }
};

const getContentIntegrations = (tab : ContextTab) => {
    switch (tab) {
        case ContextTab.HELP:
            return (
                <div className="context-tab-body cell-p-lg">
                    <h5>Does BevSpot work with my POS?</h5>
                    <p>
                        BevSpot works with any POS system including, but not limited to, Aloha, Micros, POSitouch, Clover,
                        Square, and more. We have direct integrations with Breadcrumb and Toast.
                    </p>
                    <p>
                        We’ve been able to work with over 30 different POS systems in the food and beverage industry.
                        Chat with our team to make sure this setup goes as smoothly as possible.
                    </p>
                </div>
            );
        case ContextTab.TUTORIAL:
        case ContextTab.VIDEOS:
        default:
            return noContentDefault;
    }
};
