import { StringValueMap } from 'api/Core/StringValueMap';
import { Category } from 'api/Product/model/Category';
import { CategoryId } from 'api/Product/model/CategoryId';
import { CategoryUtils } from 'api/Product/utils/categoryUtils';
import { Action } from 'redux';

import {
    addToSortedOptionList,
    OptionsAndLabelNameTuples
} from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { IValidationInputData } from 'shared/components/ValidationInput';
import {
    ActionTypes,
    ProductSlimCreateFormActionInterfaces,
} from './ProductSlimCreateFormActions';

import { DEFAULT_PRICE_VALUE } from 'apps/ItemCard/reducers/ItemCardReducers';

export type ProductSlimCreateFormFieldName = 'name' | 'categoryId' | 'priceAmount' | 'priceUnit' | 'distributorId' | 'sku';
export type ProductSlimCreateFormComponentName = 'packagingDefaultText' | 'priceDefaultText' | 'categoryDefaultText' | 'packagingDisplayText';

export interface IProductSlimCreateFormData {
    distributorSortedOptionsAndLabelNames : OptionsAndLabelNameTuples;
    categorySortedOptionsAndLabelNames : OptionsAndLabelNameTuples;
}

export interface IProductSlimCreateFormState {
    validationInputDataByFieldName : { [fieldName in ProductSlimCreateFormFieldName] : IValidationInputData };
    isValid : boolean;
    isSubmitting : boolean;
    errorMessage : string;
    formData : IProductSlimCreateFormData | null;
    isShownByComponentName : {[componentName in ProductSlimCreateFormComponentName] : boolean};
    categoriesById : StringValueMap<CategoryId, Category>;
}

const productSlimCreateFormInitialValidationInputDataByFieldName = {
    name: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    categoryId: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    priceAmount: {
        value: DEFAULT_PRICE_VALUE,
        isValid: true,
        errorMessage: '',
    },
    priceUnit: {
        value: 'other', // will pass validation even if not available on form
        isValid: true,
        errorMessage: '',
    },
    distributorId: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    sku: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
};

const formInitialState : IProductSlimCreateFormState = {
    validationInputDataByFieldName: { ...productSlimCreateFormInitialValidationInputDataByFieldName },
    isValid: true,
    isSubmitting: false,
    errorMessage: '',
    isShownByComponentName: { // defaults for single row layout - show defaults on load
        packagingDefaultText: true,
        priceDefaultText: true,
        categoryDefaultText: true,
        packagingDisplayText: false,
    },
    formData: null,
    categoriesById: new StringValueMap()
};

const reduceSetFormData = (
    state : IProductSlimCreateFormState,
    action : ProductSlimCreateFormActionInterfaces.ISetFormData
) : IProductSlimCreateFormState => {
    return {
        ...state,
        formData: { ...action.payload.formData },
    };
};

const reduceSetFormFieldValidationData = (
    state : IProductSlimCreateFormState,
    action : ProductSlimCreateFormActionInterfaces.ISetFormFieldValidationData
) : IProductSlimCreateFormState => {
    return {
        ...state,
        validationInputDataByFieldName: {
            ...state.validationInputDataByFieldName,
            [action.payload.field]: {
                value: action.payload.value,
                errorMessage: action.payload.errorMessage,
                isValid: action.payload.isValid,
            },
        },
    };
};

const reduceSetFormValidationData = (
    state : IProductSlimCreateFormState,
    action : ProductSlimCreateFormActionInterfaces.ISetFormFieldValidationData
) : IProductSlimCreateFormState => {
    return {
        ...state,
        isValid: action.payload.isValid,
        errorMessage: action.payload.errorMessage,
    };
};

const reduceSetIsSubmitting = (
    state : IProductSlimCreateFormState,
    action : ProductSlimCreateFormActionInterfaces.ISetIsSubmitting
) : IProductSlimCreateFormState => {
    return {
        ...state,
        isSubmitting: action.payload.isSubmitting,
    };
};

const reduceResetForm = (
    state : IProductSlimCreateFormState,
    action : ProductSlimCreateFormActionInterfaces.IResetForm
) : IProductSlimCreateFormState => {
    return {
        ...state,
        validationInputDataByFieldName: { ...productSlimCreateFormInitialValidationInputDataByFieldName },
        isValid: true,
        isSubmitting: false,
        errorMessage: '',
        isShownByComponentName: { ...formInitialState.isShownByComponentName }
    };
};

const reduceSetComponentIsShown = (
    state : IProductSlimCreateFormState,
    action : ProductSlimCreateFormActionInterfaces.ISetComponentIsShown
) : IProductSlimCreateFormState  => {
    return {
        ...state,
        isShownByComponentName: {
            ...state.isShownByComponentName,
            [action.payload.componentName]: action.payload.isShown,
        },
    };
};

const reduceCategoriesById = (
    state : IProductSlimCreateFormState,
    action : ProductSlimCreateFormActionInterfaces.ISetCategoriesById
) : IProductSlimCreateFormState  => {
    return {
        ...state,
        categoriesById : action.payload.categoriesById
    };
};

const reduceCreateCustomCategory = (
    state : IProductSlimCreateFormState,
    action : ProductSlimCreateFormActionInterfaces.ICreateCustomCategory,
) : IProductSlimCreateFormState => {

    if (state.formData === null) {
        return state;
    }

    return {
        ...state,
        formData: {
            ...state.formData,
            categorySortedOptionsAndLabelNames:
                addToSortedOptionList(
                    state.formData.categorySortedOptionsAndLabelNames,
                    action.payload.category,
                    null,
                    CategoryUtils.categoryCompareFunction,
                ),
    }};
};

export const ProductSlimCreateFormReducers = (
    state : IProductSlimCreateFormState = formInitialState,
    action : Action,
) : IProductSlimCreateFormState => {
    switch (action.type) {
        case ActionTypes.SET_FORM_DATA:
            return reduceSetFormData(state, action as ProductSlimCreateFormActionInterfaces.ISetFormData);
        case ActionTypes.SET_FORM_FIELD_VALIDATION_DATA:
            return reduceSetFormFieldValidationData(state, action as ProductSlimCreateFormActionInterfaces.ISetFormFieldValidationData);
        case ActionTypes.SET_FORM_VALIDATION_DATA:
            return reduceSetFormValidationData(state, action as ProductSlimCreateFormActionInterfaces.ISetFormFieldValidationData);
        case ActionTypes.SET_IS_SUBMITTING:
            return reduceSetIsSubmitting(state, action as ProductSlimCreateFormActionInterfaces.ISetIsSubmitting);
        case ActionTypes.RESET_FORM:
            return reduceResetForm(state, action as ProductSlimCreateFormActionInterfaces.IResetForm);
        case ActionTypes.SET_COMPONENT_IS_SHOWN:
            return reduceSetComponentIsShown(state, action as ProductSlimCreateFormActionInterfaces.ISetComponentIsShown);
        case ActionTypes.SET_CATEGORIES_BY_ID:
            return reduceCategoriesById(state, action as ProductSlimCreateFormActionInterfaces.ISetCategoriesById);
        case ActionTypes.CREATE_CUSTOM_CATEGORY:
            return reduceCreateCustomCategory(state, action as ProductSlimCreateFormActionInterfaces.ICreateCustomCategory);
        default:
            return state;
    }
};
