export namespace StringUtils {

    export const normalizeToTitleCase = (rawValue : string) : string => {

        // NOTE THIS FUNCTION MUST MATCH THE LOGIC IN PYTHON: string_utils.ts!

        return rawValue

            // Normalize the whole string into lower case.
            .toLowerCase()

            // Join whitespace characters into single spaces.
            .split(/\s/).filter((word) => word !== '').join(' ')

            // Replace each first letter of each word with an upper case.
            // See anything not in [a-zA-Z0-9_'] as a seperator.
            .replace(
                /([^a-zA-Z0-9_']+|^)([a-zA-Z0-9_'])/g,
                (match, separator, firstLetter) =>
                    separator + firstLetter.toUpperCase());
    };

    // Future work: can take plural for ends in y
    export const pluralizeNumber = (descriptor : string, valuesLength : number) : string => {
        return `${ valuesLength } ${ descriptor }${ valuesLength === 1 ? '' : 's'}`;
    };
}
