//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


import Thrift from 'thrift';
import CoreApiExceptions from './core_api_Exceptions_types';
import DrinkPriceToolExceptions from './drink_price_tool_Exceptions_types';
import DrinkPriceToolModel from './drink_price_tool_Model_types';
import UnauthenticatedUserSessionModel from './unauthenticated_user_session_Model_types';
import UnauthenticatedUserSessionExceptions from './unauthenticated_user_session_Exceptions_types';
import UserAccountsModel from './accounts_user_Model_types';
import AccountsExceptions from './accounts_Exceptions_types';

var DrinkPriceToolService = {};
export default DrinkPriceToolService;
//HELPER FUNCTIONS AND STRUCTURES

DrinkPriceToolService.DrinkPriceToolService_recordPageViewAction_args = function(args) {
  this.unauthenticatedUserSessionId = null;
  if (args) {
    if (args.unauthenticatedUserSessionId !== undefined && args.unauthenticatedUserSessionId !== null) {
      this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId(args.unauthenticatedUserSessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field unauthenticatedUserSessionId is unset!');
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_recordPageViewAction_args.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_recordPageViewAction_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId();
        this.unauthenticatedUserSessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_recordPageViewAction_args.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_recordPageViewAction_args');
  if (this.unauthenticatedUserSessionId !== null && this.unauthenticatedUserSessionId !== undefined) {
    output.writeFieldBegin('unauthenticatedUserSessionId', Thrift.Type.STRUCT, 1);
    this.unauthenticatedUserSessionId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_recordPageViewAction_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownUnauthenticatedUserSessionIdException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException) {
    this.unknownUnauthenticatedUserSessionIdException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownUnauthenticatedUserSessionIdException !== undefined && args.unknownUnauthenticatedUserSessionIdException !== null) {
      this.unknownUnauthenticatedUserSessionIdException = args.unknownUnauthenticatedUserSessionIdException;
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_recordPageViewAction_result.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_recordPageViewAction_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownUnauthenticatedUserSessionIdException = new UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException();
        this.unknownUnauthenticatedUserSessionIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_recordPageViewAction_result.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_recordPageViewAction_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownUnauthenticatedUserSessionIdException !== null && this.unknownUnauthenticatedUserSessionIdException !== undefined) {
    output.writeFieldBegin('unknownUnauthenticatedUserSessionIdException', Thrift.Type.STRUCT, 3);
    this.unknownUnauthenticatedUserSessionIdException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_recordShareAction_args = function(args) {
  this.unauthenticatedUserSessionId = null;
  this.shareMedium = null;
  if (args) {
    if (args.unauthenticatedUserSessionId !== undefined && args.unauthenticatedUserSessionId !== null) {
      this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId(args.unauthenticatedUserSessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field unauthenticatedUserSessionId is unset!');
    }
    if (args.shareMedium !== undefined && args.shareMedium !== null) {
      this.shareMedium = args.shareMedium;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field shareMedium is unset!');
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_recordShareAction_args.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_recordShareAction_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId();
        this.unauthenticatedUserSessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.shareMedium = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_recordShareAction_args.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_recordShareAction_args');
  if (this.unauthenticatedUserSessionId !== null && this.unauthenticatedUserSessionId !== undefined) {
    output.writeFieldBegin('unauthenticatedUserSessionId', Thrift.Type.STRUCT, 1);
    this.unauthenticatedUserSessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.shareMedium !== null && this.shareMedium !== undefined) {
    output.writeFieldBegin('shareMedium', Thrift.Type.I32, 2);
    output.writeI32(this.shareMedium);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_recordShareAction_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownUnauthenticatedUserSessionIdException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException) {
    this.unknownUnauthenticatedUserSessionIdException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownUnauthenticatedUserSessionIdException !== undefined && args.unknownUnauthenticatedUserSessionIdException !== null) {
      this.unknownUnauthenticatedUserSessionIdException = args.unknownUnauthenticatedUserSessionIdException;
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_recordShareAction_result.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_recordShareAction_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownUnauthenticatedUserSessionIdException = new UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException();
        this.unknownUnauthenticatedUserSessionIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_recordShareAction_result.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_recordShareAction_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownUnauthenticatedUserSessionIdException !== null && this.unknownUnauthenticatedUserSessionIdException !== undefined) {
    output.writeFieldBegin('unknownUnauthenticatedUserSessionIdException', Thrift.Type.STRUCT, 3);
    this.unknownUnauthenticatedUserSessionIdException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsForUnauthenticatedUserSession_args = function(args) {
  this.unauthenticatedUserSessionId = null;
  if (args) {
    if (args.unauthenticatedUserSessionId !== undefined && args.unauthenticatedUserSessionId !== null) {
      this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId(args.unauthenticatedUserSessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field unauthenticatedUserSessionId is unset!');
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsForUnauthenticatedUserSession_args.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsForUnauthenticatedUserSession_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId();
        this.unauthenticatedUserSessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsForUnauthenticatedUserSession_args.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_getDrinkIdsForUnauthenticatedUserSession_args');
  if (this.unauthenticatedUserSessionId !== null && this.unauthenticatedUserSessionId !== undefined) {
    output.writeFieldBegin('unauthenticatedUserSessionId', Thrift.Type.STRUCT, 1);
    this.unauthenticatedUserSessionId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsForUnauthenticatedUserSession_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownUnauthenticatedUserSessionIdException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException) {
    this.unknownUnauthenticatedUserSessionIdException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [DrinkPriceToolModel.DrinkId]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownUnauthenticatedUserSessionIdException !== undefined && args.unknownUnauthenticatedUserSessionIdException !== null) {
      this.unknownUnauthenticatedUserSessionIdException = args.unknownUnauthenticatedUserSessionIdException;
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsForUnauthenticatedUserSession_result.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsForUnauthenticatedUserSession_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.SET) {
        var _size0 = 0;
        var _rtmp34;
        this.success = [];
        var _etype3 = 0;
        _rtmp34 = input.readSetBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new DrinkPriceToolModel.DrinkId();
          elem6.read(input);
          this.success.push(elem6);
        }
        input.readSetEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownUnauthenticatedUserSessionIdException = new UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException();
        this.unknownUnauthenticatedUserSessionIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsForUnauthenticatedUserSession_result.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_getDrinkIdsForUnauthenticatedUserSession_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.SET, 0);
    output.writeSetBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter7 in this.success)
    {
      if (this.success.hasOwnProperty(iter7))
      {
        iter7 = this.success[iter7];
        iter7.write(output);
      }
    }
    output.writeSetEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownUnauthenticatedUserSessionIdException !== null && this.unknownUnauthenticatedUserSessionIdException !== undefined) {
    output.writeFieldBegin('unknownUnauthenticatedUserSessionIdException', Thrift.Type.STRUCT, 3);
    this.unknownUnauthenticatedUserSessionIdException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_getDrinksByDrinkIdsForUnauthenticatedUserSession_args = function(args) {
  this.unauthenticatedUserSessionId = null;
  this.drinkIds = null;
  if (args) {
    if (args.unauthenticatedUserSessionId !== undefined && args.unauthenticatedUserSessionId !== null) {
      this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId(args.unauthenticatedUserSessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field unauthenticatedUserSessionId is unset!');
    }
    if (args.drinkIds !== undefined && args.drinkIds !== null) {
      this.drinkIds = Thrift.copyList(args.drinkIds, [DrinkPriceToolModel.DrinkId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field drinkIds is unset!');
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_getDrinksByDrinkIdsForUnauthenticatedUserSession_args.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_getDrinksByDrinkIdsForUnauthenticatedUserSession_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId();
        this.unauthenticatedUserSessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size8 = 0;
        var _rtmp312;
        this.drinkIds = [];
        var _etype11 = 0;
        _rtmp312 = input.readListBegin();
        _etype11 = _rtmp312.etype;
        _size8 = _rtmp312.size;
        for (var _i13 = 0; _i13 < _size8; ++_i13)
        {
          var elem14 = null;
          elem14 = new DrinkPriceToolModel.DrinkId();
          elem14.read(input);
          this.drinkIds.push(elem14);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_getDrinksByDrinkIdsForUnauthenticatedUserSession_args.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_getDrinksByDrinkIdsForUnauthenticatedUserSession_args');
  if (this.unauthenticatedUserSessionId !== null && this.unauthenticatedUserSessionId !== undefined) {
    output.writeFieldBegin('unauthenticatedUserSessionId', Thrift.Type.STRUCT, 1);
    this.unauthenticatedUserSessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.drinkIds !== null && this.drinkIds !== undefined) {
    output.writeFieldBegin('drinkIds', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.drinkIds.length);
    for (var iter15 in this.drinkIds)
    {
      if (this.drinkIds.hasOwnProperty(iter15))
      {
        iter15 = this.drinkIds[iter15];
        iter15.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_getDrinksByDrinkIdsForUnauthenticatedUserSession_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownUnauthenticatedUserSessionIdException = null;
  this.unknownDrinkIdException = null;
  this.emptyArgumentException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException) {
    this.unknownUnauthenticatedUserSessionIdException = args;
    return;
  }
  if (args instanceof DrinkPriceToolExceptions.UnknownDrinkIdException) {
    this.unknownDrinkIdException = args;
    return;
  }
  if (args instanceof DrinkPriceToolExceptions.EmptyArgumentException) {
    this.emptyArgumentException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [DrinkPriceToolModel.Drink]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownUnauthenticatedUserSessionIdException !== undefined && args.unknownUnauthenticatedUserSessionIdException !== null) {
      this.unknownUnauthenticatedUserSessionIdException = args.unknownUnauthenticatedUserSessionIdException;
    }
    if (args.unknownDrinkIdException !== undefined && args.unknownDrinkIdException !== null) {
      this.unknownDrinkIdException = args.unknownDrinkIdException;
    }
    if (args.emptyArgumentException !== undefined && args.emptyArgumentException !== null) {
      this.emptyArgumentException = args.emptyArgumentException;
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_getDrinksByDrinkIdsForUnauthenticatedUserSession_result.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_getDrinksByDrinkIdsForUnauthenticatedUserSession_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size16 = 0;
        var _rtmp320;
        this.success = [];
        var _etype19 = 0;
        _rtmp320 = input.readListBegin();
        _etype19 = _rtmp320.etype;
        _size16 = _rtmp320.size;
        for (var _i21 = 0; _i21 < _size16; ++_i21)
        {
          var elem22 = null;
          elem22 = new DrinkPriceToolModel.Drink();
          elem22.read(input);
          this.success.push(elem22);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownUnauthenticatedUserSessionIdException = new UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException();
        this.unknownUnauthenticatedUserSessionIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownDrinkIdException = new DrinkPriceToolExceptions.UnknownDrinkIdException();
        this.unknownDrinkIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.emptyArgumentException = new DrinkPriceToolExceptions.EmptyArgumentException();
        this.emptyArgumentException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_getDrinksByDrinkIdsForUnauthenticatedUserSession_result.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_getDrinksByDrinkIdsForUnauthenticatedUserSession_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter23 in this.success)
    {
      if (this.success.hasOwnProperty(iter23))
      {
        iter23 = this.success[iter23];
        iter23.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownUnauthenticatedUserSessionIdException !== null && this.unknownUnauthenticatedUserSessionIdException !== undefined) {
    output.writeFieldBegin('unknownUnauthenticatedUserSessionIdException', Thrift.Type.STRUCT, 3);
    this.unknownUnauthenticatedUserSessionIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownDrinkIdException !== null && this.unknownDrinkIdException !== undefined) {
    output.writeFieldBegin('unknownDrinkIdException', Thrift.Type.STRUCT, 4);
    this.unknownDrinkIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.emptyArgumentException !== null && this.emptyArgumentException !== undefined) {
    output.writeFieldBegin('emptyArgumentException', Thrift.Type.STRUCT, 5);
    this.emptyArgumentException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_createDrinksForUnauthenticatedUserSession_args = function(args) {
  this.unauthenticatedUserSessionId = null;
  this.drinks = null;
  if (args) {
    if (args.unauthenticatedUserSessionId !== undefined && args.unauthenticatedUserSessionId !== null) {
      this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId(args.unauthenticatedUserSessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field unauthenticatedUserSessionId is unset!');
    }
    if (args.drinks !== undefined && args.drinks !== null) {
      this.drinks = Thrift.copyList(args.drinks, [DrinkPriceToolModel.Drink]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field drinks is unset!');
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_createDrinksForUnauthenticatedUserSession_args.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_createDrinksForUnauthenticatedUserSession_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId();
        this.unauthenticatedUserSessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size24 = 0;
        var _rtmp328;
        this.drinks = [];
        var _etype27 = 0;
        _rtmp328 = input.readListBegin();
        _etype27 = _rtmp328.etype;
        _size24 = _rtmp328.size;
        for (var _i29 = 0; _i29 < _size24; ++_i29)
        {
          var elem30 = null;
          elem30 = new DrinkPriceToolModel.Drink();
          elem30.read(input);
          this.drinks.push(elem30);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_createDrinksForUnauthenticatedUserSession_args.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_createDrinksForUnauthenticatedUserSession_args');
  if (this.unauthenticatedUserSessionId !== null && this.unauthenticatedUserSessionId !== undefined) {
    output.writeFieldBegin('unauthenticatedUserSessionId', Thrift.Type.STRUCT, 1);
    this.unauthenticatedUserSessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.drinks !== null && this.drinks !== undefined) {
    output.writeFieldBegin('drinks', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.drinks.length);
    for (var iter31 in this.drinks)
    {
      if (this.drinks.hasOwnProperty(iter31))
      {
        iter31 = this.drinks[iter31];
        iter31.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_createDrinksForUnauthenticatedUserSession_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownUnauthenticatedUserSessionIdException = null;
  this.emptyArgumentException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException) {
    this.unknownUnauthenticatedUserSessionIdException = args;
    return;
  }
  if (args instanceof DrinkPriceToolExceptions.EmptyArgumentException) {
    this.emptyArgumentException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [DrinkPriceToolModel.DrinkId]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownUnauthenticatedUserSessionIdException !== undefined && args.unknownUnauthenticatedUserSessionIdException !== null) {
      this.unknownUnauthenticatedUserSessionIdException = args.unknownUnauthenticatedUserSessionIdException;
    }
    if (args.emptyArgumentException !== undefined && args.emptyArgumentException !== null) {
      this.emptyArgumentException = args.emptyArgumentException;
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_createDrinksForUnauthenticatedUserSession_result.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_createDrinksForUnauthenticatedUserSession_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size32 = 0;
        var _rtmp336;
        this.success = [];
        var _etype35 = 0;
        _rtmp336 = input.readListBegin();
        _etype35 = _rtmp336.etype;
        _size32 = _rtmp336.size;
        for (var _i37 = 0; _i37 < _size32; ++_i37)
        {
          var elem38 = null;
          elem38 = new DrinkPriceToolModel.DrinkId();
          elem38.read(input);
          this.success.push(elem38);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownUnauthenticatedUserSessionIdException = new UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException();
        this.unknownUnauthenticatedUserSessionIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.emptyArgumentException = new DrinkPriceToolExceptions.EmptyArgumentException();
        this.emptyArgumentException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_createDrinksForUnauthenticatedUserSession_result.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_createDrinksForUnauthenticatedUserSession_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter39 in this.success)
    {
      if (this.success.hasOwnProperty(iter39))
      {
        iter39 = this.success[iter39];
        iter39.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownUnauthenticatedUserSessionIdException !== null && this.unknownUnauthenticatedUserSessionIdException !== undefined) {
    output.writeFieldBegin('unknownUnauthenticatedUserSessionIdException', Thrift.Type.STRUCT, 3);
    this.unknownUnauthenticatedUserSessionIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.emptyArgumentException !== null && this.emptyArgumentException !== undefined) {
    output.writeFieldBegin('emptyArgumentException', Thrift.Type.STRUCT, 4);
    this.emptyArgumentException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_editDrinksByDrinkIdForUnauthenticatedUserSession_args = function(args) {
  this.unauthenticatedUserSessionId = null;
  this.drinkIds = null;
  this.drinks = null;
  if (args) {
    if (args.unauthenticatedUserSessionId !== undefined && args.unauthenticatedUserSessionId !== null) {
      this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId(args.unauthenticatedUserSessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field unauthenticatedUserSessionId is unset!');
    }
    if (args.drinkIds !== undefined && args.drinkIds !== null) {
      this.drinkIds = Thrift.copyList(args.drinkIds, [DrinkPriceToolModel.DrinkId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field drinkIds is unset!');
    }
    if (args.drinks !== undefined && args.drinks !== null) {
      this.drinks = Thrift.copyList(args.drinks, [DrinkPriceToolModel.Drink]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field drinks is unset!');
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_editDrinksByDrinkIdForUnauthenticatedUserSession_args.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_editDrinksByDrinkIdForUnauthenticatedUserSession_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId();
        this.unauthenticatedUserSessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size40 = 0;
        var _rtmp344;
        this.drinkIds = [];
        var _etype43 = 0;
        _rtmp344 = input.readListBegin();
        _etype43 = _rtmp344.etype;
        _size40 = _rtmp344.size;
        for (var _i45 = 0; _i45 < _size40; ++_i45)
        {
          var elem46 = null;
          elem46 = new DrinkPriceToolModel.DrinkId();
          elem46.read(input);
          this.drinkIds.push(elem46);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        var _size47 = 0;
        var _rtmp351;
        this.drinks = [];
        var _etype50 = 0;
        _rtmp351 = input.readListBegin();
        _etype50 = _rtmp351.etype;
        _size47 = _rtmp351.size;
        for (var _i52 = 0; _i52 < _size47; ++_i52)
        {
          var elem53 = null;
          elem53 = new DrinkPriceToolModel.Drink();
          elem53.read(input);
          this.drinks.push(elem53);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_editDrinksByDrinkIdForUnauthenticatedUserSession_args.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_editDrinksByDrinkIdForUnauthenticatedUserSession_args');
  if (this.unauthenticatedUserSessionId !== null && this.unauthenticatedUserSessionId !== undefined) {
    output.writeFieldBegin('unauthenticatedUserSessionId', Thrift.Type.STRUCT, 1);
    this.unauthenticatedUserSessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.drinkIds !== null && this.drinkIds !== undefined) {
    output.writeFieldBegin('drinkIds', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.drinkIds.length);
    for (var iter54 in this.drinkIds)
    {
      if (this.drinkIds.hasOwnProperty(iter54))
      {
        iter54 = this.drinkIds[iter54];
        iter54.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.drinks !== null && this.drinks !== undefined) {
    output.writeFieldBegin('drinks', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRUCT, this.drinks.length);
    for (var iter55 in this.drinks)
    {
      if (this.drinks.hasOwnProperty(iter55))
      {
        iter55 = this.drinks[iter55];
        iter55.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_editDrinksByDrinkIdForUnauthenticatedUserSession_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownUnauthenticatedUserSessionIdException = null;
  this.unknownDrinkIdException = null;
  this.emptyArgumentException = null;
  this.argumentArraysOfUnevenLengthException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException) {
    this.unknownUnauthenticatedUserSessionIdException = args;
    return;
  }
  if (args instanceof DrinkPriceToolExceptions.UnknownDrinkIdException) {
    this.unknownDrinkIdException = args;
    return;
  }
  if (args instanceof DrinkPriceToolExceptions.EmptyArgumentException) {
    this.emptyArgumentException = args;
    return;
  }
  if (args instanceof DrinkPriceToolExceptions.ArgumentArraysOfUnevenLengthException) {
    this.argumentArraysOfUnevenLengthException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownUnauthenticatedUserSessionIdException !== undefined && args.unknownUnauthenticatedUserSessionIdException !== null) {
      this.unknownUnauthenticatedUserSessionIdException = args.unknownUnauthenticatedUserSessionIdException;
    }
    if (args.unknownDrinkIdException !== undefined && args.unknownDrinkIdException !== null) {
      this.unknownDrinkIdException = args.unknownDrinkIdException;
    }
    if (args.emptyArgumentException !== undefined && args.emptyArgumentException !== null) {
      this.emptyArgumentException = args.emptyArgumentException;
    }
    if (args.argumentArraysOfUnevenLengthException !== undefined && args.argumentArraysOfUnevenLengthException !== null) {
      this.argumentArraysOfUnevenLengthException = args.argumentArraysOfUnevenLengthException;
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_editDrinksByDrinkIdForUnauthenticatedUserSession_result.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_editDrinksByDrinkIdForUnauthenticatedUserSession_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownUnauthenticatedUserSessionIdException = new UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException();
        this.unknownUnauthenticatedUserSessionIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownDrinkIdException = new DrinkPriceToolExceptions.UnknownDrinkIdException();
        this.unknownDrinkIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.emptyArgumentException = new DrinkPriceToolExceptions.EmptyArgumentException();
        this.emptyArgumentException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.argumentArraysOfUnevenLengthException = new DrinkPriceToolExceptions.ArgumentArraysOfUnevenLengthException();
        this.argumentArraysOfUnevenLengthException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_editDrinksByDrinkIdForUnauthenticatedUserSession_result.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_editDrinksByDrinkIdForUnauthenticatedUserSession_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownUnauthenticatedUserSessionIdException !== null && this.unknownUnauthenticatedUserSessionIdException !== undefined) {
    output.writeFieldBegin('unknownUnauthenticatedUserSessionIdException', Thrift.Type.STRUCT, 3);
    this.unknownUnauthenticatedUserSessionIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownDrinkIdException !== null && this.unknownDrinkIdException !== undefined) {
    output.writeFieldBegin('unknownDrinkIdException', Thrift.Type.STRUCT, 4);
    this.unknownDrinkIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.emptyArgumentException !== null && this.emptyArgumentException !== undefined) {
    output.writeFieldBegin('emptyArgumentException', Thrift.Type.STRUCT, 5);
    this.emptyArgumentException.write(output);
    output.writeFieldEnd();
  }
  if (this.argumentArraysOfUnevenLengthException !== null && this.argumentArraysOfUnevenLengthException !== undefined) {
    output.writeFieldBegin('argumentArraysOfUnevenLengthException', Thrift.Type.STRUCT, 6);
    this.argumentArraysOfUnevenLengthException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_deleteDrinksByDrinkIdForUnauthenticatedUserSession_args = function(args) {
  this.unauthenticatedUserSessionId = null;
  this.drinkIds = null;
  if (args) {
    if (args.unauthenticatedUserSessionId !== undefined && args.unauthenticatedUserSessionId !== null) {
      this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId(args.unauthenticatedUserSessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field unauthenticatedUserSessionId is unset!');
    }
    if (args.drinkIds !== undefined && args.drinkIds !== null) {
      this.drinkIds = Thrift.copyList(args.drinkIds, [DrinkPriceToolModel.DrinkId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field drinkIds is unset!');
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_deleteDrinksByDrinkIdForUnauthenticatedUserSession_args.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_deleteDrinksByDrinkIdForUnauthenticatedUserSession_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId();
        this.unauthenticatedUserSessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.SET) {
        var _size56 = 0;
        var _rtmp360;
        this.drinkIds = [];
        var _etype59 = 0;
        _rtmp360 = input.readSetBegin();
        _etype59 = _rtmp360.etype;
        _size56 = _rtmp360.size;
        for (var _i61 = 0; _i61 < _size56; ++_i61)
        {
          var elem62 = null;
          elem62 = new DrinkPriceToolModel.DrinkId();
          elem62.read(input);
          this.drinkIds.push(elem62);
        }
        input.readSetEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_deleteDrinksByDrinkIdForUnauthenticatedUserSession_args.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_deleteDrinksByDrinkIdForUnauthenticatedUserSession_args');
  if (this.unauthenticatedUserSessionId !== null && this.unauthenticatedUserSessionId !== undefined) {
    output.writeFieldBegin('unauthenticatedUserSessionId', Thrift.Type.STRUCT, 1);
    this.unauthenticatedUserSessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.drinkIds !== null && this.drinkIds !== undefined) {
    output.writeFieldBegin('drinkIds', Thrift.Type.SET, 2);
    output.writeSetBegin(Thrift.Type.STRUCT, this.drinkIds.length);
    for (var iter63 in this.drinkIds)
    {
      if (this.drinkIds.hasOwnProperty(iter63))
      {
        iter63 = this.drinkIds[iter63];
        iter63.write(output);
      }
    }
    output.writeSetEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_deleteDrinksByDrinkIdForUnauthenticatedUserSession_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownUnauthenticatedUserSessionIdException = null;
  this.unknownDrinkIdException = null;
  this.emptyArgumentException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException) {
    this.unknownUnauthenticatedUserSessionIdException = args;
    return;
  }
  if (args instanceof DrinkPriceToolExceptions.UnknownDrinkIdException) {
    this.unknownDrinkIdException = args;
    return;
  }
  if (args instanceof DrinkPriceToolExceptions.EmptyArgumentException) {
    this.emptyArgumentException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownUnauthenticatedUserSessionIdException !== undefined && args.unknownUnauthenticatedUserSessionIdException !== null) {
      this.unknownUnauthenticatedUserSessionIdException = args.unknownUnauthenticatedUserSessionIdException;
    }
    if (args.unknownDrinkIdException !== undefined && args.unknownDrinkIdException !== null) {
      this.unknownDrinkIdException = args.unknownDrinkIdException;
    }
    if (args.emptyArgumentException !== undefined && args.emptyArgumentException !== null) {
      this.emptyArgumentException = args.emptyArgumentException;
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_deleteDrinksByDrinkIdForUnauthenticatedUserSession_result.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_deleteDrinksByDrinkIdForUnauthenticatedUserSession_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownUnauthenticatedUserSessionIdException = new UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException();
        this.unknownUnauthenticatedUserSessionIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownDrinkIdException = new DrinkPriceToolExceptions.UnknownDrinkIdException();
        this.unknownDrinkIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.emptyArgumentException = new DrinkPriceToolExceptions.EmptyArgumentException();
        this.emptyArgumentException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_deleteDrinksByDrinkIdForUnauthenticatedUserSession_result.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_deleteDrinksByDrinkIdForUnauthenticatedUserSession_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownUnauthenticatedUserSessionIdException !== null && this.unknownUnauthenticatedUserSessionIdException !== undefined) {
    output.writeFieldBegin('unknownUnauthenticatedUserSessionIdException', Thrift.Type.STRUCT, 3);
    this.unknownUnauthenticatedUserSessionIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownDrinkIdException !== null && this.unknownDrinkIdException !== undefined) {
    output.writeFieldBegin('unknownDrinkIdException', Thrift.Type.STRUCT, 4);
    this.unknownDrinkIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.emptyArgumentException !== null && this.emptyArgumentException !== undefined) {
    output.writeFieldBegin('emptyArgumentException', Thrift.Type.STRUCT, 5);
    this.emptyArgumentException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsForAuthenticatedUserSession_args = function(args) {
  this.authenticatedUserSessionId = null;
  if (args) {
    if (args.authenticatedUserSessionId !== undefined && args.authenticatedUserSessionId !== null) {
      this.authenticatedUserSessionId = new UserAccountsModel.UserSessionIdentifier(args.authenticatedUserSessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field authenticatedUserSessionId is unset!');
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsForAuthenticatedUserSession_args.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsForAuthenticatedUserSession_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticatedUserSessionId = new UserAccountsModel.UserSessionIdentifier();
        this.authenticatedUserSessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsForAuthenticatedUserSession_args.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_getDrinkIdsForAuthenticatedUserSession_args');
  if (this.authenticatedUserSessionId !== null && this.authenticatedUserSessionId !== undefined) {
    output.writeFieldBegin('authenticatedUserSessionId', Thrift.Type.STRUCT, 1);
    this.authenticatedUserSessionId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsForAuthenticatedUserSession_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownActorException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [DrinkPriceToolModel.DrinkId]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsForAuthenticatedUserSession_result.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsForAuthenticatedUserSession_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.SET) {
        var _size64 = 0;
        var _rtmp368;
        this.success = [];
        var _etype67 = 0;
        _rtmp368 = input.readSetBegin();
        _etype67 = _rtmp368.etype;
        _size64 = _rtmp368.size;
        for (var _i69 = 0; _i69 < _size64; ++_i69)
        {
          var elem70 = null;
          elem70 = new DrinkPriceToolModel.DrinkId();
          elem70.read(input);
          this.success.push(elem70);
        }
        input.readSetEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsForAuthenticatedUserSession_result.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_getDrinkIdsForAuthenticatedUserSession_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.SET, 0);
    output.writeSetBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter71 in this.success)
    {
      if (this.success.hasOwnProperty(iter71))
      {
        iter71 = this.success[iter71];
        iter71.write(output);
      }
    }
    output.writeSetEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_getDrinksByDrinkIdsForAuthenticatedUserSession_args = function(args) {
  this.authenticatedUserSessionId = null;
  this.drinkIds = null;
  if (args) {
    if (args.authenticatedUserSessionId !== undefined && args.authenticatedUserSessionId !== null) {
      this.authenticatedUserSessionId = new UserAccountsModel.UserSessionIdentifier(args.authenticatedUserSessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field authenticatedUserSessionId is unset!');
    }
    if (args.drinkIds !== undefined && args.drinkIds !== null) {
      this.drinkIds = Thrift.copyList(args.drinkIds, [DrinkPriceToolModel.DrinkId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field drinkIds is unset!');
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_getDrinksByDrinkIdsForAuthenticatedUserSession_args.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_getDrinksByDrinkIdsForAuthenticatedUserSession_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticatedUserSessionId = new UserAccountsModel.UserSessionIdentifier();
        this.authenticatedUserSessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size72 = 0;
        var _rtmp376;
        this.drinkIds = [];
        var _etype75 = 0;
        _rtmp376 = input.readListBegin();
        _etype75 = _rtmp376.etype;
        _size72 = _rtmp376.size;
        for (var _i77 = 0; _i77 < _size72; ++_i77)
        {
          var elem78 = null;
          elem78 = new DrinkPriceToolModel.DrinkId();
          elem78.read(input);
          this.drinkIds.push(elem78);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_getDrinksByDrinkIdsForAuthenticatedUserSession_args.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_getDrinksByDrinkIdsForAuthenticatedUserSession_args');
  if (this.authenticatedUserSessionId !== null && this.authenticatedUserSessionId !== undefined) {
    output.writeFieldBegin('authenticatedUserSessionId', Thrift.Type.STRUCT, 1);
    this.authenticatedUserSessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.drinkIds !== null && this.drinkIds !== undefined) {
    output.writeFieldBegin('drinkIds', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.drinkIds.length);
    for (var iter79 in this.drinkIds)
    {
      if (this.drinkIds.hasOwnProperty(iter79))
      {
        iter79 = this.drinkIds[iter79];
        iter79.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_getDrinksByDrinkIdsForAuthenticatedUserSession_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownActorException = null;
  this.unknownDrinkIdException = null;
  this.emptyArgumentException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof DrinkPriceToolExceptions.UnknownDrinkIdException) {
    this.unknownDrinkIdException = args;
    return;
  }
  if (args instanceof DrinkPriceToolExceptions.EmptyArgumentException) {
    this.emptyArgumentException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [DrinkPriceToolModel.Drink]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.unknownDrinkIdException !== undefined && args.unknownDrinkIdException !== null) {
      this.unknownDrinkIdException = args.unknownDrinkIdException;
    }
    if (args.emptyArgumentException !== undefined && args.emptyArgumentException !== null) {
      this.emptyArgumentException = args.emptyArgumentException;
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_getDrinksByDrinkIdsForAuthenticatedUserSession_result.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_getDrinksByDrinkIdsForAuthenticatedUserSession_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size80 = 0;
        var _rtmp384;
        this.success = [];
        var _etype83 = 0;
        _rtmp384 = input.readListBegin();
        _etype83 = _rtmp384.etype;
        _size80 = _rtmp384.size;
        for (var _i85 = 0; _i85 < _size80; ++_i85)
        {
          var elem86 = null;
          elem86 = new DrinkPriceToolModel.Drink();
          elem86.read(input);
          this.success.push(elem86);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownDrinkIdException = new DrinkPriceToolExceptions.UnknownDrinkIdException();
        this.unknownDrinkIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.emptyArgumentException = new DrinkPriceToolExceptions.EmptyArgumentException();
        this.emptyArgumentException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_getDrinksByDrinkIdsForAuthenticatedUserSession_result.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_getDrinksByDrinkIdsForAuthenticatedUserSession_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter87 in this.success)
    {
      if (this.success.hasOwnProperty(iter87))
      {
        iter87 = this.success[iter87];
        iter87.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownDrinkIdException !== null && this.unknownDrinkIdException !== undefined) {
    output.writeFieldBegin('unknownDrinkIdException', Thrift.Type.STRUCT, 4);
    this.unknownDrinkIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.emptyArgumentException !== null && this.emptyArgumentException !== undefined) {
    output.writeFieldBegin('emptyArgumentException', Thrift.Type.STRUCT, 5);
    this.emptyArgumentException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_createDrinksForAuthenticatedUserSession_args = function(args) {
  this.authenticatedUserSessionId = null;
  this.drinks = null;
  if (args) {
    if (args.authenticatedUserSessionId !== undefined && args.authenticatedUserSessionId !== null) {
      this.authenticatedUserSessionId = new UserAccountsModel.UserSessionIdentifier(args.authenticatedUserSessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field authenticatedUserSessionId is unset!');
    }
    if (args.drinks !== undefined && args.drinks !== null) {
      this.drinks = Thrift.copyList(args.drinks, [DrinkPriceToolModel.Drink]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field drinks is unset!');
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_createDrinksForAuthenticatedUserSession_args.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_createDrinksForAuthenticatedUserSession_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticatedUserSessionId = new UserAccountsModel.UserSessionIdentifier();
        this.authenticatedUserSessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size88 = 0;
        var _rtmp392;
        this.drinks = [];
        var _etype91 = 0;
        _rtmp392 = input.readListBegin();
        _etype91 = _rtmp392.etype;
        _size88 = _rtmp392.size;
        for (var _i93 = 0; _i93 < _size88; ++_i93)
        {
          var elem94 = null;
          elem94 = new DrinkPriceToolModel.Drink();
          elem94.read(input);
          this.drinks.push(elem94);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_createDrinksForAuthenticatedUserSession_args.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_createDrinksForAuthenticatedUserSession_args');
  if (this.authenticatedUserSessionId !== null && this.authenticatedUserSessionId !== undefined) {
    output.writeFieldBegin('authenticatedUserSessionId', Thrift.Type.STRUCT, 1);
    this.authenticatedUserSessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.drinks !== null && this.drinks !== undefined) {
    output.writeFieldBegin('drinks', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.drinks.length);
    for (var iter95 in this.drinks)
    {
      if (this.drinks.hasOwnProperty(iter95))
      {
        iter95 = this.drinks[iter95];
        iter95.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_createDrinksForAuthenticatedUserSession_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownActorException = null;
  this.emptyArgumentException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof DrinkPriceToolExceptions.EmptyArgumentException) {
    this.emptyArgumentException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [DrinkPriceToolModel.DrinkId]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.emptyArgumentException !== undefined && args.emptyArgumentException !== null) {
      this.emptyArgumentException = args.emptyArgumentException;
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_createDrinksForAuthenticatedUserSession_result.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_createDrinksForAuthenticatedUserSession_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size96 = 0;
        var _rtmp3100;
        this.success = [];
        var _etype99 = 0;
        _rtmp3100 = input.readListBegin();
        _etype99 = _rtmp3100.etype;
        _size96 = _rtmp3100.size;
        for (var _i101 = 0; _i101 < _size96; ++_i101)
        {
          var elem102 = null;
          elem102 = new DrinkPriceToolModel.DrinkId();
          elem102.read(input);
          this.success.push(elem102);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.emptyArgumentException = new DrinkPriceToolExceptions.EmptyArgumentException();
        this.emptyArgumentException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_createDrinksForAuthenticatedUserSession_result.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_createDrinksForAuthenticatedUserSession_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter103 in this.success)
    {
      if (this.success.hasOwnProperty(iter103))
      {
        iter103 = this.success[iter103];
        iter103.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.emptyArgumentException !== null && this.emptyArgumentException !== undefined) {
    output.writeFieldBegin('emptyArgumentException', Thrift.Type.STRUCT, 4);
    this.emptyArgumentException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_editDrinksByDrinkIdForAuthenticatedUserSession_args = function(args) {
  this.authenticatedUserSessionId = null;
  this.drinkIds = null;
  this.drinks = null;
  if (args) {
    if (args.authenticatedUserSessionId !== undefined && args.authenticatedUserSessionId !== null) {
      this.authenticatedUserSessionId = new UserAccountsModel.UserSessionIdentifier(args.authenticatedUserSessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field authenticatedUserSessionId is unset!');
    }
    if (args.drinkIds !== undefined && args.drinkIds !== null) {
      this.drinkIds = Thrift.copyList(args.drinkIds, [DrinkPriceToolModel.DrinkId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field drinkIds is unset!');
    }
    if (args.drinks !== undefined && args.drinks !== null) {
      this.drinks = Thrift.copyList(args.drinks, [DrinkPriceToolModel.Drink]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field drinks is unset!');
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_editDrinksByDrinkIdForAuthenticatedUserSession_args.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_editDrinksByDrinkIdForAuthenticatedUserSession_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticatedUserSessionId = new UserAccountsModel.UserSessionIdentifier();
        this.authenticatedUserSessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size104 = 0;
        var _rtmp3108;
        this.drinkIds = [];
        var _etype107 = 0;
        _rtmp3108 = input.readListBegin();
        _etype107 = _rtmp3108.etype;
        _size104 = _rtmp3108.size;
        for (var _i109 = 0; _i109 < _size104; ++_i109)
        {
          var elem110 = null;
          elem110 = new DrinkPriceToolModel.DrinkId();
          elem110.read(input);
          this.drinkIds.push(elem110);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        var _size111 = 0;
        var _rtmp3115;
        this.drinks = [];
        var _etype114 = 0;
        _rtmp3115 = input.readListBegin();
        _etype114 = _rtmp3115.etype;
        _size111 = _rtmp3115.size;
        for (var _i116 = 0; _i116 < _size111; ++_i116)
        {
          var elem117 = null;
          elem117 = new DrinkPriceToolModel.Drink();
          elem117.read(input);
          this.drinks.push(elem117);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_editDrinksByDrinkIdForAuthenticatedUserSession_args.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_editDrinksByDrinkIdForAuthenticatedUserSession_args');
  if (this.authenticatedUserSessionId !== null && this.authenticatedUserSessionId !== undefined) {
    output.writeFieldBegin('authenticatedUserSessionId', Thrift.Type.STRUCT, 1);
    this.authenticatedUserSessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.drinkIds !== null && this.drinkIds !== undefined) {
    output.writeFieldBegin('drinkIds', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.drinkIds.length);
    for (var iter118 in this.drinkIds)
    {
      if (this.drinkIds.hasOwnProperty(iter118))
      {
        iter118 = this.drinkIds[iter118];
        iter118.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.drinks !== null && this.drinks !== undefined) {
    output.writeFieldBegin('drinks', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRUCT, this.drinks.length);
    for (var iter119 in this.drinks)
    {
      if (this.drinks.hasOwnProperty(iter119))
      {
        iter119 = this.drinks[iter119];
        iter119.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_editDrinksByDrinkIdForAuthenticatedUserSession_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownActorException = null;
  this.unknownDrinkIdException = null;
  this.emptyArgumentException = null;
  this.argumentArraysOfUnevenLengthException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof DrinkPriceToolExceptions.UnknownDrinkIdException) {
    this.unknownDrinkIdException = args;
    return;
  }
  if (args instanceof DrinkPriceToolExceptions.EmptyArgumentException) {
    this.emptyArgumentException = args;
    return;
  }
  if (args instanceof DrinkPriceToolExceptions.ArgumentArraysOfUnevenLengthException) {
    this.argumentArraysOfUnevenLengthException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.unknownDrinkIdException !== undefined && args.unknownDrinkIdException !== null) {
      this.unknownDrinkIdException = args.unknownDrinkIdException;
    }
    if (args.emptyArgumentException !== undefined && args.emptyArgumentException !== null) {
      this.emptyArgumentException = args.emptyArgumentException;
    }
    if (args.argumentArraysOfUnevenLengthException !== undefined && args.argumentArraysOfUnevenLengthException !== null) {
      this.argumentArraysOfUnevenLengthException = args.argumentArraysOfUnevenLengthException;
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_editDrinksByDrinkIdForAuthenticatedUserSession_result.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_editDrinksByDrinkIdForAuthenticatedUserSession_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownDrinkIdException = new DrinkPriceToolExceptions.UnknownDrinkIdException();
        this.unknownDrinkIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.emptyArgumentException = new DrinkPriceToolExceptions.EmptyArgumentException();
        this.emptyArgumentException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.argumentArraysOfUnevenLengthException = new DrinkPriceToolExceptions.ArgumentArraysOfUnevenLengthException();
        this.argumentArraysOfUnevenLengthException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_editDrinksByDrinkIdForAuthenticatedUserSession_result.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_editDrinksByDrinkIdForAuthenticatedUserSession_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownDrinkIdException !== null && this.unknownDrinkIdException !== undefined) {
    output.writeFieldBegin('unknownDrinkIdException', Thrift.Type.STRUCT, 4);
    this.unknownDrinkIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.emptyArgumentException !== null && this.emptyArgumentException !== undefined) {
    output.writeFieldBegin('emptyArgumentException', Thrift.Type.STRUCT, 5);
    this.emptyArgumentException.write(output);
    output.writeFieldEnd();
  }
  if (this.argumentArraysOfUnevenLengthException !== null && this.argumentArraysOfUnevenLengthException !== undefined) {
    output.writeFieldBegin('argumentArraysOfUnevenLengthException', Thrift.Type.STRUCT, 6);
    this.argumentArraysOfUnevenLengthException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_deleteDrinksByDrinkIdForAuthenticatedUserSession_args = function(args) {
  this.authenticatedUserSessionId = null;
  this.drinkIds = null;
  if (args) {
    if (args.authenticatedUserSessionId !== undefined && args.authenticatedUserSessionId !== null) {
      this.authenticatedUserSessionId = new UserAccountsModel.UserSessionIdentifier(args.authenticatedUserSessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field authenticatedUserSessionId is unset!');
    }
    if (args.drinkIds !== undefined && args.drinkIds !== null) {
      this.drinkIds = Thrift.copyList(args.drinkIds, [DrinkPriceToolModel.DrinkId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field drinkIds is unset!');
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_deleteDrinksByDrinkIdForAuthenticatedUserSession_args.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_deleteDrinksByDrinkIdForAuthenticatedUserSession_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticatedUserSessionId = new UserAccountsModel.UserSessionIdentifier();
        this.authenticatedUserSessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.SET) {
        var _size120 = 0;
        var _rtmp3124;
        this.drinkIds = [];
        var _etype123 = 0;
        _rtmp3124 = input.readSetBegin();
        _etype123 = _rtmp3124.etype;
        _size120 = _rtmp3124.size;
        for (var _i125 = 0; _i125 < _size120; ++_i125)
        {
          var elem126 = null;
          elem126 = new DrinkPriceToolModel.DrinkId();
          elem126.read(input);
          this.drinkIds.push(elem126);
        }
        input.readSetEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_deleteDrinksByDrinkIdForAuthenticatedUserSession_args.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_deleteDrinksByDrinkIdForAuthenticatedUserSession_args');
  if (this.authenticatedUserSessionId !== null && this.authenticatedUserSessionId !== undefined) {
    output.writeFieldBegin('authenticatedUserSessionId', Thrift.Type.STRUCT, 1);
    this.authenticatedUserSessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.drinkIds !== null && this.drinkIds !== undefined) {
    output.writeFieldBegin('drinkIds', Thrift.Type.SET, 2);
    output.writeSetBegin(Thrift.Type.STRUCT, this.drinkIds.length);
    for (var iter127 in this.drinkIds)
    {
      if (this.drinkIds.hasOwnProperty(iter127))
      {
        iter127 = this.drinkIds[iter127];
        iter127.write(output);
      }
    }
    output.writeSetEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_deleteDrinksByDrinkIdForAuthenticatedUserSession_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownActorException = null;
  this.unknownDrinkIdException = null;
  this.emptyArgumentException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof DrinkPriceToolExceptions.UnknownDrinkIdException) {
    this.unknownDrinkIdException = args;
    return;
  }
  if (args instanceof DrinkPriceToolExceptions.EmptyArgumentException) {
    this.emptyArgumentException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.unknownDrinkIdException !== undefined && args.unknownDrinkIdException !== null) {
      this.unknownDrinkIdException = args.unknownDrinkIdException;
    }
    if (args.emptyArgumentException !== undefined && args.emptyArgumentException !== null) {
      this.emptyArgumentException = args.emptyArgumentException;
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_deleteDrinksByDrinkIdForAuthenticatedUserSession_result.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_deleteDrinksByDrinkIdForAuthenticatedUserSession_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownDrinkIdException = new DrinkPriceToolExceptions.UnknownDrinkIdException();
        this.unknownDrinkIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.emptyArgumentException = new DrinkPriceToolExceptions.EmptyArgumentException();
        this.emptyArgumentException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_deleteDrinksByDrinkIdForAuthenticatedUserSession_result.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_deleteDrinksByDrinkIdForAuthenticatedUserSession_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownDrinkIdException !== null && this.unknownDrinkIdException !== undefined) {
    output.writeFieldBegin('unknownDrinkIdException', Thrift.Type.STRUCT, 4);
    this.unknownDrinkIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.emptyArgumentException !== null && this.emptyArgumentException !== undefined) {
    output.writeFieldBegin('emptyArgumentException', Thrift.Type.STRUCT, 5);
    this.emptyArgumentException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsToImport_args = function(args) {
  this.authenticatedUserSessionId = null;
  if (args) {
    if (args.authenticatedUserSessionId !== undefined && args.authenticatedUserSessionId !== null) {
      this.authenticatedUserSessionId = new UserAccountsModel.UserSessionIdentifier(args.authenticatedUserSessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field authenticatedUserSessionId is unset!');
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsToImport_args.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsToImport_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticatedUserSessionId = new UserAccountsModel.UserSessionIdentifier();
        this.authenticatedUserSessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsToImport_args.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_getDrinkIdsToImport_args');
  if (this.authenticatedUserSessionId !== null && this.authenticatedUserSessionId !== undefined) {
    output.writeFieldBegin('authenticatedUserSessionId', Thrift.Type.STRUCT, 1);
    this.authenticatedUserSessionId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsToImport_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownActorException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [DrinkPriceToolModel.DrinkIdAndDrink]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsToImport_result.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsToImport_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size128 = 0;
        var _rtmp3132;
        this.success = [];
        var _etype131 = 0;
        _rtmp3132 = input.readListBegin();
        _etype131 = _rtmp3132.etype;
        _size128 = _rtmp3132.size;
        for (var _i133 = 0; _i133 < _size128; ++_i133)
        {
          var elem134 = null;
          elem134 = new DrinkPriceToolModel.DrinkIdAndDrink();
          elem134.read(input);
          this.success.push(elem134);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsToImport_result.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_getDrinkIdsToImport_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter135 in this.success)
    {
      if (this.success.hasOwnProperty(iter135))
      {
        iter135 = this.success[iter135];
        iter135.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_importDrinkIds_args = function(args) {
  this.authenticatedUserSessionId = null;
  this.drinkIds = null;
  if (args) {
    if (args.authenticatedUserSessionId !== undefined && args.authenticatedUserSessionId !== null) {
      this.authenticatedUserSessionId = new UserAccountsModel.UserSessionIdentifier(args.authenticatedUserSessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field authenticatedUserSessionId is unset!');
    }
    if (args.drinkIds !== undefined && args.drinkIds !== null) {
      this.drinkIds = Thrift.copyList(args.drinkIds, [DrinkPriceToolModel.DrinkId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field drinkIds is unset!');
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_importDrinkIds_args.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_importDrinkIds_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.authenticatedUserSessionId = new UserAccountsModel.UserSessionIdentifier();
        this.authenticatedUserSessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.SET) {
        var _size136 = 0;
        var _rtmp3140;
        this.drinkIds = [];
        var _etype139 = 0;
        _rtmp3140 = input.readSetBegin();
        _etype139 = _rtmp3140.etype;
        _size136 = _rtmp3140.size;
        for (var _i141 = 0; _i141 < _size136; ++_i141)
        {
          var elem142 = null;
          elem142 = new DrinkPriceToolModel.DrinkId();
          elem142.read(input);
          this.drinkIds.push(elem142);
        }
        input.readSetEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_importDrinkIds_args.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_importDrinkIds_args');
  if (this.authenticatedUserSessionId !== null && this.authenticatedUserSessionId !== undefined) {
    output.writeFieldBegin('authenticatedUserSessionId', Thrift.Type.STRUCT, 1);
    this.authenticatedUserSessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.drinkIds !== null && this.drinkIds !== undefined) {
    output.writeFieldBegin('drinkIds', Thrift.Type.SET, 2);
    output.writeSetBegin(Thrift.Type.STRUCT, this.drinkIds.length);
    for (var iter143 in this.drinkIds)
    {
      if (this.drinkIds.hasOwnProperty(iter143))
      {
        iter143 = this.drinkIds[iter143];
        iter143.write(output);
      }
    }
    output.writeSetEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_importDrinkIds_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownActorException = null;
  this.unknownDrinkIdException = null;
  this.emptyArgumentException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof DrinkPriceToolExceptions.UnknownDrinkIdException) {
    this.unknownDrinkIdException = args;
    return;
  }
  if (args instanceof DrinkPriceToolExceptions.EmptyArgumentException) {
    this.emptyArgumentException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.unknownDrinkIdException !== undefined && args.unknownDrinkIdException !== null) {
      this.unknownDrinkIdException = args.unknownDrinkIdException;
    }
    if (args.emptyArgumentException !== undefined && args.emptyArgumentException !== null) {
      this.emptyArgumentException = args.emptyArgumentException;
    }
  }
};
DrinkPriceToolService.DrinkPriceToolService_importDrinkIds_result.prototype = {};
DrinkPriceToolService.DrinkPriceToolService_importDrinkIds_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownDrinkIdException = new DrinkPriceToolExceptions.UnknownDrinkIdException();
        this.unknownDrinkIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.emptyArgumentException = new DrinkPriceToolExceptions.EmptyArgumentException();
        this.emptyArgumentException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolService_importDrinkIds_result.prototype.write = function(output) {
  output.writeStructBegin('DrinkPriceToolService_importDrinkIds_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownDrinkIdException !== null && this.unknownDrinkIdException !== undefined) {
    output.writeFieldBegin('unknownDrinkIdException', Thrift.Type.STRUCT, 4);
    this.unknownDrinkIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.emptyArgumentException !== null && this.emptyArgumentException !== undefined) {
    output.writeFieldBegin('emptyArgumentException', Thrift.Type.STRUCT, 5);
    this.emptyArgumentException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolService.DrinkPriceToolServiceClient = function(input, output) {
    this.input = input;
    this.output = (!output) ? input : output;
    this.seqid = 0;
};
DrinkPriceToolService.DrinkPriceToolServiceClient.prototype = {};
DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.recordPageViewAction = function(unauthenticatedUserSessionId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_recordPageViewAction(pendingError, unauthenticatedUserSessionId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_recordPageViewAction();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.send_recordPageViewAction = function(pendingError, unauthenticatedUserSessionId, callback) {
  this.output.writeMessageBegin('recordPageViewAction', Thrift.MessageType.CALL, this.seqid);
  var args = new DrinkPriceToolService.DrinkPriceToolService_recordPageViewAction_args();
  args.unauthenticatedUserSessionId = unauthenticatedUserSessionId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_recordPageViewAction() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.recv_recordPageViewAction = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DrinkPriceToolService.DrinkPriceToolService_recordPageViewAction_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownUnauthenticatedUserSessionIdException) {
    throw result.unknownUnauthenticatedUserSessionIdException;
  }
  return;
};
DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.recordShareAction = function(unauthenticatedUserSessionId, shareMedium, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_recordShareAction(pendingError, unauthenticatedUserSessionId, shareMedium, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_recordShareAction();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.send_recordShareAction = function(pendingError, unauthenticatedUserSessionId, shareMedium, callback) {
  this.output.writeMessageBegin('recordShareAction', Thrift.MessageType.CALL, this.seqid);
  var args = new DrinkPriceToolService.DrinkPriceToolService_recordShareAction_args();
  args.unauthenticatedUserSessionId = unauthenticatedUserSessionId;
  args.shareMedium = shareMedium;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_recordShareAction() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.recv_recordShareAction = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DrinkPriceToolService.DrinkPriceToolService_recordShareAction_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownUnauthenticatedUserSessionIdException) {
    throw result.unknownUnauthenticatedUserSessionIdException;
  }
  return;
};
DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.getDrinkIdsForUnauthenticatedUserSession = function(unauthenticatedUserSessionId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getDrinkIdsForUnauthenticatedUserSession(pendingError, unauthenticatedUserSessionId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getDrinkIdsForUnauthenticatedUserSession();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.send_getDrinkIdsForUnauthenticatedUserSession = function(pendingError, unauthenticatedUserSessionId, callback) {
  this.output.writeMessageBegin('getDrinkIdsForUnauthenticatedUserSession', Thrift.MessageType.CALL, this.seqid);
  var args = new DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsForUnauthenticatedUserSession_args();
  args.unauthenticatedUserSessionId = unauthenticatedUserSessionId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getDrinkIdsForUnauthenticatedUserSession() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.recv_getDrinkIdsForUnauthenticatedUserSession = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsForUnauthenticatedUserSession_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownUnauthenticatedUserSessionIdException) {
    throw result.unknownUnauthenticatedUserSessionIdException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getDrinkIdsForUnauthenticatedUserSession failed: unknown result';
};
DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.getDrinksByDrinkIdsForUnauthenticatedUserSession = function(unauthenticatedUserSessionId, drinkIds, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getDrinksByDrinkIdsForUnauthenticatedUserSession(pendingError, unauthenticatedUserSessionId, drinkIds, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getDrinksByDrinkIdsForUnauthenticatedUserSession();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.send_getDrinksByDrinkIdsForUnauthenticatedUserSession = function(pendingError, unauthenticatedUserSessionId, drinkIds, callback) {
  this.output.writeMessageBegin('getDrinksByDrinkIdsForUnauthenticatedUserSession', Thrift.MessageType.CALL, this.seqid);
  var args = new DrinkPriceToolService.DrinkPriceToolService_getDrinksByDrinkIdsForUnauthenticatedUserSession_args();
  args.unauthenticatedUserSessionId = unauthenticatedUserSessionId;
  args.drinkIds = drinkIds;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getDrinksByDrinkIdsForUnauthenticatedUserSession() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.recv_getDrinksByDrinkIdsForUnauthenticatedUserSession = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DrinkPriceToolService.DrinkPriceToolService_getDrinksByDrinkIdsForUnauthenticatedUserSession_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownUnauthenticatedUserSessionIdException) {
    throw result.unknownUnauthenticatedUserSessionIdException;
  }
  if (null !== result.unknownDrinkIdException) {
    throw result.unknownDrinkIdException;
  }
  if (null !== result.emptyArgumentException) {
    throw result.emptyArgumentException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getDrinksByDrinkIdsForUnauthenticatedUserSession failed: unknown result';
};
DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.createDrinksForUnauthenticatedUserSession = function(unauthenticatedUserSessionId, drinks, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_createDrinksForUnauthenticatedUserSession(pendingError, unauthenticatedUserSessionId, drinks, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_createDrinksForUnauthenticatedUserSession();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.send_createDrinksForUnauthenticatedUserSession = function(pendingError, unauthenticatedUserSessionId, drinks, callback) {
  this.output.writeMessageBegin('createDrinksForUnauthenticatedUserSession', Thrift.MessageType.CALL, this.seqid);
  var args = new DrinkPriceToolService.DrinkPriceToolService_createDrinksForUnauthenticatedUserSession_args();
  args.unauthenticatedUserSessionId = unauthenticatedUserSessionId;
  args.drinks = drinks;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_createDrinksForUnauthenticatedUserSession() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.recv_createDrinksForUnauthenticatedUserSession = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DrinkPriceToolService.DrinkPriceToolService_createDrinksForUnauthenticatedUserSession_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownUnauthenticatedUserSessionIdException) {
    throw result.unknownUnauthenticatedUserSessionIdException;
  }
  if (null !== result.emptyArgumentException) {
    throw result.emptyArgumentException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'createDrinksForUnauthenticatedUserSession failed: unknown result';
};
DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.editDrinksByDrinkIdForUnauthenticatedUserSession = function(unauthenticatedUserSessionId, drinkIds, drinks, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_editDrinksByDrinkIdForUnauthenticatedUserSession(pendingError, unauthenticatedUserSessionId, drinkIds, drinks, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_editDrinksByDrinkIdForUnauthenticatedUserSession();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.send_editDrinksByDrinkIdForUnauthenticatedUserSession = function(pendingError, unauthenticatedUserSessionId, drinkIds, drinks, callback) {
  this.output.writeMessageBegin('editDrinksByDrinkIdForUnauthenticatedUserSession', Thrift.MessageType.CALL, this.seqid);
  var args = new DrinkPriceToolService.DrinkPriceToolService_editDrinksByDrinkIdForUnauthenticatedUserSession_args();
  args.unauthenticatedUserSessionId = unauthenticatedUserSessionId;
  args.drinkIds = drinkIds;
  args.drinks = drinks;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_editDrinksByDrinkIdForUnauthenticatedUserSession() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.recv_editDrinksByDrinkIdForUnauthenticatedUserSession = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DrinkPriceToolService.DrinkPriceToolService_editDrinksByDrinkIdForUnauthenticatedUserSession_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownUnauthenticatedUserSessionIdException) {
    throw result.unknownUnauthenticatedUserSessionIdException;
  }
  if (null !== result.unknownDrinkIdException) {
    throw result.unknownDrinkIdException;
  }
  if (null !== result.emptyArgumentException) {
    throw result.emptyArgumentException;
  }
  if (null !== result.argumentArraysOfUnevenLengthException) {
    throw result.argumentArraysOfUnevenLengthException;
  }
  return;
};
DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.deleteDrinksByDrinkIdForUnauthenticatedUserSession = function(unauthenticatedUserSessionId, drinkIds, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_deleteDrinksByDrinkIdForUnauthenticatedUserSession(pendingError, unauthenticatedUserSessionId, drinkIds, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_deleteDrinksByDrinkIdForUnauthenticatedUserSession();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.send_deleteDrinksByDrinkIdForUnauthenticatedUserSession = function(pendingError, unauthenticatedUserSessionId, drinkIds, callback) {
  this.output.writeMessageBegin('deleteDrinksByDrinkIdForUnauthenticatedUserSession', Thrift.MessageType.CALL, this.seqid);
  var args = new DrinkPriceToolService.DrinkPriceToolService_deleteDrinksByDrinkIdForUnauthenticatedUserSession_args();
  args.unauthenticatedUserSessionId = unauthenticatedUserSessionId;
  args.drinkIds = drinkIds;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_deleteDrinksByDrinkIdForUnauthenticatedUserSession() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.recv_deleteDrinksByDrinkIdForUnauthenticatedUserSession = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DrinkPriceToolService.DrinkPriceToolService_deleteDrinksByDrinkIdForUnauthenticatedUserSession_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownUnauthenticatedUserSessionIdException) {
    throw result.unknownUnauthenticatedUserSessionIdException;
  }
  if (null !== result.unknownDrinkIdException) {
    throw result.unknownDrinkIdException;
  }
  if (null !== result.emptyArgumentException) {
    throw result.emptyArgumentException;
  }
  return;
};
DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.getDrinkIdsForAuthenticatedUserSession = function(authenticatedUserSessionId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getDrinkIdsForAuthenticatedUserSession(pendingError, authenticatedUserSessionId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getDrinkIdsForAuthenticatedUserSession();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.send_getDrinkIdsForAuthenticatedUserSession = function(pendingError, authenticatedUserSessionId, callback) {
  this.output.writeMessageBegin('getDrinkIdsForAuthenticatedUserSession', Thrift.MessageType.CALL, this.seqid);
  var args = new DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsForAuthenticatedUserSession_args();
  args.authenticatedUserSessionId = authenticatedUserSessionId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getDrinkIdsForAuthenticatedUserSession() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.recv_getDrinkIdsForAuthenticatedUserSession = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsForAuthenticatedUserSession_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getDrinkIdsForAuthenticatedUserSession failed: unknown result';
};
DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.getDrinksByDrinkIdsForAuthenticatedUserSession = function(authenticatedUserSessionId, drinkIds, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getDrinksByDrinkIdsForAuthenticatedUserSession(pendingError, authenticatedUserSessionId, drinkIds, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getDrinksByDrinkIdsForAuthenticatedUserSession();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.send_getDrinksByDrinkIdsForAuthenticatedUserSession = function(pendingError, authenticatedUserSessionId, drinkIds, callback) {
  this.output.writeMessageBegin('getDrinksByDrinkIdsForAuthenticatedUserSession', Thrift.MessageType.CALL, this.seqid);
  var args = new DrinkPriceToolService.DrinkPriceToolService_getDrinksByDrinkIdsForAuthenticatedUserSession_args();
  args.authenticatedUserSessionId = authenticatedUserSessionId;
  args.drinkIds = drinkIds;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getDrinksByDrinkIdsForAuthenticatedUserSession() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.recv_getDrinksByDrinkIdsForAuthenticatedUserSession = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DrinkPriceToolService.DrinkPriceToolService_getDrinksByDrinkIdsForAuthenticatedUserSession_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.unknownDrinkIdException) {
    throw result.unknownDrinkIdException;
  }
  if (null !== result.emptyArgumentException) {
    throw result.emptyArgumentException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getDrinksByDrinkIdsForAuthenticatedUserSession failed: unknown result';
};
DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.createDrinksForAuthenticatedUserSession = function(authenticatedUserSessionId, drinks, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_createDrinksForAuthenticatedUserSession(pendingError, authenticatedUserSessionId, drinks, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_createDrinksForAuthenticatedUserSession();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.send_createDrinksForAuthenticatedUserSession = function(pendingError, authenticatedUserSessionId, drinks, callback) {
  this.output.writeMessageBegin('createDrinksForAuthenticatedUserSession', Thrift.MessageType.CALL, this.seqid);
  var args = new DrinkPriceToolService.DrinkPriceToolService_createDrinksForAuthenticatedUserSession_args();
  args.authenticatedUserSessionId = authenticatedUserSessionId;
  args.drinks = drinks;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_createDrinksForAuthenticatedUserSession() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.recv_createDrinksForAuthenticatedUserSession = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DrinkPriceToolService.DrinkPriceToolService_createDrinksForAuthenticatedUserSession_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.emptyArgumentException) {
    throw result.emptyArgumentException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'createDrinksForAuthenticatedUserSession failed: unknown result';
};
DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.editDrinksByDrinkIdForAuthenticatedUserSession = function(authenticatedUserSessionId, drinkIds, drinks, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_editDrinksByDrinkIdForAuthenticatedUserSession(pendingError, authenticatedUserSessionId, drinkIds, drinks, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_editDrinksByDrinkIdForAuthenticatedUserSession();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.send_editDrinksByDrinkIdForAuthenticatedUserSession = function(pendingError, authenticatedUserSessionId, drinkIds, drinks, callback) {
  this.output.writeMessageBegin('editDrinksByDrinkIdForAuthenticatedUserSession', Thrift.MessageType.CALL, this.seqid);
  var args = new DrinkPriceToolService.DrinkPriceToolService_editDrinksByDrinkIdForAuthenticatedUserSession_args();
  args.authenticatedUserSessionId = authenticatedUserSessionId;
  args.drinkIds = drinkIds;
  args.drinks = drinks;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_editDrinksByDrinkIdForAuthenticatedUserSession() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.recv_editDrinksByDrinkIdForAuthenticatedUserSession = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DrinkPriceToolService.DrinkPriceToolService_editDrinksByDrinkIdForAuthenticatedUserSession_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.unknownDrinkIdException) {
    throw result.unknownDrinkIdException;
  }
  if (null !== result.emptyArgumentException) {
    throw result.emptyArgumentException;
  }
  if (null !== result.argumentArraysOfUnevenLengthException) {
    throw result.argumentArraysOfUnevenLengthException;
  }
  return;
};
DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.deleteDrinksByDrinkIdForAuthenticatedUserSession = function(authenticatedUserSessionId, drinkIds, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_deleteDrinksByDrinkIdForAuthenticatedUserSession(pendingError, authenticatedUserSessionId, drinkIds, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_deleteDrinksByDrinkIdForAuthenticatedUserSession();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.send_deleteDrinksByDrinkIdForAuthenticatedUserSession = function(pendingError, authenticatedUserSessionId, drinkIds, callback) {
  this.output.writeMessageBegin('deleteDrinksByDrinkIdForAuthenticatedUserSession', Thrift.MessageType.CALL, this.seqid);
  var args = new DrinkPriceToolService.DrinkPriceToolService_deleteDrinksByDrinkIdForAuthenticatedUserSession_args();
  args.authenticatedUserSessionId = authenticatedUserSessionId;
  args.drinkIds = drinkIds;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_deleteDrinksByDrinkIdForAuthenticatedUserSession() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.recv_deleteDrinksByDrinkIdForAuthenticatedUserSession = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DrinkPriceToolService.DrinkPriceToolService_deleteDrinksByDrinkIdForAuthenticatedUserSession_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.unknownDrinkIdException) {
    throw result.unknownDrinkIdException;
  }
  if (null !== result.emptyArgumentException) {
    throw result.emptyArgumentException;
  }
  return;
};
DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.getDrinkIdsToImport = function(authenticatedUserSessionId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getDrinkIdsToImport(pendingError, authenticatedUserSessionId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getDrinkIdsToImport();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.send_getDrinkIdsToImport = function(pendingError, authenticatedUserSessionId, callback) {
  this.output.writeMessageBegin('getDrinkIdsToImport', Thrift.MessageType.CALL, this.seqid);
  var args = new DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsToImport_args();
  args.authenticatedUserSessionId = authenticatedUserSessionId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getDrinkIdsToImport() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.recv_getDrinkIdsToImport = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DrinkPriceToolService.DrinkPriceToolService_getDrinkIdsToImport_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getDrinkIdsToImport failed: unknown result';
};
DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.importDrinkIds = function(authenticatedUserSessionId, drinkIds, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_importDrinkIds(pendingError, authenticatedUserSessionId, drinkIds, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_importDrinkIds();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.send_importDrinkIds = function(pendingError, authenticatedUserSessionId, drinkIds, callback) {
  this.output.writeMessageBegin('importDrinkIds', Thrift.MessageType.CALL, this.seqid);
  var args = new DrinkPriceToolService.DrinkPriceToolService_importDrinkIds_args();
  args.authenticatedUserSessionId = authenticatedUserSessionId;
  args.drinkIds = drinkIds;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_importDrinkIds() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DrinkPriceToolService.DrinkPriceToolServiceClient.prototype.recv_importDrinkIds = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DrinkPriceToolService.DrinkPriceToolService_importDrinkIds_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.unknownDrinkIdException) {
    throw result.unknownDrinkIdException;
  }
  if (null !== result.emptyArgumentException) {
    throw result.emptyArgumentException;
  }
  return;
};
