
export interface IJSONAddress {
    line1? : string;
    line2? : string;
    city? : string;
    state? : string;
    zipcode? : string;
}

export class Address {

    public static createFromJSONObject(JSONObject : IJSONAddress) : Address {
        return new Address(
            Address.undefToBlank(JSONObject.line1),
            Address.undefToBlank(JSONObject.line2),
            Address.undefToBlank(JSONObject.city),
            Address.undefToBlank(JSONObject.state),
            Address.undefToBlank(JSONObject.zipcode),
        );
    }

    public static copy(other : Address) : Address {
        return new Address(
            other.line1,
            other.line2,
            other.city,
            other.state,
            other.zipcode,
        );
    }

    private static undefToBlank(value : string | undefined) : string {
        if (typeof value === 'undefined') { return ''; }
        return value;
    }

    constructor (
        public line1 : string,
        public line2 : string,
        public city : string,
        public state : string,
        public zipcode : string,
    ) {}
}
