import React from 'react';

import { AxisPosition } from 'shared/models/Charts/Axes/AxisPosition';
import { IAxisLine } from 'shared/models/Charts/Axes/IAxisLine';
import { IAxisTick } from 'shared/models/Charts/Axes/IAxisTick';
import { IBoundingBox } from 'shared/models/Charts/IBoundingBox';
import { TextPosition } from 'shared/models/Charts/TextPosition';

import { AxisTick } from 'shared/components/Charts/Axes/AxisTick';

import { utils } from 'shared/components/Charts/utils';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import {
    axisLabelDistanceFromEdgeInPixels,
    axisLineColor,
    axisLineWidthInPixels,
    axisTickLabelHeightInPixels,
} from 'shared/components/Charts/Axes/constants';

export interface IAxisProps {
    readonly axisPosition : AxisPosition;
    readonly axisTicks : ReadonlyArray<IAxisTick>;
    readonly labelValue : string;
    readonly axisLine : IAxisLine | null;
    readonly boundingBox : IBoundingBox;
}

export class Axis extends React.Component<IAxisProps, object> {
    private classNamePrefix : string = '';
    private halfAxisTickLength : number = 0;

    public render() {
        const {
            boundingBox,
        } = this.props;

        utils.ensureBoundingBoxIsValid(boundingBox);

        this.determineClassNamePrefix();
        this.determineHalfAxisTickLength();

        return (
            <g
                className={ this.classNamePrefix }
            >
                { this.createLine() }
                { this.createTicks() }
                { this.createLabel() }
            </g>
        );
    }

    private determineClassNamePrefix = () : void => {
        const {
            axisPosition,
        } = this.props;

        switch (axisPosition) {
            case AxisPosition.TOP:
            case AxisPosition.BOTTOM: {
                this.classNamePrefix = 'x-axis';
                break;
            }
            case AxisPosition.LEFT:
            case AxisPosition.RIGHT: {
                this.classNamePrefix = 'y-axis';
                break;
            }
            default: {
                throw new RuntimeException('axisPosition is unexpected value');
            }
        }
    }

    private determineHalfAxisTickLength = () : void => {
        const {
            axisPosition,
            axisTicks,
            boundingBox,
        } = this.props;

        const numberOfAxisTicks = axisTicks.length;

        switch (axisPosition) {
            case AxisPosition.TOP:
            case AxisPosition.BOTTOM: {
                this.halfAxisTickLength = boundingBox.widthInPixels / numberOfAxisTicks / 2;
                break;
            }
            case AxisPosition.LEFT:
            case AxisPosition.RIGHT: {
                this.halfAxisTickLength = boundingBox.heightInPixels / numberOfAxisTicks / 2;
                break;
            }
            default: {
                throw new RuntimeException('axisPosition is unexpected value');
            }
        }
    }

    private createLine = () : JSX.Element | void => {
        const {
            axisPosition,
            axisLine,
            boundingBox,
        } = this.props;

        if (axisLine === null) {
            return;
        }

        const className : string = this.classNamePrefix + '-line';

        let x1 : number;
        let x2 : number;
        let y1 : number;
        let y2 : number;
        switch (axisPosition) {
            case AxisPosition.BOTTOM: {
                x1 = axisLine.startsAtTick ? this.halfAxisTickLength : 0;
                x2 = boundingBox.widthInPixels - (axisLine.endsAtTick ? this.halfAxisTickLength : 0);

                y1 = y2 = 0;

                break;
            }
            case AxisPosition.TOP: {
                x1 = axisLine.startsAtTick ? this.halfAxisTickLength : 0;
                x2 = boundingBox.widthInPixels - (axisLine.endsAtTick ? this.halfAxisTickLength : 0);

                y1 = y2 = boundingBox.heightInPixels;

                break;
            }
            case AxisPosition.LEFT: {
                x1 = x2 = boundingBox.widthInPixels;

                y1 = axisLine.endsAtTick ? this.halfAxisTickLength : 0;
                y2 = boundingBox.heightInPixels - (axisLine.startsAtTick ? this.halfAxisTickLength : 0);

                break;
            }
            case AxisPosition.RIGHT: {
                x1 = x2 = 0;

                y1 = axisLine.endsAtTick ? this.halfAxisTickLength : 0;
                y2 = boundingBox.heightInPixels - (axisLine.startsAtTick ? this.halfAxisTickLength : 0);

                break;
            }
            default: {
                throw new RuntimeException('axisPosition is unexpected value');
            }
        }

        return (
            <line
                className={ className }
                stroke={ axisLineColor.getValue() }
                strokeWidth={ axisLineWidthInPixels }

                x1={ x1 }
                y1={ y1 }
                x2={ x2 }
                y2={ y2 }
            />
        );
    }

    private createTicks = () : ReadonlyArray<JSX.Element> | void => {
        const {
            axisPosition,
            axisTicks,
            boundingBox,
        } = this.props;

        const numberOfAxisTicks = axisTicks.length;

        if (numberOfAxisTicks === 0) {
            return;
        }

        const className : string = this.classNamePrefix + '-tick-container';

        let axisTickComponentHeightInPixels : number;
        let axisTickComponentWidthInPixels : number;
        switch (axisPosition) {
            case AxisPosition.TOP:
            case AxisPosition.BOTTOM: {
                axisTickComponentHeightInPixels = boundingBox.heightInPixels;
                axisTickComponentWidthInPixels = boundingBox.widthInPixels / numberOfAxisTicks;

                break;
            }
            case AxisPosition.LEFT:
            case AxisPosition.RIGHT: {
                axisTickComponentHeightInPixels = boundingBox.heightInPixels / numberOfAxisTicks;
                axisTickComponentWidthInPixels = boundingBox.widthInPixels;

                break;
            }
            default: {
                throw new RuntimeException('axisPosition is unexpected value');
            }
        }

        return axisTicks.map((axisTick : IAxisTick, index : number) => {
            let x : number;
            let y : number;
            switch (axisPosition) {
                case AxisPosition.TOP:
                case AxisPosition.BOTTOM: {
                    x = index * axisTickComponentWidthInPixels;
                    y = 0;

                    break;
                }
                case AxisPosition.LEFT:
                case AxisPosition.RIGHT: {
                    x = 0;
                    y = boundingBox.heightInPixels - ((index + 1) * axisTickComponentHeightInPixels);

                    break;
                }
                default: {
                    throw new RuntimeException('axisPosition is unexpected value');
                }
            }

            return (
                <svg
                    key={ index }
                    className={ className }

                    height={ axisTickComponentHeightInPixels }
                    width={ axisTickComponentWidthInPixels }
                    x={ x }
                    y={ y }
                >
                    <AxisTick
                        axisPosition={ axisPosition }
                        showTickLine={ axisTick.showTickLine }
                        labelValue={ axisTick.labelValue }
                        boundingBox={ {
                            heightInPixels: axisTickComponentHeightInPixels,
                            widthInPixels: axisTickComponentWidthInPixels,
                        } }
                    />
                </svg>
            );
        });
    }

    private createLabel = () : JSX.Element | void => {
        const {
            axisPosition,
            labelValue,
            boundingBox,
        } = this.props;

        if (labelValue.length === 0) {
            return;
        }

        const className : string = this.classNamePrefix + '-label';
        const textPosition : TextPosition = 'middle';

        let x : number;
        let y : number;
        let degreesToRotate : number;
        switch (axisPosition) {
            case AxisPosition.BOTTOM: {
                x = boundingBox.widthInPixels / 2;
                y = boundingBox.heightInPixels - axisLabelDistanceFromEdgeInPixels;
                degreesToRotate = 0;

                break;
            }
            case AxisPosition.TOP: {
                x = boundingBox.widthInPixels / 2;
                y = axisLabelDistanceFromEdgeInPixels + axisTickLabelHeightInPixels;
                degreesToRotate = 0;

                break;
            }
            case AxisPosition.LEFT: {
                x = axisLabelDistanceFromEdgeInPixels + axisTickLabelHeightInPixels;
                y = boundingBox.heightInPixels / 2;
                degreesToRotate = -90;

                break;
            }
            case AxisPosition.RIGHT: {
                x = boundingBox.widthInPixels - axisLabelDistanceFromEdgeInPixels;
                y = boundingBox.heightInPixels / 2;
                degreesToRotate = -90;

                break;
            }
            default: {
                throw new RuntimeException('axisPosition is unexpected value');
            }
        }

        let transform : string = utils.getTranslationTransformation(x, y);
        if (degreesToRotate !== 0) {
            transform += 'rotate(' + degreesToRotate + ', 0, 0)';
        }

        return (
            <text
                className={ className }
                textAnchor={ textPosition }
                transform={ transform }
            >
                { labelValue }
            </text>
        );
    }
}
