import { AjaxUtils } from 'shared/utils/ajaxUtils';

export const HubspotChatUtils = {

    async openChatWidget() {
        const queryParameters = {
            user_id: window.GLOBAL_USER_ID
        };

        try {
            const response = await AjaxUtils.ajaxPost(urlWithoutRetailerId('api:hubspot_chat_token'), {}, queryParameters);
            window.hsConversationsSettings = {
                ...window.hsConversationsSettings,
                identificationEmail: window.GLOBAL_USER_EMAIL,
                identificationToken: response.token
            };
        } catch (error) {
            // Do nothing. It's OK if there's an error. The worst that happens is we don't send HubSpot a chat token,
            // which means that support doesn't automatically see who they're talking to.
        }

        window.HubSpotConversations.widget.load({ widgetOpen: true });
    }
};
