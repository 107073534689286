import { StringValueMap } from 'api/Core/StringValueMap';
import { LocationId } from 'api/Location/model/LocationId';
import { AjaxUtils } from 'shared/utils/ajaxUtils';
import { ISalesSettingsService } from '../interfaces/ISalesSettingsService';
import { PosItemId } from '../model/PosItemId';

export class SalesSettingsServiceImpl implements ISalesSettingsService {
    public getOmittedNamesByPosId(
        locationId : LocationId,
    ) : Promise<StringValueMap<PosItemId, Set<string>>> {
        return AjaxUtils.ajaxGet(url('sales:reports:item_level:get_omitted_names_by_pos_id', null, locationId.getValue(), null))
        .then((response : {data: {[key : string] : Set<string>}}) => {
            const omittedNamesByPosId = new StringValueMap<PosItemId, Set<string>>();
            Object.keys(response.data).forEach((posIdValue) =>  {
                omittedNamesByPosId.set(new PosItemId(posIdValue), response.data[posIdValue]);
            });

            return omittedNamesByPosId;
        });
    }

    public updateOmittedNamesByPosId(
        locationId : LocationId,
        newShouldOmitByNameAndPosId : StringValueMap<PosItemId, Record<string, boolean>>
    ) : Promise<void> {

        const shouldOmitDict : Record<string, Record<string, boolean>> = {};
        newShouldOmitByNameAndPosId.forEach((shouldOmitByName, posId) => {
            shouldOmitDict[posId.getValue()] = shouldOmitByName;
        });

        const requestBody = {
            should_omit_by_name_and_pos_id: shouldOmitDict
        };

        return AjaxUtils.ajaxPut(
            url('sales:reports:item_level:update_omitted_names_by_pos_id', null, locationId.getValue(), null),
            requestBody
        );
    }

    public getShouldIncludeLossInVariance(
        locationId : LocationId,
    ) : Promise<boolean> {
        return AjaxUtils.ajaxGet(url('sales:reports:item_level:get_should_include_losses_in_variance', null, locationId.getValue(), null))
        .then((response : {should_include_losses_in_variance : boolean}) => {
            return response.should_include_losses_in_variance;
        });
    }

    public updateShouldIncludeLossInVariance(
        locationId : LocationId,
        shouldIncludeLossInVariance : boolean
    ) : Promise<void> {
        const requestBody = {
            should_include_losses_in_variance: shouldIncludeLossInVariance
        };

        return AjaxUtils.ajaxPut(
            url('sales:reports:item_level:update_should_include_losses_in_variance', null, locationId.getValue(), null),
            requestBody
        );
    }

    public getShouldUseGrossSalesForTotalSales(
        locationId : LocationId,
    ) : Promise<boolean> {
        return AjaxUtils.ajaxGet(url('sales:reports:item_level:get_should_use_gross_sales_for_total_sales', null, locationId.getValue(), null))
        .then((response : {should_use_gross_sales_for_total_sales : boolean}) => {
            return response.should_use_gross_sales_for_total_sales;
        });
    }

    public updateShouldUseGrossSalesForTotalSales(
        locationId : LocationId,
        shouldUseGrossSalesForTotalSales : boolean
    ) : Promise<void> {
        const requestBody = {
            should_use_gross_sales_for_total_sales: shouldUseGrossSalesForTotalSales
        };

        return AjaxUtils.ajaxPut(
            url('sales:reports:item_level:update_should_use_gross_sales_for_total_sales', null, locationId.getValue(), null),
            requestBody
        );
    }
}
