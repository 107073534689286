import { getCurrencySymbol } from 'shared/models/Currency';

export const FormatMonetaryValue = (value : number, currencySymbol? : string) : string => {
    const absoluteValue = Math.abs(value);
    const symbol = typeof currencySymbol === 'undefined' ? getCurrencySymbol() : currencySymbol;
    return (value < 0 ? '-' : '') + (symbol + ToFixed(absoluteValue, 0).replace(/(\d)(?=(\d{3})+(\.|$))/g, '$1,').toString());
};

export const FormatMonetaryValueWithCents = (value : number | null, currencySymbol? : string, decimalRoundedTo? : number) : string => {
    if (value === null) {
        return '-';
    }
    const decimal = typeof decimalRoundedTo === 'undefined' ? 2 : decimalRoundedTo;

    const absoluteValue = ToFixed(Math.abs(value), decimal);
    const symbol = typeof currencySymbol === 'undefined' ? getCurrencySymbol() : currencySymbol;
    return (value < 0 ? '-' : '') + (symbol + absoluteValue.replace(/(\d)(?=(\d{3})+(\.|$))/g, '$1,').toString());
};

export const FormatPrice = (value : number, currencySymbol? : string) : string => {
    const symbol = typeof currencySymbol === 'undefined' ? getCurrencySymbol() : currencySymbol;
    return value === null ? '-' : (symbol + ToFixed(value, 2));
};

export const FormatDollarValue = (dollarValue : number, currencySymbol? : string) : string => {
    const symbol = typeof currencySymbol === 'undefined' ? getCurrencySymbol() : currencySymbol;
    if (dollarValue < 1) {
        return symbol + ToFixed(dollarValue, 2);
    }

    return FormatMonetaryValue(dollarValue);
};

export const ToFixed = (value : number, decimalRoundedTo : number) : string => {
    // Floating point rounding fix for x.xx5
    // https://stackoverflow.com/a/43998255
    const factor = Math.pow(10, decimalRoundedTo + 4);
    if (value >= 0) {
        return (value + (0.01 / factor)).toFixed(decimalRoundedTo);
    } else {
        return (value - (0.01 / factor)).toFixed(decimalRoundedTo);
    }
};
