import CoreTimeModel from 'gen-thrift/core_time_Model_types';
// TODO Use different time model

import { LocationId } from 'api/Location/model/LocationId';
import { ProductAmount } from 'api/Product/model/ProductAmount';

export class BreakageReportData {

    constructor (
        private readonly perspectiveLocation : LocationId,
        private readonly productAmounts : Array<ProductAmount>,
        private readonly time : CoreTimeModel.TimestampWithMillisecondPrecision,
        private readonly note : string,
        private readonly type : string | null,
    ) {}

    public getPerspectiveLocation() : LocationId {
        return this.perspectiveLocation;
    }

    public getProductAmounts() : Array<ProductAmount> {
        return this.productAmounts;
    }

    public getTime() : CoreTimeModel.TimestampWithMillisecondPrecision {
        return this.time;
    }

    public getNote() : string {
        return this.note;
    }

    public getType() : string | null {
        return this.type;
    }
}
