import React from 'react';

import { DateTime } from '../../models/DateTime';
import { IFormValue } from '../../models/FormValue';
import { IFormActions } from '../../models/IFormActions';

import { BevSpotTimePickerHourInput } from './BevSpotTimePickerHourInput';
import { BevSpotTimePickerMinuteInput } from './BevSpotTimePickerMinuteInput';
import { BevSpotTimePickerPeriodOfDaySelector } from './BevSpotTimePickerPeriodOfDaySelector';

export interface IBevSpotTimePickerFormActions {
    hour : IFormActions;
    minute : IFormActions;
}
export interface IBevSpotTimePickerFormValues {
    hour : IFormValue<string>;
    minute : IFormValue<string>;
}
export interface IBevSpotTimePicker {
    selectedDateTime : DateTime;
    setSelectedDateTime : (selectedDateTime : DateTime) => void;
    formActions : IBevSpotTimePickerFormActions;
    formValues : IBevSpotTimePickerFormValues;
}
export class BevSpotTimePicker extends React.Component<IBevSpotTimePicker, object> {
    public render() {
        const {
            selectedDateTime,
            formActions,
            formValues,
            setSelectedDateTime,
        } = this.props;
        let errorElement : JSX.Element | null = null;
        if (!formValues.hour.isValid) {
            errorElement = <span className="error-message">{ formValues.hour.errorMessage }</span>;
        }
        if (formValues.hour.isValid && !formValues.minute.isValid) {
            errorElement = <span className="error-message">{ formValues.minute.errorMessage }</span>;
        }
        return (
            <div className="bevspot-time-picker">
                <div className="col-row">
                    <div className="col-xs-7 col-sm-10 text-center no-pad">
                        <div className="col-row">
                            <div className="cell col-xs-5">
                                <BevSpotTimePickerHourInput
                                    dateTime={ selectedDateTime }
                                    formValue={ formValues.hour }
                                    setErrorMessage={ formActions.hour.setErrorMessage }
                                    setFormValue={ formActions.hour.setFormValue }
                                    setIsValid={ formActions.hour.setIsValid }
                                    setSelectedDateTime={ setSelectedDateTime }
                                />
                            </div>
                            <div className="cell col-xs-1 time-picker-colon-wrapper">
                                <span className="time-picker-colon">:</span>
                            </div>
                            <div className="cell col-xs-5">
                                <BevSpotTimePickerMinuteInput
                                    dateTime={ selectedDateTime }
                                    formValue={ formValues.minute }
                                    setErrorMessage={ formActions.minute.setErrorMessage }
                                    setFormValue={ this.handleSetMinuteFormValue }
                                    setIsValid={ formActions.minute.setIsValid }
                                    setSelectedDateTime={ setSelectedDateTime }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-5 col-sm-10 cell-ptb-xl">
                        <BevSpotTimePickerPeriodOfDaySelector
                            dateTime={ selectedDateTime }
                            setSelectedDateTime={ setSelectedDateTime }
                        />
                        { errorElement }
                    </div>
                </div>
            </div>
        );
    }
    private handleSetMinuteFormValue = (minuteFormValue : string, shouldUpdateHourFormValue : boolean) : void => {
        const {
            formActions,
            formValues,
        } = this.props;
        if (formValues.hour.value === null) {
            throw new Error('Hour form value unexpectedly null');
        }
        if (formValues.minute.value === null) {
            throw new Error('Minute form value unexpectedly null');
        }
        if (shouldUpdateHourFormValue) {
            let newHourFormValue : number;
            const currentHourFormValueAsNumber = parseInt(formValues.hour.value, 10);
            const currentMinuteValueAsNumber = parseInt(formValues.minute.value, 10);
            const minuteFormValueAsNumber = parseInt(minuteFormValue, 10);
            if (minuteFormValueAsNumber === 0 && currentMinuteValueAsNumber === 59) {
                newHourFormValue = currentHourFormValueAsNumber + 1;
                if (newHourFormValue === 13) {
                    newHourFormValue = 1;
                }
                formActions.hour.setFormValue(newHourFormValue.toString());
            }
            if (minuteFormValueAsNumber === 59 && currentMinuteValueAsNumber === 0) {
                newHourFormValue = currentHourFormValueAsNumber - 1;
                if (newHourFormValue === 0) {
                    newHourFormValue = 12;
                }
                formActions.hour.setFormValue(newHourFormValue.toString());
            }
        }
        formActions.minute.setFormValue(minuteFormValue);
    }
}
