import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { ProductQuickAdd } from 'api/Onboarding/model/ProductQuickAdd';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemWithMetadata } from 'api/SalesItem/model/SalesItemWithMetadata';
import { IColumnSorting } from 'shared/components/SortableColumnHeader';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { GroupByOption } from 'shared/models/GroupByOption';
import { defaultProductQuickAddRowFilter } from 'shared/utils/productQuickAddSortingFilteringAndGroupingUtils';
import { defaultProductRowFilter, getBrandAndNameStringForSort } from 'shared/utils/productSortingFilteringAndGroupingUtils';
import { defaultSalesItemRowFilter } from 'shared/utils/salesItemSortingFilteringAndGroupingUtils';
import { SortingFilteringAndGroupingUtil } from 'shared/utils/sortingFilteringAndGroupingUtils';

const ACTIVE_PRODUCTS_GROUP_NAME = 'From Items';
const ARCHIVED_PRODUCTS_GROUP_NAME = 'Archived Items';
const SALES_ITEMS_GROUP_NAME = 'From Recipes';
const ARCHIVED_SALES_ITEMS_GROUP_NAME = 'Archived Recipes';
const POPULAR_ITEMS_GROUP_NAME = 'BevSpot Popular Items';

interface IProductAndSalesItemSearchBarSortingUtilExtraArgs {
    salesItemFormData : {
        productsById : StringValueMap<ProductId, Product>;
        activeProductIds : StringValueSet<ProductId>;
        salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>;
    };
}

const groupNameSortOrder = [
    ACTIVE_PRODUCTS_GROUP_NAME,
    ARCHIVED_PRODUCTS_GROUP_NAME,
    SALES_ITEMS_GROUP_NAME,
    ARCHIVED_SALES_ITEMS_GROUP_NAME,
    POPULAR_ITEMS_GROUP_NAME
];

const groupNameComparator = (groupName1 : string, groupName2 : string, groupByOption : GroupByOption, extraArgs : IProductAndSalesItemSearchBarSortingUtilExtraArgs) : number => {
    const sortOrder1 = groupNameSortOrder.indexOf(groupName1);
    const sortOrder2 = groupNameSortOrder.indexOf(groupName2);

    return sortOrder1 - sortOrder2;
};

// PRODUCT UTILS
const productRowIdComparator = (productId1 : ProductId, productId2 : ProductId, columnSort : IColumnSorting, extraArgs : IProductAndSalesItemSearchBarSortingUtilExtraArgs) : number => {
    const product1 = extraArgs.salesItemFormData.productsById.get(productId1);
    const product2 = extraArgs.salesItemFormData.productsById.get(productId2);

    if ((typeof product1 === 'undefined') || (typeof product2 === 'undefined')) {
        throw new RuntimeException('unexpected');
    }

    const nameA = getBrandAndNameStringForSort(product1);
    const nameB = getBrandAndNameStringForSort(product2);
    return nameA.localeCompare(nameB);
};

let lastProductsById : StringValueMap<ProductId, Product>;
let fakeDistributorIdsByProductId : StringValueMap<ProductId, DistributorId | null>;
const productRowIdFilterFunction = (productId : ProductId, filterTerm : string | null, extraArgs : IProductAndSalesItemSearchBarSortingUtilExtraArgs) : boolean => {
    if (typeof lastProductsById === 'undefined' || typeof fakeDistributorIdsByProductId === 'undefined' || lastProductsById !== extraArgs.salesItemFormData.productsById) {
        lastProductsById = extraArgs.salesItemFormData.productsById;
        fakeDistributorIdsByProductId = new StringValueMap();
        lastProductsById.forEach((prod, prodId) => fakeDistributorIdsByProductId.set(prodId, null));
    }

    // TODO don't want to rewrite the product row filter, but not using distributors for filter for now? OR should we use the distributor (would need to retrieve it)
    return defaultProductRowFilter(productId, filterTerm, extraArgs.salesItemFormData.productsById, fakeDistributorIdsByProductId, new StringValueMap(), new StringValueMap());
};

const getGroupNameForProductRowId = (productId : ProductId, groupByOption : GroupByOption, extraArgs : IProductAndSalesItemSearchBarSortingUtilExtraArgs) : string => {
    const activeProductIds = extraArgs.salesItemFormData.activeProductIds;

    if (activeProductIds.has(productId)) {
        return ACTIVE_PRODUCTS_GROUP_NAME;
    }

    return ARCHIVED_PRODUCTS_GROUP_NAME;
};

const intermediateProductResultCacheIsValid = (
    rowIds : Array<ProductId>, lastRowIds : Array<ProductId>,
    extraArgs : IProductAndSalesItemSearchBarSortingUtilExtraArgs, lastExtraArgs : IProductAndSalesItemSearchBarSortingUtilExtraArgs) : boolean => {
    return (extraArgs.salesItemFormData === lastExtraArgs.salesItemFormData);
};

// SALES ITEM UTILS
const salesItemRowIdComparator = (salesItemId1 : SalesItemId, salesItemId2 : SalesItemId, columnSort : IColumnSorting, extraArgs : IProductAndSalesItemSearchBarSortingUtilExtraArgs) : number => {
    const salesItem1 = extraArgs.salesItemFormData.salesItemsById.get(salesItemId1);
    const salesItem2 = extraArgs.salesItemFormData.salesItemsById.get(salesItemId2);

    if ((typeof salesItem1 === 'undefined') || (typeof salesItem2 === 'undefined')) {
        throw new RuntimeException('unexpected');
    }

    const nameA = salesItem1.getSalesItem().getName() + ' ' + salesItem1.getSalesItem().getPOSId();
    const nameB = salesItem2.getSalesItem().getName() + ' ' + salesItem2.getSalesItem().getPOSId();
    return nameA.localeCompare(nameB); // not using clean phrase speeds this up noticeably...
    // return cleanPhrase(nameA).localeCompare(cleanPhrase(nameB));
};

const salesItemRowIdFilterFunction = (salesItemId : SalesItemId, filterTerm : string | null, extraArgs : IProductAndSalesItemSearchBarSortingUtilExtraArgs) : boolean => {
    return defaultSalesItemRowFilter(salesItemId, filterTerm, extraArgs.salesItemFormData.salesItemsById, extraArgs.salesItemFormData.productsById);
};

const getGroupNameForSalesItemRowId = (salesItemId : SalesItemId, groupByOption : GroupByOption, extraArgs : IProductAndSalesItemSearchBarSortingUtilExtraArgs) : string => {
    // TODO Product: active vs archived?
    return SALES_ITEMS_GROUP_NAME;
};

const intermediateSalesItemResultCacheIsValid = (
    rowIds : Array<SalesItemId>, lastRowIds : Array<SalesItemId>,
    extraArgs : IProductAndSalesItemSearchBarSortingUtilExtraArgs, lastExtraArgs : IProductAndSalesItemSearchBarSortingUtilExtraArgs) : boolean => {
    return (extraArgs.salesItemFormData === lastExtraArgs.salesItemFormData); // (rowIds === lastRowIds) &&
};

// PRODUCT QUICK ADD UTILS
const productQuickAddComparator = (productQuickAddItem1 : ProductQuickAdd, productQuickAddItem2 : ProductQuickAdd, columnSort : IColumnSorting, extraArgs : IProductAndSalesItemSearchBarSortingUtilExtraArgs) : number => {

    if ((typeof productQuickAddItem1 === 'undefined') || (typeof productQuickAddItem2 === 'undefined')) {
        throw new RuntimeException('unexpected');
    }

    const nameA = productQuickAddItem1.getValue();
    const nameB = productQuickAddItem2.getValue();
    return nameA.localeCompare(nameB);
};

const productQuickAddFilterFunction = (productQuickAddItem : ProductQuickAdd, filterTerm : string | null, extraArgs : IProductAndSalesItemSearchBarSortingUtilExtraArgs) : boolean => {
    return defaultProductQuickAddRowFilter(productQuickAddItem, filterTerm);
};

const getGroupNameForQuickAddRowId = () => {
    return POPULAR_ITEMS_GROUP_NAME;
};

const intermediateProductQuickAddResultCacheIsValid = (
    rowIds : Array<ProductQuickAdd>, lastRowIds : Array<ProductQuickAdd>,
    extraArgs : IProductAndSalesItemSearchBarSortingUtilExtraArgs, lastExtraArgs : IProductAndSalesItemSearchBarSortingUtilExtraArgs) : boolean => {
    return (extraArgs.salesItemFormData === lastExtraArgs.salesItemFormData); // (rowIds === lastRowIds) &&
};

export const ProductSearchBarSortingUtil = new SortingFilteringAndGroupingUtil(
    productRowIdComparator,
    productRowIdFilterFunction,
    getGroupNameForProductRowId,
    groupNameComparator,
    intermediateProductResultCacheIsValid
);
// TODO other option: could have one sorting util for both. but not sure that actually optimizes anything, and might make component more difficult
export const SalesItemSearchBarSortingUtil = new SortingFilteringAndGroupingUtil(
    salesItemRowIdComparator,
    salesItemRowIdFilterFunction,
    getGroupNameForSalesItemRowId,
    groupNameComparator,
    intermediateSalesItemResultCacheIsValid
);

// TODO: There is another PQASortingUtil in the inventory count directory.
// We should combine them the next time this is touched.
export const ProductQuickAddSortingUtil = new SortingFilteringAndGroupingUtil(
    productQuickAddComparator,
    productQuickAddFilterFunction,
    getGroupNameForQuickAddRowId,
    groupNameComparator,
    intermediateProductQuickAddResultCacheIsValid
);
