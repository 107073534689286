import { AccountType } from 'api/Location/model/AccountType';
import { BaseUnit } from 'api/Product/model/BaseUnit';
import { MassUnit } from 'api/Product/model/MassUnit';
import { Packaging } from 'api/Product/model/Packaging';
import { PackagingUnit } from 'api/Product/model/PackagingUnit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import { oldPackagingUtils } from 'api/Product/utils/oldPackagingUtils';
import { UNIT_DROPDOWN_LABEL, VOLUME_DROPDOWN_LABEL, WEIGHT_DROPDOWN_LABEL } from 'api/Product/utils/PackagingUtils';

import { PackagingFormFieldName, PackagingFormValidationInputDataByFieldName } from './PackagingForm';

import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { OptionsAndLabelNameTuples } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { IValidationResult, Validation } from 'shared/validators/validators';

export const unitNameLabelPluralsByValue = {
    [PackagingUnit.BOTTLE]: 'bottles',
    [PackagingUnit.CAN]: 'cans',
    [PackagingUnit.KEG]: 'kegs',
    [PackagingUnit.BAG]: 'bags',
    [PackagingUnit.BOX]: 'boxes',
    [PackagingUnit.CAN_FOOD]: 'cans (food)',
    [PackagingUnit.CARTON]: 'cartons',
    [PackagingUnit.CONTAINER]: 'containers',
    [PackagingUnit.PACKAGE]: 'packages',
    [PackagingUnit.TUB]: 'tubs',
    [PackagingUnit.OTHER]: 'others'
};

const getDisplayTextForPackagingUnitValueAndQuantity = (unitNameValue : string, quantity : string) : string => {
    const quantityNumber = Number(quantity);
    if (unitNameValue) {
        if (unitNameValue && quantityNumber === 1) {
            return oldPackagingUtils.getDisplayTextForUnit(PackagingUnit.getByPackagingUnitValue(unitNameValue));
        } else {
            return (unitNameLabelPluralsByValue as any)[unitNameValue];
        }
    } else {
        return '?';
    }
};

const beverageUnitNameOptions = [PackagingUnit.BOTTLE, PackagingUnit.KEG, PackagingUnit.CAN, PackagingUnit.OTHER];
const foodUnitNameOptions = [
    PackagingUnit.BOTTLE, PackagingUnit.KEG, PackagingUnit.CAN, PackagingUnit.BAG, PackagingUnit.BOX, PackagingUnit.CAN_FOOD,
    PackagingUnit.CARTON, PackagingUnit.CONTAINER, PackagingUnit.PACKAGE, PackagingUnit.TUB, PackagingUnit.OTHER
];

const getUnitNameSortedOptionsAndLabelName = (accountType : AccountType) : OptionsAndLabelNameTuples => {
    const unitNameOptions = (accountType === 'beverage') ? beverageUnitNameOptions : foodUnitNameOptions;
    return [[null, unitNameOptions.map((unitNameOption) => {
        return {
            icon: null,
            label: oldPackagingUtils.getDisplayTextForUnit(unitNameOption),
            value: unitNameOption,
        };
    })]];
};

const bottleCanContentUnitOptions = [VolumeUnit.LITER, VolumeUnit.MILLILITER, VolumeUnit.OUNCE];
const kegContentUnitOptions = [VolumeUnit.GALLON, VolumeUnit.LITER];
const allContentUnitOptions = [VolumeUnit.GALLON, VolumeUnit.LITER, VolumeUnit.MILLILITER, VolumeUnit.OUNCE,
    VolumeUnit.QUART, VolumeUnit.PINT, BaseUnit.UNIT, MassUnit.KILOGRAM, MassUnit.GRAM, MassUnit.DRY_OUNCE, MassUnit.POUND, BaseUnit.EACH];

const getContentUnitOptions = (unitNameValue : string) : Array<VolumeUnit | MassUnit | BaseUnit> => {
    if (unitNameValue === PackagingUnit.KEG) {
        return kegContentUnitOptions;
    } else if ((unitNameValue === PackagingUnit.BOTTLE) || (unitNameValue === PackagingUnit.CAN)) {
        return bottleCanContentUnitOptions;
    }

    return allContentUnitOptions;
};

const getContentUnitSortedOptionsAndLabelName = (unitNameValue : string) : OptionsAndLabelNameTuples => {
    const contentUnitOptions = getContentUnitOptions(unitNameValue);

    const allContentUnitSortedOptionsAndLabelName : OptionsAndLabelNameTuples = [[UNIT_DROPDOWN_LABEL, []], [VOLUME_DROPDOWN_LABEL, []], [WEIGHT_DROPDOWN_LABEL, []]];
    contentUnitOptions.forEach((contentUnitOption) => {
        const contentUnitIOption = {
            icon: null,
            label: oldPackagingUtils.getDisplayTextForUnit(contentUnitOption),
            value: contentUnitOption,
        };

        if (VolumeUnit.isVolumeUnitValue(contentUnitOption)) {
            allContentUnitSortedOptionsAndLabelName[1][1].push(contentUnitIOption);
        } else if (MassUnit.isMassUnitValue(contentUnitOption)) {
            allContentUnitSortedOptionsAndLabelName[2][1].push(contentUnitIOption);
        } else {
            allContentUnitSortedOptionsAndLabelName[0][1].push(contentUnitIOption);
        }
    });

    const contentUnitSortedOptionsAndLabelName : OptionsAndLabelNameTuples = [];
    allContentUnitSortedOptionsAndLabelName.forEach((labelNameAndOptions) => {
        const options = labelNameAndOptions[1];
        if (options.length > 0) {
            contentUnitSortedOptionsAndLabelName.push(labelNameAndOptions);
        }
    });

    return contentUnitSortedOptionsAndLabelName;
};

const getIOptionForUnitValue = (unitValue : string) : IOption | null => {
    if (unitValue) {
        return {
            icon: null,
            label: oldPackagingUtils.getDisplayTextForUnit(oldPackagingUtils.getUnitFromValue(unitValue)),
            value: unitValue,
        };
    }

    return null;
};

const contentUnitIsCompatibleWithUnitName = (unitNameValue : string, contentUnitValue : string) : boolean => {
    return ((getContentUnitOptions(unitNameValue) as Array<string>).indexOf(contentUnitValue) !== -1);
};

const validateValueByFieldName = (
    fieldName : PackagingFormFieldName,
    value : string,
) : IValidationResult => {
    let isValid : null | boolean = null;
    let errorMessage = '';

    // required
    switch (fieldName) {
        case 'unitName':
            isValid = Validation.validateRequired(value);
            if (!isValid) {
                errorMessage = `Unit type is required`;
                return {
                    errorMessage,
                    isValid,
                };
            }
            break;
        case 'contentQuantity':
            isValid = Validation.validateRequired(value);
            if (!isValid) {
                errorMessage = `Unit quantity is required`;
                return {
                    errorMessage,
                    isValid,
                };
            }
            break;
        case 'contentUnit':
            isValid = Validation.validateRequired(value);
            if (!isValid) {
                errorMessage = `Unit of measure is required`;
                return {
                    errorMessage,
                    isValid,
                };
            }
            break;
        case 'caseName':
            isValid = Validation.validateRequired(value);
            if (!isValid) {
                errorMessage = `Case name is required`;
                return {
                    errorMessage,
                    isValid,
                };
            }
            break;
    }

    // input type specific
    switch (fieldName) {
        case 'caseQuantity':
            if (value === '') {
                isValid = true;
                errorMessage = '';
                break;
            }
            isValid = Validation.validateNumber(value) && (parseFloat(value) > 0);
            if (!isValid) {
                errorMessage = 'Invalid value';
                break;
            }
            isValid = (Math.floor(Number(value)) === Number(value));
            if (!isValid) {
                errorMessage = 'Must be a whole number';
            }
            break;
        case 'contentQuantity':
            isValid = Validation.validateNumber(value) && (parseFloat(value) > 0);
            if (!isValid) {
                errorMessage = 'Invalid value';
            }
            break;
        default:
            if (isValid === null) {
                throw new Error('no validation implemented for fieldname ' + fieldName);
            }
    }

    return {
        isValid,
        errorMessage,
    };
};

export const DEFAULT_PACKAGING_FORM_VALIDATION_INPUT_DATA_BY_FIELD_NAME : PackagingFormValidationInputDataByFieldName = {
    unitName: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    contentQuantity: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    contentUnit: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    caseName: {
        value: 'case',
        isValid: true,
        errorMessage: '',
    },
    caseQuantity: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    comesAsCase: {
        value: false,
        isValid: true,
        errorMessage: '',
    }
};

const getValidationInputDataByFieldNameFromInitialPackaging = (initialPackaging : Packaging | PackagingFormValidationInputDataByFieldName) : PackagingFormValidationInputDataByFieldName => {

    let validationInputDataByFieldName : PackagingFormValidationInputDataByFieldName;

    if (initialPackaging instanceof Packaging) {
        const oldPackaging = oldPackagingUtils.getOldPackagingFromPackaging(initialPackaging);

        let caseQuantityValue = '';
        if (oldPackagingUtils.hasCase(oldPackaging)) {
            const caseQuantityOfContent = oldPackaging.getQuantityOfContent();
            if (!caseQuantityOfContent) {
                throw new RuntimeException('unexpected in parsing initialPackaging');
            }

            caseQuantityValue = caseQuantityOfContent.toString();
        }

        const container = oldPackagingUtils.getContainer(oldPackaging);

        const unitNameValue = container.getUnit();
        const contentQuantity = container.getQuantityOfContent();
        const containerContent = container.getContent();
        if (!contentQuantity || !containerContent) {
            throw new RuntimeException('unexpected in parsing initialPackaging');
        }

        const contentUnitValue = containerContent.getUnit();
        const contentQuantityValue = contentQuantity.toString();

        validationInputDataByFieldName = {
            caseName: {
                value: 'case',
                isValid: true,
                errorMessage: '',
            },
            caseQuantity: {
                value: caseQuantityValue,
                isValid: true,
                errorMessage: '',
            },
            unitName: {
                value: unitNameValue,
                isValid: true,
                errorMessage: '',
            },
            contentQuantity: {
                value: contentQuantityValue,
                isValid: true,
                errorMessage: '',
            },
            contentUnit: {
                value: contentUnitValue,
                isValid: true,
                errorMessage: '',
            },
            comesAsCase: {
                value: caseQuantityValue !== '',
                isValid: true,
                errorMessage: '',
            }
        };
    } else {
        validationInputDataByFieldName = initialPackaging;
    }

    return validationInputDataByFieldName;
};

const getAppearsAsText = (validationInputDataByFieldName : PackagingFormValidationInputDataByFieldName) : string => {
    let caseQuantityText = '';
    if (validationInputDataByFieldName.comesAsCase.value) {
        caseQuantityText = `${ (validationInputDataByFieldName.caseQuantity.isValid && validationInputDataByFieldName.caseQuantity.value) ? validationInputDataByFieldName.caseQuantity.value : '?' } x`;
    }

    const contentQuantityText = (validationInputDataByFieldName.contentQuantity.isValid && validationInputDataByFieldName.contentQuantity.value) ? validationInputDataByFieldName.contentQuantity.value : '?';

    const contentUnitValue = validationInputDataByFieldName.contentUnit.value;
    const contentUnitText = (validationInputDataByFieldName.contentUnit.isValid && contentUnitValue) ? oldPackagingUtils.getDisplayTextForUnit(contentUnitValue as any) : '?';

    const unitNameValue = validationInputDataByFieldName.unitName.value;
    const unitNameText = (validationInputDataByFieldName.unitName.isValid && unitNameValue) ? oldPackagingUtils.getDisplayTextForUnit(unitNameValue as any) : '?';

    return `${ caseQuantityText } ${ contentQuantityText }${ contentUnitText } (${ unitNameText })`.trim();
};

export const PackagingFormUtils = {
    getUnitNameSortedOptionsAndLabelName,
    getContentUnitOptions,
    getContentUnitSortedOptionsAndLabelName,
    getIOptionForUnitValue,
    contentUnitIsCompatibleWithUnitName,
    validateValueByFieldName,
    getValidationInputDataByFieldNameFromInitialPackaging,
    getDisplayTextForPackagingUnitValueAndQuantity,
    getAppearsAsText,
};
