//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';



var InventoryExceptions = {};
export default InventoryExceptions;
InventoryExceptions.NoSuchInventoryCountException = function(args) {
};
Thrift.inherits(InventoryExceptions.NoSuchInventoryCountException, Thrift.TException);
InventoryExceptions.NoSuchInventoryCountException.prototype.name = 'NoSuchInventoryCountException';
InventoryExceptions.NoSuchInventoryCountException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryExceptions.NoSuchInventoryCountException.prototype.write = function(output) {
  output.writeStructBegin('NoSuchInventoryCountException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryExceptions.UnknownInventoryCountIdException = function(args) {
};
Thrift.inherits(InventoryExceptions.UnknownInventoryCountIdException, Thrift.TException);
InventoryExceptions.UnknownInventoryCountIdException.prototype.name = 'UnknownInventoryCountIdException';
InventoryExceptions.UnknownInventoryCountIdException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryExceptions.UnknownInventoryCountIdException.prototype.write = function(output) {
  output.writeStructBegin('UnknownInventoryCountIdException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryExceptions.UnknownStorageAreaIdException = function(args) {
};
Thrift.inherits(InventoryExceptions.UnknownStorageAreaIdException, Thrift.TException);
InventoryExceptions.UnknownStorageAreaIdException.prototype.name = 'UnknownStorageAreaIdException';
InventoryExceptions.UnknownStorageAreaIdException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryExceptions.UnknownStorageAreaIdException.prototype.write = function(output) {
  output.writeStructBegin('UnknownStorageAreaIdException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryExceptions.CurrentInventoryCountAlreadyExistsForLocationException = function(args) {
};
Thrift.inherits(InventoryExceptions.CurrentInventoryCountAlreadyExistsForLocationException, Thrift.TException);
InventoryExceptions.CurrentInventoryCountAlreadyExistsForLocationException.prototype.name = 'CurrentInventoryCountAlreadyExistsForLocationException';
InventoryExceptions.CurrentInventoryCountAlreadyExistsForLocationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryExceptions.CurrentInventoryCountAlreadyExistsForLocationException.prototype.write = function(output) {
  output.writeStructBegin('CurrentInventoryCountAlreadyExistsForLocationException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryExceptions.InventoryCountIsDeletedException = function(args) {
};
Thrift.inherits(InventoryExceptions.InventoryCountIsDeletedException, Thrift.TException);
InventoryExceptions.InventoryCountIsDeletedException.prototype.name = 'InventoryCountIsDeletedException';
InventoryExceptions.InventoryCountIsDeletedException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryExceptions.InventoryCountIsDeletedException.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountIsDeletedException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryExceptions.InventoryCountIsFinalizedException = function(args) {
};
Thrift.inherits(InventoryExceptions.InventoryCountIsFinalizedException, Thrift.TException);
InventoryExceptions.InventoryCountIsFinalizedException.prototype.name = 'InventoryCountIsFinalizedException';
InventoryExceptions.InventoryCountIsFinalizedException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryExceptions.InventoryCountIsFinalizedException.prototype.write = function(output) {
  output.writeStructBegin('InventoryCountIsFinalizedException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryExceptions.LocationIsAlreadyMigratedException = function(args) {
};
Thrift.inherits(InventoryExceptions.LocationIsAlreadyMigratedException, Thrift.TException);
InventoryExceptions.LocationIsAlreadyMigratedException.prototype.name = 'LocationIsAlreadyMigratedException';
InventoryExceptions.LocationIsAlreadyMigratedException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryExceptions.LocationIsAlreadyMigratedException.prototype.write = function(output) {
  output.writeStructBegin('LocationIsAlreadyMigratedException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryExceptions.LocationIsInMigrationException = function(args) {
};
Thrift.inherits(InventoryExceptions.LocationIsInMigrationException, Thrift.TException);
InventoryExceptions.LocationIsInMigrationException.prototype.name = 'LocationIsInMigrationException';
InventoryExceptions.LocationIsInMigrationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryExceptions.LocationIsInMigrationException.prototype.write = function(output) {
  output.writeStructBegin('LocationIsInMigrationException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

