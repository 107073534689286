import moment from 'moment-timezone';

import { InvoiceProcessingData } from 'api/Ordering/model/InvoiceProcessingData';
import { DeliveryId } from './DeliveryId';

export class InvoiceUpload {
    constructor (
        private readonly name : string,
        private readonly uploadUrl : string,
        private readonly uploadTime : moment.Moment,
        private readonly fileExtension : string,
        private readonly deliveryId : DeliveryId | null,
        private readonly invoiceProcessingData : InvoiceProcessingData | null,
    ) {}

    public getName() : string {
        return this.name;
    }

    public getUploadUrl() : string {
        return this.uploadUrl;
    }

    public getUploadTime() : moment.Moment {
        return this.uploadTime;
    }

    public getFileExtension() : string {
        return this.fileExtension;
    }

    public getDeliveryId() : DeliveryId | null {
        return this.deliveryId;
    }

    public getInvoiceProcessingData() : InvoiceProcessingData | null {
        return this.invoiceProcessingData;
    }
}
