import React from 'react';

import './ProductName.scss';
import { Product } from 'api/Product/model/Product';

export interface IProductNameProps {
    readonly product : Product;
    readonly sku? : string | null;
}

export class ProductName extends React.Component<IProductNameProps, object> {

    public render() {
        const {
            product,
            sku
        } = this.props;

        const productCategoryId = product.getProductCategoryId();
        const productType = product.getProductType();

        return (
            <span className="item-name">
                <span className="product-brand">{ product.getBrand() }</span>
                <span className="product-name">{ product.getName() }</span>
                <small className="product-details">{ productCategoryId ? productCategoryId : '' }{ productType ? '  |  ' + productType : '' }{ sku && sku !== null ? '  |  SKU: ' + sku : '' }</small>
            </span>
        );
    }
}
