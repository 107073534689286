import React from 'react';

import { ISignUpFormState, signUpFormFieldName } from './reducers';

import { AddressFormFieldName, ConnectedAddressForm } from 'shared/components/AddressForm/AddressForm';
import { Button } from '../../Button';
import { IValidationInputData, ValidationInput } from '../../ValidationInput';

export interface ISignUpFormProps {
    headerTitle : string | null;
    headerMessage : string | null;
    signUpFormState : ISignUpFormState;
    onFormFieldChange : (field : signUpFormFieldName, value : string | boolean) => void;
    onFormFieldBlur : (field : signUpFormFieldName, value : string | boolean) => void;
    additionalUserFieldsAreShown : boolean;
    additionalRetailerFieldsAreShown : boolean;
    additionalRetailerFieldsAreRequired : boolean;
    submitButtonContents : string | JSX.Element;
    onSubmit : () => void;
    setAddressFormField : (fieldName : AddressFormFieldName, validationInputData : IValidationInputData) => void;
}

export class SignUpForm extends React.Component<ISignUpFormProps, object> {
    public render() {
        const {
            headerTitle,
            headerMessage,
            signUpFormState,
            onFormFieldChange,
            onFormFieldBlur,
            additionalUserFieldsAreShown,
            additionalRetailerFieldsAreShown,
            additionalRetailerFieldsAreRequired,
            submitButtonContents,
            onSubmit,
            setAddressFormField,
        } = this.props;

        const makeValidationInput = (fieldName : signUpFormFieldName, fieldData : IValidationInputData, label : string) : JSX.Element => {
            const onChange = (event : React.ChangeEvent<HTMLInputElement>) => onFormFieldChange(fieldName, event.currentTarget.value);
            const onBlur = (event : React.FocusEvent<HTMLInputElement>) => onFormFieldBlur(fieldName, event.currentTarget.value);

            return (
                <ValidationInput
                    key={ fieldName }
                    inputClassName=""
                    type="text"
                    autoFocus={ false }
                    autoComplete={ null }
                    errorMessage={ fieldData.errorMessage }
                    isValid={ fieldData.isValid }
                    handleBlur={ onBlur }
                    handleFocus={ null }
                    handleChange={ onChange }
                    handleEnterClick={ onSubmit }
                    label={ label }
                    hintText={ null }
                    value={ fieldData.value }
                />
            );
        };

        /* tslint:disable:max-line-length */
        return (
            <div className="sign-up-form">
                { (headerMessage !== null) &&
                    <div>
                        <h5>{ headerTitle }</h5>
                        <p>{ headerMessage }</p>
                    </div>
                }
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        { makeValidationInput('firstName', signUpFormState.validationInputDataByFieldName.firstName, 'First Name') }
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        { makeValidationInput('lastName', signUpFormState.validationInputDataByFieldName.lastName, 'Last Name') }
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12">
                        { makeValidationInput('emailAddress', signUpFormState.validationInputDataByFieldName.emailAddress, 'Email') }
                    </div>
                </div>

                { additionalUserFieldsAreShown &&
                    <div className="row">
                        <div className="col-xs-12">
                            { makeValidationInput('phone', signUpFormState.validationInputDataByFieldName.phone, 'Mobile Phone') }
                        </div>
                    </div>
                }

                { additionalRetailerFieldsAreShown &&
                    <div className="row">
                        <div className="col-xs-12">
                            { makeValidationInput('barName', signUpFormState.validationInputDataByFieldName.barName, `Bar Name${ additionalRetailerFieldsAreRequired ? '' : ' (Optional)' }`) }
                        </div>
                    </div>
                }

                { additionalRetailerFieldsAreShown && additionalRetailerFieldsAreRequired &&
                    <div className="row">
                        <ConnectedAddressForm // for right now, need full address to create a retailer
                            validationInputDataByFieldName={ signUpFormState.validationInputDataByFieldName }
                            rowClassName="col-xs-12"
                            addressLine1Label="Street Address Line 1"
                            addressLine2Label="Street Address Line 2 (Optional)"
                            zipCodeAndCountryAreDisabled={ false }
                            setValidationInputForField={ setAddressFormField }
                            onEnterClick={ this.doNothingFunction }
                        />
                    </div>
                }

                { additionalRetailerFieldsAreShown && !additionalRetailerFieldsAreRequired &&
                    <div className="row">
                        <div className="col-xs-12">
                            { makeValidationInput('optionalZipCode', signUpFormState.validationInputDataByFieldName.optionalZipCode, 'Zip Code (Optional)') }
                        </div>
                    </div>
                }

                { /*<span className="subscribe-to-blog-checkbox-container">
                    <input
                        id="subscribe-to-blog"
                        type="checkbox"
                        checked={ signUpFormState.validationInputDataByFieldName.subscribeToBlog.value }
                        onChange={ onFormFieldChange.bind(null, 'subscribeToBlog', !signUpFormState.validationInputDataByFieldName.subscribeToBlog.value) }
                    />
                    <label
                        className="bar-profit-subscribe-label"
                        onClick={ onFormFieldChange.bind(null, 'subscribeToBlog', !signUpFormState.validationInputDataByFieldName.subscribeToBlog.value) }
                    >
                        Keep me up-to-date with industry news and resources
                    </label>
                </span>*/ }

                <Button
                    buttonClassName="modal-sign-up-button primary"
                    isDisabled={ signUpFormState.isSubmitting }
                    isLoading={ signUpFormState.isSubmitting }
                    onClick={ onSubmit }
                >
                    { submitButtonContents }
                </Button>

                { !signUpFormState.isValid && signUpFormState.errorMessage &&
                    <p className="form-error-message">{ signUpFormState.errorMessage }</p>
                }

            </div>
        );
    }

    private readonly doNothingFunction = () => undefined;
}
