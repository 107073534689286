import * as _ from 'lodash';
import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { StorageArea } from 'api/InventoryCount/model/StorageArea';
import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { GroupByOption } from 'shared/models/GroupByOption';

import { DropdownRow } from 'apps/InventoryCount/components/UnassignedItems/UnassignedItemsView';
import { ComponentName, IUnassignedItemsState } from 'apps/InventoryCount/reducers/UnassignedItemsReducers';
import { IExtraArguments } from 'shared/components/Provider';
import { IColumnSorting } from 'shared/components/SortableColumnHeader';
import { IAction, IActionCreatorsMapObject } from 'shared/models/IAction';

import { unassignedItemsSortingUtil } from '../utils/UnassignedItemsSortingUtil';

export interface IUnassignedItemsStore {
    unassignedItemsState : IUnassignedItemsState;
}

export const ActionTypes = {
    SET_VISIBLE_DROPDOWN_ROW: 'UNASSIGNED_ITEMS/SET_VISIBLE_DROPDOWN_ROW',
    SET_MORE_OPTIONS_IS_SHOWN_FOR_PRODUCT_IDS: 'UNASSIGNED_ITEMS/SET_MORE_OPTIONS_IS_SHOWN_FOR_PRODUCT_IDS',
    SET_COMPONENT_IS_SHOWN: 'UNASSIGNED_ITEMS/SET_COMPONENT_IS_SHOWN',
    SET_PRODUCT_ROWS_SELECTED: 'UNASSIGNED_ITEMS/SET_PRODUCT_ROWS_SELECTED',
    SET_ACTIVE_GROUP_BY_OPTION: 'UNASSIGNED_ITEMS/SET_ACTIVE_GROUP_BY_OPTION',
    SET_SORTED_GROUP_NAMES_AND_SORTED_PRODUCT_IDS_TO_DISPLAY_BY_GROUP_NAME: 'UNASSIGNED_ITEMS/SET_SORTED_GROUP_NAMES_AND_SORTED_PRODUCT_IDS_TO_DISPLAY_BY_GROUP_NAME',
    SET_PRODUCTS_BY_ID_AND_STORAGE_AREA_ID_SET_BY_PRODUCT_ID: 'UNASSIGNED_ITEMS/SET_PRODUCTS_BY_ID_AND_STORAGE_AREA_ID_SET_BY_PRODUCT_ID',
    SET_TABLE_SORTING: 'UNASSIGNED_ITEMS/SET_TABLE_SORTING',
    SET_SEARCH_TERM: 'UNASSIGNED_ITEMS/SET_SEARCH_TERM',
    SET_PANEL_IS_OPEN_FROM_USER_INPUT_FOR_GROUP_NAMES: 'UNASSIGNED_ITEMS/SET_PANEL_IS_OPEN_FROM_USER_INPUT_FOR_GROUP_NAMES',
    SET_DEFAULT_PANEL_IS_OPEN: 'UNASSIGNED_ITEMS/SET_DEFAULT_PANEL_IS_OPEN'
};

export namespace UnassignedItemsActionInterfaces {
    export interface ISetVisibleDropdownRow extends IAction {
        payload : {
            visibleDropdownRow : DropdownRow | null;
        };
    }

    export interface ISetMoreOptionsIsShownForProductIds extends IAction {
        payload : {
            productIds : StringValueSet<ProductId>,
            isShown : boolean,
        };
    }

    export interface ISetComponentIsShown extends IAction {
        payload : {
            isShown : boolean,
            componentName : ComponentName
        };
    }

    export interface ISetSelectedProductIds extends IAction {
        payload : {
            productIds : StringValueSet<ProductId>,
        };
    }

    export interface ISetActiveGroupByOption extends IAction {
        payload : {
            groupByOption : GroupByOption,
        };
    }

    export interface ISetTableSorting extends IAction {
        payload : {
            columnSort : IColumnSorting,
        };
    }

    export interface ISetSearchTerm extends IAction {
        payload : {
            searchTerm : string | null,
        };
    }

    export interface ISetDefaultPanelIsOpen extends IAction {
        payload : {
            defaultPanelIsOpen : boolean,
        };
    }

    export interface ISetPanelIsOpenFromUserInputForGroupNames extends IAction {
        payload : {
            groupNames : Set<string>,
            panelIsOpen : boolean,
        };
    }

    export interface ISetProductsByIdAndStorageAreaIdSetByProductId extends IAction {
        payload : {
            productIdsForDisplay : StringValueSet<ProductId>,
            productsById : StringValueMap<ProductId, Product>,
            storageAreaIdSetByProductId : StringValueMap<ProductId, StringValueSet<StorageAreaId>>,
            storageAreasById : StringValueMap<StorageAreaId, StorageArea>
        };
    }

    export interface ISetSortedGroupNamesAndSortedProductIdsToDisplayByGroupName extends IAction {
        payload : {
            sortedGroupNamesToDisplay : Array<string>,
            sortedProductIdsToDisplayByGroupName : {[groupName : string] : Array<ProductId>},
            panelIsOpenByGroupNameFromFiltering : {[groupName : string] : boolean},
            unfilteredSortedProductIdsByGroupName : {[groupName : string] : Array<ProductId>},
        };
    }

    export interface IServices {
    }

    export interface IUnassignedItemsExtraArguments extends IExtraArguments {
        services : IServices;
    }

    export interface IUnassignedItemsActionCreatorsMapObject extends IActionCreatorsMapObject {
        setVisibleDropdownRow : (
            visibleDropdownRow : DropdownRow | null,
        ) => UnassignedItemsActionInterfaces.ISetVisibleDropdownRow;
        setMoreOptionsIsShownForProductIds : (
            productIds : StringValueSet<ProductId>,
            isShown : boolean,
        ) => ISetMoreOptionsIsShownForProductIds;
        setComponentIsShown : (
            componentName : ComponentName,
            isShown : boolean,
        ) => ISetComponentIsShown;
        setSelectedProductIds : ISetSelectedProductIdsActionCreator;
        setActiveGroupByOption : ISetActiveGroupByOptionActionCreator;
        setSortedGroupNamesAndSortedProductIdsToDisplayByGroupName : ISetSortedGroupNamesAndSortedProductIdsToDisplayByGroupNameActionCreator;
        setProductsByIdAndStorageAreaIdSetByProductId : ISetProductsByIdAndStorageAreaIdSetByProductIdActionCreator;
        setPanelIsOpenFromUserInputForGroupNames : ISetPanelIsOpenFromUserInputForGroupNamesActionCreator;

        onSelectActiveGroupByOption : (
            groupByOption : GroupByOption,
            productsById : StringValueMap<ProductId, Product>,
            storageAreaIdSetByProductId : StringValueMap<ProductId, StringValueSet<StorageAreaId>>
        ) => ThunkAction<void, IUnassignedItemsStore, UnassignedItemsActionInterfaces.IUnassignedItemsExtraArguments>;
        setSortingFilteringAndGroupingOnPropsChange : (
            productIdsForDisplay : StringValueSet<ProductId>,
            productsById : StringValueMap<ProductId, Product>,
            storageAreaIdSetByProductId : StringValueMap<ProductId, StringValueSet<StorageAreaId>>,
            storageAreasById : StringValueMap<StorageAreaId, StorageArea>,
            searchTerm : string | null,
        ) => ThunkAction<void, IUnassignedItemsStore, UnassignedItemsActionInterfaces.IUnassignedItemsExtraArguments>;
        onSelectActiveColumnSort : (
            columnSort : IColumnSorting
        ) => ThunkAction<void, IUnassignedItemsStore, UnassignedItemsActionInterfaces.IUnassignedItemsExtraArguments>;
        onSearchTermChange : (
            searchTerm : string | null
        ) => ThunkAction<void, IUnassignedItemsStore, UnassignedItemsActionInterfaces.IUnassignedItemsExtraArguments>;
        onCollapseAllClick : (
            nextClickWillCollapseAll : boolean,
        ) => ThunkAction<void, IUnassignedItemsStore, UnassignedItemsActionInterfaces.IUnassignedItemsExtraArguments>;
    }
}

type ISetVisibleDropdownRowActionCreator = (
    visibleDropdownRow : DropdownRow | null,
) => UnassignedItemsActionInterfaces.ISetVisibleDropdownRow;
type ISetMoreOptionsIsShownForProductIdsActionCreator = (
    productIds : StringValueSet<ProductId>,
    isShown : boolean,
) => UnassignedItemsActionInterfaces.ISetMoreOptionsIsShownForProductIds;
type ISetComponentIsShownActionCreator = (componentName : ComponentName, isShown : boolean) => UnassignedItemsActionInterfaces.ISetComponentIsShown;
type ISetSelectedProductIdsActionCreator = (productIds : StringValueSet<ProductId>) => UnassignedItemsActionInterfaces.ISetSelectedProductIds;
type ISetActiveGroupByOptionActionCreator = (groupByOption : GroupByOption) => UnassignedItemsActionInterfaces.ISetActiveGroupByOption;
type ISetSortedGroupNamesAndSortedProductIdsToDisplayByGroupNameActionCreator = (
    sortedGroupNamesToDisplay : Array<string>,
    sortedProductIdsToDisplayByGroupName : {[groupName : string] : Array<ProductId>},
    panelIsOpenByGroupNameFromFiltering : {[groupName : string] : boolean},
    unfilteredSortedProductIdsByGroupName : {[groupName : string] : Array<ProductId>},
) => UnassignedItemsActionInterfaces.ISetSortedGroupNamesAndSortedProductIdsToDisplayByGroupName;
type ISetProductsByIdAndStorageAreaIdSetByProductIdActionCreator = (
    productIdsForDisplay : StringValueSet<ProductId>,
    productsById : StringValueMap<ProductId, Product>,
    storageAreaIdSetByProductId : StringValueMap<ProductId, StringValueSet<StorageAreaId>>,
    storageAreasById : StringValueMap<StorageAreaId, StorageArea>,
) => UnassignedItemsActionInterfaces.ISetProductsByIdAndStorageAreaIdSetByProductId;
type ISetTableSortingActionCreator = (columnSort : IColumnSorting) => UnassignedItemsActionInterfaces.ISetTableSorting;
type ISetSearchTermActionCreator = (searchTerm : string | null) => UnassignedItemsActionInterfaces.ISetSearchTerm;
type ISetPanelIsOpenFromUserInputForGroupNamesActionCreator = (
    groupNames : Set<string>,
    panelIsOpen : boolean,
) => UnassignedItemsActionInterfaces.ISetPanelIsOpenFromUserInputForGroupNames;

const setVisibleDropdownRow : ISetVisibleDropdownRowActionCreator = (
    visibleDropdownRow : DropdownRow | null,
) : UnassignedItemsActionInterfaces.ISetVisibleDropdownRow => ({
    payload: {
        visibleDropdownRow,
    },
    type: ActionTypes.SET_VISIBLE_DROPDOWN_ROW,
});

const setMoreOptionsIsShownForProductIds : ISetMoreOptionsIsShownForProductIdsActionCreator = (
    productIds : StringValueSet<ProductId>,
    isShown : boolean,
) : UnassignedItemsActionInterfaces.ISetMoreOptionsIsShownForProductIds => ({
    payload: {
        productIds,
        isShown,
    },
    type: ActionTypes.SET_MORE_OPTIONS_IS_SHOWN_FOR_PRODUCT_IDS
});

const setComponentIsShown : ISetComponentIsShownActionCreator = (
    componentName : ComponentName,
    isShown : boolean,
) : UnassignedItemsActionInterfaces.ISetComponentIsShown => ({
    payload: {
        isShown,
        componentName,
    },
    type: ActionTypes.SET_COMPONENT_IS_SHOWN
});

const setSelectedProductIds : ISetSelectedProductIdsActionCreator = (
    productIds : StringValueSet<ProductId>,
) : UnassignedItemsActionInterfaces.ISetSelectedProductIds => ({
    payload: {
        productIds,
    },
    type: ActionTypes.SET_PRODUCT_ROWS_SELECTED,
});

const setActiveGroupByOption : ISetActiveGroupByOptionActionCreator = (
    groupByOption : GroupByOption
) : UnassignedItemsActionInterfaces.ISetActiveGroupByOption => ({
    payload: {
        groupByOption,
    },
    type: ActionTypes.SET_ACTIVE_GROUP_BY_OPTION,
});

const setSortedGroupNamesAndSortedProductIdsToDisplayByGroupName : ISetSortedGroupNamesAndSortedProductIdsToDisplayByGroupNameActionCreator = (
    sortedGroupNamesToDisplay : Array<string>,
    sortedProductIdsToDisplayByGroupName : {[groupName : string] : Array<ProductId>},
    panelIsOpenByGroupNameFromFiltering : {[groupName : string] : boolean},
    unfilteredSortedProductIdsByGroupName : {[groupName : string] : Array<ProductId>},
) : UnassignedItemsActionInterfaces.ISetSortedGroupNamesAndSortedProductIdsToDisplayByGroupName => ({
    payload: {
        sortedGroupNamesToDisplay,
        sortedProductIdsToDisplayByGroupName,
        panelIsOpenByGroupNameFromFiltering,
        unfilteredSortedProductIdsByGroupName,
    },
    type: ActionTypes.SET_SORTED_GROUP_NAMES_AND_SORTED_PRODUCT_IDS_TO_DISPLAY_BY_GROUP_NAME
});

const setProductsByIdAndStorageAreaIdSetByProductId : ISetProductsByIdAndStorageAreaIdSetByProductIdActionCreator = (
    productIdsForDisplay : StringValueSet<ProductId>,
    productsById : StringValueMap<ProductId, Product>,
    storageAreaIdSetByProductId : StringValueMap<ProductId, StringValueSet<StorageAreaId>>,
    storageAreasById : StringValueMap<StorageAreaId, StorageArea>,
) : UnassignedItemsActionInterfaces.ISetProductsByIdAndStorageAreaIdSetByProductId => ({
    payload: {
        productIdsForDisplay,
        productsById,
        storageAreaIdSetByProductId,
        storageAreasById,
    },
    type: ActionTypes.SET_PRODUCTS_BY_ID_AND_STORAGE_AREA_ID_SET_BY_PRODUCT_ID,
});

const setTableSorting : ISetTableSortingActionCreator = (
    columnSort : IColumnSorting,
) : UnassignedItemsActionInterfaces.ISetTableSorting => ({
    payload: {
        columnSort,
    },
    type: ActionTypes.SET_TABLE_SORTING
});

const setSearchTerm : ISetSearchTermActionCreator = (
    searchTerm : string | null,
) : UnassignedItemsActionInterfaces.ISetSearchTerm => ({
    payload: {
        searchTerm,
    },
    type: ActionTypes.SET_SEARCH_TERM
});

const setPanelIsOpenFromUserInputForGroupNames : ISetPanelIsOpenFromUserInputForGroupNamesActionCreator = (
    groupNames : Set<string>,
    panelIsOpen : boolean,
) : UnassignedItemsActionInterfaces.ISetPanelIsOpenFromUserInputForGroupNames => ({
    payload: {
        groupNames,
        panelIsOpen,
    },
    type: ActionTypes.SET_PANEL_IS_OPEN_FROM_USER_INPUT_FOR_GROUP_NAMES,
});

const setDefaultPanelIsOpen = (
    defaultPanelIsOpen : boolean,
) : UnassignedItemsActionInterfaces.ISetDefaultPanelIsOpen => ({
    payload: {
        defaultPanelIsOpen,
    },
    type: ActionTypes.SET_DEFAULT_PANEL_IS_OPEN
});

const handleSortingFilteringAndGrouping = () : ThunkAction<void, IUnassignedItemsStore, UnassignedItemsActionInterfaces.IUnassignedItemsExtraArguments> => {
    return throttledHandleSortingFilteringAndGrouping;
};

const throttledHandleSortingFilteringAndGrouping = _.throttle(
    (dispatch : Dispatch<IUnassignedItemsStore>, getState : () => IUnassignedItemsStore, extraArguments : UnassignedItemsActionInterfaces.IUnassignedItemsExtraArguments) : void => {
        const state = getState().unassignedItemsState;

        const extraArgumentsForSorting = {
            productsById: state.productsById,
            storageAreaIdSetByProductId: state.storageAreaIdSetByProductId,
            storageAreasById: state.storageAreasById,
        };

        unassignedItemsSortingUtil.getSortedFilteredAndGroupedResult(
            Array.from(state.productIdsForDisplay.values()),
            state.tableSorting,
            state.searchTerm,
            state.activeGroupByOption,
            extraArgumentsForSorting
        )
        .then((result) => {
            dispatch(setSortedGroupNamesAndSortedProductIdsToDisplayByGroupName(
                result.sortedGroupNamesToDisplay,
                result.sortedRowIdsToDisplayByGroupName,
                result.panelIsOpenByGroupNameFromFiltering,
                result.unfilteredSortedRowIdsByGroupName,
            ));
        });
    }, 200, { leading : false }
);

const onSearchTermChange = (
    searchTerm : string | null,
) : ThunkAction<void, IUnassignedItemsStore, UnassignedItemsActionInterfaces.IUnassignedItemsExtraArguments> => {
    return (dispatch : Dispatch<IUnassignedItemsStore>, getState : () => IUnassignedItemsStore, extraArguments : UnassignedItemsActionInterfaces.IUnassignedItemsExtraArguments) : void => {
        dispatch(setSearchTerm(searchTerm));
        dispatch(handleSortingFilteringAndGrouping());
    };
};

const onSelectActiveGroupByOption = (
    groupByOption : GroupByOption,
    productsById : StringValueMap<ProductId, Product>,
    storageAreaIdSetByProductId : StringValueMap<ProductId, StringValueSet<StorageAreaId>>
) : ThunkAction<void, IUnassignedItemsStore, UnassignedItemsActionInterfaces.IUnassignedItemsExtraArguments> => {
    return (dispatch : Dispatch<IUnassignedItemsStore>, getState : () => IUnassignedItemsStore, extraArguments : UnassignedItemsActionInterfaces.IUnassignedItemsExtraArguments) : void => {
        dispatch(setActiveGroupByOption(groupByOption));
        dispatch(handleSortingFilteringAndGrouping());
    };
};

const onSelectActiveColumnSort = (
    columnSort : IColumnSorting,
) : ThunkAction<void, IUnassignedItemsStore, UnassignedItemsActionInterfaces.IUnassignedItemsExtraArguments> => {
    return (dispatch : Dispatch<IUnassignedItemsStore>, getState : () => IUnassignedItemsStore, extraArguments : UnassignedItemsActionInterfaces.IUnassignedItemsExtraArguments) : void => {
        dispatch(setTableSorting(columnSort));
        dispatch(handleSortingFilteringAndGrouping());
    };
};

// TODO: need to fix this setting data on the state before sorting is finished in the case of things getting out of date
const setSortingFilteringAndGroupingOnPropsChange = (
    productIdsForDisplay : StringValueSet<ProductId>,
    productsById : StringValueMap<ProductId, Product>,
    storageAreaIdSetByProductId : StringValueMap<ProductId, StringValueSet<StorageAreaId>>,
    storageAreasById : StringValueMap<StorageAreaId, StorageArea>,
    searchTerm : string | null,
) : ThunkAction<void, IUnassignedItemsStore, UnassignedItemsActionInterfaces.IUnassignedItemsExtraArguments> => {
    return (dispatch : Dispatch<IUnassignedItemsStore>, getState : () => IUnassignedItemsStore, extraArguments : UnassignedItemsActionInterfaces.IUnassignedItemsExtraArguments) : void => {
        // initial: sort by item name, grouping = All Items
        dispatch(setSearchTerm(searchTerm));
        dispatch(setProductsByIdAndStorageAreaIdSetByProductId(productIdsForDisplay, productsById, storageAreaIdSetByProductId, storageAreasById));
        dispatch(handleSortingFilteringAndGrouping());
    };
};

const onCollapseAllClick = (
    nextClickWillCollapseAll : boolean,
) : ThunkAction<void, IUnassignedItemsStore, UnassignedItemsActionInterfaces.IUnassignedItemsExtraArguments> => {
    return (dispatch : Dispatch<IUnassignedItemsStore>, getState : () => IUnassignedItemsStore, extraArguments : UnassignedItemsActionInterfaces.IUnassignedItemsExtraArguments) : void => {
        dispatch(setDefaultPanelIsOpen(!nextClickWillCollapseAll));
    };
};

export const UnassignedItemsActions : UnassignedItemsActionInterfaces.IUnassignedItemsActionCreatorsMapObject = {
    setVisibleDropdownRow,
    setMoreOptionsIsShownForProductIds,
    setComponentIsShown,
    setSelectedProductIds,
    setActiveGroupByOption,
    setSortedGroupNamesAndSortedProductIdsToDisplayByGroupName,
    setProductsByIdAndStorageAreaIdSetByProductId,
    setPanelIsOpenFromUserInputForGroupNames,

    // thunk actions
    onSelectActiveGroupByOption,
    setSortingFilteringAndGroupingOnPropsChange,
    onSelectActiveColumnSort,
    onSearchTermChange,
    onCollapseAllClick
};
