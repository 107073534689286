import React from 'react';

import { SortDirection } from 'shared/models/SortDirection';

import 'shared/css/SortableColumnHeader.scss';

export interface IColumnSorting {
    sortedBy : string;
    direction : SortDirection;
}

// TODO Cheezy: Rename onSortChange to onClick
export interface ISortableColumnHeaderProps {
    classes : string | null;
    columnId : string;
    sorting : IColumnSorting;
    onSortChange(columnId : string) : void;
}

// Adapted from OrderHistory ColumnHeader component
export class SortableColumnHeader extends React.Component<ISortableColumnHeaderProps, object> {
    constructor(props : ISortableColumnHeaderProps) {
        super(props);
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    public render() {
        const {
            classes,
            columnId,
            sorting,
        } = this.props;

        let active : string = '';
        let sortDirection : string = 'sorted-down ';
        if (columnId === sorting.sortedBy) {
            active = 'active-sort';
        }
        if (sorting.direction === SortDirection.DESCENDING) {
            sortDirection = 'sorted-up ';
        }

        const extraClasses = classes === null ? '' : classes + ' ';

        return (
            <div
                className={ 'cell cell-ptb sort-table-trigger ' + extraClasses + sortDirection + active }
                onClick={ this.handleOnClick }
            >
                { this.props.children }
            </div>
        );
    }

    private handleOnClick(mouseClickEvent : React.MouseEvent<HTMLDivElement>) {
        mouseClickEvent.preventDefault();
        this.props.onSortChange(this.props.columnId);
    }

}
