import React from 'react';
import MediaQuery from 'react-responsive';

import { Button } from 'shared/components/Button';

import { MAX_TABLET_WIDTH, MIN_DESKTOP_WIDTH } from 'shared/constants';

import { IActionButton } from 'apps/ItemManager/components/BulkActionsPageHeader';
import { Flex } from 'shared/components/FlexLayout/Flex';

// Based on `BulkActionsToolbar` from MyItems
export interface IBulkActionsHeaderProps {
    numberSelected : number;
    bulkActionButtons : Array<IActionButton>;
    onLeaveBulkActions : () => void;
}

export class BulkActionsHeader extends React.Component<IBulkActionsHeaderProps, object> {
    public render() {
        const {
            numberSelected,
            onLeaveBulkActions,
            bulkActionButtons
        } = this.props;

        const buttonElements = bulkActionButtons.map((button, index) => {
            const buttonClass = `bulk-action-button medium ${button.buttonClass}${button.withIcon ? ' with-icon' : '' }`;
            return (
                <React.Fragment key={ index }>
                    <MediaQuery minWidth={ MIN_DESKTOP_WIDTH }>
                        <Button
                            key={ index }
                            buttonClassName={ buttonClass }
                            isDisabled={ false }
                            isLoading={ false }
                            onClick={ button.onClick }
                        >
                            { button.withIcon &&
                                <span className={ `bevicon ${button.iconClass}` } />
                            }
                            <span>{ button.text }</span>
                        </Button>
                    </MediaQuery>
                    <MediaQuery maxWidth={ MAX_TABLET_WIDTH }>
                        <Button
                            key={ index }
                            buttonClassName="bulk-action-button flat with-icon"
                            isDisabled={ false }
                            isLoading={ false }
                            onClick={ button.onClick }
                        >
                            <span className={ `bevicon ${button.iconClass}` } />
                        </Button>
                    </MediaQuery>
                </React.Fragment>
            );
        });

        const closeBulkActionsButton = (
            <Button
                buttonClassName="flat icon bevicon bevico-close bulk-exit"
                isDisabled={ false }
                isLoading={ false }
                onClick={ onLeaveBulkActions }
            />
        );

        return (
            <div className={ `page-header mapping-tool-header ${ numberSelected > 0 ? 'bulk-actions dark' : 'main-view'}` }>
                <Flex direction="row" inline={ true } className="flex-px-2 mobile-table-controls" align="center" grow={ 2 }>
                    <div className="number-selected">
                        <span className="number">{ numberSelected }</span>
                        <span>&nbsp; items selected</span>
                    </div>
                    <div className="bulk-actions-buttons">
                        { buttonElements }
                    </div>
                </Flex>
                { closeBulkActionsButton }
            </div>
        );
    }
}
