import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

// TODO Think about how this can be better
export const getInventoryCountIdValueFromUrlString = (urlString : string) => {
    const urlStringStartingWithInventoryCountId = urlString.split('inventory/detail/view/')[1];
    return urlStringStartingWithInventoryCountId.split('/')[0];
};

// TODO Cheezy move to shared util
export const getNotificationMessageProductIdentifier = (productIds : StringValueSet<ProductId>, productsById : StringValueMap<ProductId, Product>) : string => {
    let notificationMessageProductIdentifier : string;
    if (productIds.size === 1) {
        const productId = Array.from(productIds.values())[0];
        const product = productsById.get(productId);
        if (typeof product === 'undefined') {
            throw new RuntimeException('unexpected');
        }

        notificationMessageProductIdentifier = `${ product.getBrand() } ${ product.getName() } has`.trim();
    } else {
        notificationMessageProductIdentifier = `${ productIds.size } items have`;
    }

    return notificationMessageProductIdentifier;
};
