//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';
import MoneyModel from './money_Model_types';
import InventoryModel from './inventory_Model_types';
import ProductModel from './product_Model_types';



var ProductCostModel = {};
export default ProductCostModel;
ProductCostModel.InventoryIdAndProductIdAndProductCost = function(args) {
  this.inventoryCountId = null;
  this.productId = null;
  this.productCost = null;
  if (args) {
    if (args.inventoryCountId !== undefined && args.inventoryCountId !== null) {
      this.inventoryCountId = new InventoryModel.InventoryCountId(args.inventoryCountId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountId is unset!');
    }
    if (args.productId !== undefined && args.productId !== null) {
      this.productId = new ProductModel.ProductId(args.productId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productId is unset!');
    }
    if (args.productCost !== undefined && args.productCost !== null) {
      this.productCost = new ProductModel.ProductCost(args.productCost);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productCost is unset!');
    }
  }
};
ProductCostModel.InventoryIdAndProductIdAndProductCost.prototype = {};
ProductCostModel.InventoryIdAndProductIdAndProductCost.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountId = new InventoryModel.InventoryCountId();
        this.inventoryCountId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.productId = new ProductModel.ProductId();
        this.productId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.productCost = new ProductModel.ProductCost();
        this.productCost.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductCostModel.InventoryIdAndProductIdAndProductCost.prototype.write = function(output) {
  output.writeStructBegin('InventoryIdAndProductIdAndProductCost');
  if (this.inventoryCountId !== null && this.inventoryCountId !== undefined) {
    output.writeFieldBegin('inventoryCountId', Thrift.Type.STRUCT, 1);
    this.inventoryCountId.write(output);
    output.writeFieldEnd();
  }
  if (this.productId !== null && this.productId !== undefined) {
    output.writeFieldBegin('productId', Thrift.Type.STRUCT, 2);
    this.productId.write(output);
    output.writeFieldEnd();
  }
  if (this.productCost !== null && this.productCost !== undefined) {
    output.writeFieldBegin('productCost', Thrift.Type.STRUCT, 3);
    this.productCost.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

