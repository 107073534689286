import LocationModel from 'gen-thrift/location_Model_types';

import { LocationId } from 'api/Location/model/LocationId';

export class LocationThriftToObjectSerializer {

    ////////////////////////////////////
    // CORE MODELS
    ////////////////////////////////////
    public getLocationId(
        locationId : LocationModel.LocationIdentifier,
    ) : LocationId {
        return new LocationId(locationId.value);
    }
}
