// This component might not be generalized enough to be used everywhere. Feel free to update when using.
import React from 'react';

import { GroupByOption } from 'shared/models/GroupByOption';

import { DropdownMenu, IOption } from 'shared/components/Dropdown/DropdownMenu';

const defaultGroupByOptions : Array<GroupByOption> = [GroupByOption.ALL_ITEMS, GroupByOption.CATEGORY, GroupByOption.DISTRIBUTOR, GroupByOption.ITEM_TYPE];

const iOptionByGroupByOption = {
    [GroupByOption.ALL_ITEMS]: {
        icon : null,
        label : 'Group by All Items',
        value : GroupByOption[GroupByOption.ALL_ITEMS],
    },
    [GroupByOption.CATEGORY]: {
        icon : null,
        label : 'Group by Category',
        value : GroupByOption[GroupByOption.CATEGORY],
    },
    [GroupByOption.DISTRIBUTOR]: {
        icon : null,
        label : 'Group by Vendor',
        value : GroupByOption[GroupByOption.DISTRIBUTOR],
    },
    [GroupByOption.ITEM_TYPE]: {
        icon : null,
        label : 'Group by Subcategory',
        value : GroupByOption[GroupByOption.ITEM_TYPE],
    },
    [GroupByOption.LAST_EDITED]: {
        icon : null,
        label : 'Group by Last Edited',
        value : GroupByOption[GroupByOption.LAST_EDITED],
    },
    [GroupByOption.GL_CODE] : {
        icon : null,
        label : 'Group by GL Code',
        value : GroupByOption[GroupByOption.GL_CODE],
    },
    [GroupByOption.MENU_GROUP] : {
        icon : null,
        label : 'Group by Menu Group',
        value : GroupByOption[GroupByOption.MENU_GROUP],
    }
};

export interface IGroupByDropdown {
    setDropdownOptionsShown : (isShown : boolean) => void;
    setSelectedOption : (groupByOption : GroupByOption) => void;
    dropdownOptionsShown : boolean;
    selectedOption : GroupByOption;
    availableGroupByOptions? : Array<GroupByOption>;
}

export class GroupByDropdown extends React.Component<IGroupByDropdown, object> {
    public render() {
        const {
            selectedOption,
            dropdownOptionsShown,
            availableGroupByOptions,
        } = this.props;

        let optionsForDropdownMenu : Array<IOption>;
        if (typeof availableGroupByOptions === 'undefined') {
            optionsForDropdownMenu = defaultGroupByOptions.map((groupByOption) => iOptionByGroupByOption[groupByOption]);
        } else {
            optionsForDropdownMenu = availableGroupByOptions.map((groupByOption) => iOptionByGroupByOption[groupByOption]);
        }

        const selectedOptionValue = selectedOption;
        return (
            <DropdownMenu
                styleType="outline"
                options={ optionsForDropdownMenu }
                placeholderText=""
                onOptionClick={ this.onOptionClick }
                onClick={ this.onClick }
                onBlur={ this.onBlur }
                dropdownOptionsShown={ dropdownOptionsShown }
                selectedOptionValue={ selectedOptionValue }
            />
        );
    }

    private readonly onClick = () => {
        const {
            dropdownOptionsShown,
        } = this.props;
        this.props.setDropdownOptionsShown(!dropdownOptionsShown);
    }

    private readonly onOptionClick = (option : IOption) => {
        const {
            setDropdownOptionsShown,
            setSelectedOption,
        } = this.props;
        const selectedGroupByOption = option.value as GroupByOption;
        setDropdownOptionsShown(false);
        setSelectedOption(selectedGroupByOption);
    }

    private readonly onBlur = () => {
        this.props.setDropdownOptionsShown(false);
    }

}
