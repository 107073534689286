import { LocationId } from 'api/Location/model/LocationId';
import CoreTimeModel from 'gen-thrift/core_time_Model_types';
// TODO Use different time model

import { UserAccountIdAndTimestamp } from 'api/UserAccount/model/UserAccountIdAndTimestamp';
import { InventoryCountType } from './InventoryCountType';

export class InventoryCountMetadata {

    constructor (
        private readonly inventoryCountTime : CoreTimeModel.TimestampWithMillisecondPrecision,
        private readonly inventoryCountType : InventoryCountType,
        private readonly creationMetadata : UserAccountIdAndTimestamp,
        private readonly finalizationMetadata : UserAccountIdAndTimestamp | null,
        private readonly deletionMetadata : UserAccountIdAndTimestamp | null,
        private readonly name : string,
        private readonly note : string,
        private readonly locationId : LocationId,
    ) { }

    public getInventoryCountTime() : CoreTimeModel.TimestampWithMillisecondPrecision {
        return this.inventoryCountTime;
    }

    public getInventoryCountType() : InventoryCountType {
        return this.inventoryCountType;
    }

    public getCreationMetadata() : UserAccountIdAndTimestamp {
        return this.creationMetadata;
    }

    public getFinalizationMetadata() : UserAccountIdAndTimestamp | null {
        return this.finalizationMetadata;
    }

    public getDeletionMetadata() : UserAccountIdAndTimestamp | null {
        return this.deletionMetadata;
    }

    public getName() : string {
        return this.name;
    }

    public getNote() : string {
        return this.note;
    }

    public getLocationId() : LocationId {
        return this.locationId;
    }
}
