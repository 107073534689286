//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';



var AccountsExceptions = {};
export default AccountsExceptions;
AccountsExceptions.EmailAddressAlreadyAssociatedWithAnAccountException = function(args) {
};
Thrift.inherits(AccountsExceptions.EmailAddressAlreadyAssociatedWithAnAccountException, Thrift.TException);
AccountsExceptions.EmailAddressAlreadyAssociatedWithAnAccountException.prototype.name = 'EmailAddressAlreadyAssociatedWithAnAccountException';
AccountsExceptions.EmailAddressAlreadyAssociatedWithAnAccountException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AccountsExceptions.EmailAddressAlreadyAssociatedWithAnAccountException.prototype.write = function(output) {
  output.writeStructBegin('EmailAddressAlreadyAssociatedWithAnAccountException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

AccountsExceptions.UnknownEmailAddressException = function(args) {
};
Thrift.inherits(AccountsExceptions.UnknownEmailAddressException, Thrift.TException);
AccountsExceptions.UnknownEmailAddressException.prototype.name = 'UnknownEmailAddressException';
AccountsExceptions.UnknownEmailAddressException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AccountsExceptions.UnknownEmailAddressException.prototype.write = function(output) {
  output.writeStructBegin('UnknownEmailAddressException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

AccountsExceptions.UnknownSessionIdentifierException = function(args) {
};
Thrift.inherits(AccountsExceptions.UnknownSessionIdentifierException, Thrift.TException);
AccountsExceptions.UnknownSessionIdentifierException.prototype.name = 'UnknownSessionIdentifierException';
AccountsExceptions.UnknownSessionIdentifierException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AccountsExceptions.UnknownSessionIdentifierException.prototype.write = function(output) {
  output.writeStructBegin('UnknownSessionIdentifierException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

AccountsExceptions.AccountDoesNotHavePermissionException = function(args) {
};
Thrift.inherits(AccountsExceptions.AccountDoesNotHavePermissionException, Thrift.TException);
AccountsExceptions.AccountDoesNotHavePermissionException.prototype.name = 'AccountDoesNotHavePermissionException';
AccountsExceptions.AccountDoesNotHavePermissionException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AccountsExceptions.AccountDoesNotHavePermissionException.prototype.write = function(output) {
  output.writeStructBegin('AccountDoesNotHavePermissionException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

AccountsExceptions.SessionAlreadyTerminatedException = function(args) {
};
Thrift.inherits(AccountsExceptions.SessionAlreadyTerminatedException, Thrift.TException);
AccountsExceptions.SessionAlreadyTerminatedException.prototype.name = 'SessionAlreadyTerminatedException';
AccountsExceptions.SessionAlreadyTerminatedException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AccountsExceptions.SessionAlreadyTerminatedException.prototype.write = function(output) {
  output.writeStructBegin('SessionAlreadyTerminatedException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

AccountsExceptions.UnknownAccountSessionException = function(args) {
};
Thrift.inherits(AccountsExceptions.UnknownAccountSessionException, Thrift.TException);
AccountsExceptions.UnknownAccountSessionException.prototype.name = 'UnknownAccountSessionException';
AccountsExceptions.UnknownAccountSessionException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AccountsExceptions.UnknownAccountSessionException.prototype.write = function(output) {
  output.writeStructBegin('UnknownAccountSessionException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

AccountsExceptions.UnknownActorException = function(args) {
};
Thrift.inherits(AccountsExceptions.UnknownActorException, Thrift.TException);
AccountsExceptions.UnknownActorException.prototype.name = 'UnknownActorException';
AccountsExceptions.UnknownActorException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AccountsExceptions.UnknownActorException.prototype.write = function(output) {
  output.writeStructBegin('UnknownActorException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

AccountsExceptions.AccountIdentifierIsRequiredException = function(args) {
};
Thrift.inherits(AccountsExceptions.AccountIdentifierIsRequiredException, Thrift.TException);
AccountsExceptions.AccountIdentifierIsRequiredException.prototype.name = 'AccountIdentifierIsRequiredException';
AccountsExceptions.AccountIdentifierIsRequiredException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AccountsExceptions.AccountIdentifierIsRequiredException.prototype.write = function(output) {
  output.writeStructBegin('AccountIdentifierIsRequiredException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

AccountsExceptions.SessionIdentifierIsRequiredException = function(args) {
};
Thrift.inherits(AccountsExceptions.SessionIdentifierIsRequiredException, Thrift.TException);
AccountsExceptions.SessionIdentifierIsRequiredException.prototype.name = 'SessionIdentifierIsRequiredException';
AccountsExceptions.SessionIdentifierIsRequiredException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AccountsExceptions.SessionIdentifierIsRequiredException.prototype.write = function(output) {
  output.writeStructBegin('SessionIdentifierIsRequiredException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

AccountsExceptions.UnknownPasswordSettingCredentialException = function(args) {
};
Thrift.inherits(AccountsExceptions.UnknownPasswordSettingCredentialException, Thrift.TException);
AccountsExceptions.UnknownPasswordSettingCredentialException.prototype.name = 'UnknownPasswordSettingCredentialException';
AccountsExceptions.UnknownPasswordSettingCredentialException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AccountsExceptions.UnknownPasswordSettingCredentialException.prototype.write = function(output) {
  output.writeStructBegin('UnknownPasswordSettingCredentialException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

AccountsExceptions.PasswordSettingCredentialExpiredException = function(args) {
};
Thrift.inherits(AccountsExceptions.PasswordSettingCredentialExpiredException, Thrift.TException);
AccountsExceptions.PasswordSettingCredentialExpiredException.prototype.name = 'PasswordSettingCredentialExpiredException';
AccountsExceptions.PasswordSettingCredentialExpiredException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AccountsExceptions.PasswordSettingCredentialExpiredException.prototype.write = function(output) {
  output.writeStructBegin('PasswordSettingCredentialExpiredException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

AccountsExceptions.PasswordSettingCredentialAlreadyUsedException = function(args) {
};
Thrift.inherits(AccountsExceptions.PasswordSettingCredentialAlreadyUsedException, Thrift.TException);
AccountsExceptions.PasswordSettingCredentialAlreadyUsedException.prototype.name = 'PasswordSettingCredentialAlreadyUsedException';
AccountsExceptions.PasswordSettingCredentialAlreadyUsedException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AccountsExceptions.PasswordSettingCredentialAlreadyUsedException.prototype.write = function(output) {
  output.writeStructBegin('PasswordSettingCredentialAlreadyUsedException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

AccountsExceptions.PasswordDoesNotMeetRequirementsException = function(args) {
};
Thrift.inherits(AccountsExceptions.PasswordDoesNotMeetRequirementsException, Thrift.TException);
AccountsExceptions.PasswordDoesNotMeetRequirementsException.prototype.name = 'PasswordDoesNotMeetRequirementsException';
AccountsExceptions.PasswordDoesNotMeetRequirementsException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AccountsExceptions.PasswordDoesNotMeetRequirementsException.prototype.write = function(output) {
  output.writeStructBegin('PasswordDoesNotMeetRequirementsException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

AccountsExceptions.UnknownCredentialsException = function(args) {
};
Thrift.inherits(AccountsExceptions.UnknownCredentialsException, Thrift.TException);
AccountsExceptions.UnknownCredentialsException.prototype.name = 'UnknownCredentialsException';
AccountsExceptions.UnknownCredentialsException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AccountsExceptions.UnknownCredentialsException.prototype.write = function(output) {
  output.writeStructBegin('UnknownCredentialsException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

AccountsExceptions.UnknownAccountIdentifierException = function(args) {
};
Thrift.inherits(AccountsExceptions.UnknownAccountIdentifierException, Thrift.TException);
AccountsExceptions.UnknownAccountIdentifierException.prototype.name = 'UnknownAccountIdentifierException';
AccountsExceptions.UnknownAccountIdentifierException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AccountsExceptions.UnknownAccountIdentifierException.prototype.write = function(output) {
  output.writeStructBegin('UnknownAccountIdentifierException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

AccountsExceptions.ActorIsRequiredException = function(args) {
};
Thrift.inherits(AccountsExceptions.ActorIsRequiredException, Thrift.TException);
AccountsExceptions.ActorIsRequiredException.prototype.name = 'ActorIsRequiredException';
AccountsExceptions.ActorIsRequiredException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AccountsExceptions.ActorIsRequiredException.prototype.write = function(output) {
  output.writeStructBegin('ActorIsRequiredException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

AccountsExceptions.SessionNotPermittedToPerformOperationException = function(args) {
};
Thrift.inherits(AccountsExceptions.SessionNotPermittedToPerformOperationException, Thrift.TException);
AccountsExceptions.SessionNotPermittedToPerformOperationException.prototype.name = 'SessionNotPermittedToPerformOperationException';
AccountsExceptions.SessionNotPermittedToPerformOperationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AccountsExceptions.SessionNotPermittedToPerformOperationException.prototype.write = function(output) {
  output.writeStructBegin('SessionNotPermittedToPerformOperationException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

AccountsExceptions.UnknownEmailAddressVerificationCredentialException = function(args) {
};
Thrift.inherits(AccountsExceptions.UnknownEmailAddressVerificationCredentialException, Thrift.TException);
AccountsExceptions.UnknownEmailAddressVerificationCredentialException.prototype.name = 'UnknownEmailAddressVerificationCredentialException';
AccountsExceptions.UnknownEmailAddressVerificationCredentialException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AccountsExceptions.UnknownEmailAddressVerificationCredentialException.prototype.write = function(output) {
  output.writeStructBegin('UnknownEmailAddressVerificationCredentialException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

AccountsExceptions.ExpiredEmailAddressVerificationCredentialException = function(args) {
};
Thrift.inherits(AccountsExceptions.ExpiredEmailAddressVerificationCredentialException, Thrift.TException);
AccountsExceptions.ExpiredEmailAddressVerificationCredentialException.prototype.name = 'ExpiredEmailAddressVerificationCredentialException';
AccountsExceptions.ExpiredEmailAddressVerificationCredentialException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AccountsExceptions.ExpiredEmailAddressVerificationCredentialException.prototype.write = function(output) {
  output.writeStructBegin('ExpiredEmailAddressVerificationCredentialException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

AccountsExceptions.EmailAddressVerificationCredentialAlreadyUsedException = function(args) {
};
Thrift.inherits(AccountsExceptions.EmailAddressVerificationCredentialAlreadyUsedException, Thrift.TException);
AccountsExceptions.EmailAddressVerificationCredentialAlreadyUsedException.prototype.name = 'EmailAddressVerificationCredentialAlreadyUsedException';
AccountsExceptions.EmailAddressVerificationCredentialAlreadyUsedException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AccountsExceptions.EmailAddressVerificationCredentialAlreadyUsedException.prototype.write = function(output) {
  output.writeStructBegin('EmailAddressVerificationCredentialAlreadyUsedException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

