import React from 'react';

import 'shared/css/Button.scss';

export interface IButtonProps {
    readonly buttonClassName : string;
    readonly isDisabled : boolean;
    readonly isLoading : boolean;
    readonly onClick : (event? : any) => any;
    readonly children? : any;
}

export class Button extends React.Component<IButtonProps, object> {

    public shouldComponentUpdate(nextProps : IButtonProps) {
        return (
            (this.props.buttonClassName !== nextProps.buttonClassName) ||
            (this.props.isDisabled !== nextProps.isDisabled) ||
            (this.props.isLoading !== nextProps.isLoading) ||
            (this.props.onClick !== nextProps.onClick) ||
            (this.props.children !== nextProps.children)
        );
    }

    public render() {
        const {
            buttonClassName,
            isDisabled,
            isLoading,
            onClick,
            children,
        } = this.props;

        let className = `btn bevspot-button ${ buttonClassName }`;
        {
            if (isDisabled) {
                className += ' disabled';
            }

            if (isLoading) {
                className += ' loading';
            }
        }

        return (
            <button
                className={ className }
                disabled={ isDisabled || isLoading }
                onClick={ onClick }
            >
                <span className="button-text-container">
                    { children }
                </span>
                { isLoading &&
                    <div className="loading-spinner-container">
                        <span className="bevicon bevico-spinner2 make-it-spin loading-spinner" />
                    </div>
                }
            </button>
        );
    }
}
