import { StringValueMap } from 'api/Core/StringValueMap';
import { Distributor } from 'api/Distributor/model/Distributor';
import { DistributorId } from 'api/Distributor/model/DistributorId';

export class DistributorNameCollisionException {
    public readonly collidedDistributorsById : StringValueMap<DistributorId, Distributor>;
    public constructor(collidedDistributorsById : StringValueMap<DistributorId, Distributor>) {
        this.collidedDistributorsById = collidedDistributorsById;
    }
}
