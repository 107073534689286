import React from 'react';

import { AxisPosition } from 'shared/models/Charts/Axes/AxisPosition';
import { IAxis } from 'shared/models/Charts/Axes/IAxis';
import { IAxisLine } from 'shared/models/Charts/Axes/IAxisLine';
import { IBoundingBox } from 'shared/models/Charts/IBoundingBox';

import { Axis } from 'shared/components/Charts/Axes/Axis';

import { utils } from 'shared/components/Charts/utils';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export interface IXAxisProps {
    readonly xAxis : IAxis;
    readonly yAxisWidthInPixels : number;
    readonly hasAxisTickOnLeftEdge : boolean;
    readonly hasAxisTickOnRightEdge : boolean;
    readonly boundingBox : IBoundingBox;
}

export class XAxis extends React.Component<IXAxisProps, object> {
    public render() {
        const {
            xAxis,
            yAxisWidthInPixels,
            hasAxisTickOnLeftEdge,
            hasAxisTickOnRightEdge,
            boundingBox,
        } = this.props;

        // This assumes the yAxis is on the left

        utils.ensureBoundingBoxIsValid(boundingBox);

        if (xAxis.axisPosition !== AxisPosition.BOTTOM && xAxis.axisPosition !== AxisPosition.TOP) {
            throw new RuntimeException('unexpected xAxis.axisPosition value');
        }

        if (xAxis.axisPosition === AxisPosition.TOP) {
            throw new RuntimeException('not implemented');
        }

        const numberOfAxisTicks = xAxis.axisTicks.length;

        const y : number = boundingBox.heightInPixels - xAxis.axisLength;
        const heightInPixels : number = xAxis.axisLength;

        let x : number = yAxisWidthInPixels;
        let widthInPixels : number = boundingBox.widthInPixels - yAxisWidthInPixels;
        if (numberOfAxisTicks > 1) {
            const numberOfAxisTicksOutsideOfWidth = (hasAxisTickOnLeftEdge ? .5 : 0) + (hasAxisTickOnRightEdge ? .5 : 0);

            if (numberOfAxisTicksOutsideOfWidth > 0) {
                const widthOfAxisTick : number = widthInPixels / (numberOfAxisTicks - numberOfAxisTicksOutsideOfWidth);

                if (hasAxisTickOnLeftEdge) {
                    x = yAxisWidthInPixels - (widthOfAxisTick / 2);
                }

                widthInPixels = widthOfAxisTick * numberOfAxisTicks;
            }
        }

        let axisLine : IAxisLine | null;
        if (xAxis.showAxisLine) {
            axisLine = {
                startsAtTick: hasAxisTickOnLeftEdge,
                endsAtTick: hasAxisTickOnRightEdge,
            };
        } else {
            axisLine = null;
        }

        return (
            <svg
                x={ x }
                y={ y }
                height={ heightInPixels }
                width={ widthInPixels }
            >
                <Axis
                    axisPosition={ xAxis.axisPosition }
                    axisTicks={ xAxis.axisTicks }
                    labelValue={ xAxis.labelValue }
                    axisLine={ axisLine }
                    boundingBox={ {
                        heightInPixels,
                        widthInPixels,
                    } }
                />
            </svg>
        );
    }
}
