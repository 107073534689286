import { MomentObjectToThriftSerializer } from 'api/Core/serializer/MomentObjectToThriftSerializer';
import { StringValueMap } from 'api/Core/StringValueMap';
import { LocationId } from 'api/Location/model/LocationId';
import { LocationObjectToThriftSerializer } from 'api/Location/serializer/LocationObjectToThriftSerializer';
import { IUserActionLogCommentService } from 'api/Messaging/interfaces/IUserActionLogCommentService';
import { IUserActionLogComment } from 'api/Messaging/model/IUserActionLogComment';
import { UserActionLogCommentId } from 'api/Messaging/model/UserActionLogCommentId';
import { UserActionLogCommentTimestampType } from 'api/Messaging/model/UserActionLogCommentTimestampType';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { UserAccountObjectToThriftSerializer } from 'api/UserAccount/serializer/UserAccountObjectToThriftSerializer';
import { UserAccountThriftToObjectSerializer } from 'api/UserAccount/serializer/UserAccountThriftToObjectSerializer';
import UserActionLogModel from 'gen-thrift/user_action_log_Model_types';
import UserActionLogService from 'gen-thrift/UserActionLogService';
import moment from 'moment-timezone';

export class UserActionLogCommentService implements IUserActionLogCommentService {
    constructor(
        private client : UserActionLogService.UserActionLogServiceClient,
        private userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer,
        private locationObjectToThriftSerializer : LocationObjectToThriftSerializer,
        private userAccountThriftToObjectSerializer : UserAccountThriftToObjectSerializer,
        private readonly momentObjectToThriftSerializer : MomentObjectToThriftSerializer,
    ) {}

    public createComment(session : UserSessionId, locationId : LocationId, value : string, isAdmin : boolean) : Promise<[ UserActionLogCommentId, IUserActionLogComment ]> {
        return new Promise((resolve, reject) => {
            this.client.createComment(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(session),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                value,
                isAdmin,
                (result : UserActionLogModel.UserActionLogComment | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve([
                        new UserActionLogCommentId(result.id),
                        this.serializeUserActionLogCommentFromThrift(result)
                    ]);
                }
            );
        });
    }

    public deleteComment(session : UserSessionId, commentId : UserActionLogCommentId) : Promise<void> {
        return new Promise((resolve, reject) => {
            this.client.deleteComment(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(session),
                commentId.getValue(),
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve();
                }
            );
        });
    }

    public retrieveComments(
        session : UserSessionId,
        locationId : LocationId,
        timeRange : [ moment.Moment, moment.Moment ],
        timestampTypes : Set<UserActionLogCommentTimestampType>
    ) : Promise<StringValueMap<UserActionLogCommentId, IUserActionLogComment>> {
        return new Promise((resolve, reject) => {
            this.client.getCommentsCreatedInTimeRange(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(session),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                this.momentObjectToThriftSerializer.getThriftTimestampFromMoment(timeRange[0]),
                this.momentObjectToThriftSerializer.getThriftTimestampFromMoment(timeRange[1]),
                (result : Array<UserActionLogModel.UserActionLogComment> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    const commentsById : StringValueMap<UserActionLogCommentId, IUserActionLogComment> = new StringValueMap();
                    result.forEach((v) => {
                        commentsById.set(new UserActionLogCommentId(v.id), this.serializeUserActionLogCommentFromThrift(v));
                    });
                    return resolve(commentsById);
                }
            );
        });
    }

    private serializeUserActionLogCommentFromThrift(value : UserActionLogModel.UserActionLogComment) : IUserActionLogComment {
        return {
            userId: this.userAccountThriftToObjectSerializer.getUserAccountId(value.creationMetadata.userAccountIdentifier),
            isAdmin: value.isAdmin,
            value: value.value,
            timeCreated: moment.utc(value.creationMetadata.timestamp.timeSinceUnixEpoch.value),
        };
    }
}
