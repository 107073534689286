import { StringValueMap } from 'api/Core/StringValueMap';

import { MassUnit } from 'api/Product/model/MassUnit';
import { OldPackaging } from 'api/Product/model/OldPackaging';
import { PackagingId } from 'api/Product/model/PackagingId';
import { PackagingUnit } from 'api/Product/model/PackagingUnit';
import { PackagingsAndMappings } from 'api/Product/model/PackagingsAndMappings';
import { PackagingWeight } from 'api/Product/model/PackagingWeight';
import { Price } from 'api/Product/model/Price';
import { CategoryId } from 'api/Product/model/CategoryId';
import { ProductQuantityUnit} from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { oldPackagingUtils } from 'api/Product/utils/oldPackagingUtils';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { UserAccountIdAndTimestamp } from 'api/UserAccount/model/UserAccountIdAndTimestamp';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export class Product {
    constructor(
        private readonly brand : string,
        private readonly name : string,
        private readonly packagingsAndMappings : PackagingsAndMappings,
        private readonly preferredReportingUnit : ProductQuantityUnit,
        private readonly weightsByPackagingId : StringValueMap<PackagingId, PackagingWeight>,
        private readonly productCategoryId : string,
        private readonly categoryId : CategoryId | null,
        private readonly productType : string,
        private readonly unitPrice : Price,
        private readonly depositInDollars : number,
        private readonly sku : string,
        private readonly glCode : string,
        private readonly note : string,
        private readonly lastUpdateEvent : UserAccountIdAndTimestamp
    ) {
        if (name.length === 0) {
            throw new RuntimeException('name must not be an empty string');
        }
    }

    public getBrand() : string {
        return this.brand;
    }

    public getName() : string {
        return this.name;
    }

    public getPackagingsAndMappings() : PackagingsAndMappings {
        return this.packagingsAndMappings;
    }

    public getPreferredReportingUnit() : ProductQuantityUnit {
        return this.preferredReportingUnit;
    }

    public getOldPackaging() : OldPackaging {
        return oldPackagingUtils.getOldPackagingFromPackaging(this.packagingsAndMappings.getPackaging());
    }

    public getWeightsByPackagingId() {
        return this.weightsByPackagingId;
    }

    public getProductCategoryId() : string {
        return this.productCategoryId;
    }

    public getNewProductCategoryId() : CategoryId | null {
        return this.categoryId;
    }

    public getProductType() : string {
        return this.productType;
    }

    public getPriceInUnit(unit : PackagingUnit | MassUnit) : number {
        if (unit === PackagingUnit.CASE) {
            // Lesman 2/8/2018 this is a bit of a hack to allow getting 'case' price of things that do not come in case
            if (this.getOldPackaging().getUnit() !== PackagingUnit.CASE) {
                unit = PackagingUnit.CONTAINER;
            }
        }
        const quantityOfPricingUnitInOutputUnit = oldPackagingUtils.getQuantityOfPackageInUnit(
            this.getOldPackaging(),
            new QuantityInUnit(1, unit),
            this.getUnitPrice().getUnit()
        );

        return quantityOfPricingUnitInOutputUnit.getQuantity() * this.getUnitPrice().getDollarValue();
    }

    // Lesman 2/8/2018 kill after "price unit" is reflected in InfoPopover and AddItemModal
    public getLegacyCasePriceInDollars() : number {
        return this.getPriceInUnit(PackagingUnit.CASE);
    }

    public getUnitPrice() : Price {
        return this.unitPrice;
    }

    public getDepositInDollars() : number {
        return this.depositInDollars;
    }

    public getSku() : string {
        return this.sku;
    }

    public getGLCode() : string {
        return this.glCode;
    }

    public getNote() : string {
        return this.note;
    }

    public getLastUpdateEvent() : UserAccountIdAndTimestamp {
        return this.lastUpdateEvent;
    }

    public equals(other : any) : boolean {
        if (!(other instanceof Product)) {
            return false;
        }

        if ((this.brand === other.getBrand()) &&
            (this.name === other.getName()) &&
            this.packagingsAndMappings.equals(other.getPackagingsAndMappings()) &&
            PackagingUtils.productQuantityUnitsAreEqual(this.preferredReportingUnit, other.getPreferredReportingUnit()) &&
            (this.productCategoryId === other.getProductCategoryId()) &&
            (this.productType === other.getProductType()) &&
            this.unitPrice.equals(other.getUnitPrice()) &&
            (this.depositInDollars === other.getDepositInDollars()) &&
            (this.sku === other.getSku()) &&
            (this.glCode === other.getGLCode()) &&
            (this.note === other.getNote()) &&
            (this.lastUpdateEvent === other.getLastUpdateEvent())
        ) {
            if (this.weightsByPackagingId.size !== other.getWeightsByPackagingId().size) {
                return false;
            }
    
            let weightsByPackageIdIsEqual = true;
            this.weightsByPackagingId.forEach((packagingWeight, packagingId) => {
                weightsByPackageIdIsEqual = weightsByPackageIdIsEqual && (packagingWeight.equals(other.getWeightsByPackagingId().get(packagingId)));
            });

            return weightsByPackageIdIsEqual;
        }

        return false;
    }
}
