import moment from 'moment-timezone';

import CoreTimeModel from 'gen-thrift/core_time_Model_types';

export class MomentObjectToThriftSerializer {

    ////////////////////////////////////
    // MODELS
    ////////////////////////////////////
    public getThriftTimestampFromMoment(date : moment.Moment) : CoreTimeModel.TimestampWithMillisecondPrecision {
        return new CoreTimeModel.TimestampWithMillisecondPrecision({
            timeSinceUnixEpoch : new CoreTimeModel.Milliseconds({ value : date.valueOf() })
        });
    }
}
