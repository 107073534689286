//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


import Thrift from 'thrift';
import InventoryTransferReportModel from './inventory_transfer_report_Model_types';
import InventoryTransferReportExceptions from './inventory_transfer_report_Exceptions_types';
import ProductExceptions from './product_Exceptions_types';
import ProductAmountExceptions from './product_amount_Exceptions_types';
import LocationModel from './location_Model_types';
import LocationExceptions from './location_Exceptions_types';
import CoreTimeModel from './core_time_Model_types';
import UserAccountsModel from './accounts_user_Model_types';
import AccountsExceptions from './accounts_Exceptions_types';
import CoreApiExceptions from './core_api_Exceptions_types';

var InventoryTransferReportService = {};
export default InventoryTransferReportService;
//HELPER FUNCTIONS AND STRUCTURES

InventoryTransferReportService.BreakageReportService_createBreakageReport_args = function(args) {
  this.actor = null;
  this.reportData = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.reportData !== undefined && args.reportData !== null) {
      this.reportData = new InventoryTransferReportModel.BreakageReportInfo(args.reportData);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field reportData is unset!');
    }
  }
};
InventoryTransferReportService.BreakageReportService_createBreakageReport_args.prototype = {};
InventoryTransferReportService.BreakageReportService_createBreakageReport_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.reportData = new InventoryTransferReportModel.BreakageReportInfo();
        this.reportData.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_createBreakageReport_args.prototype.write = function(output) {
  output.writeStructBegin('BreakageReportService_createBreakageReport_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.reportData !== null && this.reportData !== undefined) {
    output.writeFieldBegin('reportData', Thrift.Type.STRUCT, 2);
    this.reportData.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_createBreakageReport_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownActorException = null;
  this.UnknownLocationIdentifierException = null;
  this.actorDoesNotHaveAccessToLocationException = null;
  this.unknownProductIdException = null;
  this.unitIncompatibleWithProductPackagingException = null;
  this.quantityMustBeNonnegativeException = null;
  this.atMostOneQuantityIsAllowedForProductAndUnitException = null;
  this.productNotAssociatedWithLocationException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.UnknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof LocationExceptions.ActorDoesNotHaveAccessToLocationException) {
    this.actorDoesNotHaveAccessToLocationException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof ProductAmountExceptions.UnitIncompatibleWithProductPackagingException) {
    this.unitIncompatibleWithProductPackagingException = args;
    return;
  }
  if (args instanceof ProductAmountExceptions.QuantityMustBeNonnegativeException) {
    this.quantityMustBeNonnegativeException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.AtMostOneQuantityIsAllowedForProductAndUnitException) {
    this.atMostOneQuantityIsAllowedForProductAndUnitException = args;
    return;
  }
  if (args instanceof ProductAmountExceptions.ProductNotAssociatedWithLocationException) {
    this.productNotAssociatedWithLocationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new InventoryTransferReportModel.BreakageReportIdentifier(args.success);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.UnknownLocationIdentifierException !== undefined && args.UnknownLocationIdentifierException !== null) {
      this.UnknownLocationIdentifierException = args.UnknownLocationIdentifierException;
    }
    if (args.actorDoesNotHaveAccessToLocationException !== undefined && args.actorDoesNotHaveAccessToLocationException !== null) {
      this.actorDoesNotHaveAccessToLocationException = args.actorDoesNotHaveAccessToLocationException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.unitIncompatibleWithProductPackagingException !== undefined && args.unitIncompatibleWithProductPackagingException !== null) {
      this.unitIncompatibleWithProductPackagingException = args.unitIncompatibleWithProductPackagingException;
    }
    if (args.quantityMustBeNonnegativeException !== undefined && args.quantityMustBeNonnegativeException !== null) {
      this.quantityMustBeNonnegativeException = args.quantityMustBeNonnegativeException;
    }
    if (args.atMostOneQuantityIsAllowedForProductAndUnitException !== undefined && args.atMostOneQuantityIsAllowedForProductAndUnitException !== null) {
      this.atMostOneQuantityIsAllowedForProductAndUnitException = args.atMostOneQuantityIsAllowedForProductAndUnitException;
    }
    if (args.productNotAssociatedWithLocationException !== undefined && args.productNotAssociatedWithLocationException !== null) {
      this.productNotAssociatedWithLocationException = args.productNotAssociatedWithLocationException;
    }
  }
};
InventoryTransferReportService.BreakageReportService_createBreakageReport_result.prototype = {};
InventoryTransferReportService.BreakageReportService_createBreakageReport_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new InventoryTransferReportModel.BreakageReportIdentifier();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.UnknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.UnknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToLocationException = new LocationExceptions.ActorDoesNotHaveAccessToLocationException();
        this.actorDoesNotHaveAccessToLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.unitIncompatibleWithProductPackagingException = new ProductAmountExceptions.UnitIncompatibleWithProductPackagingException();
        this.unitIncompatibleWithProductPackagingException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.quantityMustBeNonnegativeException = new ProductAmountExceptions.QuantityMustBeNonnegativeException();
        this.quantityMustBeNonnegativeException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.STRUCT) {
        this.atMostOneQuantityIsAllowedForProductAndUnitException = new InventoryTransferReportExceptions.AtMostOneQuantityIsAllowedForProductAndUnitException();
        this.atMostOneQuantityIsAllowedForProductAndUnitException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.STRUCT) {
        this.productNotAssociatedWithLocationException = new ProductAmountExceptions.ProductNotAssociatedWithLocationException();
        this.productNotAssociatedWithLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_createBreakageReport_result.prototype.write = function(output) {
  output.writeStructBegin('BreakageReportService_createBreakageReport_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.UnknownLocationIdentifierException !== null && this.UnknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('UnknownLocationIdentifierException', Thrift.Type.STRUCT, 4);
    this.UnknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToLocationException !== null && this.actorDoesNotHaveAccessToLocationException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToLocationException', Thrift.Type.STRUCT, 5);
    this.actorDoesNotHaveAccessToLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 6);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.unitIncompatibleWithProductPackagingException !== null && this.unitIncompatibleWithProductPackagingException !== undefined) {
    output.writeFieldBegin('unitIncompatibleWithProductPackagingException', Thrift.Type.STRUCT, 7);
    this.unitIncompatibleWithProductPackagingException.write(output);
    output.writeFieldEnd();
  }
  if (this.quantityMustBeNonnegativeException !== null && this.quantityMustBeNonnegativeException !== undefined) {
    output.writeFieldBegin('quantityMustBeNonnegativeException', Thrift.Type.STRUCT, 8);
    this.quantityMustBeNonnegativeException.write(output);
    output.writeFieldEnd();
  }
  if (this.atMostOneQuantityIsAllowedForProductAndUnitException !== null && this.atMostOneQuantityIsAllowedForProductAndUnitException !== undefined) {
    output.writeFieldBegin('atMostOneQuantityIsAllowedForProductAndUnitException', Thrift.Type.STRUCT, 9);
    this.atMostOneQuantityIsAllowedForProductAndUnitException.write(output);
    output.writeFieldEnd();
  }
  if (this.productNotAssociatedWithLocationException !== null && this.productNotAssociatedWithLocationException !== undefined) {
    output.writeFieldBegin('productNotAssociatedWithLocationException', Thrift.Type.STRUCT, 10);
    this.productNotAssociatedWithLocationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_updateBreakageReport_args = function(args) {
  this.actor = null;
  this.reportIdentifier = null;
  this.update = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.reportIdentifier !== undefined && args.reportIdentifier !== null) {
      this.reportIdentifier = new InventoryTransferReportModel.BreakageReportIdentifier(args.reportIdentifier);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field reportIdentifier is unset!');
    }
    if (args.update !== undefined && args.update !== null) {
      this.update = new InventoryTransferReportModel.BreakageReportUpdate(args.update);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field update is unset!');
    }
  }
};
InventoryTransferReportService.BreakageReportService_updateBreakageReport_args.prototype = {};
InventoryTransferReportService.BreakageReportService_updateBreakageReport_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.reportIdentifier = new InventoryTransferReportModel.BreakageReportIdentifier();
        this.reportIdentifier.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.update = new InventoryTransferReportModel.BreakageReportUpdate();
        this.update.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_updateBreakageReport_args.prototype.write = function(output) {
  output.writeStructBegin('BreakageReportService_updateBreakageReport_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.reportIdentifier !== null && this.reportIdentifier !== undefined) {
    output.writeFieldBegin('reportIdentifier', Thrift.Type.STRUCT, 2);
    this.reportIdentifier.write(output);
    output.writeFieldEnd();
  }
  if (this.update !== null && this.update !== undefined) {
    output.writeFieldBegin('update', Thrift.Type.STRUCT, 3);
    this.update.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_updateBreakageReport_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownActorException = null;
  this.unknownReportIdentifierException = null;
  this.actorDoesNotHaveAccessToReportException = null;
  this.unknownProductIdException = null;
  this.unitIncompatibleWithProductPackagingException = null;
  this.quantityMustBeNonnegativeException = null;
  this.quantityOfProductAndUnitDoesNotExistException = null;
  this.atMostOneQuantityIsAllowedForProductAndUnitException = null;
  this.productNotAssociatedWithLocationException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.UnknownReportIdentifierException) {
    this.unknownReportIdentifierException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.ActorDoesNotHaveAccessToReportException) {
    this.actorDoesNotHaveAccessToReportException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof ProductAmountExceptions.UnitIncompatibleWithProductPackagingException) {
    this.unitIncompatibleWithProductPackagingException = args;
    return;
  }
  if (args instanceof ProductAmountExceptions.QuantityMustBeNonnegativeException) {
    this.quantityMustBeNonnegativeException = args;
    return;
  }
  if (args instanceof ProductAmountExceptions.QuantityOfProductAndUnitDoesNotExistException) {
    this.quantityOfProductAndUnitDoesNotExistException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.AtMostOneQuantityIsAllowedForProductAndUnitException) {
    this.atMostOneQuantityIsAllowedForProductAndUnitException = args;
    return;
  }
  if (args instanceof ProductAmountExceptions.ProductNotAssociatedWithLocationException) {
    this.productNotAssociatedWithLocationException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.unknownReportIdentifierException !== undefined && args.unknownReportIdentifierException !== null) {
      this.unknownReportIdentifierException = args.unknownReportIdentifierException;
    }
    if (args.actorDoesNotHaveAccessToReportException !== undefined && args.actorDoesNotHaveAccessToReportException !== null) {
      this.actorDoesNotHaveAccessToReportException = args.actorDoesNotHaveAccessToReportException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.unitIncompatibleWithProductPackagingException !== undefined && args.unitIncompatibleWithProductPackagingException !== null) {
      this.unitIncompatibleWithProductPackagingException = args.unitIncompatibleWithProductPackagingException;
    }
    if (args.quantityMustBeNonnegativeException !== undefined && args.quantityMustBeNonnegativeException !== null) {
      this.quantityMustBeNonnegativeException = args.quantityMustBeNonnegativeException;
    }
    if (args.quantityOfProductAndUnitDoesNotExistException !== undefined && args.quantityOfProductAndUnitDoesNotExistException !== null) {
      this.quantityOfProductAndUnitDoesNotExistException = args.quantityOfProductAndUnitDoesNotExistException;
    }
    if (args.atMostOneQuantityIsAllowedForProductAndUnitException !== undefined && args.atMostOneQuantityIsAllowedForProductAndUnitException !== null) {
      this.atMostOneQuantityIsAllowedForProductAndUnitException = args.atMostOneQuantityIsAllowedForProductAndUnitException;
    }
    if (args.productNotAssociatedWithLocationException !== undefined && args.productNotAssociatedWithLocationException !== null) {
      this.productNotAssociatedWithLocationException = args.productNotAssociatedWithLocationException;
    }
  }
};
InventoryTransferReportService.BreakageReportService_updateBreakageReport_result.prototype = {};
InventoryTransferReportService.BreakageReportService_updateBreakageReport_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownReportIdentifierException = new InventoryTransferReportExceptions.UnknownReportIdentifierException();
        this.unknownReportIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToReportException = new InventoryTransferReportExceptions.ActorDoesNotHaveAccessToReportException();
        this.actorDoesNotHaveAccessToReportException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.unitIncompatibleWithProductPackagingException = new ProductAmountExceptions.UnitIncompatibleWithProductPackagingException();
        this.unitIncompatibleWithProductPackagingException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.quantityMustBeNonnegativeException = new ProductAmountExceptions.QuantityMustBeNonnegativeException();
        this.quantityMustBeNonnegativeException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.STRUCT) {
        this.quantityOfProductAndUnitDoesNotExistException = new ProductAmountExceptions.QuantityOfProductAndUnitDoesNotExistException();
        this.quantityOfProductAndUnitDoesNotExistException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.STRUCT) {
        this.atMostOneQuantityIsAllowedForProductAndUnitException = new InventoryTransferReportExceptions.AtMostOneQuantityIsAllowedForProductAndUnitException();
        this.atMostOneQuantityIsAllowedForProductAndUnitException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.STRUCT) {
        this.productNotAssociatedWithLocationException = new ProductAmountExceptions.ProductNotAssociatedWithLocationException();
        this.productNotAssociatedWithLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_updateBreakageReport_result.prototype.write = function(output) {
  output.writeStructBegin('BreakageReportService_updateBreakageReport_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownReportIdentifierException !== null && this.unknownReportIdentifierException !== undefined) {
    output.writeFieldBegin('unknownReportIdentifierException', Thrift.Type.STRUCT, 4);
    this.unknownReportIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToReportException !== null && this.actorDoesNotHaveAccessToReportException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToReportException', Thrift.Type.STRUCT, 5);
    this.actorDoesNotHaveAccessToReportException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 6);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.unitIncompatibleWithProductPackagingException !== null && this.unitIncompatibleWithProductPackagingException !== undefined) {
    output.writeFieldBegin('unitIncompatibleWithProductPackagingException', Thrift.Type.STRUCT, 7);
    this.unitIncompatibleWithProductPackagingException.write(output);
    output.writeFieldEnd();
  }
  if (this.quantityMustBeNonnegativeException !== null && this.quantityMustBeNonnegativeException !== undefined) {
    output.writeFieldBegin('quantityMustBeNonnegativeException', Thrift.Type.STRUCT, 8);
    this.quantityMustBeNonnegativeException.write(output);
    output.writeFieldEnd();
  }
  if (this.quantityOfProductAndUnitDoesNotExistException !== null && this.quantityOfProductAndUnitDoesNotExistException !== undefined) {
    output.writeFieldBegin('quantityOfProductAndUnitDoesNotExistException', Thrift.Type.STRUCT, 9);
    this.quantityOfProductAndUnitDoesNotExistException.write(output);
    output.writeFieldEnd();
  }
  if (this.atMostOneQuantityIsAllowedForProductAndUnitException !== null && this.atMostOneQuantityIsAllowedForProductAndUnitException !== undefined) {
    output.writeFieldBegin('atMostOneQuantityIsAllowedForProductAndUnitException', Thrift.Type.STRUCT, 10);
    this.atMostOneQuantityIsAllowedForProductAndUnitException.write(output);
    output.writeFieldEnd();
  }
  if (this.productNotAssociatedWithLocationException !== null && this.productNotAssociatedWithLocationException !== undefined) {
    output.writeFieldBegin('productNotAssociatedWithLocationException', Thrift.Type.STRUCT, 11);
    this.productNotAssociatedWithLocationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_updateType_args = function(args) {
  this.actor = null;
  this.reportIdentifier = null;
  this.type = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.reportIdentifier !== undefined && args.reportIdentifier !== null) {
      this.reportIdentifier = new InventoryTransferReportModel.BreakageReportIdentifier(args.reportIdentifier);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field reportIdentifier is unset!');
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field type is unset!');
    }
  }
};
InventoryTransferReportService.BreakageReportService_updateType_args.prototype = {};
InventoryTransferReportService.BreakageReportService_updateType_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.reportIdentifier = new InventoryTransferReportModel.BreakageReportIdentifier();
        this.reportIdentifier.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.type = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_updateType_args.prototype.write = function(output) {
  output.writeStructBegin('BreakageReportService_updateType_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.reportIdentifier !== null && this.reportIdentifier !== undefined) {
    output.writeFieldBegin('reportIdentifier', Thrift.Type.STRUCT, 2);
    this.reportIdentifier.write(output);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.STRING, 3);
    output.writeString(this.type);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_updateType_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownActorException = null;
  this.unknownReportIdentifierException = null;
  this.actorDoesNotHaveAccessToReportException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.UnknownReportIdentifierException) {
    this.unknownReportIdentifierException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.ActorDoesNotHaveAccessToReportException) {
    this.actorDoesNotHaveAccessToReportException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.unknownReportIdentifierException !== undefined && args.unknownReportIdentifierException !== null) {
      this.unknownReportIdentifierException = args.unknownReportIdentifierException;
    }
    if (args.actorDoesNotHaveAccessToReportException !== undefined && args.actorDoesNotHaveAccessToReportException !== null) {
      this.actorDoesNotHaveAccessToReportException = args.actorDoesNotHaveAccessToReportException;
    }
  }
};
InventoryTransferReportService.BreakageReportService_updateType_result.prototype = {};
InventoryTransferReportService.BreakageReportService_updateType_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownReportIdentifierException = new InventoryTransferReportExceptions.UnknownReportIdentifierException();
        this.unknownReportIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToReportException = new InventoryTransferReportExceptions.ActorDoesNotHaveAccessToReportException();
        this.actorDoesNotHaveAccessToReportException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_updateType_result.prototype.write = function(output) {
  output.writeStructBegin('BreakageReportService_updateType_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownReportIdentifierException !== null && this.unknownReportIdentifierException !== undefined) {
    output.writeFieldBegin('unknownReportIdentifierException', Thrift.Type.STRUCT, 4);
    this.unknownReportIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToReportException !== null && this.actorDoesNotHaveAccessToReportException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToReportException', Thrift.Type.STRUCT, 5);
    this.actorDoesNotHaveAccessToReportException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_getBreakageTypesForLocation_args = function(args) {
  this.actor = null;
  this.locationIdentifier = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationIdentifier !== undefined && args.locationIdentifier !== null) {
      this.locationIdentifier = new LocationModel.LocationIdentifier(args.locationIdentifier);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationIdentifier is unset!');
    }
  }
};
InventoryTransferReportService.BreakageReportService_getBreakageTypesForLocation_args.prototype = {};
InventoryTransferReportService.BreakageReportService_getBreakageTypesForLocation_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationIdentifier = new LocationModel.LocationIdentifier();
        this.locationIdentifier.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_getBreakageTypesForLocation_args.prototype.write = function(output) {
  output.writeStructBegin('BreakageReportService_getBreakageTypesForLocation_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationIdentifier !== null && this.locationIdentifier !== undefined) {
    output.writeFieldBegin('locationIdentifier', Thrift.Type.STRUCT, 2);
    this.locationIdentifier.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_getBreakageTypesForLocation_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.LocationIdentifierIsRequiredException = null;
  this.unknownActorException = null;
  this.unknownLocationIdentifierException = null;
  this.actorDoesNotHaveAccessToLocationException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof LocationExceptions.LocationIdentifierIsRequiredException) {
    this.LocationIdentifierIsRequiredException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof LocationExceptions.ActorDoesNotHaveAccessToLocationException) {
    this.actorDoesNotHaveAccessToLocationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [null]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.LocationIdentifierIsRequiredException !== undefined && args.LocationIdentifierIsRequiredException !== null) {
      this.LocationIdentifierIsRequiredException = args.LocationIdentifierIsRequiredException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.actorDoesNotHaveAccessToLocationException !== undefined && args.actorDoesNotHaveAccessToLocationException !== null) {
      this.actorDoesNotHaveAccessToLocationException = args.actorDoesNotHaveAccessToLocationException;
    }
  }
};
InventoryTransferReportService.BreakageReportService_getBreakageTypesForLocation_result.prototype = {};
InventoryTransferReportService.BreakageReportService_getBreakageTypesForLocation_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size48 = 0;
        var _rtmp352;
        this.success = [];
        var _etype51 = 0;
        _rtmp352 = input.readListBegin();
        _etype51 = _rtmp352.etype;
        _size48 = _rtmp352.size;
        for (var _i53 = 0; _i53 < _size48; ++_i53)
        {
          var elem54 = null;
          elem54 = input.readString().value;
          this.success.push(elem54);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.LocationIdentifierIsRequiredException = new LocationExceptions.LocationIdentifierIsRequiredException();
        this.LocationIdentifierIsRequiredException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToLocationException = new LocationExceptions.ActorDoesNotHaveAccessToLocationException();
        this.actorDoesNotHaveAccessToLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_getBreakageTypesForLocation_result.prototype.write = function(output) {
  output.writeStructBegin('BreakageReportService_getBreakageTypesForLocation_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRING, this.success.length);
    for (var iter55 in this.success)
    {
      if (this.success.hasOwnProperty(iter55))
      {
        iter55 = this.success[iter55];
        output.writeString(iter55);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.LocationIdentifierIsRequiredException !== null && this.LocationIdentifierIsRequiredException !== undefined) {
    output.writeFieldBegin('LocationIdentifierIsRequiredException', Thrift.Type.STRUCT, 4);
    this.LocationIdentifierIsRequiredException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 5);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 6);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToLocationException !== null && this.actorDoesNotHaveAccessToLocationException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToLocationException', Thrift.Type.STRUCT, 7);
    this.actorDoesNotHaveAccessToLocationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_getBreakageReportsByIds_args = function(args) {
  this.actor = null;
  this.reportIdentifiers = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.reportIdentifiers !== undefined && args.reportIdentifiers !== null) {
      this.reportIdentifiers = Thrift.copyList(args.reportIdentifiers, [InventoryTransferReportModel.BreakageReportIdentifier]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field reportIdentifiers is unset!');
    }
  }
};
InventoryTransferReportService.BreakageReportService_getBreakageReportsByIds_args.prototype = {};
InventoryTransferReportService.BreakageReportService_getBreakageReportsByIds_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size56 = 0;
        var _rtmp360;
        this.reportIdentifiers = [];
        var _etype59 = 0;
        _rtmp360 = input.readListBegin();
        _etype59 = _rtmp360.etype;
        _size56 = _rtmp360.size;
        for (var _i61 = 0; _i61 < _size56; ++_i61)
        {
          var elem62 = null;
          elem62 = new InventoryTransferReportModel.BreakageReportIdentifier();
          elem62.read(input);
          this.reportIdentifiers.push(elem62);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_getBreakageReportsByIds_args.prototype.write = function(output) {
  output.writeStructBegin('BreakageReportService_getBreakageReportsByIds_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.reportIdentifiers !== null && this.reportIdentifiers !== undefined) {
    output.writeFieldBegin('reportIdentifiers', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.reportIdentifiers.length);
    for (var iter63 in this.reportIdentifiers)
    {
      if (this.reportIdentifiers.hasOwnProperty(iter63))
      {
        iter63 = this.reportIdentifiers[iter63];
        iter63.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_getBreakageReportsByIds_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownActorException = null;
  this.unknownReportIdentifierException = null;
  this.actorDoesNotHaveAccessToReportException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.UnknownReportIdentifierException) {
    this.unknownReportIdentifierException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.ActorDoesNotHaveAccessToReportException) {
    this.actorDoesNotHaveAccessToReportException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [InventoryTransferReportModel.BreakageReportAndMetadata]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.unknownReportIdentifierException !== undefined && args.unknownReportIdentifierException !== null) {
      this.unknownReportIdentifierException = args.unknownReportIdentifierException;
    }
    if (args.actorDoesNotHaveAccessToReportException !== undefined && args.actorDoesNotHaveAccessToReportException !== null) {
      this.actorDoesNotHaveAccessToReportException = args.actorDoesNotHaveAccessToReportException;
    }
  }
};
InventoryTransferReportService.BreakageReportService_getBreakageReportsByIds_result.prototype = {};
InventoryTransferReportService.BreakageReportService_getBreakageReportsByIds_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size64 = 0;
        var _rtmp368;
        this.success = [];
        var _etype67 = 0;
        _rtmp368 = input.readListBegin();
        _etype67 = _rtmp368.etype;
        _size64 = _rtmp368.size;
        for (var _i69 = 0; _i69 < _size64; ++_i69)
        {
          var elem70 = null;
          elem70 = new InventoryTransferReportModel.BreakageReportAndMetadata();
          elem70.read(input);
          this.success.push(elem70);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownReportIdentifierException = new InventoryTransferReportExceptions.UnknownReportIdentifierException();
        this.unknownReportIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToReportException = new InventoryTransferReportExceptions.ActorDoesNotHaveAccessToReportException();
        this.actorDoesNotHaveAccessToReportException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_getBreakageReportsByIds_result.prototype.write = function(output) {
  output.writeStructBegin('BreakageReportService_getBreakageReportsByIds_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter71 in this.success)
    {
      if (this.success.hasOwnProperty(iter71))
      {
        iter71 = this.success[iter71];
        iter71.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownReportIdentifierException !== null && this.unknownReportIdentifierException !== undefined) {
    output.writeFieldBegin('unknownReportIdentifierException', Thrift.Type.STRUCT, 4);
    this.unknownReportIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToReportException !== null && this.actorDoesNotHaveAccessToReportException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToReportException', Thrift.Type.STRUCT, 5);
    this.actorDoesNotHaveAccessToReportException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_getReportsForLocationInPeriod_args = function(args) {
  this.actor = null;
  this.locationIdentifier = null;
  this.periodStartInclusive = null;
  this.periodEndExclusive = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationIdentifier !== undefined && args.locationIdentifier !== null) {
      this.locationIdentifier = new LocationModel.LocationIdentifier(args.locationIdentifier);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationIdentifier is unset!');
    }
    if (args.periodStartInclusive !== undefined && args.periodStartInclusive !== null) {
      this.periodStartInclusive = new CoreTimeModel.TimestampWithMillisecondPrecision(args.periodStartInclusive);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field periodStartInclusive is unset!');
    }
    if (args.periodEndExclusive !== undefined && args.periodEndExclusive !== null) {
      this.periodEndExclusive = new CoreTimeModel.TimestampWithMillisecondPrecision(args.periodEndExclusive);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field periodEndExclusive is unset!');
    }
  }
};
InventoryTransferReportService.BreakageReportService_getReportsForLocationInPeriod_args.prototype = {};
InventoryTransferReportService.BreakageReportService_getReportsForLocationInPeriod_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationIdentifier = new LocationModel.LocationIdentifier();
        this.locationIdentifier.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.periodStartInclusive = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.periodStartInclusive.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.periodEndExclusive = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.periodEndExclusive.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_getReportsForLocationInPeriod_args.prototype.write = function(output) {
  output.writeStructBegin('BreakageReportService_getReportsForLocationInPeriod_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationIdentifier !== null && this.locationIdentifier !== undefined) {
    output.writeFieldBegin('locationIdentifier', Thrift.Type.STRUCT, 2);
    this.locationIdentifier.write(output);
    output.writeFieldEnd();
  }
  if (this.periodStartInclusive !== null && this.periodStartInclusive !== undefined) {
    output.writeFieldBegin('periodStartInclusive', Thrift.Type.STRUCT, 3);
    this.periodStartInclusive.write(output);
    output.writeFieldEnd();
  }
  if (this.periodEndExclusive !== null && this.periodEndExclusive !== undefined) {
    output.writeFieldBegin('periodEndExclusive', Thrift.Type.STRUCT, 4);
    this.periodEndExclusive.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_getReportsForLocationInPeriod_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.LocationIdentifierIsRequiredException = null;
  this.unknownActorException = null;
  this.unknownLocationIdentifierException = null;
  this.actorDoesNotHaveAccessToLocationException = null;
  this.periodStartIsLaterThanEndException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof LocationExceptions.LocationIdentifierIsRequiredException) {
    this.LocationIdentifierIsRequiredException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof LocationExceptions.ActorDoesNotHaveAccessToLocationException) {
    this.actorDoesNotHaveAccessToLocationException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.PeriodStartIsLaterThanEndException) {
    this.periodStartIsLaterThanEndException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [InventoryTransferReportModel.BreakageReportIdentifier]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.LocationIdentifierIsRequiredException !== undefined && args.LocationIdentifierIsRequiredException !== null) {
      this.LocationIdentifierIsRequiredException = args.LocationIdentifierIsRequiredException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.actorDoesNotHaveAccessToLocationException !== undefined && args.actorDoesNotHaveAccessToLocationException !== null) {
      this.actorDoesNotHaveAccessToLocationException = args.actorDoesNotHaveAccessToLocationException;
    }
    if (args.periodStartIsLaterThanEndException !== undefined && args.periodStartIsLaterThanEndException !== null) {
      this.periodStartIsLaterThanEndException = args.periodStartIsLaterThanEndException;
    }
  }
};
InventoryTransferReportService.BreakageReportService_getReportsForLocationInPeriod_result.prototype = {};
InventoryTransferReportService.BreakageReportService_getReportsForLocationInPeriod_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size72 = 0;
        var _rtmp376;
        this.success = [];
        var _etype75 = 0;
        _rtmp376 = input.readListBegin();
        _etype75 = _rtmp376.etype;
        _size72 = _rtmp376.size;
        for (var _i77 = 0; _i77 < _size72; ++_i77)
        {
          var elem78 = null;
          elem78 = new InventoryTransferReportModel.BreakageReportIdentifier();
          elem78.read(input);
          this.success.push(elem78);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.LocationIdentifierIsRequiredException = new LocationExceptions.LocationIdentifierIsRequiredException();
        this.LocationIdentifierIsRequiredException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToLocationException = new LocationExceptions.ActorDoesNotHaveAccessToLocationException();
        this.actorDoesNotHaveAccessToLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.periodStartIsLaterThanEndException = new InventoryTransferReportExceptions.PeriodStartIsLaterThanEndException();
        this.periodStartIsLaterThanEndException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_getReportsForLocationInPeriod_result.prototype.write = function(output) {
  output.writeStructBegin('BreakageReportService_getReportsForLocationInPeriod_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter79 in this.success)
    {
      if (this.success.hasOwnProperty(iter79))
      {
        iter79 = this.success[iter79];
        iter79.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.LocationIdentifierIsRequiredException !== null && this.LocationIdentifierIsRequiredException !== undefined) {
    output.writeFieldBegin('LocationIdentifierIsRequiredException', Thrift.Type.STRUCT, 4);
    this.LocationIdentifierIsRequiredException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 5);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 6);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToLocationException !== null && this.actorDoesNotHaveAccessToLocationException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToLocationException', Thrift.Type.STRUCT, 7);
    this.actorDoesNotHaveAccessToLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.periodStartIsLaterThanEndException !== null && this.periodStartIsLaterThanEndException !== undefined) {
    output.writeFieldBegin('periodStartIsLaterThanEndException', Thrift.Type.STRUCT, 8);
    this.periodStartIsLaterThanEndException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_deleteReport_args = function(args) {
  this.actor = null;
  this.reportIdentifier = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.reportIdentifier !== undefined && args.reportIdentifier !== null) {
      this.reportIdentifier = new InventoryTransferReportModel.BreakageReportIdentifier(args.reportIdentifier);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field reportIdentifier is unset!');
    }
  }
};
InventoryTransferReportService.BreakageReportService_deleteReport_args.prototype = {};
InventoryTransferReportService.BreakageReportService_deleteReport_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.reportIdentifier = new InventoryTransferReportModel.BreakageReportIdentifier();
        this.reportIdentifier.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_deleteReport_args.prototype.write = function(output) {
  output.writeStructBegin('BreakageReportService_deleteReport_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.reportIdentifier !== null && this.reportIdentifier !== undefined) {
    output.writeFieldBegin('reportIdentifier', Thrift.Type.STRUCT, 2);
    this.reportIdentifier.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_deleteReport_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownActorException = null;
  this.unknownReportIdentifierException = null;
  this.actorDoesNotHaveAccessToReportException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.UnknownReportIdentifierException) {
    this.unknownReportIdentifierException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.ActorDoesNotHaveAccessToReportException) {
    this.actorDoesNotHaveAccessToReportException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.unknownReportIdentifierException !== undefined && args.unknownReportIdentifierException !== null) {
      this.unknownReportIdentifierException = args.unknownReportIdentifierException;
    }
    if (args.actorDoesNotHaveAccessToReportException !== undefined && args.actorDoesNotHaveAccessToReportException !== null) {
      this.actorDoesNotHaveAccessToReportException = args.actorDoesNotHaveAccessToReportException;
    }
  }
};
InventoryTransferReportService.BreakageReportService_deleteReport_result.prototype = {};
InventoryTransferReportService.BreakageReportService_deleteReport_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownReportIdentifierException = new InventoryTransferReportExceptions.UnknownReportIdentifierException();
        this.unknownReportIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToReportException = new InventoryTransferReportExceptions.ActorDoesNotHaveAccessToReportException();
        this.actorDoesNotHaveAccessToReportException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportService_deleteReport_result.prototype.write = function(output) {
  output.writeStructBegin('BreakageReportService_deleteReport_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownReportIdentifierException !== null && this.unknownReportIdentifierException !== undefined) {
    output.writeFieldBegin('unknownReportIdentifierException', Thrift.Type.STRUCT, 4);
    this.unknownReportIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToReportException !== null && this.actorDoesNotHaveAccessToReportException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToReportException', Thrift.Type.STRUCT, 5);
    this.actorDoesNotHaveAccessToReportException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.BreakageReportServiceClient = function(input, output) {
    this.input = input;
    this.output = (!output) ? input : output;
    this.seqid = 0;
};
InventoryTransferReportService.BreakageReportServiceClient.prototype = {};
InventoryTransferReportService.BreakageReportServiceClient.prototype.createBreakageReport = function(actor, reportData, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_createBreakageReport(pendingError, actor, reportData, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_createBreakageReport();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

InventoryTransferReportService.BreakageReportServiceClient.prototype.send_createBreakageReport = function(pendingError, actor, reportData, callback) {
  this.output.writeMessageBegin('createBreakageReport', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryTransferReportService.BreakageReportService_createBreakageReport_args();
  args.actor = actor;
  args.reportData = reportData;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_createBreakageReport() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryTransferReportService.BreakageReportServiceClient.prototype.recv_createBreakageReport = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryTransferReportService.BreakageReportService_createBreakageReport_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.UnknownLocationIdentifierException) {
    throw result.UnknownLocationIdentifierException;
  }
  if (null !== result.actorDoesNotHaveAccessToLocationException) {
    throw result.actorDoesNotHaveAccessToLocationException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.unitIncompatibleWithProductPackagingException) {
    throw result.unitIncompatibleWithProductPackagingException;
  }
  if (null !== result.quantityMustBeNonnegativeException) {
    throw result.quantityMustBeNonnegativeException;
  }
  if (null !== result.atMostOneQuantityIsAllowedForProductAndUnitException) {
    throw result.atMostOneQuantityIsAllowedForProductAndUnitException;
  }
  if (null !== result.productNotAssociatedWithLocationException) {
    throw result.productNotAssociatedWithLocationException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'createBreakageReport failed: unknown result';
};
InventoryTransferReportService.BreakageReportServiceClient.prototype.updateBreakageReport = function(actor, reportIdentifier, update, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_updateBreakageReport(pendingError, actor, reportIdentifier, update, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_updateBreakageReport();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

InventoryTransferReportService.BreakageReportServiceClient.prototype.send_updateBreakageReport = function(pendingError, actor, reportIdentifier, update, callback) {
  this.output.writeMessageBegin('updateBreakageReport', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryTransferReportService.BreakageReportService_updateBreakageReport_args();
  args.actor = actor;
  args.reportIdentifier = reportIdentifier;
  args.update = update;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_updateBreakageReport() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryTransferReportService.BreakageReportServiceClient.prototype.recv_updateBreakageReport = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryTransferReportService.BreakageReportService_updateBreakageReport_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.unknownReportIdentifierException) {
    throw result.unknownReportIdentifierException;
  }
  if (null !== result.actorDoesNotHaveAccessToReportException) {
    throw result.actorDoesNotHaveAccessToReportException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.unitIncompatibleWithProductPackagingException) {
    throw result.unitIncompatibleWithProductPackagingException;
  }
  if (null !== result.quantityMustBeNonnegativeException) {
    throw result.quantityMustBeNonnegativeException;
  }
  if (null !== result.quantityOfProductAndUnitDoesNotExistException) {
    throw result.quantityOfProductAndUnitDoesNotExistException;
  }
  if (null !== result.atMostOneQuantityIsAllowedForProductAndUnitException) {
    throw result.atMostOneQuantityIsAllowedForProductAndUnitException;
  }
  if (null !== result.productNotAssociatedWithLocationException) {
    throw result.productNotAssociatedWithLocationException;
  }
  return;
};
InventoryTransferReportService.BreakageReportServiceClient.prototype.updateType = function(actor, reportIdentifier, type, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_updateType(pendingError, actor, reportIdentifier, type, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_updateType();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

InventoryTransferReportService.BreakageReportServiceClient.prototype.send_updateType = function(pendingError, actor, reportIdentifier, type, callback) {
  this.output.writeMessageBegin('updateType', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryTransferReportService.BreakageReportService_updateType_args();
  args.actor = actor;
  args.reportIdentifier = reportIdentifier;
  args.type = type;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_updateType() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryTransferReportService.BreakageReportServiceClient.prototype.recv_updateType = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryTransferReportService.BreakageReportService_updateType_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.unknownReportIdentifierException) {
    throw result.unknownReportIdentifierException;
  }
  if (null !== result.actorDoesNotHaveAccessToReportException) {
    throw result.actorDoesNotHaveAccessToReportException;
  }
  return;
};
InventoryTransferReportService.BreakageReportServiceClient.prototype.getBreakageTypesForLocation = function(actor, locationIdentifier, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getBreakageTypesForLocation(pendingError, actor, locationIdentifier, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getBreakageTypesForLocation();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

InventoryTransferReportService.BreakageReportServiceClient.prototype.send_getBreakageTypesForLocation = function(pendingError, actor, locationIdentifier, callback) {
  this.output.writeMessageBegin('getBreakageTypesForLocation', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryTransferReportService.BreakageReportService_getBreakageTypesForLocation_args();
  args.actor = actor;
  args.locationIdentifier = locationIdentifier;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getBreakageTypesForLocation() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryTransferReportService.BreakageReportServiceClient.prototype.recv_getBreakageTypesForLocation = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryTransferReportService.BreakageReportService_getBreakageTypesForLocation_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.LocationIdentifierIsRequiredException) {
    throw result.LocationIdentifierIsRequiredException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.actorDoesNotHaveAccessToLocationException) {
    throw result.actorDoesNotHaveAccessToLocationException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getBreakageTypesForLocation failed: unknown result';
};
InventoryTransferReportService.BreakageReportServiceClient.prototype.getBreakageReportsByIds = function(actor, reportIdentifiers, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getBreakageReportsByIds(pendingError, actor, reportIdentifiers, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getBreakageReportsByIds();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

InventoryTransferReportService.BreakageReportServiceClient.prototype.send_getBreakageReportsByIds = function(pendingError, actor, reportIdentifiers, callback) {
  this.output.writeMessageBegin('getBreakageReportsByIds', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryTransferReportService.BreakageReportService_getBreakageReportsByIds_args();
  args.actor = actor;
  args.reportIdentifiers = reportIdentifiers;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getBreakageReportsByIds() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryTransferReportService.BreakageReportServiceClient.prototype.recv_getBreakageReportsByIds = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryTransferReportService.BreakageReportService_getBreakageReportsByIds_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.unknownReportIdentifierException) {
    throw result.unknownReportIdentifierException;
  }
  if (null !== result.actorDoesNotHaveAccessToReportException) {
    throw result.actorDoesNotHaveAccessToReportException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getBreakageReportsByIds failed: unknown result';
};
InventoryTransferReportService.BreakageReportServiceClient.prototype.getReportsForLocationInPeriod = function(actor, locationIdentifier, periodStartInclusive, periodEndExclusive, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getReportsForLocationInPeriod(pendingError, actor, locationIdentifier, periodStartInclusive, periodEndExclusive, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getReportsForLocationInPeriod();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

InventoryTransferReportService.BreakageReportServiceClient.prototype.send_getReportsForLocationInPeriod = function(pendingError, actor, locationIdentifier, periodStartInclusive, periodEndExclusive, callback) {
  this.output.writeMessageBegin('getReportsForLocationInPeriod', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryTransferReportService.BreakageReportService_getReportsForLocationInPeriod_args();
  args.actor = actor;
  args.locationIdentifier = locationIdentifier;
  args.periodStartInclusive = periodStartInclusive;
  args.periodEndExclusive = periodEndExclusive;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getReportsForLocationInPeriod() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryTransferReportService.BreakageReportServiceClient.prototype.recv_getReportsForLocationInPeriod = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryTransferReportService.BreakageReportService_getReportsForLocationInPeriod_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.LocationIdentifierIsRequiredException) {
    throw result.LocationIdentifierIsRequiredException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.actorDoesNotHaveAccessToLocationException) {
    throw result.actorDoesNotHaveAccessToLocationException;
  }
  if (null !== result.periodStartIsLaterThanEndException) {
    throw result.periodStartIsLaterThanEndException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getReportsForLocationInPeriod failed: unknown result';
};
InventoryTransferReportService.BreakageReportServiceClient.prototype.deleteReport = function(actor, reportIdentifier, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_deleteReport(pendingError, actor, reportIdentifier, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_deleteReport();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

InventoryTransferReportService.BreakageReportServiceClient.prototype.send_deleteReport = function(pendingError, actor, reportIdentifier, callback) {
  this.output.writeMessageBegin('deleteReport', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryTransferReportService.BreakageReportService_deleteReport_args();
  args.actor = actor;
  args.reportIdentifier = reportIdentifier;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_deleteReport() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryTransferReportService.BreakageReportServiceClient.prototype.recv_deleteReport = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryTransferReportService.BreakageReportService_deleteReport_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.unknownReportIdentifierException) {
    throw result.unknownReportIdentifierException;
  }
  if (null !== result.actorDoesNotHaveAccessToReportException) {
    throw result.actorDoesNotHaveAccessToReportException;
  }
  return;
};
