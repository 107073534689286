import React from 'react';

export interface IOutsideEventListenerContainerProps {
    onOutsideClick : () => void;
    className? : string;
}

// This is intended to be generic, and props should be added as seen fit
// later improvements: add optional hover listener
export class OutsideEventListenerContainer extends React.Component<IOutsideEventListenerContainerProps, object> {
    private containerDiv : HTMLDivElement | undefined;

    public componentDidMount() {
        // 6/20/17 can't test that this properly triggers the function with karma/sinon
        window.addEventListener('click', this.handleOnOutsideComponentClick);
    }

    public componentWillUnmount() {
        window.removeEventListener('click', this.handleOnOutsideComponentClick);
    }

    public render() {
        const {
            children,
            className
        } = this.props;

        return (
            <div className={ className }ref={ this.getContainerRef }>
                { children }
            </div>
        );
    }

    private readonly getContainerRef = (element : HTMLDivElement) => {
        this.containerDiv = element;
    }

    private readonly handleOnOutsideComponentClick = (event : Event) : void => {
        if (typeof this.containerDiv === 'undefined' || !this.containerDiv.contains(event.target as Element)) {
            this.props.onOutsideClick();
            event.stopPropagation();
        }
    }
}
