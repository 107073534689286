import { CartItemId } from 'api/Cart/model/CartItemId';
import moment from 'moment-timezone';

import { ICartService } from 'api/Cart/interfaces/ICartService';
import { Cart } from 'api/Cart/model/Cart';
import { InventoryColumnOption } from 'api/Cart/model/InventoryColumnOption';
import { UsageColumnOption } from 'api/Cart/model/UsageColumnOption';
import { CartJSONToObjectSerializer } from 'api/Cart/serializer/CartJSONToObjectSerializer';
import { CartObjectToJSONSerializer } from 'api/Cart/serializer/CartObjectToJSONSerializer';
import { StringValueSet } from 'api/Core/StringValueSet';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { DistributorRepId } from 'api/Distributor/model/DistributorRepId';
import { InventoryCountId } from 'api/InventoryCount/model/InventoryCountId';
import { LocationId } from 'api/Location/model/LocationId';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { ProductObjectToJSONSerializer } from 'api/Product/serializer/ProductObjectToJSONSerializer';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';

import { GroupByOption } from 'shared/models/GroupByOption';
import { AjaxUtils } from 'shared/utils/ajaxUtils';
import { DJANGO_DATE_TIME_FIELD_FORMAT } from 'shared/constants';

export class CartServiceImpl implements ICartService {

    constructor (
        private readonly cartJSONToObjectSerializer : CartJSONToObjectSerializer,
        private readonly cartObjectToJSONSerializer : CartObjectToJSONSerializer,
        private readonly productObjectToJSONSerializer : ProductObjectToJSONSerializer,
    ) { }

    public getCartForLocation(
        userSessionId : UserSessionId,
        locationId : LocationId,
    ) : Promise<Cart> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:cart'), queryParameters)
        .then((response) => {
            return this.cartJSONToObjectSerializer.getCart(response);
        });
    }

    public clearCart(
        userSessionId : UserSessionId,
        locationId : LocationId,
    ) : Promise<void> {
        return AjaxUtils.ajaxPost(url('ordering:cart:remove', null, locationId.getValue(), null), {})
        .then((response) => {
            return Promise.resolve();
        });
    }

    public setNoteForDistributor(
        userSessionId : UserSessionId,
        locationId : LocationId,
        distributorId : DistributorId,
        note : string
    ) : Promise<void> {
        const urlValue = url('ordering:cart:add_note', null, locationId.getValue(), null);

        const formData : FormData = new FormData();
        formData.append('distributor_id', distributorId.getValue());
        formData.append('note', note);

        return AjaxUtils.ajaxPostForm(urlValue, formData)
        .then(() => {
            return Promise.resolve();
        });
    }

    public removeDistributorItems(
        userSessionId : UserSessionId,
        locationId : LocationId,
        distributorId : DistributorId | null,
    ) : Promise<void> {
        const urlValue = url('ordering:cart:remove_distributor_items', null, locationId.getValue(), null);

        const formData : FormData = new FormData();

        if (distributorId) {
            formData.append('distributor_id', distributorId.getValue());
        }

        return AjaxUtils.ajaxPostForm(urlValue, formData)
        .then(() => {
            return Promise.resolve();
        });
    }

    public setDistributorItemsIsSavedForLater(
        userSessionId : UserSessionId,
        locationId : LocationId,
        distributorId : DistributorId | null,
        isSavedForLater : boolean
    ) : Promise<void> {
        const urlValue = url('ordering:cart:save_distributor_items_for_later', null, locationId.getValue(), null);

        const formData : FormData = new FormData();

        if (distributorId) {
            formData.append('distributor_id', distributorId.getValue());
        }

        formData.append('saved_for_later', isSavedForLater.toString());

        return AjaxUtils.ajaxPostForm(urlValue, formData)
        .then(() => {
            return Promise.resolve();
        });
    }

    public setPurchaseOrderNumberForDistributor(
        userSessionId : UserSessionId,
        locationId : LocationId,
        distributorId : DistributorId,
        purchaseOrderNumber : string
    ) : Promise<void> {
        const urlValue = url('ordering:cart:add_purchase_order_number', null, locationId.getValue(), null);

        const formData : FormData = new FormData();
        formData.append('distributor_id', distributorId.getValue());
        formData.append('purchase_order_number', purchaseOrderNumber);

        return AjaxUtils.ajaxPostForm(urlValue, formData)
        .then(() => {
            return Promise.resolve();
        });
    }

    public addDistributorRep(
        userSessionId : UserSessionId,
        locationId : LocationId,
        distributorId : DistributorId,
        distributorRepId : DistributorRepId,
    ) : Promise<void> {
        const urlValue = url('ordering:cart:add_rep', null, locationId.getValue(), null);

        const formData : FormData = new FormData();
        formData.append('distributor_id', distributorId.getValue());
        formData.append('rep_id', distributorRepId.getValue());

        return AjaxUtils.ajaxPostForm(urlValue, formData)
        .then(() => {
            return Promise.resolve();
        });
    }

    public removeDistributorRep(
        userSessionId : UserSessionId,
        locationId : LocationId,
        distributorId : DistributorId,
        distributorRepId : DistributorRepId,
    ) : Promise<void> {
        const urlValue = url('ordering:cart:remove_rep', null, locationId.getValue(), null);

        const formData : FormData = new FormData();
        formData.append('distributor_id', distributorId.getValue());
        formData.append('rep_id', distributorRepId.getValue());

        return AjaxUtils.ajaxPostForm(urlValue, formData)
        .then(() => {
            return Promise.resolve();
        });
    }

    public smartCartFromPar(
        userSessionId : UserSessionId,
        locationId : LocationId,
        referenceInventoryId : InventoryCountId | null,
        inventoryColumnOption : InventoryColumnOption,
    ) : Promise<void> {
        const urlValue = url('ordering:cart:smart_par', null, locationId.getValue(), null);

        const formData : FormData = new FormData();
        formData.append('comparison_type', 'par');
        formData.append('reference_inventory_id', referenceInventoryId ? referenceInventoryId.getValue() : '');
        formData.append('reference_inventory_option', this.cartObjectToJSONSerializer.getInventoryColumnOption(inventoryColumnOption));

        return AjaxUtils.ajaxPostForm(urlValue, formData)
        .then(() => {
            return Promise.resolve();
        });
    }

    public smartCartFromWeeklyUsage(
        userSessionId : UserSessionId,
        locationId : LocationId,
        referenceInventoryId : InventoryCountId,
        inventoryColumnOption : InventoryColumnOption,
        usageMultiple : number,
    ) : Promise<void> {
        const urlValue = url('ordering:cart:smart_par', null, locationId.getValue(), null);

        const formData : FormData = new FormData();
        formData.append('comparison_type', 'weekly_usage');
        formData.append('usage_multiple', usageMultiple.toString());
        formData.append('reference_inventory_id', referenceInventoryId ? referenceInventoryId.getValue() : '');
        formData.append('reference_inventory_option', this.cartObjectToJSONSerializer.getInventoryColumnOption(inventoryColumnOption));

        return AjaxUtils.ajaxPostForm(urlValue, formData)
        .then(() => {
            return Promise.resolve();
        });
    }

    public setUserCartBuilderViewPreferences(
        userSessionId : UserSessionId,
        locationId : LocationId,
        groupByOption : GroupByOption,
        usageColumnOption : UsageColumnOption,
        inventoryColumnOption : InventoryColumnOption,
    ) : Promise<void> {
        const urlValue = url('ordering:cart:set_builder_view_preferences', null, locationId.getValue(), null);

        const formData : FormData = new FormData();
        formData.append('group_by', this.cartObjectToJSONSerializer.getGroupByOption(groupByOption));
        formData.append('comparison', this.cartObjectToJSONSerializer.getUsageColumnOption(usageColumnOption));
        formData.append('shown', this.cartObjectToJSONSerializer.getInventoryColumnOption(inventoryColumnOption));

        return AjaxUtils.ajaxPostForm(urlValue, formData)
        .then(() => {
            return Promise.resolve();
        });
    }

    public getAdditionalCartBuilderData(
        userSessionId : UserSessionId,
        locationId : LocationId,
    ) {
        return AjaxUtils.ajaxGet(url('ordering:cart:get_additional_builder_data', null, locationId.getValue(), null))
        .then((response) => {
            return this.cartJSONToObjectSerializer.getAdditionalCartBuilderData(response);
        });
    }

    public addCartItemToCart(
        userSessionId : UserSessionId,
        locationId : LocationId,
        productId : ProductId,
        distributorId : DistributorId | null,
        productQuantityUnit : ProductQuantityUnit,
        quantity : number,
    ) : Promise<Cart> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
            product_id: productId.getValue(),
            distributor_id: distributorId ? distributorId.getValue() : '',
            product_quantity_unit: this.productObjectToJSONSerializer.getProductQuantityUnit(productQuantityUnit),
            quantity
        };
        return AjaxUtils.ajaxPut(urlWithoutRetailerId('api:cart'), null, queryParameters)
        .then((response) => {
            return this.cartJSONToObjectSerializer.getCart(response);
        });
    }

    public updateCartItemInCart(
        userSessionId : UserSessionId,
        locationId : LocationId,
        cartItemId : CartItemId,
        productId : ProductId,
        distributorId : DistributorId | null,
        productQuantityUnit : ProductQuantityUnit,
        quantity : number,
    ) : Promise<Cart> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
            product_id: productId.getValue(),
            distributor_id: distributorId ? distributorId.getValue() : '',
            cart_item_id: cartItemId.getValue(),
            product_quantity_unit: this.productObjectToJSONSerializer.getProductQuantityUnit(productQuantityUnit),
            quantity: quantity.toString()
        };
        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:cart'), null, queryParameters, 'json')
        .then((response) => {
            return this.cartJSONToObjectSerializer.getCart(response);
        });
    }

    public placeOrder(
        userSessionId : UserSessionId,
        locationId : LocationId,
        cartHash: string,
    ) : Promise<void> {
        const urlValue = url('ordering:cart:place_order', null, locationId.getValue(), null);

        const formData : FormData = new FormData();
        formData.append('cart_hash', cartHash);

        return AjaxUtils.ajaxPostForm(urlValue, formData)
        .then((response) => {
            return Promise.resolve();
        })
        .catch((error) => {
            return Promise.reject(error);
        });
    }

    public recordOrder(
        userSessionId : UserSessionId,
        locationId : LocationId,
        cartHash: string,
        orderDate: moment.Moment
    ) : Promise<void> {
        const urlValue = url('ordering:cart:create_order', null, locationId.getValue(), null);

        const formData : FormData = new FormData();
        formData.append('cart_hash', cartHash);
        formData.append('date_time', orderDate.format(DJANGO_DATE_TIME_FIELD_FORMAT));

        return AjaxUtils.ajaxPostForm(urlValue, formData)
        .then((response) => {
            return Promise.resolve();
        })
        .catch((error : Error) => {
            return Promise.reject(error);
        });
    }

    public shareOrder(
        userSessionId : UserSessionId,
        locationId : LocationId,
        userAccountIds : StringValueSet<UserAccountId>,
    ) : Promise<void> {
        const userAccountIdValues : Array<string> = [];
        userAccountIds.forEach((userAccountId) => {
            userAccountIdValues.push(userAccountId.getValue());
        });

        const urlValue = url('ordering:cart:share_order', null, locationId.getValue(), null);

        const formData : FormData = new FormData();
        formData.append('user_ids', userAccountIdValues.join());

        return AjaxUtils.ajaxPostForm(urlValue, formData)
        .then((response) => {
            return Promise.resolve();
        });
    }

    public getUserOptionsForShareOrder(
        userSessionId : UserSessionId,
        locationId : LocationId,
    ) : Promise<Array<{userAccountId : UserAccountId, firstName : string, lastName : string, emailAddress : string}>> {
        return AjaxUtils.ajaxGet(url('ordering:cart:get_users_for_share_order', null, locationId.getValue(), null))
        .then((response : any) => {
            return response.users_by_id.map((userDict : any) => {
                return {
                    userAccountId: new UserAccountId(userDict.user_id),
                    firstName: userDict.first_name,
                    lastName: userDict.last_name,
                    emailAddress: userDict.email_address
                };
            });
        });
    }
}
