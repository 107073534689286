import AccountsUserModel from 'gen-thrift/accounts_user_Model_types';

import { UserAccountId } from '../model/UserAccountId';
import { UserAccountIdAndTimestamp } from '../model/UserAccountIdAndTimestamp';
import { UserEmailAddressVerificationCredential } from '../model/UserEmailAddressVerificationCredential';
import { UserPasswordSettingCredential } from '../model/UserPasswordSettingCredential';
import { UserSessionId } from '../model/UserSessionId';

export class UserAccountThriftToObjectSerializer {

    public getUserAccountId(
        userAccountId : AccountsUserModel.UserAccountIdentifier
    ) : UserAccountId {
        return new UserAccountId(userAccountId.value);
    }

    public getUserEmailAddressVerificationCredential(
        userEmailAddressVerificationCredential : AccountsUserModel.UserEmailAddressVerificationCredential
    ) : UserEmailAddressVerificationCredential {
        return new UserEmailAddressVerificationCredential(userEmailAddressVerificationCredential.value);
    }

    public getUserPasswordSettingCredential(
        userPasswordSettingCredential : AccountsUserModel.UserPasswordSettingCredential
    ) : UserPasswordSettingCredential {
        return new UserPasswordSettingCredential(userPasswordSettingCredential.value);
    }

    public getUserSessionId(
        userSessionId : AccountsUserModel.UserSessionIdentifier
    ) : UserSessionId {
        return new UserSessionId(userSessionId.value);
    }

    public getUserAccountIdentifierAndTimestamp(
        userAccountIdAndTimestamp : AccountsUserModel.UserAccountIdentifierAndTimestamp
    ) : UserAccountIdAndTimestamp {
        return new UserAccountIdAndTimestamp(
            this.getUserAccountId(userAccountIdAndTimestamp.userAccountIdentifier),
            userAccountIdAndTimestamp.timestamp
        );
    }
}
