import moment from 'moment-timezone';
import React from 'react';

import { FreeTrialStatus } from 'api/Location/model/FreeTrialStatus';
import { FreeTrialType } from 'api/Location/model/FreeTrialType';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import './FreeTrialStatusIndicator.scss';

interface IFreeTrialStatusIndicatorProps {
    readonly currentDateTime : moment.Moment;
    readonly freeTrialStatus : FreeTrialStatus;
    readonly size? : 'small' | 'medium' | 'large' | null;
    readonly shouldIncludeLinks : boolean; // consider a better name
}

export class FreeTrialStatusIndicator extends React.Component<IFreeTrialStatusIndicatorProps, object> {
    public render() {
        const {
            currentDateTime,
            freeTrialStatus,
            size,
            shouldIncludeLinks
        } = this.props;

        const freeTrialStatusText = this.getTrialRemainingText(freeTrialStatus, currentDateTime, shouldIncludeLinks);

        let statusClass : string = '';
        if (freeTrialStatus.getFreeTrialType() === FreeTrialType.TIME) {
            statusClass = 'time-based-trial';
        } else if (freeTrialStatus.getIsFreeTrialExpired()) {
            statusClass = 'expired-trial';
        } else {
            const numberActionItemsRemaining = freeTrialStatus.getNumberOfTrialActionsRemaining();
            if (((numberActionItemsRemaining.orders !== null) && (numberActionItemsRemaining.orders <= 1)) ||
                ((numberActionItemsRemaining.inventories !== null) && (numberActionItemsRemaining.inventories <= 1))) {
                statusClass = 'trial-expiring-soon';
            } else {
                statusClass = 'trial-beginning';
            }
        }

        const classNames = `free-trial-status-chip free-trial-status-chip-${ size || 'medium' } ${ statusClass }`;

        return (
            <span className={ classNames }>
                <span className="status dark">{ freeTrialStatusText }</span>
            </span>
        );
    }

    private readonly getTrialRemainingText = (freeTrialStatus : FreeTrialStatus, currentDateTime : moment.Moment, shouldIncludeLinks : boolean) : JSX.Element => {
        let freeTrialStatusText : string | JSX.Element = '';

        const linksForLongText = (
            <span> See <a href={ `/account/billing/r/${ window.GLOBAL_RETAILER_ID }` }>our plans</a>, or <a className="schedulue-a-chat hubspot-meeting-scheduler-modal-show-trigger">schedule a call</a>.</span>
        );

        if (freeTrialStatus.getIsFreeTrialExpired()) {
            freeTrialStatusText = `Expired${ shouldIncludeLinks ? '.' : '' }`;
        } else {
            switch (freeTrialStatus.getFreeTrialType()) {
                case FreeTrialType.TIME:
                    const endDateTime = freeTrialStatus.getEndDate();
                    if (endDateTime === null) {
                        throw new RuntimeException('unexpected');
                    }
                    freeTrialStatusText = `${ currentDateTime.to(endDateTime, true) } left!`;
                    break;
                case FreeTrialType.ACTION:
                    const actionItemsRemaining = freeTrialStatus.getNumberOfTrialActionsRemaining();
                    const {
                        inventories,
                        orders,
                        salesItems,
                    } = actionItemsRemaining;

                    if (shouldIncludeLinks) {
                        freeTrialStatusText = (
                            <span>
                                <a href={ `/inventory/history/r/${ window.GLOBAL_RETAILER_ID }` }>{ `${ (inventories === null) ? 'Unlimited' : inventories } inventor${ inventories === 1 ? 'y' : 'ies' }` }</a>{ ', ' }
                                <a href={ `/ordering/record/r/${ window.GLOBAL_RETAILER_ID }` }>{ `${ (orders === null) ? 'Unlimited' : orders } order${ orders === 1 ? '' : 's' }` }</a>{ ', and ' }
                                <a href={ `/sales/items/overview/r/${ window.GLOBAL_RETAILER_ID }` }>{ `${ (salesItems === null) ? 'Unlimited' : salesItems } recipe${ salesItems === 1 ? '' : 's' }` }</a> left!
                            </span>
                        );
                    } else {
                        freeTrialStatusText = `\
                            ${ (actionItemsRemaining.inventories === null) ? 'Unlimited' : inventories } inventor${ inventories === 1 ? 'y' : 'ies' }, \
                            ${ (actionItemsRemaining.orders === null) ? 'Unlimited' : orders } order${ orders === 1 ? '' : 's' }, and \
                            ${ (actionItemsRemaining.salesItems === null) ? 'Unlimited' : salesItems } recipe${ salesItems === 1 ? '' : 's' } left!`;
                    }
                    break;
            }
        }

        return (
            <span>
                BevSpot Pro Trial: { freeTrialStatusText }
                { shouldIncludeLinks && linksForLongText }
            </span>
        );
    }
}
