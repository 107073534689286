import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { AddressFormActions } from './actions';
import { validateAddressValueByFieldName } from './utils';

import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { OptionsAndLabelNameTuples, Select2DropdownMenu } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { autoCompleteValue, IValidationInputData, ValidationInput } from 'shared/components/ValidationInput';
import { CountryNameByCode, getCountryOptionsForSelect2Dropdown } from 'shared/models/CountryList';

import './AddressForm.scss';

export type AddressFormFieldName = 'streetAddressLine1' | 'streetAddressLine2' | 'city' | 'state' | 'zipCode' | 'country';

export interface IAddressFormProps {
    rowClassName : string;
    addressLine1Label : string;
    addressLine2Label : string;
    zipCodeAndCountryAreDisabled : boolean; // once a retailer is created, not allowing them to change zip and country
    validationInputDataByFieldName : {[fieldName in AddressFormFieldName] : IValidationInputData };
    setValidationInputForField : (fieldName : AddressFormFieldName, validationInputData : IValidationInputData) => void;
    onEnterClick : () => void;
}

export interface IBoundAddressFormProps extends IAddressFormProps {
    dispatch : Dispatch<object>;
}

// this component captures special case behavior for US-based accounts
class AddressForm extends React.Component<IBoundAddressFormProps, object> {

    public render() {
        const {
            rowClassName,
            addressLine1Label,
            addressLine2Label,
            zipCodeAndCountryAreDisabled,
            validationInputDataByFieldName
        } = this.props;

        const countryOptionsAndLabelName : OptionsAndLabelNameTuples = getCountryOptionsForSelect2Dropdown();
        const selectedCountryOptionValue = validationInputDataByFieldName.country.value;

        const selectedCountryOption : IOption = {
            icon: null,
            value: selectedCountryOptionValue,
            label: CountryNameByCode[selectedCountryOptionValue]
        };

        const unitedStatesCityAndStateAreSetAndValid = (validationInputDataByFieldName.city.isValid &&
                                            validationInputDataByFieldName.state.isValid &&
                                            validationInputDataByFieldName.city.value !== '' &&
                                            validationInputDataByFieldName.state.value !== '');

        return (
            <div className="address-form">
                <div className={ rowClassName }>
                    <div className="input-container">
                        { this.makeValidationInput('streetAddressLine1', validationInputDataByFieldName.streetAddressLine1, addressLine1Label) }
                    </div>
                    <div className="input-container">
                        { this.makeValidationInput('streetAddressLine2', validationInputDataByFieldName.streetAddressLine2, addressLine2Label) }
                    </div>
                </div>

                <div className={ rowClassName }>
                    { !zipCodeAndCountryAreDisabled &&
                        <div className="input-container">
                            <label>Country</label>
                            <Select2DropdownMenu
                                isSearchable={ true }
                                buttonShouldDisplaySelectedLabel={ true }
                                selectedOptionHeaderShown={ true }
                                sortedOptionsAndLabelName={ countryOptionsAndLabelName }
                                selectedOption={ selectedCountryOption }
                                onOptionSelect={ this.onSelectCountryOption }
                                placeholderText="Select Country"
                                emptyState={ null }
                                hasCreateCustomOption={ false }
                                createCustomOption={ this.doNothingFunction }
                                customOptionGroupLabel={ null }
                                createCustomOptionButtonLabel={ null }
                            />
                            { !validationInputDataByFieldName.country.isValid &&
                                <div className="validation-input-wrapper">
                                    <div className="error-text">
                                        { validationInputDataByFieldName.country.errorMessage }
                                    </div>
                                </div>
                            }
                        </div>

                    }
                    { zipCodeAndCountryAreDisabled &&
                        <div className="input-container">
                            { this.makeValidationInput('country', validationInputDataByFieldName.country, 'Country', true) }
                        </div>
                    }
                </div>
                { selectedCountryOptionValue === 'US' && // for US-based accounts, infer city/state from zip code
                    <div className={ rowClassName }>
                        <div className="input-container">
                            { this.makeValidationInput('zipCode', validationInputDataByFieldName.zipCode, 'ZIP Code', zipCodeAndCountryAreDisabled) }
                        </div>
                        <div className="input-container">
                            <div>
                                { unitedStatesCityAndStateAreSetAndValid ? `${ validationInputDataByFieldName.city.value }, ${ validationInputDataByFieldName.state.value }` : '' }
                            </div>
                        </div>
                    </div>
                }
                { selectedCountryOptionValue !== 'US' && // for all non-US accounts, have input fields for city and state
                    <div className={ rowClassName }>
                        <div className="input-container">
                            { this.makeValidationInput('city', validationInputDataByFieldName.city, 'City', zipCodeAndCountryAreDisabled) }
                        </div>
                        <div className="input-container">
                            { this.makeValidationInput('state', validationInputDataByFieldName.state, 'State/Province/Region', zipCodeAndCountryAreDisabled) }
                        </div>
                        <div className="input-container">
                            { this.makeValidationInput('zipCode', validationInputDataByFieldName.zipCode, 'ZIP Code', zipCodeAndCountryAreDisabled) }
                        </div>
                    </div>
                }
            </div>
        );

    }

    private makeValidationInput = (fieldName : AddressFormFieldName, fieldData : IValidationInputData, label : string, isDisabled? : boolean) : JSX.Element => {
        const onChange = (event : React.ChangeEvent<HTMLInputElement>) => this.onFormFieldChange(fieldName, event.currentTarget.value);
        const onBlur = (event : React.FocusEvent<HTMLInputElement>) => this.onFormFieldBlur(fieldName, event.currentTarget.value);
        const onEnterClick = (event : React.FormEvent<HTMLInputElement>) => this.props.onEnterClick();

        let autoComplete : autoCompleteValue | null = null;
        switch (fieldName) {
            case 'streetAddressLine1':
                autoComplete = 'address-line1';
                break;
            case 'streetAddressLine2':
                autoComplete = 'address-line2';
                break;
            case 'zipCode':
                autoComplete = 'postal-code';
                break;
        }

        let fieldValue : string = fieldData.value;

        if (fieldName === 'country') {
            const countryName = CountryNameByCode[fieldData.value];
            fieldValue = countryName;
        }

        return (
            <ValidationInput
                key={ fieldName }
                inputClassName=""
                type="text"
                autoFocus={ false }
                autoComplete={ autoComplete }
                errorMessage={ fieldData.errorMessage }
                isValid={ fieldData.isValid }
                handleBlur={ onBlur }
                handleFocus={ null }
                handleChange={ onChange }
                handleEnterClick={ onEnterClick }
                label={ label }
                hintText={ null }
                value={ fieldValue }
                isDisabled={ isDisabled }
            />
        );
    }

    private readonly onFormFieldChange = (field : AddressFormFieldName, value : string) => {
        const {
            validationInputDataByFieldName,
            setValidationInputForField,
        } = this.props;

        const validationResult = validateAddressValueByFieldName(field, value, validationInputDataByFieldName.country.value);
        setValidationInputForField(field, { value, isValid: validationResult.isValid, errorMessage: validationResult.errorMessage });
    }

    private readonly onFormFieldBlur = (field : AddressFormFieldName, value : string) => {
        const {
            dispatch,
            validationInputDataByFieldName,
            setValidationInputForField,
        } = this.props;

        dispatch(AddressFormActions.onFormFieldBlur(field, value, validationInputDataByFieldName.country.value))
        .then((updatedFieldsAndValidationInputs) => {
            updatedFieldsAndValidationInputs.forEach((fieldAndValidationInputTuple) => {
                setValidationInputForField(fieldAndValidationInputTuple[0], fieldAndValidationInputTuple[1]);
            });
        });
    }

    private readonly doNothingFunction = () => undefined;

    private readonly onSelectCountryOption = (optionValue : string) => {
        this.onFormFieldBlur('country', optionValue);
    }
}

interface IStateProps {}

const mapStateToProps = (state : IStateProps) : IStateProps => {
    return {};
};

export const ConnectedAddressForm = connect<object, object, object>(mapStateToProps)(AddressForm);
