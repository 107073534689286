import React from 'react';

import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { OptionsAndLabelNameTuples, Select2DropdownMenu, } from 'shared/components/Select2Dropdown/Select2DropdownMenu';

export interface ISalesItemFlagSelectorProps {
    selectedFlagValue : string | null;
    onFlagChange : (flagValue : string | null) => void;
}

export const availableSalesItemFlags : Array<string> = [
    'Needs Confirmation',
    'Highest Priority',
    'Save for CS Rep',
    'Confirm Serving Size',
    'Needs Recipe/Ingredients',
    'Recipe Needs Specific Product',
    'Pricing Issues',
    'Product Not in Inventory',
    'Multiple of Same Product',
    'Non-Alch/Not in Inventory',
    'Upcharge Modifier',
    'No Price Modifier',
    'Other'
];
const availbleFlagsAsOptions : Array<IOption> = availableSalesItemFlags.map((value) => {
    return {
        icon: null,
        label: value,
        value
    };
});

export class SalesItemFlagSelector extends React.Component<ISalesItemFlagSelectorProps, object> {
    private readonly sortedOptionsAndLabelName : OptionsAndLabelNameTuples = [[null, [
        {
            icon: null,
            label: 'Not Flagged',
            value: '', // this would ideally be null, but need to discuss
        },
        ...availbleFlagsAsOptions
    ]]];

    public render() {
        const {
            selectedFlagValue
        } = this.props;

        const selectedOption = selectedFlagValue === null ? null : {
            icon: null,
            label: selectedFlagValue,
            value: selectedFlagValue,
        };

        return (
            <div className="sales-item-form-section sales-item-flag-selector">
                <div className="label">
                    CS Flag Status:
                </div>
                <Select2DropdownMenu
                    onOptionSelect={ this.handleOnFlagChange }
                    sortedOptionsAndLabelName={ this.sortedOptionsAndLabelName }
                    selectedOption={ selectedOption }
                    placeholderText="Not Flagged"
                    hasCreateCustomOption={ false }
                    createCustomOption={ null }
                    buttonShouldDisplaySelectedLabel={ true }
                    emptyState={ null }
                    isSearchable={ false }
                    selectedOptionHeaderShown={ false }
                    customOptionGroupLabel={ null }
                    createCustomOptionButtonLabel={ null }
                />
            </div>
        );
    }

    private readonly handleOnFlagChange = (selectedFlag : string | null) => {
        this.props.onFlagChange(selectedFlag === '' ? null : selectedFlag); // would ideally not need to account for ''
    }
}
