import { StringValueMap } from 'api/Core/StringValueMap';
import { InventoryCountId } from 'api/InventoryCount/model/InventoryCountId';

import { AjaxUtils } from 'shared/utils/ajaxUtils';

import { ICategoryLevelSalesReportConfigurationService } from '../interfaces/ICategoryLevelSalesReportConfigurationService';
import { CategoryLevelSalesReportConfiguration } from '../model/CategoryLevelSalesReportConfiguration';
import { CategoryLevelSalesReportConfigurationId } from '../model/CategoryLevelSalesReportConfigurationId';
import {
    CategoryLevelSalesReportConfigurationSerializer,
    ICategoryLevelSalesReportConfigurationJSONObject
} from '../serializer/CategoryLevelSalesReportConfigurationSerializer';

export class CategoryLevelSalesReportConfigurationServiceImpl implements ICategoryLevelSalesReportConfigurationService {
    private readonly serializer : CategoryLevelSalesReportConfigurationSerializer;

    constructor(
        serializer : CategoryLevelSalesReportConfigurationSerializer,
    ) {
        this.serializer = serializer;
    }

    public getCategoryLevelSalesReportConfigurationsByIdForInventoryCount(
        inventoryCountId : InventoryCountId,
    ) : Promise<StringValueMap<CategoryLevelSalesReportConfigurationId, CategoryLevelSalesReportConfiguration>> {
        const queryParameters = {
            inventory_id: inventoryCountId.getValue()
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:category_level_revenue_and_cost_report_configuration'), queryParameters)
        .then((response : {category_level_revenue_and_cost_report_configurations_by_id : {[reportConfigurationId : string] : ICategoryLevelSalesReportConfigurationJSONObject}}) => {
            return Promise.resolve(this.serializer.getCategoryLevelSalesReportConfigurationsById(response.category_level_revenue_and_cost_report_configurations_by_id));
        })
        .catch((error : Error) => {
            return Promise.reject(error);
        });
    }
}
