//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';
import LocationModel from './location_Model_types';
import UserAccountsModel from './accounts_user_Model_types';



var UserActionLogModel = {};
export default UserActionLogModel;
UserActionLogModel.UserActionLogComment = function(args) {
  this.id = null;
  this.locationId = null;
  this.value = null;
  this.isAdmin = null;
  this.creationMetadata = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field id is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field value is unset!');
    }
    if (args.isAdmin !== undefined && args.isAdmin !== null) {
      this.isAdmin = args.isAdmin;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field isAdmin is unset!');
    }
    if (args.creationMetadata !== undefined && args.creationMetadata !== null) {
      this.creationMetadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp(args.creationMetadata);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field creationMetadata is unset!');
    }
  }
};
UserActionLogModel.UserActionLogComment.prototype = {};
UserActionLogModel.UserActionLogComment.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.BOOL) {
        this.isAdmin = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.creationMetadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp();
        this.creationMetadata.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserActionLogModel.UserActionLogComment.prototype.write = function(output) {
  output.writeStructBegin('UserActionLogComment');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 3);
    output.writeString(this.value);
    output.writeFieldEnd();
  }
  if (this.isAdmin !== null && this.isAdmin !== undefined) {
    output.writeFieldBegin('isAdmin', Thrift.Type.BOOL, 4);
    output.writeBool(this.isAdmin);
    output.writeFieldEnd();
  }
  if (this.creationMetadata !== null && this.creationMetadata !== undefined) {
    output.writeFieldBegin('creationMetadata', Thrift.Type.STRUCT, 5);
    this.creationMetadata.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

