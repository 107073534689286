import moment from 'moment';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { ILocationProductService } from 'api/Location/interfaces/ILocationProductService';
import { LocationId } from 'api/Location/model/LocationId';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductJSONToObjectSerializer } from 'api/Product/serializer/ProductJSONToObjectSerializer';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { AjaxUtils } from 'shared/utils/ajaxUtils';

export class LocationProductServiceImpl implements ILocationProductService {

    constructor (
        private readonly productJSONToObjectSerializer : ProductJSONToObjectSerializer,
    ) { }

    public getProductIds(
        userSessionId : UserSessionId,
        locationId : LocationId,
    ) : Promise<StringValueSet<ProductId>> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:product'), queryParameters)
        .then((response) => {
            const productIds : Array<ProductId> = [];
            response.forEach((productIdValue : string) => {
                productIds.push(this.productJSONToObjectSerializer.getProductId(productIdValue));
            });

            return Promise.resolve(new StringValueSet<ProductId>(productIds));
        });
    }

    public getProductsForLocationMatchingSearchParameters(
        userSessionId : UserSessionId,
        locationId : LocationId,
        maxResultCount : number,
        inclusiveStartIndex : number,
        searchTerm : string,
        filterTermsById : { [filterId : string] : string }
    ) : Promise<{ productIds: Array<ProductId>, productsById: StringValueMap<ProductId, Product>, matchCount: number }> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
            max_result_count: maxResultCount,
            inclusive_start_index: inclusiveStartIndex,
            search_term: searchTerm,
            filter_terms_by_id: JSON.stringify(filterTermsById),
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:product'), queryParameters)
        .then((response) => {
            const productIdValues : Array<string> = response.product_ids;
            const productsByIdJSON = response.products_by_id;
            const matchCount = response.total_matched;
            // const filterCountByFilterIdAndTermJSON = response.filter_count_by_filter_id_and_term;

            if ((typeof productIdValues === 'undefined') || (typeof productsByIdJSON === 'undefined') || (typeof matchCount === 'undefined')) {
                throw new RuntimeException('unexpected');
            }

            const productIds : Array<ProductId> = productIdValues.map((productIdValue : string) => {
                return this.productJSONToObjectSerializer.getProductId(productIdValue);
            });

            return Promise.resolve({
                productIds,
                productsById: this.productJSONToObjectSerializer.getProductsById(productsByIdJSON),
                matchCount
            });
        });
    }

    public getActiveProductIds(
        userSessionId : UserSessionId,
        locationId : LocationId,
    ) : Promise<StringValueSet<ProductId>> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:inventory_list'), queryParameters)
        .then((response) => {
            const activeProductIdValues : Array<string> = response.inventory_list;

            if (typeof activeProductIdValues === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            const productIds : Array<ProductId> = activeProductIdValues.map((productIdValue : string) => {
                return this.productJSONToObjectSerializer.getProductId(productIdValue);
            });

            return Promise.resolve(new StringValueSet<ProductId>(productIds));
        });
    }

    public getDeletionEventsByProductId(
        userSessionId : UserSessionId,
        locationId : LocationId,
    ) : Promise<StringValueMap<ProductId, moment.Moment>> {
        const queryParameters = {
            retailer_id: locationId.getValue()
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:product_deleted_from_search'), queryParameters)
        .then((response) => {
            const deletedFromSearchEventByProductId : { [productIdValue : string] : { time_deleted : string } } = response.deleted_from_search_event_by_product_id;
            if (typeof deletedFromSearchEventByProductId === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            const deletionTimeByProductId = new StringValueMap<ProductId, moment.Moment>();
            Object.keys(deletedFromSearchEventByProductId).forEach((productIdValue : string) => {
                const deletionEvent = deletedFromSearchEventByProductId[productIdValue];
                deletionTimeByProductId.set(
                    this.productJSONToObjectSerializer.getProductId(productIdValue),
                    moment.utc(deletionEvent.time_deleted)
                );
            });

            return Promise.resolve(deletionTimeByProductId);
        });
    }

    public setProductAsActive(
        userSessionId : UserSessionId,
        locationId : LocationId,
        productId : ProductId
    ) : Promise<void> {
        const queryParameters = {
            product_id: productId.getValue(),
        };

        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:inventory_list_item'), null, queryParameters)
        .then(() => Promise.resolve())
        .catch((error : Error) => Promise.reject(error));
    }

    // TODO Remove after bulk call is fixed on the backend
    public setProductAsInactive(
        userSessionId : UserSessionId,
        locationId : LocationId,
        productId : ProductId
    ) : Promise<void> {
        const queryParameters = [productId.getValue()];

        return AjaxUtils.ajaxDelete(urlWithoutRetailerId('api:inventory_list_item'), {}, queryParameters);
    }

    public setProductsAsInactive(
        userSessionId : UserSessionId,
        locationId : LocationId,
        productIds : StringValueSet<ProductId>
    ) : Promise<void> {
        const queryParameters : Array<string> = [];
        productIds.forEach((productId) => {
            queryParameters.push(productId.getValue());
        });

        return AjaxUtils.ajaxDelete(urlWithoutRetailerId('api:inventory_list_item'), {}, queryParameters);
    }

    public deleteProducts(
        userSessionId : UserSessionId,
        locationId : LocationId,
        productIds : StringValueSet<ProductId>
    ) : Promise<void> {
        const queryParameters = {
            retailer_id: locationId.getValue()
        };

        const productIdValues : Array<string> = [];
        productIds.forEach((productId : ProductId) => {
            productIdValues.push(productId.getValue());
        });

        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:batch_product_delete'), productIdValues, queryParameters, 'text');
    }

    public restoreDeletedProduct(
        userSessionId : UserSessionId,
        locationId : LocationId,
        productId : ProductId
    ) : Promise<void> {
        const queryParameters = {
            product_id: productId.getValue()
        };

        return AjaxUtils.ajaxPut(urlWithoutRetailerId('api:restore_product_deleted_from_search'), null, queryParameters);
    }
}
