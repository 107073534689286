import { CartItem } from 'api/Cart/model/CartItem';
import { StringValueMap } from 'api/Core/StringValueMap';
import { LocationId } from 'api/Location/model/LocationId';
import { DeliveryId } from 'api/Ordering/model/DeliveryId';
import { ProductId } from 'api/Product/model/ProductId';

export class DistributorDependentObjectSet {
    constructor (
        private readonly deliveryIds : Array<DeliveryId>,
        private readonly productIds : Array<ProductId>,
        private readonly retailerIds : Array<LocationId>,
        private readonly cartIds : Array<LocationId>,
        private readonly cartItemsByCartId : StringValueMap<LocationId, Array<CartItem>>
    ) {}

    public getDeliveryIds() : Array<DeliveryId> {
        return this.deliveryIds;
    }

    public getProductIds() : Array<ProductId> {
        return this.productIds;
    }

    public getRetailerIds() : Array<LocationId> {
        return this.retailerIds;
    }

    public getCartIds() : Array<LocationId> {
        return this.cartIds;
    }

    public getCartItemsByCartId() : StringValueMap<LocationId, Array<CartItem>> {
        return this.cartItemsByCartId;
    }

    public equals(other : DistributorDependentObjectSet) : boolean {
        return this.getDeliveryIds() === other.getDeliveryIds() &&
            this.getProductIds() === other.getProductIds() &&
            this.getRetailerIds() === other.getRetailerIds() &&
            this.getDeliveryIds() === other.getDeliveryIds() &&
            this.getCartItemsByCartId() === other.getCartItemsByCartId();
    }
}
