import { IIncidentBannerService } from 'shared/components/IncidentBanner/interfaces/IIncidentBannerService';
import { IncidentBannerData } from 'shared/components/IncidentBanner/model/IncidentBannerData';
import { AjaxUtils } from 'shared/utils/ajaxUtils';

export class IncidentBannerServiceImpl implements IIncidentBannerService {

    public getIncidentBannerData(
    ) : Promise<IncidentBannerData> {
        interface IIncidentBannerDataJSON {
            message : string | null;
            isShown : boolean;
        }

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:incident_banner'))
        .then((response : IIncidentBannerDataJSON) => {
            return new IncidentBannerData(
                response.message,
                response.isShown
            );
        });
    }
}
