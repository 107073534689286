import moment from 'moment-timezone';

import { InventoryCountId } from 'api/InventoryCount/model/InventoryCountId';

export class CategoryLevelSalesReportConfiguration {
    constructor(
        private readonly creationTime : moment.Moment,
        private readonly startingInventoryId : InventoryCountId,
        private readonly endingInventoryId : InventoryCountId,
    ) {}

    public getCreationTime() : moment.Moment {
        return this.creationTime;
    }

    public getStartingInventoryId() : InventoryCountId {
        return this.startingInventoryId;
    }

    public getEndingInventoryId() : InventoryCountId {
        return this.endingInventoryId;
    }
}
