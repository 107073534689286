import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { Product } from 'api/Product/model/Product';
import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { productCostUtils } from 'api/Product/utils/productCostUtils';
import { UnitUtils } from 'api/Product/utils/UnitUtils';
import { SalesItem } from 'api/SalesItem/model/SalesItem';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemWithMetadata } from 'api/SalesItem/model/SalesItemWithMetadata';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { SalesQuantityAndUnit } from '../model/SalesQuantityAndUnit';

const getTotalCostOfProductsInSalesItem = (
    salesItem : SalesItem,
    productsById : StringValueMap<ProductId, Product>,
    productCostsByProductId : StringValueMap<ProductId, ProductCost>,
) : number => {
    let totalCostOfProductsInItem : number = 0;
    salesItem.getComponentQuantityOfProductByProductId().forEach((quantityOfProduct, productId) => {
        totalCostOfProductsInItem += getCostOfProductComponentInSalesItem(productId, quantityOfProduct, productsById, productCostsByProductId);
    });
    return totalCostOfProductsInItem;
};

const calculateCostOfSalesItem = (
    salesItemId : SalesItemId,
    salesItem : SalesItem,
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>,
    productsById : StringValueMap<ProductId, Product>,
    productCostsByProductId : StringValueMap<ProductId, ProductCost>,
    alreadyCalculatedCostsBySalesItemId : StringValueMap<SalesItemId, number | null>, // optimization: keep track of things we've already successfully calculated and just look up instead of re-calculating
    salesItemsSeen : StringValueSet<SalesItemId>,
) : number | null => { // null = infinite loop detected
    const alreadyCalculatedCost = alreadyCalculatedCostsBySalesItemId.get(salesItemId);
    if (typeof alreadyCalculatedCost !== 'undefined') {
        return alreadyCalculatedCost;
    }

    if (salesItemsSeen.has(salesItemId)) {
        // if we've already seen this id, we're in an infinite loop for now and say cost not calculable
        // TODO future: can try to optimize solving this loop
        return null;
    }
    salesItemsSeen.add(salesItemId);

    const totalCostOfProductsInItem = getTotalCostOfProductsInSalesItem(salesItem, productsById, productCostsByProductId);

    let totalCostOfSalesItemsInItem : number | null = 0;
    salesItem.getComponentServingsBySalesItemId().forEach((numberOfServings, componentSalesItemId) => {
        const componentSalesItemWithMetadata = salesItemsById.get(componentSalesItemId);
        if (typeof componentSalesItemWithMetadata === 'undefined') {
            throw new RuntimeException('unexpected');
        }

        const costOfSalesItem = calculateCostOfSalesItem(
            componentSalesItemId, componentSalesItemWithMetadata.getSalesItem(),
            salesItemsById, productsById, productCostsByProductId,
            alreadyCalculatedCostsBySalesItemId, new StringValueSet(salesItemsSeen));

        if (costOfSalesItem !== null && totalCostOfSalesItemsInItem !== null) {
            const costOfComponentQuantity = getNotNullCostOfComponentSalesItem(componentSalesItemWithMetadata.getSalesItem(), numberOfServings, costOfSalesItem);
            totalCostOfSalesItemsInItem += costOfComponentQuantity;
        } else {
            totalCostOfSalesItemsInItem = null;
        }
    });

    let totalCost : number | null;
    if (totalCostOfSalesItemsInItem === null) {
        totalCost = null;
    } else {
        totalCost = totalCostOfProductsInItem + totalCostOfSalesItemsInItem + salesItem.getMiscellaneousCost();
    }

    alreadyCalculatedCostsBySalesItemId.set(salesItemId, totalCost);
    return totalCost;
};

// VERY IMPORTANT: the logic in flattenSalesItemsIntoProductComponents and getFlatDictOfProductsInSalesItem
// must stay in line with _get_flat_dict_of_products_in_sales_item and _get_per_serving_product_components_and_misc_cost_by_sales_item_id in ThriftSalesItemService.py
// that python function is used for item-level reports and the cache job, and this is currently used just in ExpectedInventory
// but in order to ensure numbers are consistent, we need to have both functions behave the same
const flattenSalesItemsIntoProductPerServingComponents = (
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>,
    productsById : StringValueMap<ProductId, Product>,
) : StringValueMap<SalesItemId, StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>>> => {
    const flattenedProductComponentsBySalesItemId = new StringValueMap<SalesItemId, StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>>>();
    salesItemsById.forEach((salesItemWithMetadata, salesItemId) => {
        const flattenedProductComponents = getFlatDictOfProductsPerServingInSalesItem(
            salesItemId,
            salesItemWithMetadata.getSalesItem(),
            salesItemsById,
            productsById,
            new StringValueSet());
        if (flattenedProductComponents === null) {
            flattenedProductComponentsBySalesItemId.set(salesItemId, new StringValueMap());
        } else {
            flattenedProductComponentsBySalesItemId.set(salesItemId, flattenedProductComponents);
        }
    });

    return flattenedProductComponentsBySalesItemId;
};

const getFlatDictOfProductsPerServingInSalesItem = (
    salesItemId : SalesItemId,
    salesItem : SalesItem,
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>,
    productsById : StringValueMap<ProductId, Product>,
    salesItemsSeen : StringValueSet<SalesItemId>,
) : StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>> | null => { // null = infinite loop detected
    if (salesItemsSeen.has(salesItemId)) {
        // if we've already seen this id, we're in an infinite loop for now and say cost not calculable
        // TODO future: can try to optimize solving this loop
        return null;
    }
    salesItemsSeen.add(salesItemId);

    // Current behavior is that when we say we sold a sales item, it means selling a serving, not a yield.
    // this matches the backend. It is unclear if this is actually the way we want to treat POS items that have
    // a yield that does not match the serving size (it is also unclear if this ever happens, or if the yield/serving size is only for subrecipes)
    // if this logic changes, must change the backend as well.
    const mapOfAllProductQuantitiesPerServing = new StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>>();

    const servingsPerYield = SalesItemUtils.getServingsPerYieldForSalesItem(salesItemsById.getRequired(salesItemId).getSalesItem());

    // product ingredients
    salesItem.getComponentQuantityOfProductByProductId().forEach((quantityInUnit, productId) => {
        const quantityPerServing = quantityInUnit.getQuantity() / servingsPerYield;
        mapOfAllProductQuantitiesPerServing.set(productId, new QuantityInUnit(quantityPerServing, quantityInUnit.getUnit()));
    });

    if (salesItem.getComponentServingsBySalesItemId().size === 0) {
        return mapOfAllProductQuantitiesPerServing;
    }

    // sub-recipe ingredients
    for (const componentSalesItemId of salesItem.getComponentServingsBySalesItemId().keys()) {
        const componentSalesItemWithMetadata = salesItemsById.get(componentSalesItemId);
        const numberOfServings = salesItem.getComponentServingsBySalesItemId().get(componentSalesItemId);
        if (typeof componentSalesItemWithMetadata === 'undefined' || typeof numberOfServings === 'undefined') {
            throw new RuntimeException('unexpected');
        }
        const productComponentsInComponentSalesItem = getFlatDictOfProductsPerServingInSalesItem(
            componentSalesItemId,
            componentSalesItemWithMetadata.getSalesItem(),
            salesItemsById,
            productsById,
            new StringValueSet(salesItemsSeen));

        if (productComponentsInComponentSalesItem !== null) {
            productComponentsInComponentSalesItem.forEach((quantityOfProductInServing, productId) => {
                // amount of product in parent (per yield) = amount of product per serving in component * number servings
                let quantityOfProductInParentSalesItem = quantityOfProductInServing.getQuantity() * numberOfServings;
                if (window.GLOBAL_FEATURE_ACCESS.subrecipe_ingredient_quantity_fix) {
                    quantityOfProductInParentSalesItem /= servingsPerYield;
                }
                const quantityInUnitOfProductInSalesItem = new QuantityInUnit(quantityOfProductInParentSalesItem, quantityOfProductInServing.getUnit());

                const oldValue = mapOfAllProductQuantitiesPerServing.get(productId);
                if (typeof oldValue === 'undefined') {
                    mapOfAllProductQuantitiesPerServing.set(productId, quantityInUnitOfProductInSalesItem);
                } else {
                    const product = productsById.get(productId);
                    if (typeof product === 'undefined') {
                        throw new RuntimeException('unexpected');
                    }

                    const unitToConvertTo = oldValue.getUnit();
                    const newQuantityInOldUnit = PackagingUtils.convertProductQuantityToUnit(
                        product.getPackagingsAndMappings(),
                        quantityInUnitOfProductInSalesItem,
                        unitToConvertTo,
                        productId
                    ).getQuantity();
                    mapOfAllProductQuantitiesPerServing.set(productId, new QuantityInUnit(oldValue.getQuantity() + newQuantityInOldUnit, unitToConvertTo));
                }
            });
        } else {
            return new StringValueMap(); // if inifinite loop detected, break;
        }
    }

    return mapOfAllProductQuantitiesPerServing;
};

const getServingsPerYieldForSalesItem = (salesItem : SalesItem) : number => {
    const itemYield = salesItem.getItemYield();
    const servingSize = salesItem.getServingSize();

    return getServingsPerYield(servingSize, itemYield);
};

const getServingsPerYield = (servingSize : SalesQuantityAndUnit, itemYield : SalesQuantityAndUnit) => {
    const yieldUnit = itemYield.getUnit();
    const servingUnit = servingSize.getUnit();

    let servingsPerYield : number;
    if (yieldUnit === null || servingUnit === null) { // the model enforces that these both must be null or both not null
        servingsPerYield = itemYield.getQuantity() / servingSize.getQuantity();
    } else {
        const numberServingsPerOneYieldUnit = UnitUtils.convertUnitQuantity(new QuantityInUnit(servingSize.getQuantity(), servingUnit), yieldUnit).getQuantity();
        servingsPerYield = itemYield.getQuantity() / numberServingsPerOneYieldUnit;
    }

    return servingsPerYield;
};

const getCostOfProductComponentInSalesItem = (
    productId : ProductId,
    quantityInSalesItem : QuantityInUnit<ProductQuantityUnit>,
    productsById : StringValueMap<ProductId, Product>,
    productCostsByProductId : StringValueMap<ProductId, ProductCost>,
) => {
    const product = productsById.getRequired(productId);
    const productCost = productCostsByProductId.getRequired(productId);
    return productCostUtils.totalDollarCostOfQuantity(product.getPackagingsAndMappings(), productCost, quantityInSalesItem, productId);
};

const getCostOfComponentSalesItem = (
    componentSalesItem : SalesItem,
    numberOfServingsInMainSalesItem : number,
    costOfComponentSalesItem : number | null) : number | null => {
    if (costOfComponentSalesItem === null) {
        return null;
    }

    return getNotNullCostOfComponentSalesItem(componentSalesItem, numberOfServingsInMainSalesItem, costOfComponentSalesItem);
};

// separate function for typing reasons
const getNotNullCostOfComponentSalesItem = (
    componentSalesItem : SalesItem,
    numberOfServingsInMainSalesItem : number,
    costOfComponentSalesItem : number) : number => {
    const servingsPerYieldInComponent = getServingsPerYieldForSalesItem(componentSalesItem);
    const costOfComponentQuantity = (numberOfServingsInMainSalesItem / servingsPerYieldInComponent) * costOfComponentSalesItem;

    return costOfComponentQuantity;
};

const getCostsBySalesItemId = (
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>,
    productsById : StringValueMap<ProductId, Product>,
    productCostsByProductId : StringValueMap<ProductId, ProductCost>,
) : StringValueMap<SalesItemId, number | null> => {

    const costBySalesItemId = new StringValueMap<SalesItemId, number | null>();
    salesItemsById.forEach((salesItemWithMetadata, salesItemId) => {
        const salesItemCost = calculateCostOfSalesItem(
            salesItemId,
            salesItemWithMetadata.getSalesItem(),
            salesItemsById,
            productsById,
            productCostsByProductId,
            costBySalesItemId,
            new StringValueSet());
        costBySalesItemId.set(salesItemId, salesItemCost);
    });

    return costBySalesItemId;
};

const getCostPercentageOfSalesItem = (
    salesItemPrice : number,
    salesItemCost : number | null
) : number | null => {
    if (salesItemCost === null || salesItemPrice === 0) { // no dividing by 0
        return null;
    }

    return (salesItemCost * 100) / salesItemPrice; // TODO do we want to ensure that this ratio isn't insane?
};

const calculatePricePlusTax = (price : number, taxPercent : number | null) => {
    if (taxPercent === null) {
        return price;
    }

    return (price * (100.0 + taxPercent) / 100.0);
};

const calculateSalesItemProfit = (price : number, cost : number | null) => {
    if (cost === null) {
        return null;
    }

    return price - cost;
};

const getSalesPriceFromPriceAndTax = (pricePlusTax : number, taxPercent : number | null) : number => {
    if (taxPercent === null) {
        return pricePlusTax;
    }

    return (pricePlusTax * 100.0 / (100.0 + taxPercent));
};

// these two utils should not be called for items with a null cost (cannot calculate these things)
const getSalesPriceFromCostPercent = (costPercent : number, salesItemCost : number) : number => {
   return (salesItemCost * 100.0 / costPercent);
};
const getSalesPriceFromSalesProfit = (salesProfit : number, salesItemCost : number) : number => {
    return salesProfit + salesItemCost;
};

// should be kept in sync with sales_utils! TODO do we want to improve this???
const getCategoryAndSubCategoryForSalesItem = (
    flattenedMapOfProductsInSalesItem : StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>>,
    productsById : StringValueMap<ProductId, Product>,
) : {category : string, subcategory : string} => {
    let category : string;
    let subcategory : string;

    const productsInSalesItem = flattenedMapOfProductsInSalesItem;
    if (productsInSalesItem.size === 0) {
        category = 'Uncategorized';
        subcategory = 'Uncategorized';
    } else if (productsInSalesItem.size === 1) {
        const productId = productsInSalesItem.keys().next().value;
        const product = productsById.get(productId);
        if (typeof product === 'undefined') {
            throw new RuntimeException('unexpected');
        }
        category = product.getProductCategoryId();
        subcategory = product.getProductType();
    } else {
        const categories = new Set<string>();
        productsInSalesItem.forEach((quantity, productId) => {
            const product = productsById.getRequired(productId);
            categories.add(product.getProductCategoryId());
        });
        if (categories.size === 1) {
            const categoryValue = categories.keys().next().value;
            category = categoryValue;
            subcategory = categoryValue;
        } else {
            category = 'Multiple Ingredients';
            subcategory = 'Multiple Ingredients';
        }
    }

    return {
        category,
        subcategory
    };
};

// need products by id for unit resolution
const areSalesItemsEqual = (salesItem1 : SalesItem, salesItem2 : SalesItem, productsById : StringValueMap<ProductId, Product>) : boolean => {
    // simple fields
    if (salesItem1.getName() !== salesItem2.getName() ||
        !salesItem1.getLocationId().equals(salesItem2.getLocationId()) ||
        salesItem1.getMenuGroup() !== salesItem2.getMenuGroup() ||
        salesItem1.getPOSId() !== salesItem2.getPOSId() ||
        salesItem1.getNote() !== salesItem2.getNote() ||
        salesItem1.getNeedsAttentionCategory() !== salesItem2.getNeedsAttentionCategory() ||
        salesItem1.getSalesPrice() !== salesItem2.getSalesPrice() ||
        salesItem1.getMiscellaneousCost() !== salesItem2.getMiscellaneousCost()) {
        return false;
    }

    // object fields

    // componentQuantityOfProductByProductId
    if (salesItem1.getComponentQuantityOfProductByProductId().size !== salesItem2.getComponentQuantityOfProductByProductId().size) {
        return false;
    } else {
        let allProductComponentsAreEqual : boolean = true;
        salesItem1.getComponentQuantityOfProductByProductId().forEach((quantityInUnit1, productId) => {
            const product = productsById.get(productId);
            if (typeof product === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            const quantityInUnit2 = salesItem2.getComponentQuantityOfProductByProductId().get(productId);
            if (typeof quantityInUnit2 === 'undefined') {
                allProductComponentsAreEqual = false;
            } else {
                const resolved1 = PackagingUtils.resolveProductQuantityUnit(quantityInUnit1, product.getPackagingsAndMappings().getMappings());
                const resolved2 = PackagingUtils.resolveProductQuantityUnit(quantityInUnit2, product.getPackagingsAndMappings().getMappings());

                if (!resolved1.equals(resolved2)) {
                    allProductComponentsAreEqual = false;
                }
            }
        });

        if (!allProductComponentsAreEqual) {
            return false;
        }
    }

    // componentServingsBySalesItemId
    if (salesItem1.getComponentServingsBySalesItemId().size !== salesItem2.getComponentServingsBySalesItemId().size) {
        return false;
    } else {
        let allSalesItemComponentsAreEqual : boolean = true;
        salesItem1.getComponentServingsBySalesItemId().forEach((numberServings1, salesItemId) => {
            const numberServings2 = salesItem2.getComponentServingsBySalesItemId().get(salesItemId);
            if (typeof numberServings2 === 'undefined' || numberServings1 !== numberServings2) {
                allSalesItemComponentsAreEqual = false;
            }
        });
        if (!allSalesItemComponentsAreEqual) {
            return false;
        }
    }

    // itemYield, servingSize, salesItemCustomUnitName
    if (salesItem1.getSalesItemCustomUnitName() !== salesItem2.getSalesItemCustomUnitName() ||
        !salesItem1.getItemYield().equals(salesItem2.getItemYield()) ||
        !salesItem1.getServingSize().equals(salesItem2.getServingSize())) {
        return false;
    }

    return true;
};

export const SalesItemUtils = {
    calculateCostOfSalesItem,
    getCostsBySalesItemId,
    getServingsPerYieldForSalesItem,
    getCostOfComponentSalesItem,
    getCostOfProductComponentInSalesItem,
    getCostPercentageOfSalesItem,
    calculatePricePlusTax,
    calculateSalesItemProfit,
    getCategoryAndSubCategoryForSalesItem,
    getSalesPriceFromCostPercent,
    getSalesPriceFromPriceAndTax,
    getSalesPriceFromSalesProfit,
    areSalesItemsEqual,
    getServingsPerYield,
    flattenSalesItemsIntoProductPerServingComponents,
    getFlatDictOfProductsPerServingInSalesItem
};
