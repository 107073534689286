import React from 'react';

import { ProductDistributorAssociationFormId } from '../model/ProductDistributorAssociationFormId';
import { IProductDistributorAssociationForm, ProductDistributorAssociationFormFieldName } from '../reducers/ItemCardReducers';

import { Button } from 'shared/components/Button';
import { OptionsAndLabelNameTuples, Select2DropdownMenu } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { ValidationInput } from 'shared/components/ValidationInput';
import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { StringValueSet } from 'api/Core/StringValueSet';

interface IProductDistributorAssociationRowProps {
    readonly productDistributorAssociationFormId : ProductDistributorAssociationFormId;
    readonly productDistributorAssociationForm : IProductDistributorAssociationForm;
    readonly distributorSortedOptionsAndLabelNames : OptionsAndLabelNameTuples;
    readonly unitSortedOptionsAndLabelNames : OptionsAndLabelNameTuples;
    readonly onFormFieldChange : (formId : ProductDistributorAssociationFormId, fieldName : ProductDistributorAssociationFormFieldName, value : string) => void;
    readonly onFormFieldBlur : (formId : ProductDistributorAssociationFormId, fieldName : ProductDistributorAssociationFormFieldName, value : string) => void;
    readonly onRemoveProductDistributorAssociationForms : (formIds : StringValueSet<ProductDistributorAssociationFormId>) => void;
    readonly onCreateCustomDistributorClick : (distributorName : string) => void;
}

export class ProductDistributorAssociationRow extends React.Component<IProductDistributorAssociationRowProps, object> {
    public render() {
        const {
            productDistributorAssociationForm,
            distributorSortedOptionsAndLabelNames,
            unitSortedOptionsAndLabelNames,
            onCreateCustomDistributorClick
        } = this.props;

        const {
            validationInputDataByFieldName,
        } = productDistributorAssociationForm;

        let distributorSelectedIOption : IOption | null = null;
        distributorSortedOptionsAndLabelNames.forEach(([labelName, iOptions]) => {
            iOptions.forEach((iOption) => {
                if (iOption.value === validationInputDataByFieldName.distributorId.value) {
                    distributorSelectedIOption = iOption;
                }
            });
        });

        let unitSelectedIOption : IOption | null = null;
        let priceUnitSelectedIOption : IOption | null = null;
        let depositUnitLabelString = '';
        if (unitSortedOptionsAndLabelNames.length > 0) {
            unitSortedOptionsAndLabelNames[0][1].forEach((packagingUnitOption) => {
                if (validationInputDataByFieldName.unit.value && (packagingUnitOption.value === validationInputDataByFieldName.unit.value)) {
                    unitSelectedIOption = packagingUnitOption;
                }

                if (validationInputDataByFieldName.priceUnit.value && (packagingUnitOption.value === validationInputDataByFieldName.priceUnit.value)) {
                    priceUnitSelectedIOption = packagingUnitOption;
                }

                if (validationInputDataByFieldName.depositUnit.value && (packagingUnitOption.value === validationInputDataByFieldName.depositUnit.value)) {
                    depositUnitLabelString = packagingUnitOption.label;
                }
            });
        }

        return (
            <div className="product-distributor-association-row card">
                <div className="packaging-section">
                    <div className="packaging-section-subsection">
                        <label className="input-label reset-dark-styles">
                            Vendor
                        </label>
                        <div className="floating-dropdown-menu">
                            <Select2DropdownMenu
                                isSearchable={ true }
                                buttonShouldDisplaySelectedLabel={ true }
                                selectedOptionHeaderShown={ true }
                                sortedOptionsAndLabelName={ distributorSortedOptionsAndLabelNames }
                                selectedOption={ distributorSelectedIOption }
                                onOptionSelect={ this.onDistributorSelect }
                                placeholderText="Select Vendor"
                                emptyState={ null }
                                hasCreateCustomOption={ true }
                                createCustomOption={ onCreateCustomDistributorClick }
                                customOptionGroupLabel="My Vendors"
                                createCustomOptionButtonLabel={ null }
                            />
                        </div>
                        { validationInputDataByFieldName.distributorId.errorMessage &&
                            <span className="error-text">{ validationInputDataByFieldName.distributorId.errorMessage }</span>
                        }
                    </div>
                    <div className="packaging-section-subsection">
                        <label className="input-label reset-dark-styles">
                            SKU
                        </label>
                        <div className="packaging-input-group">
                            <ValidationInput
                                key="sku"
                                inputClassName=""
                                type="text"
                                autoFocus={ false }
                                autoComplete={ null }
                                value={ validationInputDataByFieldName.sku.value }
                                errorMessage={ validationInputDataByFieldName.sku.errorMessage }
                                isValid={ validationInputDataByFieldName.sku.isValid }
                                handleBlur={ this.onSkuChangeOrBlur }
                                handleFocus={ null }
                                handleChange={ this.onSkuChangeOrBlur }
                                handleEnterClick={ this.doNothing }
                                label=""
                                hintText=""
                                isDisabled={ false }
                            />
                        </div>
                    </div>
                </div>
                <div className="packaging-section">
                    <div className="packaging-section-subsection">
                        <label className="input-label reset-dark-styles">
                            Purchasing Unit
                        </label>
                        <div className="floating-dropdown-menu">
                            <Select2DropdownMenu
                                isSearchable={ false }
                                buttonShouldDisplaySelectedLabel={ true }
                                selectedOptionHeaderShown={ false }
                                sortedOptionsAndLabelName={ unitSortedOptionsAndLabelNames }
                                selectedOption={ unitSelectedIOption }
                                onOptionSelect={ this.onUnitSelect }
                                placeholderText="Select Unit"
                                emptyState={ null }
                                hasCreateCustomOption={ false }
                                createCustomOption={ this.doNothing }
                                customOptionGroupLabel={ null }
                                createCustomOptionButtonLabel={ null }
                            />
                        </div>
                        { validationInputDataByFieldName.unit.errorMessage &&
                            <span className="error-text">{ validationInputDataByFieldName.unit.errorMessage }</span>
                        }
                    </div>
                    <div className="packaging-section-subsection">
                        <label className="input-label reset-dark-styles">
                            Price
                        </label>
                        <div className="packaging-input-group">
                            <ValidationInput
                                key="priceAmount"
                                inputClassName=""
                                type="text"
                                autoFocus={ false }
                                autoComplete={ null }
                                errorMessage={ validationInputDataByFieldName.priceAmount.errorMessage }
                                isValid={ validationInputDataByFieldName.priceAmount.isValid }
                                handleBlur={ this.onPriceAmountBlur }
                                handleFocus={ null }
                                handleChange={ this.onPriceAmountChange }
                                handleEnterClick={ this.doNothing }
                                label={ null }
                                hintText={ null }
                                value={ validationInputDataByFieldName.priceAmount.value }
                                isDisabled={ false }
                                isCurrencyInput={ true }
                            />
                            <div className="floating-dropdown-menu">
                                <Select2DropdownMenu
                                    isSearchable={ false }
                                    buttonShouldDisplaySelectedLabel={ true }
                                    selectedOptionHeaderShown={ false }
                                    sortedOptionsAndLabelName={ unitSortedOptionsAndLabelNames }
                                    selectedOption={ priceUnitSelectedIOption }
                                    onOptionSelect={ this.onPriceUnitSelect }
                                    placeholderText="Select Unit"
                                    emptyState={ null }
                                    hasCreateCustomOption={ false }
                                    createCustomOption={ this.doNothing }
                                    customOptionGroupLabel={ null }
                                    createCustomOptionButtonLabel={ null }
                                />
                                { validationInputDataByFieldName.unit.errorMessage &&
                                    <span className="error-text">{ validationInputDataByFieldName.unit.errorMessage }</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="packaging-section-subsection">
                        <label className="input-label reset-dark-styles">
                            Deposit/ { depositUnitLabelString }
                        </label>
                        <div className="packaging-input-group">
                            <ValidationInput
                                key="depositAmount"
                                inputClassName=""
                                type="text"
                                autoFocus={ false }
                                autoComplete={ null }
                                errorMessage={ validationInputDataByFieldName.depositAmount.errorMessage }
                                isValid={ validationInputDataByFieldName.depositAmount.isValid }
                                handleBlur={ this.onDepositAmountBlur }
                                handleFocus={ null }
                                handleChange={ this.onDepositAmountChange }
                                handleEnterClick={ this.doNothing }
                                label=""
                                hintText={ null }
                                value={ validationInputDataByFieldName.depositAmount.value }
                                isDisabled={ false }
                                isCurrencyInput={ true }
                            />
                        </div>
                    </div>
                </div>
                <Button
                    buttonClassName="remove-btn with-icon flat"
                    isDisabled={ false }
                    isLoading={ false }
                    onClick={ this.onRemoveProductDistributorAssociationForm }
                >
                    <span className="bevicon bevico-remove"/>
                </Button>
            </div>
        );
    }

    private readonly onDistributorSelect = (distributorIdValue : string) => {
        this.props.onFormFieldChange(this.props.productDistributorAssociationFormId, 'distributorId', distributorIdValue);
    }

    private readonly onUnitSelect = (productQuantityUnitValue : string) => {
        this.props.onFormFieldChange(this.props.productDistributorAssociationFormId, 'unit', productQuantityUnitValue);

        if (this.props.productDistributorAssociationForm.validationInputDataByFieldName.priceUnit.value === '') {
            this.props.onFormFieldChange(this.props.productDistributorAssociationFormId, 'priceUnit', productQuantityUnitValue);
            this.props.onFormFieldChange(this.props.productDistributorAssociationFormId, 'depositUnit', productQuantityUnitValue);
        }
    }

    private readonly onSkuChangeOrBlur = (event : React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
        return this.props.onFormFieldChange(this.props.productDistributorAssociationFormId, 'sku', event.currentTarget.value);
    }

    private readonly onPriceAmountChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.onFormFieldChange(this.props.productDistributorAssociationFormId, 'priceAmount', event.currentTarget.value);
    }

    private readonly onPriceAmountBlur = (event : React.FocusEvent<HTMLInputElement>) => {
        this.props.onFormFieldBlur(this.props.productDistributorAssociationFormId, 'priceAmount', event.currentTarget.value);
    }

    private readonly onPriceUnitSelect = (priceUnitValue : string) => {
        this.props.onFormFieldChange(this.props.productDistributorAssociationFormId, 'priceUnit', priceUnitValue);
        this.props.onFormFieldChange(this.props.productDistributorAssociationFormId, 'depositUnit', priceUnitValue);

        if (this.props.productDistributorAssociationForm.validationInputDataByFieldName.unit.value === '') {
            this.props.onFormFieldChange(this.props.productDistributorAssociationFormId, 'unit', priceUnitValue);
        }
    }

    private readonly onDepositAmountChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.onFormFieldChange(this.props.productDistributorAssociationFormId, 'depositAmount', event.currentTarget.value);
    }

    private readonly onDepositAmountBlur = (event : React.FocusEvent<HTMLInputElement>) => {
        this.props.onFormFieldBlur(this.props.productDistributorAssociationFormId, 'depositAmount', event.currentTarget.value);
    }

    private readonly onRemoveProductDistributorAssociationForm = () => {
        this.props.onRemoveProductDistributorAssociationForms(new StringValueSet([this.props.productDistributorAssociationFormId]));
    }

    private readonly doNothing = () => {
        // Do Nothing
    }
}
