import { PackagingId } from 'api/Product/model/PackagingId';
import { IProductAmountJSONObject } from 'api/Product/serializer/IProductJSONObject';
import { ReportMetadata } from 'api/Transfer/model/ReportMetadata';
import { TransferDirection } from 'api/Transfer/model/TransferDirection';
import { TransferReport } from 'api/Transfer/model/TransferReport';
import { TransferReportData } from 'api/Transfer/model/TransferReportData';
import {
    IProductCostsByProductIdJSONObject,
    IReportMetaDataJSONObject,
    ITransferJSONObject,
    ITransferReportDataJSONObject
} from 'api/Transfer/serializer/ITransferJSONObject';
import { IProductCostJsonObject } from 'api/UsageData/serializer/IUsageDataJSONObject';
import { UserAccountUtils } from 'api/UserAccount/utils/UserAccountUtils';
import { decimalToNumber } from 'shared/utils/decimalUtils';

export class TransferObjectToJSONSerializer {
    public getTransferReportJSON(
        transferReport: TransferReport
    ) : ITransferJSONObject {
        const transferReportData = this.getTransferReportDataJSON(transferReport.getTransferReportData());
        const reportMetaData = this.getReportMetaDataJSON(transferReport.getReportMetaData());
        const productCostsByProductId = this.getProductCostsByProductIdMapping(transferReport);

        return {
            transfer_report_data: transferReportData,
            report_meta_data: reportMetaData,
            product_costs_by_product_id: productCostsByProductId,
        };
    }

    public getTransferReportDataJSON(
        transferReportData: TransferReportData
    ) : ITransferReportDataJSONObject {
        const partnerLocation = transferReportData.getPartnerLocation();
        const requestMetadata = transferReportData.getRequestMetadata();
        const sentMetadata = transferReportData.getSentMetadata();
        const receivedMetadata = transferReportData.getReceivedMetadata();
        const cancellationMetadata = transferReportData.getCancellationMetadata();

        const productAmountsMap: {[productId: string]: IProductAmountJSONObject} = {};
        transferReportData.getProductAmounts().forEach((productAmount) => {
            const quantityInUnit = productAmount.getQuantityInUnit();
            const unit = quantityInUnit.getUnit();

            productAmountsMap[productAmount.getProductId().getValue()] = {
                quantity: quantityInUnit.getQuantity(),
                unit: unit instanceof PackagingId ? unit.getValue() : unit,
            };
        });

        return {
            perspective_location: transferReportData.getPerspectiveLocation().getValue(),
            product_amounts: productAmountsMap,
            partner_location: typeof partnerLocation === 'string' ? partnerLocation : partnerLocation.getValue(),
            direction: TransferDirection[transferReportData.getDirection()],
            time: transferReportData.getTime().timeSinceUnixEpoch.value,
            note: transferReportData.getNote(),
            request_metadata: requestMetadata ? UserAccountUtils.getUserAccountIdAndTimestampJSONFromObject(requestMetadata) : null,
            sent_metadata: sentMetadata ? UserAccountUtils.getUserAccountIdAndTimestampJSONFromObject(sentMetadata) : null,
            received_metadata: receivedMetadata ? UserAccountUtils.getUserAccountIdAndTimestampJSONFromObject(receivedMetadata) : null,
            cancellation_metadata: cancellationMetadata ? UserAccountUtils.getUserAccountIdAndTimestampJSONFromObject(cancellationMetadata) : null,
            cancellation_note: transferReportData.getCancellationNote(),
        };
    }

    public getReportMetaDataJSON(
        reportMetaData: ReportMetadata
    ) : IReportMetaDataJSONObject {
        const creationMetadata = reportMetaData.getCreationMetadata();
        const updateMetadata = reportMetaData.getUpdateMetadata();

        return {
            creation_metadata: UserAccountUtils.getUserAccountIdAndTimestampJSONFromObject(creationMetadata),
            update_metadata: updateMetadata ? UserAccountUtils.getUserAccountIdAndTimestampJSONFromObject(updateMetadata) : null,
            perspective_is_originator: reportMetaData.getPerspectiveIsOriginator()
        };
    }

    public getProductCostsByProductIdMapping(
        transferReport: TransferReport
    ) : IProductCostsByProductIdJSONObject {
        const productCostsJSON: { [productId : string] : IProductCostJsonObject } = {};
        transferReport.getProductCostsByProductId().forEach((productCost, productId) => {
            const unit = productCost.getUnit();
            productCostsJSON[productId.getValue()] = {
                cost: decimalToNumber(productCost.getCost()),
                product_quantity_unit: (unit instanceof PackagingId ? unit.getValue() : unit)
            };
        });

        return productCostsJSON;
    }
}
