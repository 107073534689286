import { CreateRetailerFormFieldName } from 'shared/components/SignUpOrSignIn/CreateRetailerForm/reducers/CreateRetailerFormReducers';
import { IValidationResult, Validation } from '../../validators/validators';

import { AdminCreateRetailerFormFieldName } from './AdminCreateRetailerForm/reducers';
import { signInFormFieldName } from './SignInForm/reducers';
import { signUpFormFieldName } from './SignUpForm/reducers';

export const validateValueByFieldName = (
    fieldName : signInFormFieldName | signUpFormFieldName | CreateRetailerFormFieldName | AdminCreateRetailerFormFieldName,
    value : string | boolean,
    countryCode : string | null,
) : IValidationResult => {
    let isValid : null | boolean = null;
    let errorMessage = '';

    // required
    switch (fieldName) {
        case 'firstName':
        case 'lastName':
        case 'emailAddress':
        case 'emailAddressOrAccountId':
        case 'password':
        case 'phone':
        case 'retailerName':
        case 'accountType':
        case 'userIdValue':
        case 'streetAddressLine1':
        case 'city':
        case 'state':
        case 'country':
        case 'locationCount':
            isValid = Validation.validateRequired(value);
            if (!isValid) {
                return {
                    errorMessage,
                    isValid,
                };
            }
        default:
            break;
    }

    // input type specific
    switch (fieldName) {
        case 'zipCode':
            isValid = typeof value !== 'boolean' && Validation.validateZipCode(value, countryCode);
            if (!isValid) {
                errorMessage = 'Invalid ZIP code.';
            }
            break;
        case 'emailAddress':
            isValid = typeof value !== 'boolean' && Validation.validateEmailAddress(value);
            if (!isValid) {
                errorMessage = 'Invalid email address.';
            }
            break;
        case 'phone':
            if (value === '') {
                isValid = true;
                errorMessage = '';
                break;
            }
            isValid = typeof value !== 'boolean' && Validation.validatePhoneNumber(value, countryCode);
            if (!isValid) {
                errorMessage = 'Invalid phone number.';
            }
            break;
        case 'barName':
        case 'beverageRetailerName':
        case 'foodRetailerName':
        case 'streetAddressLine2':
        case 'introduction':
        case 'introductionDrillDown':
        case 'introductionAnswer':
        case 'optionalZipCode':
            isValid = true;
            errorMessage = '';
            break;
        case 'locationCount':
            isValid = typeof value !== 'boolean' && Validation.validateNonNegativeNumber(value);
            if (!isValid) {
                errorMessage = 'Invalid location count.';
            }
            break;
        default:
            if (isValid === null) {
                throw new Error('no validation implemented for fieldname ' + fieldName);
            }
    }

    return {
        isValid,
        errorMessage,
    };
};
