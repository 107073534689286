import React from 'react';
import MediaQuery from 'react-responsive';

import { MAX_TABLET_WIDTH, MIN_DESKTOP_WIDTH } from 'shared/constants';
import './TabButton.scss';

interface ITabButtonProps {
    label : string;
    shortLabel? : string;
    icon? : string | JSX.Element;
    onClick : (event? : any) => any;
    isSelected : boolean;
    isDisabled? : boolean;
}

export class TabButton extends React.Component<ITabButtonProps, object> {
    public render() {
        const classList = `tab-button${ this.props.isSelected ? ' selected' : '' }${ this.props.isDisabled ? ' disabled' : ''}`;
        return (
            <div className={ classList } onClick={ this.props.onClick }>
                { this.renderIcon() }
                { this.renderLabel() }
            </div>
        );
    }

    private renderIcon() {
        if (this.props.icon) {
            const iconClassList = `bevicon ${this.props.icon}`;
            return (<div className="icon-container"><span className={ iconClassList }/></div>);
        }
    }

    private renderLabel() {
        const { label, shortLabel } = this.props;

        if (label) {
            return (
                <div className="label-container">
                     <MediaQuery minWidth={ MIN_DESKTOP_WIDTH }>
                        <span className="label">{ label }</span>
                     </MediaQuery>
                    <MediaQuery maxWidth={ MAX_TABLET_WIDTH }>
                        <span className="label">{ shortLabel ? shortLabel : label }</span>
                    </MediaQuery>
                </div>
            );
        }
    }
}
