import { StringValueMap } from 'api/Core/StringValueMap';
import { BottleBreakdown } from 'api/InventoryCount/model/BottleBreakdown';
import { ExpectedInventory } from 'api/InventoryCount/model/ExpectedInventory';
import { ProductId } from 'api/Product/model/ProductId';

export class ExpectedInventoryDeserializer {

    public getExpectedInventory(
        response : any
    ) : ExpectedInventory {
        const bottleCountByProductId = new StringValueMap<ProductId, number>();
        const breakdownByProductId = new StringValueMap<ProductId, BottleBreakdown>();

        Object.keys(response.expected_inventory_bottle_count_by_id).forEach((key) => {
            bottleCountByProductId.set(new ProductId(key), response.expected_inventory_bottle_count_by_id[key]);
        });

        Object.keys(response.bottle_breakdown_by_product_id).forEach((key) => {
            const bottleBreakdownJson = response.bottle_breakdown_by_product_id[key];
            const bottleBreakdown = new BottleBreakdown(
                bottleBreakdownJson.start_count,
                bottleBreakdownJson.deliveries,
                bottleBreakdownJson.transfers,
                bottleBreakdownJson.prep_inputs,
                bottleBreakdownJson.prep_outputs,
                bottleBreakdownJson.breakages,
                bottleBreakdownJson.sales,
            );

            breakdownByProductId.set(new ProductId(key), bottleBreakdown);
        });

        return new ExpectedInventory(
            bottleCountByProductId,
            breakdownByProductId
        );
    }}
