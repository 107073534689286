import { LocationId } from 'api/Location/model/LocationId';
import { ProductMergeEvent } from 'api/Product/model/ProductMergeEvent';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductJSONToObjectSerializer } from 'api/Product/serializer/ProductJSONToObjectSerializer';
import { ProductObjectToJSONSerializer } from 'api/Product/serializer/ProductObjectToJSONSerializer';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { MergeItemUtils } from 'apps/MergeItemCard/utils/MergeItemUtils';
import { AjaxUtils } from 'shared/utils/ajaxUtils';

import { IProductMergeService } from '../interfaces/IProductMergeService';
import { StringValueMap } from 'api/Core/StringValueMap';

export class ProductMergeServiceImpl implements IProductMergeService {

    constructor (
        private readonly productJSONToObjectSerializer : ProductJSONToObjectSerializer,
        private readonly productObjectToJSONSerializer : ProductObjectToJSONSerializer,
    ) { }

    public getMergeEventsForRetailer (
        userSessionId : UserSessionId,
        locationId : LocationId
    ) : Promise<StringValueMap<ProductId, Array<ProductMergeEvent>>> {
        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:get_merged_products'))
        .then((response) => {
            return Promise.resolve(MergeItemUtils.getProductMergeEventsByProductId(response, this.productJSONToObjectSerializer));
        })
        .catch((error : Error) => {
            return Promise.reject(error);
        });
    }

    public createProductMergeEvent (
        userSessionId : UserSessionId,
        mergeEvent : ProductMergeEvent,
    ) : Promise<void> {
        const mergeEventJson = this.productObjectToJSONSerializer.getMergeEventJSON(mergeEvent);

        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:post_merged_products'), mergeEventJson)
        .then((response) => {
            return Promise.resolve();
        })
        .catch((error : Error) => {
            return Promise.reject(error);
        });
    }
}
