//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';
import DistributorModel from './distributor_Model_types';



var DistributorExceptions = {};
export default DistributorExceptions;
DistributorExceptions.UnknownDistributorIdException = function(args) {
};
Thrift.inherits(DistributorExceptions.UnknownDistributorIdException, Thrift.TException);
DistributorExceptions.UnknownDistributorIdException.prototype.name = 'UnknownDistributorIdException';
DistributorExceptions.UnknownDistributorIdException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorExceptions.UnknownDistributorIdException.prototype.write = function(output) {
  output.writeStructBegin('UnknownDistributorIdException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorExceptions.DuplicateDistributorIdException = function(args) {
};
Thrift.inherits(DistributorExceptions.DuplicateDistributorIdException, Thrift.TException);
DistributorExceptions.DuplicateDistributorIdException.prototype.name = 'DuplicateDistributorIdException';
DistributorExceptions.DuplicateDistributorIdException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorExceptions.DuplicateDistributorIdException.prototype.write = function(output) {
  output.writeStructBegin('DuplicateDistributorIdException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorExceptions.UnknownDistributorRegionIdException = function(args) {
};
Thrift.inherits(DistributorExceptions.UnknownDistributorRegionIdException, Thrift.TException);
DistributorExceptions.UnknownDistributorRegionIdException.prototype.name = 'UnknownDistributorRegionIdException';
DistributorExceptions.UnknownDistributorRegionIdException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorExceptions.UnknownDistributorRegionIdException.prototype.write = function(output) {
  output.writeStructBegin('UnknownDistributorRegionIdException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorExceptions.UnknownScopeException = function(args) {
};
Thrift.inherits(DistributorExceptions.UnknownScopeException, Thrift.TException);
DistributorExceptions.UnknownScopeException.prototype.name = 'UnknownScopeException';
DistributorExceptions.UnknownScopeException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorExceptions.UnknownScopeException.prototype.write = function(output) {
  output.writeStructBegin('UnknownScopeException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorExceptions.DistributorNameCollisionException = function(args) {
  this.collidedDistributorsById = null;
  if (args) {
    if (args.collidedDistributorsById !== undefined && args.collidedDistributorsById !== null) {
      this.collidedDistributorsById = Thrift.copyList(args.collidedDistributorsById, [DistributorModel.DistributorIdAndDistributor]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field collidedDistributorsById is unset!');
    }
  }
};
Thrift.inherits(DistributorExceptions.DistributorNameCollisionException, Thrift.TException);
DistributorExceptions.DistributorNameCollisionException.prototype.name = 'DistributorNameCollisionException';
DistributorExceptions.DistributorNameCollisionException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.collidedDistributorsById = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new DistributorModel.DistributorIdAndDistributor();
          elem6.read(input);
          this.collidedDistributorsById.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorExceptions.DistributorNameCollisionException.prototype.write = function(output) {
  output.writeStructBegin('DistributorNameCollisionException');
  if (this.collidedDistributorsById !== null && this.collidedDistributorsById !== undefined) {
    output.writeFieldBegin('collidedDistributorsById', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.collidedDistributorsById.length);
    for (var iter7 in this.collidedDistributorsById)
    {
      if (this.collidedDistributorsById.hasOwnProperty(iter7))
      {
        iter7 = this.collidedDistributorsById[iter7];
        iter7.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

