//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';



var ProductAmountExceptions = {};
export default ProductAmountExceptions;
ProductAmountExceptions.ProductNotAssociatedWithLocationException = function(args) {
};
Thrift.inherits(ProductAmountExceptions.ProductNotAssociatedWithLocationException, Thrift.TException);
ProductAmountExceptions.ProductNotAssociatedWithLocationException.prototype.name = 'ProductNotAssociatedWithLocationException';
ProductAmountExceptions.ProductNotAssociatedWithLocationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductAmountExceptions.ProductNotAssociatedWithLocationException.prototype.write = function(output) {
  output.writeStructBegin('ProductNotAssociatedWithLocationException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductAmountExceptions.UnitIncompatibleWithProductPackagingException = function(args) {
};
Thrift.inherits(ProductAmountExceptions.UnitIncompatibleWithProductPackagingException, Thrift.TException);
ProductAmountExceptions.UnitIncompatibleWithProductPackagingException.prototype.name = 'UnitIncompatibleWithProductPackagingException';
ProductAmountExceptions.UnitIncompatibleWithProductPackagingException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductAmountExceptions.UnitIncompatibleWithProductPackagingException.prototype.write = function(output) {
  output.writeStructBegin('UnitIncompatibleWithProductPackagingException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductAmountExceptions.QuantityMustBeNonnegativeException = function(args) {
};
Thrift.inherits(ProductAmountExceptions.QuantityMustBeNonnegativeException, Thrift.TException);
ProductAmountExceptions.QuantityMustBeNonnegativeException.prototype.name = 'QuantityMustBeNonnegativeException';
ProductAmountExceptions.QuantityMustBeNonnegativeException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductAmountExceptions.QuantityMustBeNonnegativeException.prototype.write = function(output) {
  output.writeStructBegin('QuantityMustBeNonnegativeException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductAmountExceptions.QuantityOfProductAndUnitDoesNotExistException = function(args) {
};
Thrift.inherits(ProductAmountExceptions.QuantityOfProductAndUnitDoesNotExistException, Thrift.TException);
ProductAmountExceptions.QuantityOfProductAndUnitDoesNotExistException.prototype.name = 'QuantityOfProductAndUnitDoesNotExistException';
ProductAmountExceptions.QuantityOfProductAndUnitDoesNotExistException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductAmountExceptions.QuantityOfProductAndUnitDoesNotExistException.prototype.write = function(output) {
  output.writeStructBegin('QuantityOfProductAndUnitDoesNotExistException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

