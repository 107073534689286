import moment from 'moment-timezone';

import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { Distributor } from 'api/Distributor/model/Distributor';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { LocationId } from 'api/Location/model/LocationId';
import { IInvoiceUploadService } from 'api/Ordering/interfaces/IInvoiceUploadService';
import { IDeliveryReport, IOrderingService } from 'api/Ordering/interfaces/IOrderingService';
import { Delivery } from 'api/Ordering/model/Delivery';
import { DeliveryId } from 'api/Ordering/model/DeliveryId';
import { InvoiceUpload } from 'api/Ordering/model/InvoiceUpload';
import { InvoiceUploadId } from 'api/Ordering/model/InvoiceUploadId';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';

import { RecordNewOrderFormUtils } from './RecordNewOrderFormUtils';
import { ComponentName, IRecordNewOrderState, OrderDateType, RecordOrderFormFieldName } from './RecordNewOrderReducers';

import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { IFileUploadState } from 'shared/components/FileUpload/reducers';
import { IExtraArguments } from 'shared/components/Provider';
import { OptionsAndLabelNameTuples } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { IValidationInputData } from 'shared/components/ValidationInput';
import { NULL_DISTRIBUTOR_ID } from 'shared/constants';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { DateTime } from 'shared/models/DateTime';
import { IAction, IActionCreatorsMapObject } from 'shared/models/IAction';
import { Observer } from 'shared/utils/observer';

import 'custom-event-polyfill';

export interface IRecordNewOrderStore {
    recordNewOrderState : IRecordNewOrderState;
    fileUploadState : IFileUploadState;
}

export const ActionTypes = {
    SET_COMPONENT_IS_SHOWN: 'RECORD_NEW_ORDER/SET_COMPONENT_IS_SHOWN',
    INITIALIZE_RECORD_NEW_ORDER_VIEW_FORM: 'RECORD_NEW_ORDER/INITIALIZE_RECORD_NEW_ORDER_VIEW_FORM',
    CLEAR_RECORD_NEW_ORDER_VIEW_FORM: 'RECORD_NEW_ORDER/CLEAR_RECORD_NEW_ORDER_VIEW_FORM',
    SET_SORTED_DISTRIBUTOR_OPTIONS: 'RECORD_NEW_ORDER/SET_SORTED_DISTRIBUTOR_OPTIONS',
    SET_CURRENT_DISTRIBUTOR_OPTION: 'RECORD_NEW_ORDER/SET_CURRENT_DISTRIBUTOR_OPTION',
    SET_DELIVERIES_BY_ID: 'RECORD_NEW_ORDER/SET_DELIVERIES_BY_ID',
    SET_ORDER_DATE: 'RECORD_NEW_ORDER/SET_ORDER_DATE',
    SET_VALIDATION_INPUT_DATA_BY_FIELD_NAME: 'RECORD_NEW_ORDER/SET_VALIDATION_INPUT_DATA_BY_FIELD_NAME',
    SET_ORDER_HAS_BEEN_DELIVERED: 'RECORD_NEW_ORDER/SET_ORDER_HAS_BEEN_DELIVERED',
    SET_SHOULD_SUBMIT_UPLOAD_FOR_PROCESSING: 'RECORD_NEW_ORDER/SET_SHOULD_SUBMIT_UPLOAD_FOR_PROCESSING',
    SET_PRICE_PER_INVOICE: 'RECORD_NEW_ORDER/SET_PRICE_PER_INVOICE',
};

export namespace RecordNewOrderActionInterfaces {
    export interface ISetComponentIsShown extends IAction {
        payload : {
            id : string | null;
            isShown : boolean;
            componentName : ComponentName;
        };
    }

    export interface IInitializeRecordNewOrderViewForm extends IAction {
        payload : {
            id : string;
        };
    }

    export interface IClearRecordNewOrderViewForm extends IAction {
        payload : {
            id : string;
        };
    }

    export interface ISetSortedDistributorOptionsAndLabelName extends IAction {
        payload : {
            sortedDistributorOptionsAndLabelName : OptionsAndLabelNameTuples;
        };
    }

    export interface ISetDeliveriesById extends IAction {
        payload : {
            deliveriesById : StringValueMap<DeliveryId, Delivery>;
        };
    }

    export interface ISetCurrentDistributorOption extends IAction {
        payload : {
            id : string;
            currentDistributorOption : IOption | null;
        };
    }

    export interface ISetOrderDate extends IAction {
        payload : {
            id : string;
            dateType : OrderDateType;
            date : DateTime;
        };
    }

    export interface ISetValidationInputForFieldName extends IAction {
        payload : {
            id : string;
            fieldName : RecordOrderFormFieldName;
            inputData : IValidationInputData;
        };
    }

    export interface ISetOrderHasBeenDelivered extends IAction {
        payload : {
            id : string;
            orderHasBeenDelivered : boolean;
        };
    }

    export interface ISetShouldSubmitUploadForProcessing extends IAction {
        payload : {
            id : string;
            shouldSubmitUploadForProcessing : boolean;
        };
    }

    export interface ISetPricePerInvoice extends IAction {
        payload : {
            pricePerInvoice : number;
        };
    }

    export interface IServices {
        orderingService : IOrderingService;
        invoiceUploadService : IInvoiceUploadService;
    }
    export interface IRecordNewOrderExtraArguments extends IExtraArguments {
        services : IServices;
    }

    export interface IRecordNewOrderActionCreatorsMapObject extends IActionCreatorsMapObject {
        setComponentIsShown : ISetComponentIsShownActionCreator;
        initializeRecordNewOrderViewForm : IInitializeRecordNewOrderViewFormActionCreator;
        clearRecordNewOrderViewForm : IClearRecordNewOrderViewFormActionCreator;
        setSortedDistributorOptionsAndLabelName : ISetSortedDistributorOptionsAndLabelNameActionCreator;
        setCurrentDistributorOption : ISetCurrentDistributorOptionActionCreator;
        setOrderDate : ISetOrderDateActionCreator;
        setValidationInputForFieldName : ISetValidationInputForFieldNameActionCreator;
        setOrderHasBeenDelivered : ISetOrderHasBeenDeliveredActionCreator;
        setShouldSubmitUploadForProcessing : ISetShouldSubmitUploadForProcessingActionCreator;
        setInitialRetailerData : (
            distributorsById : StringValueMap<DistributorId, Distributor>,
            distributorIdsWithRelationship : StringValueSet<DistributorId>,
            deliveriesById : StringValueMap<DeliveryId, Delivery>,
            pricePerInvoice : number,
        ) => ThunkAction<Promise<void>, IRecordNewOrderStore, RecordNewOrderActionInterfaces.IRecordNewOrderExtraArguments>;
        onNewDistributorCreated : (
            distributor : Distributor,
            distributorId : DistributorId,
        ) => ThunkAction<Promise<void>, IRecordNewOrderStore, RecordNewOrderActionInterfaces.IRecordNewOrderExtraArguments>;
        recordNewOrder : (
            id : string,
            retailerId : LocationId,
            invoiceUploadFiles : Array<File | null>,
            shouldRedirectOnCreate : boolean,
        ) => ThunkAction<Promise<{deliveryId : DeliveryId, delivery : Delivery, invoiceUploadIdsById : StringValueMap<InvoiceUploadId, InvoiceUpload>}>, IRecordNewOrderStore, RecordNewOrderActionInterfaces.IRecordNewOrderExtraArguments>;
        recordDeliveries : (
            ids : Array<string>,
            retailerId : LocationId,
        ) => ThunkAction<
            Promise<Array<{deliveryId : DeliveryId, delivery : Delivery, invoiceUploadIdsById : StringValueMap<InvoiceUploadId, InvoiceUpload>}>>, IRecordNewOrderStore, RecordNewOrderActionInterfaces.IRecordNewOrderExtraArguments
        >;
        onFormFieldChange : (
            id : string,
            fieldName : RecordOrderFormFieldName,
            value : string,
        ) => ThunkAction<void, IRecordNewOrderStore, RecordNewOrderActionInterfaces.IRecordNewOrderExtraArguments>;
    }
}

type ISetComponentIsShownActionCreator = (id : string | null, componentName : ComponentName, isShown : boolean) => RecordNewOrderActionInterfaces.ISetComponentIsShown;
type IInitializeRecordNewOrderViewFormActionCreator = (id : string) => RecordNewOrderActionInterfaces.IInitializeRecordNewOrderViewForm;
type IClearRecordNewOrderViewFormActionCreator = (id : string) => RecordNewOrderActionInterfaces.IClearRecordNewOrderViewForm;
type ISetSortedDistributorOptionsAndLabelNameActionCreator = (sortedDistributorOptionsAndLabelName : OptionsAndLabelNameTuples) => RecordNewOrderActionInterfaces.ISetSortedDistributorOptionsAndLabelName;
type ISetDeliveriesByIdActionCreator = (deliveriesById : StringValueMap<DeliveryId, Delivery>) => RecordNewOrderActionInterfaces.ISetDeliveriesById;
type ISetCurrentDistributorOptionActionCreator = (id : string, currentDistributorOption : IOption | null) => RecordNewOrderActionInterfaces.ISetCurrentDistributorOption;
type ISetOrderDateActionCreator = (id : string, dateType : OrderDateType, date : DateTime) => RecordNewOrderActionInterfaces.ISetOrderDate;
type ISetValidationInputForFieldNameActionCreator = (id : string, fieldName : RecordOrderFormFieldName, inputData : IValidationInputData) => RecordNewOrderActionInterfaces.ISetValidationInputForFieldName;
type ISetOrderHasBeenDeliveredActionCreator = (id : string, orderHasBeenDelivered : boolean) => RecordNewOrderActionInterfaces.ISetOrderHasBeenDelivered;
type ISetShouldSubmitUploadForProcessingActionCreator = (id : string, shouldSubmitUploadForProcessing : boolean) => RecordNewOrderActionInterfaces.ISetShouldSubmitUploadForProcessing;
type ISetPricePerInvoiceActionCreator = (pricePerInvoice : number) => RecordNewOrderActionInterfaces.ISetPricePerInvoice;

const setComponentIsShown : ISetComponentIsShownActionCreator = (
    id : string | null,
    componentName : ComponentName,
    isShown : boolean,
) : RecordNewOrderActionInterfaces.ISetComponentIsShown => ({
    payload: {
        id,
        isShown,
        componentName,
    },
    type: ActionTypes.SET_COMPONENT_IS_SHOWN
});

const initializeRecordNewOrderViewForm : IInitializeRecordNewOrderViewFormActionCreator = (
    id : string,
) : RecordNewOrderActionInterfaces.IInitializeRecordNewOrderViewForm => ({
    payload: {
        id,
    },
    type: ActionTypes.INITIALIZE_RECORD_NEW_ORDER_VIEW_FORM,
});

const clearRecordNewOrderViewForm : IClearRecordNewOrderViewFormActionCreator = (
    id : string,
) : RecordNewOrderActionInterfaces.IClearRecordNewOrderViewForm => ({
    payload: {
        id,
    },
    type: ActionTypes.CLEAR_RECORD_NEW_ORDER_VIEW_FORM,
});

const setSortedDistributorOptionsAndLabelName : ISetSortedDistributorOptionsAndLabelNameActionCreator = (
    sortedDistributorOptionsAndLabelName : OptionsAndLabelNameTuples,
) : RecordNewOrderActionInterfaces.ISetSortedDistributorOptionsAndLabelName => ({
    payload: {
        sortedDistributorOptionsAndLabelName,
    },
    type: ActionTypes.SET_SORTED_DISTRIBUTOR_OPTIONS,
});

const setCurrentDistributorOption : ISetCurrentDistributorOptionActionCreator = (
    id : string,
    currentDistributorOption : IOption | null,
) : RecordNewOrderActionInterfaces.ISetCurrentDistributorOption => ({
    payload: {
        id,
        currentDistributorOption,
    },
    type: ActionTypes.SET_CURRENT_DISTRIBUTOR_OPTION,
});

const setDeliveriesById : ISetDeliveriesByIdActionCreator = (
    deliveriesById : StringValueMap<DeliveryId, Delivery>,
) : RecordNewOrderActionInterfaces.ISetDeliveriesById => ({
    payload: {
        deliveriesById
    },
    type: ActionTypes.SET_DELIVERIES_BY_ID,
});

const setOrderDate : ISetOrderDateActionCreator = (
    id : string,
    dateType : OrderDateType,
    date : DateTime,
) : RecordNewOrderActionInterfaces.ISetOrderDate => ({
    payload: {
        id,
        dateType,
        date,
    },
    type: ActionTypes.SET_ORDER_DATE,
});

const setValidationInputForFieldName : ISetValidationInputForFieldNameActionCreator = (
    id : string,
    fieldName : RecordOrderFormFieldName,
    inputData : IValidationInputData,
) : RecordNewOrderActionInterfaces.ISetValidationInputForFieldName => ({
    payload: {
        id,
        fieldName,
        inputData,
    },
    type: ActionTypes.SET_VALIDATION_INPUT_DATA_BY_FIELD_NAME,
});

const setOrderHasBeenDelivered : ISetOrderHasBeenDeliveredActionCreator = (
    id : string,
    orderHasBeenDelivered : boolean,
) : RecordNewOrderActionInterfaces.ISetOrderHasBeenDelivered => ({
    payload: {
        id,
        orderHasBeenDelivered,
    },
    type: ActionTypes.SET_ORDER_HAS_BEEN_DELIVERED,
});

const setShouldSubmitUploadForProcessing : ISetShouldSubmitUploadForProcessingActionCreator = (
    id : string,
    shouldSubmitUploadForProcessing : boolean,
) : RecordNewOrderActionInterfaces.ISetShouldSubmitUploadForProcessing => ({
    payload: {
        id,
        shouldSubmitUploadForProcessing,
    },
    type: ActionTypes.SET_SHOULD_SUBMIT_UPLOAD_FOR_PROCESSING,
});

const setPricePerInvoice : ISetPricePerInvoiceActionCreator = (
    pricePerInvoice : number,
) : RecordNewOrderActionInterfaces.ISetPricePerInvoice => ({
    payload: {
        pricePerInvoice,
    },
    type: ActionTypes.SET_PRICE_PER_INVOICE,
});

const recordNewOrder = (
    id : string,
    retailerId : LocationId,
    invoiceUploadFiles : Array<File | null>,
    shouldRedirectOnCreate : boolean,
) : ThunkAction<Promise<{deliveryId : DeliveryId, delivery : Delivery, invoiceUploadIdsById : StringValueMap<InvoiceUploadId, InvoiceUpload>}>, IRecordNewOrderStore, RecordNewOrderActionInterfaces.IRecordNewOrderExtraArguments> => {
    return (
        dispatch : Dispatch<IRecordNewOrderStore>,
        getState : () => IRecordNewOrderStore,
        extraArguments : RecordNewOrderActionInterfaces.IRecordNewOrderExtraArguments
    ) : Promise<{deliveryId : DeliveryId, delivery : Delivery, invoiceUploadIdsById : StringValueMap<InvoiceUploadId, InvoiceUpload>}> => {
        return new Promise<{deliveryId : DeliveryId, delivery : Delivery, invoiceUploadIdsById : StringValueMap<InvoiceUploadId, InvoiceUpload>}>((resolve, reject) => {
            const modalForm = getState().recordNewOrderState.recordNewOrderViewForm[id];

            if (modalForm.currentDistributorOption === null) {
                return reject('cannot record order without vendor');
            }

            dispatch(setComponentIsShown(id, 'recordNewOrderModalLoadingCover', true));

            const distributorId = new DistributorId(modalForm.currentDistributorOption.value);

            const dateDelivered = moment(modalForm.dateDelivered.toTimestampWithMillisecondPrecision().timeSinceUnixEpoch.value);
            const datePlaced = moment(modalForm.datePlaced.toTimestampWithMillisecondPrecision().timeSinceUnixEpoch.value);

            let userDefinedTotal : number | null;
            if (window.GLOBAL_FEATURE_ACCESS.invoice_totals && modalForm.validationInputByFieldName.invoiceTotal.value !== '') {
                if (!modalForm.validationInputByFieldName.invoiceTotal.isValid) {
                    dispatch(setComponentIsShown(id, 'recordNewOrderModalLoadingCover', false));
                    return reject('Invalid invoice total');
                }
                userDefinedTotal = parseFloat(modalForm.validationInputByFieldName.invoiceTotal.value.replace(/\$/g, ''));
            } else {
                userDefinedTotal = null;
            }

            let invoiceNumber : string | null;
            if (!modalForm.validationInputByFieldName.invoiceNumber.isValid) {
                dispatch(setComponentIsShown(id, 'recordNewOrderModalLoadingCover', false));
                return reject('Invalid invoice number');
            }
            if (modalForm.validationInputByFieldName.invoiceNumber.value === '') {
                invoiceNumber = null;
            } else {
                invoiceNumber = modalForm.validationInputByFieldName.invoiceNumber.value;
            }

            const timeNow = moment.utc();

            const deliveryReport : IDeliveryReport | null = modalForm.orderHasBeenDelivered ? {
                dateDelivered,
                dateReported: timeNow,
                reporterUserId: new UserAccountId(window.GLOBAL_USER_ID), // is there a different/better way we want to do this?,
            } : null;

            return extraArguments.services.orderingService.recordNewOrderForLocation(
                retailerId,
                distributorId,
                datePlaced,
                dateDelivered,
                window.GLOBAL_USER_NAME,
                timeNow,
                userDefinedTotal,
                invoiceNumber,
                deliveryReport)
            .then((deliveryId) => {
                const invoiceUploadPromises : Array<Promise<{ invoiceUpload : InvoiceUpload, invoiceUploadId : InvoiceUploadId }>> = [];
                invoiceUploadFiles.forEach((file) => {
                    if (file !== null) {
                        invoiceUploadPromises.push(extraArguments.services.invoiceUploadService.createInvoiceUpload(retailerId, file, deliveryId));
                    }
                });
                return Promise.all(invoiceUploadPromises)
                .then((invoiceUploadResponse) => {
                    const recordOrderTrackingInfo = {
                        type: shouldRedirectOnCreate ? 'record_and_edit' : 'stay_on_history_page',
                        added_invoice_number: !(invoiceNumber === null),
                        added_invoice_total: !(userDefinedTotal === null),
                        marked_as_confirmed: modalForm.orderHasBeenDelivered,
                    };
                    Observer.observeAction('record_order_from_modal', recordOrderTrackingInfo);

                    if (shouldRedirectOnCreate) {
                        window.location.href = '/ordering/record/detail/r/' + retailerId.getValue() + '/#/order/' + deliveryId.getValue();
                    } else {
                        window.dispatchEvent(new window.CustomEvent('reloadOrderHistoryTableOnRecordOrder'));
                    }
                    dispatch(setComponentIsShown(id, 'recordNewOrderModalLoadingCover', false));

                    const distributorForDelivery = distributorId.getValue() === NULL_DISTRIBUTOR_ID ? null : distributorId;

                    const delivery = new Delivery(
                        datePlaced,
                        dateDelivered,
                        distributorForDelivery,
                        [],
                        new Map(),
                        [],
                        new Map(),
                        invoiceNumber,
                        userDefinedTotal,
                        null,
                        null,
                        null,
                        [],
                        window.GLOBAL_USER_NAME,
                        datePlaced,
                        deliveryReport === null ? null : deliveryReport.dateReported,
                        deliveryReport === null ? null : deliveryReport.reporterUserId,
                        new StringValueMap(),
                        null,
                        null,
                        null
                    );

                    const invoiceUploadIdsById = new StringValueMap<InvoiceUploadId, InvoiceUpload>();
                    invoiceUploadResponse.forEach((response) => {
                        invoiceUploadIdsById.set(response.invoiceUploadId, response.invoiceUpload);
                    });

                    return resolve({
                        deliveryId,
                        delivery,
                        invoiceUploadIdsById,
                    });
                });
            })
            .catch((error) => {
                window.showGlobalErrorDialog(null);
                dispatch(setComponentIsShown(id, 'recordNewOrderModalLoadingCover', false));
                return reject(error);
            });
        });
    };
};

const recordDeliveries = (
    recordNewOrderViewIds : Array<string>,
    retailerId : LocationId,
) : ThunkAction<Promise<Array<{deliveryId : DeliveryId, delivery : Delivery, invoiceUploadIdsById : StringValueMap<InvoiceUploadId, InvoiceUpload>}>>, IRecordNewOrderStore, RecordNewOrderActionInterfaces.IRecordNewOrderExtraArguments> => {
    return (
        dispatch : Dispatch<IRecordNewOrderStore>,
        getState : () => IRecordNewOrderStore,
        extraArguments : RecordNewOrderActionInterfaces.IRecordNewOrderExtraArguments
    ) : Promise<Array<{deliveryId : DeliveryId, delivery : Delivery, invoiceUploadIdsById : StringValueMap<InvoiceUploadId, InvoiceUpload>}>> => {
        const locationId = new LocationId(window.GLOBAL_RETAILER_ID);
        const  {
            fileUploadState,
            recordNewOrderState
        } = getState();

        const recordNewOrderViewForms = recordNewOrderState.recordNewOrderViewForm;

        let allFormsAreValid = true;
        recordNewOrderViewIds.map((id) => {
            const recordNewOrderForm = recordNewOrderViewForms[id];
            const invoiceUploadFiles = fileUploadState[id] || [];

            allFormsAreValid = allFormsAreValid && RecordNewOrderFormUtils.validateRecordNewOrderForm(recordNewOrderForm, invoiceUploadFiles);
        });

        if (allFormsAreValid) {
            return Promise.all(recordNewOrderViewIds.map((id) => {
                const recordNewOrderForm = recordNewOrderViewForms[id];
                const invoiceUploadFiles = fileUploadState[id] || [];

                return dispatch(RecordNewOrderActions.recordNewOrder(id, retailerId, invoiceUploadFiles, false))
                .then((orderResponse) => {
                    let submitInventoryUploadsForProcessingPromise : Promise<Array<void>> = Promise.resolve([]);

                    if (recordNewOrderForm.shouldSubmitUploadForProcessing) {
                        submitInventoryUploadsForProcessingPromise = Promise.all(Array.from(orderResponse.invoiceUploadIdsById.keys()).map((invoiceUploadId : InvoiceUploadId) => {
                            return extraArguments.services.invoiceUploadService.submitInvoiceUploadsForProcessing(locationId, new Array(invoiceUploadId), false);
                        }));
                    }

                    return submitInventoryUploadsForProcessingPromise
                    .then(() => {
                        return orderResponse;
                    });
                });
            }));
        }

        throw new RuntimeException('unexpected');
    };
};

const setInitialRetailerData = (
    distributorsById : StringValueMap<DistributorId, Distributor>,
    distributorIdsWithRelationship : StringValueSet<DistributorId>,
    deliveriesById : StringValueMap<DeliveryId, Delivery>,
    pricePerInvoice : number
) : ThunkAction<Promise<void>, IRecordNewOrderStore, RecordNewOrderActionInterfaces.IRecordNewOrderExtraArguments> => {
    return (dispatch : Dispatch<IRecordNewOrderStore>, getState : () => IRecordNewOrderStore, extraArguments : RecordNewOrderActionInterfaces.IRecordNewOrderExtraArguments) : Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const sortedDistributorOptionsAndLabelName : OptionsAndLabelNameTuples = [
                ['My Vendors', []],
                ['Other Vendors', []],
            ];

            distributorsById.forEach((distributor, distributorId) => {
                const isRelatedDistributor = distributorIdsWithRelationship.has(distributorId);

                const bucketForDistributorOption : 0 | 1 = isRelatedDistributor ? 0 : 1;
                sortedDistributorOptionsAndLabelName[bucketForDistributorOption][1].push({
                    value: distributorId.getValue(),
                    label: distributor.getName(),
                    icon: null,
                });
            });

            const nullDistributorDropdownOption = {
                label: 'Other',
                value: NULL_DISTRIBUTOR_ID,
                icon: null,
            };
            sortedDistributorOptionsAndLabelName[0][1].push(nullDistributorDropdownOption);
            sortedDistributorOptionsAndLabelName[0][1].sort((a : IOption, b : IOption) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : ((b.label.toLowerCase() > a.label.toLowerCase()) ? -1 : 0));

            dispatch(setSortedDistributorOptionsAndLabelName(sortedDistributorOptionsAndLabelName));
            dispatch(setPricePerInvoice(pricePerInvoice));
            dispatch(setDeliveriesById(deliveriesById));
            return resolve();
        });
    };
};

const onNewDistributorCreated = (
    distributor : Distributor,
    distributorId : DistributorId,
) : ThunkAction<Promise<void>, IRecordNewOrderStore, RecordNewOrderActionInterfaces.IRecordNewOrderExtraArguments> => {
    return (dispatch : Dispatch<IRecordNewOrderStore>, getState : () => IRecordNewOrderStore, extraArguments : RecordNewOrderActionInterfaces.IRecordNewOrderExtraArguments) : Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const sortedDistributorOptionsAndLabelName = getState().recordNewOrderState.recordNewOrderViewFormDefaults;

            sortedDistributorOptionsAndLabelName[0][1].push({
                value: distributorId.getValue(),
                label: distributor.getName(),
                icon: null,
            });
            sortedDistributorOptionsAndLabelName[0][1].sort((a : IOption, b : IOption) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : ((b.label.toLowerCase() > a.label.toLowerCase()) ? -1 : 0));

            dispatch(setSortedDistributorOptionsAndLabelName(sortedDistributorOptionsAndLabelName));
        });
    };
};

const onFormFieldChange = (
    id : string,
    fieldName : RecordOrderFormFieldName,
    value : string,
) : ThunkAction<void, IRecordNewOrderStore, RecordNewOrderActionInterfaces.IRecordNewOrderExtraArguments> => {
    return (dispatch : Dispatch<IRecordNewOrderStore>, getState : () => IRecordNewOrderStore, extraArguments : RecordNewOrderActionInterfaces.IRecordNewOrderExtraArguments) : void => {
        const { isValid, errorMessage } = RecordNewOrderFormUtils.validateRecordNewOrderFormValueByFieldName(fieldName, value);

        dispatch(setValidationInputForFieldName(id, fieldName, {
            value,
            isValid,
            errorMessage,
        }));
    };
};

export const RecordNewOrderActions : RecordNewOrderActionInterfaces.IRecordNewOrderActionCreatorsMapObject = {
    // non-thunk actions
    setComponentIsShown,
    initializeRecordNewOrderViewForm,
    clearRecordNewOrderViewForm,
    setSortedDistributorOptionsAndLabelName,
    setCurrentDistributorOption,
    setOrderDate,
    setValidationInputForFieldName,
    setOrderHasBeenDelivered,
    setShouldSubmitUploadForProcessing,

    // thunk actions
    setInitialRetailerData,
    recordNewOrder,
    recordDeliveries,
    onFormFieldChange,
    onNewDistributorCreated,
};
