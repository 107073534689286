import React from 'react';

interface IPriceEstimatesProps {
    readonly currencySymbol : string;
    readonly sortedPriceEstimates : Array<{ unit : string, priceEstimateString : string}>;
}

export class PriceEstimates extends React.Component<IPriceEstimatesProps, object> {
    public render() {
        const {
            currencySymbol,
            sortedPriceEstimates,
        } = this.props;

        const priceEstimateElements : Array<JSX.Element> = [];

        sortedPriceEstimates.forEach((priceEstimate) => {
            priceEstimateElements.push(
                <div key={ priceEstimate.unit } className="product-form-price-estimate-cell">
                    <div className="product-form-row-content-label">
                        { currencySymbol }
                    </div>
                    <span>
                        { `${ priceEstimate.priceEstimateString } / ${ priceEstimate.unit }` }
                    </span>
                </div>
            );
        });

        return priceEstimateElements;
    }
}
