import { IStringValue } from 'api/Core/IStringValue';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

const NULL_KEY_VALUE = '__NULL_KEY_VALUE__';

export class StringValueMap<K extends IStringValue | null | string, V> {
    public static merge<K extends IStringValue, V>(...maps : Array<StringValueMap<K, V>>) : StringValueMap<K, V> {
        const result = new StringValueMap<K, V>();
        maps.forEach((m) => m.forEach((v, k) => result.set(k, v)));
        return result;
    }

    private readonly keyMap : Map<string, K>;
    private readonly valueMap : Map<string, V>;

    constructor(stringValueMap? : StringValueMap<K, V>) {
        this.keyMap = (stringValueMap) ? new Map(stringValueMap.keyMap) : new Map();
        this.valueMap = (stringValueMap) ? new Map(stringValueMap.valueMap) : new Map();
    }

    public clear() : void {
        this.keyMap.clear();
        this.valueMap.clear();
    }

    public delete(key : K) : boolean {
        this.keyMap.delete(this.getKeyValue(key));
        return this.valueMap.delete(this.getKeyValue(key));
    }

    public get(key : K) : V | undefined {
        return this.valueMap.get(this.getKeyValue(key));
    }

    public getRequired(k : K) : V {
        const v = this.get(k);
        if (typeof v === 'undefined') {
            throw new RuntimeException('not found: ' + this.getKeyValue(k));
        }
        return v;
    }

    public has(key : K) : boolean {
        return this.valueMap.has(this.getKeyValue(key));
    }

    public set(key : K, value : V) : this {
        this.valueMap.set(this.getKeyValue(key), value);
        this.keyMap.set(this.getKeyValue(key), key);

        return this;
    }

    get size() : number {
        return this.valueMap.size;
    }

    public forEach(
        callbackFunction : (value : V, key : K) => void,
        thisArg? : any
    ) : void {
        this.valueMap.forEach((value : V, keyValue : string, map : Map<string, V>) => {
            const key : K | undefined = this.keyMap.get(keyValue);

            if (typeof key === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            callbackFunction(value, key);

        }, thisArg);
    }

    public keys() : IterableIterator<K> {
        return this.keyMap.values();
    }

    public values() : IterableIterator<V> {
        return this.valueMap.values();
    }

    private getKeyValue(key : K) : string {
        if (key === null) {
            return NULL_KEY_VALUE;
        } else if (typeof key === 'string') {
                return key + ':-string';
        }

        return key.getValue() + ':-' + key.constructor.name;
    }
}
