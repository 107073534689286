import { StringValueMap } from 'api/Core/StringValueMap';
import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';
import { ProductId } from 'api/Product/model/ProductId';
import { IColumnSorting } from 'shared/components/SortableColumnHeader';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { GroupByOption } from 'shared/models/GroupByOption';
import { SortDirection } from 'shared/models/SortDirection';
import { IInventoryCountData } from '../reducers/InventoryCountReducers';

import { defaultGroupNameComparator, defaultProductRowFilter, getBrandAndNameStringForSort, getCleanCountFromCountEvent, getDefaultGroupNameForProductRow } from 'shared/utils/productSortingFilteringAndGroupingUtils';
import { compareNumbers, SortingFilteringAndGroupingUtil } from 'shared/utils/sortingFilteringAndGroupingUtils';

interface IStorageAreaSortingUtilExtraArgs {
    inventoryCountData : IInventoryCountData;
    storageAreaId : StorageAreaId;
}

const rowIdComparator = (productId1 : ProductId, productId2 : ProductId, columnSort : IColumnSorting, extraArgs : IStorageAreaSortingUtilExtraArgs) : number => {
    const {
        storageAreaId,
        inventoryCountData,
    } = extraArgs;

    const direction = columnSort.direction;
    const sortedBy = columnSort.sortedBy;

    let comparisonResult : number;
    switch (sortedBy) {
        case 'ITEM_NAME':
            const product1 = inventoryCountData.productsById.get(productId1);
            const product2 = inventoryCountData.productsById.get(productId2);

            if ((typeof product1 === 'undefined') || (typeof product2 === 'undefined')) {
                throw new RuntimeException('unexpected');
            }

            const nameString1 = getBrandAndNameStringForSort(product1);
            const nameString2 = getBrandAndNameStringForSort(product2);
            comparisonResult = nameString1.localeCompare(nameString2);
            break;
        case 'STORAGE_AREA_INDEX':
            const sortedProductIdList = inventoryCountData.inventoryCount.getInventoryConfiguration().getSortedProductIdListsByStorageAreaId().get(storageAreaId);

            if (typeof sortedProductIdList === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            const storageAreaIndex1 = sortedProductIdList.findIndex((p) => p.equals(productId1));
            const storageAreaIndex2 = sortedProductIdList.findIndex((p) => p.equals(productId2));
            comparisonResult = compareNumbers(storageAreaIndex1, storageAreaIndex2);
            break;
        case 'LAST_COUNT':
            if (inventoryCountData.usageStartInventoryCount === null) {
                comparisonResult = 0;
            } else {
                const lastProductCountEventsByProductId = inventoryCountData.usageStartInventoryCount.getProductCountEventsByProductIdByStorageAreaId().get(storageAreaId);
                if (typeof lastProductCountEventsByProductId === 'undefined') {
                    throw new RuntimeException('unexpected');
                }
                const lastCount1 = getCleanCountFromCountEvent(lastProductCountEventsByProductId.get(productId1));
                const lastCount2 = getCleanCountFromCountEvent(lastProductCountEventsByProductId.get(productId2));
                comparisonResult = compareNumbers(lastCount1, lastCount2);
            }
            break;
        case 'CURRENT_COUNT':
            const productCountEventsByProductId = inventoryCountData.inventoryCount.getProductCountEventsByProductIdByStorageAreaId().get(storageAreaId);

            if (typeof productCountEventsByProductId === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            const currentCount1 = getCleanCountFromCountEvent(productCountEventsByProductId.get(productId1));
            const currentCount2 = getCleanCountFromCountEvent(productCountEventsByProductId.get(productId2));
            comparisonResult = compareNumbers(currentCount1, currentCount2);
            break;
        default:
            throw new RuntimeException('unhandled sort field');
    }

    if (direction === SortDirection.DESCENDING) {
        return comparisonResult * -1;
    }

    return comparisonResult;
};

const rowFilter = (productId : ProductId, filterTerm : string | null, extraArgs : IStorageAreaSortingUtilExtraArgs) => {
    return defaultProductRowFilter(productId, filterTerm, extraArgs.inventoryCountData.productsById, extraArgs.inventoryCountData.distributorIdsByProductId, extraArgs.inventoryCountData.distributorsById, new StringValueMap());
};

const groupNameGetter = (productId : ProductId, groupByOption : GroupByOption, extraArgs : IStorageAreaSortingUtilExtraArgs) => {
    return getDefaultGroupNameForProductRow(productId, groupByOption, extraArgs.inventoryCountData.productsById, extraArgs.inventoryCountData.distributorIdsByProductId, extraArgs.inventoryCountData.distributorsById);
};

const groupNameComparator = (groupName1 : string, groupName2 : string, groupByOption : GroupByOption, extraArgs : IStorageAreaSortingUtilExtraArgs) => {
    return defaultGroupNameComparator(groupName1, groupName2, groupByOption);
};

const intermediateResultCacheIsValid = (rowIds : Array<ProductId>, lastRowIds : Array<ProductId>, extraArgs : IStorageAreaSortingUtilExtraArgs, lastExtraArgs : IStorageAreaSortingUtilExtraArgs) : boolean => {
    return (rowIds === lastRowIds) && (extraArgs.inventoryCountData === lastExtraArgs.inventoryCountData) && extraArgs.storageAreaId.equals(lastExtraArgs.storageAreaId);
};

export const getClickWillCollapseAll = (isOpenByGroupName : {[groupName : string] : boolean}) => {
    return Object.keys(isOpenByGroupName).reduce((nextClickWillCollapseAll : boolean, groupName : string) => nextClickWillCollapseAll || isOpenByGroupName[groupName], false);
};

export const storageAreaSortingUtil = new SortingFilteringAndGroupingUtil(
    rowIdComparator,
    rowFilter,
    groupNameGetter,
    groupNameComparator,
    intermediateResultCacheIsValid,
);
