import PrepEventModel from 'gen-thrift/prep_event_Model_types';

import moment from 'moment-timezone';

import { StringValueMap } from 'api/Core/StringValueMap';
import { PrepEvent } from 'api/PrepEvent/model/PrepEvent';
import { PrepEventId } from 'api/PrepEvent/model/PrepEventId';
import { ProductThriftToObjectSerializer } from 'api/Product/serializer/ProductThriftToObjectSerializer';
import { UserAccountThriftToObjectSerializer } from 'api/UserAccount/serializer/UserAccountThriftToObjectSerializer';

export class PrepEventThriftToObjectSerializer {

    constructor(
        private userAccountThriftToObjectSerializer : UserAccountThriftToObjectSerializer,
        private productThriftToObjectSerializer : ProductThriftToObjectSerializer
    ) {}

    ////////////////////////////////////
    // MODELS
    ////////////////////////////////////

    public getPrepEventId(
        prepEventId : PrepEventModel.PrepEventId
    ) : PrepEventId {
        return new PrepEventId(prepEventId.value);
    }

    public getPrepEvent(
        prepEvent : PrepEventModel.PrepEvent
    ) : PrepEvent {
        const creationMetadata = this.userAccountThriftToObjectSerializer.getUserAccountIdentifierAndTimestamp(prepEvent.creationMetadata);
        const lastEditedMetadata = prepEvent.lastEditedMetadata ? this.userAccountThriftToObjectSerializer.getUserAccountIdentifierAndTimestamp(prepEvent.lastEditedMetadata) : null;
        const deletionMetadata = prepEvent.deletionMetadata ? this.userAccountThriftToObjectSerializer.getUserAccountIdentifierAndTimestamp(prepEvent.deletionMetadata) : null;

        return new PrepEvent(
            moment(prepEvent.prepEventTime.timeSinceUnixEpoch.value),
            creationMetadata,
            lastEditedMetadata,
            deletionMetadata,
            prepEvent.note,
            prepEvent.type,
            this.productThriftToObjectSerializer.getQuantityOfProductByProductId(prepEvent.inputQuantityOfProductByProductId),
            this.productThriftToObjectSerializer.getQuantityOfProductByProductId(prepEvent.outputQuantityOfProductByProductId),
            this.productThriftToObjectSerializer.getCostByProductId(prepEvent.outputPriceByProductId),
        );
    }

    ////////////////////////////////////
    // COLLECTIONS
    ////////////////////////////////////

    public getMapFromPrepEventAndIds(
        prepEventAndIds : Array<PrepEventModel.PrepEventAndId>
    ) : StringValueMap<PrepEventId, PrepEvent> {
        const prepEventsByPrepEventId = new StringValueMap<PrepEventId, PrepEvent>();
        prepEventAndIds.map((prepEventAndId : PrepEventModel.PrepEventAndId) => {
            const prepEventId = this.getPrepEventId(prepEventAndId.prepEventId);
            const prepEvent = this.getPrepEvent(prepEventAndId.prepEvent);
            prepEventsByPrepEventId.set(prepEventId, prepEvent);
        });

        return prepEventsByPrepEventId;
    }

}
