import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';

import { IColumnSorting } from 'shared/components/SortableColumnHeader';
import { GroupByOption } from 'shared/models/GroupByOption';
import { HexColor } from 'shared/models/HexColor';
import { SortDirection } from 'shared/models/SortDirection';

// TotalCountSummary
export const PROGRESS_BAR_COLOR = new HexColor('#F49C57');
export const PROGRESS_BAR_BACKGROUND_COLOR = new HexColor('#dddddd');

export const PROGRESS_BAR_HEIGHT_IN_PIXELS : number = 5;

export const STORAGE_AREA_ID_FOR_COUNT_SUMMARY = new StorageAreaId('count_summary');

export const DEFAULT_STORAGE_AREA_GROUP_BY_OPTION = GroupByOption.ALL_ITEMS;
export const DEFAULT_COUNT_SUMMARY_COLUMN_SORT : IColumnSorting = {
    sortedBy: 'ITEM_NAME',
    direction: SortDirection.ASCENDING,
};
export const DEFAULT_STORAGE_AREA_COLUMN_SORT : IColumnSorting = {
    sortedBy: 'STORAGE_AREA_INDEX',
    direction: SortDirection.ASCENDING,
};
