
import { TransferDirection } from 'api/Transfer/model/TransferDirection';
import React from 'react';

import { StringValueMap } from 'api/Core/StringValueMap';
import { LocationId } from 'api/Location/model/LocationId';
import { Product } from 'api/Product/model/Product';
import { ProductAmount } from 'api/Product/model/ProductAmount';
import { ProductId } from 'api/Product/model/ProductId';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { TransferId } from 'api/Transfer/model/TransferId';
import { TransferReport } from 'api/Transfer/model/TransferReport';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { numberUtils } from 'shared/utils/numberUtils';

export interface IItemCardHistoryTransfer {
    product : Product;
    productId : ProductId;
    transfer : TransferReport;
    transferId : TransferId;
    locationNamesByLocationId : StringValueMap<LocationId, string>;
}

export class ItemCardHistoryTransfer extends React.Component<IItemCardHistoryTransfer, object> {
    public render() {
        const {
            transfer,
            transferId,
            productId,
            product,
            locationNamesByLocationId,
        } = this.props;

        const transferReportData = transfer.getTransferReportData();
        const isOriginator = transferReportData.getDirection() === TransferDirection.FROM_PERSPECTIVE_TO_PARTNER_LOCATION;
        const productAmounts = transferReportData.getProductAmounts().filter((productAmount : ProductAmount) => {
            return productAmount.getProductId().equals(productId);
        });
        const partnerLocation = transferReportData.getPartnerLocation();
        let partnerLocationName;
        if (partnerLocation instanceof LocationId) {
            const locationName = locationNamesByLocationId.get(partnerLocation);
            if (typeof locationName === 'undefined') {
                throw new RuntimeException('unexpected');
            }
            partnerLocationName = locationName;
        } else {
            partnerLocationName = partnerLocation === '' ? 'Other' : partnerLocation;
        }

        const transferLink = `/inventory/transfer_report/${ transferId.getValue() }/r/${ window.GLOBAL_RETAILER_ID }`;

        const iconClassName = isOriginator ? 'bevico-transfer-out' : 'bevico-transfer-in';

        return (
            <div className="activity-item item-history-transfer" key={ transferId.getValue() }>
                <div className="top-row">
                    <span className={ 'bevicon ' + iconClassName } />
                    <div className="title">
                        <a className="link" href={ transferLink }>Transfer { isOriginator ? 'Out' : 'In' }</a>
                        <span className="to-from-label ellipsis">{ isOriginator ? ' to ' : ' from ' }{ partnerLocationName }</span>
                    </div>
                </div>
                <div className="line-item-row line-item-row-header">
                    <div className="qty">Qty</div>
                    <div className="spacer"/>
                </div>
                { productAmounts.map((productAmount, index, array) => {
                    const quantityInUnit = productAmount.getQuantityInUnit();
                    const unit = quantityInUnit.getUnit();
                    const quantity = quantityInUnit.getQuantity();
                    let quantityString = quantity.toFixed(2) + ' ';

                    quantityString += PackagingUtils.getPackagingDisplayTextForProductQuantityUnit(product.getPackagingsAndMappings(), unit, numberUtils.isPlural(quantity));

                    return (
                        <div key={ index } className={ 'line-item-row' + (array.length > 1 ? ' multiple' : '') }>
                            <div className="qty">{ quantityString }</div>
                            <div className="spacer"/>
                        </div>
                    );
                }) }

            </div>
        );
    }
}
