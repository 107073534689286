import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import CorePrimitivesModel from 'gen-thrift/core_primitives_Model_types';

import { IAction, IActionCreatorsMapObject } from '../../../models/IAction';

import { UserPasswordManagerImpl } from '../../../lib/account/impl/UserPasswordManagerImpl';

import { IThanksForRegisteringState } from './reducers';

export interface IThanksForRegisteringStore {
    thanksForRegisteringState : IThanksForRegisteringState;
}

export const ActionTypes = {
    SET_IS_RESENDING_EMAIL : 'THANKS_FOR_REGISTERING_MODAL/SET_IS_RESENDING_EMAIL',
    SET_IS_EMAIL_RESENT : 'THANKS_FOR_REGISTERING_MODAL/SET_IS_EMAIL_RESENT',
};

export namespace ThanksForRegisteringActionInterfaces {
    export interface ISetIsResendingEmail extends IAction {
        payload : {
            isResendingEmail : boolean;
        };
    }

    export interface ISetIsEmailResent extends IAction {
        payload : {
            isEmailResent : boolean;
        };
    }

    export interface IThanksForRegisteringActionCreatorsMapObject extends IActionCreatorsMapObject {
        onResendEmail : (
            emailAddress : string,
        ) => ThunkAction<void, IThanksForRegisteringStore, {services : ThanksForRegisteringActionInterfaces.IServices}>;
    }

    export interface IServices {
        userPasswordManager : UserPasswordManagerImpl;
    }
}

const setIsResendingEmail = (
    isResendingEmail : boolean
) : ThanksForRegisteringActionInterfaces.ISetIsResendingEmail => ({
    payload : {
        isResendingEmail,
    },
    type : ActionTypes.SET_IS_RESENDING_EMAIL,
});

const setIsEmailResent = (
    isEmailResent : boolean
) : ThanksForRegisteringActionInterfaces.ISetIsEmailResent => ({
    payload : {
        isEmailResent,
    },
    type : ActionTypes.SET_IS_EMAIL_RESENT,
});

const onResendEmail = (
    emailAddress : string,
) : ThunkAction<void, IThanksForRegisteringStore, {services : ThanksForRegisteringActionInterfaces.IServices}> => {
    return (dispatch : Dispatch<IThanksForRegisteringStore>, getState : () => IThanksForRegisteringStore, extraArguments : {services : ThanksForRegisteringActionInterfaces.IServices}) : void => {
        dispatch(setIsResendingEmail(true));
        extraArguments.services.userPasswordManager.sendPasswordSettingEmail(new CorePrimitivesModel.EmailAddress({ value : emailAddress }))
        .then(() => {
            dispatch(setIsResendingEmail(false));
            dispatch(setIsEmailResent(true));
        })
        .catch((error) => {
            dispatch(setIsResendingEmail(false));
            throw error;
        });
    };
};

export const ThanksForRegisteringActions : ThanksForRegisteringActionInterfaces.IThanksForRegisteringActionCreatorsMapObject = {
    onResendEmail,
};
