import React from 'react';

import '../css/ExpandingNote.scss';

export enum ExpandingNoteTheme {
    Underline = 1,
    Boxed = 2,
    Basic = 5,
}

export interface IExpandingNoteProps {
    readonly theme : ExpandingNoteTheme;
    readonly onChange : (event : React.ChangeEvent<HTMLTextAreaElement>) => void;
    readonly onBlur : (event : React.FocusEvent<HTMLTextAreaElement>) => void;
    readonly noteContent : string;
    readonly placeholder : string;
    readonly isDisabled : boolean;
}

export class ExpandingNote extends React.Component<IExpandingNoteProps, object> {
    private wrapperElement : Element | undefined;

    public render() {

        const {
            onChange,
            noteContent,
            placeholder,
            theme,
            isDisabled
        } = this.props;

        const themeClasses = this.generateThemeClasses();

        return (
            <div
                className={ themeClasses }
                ref={ this.wrapperRef }
            >
                <textarea
                    className="expanding-note-textarea"
                    placeholder={ placeholder }
                    onChange={ onChange }
                    onFocus={ this.onFocus }
                    onBlur={ this.onBlur }
                    value={ noteContent }
                    disabled={ isDisabled }
                />
                { theme !== ExpandingNoteTheme.Boxed && <hr className="active-border" /> }
                { theme !== ExpandingNoteTheme.Boxed && <hr className="bottom-border" /> }
                <div className="expanding-note-size">
                    { noteContent }
                </div>
            </div>
        );
    }

    private readonly generateThemeClasses = () : string => {
        let themeClasses : string = 'expanding-note-container';

        if (this.props.theme === ExpandingNoteTheme.Underline) {
            themeClasses += ' underline';
        } else if (this.props.theme === ExpandingNoteTheme.Boxed) {
            themeClasses += ' boxed';
        } else if (this.props.theme === ExpandingNoteTheme.Basic) {
            themeClasses += ' theme-basic';
        }

        return themeClasses;
    }

    private onFocus = (event : React.FocusEvent<HTMLTextAreaElement>) => {
        window.requestAnimationFrame(() => {
            // inputElement.setSelectionRange(0, 9999);
            if (this.wrapperElement) {
                this.wrapperElement.className = this.generateThemeClasses() + ' active';
            }
        });
    }

    private onBlur = (event : React.FocusEvent<HTMLTextAreaElement>) => {
        if (this.wrapperElement) {
            this.wrapperElement.className = this.generateThemeClasses();
        }

        if (this.props.onBlur !== null) {
            this.props.onBlur(event);
        }
    }

    private wrapperRef = (wrapperElement : HTMLDivElement) : void => {
        this.wrapperElement = wrapperElement;
    }
}
