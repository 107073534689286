export class FtpCredentials {
    constructor(
        private readonly username : string,
        private readonly password : string
    ) {
    }

    public getUsername() : string {
        return this.username;
    }

    public getPassword() : string {
        return this.password;
    }
}
