
import React from 'react';

import { BreakageId } from 'api/Breakage/model/BreakageId';
import { BreakageReport } from 'api/Breakage/model/BreakageReport';
import { Product } from 'api/Product/model/Product';
import { ProductAmount } from 'api/Product/model/ProductAmount';
import { ProductId } from 'api/Product/model/ProductId';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { numberUtils } from 'shared/utils/numberUtils';

export interface IItemCardHistoryBreakageProps {
    product : Product;
    productId : ProductId;
    breakage : BreakageReport;
    breakageId : BreakageId;
}

export class ItemCardHistoryBreakage extends React.Component<IItemCardHistoryBreakageProps, object> {

    public render() {
        const {
            breakage,
            breakageId,
            productId,
            product,
        } = this.props;

        const productAmounts = breakage.getBreakageReportData().getProductAmounts().filter((productAmount : ProductAmount) => {
            return productAmount.getProductId().equals(productId);
        });

        const breakageLink = `/inventory/breakage_report/${ breakageId.getValue() }/r/${ window.GLOBAL_RETAILER_ID }`;

        return (
            <div className="activity-item item-history-breakage" key={ breakageId.getValue() }>
                <div className="top-row">
                    <span className="bevicon bevico-breakage" />
                    <div className="title"><a className="link" href={ breakageLink }>Loss</a></div>
                </div>
                <div className="line-item-row line-item-row-header">
                    <div className="qty">Qty</div>
                    <div className="spacer"/>
                </div>
                { productAmounts.map((productAmount, index, array) => {
                    const quantityInUnit = productAmount.getQuantityInUnit();
                    const unit = quantityInUnit.getUnit();
                    const quantity = quantityInUnit.getQuantity();
                    let quantityString = quantity.toFixed(2) + ' ';

                    quantityString += PackagingUtils.getPackagingDisplayTextForProductQuantityUnit(product.getPackagingsAndMappings(), unit, numberUtils.isPlural(quantity));

                    return (
                        <div key={ index } className={ 'line-item-row' + (array.length > 1 ? ' multiple' : '') }>
                            <div className="qty">{ quantityString }</div>
                            <div className="spacer"/>
                        </div>
                    );
                }) }
            </div>
        );
    }
}
