import { IStringValue } from 'api/Core/IStringValue';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

const NULL_KEY_VALUE = '__NULL_KEY_VALUE__';

export class StringValueSet<T extends IStringValue | null | string> {
    private readonly keyMap : Map<string, T>;
    private readonly valueSet : Set<string>;

    constructor(stringValueSetOrArray? : StringValueSet<T> | ReadonlyArray<T> | IterableIterator<T>) {
        if (typeof stringValueSetOrArray === 'undefined') {
            this.keyMap = new Map();
            this.valueSet = new Set();

        } else if (stringValueSetOrArray instanceof StringValueSet) {
            const stringValueSet : StringValueSet<T> = stringValueSetOrArray;

            this.keyMap = new Map(stringValueSet.keyMap);
            this.valueSet = new Set(stringValueSet.valueSet);
        } else {
            const stringValueArray = Array.isArray(stringValueSetOrArray) ? stringValueSetOrArray : Array.from(stringValueSetOrArray);

            this.keyMap = new Map(
                stringValueArray.map(
                    (stringValue : T) => [
                        this.getKeyValue(stringValue),
                        stringValue,
                    ] as [string, T]
                )
            );

            this.valueSet = new Set(
                stringValueArray.map(
                    (stringValue : T) => this.getKeyValue(stringValue)
                )
            );
        }
    }

    public add(key : T) : this {
        this.keyMap.set(this.getKeyValue(key), key);
        this.valueSet.add(this.getKeyValue(key));

        return this;
    }

    public clear() : void {
        this.keyMap.clear();
        this.valueSet.clear();
    }

    public delete(key : T) : boolean {
        this.keyMap.delete(this.getKeyValue(key));
        return this.valueSet.delete(this.getKeyValue(key));
    }

    public has(key : T) : boolean {
        return this.valueSet.has(this.getKeyValue(key));
    }

    get size() : number {
        return this.valueSet.size;
    }

    public values() : IterableIterator<T> {
        return this.keyMap.values();
    }

    public forEach(
        callbackFunction : (value : T) => void,
        thisArg? : any
    ) : void {
        this.valueSet.forEach((value : string, value2 : string, set : Set<string>) => {
            const key : T | undefined = this.keyMap.get(value);

            if (typeof key === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            callbackFunction(key);

        }, thisArg);
    }

    public equals(other : any) : boolean {
        if (!(other instanceof StringValueSet)) {
            return false;
        }

        if (this.size !== other.size) {
            return false;
        }

        let isEqual = true;
        this.forEach((value) => {
            isEqual = other.has(value) && isEqual;
        });

        return isEqual;
    }

    public isSubsetOf(other : any) : boolean {
        if (!(other instanceof StringValueSet)) {
            return false;
        }

        if (this.size > other.size) {
            return false;
        }

        let isSubset = true;
        this.forEach((value) => {
            isSubset = other.has(value) && isSubset;
        });

        return isSubset;
    }

    private getKeyValue(key : T) : string {
        if (key === null) {
            return NULL_KEY_VALUE;
        } else if (typeof key === 'string') {
                return key + ':-string';
        }

        return key.getValue() + ':-' + key.constructor.name;
    }
}
