import { UserAccountIdAndTimestamp } from 'api/UserAccount/model/UserAccountIdAndTimestamp';
import CoreTimeModel from 'gen-thrift/core_time_Model_types';
// TODO Use different time model

import { LocationId } from 'api/Location/model/LocationId';
import { ProductAmount } from 'api/Product/model/ProductAmount';
import { TransferDirection } from 'api/Transfer/model/TransferDirection';

export class TransferReportData {

    constructor (
        private readonly perspectiveLocation : LocationId,
        private readonly productAmounts : Array<ProductAmount>,
        private readonly partnerLocation : LocationId | string,
        private readonly direction : TransferDirection,
        private readonly time : CoreTimeModel.TimestampWithMillisecondPrecision,
        private readonly note : string,
        private readonly requestMetadata : UserAccountIdAndTimestamp | null,
        private readonly sentMetadata : UserAccountIdAndTimestamp | null,
        private readonly receivedMetadata : UserAccountIdAndTimestamp | null,
        private readonly cancellationMetadata : UserAccountIdAndTimestamp | null,
        private readonly cancellationNote : string | null,
    ) {}

    public getPerspectiveLocation() : LocationId {
        return this.perspectiveLocation;
    }

    public getProductAmounts() : Array<ProductAmount> {
        return this.productAmounts;
    }

    public getPartnerLocation() : LocationId | string {
        return this.partnerLocation;
    }

    public getDirection() : TransferDirection {
        return this.direction;
    }

    public getTime() : CoreTimeModel.TimestampWithMillisecondPrecision {
        return this.time;
    }

    public getNote() : string {
        return this.note;
    }

    public getRequestMetadata() : UserAccountIdAndTimestamp | null {
        return this.requestMetadata;
    }

    public getSentMetadata() : UserAccountIdAndTimestamp | null {
        return this.sentMetadata;
    }

    public getReceivedMetadata() : UserAccountIdAndTimestamp | null {
        return this.receivedMetadata;
    }

    public getCancellationMetadata() : UserAccountIdAndTimestamp | null {
        return this.cancellationMetadata;
    }

    public getCancellationNote() : string | null {
        return this.cancellationNote;
    }
}
