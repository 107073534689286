import { ProductCount } from 'api/InventoryCount/model/ProductCount';
import { Mappings } from 'api/Product/model/Mappings';
import { PackagingsAndMappings } from 'api/Product/model/PackagingsAndMappings';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';

import { decimalToNumber, numberToDecimalRoundingDown } from 'shared/utils/decimalUtils';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

const getAppropriateDecimalScale = (n : number) : number => {
    const decimalDigits = n.toString().split('.')[1];
    if (decimalDigits) {
        return Math.min(decimalDigits.length, 6);
    }

    return 0;
};

const resolveProductCountUnit = (
    productCount : ProductCount,
    mappings : Mappings
) : ProductCount => {
    const count = productCount.getCount();

    if (count === null) {
        const resolvedProductQuantityUnit = PackagingUtils.resolveProductQuantityUnit(new QuantityInUnit(1, productCount.getProductQuantityUnit()), mappings);
        return new ProductCount(null, resolvedProductQuantityUnit.getUnit());
    } else {
        return getProductCount(
            PackagingUtils.resolveProductQuantityUnit(getQuantityInUnit(productCount), mappings),
        );
    }
};

const getProductCount = (quantityInUnit : QuantityInUnit<any>) : ProductCount => {
    const scale = { value : getAppropriateDecimalScale(quantityInUnit.getQuantity()) };
    return new ProductCount(
        numberToDecimalRoundingDown(quantityInUnit.getQuantity(), scale),
        quantityInUnit.getUnit(),
    );
};

const getQuantityInUnit = (productCount : ProductCount) : QuantityInUnit<any> => {
    const count = productCount.getCount();
    if (count === null) {
        throw new RuntimeException('Cannot convert null ProductCount to QuantityInUnit');
    }

    return new QuantityInUnit(
        decimalToNumber(count),
        productCount.getProductQuantityUnit(),
    );
};

const convertProductCountUnit = (
    productCount : ProductCount,
    newProductQuantityUnit : ProductQuantityUnit,
    packagingsAndMappings : PackagingsAndMappings
) : ProductCount => {
    if (productCount.getProductQuantityUnit() === newProductQuantityUnit) {
        return productCount;
    }

    const count = productCount.getCount();
    if (count === null) {
        return new ProductCount(null, newProductQuantityUnit);
    }

    const covertedQuantityInUnit = PackagingUtils.convertProductQuantityToUnit(
        packagingsAndMappings,
        getQuantityInUnit(productCount),
        newProductQuantityUnit,
    );

    return getProductCount(covertedQuantityInUnit);
};

const sumProductCounts = (
    productCounts : Array<ProductCount>,
    packagingsAndMappings : PackagingsAndMappings
) : ProductCount => {
    const containerPackagingId = PackagingUtils.getContainerPackagingId(packagingsAndMappings.getPackaging());
    let totalProductCountValue : number | null = null;

    productCounts.forEach((productCount) => {
        const count = productCount.getCount();

        if (count !== null) {
            totalProductCountValue = totalProductCountValue || 0;
            const covertedQuantityInUnit = PackagingUtils.convertProductQuantityToUnit(packagingsAndMappings, getQuantityInUnit(productCount), containerPackagingId);
            totalProductCountValue = totalProductCountValue + covertedQuantityInUnit.getQuantity();
        }
    });

    return new ProductCount(
        (totalProductCountValue === null) ? null : numberToDecimalRoundingDown(totalProductCountValue, { value : getAppropriateDecimalScale(totalProductCountValue) }),
        containerPackagingId
    );
};

export const productCountUtils = {
    getQuantityInUnit,
    sumProductCounts,
    convertProductCountUnit,
    resolveProductCountUnit,
};
