import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

// Would be nice if the typedef cpould be generated from the map
export type InventoryCountTypeValue = 'complete' | 'partial';

export class InventoryCountType {
    public static readonly inventoryCountTypeByValue = new Map<string, InventoryCountType>();

    public static readonly COMPLETE = new InventoryCountType('complete');
    public static readonly PARTIAL = new InventoryCountType('partial');

    public static getByCategoryValue(value : InventoryCountTypeValue) : InventoryCountType {
        const inventoryCountType = InventoryCountType.inventoryCountTypeByValue.get(value);

        if (typeof inventoryCountType === 'undefined') {
            throw new RuntimeException(`unexpected value: "${ value }"`);
        }

        return inventoryCountType;
    }

    private constructor(
        private readonly value : InventoryCountTypeValue
    ) {
        InventoryCountType.inventoryCountTypeByValue.set(value, this);
    }

    public getValue() : InventoryCountTypeValue {
        return this.value;
    }
}
