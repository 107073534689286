import React from 'react';

import { IHexColorsByCategoryName } from 'shared/models/Charts/IHexColorsByCategoryName';
import { IValuesByCategoryName } from 'shared/models/Charts/IValuesByCategoryName';
import { IValuesByDataIdAndCategoryName } from 'shared/models/Charts/IValuesByDataIdAndCategoryName';
import { HexColor } from 'shared/models/HexColor';
import { LinearScale } from 'shared/models/StackedBarChart/LinearScale';
import { OrdinalBandScale } from 'shared/models/StackedBarChart/OrdinalBandScale';

import { HorizontalStackedBar } from './HorizontalStackedBar';

import { utils } from 'shared/components/StackedBarGraph/utils';

/**
 * HorizontalBarChartGrid Properties
 */
export interface IBarChartGrid {
    /** values for each data group (y-axis label), broken down by category */
    chartValuesByDataIdAndCategoryName : IValuesByDataIdAndCategoryName;
    /** colors for each category possible within a stacked bar */
    perCategoryColorsByCategoryId : IHexColorsByCategoryName;
    /** scaling function to get y value for a bar given a y axis key name */
    yScale : OrdinalBandScale;
    /** scaling function to get the x value of a given stack in a bar */
    xScale : LinearScale;
    /** height of each bar in the grid */
    barHeight : number;
    /** default color for bars on the chart */
    barDefaultColor : HexColor;
    /** mouse event for hovering over the bar */
    handleMouseOver : ((groupName : string) => void) | null;
    /** mouse event for leaving hover over bar */
    handleMouseOut : ((groupName : string) => void) | null;

    // For future work, if only showing colors on hover:
    showCategoryColorsByGroupName : { [key : string] : boolean } | null;
}

/**
 * Form HorizontalBarChartGrid
 */
export class HorizontalBarChartGrid extends React.Component<IBarChartGrid, object> {

    public render() {

        const {
            chartValuesByDataIdAndCategoryName,
            perCategoryColorsByCategoryId,
            yScale,
            xScale,
            barHeight,
            barDefaultColor,
            handleMouseOut,
            handleMouseOver,
            showCategoryColorsByGroupName,
        } = this.props;

        const dataKeys : Array<string> = Object.keys(chartValuesByDataIdAndCategoryName);

        const bars = dataKeys.map((keyName : string, index : number) => {
            const valuesByCategoryName : IValuesByCategoryName = chartValuesByDataIdAndCategoryName[keyName];

            const yValue = utils.scaleValueOrdinal(keyName, yScale);
            if (yValue === null) {
                throw Error('invalid y-axis key for bar chart');
            }

            let shouldShowColors : boolean;
            if (showCategoryColorsByGroupName && showCategoryColorsByGroupName[keyName]) {
                shouldShowColors = showCategoryColorsByGroupName[keyName];
            } else {
                shouldShowColors = true;
            }

            return (
                <HorizontalStackedBar
                    valuesByCategoryName={ valuesByCategoryName }
                    categoryColorsByCategoryName={ perCategoryColorsByCategoryId }
                    xScale={ xScale }
                    yValue={ yValue }
                    barHeight={ barHeight }
                    defaultColor={ barDefaultColor }
                    handleMouseOut={ handleMouseOut }
                    handleMouseOver={ handleMouseOver }
                    showColors={ shouldShowColors }
                    groupName={ keyName }
                    key={ index }
                />
            );
        });

        return (
            <g>
                { bars }
            </g>
        );

    }
}
