import React from 'react';

import { IValuesByCategoryName } from 'shared/models/Charts/IValuesByCategoryName';

import { MIN_DESKTOP_WIDTH } from 'shared/constants';

/**
 * YAxisLabel properties
 */
export interface IYAxisLabel {
    /** function for mouse event moving over component */
    handleMouseOver : ((keyName : string) => void) | null;
    /** function for mouse event moving off of component */
    handleMouseOut : ((keyName : string) => void) | null;
    /** text for the label */
    labelText : string;
    /** height of the label in pixels. Note: this does not necessarily === height of the actual bar, but should always be >= height of bar */
    labelHeight : number;
    /** category values for expanded label view (on print styles) */
    valuesByCategoryName : IValuesByCategoryName;
    /** function to format the numbers (shown on print styles) */
    formatNumbers : ((value : number) => string | number) | null;
}

export class YAxisLabel extends React.Component<IYAxisLabel, object> {

    public render() {
        const {
            handleMouseOut,
            handleMouseOver,
            labelText,
            labelHeight,
            valuesByCategoryName,
            formatNumbers,
        } = this.props;

        const onMouseOut = () => {
            if (handleMouseOut !== null) {
                handleMouseOut(labelText);
            }
        };
        const onMouseOver = () => {
            if (handleMouseOver !== null) {
                handleMouseOver(labelText);
            }
        };

        const onMobileClick = (event : React.MouseEvent<HTMLElement>) => {
            if (window.matchMedia('(max-width: ' + MIN_DESKTOP_WIDTH + 'px)').matches) {
                if (handleMouseOver !== null) {
                    handleMouseOver(labelText);
                }
            }
        };

        const categoryKeys = Object.keys(valuesByCategoryName);
        let totalValue = 0;
        const categoryBreakdown = categoryKeys.map((categoryName : string, index : number) => {
            const nullableValue : number | null = valuesByCategoryName[categoryName];
            const value : number = (nullableValue === null) ? 0 : nullableValue;

            totalValue += value;

            if (categoryKeys.length > 1) {
                return (
                    <div className="category-value-y-axis-label" key={ categoryName }>
                        { (formatNumbers ? formatNumbers(value) : valuesByCategoryName[categoryName]) }
                    </div>
                );
            } else {
                return;
            }
        });
        const totalValueDiv = (
            <div className="category-value-y-axis-label total-value-y-axis-label">
                { (formatNumbers ? formatNumbers(totalValue) : totalValue) }
            </div>
        );

        return (
            <div
                className="cell text-left bar-chart-y-axis-label-name"
                onMouseOver={ onMouseOver || undefined }
                onMouseOut={ onMouseOut || undefined }
                style={ { height: labelHeight + 'px' } }
                id={ 'y-axis-label-' + labelText }
                onClick={ onMobileClick }
                key={ labelText }
            >
                <div className="label-fade-out"/>
                <div className="y-axis-label-wrapper">
                    <div className="y-axis-label-text">
                        { labelText }
                        <div className="y-axis-label-ellipsis" />
                    </div>
                </div>
                <div className="y-axis-label-per-category-breakdown">
                    <div className="y-axis-label-category-breakdown">
                        { categoryBreakdown }
                        { totalValueDiv }
                    </div>
                </div>
            </div>
        );

    }
}
