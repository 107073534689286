
import React from 'react';

import { PrepEvent } from 'api/PrepEvent/model/PrepEvent';
import { PrepEventId } from 'api/PrepEvent/model/PrepEventId';
import { PrepEventUtils } from 'api/PrepEvent/utils/prepEventUtils';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { FormatMonetaryValueWithCents } from 'shared/utils/FormatMonetaryValue';
import { numberUtils } from 'shared/utils/numberUtils';

export interface IItemCardHistoryPrepEventProps {
    product : Product;
    productId : ProductId;
    prepEvent : PrepEvent;
    prepEventId : PrepEventId;
    prepEventLabel : string;
}

export class ItemCardHistoryPrepEvent extends React.Component<IItemCardHistoryPrepEventProps, object> {

    public render() {
        const {
            prepEvent,
            prepEventId,
            productId,
            product,
            prepEventLabel,
        } = this.props;

        const productAmountInInput = prepEvent.getInputQuantityOfProductByProductId().get(productId);
        const productAmountInOutput = prepEvent.getOutputQuantityOfProductByProductId().get(productId);

        const packagingsAndMappings = product.getPackagingsAndMappings();

        let inputQuantityString : string = '';
        let outputQuantityString : string = '';
        let outputPriceString : string = '';

        if (productAmountInInput) {
            const inputPackagingString = PackagingUtils.getPackagingDisplayTextForProductQuantityUnit(packagingsAndMappings, productAmountInInput.getUnit(), numberUtils.isPlural(productAmountInInput.getQuantity()));
            inputQuantityString = `${ productAmountInInput.getQuantity() } ${ inputPackagingString }`;
        }

        if (productAmountInOutput) {
            const outputTotalCostOfProduct = PrepEventUtils.getTotalCostOfProductInOutput(prepEvent, productId, product);
            outputPriceString = FormatMonetaryValueWithCents(outputTotalCostOfProduct);

            const outputPackagingString = PackagingUtils.getPackagingDisplayTextForProductQuantityUnit(packagingsAndMappings, productAmountInOutput.getUnit(), numberUtils.isPlural(productAmountInOutput.getQuantity()));
            outputQuantityString = `${ productAmountInOutput.getQuantity() } ${ outputPackagingString }`;
        }

        const prepEventLink = `/inventory/prep_record/${ prepEventId.getValue() }/r/${ window.GLOBAL_RETAILER_ID }`;

        return (
            <div className="activity-item item-history-prep-event" key={ prepEventId.getValue() }>
                <div className="top-row">
                    <span className="bevicon bevico-prep-item" />
                    <div className="title">
                        <a className="link" href={ prepEventLink }>Prep</a>
                        <span className="activity-item-detail"> { prepEventLabel }</span>
                    </div>
                </div>
                { productAmountInInput &&
                    <div className="line-item-row line-item-row-header">
                        <div className="qty">Qty</div>
                        <div className="spacer"/>
                    </div>
                }
                { productAmountInInput &&
                    <div className="line-item-row">
                        <div className="qty">{ inputQuantityString }</div>
                        <div className="spacer"/>
                    </div>
                }
                { productAmountInOutput &&
                    <div className="line-item-row line-item-row-header">
                        <div className="qty">Yield</div>
                        <div className="spacer"/>
                        <div className="est-cost">Est. Cost</div>
                    </div>
                }
                { productAmountInOutput &&
                    <div className="line-item-row">
                        <div className="qty">{ outputQuantityString }</div>
                        <div className="spacer"/>
                        <div className="est-cost">{ outputPriceString }</div>
                    </div>
                }
            </div>
        );
    }
}
