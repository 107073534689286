import React from 'react';

import { ISearchBarBaseProps } from 'shared/components/SearchBar/SearchBar';
import { ValidationInput } from 'shared/components/ValidationInput';

import './MobileSearchBar.scss';

export interface IMobileSearchBarProps extends ISearchBarBaseProps {
    handleOnHideSearchBar() : void;
}

/**
 * Anna Lee Barber 6/5/17
 * this component shares a lot of code with the SearchBar component, but not extending for now because we can't
 * extend SearchBar while also extending the props unless SearchBar is a generic.
 */
export class MobileSearchBar extends React.Component<IMobileSearchBarProps, object> {
    private handleOnHideAndClearSearchBar : (event : React.MouseEvent<HTMLElement>) => void;
    private handleOnEnterClick : (event : React.KeyboardEvent<HTMLInputElement>) => void;
    private handleOnBlur : (event : React.FocusEvent<HTMLInputElement>) => void;
    private handleOnChange : (event : React.ChangeEvent<HTMLInputElement>) => void;

    constructor(props : IMobileSearchBarProps) {
        super(props);

        this.handleOnHideAndClearSearchBar = this.handleOnHideAndClearSearchBarGenerator();
        this.handleOnBlur = this.handleOnBlurGenerator();
        this.handleOnEnterClick = this.handleOnEnterClickGenerator();
        this.handleOnChange = this.handleOnChangeGenerator();
    }
    public render() {
        const {
            placeholderText,
            value,
            clearSearchBar,
            isDisabled,
            isFocused,
        } = this.props;

        return (
            <div className="search-bar search-bar-mobile">
                <div className="search-bar-container">
                    <span className="bevicon bevico-search"/>
                    <ValidationInput
                        type="text'"
                        label={ null }
                        hintText={ placeholderText }
                        value={ (value !== null) ? value : '' }
                        autoFocus={ isFocused }
                        autoComplete={ null }
                        isValid={ true } // TODO: Would this ever be false?
                        errorMessage=""
                        inputClassName=""
                        handleChange={ this.handleOnChange }
                        handleBlur={ this.handleOnBlur }
                        handleFocus={ this.onFocus }
                        handleEnterClick={ this.handleOnEnterClick }
                        isDisabled={ isDisabled }
                    />
                    { (value !== null) &&
                        <span
                            className="bevicon bevico-remove-inverse"
                            onClick={ clearSearchBar }
                        />
                    }
                    { /* { (value === null) && */ }
                        <span
                            className="bevicon bevico-arrow-back"
                            onClick={ this.handleOnHideAndClearSearchBar }
                        />
                    { /* } */ }
                </div>
            </div>
        );
    }

    private handleOnHideAndClearSearchBarGenerator() {
        return (event : React.MouseEvent<HTMLElement>) => {
            this.props.handleOnHideSearchBar();
            this.props.clearSearchBar();
        };
    }

    private handleOnChangeGenerator() {
        return (event : React.ChangeEvent<HTMLInputElement>) => {
            const input = event.currentTarget;
            this.props.handleOnChange(input.value);
        };
    }

    private handleOnBlurGenerator() {
        return (event : React.FocusEvent<HTMLInputElement>) => {
            const input = event.currentTarget;
            this.props.handleBlur(input.value);
        };
    }

    private handleOnEnterClickGenerator() {
        return (event : React.KeyboardEvent<HTMLInputElement>) => {
            const input = event.currentTarget;
            this.props.handleEnterClick(input.value);
        };
    }

    private readonly onFocus = (event : React.FocusEvent<HTMLInputElement>) => {
        const inputElement = event.currentTarget;
        window.requestAnimationFrame(() => {
            inputElement.setSelectionRange(0, 9999);
        });
    }
}
