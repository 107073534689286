import { ReportMetadata } from 'api/Transfer/model/ReportMetadata';
import { TransferReportData } from 'api/Transfer/model/TransferReportData';

export class TransferReportWithoutCost {

    constructor (
        private readonly transferReportData : TransferReportData,
        private readonly reportMetaData : ReportMetadata,
    ) {}

    public getTransferReportData() : TransferReportData {
        return this.transferReportData;
    }

    public getReportMetaData() : ReportMetadata {
        return this.reportMetaData;
    }
}
