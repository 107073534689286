import { DistributorId } from 'api/Distributor/model/DistributorId';
import MoneyModel from 'gen-thrift/money_Model_types';
import ProductAmountModel from 'gen-thrift/product_amount_Model_types';
import ProductModel from 'gen-thrift/product_Model_types';
import DistributorModel from 'gen-thrift/distributor_Model_types';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { MassUnit } from 'api/Product/model/MassUnit';
import { PackagingId } from 'api/Product/model/PackagingId';
import { ProductAmount } from 'api/Product/model/ProductAmount';
import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { numberToDecimal } from 'shared/utils/decimalUtils';

export class ProductObjectToThriftSerializer {

    ////////////////////////////////////
    // MODELS
    ////////////////////////////////////

    public getThriftProductId(
        productId : ProductId,
    ) : ProductModel.ProductId {
        return new ProductModel.ProductId({
            value: productId.getValue(),
        });
    }

    public getThriftDistributorId(
        distributorId : DistributorId,
    ) : DistributorModel.DistributorId {
        return new DistributorModel.DistributorId({
            value: distributorId.getValue(),
        });
    }

    public getThriftPackagingId(
        packagingId : PackagingId,
    ) : ProductModel.PackagingId {
        return new ProductModel.PackagingId({
            value: packagingId.getValue(),
        });
    }

    public getThriftProductIdAndQuantityOfProduct(
        productAmount : ProductAmount
    ) : ProductModel.ProductIdAndQuantityOfProduct {
        if (productAmount.getProductId() === null || productAmount.getQuantityInUnit() === null) {
            throw new Error('Product amount has unexpectedly null fields'); // TODO do we still have concerns about this? this is how old functions worked...
        }

        return new ProductModel.ProductIdAndQuantityOfProduct({
            productId: this.getThriftProductId(productAmount.getProductId()),
            quantityOfProduct: this.getThriftQuantityOfProduct(productAmount.getQuantityInUnit())
            // TODO getThriftQuantityOfProduct sets precision to 4... this is different than old breakage behavior...
        });
    }

    public getThriftMassUnit (
        unit : MassUnit
    ) : ProductAmountModel.MassUnit {
        switch (unit) {
            case MassUnit.KILOGRAM:
                return ProductAmountModel.MassUnit.METRIC_KILOGRAM;
            case MassUnit.GRAM:
                return ProductAmountModel.MassUnit.METRIC_GRAM;
            case MassUnit.POUND:
                return ProductAmountModel.MassUnit.US_POUND;
            case MassUnit.DRY_OUNCE:
                return ProductAmountModel.MassUnit.US_DRY_OUNCE;
            default:
                throw new RuntimeException(`unexpected value for mass unit: ${ unit }`);
        }
    }

    public getThriftVolumeUnit (
        unit : VolumeUnit
    ) : ProductAmountModel.VolumeUnit {
        switch (unit) {
            case VolumeUnit.LITER:
                return ProductAmountModel.VolumeUnit.METRIC_LITER;
            case VolumeUnit.CENTILITER:
                return ProductAmountModel.VolumeUnit.METRIC_CENTILITER;
            case VolumeUnit.MILLILITER:
                return ProductAmountModel.VolumeUnit.METRIC_MILLILITER;
            case VolumeUnit.OUNCE:
                return ProductAmountModel.VolumeUnit.US_FLUID_OUNCE;
            case VolumeUnit.GALLON:
                return ProductAmountModel.VolumeUnit.US_FLUID_GALLON;
            case VolumeUnit.QUART:
                return ProductAmountModel.VolumeUnit.US_FLUID_QUART;
            case VolumeUnit.PINT:
                return ProductAmountModel.VolumeUnit.US_FLUID_PINT;
            case VolumeUnit.BAR_SPOON:
                return ProductAmountModel.VolumeUnit.BAR_SPOON;
            case VolumeUnit.DASH:
                return ProductAmountModel.VolumeUnit.DASH;
            default:
                throw new RuntimeException(`unexpected value for volume unit: ${ unit }`);
        }
    }

    public getThriftUnitOfMeasure(
        unit : VolumeUnit | MassUnit
    ) : ProductModel.UnitOfMeasure {
        if (VolumeUnit.isVolumeUnit(unit)) {
            return new ProductModel.UnitOfMeasure({
                unitOfMass: null,
                unitOfVolume: this.getThriftVolumeUnit(unit)
            });
        } else {
            return new ProductModel.UnitOfMeasure({
                unitOfMass: this.getThriftMassUnit(unit),
                unitOfVolume: null
            });
        }
    }

    public getThriftProductQuantityUnit(
        productQuantityUnit : ProductQuantityUnit
    ) : ProductModel.ProductQuantityUnit {
        if (productQuantityUnit instanceof PackagingId) {
            return new ProductModel.ProductQuantityUnit({
                unitOfMass: null,
                unitOfVolume: null,
                packagingId: this.getThriftPackagingId(productQuantityUnit)
            });
        } else if (MassUnit.isMassUnit(productQuantityUnit)) {
            return new ProductModel.ProductQuantityUnit({
                unitOfMass: this.getThriftMassUnit(productQuantityUnit),
                unitOfVolume: null,
                packagingId: null
            });
        } else if (VolumeUnit.isVolumeUnit(productQuantityUnit)) {
            return new ProductModel.ProductQuantityUnit({
                unitOfMass: null,
                unitOfVolume: this.getThriftVolumeUnit(productQuantityUnit),
                packagingId: null
            });
        }

        throw new RuntimeException('Unknown productQuantityUnit');
    }

    // TODO Move this function to a CORE serializers file
    public getThriftMonetaryValueInDollars(
        monetaryValue : number
    ) : MoneyModel.MonetaryValue {
        return new MoneyModel.MonetaryValue({
            value: numberToDecimal(monetaryValue),
            currency: MoneyModel.Currency.USD
        });
    }

    public getThriftProductCost(
        productCost : ProductCost,
    ) : ProductModel.ProductCost {
        return new ProductModel.ProductCost({
            monetaryValue: new MoneyModel.MonetaryValue({
                value: productCost.getCost(),
                currency: MoneyModel.Currency.USD
            }),
            productQuantityUnit: this.getThriftProductQuantityUnit(productCost.getUnit())
        });
    }

    public getThriftQuantityOfProduct(
        quantityInUnit : QuantityInUnit<ProductQuantityUnit>
    ) : ProductModel.QuantityOfProduct {
        return new ProductModel.QuantityOfProduct({
            quantity: numberToDecimal(quantityInUnit.getQuantity()),
            productQuantityUnit: this.getThriftProductQuantityUnit(quantityInUnit.getUnit()),
        });
    }

    ////////////////////////////////////
    // COLLECTIONS
    ////////////////////////////////////

    public getThriftProductIdsFromArray(
        productIds : Array<ProductId>
    ) : Array<ProductModel.ProductId> {
        return productIds.map((productId : ProductId) => {
            return this.getThriftProductId(productId);
        });
    }

    public getThriftProductIdsFromSet(
        productIdSet : StringValueSet<ProductId>
    ) : Array<ProductModel.ProductId> {
        const productIds = new Array<ProductModel.ProductId>();
        productIdSet.forEach((productId : ProductId) => {
            productIds.push(this.getThriftProductId(productId));
        });
        return productIds;
    }

    public getThriftProductIdAndQuantityOfProducts(
        quantityOfProductByProductId : StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>>,
    ) : Array<ProductModel.ProductIdAndQuantityOfProduct> {
        const productIdAndQuantityOfProducts = new Array<ProductModel.ProductIdAndQuantityOfProduct>();
        quantityOfProductByProductId.forEach((quantityInUnit, productId) => {
            const productIdAndQuantityOfProduct = new ProductModel.ProductIdAndQuantityOfProduct({
                productId: this.getThriftProductId(productId),
                quantityOfProduct: this.getThriftQuantityOfProduct(quantityInUnit),
            });
            productIdAndQuantityOfProducts.push(productIdAndQuantityOfProduct);
        });

        return productIdAndQuantityOfProducts;
    }

    public getThriftProductIdAndCosts(
        productCostByProductId : StringValueMap<ProductId, ProductCost>
    ) : Array<ProductModel.ProductIdAndCost> {
        const productIdAndCosts = new Array<ProductModel.ProductIdAndCost>();
        productCostByProductId.forEach((productCost, productId) => {
            const productIdAndCost = new ProductModel.ProductIdAndCost({
                productId: this.getThriftProductId(productId),
                productCost: this.getThriftProductCost(productCost),
            });
            productIdAndCosts.push(productIdAndCost);
        });

        return productIdAndCosts;
    }

    public getThriftDistributorIdsFromSet(
        distributorIdSet : StringValueSet<DistributorId>
    ) : Array<DistributorModel.DistributorId> {
        const distributorIds = new Array<DistributorModel.DistributorId>();
        distributorIdSet.forEach((distributorId : DistributorId) => {
            distributorIds.push(this.getThriftDistributorId(distributorId));
        });
        return distributorIds;
    }
}
