export class PublicContentUrl {
    private hostname : string;
    private path : string;

    constructor(hostname : string, path : string) {
        this.hostname = hostname;
        this.path = path;
    }

    public getHostname() : string {
        return this.hostname;
    }

    public getPath() : string {
        return this.path;
    }
}
