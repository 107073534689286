import CoreTimeModel from 'gen-thrift/core_time_Model_types';
import moment from 'moment-timezone';

export enum TimestampGroups {
    SEVEN_DAYS = 'Within 7 days',
    THIRTY_DAYS = 'Within 30 days',
    SIX_MONTHS = 'Within 6 months',
    OLDER_THAN_TWELVE_MONTHS = 'More than 12 months'
}

const TimestampSortOrder : Array<string> = [
    TimestampGroups.SEVEN_DAYS,
    TimestampGroups.THIRTY_DAYS,
    TimestampGroups.SIX_MONTHS,
    TimestampGroups.OLDER_THAN_TWELVE_MONTHS
];

export const getDefaultTimestampGroupName = (timestamp : CoreTimeModel.TimestampWithMillisecondPrecision) : string => {
    const daysFromCurrentDate = moment.utc().diff(moment.utc(timestamp.timeSinceUnixEpoch.value), 'day');
    if (daysFromCurrentDate < 7) {
        return TimestampGroups.SEVEN_DAYS;
    } else if (daysFromCurrentDate < 30) {
        return TimestampGroups.THIRTY_DAYS;
    } else if (daysFromCurrentDate < (30 * 6)) {
        return TimestampGroups.SIX_MONTHS;
    } else {
        return TimestampGroups.OLDER_THAN_TWELVE_MONTHS;
    }
};

export const getTimestampGroupComparator = (groupName1 : string, groupName2: string) => {
    const sortOrder1 = TimestampSortOrder.indexOf(groupName1);
    const sortOrder2 = TimestampSortOrder.indexOf(groupName2);
    return sortOrder1 - sortOrder2;
};
