import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export enum DefaultPaymentTermOptions {
    NONE = 'NONE',
    DUE_ON_RECEIPT = 'DUE_ON_RECEIPT',
    NET_15 = 'NET_15',
    NET_30 = 'NET_30',
    NET_60 = 'NET_60',
}

export class PaymentTerm {

    public static createFromJson(value : string | undefined) {
        if (typeof value === 'undefined' || value === null) {
            return new PaymentTerm(DefaultPaymentTermOptions.NONE);
        } else {
            return new PaymentTerm(value);
        }
    }

    constructor(
        private readonly paymentTerm : string
    ) {
        if (!(paymentTerm in DefaultPaymentTermOptions) && !paymentTerm.startsWith("NET_")) {
            throw new RuntimeException('unexpected payment term ' + paymentTerm);
        }
    }

    public getValue() : string {
        return this.paymentTerm;
    }
}
