import InventoryTransferReportModel from 'gen-thrift/inventory_transfer_report_Model_types';
import ProductModel from 'gen-thrift/product_Model_types';

import { BreakageId } from 'api/Breakage/model/BreakageId';
import { ProductAmount } from 'api/Product/model/ProductAmount';
import { ProductObjectToThriftSerializer } from 'api/Product/serializer/ProductObjectToThriftSerializer';

export class BreakageObjectToThriftSerializer {

    constructor(
        private productObjectToThriftSerializer : ProductObjectToThriftSerializer,
    ) {}

    ////////////////////////////////////
    // MODELS
    ////////////////////////////////////

    public getThriftBreakageId(
        breakageId : BreakageId,
    ) : InventoryTransferReportModel.BreakageReportIdentifier {
        return new InventoryTransferReportModel.BreakageReportIdentifier({ value : breakageId.getValue() });
    }

    ////////////////////////////////////
    // COLLECTIONS
    ////////////////////////////////////

    public getThriftBreakageIds(
        breakageIds : Array<BreakageId>
    ) : Array<InventoryTransferReportModel.BreakageReportIdentifier> {
        return breakageIds.map((breakageId) => {
            return this.getThriftBreakageId(breakageId);
        });
    }

    public getThriftProductIdsAndQuantityOfProducts(
        productAmounts : Array<ProductAmount>
    ) : Array<ProductModel.ProductIdAndQuantityOfProduct> {
        return productAmounts.map((productAmount) => {
            return this.productObjectToThriftSerializer.getThriftProductIdAndQuantityOfProduct(productAmount);
        });
    }
}
