import { LocationId } from 'api/Location/model/LocationId';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';

export class InterLocationProductMapping {

    constructor (
        private readonly sourceLocationId : LocationId,
        private readonly sourceProductId : ProductId,
        private readonly sourceQuantityOfProduct : QuantityInUnit<ProductQuantityUnit>,
        private readonly targetLocationId : LocationId,
        private readonly targetProductId : ProductId,
        private readonly targetQuantityOfProduct : QuantityInUnit<ProductQuantityUnit>
    ) {}

    public getSourceLocationId() : LocationId {
        return this.sourceLocationId;
    }

    public getSourceProductId() : ProductId {
        return this.sourceProductId;
    }

    public getSourceQuantityOfProduct() : QuantityInUnit<ProductQuantityUnit> {
        return this.sourceQuantityOfProduct;
    }

    public getTargetLocationId() : LocationId {
        return this.targetLocationId;
    }

    public getTargetProductId() : ProductId {
        return this.targetProductId;
    }

    public getTargetQuantityOfProduct() : QuantityInUnit<ProductQuantityUnit> {
        return this.targetQuantityOfProduct;
    }
}
