import React from 'react';

import { tooltipItemSquareLengthInPixels } from 'shared/components/Charts/constants';
import { Shape } from 'shared/components/Charts/Shape';
import { ITooltipItemShape } from 'shared/models/Charts/Tooltip/ITooltipItemShape';

export interface ITooltipItemProps {
    readonly name : string;
    readonly value : string;
    readonly hasFocus : boolean;
    readonly tooltipItemShape : ITooltipItemShape | null;
}

export class TooltipItem extends React.Component<ITooltipItemProps, object> {
    private readonly classNamePrefix = 'chart-tooltip-item';

    public render() {
        const {
            hasFocus,
        } = this.props;

        let className : string = `${ this.classNamePrefix } col-row`;
        if (hasFocus) {
            className += ` ${ this.classNamePrefix }-has-focus`;
        }

        return (
            <div
                className={ className }
            >
                { this.createShape() }
                { this.createName() }
                { this.createValue() }
            </div>
        );
    }

    private createShape = () : JSX.Element | void => {
        const {
            tooltipItemShape,
        } = this.props;

        if (tooltipItemShape === null) {
            return;
        }

        const className : string = `${ this.classNamePrefix }-shape cell col-xs-1`;

        return (
            <div className={ className }>
                <Shape
                    shape={ tooltipItemShape.shape }
                    color={ tooltipItemShape.color }
                    boundingBox={ {
                        heightInPixels: tooltipItemSquareLengthInPixels,
                        widthInPixels: tooltipItemSquareLengthInPixels,
                    } }
                />
            </div>
        );
    }

    private createName = () : JSX.Element => {
        const {
            name,
        } = this.props;

        const className : string = this.classNamePrefix + '-name cell col-xs-6 cell-plr-xs';

        return (
            <div className={ className }>
                { name }
            </div>
        );
    }

    private createValue = () : JSX.Element => {
        const {
            value,
        } = this.props;

        const className : string = this.classNamePrefix + '-value cell text-right col-xs-5 cell-ptb-sm';

        return (
            <div className={ className }>
                { value }
            </div>
        );
    }
}
