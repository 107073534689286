//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';



var PrepEventExceptions = {};
export default PrepEventExceptions;
PrepEventExceptions.UnknownPrepEventIdException = function(args) {
};
Thrift.inherits(PrepEventExceptions.UnknownPrepEventIdException, Thrift.TException);
PrepEventExceptions.UnknownPrepEventIdException.prototype.name = 'UnknownPrepEventIdException';
PrepEventExceptions.UnknownPrepEventIdException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PrepEventExceptions.UnknownPrepEventIdException.prototype.write = function(output) {
  output.writeStructBegin('UnknownPrepEventIdException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PrepEventExceptions.PrepEventIsDeletedException = function(args) {
};
Thrift.inherits(PrepEventExceptions.PrepEventIsDeletedException, Thrift.TException);
PrepEventExceptions.PrepEventIsDeletedException.prototype.name = 'PrepEventIsDeletedException';
PrepEventExceptions.PrepEventIsDeletedException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PrepEventExceptions.PrepEventIsDeletedException.prototype.write = function(output) {
  output.writeStructBegin('PrepEventIsDeletedException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

