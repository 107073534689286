import moment from 'moment-timezone';

import { BillingPlanId } from './BillingPlanId';

export enum SubscriptionStatus {
    ACTIVE,
    CANCELLED
}

export class BevSpotSubscription {
    constructor (
        private readonly planId : BillingPlanId, // TODO future: can have multiple plans per subscription
        private readonly creationTimestamp : moment.Moment,
        private readonly lastPaymentTimestamp : moment.Moment, // TODO can this be null? need to check data...
        private readonly nextPaymentTimestamp : moment.Moment,
        private readonly cancellationTimestamp : moment.Moment | null, // TODO might need expiration date?
        private readonly status : SubscriptionStatus,
        private readonly quantity : number,
    ) { }

    public getPlanId() : BillingPlanId {
        return this.planId;
    }

    public getCreationTimestamp() : moment.Moment {
        return this.creationTimestamp;
    }

    public getLastPaymentTimestamp() : moment.Moment {
        return this.lastPaymentTimestamp;
    }

    public getNextPaymentTimestamp() : moment.Moment {
        return this.nextPaymentTimestamp;
    }

    public getCancellationTimestamp() : moment.Moment | null {
        return this.cancellationTimestamp;
    }

    public getStatus() : SubscriptionStatus {
        return this.status;
    }

    public getQuantity() : number {
        return this.quantity;
    }
}
