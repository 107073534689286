import React from 'react';

import 'shared/css/CollapseAllButton.scss';

export interface ICollapseAllButtonProps {
    nextClickWillCollapseAll : boolean;
    onClick : () => void;
    collapseLabel? : string;
    expandLabel? : string;
}

export class CollapseAllButton extends React.PureComponent<ICollapseAllButtonProps, object> {
    public render() {
        const {
            nextClickWillCollapseAll,
            onClick,
        } = this.props;

        const collapseLabel = this.props.collapseLabel || 'Collapse All';
        const expandLabel = this.props.expandLabel || 'Expand All';

        return (
            <div className="btn flat collapse-all-button medium capitalize with-icon" onClick={ onClick } >
                <span className="button-text-container">
                    <span>{ nextClickWillCollapseAll ? collapseLabel : expandLabel }</span>
                    <span className={ `main-icon-right bevicon bevico-unfold-${ nextClickWillCollapseAll ? 'less' : 'more' }` } />
                </span>
            </div>
        );
    }
}
