import { PackagingUnit } from 'api/Product/model/PackagingUnit';
import { Unit } from 'api/Product/model/Unit';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export class OldPackaging {
    constructor(
        private readonly content : OldPackaging | null,
        private readonly quantityOfContent : number | null,
        private readonly unit : Unit,
    ) {
        if (PackagingUnit.isPackagingUnit(unit)) {
            if (content === null) {
                throw new RuntimeException('content must not be null when unit is a PackagingUnit');
            }

            if (quantityOfContent === null) {
                throw new RuntimeException('quantityOfContent must not be null when unit is a PackagingUnit');
            }

            if (unit === PackagingUnit.CASE) {
                if (quantityOfContent % 1 !== 0) { // if quantityOfContent is NOT an integer
                    throw new RuntimeException('quantityOfContent for a case must be an integer');
                }
            }
        } else {
            if (content !== null) {
                throw new RuntimeException('content must be null when unit is not a PackagingUnit');
            }

            if (quantityOfContent !== null) {
                throw new RuntimeException('quantityOfContent must be null when unit is not a PackagingUnit');
            }
        }
    }

    public getContent() : OldPackaging | null {
        return this.content;
    }

    public getQuantityOfContent() : number | null {
        return this.quantityOfContent;
    }

    public getUnit() : Unit {
        return this.unit;
    }
}
