import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import moment from 'moment-timezone';

import { DateTimePickerActions } from 'shared/components/BevSpotDateTimePicker/actions';
import { ConnectedBevSpotDateTimePicker, IConnectedBevSpotDateTimePicker } from 'shared/components/BevSpotDateTimePicker/BevSpotDateTimePicker';
import { IDateTimePickerStateById } from 'shared/components/BevSpotDateTimePicker/reducers';
import { Button } from 'shared/components/Button';

import './DateRangePicker.scss';

export interface IConnectedDateRangePickerProps {
    headerElement : JSX.Element | null;
    bodyElement : JSX.Element | null;
    onExitDateRangeSelector : () => void;
    onConfirmDateRange : (startDate : moment.Moment, endDate : moment.Moment) => void;
    startDatePickerProps : IConnectedBevSpotDateTimePicker;
    endDatePickerProps : IConnectedBevSpotDateTimePicker;
    confirmButtonText : string;
}

interface IDateRangePickerProps extends IConnectedDateRangePickerProps {
    dispatch : Dispatch<object>;
    dateTimePickerStateById : IDateTimePickerStateById;
}

export class DateRangePicker extends React.Component<IDateRangePickerProps, object> {
    public render() {
        const {
            headerElement,
            bodyElement,
            startDatePickerProps,
            endDatePickerProps,
            confirmButtonText,
        } = this.props;

        const invalidRangeElement = this.getInvalidRangeElement();

        return (
            <div className="date-range-picker mobile-date-range-overlay">
                <div className="date-range-picker-inner-container">
                    { headerElement &&
                        <div className="date-range-picker-header-container">
                            { headerElement }
                        </div>
                    }
                    <div className="date-range-picker-content-container">
                        <div className="date-range-picker-body">{ bodyElement }</div>
                        <div className="date-range-picker-embedded-component-container">
                            <div className="date-range-picker-embedded-component">
                                <div className="date-range-picker-label">Start Date</div>
                                <ConnectedBevSpotDateTimePicker
                                    initialDateTime={ startDatePickerProps.initialDateTime }
                                    setDateTime={ startDatePickerProps.setDateTime }
                                    shouldShowTime={ startDatePickerProps.shouldShowTime }
                                    buttons={ startDatePickerProps.buttons }
                                    type={ startDatePickerProps.type }
                                    uniqueId={ startDatePickerProps.uniqueId }
                                />
                            </div>
                            <div className="date-range-picker-arrow">
                                <span className="bevicon bevico-arrow-forward"/>
                            </div>
                            <div className="date-range-picker-embedded-component">
                                <div className="date-range-picker-label">End Date</div>
                                <ConnectedBevSpotDateTimePicker
                                    initialDateTime={ endDatePickerProps.initialDateTime }
                                    setDateTime={ endDatePickerProps.setDateTime }
                                    shouldShowTime={ endDatePickerProps.shouldShowTime }
                                    buttons={ endDatePickerProps.buttons }
                                    type={ endDatePickerProps.type }
                                    uniqueId={ endDatePickerProps.uniqueId }
                                />
                            </div>
                        </div>
                        { invalidRangeElement }
                    </div>
                    <div className="date-range-picker-footer">
                        <Button
                            buttonClassName="flat"
                            isDisabled={ false }
                            isLoading={ false }
                            onClick={ this.onExitClick }
                        >
                            <span>Cancel</span>
                        </Button>
                        <Button
                            buttonClassName="primary"
                            isDisabled={ (invalidRangeElement !== null) }
                            isLoading={ false }
                            onClick={ this.onConfirmDateRangeClick }
                        >
                            <span>{ confirmButtonText }</span>
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    private getInvalidRangeElement = () : JSX.Element | null => {
        const {
            startDatePickerProps,
            endDatePickerProps,
            dateTimePickerStateById,
        } = this.props;
        if (typeof dateTimePickerStateById[startDatePickerProps.uniqueId] !== 'undefined' && typeof dateTimePickerStateById[endDatePickerProps.uniqueId] !== 'undefined') { // on initial load may not be set yet
            const selectedStart = dateTimePickerStateById[startDatePickerProps.uniqueId].selectedDateTime.toMoment();
            const selectedEnd =  dateTimePickerStateById[endDatePickerProps.uniqueId].selectedDateTime.toMoment();

            if (selectedEnd.diff(selectedStart) < 0) {
                return (
                    <div className="error-text">
                        Start date must be before end date
                    </div>
                );
            }
        }

        return null;
    }

    private readonly onConfirmDateRangeClick = () => {
        const {
            startDatePickerProps,
            endDatePickerProps,
            dateTimePickerStateById,
            onConfirmDateRange,
        } = this.props;

        const selectedStart = dateTimePickerStateById[startDatePickerProps.uniqueId].selectedDateTime.toMoment();
        const selectedEnd =  dateTimePickerStateById[endDatePickerProps.uniqueId].selectedDateTime.toMoment();

        onConfirmDateRange(selectedStart, selectedEnd);
    }

    // reset dates on exit
    private readonly onExitClick = () => {
        const {
            startDatePickerProps,
            endDatePickerProps,
            onExitDateRangeSelector,
            dispatch
        } = this.props;
        dispatch(DateTimePickerActions.setSelectedDateTime(startDatePickerProps.uniqueId, startDatePickerProps.initialDateTime));
        dispatch(DateTimePickerActions.setSelectedDateTime(endDatePickerProps.uniqueId, endDatePickerProps.initialDateTime));
        onExitDateRangeSelector();
    }
}

interface IStateProps {
    dateTimePickerStateById : IDateTimePickerStateById;
}

const mapStateToProps = (state : IStateProps) : IStateProps => {
    return {
        dateTimePickerStateById : state.dateTimePickerStateById
    };
};

export const ConnectedDateRangePicker = connect<IStateProps, object, IConnectedDateRangePickerProps, IStateProps>(mapStateToProps)(DateRangePicker);
