import { IExpectedInventoryService } from 'api/InventoryCount/interfaces/IExpectedInventoryService';
import { ExpectedInventory } from 'api/InventoryCount/model/ExpectedInventory';
import { InventoryCountId } from 'api/InventoryCount/model/InventoryCountId';
import { ExpectedInventoryDeserializer } from 'api/InventoryCount/serializer/ExpectedInventoryDeserializer';
import { LocationId } from 'api/Location/model/LocationId';
import { AjaxUtils } from 'shared/utils/ajaxUtils';

export class ExpectedInventoryServiceImpl implements IExpectedInventoryService {

    private readonly exceptedInventoryDeserializer : ExpectedInventoryDeserializer;

    constructor (
        exceptedInventoryDeserializer : ExpectedInventoryDeserializer,
    ) {
        this.exceptedInventoryDeserializer = exceptedInventoryDeserializer;
    }

    public async getExpectedInventoryData(locationId: LocationId, referenceInventoryId : InventoryCountId) : Promise<ExpectedInventory> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
        };

        const requestBody = {
            reference_inventory_id: referenceInventoryId.getValue()
        };

        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:expected_inventory'), requestBody, queryParameters)
            .then((response) => {
                return Promise.resolve(this.exceptedInventoryDeserializer.getExpectedInventory(response));
            });
    }
}
