import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { LocationId } from 'api/Location/model/LocationId';
import { MassUnit } from 'api/Product/model/MassUnit';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import { oldPackagingUtils } from 'api/Product/utils/oldPackagingUtils';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { PosItem } from 'api/SalesData/model/PosItem';
import { PosItemId } from 'api/SalesData/model/PosItemId';
import { SalesItem } from 'api/SalesItem/model/SalesItem';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemWithMetadata } from 'api/SalesItem/model/SalesItemWithMetadata';
import { SalesQuantityAndUnit } from 'api/SalesItem/model/SalesQuantityAndUnit';
import { SalesItemUtils } from 'api/SalesItem/utils/SalesItemUtils';
import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { makeOptionFromValue, OptionsAndLabelNameTuples } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { IValidationInputData } from 'shared/components/ValidationInput';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { productJSONToObjectSerializer, productObjectToJSONSerializer } from 'shared/lib/manager';
import { IValidationResult, Validation } from 'shared/validators/validators';
import { LinkedPriceField } from '../actions/actions';
import {
    DEFAULT_SERVING_AND_YIELD_UNIT,
    ICreateOrEditSalesItemState,
    IngredientFormFieldName,
    ingredientFormInitialState,
    IngredientFormValidationByFieldName,
    initialState,
    IProductAndSalesItemSearchBar,
    IProductIngredientRowInfo,
    ISalesItemForm,
    ISalesItemFormData,
    ISalesItemIngredientRowInfo,
    ProductIngredientRowFormFieldName,
    ProductQuickAddRowInfoByFormFieldName,
    SalesInformationFormFieldName,
    SalesInformationFormValidationByFieldName,
    SalesItemFormFieldName,
    SalesItemFormValidationByFieldName,
    SalesItemIngredientRowFormFieldName,
    SlimCreateSalesItemFormFieldName
} from '../reducers/reducers';

const getMenuGroupOptionByValue = (optionValue : string | null, currentOptions : Array<IOption>) : IOption | null => {
    if (optionValue === null) {
        return null;
    }

    const matchedOption = currentOptions.find((option) => option.value === optionValue);
    if (typeof matchedOption === 'undefined') {
        throw new Error('unexpected option: ' + optionValue);
    }
    return matchedOption;
};

const getSortedMenuGroupOptions = (menuGroups : Set<string>) : Array<IOption> => {
    const sortedList = Array.from(menuGroups).sort();

    return sortedList.map((menuGroupValue) => makeOptionFromValue(menuGroupValue));
};

const validateValueByFieldName = (
    fieldName : SalesItemFormFieldName | IngredientFormFieldName | SalesInformationFormFieldName | ProductIngredientRowFormFieldName | SalesItemIngredientRowFormFieldName | ProductQuickAddRowInfoByFormFieldName,
    value : string,
) : IValidationResult => {
    let isValid : null | boolean = null;
    let errorMessage = '';

    /** @NOTE no specific validation required */
    switch (fieldName) {
        case 'posId':
        case 'menuGroup':
        case 'note':
        case 'ingredientQuantityUnit':
        case 'yieldUnit':
        case 'servingSizeUnit':

        // TODO: what validation is needed for these?
        case 'newSalesItemYieldAmount':
        case 'newSalesItemYieldUnit':
        case 'newSalesItemServingSizeAmount':
        case 'newSalesItemServingSizeUnit':
        case 'newSalesItemName':
            isValid = true;
            break;
        default:
            break;
    }

    // required
    switch (fieldName) {
        case 'unit':
        case 'salesItemName':
            isValid = Validation.validateRequired(value);
            if (!isValid) {
                errorMessage = 'Field is Required';
                return {
                    errorMessage,
                    isValid,
                };
            }
            break;
        default:
            break;
    }

    // input type specific
    switch (fieldName) {
        case 'yieldAmount':
        case 'servingSizeAmount':
            isValid = Validation.validateNonNegativeNumber(value) && (parseFloat(value) > 0);
            if (!isValid) {
                errorMessage = 'Must be a number greater than 0';
            }
            break;
        case 'miscCost':
        case 'salesProfit': // can be negative
        case 'salesPrice': // price can be negative in the case of modifiers, etc.
        case 'priceAndTax':
        case 'ingredientQuantityAmount': // ingredient quantities can be negative or 0 per CS request: negative helps with modifiers, 0 for when they don't know an exact amount (or don't want to track but want in the recipe)
        case 'quantity':
            isValid = Validation.validateNumber(value);
            if (!isValid) {
                errorMessage = 'Invalid value';
            }
            break;
        case 'totalCost':
            isValid = (value === NOT_CALCULABLE_FIELD_VALUE) || Validation.validateNumber(value);
            if (!isValid) {
                errorMessage = 'Invalid value';
            }
            break;
        case 'costPercentage':
            isValid = (value === NOT_CALCULABLE_FIELD_VALUE) || Validation.validatePercentage(value);
            if (!isValid) {
                errorMessage = 'Invalid percentage.';
            }
            break;
        default:
            if (isValid === null) {
                throw new Error('no validation implemented for fieldname ' + fieldName);
            }
            break;
    }

    return {
        isValid,
        errorMessage,
    };
};

const validateServingSizeAndYieldAreCompatible = (
    itemYieldAmountValue : string,
    itemYieldUnitValue : string,
    itemServingSizeAmountValue : string,
    itemServingSizeUnitValue : string
) : boolean => {
    const { itemYield, servingSize, salesItemCustomUnitName } = getYieldAndServingSizeInfoFromForm(itemYieldAmountValue, itemYieldUnitValue, itemServingSizeAmountValue, itemServingSizeUnitValue);

    if (salesItemCustomUnitName !== null && salesItemCustomUnitName !== '') {
        if (itemYield.getUnit() !== null || servingSize.getUnit() !== null) {
            return false;
        }
        return true;
    } else {
        const itemYieldUnit = itemYield.getUnit();
        const servingSizeUnit = servingSize.getUnit();
        if (itemYieldUnit === null || servingSizeUnit === null) {
            return false;
        } else if ((MassUnit.isMassUnit(itemYieldUnit) && !MassUnit.isMassUnit(servingSizeUnit)) || (VolumeUnit.isVolumeUnit(itemYieldUnit) && !VolumeUnit.isVolumeUnit(servingSizeUnit))) {
           return false;
        }
        return true;
    }
};

const getFieldUnitType = (fieldValue : string) => {
    if (MassUnit.isMassUnitValue(fieldValue)) {
        return MassUnit.getByMassUnitValue(fieldValue);
    } else if (VolumeUnit.isVolumeUnitValue(fieldValue)) {
        return VolumeUnit.getByVolumeUnitValue(fieldValue);
    }
    return null;
};

// assumes all form fields are valid
const getSalesItemInfo = (salesItemForm : ISalesItemForm, salesItemById : StringValueMap<SalesItemId, SalesItemWithMetadata>, locationId : LocationId) => {
    const salesItemName = salesItemForm.salesItemInfoForm.salesItemName.value;
    const menuGroup = salesItemForm.salesItemInfoForm.menuGroup.value;
    const posId = salesItemForm.salesItemInfoForm.posId.value;
    const note = salesItemForm.salesItemInfoForm.note.value;
    const needsAttentionCategory = salesItemForm.selectedNeedsAttentionCategory;
    const salesPrice = salesItemForm.salesInformationForm.salesPrice.value.trim() === '' ? 0 : parseFloat(salesItemForm.salesInformationForm.salesPrice.value);
    const miscellaneousCost = salesItemForm.salesInformationForm.miscCost.value.trim() === '' ? 0 : parseFloat(salesItemForm.salesInformationForm.miscCost.value);

    const componentQuantityOfProductByProductId = new StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>>();
    salesItemForm.productIngredientItemsById.forEach((ingredientInfo, ingredientId) => {
        const unit = productJSONToObjectSerializer.getProductQuantityUnit(ingredientInfo.formInfo.unit.value);
        const productQuantityInUnit = new QuantityInUnit(
            parseFloat(ingredientInfo.formInfo.quantity.value),
            unit
        );
        componentQuantityOfProductByProductId.set(ingredientId, productQuantityInUnit);
    });

    const componentServingsBySalesItemId = new StringValueMap<SalesItemId, number>();
    salesItemForm.salesItemIngredientItemsById.forEach((ingredientInfo, ingredientId) => {
        const quantity = parseFloat(ingredientInfo.formInfo.quantity.value);
        componentServingsBySalesItemId.set(ingredientId, quantity);
    });

    const itemYieldAmount = salesItemForm.ingredientForm.yieldAmount.value;
    const itemYieldUnit = salesItemForm.ingredientForm.yieldUnit.value;
    const itemServingSizeAmount = salesItemForm.ingredientForm.servingSizeAmount.value;
    const itemServingSizeUnit = salesItemForm.ingredientForm.servingSizeUnit.value;

    const {
        itemYield,
        servingSize,
        salesItemCustomUnitName
    } = getYieldAndServingSizeInfoFromForm(itemYieldAmount, itemYieldUnit, itemServingSizeAmount, itemServingSizeUnit);

    return new SalesItem(
        salesItemName,
        locationId,
        menuGroup,
        posId,
        note,
        needsAttentionCategory,
        salesPrice,
        miscellaneousCost,
        componentQuantityOfProductByProductId,
        componentServingsBySalesItemId,
        itemYield,
        servingSize,
        salesItemCustomUnitName,
    );
};

const getYieldAndServingSizeInfoFromForm = (
    itemYieldAmount : string,
    itemYieldUnit : string,
    itemServingSizeAmount : string,
    itemServingSizeUnit : string
) => {
    const itemYield = new SalesQuantityAndUnit(parseFloat(itemYieldAmount), getFieldUnitType(itemYieldUnit));
    const servingSize = new SalesQuantityAndUnit(parseFloat(itemServingSizeAmount), getFieldUnitType(itemServingSizeUnit));

    let salesItemCustomUnitName : string | null = null;

    if (MassUnit.isMassUnitValue(itemYieldUnit) && MassUnit.isMassUnitValue(itemServingSizeUnit)) {
        salesItemCustomUnitName = null;
    } else if (VolumeUnit.isVolumeUnitValue(itemYieldUnit) && VolumeUnit.isVolumeUnitValue(itemServingSizeUnit)) {
        salesItemCustomUnitName = null;
    } else if ((itemYieldUnit === itemServingSizeUnit) && !MassUnit.isMassUnitValue(itemYieldUnit) && !MassUnit.isMassUnitValue(itemServingSizeUnit)
    && !VolumeUnit.isVolumeUnitValue(itemYieldUnit) && !VolumeUnit.isVolumeUnitValue(itemServingSizeUnit)) {
        salesItemCustomUnitName = itemYieldUnit;
    }

    return {
        itemYield,
        servingSize,
        salesItemCustomUnitName
    };
};

const getSalesItemFromSubrecipeSlimCreateFields = (
    slimCreateSalesItemInfo : { [fieldName in SlimCreateSalesItemFormFieldName] : IValidationInputData },
    locationId : LocationId,
) : SalesItem => {
    const itemYieldAmount = slimCreateSalesItemInfo.newSalesItemYieldAmount.value;
    const itemYieldUnit = slimCreateSalesItemInfo.newSalesItemYieldUnit.value;
    const itemServingSizeAmount = slimCreateSalesItemInfo.newSalesItemServingSizeAmount.value;
    const itemServingSizeUnit = slimCreateSalesItemInfo.newSalesItemServingSizeUnit.value;

    const {
        itemYield,
        servingSize,
        salesItemCustomUnitName
    } = getYieldAndServingSizeInfoFromForm(itemYieldAmount, itemYieldUnit, itemServingSizeAmount, itemServingSizeUnit);

    return new SalesItem(
        slimCreateSalesItemInfo.newSalesItemName.value,
        locationId,
        '',
        '',
        '',
        null,
        0, // sales price = 0
        0, // misc. cost = 0 (? might want to allow user to set misc. cost, otherwise cost of this ingredient is gonna be 0...)
        new StringValueMap(),
        new StringValueMap(),
        itemYield,
        servingSize,
        salesItemCustomUnitName,
    );
};

// menuGroupOptions and ingredientSearchBar should have been determined already. the rest is directly from the SalesItemWithMetadata object.
const getSalesItemFormFromSalesItemWithMetadata = (
    salesItemWithMetadata : SalesItemWithMetadata,
    menuGroupOptions : Array<IOption>,
    ingredientSearchBar : IProductAndSalesItemSearchBar,
    productsById : StringValueMap<ProductId, Product>,
    cost : number | null,
    retailerTaxPercentage : number | null,
) : ISalesItemForm => {
    const salesItem = salesItemWithMetadata.getSalesItem();

    const salesItemInfoForm : SalesItemFormValidationByFieldName = {
        salesItemName: constructValidationInputForInitialStringValue(salesItem.getName()),
        posId: constructValidationInputForInitialStringValue(salesItem.getPOSId()),
        menuGroup: constructValidationInputForInitialStringValue(salesItem.getMenuGroup()),
        note: constructValidationInputForInitialStringValue(salesItem.getNote())
    };

    // turn price into 3 other fields
    const salesItemPrice = salesItem.getSalesPrice();
    const pricePlusTax = SalesItemUtils.calculatePricePlusTax(salesItemPrice, retailerTaxPercentage);
    const profit = SalesItemUtils.calculateSalesItemProfit(salesItemPrice, cost);
    const costPercent = SalesItemUtils.getCostPercentageOfSalesItem(salesItemPrice, cost);

    const salesInformationForm : SalesInformationFormValidationByFieldName = {
        salesPrice: constructValidationInputForInitialStringValue(getNumberFieldStringFromValue(salesItemPrice)),
        miscCost: constructValidationInputForInitialStringValue(salesItem.getMiscellaneousCost().toString()),
        priceAndTax: constructValidationInputForInitialStringValue(getNumberFieldStringFromValue(pricePlusTax)),

        // If cost is not calculable (is null), these cannot be edited...
        costPercentage: constructValidationInputForInitialStringValue(getNumberFieldStringFromValue(costPercent)),
        totalCost: constructValidationInputForInitialStringValue(getNumberFieldStringFromValue(cost)),
        salesProfit: constructValidationInputForInitialStringValue(getNumberFieldStringFromValue(profit)),
    };

    const itemYieldUnit = salesItem.getItemYield().getUnit();
    const servingSizeUnit = salesItem.getServingSize().getUnit();
    let yieldUnitString : string;
    let servingSizeUnitString : string;
    if (itemYieldUnit === null || servingSizeUnit === null) {
         // salesItem.getSalesItemCustomUnitName() will never be null (the constructor forbids it in this case), but typescript thinks it can be :(
        yieldUnitString = servingSizeUnitString = DEFAULT_SERVING_AND_YIELD_UNIT; // NOTE: we do not allow custom names for now, so using the default. This should change when allowing custom name input
        // salesItem.getSalesItemCustomUnitName() || '';
    } else {
        yieldUnitString = itemYieldUnit;
        servingSizeUnitString = servingSizeUnit;
    }

    const ingredientForm : IngredientFormValidationByFieldName = {
        // fields from creating a new sales item within a sales item
        ...ingredientFormInitialState,

        // just blank values at first (form for search & add ingredients)
        ingredientQuantityAmount: constructValidationInputForInitialStringValue(''),
        ingredientQuantityUnit: constructValidationInputForInitialStringValue(''),

        // fields from sales item
        yieldAmount: constructValidationInputForInitialStringValue(salesItem.getItemYield().getQuantity().toString()),
        yieldUnit: constructValidationInputForInitialStringValue(yieldUnitString),
        servingSizeAmount: constructValidationInputForInitialStringValue(salesItem.getServingSize().getQuantity().toString()),
        servingSizeUnit: constructValidationInputForInitialStringValue(servingSizeUnitString),
    };

    const orderedIngredientItems : Array<ProductId | SalesItemId> = [];

    const productIngredientItemsById : StringValueMap<ProductId, IProductIngredientRowInfo> = new StringValueMap();
    salesItem.getComponentQuantityOfProductByProductId().forEach((quantityInUnit, productId) => {
        orderedIngredientItems.push(productId);
        const product = productsById.get(productId);
        if (typeof product === 'undefined') {
            throw new RuntimeException('unexpected');
        }

        // TODO or we could resolve in the component UI...
        const resolvedQuantityInUnit = PackagingUtils.resolveProductQuantityUnit(quantityInUnit, product.getPackagingsAndMappings().getMappings());

        productIngredientItemsById.set(
            productId,
            {
                ingredientId: productId,
                formInfo: {
                    quantity: constructValidationInputForInitialStringValue(resolvedQuantityInUnit.getQuantity().toString()),
                    unit: constructValidationInputForInitialStringValue(productObjectToJSONSerializer.getProductQuantityUnit(resolvedQuantityInUnit.getUnit()))
                }
            }
        );
    });

    const salesItemIngredientItemsById : StringValueMap<SalesItemId, ISalesItemIngredientRowInfo> = new StringValueMap();
    salesItem.getComponentServingsBySalesItemId().forEach((quantity, componentSalesItemId) => {
        orderedIngredientItems.push(componentSalesItemId);
        salesItemIngredientItemsById.set(
            componentSalesItemId,
            {
                ingredientId: componentSalesItemId,
                formInfo: {
                    quantity: constructValidationInputForInitialStringValue(quantity.toString()),
                }
            }
        );
    });

    const salesItemForm : ISalesItemForm = {
        orderedIngredientItems,
        salesItemInfoForm,
        salesInformationForm,
        ingredientForm,
        productIngredientItemsById,
        salesItemIngredientItemsById,
        selectedNeedsAttentionCategory: salesItem.getNeedsAttentionCategory(),
        lastEditedMetadata: salesItemWithMetadata.getLastEditedMetadata(),
        menuGroupOptions,
        ingredientSearchBar
    };
    return salesItemForm;
};

const getSalesItemFormFromPosItem = (
    posItem : PosItem,
    salesPrice : number,
    menuGroupOptions : Array<IOption>,
    ingredientSearchBar : IProductAndSalesItemSearchBar,
    retailerTaxPercentage : number | null,
) : ISalesItemForm => {

    const salesItemInfoForm : SalesItemFormValidationByFieldName = {
        salesItemName: constructValidationInputForInitialStringValue(posItem.getName()),
        posId: constructValidationInputForInitialStringValue(posItem.getPosItemId().getValue()),
        menuGroup: constructValidationInputForInitialStringValue(posItem.getMenuGroup()),
        note: constructValidationInputForInitialStringValue('')
    };

    // turn price into 3 other fields
    const cost = 0; // no ingredients or misc cost = 0
    const pricePlusTax = SalesItemUtils.calculatePricePlusTax(salesPrice, retailerTaxPercentage);
    const profit = SalesItemUtils.calculateSalesItemProfit(salesPrice, cost);
    const costPercent = SalesItemUtils.getCostPercentageOfSalesItem(salesPrice, cost);

    const salesInformationForm : SalesInformationFormValidationByFieldName = {
        salesPrice: constructValidationInputForInitialStringValue(getNumberFieldStringFromValue(salesPrice)),
        miscCost: initialState.salesItemForm.salesInformationForm.miscCost, // initial form value
        priceAndTax: constructValidationInputForInitialStringValue(getNumberFieldStringFromValue(pricePlusTax)),

        // If cost is not calculable (is null), these cannot be edited...
        costPercentage: constructValidationInputForInitialStringValue(getNumberFieldStringFromValue(costPercent)),
        totalCost: constructValidationInputForInitialStringValue(getNumberFieldStringFromValue(cost)),
        salesProfit: constructValidationInputForInitialStringValue(getNumberFieldStringFromValue(profit)),
    };

    const ingredientForm : IngredientFormValidationByFieldName = {
        // fields from creating a new sales item within a sales item
        ...ingredientFormInitialState,

        // just blank values at first (form for search & add ingredients)
        ingredientQuantityAmount: constructValidationInputForInitialStringValue(''),
        ingredientQuantityUnit: constructValidationInputForInitialStringValue(''),
    };

    const orderedIngredientItems : Array<ProductId | SalesItemId> = [];

    const productIngredientItemsById : StringValueMap<ProductId, IProductIngredientRowInfo> = new StringValueMap();
    const salesItemIngredientItemsById : StringValueMap<SalesItemId, ISalesItemIngredientRowInfo> = new StringValueMap();

    const salesItemForm : ISalesItemForm = {
        orderedIngredientItems,
        salesItemInfoForm,
        salesInformationForm,
        ingredientForm,
        productIngredientItemsById,
        salesItemIngredientItemsById,
        selectedNeedsAttentionCategory: null,
        lastEditedMetadata: null,
        menuGroupOptions,
        ingredientSearchBar,
    };
    return salesItemForm;
};

const constructValidationInputForInitialStringValue = (value : string) : IValidationInputData => {
    return {
        value,
        isValid: true,
        errorMessage: ''
    };
};

// TODO should this live in SalesItemUtils ?
// TODOfuture: could cache this for optimization or have a function that returns a map of all ids -> sets of ids they are linked in
// IMPORTANT: this assumes that salesItemsById contains all non-deleted items. if that becomes not the case, this could be incorrect (we would need to hit the server for the complete correct list)
const getLinkedSalesItems = (salesItemId : SalesItemId, salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>) : StringValueSet<SalesItemId> => {
    const linkedSalesItemIds = new StringValueSet<SalesItemId>();
    salesItemsById.forEach((salesItemWithMetadata, parentSalesItemId) => {
        if (salesItemWithMetadata.getSalesItem().getComponentServingsBySalesItemId().has(salesItemId)) {
            linkedSalesItemIds.add(parentSalesItemId);
        }
    });
    return linkedSalesItemIds;
};

export const NOT_CALCULABLE_FIELD_VALUE = 'COST_NOT_CALCULABLE';
const getNumberFieldStringFromValue = (fieldValue : number | null) => {
    return fieldValue !== null && !isNaN(fieldValue) ? fieldValue.toFixed(2) : NOT_CALCULABLE_FIELD_VALUE;
};

const getDependentSalesInformationFormValues = (
    inputField : LinkedPriceField,
    inputValue : number,
    itemCost : number | null,
    retailerTaxPercentage : number | null
) => {
    // take number and get sales price. then we can calculate the others from there.

    let salesPriceValue : number;
    if (inputField === 'salesPrice') {
        salesPriceValue = inputValue;
    } else if (inputField === 'costPercentage') {
        if (itemCost === null) { // TODO: this should not be valid through the UI - would we prefer to be more graceful here though?
            throw new RuntimeException('should not be able to edit costPercentage if cost is not caculable');
        }
        salesPriceValue = SalesItemUtils.getSalesPriceFromCostPercent(inputValue, itemCost);
    } else if (inputField === 'priceAndTax') {
        salesPriceValue = SalesItemUtils.getSalesPriceFromPriceAndTax(inputValue, retailerTaxPercentage);
    } else if (inputField === 'salesProfit') {
        if (itemCost === null) {
            throw new RuntimeException('should not be able to edit salesProfit if cost is not caculable');
        }
        salesPriceValue = SalesItemUtils.getSalesPriceFromSalesProfit(inputValue, itemCost);
    } else {
        throw new RuntimeException('unhandled field');
    }

    const pricePlusTax = inputField === 'priceAndTax' ? inputValue : SalesItemUtils.calculatePricePlusTax(salesPriceValue, retailerTaxPercentage);
    const profit = inputField === 'salesProfit' ? inputValue : SalesItemUtils.calculateSalesItemProfit(salesPriceValue, itemCost);
    const costPercent = inputField === 'costPercentage' ? inputValue : SalesItemUtils.getCostPercentageOfSalesItem(salesPriceValue, itemCost);

    return {
        salesPrice: getNumberFieldStringFromValue(salesPriceValue),
        costPercentage: getNumberFieldStringFromValue(costPercent),
        priceAndTax: getNumberFieldStringFromValue(pricePlusTax),
        salesProfit: getNumberFieldStringFromValue(profit),
    };
};

const getServingSizeAndYieldDropdownOptions = () : OptionsAndLabelNameTuples => {
    const dropdownOptions : Array<IOption> = [];
    VolumeUnit.getUnits().forEach((volumeUnit) => dropdownOptions.push({
        label: oldPackagingUtils.getDisplayTextForUnit(volumeUnit),
        icon: null,
        value: volumeUnit,
    }));
    MassUnit.getUnits().forEach((massUnit) => dropdownOptions.push({
        label: oldPackagingUtils.getDisplayTextForUnit(massUnit),
        icon: null,
        value: massUnit,
    }));
    dropdownOptions.push({ // custom option
        label: DEFAULT_SERVING_AND_YIELD_UNIT,
        icon: null,
        value: DEFAULT_SERVING_AND_YIELD_UNIT
    });

    return [[null, dropdownOptions]];
};

const checkFormHasChangedFromInitial = (createOrEditSalesItemState : ICreateOrEditSalesItemState) : boolean => {
    if (createOrEditSalesItemState.salesItemId instanceof SalesItemId) {
        if (createOrEditSalesItemState.salesItemFormData === null) {
            throw new RuntimeException('unexpected');
        }

        const salesItem = createOrEditSalesItemState.salesItemFormData.salesItemsById.get(createOrEditSalesItemState.salesItemId);
        if (typeof salesItem === 'undefined') {
            throw new RuntimeException('unexpected');
        }

        return checkDoesFormDifferFromSalesItem(salesItem, createOrEditSalesItemState.salesItemForm, createOrEditSalesItemState.salesItemFormData);
    } else if (createOrEditSalesItemState.salesItemId instanceof PosItemId) {
        // pos item: just check if ingredients ahve been added, or misc cost changed, or needs attention flag changed. can update later if logic of mapping tool changes
        if (createOrEditSalesItemState.salesItemForm.productIngredientItemsById.size > 0 || createOrEditSalesItemState.salesItemForm.salesItemIngredientItemsById.size > 0) {
            return true;
        }
        const salesInformationFormInfo = createOrEditSalesItemState.salesItemForm.salesInformationForm;
        if (salesInformationFormInfo.miscCost.value !== initialState.salesItemForm.salesInformationForm.miscCost.value) {
            return true;
        }
        if (createOrEditSalesItemState.salesItemForm.selectedNeedsAttentionCategory !== initialState.salesItemForm.selectedNeedsAttentionCategory) {
            return true;
        }

        return false;
    } else {
        // future: can make these checks more granular
        const salesItemFormInfo = createOrEditSalesItemState.salesItemForm.salesItemInfoForm;
        Object.keys(salesItemFormInfo).forEach((fieldKey) => {
            const fieldName = fieldKey as SalesItemFormFieldName;
            const value = salesItemFormInfo[fieldName].value;

            if (value !== initialState.salesItemForm.salesItemInfoForm[fieldName].value) {
                return true;
            }
        });

        // salesInformationFormFields
        const salesInformationFormInfo = createOrEditSalesItemState.salesItemForm.salesInformationForm;
        Object.keys(salesInformationFormInfo).forEach((fieldKey) => {
            const fieldName = fieldKey as SalesInformationFormFieldName;
            const value = salesInformationFormInfo[fieldName].value;
            if (value !== initialState.salesItemForm.salesInformationForm[fieldName].value) {
                return true;
            }
        });

        // productIngredientItems and salesItemIngredientItemsById
        if (createOrEditSalesItemState.salesItemForm.productIngredientItemsById.size > 0 || createOrEditSalesItemState.salesItemForm.salesItemIngredientItemsById.size > 0) {
            return true;
        }

        // ingredient form fields -- ignore the unadded ingredients, but must validate yield/serving size
        const ingredientFormInfo : IngredientFormValidationByFieldName = createOrEditSalesItemState.salesItemForm.ingredientForm;
        Object.keys(ingredientFormInfo).forEach((fieldKey) => {
            const fieldName = fieldKey as IngredientFormFieldName;
            const value = ingredientFormInfo[fieldName].value;
            if (value !== initialState.salesItemForm.ingredientForm[fieldName].value) {
                return true;
            }
        });

        return false;
    }
};

// future work: could return sections that differ if that's something that would be useful. for now, just return an overall isDifferent
const checkDoesFormDifferFromSalesItem = (salesItemWithMetadata : SalesItemWithMetadata, salesItemForm : ISalesItemForm, salesItemFormData : ISalesItemFormData) : boolean => {
    let formHasChanged : boolean = false;

    try {
        const newSalesItemFromForm = getSalesItemInfo(
            salesItemForm,
            salesItemFormData.salesItemsById,
            new LocationId(window.GLOBAL_RETAILER_ID));

        formHasChanged = !SalesItemUtils.areSalesItemsEqual(newSalesItemFromForm, salesItemWithMetadata.getSalesItem(), salesItemFormData.productsById);
    } catch (error) {
        // form was invalid so must have changed...
        formHasChanged = true;
    }

    return formHasChanged;
};

export const CreateOrEditSalesItemFormUtils = {
    validateValueByFieldName,
    validateServingSizeAndYieldAreCompatible,
    getSalesItemInfo,
    getSalesItemFromSubrecipeSlimCreateFields,
    getMenuGroupOptionByValue,
    getSalesItemFormFromSalesItemWithMetadata,
    getSalesItemFormFromPosItem,
    getSortedMenuGroupOptions,
    getLinkedSalesItems,
    getNumberFieldStringFromValue,
    getDependentSalesInformationFormValues,
    getServingSizeAndYieldDropdownOptions,
    checkFormHasChangedFromInitial,
    getYieldAndServingSizeInfoFromForm,
};
