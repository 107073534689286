import { BaseUnit } from 'api/Product/model/BaseUnit';
import { MassUnit } from 'api/Product/model/MassUnit';
import { OldPackaging } from 'api/Product/model/OldPackaging';
import { Packaging } from 'api/Product/model/Packaging';
import { PackagingId } from 'api/Product/model/PackagingId';
import { PackagingUnit } from 'api/Product/model/PackagingUnit';
import { Price } from 'api/Product/model/Price';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { Unit } from 'api/Product/model/Unit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import ProductAmountModel from 'gen-thrift/product_amount_Model_types';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

const hasCase = (packaging : OldPackaging) : boolean => {
    const unit = packaging.getUnit();

    if (PackagingUnit.isPackagingUnit(unit)) {
        return unit === PackagingUnit.CASE;
    }

    return false;
};

const getContainer = (packaging : OldPackaging) : OldPackaging => {
    if (hasCase(packaging)) {
        const container : OldPackaging | null = packaging.getContent();

        if (container === null) {
            throw new RuntimeException('unexpected: packaging has case but no content');
        }

        return container;
    }

    return packaging;
};

const getDisplayTextForPackaging = (packaging : OldPackaging, showContainerType : boolean) => {
    const containerSizeDisplayText : string = getContainerSizeDisplayTextForPackaging(packaging);

    let packagingDisplayText : string = '';
    if (hasCase(packaging)) {
        packagingDisplayText = `${ packaging.getQuantityOfContent() } x `;
    }

    packagingDisplayText += containerSizeDisplayText;

    if (showContainerType) {
        const containerType : string = getContainerTypeDisplayTextForPackaging(packaging);
        packagingDisplayText += ` (${ containerType })`;
    }

    return packagingDisplayText;
};

const getDisplayTextForUnit = (unit : Unit) : string => {
    switch (unit) {
        case PackagingUnit.CASE:
            return 'case';
        case PackagingUnit.BOTTLE:
            return 'bottle';
        case PackagingUnit.CAN:
            return 'can';
        case PackagingUnit.KEG:
            return 'keg';
        case PackagingUnit.BAG:
            return 'bag';
        case PackagingUnit.BOX:
            return 'box';
        case PackagingUnit.CAN_FOOD:
            return 'can (food)';
        case PackagingUnit.CARTON:
            return 'carton';
        case PackagingUnit.CONTAINER:
            return 'container';
        case PackagingUnit.PACKAGE:
            return 'package';
        case PackagingUnit.TUB:
            return 'tub';
        case PackagingUnit.OTHER:
            return 'other';
        case VolumeUnit.LITER:
            return 'L';
        case VolumeUnit.MILLILITER:
            return 'mL';
        case VolumeUnit.CENTILITER:
            return 'cL';
        case VolumeUnit.GALLON:
            return 'gal';
        case VolumeUnit.QUART:
            return 'qt';
        case VolumeUnit.PINT:
            return 'pt';
        case VolumeUnit.OUNCE:
            return 'fl. oz';
        case VolumeUnit.BAR_SPOON:
            return 'bsp';
        case VolumeUnit.DASH:
            return 'dash';
        case MassUnit.GRAM:
            return 'g';
        case MassUnit.KILOGRAM:
            return 'kg';
        case MassUnit.POUND:
            return 'lbs';
        case MassUnit.DRY_OUNCE:
            return 'oz (dry)';
        case BaseUnit.EACH:
            return 'ea';
        case BaseUnit.UNIT:
            return 'unit';
        default:
            throw new RuntimeException('unexpected value for unit: "' + unit + '"');
    }
};

const getDisplayTextForPackagingUnitWithProductPackagingAndPluralization = (unit : PackagingUnit, productPackaging : OldPackaging, isPlural : boolean) : string => {
    const displayPackagingUnit = resolvePackagingUnitForProductPackaging(unit, productPackaging);
    let displayText = oldPackagingUtils.getDisplayTextForUnit(displayPackagingUnit);
    if (isPlural) {
        if (displayText.endsWith('x')) {
            displayText += 'e';
        }
        displayText +=  's';
    }
    return displayText;
};

const getContainerTypeDisplayTextForPackaging = (packaging : OldPackaging) : string => {
    const container : OldPackaging = getContainer(packaging);
    const unit = container.getUnit();

    if (!PackagingUnit.isPackagingUnit(unit)) {
        throw new RuntimeException('unexpected: containerUnit is not PackagingUnit');
    }

    return getDisplayTextForUnit(unit);
};

export const getBottlePriceGivenCasePrice = (casePrice : number, packaging : OldPackaging) : number => {
    if (packaging.getUnit() !== PackagingUnit.CASE) {
        return casePrice;
    }

    const numberUnitsInPackage : number | null = packaging.getQuantityOfContent();

    if (numberUnitsInPackage === null) {
        return casePrice;
    }

    const bottlePrice : number = casePrice / numberUnitsInPackage;

    return bottlePrice;
};

const getContainerSizeDisplayTextForPackaging = (packaging : OldPackaging) : string => {
    const container : OldPackaging = getContainer(packaging);

    let containerUnit : VolumeUnit | MassUnit | BaseUnit;
    {
        let containerContent : OldPackaging;
        {
            const nullableContainerContent : OldPackaging | null = container.getContent();

            if (nullableContainerContent === null) {
                throw new RuntimeException('unexpected: container has no content');
            }

            containerContent = nullableContainerContent;
        }

        const unit = containerContent.getUnit();

        if (PackagingUnit.isPackagingUnit(unit)) {
            throw new RuntimeException('unexpected: containerUnit is PackagingUnit');
        }

        containerUnit = unit;
    }

    const unitDisplayText : string = getDisplayTextForUnit(containerUnit);
    const spacer : string = BaseUnit.isBaseUnit(containerUnit) || PackagingUnit.isPackagingUnit(containerUnit) ? " " : "";

    return `${ container.getQuantityOfContent() }${ spacer }${ unitDisplayText }`;
};

const isUnitValueValid = (unitValue : string) : boolean => {
    return PackagingUnit.isPackagingUnitValue(unitValue)
        || MassUnit.isMassUnitValue(unitValue)
        || VolumeUnit.isVolumeUnitValue(unitValue)
        || BaseUnit.isBaseUnitValue(unitValue);
};

const getUnitFromValue = (unitValue : string) : Unit => {
    if (BaseUnit.isBaseUnitValue(unitValue)) {
        return BaseUnit.getByBaseUnitValue(unitValue);
    } else if (PackagingUnit.isPackagingUnitValue(unitValue)) {
        return PackagingUnit.getByPackagingUnitValue(unitValue);
    } else if (MassUnit.isMassUnitValue(unitValue)) {
        return MassUnit.getByMassUnitValue(unitValue);
    } else if (VolumeUnit.isVolumeUnitValue(unitValue)) {
        return VolumeUnit.getByVolumeUnitValue(unitValue);
    } else {
        throw new RuntimeException(`unexpected unitValue: "${ unitValue }"`);
    }
};

const getValidContentUnitsForContainerType = (containerType : PackagingUnit) : Array<VolumeUnit | MassUnit | BaseUnit> => {
    switch (containerType) {
        case PackagingUnit.BOTTLE:
        case PackagingUnit.CAN:
            return [VolumeUnit.OUNCE, VolumeUnit.MILLILITER, VolumeUnit.LITER];
        case PackagingUnit.OTHER:
        case PackagingUnit.BAG:
        case PackagingUnit.BOX:
        case PackagingUnit.CAN_FOOD:
        case PackagingUnit.CARTON:
        case PackagingUnit.CONTAINER:
        case PackagingUnit.PACKAGE:
        case PackagingUnit.TUB:
            return [VolumeUnit.OUNCE, VolumeUnit.MILLILITER, VolumeUnit.LITER, VolumeUnit.GALLON, VolumeUnit.QUART, VolumeUnit.PINT, MassUnit.GRAM, MassUnit.KILOGRAM, MassUnit.POUND, MassUnit.DRY_OUNCE, BaseUnit.UNIT, BaseUnit.EACH];
        case PackagingUnit.KEG:
            return [VolumeUnit.GALLON, VolumeUnit.LITER];
        default:
            throw new RuntimeException('unexpected container');
    }
};

const getQuantityOfPackageInUnit = <T extends PackagingUnit | BaseUnit | MassUnit | VolumeUnit>(
        packaging : OldPackaging,
        inputQuantityInUnit : QuantityInUnit<PackagingUnit | BaseUnit | MassUnit | VolumeUnit>,
        outputUnit : T) : QuantityInUnit<T> => {

    const inputQuantityInBaseUnitOfPackaging = getQuantityInBaseUnitOfPackaging(packaging, inputQuantityInUnit);
    const quantityOfOutputUnitInBaseUnitOfPackaging = getQuantityInBaseUnitOfPackaging(packaging, new QuantityInUnit(1, outputUnit));

    return new QuantityInUnit(
        inputQuantityInBaseUnitOfPackaging.getQuantity() / quantityOfOutputUnitInBaseUnitOfPackaging.getQuantity(),
        outputUnit
    );
};

const getQuantityInBaseUnitOfPackaging = (packaging : OldPackaging, quantityInUnit : QuantityInUnit<PackagingUnit | BaseUnit | MassUnit | VolumeUnit>) : QuantityInUnit<BaseUnit | MassUnit | VolumeUnit> => {
    const unit = quantityInUnit.getUnit();
    const quantity = quantityInUnit.getQuantity();

    const packagingUnit = packaging.getUnit();

    if (unit === PackagingUnit.CASE) {
        if (packagingUnit !== PackagingUnit.CASE) {
            // This handles the scenario where we have a product that doesn't come as case but is described as a case in a delivery/transfer/breakage
            return getQuantityInBaseUnitOfPackaging(packaging, new QuantityInUnit(quantityInUnit.getQuantity(), PackagingUnit.CONTAINER));
        }

        const content = packaging.getContent();
        const quantityOfContent = packaging.getQuantityOfContent();
        if (content == null || quantityOfContent == null) {
            throw new RuntimeException('Content or QuantityOfContent null in packaging of unit Case');
        }

        return getQuantityInBaseUnitOfPackaging(content, new QuantityInUnit(quantityInUnit.getQuantity() * quantityOfContent, content.getUnit()));
    } else if (PackagingUnit.isPackagingUnit(unit)) {
        const content = packaging.getContent();
        const quantityOfContent = packaging.getQuantityOfContent();
        if (content == null || quantityOfContent == null) {
            throw new RuntimeException('Content or QuantityOfContent null in packaging with unit UnitOfPackaging');
        }

        if (packagingUnit === PackagingUnit.CASE) {
            return getQuantityInBaseUnitOfPackaging(content, new QuantityInUnit(quantityInUnit.getQuantity(), content.getUnit()));
        } else if (PackagingUnit.isPackagingUnit(packagingUnit)) {
            return getQuantityInBaseUnitOfPackaging(content, new QuantityInUnit(quantityInUnit.getQuantity() * quantityOfContent, content.getUnit()));
        } else {
            throw new RuntimeException('Incompatible OldPackaging: Cannot specify quantity in PackagingUnit for packaging not of type PackagingUnit');
        }
    } else {
        if (PackagingUnit.isPackagingUnit(packagingUnit)) {
            const content = packaging.getContent();
            const quantityOfContent = packaging.getQuantityOfContent();
            if (content == null || quantityOfContent == null) {
                throw new RuntimeException('Content or QuantityOfContent null in packaging with unit UnitOfPackaging');
            }
            return getQuantityInBaseUnitOfPackaging(content, quantityInUnit);
        }

        if (MassUnit.isMassUnit(unit)) {
            if (!(MassUnit.isMassUnit(packagingUnit))) {
                throw new RuntimeException('Incompatible OldPackaging: Cannot specify quantity in mass units for packaging with non-mass base');
            }

            return getQuantityInUnitMass(new QuantityInUnit(quantity, unit), packagingUnit);
        } else if (VolumeUnit.isVolumeUnit(unit)) {
            if (!VolumeUnit.isVolumeUnit(packagingUnit)) {
                throw new RuntimeException('Incompatible OldPackaging: Cannot specify quantity in volume units for packaging with non-volume base');
            }
            return getQuantityInUnitVolume(new QuantityInUnit(quantity, unit), packagingUnit);
        } else if (BaseUnit.isBaseUnit(unit)) {
            if (!BaseUnit.isBaseUnit(packagingUnit)) {
                throw new RuntimeException('Incompatible OldPackaging: Cannot specify quantity in base units for packaging with non-base base');
            }
            return new QuantityInUnit(quantity, packagingUnit);
        }
    }

    throw new RuntimeException('Unexpected - unreachable code');
};

const getQuantityInUnitMass = <T extends MassUnit>(quantityInUnit : QuantityInUnit<T>, unit : T) : QuantityInUnit<T> => {
    return new QuantityInUnit(
        getMassInKg(quantityInUnit).getQuantity() / getMassInKg(new QuantityInUnit(1, unit)).getQuantity(),
        unit);
};

const getQuantityInUnitVolume = <T extends VolumeUnit>(quantityInUnit : QuantityInUnit<T>, unit : T) : QuantityInUnit<T> => {
    return new QuantityInUnit(
        getVolumeInMl(quantityInUnit) / getVolumeInMl(new QuantityInUnit(1, unit)),
        unit);
};

const getMassInKg = (quantityInUnit : QuantityInUnit<MassUnit>) : QuantityInUnit<MassUnit.KILOGRAM> => {
    let quantity : number;
    switch (quantityInUnit.getUnit()) {
        case MassUnit.KILOGRAM:
            quantity = quantityInUnit.getQuantity();
            break;
        case MassUnit.GRAM:
            quantity = quantityInUnit.getQuantity() * 0.001;
            break;
        case MassUnit.POUND:
            quantity = quantityInUnit.getQuantity() * 0.453592;
            break;
        case MassUnit.DRY_OUNCE:
            quantity = quantityInUnit.getQuantity() * 0.0283495;
            break;
        default:
            throw new RuntimeException('unexpected mass unit');
    }

    return new QuantityInUnit(quantity, MassUnit.KILOGRAM);
};

const getQuantityOfMassUnit = (massInKg : number, unit : MassUnit) : number => {
    return massInKg / getMassInKg(new QuantityInUnit(1, unit)).getQuantity();
};

const getVolumeInMl = (quantityInUnit : QuantityInUnit<VolumeUnit>) : number => {
    switch (quantityInUnit.getUnit()) {
        case VolumeUnit.LITER:
            return quantityInUnit.getQuantity() * 1000.0;
        case VolumeUnit.CENTILITER:
            return quantityInUnit.getQuantity() * 10.0;
        case VolumeUnit.MILLILITER:
            return quantityInUnit.getQuantity();

        case VolumeUnit.GALLON:
            return quantityInUnit.getQuantity() * 3785.41;
        case VolumeUnit.QUART:
            return quantityInUnit.getQuantity() * 946.353;
        case VolumeUnit.PINT:
            return quantityInUnit.getQuantity() * 473.176;
        case VolumeUnit.OUNCE:
            return quantityInUnit.getQuantity() * 29.5735;

        case VolumeUnit.BAR_SPOON:
            return quantityInUnit.getQuantity() * 4.92892;
        case VolumeUnit.DASH:
            return quantityInUnit.getQuantity() * 0.616115;
        default:
            throw new RuntimeException('unexpected volume unit');
    }
};

const getQuantityOfVolumeUnit = (volumeInMl : number, unit : VolumeUnit) : number => {
    return volumeInMl / getVolumeInMl(new QuantityInUnit(1, unit));
};

const resolvePackagingUnitForProductPackaging = (packagingUnit : PackagingUnit, productPackaging : OldPackaging) : PackagingUnit => {
    let packagingUnitToDisplay = packagingUnit;
    const topLevelPackagingUnit = productPackaging.getUnit();
    if (packagingUnit !== PackagingUnit.CASE) {
        if (topLevelPackagingUnit === PackagingUnit.CASE) {
            const productPackagingContent = productPackaging.getContent();
            if (productPackagingContent) {
                const productPackagingContentUnit = productPackagingContent.getUnit();
                if (PackagingUnit.isPackagingUnit(productPackagingContentUnit)) {
                    // if count is not in case and product packaging has case, interpret count unit as the package below case ("container")
                    packagingUnitToDisplay = productPackagingContentUnit;
                }
            }
        } else {
            if (PackagingUnit.isPackagingUnit(topLevelPackagingUnit)) {
                // if count is not in case and product packaging does not have case, interpret count unit as the top-level package ("container")
                packagingUnitToDisplay = topLevelPackagingUnit;
            } else {
                throw new RuntimeException('unexpected');
            }
        }
    } else if (!hasCase(productPackaging)) {
        // if count is in case but packaging does not have case, interpret count unit as the top-level package ("container")
        if (PackagingUnit.isPackagingUnit(topLevelPackagingUnit)) {
            packagingUnitToDisplay = topLevelPackagingUnit;
        } else {
            // top-level package is not packaging unit
            throw new RuntimeException('unexpected');
        }
    }
    // count is in case, package has case
    return packagingUnitToDisplay;
};

const getOldPackagingFromPackaging = (packaging : Packaging) : OldPackaging => {
    const unitValue = packaging.getName() || packaging.getUnit();
    const content = packaging.getContent();

    if (unitValue === null) {
        throw new RuntimeException('unitValue unexpectedly null');
    }

    return new OldPackaging(
        (content === null) ? null : getOldPackagingFromPackaging(content),
        packaging.getQuantityOfContent(),
        getUnitFromValue(unitValue)
    );
};

const getPackagingWithDummyPackagingIdsFromOldPackaging = (oldPackaging : OldPackaging) : Packaging => { // PTODO
    const content = oldPackaging.getContent();
    const unit = oldPackaging.getUnit();
    const quantityOfContent = oldPackaging.getQuantityOfContent();

    if (MassUnit.isMassUnit(unit) || VolumeUnit.isVolumeUnit(unit)) {
        return new Packaging(null, null, null, null, unit);
    } else {
        return new Packaging(
            new PackagingId(unit),
            unit,
            (content === null) ? null : getPackagingWithDummyPackagingIdsFromOldPackaging(content),
            quantityOfContent,
            null
        );
    }
};

// PTPDO Cheezy
const getPackagingDataFromPackagingArray = (packagings : Array<Packaging>) => {
    return packagings.map((packaging) => {
        return {
            isActive: true,
            deleted: false,
            packaging
        };
    });
};

// should possibly belong to "priceUtils", esp. once it uses Packaging
const convertPriceUnit = (price : Price, packaging : OldPackaging, newUnit : Unit) : Price => {
    return new Price(
        oldPackagingUtils.getQuantityOfPackageInUnit(
            packaging,
            new QuantityInUnit(1, newUnit),
            price.getUnit(),
        ).getQuantity() * price.getDollarValue(),
        newUnit,
    );
};

const getThriftUnitFromUnit = (unit : Unit) : ProductAmountModel.Unit => {
    switch (unit) {
        case PackagingUnit.BOTTLE:
            return ProductAmountModel.Unit.BOTTLE;
        case PackagingUnit.CAN:
            return ProductAmountModel.Unit.CAN;
        case PackagingUnit.CASE:
            return ProductAmountModel.Unit.CASE;
        case PackagingUnit.KEG:
            return ProductAmountModel.Unit.KEG;
        case PackagingUnit.BAG:
            return ProductAmountModel.Unit.BAG;
        case PackagingUnit.BOX:
            return ProductAmountModel.Unit.BOX;
        case PackagingUnit.CAN_FOOD:
            return ProductAmountModel.Unit.CAN_FOOD;
        case PackagingUnit.CARTON:
            return ProductAmountModel.Unit.CARTON;
        case PackagingUnit.CONTAINER:
            return ProductAmountModel.Unit.CONTAINER;
        case PackagingUnit.PACKAGE:
            return ProductAmountModel.Unit.PACKAGE;
        case PackagingUnit.TUB:
            return ProductAmountModel.Unit.TUB;
        case PackagingUnit.OTHER:
            return ProductAmountModel.Unit.OTHER_CONTAINER;

        case VolumeUnit.LITER:
            return ProductAmountModel.Unit.METRIC_LITER;
        case VolumeUnit.MILLILITER:
            return ProductAmountModel.Unit.METRIC_MILLILITER;
        case VolumeUnit.OUNCE:
            return ProductAmountModel.Unit.US_FLUID_OUNCE;
        case VolumeUnit.GALLON:
            return ProductAmountModel.Unit.US_FLUID_GALLON;
        case VolumeUnit.QUART:
            return ProductAmountModel.Unit.US_FLUID_QUART;
        case VolumeUnit.PINT:
            return ProductAmountModel.Unit.US_FLUID_PINT;
        case VolumeUnit.CENTILITER:
            return ProductAmountModel.Unit.METRIC_CENTILITER;
        case VolumeUnit.BAR_SPOON:
            return ProductAmountModel.Unit.BAR_SPOON;
        case VolumeUnit.DASH:
            return ProductAmountModel.Unit.DASH;

        case MassUnit.KILOGRAM:
            return ProductAmountModel.Unit.METRIC_KILOGRAM;
        case MassUnit.GRAM:
            return ProductAmountModel.Unit.METRIC_GRAM;
        case MassUnit.POUND:
            return ProductAmountModel.Unit.US_POUND;
        case MassUnit.DRY_OUNCE:
            return ProductAmountModel.Unit.US_DRY_OUNCE;

        case BaseUnit.EACH:
            return ProductAmountModel.Unit.EACH;
        case BaseUnit.UNIT:
            return ProductAmountModel.Unit.UNIT;
        default:
            throw new RuntimeException('unexpected value for unit');
    }
};

const getFlattenedPackagingInfo = (oldPackaging : OldPackaging) : {
    containerType : ProductAmountModel.Unit;
    containerContentUnit : ProductAmountModel.Unit;
    containerContentQuantity : number;
    caseSize : number | null;
    hasCase : boolean;
} => {
    let container = oldPackaging;
    let caseSize = null;
    if (oldPackaging.getUnit() === PackagingUnit.CASE) {
        const content = oldPackaging.getContent();
        const quantityOfContent = oldPackaging.getQuantityOfContent();

        if (content === null || quantityOfContent === null) {
            throw new RuntimeException('unexpected null content');
        }

        container = content;
        caseSize = quantityOfContent;
    }

    const containerContent = container.getContent();
    const containerQuantityOfContent = container.getQuantityOfContent();
    if (containerContent === null || containerQuantityOfContent === null) {
        throw new RuntimeException('unexpected null content');
    }

    return {
        containerType: getThriftUnitFromUnit(container.getUnit()),
        containerContentUnit: getThriftUnitFromUnit(containerContent.getUnit()),
        containerContentQuantity: containerQuantityOfContent,
        caseSize,
        hasCase: caseSize !== null,
    };
};

export const oldPackagingUtils = {
    hasCase,
    getContainer,
    getDisplayTextForPackaging,
    getContainerTypeDisplayTextForPackaging,
    getContainerSizeDisplayTextForPackaging,
    getDisplayTextForUnit,
    getDisplayTextForPackagingUnitWithProductPackagingAndPluralization,
    getValidContentUnitsForContainerType,
    isUnitValueValid,
    getUnitFromValue,

    getBottlePriceGivenCasePrice,
    convertPriceUnit,

    getQuantityOfPackageInUnit,
    getQuantityInBaseUnitOfPackaging,

    getQuantityInUnitMass,
    getQuantityInUnitVolume,
    resolvePackagingUnitForProductPackaging,
    getOldPackagingFromPackaging,
    getPackagingWithDummyPackagingIdsFromOldPackaging,
    getPackagingDataFromPackagingArray,
    getThriftUnitFromUnit,
    getFlattenedPackagingInfo,

    // To Deprecate?
    getMassInKg,
    getVolumeInMl,
    getQuantityOfMassUnit,
    getQuantityOfVolumeUnit,
};
