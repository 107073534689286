//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';



var ProductAmountModel = {};
export default ProductAmountModel;
ProductAmountModel.Unit = {
  '0' : 'BOTTLE',
  'BOTTLE' : 0,
  '1' : 'CAN',
  'CAN' : 1,
  '2' : 'CASE',
  'CASE' : 2,
  '3' : 'KEG',
  'KEG' : 3,
  '4' : 'BAG',
  'BAG' : 4,
  '5' : 'BOX',
  'BOX' : 5,
  '6' : 'CAN_FOOD',
  'CAN_FOOD' : 6,
  '7' : 'CARTON',
  'CARTON' : 7,
  '8' : 'CONTAINER',
  'CONTAINER' : 8,
  '9' : 'PACKAGE',
  'PACKAGE' : 9,
  '10' : 'TUB',
  'TUB' : 10,
  '11' : 'OTHER_CONTAINER',
  'OTHER_CONTAINER' : 11,
  '12' : 'METRIC_LITER',
  'METRIC_LITER' : 12,
  '13' : 'METRIC_CENTILITER',
  'METRIC_CENTILITER' : 13,
  '14' : 'METRIC_MILLILITER',
  'METRIC_MILLILITER' : 14,
  '15' : 'US_FLUID_OUNCE',
  'US_FLUID_OUNCE' : 15,
  '16' : 'US_FLUID_GALLON',
  'US_FLUID_GALLON' : 16,
  '17' : 'US_FLUID_QUART',
  'US_FLUID_QUART' : 17,
  '18' : 'US_FLUID_PINT',
  'US_FLUID_PINT' : 18,
  '19' : 'BAR_SPOON',
  'BAR_SPOON' : 19,
  '20' : 'DASH',
  'DASH' : 20,
  '21' : 'METRIC_KILOGRAM',
  'METRIC_KILOGRAM' : 21,
  '22' : 'METRIC_GRAM',
  'METRIC_GRAM' : 22,
  '23' : 'US_POUND',
  'US_POUND' : 23,
  '24' : 'US_DRY_OUNCE',
  'US_DRY_OUNCE' : 24,
  '25' : 'UNIT',
  'UNIT' : 25,
  '26' : 'EACH',
  'EACH' : 26
};
ProductAmountModel.MassUnit = {
  '0' : 'METRIC_KILOGRAM',
  'METRIC_KILOGRAM' : 0,
  '1' : 'METRIC_GRAM',
  'METRIC_GRAM' : 1,
  '2' : 'US_POUND',
  'US_POUND' : 2,
  '3' : 'US_DRY_OUNCE',
  'US_DRY_OUNCE' : 3
};
ProductAmountModel.VolumeUnit = {
  '0' : 'METRIC_LITER',
  'METRIC_LITER' : 0,
  '1' : 'METRIC_CENTILITER',
  'METRIC_CENTILITER' : 1,
  '2' : 'METRIC_MILLILITER',
  'METRIC_MILLILITER' : 2,
  '3' : 'US_FLUID_OUNCE',
  'US_FLUID_OUNCE' : 3,
  '4' : 'US_FLUID_GALLON',
  'US_FLUID_GALLON' : 4,
  '5' : 'US_FLUID_QUART',
  'US_FLUID_QUART' : 5,
  '6' : 'US_FLUID_PINT',
  'US_FLUID_PINT' : 6,
  '7' : 'BAR_SPOON',
  'BAR_SPOON' : 7,
  '8' : 'DASH',
  'DASH' : 8
};
