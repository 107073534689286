import { StringValueMap } from 'api/Core/StringValueMap';
import { IUserName } from 'api/UserAccount/model/IUserName';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import {
    IUserAccountIdAndTimestampJSONObject,
    UserAccountIdAndTimestamp
} from 'api/UserAccount/model/UserAccountIdAndTimestamp';
import moment from 'moment-timezone';
import { DJANGO_API_UTC_TIMESTAMP_FORMAT } from 'shared/constants';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { momentObjectToThriftSerializer } from 'shared/lib/manager';
import { getDateTimeFromTimestamp, getTimezoneFromString } from 'shared/utils/dateTimeUtils';

const getUserAccountNameString = (
    userAccountId : UserAccountId,
    currentUserAccountId : UserAccountId,
    namesByUserAccountId : StringValueMap<UserAccountId, IUserName>,
) : string => {
    let nameString : string;
    if (userAccountId.equals(currentUserAccountId)) {
        nameString = 'You';
    } else {
        const userName = namesByUserAccountId.get(userAccountId);

        if (typeof userName === 'undefined') {
            throw new RuntimeException('unexpected');
        }

        nameString = userName.firstName + ' ' + userName.lastName;
    }

    return nameString;
};

const getUserAccountIdAndTimestampString = (
    userAccountIdAndTimestamp : UserAccountIdAndTimestamp,
    currentUserAccountId : UserAccountId,
    namesByUserAccountId : StringValueMap<UserAccountId, IUserName>,
) : string => {
    const userAccountId = userAccountIdAndTimestamp.getUserAccountId();
    const timestamp = userAccountIdAndTimestamp.getTimestamp();

    const timestampDateTime = getDateTimeFromTimestamp(timestamp, getTimezoneFromString(window.GLOBAL_RETAILER_TIME_ZONE));

    const nameString = getUserAccountNameString(userAccountId, currentUserAccountId, namesByUserAccountId);

    return `${ timestampDateTime.getValue('ddd, MMM. DD YYYY, h:mm a') } by ${ nameString }`;
};

const getUserAccountIdAndTimestampObjectFromJSON = (
    userAccountIdAndTimestampObject : IUserAccountIdAndTimestampJSONObject
) : UserAccountIdAndTimestamp => {
    const userId = new UserAccountId(userAccountIdAndTimestampObject.user_account_id);
    const timestamp = momentObjectToThriftSerializer.getThriftTimestampFromMoment(moment.utc(userAccountIdAndTimestampObject.timestamp));
    return new UserAccountIdAndTimestamp(userId, timestamp);
};

const getUserAccountIdAndTimestampJSONFromObject = (
    userAccountIdAndTimestamp : UserAccountIdAndTimestamp
) : IUserAccountIdAndTimestampJSONObject => {

    return {
        timestamp: moment(userAccountIdAndTimestamp.getTimestamp().timeSinceUnixEpoch.value).utc().format(DJANGO_API_UTC_TIMESTAMP_FORMAT),
        user_account_id: userAccountIdAndTimestamp.getUserAccountId().getValue()
    };
};

export const UserAccountUtils = {
    getUserAccountIdAndTimestampString,
    getUserAccountNameString,
    getUserAccountIdAndTimestampObjectFromJSON,
    getUserAccountIdAndTimestampJSONFromObject
};
