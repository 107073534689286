import ProductMappingModel from 'gen-thrift/product_mapping_Model_types';

import { LocationId } from 'api/Location/model/LocationId';
import { LocationThriftToObjectSerializer } from 'api/Location/serializer/LocationThriftToObjectSerializer';
import { ProductThriftToObjectSerializer } from 'api/Product/serializer/ProductThriftToObjectSerializer';
import { InterLocationProductMapping } from 'api/Transfer/model/InterLocationProductMapping';

export class ProductMappingThriftToObjectSerializer {

    constructor(
        private locationThriftToObjectSerializer : LocationThriftToObjectSerializer,
        private productThriftToObjectSerializer : ProductThriftToObjectSerializer
    ) {}

    ////////////////////////////////////
    // MODELS
    ////////////////////////////////////

    public getInterLocationProductMapping(
        thriftInterLocationProductMapping : ProductMappingModel.InterLocationProductQuantityUnitMapping
    ) : InterLocationProductMapping {
        return new InterLocationProductMapping(
            this.locationThriftToObjectSerializer.getLocationId(thriftInterLocationProductMapping.sourceLocationIdentifier),
            this.productThriftToObjectSerializer.getProductId(thriftInterLocationProductMapping.sourceProductId),
            this.productThriftToObjectSerializer.getQuantityInUnit(thriftInterLocationProductMapping.sourceQuantityOfProduct),
            this.locationThriftToObjectSerializer.getLocationId(thriftInterLocationProductMapping.targetLocationIdentifier),
            this.productThriftToObjectSerializer.getProductId(thriftInterLocationProductMapping.targetProductId),
            this.productThriftToObjectSerializer.getQuantityInUnit(thriftInterLocationProductMapping.targetQuantityOfProduct),
        );
    }

    public getInterLocationProductMappingFromUpdateEvent(
        sourceLocation : LocationId,
        targetLocation : LocationId,
        thriftInterLocationProductMappingUpdateEvent : ProductMappingModel.ProductQuantityUnitMappingUpdateEvent
    ) : InterLocationProductMapping {
        return new InterLocationProductMapping(
            sourceLocation,
            this.productThriftToObjectSerializer.getProductId(thriftInterLocationProductMappingUpdateEvent.sourceProductId),
            this.productThriftToObjectSerializer.getQuantityInUnit(thriftInterLocationProductMappingUpdateEvent.sourceQuantityOfProduct),
            targetLocation,
            this.productThriftToObjectSerializer.getProductId(thriftInterLocationProductMappingUpdateEvent.targetProductId),
            this.productThriftToObjectSerializer.getQuantityInUnit(thriftInterLocationProductMappingUpdateEvent.targetQuantityOfProduct),
        );
    }
}
