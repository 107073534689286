import { PublicContent } from './PublicContent';
import { PublicContentViewEvent } from './PublicContentViewEvent';

export class PublicContentAndPublicContentViewEvent {
    private publicContent : PublicContent;
    private publicContentViewEvent : PublicContentViewEvent | null;

    constructor(
        publicContent : PublicContent,
        publicContentViewEvent : PublicContentViewEvent | null
    ) {
        this.publicContent = publicContent;
        this.publicContentViewEvent = publicContentViewEvent;
    }

    public getPublicContent() : PublicContent {
        return this.publicContent;
    }

    public getPublicContentViewEvent() : PublicContentViewEvent | null {
        return this.publicContentViewEvent;
    }
}
