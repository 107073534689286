// Should consider moving this to the SalesData directory, it fits better there...

export class SalesEntry {
    constructor(
        private readonly price : number | null,
        private readonly quantity : number | null,
        private readonly quantityAdjustment : number | null,
        private readonly salesAdjustment : number | null,
    ) {}

    public getPrice() : number | null {
        return this.price;
    }

    public getQuantity() : number | null {
        return this.quantity;
    }

    public getQuantityAdjustment() : number | null {
        return this.quantityAdjustment;
    }

    public getSalesAdjustment() : number | null {
        return this.salesAdjustment;
    }

    // not typing this - field types & nullity should stay identical to the model
    public toJson() {
        return {
            price : this.price,
            quantity : this.quantity,
            quantity_adjustment : this.quantityAdjustment,
            sales_adjustment : this.salesAdjustment,
        };
    }
}
