import * as _ from 'lodash';
import { Action } from 'redux';

import { ActionTypes, BreadcrumbIntegrationActionInterfaces } from './BreadcrumbIntegrationModalActions';
import { IBreadcrumbCredentialFormState, IBreadcrumbIntegrationState } from './models';

const credentialFormEmptyState : IBreadcrumbCredentialFormState = {
    usernameField : {
        value : '',
        isValid : true,
        errorMessage : ''
    },
    passwordField : {
        value : '',
        isValid : true,
        errorMessage : ''
    },
    credentialIsValid : true,
    isSubmittingUpdate : false,
    isDeleting : false,
    updateSubmitted : false,
};

const initialState : IBreadcrumbIntegrationState = {
    existingIntegration : null,
    modalIsShown : false,
    isLoadingExistingCredential : false,
    credentialFormState : credentialFormEmptyState,
};

const reduceSetModalShown = (
    state : IBreadcrumbIntegrationState,
    action : BreadcrumbIntegrationActionInterfaces.ISetModalShown
) : IBreadcrumbIntegrationState => {
    const updates = {
        modalIsShown : action.payload.isShown,
    };
    return _.merge({}, state, updates);
};

const reduceResetCredentialFormAccordingToExistingCredential = (
    state : IBreadcrumbIntegrationState,
    action : BreadcrumbIntegrationActionInterfaces.IResetCredentialFormAccordingToExistingCredential
) : IBreadcrumbIntegrationState => {
    let credentialFormState : IBreadcrumbCredentialFormState;

    const existingCredentialState = state.existingIntegration;

    if (existingCredentialState === null) {
        credentialFormState =  _.cloneDeep(credentialFormEmptyState);
    } else {
        const {
            username,
            password,
            isValid,
        } = existingCredentialState;
        credentialFormState = {
            usernameField : {
                value : username,
                isValid : true,
                errorMessage : '',
            },
            passwordField : {
                value : password,
                isValid : true,
                errorMessage : '',
            },
            credentialIsValid : isValid,
            isSubmittingUpdate : false,
            isDeleting : false,
            updateSubmitted : false,
        };
    }

    return {
        ...state,
        credentialFormState,
    };
};

const reduceFetchExistingCredentialRequest = (
    state : IBreadcrumbIntegrationState,
    action : BreadcrumbIntegrationActionInterfaces.IFetchExistingCredentialRequest
) : IBreadcrumbIntegrationState => {
    const updates = {
        isLoadingExistingCredential : true,
    };
    return _.merge({}, state, updates);
};

const reduceFetchExistingCredentialSuccess = (
    state : IBreadcrumbIntegrationState,
    action : BreadcrumbIntegrationActionInterfaces.IFetchExistingCredentialSuccess
) : IBreadcrumbIntegrationState => {
    const updates = {
        isLoadingExistingCredential : false,
    };
    return _.assign({}, state, updates);
};

const reduceSetExistingCredentialState = (
    state : IBreadcrumbIntegrationState,
    action : BreadcrumbIntegrationActionInterfaces.ISetExistingCredentialState
) : IBreadcrumbIntegrationState => {
    return {
        ...state,
        existingIntegration : action.payload.credentialData,
        isLoadingExistingCredential : false,
    };
};

const reduceUpdateCredentialRequest = (
    state : IBreadcrumbIntegrationState,
    action : BreadcrumbIntegrationActionInterfaces.IUpdateCredentialRequest
) : IBreadcrumbIntegrationState => {
    const updates = {
        credentialFormState : {
            isSubmittingUpdate : true
        },
    };
    return _.merge({}, state, updates);
};

const reduceUpdateCredentialSuccess = (
    state : IBreadcrumbIntegrationState,
    action : BreadcrumbIntegrationActionInterfaces.IUpdateCredentialSuccess
) : IBreadcrumbIntegrationState => {
    const updates = {
        credentialFormState : {
            isSubmittingUpdate : false,
            updateSubmitted : true,
        },
    };
    return _.merge({}, state, updates);
};

const reduceUpdateCredentialFailure = (
    state : IBreadcrumbIntegrationState,
    action : BreadcrumbIntegrationActionInterfaces.IUpdateCredentialFailure
) : IBreadcrumbIntegrationState => {
    // "invalid credential" is the only expected (recoverable) failure
    const updates = {
        credentialFormState : {
            isSubmittingUpdate : false,
            credentialIsValid : false,
        },
    };
    return _.merge({}, state, updates);
};

const reduceDeleteCredentialRequest = (
    state : IBreadcrumbIntegrationState,
    action : BreadcrumbIntegrationActionInterfaces.IDeleteCredentialRequest
) : IBreadcrumbIntegrationState => {
    return {
        ...state,
        credentialFormState : {
            ...state.credentialFormState,
            isDeleting : true,
        }
    };
};

const reduceDeleteCredentialSuccess = (
    state : IBreadcrumbIntegrationState,
    action : BreadcrumbIntegrationActionInterfaces.IDeleteCredentialSuccess
) : IBreadcrumbIntegrationState => {
    return {
        ...state,
        credentialFormState : {
            ...state.credentialFormState,
            isDeleting : false,
        }
    };
};

const reduceSetUsernameFieldValue = (
    state : IBreadcrumbIntegrationState,
    action : BreadcrumbIntegrationActionInterfaces.ISetUsernameFieldValue
) : IBreadcrumbIntegrationState => {
    const updates = {
        credentialFormState : {
            usernameField : {
                value : action.payload.value,
                isValid : true,
                errorMessage : '',
            },
        },
    };
    return _.merge({}, state, updates);
};

const reduceSetPasswordFieldValue = (
    state : IBreadcrumbIntegrationState,
    action : BreadcrumbIntegrationActionInterfaces.ISetPasswordFieldValue
) : IBreadcrumbIntegrationState => {
    const updates = {
        credentialFormState : {
            passwordField : {
                value : action.payload.value,
                isValid : true,
                errorMessage : '',
            },
        },
    };
    return _.merge({}, state, updates);
};

const reduceSetUsernameFieldError = (
    state : IBreadcrumbIntegrationState,
    action : BreadcrumbIntegrationActionInterfaces.ISetUsernameFieldError
) : IBreadcrumbIntegrationState => {
    const updates = {
        credentialFormState : {
            usernameField : {
                isValid : false,
                errorMessage : action.payload.message,
            },
        },
    };
    return _.merge({}, state, updates);
};

const reduceSetPasswordFieldError = (
    state : IBreadcrumbIntegrationState,
    action : BreadcrumbIntegrationActionInterfaces.ISetPasswordFieldError
) : IBreadcrumbIntegrationState => {
    const updates = {
        credentialFormState : {
            passwordField : {
                isValid : false,
                errorMessage : action.payload.message,
            },
        },
    };
    return _.merge({}, state, updates);
};

export const BreadcrumbIntegrationModalReducers = (
    state : IBreadcrumbIntegrationState = initialState,
    action : Action
) : IBreadcrumbIntegrationState => {
    switch (action.type) {
        case ActionTypes.SET_MODAL_SHOWN:
            return reduceSetModalShown(state, action as BreadcrumbIntegrationActionInterfaces.ISetModalShown);
        case ActionTypes.RESET_CREDENTIAL_FORM_ACCORDING_TO_EXISTING_CREDENTIAL:
            return reduceResetCredentialFormAccordingToExistingCredential(state, action as BreadcrumbIntegrationActionInterfaces.IResetCredentialFormAccordingToExistingCredential);
        case ActionTypes.FETCH_EXISTING_CREDENTIAL_REQUEST:
            return reduceFetchExistingCredentialRequest(state, action as BreadcrumbIntegrationActionInterfaces.IFetchExistingCredentialRequest);
        case ActionTypes.FETCH_EXISTING_CREDENTIAL_SUCCESS:
            return reduceFetchExistingCredentialSuccess(state, action as BreadcrumbIntegrationActionInterfaces.IFetchExistingCredentialSuccess);
        case ActionTypes.SET_EXISTING_CREDENTIAL_STATE:
            return reduceSetExistingCredentialState(state, action as BreadcrumbIntegrationActionInterfaces.ISetExistingCredentialState);
        case ActionTypes.UPDATE_CREDENTIAL_REQUEST:
            return reduceUpdateCredentialRequest(state, action as BreadcrumbIntegrationActionInterfaces.IUpdateCredentialRequest);
        case ActionTypes.UPDATE_CREDENTIAL_SUCCESS:
            return reduceUpdateCredentialSuccess(state, action as BreadcrumbIntegrationActionInterfaces.IUpdateCredentialSuccess);
        case ActionTypes.UPDATE_CREDENTIAL_FAILURE:
            return reduceUpdateCredentialFailure(state, action as BreadcrumbIntegrationActionInterfaces.IUpdateCredentialFailure);
        case ActionTypes.DELETE_CREDENTIAL_REQUEST:
            return reduceDeleteCredentialRequest(state, action as BreadcrumbIntegrationActionInterfaces.IDeleteCredentialRequest);
        case ActionTypes.DELETE_CREDENTIAL_SUCCESS:
            return reduceDeleteCredentialSuccess(state, action as BreadcrumbIntegrationActionInterfaces.IDeleteCredentialSuccess);
        case ActionTypes.SET_USERNAME_FIELD_VALUE:
            return reduceSetUsernameFieldValue(state, action as BreadcrumbIntegrationActionInterfaces.ISetUsernameFieldValue);
        case ActionTypes.SET_PASSWORD_FIELD_VALUE:
            return reduceSetPasswordFieldValue(state, action as BreadcrumbIntegrationActionInterfaces.ISetPasswordFieldValue);
        case ActionTypes.SET_USERNAME_FIELD_ERROR:
            return reduceSetUsernameFieldError(state, action as BreadcrumbIntegrationActionInterfaces.ISetUsernameFieldError);
        case ActionTypes.SET_PASSWORD_FIELD_ERROR:
            return reduceSetPasswordFieldError(state, action as BreadcrumbIntegrationActionInterfaces.ISetPasswordFieldError);
        default:
            return state;
    }
};
