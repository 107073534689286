import SalesItemModel from 'gen-thrift/sales_item_Model_types';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { LocationObjectToThriftSerializer } from 'api/Location/serializer/LocationObjectToThriftSerializer';
import { ProductObjectToThriftSerializer } from 'api/Product/serializer/ProductObjectToThriftSerializer';
import { SalesItem } from 'api/SalesItem/model/SalesItem';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesQuantityAndUnit } from 'api/SalesItem/model/SalesQuantityAndUnit';
import { Integer } from 'shared/models/Integer';
import { numberToDecimalRoundingDown } from 'shared/utils/decimalUtils';

export class SalesItemObjectToThriftSerializer {
    constructor(
        private locationObjectToThriftSerializer : LocationObjectToThriftSerializer,
        private productObjectToThriftSerializer : ProductObjectToThriftSerializer
    ) {}

    public getThriftSalesItemIdList(salesItemIds : StringValueSet<SalesItemId>) : Array<SalesItemModel.SalesItemId> {
        return Array.from(salesItemIds.values()).map((id) => this.getThriftSalesItemId(id));
    }

    public getThriftSalesItemId(salesItemId : SalesItemId) : SalesItemModel.SalesItemId {
        return new SalesItemModel.SalesItemId({ value: salesItemId.getValue() });
    }

    public getThriftSalesItem(salesItem : SalesItem) : SalesItemModel.SalesItem {
        const needsAttentionCategoryFromObj = salesItem.getNeedsAttentionCategory();
        return new SalesItemModel.SalesItem({
            name: salesItem.getName(),
            locationId: this.locationObjectToThriftSerializer.getThriftLocationId(salesItem.getLocationId()),
            menuGroup: salesItem.getMenuGroup(),
            posId: salesItem.getPOSId(),
            note: salesItem.getNote(),
            needsAttentionCategory: needsAttentionCategoryFromObj ? needsAttentionCategoryFromObj : '',
            salesPrice: this.productObjectToThriftSerializer.getThriftMonetaryValueInDollars(salesItem.getSalesPrice()),
            miscellaneousCost: this.productObjectToThriftSerializer.getThriftMonetaryValueInDollars(salesItem.getMiscellaneousCost()),
            componentQuantityOfProductByProductId: this.productObjectToThriftSerializer.getThriftProductIdAndQuantityOfProducts(salesItem.getComponentQuantityOfProductByProductId()),
            componentServingsBySalesItemId: this.getSalesItemIdAndQuantityArray(salesItem.getComponentServingsBySalesItemId()),
            itemYield: this.getThriftSalesQuantityAndUnit(salesItem.getItemYield()),
            servingSize: this.getThriftSalesQuantityAndUnit(salesItem.getServingSize()),
            salesItemCustomUnitName: salesItem.getSalesItemCustomUnitName(),
        });
    }

    private getSalesItemIdAndQuantityArray(salesItemQuantityById : StringValueMap<SalesItemId, number>) : Array<SalesItemModel.SalesItemIdAndQuantity> {
        const thriftSalesItemIdAndQuantityArray : Array<SalesItemModel.SalesItemIdAndQuantity> = [];
        salesItemQuantityById.forEach((quantity, salesItemId) => {
            thriftSalesItemIdAndQuantityArray.push(new SalesItemModel.SalesItemIdAndQuantity({
                salesItemId: this.getThriftSalesItemId(salesItemId),
                quantity: numberToDecimalRoundingDown(quantity, new Integer(4)) // TODO scale?
            }));
        });
        return thriftSalesItemIdAndQuantityArray;
    }

    private getThriftSalesQuantityAndUnit(salesQuantityAndUnit : SalesQuantityAndUnit) : SalesItemModel.SalesQuantityAndUnit {
        const unit = salesQuantityAndUnit.getUnit();
        return new SalesItemModel.SalesQuantityAndUnit({
            quantity: numberToDecimalRoundingDown(salesQuantityAndUnit.getQuantity(), new Integer(4)), // TODO scale?
            unit: unit === null ? null : this.productObjectToThriftSerializer.getThriftUnitOfMeasure(unit)
        });
    }
}
