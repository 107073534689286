interface ICurrency {
    code : string;
    name : string;
    symbol : string;
}

// should be kept in sync with the CURRENCY_CODES in account/settings/views.py
export type CurrencyCode = 'ALL' | 'AFN' | 'ARS' | 'AWG' | 'AUD' | 'AZN' | 'BSD' | 'BBD' | 'BYN' | 'BZD' | 'BMD' | 'BOB' | 'BAM' |
'BWP' | 'BGN' | 'BRL' | 'BND' | 'KHR' | 'CAD' | 'KYD' | 'CLP' | 'CNY' | 'COP' | 'CRC' | 'HRK' | 'CUP' | 'CZK' | 'DKK' |
'DOP' | 'XCD' | 'EGP' | 'SVC' | 'EUR' | 'FKP' | 'FJD' | 'GHS' | 'GIP' | 'GTQ' | 'GGP' | 'GYD' | 'HNL' | 'HKD' | 'HUF' |
'ISK' | 'INR' | 'IDR' | 'IRR' | 'IMP' | 'ILS' | 'JMD' | 'JPY' | 'JEP' | 'KES' | 'KZT' | 'KPW' | 'KRW' | 'KGS' | 'LAK' |
'LBP' | 'LRD' | 'MKD' | 'MYR' | 'MUR' | 'MXN' | 'MNT' | 'MZN' | 'NAD' | 'NPR' | 'ANG' | 'NZD' | 'NIO' | 'NGN' | 'NOK' |
'OMR' | 'PKR' | 'PAB' | 'PYG' | 'PEN' | 'PHP' | 'PLN' | 'QAR' | 'RON' | 'RUB' | 'SHP' | 'SAR' | 'RSD' | 'SCR' | 'SLL' | 'SGD' |
'SBD' | 'SOS' | 'ZAR' | 'LKR' | 'SEK' | 'CHF' | 'SRD' | 'SYP' | 'TWD' | 'THB' | 'TTD' | 'TRY' | 'TVD' | 'UAH' | 'GBP' |
'USD' | 'UYU' | 'UZS' | 'VEF' | 'VND' | 'YER';

export const currencyByCode : { [key in CurrencyCode] : ICurrency } = {
    ALL: {
        code: 'ALL',
        name: 'Albania Lek',
        symbol: 'Lek',
    },
    AFN: {
        code: 'AFN',
        name: 'Afghanistan Afghani',
        symbol: '؋',
    },
    ARS: {
        code: 'ARS',
        name: 'Argentina Peso',
        symbol: '$',
    },
    AWG: {
        code: 'AWG',
        name: 'Aruba Guilder',
        symbol: 'ƒ',
    },
    AUD: {
        code: 'AUD',
        name: 'Australia Dollar',
        symbol: '$',
    },
    AZN: {
        code: 'AZN',
        name: 'Azerbaijan Manat',
        symbol: '₼',
    },
    BSD: {
        code: 'BSD',
        name: 'Bahamas Dollar',
        symbol: '$',
    },
    BBD: {
        code: 'BBD',
        name: 'Barbados Dollar',
        symbol: '$',
    },
    BYN: {
        code: 'BYN',
        name: 'Belarus Ruble',
        symbol: 'Br',
    },
    BZD: {
        code: 'BZD',
        name: 'Belize Dollar',
        symbol: 'BZ$',
    },
    BMD: {
        code: 'BMD',
        name: 'Bermuda Dollar',
        symbol: '$',
    },
    BOB: {
        code: 'BOB',
        name: 'Bolivia Bolíviano',
        symbol: '$b',
    },
    BAM: {
        code: 'BAM',
        name: 'Bosnia and Herzegovina Convertible Marka',
        symbol: 'KM',
    },
    BWP: {
        code: 'BWP',
        name: 'Botswana Pula',
        symbol: 'P',
    },
    BGN: {
        code: 'BGN',
        name: 'Bulgaria Lev',
        symbol: 'лв',
    },
    BRL: {
        code: 'BRL',
        name: 'Brazil Real',
        symbol: 'R$',
    },
    BND: {
        code: 'BND',
        name: 'Brunei Darussalam Dollar',
        symbol: '$',
    },
    KHR: {
        code: 'KHR',
        name: 'Cambodia Riel',
        symbol: '៛',
    },
    CAD: {
        code: 'CAD',
        name: 'Canada Dollar',
        symbol: '$',
    },
    KYD: {
        code: 'KYD',
        name: 'Cayman Islands Dollar',
        symbol: '$',
    },
    CLP: {
        code: 'CLP',
        name: 'Chile Peso',
        symbol: '$',
    },
    CNY: {
        code: 'CNY',
        name: 'China Yuan Renminbi',
        symbol: '¥',
    },
    COP: {
        code: 'COP',
        name: 'Colombia Peso',
        symbol: '$',
    },
    CRC: {
        code: 'CRC',
        name: 'Costa Rica Colon',
        symbol: '₡',
    },
    HRK: {
        code: 'HRK',
        name: 'Croatia Kuna',
        symbol: 'kn',
    },
    CUP: {
        code: 'CUP',
        name: 'Cuba Peso',
        symbol: '₱',
    },
    CZK: {
        code: 'CZK',
        name: 'Czech Republic Koruna',
        symbol: 'Kč',
    },
    DKK: {
        code: 'DKK',
        name: 'Denmark Krone',
        symbol: 'kr',
    },
    DOP: {
        code: 'DOP',
        name: 'Dominican Republic Peso',
        symbol: 'RD$',
    },
    XCD: {
        code: 'XCD',
        name: 'East Caribbean Dollar',
        symbol: '$',
    },
    EGP: {
        code: 'EGP',
        name: 'Egypt Pound',
        symbol: '£',
    },
    SVC: {
        code: 'SVC',
        name: 'El Salvador Colon',
        symbol: '$',
    },
    EUR: {
        code: 'EUR',
        name: 'Euro Member Countries',
        symbol: '€',
    },
    FKP: {
        code: 'FKP',
        name: 'Falkland Islands (Malvinas) Pound',
        symbol: '£',
    },
    FJD: {
        code: 'FJD',
        name: 'Fiji Dollar',
        symbol: '$',
    },
    GHS: {
        code: 'GHS',
        name: 'Ghana Cedi',
        symbol: '¢',
    },
    GIP: {
        code: 'GIP',
        name: 'Gibraltar Pound',
        symbol: '£',
    },
    GTQ: {
        code: 'GTQ',
        name: 'Guatemala Quetzal',
        symbol: 'Q',
    },
    GGP: {
        code: 'GGP',
        name: 'Guernsey Pound',
        symbol: '£',
    },
    GYD: {
        code: 'GYD',
        name: 'Guyana Dollar',
        symbol: '$',
    },
    HNL: {
        code: 'HNL',
        name: 'Honduras Lempira',
        symbol: 'L',
    },
    HKD: {
        code: 'HKD',
        name: 'Hong Kong Dollar',
        symbol: '$',
    },
    HUF: {
        code: 'HUF',
        name: 'Hungary Forint',
        symbol: 'Ft',
    },
    ISK: {
        code: 'ISK',
        name: 'Iceland Krona',
        symbol: 'kr',
    },
    INR: {
        code: 'INR',
        name: 'India Rupee',
        symbol: '₹',
    },
    IDR: {
        code: 'IDR',
        name: 'Indonesia Rupiah',
        symbol: 'Rp',
    },
    IRR: {
        code: 'IRR',
        name: 'Iran Rial',
        symbol: '﷼',
    },
    IMP: {
        code: 'IMP',
        name: 'Isle of Man Pound',
        symbol: '£',
    },
    ILS: {
        code: 'ILS',
        name: 'Israel Shekel',
        symbol: '₪',
    },
    JMD: {
        code: 'JMD',
        name: 'Jamaica Dollar',
        symbol: 'J$',
    },
    JPY: {
        code: 'JPY',
        name: 'Japan Yen',
        symbol: '¥',
    },
    JEP: {
        code: 'JEP',
        name: 'Jersey Pound',
        symbol: '£',
    },
    KES: {
        code: 'KES',
        name: 'Kenyan Shilling',
        symbol: 'KSh',
    },
    KZT: {
        code: 'KZT',
        name: 'Kazakhstan Tenge',
        symbol: 'лв',
    },
    KPW: {
        code: 'KPW',
        name: 'Korea (North) Won',
        symbol: '₩',
    },
    KRW: {
        code: 'KRW',
        name: 'Korea (South) Won',
        symbol: '₩',
    },
    KGS: {
        code: 'KGS',
        name: 'Kyrgyzstan Som',
        symbol: 'лв',
    },
    LAK: {
        code: 'LAK',
        name: 'Laos Kip',
        symbol: '₭',
    },
    LBP: {
        code: 'LBP',
        name: 'Lebanon Pound',
        symbol: '£',
    },
    LRD: {
        code: 'LRD',
        name: 'Liberia Dollar',
        symbol: '$',
    },
    MKD: {
        code: 'MKD',
        name: 'Macedonia Denar',
        symbol: 'ден',
    },
    MYR: {
        code: 'MYR',
        name: 'Malaysia Ringgit',
        symbol: 'RM',
    },
    MUR: {
        code: 'MUR',
        name: 'Mauritius Rupee',
        symbol: '₨',
    },
    MXN: {
        code: 'MXN',
        name: 'Mexico Peso',
        symbol: '$',
    },
    MNT: {
        code: 'MNT',
        name: 'Mongolia Tughrik',
        symbol: '₮',
    },
    MZN: {
        code: 'MZN',
        name: 'Mozambique Metical',
        symbol: 'MT',
    },
    NAD: {
        code: 'NAD',
        name: 'Namibia Dollar',
        symbol: '$',
    },
    NPR: {
        code: 'NPR',
        name: 'Nepal Rupee',
        symbol: '₨',
    },
    ANG: {
        code: 'ANG',
        name: 'Netherlands Antilles Guilder',
        symbol: 'ƒ',
    },
    NZD: {
        code: 'NZD',
        name: 'New Zealand Dollar',
        symbol: '$',
    },
    NIO: {
        code: 'NIO',
        name: 'Nicaragua Cordoba',
        symbol: 'C$',
    },
    NGN: {
        code: 'NGN',
        name: 'Nigeria Naira',
        symbol: '₦',
    },
    NOK: {
        code: 'NOK',
        name: 'Norway Krone',
        symbol: 'kr',
    },
    OMR: {
        code: 'OMR',
        name: 'Oman Rial',
        symbol: '﷼',
    },
    PKR: {
        code: 'PKR',
        name: 'Pakistan Rupee',
        symbol: '₨',
    },
    PAB: {
        code: 'PAB',
        name: 'Panama Balboa',
        symbol: 'B/.',
    },
    PYG: {
        code: 'PYG',
        name: 'Paraguay Guarani',
        symbol: 'Gs',
    },
    PEN: {
        code: 'PEN',
        name: 'Peru Sol',
        symbol: 'S/.',
    },
    PHP: {
        code: 'PHP',
        name: 'Philippines Peso',
        symbol: '₱',
    },
    PLN: {
        code: 'PLN',
        name: 'Poland Zloty',
        symbol: 'zł',
    },
    QAR: {
        code: 'QAR',
        name: 'Qatar Riyal',
        symbol: '﷼',
    },
    RON: {
        code: 'RON',
        name: 'Romania Leu',
        symbol: 'lei',
    },
    RUB: {
        code: 'RUB',
        name: 'Russia Ruble',
        symbol: '₽',
    },
    SHP: {
        code: 'SHP',
        name: 'Saint Helena Pound',
        symbol: '£',
    },
    SAR: {
        code: 'SAR',
        name: 'Saudi Arabia Riyal',
        symbol: '﷼',
    },
    RSD: {
        code: 'RSD',
        name: 'Serbia Dinar',
        symbol: 'Дин.',
    },
    SCR: {
        code: 'SCR',
        name: 'Seychelles Rupee',
        symbol: '₨',
    },
    SLL: {
        code: 'SLL',
        name: 'Sierra Leone',
        symbol: 'SLE',
    },
    SGD: {
        code: 'SGD',
        name: 'Singapore Dollar',
        symbol: '$',
    },
    SBD: {
        code: 'SBD',
        name: 'Solomon Islands Dollar',
        symbol: '$',
    },
    SOS: {
        code: 'SOS',
        name: 'Somalia Shilling',
        symbol: 'S',
    },
    ZAR: {
        code: 'ZAR',
        name: 'South Africa Rand',
        symbol: 'R',
    },
    LKR: {
        code: 'LKR',
        name: 'Sri Lanka Rupee',
        symbol: '₨',
    },
    SEK: {
        code: 'SEK',
        name: 'Sweden Krona',
        symbol: 'kr',
    },
    CHF: {
        code: 'CHF',
        name: 'Switzerland Franc',
        symbol: 'CHF',
    },
    SRD: {
        code: 'SRD',
        name: 'Suriname Dollar',
        symbol: '$',
    },
    SYP: {
        code: 'SYP',
        name: 'Syria Pound',
        symbol: '£',
    },
    TWD: {
        code: 'TWD',
        name: 'Taiwan New Dollar',
        symbol: 'NT$',
    },
    THB: {
        code: 'THB',
        name: 'Thailand Baht',
        symbol: '฿',
    },
    TTD: {
        code: 'TTD',
        name: 'Trinidad and Tobago Dollar',
        symbol: 'TT$',
    },
    TRY: {
        code: 'TRY',
        name: 'Turkey Lira',
        symbol: '₺',
    },
    TVD: {
        code: 'TVD',
        name: 'Tuvalu Dollar',
        symbol: '$',
    },
    UAH: {
        code: 'UAH',
        name: 'Ukraine Hryvnia',
        symbol: '₴',
    },
    GBP: {
        code: 'GBP',
        name: 'United Kingdom Pound',
        symbol: '£',
    },
    USD: {
        code: 'USD',
        name: 'United States Dollar',
        symbol: '$',
    },
    UYU: {
        code: 'UYU',
        name: 'Uruguay Peso',
        symbol: '$U',
    },
    UZS: {
        code: 'UZS',
        name: 'Uzbekistan Som',
        symbol: 'лв',
    },
    VEF: {
        code: 'VEF',
        name: 'Venezuela Bolívar',
        symbol: 'Bs',
    },
    VND: {
        code: 'VND',
        name: 'Viet Nam Dong',
        symbol: '₫',
    },
    YER: {
        code: 'YER',
        name: 'Yemen Rial',
        symbol: '﷼',
    }
};

export const getCurrencySymbol = (code? : CurrencyCode) => {
    const currencyCode = typeof code === 'undefined' ? window.GLOBAL_RETAILER_CURRENCY : code;
    const currency = currencyByCode[currencyCode as CurrencyCode];
    let symbol = '$';

    // because window.GLOBAL_RETAILER_CURRENCY is type `string` and not `CurrencyCode`
    if (typeof currency !== 'undefined') {
        symbol = currency.symbol;
    }

    return symbol;
};
