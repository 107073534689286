import { CalendarDay } from './CalendarDay';
import { CalendarWeek } from './CalendarWeek';
import { DayOfWeekName } from './DayOfWeekName';

import { BevSpotDatePickerUtils } from '../components/BevSpotDatePicker/utils';

export interface ICalendarMonth {
    firstDayOfMonth : CalendarDay;
    numberOfDaysInMonth : number;
}
export class CalendarMonth {
    private firstDayOfMonth : CalendarDay;
    private numberOfDaysInMonth : number;
    constructor(args : ICalendarMonth) {
        this.firstDayOfMonth = args.firstDayOfMonth;
        this.numberOfDaysInMonth = args.numberOfDaysInMonth;
    }
    public getCalendarWeeks = () : Array<CalendarWeek> => {
        const firstWeekOfMonth : CalendarWeek = new CalendarWeek({
            firstDayInWeek: this.firstDayOfMonth,
            numberOfDaysInMonth: this.numberOfDaysInMonth,
        });
        const calendarWeeks : Array<CalendarWeek> = [];
        const numberOfDaysInMonth = firstWeekOfMonth.getNumberOfDaysInMonth();
        const numberOfWeeksInMonth = BevSpotDatePickerUtils.getNumberOfWeeksInMonth(firstWeekOfMonth.getFirstDayInWeek().getDayOfWeekName(), numberOfDaysInMonth);
        let previousSaturdayNumericalDayOfMonth : number = firstWeekOfMonth.getNumericalDayOfMonthFromDayOfWeekName(DayOfWeekName.SATURDAY) || 0;
        calendarWeeks.push(firstWeekOfMonth);
        for (let i = 1; i < numberOfWeeksInMonth; i++) {
            const calendarWeek : CalendarWeek = new CalendarWeek({
                firstDayInWeek: new CalendarDay({
                    dayOfWeekName: DayOfWeekName.SUNDAY,
                    numericalDayOfMonth: previousSaturdayNumericalDayOfMonth + 1,
                }),
                numberOfDaysInMonth,
            });
            calendarWeeks.push(calendarWeek);
            previousSaturdayNumericalDayOfMonth = calendarWeek.getNumericalDayOfMonthFromDayOfWeekName(DayOfWeekName.SATURDAY) || 0;
        }
        return calendarWeeks;
    }
}
