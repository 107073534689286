import { DeliveryId } from 'api/Ordering/model/DeliveryId';
import { PackagingId } from 'api/Product/model/PackagingId';
import { Product } from 'api/Product/model/Product';
import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { IQuantityInUnitJSONObject } from 'api/Product/serializer/IProductJSONObject';
import { TransferId } from 'api/Transfer/model/TransferId';
import { IProductCostJsonObject, IUsageCalculationParametersObject, IUsageDataJSONObject, IUsageJsonObject } from 'api/UsageData/serializer/IUsageDataJSONObject';
import { decimalToNumber, decimalUtils } from 'shared/utils/decimalUtils';

import { UsageCalculationParameters } from '../model/UsageCalculationParameters';
import { UsageData } from '../model/UsageData';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';
import { PrepEventId } from 'api/PrepEvent/model/PrepEventId';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';

export class UsageDataSerializer {

    public getUsageData(
        usageDataObject : {usage_by_product_id : object, usage_calculation_parameters : object },
        productsById : StringValueMap<ProductId, Product>
    ) : UsageData {
        const usageCalculationParametersObject : any = usageDataObject.usage_calculation_parameters;
        const bottleCountByProductIdByStorageAreaIdForStartingInventory : StringValueMap<ProductId, StringValueMap<StorageAreaId, QuantityInUnit<ProductQuantityUnit>>> = new StringValueMap();
        const bottleCountByProductIdByStorageAreaIdForEndingInventory : StringValueMap<ProductId, StringValueMap<StorageAreaId, QuantityInUnit<ProductQuantityUnit>>> = new StringValueMap();
        const bottleCountByProductIdByDeliveryId : StringValueMap<ProductId, StringValueMap<DeliveryId, QuantityInUnit<ProductQuantityUnit>>> = new StringValueMap();
        const bottleCountByProductIdByTransferId : StringValueMap<ProductId, StringValueMap<TransferId, QuantityInUnit<ProductQuantityUnit>>> = new StringValueMap();
        const inputBottleCountByProductIdByPrepEventId : StringValueMap<ProductId, StringValueMap<PrepEventId, QuantityInUnit<ProductQuantityUnit>>> = new StringValueMap();
        const outputBottleCountByProductIdByPrepEventId : StringValueMap<ProductId, StringValueMap<PrepEventId, QuantityInUnit<ProductQuantityUnit>>> = new StringValueMap();
        const bottleCostByProductId : StringValueMap<ProductId, ProductCost> = new StringValueMap();

        Object.keys(usageCalculationParametersObject.bottle_count_by_product_id_by_location_id_for_starting_inventory).map((productIdString : string) => {
            const productId = new ProductId(productIdString);
            const product = productsById.getRequired(productId);
            const bottleCountByStorageAreaIdData : any = usageCalculationParametersObject.bottle_count_by_product_id_by_location_id_for_starting_inventory[productIdString];
            const bottleCountByStorageAreaId : StringValueMap<StorageAreaId, QuantityInUnit<ProductQuantityUnit>> = new StringValueMap();
            Object.keys(bottleCountByStorageAreaIdData).map((StorageAreaIdString : string) => {
                bottleCountByStorageAreaId.set(
                    new StorageAreaId(StorageAreaIdString),
                    new QuantityInUnit(
                        bottleCountByStorageAreaIdData[StorageAreaIdString],
                        PackagingUtils.getContainerPackagingId(product.getPackagingsAndMappings().getPackaging()), // TODO Change when backend changes
                    )
                );
            });

            bottleCountByProductIdByStorageAreaIdForStartingInventory.set(new ProductId(productIdString), bottleCountByStorageAreaId);
        });

        Object.keys(usageCalculationParametersObject.bottle_count_by_product_id_by_location_id_for_ending_inventory).map((productIdString : string) => {
            const productId = new ProductId(productIdString);
            const product = productsById.getRequired(productId);
            const bottleCountByStorageAreaIdData : any = usageCalculationParametersObject.bottle_count_by_product_id_by_location_id_for_ending_inventory[productIdString];
            const bottleCountByStorageAreaId : StringValueMap<StorageAreaId, QuantityInUnit<ProductQuantityUnit>> = new StringValueMap();
            Object.keys(bottleCountByStorageAreaIdData).map((StorageAreaIdString : string) => {
                bottleCountByStorageAreaId.set(
                    new StorageAreaId(StorageAreaIdString),
                    new QuantityInUnit(
                        bottleCountByStorageAreaIdData[StorageAreaIdString],
                        PackagingUtils.getContainerPackagingId(product.getPackagingsAndMappings().getPackaging()), // TODO Change when backend changes
                    )
                );
            });

            bottleCountByProductIdByStorageAreaIdForEndingInventory.set(new ProductId(productIdString), bottleCountByStorageAreaId);
        });

        Object.keys(usageCalculationParametersObject.bottle_count_by_product_id_by_delivery_id).map((productIdString : string) => {
            const productId = new ProductId(productIdString);
            const product = productsById.getRequired(productId);
            const bottleCountByDeliveryIdData : any = usageCalculationParametersObject.bottle_count_by_product_id_by_delivery_id[productIdString];
            const bottleCountByDeliveryId : StringValueMap<DeliveryId, QuantityInUnit<ProductQuantityUnit>> = new StringValueMap();
            Object.keys(bottleCountByDeliveryIdData).map((DeliveryIdString : string) => {
                bottleCountByDeliveryId.set(
                    new DeliveryId(DeliveryIdString),
                    new QuantityInUnit(
                        bottleCountByDeliveryIdData[DeliveryIdString],
                        PackagingUtils.getContainerPackagingId(product.getPackagingsAndMappings().getPackaging()), // TODO Change when backend changes
                    )
                );
            });

            bottleCountByProductIdByDeliveryId.set(new ProductId(productIdString), bottleCountByDeliveryId);
        });

        Object.keys(usageCalculationParametersObject.bottle_count_by_product_id_by_transfer_id).map((productIdString : string) => {
            const productId = new ProductId(productIdString);
            const product = productsById.getRequired(productId);
            const bottleCountByTransferIdData : any = usageCalculationParametersObject.bottle_count_by_product_id_by_transfer_id[productIdString];
            const bottleCountByTransferId : StringValueMap<TransferId, QuantityInUnit<ProductQuantityUnit>> = new StringValueMap();
            Object.keys(bottleCountByTransferIdData).map((TransferIdString : string) => {
                bottleCountByTransferId.set(
                    new TransferId(TransferIdString),
                    new QuantityInUnit(
                        bottleCountByTransferIdData[TransferIdString],
                        PackagingUtils.getContainerPackagingId(product.getPackagingsAndMappings().getPackaging()), // TODO Change when backend changes
                    )
                );
            });

            bottleCountByProductIdByTransferId.set(new ProductId(productIdString), bottleCountByTransferId);
        });

        Object.keys(usageCalculationParametersObject.input_bottle_count_by_product_id_by_prep_event_id).map((productIdString : string) => {
            const productId = new ProductId(productIdString);
            const product = productsById.getRequired(productId);
            const inputBottleCountByPrepEventIdData : any = usageCalculationParametersObject.input_bottle_count_by_product_id_by_prep_event_id[productIdString];
            const inputBottleCountByPrepEventId : StringValueMap<PrepEventId, QuantityInUnit<ProductQuantityUnit>> = new StringValueMap();
            Object.keys(inputBottleCountByPrepEventIdData).map((prepEventIdString : string) => {
                inputBottleCountByPrepEventId.set(
                    new PrepEventId(prepEventIdString),
                    new QuantityInUnit(
                        inputBottleCountByPrepEventIdData[prepEventIdString],
                        PackagingUtils.getContainerPackagingId(product.getPackagingsAndMappings().getPackaging()), // TODO Change when backend changes
                    )
                );
            });
            inputBottleCountByProductIdByPrepEventId.set(new ProductId(productIdString), inputBottleCountByPrepEventId);
        });

        Object.keys(usageCalculationParametersObject.output_bottle_count_by_product_id_by_prep_event_id).map((productIdString : string) => {
            const productId = new ProductId(productIdString);
            const product = productsById.getRequired(productId);
            const outputBottleCountByPrepEventIdData : any = usageCalculationParametersObject.output_bottle_count_by_product_id_by_prep_event_id[productIdString];
            const outputBottleCountByPrepEventId : StringValueMap<PrepEventId, QuantityInUnit<ProductQuantityUnit>> = new StringValueMap();
            Object.keys(outputBottleCountByPrepEventIdData).map((prepEventIdString : string) => {
                const prepEventId = new PrepEventId(prepEventIdString);
                outputBottleCountByPrepEventId.set(
                    prepEventId,
                    new QuantityInUnit(
                        outputBottleCountByPrepEventIdData[prepEventIdString],
                        PackagingUtils.getContainerPackagingId(product.getPackagingsAndMappings().getPackaging()), // TODO Change when backend changes
                    )
                );
            });

            outputBottleCountByProductIdByPrepEventId.set(productId, outputBottleCountByPrepEventId);
        });

        Object.keys(usageCalculationParametersObject.bottle_cost_by_product_id).map((productIdString : string) => {
            const productId = new ProductId(productIdString);
            const product = productsById.getRequired(productId);
            bottleCostByProductId.set(
                new ProductId(productIdString),
                new ProductCost(
                    decimalUtils.numberToDecimal(usageCalculationParametersObject.bottle_cost_by_product_id[productIdString]),
                    PackagingUtils.getContainerPackagingId(product.getPackagingsAndMappings().getPackaging()), // TODO Change when backend changes
                )
            );
        });

        const usageCalculationParameters : UsageCalculationParameters = new UsageCalculationParameters(
            bottleCountByProductIdByStorageAreaIdForStartingInventory,
            bottleCountByProductIdByStorageAreaIdForEndingInventory,
            bottleCountByProductIdByDeliveryId,
            bottleCountByProductIdByTransferId,
            inputBottleCountByProductIdByPrepEventId,
            outputBottleCountByProductIdByPrepEventId,
            bottleCostByProductId,
        );

        const usageByProductId : StringValueMap<ProductId, {unitUsage : QuantityInUnit<ProductQuantityUnit>, dollarUsage : number}> = new StringValueMap();
        Object.keys(usageDataObject.usage_by_product_id).map((productIdString : string) => {
            const productId = new ProductId(productIdString);
            const product = productsById.getRequired(productId);
            const usage : {bottle_usage : number, dollar_usage : number} = (usageDataObject.usage_by_product_id as any)[productIdString];
            usageByProductId.set(
                new ProductId(productIdString),
                {
                    unitUsage : new QuantityInUnit(
                        usage.bottle_usage,
                        PackagingUtils.getContainerPackagingId(product.getPackagingsAndMappings().getPackaging()), // TODO Change when backend changes
                    ),
                    dollarUsage : usage.dollar_usage }
            );
        });

        return new UsageData(usageCalculationParameters, usageByProductId);
    }

    public getJson(
        usageData : UsageData
    ) : IUsageDataJSONObject {
        const unitCountsByProductIdByStorageAreaIdForStartingInventory: { [productId : string] : { [storageAreaId : string] : IQuantityInUnitJSONObject } } = {};
        const unitCountsByProductIdByStorageAreaIdForEndingInventory: { [productId : string] : { [storageAreaId : string] : IQuantityInUnitJSONObject } } = {};
        const unitCountsByProductIdByDeliveryId: { [productId : string] : { [deliveryId : string] : IQuantityInUnitJSONObject } } = {};
        const unitCountsByProductIdByTransferId: { [productId : string] : { [transferId : string] : IQuantityInUnitJSONObject } } = {};
        const inputUnitCountsByProductIdByPrepEventId: { [productId : string] : { [prepEventId : string] : IQuantityInUnitJSONObject } } = {};
        const outputUnitCountsByProductIdByPrepEventId: { [productId : string] : { [prepEventId : string] : IQuantityInUnitJSONObject } } = {};
        const unitCostsByProductId: { [productId : string] : IProductCostJsonObject } = {};
        const usageByProductId : { [productId : string] : IUsageJsonObject } = {};

        usageData.getUsageCalculationParameters().getUnitCountsByProductIdByStorageAreaIdForStartingInventory().forEach((productCountForStorageAreaIds, productId) => {
            const productCountByStorageAreaId : { [storageAreaId : string] : IQuantityInUnitJSONObject } = {};
            productCountForStorageAreaIds.forEach((productCount, storageAreaId) => {
                const unit = productCount.getUnit();
                productCountByStorageAreaId[storageAreaId.getValue()] = {
                    quantity: productCount.getQuantity(),
                    product_quantity_unit: (unit instanceof PackagingId ? unit.getValue() : unit)
                };
            });
            unitCountsByProductIdByStorageAreaIdForStartingInventory[productId.getValue()] = productCountByStorageAreaId;
        });
        usageData.getUsageCalculationParameters().getUnitCountsByProductIdByStorageAreaIdForEndingInventory().forEach((productCountForStorageAreaIds, productId) => {
            const productCountByStorageAreaId : { [storageAreaId : string] : IQuantityInUnitJSONObject } = {};
            productCountForStorageAreaIds.forEach((productCount, storageAreaId) => {
                const unit = productCount.getUnit();
                productCountByStorageAreaId[storageAreaId.getValue()] = {
                    quantity: productCount.getQuantity(),
                    product_quantity_unit: (unit instanceof PackagingId ? unit.getValue() : unit)
                };
            });
            unitCountsByProductIdByStorageAreaIdForEndingInventory[productId.getValue()] = productCountByStorageAreaId;
        });
        usageData.getUsageCalculationParameters().getUnitCountsByProductIdByDeliveryId().forEach((productCountForDeliveryIds, productId) => {
            const productCountByDeliveryId : { [deliveryId : string] : IQuantityInUnitJSONObject } = {};
            productCountForDeliveryIds.forEach((productCount, deliveryId) => {
                const unit = productCount.getUnit();
                productCountByDeliveryId[deliveryId.getValue()] = {
                    quantity: productCount.getQuantity(),
                    product_quantity_unit: (unit instanceof PackagingId ? unit.getValue() : unit)
                };
            });
            unitCountsByProductIdByDeliveryId[productId.getValue()] = productCountByDeliveryId;
        });
        usageData.getUsageCalculationParameters().getUnitCountsByProductIdByTransferId().forEach((productCountForTransferIds, productId) => {
            const productCountByTransferId : { [transferId : string] : IQuantityInUnitJSONObject } = {};
            productCountForTransferIds.forEach((productCount, transferId) => {
                const unit = productCount.getUnit();
                productCountByTransferId[transferId.getValue()] = {
                    quantity: productCount.getQuantity(),
                    product_quantity_unit: (unit instanceof PackagingId ? unit.getValue() : unit)
                };
            });
            unitCountsByProductIdByTransferId[productId.getValue()] = productCountByTransferId;
        });
        usageData.getUsageCalculationParameters().getInputUnitCountsByProductIdByPrepEventId().forEach((productCountForPrepIds, productId) => {
            const productCountByPrepId : { [prepEventId : string] : IQuantityInUnitJSONObject } = {};
            productCountForPrepIds.forEach((productCount, prepId) => {
                const unit = productCount.getUnit();
                productCountByPrepId[prepId.getValue()] = {
                    quantity: productCount.getQuantity(),
                    product_quantity_unit: (unit instanceof PackagingId ? unit.getValue() : unit)
                };
            });
            inputUnitCountsByProductIdByPrepEventId[productId.getValue()] = productCountByPrepId;
        });
        usageData.getUsageCalculationParameters().getOutputUnitCountsByProductIdByPrepEventId().forEach((productCountForPrepIds, productId) => {
            const productCountByPrepId : { [prepEventId : string] : IQuantityInUnitJSONObject } = {};
            productCountForPrepIds.forEach((productCount, prepId) => {
                const unit = productCount.getUnit();
                productCountByPrepId[prepId.getValue()] = {
                    quantity: productCount.getQuantity(),
                    product_quantity_unit: (unit instanceof PackagingId ? unit.getValue() : unit)
                };
            });
            outputUnitCountsByProductIdByPrepEventId[productId.getValue()] = productCountByPrepId;
        });
        usageData.getUsageCalculationParameters().getUnitCostsByProductId().forEach((productCost, productId) => {
            const unit = productCost.getUnit();
            unitCostsByProductId[productId.getValue()] = {
                cost: decimalToNumber(productCost.getCost()),
                product_quantity_unit: (unit instanceof PackagingId ? unit.getValue() : unit)
            };
        });

        usageData.getUsageByProductId().forEach((usage, productId) => {
            const unit = usage.unitUsage.unit;
            usageByProductId[productId.getValue()] = {
                unit_usage: {
                    quantity: usage.unitUsage.getQuantity(),
                    product_quantity_unit: (unit instanceof PackagingId ? unit.getValue() : unit)
                },
                dollar_usage: usage.dollarUsage
            };
        });

        const usageCalculationParameters : IUsageCalculationParametersObject = {
            unit_counts_by_product_id_by_storage_area_id_for_starting_inventory: unitCountsByProductIdByStorageAreaIdForStartingInventory,
            unit_counts_by_product_id_by_storage_area_id_for_ending_inventory: unitCountsByProductIdByStorageAreaIdForEndingInventory,
            unit_counts_by_product_id_by_delivery_id: unitCountsByProductIdByDeliveryId,
            unit_counts_by_product_id_by_transfer_id: unitCountsByProductIdByTransferId,
            input_unit_counts_by_product_id_by_prep_event_id: inputUnitCountsByProductIdByPrepEventId,
            output_unit_counts_by_product_id_by_prep_event_id: outputUnitCountsByProductIdByPrepEventId,
            unit_costs_by_product_id: unitCostsByProductId
        };

        return {
            usage_calculation_parameters: usageCalculationParameters,
            usage_by_product_id: usageByProductId,
        };
    }
}
