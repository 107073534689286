import React from 'react';

import { StringValueMap } from 'api/Core/StringValueMap';
import { ProductQuickAdd } from 'api/Onboarding/model/ProductQuickAdd';
import { MassUnit } from 'api/Product/model/MassUnit';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import { oldPackagingUtils } from 'api/Product/utils/oldPackagingUtils';
import { UnitUtils } from 'api/Product/utils/UnitUtils';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemWithMetadata } from 'api/SalesItem/model/SalesItemWithMetadata';
import { SalesItemUtils } from 'api/SalesItem/utils/SalesItemUtils';

import { Button } from 'shared/components/Button';
import { ProductOptionRow } from 'shared/components/Product/ProductOptionRow';
import { SearchBarTheme } from 'shared/components/SearchBar/SearchBar';
import { SearchBarWithSuggestedOptions } from 'shared/components/SearchBar/SearchBarWithSuggestedOptions';
import { OptionsAndLabelNameTuples, Select2DropdownMenu } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { ValidationInput } from 'shared/components/ValidationInput';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { ISearchBarState } from 'shared/models/ISearchBarState';
import { FormatMonetaryValueWithCents } from 'shared/utils/FormatMonetaryValue';

import { IngredientFormFieldName, IngredientFormValidationByFieldName } from '../reducers/reducers';
import { CreateOrEditSalesItemFormUtils } from '../utils/CreateOrEditSalesItemFormUtils';

// CAS TODO: need to update this to have it's own css file, not great but these styles will work just fine in the meantime
import '../../../shared/components/Product/ProductSearchBar.scss';
import '../css/IngredientSearchBar.scss';
import { ProductCost } from 'api/Product/model/ProductCost';
import { ItemCardEvents } from 'apps/ItemCard/ItemCard';
export interface IIngredientSearchBarProps {
    productsById : StringValueMap<ProductId, Product>;
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>;
    searchBarPlaceholderText : string;
    searchBarTheme : SearchBarTheme;
    labelNamesAndSortedProductIdsToDisplay : Array<[string, Array<ProductId>]>;
    labelNamesAndSortedSalesItemIdsToDisplay : Array<[string, Array<SalesItemId>]>;
    labelNamesAndSortedProductQuickAddItemsToDisplay : Array<[string, Array<ProductQuickAdd>]>;
    searchBar : ISearchBarState;
    setSearchTerm : (searchTerm : string | null) => void;
    highlightedIngredientId : ProductId | SalesItemId | ProductQuickAdd | null;
    setHighlightedIngredientId : (searchSuggestion : ProductId | SalesItemId | ProductQuickAdd | null) => void;
    dropdownIsShown : boolean;
    setIngredientDropdownIsShown : (isShown : boolean) => void;
    slimProductCreateIsShown : boolean;
    createNewSalesItemFieldsAreShown : boolean;
    setSlimCreateIsShown : (isShown : boolean) => void;
    setCreateNewSalesItemFieldsAreShown : (isShown : boolean) => void;
    onSelectIngredient : (ingredientId : ProductId | SalesItemId | ProductQuickAdd | ProductQuickAdd | null) => void;
    onSlimCreate : (productId : ProductId) => void;
    validationInputDataByFieldName : IngredientFormValidationByFieldName;
    onIngredientInfoFormFieldChange : (fieldName : IngredientFormFieldName, value : string) => void;
    showIngredientProductPrice : boolean;
    productCostsByProductId : StringValueMap<ProductId, ProductCost>;
}

export class IngredientSearchBar extends React.Component<IIngredientSearchBarProps, object> {
    private servingSizeAndYieldUnitDropdownOptions : OptionsAndLabelNameTuples;

    constructor(props : IIngredientSearchBarProps) {
        super(props);
        this.servingSizeAndYieldUnitDropdownOptions = CreateOrEditSalesItemFormUtils.getServingSizeAndYieldDropdownOptions();
    }

    public render() {
        const {
            searchBar,
            searchBarTheme,
            searchBarPlaceholderText,
            dropdownIsShown,
            labelNamesAndSortedProductIdsToDisplay,
            labelNamesAndSortedSalesItemIdsToDisplay,
            labelNamesAndSortedProductQuickAddItemsToDisplay,
            highlightedIngredientId,
            createNewSalesItemFieldsAreShown,
            validationInputDataByFieldName,
        } = this.props;

        const newSalesItemYieldUnitSelectedOption = this.servingSizeAndYieldUnitDropdownOptions[0][1].find((option) => {
            return validationInputDataByFieldName.newSalesItemYieldUnit.value === option.value;
        }) || null;
        const newSalesItemServingSizeUnitSelectedOption = this.servingSizeAndYieldUnitDropdownOptions[0][1].find((option) => {
            return validationInputDataByFieldName.newSalesItemServingSizeUnit.value === option.value;
        }) || null;

        const labelNamesAndSortedIngredientIds = labelNamesAndSortedProductIdsToDisplay.concat(
            labelNamesAndSortedSalesItemIdsToDisplay as any,
            labelNamesAndSortedProductQuickAddItemsToDisplay as any
        ); // TODO: make this not assy
        const newItemName = searchBar.searchTerm || 'New Item';
        return (
            <div className="ingredient-search-bar product-search-bar">
                <SearchBarWithSuggestedOptions
                    searchBar={ searchBar }
                    searchBarTheme={ searchBarTheme }
                    searchBarPlaceholderText={ searchBarPlaceholderText }
                    searchSuggestionListIsShown={ dropdownIsShown }
                    labelNamesAndSearchSuggestions={ labelNamesAndSortedIngredientIds }
                    highlightedSearchSuggestion={ highlightedIngredientId }
                    moreOptionsHeader={ null }
                    moreOptionsFooter={
                        <div className="more-options-button-container">
                            <Button
                                buttonClassName="primary flat capitalize medium with-icon"
                                isDisabled={ false }
                                isLoading={ false }
                                onClick={ this.onCreateNewProductClick }
                            >
                                <span className="bevicon bevico-add main-icon-left"/>
                                <span>Add { newItemName }</span>
                            </Button>
                        </div>
                    }
                    searchBarIsMobileComponent={ false }
                    setSearchSuggestionListIsShown={ this.setIngredientDropdownIsShown }
                    createSuggestionOptionElement={ this.createIngredientOptionRow }
                    onSearchBarEnterClick={ this.onSearchBarEnterClick }
                    onSearchTermValueChange={ this.onSearchTermValueChange }
                    onClearSearchTerm={ this.onClearSearchTerm }
                    onOptionClick={ this.onOptionClick }
                    setHighlightedSearchSuggestionKeyboard={ this.setHighlightedOptionKeyboard }
                    setHighlightedSearchSuggestionMouse={ this.setHighlightedOptionMouse }
                    closeMobileSearchBar={ this.doNothing }
                    dropdownRowClassName=""
                    dropdownContainerClassName="dropdown-inner-container"
                />
                { createNewSalesItemFieldsAreShown &&
                    <div className="slim-create-sales-item-container">
                        <div className="slim-create-sales-item-name">
                            <div className="label">New Sales Item Name</div>
                            <ValidationInput
                                type="text"
                                label={ null }
                                hintText=""
                                value={ validationInputDataByFieldName.newSalesItemName.value }
                                autoFocus={ false }
                                autoComplete={ null }
                                isValid={ validationInputDataByFieldName.newSalesItemName.isValid }
                                isDisabled={ false }
                                errorMessage={ validationInputDataByFieldName.newSalesItemName.errorMessage }
                                inputClassName=""
                                handleEnterClick={ null }
                                handleChange={ this.onNewSalesItemNameChange }
                                handleBlur={ null }
                                handleFocus={ null }
                            />
                        </div>
                        <div className="slim-create-sales-item-yield">
                            <div className="label">Yield</div>
                            <div className="yield-and-serving-size-input-fields input-group">
                                <ValidationInput
                                    type="number"
                                    step="any"
                                    label={ null }
                                    hintText=""
                                    value={ validationInputDataByFieldName.newSalesItemYieldAmount.value }
                                    autoFocus={ false }
                                    autoComplete={ null }
                                    isValid={ validationInputDataByFieldName.newSalesItemYieldAmount.isValid }
                                    isDisabled={ false }
                                    errorMessage={ validationInputDataByFieldName.newSalesItemYieldAmount.errorMessage }
                                    inputClassName=""
                                    handleEnterClick={ null }
                                    handleChange={ this.onNewSalesItemYieldAmountChange }
                                    handleBlur={ null }
                                    handleFocus={ null }
                                />
                                <Select2DropdownMenu
                                    onOptionSelect={ this.handleOnSelectNewSalesItemYieldUnit }
                                    sortedOptionsAndLabelName={ this.servingSizeAndYieldUnitDropdownOptions }
                                    selectedOption={ newSalesItemYieldUnitSelectedOption }
                                    placeholderText="Select Unit"
                                    hasCreateCustomOption={ false }
                                    createCustomOption={ null }
                                    buttonShouldDisplaySelectedLabel={ true }
                                    emptyState={ null }
                                    isSearchable={ false }
                                    selectedOptionHeaderShown={ false }
                                    customOptionGroupLabel={ null }
                                    createCustomOptionButtonLabel={ null }
                                />
                                { !validationInputDataByFieldName.newSalesItemYieldUnit.isValid &&
                                    <div className="error-text">
                                        { validationInputDataByFieldName.newSalesItemYieldUnit.errorMessage }
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="slim-create-sales-item-serving-size">
                            <div className="label">Serving Size</div>
                            <div className="yield-and-serving-size-input-fields input-group">
                                <ValidationInput
                                    type="number"
                                    step="any"
                                    label={ null }
                                    hintText=""
                                    value={ validationInputDataByFieldName.newSalesItemServingSizeAmount.value }
                                    autoFocus={ false }
                                    autoComplete={ null }
                                    isValid={ validationInputDataByFieldName.newSalesItemServingSizeAmount.isValid }
                                    isDisabled={ false }
                                    errorMessage={ validationInputDataByFieldName.newSalesItemServingSizeAmount.errorMessage }
                                    inputClassName=""
                                    handleEnterClick={ null }
                                    handleChange={ this.onNewSalesItemServingSizeAmountChange }
                                    handleBlur={ null }
                                    handleFocus={ null }
                                />
                                <Select2DropdownMenu
                                    onOptionSelect={ this.handleOnNewSalesItemSelectServingSizeUnit }
                                    sortedOptionsAndLabelName={ this.servingSizeAndYieldUnitDropdownOptions }
                                    selectedOption={ newSalesItemServingSizeUnitSelectedOption }
                                    placeholderText="Select Unit"
                                    hasCreateCustomOption={ false }
                                    createCustomOption={ null }
                                    buttonShouldDisplaySelectedLabel={ true }
                                    emptyState={ null }
                                    isSearchable={ false }
                                    selectedOptionHeaderShown={ false }
                                    customOptionGroupLabel={ null }
                                    createCustomOptionButtonLabel={ null }
                                />
                                { !validationInputDataByFieldName.newSalesItemServingSizeUnit.isValid &&
                                    <div className="error-text">
                                        { validationInputDataByFieldName.newSalesItemServingSizeUnit.errorMessage }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }

    private readonly doNothing = () => {
        // pass
    }
    private readonly onCreateNewProductClick = () : void => {
        this.props.setIngredientDropdownIsShown(false);
        this.props.setSlimCreateIsShown(true);
        ItemCardEvents.loadCreateItemModal({});
    }

    private readonly setIngredientDropdownIsShown = (isShown : boolean) => {
        this.props.setIngredientDropdownIsShown(isShown);
    }
    private readonly createIngredientOptionRow = (ingredientId : ProductId | SalesItemId | ProductQuickAdd | ProductQuickAdd) => {
        const {
            highlightedIngredientId,
            productsById,
            salesItemsById,
            showIngredientProductPrice,
            productCostsByProductId,
        } = this.props;

        let ingredient : Product | SalesItemWithMetadata | ProductQuickAdd | undefined;
        let productCost : ProductCost | undefined;
        if (ingredientId instanceof ProductId) {
            ingredient = productsById.get(ingredientId);
        } else if (ingredientId instanceof ProductQuickAdd) {
            ingredient = ingredientId;
        } else {
            ingredient = salesItemsById.get(ingredientId);
        }

        if (typeof ingredient === 'undefined') {
            throw new RuntimeException('unexpected');
        }

        const isHighlighted = ((highlightedIngredientId !== null) && highlightedIngredientId.equals(ingredientId));
        if (ingredient instanceof Product) {
            if (ingredientId instanceof ProductId) {
                productCost = productCostsByProductId.get(ingredientId);
            }
            if (typeof productCost === 'undefined') {
                throw new RuntimeException('unexpected');
            }
            return (
                <ProductOptionRow
                    key={ ingredientId.getValue() }
                    isAdded={ false }
                    productCost={ productCost }
                    product={ ingredient }
                    isActive={ true }
                    isHighlighted={ isHighlighted }
                    showPricePerLowestUnit={ showIngredientProductPrice }
                />
            );
        } else if (ingredient instanceof ProductQuickAdd) {
            const options = ingredient.getOptions();
            return (
                <div className={ `product-option-row ${ isHighlighted ? 'highlighted' : '' }` }>
                    <div className="row-content">
                        <div className="product-options-row-sales-item-name cell col-xs-7 col-sm-8">
                            <div className="product-brand-and-name ellipsis-out" title={ ingredient.getName() }>
                            <   span className="product-brand">
                                    { ingredient.getBrand() }
                                </span>
                                <span className="product-name">
                                    { ingredient.getName() }
                                </span>
                            </div>
                        </div>
                        <div className="product-option-row-packaging cell col-xs-5 col-sm-4 text-right">
                            { oldPackagingUtils.getDisplayTextForPackaging(options[0].packaging, true) }
                        </div>
                    </div>
                </div>
            );
        } else {
            const salesItem = ingredient.getSalesItem();
            const salesYield = salesItem.getItemYield();
            const salesYieldUnit = salesYield.getUnit();
            const servingsPerYield = SalesItemUtils.getServingsPerYield(salesItem.getServingSize(), salesYield);
            const pricePerServing = salesItem.getSalesPrice() / servingsPerYield;
            const salesServingSize = salesItem.getServingSize();
            const salesServingUnit = salesServingSize.getUnit();

            let pricePerServingDisplay : string;
            if (salesYieldUnit === null || salesServingUnit === null) {
                pricePerServingDisplay = `${ FormatMonetaryValueWithCents(pricePerServing) } / ${ salesItem.getServingSize().getUnit() || salesItem.getSalesItemCustomUnitName() }`;
            } else {
                const servingsPerOneYieldUnit = UnitUtils.convertUnitQuantity(new QuantityInUnit(salesServingSize.getQuantity(), salesServingUnit), salesYieldUnit);
                let convertUnit : VolumeUnit.OUNCE | MassUnit.DRY_OUNCE;
                if (VolumeUnit.isVolumeUnit(salesYieldUnit)) {
                    convertUnit = VolumeUnit.OUNCE;
                } else if (MassUnit.isMassUnit(salesYieldUnit)) {
                    convertUnit = MassUnit.DRY_OUNCE;
                } else {
                    throw new Error('unexpected value for yield unit');
                }

                const servingsPerOneYieldUnitInOz = UnitUtils.convertUnitQuantity(servingsPerOneYieldUnit, convertUnit);
                pricePerServingDisplay = `${ FormatMonetaryValueWithCents(pricePerServing / servingsPerOneYieldUnitInOz.getQuantity()) } / ${ convertUnit }`;
            }

            return (
                <div className={ `product-option-row ${ isHighlighted ? 'highlighted' : '' }` }>
                    <div className="row-content">
                        <div className="product-options-row-sales-item-name cell col-xs-7 col-sm-8">
                            { ingredient.getSalesItem().getName() }
                        </div>
                        <div className="product-option-row-packaging cell col-xs-5 col-sm-4 text-right">
                            { salesYield.getQuantity() } { salesYield.getUnit() ? salesYield.getUnit() : salesItem.getSalesItemCustomUnitName() }
                            <div>
                                { pricePerServingDisplay }
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    private readonly onSearchBarEnterClick = (searchTerm : string) => {
        const {
            highlightedIngredientId,
            onSelectIngredient,
        } = this.props;

        if (highlightedIngredientId !== null) {
            onSelectIngredient(highlightedIngredientId);
        }
    }

    private readonly onSearchTermValueChange = (searchTerm : string) => {
        this.props.setIngredientDropdownIsShown(true);
        this.props.setSearchTerm(searchTerm);
    }

    private readonly onClearSearchTerm = () => {
        this.props.setSearchTerm(null);
    }

    private readonly onOptionClick = (ingredientId : ProductId | SalesItemId | ProductQuickAdd) => {
        this.props.onSelectIngredient(ingredientId);
        this.props.setCreateNewSalesItemFieldsAreShown(false); // reset if necessary
    }

    private readonly setHighlightedOptionKeyboard = (ingredientId : ProductId | SalesItemId | ProductQuickAdd | null) => {
        this.props.setHighlightedIngredientId(ingredientId);
    }

    private readonly setHighlightedOptionMouse = (ingredientId : ProductId | SalesItemId | ProductQuickAdd | null) => {
        this.props.setHighlightedIngredientId(ingredientId);
    }

    private readonly onNewSalesItemYieldAmountChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.onIngredientInfoFormFieldChange('newSalesItemYieldAmount', event.currentTarget.value);
    }

    private readonly handleOnSelectNewSalesItemYieldUnit = (selectedUnitValue : string) => {
        this.props.onIngredientInfoFormFieldChange('newSalesItemYieldUnit', selectedUnitValue);
    }

    private readonly onNewSalesItemServingSizeAmountChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.onIngredientInfoFormFieldChange('newSalesItemServingSizeAmount', event.currentTarget.value);
    }

    private readonly handleOnNewSalesItemSelectServingSizeUnit = (selectedUnitValue : string) => {
        this.props.onIngredientInfoFormFieldChange('newSalesItemServingSizeUnit', selectedUnitValue);
    }

    private readonly onNewSalesItemNameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.onIngredientInfoFormFieldChange('newSalesItemName', event.currentTarget.value);
    }
}
