import React from 'react';

import { Dialog } from 'shared/components/Dialog';
import { IModalButton } from 'shared/models/Modal';

export interface IRemoveStorageAreaConfirmDialogProps {
    readonly onDeleteConfirmClick : () => void;
    readonly onCancelClick : () => void;
}

export class RemoveStorageAreaConfirmDialog extends React.Component<IRemoveStorageAreaConfirmDialogProps, object> {

    public render() {
        const {
            onDeleteConfirmClick,
            onCancelClick,
        } = this.props;

        const headerText = 'Delete Storage Area?';

        const buttons : Array<IModalButton> = [
            {
                onClick: onCancelClick,
                classes: 'primary flat',
                isDisabled: false,
                isLoading: false,
                children: 'Cancel',
            },
            {
                onClick: onDeleteConfirmClick,
                classes: 'primary danger flat',
                isDisabled: false,
                isLoading: false,
                children: 'Delete',
            },
        ];

        return (
            <Dialog
                className="remove-storage-area-confirm-dialog"
                buttons={ buttons }
                headerText={ headerText }
            >
                <p>
                    Are you sure you want to delete this storage area? All items and counts will be lost.
                </p>
            </Dialog>
        );
    }
}
