import React from 'react';

import { DateTime } from '../../models/DateTime';
import { MonthOfYear } from '../../models/MonthOfYear';

import { BevSpotDatePickerUtils } from './utils';
export interface IBevSpotDatePickerMonthSelector {
    dateTime : DateTime;
    setVisibleMonth : (visibleMonth : MonthOfYear) => void;
    setVisibleYear : (visibleYear : number) => void;
    visibleMonth : MonthOfYear;
    visibleYear : number;
}
export class BevSpotDatePickerMonthSelector extends React.Component<IBevSpotDatePickerMonthSelector, object> {
    public render() {
        const monthOfYear : string = BevSpotDatePickerUtils.getMonthAsStringFromMonthOfYear(this.props.visibleMonth);
        return (
            <div className="bevspot-datepicker-month-selector">
                <div className="pull-left decrement-month" onClick={ this.decrementMonth }>
                    <span className="bevicon bevico-keyboard-arrow-left" />
                </div>
                <div className="month-and-year">
                    { `${ monthOfYear } ${ this.props.visibleYear }` }
                </div>
                <div className="pull-right increment-month" onClick={ this.incrementMonth }>
                    <span className="bevicon bevico-keyboard-arrow-right" />
                </div>
            </div>
        );
    }
    private decrementMonth = () : void => {
        const {
            setVisibleMonth,
            setVisibleYear,
            visibleMonth,
            visibleYear,
        } = this.props;
        const decrementedMonth : MonthOfYear = BevSpotDatePickerUtils.decrementMonth(visibleMonth);
        setVisibleMonth(decrementedMonth);
        if (decrementedMonth === MonthOfYear.DECEMBER) {
            setVisibleYear(visibleYear - 1);
        }
    }
    private incrementMonth = () : void => {
        const {
            setVisibleMonth,
            setVisibleYear,
            visibleMonth,
            visibleYear,
        } = this.props;
        const incrementedMonth : MonthOfYear = BevSpotDatePickerUtils.incrementMonth(visibleMonth);
        setVisibleMonth(incrementedMonth);
        if (incrementedMonth === MonthOfYear.JANUARY) {
            setVisibleYear(visibleYear + 1);
        }
    }
}
