import CoreTimeModel from 'gen-thrift/core_time_Model_types';
import ProductModel from 'gen-thrift/product_Model_types';
import ProductCostService from 'gen-thrift/ProductCostService';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { InventoryCountId } from 'api/InventoryCount/model/InventoryCountId';
import { InventoryCountObjectToThriftSerializer } from 'api/InventoryCount/serializer/InventoryCountObjectToThriftSerializer';
import { LocationId } from 'api/Location/model/LocationId';
import { LocationObjectToThriftSerializer } from 'api/Location/serializer/LocationObjectToThriftSerializer';
import { IProductCostService } from 'api/Product/interfaces/IProductCostService';
import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductObjectToThriftSerializer } from 'api/Product/serializer/ProductObjectToThriftSerializer';
import { ProductThriftToObjectSerializer } from 'api/Product/serializer/ProductThriftToObjectSerializer';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { UserAccountObjectToThriftSerializer } from 'api/UserAccount/serializer/UserAccountObjectToThriftSerializer';

export class ProductCostServiceImpl implements IProductCostService {

    constructor(
        private productCostServiceClient : ProductCostService.ProductCostServiceClient,
        private userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer,
        private locationObjectToThriftSerializer : LocationObjectToThriftSerializer,
        private productObjectToThriftSerializer : ProductObjectToThriftSerializer,
        private productThriftToObjectSerializer : ProductThriftToObjectSerializer,
        private inventoryCountObjectToThriftSerializer : InventoryCountObjectToThriftSerializer,
    ) {}

    public setProductCostAtInventoryCount(
        userSessionId : UserSessionId,
        inventoryCountId : InventoryCountId,
        productId : ProductId,
        productCost : ProductCost,
        clientTimestamp : CoreTimeModel.TimestampWithMillisecondPrecision,
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.productCostServiceClient.setProductCostAtInventoryCount(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.productObjectToThriftSerializer.getThriftProductId(productId),
                this.inventoryCountObjectToThriftSerializer.getThriftInventoryCountId(inventoryCountId),
                this.productObjectToThriftSerializer.getThriftProductCost(productCost),
                clientTimestamp,
                (result : void | Error) => {
                     if (result instanceof Error) {
                         return reject(result);
                     }
                     return resolve();
                 }
            );
        });
    }

    public setCurrentProductCost(
        userSessionId : UserSessionId,
        productId : ProductId,
        productCost : ProductCost,
        clientTimestamp : CoreTimeModel.TimestampWithMillisecondPrecision,
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.productCostServiceClient.setCurrentProductCost(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.productObjectToThriftSerializer.getThriftProductId(productId),
                this.productObjectToThriftSerializer.getThriftProductCost(productCost),
                clientTimestamp,
                (result : void | Error) => {
                     if (result instanceof Error) {
                         return reject(result);
                     }
                     return resolve();
                 }
            );
        });
    }

    public getProductCostsByProductIdAtInventoryCount(
        userSessionId : UserSessionId,
        productIds : StringValueSet<ProductId>,
        inventoryCountId : InventoryCountId,
    ) : Promise<StringValueMap<ProductId, ProductCost>> {
        return new Promise<StringValueMap<ProductId, ProductCost>>((resolve, reject) => {
            this.productCostServiceClient.getProductCostsByProductIdAtInventoryCount(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.productObjectToThriftSerializer.getThriftProductIdsFromSet(productIds),
                this.inventoryCountObjectToThriftSerializer.getThriftInventoryCountId(inventoryCountId),
                (result : Array<ProductModel.ProductIdAndCost> | Error) => {
                     if (result instanceof Error) {
                         return reject(result);
                     }
                     return resolve(this.productThriftToObjectSerializer.getCostByProductId(result));
                 }
            );
        });
    }

    public getCurrentProductCostsByProductId(
        userSessionId : UserSessionId,
        productIds : StringValueSet<ProductId>,
    ) : Promise<StringValueMap<ProductId, ProductCost>> {
        return new Promise<StringValueMap<ProductId, ProductCost>>((resolve, reject) => {
            this.productCostServiceClient.getCurrentProductCostsByProductId(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.productObjectToThriftSerializer.getThriftProductIdsFromSet(productIds),
                (result : Array<ProductModel.ProductIdAndCost> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve(this.productThriftToObjectSerializer.getCostByProductId(result));
                }
            );
        });
    }

    public getProductCostsByProductIdAtTime(
        userSessionId : UserSessionId,
        productIds : StringValueSet<ProductId>,
        locationId : LocationId,
        time : CoreTimeModel.TimestampWithMillisecondPrecision,
    ) : Promise<StringValueMap<ProductId, ProductCost>> {
        if (productIds.size === 0) {
            return Promise.resolve(new StringValueMap());
        }
        return new Promise<StringValueMap<ProductId, ProductCost>>((resolve, reject) => {
            this.productCostServiceClient.getProductCostsByProductIdAtTime(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.productObjectToThriftSerializer.getThriftProductIdsFromSet(productIds),
                time,
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                (result : Array<ProductModel.ProductIdAndCost> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve(this.productThriftToObjectSerializer.getCostByProductId(result));
                }
            );
        });
    }
}
