export class IncidentBannerData {

    constructor (
        private readonly message : string | null,
        private readonly isShown : boolean
    ) {
    }

    public getMessage() : string | null {
        return this.message;
    }

    public getIsShown() : boolean {
        return this.isShown;
    }
}
