import React from 'react';

import { Product } from 'api/Product/model/Product';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';

import { FormatMonetaryValueWithCents } from 'shared/utils/FormatMonetaryValue';

import './ProductOptionRow.scss';
import { productCostUtils } from 'api/Product/utils/productCostUtils';
import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductName } from '../ProductName/ProductName';

export interface IProductOptionRowProps {
    readonly product : Product;
    readonly isAdded : boolean;
    readonly isActive : boolean;
    readonly isHighlighted : boolean;
    readonly showPricePerLowestUnit : boolean;
    readonly productCost? : ProductCost;
}

export class ProductOptionRow extends React.Component<IProductOptionRowProps, object> {

    public render() {
        const {
            product,
            isAdded,
            isActive,
            isHighlighted,
            productCost,
            showPricePerLowestUnit,
        } = this.props;

        let ingredientCost : string | undefined;

        if (showPricePerLowestUnit && productCost) {
            const baseUnit = product.getPackagingsAndMappings().getConversions().getBaseUnit();
        
            const productQuantityUnitDisplayText = PackagingUtils.getPackagingDisplayTextForProductQuantityUnit(product.getPackagingsAndMappings(), baseUnit, false);
            const costDisplayInfo = productCostUtils.getCostInUnit(productCost, baseUnit, product.getPackagingsAndMappings());
    
            ingredientCost = `${ FormatMonetaryValueWithCents(costDisplayInfo) } / ${ productQuantityUnitDisplayText }`;
        }

        return (
            <div
                className={ `product-option-row ${ isHighlighted ? 'highlighted' : '' } ${ isAdded ? 'added' : '' } ${ isActive ? '' : 'archived' }` }
            >
                <div className="row-content">
                    <div className="product-option-row-brand-and-name cell col-xs-9">
                        <ProductName 
                            product={ product } 
                            sku={ !window.GLOBAL_FEATURE_ACCESS.multi_vendor ? product.getSku() : null }
                        />
                    </div>
                    <div  className="product-option-row-packaging cell col-xs-3 text-right">
                        { showPricePerLowestUnit &&
                            <div className="ellipsis-out">
                                { `${ ingredientCost }` }
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
