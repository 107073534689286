import { GroupId } from './GroupId';
import { LocationId } from './LocationId';

export enum ScopeType {
    'GLOBAL',
    'GROUP',
    'RETAILER',
}

export class Scope {
    public static createRetailerScope(locationId : LocationId) {
        return new Scope(ScopeType.RETAILER, locationId);
    }

    public static createGroupScope(groupId : GroupId) {
        return new Scope(ScopeType.GROUP, groupId);
    }

    public static createGlobalScope() {
        return Scope.GLOBAL;
    }

    private static readonly GLOBAL = new Scope(ScopeType.GLOBAL, null);
    private constructor(private readonly type : ScopeType, private readonly id : LocationId | GroupId | null) {}

    public getType() {
        return this.type;
    }

    public getId() {
        return this.id;
    }
}
