//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';
import CoreMathModel from './core_math_Model_types';
import MoneyModel from './money_Model_types';



var DrinkPriceToolModel = {};
export default DrinkPriceToolModel;
DrinkPriceToolModel.DrinkCategory = {
  '0' : 'COCKTAIL',
  'COCKTAIL' : 0,
  '1' : 'DRAUGHT',
  'DRAUGHT' : 1,
  '2' : 'BY_GLASS',
  'BY_GLASS' : 2,
  '3' : 'SINGLE_SERVE',
  'SINGLE_SERVE' : 3
};
DrinkPriceToolModel.DrinkUnit = {
  '0' : 'METRIC_LITER',
  'METRIC_LITER' : 0,
  '1' : 'METRIC_MILLILITER',
  'METRIC_MILLILITER' : 1,
  '2' : 'US_FLUID_OUNCE',
  'US_FLUID_OUNCE' : 2,
  '3' : 'US_FLUID_GALLON',
  'US_FLUID_GALLON' : 3,
  '4' : 'DASH',
  'DASH' : 4,
  '5' : 'BAR_SPOON',
  'BAR_SPOON' : 5,
  '6' : 'UNIT',
  'UNIT' : 6
};
DrinkPriceToolModel.ShareMedium = {
  '0' : 'FACEBOOK',
  'FACEBOOK' : 0,
  '1' : 'TWITTER',
  'TWITTER' : 1,
  '2' : 'LINKED_IN',
  'LINKED_IN' : 2,
  '3' : 'EMAIL',
  'EMAIL' : 3
};
DrinkPriceToolModel.DrinkId = function(args) {
  this.value = null;
  if (args) {
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field value is unset!');
    }
  }
};
DrinkPriceToolModel.DrinkId.prototype = {};
DrinkPriceToolModel.DrinkId.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolModel.DrinkId.prototype.write = function(output) {
  output.writeStructBegin('DrinkId');
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 1);
    output.writeString(this.value);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolModel.QuantityAndUnit = function(args) {
  this.quantity = null;
  this.unit = null;
  if (args) {
    if (args.quantity !== undefined && args.quantity !== null) {
      this.quantity = new CoreMathModel.Decimal(args.quantity);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field quantity is unset!');
    }
    if (args.unit !== undefined && args.unit !== null) {
      this.unit = args.unit;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field unit is unset!');
    }
  }
};
DrinkPriceToolModel.QuantityAndUnit.prototype = {};
DrinkPriceToolModel.QuantityAndUnit.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.quantity = new CoreMathModel.Decimal();
        this.quantity.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.unit = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolModel.QuantityAndUnit.prototype.write = function(output) {
  output.writeStructBegin('QuantityAndUnit');
  if (this.quantity !== null && this.quantity !== undefined) {
    output.writeFieldBegin('quantity', Thrift.Type.STRUCT, 1);
    this.quantity.write(output);
    output.writeFieldEnd();
  }
  if (this.unit !== null && this.unit !== undefined) {
    output.writeFieldBegin('unit', Thrift.Type.I32, 2);
    output.writeI32(this.unit);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolModel.DrinkIngredient = function(args) {
  this.name = null;
  this.amount = null;
  this.packageSize = null;
  this.packageCost = null;
  if (args) {
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    }
    if (args.amount !== undefined && args.amount !== null) {
      this.amount = new DrinkPriceToolModel.QuantityAndUnit(args.amount);
    }
    if (args.packageSize !== undefined && args.packageSize !== null) {
      this.packageSize = new DrinkPriceToolModel.QuantityAndUnit(args.packageSize);
    }
    if (args.packageCost !== undefined && args.packageCost !== null) {
      this.packageCost = new MoneyModel.MonetaryValue(args.packageCost);
    }
  }
};
DrinkPriceToolModel.DrinkIngredient.prototype = {};
DrinkPriceToolModel.DrinkIngredient.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.amount = new DrinkPriceToolModel.QuantityAndUnit();
        this.amount.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.packageSize = new DrinkPriceToolModel.QuantityAndUnit();
        this.packageSize.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.packageCost = new MoneyModel.MonetaryValue();
        this.packageCost.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolModel.DrinkIngredient.prototype.write = function(output) {
  output.writeStructBegin('DrinkIngredient');
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 1);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.amount !== null && this.amount !== undefined) {
    output.writeFieldBegin('amount', Thrift.Type.STRUCT, 2);
    this.amount.write(output);
    output.writeFieldEnd();
  }
  if (this.packageSize !== null && this.packageSize !== undefined) {
    output.writeFieldBegin('packageSize', Thrift.Type.STRUCT, 3);
    this.packageSize.write(output);
    output.writeFieldEnd();
  }
  if (this.packageCost !== null && this.packageCost !== undefined) {
    output.writeFieldBegin('packageCost', Thrift.Type.STRUCT, 4);
    this.packageCost.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolModel.Drink = function(args) {
  this.name = null;
  this.drinkCategory = null;
  this.ingredients = null;
  this.price = null;
  this.taxRate = null;
  this.note = null;
  if (args) {
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field name is unset!');
    }
    if (args.drinkCategory !== undefined && args.drinkCategory !== null) {
      this.drinkCategory = args.drinkCategory;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field drinkCategory is unset!');
    }
    if (args.ingredients !== undefined && args.ingredients !== null) {
      this.ingredients = Thrift.copyList(args.ingredients, [DrinkPriceToolModel.DrinkIngredient]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field ingredients is unset!');
    }
    if (args.price !== undefined && args.price !== null) {
      this.price = new MoneyModel.MonetaryValue(args.price);
    }
    if (args.taxRate !== undefined && args.taxRate !== null) {
      this.taxRate = new CoreMathModel.Decimal(args.taxRate);
    }
    if (args.note !== undefined && args.note !== null) {
      this.note = args.note;
    }
  }
};
DrinkPriceToolModel.Drink.prototype = {};
DrinkPriceToolModel.Drink.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.drinkCategory = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.ingredients = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new DrinkPriceToolModel.DrinkIngredient();
          elem6.read(input);
          this.ingredients.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.price = new MoneyModel.MonetaryValue();
        this.price.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.taxRate = new CoreMathModel.Decimal();
        this.taxRate.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.note = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolModel.Drink.prototype.write = function(output) {
  output.writeStructBegin('Drink');
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 1);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.drinkCategory !== null && this.drinkCategory !== undefined) {
    output.writeFieldBegin('drinkCategory', Thrift.Type.I32, 2);
    output.writeI32(this.drinkCategory);
    output.writeFieldEnd();
  }
  if (this.ingredients !== null && this.ingredients !== undefined) {
    output.writeFieldBegin('ingredients', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRUCT, this.ingredients.length);
    for (var iter7 in this.ingredients)
    {
      if (this.ingredients.hasOwnProperty(iter7))
      {
        iter7 = this.ingredients[iter7];
        iter7.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.price !== null && this.price !== undefined) {
    output.writeFieldBegin('price', Thrift.Type.STRUCT, 4);
    this.price.write(output);
    output.writeFieldEnd();
  }
  if (this.taxRate !== null && this.taxRate !== undefined) {
    output.writeFieldBegin('taxRate', Thrift.Type.STRUCT, 5);
    this.taxRate.write(output);
    output.writeFieldEnd();
  }
  if (this.note !== null && this.note !== undefined) {
    output.writeFieldBegin('note', Thrift.Type.STRING, 6);
    output.writeString(this.note);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DrinkPriceToolModel.DrinkIdAndDrink = function(args) {
  this.drinkId = null;
  this.drink = null;
  if (args) {
    if (args.drinkId !== undefined && args.drinkId !== null) {
      this.drinkId = new DrinkPriceToolModel.DrinkId(args.drinkId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field drinkId is unset!');
    }
    if (args.drink !== undefined && args.drink !== null) {
      this.drink = new DrinkPriceToolModel.Drink(args.drink);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field drink is unset!');
    }
  }
};
DrinkPriceToolModel.DrinkIdAndDrink.prototype = {};
DrinkPriceToolModel.DrinkIdAndDrink.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.drinkId = new DrinkPriceToolModel.DrinkId();
        this.drinkId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.drink = new DrinkPriceToolModel.Drink();
        this.drink.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DrinkPriceToolModel.DrinkIdAndDrink.prototype.write = function(output) {
  output.writeStructBegin('DrinkIdAndDrink');
  if (this.drinkId !== null && this.drinkId !== undefined) {
    output.writeFieldBegin('drinkId', Thrift.Type.STRUCT, 1);
    this.drinkId.write(output);
    output.writeFieldEnd();
  }
  if (this.drink !== null && this.drink !== undefined) {
    output.writeFieldBegin('drink', Thrift.Type.STRUCT, 2);
    this.drink.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

