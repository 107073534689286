import * as moment from 'moment-timezone';

import { InvoiceId } from 'api/Billing/model/InvoiceId';
import { SubscriptionId } from 'api/Billing/model/SubscriptionId';

export class BillingCharge {
    constructor (
        private readonly billingChargeId : string,
        private readonly subscriptionId : SubscriptionId | null,
        private readonly invoiceId : InvoiceId | null,
        private readonly amount : number,
        private readonly amountRefunded : number,
        private readonly statementDescriptor : string,
        private readonly chargeDate : moment.Moment,
        private readonly status : string,
        private readonly isPaid : boolean,
        private readonly invoiceProcessingMetadata : { // TODO future could make this more generic? or have different metadata types
            startDate : moment.Moment,
            endDate : moment.Moment,
        } | null,
    ) { }

    public getBillingChargeId() {
        return this.billingChargeId;
    }

    public getSubscriptionId() {
        return this.subscriptionId;
    }

    public getInvoiceId() {
        return this.invoiceId;
    }

    public getAmount() {
        return this.amount;
    }

    public getAmountRefunded() {
        return this.amountRefunded;
    }

    public getStatementDescriptor() {
        return this.statementDescriptor;
    }

    public getChargeDate() {
        return this.chargeDate;
    }

    public getStatus() {
        return this.status;
    }

    public getIsPaid() {
        return this.isPaid;
    }

    public getInvoiceProcessingMetadata() {
        return this.invoiceProcessingMetadata;
    }
}
