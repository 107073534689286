import moment from 'moment-timezone';

import { IStripeCustomerService } from '../interfaces/IStripeCustomerService';

import { BankAccount } from 'api/Billing/model/BankAccount';
import { BankAccountId } from 'api/Billing/model/BankAccountId';
import { BevSpotSubscription } from 'api/Billing/model/BevSpotSubscription';
import { BillingCharge } from 'api/Billing/model/BillingCharge';
import { BillingPlan } from 'api/Billing/model/BillingPlan';
import { BillingPlanId } from 'api/Billing/model/BillingPlanId';
import { CreditCard } from 'api/Billing/model/CreditCard';
import { CreditCardId } from 'api/Billing/model/CreditCardId';
import { InvoiceId } from 'api/Billing/model/InvoiceId';
import { SubscriptionId } from 'api/Billing/model/SubscriptionId';
import { StringValueMap } from 'api/Core/StringValueMap';

import { IDelinquentPaymentStatus, ILatestInvoiceData } from 'apps/Billing/reducers/PlansAndBillingReducers';

import { LocationId } from 'api/Location/model/LocationId';

import { BillingDataSerializer } from '../serializer/BillingDataSerializer';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { AjaxUtils } from 'shared/utils/ajaxUtils';

export class StripeCustomerServiceImpl implements IStripeCustomerService {
    private readonly billingDataSerializer : BillingDataSerializer;

    constructor (
        billingDataSerializer : BillingDataSerializer,
    ) {
        this.billingDataSerializer = billingDataSerializer;
    }

    // Consider having this return an object that matches what we have on the backend instead
    public getBillingAccountForRetailer(
        retailerId : LocationId
    ) : Promise<IStripeCustomerData> {

        const queryParameters = {
            retailer_id: retailerId.getValue(),
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:billing_customer'), queryParameters)
        .then((billingAccountResponse : IBillingAccountResponse) => {
            const userIsAdminOnBillingAccount = billingAccountResponse.user_is_admin_on_billing_account;

            const response = billingAccountResponse.billing_account;
            if (typeof (response || {}).billing_retailers_by_id !== 'object') {
                throw new RuntimeException('Invalid response structure for api:billing_customer');
            }

            // shared accross account
            const paymentSources = this.billingDataSerializer.getBankAccountsAndCreditCardsById(response.sources);
            const creditCardsById = paymentSources.creditCardsById;
            const bankAccountsById = paymentSources.bankAccountsById;

            let primaryPaymentSourceId : CreditCardId | BankAccountId | null;
            if (response.default_source) {
                const sourceAsCreditCard = new CreditCardId(response.default_source);
                if (creditCardsById.has(sourceAsCreditCard)) {
                    primaryPaymentSourceId = sourceAsCreditCard;
                } else {
                    primaryPaymentSourceId = new BankAccountId(response.default_source);
                }
            } else {
                primaryPaymentSourceId = null;
            }

            // retailer-specific pieces of billing account
            const customerSubscriptionInfoByRetailerId : StringValueMap<LocationId, SubscriptionId | IRetailerWithoutSubscriptionResponse> = new StringValueMap();
            const subscriptionsByIdForBillingAccount : StringValueMap<SubscriptionId, BevSpotSubscription> = new StringValueMap();
            const delinquentPaymentStatusByRetailerId : StringValueMap<LocationId, IDelinquentPaymentStatus | null> = new StringValueMap();
            const billingPlansByIdForAccount : StringValueMap<BillingPlanId, BillingPlan> = new StringValueMap();
            const retailerNamesById : StringValueMap<LocationId, string> = new StringValueMap();
            const retailerCountriesById : StringValueMap<LocationId, string> = new StringValueMap();

            const emailAddressForRetailer : string = response.billing_retailers_by_id[retailerId.getValue()].contact.email; // TODO this is silly maybe fix this
            const billingCustomerId : string = response.billing_retailers_by_id[retailerId.getValue()].stripe_id;

            Object.keys(response.billing_retailers_by_id).forEach((retailerIdValue) => {
                const retailerIdInAccount = new LocationId(retailerIdValue);

                const retailerObject = response.billing_retailers_by_id[retailerIdValue];
                if (typeof  (retailerObject || {}).delinquent_status === 'undefined') {
                    throw new RuntimeException('Invalid response structure for api:billing_customer');
                }

                const delinquentPaymentStatus = retailerObject.delinquent_status !== null ? {
                    gracePeriodEndDate: retailerObject.delinquent_status.grace_period_end_date === null ? null : moment.utc(retailerObject.delinquent_status.grace_period_end_date),
                    hasDelinquentPayment: retailerObject.delinquent_status.has_delinquent_payment,
                    shouldOverrideStripe: retailerObject.delinquent_status.should_override_stripe,
                } : null;
                delinquentPaymentStatusByRetailerId.set(retailerIdInAccount, delinquentPaymentStatus);

                const responseSubscriptionsByRetailerId : {[key : string] : Array<any>} = response.subscriptions_by_retailer_id;
                const subscriptionsForRetailer = (responseSubscriptionsByRetailerId[retailerIdValue] || []);
                if (subscriptionsForRetailer.length === 0) {
                    const retailerWithoutSubscriptionResponse : IRetailerWithoutSubscriptionResponse = {
                        isInFreeTrial: retailerObject.is_in_free_trial,
                    };
                    customerSubscriptionInfoByRetailerId.set(retailerIdInAccount, retailerWithoutSubscriptionResponse);
                } else {
                    const subscriptionAndPlan = this.billingDataSerializer.getSubscriptionData(subscriptionsForRetailer[0]); // TODOlater assume only 1 for now -- but possible to have many in the future
                    const subscriptionId = subscriptionAndPlan[0];
                    const subscription = subscriptionAndPlan[1];
                    const plan = subscriptionAndPlan[2];

                    customerSubscriptionInfoByRetailerId.set(retailerIdInAccount, subscriptionId);
                    billingPlansByIdForAccount.set(subscription.getPlanId(), plan);
                    subscriptionsByIdForBillingAccount.set(subscriptionId, subscription);
                }

                retailerNamesById.set(retailerIdInAccount, retailerObject.name);
                retailerCountriesById.set(retailerIdInAccount, retailerObject.address.country);
            });

            const billingAccountData : IStripeCustomerData = {
                primaryPaymentSourceId,
                creditCardsById,
                bankAccountsById,
                customerSubscriptionInfoByRetailerId,
                subscriptionsByIdForBillingAccount,
                delinquentPaymentStatusByRetailerId,
                billingPlansByIdForAccount,
                emailAddressForRetailer,
                retailerNamesById,
                retailerCountriesById,
                billingCustomerId,
                userIsAdminOnBillingAccount
            };
            return Promise.resolve(billingAccountData);
        });
    }

    public getLatestInvoiceForRetailer(retailerId : LocationId) : Promise<ILatestInvoiceData | null> {
        const queryParameters = {
            retailer_id: retailerId.getValue(),
            max_result_count: 1,
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:billing_invoice'), queryParameters)
        .then((response : Array<any>) => {
            if (response.length === 0) {
                return Promise.resolve(null);
            }
            const latestInvoiceResponse : any = response[0];
            const latestInvoice = this.billingDataSerializer.getInvoiceData(latestInvoiceResponse);

            return Promise.resolve(latestInvoice);
        });
    }

    public payInvoiceForCustomerWithPaymentMethod(
        locationId : LocationId,
        invoiceId : InvoiceId,
        paymentSourceId : CreditCardId | BankAccountId
    ) : Promise<ILatestInvoiceData> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
        };

        const requestBody = {
            source_id: paymentSourceId.getValue(),
            invoice_id: invoiceId.getValue(),
        };

        return AjaxUtils.ajaxPut(urlWithoutRetailerId('api:billing_invoice'), requestBody, queryParameters)
        .then((invoiceResponse : any) => {
            return this.billingDataSerializer.getInvoiceData(invoiceResponse);
        });
    }

    public getMostRecentChargesForCustomer(
        locationId : LocationId,
        maxResultCount : number
    ) : Promise<Array<BillingCharge>> {

        const queryParameters = {
            retailer_id: locationId.getValue(),
            max_result_count: maxResultCount,
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('customer_success:get_past_charges_for_retailer'), queryParameters)
        .then((pastChargesJson : Array<any>) => {
            const pastCharges : Array<BillingCharge> = [];

            pastChargesJson.forEach((chargeJson) => {
                const billingCharge = this.billingDataSerializer.getBillingCharge(chargeJson);
                pastCharges.push(billingCharge);
            });

            return pastCharges;
        });
    }

    public createNewInvoiceProcessingChargeForCustomer(
        locationId : LocationId,
        chargeAmountInCents : number,
        chargeDescription : string,
        startDateOfInvoices : moment.Moment,
        endDateOfInvoices : moment.Moment
    ) : Promise<void> { // This could return the updated charge...

        const queryParameters = {
            retailer_id: locationId.getValue(),
        };

        const requestBody = {
            amount_in_cents: chargeAmountInCents,
            charge_description: chargeDescription,
            start_date: startDateOfInvoices.unix(),
            end_date: endDateOfInvoices.unix(),
        };

        return AjaxUtils.ajaxPut(urlWithoutRetailerId('customer_success:create_charge_for_retailer'), requestBody, queryParameters);
    }
}

interface IBillingAccountResponse {
    billing_account : any;
    user_is_admin_on_billing_account : boolean;
}

export interface IRetailerWithoutSubscriptionResponse { // free trial OR just don't have their data in Stripe (usally the case with paying by ACH/Check)
    isInFreeTrial : boolean;
}

export interface IStripeCustomerData {
    primaryPaymentSourceId : CreditCardId | BankAccountId | null;
    creditCardsById : StringValueMap<CreditCardId, CreditCard>;
    bankAccountsById : StringValueMap<BankAccountId, BankAccount>;
    customerSubscriptionInfoByRetailerId : StringValueMap<LocationId, SubscriptionId | IRetailerWithoutSubscriptionResponse>; // TODOfuture: support multiple subscriptions per retailer
    subscriptionsByIdForBillingAccount : StringValueMap<SubscriptionId, BevSpotSubscription>;
    billingPlansByIdForAccount : StringValueMap<BillingPlanId, BillingPlan>;
    emailAddressForRetailer : string;
    delinquentPaymentStatusByRetailerId : StringValueMap<LocationId, IDelinquentPaymentStatus | null>;
    retailerNamesById : StringValueMap<LocationId, string>;
    retailerCountriesById : StringValueMap<LocationId, string>;

    billingCustomerId : string;

    userIsAdminOnBillingAccount : boolean; // So dirty... can we do something else with this?
}
