import { UserAccountIdAndTimestamp } from 'api/UserAccount/model/UserAccountIdAndTimestamp';
import { ProductCount } from './ProductCount';

export class ProductCountEvent {

    constructor (
        private readonly productCount : ProductCount,
        private readonly userAccountIdAndTimestamp : UserAccountIdAndTimestamp,
    ) { }

    public getProductCount() : ProductCount {
        return this.productCount;
    }

    public getUserAccountIdAndTimestamp() : UserAccountIdAndTimestamp {
        return this.userAccountIdAndTimestamp;
    }
}
