import { IOfflineQueueManager } from 'api/Core/OfflineQueueManager/interfaces/IOfflineQueueManager';
import { OfflineQueueState } from 'api/Core/OfflineQueueManager/model/OfflineQueueState';
import { refreshToken } from 'shared/global/refreshJWT';

import { IRestApiRejection } from 'shared/utils/ajaxUtils';

interface IServiceCallEntry {
    service : any;
    methodName : string;
    arguments : IArguments;
    methodClosure : () => Promise<void>;
}

const MINIMUM_OFFLINE_QUEUE_RETRY_TIMEOUT_IN_MILLISECONDS = 5000;
const MAXIMUM_OFFLINE_QUEUE_RETRY_TIMEOUT_IN_MILLISECONDS = 120000;

export class OfflineQueueManagerImpl implements IOfflineQueueManager {

    private retryTimeout : number;
    private retryTimeoutId : number | null;
    private serviceCallQueue = Array<IServiceCallEntry>();
    private isProcessingRequest = false;
    private queueState : OfflineQueueState = OfflineQueueState.ONLINE_WITH_EMPTY_QUEUE;
    private stateCallback : ((offlineState : OfflineQueueState) => void) | null;

    constructor() {
        this.retryTimeout = MINIMUM_OFFLINE_QUEUE_RETRY_TIMEOUT_IN_MILLISECONDS;
        this.retryTimeoutId = null;
        this.stateCallback = null;

        window.addEventListener('offline', (event) => {
            if (this.serviceCallQueue.length > 0) {
                this.setQueueState(OfflineQueueState.OFFLINE_WITH_ENQUEUED_ITEMS);
            } else {
                this.setQueueState(OfflineQueueState.OFFLINE_WITH_EMPTY_QUEUE);
            }
        });

        window.addEventListener('online', (event) => {
            if (this.serviceCallQueue.length > 0) {
                if (this.retryTimeoutId) {
                    window.clearTimeout(this.retryTimeoutId);
                    this.retryTimeoutId = null;
                    this.isProcessingRequest = false;
                }

                this.setQueueState(OfflineQueueState.ONLINE_AFTER_BEING_OFFLINE_WITH_ENQUEUED_ITEMS);
                // Attempt refresh of JWT token when coming back online
                Promise.resolve(refreshToken()).then(() => {
                    this.processServiceCallQueue();
                });
            } else {
                this.setQueueState(OfflineQueueState.ONLINE_WITH_EMPTY_QUEUE);
            }
        });
    }

    public registerOfflineStateCallback = (callback : (offlineState : OfflineQueueState) => void) => {
        this.stateCallback = callback;
    }

    public enqueueServiceCall = (serviceToCall : any, name : string, args : IArguments, method : () => Promise<void>) : Promise<void> => {
        this.serviceCallQueue.push({
            service: serviceToCall,
            methodName: name,
            arguments: args,
            methodClosure: method
        });

        if (this.queueState === OfflineQueueState.ONLINE_WITH_EMPTY_QUEUE) {
            this.setQueueState(OfflineQueueState.ONLINE_WITH_ENQUEUED_ITEMS);
        } else if (this.queueState === OfflineQueueState.OFFLINE_WITH_EMPTY_QUEUE) {
            this.setQueueState(OfflineQueueState.OFFLINE_WITH_ENQUEUED_ITEMS);
        }

        // TODO this return is not really right if the caller actually needs to depend on this promise
        return this.processServiceCallQueue();
    }

    private processServiceCallQueue = () : Promise<void> => {
        if (this.serviceCallQueue.length === 0) {
            return Promise.resolve();
        }

        if (this.isProcessingRequest) {
            return Promise.resolve();
        }

        this.isProcessingRequest = true;
        const serviceCallEntry = this.serviceCallQueue[0];

        return serviceCallEntry.methodClosure()
        .then(() => {
            this.serviceCallQueue.shift();
            this.isProcessingRequest = false;
            this.retryTimeout = MINIMUM_OFFLINE_QUEUE_RETRY_TIMEOUT_IN_MILLISECONDS;

            if (this.serviceCallQueue.length === 0) {
                this.setQueueState(OfflineQueueState.ONLINE_WITH_EMPTY_QUEUE);
                return;
            }

            if (this.queueState === OfflineQueueState.OFFLINE_WITH_ENQUEUED_ITEMS) {
                this.setQueueState(OfflineQueueState.ONLINE_AFTER_BEING_OFFLINE_WITH_ENQUEUED_ITEMS);
            }

            this.processServiceCallQueue();
        })
        .catch((result : IRestApiRejection | Error) => {
            const xhr = (result as any).xhr;

            if ((xhr && (xhr.status === 0) && (xhr.readyState === 4)) || (result.message === 'Unexpected status: 0')) {  // TODO We probably need to rethink how we define offline errors
                this.retryTimeout = Math.min(this.retryTimeout * 2, MAXIMUM_OFFLINE_QUEUE_RETRY_TIMEOUT_IN_MILLISECONDS); // Reducing the rate of retry with each subsequent failure
                this.setQueueState(OfflineQueueState.OFFLINE_WITH_ENQUEUED_ITEMS);

                this.retryTimeoutId = window.setTimeout(() => {
                    this.retryTimeoutId = null;
                    this.isProcessingRequest = false;
                    this.processServiceCallQueue();
                }, this.retryTimeout);
            } else {
                this.serviceCallQueue.shift(); // Removing the action from the queue
                this.isProcessingRequest = false;

                if (this.serviceCallQueue.length > 0) {
                    this.setQueueState(OfflineQueueState.ONLINE_WITH_ENQUEUED_ITEMS);
                    this.processServiceCallQueue();
                } else {
                    this.setQueueState(OfflineQueueState.ONLINE_WITH_EMPTY_QUEUE);
                }

                // TODO log serviceCallQueue
                throw result;
            }
        });
    }

    private setQueueState = (newState : OfflineQueueState) => {
        this.queueState = newState;

        if (!window.navigator.onLine) {
            window.onbeforeunload = () => {
                return true;
            };
        } else {
            window.onbeforeunload = null as any;
        }

        if (this.stateCallback !== null) {
            this.stateCallback(newState);
        }
    }
}
