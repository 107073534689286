import React from 'react';

import { PackagingsAndMappings } from 'api/Product/model/PackagingsAndMappings';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';

import { Popover } from 'shared/components/Popover/Popover';

export interface IPackagingProps {
    readonly packagingAndMappings : PackagingsAndMappings;
    readonly hasPackagingPopover : boolean;
}

interface IPackagingState {
    popoverIsShown : boolean;
}

export class Packaging extends React.Component<IPackagingProps, IPackagingState> {

    public constructor(props : IPackagingProps) {
        super(props);

        this.state = {
            popoverIsShown: false,
        };
    }

    public shouldComponentUpdate(nextProps : IPackagingProps, nextState : IPackagingState) {
        return (this.props.packagingAndMappings !== nextProps.packagingAndMappings) || (this.state.popoverIsShown !== nextState.popoverIsShown);
    }

    public render() {
        const {
            hasPackagingPopover,
            packagingAndMappings,
        } = this.props;

        const packagingData = packagingAndMappings.getNonDeletedPackagingData();

        const hasMultiplePackagings = (packagingData.length > 1);
        const packagingsElement = (
            <div className="ellipsis-out">
                { PackagingUtils.getDisplayTextForPackaging(packagingData[0].packaging, true) }{hasMultiplePackagings && <span> +{ packagingData.length - 1 }</span>}
            </div>
        );

        return (
            <div className="product-packaging">
                { (!hasPackagingPopover || !hasMultiplePackagings) && 
                    packagingsElement
                }
                { hasPackagingPopover && hasMultiplePackagings && 
                    <Popover
                        className="product-packaging-popover"
                        preferredPositionArray={ ['below', 'above', 'left', 'right'] }
                        showOnHover={ true }
                        popoverContentIsShown={ this.state.popoverIsShown }
                        setPopoverContentIsShown={ this.setPopoverContentIsShown }
                        anchorContent={ packagingsElement }
                        popoverContent={ (
                            <div className="flex-child">
                                {
                                    packagingData.map((data, index) => {
                                        return (
                                            <div key={ `${ index } ${ data.packaging.getPackagingId() } ` }>
                                                { PackagingUtils.getDisplayTextForPackaging(data.packaging, true) }
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        ) }
                    />
                }
            </div>
        );
    }

    private setPopoverContentIsShown = (isShown : boolean) => {
        this.setState({ popoverIsShown: isShown });
    }
}
