import CoreMathModel from 'gen-thrift/core_math_Model_types';
 // TODO Use different decimal

import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { decimalUtils } from 'shared/utils/decimalUtils';

export class ProductCost {
    constructor(
        private readonly cost : CoreMathModel.Decimal,
        private readonly unit : ProductQuantityUnit,
    ) {}

    public getCost() {
        return this.cost;
    }

    public getUnit() {
        return this.unit;
    }

    public equals(other : any) {
        if (!(other instanceof ProductCost)) {
            return false;
        }

        return (
            decimalUtils.isEqual(this.cost, other.getCost()) &&
            this.unit === other.getUnit()
        );
    }
}
