import { StringValueMap } from 'api/Core/StringValueMap';
import { Product } from 'api/Product/model/Product';
import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { PrepEventDisplayUtils } from 'apps/CreateOrEditPrepEvent/utils/PrepEventDisplayUtils';
import { numberUtils } from 'shared/utils/numberUtils';
import { SalesItem } from '../model/SalesItem';
import { SalesItemId } from '../model/SalesItemId';
import { SalesItemWithMetadata } from '../model/SalesItemWithMetadata';
import { SalesItemUtils } from './SalesItemUtils';

export interface IIngredientInformation {
    ingredientId : SalesItemId | ProductId;
    ingredientLabel : string;
    category : string;
    quantityAmount : number;
    quantityUnitLabel : string;
    costOfIngredient : number | null;
}
const getIngredientsDisplayInformationForSalesItem = (
    salesItem : SalesItem,
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>,
    productsById : StringValueMap<ProductId, Product>,
    productCostsById : StringValueMap<ProductId, ProductCost> | null,
    salesItemCostBySalesItemId : StringValueMap<SalesItemId, number | null> | null, // if null, cost is not relevant for use case
) : Array<IIngredientInformation> => {
    const ingredientInformationList : Array<IIngredientInformation> = [];

    // Products then sales item ingredients
    const productIngredients = salesItem.getComponentQuantityOfProductByProductId();
    productIngredients.forEach((quantityOfProduct, productId) => {
        const product = productsById.getRequired(productId);

        const costOfIngredient = productCostsById === null ? 0 : SalesItemUtils.getCostOfProductComponentInSalesItem(productId, quantityOfProduct, productsById, productCostsById);

        ingredientInformationList.push({
            ingredientId: productId,
            ingredientLabel: PrepEventDisplayUtils.getLineItemProductLabel(product),
            category: product.getProductCategoryId(),
            quantityAmount: quantityOfProduct.getQuantity(),
            quantityUnitLabel: PackagingUtils.getPackagingDisplayTextForProductQuantityUnit(product.getPackagingsAndMappings(), quantityOfProduct.getUnit(), numberUtils.isPlural(quantityOfProduct.getQuantity())),
            costOfIngredient,
        });
    });

    const salesItemIngredients = salesItem.getComponentServingsBySalesItemId();
    salesItemIngredients.forEach((componentServingsQuantity, componentSalesItemId) => {
        const componentSalesItem = salesItemsById.getRequired(componentSalesItemId);
        const costOfComponentSalesItem = salesItemCostBySalesItemId === null ? 0 : salesItemCostBySalesItemId.getRequired(componentSalesItemId);

        const costOfIngredient = SalesItemUtils.getCostOfComponentSalesItem(componentSalesItem.getSalesItem(), componentServingsQuantity, costOfComponentSalesItem);

        ingredientInformationList.push({
            ingredientId: componentSalesItemId,
            ingredientLabel: componentSalesItem.getSalesItem().getName(),
            category: 'recipe',
            quantityAmount: componentServingsQuantity,
            quantityUnitLabel: `serving${ componentServingsQuantity === 1 ? '' : 's'}`,
            costOfIngredient,
        });
    });

    return ingredientInformationList;
};

const getIngredientsDisplayStringForSalesItem = (
    ingredientsInformation : Array<IIngredientInformation>
) : string => {
    const ingredientsList : Array<string> = [];
    ingredientsInformation.forEach((ingredientInformation) => {
        const ingredientString = `${ ingredientInformation.ingredientLabel } (${ ingredientInformation.quantityAmount } ${ ingredientInformation.quantityUnitLabel })`;
        ingredientsList.push(ingredientString);
    });

    return ingredientsList.join(', ');
};

const getServingSizeDisplay = (product : Product, quantityOfProductInSalesItem : QuantityInUnit<ProductQuantityUnit>, isPlural : boolean) : string => {
    return PrepEventDisplayUtils.getLineItemQuantityLabel(quantityOfProductInSalesItem, product.getPackagingsAndMappings(), isPlural);
};

export const SalesItemDisplayUtils = {
    getIngredientsDisplayInformationForSalesItem,
    getIngredientsDisplayStringForSalesItem,
    getServingSizeDisplay
};
