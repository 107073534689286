import { StringValueMap } from 'api/Core/StringValueMap';
import { Address, IJSONAddress } from 'api/Distributor/model/Address';
import { DistributorAdjustment, IJSONDistributorAdjustment } from 'api/Distributor/model/DistributorAdjustment';
import { DistributorAdjustmentId } from 'api/Distributor/model/DistributorAdjustmentId';
import { DistributorRep, IJSONDistributorRep } from 'api/Distributor/model/DistributorRep';
import { PaymentTerm } from 'api/Distributor/model/PaymentTerm';
import { createDefaultDeliveryDays } from 'apps/Vendors/appTypes';

export interface IJSONExtraDistributorProperties {
    accountNumber? : string;
    officePhone? : string;
    website? : string;
    accountingId? : string;
    address? : IJSONAddress;
    deliveryDays? : Array<boolean>;
    caseMinimum? : number;
    dollarMinimum? : number;
    notes? : string;
    reps? : Record<string, IJSONDistributorRep>;
    adjustments?: Record<string, IJSONDistributorAdjustment>;
    paymentTerm?: string;
}

interface IDistributorRepJSONMap {
    [entry : string] : IJSONDistributorRep;
}

interface IDistributorAdjustmentJSONMap {
    [entry : string] : IJSONDistributorAdjustment;
}

export class ExtraDistributorProperties {

    public static createFromJSONObject(name : string, JSONObject : IJSONExtraDistributorProperties) : ExtraDistributorProperties {
        const reps = new Map<string, DistributorRep>();
        const adjustments = new StringValueMap<DistributorAdjustmentId, DistributorAdjustment>();

        if (typeof JSONObject.reps !== 'undefined') {
            const JSONReps : Record<string, IJSONDistributorRep> = JSONObject.reps;
            Object.keys(JSONObject.reps).forEach(
                (key) => reps.set(key, DistributorRep.createFromJSONObject(JSONReps[key])));
        }

        if (typeof JSONObject.adjustments !== 'undefined') {
            const JSONAdjustments : Record<string, IJSONDistributorAdjustment> = JSONObject.adjustments;
            Object.keys(JSONObject.adjustments).forEach(
                (key) => adjustments.set(new DistributorAdjustmentId(key), DistributorAdjustment.createFromJSONObject(JSONAdjustments[key])));
        }


        return new ExtraDistributorProperties(
            ExtraDistributorProperties.procDefault(JSONObject.accountNumber, ''),
            ExtraDistributorProperties.procDefault(JSONObject.officePhone, ''),
            ExtraDistributorProperties.procDefault(JSONObject.website, ''),
            ExtraDistributorProperties.procDefault(JSONObject.accountingId, ''),
            Address.createFromJSONObject(ExtraDistributorProperties.procDefault(JSONObject.address, {})),
            ExtraDistributorProperties.procDefault(JSONObject.deliveryDays, createDefaultDeliveryDays()),
            ExtraDistributorProperties.procDefault(JSONObject.caseMinimum, 0),
            ExtraDistributorProperties.procDefault(JSONObject.dollarMinimum, 0),
            ExtraDistributorProperties.procDefault(JSONObject.notes, ''),
            reps,
            adjustments,
            PaymentTerm.createFromJson(JSONObject.paymentTerm),
        );
    }

    public static createBlank() : ExtraDistributorProperties {
        return ExtraDistributorProperties.createFromJSONObject('', {});
    }

    public static copy(other : ExtraDistributorProperties) : ExtraDistributorProperties {
        return new ExtraDistributorProperties(
            other.accountNumber,
            other.officePhone,
            other.website,
            other.accountingId,
            Address.copy(other.address),
            [ ...other.deliveryDays ],
            other.caseMinimum,
            other.dollarMinimum,
            other.notes,
            new Map(other.reps),
            new StringValueMap(other.adjustments),
            other.paymentTerm
        );
    }

    private static procDefault<T>(value : T | undefined, defaultValue : T) : T {
        if (typeof value === 'undefined' || value === null) {
            return defaultValue;
        } else {
            return value;
        }
    }

    constructor(
        public readonly accountNumber : string,
        public readonly officePhone : string,
        public readonly website : string,
        public readonly accountingId : string,
        public readonly address : Address,
        public readonly deliveryDays : Array<boolean>,
        public readonly caseMinimum : number,
        public readonly dollarMinimum : number,
        public readonly notes : string,
        public readonly reps : Map<string, DistributorRep>,
        public readonly adjustments : StringValueMap<DistributorAdjustmentId, DistributorAdjustment>,
        public readonly paymentTerm : PaymentTerm,
    ) {
        // Make sure that delivery days is valid.
        this.deliveryDays = this.transferDeliveryDays(deliveryDays, 8);
    }

    public getRestFormData(formData : FormData) : FormData {
        const convertRepMapToJSON = (map : Map<string, DistributorRep>) : IDistributorRepJSONMap => {
            const result : IDistributorRepJSONMap = {};
            map.forEach((value, key) => result[key] = value.getJSONObject());
            return result;
        };

        const convertAdjustmentMapToJSON = (map : StringValueMap<DistributorAdjustmentId, DistributorAdjustment>) : IDistributorAdjustmentJSONMap => {
            const result : IDistributorAdjustmentJSONMap = {};
            map.forEach((value, key) => result[key.getValue()] = value.getJSONObject());
            return result;
        };

        formData.append(
            'extra_distributor_properties',
                JSON.stringify({
                    accountNumber : this.accountNumber,
                    officePhone : this.officePhone,
                    website : this.website,
                    accountingId : this.accountingId,
                    address : this.address,
                    deliveryDays : this.transferDeliveryDays(this.deliveryDays, 7),
                    caseMinimum : this.caseMinimum,
                    dollarMinimum : this.dollarMinimum,
                    notes : this.notes,
                    reps : convertRepMapToJSON(this.reps),
                    adjustments : convertAdjustmentMapToJSON(this.adjustments),
                    paymentTerm : this.paymentTerm.getValue()
                })
            );
        return formData;
    }

    private transferDeliveryDays(src : Array<boolean>, maxLen : number) : Array<boolean> {
        const result = new Array<boolean>(maxLen).fill(false);

        for (let i = 0; i < src.length; i++) {
            if (src[i]) {
                result[i] = true;
            }
        }

        return result;
    }
}
