import React from 'react';

import { ValidationInput, ValidationInputTheme } from 'shared/components/ValidationInput';

import './SearchBar.scss';

export enum SearchBarTheme {
    Default = ValidationInputTheme.Default,
    Boxed = 2,
    PreparedItemProductSearch = 3, // TODO: this will eventually be generic-ified, but for now is only used in one place
    Transparent = 4,
    Basic = ValidationInputTheme.Basic,
    BasicInverted = ValidationInputTheme.BasicInverted,
}

export interface ISearchBarBaseProps {
    placeholderText : string | null;
    value : string | null;
    isDisabled : boolean;
    isFocused : boolean;
    clearSearchBar : () => void;
    handleOnChange : (searchTerm : string) => void;
    handleEnterClick : (searchTerm : string) => void;
    handleFocus : ((searchTerm : string) => void) | null;
    handleBlur : (searchTerm : string) => void;
}

export interface ISearchBarProps extends ISearchBarBaseProps {
    theme : SearchBarTheme;
}

export class SearchBar extends React.Component<ISearchBarProps, object> {
    private searchBarElement : Element | undefined;

    constructor(props : ISearchBarProps) {
        super(props);
        this.generateThemeClasses = this.generateThemeClasses.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.enterClick = this.enterClick.bind(this);
        this.searchBarRef = this.searchBarRef.bind(this);
    }

    public render() {
        const {
            placeholderText,
            value,
            isDisabled,
            isFocused,
        } = this.props;

        const themeClasses = this.generateThemeClasses();

        return (
            <div
                className={ themeClasses }
                ref={ this.searchBarRef }
            >
                <div className="search-bar-container">
                    <span className="bevicon bevico-search" />
                    <ValidationInput
                        theme={ this.getInputTheme() }
                        type="text"
                        label={ null }
                        hintText={ placeholderText }
                        value={ (value !== null) ? value : '' }
                        autoFocus={ isFocused }
                        autoComplete={ null }
                        autoCorrect="off"
                        isValid={ true } // TODO: Would this ever be false?
                        errorMessage=""
                        inputClassName=""
                        handleChange={ this.onChange }
                        handleFocus={ this.onFocus }
                        handleBlur={ this.onBlur }
                        handleEnterClick={ this.enterClick }
                        isDisabled={ isDisabled }
                    />
                    { (value) &&
                        <span
                            className="bevicon bevico-remove-inverse"
                            onClick={ this.handleClearSearchBar }
                        />
                    }
                </div>
            </div>
        );
    }

    private getInputTheme() {
        if (this.props.theme === SearchBarTheme.Basic) {
            return ValidationInputTheme.Basic;
        }
        return ValidationInputTheme.Default;
    }

    private generateThemeClasses() {
        let themeClasses : string = 'search-bar';

        if (this.props.theme === SearchBarTheme.Boxed) {
            themeClasses += ' boxed';
        } else if (this.props.theme === SearchBarTheme.PreparedItemProductSearch) {
            themeClasses += ' prepared-item-product-search';
        } else if (this.props.theme === SearchBarTheme.Transparent) {
            themeClasses += ' boxed transparent';
        } else if (this.props.theme === SearchBarTheme.Basic) {
            themeClasses += ' theme-basic';
        }

        return themeClasses;
    }

    private onChange(event : React.ChangeEvent<HTMLInputElement>) {
        const input = event.currentTarget;
        this.props.handleOnChange(input.value);
    }

    private onFocus(event : React.FocusEvent<HTMLInputElement>) {
        const inputElement = event.currentTarget;
        window.requestAnimationFrame(() => {
            inputElement.setSelectionRange(0, 9999);
            if (this.searchBarElement) {
                this.searchBarElement.className = this.generateThemeClasses() + ' active';
            }
        });

        if (this.props.handleFocus) {
            this.props.handleFocus(inputElement.value);
        }
    }

    private onBlur(event : React.FocusEvent<HTMLInputElement>) {
        const input = event.currentTarget;
        if (this.searchBarElement) {
            this.searchBarElement.className = this.generateThemeClasses();
        }

        this.props.handleBlur(input.value);
    }

    private enterClick(event : React.KeyboardEvent<HTMLInputElement>) {
        const input = event.currentTarget;
        this.props.handleEnterClick(input.value);
    }

    private searchBarRef = (searchBarElement : HTMLDivElement) : void => {
        this.searchBarElement = searchBarElement;
    }

    private readonly handleClearSearchBar = (event : React.MouseEvent<HTMLElement>) => {
        this.props.clearSearchBar();
        event.stopPropagation();
    }
}
