import CoreApiExceptions from 'gen-thrift/core_api_Exceptions_types';
import IntegrationExceptions from 'gen-thrift/integration_Exception_types';
import moment from 'moment-timezone';

import IntegrationModel from 'gen-thrift/integration_Model_types';
import IntegrationService from 'gen-thrift/IntegrationService';
import SalesReportModel from 'gen-thrift/sales_report_model_types';

import { MomentObjectToThriftSerializer } from 'api/Core/serializer/MomentObjectToThriftSerializer';
import { StringValueMap } from 'api/Core/StringValueMap';
import { LocationId } from 'api/Location/model/LocationId';
import { LocationObjectToThriftSerializer } from 'api/Location/serializer/LocationObjectToThriftSerializer';
import { SalesEntry } from 'api/Reports/model/SalesEntry';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemThriftToObjectSerializer } from 'api/SalesItem/serializer/SalesItemThriftToObjectSerializer';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { UserAccountObjectToThriftSerializer } from 'api/UserAccount/serializer/UserAccountObjectToThriftSerializer';
import { decimalToNumber } from 'shared/utils/decimalUtils';
import { ISalesImportService } from '../interfaces/ISalesImportService';
import { PosItem } from '../model/PosItem';
import { PosItemId } from '../model/PosItemId';

export interface ISalesImportResult {
    dataEffectiveTime : moment.Moment | null;
    salesEntries : StringValueMap<SalesItemId, SalesEntry>;
    posItemsByPosId : StringValueMap<PosItemId, PosItem>;
    unmappedSalesEntries : StringValueMap<PosItemId, SalesEntry>;
    ignoredSalesEntries : StringValueMap<PosItemId, SalesEntry>;
}

export class SalesImportServiceImpl implements ISalesImportService {
    constructor(
        private readonly  thriftClient : IntegrationService.IntegrationServiceClient,
        private readonly userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer,
        private readonly locationObjectToThriftSerializer : LocationObjectToThriftSerializer,
        private readonly momentObjectToThriftSerializer : MomentObjectToThriftSerializer,
        private readonly salesItemThriftToObjectSerializer : SalesItemThriftToObjectSerializer,
    ) {}

    public getSalesDataInPeriod (
        userSessionId : UserSessionId,
        locationId : LocationId,
        periodStartInclusive : moment.Moment,
        periodEndExclusive : moment.Moment,
        includePosMenuData : boolean,
        requireRealtimePosData : boolean
    ) : Promise<ISalesImportResult | IntegrationExceptions.LocationDoesNotHaveIntegrationException | IntegrationExceptions.PosHistoryLimitExceededException | CoreApiExceptions.ServiceTemporarilyUnavailableException> {

        return new Promise((resolve, reject) => {
            this.thriftClient.importSalesEntriesViaIntegration(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                this.momentObjectToThriftSerializer.getThriftTimestampFromMoment(periodStartInclusive),
                this.momentObjectToThriftSerializer.getThriftTimestampFromMoment(periodEndExclusive),
                includePosMenuData,
                requireRealtimePosData,
                (result : IntegrationModel.SalesImportResult | Error) => {
                    if (result instanceof Error) {
                        if (result instanceof IntegrationExceptions.LocationDoesNotHaveIntegrationException
                            || result instanceof IntegrationExceptions.PosHistoryLimitExceededException
                            || result instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException
                        ) {
                            return resolve(result);
                        }
                        return reject(result);
                    }

                    const salesEntries = new StringValueMap<SalesItemId, SalesEntry>();
                    result.salesEntries.forEach((thriftSalesItemIdAndSalesEntry) => {
                        const salesItemId = this.salesItemThriftToObjectSerializer.getSalesItemId(thriftSalesItemIdAndSalesEntry.salesItemId);
                        const salesEntry = this.getSalesEntry(thriftSalesItemIdAndSalesEntry.salesEntry);
                        salesEntries.set(salesItemId, salesEntry);
                    });

                    const posItemsByPosId = new StringValueMap<PosItemId, PosItem>();
                    const unmappedSalesEntries = new StringValueMap<PosItemId, SalesEntry>();
                    const ignoredSalesEntries = new StringValueMap<PosItemId, SalesEntry>();
                    result.unmappedPosItemAndSalesEntries.forEach((thriftPosItemAndSalesEntry) => {
                        const salesEntry = this.getSalesEntry(thriftPosItemAndSalesEntry.salesEntry);
                        const posItem = this.getPosItem(thriftPosItemAndSalesEntry.posItem);
                        const posItemId = posItem.getPosItemId();

                        posItemsByPosId.set(posItemId, posItem);
                        unmappedSalesEntries.set(posItemId, salesEntry);
                    });
                    result.ignoredPosItemAndSalesEntries.forEach((thriftPosItemAndSalesEntry) => {
                        const salesEntry = this.getSalesEntry(thriftPosItemAndSalesEntry.salesEntry);
                        const posItem = this.getPosItem(thriftPosItemAndSalesEntry.posItem);
                        const posItemId = new PosItemId(thriftPosItemAndSalesEntry.posItem.posItemId);

                        posItemsByPosId.set(posItemId, posItem);
                        ignoredSalesEntries.set(posItemId, salesEntry);
                    });

                    const salesImportResult : ISalesImportResult = {
                        dataEffectiveTime: result.dataEffectiveTime ? moment(result.dataEffectiveTime.timeSinceUnixEpoch.value) : null,
                        salesEntries,
                        unmappedSalesEntries,
                        ignoredSalesEntries,
                        posItemsByPosId,
                    };

                    return resolve(salesImportResult);
                }
            );
        });
    }

    private getSalesEntry = (thriftSalesEntry : SalesReportModel.SalesEntry) => {
        return new SalesEntry(
            decimalToNumber(thriftSalesEntry.price), // java enforces that these are not nullable right now, which works for this context. may need to be adjusted in the future
            decimalToNumber(thriftSalesEntry.quantity),
            decimalToNumber(thriftSalesEntry.quantityAdjustment),
            decimalToNumber(thriftSalesEntry.salesAdjustment),
        );
    }

    private getPosItem = (thriftPosItem : IntegrationModel.PosItem) => {
        return new PosItem(
            new PosItemId(thriftPosItem.posItemId),
            thriftPosItem.name,
            thriftPosItem.menuGroup ? thriftPosItem.menuGroup : ''
        );
    }
}
