//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


import Thrift from 'thrift';
import CorePrimitivesModel from './core_primitives_Model_types';
import CoreApiExceptions from './core_api_Exceptions_types';
import AccountsModel from './accounts_Model_types';
import UserAccountsModel from './accounts_user_Model_types';
import AccountsExceptions from './accounts_Exceptions_types';
import UnauthenticatedUserSessionModel from './unauthenticated_user_session_Model_types';
import UnauthenticatedUserSessionExceptions from './unauthenticated_user_session_Exceptions_types';
import UserInfoExceptions from './user_info_Exceptions_types';

var UserAccountService = {};
export default UserAccountService;
//HELPER FUNCTIONS AND STRUCTURES

UserAccountService.UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmail_args = function(args) {
  this.unauthenticatedUserSessionId = null;
  this.firstName = null;
  this.lastName = null;
  this.emailAddress = null;
  this.optionalPhoneNumber = null;
  if (args) {
    if (args.unauthenticatedUserSessionId !== undefined && args.unauthenticatedUserSessionId !== null) {
      this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId(args.unauthenticatedUserSessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field unauthenticatedUserSessionId is unset!');
    }
    if (args.firstName !== undefined && args.firstName !== null) {
      this.firstName = args.firstName;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field firstName is unset!');
    }
    if (args.lastName !== undefined && args.lastName !== null) {
      this.lastName = args.lastName;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field lastName is unset!');
    }
    if (args.emailAddress !== undefined && args.emailAddress !== null) {
      this.emailAddress = new CorePrimitivesModel.EmailAddress(args.emailAddress);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field emailAddress is unset!');
    }
    if (args.optionalPhoneNumber !== undefined && args.optionalPhoneNumber !== null) {
      this.optionalPhoneNumber = new AccountsModel.OptionalPhoneNumber(args.optionalPhoneNumber);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field optionalPhoneNumber is unset!');
    }
  }
};
UserAccountService.UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmail_args.prototype = {};
UserAccountService.UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmail_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId();
        this.unauthenticatedUserSessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.firstName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.lastName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.emailAddress = new CorePrimitivesModel.EmailAddress();
        this.emailAddress.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.optionalPhoneNumber = new AccountsModel.OptionalPhoneNumber();
        this.optionalPhoneNumber.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserAccountService.UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmail_args.prototype.write = function(output) {
  output.writeStructBegin('UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmail_args');
  if (this.unauthenticatedUserSessionId !== null && this.unauthenticatedUserSessionId !== undefined) {
    output.writeFieldBegin('unauthenticatedUserSessionId', Thrift.Type.STRUCT, 1);
    this.unauthenticatedUserSessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.firstName !== null && this.firstName !== undefined) {
    output.writeFieldBegin('firstName', Thrift.Type.STRING, 2);
    output.writeString(this.firstName);
    output.writeFieldEnd();
  }
  if (this.lastName !== null && this.lastName !== undefined) {
    output.writeFieldBegin('lastName', Thrift.Type.STRING, 3);
    output.writeString(this.lastName);
    output.writeFieldEnd();
  }
  if (this.emailAddress !== null && this.emailAddress !== undefined) {
    output.writeFieldBegin('emailAddress', Thrift.Type.STRUCT, 4);
    this.emailAddress.write(output);
    output.writeFieldEnd();
  }
  if (this.optionalPhoneNumber !== null && this.optionalPhoneNumber !== undefined) {
    output.writeFieldBegin('optionalPhoneNumber', Thrift.Type.STRUCT, 5);
    this.optionalPhoneNumber.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserAccountService.UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmail_result = function(args) {
  this.success = null;
  this.serviceTemporarilyUnavailableException = null;
  this.rateLimitExceededException = null;
  this.unknownUnauthenticatedUserSessionIdException = null;
  this.firstNameInvalidStringLengthException = null;
  this.lastNameInvalidStringLengthException = null;
  this.unknownEmailAddressException = null;
  this.phoneNumberInvalidStringLengthException = null;
  this.emailAddressAlreadyAssociatedWithAnAccountException = null;
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException) {
    this.unknownUnauthenticatedUserSessionIdException = args;
    return;
  }
  if (args instanceof UserInfoExceptions.FirstNameInvalidStringLengthException) {
    this.firstNameInvalidStringLengthException = args;
    return;
  }
  if (args instanceof UserInfoExceptions.LastNameInvalidStringLengthException) {
    this.lastNameInvalidStringLengthException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownEmailAddressException) {
    this.unknownEmailAddressException = args;
    return;
  }
  if (args instanceof UserInfoExceptions.PhoneNumberInvalidStringLengthException) {
    this.phoneNumberInvalidStringLengthException = args;
    return;
  }
  if (args instanceof AccountsExceptions.EmailAddressAlreadyAssociatedWithAnAccountException) {
    this.emailAddressAlreadyAssociatedWithAnAccountException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new UserAccountsModel.UserAccountIdentifier(args.success);
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.unknownUnauthenticatedUserSessionIdException !== undefined && args.unknownUnauthenticatedUserSessionIdException !== null) {
      this.unknownUnauthenticatedUserSessionIdException = args.unknownUnauthenticatedUserSessionIdException;
    }
    if (args.firstNameInvalidStringLengthException !== undefined && args.firstNameInvalidStringLengthException !== null) {
      this.firstNameInvalidStringLengthException = args.firstNameInvalidStringLengthException;
    }
    if (args.lastNameInvalidStringLengthException !== undefined && args.lastNameInvalidStringLengthException !== null) {
      this.lastNameInvalidStringLengthException = args.lastNameInvalidStringLengthException;
    }
    if (args.unknownEmailAddressException !== undefined && args.unknownEmailAddressException !== null) {
      this.unknownEmailAddressException = args.unknownEmailAddressException;
    }
    if (args.phoneNumberInvalidStringLengthException !== undefined && args.phoneNumberInvalidStringLengthException !== null) {
      this.phoneNumberInvalidStringLengthException = args.phoneNumberInvalidStringLengthException;
    }
    if (args.emailAddressAlreadyAssociatedWithAnAccountException !== undefined && args.emailAddressAlreadyAssociatedWithAnAccountException !== null) {
      this.emailAddressAlreadyAssociatedWithAnAccountException = args.emailAddressAlreadyAssociatedWithAnAccountException;
    }
  }
};
UserAccountService.UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmail_result.prototype = {};
UserAccountService.UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmail_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new UserAccountsModel.UserAccountIdentifier();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownUnauthenticatedUserSessionIdException = new UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException();
        this.unknownUnauthenticatedUserSessionIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.firstNameInvalidStringLengthException = new UserInfoExceptions.FirstNameInvalidStringLengthException();
        this.firstNameInvalidStringLengthException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.lastNameInvalidStringLengthException = new UserInfoExceptions.LastNameInvalidStringLengthException();
        this.lastNameInvalidStringLengthException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownEmailAddressException = new AccountsExceptions.UnknownEmailAddressException();
        this.unknownEmailAddressException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.phoneNumberInvalidStringLengthException = new UserInfoExceptions.PhoneNumberInvalidStringLengthException();
        this.phoneNumberInvalidStringLengthException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.emailAddressAlreadyAssociatedWithAnAccountException = new AccountsExceptions.EmailAddressAlreadyAssociatedWithAnAccountException();
        this.emailAddressAlreadyAssociatedWithAnAccountException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserAccountService.UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmail_result.prototype.write = function(output) {
  output.writeStructBegin('UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmail_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 1);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 2);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownUnauthenticatedUserSessionIdException !== null && this.unknownUnauthenticatedUserSessionIdException !== undefined) {
    output.writeFieldBegin('unknownUnauthenticatedUserSessionIdException', Thrift.Type.STRUCT, 3);
    this.unknownUnauthenticatedUserSessionIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.firstNameInvalidStringLengthException !== null && this.firstNameInvalidStringLengthException !== undefined) {
    output.writeFieldBegin('firstNameInvalidStringLengthException', Thrift.Type.STRUCT, 4);
    this.firstNameInvalidStringLengthException.write(output);
    output.writeFieldEnd();
  }
  if (this.lastNameInvalidStringLengthException !== null && this.lastNameInvalidStringLengthException !== undefined) {
    output.writeFieldBegin('lastNameInvalidStringLengthException', Thrift.Type.STRUCT, 5);
    this.lastNameInvalidStringLengthException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownEmailAddressException !== null && this.unknownEmailAddressException !== undefined) {
    output.writeFieldBegin('unknownEmailAddressException', Thrift.Type.STRUCT, 6);
    this.unknownEmailAddressException.write(output);
    output.writeFieldEnd();
  }
  if (this.phoneNumberInvalidStringLengthException !== null && this.phoneNumberInvalidStringLengthException !== undefined) {
    output.writeFieldBegin('phoneNumberInvalidStringLengthException', Thrift.Type.STRUCT, 7);
    this.phoneNumberInvalidStringLengthException.write(output);
    output.writeFieldEnd();
  }
  if (this.emailAddressAlreadyAssociatedWithAnAccountException !== null && this.emailAddressAlreadyAssociatedWithAnAccountException !== undefined) {
    output.writeFieldBegin('emailAddressAlreadyAssociatedWithAnAccountException', Thrift.Type.STRUCT, 8);
    this.emailAddressAlreadyAssociatedWithAnAccountException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserAccountService.UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmailAndCreateSession_args = function(args) {
  this.unauthenticatedUserSessionId = null;
  this.firstName = null;
  this.lastName = null;
  this.emailAddress = null;
  this.optionalPhoneNumber = null;
  if (args) {
    if (args.unauthenticatedUserSessionId !== undefined && args.unauthenticatedUserSessionId !== null) {
      this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId(args.unauthenticatedUserSessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field unauthenticatedUserSessionId is unset!');
    }
    if (args.firstName !== undefined && args.firstName !== null) {
      this.firstName = args.firstName;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field firstName is unset!');
    }
    if (args.lastName !== undefined && args.lastName !== null) {
      this.lastName = args.lastName;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field lastName is unset!');
    }
    if (args.emailAddress !== undefined && args.emailAddress !== null) {
      this.emailAddress = new CorePrimitivesModel.EmailAddress(args.emailAddress);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field emailAddress is unset!');
    }
    if (args.optionalPhoneNumber !== undefined && args.optionalPhoneNumber !== null) {
      this.optionalPhoneNumber = new AccountsModel.OptionalPhoneNumber(args.optionalPhoneNumber);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field optionalPhoneNumber is unset!');
    }
  }
};
UserAccountService.UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmailAndCreateSession_args.prototype = {};
UserAccountService.UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmailAndCreateSession_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId();
        this.unauthenticatedUserSessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.firstName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.lastName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.emailAddress = new CorePrimitivesModel.EmailAddress();
        this.emailAddress.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.optionalPhoneNumber = new AccountsModel.OptionalPhoneNumber();
        this.optionalPhoneNumber.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserAccountService.UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmailAndCreateSession_args.prototype.write = function(output) {
  output.writeStructBegin('UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmailAndCreateSession_args');
  if (this.unauthenticatedUserSessionId !== null && this.unauthenticatedUserSessionId !== undefined) {
    output.writeFieldBegin('unauthenticatedUserSessionId', Thrift.Type.STRUCT, 1);
    this.unauthenticatedUserSessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.firstName !== null && this.firstName !== undefined) {
    output.writeFieldBegin('firstName', Thrift.Type.STRING, 2);
    output.writeString(this.firstName);
    output.writeFieldEnd();
  }
  if (this.lastName !== null && this.lastName !== undefined) {
    output.writeFieldBegin('lastName', Thrift.Type.STRING, 3);
    output.writeString(this.lastName);
    output.writeFieldEnd();
  }
  if (this.emailAddress !== null && this.emailAddress !== undefined) {
    output.writeFieldBegin('emailAddress', Thrift.Type.STRUCT, 4);
    this.emailAddress.write(output);
    output.writeFieldEnd();
  }
  if (this.optionalPhoneNumber !== null && this.optionalPhoneNumber !== undefined) {
    output.writeFieldBegin('optionalPhoneNumber', Thrift.Type.STRUCT, 5);
    this.optionalPhoneNumber.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserAccountService.UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmailAndCreateSession_result = function(args) {
  this.success = null;
  this.serviceTemporarilyUnavailableException = null;
  this.rateLimitExceededException = null;
  this.unknownUnauthenticatedUserSessionIdException = null;
  this.firstNameInvalidStringLengthException = null;
  this.lastNameInvalidStringLengthException = null;
  this.unknownEmailAddressException = null;
  this.phoneNumberInvalidStringLengthException = null;
  this.emailAddressAlreadyAssociatedWithAnAccountException = null;
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException) {
    this.unknownUnauthenticatedUserSessionIdException = args;
    return;
  }
  if (args instanceof UserInfoExceptions.FirstNameInvalidStringLengthException) {
    this.firstNameInvalidStringLengthException = args;
    return;
  }
  if (args instanceof UserInfoExceptions.LastNameInvalidStringLengthException) {
    this.lastNameInvalidStringLengthException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownEmailAddressException) {
    this.unknownEmailAddressException = args;
    return;
  }
  if (args instanceof UserInfoExceptions.PhoneNumberInvalidStringLengthException) {
    this.phoneNumberInvalidStringLengthException = args;
    return;
  }
  if (args instanceof AccountsExceptions.EmailAddressAlreadyAssociatedWithAnAccountException) {
    this.emailAddressAlreadyAssociatedWithAnAccountException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new UserAccountsModel.UserSessionIdentifier(args.success);
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.unknownUnauthenticatedUserSessionIdException !== undefined && args.unknownUnauthenticatedUserSessionIdException !== null) {
      this.unknownUnauthenticatedUserSessionIdException = args.unknownUnauthenticatedUserSessionIdException;
    }
    if (args.firstNameInvalidStringLengthException !== undefined && args.firstNameInvalidStringLengthException !== null) {
      this.firstNameInvalidStringLengthException = args.firstNameInvalidStringLengthException;
    }
    if (args.lastNameInvalidStringLengthException !== undefined && args.lastNameInvalidStringLengthException !== null) {
      this.lastNameInvalidStringLengthException = args.lastNameInvalidStringLengthException;
    }
    if (args.unknownEmailAddressException !== undefined && args.unknownEmailAddressException !== null) {
      this.unknownEmailAddressException = args.unknownEmailAddressException;
    }
    if (args.phoneNumberInvalidStringLengthException !== undefined && args.phoneNumberInvalidStringLengthException !== null) {
      this.phoneNumberInvalidStringLengthException = args.phoneNumberInvalidStringLengthException;
    }
    if (args.emailAddressAlreadyAssociatedWithAnAccountException !== undefined && args.emailAddressAlreadyAssociatedWithAnAccountException !== null) {
      this.emailAddressAlreadyAssociatedWithAnAccountException = args.emailAddressAlreadyAssociatedWithAnAccountException;
    }
  }
};
UserAccountService.UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmailAndCreateSession_result.prototype = {};
UserAccountService.UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmailAndCreateSession_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new UserAccountsModel.UserSessionIdentifier();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownUnauthenticatedUserSessionIdException = new UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException();
        this.unknownUnauthenticatedUserSessionIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.firstNameInvalidStringLengthException = new UserInfoExceptions.FirstNameInvalidStringLengthException();
        this.firstNameInvalidStringLengthException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.lastNameInvalidStringLengthException = new UserInfoExceptions.LastNameInvalidStringLengthException();
        this.lastNameInvalidStringLengthException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownEmailAddressException = new AccountsExceptions.UnknownEmailAddressException();
        this.unknownEmailAddressException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.phoneNumberInvalidStringLengthException = new UserInfoExceptions.PhoneNumberInvalidStringLengthException();
        this.phoneNumberInvalidStringLengthException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.emailAddressAlreadyAssociatedWithAnAccountException = new AccountsExceptions.EmailAddressAlreadyAssociatedWithAnAccountException();
        this.emailAddressAlreadyAssociatedWithAnAccountException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserAccountService.UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmailAndCreateSession_result.prototype.write = function(output) {
  output.writeStructBegin('UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmailAndCreateSession_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 1);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 2);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownUnauthenticatedUserSessionIdException !== null && this.unknownUnauthenticatedUserSessionIdException !== undefined) {
    output.writeFieldBegin('unknownUnauthenticatedUserSessionIdException', Thrift.Type.STRUCT, 3);
    this.unknownUnauthenticatedUserSessionIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.firstNameInvalidStringLengthException !== null && this.firstNameInvalidStringLengthException !== undefined) {
    output.writeFieldBegin('firstNameInvalidStringLengthException', Thrift.Type.STRUCT, 4);
    this.firstNameInvalidStringLengthException.write(output);
    output.writeFieldEnd();
  }
  if (this.lastNameInvalidStringLengthException !== null && this.lastNameInvalidStringLengthException !== undefined) {
    output.writeFieldBegin('lastNameInvalidStringLengthException', Thrift.Type.STRUCT, 5);
    this.lastNameInvalidStringLengthException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownEmailAddressException !== null && this.unknownEmailAddressException !== undefined) {
    output.writeFieldBegin('unknownEmailAddressException', Thrift.Type.STRUCT, 6);
    this.unknownEmailAddressException.write(output);
    output.writeFieldEnd();
  }
  if (this.phoneNumberInvalidStringLengthException !== null && this.phoneNumberInvalidStringLengthException !== undefined) {
    output.writeFieldBegin('phoneNumberInvalidStringLengthException', Thrift.Type.STRUCT, 7);
    this.phoneNumberInvalidStringLengthException.write(output);
    output.writeFieldEnd();
  }
  if (this.emailAddressAlreadyAssociatedWithAnAccountException !== null && this.emailAddressAlreadyAssociatedWithAnAccountException !== undefined) {
    output.writeFieldBegin('emailAddressAlreadyAssociatedWithAnAccountException', Thrift.Type.STRUCT, 8);
    this.emailAddressAlreadyAssociatedWithAnAccountException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserAccountService.UserAccountServiceClient = function(input, output) {
    this.input = input;
    this.output = (!output) ? input : output;
    this.seqid = 0;
};
UserAccountService.UserAccountServiceClient.prototype = {};
UserAccountService.UserAccountServiceClient.prototype.createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmail = function(unauthenticatedUserSessionId, firstName, lastName, emailAddress, optionalPhoneNumber, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmail(pendingError, unauthenticatedUserSessionId, firstName, lastName, emailAddress, optionalPhoneNumber, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmail();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

UserAccountService.UserAccountServiceClient.prototype.send_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmail = function(pendingError, unauthenticatedUserSessionId, firstName, lastName, emailAddress, optionalPhoneNumber, callback) {
  this.output.writeMessageBegin('createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmail', Thrift.MessageType.CALL, this.seqid);
  var args = new UserAccountService.UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmail_args();
  args.unauthenticatedUserSessionId = unauthenticatedUserSessionId;
  args.firstName = firstName;
  args.lastName = lastName;
  args.emailAddress = emailAddress;
  args.optionalPhoneNumber = optionalPhoneNumber;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmail() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

UserAccountService.UserAccountServiceClient.prototype.recv_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmail = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new UserAccountService.UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmail_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.unknownUnauthenticatedUserSessionIdException) {
    throw result.unknownUnauthenticatedUserSessionIdException;
  }
  if (null !== result.firstNameInvalidStringLengthException) {
    throw result.firstNameInvalidStringLengthException;
  }
  if (null !== result.lastNameInvalidStringLengthException) {
    throw result.lastNameInvalidStringLengthException;
  }
  if (null !== result.unknownEmailAddressException) {
    throw result.unknownEmailAddressException;
  }
  if (null !== result.phoneNumberInvalidStringLengthException) {
    throw result.phoneNumberInvalidStringLengthException;
  }
  if (null !== result.emailAddressAlreadyAssociatedWithAnAccountException) {
    throw result.emailAddressAlreadyAssociatedWithAnAccountException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmail failed: unknown result';
};
UserAccountService.UserAccountServiceClient.prototype.createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmailAndCreateSession = function(unauthenticatedUserSessionId, firstName, lastName, emailAddress, optionalPhoneNumber, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmailAndCreateSession(pendingError, unauthenticatedUserSessionId, firstName, lastName, emailAddress, optionalPhoneNumber, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmailAndCreateSession();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

UserAccountService.UserAccountServiceClient.prototype.send_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmailAndCreateSession = function(pendingError, unauthenticatedUserSessionId, firstName, lastName, emailAddress, optionalPhoneNumber, callback) {
  this.output.writeMessageBegin('createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmailAndCreateSession', Thrift.MessageType.CALL, this.seqid);
  var args = new UserAccountService.UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmailAndCreateSession_args();
  args.unauthenticatedUserSessionId = unauthenticatedUserSessionId;
  args.firstName = firstName;
  args.lastName = lastName;
  args.emailAddress = emailAddress;
  args.optionalPhoneNumber = optionalPhoneNumber;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmailAndCreateSession() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

UserAccountService.UserAccountServiceClient.prototype.recv_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmailAndCreateSession = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new UserAccountService.UserAccountService_createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmailAndCreateSession_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.unknownUnauthenticatedUserSessionIdException) {
    throw result.unknownUnauthenticatedUserSessionIdException;
  }
  if (null !== result.firstNameInvalidStringLengthException) {
    throw result.firstNameInvalidStringLengthException;
  }
  if (null !== result.lastNameInvalidStringLengthException) {
    throw result.lastNameInvalidStringLengthException;
  }
  if (null !== result.unknownEmailAddressException) {
    throw result.unknownEmailAddressException;
  }
  if (null !== result.phoneNumberInvalidStringLengthException) {
    throw result.phoneNumberInvalidStringLengthException;
  }
  if (null !== result.emailAddressAlreadyAssociatedWithAnAccountException) {
    throw result.emailAddressAlreadyAssociatedWithAnAccountException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmailAndCreateSession failed: unknown result';
};
