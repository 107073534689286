import AccountsModel from 'gen-thrift/accounts_Model_types';
import AccountsUserModel from 'gen-thrift/accounts_user_Model_types';
import CorePrimitivesModel from 'gen-thrift/core_primitives_Model_types';
import UnauthenticatedUserSessionModel from 'gen-thrift/unauthenticated_user_session_Model_types';
import UserAccountService from 'gen-thrift/UserAccountService';

import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { UserAccountThriftToObjectSerializer } from 'api/UserAccount/serializer/UserAccountThriftToObjectSerializer';

import { IUnauthenticatedUserSessionReader } from '../../unauthenticated_user_session/interfaces/IUnauthenticatedUserSessionReader';
import { IAccountCreator } from '../interfaces/IAccountCreator';
import { IAccountSessionWriter } from '../interfaces/IAccountSessionWriter';

export class UserAccountManagerImpl implements IAccountCreator {
    private userAccountServiceClient : UserAccountService.UserAccountServiceClient;
    private unauthenticatedUserSessionReader : IUnauthenticatedUserSessionReader;
    private accountSessionWriter : IAccountSessionWriter<UserSessionId>;
    private userAccountThriftToObjectSerializer : UserAccountThriftToObjectSerializer;

    constructor(
            userAccountServiceClient : UserAccountService.UserAccountServiceClient,
            unauthenticatedUserSessionReader : IUnauthenticatedUserSessionReader,
            accountSessionWriter : IAccountSessionWriter<UserSessionId>,
            userAccountThriftToObjectSerializer : UserAccountThriftToObjectSerializer) {

        this.userAccountServiceClient = userAccountServiceClient;
        this.unauthenticatedUserSessionReader = unauthenticatedUserSessionReader;
        this.accountSessionWriter = accountSessionWriter;
        this.userAccountThriftToObjectSerializer = userAccountThriftToObjectSerializer;
    }

    // ServiceTemporarilyUnavailableException
    // RateLimitExceededException
    // UnknownUnauthenticatedUserSessionIdException
    // FirstNameInvalidStringLengthException
    // LastNameInvalidStringLengthException
    // UnknownEmailAddressException
    // InvalidEmailAddressException
    // PhoneNumberInvalidStringLengthException
    // EmailAddressAlreadyAssociatedWithAnAccountException
    public createAccountAndAssociateEmailAddressAndSendPasswordSettingEmail(
        firstName : string,
        lastName : string,
        emailAddress : CorePrimitivesModel.EmailAddress,
        phoneNumber : string | null,
    ) : Promise<UserAccountId> {
        return this.unauthenticatedUserSessionReader.getOrCreateUnauthenticatedUserSessionId()
        .then((unauthenticatedUserSessionId : UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId) => {
            const optionalPhoneNumber : AccountsModel.OptionalPhoneNumber = new AccountsModel.OptionalPhoneNumber({
                phoneNumber: (phoneNumber !== null) ? phoneNumber : null,
            });

            return new Promise<UserAccountId> ((resolve, reject) => {
                this.userAccountServiceClient.createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmail(
                    unauthenticatedUserSessionId,
                    firstName,
                    lastName,
                    emailAddress,
                    optionalPhoneNumber,
                    (result : AccountsUserModel.UserAccountIdentifier | Error) => {
                        if (result instanceof Error) {
                            return reject(result);
                        }

                        const userAccountId : UserAccountId = this.userAccountThriftToObjectSerializer.getUserAccountId(result);
                        return resolve(userAccountId);
                    }
                );
            });
        });
    }

    // ServiceTemporarilyUnavailableException
    // RateLimitExceededException
    // UnknownUnauthenticatedUserSessionIdException
    // FirstNameInvalidStringLengthException
    // LastNameInvalidStringLengthException
    // UnknownEmailAddressException
    // InvalidEmailAddressException
    // PhoneNumberInvalidStringLengthException
    // EmailAddressAlreadyAssociatedWithAnAccountException
    public createAccountAndAssociateEmailAddressAndSendPasswordSettingEmailAndCreateSession(
        firstName : string,
        lastName : string,
        emailAddress : CorePrimitivesModel.EmailAddress,
        phoneNumber : string | null,
    ) : Promise<UserSessionId> {
        return this.unauthenticatedUserSessionReader.getOrCreateUnauthenticatedUserSessionId()
        .then((unauthenticatedUserSessionId : UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId) => {
            const optionalPhoneNumber : AccountsModel.OptionalPhoneNumber = new AccountsModel.OptionalPhoneNumber({
                phoneNumber: (phoneNumber !== null) ? phoneNumber : null,
            });

            return new Promise<UserSessionId> ((resolve, reject) => {
                this.userAccountServiceClient.createAccountWithAssociatedEmailAddressAndSendPasswordSettingEmailAndCreateSession(
                    unauthenticatedUserSessionId,
                    firstName,
                    lastName,
                    emailAddress,
                    optionalPhoneNumber,
                    (result : AccountsUserModel.UserSessionIdentifier | Error) => {
                        if (result instanceof Error) {
                            return reject(result);
                        }

                        const userSessionId : UserSessionId = this.userAccountThriftToObjectSerializer.getUserSessionId(result);
                        this.accountSessionWriter.setSessionId(userSessionId);
                        return resolve(userSessionId);
                    }
                );
            });
        });
    }
}
