import React from 'react';

import { IModalButton } from '../../../models/Modal';
import { Dialog } from '../../Dialog';

import './ThanksForRegistering.scss';

export interface IThanksForRegisteringProps {
    onSendEmailAgainClick : () => void;
    onCloseButtonClick : () => void;
    isResendingEmail : boolean;
    isEmailResent : boolean;
    isEmbeddedInModal : boolean;
}

export class ThanksForRegistering extends React.Component<IThanksForRegisteringProps, object> {
    public render() {
        const {
            onSendEmailAgainClick,
            onCloseButtonClick,
            isResendingEmail,
            isEmailResent,
            isEmbeddedInModal,
        } = this.props;

        const getThanksForRegistering = () : JSX.Element => {

            const headerText = 'Thanks for registering!';
            const thanksForRegisteringBody = (
                <div className="thanks-for-registering-body">
                    <p>
                        We just sent a confirmation link to the email you used to sign up. Follow the link in that email to set your password.
                    </p>
                    <p className="didnt-get-the-email-text">
                        <em>Didn't get the email?</em>
                    </p>
                    <p>
                        Maybe check your spam folder, or&nbsp;
                        <span className="send-it-again-button" onClick={ onSendEmailAgainClick }>
                            <a><b>send it again.</b></a>
                        </span>
                    </p>
                    <div>
                        { isResendingEmail ?
                            <span className="bevicon bevico-spinner2 make-it-spin sending-spinner" />
                        :
                        isEmailResent &&
                        <span className="sent-confirmation">
                            <b><span className="bevicon bevico-check" />Sent.</b>
                        </span>
                        }
                    </div>
                </div>
            );

            if (isEmbeddedInModal) {
                const buttons : Array<IModalButton> = [{
                    classes: 'close-button flat thanks-for-registering-modal-close-button',
                    children: 'DISMISS',
                    isDisabled: false,
                    isLoading: false,
                    onClick: onCloseButtonClick,
                }];

                return (
                    <Dialog
                        className="thanks-for-registering-dialog thanks-for-registering"
                        buttons={ buttons }
                        headerText={ headerText }
                    >
                        { thanksForRegisteringBody }
                    </Dialog>
                );
            }

            return (
                <div className="thanks-for-registering-not-modal">
                    <div className="header">
                        <h5>{ headerText }</h5>
                    </div>
                    <div className="body">
                        { thanksForRegisteringBody }
                    </div>
                </div>
            );
        };

        return getThanksForRegistering();
    }
}
