import React from 'react';

import { StringValueMap } from 'api/Core/StringValueMap';
import { PrepEvent } from 'api/PrepEvent/model/PrepEvent';
import { PrepEventId } from 'api/PrepEvent/model/PrepEventId';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { PrepEventDisplayUtils } from 'apps/CreateOrEditPrepEvent/utils/PrepEventDisplayUtils';
import { Popover } from 'shared/components/Popover/Popover';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import '../css/PrepEventInputsPopover.scss';

export interface IPrepEventInputsPopoverProps {
    anchorContentLabel : string;
    prepEventId : PrepEventId;
    prepEvent : PrepEvent;
    productsById : StringValueMap<ProductId, Product>;
    popoverIsShown : boolean;
    setPopoverShown : (isShown : boolean) => void;
}

export class PrepEventInputsPopover extends React.Component<IPrepEventInputsPopoverProps, object> {

    public render() {
        const {
            prepEvent,
            prepEventId,
            productsById,
            anchorContentLabel,
            popoverIsShown,
            setPopoverShown
        } = this.props;

        const inputLineItems : Array<JSX.Element> = [];

        prepEvent.getInputQuantityOfProductByProductId().forEach((inputQuantity, prodId) => {
            const product = productsById.get(prodId);
            if (typeof product === 'undefined') {
                throw new RuntimeException('unexpected');
            }
            const productLabel = PrepEventDisplayUtils.getLineItemProductLabel(product);
            const yieldLabel = PrepEventDisplayUtils.getLineItemQuantityLabel(inputQuantity, product.getPackagingsAndMappings());

            inputLineItems.push(
                <li className="input-line-item row-container" key={ prodId.getValue() + '_' + prepEventId.getValue() }>
                    <strong>{ productLabel }</strong>
                    <span>{ yieldLabel }</span>
                </li>
            );
        });

        const popoverContent = (
            <div className="popover-breakdown-container">
                <div className="popover-title">
                    Ingredient(s)
                </div>
                <hr className="rule-light"/>
                <ul className="invoice-uploads-list">
                    { inputLineItems }
                </ul>
            </div>
        );

        return (
            <Popover
                className="input-line-items-popover"
                showOnHover={ true }
                preferredPositionArray={ ['below'] }
                popoverContentIsShown={ popoverIsShown }
                setPopoverContentIsShown={ setPopoverShown }
                anchorContent={ (
                    <div className="input-line-items-label ellipsis-out cursor-pointer">
                        { anchorContentLabel }
                    </div>
                ) }
                popoverContent={ popoverContent }
            />
        );
    }
}
