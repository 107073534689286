import moment from 'moment-timezone';

import { StringValueMap } from 'api/Core/StringValueMap';
import { InventoryCountId } from 'api/InventoryCount/model/InventoryCountId';

import { CategoryLevelSalesReportConfiguration } from '../model/CategoryLevelSalesReportConfiguration';
import { CategoryLevelSalesReportConfigurationId } from '../model/CategoryLevelSalesReportConfigurationId';

export interface ICategoryLevelSalesReportConfigurationJSONObject {
    creation_time : string;
    bounding_inventory_id_0 : string;
    bounding_inventory_id_1 : string;
    revenue_by_product_category_id_and_sales_category_name_and_iso_4217_currency_code : any; // Not serializing these for now -- Marina
    revenue_without_product_category_by_sales_category_name_and_iso_4217_currency_code : any; // Not serializing these for now -- Marina
}

export class CategoryLevelSalesReportConfigurationSerializer {
    public getCategoryLevelSalesReportConfigurationsById(
        reportConfigurationsByIdJSONData : {[reportConfigurationId : string] : ICategoryLevelSalesReportConfigurationJSONObject},
    ) : StringValueMap<CategoryLevelSalesReportConfigurationId, CategoryLevelSalesReportConfiguration> {
        const result = new StringValueMap<CategoryLevelSalesReportConfigurationId, CategoryLevelSalesReportConfiguration>();

        Object.keys(reportConfigurationsByIdJSONData).forEach((idValue) => {
            const reportConfigurationJSONObject = reportConfigurationsByIdJSONData[idValue];

            result.set(
                new CategoryLevelSalesReportConfigurationId(idValue),
                this.getCategoryLevelSalesReportConfiguration(reportConfigurationJSONObject)
            );
        });

        return result;
    }

    public getCategoryLevelSalesReportConfiguration(
        reportConfigurationJSONObject : ICategoryLevelSalesReportConfigurationJSONObject
    ) : CategoryLevelSalesReportConfiguration {
        const creationTime = moment.utc(reportConfigurationJSONObject.creation_time);

        const startingInventoryId = new InventoryCountId(reportConfigurationJSONObject.bounding_inventory_id_0);

        const endingInventoryId = new InventoryCountId(reportConfigurationJSONObject.bounding_inventory_id_1);

        return new CategoryLevelSalesReportConfiguration(
            creationTime,
            startingInventoryId,
            endingInventoryId
        );
    }
}
