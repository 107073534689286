import { Unit } from 'api/Product/model/Unit';

export class Price {
    constructor(
        private readonly dollarValue : number,
        private readonly unit : Unit,
    ) {}

    public getDollarValue() {
        return this.dollarValue;
    }

    public getUnit() {
        return this.unit;
    }

    public equals(other : any) {
        if (!(other instanceof Price)) {
            return false;
        }

        return (
            this.dollarValue === other.getDollarValue() &&
            this.unit === other.getUnit()
        );
    }
}
