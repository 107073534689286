//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';



var SalesItemExceptions = {};
export default SalesItemExceptions;
SalesItemExceptions.UnknownSalesItemIdException = function(args) {
};
Thrift.inherits(SalesItemExceptions.UnknownSalesItemIdException, Thrift.TException);
SalesItemExceptions.UnknownSalesItemIdException.prototype.name = 'UnknownSalesItemIdException';
SalesItemExceptions.UnknownSalesItemIdException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SalesItemExceptions.UnknownSalesItemIdException.prototype.write = function(output) {
  output.writeStructBegin('UnknownSalesItemIdException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

