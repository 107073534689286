//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


import Thrift from 'thrift';
import AccountsExceptions from './accounts_Exceptions_types';
import UserAccountsModel from './accounts_user_Model_types';
import CoreApiExceptions from './core_api_Exceptions_types';
import DistributorExceptions from './distributor_Exceptions_types';
import DistributorModel from './distributor_Model_types';
import ProductExceptions from './product_Exceptions_types';
import ProductModel from './product_Model_types';
import LocationModel from './location_Model_types';
import LocationExceptions from './location_Exceptions_types';
import ProductDistributorModel from './product_distributor_Model_types';

var ProductDistributorService = {};
export default ProductDistributorService;
//HELPER FUNCTIONS AND STRUCTURES

ProductDistributorService.ProductDistributorService_getProductPackagingDistributorAssociationsForProductIds_args = function(args) {
  this.sessionId = null;
  this.productIds = null;
  if (args) {
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = new UserAccountsModel.UserSessionIdentifier(args.sessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionId is unset!');
    }
    if (args.productIds !== undefined && args.productIds !== null) {
      this.productIds = Thrift.copyList(args.productIds, [ProductModel.ProductId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productIds is unset!');
    }
  }
};
ProductDistributorService.ProductDistributorService_getProductPackagingDistributorAssociationsForProductIds_args.prototype = {};
ProductDistributorService.ProductDistributorService_getProductPackagingDistributorAssociationsForProductIds_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionId = new UserAccountsModel.UserSessionIdentifier();
        this.sessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.SET) {
        var _size0 = 0;
        var _rtmp34;
        this.productIds = [];
        var _etype3 = 0;
        _rtmp34 = input.readSetBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new ProductModel.ProductId();
          elem6.read(input);
          this.productIds.push(elem6);
        }
        input.readSetEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductDistributorService.ProductDistributorService_getProductPackagingDistributorAssociationsForProductIds_args.prototype.write = function(output) {
  output.writeStructBegin('ProductDistributorService_getProductPackagingDistributorAssociationsForProductIds_args');
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRUCT, 1);
    this.sessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.productIds !== null && this.productIds !== undefined) {
    output.writeFieldBegin('productIds', Thrift.Type.SET, 2);
    output.writeSetBegin(Thrift.Type.STRUCT, this.productIds.length);
    for (var iter7 in this.productIds)
    {
      if (this.productIds.hasOwnProperty(iter7))
      {
        iter7 = this.productIds[iter7];
        iter7.write(output);
      }
    }
    output.writeSetEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductDistributorService.ProductDistributorService_getProductPackagingDistributorAssociationsForProductIds_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.unknownProductIdException = null;
  this.accountDoesNotHaveAccessToProductException = null;
  this.duplicateProductIdException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof ProductExceptions.AccountDoesNotHaveAccessToProductException) {
    this.accountDoesNotHaveAccessToProductException = args;
    return;
  }
  if (args instanceof ProductExceptions.DuplicateProductIdException) {
    this.duplicateProductIdException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [ProductDistributorModel.ProductPackagingDistributorAssoc]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.accountDoesNotHaveAccessToProductException !== undefined && args.accountDoesNotHaveAccessToProductException !== null) {
      this.accountDoesNotHaveAccessToProductException = args.accountDoesNotHaveAccessToProductException;
    }
    if (args.duplicateProductIdException !== undefined && args.duplicateProductIdException !== null) {
      this.duplicateProductIdException = args.duplicateProductIdException;
    }
  }
};
ProductDistributorService.ProductDistributorService_getProductPackagingDistributorAssociationsForProductIds_result.prototype = {};
ProductDistributorService.ProductDistributorService_getProductPackagingDistributorAssociationsForProductIds_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size8 = 0;
        var _rtmp312;
        this.success = [];
        var _etype11 = 0;
        _rtmp312 = input.readListBegin();
        _etype11 = _rtmp312.etype;
        _size8 = _rtmp312.size;
        for (var _i13 = 0; _i13 < _size8; ++_i13)
        {
          var elem14 = null;
          elem14 = new ProductDistributorModel.ProductPackagingDistributorAssoc();
          elem14.read(input);
          this.success.push(elem14);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHaveAccessToProductException = new ProductExceptions.AccountDoesNotHaveAccessToProductException();
        this.accountDoesNotHaveAccessToProductException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.duplicateProductIdException = new ProductExceptions.DuplicateProductIdException();
        this.duplicateProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductDistributorService.ProductDistributorService_getProductPackagingDistributorAssociationsForProductIds_result.prototype.write = function(output) {
  output.writeStructBegin('ProductDistributorService_getProductPackagingDistributorAssociationsForProductIds_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter15 in this.success)
    {
      if (this.success.hasOwnProperty(iter15))
      {
        iter15 = this.success[iter15];
        iter15.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 4);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHaveAccessToProductException !== null && this.accountDoesNotHaveAccessToProductException !== undefined) {
    output.writeFieldBegin('accountDoesNotHaveAccessToProductException', Thrift.Type.STRUCT, 5);
    this.accountDoesNotHaveAccessToProductException.write(output);
    output.writeFieldEnd();
  }
  if (this.duplicateProductIdException !== null && this.duplicateProductIdException !== undefined) {
    output.writeFieldBegin('duplicateProductIdException', Thrift.Type.STRUCT, 6);
    this.duplicateProductIdException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductDistributorService.ProductDistributorService_createOrUpdateDistributorProductPackagingAssocs_args = function(args) {
  this.sessionId = null;
  this.assocs = null;
  if (args) {
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = new UserAccountsModel.UserSessionIdentifier(args.sessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionId is unset!');
    }
    if (args.assocs !== undefined && args.assocs !== null) {
      this.assocs = Thrift.copyList(args.assocs, [ProductDistributorModel.ProductPackagingDistributorAssoc]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field assocs is unset!');
    }
  }
};
ProductDistributorService.ProductDistributorService_createOrUpdateDistributorProductPackagingAssocs_args.prototype = {};
ProductDistributorService.ProductDistributorService_createOrUpdateDistributorProductPackagingAssocs_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionId = new UserAccountsModel.UserSessionIdentifier();
        this.sessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size16 = 0;
        var _rtmp320;
        this.assocs = [];
        var _etype19 = 0;
        _rtmp320 = input.readListBegin();
        _etype19 = _rtmp320.etype;
        _size16 = _rtmp320.size;
        for (var _i21 = 0; _i21 < _size16; ++_i21)
        {
          var elem22 = null;
          elem22 = new ProductDistributorModel.ProductPackagingDistributorAssoc();
          elem22.read(input);
          this.assocs.push(elem22);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductDistributorService.ProductDistributorService_createOrUpdateDistributorProductPackagingAssocs_args.prototype.write = function(output) {
  output.writeStructBegin('ProductDistributorService_createOrUpdateDistributorProductPackagingAssocs_args');
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRUCT, 1);
    this.sessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.assocs !== null && this.assocs !== undefined) {
    output.writeFieldBegin('assocs', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.assocs.length);
    for (var iter23 in this.assocs)
    {
      if (this.assocs.hasOwnProperty(iter23))
      {
        iter23 = this.assocs[iter23];
        iter23.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductDistributorService.ProductDistributorService_createOrUpdateDistributorProductPackagingAssocs_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.unknownProductIdException = null;
  this.accountDoesNotHaveAccessToProductException = null;
  this.unknownDistributorIdException = null;
  this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof ProductExceptions.AccountDoesNotHaveAccessToProductException) {
    this.accountDoesNotHaveAccessToProductException = args;
    return;
  }
  if (args instanceof DistributorExceptions.UnknownDistributorIdException) {
    this.unknownDistributorIdException = args;
    return;
  }
  if (args instanceof ProductExceptions.LocationAssociatedWithProductIdDoesNotHaveAccessToDistributorException) {
    this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.accountDoesNotHaveAccessToProductException !== undefined && args.accountDoesNotHaveAccessToProductException !== null) {
      this.accountDoesNotHaveAccessToProductException = args.accountDoesNotHaveAccessToProductException;
    }
    if (args.unknownDistributorIdException !== undefined && args.unknownDistributorIdException !== null) {
      this.unknownDistributorIdException = args.unknownDistributorIdException;
    }
    if (args.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException !== undefined && args.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException !== null) {
      this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException = args.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException;
    }
  }
};
ProductDistributorService.ProductDistributorService_createOrUpdateDistributorProductPackagingAssocs_result.prototype = {};
ProductDistributorService.ProductDistributorService_createOrUpdateDistributorProductPackagingAssocs_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHaveAccessToProductException = new ProductExceptions.AccountDoesNotHaveAccessToProductException();
        this.accountDoesNotHaveAccessToProductException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownDistributorIdException = new DistributorExceptions.UnknownDistributorIdException();
        this.unknownDistributorIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException = new ProductExceptions.LocationAssociatedWithProductIdDoesNotHaveAccessToDistributorException();
        this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductDistributorService.ProductDistributorService_createOrUpdateDistributorProductPackagingAssocs_result.prototype.write = function(output) {
  output.writeStructBegin('ProductDistributorService_createOrUpdateDistributorProductPackagingAssocs_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 4);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHaveAccessToProductException !== null && this.accountDoesNotHaveAccessToProductException !== undefined) {
    output.writeFieldBegin('accountDoesNotHaveAccessToProductException', Thrift.Type.STRUCT, 5);
    this.accountDoesNotHaveAccessToProductException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownDistributorIdException !== null && this.unknownDistributorIdException !== undefined) {
    output.writeFieldBegin('unknownDistributorIdException', Thrift.Type.STRUCT, 6);
    this.unknownDistributorIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException !== null && this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException !== undefined) {
    output.writeFieldBegin('locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException', Thrift.Type.STRUCT, 7);
    this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductDistributorService.ProductDistributorService_disassociateDistributorProductPackagingAssocs_args = function(args) {
  this.sessionId = null;
  this.assocs = null;
  if (args) {
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = new UserAccountsModel.UserSessionIdentifier(args.sessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionId is unset!');
    }
    if (args.assocs !== undefined && args.assocs !== null) {
      this.assocs = Thrift.copyList(args.assocs, [ProductDistributorModel.ProductPackagingDistributorAssoc]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field assocs is unset!');
    }
  }
};
ProductDistributorService.ProductDistributorService_disassociateDistributorProductPackagingAssocs_args.prototype = {};
ProductDistributorService.ProductDistributorService_disassociateDistributorProductPackagingAssocs_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionId = new UserAccountsModel.UserSessionIdentifier();
        this.sessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size24 = 0;
        var _rtmp328;
        this.assocs = [];
        var _etype27 = 0;
        _rtmp328 = input.readListBegin();
        _etype27 = _rtmp328.etype;
        _size24 = _rtmp328.size;
        for (var _i29 = 0; _i29 < _size24; ++_i29)
        {
          var elem30 = null;
          elem30 = new ProductDistributorModel.ProductPackagingDistributorAssoc();
          elem30.read(input);
          this.assocs.push(elem30);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductDistributorService.ProductDistributorService_disassociateDistributorProductPackagingAssocs_args.prototype.write = function(output) {
  output.writeStructBegin('ProductDistributorService_disassociateDistributorProductPackagingAssocs_args');
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRUCT, 1);
    this.sessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.assocs !== null && this.assocs !== undefined) {
    output.writeFieldBegin('assocs', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.assocs.length);
    for (var iter31 in this.assocs)
    {
      if (this.assocs.hasOwnProperty(iter31))
      {
        iter31 = this.assocs[iter31];
        iter31.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductDistributorService.ProductDistributorService_disassociateDistributorProductPackagingAssocs_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.unknownProductIdException = null;
  this.accountDoesNotHaveAccessToProductException = null;
  this.unknownDistributorIdException = null;
  this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof ProductExceptions.AccountDoesNotHaveAccessToProductException) {
    this.accountDoesNotHaveAccessToProductException = args;
    return;
  }
  if (args instanceof DistributorExceptions.UnknownDistributorIdException) {
    this.unknownDistributorIdException = args;
    return;
  }
  if (args instanceof ProductExceptions.LocationAssociatedWithProductIdDoesNotHaveAccessToDistributorException) {
    this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.accountDoesNotHaveAccessToProductException !== undefined && args.accountDoesNotHaveAccessToProductException !== null) {
      this.accountDoesNotHaveAccessToProductException = args.accountDoesNotHaveAccessToProductException;
    }
    if (args.unknownDistributorIdException !== undefined && args.unknownDistributorIdException !== null) {
      this.unknownDistributorIdException = args.unknownDistributorIdException;
    }
    if (args.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException !== undefined && args.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException !== null) {
      this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException = args.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException;
    }
  }
};
ProductDistributorService.ProductDistributorService_disassociateDistributorProductPackagingAssocs_result.prototype = {};
ProductDistributorService.ProductDistributorService_disassociateDistributorProductPackagingAssocs_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHaveAccessToProductException = new ProductExceptions.AccountDoesNotHaveAccessToProductException();
        this.accountDoesNotHaveAccessToProductException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownDistributorIdException = new DistributorExceptions.UnknownDistributorIdException();
        this.unknownDistributorIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException = new ProductExceptions.LocationAssociatedWithProductIdDoesNotHaveAccessToDistributorException();
        this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductDistributorService.ProductDistributorService_disassociateDistributorProductPackagingAssocs_result.prototype.write = function(output) {
  output.writeStructBegin('ProductDistributorService_disassociateDistributorProductPackagingAssocs_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 4);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHaveAccessToProductException !== null && this.accountDoesNotHaveAccessToProductException !== undefined) {
    output.writeFieldBegin('accountDoesNotHaveAccessToProductException', Thrift.Type.STRUCT, 5);
    this.accountDoesNotHaveAccessToProductException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownDistributorIdException !== null && this.unknownDistributorIdException !== undefined) {
    output.writeFieldBegin('unknownDistributorIdException', Thrift.Type.STRUCT, 6);
    this.unknownDistributorIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException !== null && this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException !== undefined) {
    output.writeFieldBegin('locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException', Thrift.Type.STRUCT, 7);
    this.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductDistributorService.ProductDistributorService_disassociateDistributorFromProductPackagings_args = function(args) {
  this.sessionId = null;
  this.productId = null;
  this.productQuantityUnits = null;
  this.distributorId = null;
  if (args) {
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = new UserAccountsModel.UserSessionIdentifier(args.sessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionId is unset!');
    }
    if (args.productId !== undefined && args.productId !== null) {
      this.productId = new ProductModel.ProductId(args.productId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productId is unset!');
    }
    if (args.productQuantityUnits !== undefined && args.productQuantityUnits !== null) {
      this.productQuantityUnits = Thrift.copyList(args.productQuantityUnits, [ProductModel.ProductQuantityUnit]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productQuantityUnits is unset!');
    }
    if (args.distributorId !== undefined && args.distributorId !== null) {
      this.distributorId = new DistributorModel.DistributorId(args.distributorId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field distributorId is unset!');
    }
  }
};
ProductDistributorService.ProductDistributorService_disassociateDistributorFromProductPackagings_args.prototype = {};
ProductDistributorService.ProductDistributorService_disassociateDistributorFromProductPackagings_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionId = new UserAccountsModel.UserSessionIdentifier();
        this.sessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.productId = new ProductModel.ProductId();
        this.productId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.SET) {
        var _size32 = 0;
        var _rtmp336;
        this.productQuantityUnits = [];
        var _etype35 = 0;
        _rtmp336 = input.readSetBegin();
        _etype35 = _rtmp336.etype;
        _size32 = _rtmp336.size;
        for (var _i37 = 0; _i37 < _size32; ++_i37)
        {
          var elem38 = null;
          elem38 = new ProductModel.ProductQuantityUnit();
          elem38.read(input);
          this.productQuantityUnits.push(elem38);
        }
        input.readSetEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.distributorId = new DistributorModel.DistributorId();
        this.distributorId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductDistributorService.ProductDistributorService_disassociateDistributorFromProductPackagings_args.prototype.write = function(output) {
  output.writeStructBegin('ProductDistributorService_disassociateDistributorFromProductPackagings_args');
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRUCT, 1);
    this.sessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.productId !== null && this.productId !== undefined) {
    output.writeFieldBegin('productId', Thrift.Type.STRUCT, 2);
    this.productId.write(output);
    output.writeFieldEnd();
  }
  if (this.productQuantityUnits !== null && this.productQuantityUnits !== undefined) {
    output.writeFieldBegin('productQuantityUnits', Thrift.Type.SET, 3);
    output.writeSetBegin(Thrift.Type.STRUCT, this.productQuantityUnits.length);
    for (var iter39 in this.productQuantityUnits)
    {
      if (this.productQuantityUnits.hasOwnProperty(iter39))
      {
        iter39 = this.productQuantityUnits[iter39];
        iter39.write(output);
      }
    }
    output.writeSetEnd();
    output.writeFieldEnd();
  }
  if (this.distributorId !== null && this.distributorId !== undefined) {
    output.writeFieldBegin('distributorId', Thrift.Type.STRUCT, 4);
    this.distributorId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductDistributorService.ProductDistributorService_disassociateDistributorFromProductPackagings_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.unknownProductIdException = null;
  this.accountDoesNotHaveAccessToProductException = null;
  this.unknownDistributorIdException = null;
  this.duplicateProductIdException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof ProductExceptions.AccountDoesNotHaveAccessToProductException) {
    this.accountDoesNotHaveAccessToProductException = args;
    return;
  }
  if (args instanceof DistributorExceptions.UnknownDistributorIdException) {
    this.unknownDistributorIdException = args;
    return;
  }
  if (args instanceof ProductExceptions.DuplicateProductIdException) {
    this.duplicateProductIdException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.accountDoesNotHaveAccessToProductException !== undefined && args.accountDoesNotHaveAccessToProductException !== null) {
      this.accountDoesNotHaveAccessToProductException = args.accountDoesNotHaveAccessToProductException;
    }
    if (args.unknownDistributorIdException !== undefined && args.unknownDistributorIdException !== null) {
      this.unknownDistributorIdException = args.unknownDistributorIdException;
    }
    if (args.duplicateProductIdException !== undefined && args.duplicateProductIdException !== null) {
      this.duplicateProductIdException = args.duplicateProductIdException;
    }
  }
};
ProductDistributorService.ProductDistributorService_disassociateDistributorFromProductPackagings_result.prototype = {};
ProductDistributorService.ProductDistributorService_disassociateDistributorFromProductPackagings_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHaveAccessToProductException = new ProductExceptions.AccountDoesNotHaveAccessToProductException();
        this.accountDoesNotHaveAccessToProductException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownDistributorIdException = new DistributorExceptions.UnknownDistributorIdException();
        this.unknownDistributorIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.duplicateProductIdException = new ProductExceptions.DuplicateProductIdException();
        this.duplicateProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductDistributorService.ProductDistributorService_disassociateDistributorFromProductPackagings_result.prototype.write = function(output) {
  output.writeStructBegin('ProductDistributorService_disassociateDistributorFromProductPackagings_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 4);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHaveAccessToProductException !== null && this.accountDoesNotHaveAccessToProductException !== undefined) {
    output.writeFieldBegin('accountDoesNotHaveAccessToProductException', Thrift.Type.STRUCT, 5);
    this.accountDoesNotHaveAccessToProductException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownDistributorIdException !== null && this.unknownDistributorIdException !== undefined) {
    output.writeFieldBegin('unknownDistributorIdException', Thrift.Type.STRUCT, 6);
    this.unknownDistributorIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.duplicateProductIdException !== null && this.duplicateProductIdException !== undefined) {
    output.writeFieldBegin('duplicateProductIdException', Thrift.Type.STRUCT, 7);
    this.duplicateProductIdException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductDistributorService.ProductDistributorService_disassociateDistributorFromProducts_args = function(args) {
  this.sessionId = null;
  this.productIds = null;
  this.distributorId = null;
  if (args) {
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = new UserAccountsModel.UserSessionIdentifier(args.sessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionId is unset!');
    }
    if (args.productIds !== undefined && args.productIds !== null) {
      this.productIds = Thrift.copyList(args.productIds, [ProductModel.ProductId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productIds is unset!');
    }
    if (args.distributorId !== undefined && args.distributorId !== null) {
      this.distributorId = new DistributorModel.DistributorId(args.distributorId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field distributorId is unset!');
    }
  }
};
ProductDistributorService.ProductDistributorService_disassociateDistributorFromProducts_args.prototype = {};
ProductDistributorService.ProductDistributorService_disassociateDistributorFromProducts_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionId = new UserAccountsModel.UserSessionIdentifier();
        this.sessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.SET) {
        var _size40 = 0;
        var _rtmp344;
        this.productIds = [];
        var _etype43 = 0;
        _rtmp344 = input.readSetBegin();
        _etype43 = _rtmp344.etype;
        _size40 = _rtmp344.size;
        for (var _i45 = 0; _i45 < _size40; ++_i45)
        {
          var elem46 = null;
          elem46 = new ProductModel.ProductId();
          elem46.read(input);
          this.productIds.push(elem46);
        }
        input.readSetEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.distributorId = new DistributorModel.DistributorId();
        this.distributorId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductDistributorService.ProductDistributorService_disassociateDistributorFromProducts_args.prototype.write = function(output) {
  output.writeStructBegin('ProductDistributorService_disassociateDistributorFromProducts_args');
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRUCT, 1);
    this.sessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.productIds !== null && this.productIds !== undefined) {
    output.writeFieldBegin('productIds', Thrift.Type.SET, 2);
    output.writeSetBegin(Thrift.Type.STRUCT, this.productIds.length);
    for (var iter47 in this.productIds)
    {
      if (this.productIds.hasOwnProperty(iter47))
      {
        iter47 = this.productIds[iter47];
        iter47.write(output);
      }
    }
    output.writeSetEnd();
    output.writeFieldEnd();
  }
  if (this.distributorId !== null && this.distributorId !== undefined) {
    output.writeFieldBegin('distributorId', Thrift.Type.STRUCT, 3);
    this.distributorId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductDistributorService.ProductDistributorService_disassociateDistributorFromProducts_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.unknownProductIdException = null;
  this.accountDoesNotHaveAccessToProductException = null;
  this.unknownDistributorIdException = null;
  this.duplicateProductIdException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof ProductExceptions.AccountDoesNotHaveAccessToProductException) {
    this.accountDoesNotHaveAccessToProductException = args;
    return;
  }
  if (args instanceof DistributorExceptions.UnknownDistributorIdException) {
    this.unknownDistributorIdException = args;
    return;
  }
  if (args instanceof ProductExceptions.DuplicateProductIdException) {
    this.duplicateProductIdException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.accountDoesNotHaveAccessToProductException !== undefined && args.accountDoesNotHaveAccessToProductException !== null) {
      this.accountDoesNotHaveAccessToProductException = args.accountDoesNotHaveAccessToProductException;
    }
    if (args.unknownDistributorIdException !== undefined && args.unknownDistributorIdException !== null) {
      this.unknownDistributorIdException = args.unknownDistributorIdException;
    }
    if (args.duplicateProductIdException !== undefined && args.duplicateProductIdException !== null) {
      this.duplicateProductIdException = args.duplicateProductIdException;
    }
  }
};
ProductDistributorService.ProductDistributorService_disassociateDistributorFromProducts_result.prototype = {};
ProductDistributorService.ProductDistributorService_disassociateDistributorFromProducts_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHaveAccessToProductException = new ProductExceptions.AccountDoesNotHaveAccessToProductException();
        this.accountDoesNotHaveAccessToProductException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownDistributorIdException = new DistributorExceptions.UnknownDistributorIdException();
        this.unknownDistributorIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.duplicateProductIdException = new ProductExceptions.DuplicateProductIdException();
        this.duplicateProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductDistributorService.ProductDistributorService_disassociateDistributorFromProducts_result.prototype.write = function(output) {
  output.writeStructBegin('ProductDistributorService_disassociateDistributorFromProducts_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 4);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHaveAccessToProductException !== null && this.accountDoesNotHaveAccessToProductException !== undefined) {
    output.writeFieldBegin('accountDoesNotHaveAccessToProductException', Thrift.Type.STRUCT, 5);
    this.accountDoesNotHaveAccessToProductException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownDistributorIdException !== null && this.unknownDistributorIdException !== undefined) {
    output.writeFieldBegin('unknownDistributorIdException', Thrift.Type.STRUCT, 6);
    this.unknownDistributorIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.duplicateProductIdException !== null && this.duplicateProductIdException !== undefined) {
    output.writeFieldBegin('duplicateProductIdException', Thrift.Type.STRUCT, 7);
    this.duplicateProductIdException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductDistributorService.ProductDistributorService_disassociateAllDistributorsFromProducts_args = function(args) {
  this.sessionId = null;
  this.productIds = null;
  if (args) {
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = new UserAccountsModel.UserSessionIdentifier(args.sessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionId is unset!');
    }
    if (args.productIds !== undefined && args.productIds !== null) {
      this.productIds = Thrift.copyList(args.productIds, [ProductModel.ProductId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productIds is unset!');
    }
  }
};
ProductDistributorService.ProductDistributorService_disassociateAllDistributorsFromProducts_args.prototype = {};
ProductDistributorService.ProductDistributorService_disassociateAllDistributorsFromProducts_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionId = new UserAccountsModel.UserSessionIdentifier();
        this.sessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.SET) {
        var _size48 = 0;
        var _rtmp352;
        this.productIds = [];
        var _etype51 = 0;
        _rtmp352 = input.readSetBegin();
        _etype51 = _rtmp352.etype;
        _size48 = _rtmp352.size;
        for (var _i53 = 0; _i53 < _size48; ++_i53)
        {
          var elem54 = null;
          elem54 = new ProductModel.ProductId();
          elem54.read(input);
          this.productIds.push(elem54);
        }
        input.readSetEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductDistributorService.ProductDistributorService_disassociateAllDistributorsFromProducts_args.prototype.write = function(output) {
  output.writeStructBegin('ProductDistributorService_disassociateAllDistributorsFromProducts_args');
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRUCT, 1);
    this.sessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.productIds !== null && this.productIds !== undefined) {
    output.writeFieldBegin('productIds', Thrift.Type.SET, 2);
    output.writeSetBegin(Thrift.Type.STRUCT, this.productIds.length);
    for (var iter55 in this.productIds)
    {
      if (this.productIds.hasOwnProperty(iter55))
      {
        iter55 = this.productIds[iter55];
        iter55.write(output);
      }
    }
    output.writeSetEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductDistributorService.ProductDistributorService_disassociateAllDistributorsFromProducts_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.unknownProductIdException = null;
  this.accountDoesNotHaveAccessToProductException = null;
  this.unknownDistributorIdException = null;
  this.duplicateProductIdException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof ProductExceptions.AccountDoesNotHaveAccessToProductException) {
    this.accountDoesNotHaveAccessToProductException = args;
    return;
  }
  if (args instanceof DistributorExceptions.UnknownDistributorIdException) {
    this.unknownDistributorIdException = args;
    return;
  }
  if (args instanceof ProductExceptions.DuplicateProductIdException) {
    this.duplicateProductIdException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.accountDoesNotHaveAccessToProductException !== undefined && args.accountDoesNotHaveAccessToProductException !== null) {
      this.accountDoesNotHaveAccessToProductException = args.accountDoesNotHaveAccessToProductException;
    }
    if (args.unknownDistributorIdException !== undefined && args.unknownDistributorIdException !== null) {
      this.unknownDistributorIdException = args.unknownDistributorIdException;
    }
    if (args.duplicateProductIdException !== undefined && args.duplicateProductIdException !== null) {
      this.duplicateProductIdException = args.duplicateProductIdException;
    }
  }
};
ProductDistributorService.ProductDistributorService_disassociateAllDistributorsFromProducts_result.prototype = {};
ProductDistributorService.ProductDistributorService_disassociateAllDistributorsFromProducts_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHaveAccessToProductException = new ProductExceptions.AccountDoesNotHaveAccessToProductException();
        this.accountDoesNotHaveAccessToProductException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownDistributorIdException = new DistributorExceptions.UnknownDistributorIdException();
        this.unknownDistributorIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.duplicateProductIdException = new ProductExceptions.DuplicateProductIdException();
        this.duplicateProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductDistributorService.ProductDistributorService_disassociateAllDistributorsFromProducts_result.prototype.write = function(output) {
  output.writeStructBegin('ProductDistributorService_disassociateAllDistributorsFromProducts_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 4);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHaveAccessToProductException !== null && this.accountDoesNotHaveAccessToProductException !== undefined) {
    output.writeFieldBegin('accountDoesNotHaveAccessToProductException', Thrift.Type.STRUCT, 5);
    this.accountDoesNotHaveAccessToProductException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownDistributorIdException !== null && this.unknownDistributorIdException !== undefined) {
    output.writeFieldBegin('unknownDistributorIdException', Thrift.Type.STRUCT, 6);
    this.unknownDistributorIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.duplicateProductIdException !== null && this.duplicateProductIdException !== undefined) {
    output.writeFieldBegin('duplicateProductIdException', Thrift.Type.STRUCT, 7);
    this.duplicateProductIdException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductDistributorService.ProductDistributorServiceClient = function(input, output) {
    this.input = input;
    this.output = (!output) ? input : output;
    this.seqid = 0;
};
ProductDistributorService.ProductDistributorServiceClient.prototype = {};
ProductDistributorService.ProductDistributorServiceClient.prototype.getProductPackagingDistributorAssociationsForProductIds = function(sessionId, productIds, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getProductPackagingDistributorAssociationsForProductIds(pendingError, sessionId, productIds, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getProductPackagingDistributorAssociationsForProductIds();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

ProductDistributorService.ProductDistributorServiceClient.prototype.send_getProductPackagingDistributorAssociationsForProductIds = function(pendingError, sessionId, productIds, callback) {
  this.output.writeMessageBegin('getProductPackagingDistributorAssociationsForProductIds', Thrift.MessageType.CALL, this.seqid);
  var args = new ProductDistributorService.ProductDistributorService_getProductPackagingDistributorAssociationsForProductIds_args();
  args.sessionId = sessionId;
  args.productIds = productIds;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getProductPackagingDistributorAssociationsForProductIds() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

ProductDistributorService.ProductDistributorServiceClient.prototype.recv_getProductPackagingDistributorAssociationsForProductIds = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new ProductDistributorService.ProductDistributorService_getProductPackagingDistributorAssociationsForProductIds_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.accountDoesNotHaveAccessToProductException) {
    throw result.accountDoesNotHaveAccessToProductException;
  }
  if (null !== result.duplicateProductIdException) {
    throw result.duplicateProductIdException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getProductPackagingDistributorAssociationsForProductIds failed: unknown result';
};
ProductDistributorService.ProductDistributorServiceClient.prototype.createOrUpdateDistributorProductPackagingAssocs = function(sessionId, assocs, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_createOrUpdateDistributorProductPackagingAssocs(pendingError, sessionId, assocs, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_createOrUpdateDistributorProductPackagingAssocs();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

ProductDistributorService.ProductDistributorServiceClient.prototype.send_createOrUpdateDistributorProductPackagingAssocs = function(pendingError, sessionId, assocs, callback) {
  this.output.writeMessageBegin('createOrUpdateDistributorProductPackagingAssocs', Thrift.MessageType.CALL, this.seqid);
  var args = new ProductDistributorService.ProductDistributorService_createOrUpdateDistributorProductPackagingAssocs_args();
  args.sessionId = sessionId;
  args.assocs = assocs;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_createOrUpdateDistributorProductPackagingAssocs() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

ProductDistributorService.ProductDistributorServiceClient.prototype.recv_createOrUpdateDistributorProductPackagingAssocs = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new ProductDistributorService.ProductDistributorService_createOrUpdateDistributorProductPackagingAssocs_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.accountDoesNotHaveAccessToProductException) {
    throw result.accountDoesNotHaveAccessToProductException;
  }
  if (null !== result.unknownDistributorIdException) {
    throw result.unknownDistributorIdException;
  }
  if (null !== result.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException) {
    throw result.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException;
  }
  return;
};
ProductDistributorService.ProductDistributorServiceClient.prototype.disassociateDistributorProductPackagingAssocs = function(sessionId, assocs, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_disassociateDistributorProductPackagingAssocs(pendingError, sessionId, assocs, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_disassociateDistributorProductPackagingAssocs();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

ProductDistributorService.ProductDistributorServiceClient.prototype.send_disassociateDistributorProductPackagingAssocs = function(pendingError, sessionId, assocs, callback) {
  this.output.writeMessageBegin('disassociateDistributorProductPackagingAssocs', Thrift.MessageType.CALL, this.seqid);
  var args = new ProductDistributorService.ProductDistributorService_disassociateDistributorProductPackagingAssocs_args();
  args.sessionId = sessionId;
  args.assocs = assocs;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_disassociateDistributorProductPackagingAssocs() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

ProductDistributorService.ProductDistributorServiceClient.prototype.recv_disassociateDistributorProductPackagingAssocs = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new ProductDistributorService.ProductDistributorService_disassociateDistributorProductPackagingAssocs_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.accountDoesNotHaveAccessToProductException) {
    throw result.accountDoesNotHaveAccessToProductException;
  }
  if (null !== result.unknownDistributorIdException) {
    throw result.unknownDistributorIdException;
  }
  if (null !== result.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException) {
    throw result.locationAssociatedWithProductIdDoesNotHaveAccessToDistributorException;
  }
  return;
};
ProductDistributorService.ProductDistributorServiceClient.prototype.disassociateDistributorFromProductPackagings = function(sessionId, productId, productQuantityUnits, distributorId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_disassociateDistributorFromProductPackagings(pendingError, sessionId, productId, productQuantityUnits, distributorId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_disassociateDistributorFromProductPackagings();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

ProductDistributorService.ProductDistributorServiceClient.prototype.send_disassociateDistributorFromProductPackagings = function(pendingError, sessionId, productId, productQuantityUnits, distributorId, callback) {
  this.output.writeMessageBegin('disassociateDistributorFromProductPackagings', Thrift.MessageType.CALL, this.seqid);
  var args = new ProductDistributorService.ProductDistributorService_disassociateDistributorFromProductPackagings_args();
  args.sessionId = sessionId;
  args.productId = productId;
  args.productQuantityUnits = productQuantityUnits;
  args.distributorId = distributorId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_disassociateDistributorFromProductPackagings() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

ProductDistributorService.ProductDistributorServiceClient.prototype.recv_disassociateDistributorFromProductPackagings = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new ProductDistributorService.ProductDistributorService_disassociateDistributorFromProductPackagings_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.accountDoesNotHaveAccessToProductException) {
    throw result.accountDoesNotHaveAccessToProductException;
  }
  if (null !== result.unknownDistributorIdException) {
    throw result.unknownDistributorIdException;
  }
  if (null !== result.duplicateProductIdException) {
    throw result.duplicateProductIdException;
  }
  return;
};
ProductDistributorService.ProductDistributorServiceClient.prototype.disassociateDistributorFromProducts = function(sessionId, productIds, distributorId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_disassociateDistributorFromProducts(pendingError, sessionId, productIds, distributorId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_disassociateDistributorFromProducts();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

ProductDistributorService.ProductDistributorServiceClient.prototype.send_disassociateDistributorFromProducts = function(pendingError, sessionId, productIds, distributorId, callback) {
  this.output.writeMessageBegin('disassociateDistributorFromProducts', Thrift.MessageType.CALL, this.seqid);
  var args = new ProductDistributorService.ProductDistributorService_disassociateDistributorFromProducts_args();
  args.sessionId = sessionId;
  args.productIds = productIds;
  args.distributorId = distributorId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_disassociateDistributorFromProducts() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

ProductDistributorService.ProductDistributorServiceClient.prototype.recv_disassociateDistributorFromProducts = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new ProductDistributorService.ProductDistributorService_disassociateDistributorFromProducts_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.accountDoesNotHaveAccessToProductException) {
    throw result.accountDoesNotHaveAccessToProductException;
  }
  if (null !== result.unknownDistributorIdException) {
    throw result.unknownDistributorIdException;
  }
  if (null !== result.duplicateProductIdException) {
    throw result.duplicateProductIdException;
  }
  return;
};
ProductDistributorService.ProductDistributorServiceClient.prototype.disassociateAllDistributorsFromProducts = function(sessionId, productIds, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_disassociateAllDistributorsFromProducts(pendingError, sessionId, productIds, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_disassociateAllDistributorsFromProducts();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

ProductDistributorService.ProductDistributorServiceClient.prototype.send_disassociateAllDistributorsFromProducts = function(pendingError, sessionId, productIds, callback) {
  this.output.writeMessageBegin('disassociateAllDistributorsFromProducts', Thrift.MessageType.CALL, this.seqid);
  var args = new ProductDistributorService.ProductDistributorService_disassociateAllDistributorsFromProducts_args();
  args.sessionId = sessionId;
  args.productIds = productIds;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_disassociateAllDistributorsFromProducts() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

ProductDistributorService.ProductDistributorServiceClient.prototype.recv_disassociateAllDistributorsFromProducts = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new ProductDistributorService.ProductDistributorService_disassociateAllDistributorsFromProducts_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.accountDoesNotHaveAccessToProductException) {
    throw result.accountDoesNotHaveAccessToProductException;
  }
  if (null !== result.unknownDistributorIdException) {
    throw result.unknownDistributorIdException;
  }
  if (null !== result.duplicateProductIdException) {
    throw result.duplicateProductIdException;
  }
  return;
};
