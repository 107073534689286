import React from 'react';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemWithMetadata } from 'api/SalesItem/model/SalesItemWithMetadata';

import { Popover } from 'shared/components/Popover/Popover';

import { LinkedItem } from './LinkedItem';

export interface ILinkedItemsPopoverProps {
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>;
    linkedItemsPopoverShown : boolean;
    handleSetLinkedItemsPopoverIsShown : (isShown : boolean) => void;
    linkedItems : StringValueSet<SalesItemId>;
}

export class LinkedItemsPopover extends React.Component<ILinkedItemsPopoverProps, object> {

    public render() {
        const {
            salesItemsById,
            linkedItemsPopoverShown,
            handleSetLinkedItemsPopoverIsShown,
            linkedItems,
        } = this.props;

        return (
            <div className="linked-items-popover-container">
                <Popover
                    preferredPositionArray={ ['below', 'left', 'right', 'above'] }
                    className="popover-linked-items"
                    showOnHover={ true }
                    popoverContentIsShown={ linkedItemsPopoverShown }
                    setPopoverContentIsShown={ handleSetLinkedItemsPopoverIsShown }
                    anchorContent={ (
                        <div className="popup-control flex-text-with-icon middle">
                            <span className="bevicon bevico-link icon-left"/>
                            {
                                <p className="popup-control-text">Item linked in { linkedItems.size } other { linkedItems.size > 1 ? 'recipes' : 'recipe' }</p>
                            }
                        </div>
                    ) }
                    popoverContent={ (
                        <ul className="popup-target">
                            {
                                Array.from(linkedItems.values()).map((linkedItem, index) => {
                                    const salesItem : SalesItemWithMetadata | undefined = salesItemsById.get(linkedItem);

                                    if (typeof salesItem !== 'undefined') {
                                        const itemName = salesItem.getSalesItem().getName();
                                        const serving = salesItem.getSalesItem().getServingSize().getQuantity();
                                        const salesPrice = salesItem.getSalesItem().getSalesPrice();

                                        return (
                                            <LinkedItem key={ index } itemName={ itemName } serving={ serving } salesPrice={ salesPrice }/>
                                        );
                                    }
                                })
                            }
                        </ul>
                    ) }
                />
            </div>
        );
    }
}
