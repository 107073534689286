//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';
import ProductModel from './product_Model_types';



var InventoryTransferReportExceptions = {};
export default InventoryTransferReportExceptions;
InventoryTransferReportExceptions.UnknownReportIdentifierException = function(args) {
};
Thrift.inherits(InventoryTransferReportExceptions.UnknownReportIdentifierException, Thrift.TException);
InventoryTransferReportExceptions.UnknownReportIdentifierException.prototype.name = 'UnknownReportIdentifierException';
InventoryTransferReportExceptions.UnknownReportIdentifierException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportExceptions.UnknownReportIdentifierException.prototype.write = function(output) {
  output.writeStructBegin('UnknownReportIdentifierException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportExceptions.PerspectiveLocationCannotEqualPartnerLocationException = function(args) {
};
Thrift.inherits(InventoryTransferReportExceptions.PerspectiveLocationCannotEqualPartnerLocationException, Thrift.TException);
InventoryTransferReportExceptions.PerspectiveLocationCannotEqualPartnerLocationException.prototype.name = 'PerspectiveLocationCannotEqualPartnerLocationException';
InventoryTransferReportExceptions.PerspectiveLocationCannotEqualPartnerLocationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportExceptions.PerspectiveLocationCannotEqualPartnerLocationException.prototype.write = function(output) {
  output.writeStructBegin('PerspectiveLocationCannotEqualPartnerLocationException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportExceptions.ProductsHaveNoMappingAtPartnerLocationException = function(args) {
  this.unmappedProducts = null;
  if (args) {
    if (args.unmappedProducts !== undefined && args.unmappedProducts !== null) {
      this.unmappedProducts = Thrift.copyList(args.unmappedProducts, [ProductModel.ProductId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field unmappedProducts is unset!');
    }
  }
};
Thrift.inherits(InventoryTransferReportExceptions.ProductsHaveNoMappingAtPartnerLocationException, Thrift.TException);
InventoryTransferReportExceptions.ProductsHaveNoMappingAtPartnerLocationException.prototype.name = 'ProductsHaveNoMappingAtPartnerLocationException';
InventoryTransferReportExceptions.ProductsHaveNoMappingAtPartnerLocationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.unmappedProducts = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new ProductModel.ProductId();
          elem6.read(input);
          this.unmappedProducts.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportExceptions.ProductsHaveNoMappingAtPartnerLocationException.prototype.write = function(output) {
  output.writeStructBegin('ProductsHaveNoMappingAtPartnerLocationException');
  if (this.unmappedProducts !== null && this.unmappedProducts !== undefined) {
    output.writeFieldBegin('unmappedProducts', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.unmappedProducts.length);
    for (var iter7 in this.unmappedProducts)
    {
      if (this.unmappedProducts.hasOwnProperty(iter7))
      {
        iter7 = this.unmappedProducts[iter7];
        iter7.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportExceptions.UnknownPartnerLocationException = function(args) {
};
Thrift.inherits(InventoryTransferReportExceptions.UnknownPartnerLocationException, Thrift.TException);
InventoryTransferReportExceptions.UnknownPartnerLocationException.prototype.name = 'UnknownPartnerLocationException';
InventoryTransferReportExceptions.UnknownPartnerLocationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportExceptions.UnknownPartnerLocationException.prototype.write = function(output) {
  output.writeStructBegin('UnknownPartnerLocationException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportExceptions.PeriodStartIsLaterThanEndException = function(args) {
};
Thrift.inherits(InventoryTransferReportExceptions.PeriodStartIsLaterThanEndException, Thrift.TException);
InventoryTransferReportExceptions.PeriodStartIsLaterThanEndException.prototype.name = 'PeriodStartIsLaterThanEndException';
InventoryTransferReportExceptions.PeriodStartIsLaterThanEndException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportExceptions.PeriodStartIsLaterThanEndException.prototype.write = function(output) {
  output.writeStructBegin('PeriodStartIsLaterThanEndException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportExceptions.PerspectiveLocationNotInvolvedInReportException = function(args) {
};
Thrift.inherits(InventoryTransferReportExceptions.PerspectiveLocationNotInvolvedInReportException, Thrift.TException);
InventoryTransferReportExceptions.PerspectiveLocationNotInvolvedInReportException.prototype.name = 'PerspectiveLocationNotInvolvedInReportException';
InventoryTransferReportExceptions.PerspectiveLocationNotInvolvedInReportException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportExceptions.PerspectiveLocationNotInvolvedInReportException.prototype.write = function(output) {
  output.writeStructBegin('PerspectiveLocationNotInvolvedInReportException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportExceptions.ActorDoesNotHaveAccessToReportException = function(args) {
};
Thrift.inherits(InventoryTransferReportExceptions.ActorDoesNotHaveAccessToReportException, Thrift.TException);
InventoryTransferReportExceptions.ActorDoesNotHaveAccessToReportException.prototype.name = 'ActorDoesNotHaveAccessToReportException';
InventoryTransferReportExceptions.ActorDoesNotHaveAccessToReportException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportExceptions.ActorDoesNotHaveAccessToReportException.prototype.write = function(output) {
  output.writeStructBegin('ActorDoesNotHaveAccessToReportException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportExceptions.AtMostOneQuantityIsAllowedForProductAndUnitException = function(args) {
};
Thrift.inherits(InventoryTransferReportExceptions.AtMostOneQuantityIsAllowedForProductAndUnitException, Thrift.TException);
InventoryTransferReportExceptions.AtMostOneQuantityIsAllowedForProductAndUnitException.prototype.name = 'AtMostOneQuantityIsAllowedForProductAndUnitException';
InventoryTransferReportExceptions.AtMostOneQuantityIsAllowedForProductAndUnitException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportExceptions.AtMostOneQuantityIsAllowedForProductAndUnitException.prototype.write = function(output) {
  output.writeStructBegin('AtMostOneQuantityIsAllowedForProductAndUnitException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportExceptions.TransferHasRetailerPartnerLocationException = function(args) {
};
Thrift.inherits(InventoryTransferReportExceptions.TransferHasRetailerPartnerLocationException, Thrift.TException);
InventoryTransferReportExceptions.TransferHasRetailerPartnerLocationException.prototype.name = 'TransferHasRetailerPartnerLocationException';
InventoryTransferReportExceptions.TransferHasRetailerPartnerLocationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportExceptions.TransferHasRetailerPartnerLocationException.prototype.write = function(output) {
  output.writeStructBegin('TransferHasRetailerPartnerLocationException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

