//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';
import CoreTimeModel from './core_time_Model_types';
import LocationModel from './location_Model_types';
import ProductModel from './product_Model_types';
import MoneyModel from './money_Model_types';
import CoreMathModel from './core_math_Model_types';
import UserAccountsModel from './accounts_user_Model_types';



var SalesItemModel = {};
export default SalesItemModel;
SalesItemModel.SalesItemId = function(args) {
  this.value = null;
  if (args) {
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field value is unset!');
    }
  }
};
SalesItemModel.SalesItemId.prototype = {};
SalesItemModel.SalesItemId.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SalesItemModel.SalesItemId.prototype.write = function(output) {
  output.writeStructBegin('SalesItemId');
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 1);
    output.writeString(this.value);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SalesItemModel.SalesItemIdAndQuantity = function(args) {
  this.salesItemId = null;
  this.quantity = null;
  if (args) {
    if (args.salesItemId !== undefined && args.salesItemId !== null) {
      this.salesItemId = new SalesItemModel.SalesItemId(args.salesItemId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field salesItemId is unset!');
    }
    if (args.quantity !== undefined && args.quantity !== null) {
      this.quantity = new CoreMathModel.Decimal(args.quantity);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field quantity is unset!');
    }
  }
};
SalesItemModel.SalesItemIdAndQuantity.prototype = {};
SalesItemModel.SalesItemIdAndQuantity.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.salesItemId = new SalesItemModel.SalesItemId();
        this.salesItemId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.quantity = new CoreMathModel.Decimal();
        this.quantity.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SalesItemModel.SalesItemIdAndQuantity.prototype.write = function(output) {
  output.writeStructBegin('SalesItemIdAndQuantity');
  if (this.salesItemId !== null && this.salesItemId !== undefined) {
    output.writeFieldBegin('salesItemId', Thrift.Type.STRUCT, 1);
    this.salesItemId.write(output);
    output.writeFieldEnd();
  }
  if (this.quantity !== null && this.quantity !== undefined) {
    output.writeFieldBegin('quantity', Thrift.Type.STRUCT, 2);
    this.quantity.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SalesItemModel.SalesQuantityAndUnit = function(args) {
  this.quantity = null;
  this.unit = null;
  if (args) {
    if (args.quantity !== undefined && args.quantity !== null) {
      this.quantity = new CoreMathModel.Decimal(args.quantity);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field quantity is unset!');
    }
    if (args.unit !== undefined && args.unit !== null) {
      this.unit = new ProductModel.UnitOfMeasure(args.unit);
    }
  }
};
SalesItemModel.SalesQuantityAndUnit.prototype = {};
SalesItemModel.SalesQuantityAndUnit.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.quantity = new CoreMathModel.Decimal();
        this.quantity.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.unit = new ProductModel.UnitOfMeasure();
        this.unit.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SalesItemModel.SalesQuantityAndUnit.prototype.write = function(output) {
  output.writeStructBegin('SalesQuantityAndUnit');
  if (this.quantity !== null && this.quantity !== undefined) {
    output.writeFieldBegin('quantity', Thrift.Type.STRUCT, 1);
    this.quantity.write(output);
    output.writeFieldEnd();
  }
  if (this.unit !== null && this.unit !== undefined) {
    output.writeFieldBegin('unit', Thrift.Type.STRUCT, 2);
    this.unit.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SalesItemModel.SalesItem = function(args) {
  this.name = null;
  this.locationId = null;
  this.menuGroup = null;
  this.posId = null;
  this.note = null;
  this.needsAttentionCategory = null;
  this.salesPrice = null;
  this.miscellaneousCost = null;
  this.componentQuantityOfProductByProductId = null;
  this.componentServingsBySalesItemId = null;
  this.itemYield = null;
  this.servingSize = null;
  this.salesItemCustomUnitName = null;
  if (args) {
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field name is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.menuGroup !== undefined && args.menuGroup !== null) {
      this.menuGroup = args.menuGroup;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field menuGroup is unset!');
    }
    if (args.posId !== undefined && args.posId !== null) {
      this.posId = args.posId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field posId is unset!');
    }
    if (args.note !== undefined && args.note !== null) {
      this.note = args.note;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field note is unset!');
    }
    if (args.needsAttentionCategory !== undefined && args.needsAttentionCategory !== null) {
      this.needsAttentionCategory = args.needsAttentionCategory;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field needsAttentionCategory is unset!');
    }
    if (args.salesPrice !== undefined && args.salesPrice !== null) {
      this.salesPrice = new MoneyModel.MonetaryValue(args.salesPrice);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field salesPrice is unset!');
    }
    if (args.miscellaneousCost !== undefined && args.miscellaneousCost !== null) {
      this.miscellaneousCost = new MoneyModel.MonetaryValue(args.miscellaneousCost);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field miscellaneousCost is unset!');
    }
    if (args.componentQuantityOfProductByProductId !== undefined && args.componentQuantityOfProductByProductId !== null) {
      this.componentQuantityOfProductByProductId = Thrift.copyList(args.componentQuantityOfProductByProductId, [ProductModel.ProductIdAndQuantityOfProduct]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field componentQuantityOfProductByProductId is unset!');
    }
    if (args.componentServingsBySalesItemId !== undefined && args.componentServingsBySalesItemId !== null) {
      this.componentServingsBySalesItemId = Thrift.copyList(args.componentServingsBySalesItemId, [SalesItemModel.SalesItemIdAndQuantity]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field componentServingsBySalesItemId is unset!');
    }
    if (args.itemYield !== undefined && args.itemYield !== null) {
      this.itemYield = new SalesItemModel.SalesQuantityAndUnit(args.itemYield);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field itemYield is unset!');
    }
    if (args.servingSize !== undefined && args.servingSize !== null) {
      this.servingSize = new SalesItemModel.SalesQuantityAndUnit(args.servingSize);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field servingSize is unset!');
    }
    if (args.salesItemCustomUnitName !== undefined && args.salesItemCustomUnitName !== null) {
      this.salesItemCustomUnitName = args.salesItemCustomUnitName;
    }
  }
};
SalesItemModel.SalesItem.prototype = {};
SalesItemModel.SalesItem.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.menuGroup = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.posId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.note = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.needsAttentionCategory = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.salesPrice = new MoneyModel.MonetaryValue();
        this.salesPrice.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.miscellaneousCost = new MoneyModel.MonetaryValue();
        this.miscellaneousCost.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.componentQuantityOfProductByProductId = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new ProductModel.ProductIdAndQuantityOfProduct();
          elem6.read(input);
          this.componentQuantityOfProductByProductId.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.LIST) {
        var _size7 = 0;
        var _rtmp311;
        this.componentServingsBySalesItemId = [];
        var _etype10 = 0;
        _rtmp311 = input.readListBegin();
        _etype10 = _rtmp311.etype;
        _size7 = _rtmp311.size;
        for (var _i12 = 0; _i12 < _size7; ++_i12)
        {
          var elem13 = null;
          elem13 = new SalesItemModel.SalesItemIdAndQuantity();
          elem13.read(input);
          this.componentServingsBySalesItemId.push(elem13);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.STRUCT) {
        this.itemYield = new SalesItemModel.SalesQuantityAndUnit();
        this.itemYield.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.STRUCT) {
        this.servingSize = new SalesItemModel.SalesQuantityAndUnit();
        this.servingSize.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 13:
      if (ftype == Thrift.Type.STRING) {
        this.salesItemCustomUnitName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SalesItemModel.SalesItem.prototype.write = function(output) {
  output.writeStructBegin('SalesItem');
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 1);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.menuGroup !== null && this.menuGroup !== undefined) {
    output.writeFieldBegin('menuGroup', Thrift.Type.STRING, 3);
    output.writeString(this.menuGroup);
    output.writeFieldEnd();
  }
  if (this.posId !== null && this.posId !== undefined) {
    output.writeFieldBegin('posId', Thrift.Type.STRING, 4);
    output.writeString(this.posId);
    output.writeFieldEnd();
  }
  if (this.note !== null && this.note !== undefined) {
    output.writeFieldBegin('note', Thrift.Type.STRING, 5);
    output.writeString(this.note);
    output.writeFieldEnd();
  }
  if (this.needsAttentionCategory !== null && this.needsAttentionCategory !== undefined) {
    output.writeFieldBegin('needsAttentionCategory', Thrift.Type.STRING, 6);
    output.writeString(this.needsAttentionCategory);
    output.writeFieldEnd();
  }
  if (this.salesPrice !== null && this.salesPrice !== undefined) {
    output.writeFieldBegin('salesPrice', Thrift.Type.STRUCT, 7);
    this.salesPrice.write(output);
    output.writeFieldEnd();
  }
  if (this.miscellaneousCost !== null && this.miscellaneousCost !== undefined) {
    output.writeFieldBegin('miscellaneousCost', Thrift.Type.STRUCT, 8);
    this.miscellaneousCost.write(output);
    output.writeFieldEnd();
  }
  if (this.componentQuantityOfProductByProductId !== null && this.componentQuantityOfProductByProductId !== undefined) {
    output.writeFieldBegin('componentQuantityOfProductByProductId', Thrift.Type.LIST, 9);
    output.writeListBegin(Thrift.Type.STRUCT, this.componentQuantityOfProductByProductId.length);
    for (var iter14 in this.componentQuantityOfProductByProductId)
    {
      if (this.componentQuantityOfProductByProductId.hasOwnProperty(iter14))
      {
        iter14 = this.componentQuantityOfProductByProductId[iter14];
        iter14.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.componentServingsBySalesItemId !== null && this.componentServingsBySalesItemId !== undefined) {
    output.writeFieldBegin('componentServingsBySalesItemId', Thrift.Type.LIST, 10);
    output.writeListBegin(Thrift.Type.STRUCT, this.componentServingsBySalesItemId.length);
    for (var iter15 in this.componentServingsBySalesItemId)
    {
      if (this.componentServingsBySalesItemId.hasOwnProperty(iter15))
      {
        iter15 = this.componentServingsBySalesItemId[iter15];
        iter15.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.itemYield !== null && this.itemYield !== undefined) {
    output.writeFieldBegin('itemYield', Thrift.Type.STRUCT, 11);
    this.itemYield.write(output);
    output.writeFieldEnd();
  }
  if (this.servingSize !== null && this.servingSize !== undefined) {
    output.writeFieldBegin('servingSize', Thrift.Type.STRUCT, 12);
    this.servingSize.write(output);
    output.writeFieldEnd();
  }
  if (this.salesItemCustomUnitName !== null && this.salesItemCustomUnitName !== undefined) {
    output.writeFieldBegin('salesItemCustomUnitName', Thrift.Type.STRING, 13);
    output.writeString(this.salesItemCustomUnitName);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SalesItemModel.SalesItemWithMetadata = function(args) {
  this.salesItem = null;
  this.creationMetadata = null;
  this.lastEditedMetadata = null;
  this.deletionMetadata = null;
  this.isArchived = null;
  if (args) {
    if (args.salesItem !== undefined && args.salesItem !== null) {
      this.salesItem = new SalesItemModel.SalesItem(args.salesItem);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field salesItem is unset!');
    }
    if (args.creationMetadata !== undefined && args.creationMetadata !== null) {
      this.creationMetadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp(args.creationMetadata);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field creationMetadata is unset!');
    }
    if (args.lastEditedMetadata !== undefined && args.lastEditedMetadata !== null) {
      this.lastEditedMetadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp(args.lastEditedMetadata);
    }
    if (args.deletionMetadata !== undefined && args.deletionMetadata !== null) {
      this.deletionMetadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp(args.deletionMetadata);
    }
    if (args.isArchived !== undefined && args.isArchived !== null) {
      this.isArchived = args.isArchived;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field isArchived is unset!');
    }
  }
};
SalesItemModel.SalesItemWithMetadata.prototype = {};
SalesItemModel.SalesItemWithMetadata.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.salesItem = new SalesItemModel.SalesItem();
        this.salesItem.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.creationMetadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp();
        this.creationMetadata.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.lastEditedMetadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp();
        this.lastEditedMetadata.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.deletionMetadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp();
        this.deletionMetadata.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.BOOL) {
        this.isArchived = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SalesItemModel.SalesItemWithMetadata.prototype.write = function(output) {
  output.writeStructBegin('SalesItemWithMetadata');
  if (this.salesItem !== null && this.salesItem !== undefined) {
    output.writeFieldBegin('salesItem', Thrift.Type.STRUCT, 1);
    this.salesItem.write(output);
    output.writeFieldEnd();
  }
  if (this.creationMetadata !== null && this.creationMetadata !== undefined) {
    output.writeFieldBegin('creationMetadata', Thrift.Type.STRUCT, 2);
    this.creationMetadata.write(output);
    output.writeFieldEnd();
  }
  if (this.lastEditedMetadata !== null && this.lastEditedMetadata !== undefined) {
    output.writeFieldBegin('lastEditedMetadata', Thrift.Type.STRUCT, 3);
    this.lastEditedMetadata.write(output);
    output.writeFieldEnd();
  }
  if (this.deletionMetadata !== null && this.deletionMetadata !== undefined) {
    output.writeFieldBegin('deletionMetadata', Thrift.Type.STRUCT, 4);
    this.deletionMetadata.write(output);
    output.writeFieldEnd();
  }
  if (this.isArchived !== null && this.isArchived !== undefined) {
    output.writeFieldBegin('isArchived', Thrift.Type.BOOL, 5);
    output.writeBool(this.isArchived);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SalesItemModel.SalesItemAndId = function(args) {
  this.salesItemId = null;
  this.salesItemWithMetadata = null;
  if (args) {
    if (args.salesItemId !== undefined && args.salesItemId !== null) {
      this.salesItemId = new SalesItemModel.SalesItemId(args.salesItemId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field salesItemId is unset!');
    }
    if (args.salesItemWithMetadata !== undefined && args.salesItemWithMetadata !== null) {
      this.salesItemWithMetadata = new SalesItemModel.SalesItemWithMetadata(args.salesItemWithMetadata);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field salesItemWithMetadata is unset!');
    }
  }
};
SalesItemModel.SalesItemAndId.prototype = {};
SalesItemModel.SalesItemAndId.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.salesItemId = new SalesItemModel.SalesItemId();
        this.salesItemId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.salesItemWithMetadata = new SalesItemModel.SalesItemWithMetadata();
        this.salesItemWithMetadata.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SalesItemModel.SalesItemAndId.prototype.write = function(output) {
  output.writeStructBegin('SalesItemAndId');
  if (this.salesItemId !== null && this.salesItemId !== undefined) {
    output.writeFieldBegin('salesItemId', Thrift.Type.STRUCT, 1);
    this.salesItemId.write(output);
    output.writeFieldEnd();
  }
  if (this.salesItemWithMetadata !== null && this.salesItemWithMetadata !== undefined) {
    output.writeFieldBegin('salesItemWithMetadata', Thrift.Type.STRUCT, 2);
    this.salesItemWithMetadata.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SalesItemModel.ItemLevelRevenueAndCostReportConfigurationId = function(args) {
  this.value = null;
  if (args) {
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field value is unset!');
    }
  }
};
SalesItemModel.ItemLevelRevenueAndCostReportConfigurationId.prototype = {};
SalesItemModel.ItemLevelRevenueAndCostReportConfigurationId.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SalesItemModel.ItemLevelRevenueAndCostReportConfigurationId.prototype.write = function(output) {
  output.writeStructBegin('ItemLevelRevenueAndCostReportConfigurationId');
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 1);
    output.writeString(this.value);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SalesItemModel.ItemLevelReportConfigurationIdAndSalesItemIds = function(args) {
  this.reportId = null;
  this.salesItemIds = null;
  if (args) {
    if (args.reportId !== undefined && args.reportId !== null) {
      this.reportId = new SalesItemModel.ItemLevelRevenueAndCostReportConfigurationId(args.reportId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field reportId is unset!');
    }
    if (args.salesItemIds !== undefined && args.salesItemIds !== null) {
      this.salesItemIds = Thrift.copyList(args.salesItemIds, [SalesItemModel.SalesItemId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field salesItemIds is unset!');
    }
  }
};
SalesItemModel.ItemLevelReportConfigurationIdAndSalesItemIds.prototype = {};
SalesItemModel.ItemLevelReportConfigurationIdAndSalesItemIds.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.reportId = new SalesItemModel.ItemLevelRevenueAndCostReportConfigurationId();
        this.reportId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size16 = 0;
        var _rtmp320;
        this.salesItemIds = [];
        var _etype19 = 0;
        _rtmp320 = input.readListBegin();
        _etype19 = _rtmp320.etype;
        _size16 = _rtmp320.size;
        for (var _i21 = 0; _i21 < _size16; ++_i21)
        {
          var elem22 = null;
          elem22 = new SalesItemModel.SalesItemId();
          elem22.read(input);
          this.salesItemIds.push(elem22);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SalesItemModel.ItemLevelReportConfigurationIdAndSalesItemIds.prototype.write = function(output) {
  output.writeStructBegin('ItemLevelReportConfigurationIdAndSalesItemIds');
  if (this.reportId !== null && this.reportId !== undefined) {
    output.writeFieldBegin('reportId', Thrift.Type.STRUCT, 1);
    this.reportId.write(output);
    output.writeFieldEnd();
  }
  if (this.salesItemIds !== null && this.salesItemIds !== undefined) {
    output.writeFieldBegin('salesItemIds', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.salesItemIds.length);
    for (var iter23 in this.salesItemIds)
    {
      if (this.salesItemIds.hasOwnProperty(iter23))
      {
        iter23 = this.salesItemIds[iter23];
        iter23.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SalesItemModel.SalesItemIdMapping = function(args) {
  this.oldSalesItemId = null;
  this.newSalesItemId = null;
  if (args) {
    if (args.oldSalesItemId !== undefined && args.oldSalesItemId !== null) {
      this.oldSalesItemId = new SalesItemModel.SalesItemId(args.oldSalesItemId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field oldSalesItemId is unset!');
    }
    if (args.newSalesItemId !== undefined && args.newSalesItemId !== null) {
      this.newSalesItemId = new SalesItemModel.SalesItemId(args.newSalesItemId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field newSalesItemId is unset!');
    }
  }
};
SalesItemModel.SalesItemIdMapping.prototype = {};
SalesItemModel.SalesItemIdMapping.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.oldSalesItemId = new SalesItemModel.SalesItemId();
        this.oldSalesItemId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.newSalesItemId = new SalesItemModel.SalesItemId();
        this.newSalesItemId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SalesItemModel.SalesItemIdMapping.prototype.write = function(output) {
  output.writeStructBegin('SalesItemIdMapping');
  if (this.oldSalesItemId !== null && this.oldSalesItemId !== undefined) {
    output.writeFieldBegin('oldSalesItemId', Thrift.Type.STRUCT, 1);
    this.oldSalesItemId.write(output);
    output.writeFieldEnd();
  }
  if (this.newSalesItemId !== null && this.newSalesItemId !== undefined) {
    output.writeFieldBegin('newSalesItemId', Thrift.Type.STRUCT, 2);
    this.newSalesItemId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SalesItemModel.AdminRemoveSalesItemsResponse = function(args) {
  this.canDelete = null;
  this.deleteSuccess = null;
  this.matchedSalesItemInfoById = null;
  this.hasComponentItemsIds = null;
  this.usedInOtherSalesItemsIds = null;
  this.referencedItemsByReportId = null;
  if (args) {
    if (args.canDelete !== undefined && args.canDelete !== null) {
      this.canDelete = args.canDelete;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field canDelete is unset!');
    }
    if (args.deleteSuccess !== undefined && args.deleteSuccess !== null) {
      this.deleteSuccess = args.deleteSuccess;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field deleteSuccess is unset!');
    }
    if (args.matchedSalesItemInfoById !== undefined && args.matchedSalesItemInfoById !== null) {
      this.matchedSalesItemInfoById = Thrift.copyList(args.matchedSalesItemInfoById, [SalesItemModel.SalesItemAndId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field matchedSalesItemInfoById is unset!');
    }
    if (args.hasComponentItemsIds !== undefined && args.hasComponentItemsIds !== null) {
      this.hasComponentItemsIds = Thrift.copyList(args.hasComponentItemsIds, [SalesItemModel.SalesItemId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field hasComponentItemsIds is unset!');
    }
    if (args.usedInOtherSalesItemsIds !== undefined && args.usedInOtherSalesItemsIds !== null) {
      this.usedInOtherSalesItemsIds = Thrift.copyList(args.usedInOtherSalesItemsIds, [SalesItemModel.SalesItemId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field usedInOtherSalesItemsIds is unset!');
    }
    if (args.referencedItemsByReportId !== undefined && args.referencedItemsByReportId !== null) {
      this.referencedItemsByReportId = Thrift.copyList(args.referencedItemsByReportId, [SalesItemModel.ItemLevelReportConfigurationIdAndSalesItemIds]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field referencedItemsByReportId is unset!');
    }
  }
};
SalesItemModel.AdminRemoveSalesItemsResponse.prototype = {};
SalesItemModel.AdminRemoveSalesItemsResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.BOOL) {
        this.canDelete = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.BOOL) {
        this.deleteSuccess = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        var _size24 = 0;
        var _rtmp328;
        this.matchedSalesItemInfoById = [];
        var _etype27 = 0;
        _rtmp328 = input.readListBegin();
        _etype27 = _rtmp328.etype;
        _size24 = _rtmp328.size;
        for (var _i29 = 0; _i29 < _size24; ++_i29)
        {
          var elem30 = null;
          elem30 = new SalesItemModel.SalesItemAndId();
          elem30.read(input);
          this.matchedSalesItemInfoById.push(elem30);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.LIST) {
        var _size31 = 0;
        var _rtmp335;
        this.hasComponentItemsIds = [];
        var _etype34 = 0;
        _rtmp335 = input.readListBegin();
        _etype34 = _rtmp335.etype;
        _size31 = _rtmp335.size;
        for (var _i36 = 0; _i36 < _size31; ++_i36)
        {
          var elem37 = null;
          elem37 = new SalesItemModel.SalesItemId();
          elem37.read(input);
          this.hasComponentItemsIds.push(elem37);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.LIST) {
        var _size38 = 0;
        var _rtmp342;
        this.usedInOtherSalesItemsIds = [];
        var _etype41 = 0;
        _rtmp342 = input.readListBegin();
        _etype41 = _rtmp342.etype;
        _size38 = _rtmp342.size;
        for (var _i43 = 0; _i43 < _size38; ++_i43)
        {
          var elem44 = null;
          elem44 = new SalesItemModel.SalesItemId();
          elem44.read(input);
          this.usedInOtherSalesItemsIds.push(elem44);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.LIST) {
        var _size45 = 0;
        var _rtmp349;
        this.referencedItemsByReportId = [];
        var _etype48 = 0;
        _rtmp349 = input.readListBegin();
        _etype48 = _rtmp349.etype;
        _size45 = _rtmp349.size;
        for (var _i50 = 0; _i50 < _size45; ++_i50)
        {
          var elem51 = null;
          elem51 = new SalesItemModel.ItemLevelReportConfigurationIdAndSalesItemIds();
          elem51.read(input);
          this.referencedItemsByReportId.push(elem51);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SalesItemModel.AdminRemoveSalesItemsResponse.prototype.write = function(output) {
  output.writeStructBegin('AdminRemoveSalesItemsResponse');
  if (this.canDelete !== null && this.canDelete !== undefined) {
    output.writeFieldBegin('canDelete', Thrift.Type.BOOL, 1);
    output.writeBool(this.canDelete);
    output.writeFieldEnd();
  }
  if (this.deleteSuccess !== null && this.deleteSuccess !== undefined) {
    output.writeFieldBegin('deleteSuccess', Thrift.Type.BOOL, 2);
    output.writeBool(this.deleteSuccess);
    output.writeFieldEnd();
  }
  if (this.matchedSalesItemInfoById !== null && this.matchedSalesItemInfoById !== undefined) {
    output.writeFieldBegin('matchedSalesItemInfoById', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRUCT, this.matchedSalesItemInfoById.length);
    for (var iter52 in this.matchedSalesItemInfoById)
    {
      if (this.matchedSalesItemInfoById.hasOwnProperty(iter52))
      {
        iter52 = this.matchedSalesItemInfoById[iter52];
        iter52.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.hasComponentItemsIds !== null && this.hasComponentItemsIds !== undefined) {
    output.writeFieldBegin('hasComponentItemsIds', Thrift.Type.LIST, 4);
    output.writeListBegin(Thrift.Type.STRUCT, this.hasComponentItemsIds.length);
    for (var iter53 in this.hasComponentItemsIds)
    {
      if (this.hasComponentItemsIds.hasOwnProperty(iter53))
      {
        iter53 = this.hasComponentItemsIds[iter53];
        iter53.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.usedInOtherSalesItemsIds !== null && this.usedInOtherSalesItemsIds !== undefined) {
    output.writeFieldBegin('usedInOtherSalesItemsIds', Thrift.Type.LIST, 5);
    output.writeListBegin(Thrift.Type.STRUCT, this.usedInOtherSalesItemsIds.length);
    for (var iter54 in this.usedInOtherSalesItemsIds)
    {
      if (this.usedInOtherSalesItemsIds.hasOwnProperty(iter54))
      {
        iter54 = this.usedInOtherSalesItemsIds[iter54];
        iter54.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.referencedItemsByReportId !== null && this.referencedItemsByReportId !== undefined) {
    output.writeFieldBegin('referencedItemsByReportId', Thrift.Type.LIST, 6);
    output.writeListBegin(Thrift.Type.STRUCT, this.referencedItemsByReportId.length);
    for (var iter55 in this.referencedItemsByReportId)
    {
      if (this.referencedItemsByReportId.hasOwnProperty(iter55))
      {
        iter55 = this.referencedItemsByReportId[iter55];
        iter55.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SalesItemModel.SalesItemImageUploadUrl = function(args) {
  this.salesItemId = null;
  this.url = null;
  if (args) {
    if (args.salesItemId !== undefined && args.salesItemId !== null) {
      this.salesItemId = new SalesItemModel.SalesItemId(args.salesItemId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field salesItemId is unset!');
    }
    if (args.url !== undefined && args.url !== null) {
      this.url = args.url;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field url is unset!');
    }
  }
};
SalesItemModel.SalesItemImageUploadUrl.prototype = {};
SalesItemModel.SalesItemImageUploadUrl.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.salesItemId = new SalesItemModel.SalesItemId();
        this.salesItemId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.url = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SalesItemModel.SalesItemImageUploadUrl.prototype.write = function(output) {
  output.writeStructBegin('SalesItemImageUploadUrl');
  if (this.salesItemId !== null && this.salesItemId !== undefined) {
    output.writeFieldBegin('salesItemId', Thrift.Type.STRUCT, 1);
    this.salesItemId.write(output);
    output.writeFieldEnd();
  }
  if (this.url !== null && this.url !== undefined) {
    output.writeFieldBegin('url', Thrift.Type.STRING, 2);
    output.writeString(this.url);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

