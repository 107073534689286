import React from 'react';

import { ValidationInput } from 'shared/components/ValidationInput';

import { DateTime } from '../../models/DateTime';
import { IFormValue } from '../../models/FormValue';

import { IValidationResult } from '../../validators/InputValidators';

import { BevSpotTimePickerUtils } from './utils';

export interface IBevSpotTimePickerHourInput {
    dateTime : DateTime;
    formValue : IFormValue<string>;
    setErrorMessage : (errorMessage : string | null) => void;
    setFormValue : (formValue : string) => void;
    setIsValid : (isValid : boolean) => void;
    setSelectedDateTime : (selectedDateTime : DateTime) => void;
}

export class BevSpotTimePickerHourInput extends React.Component<IBevSpotTimePickerHourInput, object> {
    public render() {
        const {
            formValue,
        } = this.props;
        const disabled = formValue.isValid ? null : ' disabled';
        return (
            <div className="bevspot-time-picker-hour-input">
                <div className={ 'time-picker-button increment-hour ' + disabled }  onClick={ this.incrementHour }>
                    <span className="bevicon bevico-keyboard-arrow-up"/>
                </div>
                <ValidationInput
                    type="number"
                    step={ 1 }
                    label={ null }
                    hintText={ null }
                    value={ (formValue.value !== null) ? formValue.value : '' }
                    autoFocus={ false }
                    autoComplete={ null }
                    isValid={ formValue.isValid }
                    errorMessage=""
                    inputClassName=""
                    handleBlur={ this.validateHour }
                    handleFocus={ null }
                    handleChange={ this.setHour }
                    handleEnterClick={ this.returnUndefined }
                    isDisabled={ false }
                />
                <div className={ 'time-picker-button decrement-hour ' + disabled } onClick={ this.decrementHour }>
                    <span className="bevicon bevico-keyboard-arrow-down" />
                </div>
            </div>
        );
    }
    private returnUndefined = () => undefined;
    private setHour = (event : React.ChangeEvent<HTMLInputElement>) : void => {
        const {
            formValue,
            setErrorMessage,
            setIsValid,
            setFormValue,
        } = this.props;

        const input = event.currentTarget;
        if (!formValue.isValid) {
            setErrorMessage(null);
            setIsValid(true);
        }
        setFormValue(input.value);
    }
    private incrementHour = () : void => {
        if (this.props.formValue.isValid) {
            const dateTimeWithIncrementedHour : DateTime = BevSpotTimePickerUtils.incrementHour(this.props.dateTime);
            this.props.setFormValue(dateTimeWithIncrementedHour.getValue('h'));
            this.props.setSelectedDateTime(dateTimeWithIncrementedHour);
        }
    }
    private decrementHour = () : void => {
        if (this.props.formValue.isValid) {
            const dateTimeWithDecrementedHour : DateTime = BevSpotTimePickerUtils.decrementHour(this.props.dateTime);
            this.props.setFormValue(dateTimeWithDecrementedHour.getValue('h'));
            this.props.setSelectedDateTime(dateTimeWithDecrementedHour);
        }
    }
    private validateHour = (event : React.FocusEvent<HTMLInputElement>) : void => {
        const {
            dateTime,
            setFormValue,
            setErrorMessage,
            setIsValid,
            setSelectedDateTime,
        } = this.props;
        const input = event.currentTarget;
        const newHour = parseInt(input.value, 10);
        const inputValue : string = input.value.length === 2 && newHour < 10 ? newHour.toString() : input.value;
        const validationResult : IValidationResult = BevSpotTimePickerUtils.validateHour(inputValue);
        setFormValue(inputValue);
        if (validationResult.isValid) {
            const dateTimeWithNewHour : DateTime = BevSpotTimePickerUtils.setHour(dateTime, newHour);
            setSelectedDateTime(dateTimeWithNewHour);
        } else {
            setErrorMessage(validationResult.errorMessage);
            setIsValid(false);
        }
    }
}
