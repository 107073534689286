import moment from 'moment-timezone';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { InventoryCountId } from 'api/InventoryCount/model/InventoryCountId';
import { LocationId } from 'api/Location/model/LocationId';
import { ProductId } from 'api/Product/model/ProductId';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { DJANGO_API_UTC_TIMESTAMP_FORMAT } from 'shared/constants';
import { AjaxUtils } from 'shared/utils/ajaxUtils';
import { IItemLevelSalesReportConfigurationService } from '../interfaces/IItemLevelSalesReportConfigurationService';
import { ItemLevelSalesReportConfiguration } from '../model/ItemLevelSalesReportConfiguration';
import { ItemLevelSalesReportConfigurationId } from '../model/ItemLevelSalesReportConfigurationId';
import { SalesEntry } from '../model/SalesEntry';
import {
    IItemLevelSalesReportConfigurationJSONObject,
    ItemLevelSalesReportConfigurationSerializer
} from '../serializer/ItemLevelSalesReportConfigurationSerializer';

export class ItemLevelSalesReportConfigurationServiceImpl implements IItemLevelSalesReportConfigurationService {
    private readonly serializer : ItemLevelSalesReportConfigurationSerializer;

    constructor (
        serializer : ItemLevelSalesReportConfigurationSerializer,
    ) {
        this.serializer = serializer;
    }

    public getItemLevelSalesReportConfigurationsByIdForInventoryCount(
        inventoryCountId : InventoryCountId,
    ) : Promise<StringValueMap<ItemLevelSalesReportConfigurationId, ItemLevelSalesReportConfiguration>> {
        const queryParameters = {
            inventory_id: inventoryCountId.getValue()
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:item_level_revenue_and_cost_report_configuration'), queryParameters)
        .then((response : {report_configurations_by_id : {[reportConfigurationId : string] : IItemLevelSalesReportConfigurationJSONObject}}) => {
            return Promise.resolve(this.serializer.getItemLevelSalesReportConfigurationsById(response.report_configurations_by_id));
        })
        .catch((error : Error) => {
            return Promise.reject(error);
        });
    }

    public getItemLevelSalesReportConfigurationIdsForLocation(
        locationId : LocationId,
    ) : Promise<StringValueSet<ItemLevelSalesReportConfigurationId>> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:item_level_revenue_and_cost_report_configuration'), queryParameters)
        .then((reportIds : Array<string>) => {
            return Promise.resolve(new StringValueSet(reportIds.map((idValue) => new ItemLevelSalesReportConfigurationId(idValue))));
        })
        .catch((error : Error) => {
            return Promise.reject(error);
        });
    }

    public getItemLevelSalesReportProcessedTimeByConfigurationIdInTimeRange(
        locationId : LocationId,
        startTimeInclusive : moment.Moment,
        endTimeExclusive : moment.Moment
    ) : Promise<StringValueMap<ItemLevelSalesReportConfigurationId, moment.Moment>> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
            start_time_inclusive: this.serializeTimestamp(startTimeInclusive),
            end_time_exclusive: this.serializeTimestamp(endTimeExclusive),
        };
        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:item_level_revenue_and_cost_report_configuration_processed_time'), queryParameters)
        .then((response : {[key : string] : string}) => {
            const result = new StringValueMap<ItemLevelSalesReportConfigurationId, moment.Moment>();
            Object.keys(response).forEach((key) => {
                result.set(new ItemLevelSalesReportConfigurationId(key), moment.utc(response[key]));
            });
            return Promise.resolve(result);
        })
        .catch((error : Error) => {
            return Promise.reject(error);
        });
    }

    public getItemLevelSalesReportConfigurationForId(
        reportId : ItemLevelSalesReportConfigurationId
    ) : Promise<ItemLevelSalesReportConfiguration> {
        const queryParameters = {
            configuration_id: reportId.getValue(),
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:item_level_revenue_and_cost_report_configuration'), queryParameters)
        .then((response : any) => {
            return Promise.resolve(this.serializer.getItemLevelSalesReportConfiguration(response.item_level_revenue_and_cost_report_configuration));
        })
        .catch((error : Error) => {
            return Promise.reject(error);
        });
    }

    public addOrUpdateSalesEntry(
        reportId : ItemLevelSalesReportConfigurationId,
        salesItemId : SalesItemId,
        salesEntry : SalesEntry
    ) : Promise<void> {

        // CURRENT FORM IMPL IN PYTHON:
        // price = FloatField(required=False)
        // quantity = IntegerField(required=False)
        // quantity_adjustment = IntegerField(required=False, allow_negatives=True)
        // sales_adjustment = FloatField(required=False)

        const formData : FormData = new FormData();
        formData.append('item_level_revenue_and_cost_report_configuration_id', reportId.getValue());
        formData.append('sales_item_id', salesItemId.getValue());

        const price = salesEntry.getPrice();
        if (price !== null) {
            formData.append('price', price.toString());
        }
        const quantity = salesEntry.getQuantity();
        if (quantity !== null) {
            formData.append('quantity', quantity.toString());
        }
        const quantityAdjustment = salesEntry.getQuantityAdjustment();
        if (quantityAdjustment !== null) {
            formData.append('quantity_adjustment', quantityAdjustment.toString());
        }
        const salesAdjustment = salesEntry.getSalesAdjustment();
        if (salesAdjustment !== null) {
            formData.append('sales_adjustment',  salesAdjustment.toString());
        }

        return AjaxUtils.ajaxPostForm(
            url('sales:reports:item_level:add_or_update_sales_entry', null, window.GLOBAL_RETAILER_ID, null),
            formData
        ).then((response) => {
            if (!response.success) {
                throw new Error('failed to update');
            }
        });
    }

    public addOrUpdateSalesEntries(
        reportId: ItemLevelSalesReportConfigurationId,
        salesEntryBySalesItemId: StringValueMap<SalesItemId, SalesEntry>
    ): Promise<void> {
        return AjaxUtils.ajaxPut(
            url('sales:reports:item_level:add_or_update_sales_entries', null, window.GLOBAL_RETAILER_ID, null),
            {
                item_level_revenue_and_cost_report_configuration_id: reportId.getValue(),
                sales_entries_by_sales_item_id: this.serializeSalesEntriesToJson(salesEntryBySalesItemId)
            }
        );
    }

    public removeSalesEntries(
        reportId : ItemLevelSalesReportConfigurationId,
        salesItemIds : StringValueSet<SalesItemId>,
    ) : Promise<void> {
        const idValues = new Set<string>();
        salesItemIds.forEach((salesItemId) => {
            idValues.add(salesItemId.getValue());
        });

        const requestBody = {
            sales_item_ids_to_remove: idValues,
            item_level_revenue_and_cost_report_configuration_id: reportId.getValue()
        };

        return AjaxUtils.ajaxPut(
            url('sales:reports:item_level:remove_sales_items', null, window.GLOBAL_RETAILER_ID, null),
            requestBody
        ).then((response) => {
            if (!response.success) {
                throw new Error('failed to update');
            }
        });
    }

    public updateTrackingStatesForProducts(
        reportId : ItemLevelSalesReportConfigurationId,
        isTrackedByProductId : StringValueMap<ProductId, boolean>
    ) {
        const formData : FormData = new FormData();
        formData.append('item_level_revenue_and_cost_report_configuration_id', reportId.getValue());

        const isTrackedByProductIdValue : Record<string, boolean> = {};
        isTrackedByProductId.forEach((isTracked, productId) => {
            isTrackedByProductIdValue[productId.getValue()] = isTracked;
        });
        formData.append('is_tracked_by_product_id', JSON.stringify(isTrackedByProductIdValue));

        return AjaxUtils.ajaxPostForm(
            url('sales:reports:item_level:update_tracking_states_for_products', null, window.GLOBAL_RETAILER_ID, null),
            formData
        ).then((response) => {
            if (!response.success) {
                throw new Error('failed to update');
            }
        });
    }

    public updateShouldTrackItemsWithNoComponents(reportId : ItemLevelSalesReportConfigurationId, shouldTrackItems : boolean) : Promise<void> {
        const formData : FormData = new FormData();
        formData.append('item_level_revenue_and_cost_report_configuration_id', reportId.getValue());
        formData.append('should_track_items_with_no_components', JSON.stringify(shouldTrackItems));

        return AjaxUtils.ajaxPostForm(
            url('sales:reports:item_level:update_should_track_sales_items_with_no_components', null, window.GLOBAL_RETAILER_ID, null),
            formData
        ).then((response) => {
            if (!response.success) {
                throw new Error('failed to update');
            }
        });

    }

    public deleteReport(reportId : ItemLevelSalesReportConfigurationId) : Promise<void> {
        const formData : FormData = new FormData();
        formData.append('item_level_revenue_and_cost_report_configuration_id', reportId.getValue());

        return AjaxUtils.ajaxPostForm(
            url('sales:reports:item_level:delete_report_configuration', null, window.GLOBAL_RETAILER_ID, null),
            formData
        ).then((response) => {
            if (!response.success) {
                throw new Error('failed to update');
            }
        });
    }

    public createItemLevelSalesReportConfiguration(
        startInventoryId : InventoryCountId,
        endInventoryId : InventoryCountId,
        salesEntries : StringValueMap<SalesItemId, SalesEntry>,
        reportName : string,
    ) : Promise<ItemLevelSalesReportConfigurationId> {
        const formData : FormData = new FormData();
        formData.append('start_inventory_id', startInventoryId.getValue());
        formData.append('end_inventory_id', endInventoryId.getValue());
        formData.append('sales_entries_by_sales_item_id', JSON.stringify(this.serializeSalesEntriesToJson(salesEntries)));
        formData.append('report_name', reportName);

        return AjaxUtils.ajaxPostForm(
            url('sales:reports:item_level:create_report_configuration_new', null, window.GLOBAL_RETAILER_ID, null),
            formData
        ).then((response) => {
            if (!response.success) {
                throw new Error('failed to create');
            }
            return new ItemLevelSalesReportConfigurationId(response.id);
        });

    }

    public notifySalesReportSubscribers(reportId : ItemLevelSalesReportConfigurationId) : Promise<void> {
        const formData : FormData = new FormData();
        formData.append('item_level_revenue_and_cost_report_configuration_id', reportId.getValue());

        return AjaxUtils.ajaxPostForm(
            url('sales:reports:item_level:notify_users', null, window.GLOBAL_RETAILER_ID, null),
            formData
        ).then((response) => {
            if (!response.success) {
                throw new Error('failed to notify');
            }
        });
    }

    private serializeSalesEntriesToJson(salesEntries : StringValueMap<SalesItemId, SalesEntry>) {
        const result : {[key : string] : object} = {};
        salesEntries.forEach(((value, key) => {
            result[key.getValue()] = value.toJson();
        }));
        return result;
    }

    private serializeTimestamp(time : moment.Moment) : string {
        return time.utc().format(DJANGO_API_UTC_TIMESTAMP_FORMAT);
    }

}
