import React from 'react';

import { IOption, OptionValue } from '../Dropdown/DropdownMenu';

export interface ISelect2DropdownOptionProps {
    onOptionSelect : (optionValue : OptionValue) => void;
    option : IOption;
    isSelected : boolean;
}

export class Select2DropdownOption extends React.Component<ISelect2DropdownOptionProps, object> {
    public render() {
        const {
            option,
            isSelected,
        } = this.props;

        const selectedOptionCheckmark = isSelected ? 'bevicon bevico-check' : '';

        const optionClasses = ['select-2-dropdown-menu-option'];
        if (option.disabled) {
            optionClasses.push('disabled-option');
        }
        if (isSelected) {
            optionClasses.push('selected-option');
        }

        return (
            <div
                className={ optionClasses.join(' ') }
                onClick={ this.handleOnOptionSelect }
            >
                <span className={ selectedOptionCheckmark } />
                { option.icon &&
                    <span className={ `bevicon ${option.icon}` } />
                }
                <label className="reset-dark-styles">{ option.label }</label>
            </div>
        );
    }

    private readonly handleOnOptionSelect = (event : React.SyntheticEvent<HTMLElement>) => {
        event.stopPropagation();
        if (!this.props.option.disabled) {
            this.props.onOptionSelect(this.props.option.value);
        }
    }
}
