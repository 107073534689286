import { invitationType, InviteInfo } from 'api/UserAccount/model/InviteInfo';

export interface IInviteInfoJSON {
    success : boolean;
    user_already_exists : boolean;
    invited_by_user_name : string;
    invite_type : invitationType;
    invited_to_id : string;
    invited_to_name : string;
}

export class InviteInfoJSONToObjectSerializer {
    public getInviteInfo(inviteInfo : IInviteInfoJSON) {
        return new InviteInfo(
            inviteInfo.user_already_exists,
            inviteInfo.invited_by_user_name,
            inviteInfo.invite_type,
            inviteInfo.invited_to_id,
            inviteInfo.invited_to_name,
        );
    }
}
