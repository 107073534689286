import CoreTimeModel from 'gen-thrift/core_time_Model_types';
// TODO Use different time model

import { UserAccountId } from 'api/UserAccount/model/UserAccountId';

export interface IUserAccountIdAndTimestampJSONObject {
    user_account_id: string;
    timestamp: string;
}

export class UserAccountIdAndTimestamp {
    private readonly userAccountId : UserAccountId;
    private readonly timestamp : CoreTimeModel.TimestampWithMillisecondPrecision;

    constructor(
        userAccountId : UserAccountId,
        timestamp : CoreTimeModel.TimestampWithMillisecondPrecision,
    ) {
        this.userAccountId = userAccountId;
        this.timestamp = timestamp;
    }

    public getUserAccountId = () : UserAccountId => {
        return this.userAccountId;
    }

    public getTimestamp = () : CoreTimeModel.TimestampWithMillisecondPrecision => {
        return this.timestamp;
    }

    public equals(other : any) : boolean {
        if (!(other instanceof UserAccountIdAndTimestamp)) {
            return false;
        }

        return this.getUserAccountId().equals(other.getUserAccountId()) &&
            this.getTimestamp().timeSinceUnixEpoch.value === other.getTimestamp().timeSinceUnixEpoch.value;
    }
}
