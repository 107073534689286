import React from 'react';

import { StringValueMap } from 'api/Core/StringValueMap';
import { MassUnit } from 'api/Product/model/MassUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import { UnitUtils } from 'api/Product/utils/UnitUtils';
import { SalesItem } from 'api/SalesItem/model/SalesItem';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemUtils } from 'api/SalesItem/utils/SalesItemUtils';

import { Button } from 'shared/components/Button';
import { SalesItemInfoPopover } from 'shared/components/SalesItemInfoPopover/SalesItemInfoPopover';
import { ValidationInput, ValidationInputTheme } from 'shared/components/ValidationInput';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { FormatMonetaryValueWithCents } from 'shared/utils/FormatMonetaryValue';

import { IIngredientInformation } from 'api/SalesItem/utils/SalesItemDisplayUtils';
import { ISalesItemIngredientRowInfo, SalesItemIngredientRowFormFieldName } from '../reducers/reducers';

import '../css/AddedIngredients.scss';

export interface ISalesItemIngredientRowProps {
    salesItemId : SalesItemId;
    salesItem : SalesItem | null;
    ingredientRowInfo : ISalesItemIngredientRowInfo;
    handleOnRemoveIngredientClick : (ingredientId : SalesItemId) => void;
    onFormFieldChange : (salesItemId : SalesItemId, formField : SalesItemIngredientRowFormFieldName, value : string) => void;
    onFormFieldBlur : (salesItemId : SalesItemId, fieldName : SalesItemIngredientRowFormFieldName, value : string) => void;
    subrecipeCostsById : StringValueMap<SalesItemId, number | null>;
    salesItemInfoIsShown : boolean;
    handleSetSalesItemInfoIsShown : (isShown : boolean) => void;
    salesItemIngredients : Array<IIngredientInformation>;
    salesItemTotalCost : number | null;
}
export class SalesItemIngredientRow extends React.Component<ISalesItemIngredientRowProps, object> {
    public render() {
        const {
            salesItemId,
            salesItem,
            ingredientRowInfo,
            subrecipeCostsById,
            salesItemInfoIsShown,
            handleSetSalesItemInfoIsShown,
            salesItemIngredients,
            salesItemTotalCost,
        } = this.props;

        if (salesItem === null) {
            throw new Error('unexpected null sales item ingredient');
        }

        const subrecipeName = salesItem.getName();

        const subrecipeCost = subrecipeCostsById.get(salesItemId);
        if (typeof subrecipeCost === 'undefined') {
            throw new RuntimeException('unexpected');
        }

        let subrecipeCostPerServingSize : number | null;
        let salesItemIngredientCostPerQuantity : number | null;
        let isPlural : boolean;
        if (subrecipeCost === null) {
            subrecipeCostPerServingSize = null;
        } else {
            subrecipeCostPerServingSize = SalesItemUtils.getCostOfComponentSalesItem(salesItem, 1, subrecipeCost);
        }
        if (ingredientRowInfo.formInfo.quantity.isValid) {
            const numberOfServings = parseFloat(ingredientRowInfo.formInfo.quantity.value); // may need to put in serving at the bottom
            salesItemIngredientCostPerQuantity = SalesItemUtils.getCostOfComponentSalesItem(salesItem, numberOfServings, subrecipeCost);
            isPlural = numberOfServings !== 1;
        } else {
            salesItemIngredientCostPerQuantity = null;
            isPlural = true;
        }

        const salesYield = salesItem.getItemYield();
        const salesYieldUnit = salesYield.getUnit();
        const servingsPerYield = SalesItemUtils.getServingsPerYield(salesItem.getServingSize(), salesYield);
        const pricePerServing = salesItem.getSalesPrice() / servingsPerYield;
        const salesServingSize = salesItem.getServingSize();
        const salesServingUnit = salesServingSize.getUnit();

        let pricePerServingDisplay : string;
        if (salesYieldUnit === null || salesServingUnit === null) {
            pricePerServingDisplay = `${ FormatMonetaryValueWithCents(subrecipeCostPerServingSize) } / ${ salesItem.getServingSize().getUnit() || salesItem.getSalesItemCustomUnitName() }`;
        } else {
            const servingsPerOneYieldUnit = UnitUtils.convertUnitQuantity(new QuantityInUnit(salesServingSize.getQuantity(), salesServingUnit), salesYieldUnit);
            let convertUnit : VolumeUnit.OUNCE | MassUnit.DRY_OUNCE;
            if (VolumeUnit.isVolumeUnit(salesYieldUnit)) {
                convertUnit = VolumeUnit.OUNCE;
            } else if (MassUnit.isMassUnit(salesYieldUnit)) {
                convertUnit = MassUnit.DRY_OUNCE;
            } else {
                throw new Error('unexpected value for yield unit');
            }

            const servingsPerOneYieldUnitInOz = UnitUtils.convertUnitQuantity(servingsPerOneYieldUnit, convertUnit);
            pricePerServingDisplay = `${ FormatMonetaryValueWithCents(pricePerServing / servingsPerOneYieldUnitInOz.getQuantity()) } / ${ convertUnit }`;
        }

        return (
            <li className="sales-item-ingredient-row ingredient-row">
                <Button
                    buttonClassName="icon flat remove-ingredient-btn"
                    isDisabled={ false }
                    isLoading={ false }
                    onClick={ this.handleOnRemoveIngredientClick }
                >
                    <span className="bevicon bevico-remove-circle-outline"/>
                </Button>
                <div className="ingredient-information-container">
                    <div className="ingredient-information">
                        <SalesItemInfoPopover
                            salesItem={ salesItem }
                            salesItemInfoIsShown={ salesItemInfoIsShown }
                            setSalesItemInfoIsShown={ handleSetSalesItemInfoIsShown }
                            onFullDetailsClick={ null }
                            salesItemIngredients={ salesItemIngredients }
                            salesItemTotalCost={ salesItemTotalCost }
                        >
                            <p className="sales-item-name">{ subrecipeName }</p>
                        </SalesItemInfoPopover>
                        <small className="ingredient-details">
                            <span>Recipe</span>
                            <span>&nbsp;&bull;&nbsp;</span>
                            <span>{ subrecipeCost === null ? 'N/A' : pricePerServingDisplay }</span>
                        </small>
                    </div>
                    <div className="ingredient-quantity">
                        <div className="ingredient-quantity-input-group input-group">
                            <ValidationInput
                                theme={ ValidationInputTheme.BasicInverted }
                                type="text"
                                step="any"
                                label={ null }
                                hintText=""
                                value={ ingredientRowInfo.formInfo.quantity.value }
                                autoFocus={ false }
                                autoComplete={ null }
                                isValid={ ingredientRowInfo.formInfo.quantity.isValid }
                                isDisabled={ false }
                                errorMessage={ ingredientRowInfo.formInfo.quantity.errorMessage }
                                inputClassName=""
                                handleEnterClick={ this.onSalesItemRowQuantityAmountBlurOrEnter }
                                handleChange={ this.onSalesItemRowQuantityAmountChange }
                                handleBlur={ this.onSalesItemRowQuantityAmountBlurOrEnter }
                                handleFocus={ this.doNothing }
                            />
                            <div className="serving">serving{ isPlural ? 's' : '' }</div>
                        </div>
                        <span className="unit-cost">Cost: { FormatMonetaryValueWithCents(salesItemIngredientCostPerQuantity) }</span>
                    </div>
                </div>
            </li>
        );
    }

    private readonly doNothing = () => {
        // pass
    }

    private readonly handleOnRemoveIngredientClick = () => {
        const {
            ingredientRowInfo,
            handleOnRemoveIngredientClick,
        } = this.props;

        handleOnRemoveIngredientClick(ingredientRowInfo.ingredientId);
    }

    private readonly onSalesItemRowQuantityAmountChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const {
            onFormFieldChange,
            ingredientRowInfo,
        } = this.props;

        onFormFieldChange(ingredientRowInfo.ingredientId, 'quantity', event.target.value);
    }

    private readonly onSalesItemRowQuantityAmountBlurOrEnter = (event : React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
        const {
            onFormFieldBlur,
            ingredientRowInfo,
        } = this.props;

        onFormFieldBlur(ingredientRowInfo.ingredientId, 'quantity', event.currentTarget.value);
    }
}
