import { UserSessionId } from "api/UserAccount/model/UserSessionId";

import { userSessionManager } from "shared/lib/manager";

// IGNORES the original session argument passed to the api call, which is acquired through userSessionManager anyway
function handleSessionExpiration<T>(apiCall : (sessionId : UserSessionId) => Promise<T>) : Promise<T> {
    return apiCall(userSessionManager.getSessionId())
    .catch((error : Error) => {
        throw error;
    });
}

export const ThriftApiUtils = {
    sessionSafe: handleSessionExpiration,
};
