import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import AccountsExceptions from 'gen-thrift/accounts_Exceptions_types';
import CorePrimitivesModel from 'gen-thrift/core_primitives_Model_types';
import UserInfoExceptions from 'gen-thrift/user_info_Exceptions_types';

import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';

import { IAction, IActionCreatorsMapObject } from 'shared/models/IAction';
import { validateValueByFieldName } from '../utils';

import { ISignUpFormState, signUpFormFieldName, signUpFormInitialValidationInputDataByFieldName } from './reducers';

import { AddressFormFieldName } from 'shared/components/AddressForm/AddressForm';
import { IValidationInputData } from 'shared/components/ValidationInput';
import { UserAccountManagerImpl } from 'shared/lib/account/impl/UserAccountManagerImpl';
import { IAccountSessionReader } from 'shared/lib/account/interfaces/IAccountSessionReader';

export interface ISignUpFormStore {
}

export const ActionTypes = {
    SET_FORM_FIELD_VALIDATION_DATA : 'SIGN_UP_FORM/SET_FORM_FIELD_VALIDATION_DATA',
    SET_FORM_VALIDATION_DATA : 'SIGN_UP_FORM/SET_FORM_VALIDATION_DATA',
    SET_IS_SUBMITTING : 'SIGN_UP_FORM/SET_IS_SUBMITTING',
    SET_HAS_SUCCESSFULLY_SUBMITTED : 'SIGN_UP_FORM/SET_HAS_SUCCESSFULLY_SUBMITTED',
};

export namespace SignUpFormActionInterfaces {
    export interface ISetFormFieldValidationData extends IAction {
        payload : {
            field : signUpFormFieldName;
            value : string | boolean;
            isValid : boolean;
            errorMessage : string;
        };
    }

    export interface ISetFormValidationData extends IAction {
        payload : {
            isValid : boolean;
            errorMessage : string;
        };
    }

    export interface ISetIsSubmitting extends IAction {
        payload : {
            isSubmitting : boolean;
        };
    }

    export interface ISetHasSuccessfullySubmitted extends IAction {
        payload : {
            hasSuccessfullySubmitted : boolean;
        };
    }

    export interface ISignUpFormActionCreatorsMapObject extends IActionCreatorsMapObject {
        onFormFieldChange : (
            field : signUpFormFieldName,
            value : string | boolean
        ) => ThunkAction<void, ISignUpFormStore, {services : SignUpFormActionInterfaces.IServices}>;
        onFormFieldBlur : (
            field : signUpFormFieldName,
            value : string | boolean,
        ) => ThunkAction<void, ISignUpFormStore, {services : SignUpFormActionInterfaces.IServices}>;
        onSetHasSuccessfullySubmitted : (
            hasSuccessfullySubmitted : boolean,
        ) => ThunkAction<void, ISignUpFormStore, {services : SignUpFormActionInterfaces.IServices}>;
        onResetForm : () => ThunkAction<void, ISignUpFormStore, {services : SignUpFormActionInterfaces.IServices}>;
        onSubmit : (
            formState : ISignUpFormState,
            additionalFieldsAreShown : boolean,
            additionalRetailerFieldsAreRequired : boolean,
            shouldCreateSession : boolean,
        ) => ThunkAction<Promise<UserAccountId | void>, ISignUpFormStore, {services : SignUpFormActionInterfaces.IServices}>;
        onSetAddressFormField : (
            fieldName : AddressFormFieldName,
            validationInputData : IValidationInputData
        ) => ThunkAction<void, ISignUpFormStore, {services : SignUpFormActionInterfaces.IServices}>;
    }

    export interface IServices {
        userAccountManager : UserAccountManagerImpl;
        userSessionReader : IAccountSessionReader<UserSessionId, UserAccountId>;
    }
}

const setFormFieldValidationData = (
    field : signUpFormFieldName,
    value : string | boolean,
    isValid : boolean,
    errorMessage : string
) : SignUpFormActionInterfaces.ISetFormFieldValidationData => ({
    payload : {
        field,
        value,
        isValid,
        errorMessage,
    },
    type : ActionTypes.SET_FORM_FIELD_VALIDATION_DATA,
});

const setFormValidationData = (
    isValid : boolean,
    errorMessage : string
) : SignUpFormActionInterfaces.ISetFormValidationData => ({
    payload : {
        isValid,
        errorMessage,
    },
    type : ActionTypes.SET_FORM_VALIDATION_DATA,
});

const setIsSubmitting = (
    isSubmitting : boolean
) : SignUpFormActionInterfaces.ISetIsSubmitting => ({
    payload : {
        isSubmitting,
    },
    type : ActionTypes.SET_IS_SUBMITTING,
});

const setHasSuccessfullySubmitted = (
    hasSuccessfullySubmitted : boolean
) : SignUpFormActionInterfaces.ISetHasSuccessfullySubmitted => ({
    payload : {
        hasSuccessfullySubmitted,
    },
    type : ActionTypes.SET_HAS_SUCCESSFULLY_SUBMITTED,
});

const onFormFieldChange = (
    field : signUpFormFieldName,
    value : string | boolean,
) : ThunkAction<void, ISignUpFormStore, {services : SignUpFormActionInterfaces.IServices}> => {
    return (dispatch : Dispatch<ISignUpFormStore>, getState : () => ISignUpFormStore, extraArguments : {services : SignUpFormActionInterfaces.IServices}) : void => {
        if (field === 'subscribeToBlog') {
            dispatch(setFormFieldValidationData(field, value, true, ''));
        } else {
            const validationResult = validateValueByFieldName(field, value as string, null);
            dispatch(setFormFieldValidationData(field, value, validationResult.isValid, validationResult.errorMessage));
        }
    };
};

const onFormFieldBlur = (
    field : signUpFormFieldName,
    value : string | boolean,
) : ThunkAction<void, ISignUpFormStore, {services : SignUpFormActionInterfaces.IServices}> => {
    return (dispatch : Dispatch<ISignUpFormStore>, getState : () => ISignUpFormStore, extraArguments : {services : SignUpFormActionInterfaces.IServices}) : void => {
        if (field === 'subscribeToBlog') {
            dispatch(setFormFieldValidationData(field, value, true, ''));
        } else {
            const validationResult = validateValueByFieldName(field, value as string, null);
            dispatch(setFormFieldValidationData(field, value, validationResult.isValid, validationResult.errorMessage));
        }
    };
};

const onSubmit = (
    formState : ISignUpFormState,
    additionalFieldsAreShown : boolean,
    additionalRetailerFieldsAreRequired : boolean,
    shouldCreateSession : boolean,
) : ThunkAction<Promise<UserAccountId | void>, ISignUpFormStore, {services : SignUpFormActionInterfaces.IServices}> => {
    return (dispatch : Dispatch<ISignUpFormStore>, getState : () => ISignUpFormStore, extraArguments : {services : SignUpFormActionInterfaces.IServices}) : Promise<UserAccountId | void> => {
        const validationInputDataByFieldName = formState.validationInputDataByFieldName;
        let formIsValid = true;

        Object.keys(validationInputDataByFieldName).forEach((key) => {
            const fieldName = key as signUpFormFieldName;
            if ((fieldName !== 'subscribeToBlog') && (additionalFieldsAreShown || (fieldName !== 'phone'))) {
                const fieldValue = (validationInputDataByFieldName)[fieldName].value;

                let validationResult;
                if (additionalRetailerFieldsAreRequired && fieldName === 'zipCode') {
                    validationResult = validateValueByFieldName('zipCode', fieldValue, validationInputDataByFieldName.country.value); // need to use the required validation
                } else if (additionalRetailerFieldsAreRequired && fieldName === 'barName') {
                    validationResult = validateValueByFieldName('retailerName', fieldValue, null); // need to use the required validation
                } else if (!additionalRetailerFieldsAreRequired && (fieldName === 'city' || fieldName === 'state' || fieldName === 'streetAddressLine1' || fieldName === 'streetAddressLine2' || fieldName === 'zipCode')) {
                    validationResult = { isValid: true, errorMessage: '' }; // ignore address form if those aren't required
                } else {
                    const countryFieldIsShown = additionalFieldsAreShown && additionalRetailerFieldsAreRequired;
                    validationResult = validateValueByFieldName(fieldName, fieldValue, countryFieldIsShown ? validationInputDataByFieldName.country.value : null);
                }

                const { isValid, errorMessage } = validationResult;
                formIsValid = formIsValid && isValid;

                dispatch(setFormFieldValidationData(fieldName, fieldValue, isValid, errorMessage));
            }
        });

        if (formIsValid) {
            dispatch(setFormValidationData(true, ''));
            dispatch(setIsSubmitting(true));

            let createAccountPromise : Promise<UserAccountId>;
            if (shouldCreateSession) {
                createAccountPromise = extraArguments.services.userAccountManager.createAccountAndAssociateEmailAddressAndSendPasswordSettingEmailAndCreateSession(
                    validationInputDataByFieldName.firstName.value,
                    validationInputDataByFieldName.lastName.value,
                    new CorePrimitivesModel.EmailAddress({ value : validationInputDataByFieldName.emailAddress.value as string }),
                    (validationInputDataByFieldName.phone.value) ? validationInputDataByFieldName.phone.value : null,
                )
                .then(() => {
                    return extraArguments.services.userSessionReader.getAccountId();
                });
            } else {
                createAccountPromise = extraArguments.services.userAccountManager.createAccountAndAssociateEmailAddressAndSendPasswordSettingEmail(
                    validationInputDataByFieldName.firstName.value,
                    validationInputDataByFieldName.lastName.value,
                    new CorePrimitivesModel.EmailAddress({ value : validationInputDataByFieldName.emailAddress.value as string }),
                    (validationInputDataByFieldName.phone.value) ? validationInputDataByFieldName.phone.value : null,
                );
            }

            return createAccountPromise
            .then((userAccountIdentifier) =>  {
                if (typeof window.fbq !== 'undefined') {
                    window.fbq('trackCustom', 'UserSignUp', {
                        page: window.location.href,
                    });
                }

                return userAccountIdentifier;
            }).catch((error) => {
                dispatch(setIsSubmitting(false));
                if (error instanceof AccountsExceptions.EmailAddressAlreadyAssociatedWithAnAccountException) {
                    dispatch(setFormFieldValidationData('emailAddress', validationInputDataByFieldName.emailAddress.value, false, 'Currently associated with another user'));
                } else if (error instanceof UserInfoExceptions.FirstNameInvalidStringLengthException) {
                    dispatch(setFormFieldValidationData('firstName', validationInputDataByFieldName.firstName.value, false, 'Invalid last name'));
                } else if (error instanceof UserInfoExceptions.LastNameInvalidStringLengthException) {
                    dispatch(setFormFieldValidationData('lastName', validationInputDataByFieldName.lastName.value, false, 'Invalid first name'));
                } else if (error instanceof AccountsExceptions.UnknownEmailAddressException) {
                    dispatch(setFormFieldValidationData('emailAddress', validationInputDataByFieldName.emailAddress.value, false, 'Invalid email address'));
                } else if (error instanceof UserInfoExceptions.PhoneNumberInvalidStringLengthException) {
                    dispatch(setFormFieldValidationData('phone', validationInputDataByFieldName.phone.value, false, 'Invalid phone number'));
                } else {
                    dispatch(setFormValidationData(false, 'Something went wrong with the sign up attempt'));
                    throw error;
                }
            });
        } else {
            dispatch(setFormValidationData(false, 'Please correctly fill in the form fields.'));
        }

        return Promise.resolve();
    };
};

const onSetHasSuccessfullySubmitted = (
    hasSuccessfullySubmitted : boolean,
) : ThunkAction<void, ISignUpFormStore, {services : SignUpFormActionInterfaces.IServices}> => {
    return (dispatch : Dispatch<ISignUpFormStore>, getState : () => ISignUpFormStore, extraArguments : {services : SignUpFormActionInterfaces.IServices}) : void => {
        dispatch(setHasSuccessfullySubmitted(hasSuccessfullySubmitted));
    };
};

const onSetAddressFormField = (fieldName : AddressFormFieldName, validationInputData : IValidationInputData) => {
    return (dispatch : Dispatch<ISignUpFormStore>, getState : () => ISignUpFormStore, extraArguments : {services : SignUpFormActionInterfaces.IServices}) : void => {
        dispatch(setFormFieldValidationData(fieldName, validationInputData.value, validationInputData.isValid, validationInputData.errorMessage));
    };
};

const onResetForm = () : ThunkAction<void, ISignUpFormStore, {services : SignUpFormActionInterfaces.IServices}> => {
    return (dispatch : Dispatch<ISignUpFormStore>, getState : () => ISignUpFormStore, extraArguments : {services : SignUpFormActionInterfaces.IServices}) : void => {
        Object.keys(signUpFormInitialValidationInputDataByFieldName).forEach((fieldNameValue) => {
            const fieldName = fieldNameValue as signUpFormFieldName;
            const {
                value,
                isValid,
                errorMessage,
            } = signUpFormInitialValidationInputDataByFieldName[fieldName];

            dispatch(setFormFieldValidationData(fieldName, value, isValid, errorMessage));
        });

        dispatch(setIsSubmitting(false));
    };
};

export const SignUpFormActions : SignUpFormActionInterfaces.ISignUpFormActionCreatorsMapObject = {
    onFormFieldChange,
    onFormFieldBlur,
    onSetAddressFormField,
    onResetForm,
    onSetHasSuccessfullySubmitted,
    onSubmit,
};
