import { DistributorId } from 'api/Distributor/model/DistributorId';
import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';

export class ProductDistributorAssociation {
    constructor(
        private readonly productId : ProductId,
        private readonly productQuantityUnit : ProductQuantityUnit,
        private readonly distributorId : DistributorId,
        private readonly sku : string | null,
        private readonly price : ProductCost | null,
        private readonly deposit : ProductCost | null,
    ) {}

    public getProductId() : ProductId {
        return this.productId;
    }

    public getProductQuantityUnit() : ProductQuantityUnit {
        return this.productQuantityUnit;
    }

    public getDistributorId() : DistributorId {
        return this.distributorId;
    }

    public getSku() : string | null {
        return this.sku;
    }

    public getPrice() : ProductCost | null {
        return this.price;
    }

    public getDeposit() : ProductCost | null {
        return this.deposit;
    }
}
