import React from 'react';

import { IHexColorsByCategoryName } from 'shared/models/Charts/IHexColorsByCategoryName';
import { IValuesByCategoryName } from 'shared/models/Charts/IValuesByCategoryName';
import { HexColor } from 'shared/models/HexColor';
import { SvgPattern } from 'shared/models/StackedBarChart/SvgPattern';

import { SvgPatternDefinitions } from './SvgPatternDefinitions';

/**
 * StackedBarChartTooltip properties
 */
export interface IStackedBarChartTooltipProps {
    /** title for the tooltip */
    displayTitle : string;
    /** numerical values by category for display on the tooltip */
    valuesByCategoryName : IValuesByCategoryName;
    /** colors for each category possible within a tooltip */
    perCategoryColorsByCategoryId : IHexColorsByCategoryName;
    /** x value of the tooltip position */
    xValue : number;
    /** y value of the tooltip position */
    yValue : number;
    /** category of the tooltip that is being hovered over on the stacked bar */
    categoryFocus : string;
    /** possible pattern for tooltip square(s) */
    svgPattern : SvgPattern;
    /** size of tooltip square */
    squareSize : number;
    /** Either 'top' or 'bottom', denoting if the tooltip should be placed below the horizontal stacked bar or above it */
    alignment : AlignmentEnum;
    /** function to format the numeric value of a label */
    formatNumbers : ((value : number) => string | number) | null;
    /** defaultColor for categories */
    defaultColor : HexColor;
}

export enum AlignmentEnum {
    'TOP' = 0,
    'BOTTOM' = 1,
}

/**
 * Form StackedBarChartTooltip
 */
export class StackedBarChartTooltip extends React.Component<IStackedBarChartTooltipProps, object> {

    public render() {
        const {
            displayTitle,
            valuesByCategoryName,
            perCategoryColorsByCategoryId,
            xValue,
            yValue,
            categoryFocus,
            svgPattern,
            squareSize,
            alignment,
            formatNumbers,
            defaultColor,
        } = this.props;

        const totalForDisplayName = this.totalForAllCategories(valuesByCategoryName);

        const categoryKeys = Object.keys(valuesByCategoryName);
        const shouldTooltipBeVertical : boolean = (Object.keys(perCategoryColorsByCategoryId).length > 2);

        const categoriesAndValues = (shouldTooltipBeVertical || categoryKeys.length > 1) ? categoryKeys.map((categoryName : string, index : number) => {
            const categoryColor : HexColor = perCategoryColorsByCategoryId[categoryName] || defaultColor;
            const nullableValue : number | null = valuesByCategoryName[categoryName];
            const categoryValue : number = (nullableValue === null) ? 0 : nullableValue;

            const svgClassName = categoryName.split(' ').join('-').toLowerCase() + '-block';

            return (
                <div
                    className={ 'bar-tooltip-category-name col-xs-12 col-sm-4 cell-ptb-sm ' + (categoryFocus === categoryName ? 'bar-chart-tooltip-category-focused' : '') }
                    key={ index }
                >
                    { !(shouldTooltipBeVertical) &&
                        <div
                            className="bar-tooltip-category"
                        >
                            { categoryName }
                        </div>
                    }
                    { !(shouldTooltipBeVertical) &&

                    <div className="bar-tooltip-category-value">
                        <text className="cell-plr-xs">
                            { formatNumbers ? formatNumbers(categoryValue) : categoryValue }
                        </text>
                        <svg
                            height={ squareSize }
                            width={ squareSize }
                        >
                            <SvgPatternDefinitions
                                svgPattern={ svgPattern }
                            />
                            <rect
                                className={ svgClassName }
                                x={ 0 }
                                y={ 0 }
                                height={ squareSize }
                                width={ squareSize }
                                fill={ categoryColor.getValue() }
                            />
                        </svg>
                    </div>
                    }
                    { (shouldTooltipBeVertical) &&
                        <div
                            className="col-intable bar-tooltip-category-value"
                        >
                            <div
                                className="col-row"
                            >
                                <div
                                    className="cell col-xs-5"
                                >
                                    <text className="cell-plr-xs">
                                        { formatNumbers ? formatNumbers(categoryValue) : categoryValue }
                                    </text>
                                </div>
                                <div
                                    className="cell col-xs-2 text-center"
                                >
                                    <svg
                                        className={ svgClassName }
                                        height={ squareSize }
                                        width={ squareSize }
                                    >
                                        <SvgPatternDefinitions
                                            svgPattern={ svgPattern }
                                        />
                                        <rect
                                            x={ 0 }
                                            y={ 0 }
                                            height={ squareSize }
                                            width={ squareSize }
                                            fill={ categoryColor.getValue() }
                                        />
                                    </svg>
                                </div>
                                <div
                                    className="cell col-xs-5 text-left"
                                >
                                    <div
                                        className="bar-tooltip-category"
                                    >
                                        { categoryName }
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                </div>
            );
        }) : null;

        const tooltipStyle = {
            left: xValue + 'px',

            bottom: (alignment === AlignmentEnum.BOTTOM ? yValue + 'px' : ''),
            top: (alignment === AlignmentEnum.TOP ? yValue + 'px' : ''),
        };

        return (
            <div
                className={ 'stacked-bar-chart-tooltip tooltip-' + displayTitle + (!categoriesAndValues ? ' stacked-bar-chart-single-tooltip' : '') + (shouldTooltipBeVertical ? ' stacked-bar-chart-tooltip-vertical-display' : '') }
                style={ tooltipStyle }
            >
                <div className="bar-chart-tooltip-display-name text-left">
                    { displayTitle }
                </div>
                <div
                    className="bar-chart-tooltip-data text-right col-row"
                >
                    { categoriesAndValues }
                    <div
                        className={ 'col-xs-12 cell-ptb-sm bar-chart-tooltip-total-row '
                            + (categoryFocus === 'Total' ? ' bar-chart-tooltip-category-focused' : '')
                            + (!categoriesAndValues ? ' bar-chart-tooltip-one-category-chart' : '')
                            + (!shouldTooltipBeVertical ? ' col-sm-4' : '') }
                    >
                        { !(shouldTooltipBeVertical) &&
                            <div
                                className="bar-tooltip-category bar-tooltip-category-total"
                            >
                                Total
                            </div>
                        }
                        { !(shouldTooltipBeVertical) &&
                            <div
                                className="bar-tooltip-category-value"
                            >
                                { formatNumbers ? formatNumbers(totalForDisplayName) : totalForDisplayName }
                            </div>
                        }
                        { (shouldTooltipBeVertical) &&
                            <div
                                className="col-intable"
                            >
                                <div
                                    className="col-row"
                                >
                                    <div
                                        className="cell col-xs-5"
                                    >
                                        <div
                                            className="bar-tooltip-category-value"
                                        >
                                            { formatNumbers ? formatNumbers(totalForDisplayName) : totalForDisplayName }
                                        </div>
                                    </div>
                                    <div
                                        className="cell col-xs-2"
                                    />
                                    <div
                                        className="cell col-xs-5"
                                    >
                                        <div
                                            className="bar-tooltip-category bar-tooltip-category-total"
                                        >
                                            Total
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
    private totalForAllCategories = (valuesByCategoryName : IValuesByCategoryName) : number => {

        const total : number = Object.keys(valuesByCategoryName).reduce((prev : number, current : string) => {
            const value : number | null = valuesByCategoryName[current];

            if (value === null) {
                return prev;
            }

            return prev + value;
        }, 0);

        return total;

    }
}
