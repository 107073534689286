import React from 'react';

import './BrandAndName.scss';

export interface IBrandAndNameProps {
    readonly brand : string;
    readonly name : string;
    readonly category? : string;
    readonly subcategory? : string;
}

export class BrandAndName extends React.Component<IBrandAndNameProps, object> {

    public shouldComponentUpdate(nextProps : IBrandAndNameProps) {
        return (
            (this.props.brand !== nextProps.brand) ||
            (this.props.name !== nextProps.name)
        );
    }

    public render() {
        const {
            brand,
            name,
            category,
            subcategory
        } = this.props;

        return (
            <div
                className={ `product-brand-and-name ellipsis-out ${(brand ? '' : ' empty-brand')}` }
                title={ name || undefined }
            >
                <div className="product-brand">{ brand || '--' }</div>
                <div className="product-name">{ name || '--' }</div>
                { category &&
                    <small className="product-details">{ category }{ subcategory ? '  |  ' + subcategory : '' }</small>
                }
            </div>
        );
    }
}
