import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { AddressFormFieldName } from './AddressForm';
import { validateAddressValueByFieldName } from './utils';

import { DjangoApiManager } from 'shared/api/DjangoApiManager';
import { IExtraArguments } from 'shared/components/Provider';
import { IValidationInputData } from 'shared/components/ValidationInput';
import { IActionCreatorsMapObject } from 'shared/models/IAction';

type ValidationInputDataAndFieldNameTuples = Array<[AddressFormFieldName, IValidationInputData]>;

export namespace AddressFormActionInterfaces {
    export interface IAddressFormActionCreatorsMapObject extends IActionCreatorsMapObject {
        onFormFieldBlur : (
            field : AddressFormFieldName,
            value : string,
            countryCode : string,
        ) => ThunkAction<Promise<ValidationInputDataAndFieldNameTuples>, object, IAddressFormExtraArguments>;
    }

    export interface IServices {
        djangoApiManager : DjangoApiManager;
    }

    export interface IAddressFormExtraArguments extends IExtraArguments {
        services : IServices;
    }
}

const onFormFieldBlur = (
    field : AddressFormFieldName,
    value : string,
    countryCode : string,
) : ThunkAction<Promise<ValidationInputDataAndFieldNameTuples>, object, AddressFormActionInterfaces.IAddressFormExtraArguments> => {
    return (dispatch : Dispatch<object>, getState : () => object, extraArguments : AddressFormActionInterfaces.IAddressFormExtraArguments) : Promise<ValidationInputDataAndFieldNameTuples> => {
        const validationResult = validateAddressValueByFieldName(field, value, countryCode);

        const updatedValidationInputAndFieldNameTuples : ValidationInputDataAndFieldNameTuples = [];
        if (field === 'zipCode') {
            if (validationResult.isValid) {

                // for US accounts: check that zip code is valid US zip code, set the city and state based off of that
                if (countryCode === 'US') {
                    return extraArguments.services.djangoApiManager.validateZipCode(value)
                    .then((response : any) => {
                        if (!response.valid) {
                            updatedValidationInputAndFieldNameTuples.push([field, { value, isValid: false, errorMessage: 'ZIP code not recognized' }]);
                            updatedValidationInputAndFieldNameTuples.push(['city', { value: '', isValid: false, errorMessage: '' }]);
                            updatedValidationInputAndFieldNameTuples.push(['state', { value: '', isValid: false, errorMessage: '' }]);
                        } else {
                            const cityAndState = response.extra_message.split(', ');
                            updatedValidationInputAndFieldNameTuples.push([field, { value, isValid: true, errorMessage: '' }]);
                            updatedValidationInputAndFieldNameTuples.push(['city', { value: cityAndState[0], isValid: true, errorMessage: '' }]);
                            updatedValidationInputAndFieldNameTuples.push(['state', { value: cityAndState[1], isValid: true, errorMessage: '' }]);
                        }
                        return Promise.resolve(updatedValidationInputAndFieldNameTuples);
                    });
                } else { // for non-US accounts: just set the zipcode value (with no additional validation)
                    updatedValidationInputAndFieldNameTuples.push([field, { value, isValid: true, errorMessage: '' }]);
                }

            } else {
                updatedValidationInputAndFieldNameTuples.push([field, { value, isValid: validationResult.isValid, errorMessage: validationResult.errorMessage }]);
                updatedValidationInputAndFieldNameTuples.push(['city', { value: '', isValid: false, errorMessage: '' }]);
                updatedValidationInputAndFieldNameTuples.push(['state', { value: '', isValid: false, errorMessage: '' }]);
            }
        } else {
            updatedValidationInputAndFieldNameTuples.push([field, { value, isValid: validationResult.isValid, errorMessage: validationResult.errorMessage }]);

            if (field === 'country') { // if the country changes, reset zip, city, state (otherwise could get weird data)
                updatedValidationInputAndFieldNameTuples.push(['zipCode', { value: '', isValid: true, errorMessage: '' }]);
                updatedValidationInputAndFieldNameTuples.push(['city', { value: '', isValid: true, errorMessage: '' }]);
                updatedValidationInputAndFieldNameTuples.push(['state', { value: '', isValid: true, errorMessage: '' }]);
            }
        }

        return Promise.resolve(updatedValidationInputAndFieldNameTuples);
    };
};

export const AddressFormActions : AddressFormActionInterfaces.IAddressFormActionCreatorsMapObject = {
    onFormFieldBlur,
};
