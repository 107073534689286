import moment from 'moment-timezone';

import BreakageReportService from 'gen-thrift/BreakageReportService';
import InventoryTransferReportModel from 'gen-thrift/inventory_transfer_report_Model_types';
import ProductModel from 'gen-thrift/product_Model_types';

import { IBreakageService } from 'api/Breakage/interfaces/IBreakageService';
import { BreakageId } from 'api/Breakage/model/BreakageId';
import { BreakageReport } from 'api/Breakage/model/BreakageReport';
import { BreakageObjectToThriftSerializer } from 'api/Breakage/serializer/BreakageObjectToThriftSerializer';
import { BreakageThriftToObjectSerializer } from 'api/Breakage/serializer/BreakageThriftToObjectSerializer';
import { MomentObjectToThriftSerializer } from 'api/Core/serializer/MomentObjectToThriftSerializer';
import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { LocationId } from 'api/Location/model/LocationId';
import { LocationObjectToThriftSerializer } from 'api/Location/serializer/LocationObjectToThriftSerializer';
import { ProductAmount } from 'api/Product/model/ProductAmount';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { UserAccountObjectToThriftSerializer } from 'api/UserAccount/serializer/UserAccountObjectToThriftSerializer';
import { DateTime } from 'shared/models/DateTime';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export class BreakageServiceImpl implements IBreakageService {

    constructor (
        private breakageReportServiceClient : BreakageReportService.BreakageReportServiceClient,
        private userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer,
        private locationObjectToThriftSerializer : LocationObjectToThriftSerializer,
        private breakageObjectToThriftSerializer : BreakageObjectToThriftSerializer,
        private breakageThriftToObjectSerializer : BreakageThriftToObjectSerializer,
        private momentObjectToThriftSerializer : MomentObjectToThriftSerializer,
    ) { }

    public getBreakageReportsById(
        userSessionId : UserSessionId,
        breakageIds : StringValueSet<BreakageId>,
    ) : Promise<StringValueMap<BreakageId, BreakageReport>> {
        const breakageIdsList = Array.from(breakageIds.values());

        return new Promise<StringValueMap<BreakageId, BreakageReport>>((resolve, reject) => {
            this.breakageReportServiceClient.getBreakageReportsByIds(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.breakageObjectToThriftSerializer.getThriftBreakageIds(breakageIdsList),
                (result : Array<InventoryTransferReportModel.BreakageReportAndMetadata> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    const breakageReports = this.breakageThriftToObjectSerializer.getBreakageReports(result);

                    if (breakageIdsList.length !== breakageReports.length) {
                        throw new RuntimeException('result length did not match number of breakageIds');
                    }

                    const reportsById = new StringValueMap<BreakageId, BreakageReport>();
                    breakageIdsList.forEach((breakageId, index) => {
                        reportsById.set(breakageId, breakageReports[index]);
                    });

                    return resolve(reportsById);
                }
            );
        });
    }

    public getBreakageReportsForLocationInPeriod(
        userSessionId : UserSessionId,
        locationId : LocationId,
        periodStartInclusive : moment.Moment,
        periodEndExclusive : moment.Moment,
    ) : Promise<StringValueSet<BreakageId>> {
        return new Promise<StringValueSet<BreakageId>>((resolve, reject) => {
            this.breakageReportServiceClient.getReportsForLocationInPeriod(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                this.momentObjectToThriftSerializer.getThriftTimestampFromMoment(periodStartInclusive),
                this.momentObjectToThriftSerializer.getThriftTimestampFromMoment(periodEndExclusive),
                (result : Array<InventoryTransferReportModel.BreakageReportIdentifier> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    return resolve(this.breakageThriftToObjectSerializer.getBreakageIds(result));
                }
            );
        });
    }

    public getBreakageTypesForLocation(
        userSessionId : UserSessionId,
        locationIdentifier : LocationId
    ) : Promise<Array<string>> {
        return new Promise<Array<string>>((resolve, reject) => {
            this.breakageReportServiceClient.getBreakageTypesForLocation(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationIdentifier),
                (result : Array<string> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve(result);
                }
            );
        });
    }

    public createBreakageReport(
        userSessionId : UserSessionId,
        perspectiveLocation : LocationId,
        productAmounts : Array<ProductAmount>,
        breakageTime : DateTime,
        note : string | null,
        type : string | null,
    ) : Promise<BreakageId> {
        return new Promise<BreakageId>((resolve, reject) => {
            const thriftProductIdsAndQuantityOfProducts : Array<ProductModel.ProductIdAndQuantityOfProduct> = this.breakageObjectToThriftSerializer.getThriftProductIdsAndQuantityOfProducts(productAmounts);
            const report = new InventoryTransferReportModel.BreakageReportInfo({
                perspectiveLocation: this.locationObjectToThriftSerializer.getThriftLocationId(perspectiveLocation),
                productAmounts: thriftProductIdsAndQuantityOfProducts,
                breakageTime: breakageTime.toTimestampWithMillisecondPrecision(),
                note: note || '',
                type: type || '', // TODO from thrift this appears to be allowed to be null... why do we make it a string?
            });
            this.breakageReportServiceClient.createBreakageReport(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                report,
                (result : InventoryTransferReportModel.BreakageReportIdentifier | Error) => {
                    let error : Error | null = null;
                    if (result instanceof Error) {
                        error = result;
                        return reject(error);
                    }
                    return resolve(this.breakageThriftToObjectSerializer.getBreakageId(result));
                }
            );
        });
    }

    public updateBreakageReport(
        userSessionId : UserSessionId,
        reportIdentifier : BreakageId,
        productAmounts : Array<ProductAmount>,
        newReportTime : DateTime,
        newNote : string | null,
        newType : string | null
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const productAmountUpdates = this.breakageObjectToThriftSerializer.getThriftProductIdsAndQuantityOfProducts(productAmounts);
            const reportUpdate = new InventoryTransferReportModel.BreakageReportUpdate({
                productAmountUpdates,
                newNote: newNote || '',
                newReportTime : newReportTime.toTimestampWithMillisecondPrecision(),
            });
            this.breakageReportServiceClient.updateBreakageReport(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.breakageObjectToThriftSerializer.getThriftBreakageId(reportIdentifier),
                reportUpdate,
                (result : void | Error) => {
                    let error : Error | null = null;
                    if (result instanceof Error) {
                        error = result;
                        return reject(error);
                    }

                    this.breakageReportServiceClient.updateType(
                        this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                        this.breakageObjectToThriftSerializer.getThriftBreakageId(reportIdentifier),
                        newType || '',
                        (typeUpdateResult : void | Error) => {
                            if (typeUpdateResult instanceof Error) {
                                return reject(typeUpdateResult);
                            }

                            resolve();
                        }
                    );
                }
            );
        });
    }

    public deleteBreakageReport(
        userSessionId : UserSessionId,
        reportIdentifier : BreakageId
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.breakageReportServiceClient.deleteReport(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.breakageObjectToThriftSerializer.getThriftBreakageId(reportIdentifier),
                (result : void | Error) => {
                    let error : Error | null = null;
                    if (result instanceof Error) {
                        error = result;
                        return reject(error);
                    }

                    resolve();
                }
            );
        });
    }
}
