import { PosItem } from 'api/SalesData/model/PosItem';
import { createPosItemStringForFilter } from 'apps/SalesItemMapper/utils/SalesItemMapperUtils';
import { IColumnSorting } from 'shared/components/SortableColumnHeader';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { ALL_ITEMS_GROUP_NAME } from 'shared/models/GroupByOption';
import { SortDirection } from 'shared/models/SortDirection';
import { SortingFilteringAndGroupingUtil } from 'shared/utils/sortingFilteringAndGroupingUtils';
import { OmittedItemRowId } from './OmittedItemsView';

interface IOmittedItemsExtraArgs {
    unsortedRowIds : Set<OmittedItemRowId>;
}

const posItemFilterStringCache = new Map<OmittedItemRowId, string>();
const cachedCreateOmittedItemStringForFilter = (
    rowId : OmittedItemRowId
) : string => {

    if (posItemFilterStringCache.has(rowId)) {
        const cachedValue = posItemFilterStringCache.get(rowId);
        if (typeof cachedValue === 'undefined') {
            throw new RuntimeException('unexpected');
        }
        return cachedValue;
    }

    const fakePosItem = new PosItem(rowId[0], rowId[1], '');
    const posItemStringForFilter = createPosItemStringForFilter(fakePosItem, rowId[0]);
    posItemFilterStringCache.set(rowId, posItemStringForFilter);

    return posItemStringForFilter;
};

const defaultOmittedItemRowFilter = (
    rowId : OmittedItemRowId,
    cleanedFilterTerm : string | null,
) : boolean => {
    if (cleanedFilterTerm === null || cleanedFilterTerm === '') {
        return true;
    }

    const stringForSearch = cachedCreateOmittedItemStringForFilter(rowId);
    const filterTokens = cleanedFilterTerm.split(' ');

    for (let i = 0; i < filterTokens.length; i++) {
        if (stringForSearch.indexOf(filterTokens[i]) < 0) {
            return false;
        }
    }

    return true;
};

const rowFilter = (rowId : OmittedItemRowId, filterTerm : string | null) => {
    return defaultOmittedItemRowFilter(rowId, filterTerm);
};

const groupNameComparator = () => {
    return 0; // no group by
};

const groupNameGetter = () => {
    return ALL_ITEMS_GROUP_NAME;
};

const rowIdComparator = (rowId1 : OmittedItemRowId, rowId2 : OmittedItemRowId, columnSort : IColumnSorting) : number => {
    const direction = columnSort.direction;
    const sortedBy = columnSort.sortedBy;

    let comparisonResult : number;
    switch (sortedBy) {
        case 'name':
            // sort by name then pos id (or should we do the opposite?)
            const nameString1 = rowId1[1] + ' ' + rowId1[0];
            const nameString2 = rowId2[1] + ' ' + rowId2[0];
            // comparisonResult = cleanPhrase(nameString1).localeCompare(cleanPhrase(nameString2));
            comparisonResult = nameString1.localeCompare(nameString2); // not using clean phrase speeds this up noticeably...
            break;
        default:
            throw new RuntimeException('unhandled sort field');
    }

    if (direction === SortDirection.DESCENDING) {
        return comparisonResult * -1;
    }

    return comparisonResult;
};

const intermediateResultCacheIsValid = (rowIds : Array<OmittedItemRowId>, lastRowIds : Array<OmittedItemRowId>, extraArgs : IOmittedItemsExtraArgs, lastExtraArgs : IOmittedItemsExtraArgs) : boolean => {
    return extraArgs.unsortedRowIds === lastExtraArgs.unsortedRowIds;
};

export const OmittedItemsSortingUtil = new SortingFilteringAndGroupingUtil(
    rowIdComparator,
    rowFilter,
    groupNameGetter,
    groupNameComparator,
    intermediateResultCacheIsValid
);
