import { StringValueMap } from 'api/Core/StringValueMap';
import { MassUnit } from 'api/Product/model/MassUnit';
import { PackagingId } from 'api/Product/model/PackagingId';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export class Mappings {
    private massUnit : MassUnit | null = null;
    private volumeUnit : VolumeUnit | null = null;

    constructor(
        private readonly packagingIdMappings : StringValueMap<PackagingId, QuantityInUnit<PackagingId>>,
        private readonly unitOfMeasureMappings : Map<MassUnit | VolumeUnit, QuantityInUnit<PackagingId>>,
    ) {
        let massUnit : MassUnit | null = null;
        let volumeUnit : VolumeUnit | null = null;

        Array.from(unitOfMeasureMappings.keys()).forEach((unit) => {
            if (MassUnit.isMassUnit(unit)) {
                if (massUnit) {
                    throw new RuntimeException('unitOfMeasureConversions should contain at most one MassUnit');
                }

                massUnit = unit;
            } else if (VolumeUnit.isVolumeUnit(unit)) {
                if (volumeUnit) {
                    throw new RuntimeException('unitOfMeasureConversions should contain at most one VolumeUnit');
                }

                volumeUnit = unit;
            }
        });

        this.massUnit = massUnit;
        this.volumeUnit = volumeUnit;
    }

    public getPackagingIdMappings() : StringValueMap<PackagingId, QuantityInUnit<PackagingId>> {
        return this.packagingIdMappings;
    }

    public getUnitOfMeasureMappings() : Map<MassUnit | VolumeUnit, QuantityInUnit<PackagingId>> {
        return this.unitOfMeasureMappings;
    }

    public hasMassUnit() {
        return this.massUnit !== null;
    }

    public hasVolumeUnit() {
        return this.volumeUnit !== null;
    }

    public equals(other : any) : boolean {
        if (!(other instanceof Mappings)) {
            return false;
        }

        if ((this.packagingIdMappings.size !== other.getPackagingIdMappings().size) ||
            (this.unitOfMeasureMappings.size !== other.getUnitOfMeasureMappings().size)) {
            return false;
        }

        let packagingIdMappingsAreEqual = true;
        this.packagingIdMappings.forEach((quantityInUnit, packagingId) => {
            packagingIdMappingsAreEqual = packagingIdMappingsAreEqual && (quantityInUnit.equals(other.getPackagingIdMappings().get(packagingId)));
        });

        let unitOfMeasureMappingsAreEqual = true;
        this.unitOfMeasureMappings.forEach((quantityInUnit, unit) => {
            unitOfMeasureMappingsAreEqual = unitOfMeasureMappingsAreEqual && (quantityInUnit.equals(other.getUnitOfMeasureMappings().get(unit)));
        });

        return packagingIdMappingsAreEqual && unitOfMeasureMappingsAreEqual;
    }
}
