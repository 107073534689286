//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


import Thrift from 'thrift';
import AccountsExceptions from './accounts_Exceptions_types';
import UserAccountsModel from './accounts_user_Model_types';
import CoreApiExceptions from './core_api_Exceptions_types';
import DistributorExceptions from './distributor_Exceptions_types';
import DistributorModel from './distributor_Model_types';
import DistributorDependencyModel from './distributor_dependency_Model_types';
import LocationExceptions from './location_Exceptions_types';
import LocationModel from './location_Model_types';

var DistributorService = {};
export default DistributorService;
//HELPER FUNCTIONS AND STRUCTURES

DistributorService.DistributorService_createDistributor_args = function(args) {
  this.sessionId = null;
  this.distributor = null;
  if (args) {
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = new UserAccountsModel.UserSessionIdentifier(args.sessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionId is unset!');
    }
    if (args.distributor !== undefined && args.distributor !== null) {
      this.distributor = new DistributorModel.Distributor(args.distributor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field distributor is unset!');
    }
  }
};
DistributorService.DistributorService_createDistributor_args.prototype = {};
DistributorService.DistributorService_createDistributor_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionId = new UserAccountsModel.UserSessionIdentifier();
        this.sessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.distributor = new DistributorModel.Distributor();
        this.distributor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorService.DistributorService_createDistributor_args.prototype.write = function(output) {
  output.writeStructBegin('DistributorService_createDistributor_args');
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRUCT, 1);
    this.sessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.distributor !== null && this.distributor !== undefined) {
    output.writeFieldBegin('distributor', Thrift.Type.STRUCT, 2);
    this.distributor.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorService.DistributorService_createDistributor_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownScopeException = null;
  this.distributorNameCollisionException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof DistributorExceptions.UnknownScopeException) {
    this.unknownScopeException = args;
    return;
  }
  if (args instanceof DistributorExceptions.DistributorNameCollisionException) {
    this.distributorNameCollisionException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new DistributorModel.DistributorId(args.success);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownScopeException !== undefined && args.unknownScopeException !== null) {
      this.unknownScopeException = args.unknownScopeException;
    }
    if (args.distributorNameCollisionException !== undefined && args.distributorNameCollisionException !== null) {
      this.distributorNameCollisionException = args.distributorNameCollisionException;
    }
  }
};
DistributorService.DistributorService_createDistributor_result.prototype = {};
DistributorService.DistributorService_createDistributor_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new DistributorModel.DistributorId();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownScopeException = new DistributorExceptions.UnknownScopeException();
        this.unknownScopeException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.distributorNameCollisionException = new DistributorExceptions.DistributorNameCollisionException();
        this.distributorNameCollisionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorService.DistributorService_createDistributor_result.prototype.write = function(output) {
  output.writeStructBegin('DistributorService_createDistributor_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownScopeException !== null && this.unknownScopeException !== undefined) {
    output.writeFieldBegin('unknownScopeException', Thrift.Type.STRUCT, 7);
    this.unknownScopeException.write(output);
    output.writeFieldEnd();
  }
  if (this.distributorNameCollisionException !== null && this.distributorNameCollisionException !== undefined) {
    output.writeFieldBegin('distributorNameCollisionException', Thrift.Type.STRUCT, 8);
    this.distributorNameCollisionException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorService.DistributorService_setDistributorRegion_args = function(args) {
  this.sessionId = null;
  this.distributorId = null;
  this.regionId = null;
  if (args) {
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = new UserAccountsModel.UserSessionIdentifier(args.sessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionId is unset!');
    }
    if (args.distributorId !== undefined && args.distributorId !== null) {
      this.distributorId = new DistributorModel.DistributorId(args.distributorId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field distributorId is unset!');
    }
    if (args.regionId !== undefined && args.regionId !== null) {
      this.regionId = new DistributorModel.DistributorRegionId(args.regionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field regionId is unset!');
    }
  }
};
DistributorService.DistributorService_setDistributorRegion_args.prototype = {};
DistributorService.DistributorService_setDistributorRegion_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionId = new UserAccountsModel.UserSessionIdentifier();
        this.sessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.distributorId = new DistributorModel.DistributorId();
        this.distributorId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.regionId = new DistributorModel.DistributorRegionId();
        this.regionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorService.DistributorService_setDistributorRegion_args.prototype.write = function(output) {
  output.writeStructBegin('DistributorService_setDistributorRegion_args');
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRUCT, 1);
    this.sessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.distributorId !== null && this.distributorId !== undefined) {
    output.writeFieldBegin('distributorId', Thrift.Type.STRUCT, 2);
    this.distributorId.write(output);
    output.writeFieldEnd();
  }
  if (this.regionId !== null && this.regionId !== undefined) {
    output.writeFieldBegin('regionId', Thrift.Type.STRUCT, 3);
    this.regionId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorService.DistributorService_setDistributorRegion_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownDistributorIdException = null;
  this.unknownDistributorRegionIdException = null;
  this.distributorNameCollisionException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof DistributorExceptions.UnknownDistributorIdException) {
    this.unknownDistributorIdException = args;
    return;
  }
  if (args instanceof DistributorExceptions.UnknownDistributorRegionIdException) {
    this.unknownDistributorRegionIdException = args;
    return;
  }
  if (args instanceof DistributorExceptions.DistributorNameCollisionException) {
    this.distributorNameCollisionException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownDistributorIdException !== undefined && args.unknownDistributorIdException !== null) {
      this.unknownDistributorIdException = args.unknownDistributorIdException;
    }
    if (args.unknownDistributorRegionIdException !== undefined && args.unknownDistributorRegionIdException !== null) {
      this.unknownDistributorRegionIdException = args.unknownDistributorRegionIdException;
    }
    if (args.distributorNameCollisionException !== undefined && args.distributorNameCollisionException !== null) {
      this.distributorNameCollisionException = args.distributorNameCollisionException;
    }
  }
};
DistributorService.DistributorService_setDistributorRegion_result.prototype = {};
DistributorService.DistributorService_setDistributorRegion_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownDistributorIdException = new DistributorExceptions.UnknownDistributorIdException();
        this.unknownDistributorIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownDistributorRegionIdException = new DistributorExceptions.UnknownDistributorRegionIdException();
        this.unknownDistributorRegionIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.distributorNameCollisionException = new DistributorExceptions.DistributorNameCollisionException();
        this.distributorNameCollisionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorService.DistributorService_setDistributorRegion_result.prototype.write = function(output) {
  output.writeStructBegin('DistributorService_setDistributorRegion_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownDistributorIdException !== null && this.unknownDistributorIdException !== undefined) {
    output.writeFieldBegin('unknownDistributorIdException', Thrift.Type.STRUCT, 5);
    this.unknownDistributorIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownDistributorRegionIdException !== null && this.unknownDistributorRegionIdException !== undefined) {
    output.writeFieldBegin('unknownDistributorRegionIdException', Thrift.Type.STRUCT, 7);
    this.unknownDistributorRegionIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.distributorNameCollisionException !== null && this.distributorNameCollisionException !== undefined) {
    output.writeFieldBegin('distributorNameCollisionException', Thrift.Type.STRUCT, 8);
    this.distributorNameCollisionException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorService.DistributorService_updateDistributorName_args = function(args) {
  this.sessionId = null;
  this.distributorId = null;
  this.name = null;
  this.shortName = null;
  if (args) {
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = new UserAccountsModel.UserSessionIdentifier(args.sessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionId is unset!');
    }
    if (args.distributorId !== undefined && args.distributorId !== null) {
      this.distributorId = new DistributorModel.DistributorId(args.distributorId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field distributorId is unset!');
    }
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field name is unset!');
    }
    if (args.shortName !== undefined && args.shortName !== null) {
      this.shortName = args.shortName;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field shortName is unset!');
    }
  }
};
DistributorService.DistributorService_updateDistributorName_args.prototype = {};
DistributorService.DistributorService_updateDistributorName_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionId = new UserAccountsModel.UserSessionIdentifier();
        this.sessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.distributorId = new DistributorModel.DistributorId();
        this.distributorId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.shortName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorService.DistributorService_updateDistributorName_args.prototype.write = function(output) {
  output.writeStructBegin('DistributorService_updateDistributorName_args');
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRUCT, 1);
    this.sessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.distributorId !== null && this.distributorId !== undefined) {
    output.writeFieldBegin('distributorId', Thrift.Type.STRUCT, 2);
    this.distributorId.write(output);
    output.writeFieldEnd();
  }
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 3);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.shortName !== null && this.shortName !== undefined) {
    output.writeFieldBegin('shortName', Thrift.Type.STRING, 4);
    output.writeString(this.shortName);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorService.DistributorService_updateDistributorName_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownDistributorIdException = null;
  this.distributorNameCollisionException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof DistributorExceptions.UnknownDistributorIdException) {
    this.unknownDistributorIdException = args;
    return;
  }
  if (args instanceof DistributorExceptions.DistributorNameCollisionException) {
    this.distributorNameCollisionException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownDistributorIdException !== undefined && args.unknownDistributorIdException !== null) {
      this.unknownDistributorIdException = args.unknownDistributorIdException;
    }
    if (args.distributorNameCollisionException !== undefined && args.distributorNameCollisionException !== null) {
      this.distributorNameCollisionException = args.distributorNameCollisionException;
    }
  }
};
DistributorService.DistributorService_updateDistributorName_result.prototype = {};
DistributorService.DistributorService_updateDistributorName_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownDistributorIdException = new DistributorExceptions.UnknownDistributorIdException();
        this.unknownDistributorIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.distributorNameCollisionException = new DistributorExceptions.DistributorNameCollisionException();
        this.distributorNameCollisionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorService.DistributorService_updateDistributorName_result.prototype.write = function(output) {
  output.writeStructBegin('DistributorService_updateDistributorName_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownDistributorIdException !== null && this.unknownDistributorIdException !== undefined) {
    output.writeFieldBegin('unknownDistributorIdException', Thrift.Type.STRUCT, 5);
    this.unknownDistributorIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.distributorNameCollisionException !== null && this.distributorNameCollisionException !== undefined) {
    output.writeFieldBegin('distributorNameCollisionException', Thrift.Type.STRUCT, 8);
    this.distributorNameCollisionException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorService.DistributorService_updateDistributorOwner_args = function(args) {
  this.sessionId = null;
  this.distributorId = null;
  this.newOwner = null;
  if (args) {
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = new UserAccountsModel.UserSessionIdentifier(args.sessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionId is unset!');
    }
    if (args.distributorId !== undefined && args.distributorId !== null) {
      this.distributorId = new DistributorModel.DistributorId(args.distributorId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field distributorId is unset!');
    }
    if (args.newOwner !== undefined && args.newOwner !== null) {
      this.newOwner = new DistributorModel.Scope(args.newOwner);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field newOwner is unset!');
    }
  }
};
DistributorService.DistributorService_updateDistributorOwner_args.prototype = {};
DistributorService.DistributorService_updateDistributorOwner_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionId = new UserAccountsModel.UserSessionIdentifier();
        this.sessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.distributorId = new DistributorModel.DistributorId();
        this.distributorId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.newOwner = new DistributorModel.Scope();
        this.newOwner.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorService.DistributorService_updateDistributorOwner_args.prototype.write = function(output) {
  output.writeStructBegin('DistributorService_updateDistributorOwner_args');
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRUCT, 1);
    this.sessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.distributorId !== null && this.distributorId !== undefined) {
    output.writeFieldBegin('distributorId', Thrift.Type.STRUCT, 2);
    this.distributorId.write(output);
    output.writeFieldEnd();
  }
  if (this.newOwner !== null && this.newOwner !== undefined) {
    output.writeFieldBegin('newOwner', Thrift.Type.STRUCT, 3);
    this.newOwner.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorService.DistributorService_updateDistributorOwner_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownDistributorIdException = null;
  this.unknownScopeException = null;
  this.distributorNameCollisionException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof DistributorExceptions.UnknownDistributorIdException) {
    this.unknownDistributorIdException = args;
    return;
  }
  if (args instanceof DistributorExceptions.UnknownScopeException) {
    this.unknownScopeException = args;
    return;
  }
  if (args instanceof DistributorExceptions.DistributorNameCollisionException) {
    this.distributorNameCollisionException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownDistributorIdException !== undefined && args.unknownDistributorIdException !== null) {
      this.unknownDistributorIdException = args.unknownDistributorIdException;
    }
    if (args.unknownScopeException !== undefined && args.unknownScopeException !== null) {
      this.unknownScopeException = args.unknownScopeException;
    }
    if (args.distributorNameCollisionException !== undefined && args.distributorNameCollisionException !== null) {
      this.distributorNameCollisionException = args.distributorNameCollisionException;
    }
  }
};
DistributorService.DistributorService_updateDistributorOwner_result.prototype = {};
DistributorService.DistributorService_updateDistributorOwner_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownDistributorIdException = new DistributorExceptions.UnknownDistributorIdException();
        this.unknownDistributorIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownScopeException = new DistributorExceptions.UnknownScopeException();
        this.unknownScopeException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.distributorNameCollisionException = new DistributorExceptions.DistributorNameCollisionException();
        this.distributorNameCollisionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorService.DistributorService_updateDistributorOwner_result.prototype.write = function(output) {
  output.writeStructBegin('DistributorService_updateDistributorOwner_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownDistributorIdException !== null && this.unknownDistributorIdException !== undefined) {
    output.writeFieldBegin('unknownDistributorIdException', Thrift.Type.STRUCT, 5);
    this.unknownDistributorIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownScopeException !== null && this.unknownScopeException !== undefined) {
    output.writeFieldBegin('unknownScopeException', Thrift.Type.STRUCT, 6);
    this.unknownScopeException.write(output);
    output.writeFieldEnd();
  }
  if (this.distributorNameCollisionException !== null && this.distributorNameCollisionException !== undefined) {
    output.writeFieldBegin('distributorNameCollisionException', Thrift.Type.STRUCT, 8);
    this.distributorNameCollisionException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorService.DistributorService_deleteDistributor_args = function(args) {
  this.sessionId = null;
  this.distributorId = null;
  if (args) {
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = new UserAccountsModel.UserSessionIdentifier(args.sessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionId is unset!');
    }
    if (args.distributorId !== undefined && args.distributorId !== null) {
      this.distributorId = new DistributorModel.DistributorId(args.distributorId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field distributorId is unset!');
    }
  }
};
DistributorService.DistributorService_deleteDistributor_args.prototype = {};
DistributorService.DistributorService_deleteDistributor_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionId = new UserAccountsModel.UserSessionIdentifier();
        this.sessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.distributorId = new DistributorModel.DistributorId();
        this.distributorId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorService.DistributorService_deleteDistributor_args.prototype.write = function(output) {
  output.writeStructBegin('DistributorService_deleteDistributor_args');
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRUCT, 1);
    this.sessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.distributorId !== null && this.distributorId !== undefined) {
    output.writeFieldBegin('distributorId', Thrift.Type.STRUCT, 2);
    this.distributorId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorService.DistributorService_deleteDistributor_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownDistributorIdException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof DistributorExceptions.UnknownDistributorIdException) {
    this.unknownDistributorIdException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownDistributorIdException !== undefined && args.unknownDistributorIdException !== null) {
      this.unknownDistributorIdException = args.unknownDistributorIdException;
    }
  }
};
DistributorService.DistributorService_deleteDistributor_result.prototype = {};
DistributorService.DistributorService_deleteDistributor_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownDistributorIdException = new DistributorExceptions.UnknownDistributorIdException();
        this.unknownDistributorIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorService.DistributorService_deleteDistributor_result.prototype.write = function(output) {
  output.writeStructBegin('DistributorService_deleteDistributor_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownDistributorIdException !== null && this.unknownDistributorIdException !== undefined) {
    output.writeFieldBegin('unknownDistributorIdException', Thrift.Type.STRUCT, 5);
    this.unknownDistributorIdException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorService.DistributorService_retrieveDistributorIdsAndDistributorsForIdSet_args = function(args) {
  this.distributorIds = null;
  if (args) {
    if (args.distributorIds !== undefined && args.distributorIds !== null) {
      this.distributorIds = Thrift.copyList(args.distributorIds, [DistributorModel.DistributorId]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field distributorIds is unset!');
    }
  }
};
DistributorService.DistributorService_retrieveDistributorIdsAndDistributorsForIdSet_args.prototype = {};
DistributorService.DistributorService_retrieveDistributorIdsAndDistributorsForIdSet_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.distributorIds = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new DistributorModel.DistributorId();
          elem6.read(input);
          this.distributorIds.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorService.DistributorService_retrieveDistributorIdsAndDistributorsForIdSet_args.prototype.write = function(output) {
  output.writeStructBegin('DistributorService_retrieveDistributorIdsAndDistributorsForIdSet_args');
  if (this.distributorIds !== null && this.distributorIds !== undefined) {
    output.writeFieldBegin('distributorIds', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.distributorIds.length);
    for (var iter7 in this.distributorIds)
    {
      if (this.distributorIds.hasOwnProperty(iter7))
      {
        iter7 = this.distributorIds[iter7];
        iter7.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorService.DistributorService_retrieveDistributorIdsAndDistributorsForIdSet_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownDistributorIdException = null;
  this.duplicateDistributorIdException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof DistributorExceptions.UnknownDistributorIdException) {
    this.unknownDistributorIdException = args;
    return;
  }
  if (args instanceof DistributorExceptions.DuplicateDistributorIdException) {
    this.duplicateDistributorIdException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [DistributorModel.DistributorIdAndDistributor]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownDistributorIdException !== undefined && args.unknownDistributorIdException !== null) {
      this.unknownDistributorIdException = args.unknownDistributorIdException;
    }
    if (args.duplicateDistributorIdException !== undefined && args.duplicateDistributorIdException !== null) {
      this.duplicateDistributorIdException = args.duplicateDistributorIdException;
    }
  }
};
DistributorService.DistributorService_retrieveDistributorIdsAndDistributorsForIdSet_result.prototype = {};
DistributorService.DistributorService_retrieveDistributorIdsAndDistributorsForIdSet_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size8 = 0;
        var _rtmp312;
        this.success = [];
        var _etype11 = 0;
        _rtmp312 = input.readListBegin();
        _etype11 = _rtmp312.etype;
        _size8 = _rtmp312.size;
        for (var _i13 = 0; _i13 < _size8; ++_i13)
        {
          var elem14 = null;
          elem14 = new DistributorModel.DistributorIdAndDistributor();
          elem14.read(input);
          this.success.push(elem14);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownDistributorIdException = new DistributorExceptions.UnknownDistributorIdException();
        this.unknownDistributorIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.duplicateDistributorIdException = new DistributorExceptions.DuplicateDistributorIdException();
        this.duplicateDistributorIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorService.DistributorService_retrieveDistributorIdsAndDistributorsForIdSet_result.prototype.write = function(output) {
  output.writeStructBegin('DistributorService_retrieveDistributorIdsAndDistributorsForIdSet_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter15 in this.success)
    {
      if (this.success.hasOwnProperty(iter15))
      {
        iter15 = this.success[iter15];
        iter15.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownDistributorIdException !== null && this.unknownDistributorIdException !== undefined) {
    output.writeFieldBegin('unknownDistributorIdException', Thrift.Type.STRUCT, 3);
    this.unknownDistributorIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.duplicateDistributorIdException !== null && this.duplicateDistributorIdException !== undefined) {
    output.writeFieldBegin('duplicateDistributorIdException', Thrift.Type.STRUCT, 4);
    this.duplicateDistributorIdException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorService.DistributorService_retrieveDistributorIdsAndDistributorsAvailableToRetailer_args = function(args) {
  this.sessionId = null;
  this.locationIdentifier = null;
  if (args) {
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = new UserAccountsModel.UserSessionIdentifier(args.sessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionId is unset!');
    }
    if (args.locationIdentifier !== undefined && args.locationIdentifier !== null) {
      this.locationIdentifier = new LocationModel.LocationIdentifier(args.locationIdentifier);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationIdentifier is unset!');
    }
  }
};
DistributorService.DistributorService_retrieveDistributorIdsAndDistributorsAvailableToRetailer_args.prototype = {};
DistributorService.DistributorService_retrieveDistributorIdsAndDistributorsAvailableToRetailer_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionId = new UserAccountsModel.UserSessionIdentifier();
        this.sessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationIdentifier = new LocationModel.LocationIdentifier();
        this.locationIdentifier.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorService.DistributorService_retrieveDistributorIdsAndDistributorsAvailableToRetailer_args.prototype.write = function(output) {
  output.writeStructBegin('DistributorService_retrieveDistributorIdsAndDistributorsAvailableToRetailer_args');
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRUCT, 1);
    this.sessionId.write(output);
    output.writeFieldEnd();
  }
  if (this.locationIdentifier !== null && this.locationIdentifier !== undefined) {
    output.writeFieldBegin('locationIdentifier', Thrift.Type.STRUCT, 2);
    this.locationIdentifier.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorService.DistributorService_retrieveDistributorIdsAndDistributorsAvailableToRetailer_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.unknownLocationIdentifierException = null;
  this.actorDoesNotHaveAccessToLocationException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof LocationExceptions.ActorDoesNotHaveAccessToLocationException) {
    this.actorDoesNotHaveAccessToLocationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [DistributorModel.DistributorIdAndDistributor]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.actorDoesNotHaveAccessToLocationException !== undefined && args.actorDoesNotHaveAccessToLocationException !== null) {
      this.actorDoesNotHaveAccessToLocationException = args.actorDoesNotHaveAccessToLocationException;
    }
  }
};
DistributorService.DistributorService_retrieveDistributorIdsAndDistributorsAvailableToRetailer_result.prototype = {};
DistributorService.DistributorService_retrieveDistributorIdsAndDistributorsAvailableToRetailer_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size16 = 0;
        var _rtmp320;
        this.success = [];
        var _etype19 = 0;
        _rtmp320 = input.readListBegin();
        _etype19 = _rtmp320.etype;
        _size16 = _rtmp320.size;
        for (var _i21 = 0; _i21 < _size16; ++_i21)
        {
          var elem22 = null;
          elem22 = new DistributorModel.DistributorIdAndDistributor();
          elem22.read(input);
          this.success.push(elem22);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToLocationException = new LocationExceptions.ActorDoesNotHaveAccessToLocationException();
        this.actorDoesNotHaveAccessToLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorService.DistributorService_retrieveDistributorIdsAndDistributorsAvailableToRetailer_result.prototype.write = function(output) {
  output.writeStructBegin('DistributorService_retrieveDistributorIdsAndDistributorsAvailableToRetailer_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter23 in this.success)
    {
      if (this.success.hasOwnProperty(iter23))
      {
        iter23 = this.success[iter23];
        iter23.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 4);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToLocationException !== null && this.actorDoesNotHaveAccessToLocationException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToLocationException', Thrift.Type.STRUCT, 5);
    this.actorDoesNotHaveAccessToLocationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorService.DistributorService_retrieveDistributorDependencies_args = function(args) {
  this.distributorId = null;
  if (args) {
    if (args.distributorId !== undefined && args.distributorId !== null) {
      this.distributorId = new DistributorModel.DistributorId(args.distributorId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field distributorId is unset!');
    }
  }
};
DistributorService.DistributorService_retrieveDistributorDependencies_args.prototype = {};
DistributorService.DistributorService_retrieveDistributorDependencies_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.distributorId = new DistributorModel.DistributorId();
        this.distributorId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorService.DistributorService_retrieveDistributorDependencies_args.prototype.write = function(output) {
  output.writeStructBegin('DistributorService_retrieveDistributorDependencies_args');
  if (this.distributorId !== null && this.distributorId !== undefined) {
    output.writeFieldBegin('distributorId', Thrift.Type.STRUCT, 1);
    this.distributorId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorService.DistributorService_retrieveDistributorDependencies_result = function(args) {
  this.success = null;
  this.unknownDistributorIdException = null;
  if (args instanceof DistributorExceptions.UnknownDistributorIdException) {
    this.unknownDistributorIdException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new DistributorDependencyModel.DistributorDependentObjectSet(args.success);
    }
    if (args.unknownDistributorIdException !== undefined && args.unknownDistributorIdException !== null) {
      this.unknownDistributorIdException = args.unknownDistributorIdException;
    }
  }
};
DistributorService.DistributorService_retrieveDistributorDependencies_result.prototype = {};
DistributorService.DistributorService_retrieveDistributorDependencies_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new DistributorDependencyModel.DistributorDependentObjectSet();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownDistributorIdException = new DistributorExceptions.UnknownDistributorIdException();
        this.unknownDistributorIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorService.DistributorService_retrieveDistributorDependencies_result.prototype.write = function(output) {
  output.writeStructBegin('DistributorService_retrieveDistributorDependencies_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownDistributorIdException !== null && this.unknownDistributorIdException !== undefined) {
    output.writeFieldBegin('unknownDistributorIdException', Thrift.Type.STRUCT, 1);
    this.unknownDistributorIdException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorService.DistributorService_retrieveAllDistributorIdsAndDistributors_args = function(args) {
  this.sessionId = null;
  if (args) {
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = new UserAccountsModel.UserSessionIdentifier(args.sessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionId is unset!');
    }
  }
};
DistributorService.DistributorService_retrieveAllDistributorIdsAndDistributors_args.prototype = {};
DistributorService.DistributorService_retrieveAllDistributorIdsAndDistributors_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionId = new UserAccountsModel.UserSessionIdentifier();
        this.sessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorService.DistributorService_retrieveAllDistributorIdsAndDistributors_args.prototype.write = function(output) {
  output.writeStructBegin('DistributorService_retrieveAllDistributorIdsAndDistributors_args');
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRUCT, 1);
    this.sessionId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorService.DistributorService_retrieveAllDistributorIdsAndDistributors_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [DistributorModel.DistributorIdAndDistributor]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
  }
};
DistributorService.DistributorService_retrieveAllDistributorIdsAndDistributors_result.prototype = {};
DistributorService.DistributorService_retrieveAllDistributorIdsAndDistributors_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size24 = 0;
        var _rtmp328;
        this.success = [];
        var _etype27 = 0;
        _rtmp328 = input.readListBegin();
        _etype27 = _rtmp328.etype;
        _size24 = _rtmp328.size;
        for (var _i29 = 0; _i29 < _size24; ++_i29)
        {
          var elem30 = null;
          elem30 = new DistributorModel.DistributorIdAndDistributor();
          elem30.read(input);
          this.success.push(elem30);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorService.DistributorService_retrieveAllDistributorIdsAndDistributors_result.prototype.write = function(output) {
  output.writeStructBegin('DistributorService_retrieveAllDistributorIdsAndDistributors_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter31 in this.success)
    {
      if (this.success.hasOwnProperty(iter31))
      {
        iter31 = this.success[iter31];
        iter31.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorService.DistributorService_retrieveAllDistributorIdsAndRegions_args = function(args) {
  this.sessionId = null;
  if (args) {
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = new UserAccountsModel.UserSessionIdentifier(args.sessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionId is unset!');
    }
  }
};
DistributorService.DistributorService_retrieveAllDistributorIdsAndRegions_args.prototype = {};
DistributorService.DistributorService_retrieveAllDistributorIdsAndRegions_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionId = new UserAccountsModel.UserSessionIdentifier();
        this.sessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorService.DistributorService_retrieveAllDistributorIdsAndRegions_args.prototype.write = function(output) {
  output.writeStructBegin('DistributorService_retrieveAllDistributorIdsAndRegions_args');
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRUCT, 1);
    this.sessionId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorService.DistributorService_retrieveAllDistributorIdsAndRegions_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [DistributorModel.DistributorIdAndRegionId]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
  }
};
DistributorService.DistributorService_retrieveAllDistributorIdsAndRegions_result.prototype = {};
DistributorService.DistributorService_retrieveAllDistributorIdsAndRegions_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size32 = 0;
        var _rtmp336;
        this.success = [];
        var _etype35 = 0;
        _rtmp336 = input.readListBegin();
        _etype35 = _rtmp336.etype;
        _size32 = _rtmp336.size;
        for (var _i37 = 0; _i37 < _size32; ++_i37)
        {
          var elem38 = null;
          elem38 = new DistributorModel.DistributorIdAndRegionId();
          elem38.read(input);
          this.success.push(elem38);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorService.DistributorService_retrieveAllDistributorIdsAndRegions_result.prototype.write = function(output) {
  output.writeStructBegin('DistributorService_retrieveAllDistributorIdsAndRegions_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter39 in this.success)
    {
      if (this.success.hasOwnProperty(iter39))
      {
        iter39 = this.success[iter39];
        iter39.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorService.DistributorService_retrieveAllDistributorRegionIds_args = function(args) {
  this.sessionId = null;
  if (args) {
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = new UserAccountsModel.UserSessionIdentifier(args.sessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sessionId is unset!');
    }
  }
};
DistributorService.DistributorService_retrieveAllDistributorRegionIds_args.prototype = {};
DistributorService.DistributorService_retrieveAllDistributorRegionIds_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionId = new UserAccountsModel.UserSessionIdentifier();
        this.sessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorService.DistributorService_retrieveAllDistributorRegionIds_args.prototype.write = function(output) {
  output.writeStructBegin('DistributorService_retrieveAllDistributorRegionIds_args');
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRUCT, 1);
    this.sessionId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorService.DistributorService_retrieveAllDistributorRegionIds_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [DistributorModel.DistributorRegionId]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
  }
};
DistributorService.DistributorService_retrieveAllDistributorRegionIds_result.prototype = {};
DistributorService.DistributorService_retrieveAllDistributorRegionIds_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size40 = 0;
        var _rtmp344;
        this.success = [];
        var _etype43 = 0;
        _rtmp344 = input.readListBegin();
        _etype43 = _rtmp344.etype;
        _size40 = _rtmp344.size;
        for (var _i45 = 0; _i45 < _size40; ++_i45)
        {
          var elem46 = null;
          elem46 = new DistributorModel.DistributorRegionId();
          elem46.read(input);
          this.success.push(elem46);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorService.DistributorService_retrieveAllDistributorRegionIds_result.prototype.write = function(output) {
  output.writeStructBegin('DistributorService_retrieveAllDistributorRegionIds_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter47 in this.success)
    {
      if (this.success.hasOwnProperty(iter47))
      {
        iter47 = this.success[iter47];
        iter47.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorService.DistributorServiceClient = function(input, output) {
    this.input = input;
    this.output = (!output) ? input : output;
    this.seqid = 0;
};
DistributorService.DistributorServiceClient.prototype = {};
DistributorService.DistributorServiceClient.prototype.createDistributor = function(sessionId, distributor, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_createDistributor(pendingError, sessionId, distributor, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_createDistributor();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

DistributorService.DistributorServiceClient.prototype.send_createDistributor = function(pendingError, sessionId, distributor, callback) {
  this.output.writeMessageBegin('createDistributor', Thrift.MessageType.CALL, this.seqid);
  var args = new DistributorService.DistributorService_createDistributor_args();
  args.sessionId = sessionId;
  args.distributor = distributor;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_createDistributor() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DistributorService.DistributorServiceClient.prototype.recv_createDistributor = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DistributorService.DistributorService_createDistributor_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownScopeException) {
    throw result.unknownScopeException;
  }
  if (null !== result.distributorNameCollisionException) {
    throw result.distributorNameCollisionException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'createDistributor failed: unknown result';
};
DistributorService.DistributorServiceClient.prototype.setDistributorRegion = function(sessionId, distributorId, regionId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_setDistributorRegion(pendingError, sessionId, distributorId, regionId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_setDistributorRegion();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

DistributorService.DistributorServiceClient.prototype.send_setDistributorRegion = function(pendingError, sessionId, distributorId, regionId, callback) {
  this.output.writeMessageBegin('setDistributorRegion', Thrift.MessageType.CALL, this.seqid);
  var args = new DistributorService.DistributorService_setDistributorRegion_args();
  args.sessionId = sessionId;
  args.distributorId = distributorId;
  args.regionId = regionId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_setDistributorRegion() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DistributorService.DistributorServiceClient.prototype.recv_setDistributorRegion = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DistributorService.DistributorService_setDistributorRegion_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownDistributorIdException) {
    throw result.unknownDistributorIdException;
  }
  if (null !== result.unknownDistributorRegionIdException) {
    throw result.unknownDistributorRegionIdException;
  }
  if (null !== result.distributorNameCollisionException) {
    throw result.distributorNameCollisionException;
  }
  return;
};
DistributorService.DistributorServiceClient.prototype.updateDistributorName = function(sessionId, distributorId, name, shortName, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_updateDistributorName(pendingError, sessionId, distributorId, name, shortName, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_updateDistributorName();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

DistributorService.DistributorServiceClient.prototype.send_updateDistributorName = function(pendingError, sessionId, distributorId, name, shortName, callback) {
  this.output.writeMessageBegin('updateDistributorName', Thrift.MessageType.CALL, this.seqid);
  var args = new DistributorService.DistributorService_updateDistributorName_args();
  args.sessionId = sessionId;
  args.distributorId = distributorId;
  args.name = name;
  args.shortName = shortName;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_updateDistributorName() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DistributorService.DistributorServiceClient.prototype.recv_updateDistributorName = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DistributorService.DistributorService_updateDistributorName_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownDistributorIdException) {
    throw result.unknownDistributorIdException;
  }
  if (null !== result.distributorNameCollisionException) {
    throw result.distributorNameCollisionException;
  }
  return;
};
DistributorService.DistributorServiceClient.prototype.updateDistributorOwner = function(sessionId, distributorId, newOwner, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_updateDistributorOwner(pendingError, sessionId, distributorId, newOwner, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_updateDistributorOwner();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

DistributorService.DistributorServiceClient.prototype.send_updateDistributorOwner = function(pendingError, sessionId, distributorId, newOwner, callback) {
  this.output.writeMessageBegin('updateDistributorOwner', Thrift.MessageType.CALL, this.seqid);
  var args = new DistributorService.DistributorService_updateDistributorOwner_args();
  args.sessionId = sessionId;
  args.distributorId = distributorId;
  args.newOwner = newOwner;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_updateDistributorOwner() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DistributorService.DistributorServiceClient.prototype.recv_updateDistributorOwner = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DistributorService.DistributorService_updateDistributorOwner_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownDistributorIdException) {
    throw result.unknownDistributorIdException;
  }
  if (null !== result.unknownScopeException) {
    throw result.unknownScopeException;
  }
  if (null !== result.distributorNameCollisionException) {
    throw result.distributorNameCollisionException;
  }
  return;
};
DistributorService.DistributorServiceClient.prototype.deleteDistributor = function(sessionId, distributorId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_deleteDistributor(pendingError, sessionId, distributorId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_deleteDistributor();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

DistributorService.DistributorServiceClient.prototype.send_deleteDistributor = function(pendingError, sessionId, distributorId, callback) {
  this.output.writeMessageBegin('deleteDistributor', Thrift.MessageType.CALL, this.seqid);
  var args = new DistributorService.DistributorService_deleteDistributor_args();
  args.sessionId = sessionId;
  args.distributorId = distributorId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_deleteDistributor() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DistributorService.DistributorServiceClient.prototype.recv_deleteDistributor = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DistributorService.DistributorService_deleteDistributor_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownDistributorIdException) {
    throw result.unknownDistributorIdException;
  }
  return;
};
DistributorService.DistributorServiceClient.prototype.retrieveDistributorIdsAndDistributorsForIdSet = function(distributorIds, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_retrieveDistributorIdsAndDistributorsForIdSet(pendingError, distributorIds, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_retrieveDistributorIdsAndDistributorsForIdSet();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

DistributorService.DistributorServiceClient.prototype.send_retrieveDistributorIdsAndDistributorsForIdSet = function(pendingError, distributorIds, callback) {
  this.output.writeMessageBegin('retrieveDistributorIdsAndDistributorsForIdSet', Thrift.MessageType.CALL, this.seqid);
  var args = new DistributorService.DistributorService_retrieveDistributorIdsAndDistributorsForIdSet_args();
  args.distributorIds = distributorIds;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_retrieveDistributorIdsAndDistributorsForIdSet() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DistributorService.DistributorServiceClient.prototype.recv_retrieveDistributorIdsAndDistributorsForIdSet = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DistributorService.DistributorService_retrieveDistributorIdsAndDistributorsForIdSet_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownDistributorIdException) {
    throw result.unknownDistributorIdException;
  }
  if (null !== result.duplicateDistributorIdException) {
    throw result.duplicateDistributorIdException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'retrieveDistributorIdsAndDistributorsForIdSet failed: unknown result';
};
DistributorService.DistributorServiceClient.prototype.retrieveDistributorIdsAndDistributorsAvailableToRetailer = function(sessionId, locationIdentifier, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_retrieveDistributorIdsAndDistributorsAvailableToRetailer(pendingError, sessionId, locationIdentifier, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_retrieveDistributorIdsAndDistributorsAvailableToRetailer();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

DistributorService.DistributorServiceClient.prototype.send_retrieveDistributorIdsAndDistributorsAvailableToRetailer = function(pendingError, sessionId, locationIdentifier, callback) {
  this.output.writeMessageBegin('retrieveDistributorIdsAndDistributorsAvailableToRetailer', Thrift.MessageType.CALL, this.seqid);
  var args = new DistributorService.DistributorService_retrieveDistributorIdsAndDistributorsAvailableToRetailer_args();
  args.sessionId = sessionId;
  args.locationIdentifier = locationIdentifier;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_retrieveDistributorIdsAndDistributorsAvailableToRetailer() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DistributorService.DistributorServiceClient.prototype.recv_retrieveDistributorIdsAndDistributorsAvailableToRetailer = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DistributorService.DistributorService_retrieveDistributorIdsAndDistributorsAvailableToRetailer_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.actorDoesNotHaveAccessToLocationException) {
    throw result.actorDoesNotHaveAccessToLocationException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'retrieveDistributorIdsAndDistributorsAvailableToRetailer failed: unknown result';
};
DistributorService.DistributorServiceClient.prototype.retrieveDistributorDependencies = function(distributorId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_retrieveDistributorDependencies(pendingError, distributorId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_retrieveDistributorDependencies();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

DistributorService.DistributorServiceClient.prototype.send_retrieveDistributorDependencies = function(pendingError, distributorId, callback) {
  this.output.writeMessageBegin('retrieveDistributorDependencies', Thrift.MessageType.CALL, this.seqid);
  var args = new DistributorService.DistributorService_retrieveDistributorDependencies_args();
  args.distributorId = distributorId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_retrieveDistributorDependencies() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DistributorService.DistributorServiceClient.prototype.recv_retrieveDistributorDependencies = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DistributorService.DistributorService_retrieveDistributorDependencies_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.unknownDistributorIdException) {
    throw result.unknownDistributorIdException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'retrieveDistributorDependencies failed: unknown result';
};
DistributorService.DistributorServiceClient.prototype.retrieveAllDistributorIdsAndDistributors = function(sessionId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_retrieveAllDistributorIdsAndDistributors(pendingError, sessionId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_retrieveAllDistributorIdsAndDistributors();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

DistributorService.DistributorServiceClient.prototype.send_retrieveAllDistributorIdsAndDistributors = function(pendingError, sessionId, callback) {
  this.output.writeMessageBegin('retrieveAllDistributorIdsAndDistributors', Thrift.MessageType.CALL, this.seqid);
  var args = new DistributorService.DistributorService_retrieveAllDistributorIdsAndDistributors_args();
  args.sessionId = sessionId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_retrieveAllDistributorIdsAndDistributors() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DistributorService.DistributorServiceClient.prototype.recv_retrieveAllDistributorIdsAndDistributors = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DistributorService.DistributorService_retrieveAllDistributorIdsAndDistributors_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'retrieveAllDistributorIdsAndDistributors failed: unknown result';
};
DistributorService.DistributorServiceClient.prototype.retrieveAllDistributorIdsAndRegions = function(sessionId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_retrieveAllDistributorIdsAndRegions(pendingError, sessionId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_retrieveAllDistributorIdsAndRegions();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

DistributorService.DistributorServiceClient.prototype.send_retrieveAllDistributorIdsAndRegions = function(pendingError, sessionId, callback) {
  this.output.writeMessageBegin('retrieveAllDistributorIdsAndRegions', Thrift.MessageType.CALL, this.seqid);
  var args = new DistributorService.DistributorService_retrieveAllDistributorIdsAndRegions_args();
  args.sessionId = sessionId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_retrieveAllDistributorIdsAndRegions() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DistributorService.DistributorServiceClient.prototype.recv_retrieveAllDistributorIdsAndRegions = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DistributorService.DistributorService_retrieveAllDistributorIdsAndRegions_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'retrieveAllDistributorIdsAndRegions failed: unknown result';
};
DistributorService.DistributorServiceClient.prototype.retrieveAllDistributorRegionIds = function(sessionId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_retrieveAllDistributorRegionIds(pendingError, sessionId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_retrieveAllDistributorRegionIds();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

DistributorService.DistributorServiceClient.prototype.send_retrieveAllDistributorRegionIds = function(pendingError, sessionId, callback) {
  this.output.writeMessageBegin('retrieveAllDistributorRegionIds', Thrift.MessageType.CALL, this.seqid);
  var args = new DistributorService.DistributorService_retrieveAllDistributorRegionIds_args();
  args.sessionId = sessionId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_retrieveAllDistributorRegionIds() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

DistributorService.DistributorServiceClient.prototype.recv_retrieveAllDistributorRegionIds = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new DistributorService.DistributorService_retrieveAllDistributorRegionIds_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'retrieveAllDistributorRegionIds failed: unknown result';
};
