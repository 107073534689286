import { BillingFrequency } from 'api/Billing/model/BillingFrequency';
import { BillingPlanTier } from 'api/Billing/model/BillingPlanTier';
import { AccountType } from 'api/Location/model/AccountType';

export class BillingPlan {
    constructor (
        private readonly name : string,
        private readonly priceInCents : number,
        private readonly billingFrequency : BillingFrequency,
        private readonly tier : BillingPlanTier | null,
        private readonly accountType : AccountType | null,
        private readonly active : boolean,
    ) { }

    public getName() : string {
        return this.name;
    }

    public getPriceInCents() : number {
        return this.priceInCents;
    }

    public getBillingFrequency() : BillingFrequency {
        return this.billingFrequency;
    }

    public getTier() : BillingPlanTier | null {
        return this.tier;
    }

    public getAccountType() : AccountType | null {
        return this.accountType;
    }

    public getActive() : boolean {
        return this.active;
    }
}
