import { AjaxUtils } from 'shared/utils/ajaxUtils';

import { LocationId } from 'api/Location/model/LocationId';
import { IUserInviteService } from 'api/UserAccount/interfaces/IUserInviteService';
import { InviteInfo } from 'api/UserAccount/model/InviteInfo';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { IInviteInfoJSON, InviteInfoJSONToObjectSerializer } from 'api/UserAccount/serializer/InviteInfoJSONToObjectSerializer';

import { InvalidInviteException } from '../exceptions/InvalidInviteException';

interface IRedirectResponse {
    redirect_to : string;
    success : boolean;
}
export class UserInviteServiceImpl implements IUserInviteService {
    constructor(
        private readonly inviteInfoJSONToObjectSerializer : InviteInfoJSONToObjectSerializer
    ) { }

    public addCustomerToRetailer(
        userAccountIdentifier : UserAccountId,
        inviteLink : string,
    ) : Promise<string> {
        const formData : FormData = new FormData();
        formData.append('user_id', userAccountIdentifier.getValue());
        formData.append('invite_link', inviteLink);

        return AjaxUtils.ajaxPostForm(urlWithoutRetailerId('public:join_establishment'), formData)
        .then((response : IRedirectResponse) => {
            if (response.success) {
                return response.redirect_to;
            }
            throw new Error('join_establishment failed');
        });
    }

    public invalidateInvite(
        inviteLink : string,
    ) : Promise<string> {
        const formData : FormData = new FormData();
        formData.append('invite_link', inviteLink);

        return AjaxUtils.ajaxPostForm(urlWithoutRetailerId('public:invalidate_invite'), formData)
            .then((response : IRedirectResponse) => {
                if (response.success) {
                   return response.redirect_to;
                }
                throw new Error('invalidate_invite failed');
            });
    }

    public getInvite(
        inviteLink : string,
    ) : Promise<InviteInfo> {
        const query = {
            invite_link: inviteLink
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('public:get_invite'), query)
        .then((response : IInviteInfoJSON) => {
            if (response.success) {
                return this.inviteInfoJSONToObjectSerializer.getInviteInfo(response);
            }
            throw new InvalidInviteException('get_invite failed');
        });
    }

    public inviteToRetailer(inviteeEmailAddress : string, retailerId : LocationId, inviteAsAdmin : boolean) : Promise<void> {
        const formData : FormData = new FormData();
        formData.append('invite_as_admin', inviteAsAdmin.toString());
        formData.append('email', inviteeEmailAddress);

        return AjaxUtils.ajaxPostForm(
            url('account:invite_user', null, retailerId.getValue(), null),
            formData
        ).then((response) => {
            if (!response.success) {
                throw new Error('failed to invite: ' + inviteeEmailAddress);
            }
        });
    }
}
