import { LocationId } from 'api/Location/model/LocationId';
import moment from 'moment-timezone';

import { StringValueMap } from 'api/Core/StringValueMap';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { DeliveryLineItem } from 'api/Ordering/model/DeliveryLineItem';
import { DeliveryRepInfo } from 'api/Ordering/model/DeliveryRepInfo';
import { InvoiceUploadId } from 'api/Ordering/model/InvoiceUploadId';
import { PriceAdjustment } from 'api/Ordering/model/PriceAdjustment';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserAccountIdAndTimestamp } from 'api/UserAccount/model/UserAccountIdAndTimestamp';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export class Delivery {
    // all non-monetary fields are captured/duplicated by DeliveryMetadata and should eventually be removed from this class
    constructor (
        private readonly datePlacedUTC : moment.Moment,
        private readonly dateDeliveredUTC : moment.Moment,
        private readonly distributorId : DistributorId | null,
        private readonly deliveryLineItems : Array<DeliveryLineItem>,
        private readonly priceAdjustmentsByProductCategoryId : Map<string, Array<PriceAdjustment>>,
        private readonly uncategorizedPriceAdjustments : Array<PriceAdjustment>,
        private readonly taxesByName : Map<string, number>,
        private readonly invoiceNumber : string | null,
        private readonly userDefinedTotal : number | null,
        private readonly noteToRep : string | null,
        private readonly noteToSelf : string | null,
        private readonly purchaseOrderNumber : string | null,
        private readonly distributorReps : Array<DeliveryRepInfo>,
        private readonly recordedByName : string | null,
        private readonly dateRecordedUTC : moment.Moment,
        private readonly deliveryConfirmationEventTimeUTC : moment.Moment | null,
        private readonly deliveryConfirmationUserId : UserAccountId | null,
        private readonly hiddenInvoiceUploadLineItemIdsByInvoiceUploadId : StringValueMap<InvoiceUploadId, Set<string>>,
        private readonly approvalEvent : UserAccountIdAndTimestamp | null,
        private readonly readyForApprovalEvent : UserAccountIdAndTimestamp | null,
        private readonly lastUpdateEvent : UserAccountIdAndTimestamp | null
    ) {
        deliveryLineItems.forEach((deliveryLineItem) => {
            const invoiceUploadId = deliveryLineItem.getInvoiceUploadId();
            const invoiceUploadLineItemId = deliveryLineItem.getInvoiceUploadLineItemId();

            if ((invoiceUploadId === null) !== (invoiceUploadLineItemId === null)) {
                throw new RuntimeException('unexpected');
            } else if ((invoiceUploadId !== null) && (invoiceUploadLineItemId !== null)) {
                const hiddenInvoiceUploadLineItemIds = hiddenInvoiceUploadLineItemIdsByInvoiceUploadId.get(invoiceUploadId);

                if (typeof hiddenInvoiceUploadLineItemIds !== 'undefined') {
                    if (hiddenInvoiceUploadLineItemIds.has(invoiceUploadLineItemId)) {
                        throw new RuntimeException('unexpected');
                    }
                }
            }
        });
    }

    public getDatePlacedUTC() : moment.Moment {
        return this.datePlacedUTC;
    }

    public getDateDeliveredUTC() : moment.Moment {
        return this.dateDeliveredUTC;
    }

    public getDistributorId() : DistributorId | null {
        return this.distributorId;
    }

    public getDeliveryLineItems() : Array<DeliveryLineItem> {
        return this.deliveryLineItems;
    }

    public getPriceAdjustmentsByProductCategoryId() : Map<string, Array<PriceAdjustment>> {
        return this.priceAdjustmentsByProductCategoryId;
    }

    public getUncategorizedPriceAdjustments() : Array<PriceAdjustment> {
        return this.uncategorizedPriceAdjustments;
    }

    public getTaxesByName() : Map<string, number> {
        return this.taxesByName;
    }

    public getInvoiceNumber() : string | null {
        return this.invoiceNumber;
    }

    public getUserDefinedTotal() : number | null {
        return this.userDefinedTotal;
    }

    public getNoteToRep() : string | null {
        return this.noteToRep;
    }

    public getNoteToSelf() : string | null {
        return this.noteToSelf;
    }

    public getPurchaseOrderNumber() : string | null {
        return this.purchaseOrderNumber;
    }

    public getDistributorReps() : Array<DeliveryRepInfo> {
        return this.distributorReps;
    }

    public getDateRecordedUTC() : moment.Moment {
        return this.dateRecordedUTC;
    }

    public getRecordedByName() : string | null {
        return this.recordedByName;
    }

    public getDeliveryConfirmationEventTimeUTC() : moment.Moment | null {
        return this.deliveryConfirmationEventTimeUTC;
    }

    public getDeliveryConfirmationUserId() : UserAccountId | null {
        return this.deliveryConfirmationUserId;
    }

    public getHiddenInvoiceUploadLineItemIdsByInvoiceUploadId() : StringValueMap<InvoiceUploadId, Set<string>> {
        return this.hiddenInvoiceUploadLineItemIdsByInvoiceUploadId;
    }

    public getApprovalEvent() : UserAccountIdAndTimestamp | null {
        return this.approvalEvent;
    }

    public getReadyForApprovalEvent() : UserAccountIdAndTimestamp | null {
        return this.readyForApprovalEvent;
    }

    public getLastUpdateEvent() : UserAccountIdAndTimestamp | null {
        return this.lastUpdateEvent;
    }
}
