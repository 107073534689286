import UnauthenticatedUserSessionExceptions from 'gen-thrift/unauthenticated_user_session_Exceptions_types';
import UnauthenticatedUserSessionModel from 'gen-thrift/unauthenticated_user_session_Model_types';
import UnauthenticatedUserSessionService from 'gen-thrift/UnauthenticatedUserSessionService';

import { UserInfo } from '../../user_info/models/UserInfo';
import { UnauthenticatedUserSessionNotFoundException } from '../exceptions/UnauthenticatedUserSessionNotFoundException';

import { ICookieReader } from '../../cookie/interfaces/ICookieReader';
import { ICookieSetter } from '../../cookie/interfaces/ICookieSetter';
import { IUserInfoReader } from '../../user_info/interfaces/IUserInfoReader';
import { IUnauthenticatedUserSessionReader } from '../interfaces/IUnauthenticatedUserSessionReader';

import { UnknownCookieException } from '../../cookie/exceptions/UnknownCookieException';
import { RuntimeException } from '../../general/exceptions/RuntimeException';

export class UnauthenticatedUserSessionManagerImpl implements IUnauthenticatedUserSessionReader {
    private unauthenticatedUserSessionServiceClient : UnauthenticatedUserSessionService.UnauthenticatedUserSessionServiceClient;

    private userAccountInfoReader : IUserInfoReader;
    private cookieReader : ICookieReader;
    private cookieSetter : ICookieSetter;

    private cookieName : string;

    constructor(
            unauthenticatedUserSessionServiceClient : UnauthenticatedUserSessionService.UnauthenticatedUserSessionServiceClient,
            userAccountInfoReader : IUserInfoReader,
            cookieReader : ICookieReader,
            cookieSetter : ICookieSetter) {

        this.unauthenticatedUserSessionServiceClient = unauthenticatedUserSessionServiceClient;
        this.userAccountInfoReader = userAccountInfoReader;
        this.cookieReader = cookieReader;
        this.cookieSetter = cookieSetter;

        this.cookieName = 'unauthenticatedUserSessionId';
    }

    public getOrCreateUnauthenticatedUserSessionId() : Promise<UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId> {
        return new Promise<any>((resolve, reject) => {
            try {
                return resolve(this.getSession());
            } catch (e) {
                if (e instanceof UnauthenticatedUserSessionNotFoundException) {
                    this.createSession()
                    .then((sessionId : UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId) => {
                        return resolve(sessionId);
                    })
                    .catch(() => {
                        throw new RuntimeException('unexpected');
                    });
                } else {
                    return reject(new RuntimeException('unexpected'));
                }
            }
        });
    }

    private getSession() : UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId {
        let cookieValue : string | null;
        try {
            cookieValue = this.cookieReader.getCookie(this.cookieName);
        } catch (e) {
            if (e instanceof UnknownCookieException) {
                throw new UnauthenticatedUserSessionNotFoundException();
            }

            throw new RuntimeException('unexpected');
        }

        if (null === cookieValue) {
            throw new UnauthenticatedUserSessionNotFoundException();
        }

        return new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId({
            value: cookieValue.replace(/=/g, ''), // remove = padding
        });
    }

    private createSession() : Promise<any> {
        const userInfo : UserInfo = this.userAccountInfoReader.getUserInfo();

        return new Promise<any>((resolve, reject) => {
            this.unauthenticatedUserSessionServiceClient.createSession(
                userInfo.canvasData,
                userInfo.webGLVendor,
                userInfo.webGLRenderer,
                userInfo.plugins,
                userInfo.localStorageAllowed,
                userInfo.sessionStorageAllowed,
                userInfo.cookieAllowed,
                userInfo.platform,
                userInfo.doNotTrack,
                userInfo.timezone,
                userInfo.resolution,
                document.referrer,
                (result : UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId | Error) => {
                    if (result instanceof UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId) {
                        this.cookieSetter.setCookie(this.cookieName, result.value);
                        return resolve(result);
                    }

                    if (result instanceof UnauthenticatedUserSessionExceptions.CanvasDataInvalidStringLengthException) {
                        throw new RuntimeException('unexpected');
                    } else if (result instanceof UnauthenticatedUserSessionExceptions.WebGLVendorInvalidStringLengthException) {
                        throw new RuntimeException('unexpected');
                    } else if (result instanceof UnauthenticatedUserSessionExceptions.WebGLRendererInvalidStringLengthException) {
                        throw new RuntimeException('unexpected');
                    } else if (result instanceof UnauthenticatedUserSessionExceptions.PluginsInvalidStringLengthException) {
                        throw new RuntimeException('unexpected');
                    } else if (result instanceof UnauthenticatedUserSessionExceptions.LocalStorageAllowedInvalidStringLengthException) {
                        throw new RuntimeException('unexpected');
                    } else if (result instanceof UnauthenticatedUserSessionExceptions.SessionStorageAllowedInvalidStringLengthException) {
                        throw new RuntimeException('unexpected');
                    } else if (result instanceof UnauthenticatedUserSessionExceptions.CookieAllowedInvalidStringLengthException) {
                        throw new RuntimeException('unexpected');
                    } else if (result instanceof UnauthenticatedUserSessionExceptions.PlatformInvalidStringLengthException) {
                        throw new RuntimeException('unexpected');
                    } else if (result instanceof UnauthenticatedUserSessionExceptions.DoNotTrackInvalidStringLengthException) {
                        throw new RuntimeException('unexpected');
                    } else if (result instanceof UnauthenticatedUserSessionExceptions.TimezoneInvalidStringLengthException) {
                        throw new RuntimeException('unexpected');
                    } else if (result instanceof UnauthenticatedUserSessionExceptions.ResolutionInvalidStringLengthException) {
                        throw new RuntimeException('unexpected');
                    } else if (result instanceof UnauthenticatedUserSessionExceptions.ReferrerInvalidStringLengthException) {
                        throw new RuntimeException('unexpected');
                    } else {
                        throw new RuntimeException('unexpected');
                    }
                }
            );
        });
    }
}
