import { IStringValue } from 'api/Core/IStringValue';

export class ProductDistributorAssociationFormId implements IStringValue {

    public static generateUniqueProductDistributorAssociationFormId() : ProductDistributorAssociationFormId {
        ProductDistributorAssociationFormId.counter += 1;
        return new ProductDistributorAssociationFormId(`productDistributorAssociationFormId_${ ProductDistributorAssociationFormId.counter }`);
    }

    private static counter : number = 0;

    constructor (private readonly value : string) {}

    public getValue() : string {
        return this.value;
    }

    public equals(other : any) : boolean {
        if (!(other instanceof ProductDistributorAssociationFormId)) {
            return false;
        }

        return this.getValue() === other.getValue();
    }
}
