import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { LocationId } from 'api/Location/model/LocationId';
import { Delivery } from 'api/Ordering/model/Delivery';
import { DeliveryId } from 'api/Ordering/model/DeliveryId';
import { DeliveryLineItem } from 'api/Ordering/model/DeliveryLineItem';
import { DeliveryMetadata } from 'api/Ordering/model/DeliveryMetadata';
import { InvoiceStatus } from 'api/Ordering/model/InvoiceStatus';
import { InvoiceUpload } from 'api/Ordering/model/InvoiceUpload';
import { OrderStatus } from 'api/Ordering/model/OrderStatus';
import { Category } from 'api/Product/model/Category';
import { CategoryId } from 'api/Product/model/CategoryId';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { InvoiceUploadId } from '../model/InvoiceUploadId';

const getOrderBreakdownByProductCategory = (
    delivery : Delivery,
    productsById : StringValueMap<ProductId, Product>,
    categoriesById : StringValueMap<CategoryId, Category>,
) : Map<string, number> => {
    const breakdownByCategory = new Map<string, number>();

    delivery.getPriceAdjustmentsByProductCategoryId().forEach((adjustments, productCategoryId) => {
        const newCategoryId = new CategoryId(productCategoryId);
        let newCategoryName = "";
        if (categoriesById.has(newCategoryId)) {
            newCategoryName = categoriesById.getRequired(newCategoryId).getName();
        }
        const priceAdjustmentsForCategoryTotal = adjustments.reduce((accumulator : number, adjustmentValue) => {
            return accumulator + adjustmentValue.getAmountInDollars();
        }, 0);
        breakdownByCategory.set(newCategoryName, priceAdjustmentsForCategoryTotal);
    });

    delivery.getDeliveryLineItems().forEach((lineItem) => {
        const productForLineItem = productsById.get(lineItem.getProductId());
        if (typeof productForLineItem === 'undefined') {
            throw new RuntimeException('unexpected');
        }
        const productCategory = productForLineItem.getProductCategoryId();

        const costForLineItem = getTotalCostOfDeliveryLineItem(lineItem);

        const oldValue = breakdownByCategory.get(productCategory);
        if (typeof oldValue === 'undefined') {
            breakdownByCategory.set(productCategory, costForLineItem);
        } else {
            const newValue = oldValue + costForLineItem;
            breakdownByCategory.set(productCategory, newValue);
        }
    });

    return breakdownByCategory;
};

const getTotalCostOfDeliveryLineItem = (lineItem : DeliveryLineItem) : number => {
    const quantity = lineItem.getQuantityInUnits();
    const price = lineItem.getPricePerUnit();
    const deposit = lineItem.getDepositPerUnit();
    const discount = lineItem.getDiscount();
    const tax = lineItem.getTax();
    const otherAdjustments = lineItem.getOtherAdjustments();

    return quantity * (price + deposit) + tax - discount + otherAdjustments; // This works without resolving unit because the resolution keeps this all constant
};

const getCostOfDeliveryLineItemExcludingDeposit = (lineItem : DeliveryLineItem) : number => {
    const quantity = lineItem.getQuantityInUnits();
    const price = lineItem.getPricePerUnit();
    const discount = lineItem.getDiscount();
    const tax = lineItem.getTax();
    const otherAdjustments = lineItem.getOtherAdjustments();

    return quantity * price + (tax - discount + otherAdjustments); // This works without resolving unit because the resolution keeps this all constant
};

// user defined totals are bundled with uncategorized adjustments
const getTotalUncategorizedAdjustments = (delivery : Delivery) : number => {
    const userDefinedTotal = delivery.getUserDefinedTotal();
    if (userDefinedTotal !== null) {
        const totalCostOfCategoryAdjustments =  getTotalCostOfCategoryAdjustments(delivery);
        const costOfLineItems = delivery.getDeliveryLineItems().reduce((accumulator : number, lineItem : DeliveryLineItem) => {
            return accumulator + getTotalCostOfDeliveryLineItem(lineItem);
        }, 0);

        return userDefinedTotal - (costOfLineItems + totalCostOfCategoryAdjustments + getTotalTaxes(delivery));
    } else {
        return  delivery.getUncategorizedPriceAdjustments().reduce((accumulator : number, adjustmentValue) => {
            return accumulator + adjustmentValue.getAmountInDollars();
        }, 0);
    }
};

const getTotalTaxes = (delivery : Delivery) : number => {
    let totalTaxes = 0;
    delivery.getTaxesByName().forEach((taxValue) => totalTaxes += taxValue);
    return totalTaxes;
};

const getTotalCostOfDelivery = (delivery : Delivery) : number => {
    const userDefinedTotal = delivery.getUserDefinedTotal();
    if (userDefinedTotal !== null) {
        return userDefinedTotal;
    }

    const costOfLineItems = delivery.getDeliveryLineItems().reduce((accumulator : number, lineItem : DeliveryLineItem) => {
        return accumulator + getTotalCostOfDeliveryLineItem(lineItem);
    }, 0);

    const totalCostOfCategoryAdjustments : number = getTotalCostOfCategoryAdjustments(delivery);

    const costOfUncategorizedPriceAdjustments = delivery.getUncategorizedPriceAdjustments().reduce((accumulator : number, adjustmentValue) => {
        return accumulator + adjustmentValue.getAmountInDollars();
    }, 0);

    let costOfTaxes = 0;
    delivery.getTaxesByName().forEach((taxValue) => {
        costOfTaxes += taxValue;
    });

    return costOfLineItems + totalCostOfCategoryAdjustments + costOfUncategorizedPriceAdjustments + costOfTaxes;
};

// specific use case for order history table to avoid recalculating all parts
const getTotalCostOfDeliveryGivenPreProcessedDeliveryData = (userDefinedTotal : number | null, taxesTotal : number, uncategorizedAdjustmentsTotal : number, breakdownByCategory : Map<string, number>) : number => {
    return userDefinedTotal !== null ? userDefinedTotal : (taxesTotal + uncategorizedAdjustmentsTotal + getTotalFromCategoryBreakdown(breakdownByCategory));
};
/**
 * HELPER FUNCTIONS
 */
const getTotalCostOfCategoryAdjustments = (delivery : Delivery) : number => {
    let totalCostOfCategoryAdjustments : number = 0;
    delivery.getPriceAdjustmentsByProductCategoryId().forEach((adjustments, productCategoryId) => {
        const priceAdjustmentsForCategoryTotal = adjustments.reduce((accumulator : number, adjustmentValue) => {
            return accumulator + adjustmentValue.getAmountInDollars();
        }, 0);
        totalCostOfCategoryAdjustments += priceAdjustmentsForCategoryTotal;
    });
    return totalCostOfCategoryAdjustments;
};

const getTotalFromCategoryBreakdown = (breakdownByCategory : Map<string, number>) : number => {
    let total : number = 0;
    breakdownByCategory.forEach((categoryTotal, productCategoryId) => {
        total += categoryTotal;
    });
    return total;
};

const getOrderWasCommunicatedToDistributor = (delivery : Delivery | DeliveryMetadata) : boolean => {
    let communicatedToDistributor = false;
    delivery.getDistributorReps().forEach((distributorRep) => {
        if (distributorRep.getEmailWasSentForOrder() || distributorRep.getSmsWasSentForOrder()) {
            communicatedToDistributor = true;
        }
    });

    return communicatedToDistributor;
};

const getOrderStatus = (delivery : Delivery | DeliveryMetadata) : OrderStatus => {
    if (delivery.getDeliveryConfirmationEventTimeUTC()) {
        return OrderStatus.DELIVERED;
    }

    if (getOrderWasCommunicatedToDistributor(delivery)) {
        return OrderStatus.COMMUNICATED;
    }

    return OrderStatus.RECORDED;
};

const getInvoiceStatus = (invoiceUploads: Array<InvoiceUpload>, delivery: Delivery) : InvoiceStatus  => {
    if (invoiceUploads.length === 0) {
        return InvoiceStatus.UPLOAD_NEEDED;
    } else if (delivery.getApprovalEvent() != null) {
        return InvoiceStatus.APPROVED;
    } else if (delivery.getReadyForApprovalEvent() != null) {
        return InvoiceStatus.READY_FOR_APPROVAL;
    } else {
        // if anything failed, user needs to do something
        for (let i = 0; i < invoiceUploads.length; i++) {
            const uploadedInvoice = invoiceUploads[i].getInvoiceProcessingData();
            if (uploadedInvoice) {
                const result = uploadedInvoice.getResult();
                if (result && result.getErrorMessage()) {
                    return InvoiceStatus.PROCESSING_FAILED;
                }
            }
        }

        for (let i = 0; i < invoiceUploads.length; i++) {
            const uploadedInvoice = invoiceUploads[i].getInvoiceProcessingData();
            if (uploadedInvoice && !uploadedInvoice.getResult()) {
                return InvoiceStatus.PROCESSING;
            }
        }

        for (let i = 0; i < invoiceUploads.length; i++) {
            const uploadedInvoice = invoiceUploads[i].getInvoiceProcessingData();
            if (uploadedInvoice && uploadedInvoice.getResult()) {
                // the whole delivery is not ready for review until everything succeeded
                return InvoiceStatus.NEEDS_REVIEW;
            }
        }

        return InvoiceStatus.UPLOADED;
    }
};

const getInvoiceErrorMessage = (invoiceUploads: Array<InvoiceUpload>) : null | string => {
    for (let i = 0; i < invoiceUploads.length; i++) {
        const uploadedInvoice = invoiceUploads[i].getInvoiceProcessingData();
        if (uploadedInvoice && uploadedInvoice.getResult()) {
            const result = uploadedInvoice.getResult();
            if (result && result.getErrorMessage()) {
                return result.getErrorMessage();
            }
        }
    }
    return null;
};

const getExcelExportURL = (
    locationId : LocationId,
    deliveryId : DeliveryId,
) : string => {
    return `${ url('ordering:record:excel_export', null, locationId.getValue(), null) }?delivery_id=${ deliveryId.getValue() }`;
};

const getDeliveryWithUpdatedInvoiceNumber = (delivery : Delivery, newInvoiceNumber : string | null) : Delivery => {
    return new Delivery(
        delivery.getDatePlacedUTC(),
        delivery.getDateDeliveredUTC(),
        delivery.getDistributorId(),
        delivery.getDeliveryLineItems(),
        delivery.getPriceAdjustmentsByProductCategoryId(),
        delivery.getUncategorizedPriceAdjustments(),
        delivery.getTaxesByName(),
        newInvoiceNumber,
        delivery.getUserDefinedTotal(),
        delivery.getNoteToRep(),
        delivery.getNoteToSelf(),
        delivery.getPurchaseOrderNumber(),
        delivery.getDistributorReps(),
        delivery.getRecordedByName(),
        delivery.getDateRecordedUTC(),
        delivery.getDeliveryConfirmationEventTimeUTC(),
        delivery.getDeliveryConfirmationUserId(),
        delivery.getHiddenInvoiceUploadLineItemIdsByInvoiceUploadId(),
        delivery.getApprovalEvent(),
        delivery.getReadyForApprovalEvent(),
        delivery.getLastUpdateEvent()
);
};

const getDeliveryWithUpdatedDeliveryLineItems = (delivery : Delivery, newDeliveryLineItems : Array<DeliveryLineItem>) : Delivery => {
    return new Delivery(
        delivery.getDatePlacedUTC(),
        delivery.getDateDeliveredUTC(),
        delivery.getDistributorId(),
        newDeliveryLineItems,
        delivery.getPriceAdjustmentsByProductCategoryId(),
        delivery.getUncategorizedPriceAdjustments(),
        delivery.getTaxesByName(),
        delivery.getInvoiceNumber(),
        delivery.getUserDefinedTotal(),
        delivery.getNoteToRep(),
        delivery.getNoteToSelf(),
        delivery.getPurchaseOrderNumber(),
        delivery.getDistributorReps(),
        delivery.getRecordedByName(),
        delivery.getDateRecordedUTC(),
        delivery.getDeliveryConfirmationEventTimeUTC(),
        delivery.getDeliveryConfirmationUserId(),
        delivery.getHiddenInvoiceUploadLineItemIdsByInvoiceUploadId(),
        delivery.getApprovalEvent(),
        delivery.getReadyForApprovalEvent(),
        delivery.getLastUpdateEvent()
);
};

const getDeliveryWithInvoiceUploadRemoved = (delivery : Delivery, invoiceUploadId : InvoiceUploadId) : Delivery => {
    const lineItems = delivery.getDeliveryLineItems().map((lineItem) => {
        if (invoiceUploadId.equals(lineItem.getInvoiceUploadId())) {
            return new DeliveryLineItem(
                lineItem.getProductId(),
                lineItem.getProductQuantityUnit(),
                lineItem.getQuantityInUnits(),
                lineItem.getPricePerUnit(),
                lineItem.getDepositPerUnit(),
                lineItem.getDiscount(),
                lineItem.getTax(),
                lineItem.getOtherAdjustments(),
                null,
                null,
            );
        }

        return lineItem;
    });

    let hiddenInvoiceUploadLineItemIdsByInvoiceUploadId;
    if (delivery.getHiddenInvoiceUploadLineItemIdsByInvoiceUploadId().has(invoiceUploadId)) {
        hiddenInvoiceUploadLineItemIdsByInvoiceUploadId = new StringValueMap(delivery.getHiddenInvoiceUploadLineItemIdsByInvoiceUploadId());
        hiddenInvoiceUploadLineItemIdsByInvoiceUploadId.delete(invoiceUploadId);
    } else {
        hiddenInvoiceUploadLineItemIdsByInvoiceUploadId = delivery.getHiddenInvoiceUploadLineItemIdsByInvoiceUploadId();
    }

    return new Delivery(
        delivery.getDatePlacedUTC(),
        delivery.getDateDeliveredUTC(),
        delivery.getDistributorId(),
        lineItems,
        delivery.getPriceAdjustmentsByProductCategoryId(),
        delivery.getUncategorizedPriceAdjustments(),
        delivery.getTaxesByName(),
        delivery.getInvoiceNumber(),
        delivery.getUserDefinedTotal(),
        delivery.getNoteToRep(),
        delivery.getNoteToSelf(),
        delivery.getPurchaseOrderNumber(),
        delivery.getDistributorReps(),
        delivery.getRecordedByName(),
        delivery.getDateRecordedUTC(),
        delivery.getDeliveryConfirmationEventTimeUTC(),
        delivery.getDeliveryConfirmationUserId(),
        hiddenInvoiceUploadLineItemIdsByInvoiceUploadId,
        delivery.getApprovalEvent(),
        delivery.getReadyForApprovalEvent(),
        delivery.getLastUpdateEvent()
    );
};

const getProductIdsInDelivery = (delivery : Delivery) : StringValueSet<ProductId> => {
    const productIds = new StringValueSet<ProductId>();

    delivery.getDeliveryLineItems().forEach((deliveryLineItem) => {
        productIds.add(deliveryLineItem.getProductId());
    });

    return productIds;
};

const getNumberOfOrdersByProductIdInDeliveries = (deliveriesById: StringValueMap<DeliveryId, Delivery>) : StringValueMap<ProductId, number> => {
    const numberOfProductInDeliveriesById = new StringValueMap<ProductId, number>();
    deliveriesById.forEach((delivery) => {
        delivery.getDeliveryLineItems().forEach((lineItem) => {
            if (!numberOfProductInDeliveriesById.has(lineItem.getProductId())) {
                numberOfProductInDeliveriesById.set(lineItem.getProductId(), 1);
            } else {
                numberOfProductInDeliveriesById.set(lineItem.getProductId(), numberOfProductInDeliveriesById.getRequired(lineItem.getProductId()) + 1);
            }
        });
    });
    return numberOfProductInDeliveriesById;
};

export const DeliveryUtils = {
    getOrderBreakdownByProductCategory,
    getCostOfDeliveryLineItemExcludingDeposit,
    getTotalCostOfDeliveryLineItem,
    getTotalUncategorizedAdjustments,
    getTotalTaxes,
    getTotalCostOfDelivery,
    getTotalCostOfDeliveryGivenPreProcessedDeliveryData,
    getOrderWasCommunicatedToDistributor,
    getOrderStatus,
    getInvoiceStatus,
    getInvoiceErrorMessage,
    getExcelExportURL,
    getDeliveryWithInvoiceUploadRemoved,
    getDeliveryWithUpdatedInvoiceNumber,
    getDeliveryWithUpdatedDeliveryLineItems,
    getProductIdsInDelivery,
    getNumberOfOrdersByProductIdInDeliveries,
};
