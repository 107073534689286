import { IntegrationState } from 'api/Integration/model/IntegrationState';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IExtraArguments } from 'shared/components/Provider';
import { IAccountSessionReader } from 'shared/lib/account/interfaces/IAccountSessionReader';
import {IAction, ISetShownAction} from 'shared/models/IAction';

import { IPOSIntegrationService } from 'api/Integration/interfaces/IPOSIntegrationService';
import { LocationId } from 'api/Location/model/LocationId';
import { ILightspeedIntegrationBlockState } from './LightspeedIntegrationBlockReducers';
import {integrationService} from "shared/lib/manager";
import { action } from "typesafe-actions";
import { oauthUtils } from "shared/components/PosIntegration/OAuthUtils";
import {RuntimeException} from "shared/lib/general/exceptions/RuntimeException";
import Thrift from "thrift";

export interface ILightspeedIntegrationBlockStore {
    lightspeedIntegrationBlockState : ILightspeedIntegrationBlockState;
}

export const ActionTypes = {
    SET_MODAL_SHOWN: 'LIGHTSPEED_INTEGRATION/SET_MODAL_SHOWN',
    SET_EXISTING_INTEGRATION_STATE: 'LIGHTSPEED_INTEGRATION/SET_EXISTING_INTEGRATION_STATE',
    SET_IS_LOADING: 'LIGHTSPEED_INTEGRATION/SET_IS_LOADING',
    SET_MODAL_ERROR: 'LIGHTSPEED_INTEGRATION/SET_MODAL_ERROR',
    SET_MODAL_ACCESS_TOKEN: 'LIGHTSPEED_INTEGRATION/SET_MODAL_ACCESS_TOKEN',
};

export namespace LightspeedIntegrationActionInterfaces {
    export interface ISetModalShown extends ISetShownAction {
    }

    export interface ISetIsLoading extends IAction {
        payload : {
            isLoading : boolean
        };
    }

    export interface ISetModalAccessToken extends IAction {
        payload : {
            accessToken : string | null
        };
    }

    export interface ISetExistingIntegrationState extends IAction {
        payload : {
            integrationState : IntegrationState
        };
    }

    export interface IServices {
        userSessionReader : IAccountSessionReader<UserSessionId, UserAccountId>;
        integrationService : IPOSIntegrationService;
    }
    export interface IThunkServices extends IExtraArguments {
        services : IServices;
    }
}

const setModalShown = (
    isShown : boolean
) : LightspeedIntegrationActionInterfaces.ISetModalShown => ({
    payload : {
        isShown,
    },
    type : ActionTypes.SET_MODAL_SHOWN
});

const setModalAccessToken = (
    accessToken : string | null
) : LightspeedIntegrationActionInterfaces.ISetModalAccessToken => ({
    payload : {
        accessToken,
    },
    type : ActionTypes.SET_MODAL_ACCESS_TOKEN
});

const setIsLoading = (
    isLoading : boolean
) : LightspeedIntegrationActionInterfaces.ISetIsLoading => ({
    payload : {
        isLoading
    },
    type: ActionTypes.SET_IS_LOADING,
});

const setExistingCredentialState = (
    integrationState : IntegrationState
) : LightspeedIntegrationActionInterfaces.ISetExistingIntegrationState => ({
    payload : {
        integrationState,
    },
    type: ActionTypes.SET_EXISTING_INTEGRATION_STATE,
});

const setError = (error : null | string) => action(ActionTypes.SET_MODAL_ERROR, { error });

const fetchExistingIntegration = (
    locationId : LocationId
) : ThunkAction<Promise<IntegrationState>, ILightspeedIntegrationBlockStore, LightspeedIntegrationActionInterfaces.IThunkServices> => {
    return (dispatch : Dispatch<ILightspeedIntegrationBlockStore>, getState : () => ILightspeedIntegrationBlockStore, extraArguments : LightspeedIntegrationActionInterfaces.IThunkServices) : Promise<IntegrationState> => {
        dispatch(setIsLoading(true));
        const session = extraArguments.services.userSessionReader.getSessionId();
        return extraArguments.services.integrationService.getIntegrationState('lightspeed', session, locationId)
        .then((data : IntegrationState) => {
            dispatch(setExistingCredentialState(data));
            dispatch(setIsLoading(false));
            return data;
        })
        .catch((e : Error) => {
            dispatch(setIsLoading(false));
            throw e;
        });
    };
};

const completeOAuthFlow = (
    locationId : LocationId,
    authorizationCode : string
) : ThunkAction<Promise<void>, ILightspeedIntegrationBlockStore, LightspeedIntegrationActionInterfaces.IThunkServices> => {
    return async (dispatch : Dispatch<ILightspeedIntegrationBlockStore>, getState : () => ILightspeedIntegrationBlockStore, extraArguments : LightspeedIntegrationActionInterfaces.IThunkServices) => {
        dispatch(setIsLoading(true));
        dispatch(setModalShown(true));
        const session = extraArguments.services.userSessionReader.getSessionId();
        try {
            await integrationService.addIntegrationThroughOAuthFlow(
                "lightspeed",
                session,
                locationId,
                authorizationCode,
                oauthUtils.getPKCEVerifier() || undefined
            );
        } catch (e) {
            if (e instanceof Thrift.TException) {
                throw new RuntimeException(e.name);
            }
            throw e;
        }
        await dispatch(fetchExistingIntegration(locationId));
        dispatch(setIsLoading(false));
    };
};

const disconnect = (
    locationId : LocationId,
) : ThunkAction<Promise<void>, ILightspeedIntegrationBlockStore, LightspeedIntegrationActionInterfaces.IThunkServices> => {
    return async (dispatch : Dispatch<ILightspeedIntegrationBlockStore>, getState : () => ILightspeedIntegrationBlockStore, extraArguments : LightspeedIntegrationActionInterfaces.IThunkServices) => {
        const session = extraArguments.services.userSessionReader.getSessionId();
        await integrationService.removeIntegration(
            "lightspeed",
            session,
            locationId
        );
        await dispatch(fetchExistingIntegration(locationId));
    };
};

const retrieveAccessToken = (
    locationId : LocationId,
) : ThunkAction<Promise<void>, ILightspeedIntegrationBlockStore, LightspeedIntegrationActionInterfaces.IThunkServices> => {
    return (dispatch : Dispatch<ILightspeedIntegrationBlockStore>, getState : () => ILightspeedIntegrationBlockStore, extraArguments : LightspeedIntegrationActionInterfaces.IThunkServices) : Promise<void> => {
        const session = extraArguments.services.userSessionReader.getSessionId();
        return integrationService.retrieveAccessToken(
            session,
            locationId,
            "lightspeed"
        ).then((accessToken : string | null) => {
            if (accessToken == null || accessToken === "") {
                accessToken = "Access token not found.";
            }

            dispatch(setModalAccessToken(accessToken));
        }).catch((error) => {
            throw error;
        });
    };
};

export const LightspeedIntegrationBlockActions = {
    setModalShown,
    fetchExistingIntegration,
    completeOAuthFlow,
    disconnect,
    setError,
    setModalAccessToken,
    retrieveAccessToken
};
