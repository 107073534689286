import React from 'react';

import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { BrandAndName } from 'shared/components/Product/BrandAndName';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';

interface IUnmatchedProductPanelProps {
    readonly currentIndex : number;
    readonly totalProductCount : number;
    readonly product : Product;
    readonly selectedProductFormIsValid : boolean;
    readonly partnerLocationName : string;
    readonly currentProductForm : {
        productId : ProductId;
        productQuantityUnit : ProductQuantityUnit;
    };
}

export class UnmatchedProductPanel extends React.Component<IUnmatchedProductPanelProps, object> {

    public render() {
        const {
            currentIndex,
            totalProductCount,
            product,
            currentProductForm,
            partnerLocationName,
            selectedProductFormIsValid,
        } = this.props;

        if (currentIndex <= 0) {
            throw new Error('itemNumber must be greater than 0');
        }
        if (totalProductCount <= 0) {
            throw new Error('count must be greater than 0');
        }
        if (currentIndex > totalProductCount) {
            throw new Error('count must be greater than itemNumber');
        }
        if (product === null) {
            throw new Error('product must be set');
        }

        let warning : JSX.Element | null = null;
        if (!selectedProductFormIsValid) {
            warning = (
                <span>Please match to an item right. Unit packaging quantities should match between two products.</span>
            );
        }

        const packagingsAndMappings = product.getPackagingsAndMappings();
        const baseUnitDisplayText = PackagingUtils.getDisplayTextForProductQuantityUnit(packagingsAndMappings, currentProductForm.productQuantityUnit, false);

        return (
            <div className="unmatched-product-panel cell col-xs-12 col-sm-12 col-md-6 cell-plr">
                <p>Item { currentIndex } of { totalProductCount }</p>
                <div className="unmatched-instructions cell-ptb">
                    <p>Search the { partnerLocationName }'s inventory list on the right to find the matching inventory item.</p>
                    <br/>
                    <p>If no match is found, a new item can be created and added to { partnerLocationName }'s with the same packaging and pricing information.</p>
                </div>
                
                <span className="unmatched-warning">{ warning }</span>
                <div className="unmatched-product-information">
                    <BrandAndName
                        brand={ product.getBrand() }
                        name={ product.getName() }
                        category={ product.getProductCategoryId() }
                        subcategory={ product.getProductType() }
                    />
                    <br/>
                    <div className="col-row unit-display">
                        Base Unit: 1 { baseUnitDisplayText }
                    </div>
                </div>
            </div>
        );
    }
}
