import React from 'react';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { getCurrencySymbol } from 'shared/models/Currency';

import 'shared/css/ValidationInput.scss';

export enum ValidationInputTheme {
    Default = 1,
    Basic = 5,
    BasicInverted = 6,
    SearchBarLegacy = 7,
}

// Some browswers ignore autocomplete=off so using this instead
// See https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
const NOT_A_VALID_AUTOCOMPLETE_VALUE = '_NOT_A_VALID_AUTOCOMPLETE_VALUE_';

export type autoCompleteValue = 'cc-name' | 'cc-given-name' | 'cc-additional-name' | 'cc-family-name' | 'cc-number' | 'cc-exp' | 'cc-exp-month' | 'cc-exp-year' | 'cc-csc' | 'cc-type' | 'address-line1' | 'address-line2' | 'postal-code';

export interface IValidationInputData {
    value : string;
    isValid : boolean;
    errorMessage : string;
}

/**
 * Important if type="number":
 * decimal inputs are considered invalid if step is not set (default is step=1). decimal places in step represent the number of valid decimal places for that input
 * If step is "any", any decimal or integer is considered valid
 * Firefox is now putting red boxes around invalid number inputs, so this is worse than it used to be.
 * Also microsoft edge has an existing bug with number inputs and does not correctly validate strings, so we should refactor to not use number inputs
 */
export interface IValidationInputProps extends IValidationInputData {
    type : string;
    label : string | null;
    hintText : string | null;
    value : string;
    step? : number | 'any';
    min? : number;
    maxLength? : number;
    autoFocus : boolean; // https://github.com/facebook/react/issues/5534 (only works on initial render, not updates)
    autoComplete : autoCompleteValue | null;
    autoCorrect? : string;
    isValid : boolean;
    errorMessage : string;
    inputClassName : string;
    isDisabled? : boolean;
    handleEnterClick : ((event : React.KeyboardEvent<HTMLInputElement>) => void) | null;
    handleChange : (event : React.ChangeEvent<HTMLInputElement>) => void;
    handleBlur : ((event : React.FocusEvent<HTMLInputElement>) => void) | null;
    handleFocus : ((event : React.FocusEvent<HTMLInputElement>) => void) | null;
    isCurrencyInput? : boolean; // CAS NOTE: this isn't our favorite way to accomplish this but it would require a significant refactor to implement a better solution. Made APP-3502 in Jira to account for it
    isPercentInput? : boolean; // CAS NOTE: this isn't our favorite way to accomplish this but it would require a significant refactor to implement a better solution. Made APP-3502 in Jira to account for it
    theme? : ValidationInputTheme;
}

export class ValidationInput extends React.Component<IValidationInputProps, object> {
    private wrapperElement : Element | undefined;
    private readonly currencySymbol = getCurrencySymbol();

    public shouldComponentUpdate(nextProps : IValidationInputProps, nextState : object) {
        return (this.props.label !== nextProps.label) ||
               (this.props.value !== nextProps.value) ||
               (this.props.autoFocus !== nextProps.autoFocus) ||
               (this.props.autoComplete !== nextProps.autoComplete) ||
               (this.props.isValid !== nextProps.isValid) ||
               (this.props.errorMessage !== nextProps.errorMessage) ||
               (this.props.inputClassName !== nextProps.inputClassName) ||
               (this.props.hintText !== nextProps.hintText) ||
               (this.props.isDisabled !== nextProps.isDisabled) ||
               (this.props.isCurrencyInput !== nextProps.isCurrencyInput) ||
               (this.props.isPercentInput !== nextProps.isPercentInput);
    }

    public render() {
        const {
            type,
            step,
            label,
            hintText,
            isValid,
            handleEnterClick,
            handleChange,
            value,
            min,
            maxLength,
            autoFocus,
            autoCorrect,
            autoComplete,
            inputClassName,
            errorMessage,
            isDisabled,
            isCurrencyInput,
            isPercentInput,
        } = this.props;

        const labelActiveClasses = ((hintText !== null && hintText.length > 0) || value.length > 0)  ? 'input-label-active ' : '';

        const onKeyUp = handleEnterClick === null ? this.noop : (event : React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                handleEnterClick(event);
            }
        };

        if (type === 'number' && typeof step === 'undefined') {
            throw new RuntimeException('step must be set for a number input');
        }

        const validationClass = isValid ? '' : ' invalid-input';

        return (
            <span
                className={ this.generateClassNames() }
                ref={ this.wrapperRef }
            >
                <input
                    type={ type }
                    step={ type  === 'number' ? step : undefined }
                    min={ min }
                    maxLength={ maxLength }
                    className={ 'validation-input ' + inputClassName + validationClass + (isCurrencyInput ? ' currency-input ' : '') + (isPercentInput ? ' percent-input ' : '') }
                    onKeyUp={ onKeyUp }
                    onChange={ handleChange }
                    onBlur={ this.onBlur }
                    onFocus={ this.onFocus }
                    value={ value }
                    autoFocus={ autoFocus }
                    autoComplete={ autoComplete || NOT_A_VALID_AUTOCOMPLETE_VALUE }
                    autoCorrect={ autoCorrect || '' }
                    disabled={ isDisabled }
                    placeholder={ hintText !== null ? hintText : '' }
                />
                <hr className="active-border"/> { /* note: due to some legacy css, this must come before the currency/percent */ }
                <hr className="bottom-border"/>
                { isCurrencyInput &&
                    <span className="validation-input-symbol validation-input-pre-symbol currency-symbol">{ this.currencySymbol }</span>
                }
                { isPercentInput &&
                    <span className="validation-input-symbol validation-input-post-symbol percent-symbol">%</span>
                }
                { label &&
                    <label className={ 'input-label ' + labelActiveClasses }>
                        { label }
                    </label>
                }
                { !isValid &&
                    <div className="error-text">
                        { errorMessage }
                    </div>
                }
            </span>
        );
    }

    private noop() {
        return;
    }

    private generateClassNames() {
        const {
            isCurrencyInput,
            isPercentInput,
            isDisabled,
            theme
        } = this.props;

        let themeClasses = 'validation-input-wrapper' + (isDisabled ? ' disabled' : ' ');

        if (isCurrencyInput) {
            themeClasses += ' input-pre-symbol';
        }

        if (isPercentInput) {
            themeClasses += ' input-post-symbol';
        }

        switch (theme) {
            case ValidationInputTheme.Basic:
                return themeClasses + ' theme-basic';
            case ValidationInputTheme.BasicInverted:
                return themeClasses + ' theme-basic-inverted';
            case ValidationInputTheme.SearchBarLegacy:
                return themeClasses + ' theme-search-bar--legacy';
            default:
                return themeClasses;
        }
    }

    private onFocus = (event : React.FocusEvent<HTMLInputElement>) => {
        window.requestAnimationFrame(() => {
            // inputElement.setSelectionRange(0, 9999);
            if (this.wrapperElement) {
                this.wrapperElement.className = this.generateClassNames() + ' active';
            }
        });

        if (this.props.handleFocus) {
            this.props.handleFocus(event);
        }
    }

    private onBlur = (event : React.FocusEvent<HTMLInputElement>) => {
        if (this.wrapperElement) {
            this.wrapperElement.className = this.generateClassNames();
        }

        if (this.props.handleBlur !== null) {
            this.props.handleBlur(event);
        }
    }

    private wrapperRef = (wrapperElement : HTMLDivElement) : void => {
        this.wrapperElement = wrapperElement;
    }
}
