import React from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Dispatch } from 'redux';

import { CreateOrEditSalesItemActions, ICreateOrEditSalesItemStore } from 'apps/CreateOrEditSalesItem/actions/actions';

import { StringValueSet } from 'api/Core/StringValueSet';
import { ProductQuickAdd } from 'api/Onboarding/model/ProductQuickAdd';
import { ProductId } from 'api/Product/model/ProductId';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';

import { StringValueMap } from 'api/Core/StringValueMap';
import { ItemLevelSalesReportConfigurationId } from 'api/Reports/model/ItemLevelSalesReportConfigurationId';
import { ApplyChangesDialog } from './components/ApplyChangesDialog';
import { IngredientInfo } from './components/IngredientInfo';
import { SalesItemFlagAlert } from './components/SalesItemFlagAlert';
import { availableSalesItemFlags, SalesItemFlagSelector } from './components/SalesItemFlagSelector';
import { UnsavedChangesDialog } from './components/UnsavedChangesDialog';
import {
    ICreateOrEditSalesItemState, IngredientFormFieldName, IPmixItemInformation,
    ProductIngredientRowFormFieldName, SalesInformationFormFieldName, SalesItemIngredientRowFormFieldName, SalesItemSaveOption
} from './reducers/reducers';
import { CreateOrEditSalesItemFormUtils } from './utils/CreateOrEditSalesItemFormUtils';

import { Button } from 'shared/components/Button';
import { Flex } from 'shared/components/FlexLayout/Flex';
import { LoadingCover } from 'shared/components/LoadingCover';
import { PageHeader, PageHeaderTheme } from 'shared/components/PageHeader/PageHeader';
import { PageTitle } from 'shared/components/PageTitle/PageTitle';
import { Popover } from 'shared/components/Popover/Popover';
import { IProductSlimCreateFormStore, ProductSlimCreateFormActions } from 'shared/components/SlimCreate/ProductSlimCreateForm/ProductSlimCreateFormActions';
import { IProductSlimCreateFormState } from 'shared/components/SlimCreate/ProductSlimCreateForm/ProductSlimCreateFormReducers';
import { SnackBar } from 'shared/components/SnackBar';
import { MAX_MOBILE_WIDTH, MIN_TABLET_WIDTH } from 'shared/constants';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import { SalesEntry } from 'api/Reports/model/SalesEntry';
import { PosItem } from 'api/SalesData/model/PosItem';
import { MappedStatus } from 'apps/SalesItemMapper/appTypes';
import { Dialog } from 'shared/components/Dialog';
import { SalesInputRowId } from 'shared/components/SalesInputTable/SalesInputRow';
import { StringUtils } from 'shared/utils/stringUtils';
import { InformationFromPOS } from './components/MapView/InformationFromPOS';
import { MapViewSalesInformation } from './components/MapView/MapViewSalesInformation';

import { PosItemId } from 'api/SalesData/model/PosItemId';

import './css/CreateOrEditSalesItemApp.scss'; // TODO might just want to use a different css file

/**
 * This is just a different (more limited) UI wrapper of the create/edit sales item app. therefore it shares a store/actions/reducers
 * If the behavior of these 2 things ever starts to really diverge, we should consider splitting this into its own store, etc.
 * however, given the relative complexity of the actions/reducers and that under the hood these things behave the same, we are building
 * these off of the same base for now.
 */

// TODO rename the other thing to CS Sales Mapping Tool.
export interface IMapSalesItemViewProps {
    initialItem : SalesInputRowId; // either an existing sales item or initialize from a POS Item.
    initialContextOrderedIds : Array<SalesInputRowId>;
    contextItemLevelReportId : ItemLevelSalesReportConfigurationId | null;
    posItemsByPosId : StringValueMap<PosItemId, PosItem>;
    unmappedSalesEntries : StringValueMap<PosItemId, SalesEntry>;

    initialRemainingUnmappedCount : number; // this includes unmapped pos items & empty sales items. the parent page has context for what needs to be finished
    isCustomerSuccessTool? : boolean;

    onClose : () => void;
    onSave : (updatedSalesItemIdMap : StringValueMap<SalesInputRowId, SalesItemId>) => void; // note: if we're creating from a pos item this returns map of pos id -> new sales item id
    onOmitItem : ((omittedItem : SalesInputRowId) => void) | null; // not available in all contexts;

    actionButtonText : string;
    onActionButtonClick : () => void; // could be "run report" or "finish"

    productSlimCreateFormState : IProductSlimCreateFormState;

    // Note: we have decided for now that free trial status is not necessary for this Map view.
    // if someone can access this, they can create any items they want
    // freeTrialStatus : FreeTrialStatus;
}

export interface IConnectedMapSalesItemView extends IMapSalesItemViewProps {
    dispatch : Dispatch<ICreateOrEditSalesItemStore & IProductSlimCreateFormStore>;
    createOrEditSalesItemState : ICreateOrEditSalesItemState;
}

// Exported because this is shared by the recipe tool
// key codes to take action on:
// 78 (n), 39 (>) = Next
// 80 (p), 37 (<) = Previous
// 70 (f) = Un/flag
export const actionKeyCodeSet = new Set([37, 39, 70, 78, 80]);
export enum ActionKeyCodes {
    LEFT_ARROW = 37,
    RIGHT_ARROW = 39,
    f = 70,
    n = 78,
    p = 80,
}

class MapSalesItemView extends React.Component<IConnectedMapSalesItemView, object> {
    private userIsAdmin : boolean;
    private contextOrderedIds : Array<SalesInputRowId>;
    private onlyShowAllTimeSaveOption : boolean;
    private posInformationByPosItemId = new StringValueMap<PosItemId, IPmixItemInformation>();
    private totalUnmappedCount : number;

    constructor(props : IConnectedMapSalesItemView) {
        super(props);

        this.userIsAdmin = window.GLOBAL_USER_IS_ADMIN;
        this.contextOrderedIds = this.props.initialContextOrderedIds;
        this.onlyShowAllTimeSaveOption = !window.GLOBAL_FEATURE_ACCESS.item_level_sales_reporting || !!this.props.isCustomerSuccessTool;
        this.totalUnmappedCount = this.props.initialRemainingUnmappedCount;
    }

    public componentDidMount() {
        const {
            dispatch,
            initialItem,
            initialContextOrderedIds,
            createOrEditSalesItemState,
            posItemsByPosId,
            unmappedSalesEntries,
            initialRemainingUnmappedCount,
            isCustomerSuccessTool,
        } = this.props;

        // only need to fetch data if it's not already set
        let initialDataPromise : Promise<void>;
        if (createOrEditSalesItemState.salesItemFormData === null) {
            initialDataPromise = dispatch(CreateOrEditSalesItemActions.fetchInitialData());
        } else {
            initialDataPromise = Promise.resolve();
        }

        this.contextOrderedIds = initialContextOrderedIds;
        this.totalUnmappedCount = initialRemainingUnmappedCount;

        this.posInformationByPosItemId.clear();
        unmappedSalesEntries.forEach((salesEntry, posItemId) => {
            this.posInformationByPosItemId.set(posItemId, {
                salesEntry,
                posItem: posItemsByPosId.getRequired(posItemId)
            });
        });

        initialDataPromise
        .then(() => {
            dispatch(CreateOrEditSalesItemActions.initializeFormFromSalesItem(initialItem, initialContextOrderedIds, !!isCustomerSuccessTool, this.posInformationByPosItemId));
        });

        document.addEventListener('keyup', this.onDocumentKeyUp);

        $(document).on('customItemSuccessfullyEdited', this.onCustomItemSuccessfullyEdited);
        $(document).on('customItemDeleted', this.onCustomItemActiveStateChanged);
        $(document).on('customItemActiveStateChanged', this.onCustomItemActiveStateChanged);
        // $(document).on('editItemModalClosed', this.onEditItemModalClosed);
    }

    public componentWillUnmount() {
        document.removeEventListener('keyup', this.onDocumentKeyUp);

        $(document).off('customItemSuccessfullyEdited', this.onCustomItemSuccessfullyEdited);
        $(document).off('customItemDeleted', this.onCustomItemActiveStateChanged);
        $(document).off('customItemActiveStateChanged', this.onCustomItemActiveStateChanged);
        // $(document).off('editItemModalClosed', this.onEditItemModalClosed);
    }

    public render() {
        const {
            createOrEditSalesItemState,
            contextItemLevelReportId,
            isCustomerSuccessTool,
            actionButtonText,
            onOmitItem
        } = this.props;

        const {
            isShownByComponentName,
            salesItemForm,
            salesItemFormData,
            selectedSaveOption,
            salesItemId,
            nextSalesItemId,
            previousSalesItemId,
            popoverIngredientIdIsShown,
        } = createOrEditSalesItemState;

        // not disabling save for this tool based on features - should only be available
        // on pages where we are already feature checking
        // const saveIsDisabled : boolean =
        //     isShownByComponentName.saveIsDisabled ||
        //     // freeTrialStatus.getIsExpiredByAction().salesItems || // TODO free trial relevant??
        //     !window.GLOBAL_FEATURE_ACCESS.sales;

        let mappedStatus : MappedStatus;
        if (isCustomerSuccessTool && salesItemId instanceof SalesItemId && salesItemFormData !== null && salesItemFormData.salesItemsById.getRequired(salesItemId).getDeletionMetadata() !== null) {
            mappedStatus = MappedStatus.DELETED; // only relevant for cs tool, otherwise just show mapped vs unmapped
        } else {
            mappedStatus = salesItemForm.orderedIngredientItems.length === 0 ? MappedStatus.UNMAPPED : MappedStatus.MAPPED;
        }

        let mappingIconClassName : string = '';
        let mappingStatusText : string = '';
        switch (mappedStatus) {
            case MappedStatus.DELETED:
                mappingIconClassName = 'bevico-delete';
                mappingStatusText = 'Deleted'; // future: make this omitted when that functionality exists
                break;
            case MappedStatus.UNMAPPED:
                mappingIconClassName = 'bevico-unmapped';
                mappingStatusText = 'Unmapped';
                break;
            case MappedStatus.MAPPED:
                mappingIconClassName = 'bevico-check_circle';
                mappingStatusText = 'Mapped';
                break;
        }

        const canOmit = onOmitItem !== null && salesItemForm.salesItemInfoForm.posId.value !== ''; // can't omit things without a pos id??

        const actionButton = (
            <Button
                onClick={ this.handleActionButtonClick }
                buttonClassName="primary action-button"
                isDisabled={ isShownByComponentName.saveInProgress }
                isLoading={ false } // TODO maybe add a loading state? depends on how long this callback takes
            >
                { actionButtonText }
            </Button>
        );
        // TODO - Create separate pagination components for both Mapping Tool and Recipe Builder
        const salesItemPaginationContainer = (
            <React.Fragment>
                <div className="pagination-container mapped-pagination-container">
                    <Flex direction="row" className="pagination-button-wrapper mapped-pagination-button-wrapper">
                        <Button
                            isDisabled={ isShownByComponentName.saveInProgress || previousSalesItemId === null }
                            buttonClassName="primary capitalize pagination-button left-button"
                            isLoading={ false }
                            onClick={ this.onPreviousButtonClick }
                        >
                            <span className="bevicon bevico-keyboard-arrow-left main-icon-left"/>
                        </Button>
                        <MediaQuery maxWidth={ MAX_MOBILE_WIDTH } >
                            <div className="pagination-center-section-wrapper">
                                {/* this.totalUnmappedCount !== 0 && // always showing the action button now, but if we want to hide again, the below is the html for not showing the action button
                                    <Flex direction="column" align="center" justify="space-between" className="center-section">
                                        <span className="num-items-remaining">{ `${this.totalUnmappedCount} unmapped item${this.totalUnmappedCount === 1 ? '' : 's'} remaining` }</span>
                                        <div>
                                            <span className={ `bevicon ${mappingIconClassName}` } />
                                            <span className={ mappedStatus === MappedStatus.MAPPED ? 'mapped-status-text' : 'unmapped-status-text' }>
                                                { ` ${mappingStatusText}` }
                                            </span>
                                        </div>
                                    </Flex>
                                */ }
                                <Flex direction="column" align="center" justify="space-between" className="center-section">
                                    <Flex direction="row" className="mapping-status-row" justify="center" align="center">
                                        <span className="num-items-remaining pad-lr-sm">{ `${this.totalUnmappedCount} unmapped` }</span>
                                        <div>

                                            <span className={ `bevicon ${mappingIconClassName} ` } />
                                            <span className={ mappedStatus === MappedStatus.MAPPED ? 'mapped-status-text' : 'unmapped-status-text' }>
                                                { ` ${mappingStatusText}` }
                                            </span>
                                        </div>
                                    </Flex>
                                    { actionButton }
                                </Flex>
                            </div>
                        </MediaQuery>
                        <Button
                            isDisabled={ isShownByComponentName.saveInProgress || nextSalesItemId === null }
                            buttonClassName="primary capitalize pagination-button right-button"
                            isLoading={ false }
                            onClick={ this.onNextButtonClick }
                        >
                            <span className="bevicon bevico-keyboard-arrow-right main-icon-right"/>
                        </Button>
                        <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                            { actionButton }
                        </MediaQuery>
                    </Flex>
                </div>
                <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                    <span className="num-items-remaining">{ `${ StringUtils.pluralizeNumber('unmapped item', this.totalUnmappedCount) } remaining` }</span>
                </MediaQuery>
            </React.Fragment>
        );

        return(
            <div className="create-or-edit-sales-item map-sales-item-view">
                <PageHeader
                    theme={ PageHeaderTheme.Default }
                >
                    <Button
                        buttonClassName="icon flat bevicon bevico-arrow-back exit-view"
                        isDisabled={ isShownByComponentName.saveInProgress }
                        isLoading={ false }
                        onClick={ this.handleOnShowConfirmExitDialog }
                    />
                    <PageTitle
                        heading="Back to POS Data"
                        darkTheme={ false }
                    />
                    <div className="page-header-secondary">
                        { isCustomerSuccessTool && window.GLOBAL_USER_IS_ADMIN && // TODO consider removing if we are adding walkmes
                            <Popover
                                className="shortcut-popover"
                                showOnHover={ true }
                                preferredPositionArray={ ['below', 'right'] }
                                popoverContentIsShown={ isShownByComponentName.shortcutPopover }
                                setPopoverContentIsShown={ this.setShortcutPopoverIsShown }
                                anchorContent={ <span className="bevicon bevico-help"/> }
                                popoverContent={ (
                                    <React.Fragment>
                                        <h5>Shortcut List</h5>
                                        <ul>
                                            <li>
                                                Next:
                                                <span className="bevicon bevico-keyboard-arrow-right"/>
                                                or n
                                            </li>

                                            <li>
                                                Previous:
                                                <span className="bevicon bevico-keyboard-arrow-left"/>
                                                or p
                                            </li>
                                            <li>Un/flag: f</li>
                                        </ul>
                                    </React.Fragment>
                                ) }
                            />
                        }
                    </div>
                </PageHeader>

                { salesItemFormData === null &&
                    <LoadingCover
                        className="overlay-light"
                        hasLoadingIcon={ true }
                    />
                }

                { salesItemFormData !== null &&
                    <div className="main-container container">
                        { this.userIsAdmin &&
                            <SalesItemFlagSelector
                                selectedFlagValue={ salesItemForm.selectedNeedsAttentionCategory }
                                onFlagChange={ this.onSalesItemFlagChange }
                            />
                        }
                        { !this.userIsAdmin && salesItemForm.selectedNeedsAttentionCategory !== null &&
                            <SalesItemFlagAlert
                                selectedFlagValue={ salesItemForm.selectedNeedsAttentionCategory }
                                onMarkAsResolvedClick={ this.onMarkFlagAsResolvedClick }
                            />
                        }
                        <div className="main-container-column">
                            <InformationFromPOS
                                salesItemFormInfo={ salesItemForm }
                                onOmitClick={ canOmit ? this.onOmitItemClick : null }
                            />
                            <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                                <Flex direction="row" className="mapped-status-header flex-py-1 flex-px-4">
                                    <span className={ `bevicon ${ mappingIconClassName }` }/>
                                    <span className="mapped-status-text">{ ` ${ mappingStatusText } ` }</span>
                                </Flex>
                            </MediaQuery>
                            <IngredientInfo
                                salesItemForm={ salesItemForm }
                                salesItemFormData={ salesItemFormData }
                                onIngredientInfoFormFieldChange={ this.onIngredientInfoFormFieldChange }
                                onProductRowFormFieldChange={ this.onProductRowFormFieldChange }
                                onProductRowFormFieldBlur={ this.onProductRowFormFieldBlur }
                                onSalesItemRowFormFieldChange={ this.onSalesItemRowFormFieldChange }
                                onSalesItemRowFormFieldBlur={ this.onSalesItemRowFormFieldBlur }
                                onSalesInformationFormFieldBlur={ this.onSalesInformationFormFieldBlur }
                                onSalesInformationFormFieldChange={ this.onSalesInformationFormFieldChange }
                                onAddIngredient={ this.onAddIngredient }
                                handleOnRemoveIngredientClick={ this.onRemoveIngredientClick }
                                productsById={ salesItemFormData.productsById }
                                salesItemsById={ salesItemFormData.salesItemsById }
                                setSearchTerm={ this.setIngredientSearchBarTerm }
                                setHighlightedIngredientId={ this.setHighlightedIngredientId }
                                ingredientSearchBarDropdownIsShown={ isShownByComponentName.ingredientSearchBarDropdown }
                                setIngredientDropdownIsShown={ this.setIngredientDropdownIsShown }
                                slimProductCreateIsShown={ isShownByComponentName.searchBarSlimCreate }
                                createNewSalesItemFieldsAreShown={ isShownByComponentName.createNewSalesItemFields }
                                setSlimCreateIsShown={ this.setSearchBarSlimCreateIsShown }
                                setCreateNewSalesItemFieldsAreShown={ this.setCreateNewSalesItemFieldsAreShown }
                                onSelectIngredient={ this.onSelectIngredient }
                                popoverIngredientIdIsShown={ this.setIngredientPopoverIsShownForId }
                                onProductInfoFullDetailsClick={ this.onProductInfoFullDetailsClick }
                                popoverIngredientIds={ popoverIngredientIdIsShown }
                                productCostsByProductId={ salesItemFormData.productCostsByProductId }
                                onSlimCreate={ this.onSlimCreateNewProductIngredient }
                                salesItemCostOnForm={ salesItemForm.salesInformationForm.totalCost.value }
                                showOrHideSubRecipeInfoFields={ this.toggleSubRecipeInfoIsShown }
                                subRecipeInfoFieldsAreShown={ isShownByComponentName.subRecipeInfo }
                                showIngredientPriceInDropdown={ !isCustomerSuccessTool }
                                showProTipForEmptyIngredients={ true }
                                isItemMapping={ true }
                            />
                        </div>
                        <div className="main-container-column secondary-information">
                            <div>
                                <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                                    { salesItemPaginationContainer }
                                </MediaQuery>
                                { mappedStatus !== MappedStatus.UNMAPPED && // only show when you have 1+ ingredient
                                    <MapViewSalesInformation
                                        onSalesInformationFormFieldChange={ this.onSalesInformationFormFieldChange }
                                        onSalesInformationFormFieldBlur={ this.onSalesInformationFormFieldBlur }
                                        validationInputDataByFieldName={ salesItemForm.salesInformationForm }
                                        retailerTaxPercentage={ salesItemFormData.retailerTaxPercentage }
                                        salesPriceAndTaxHintShown={ isShownByComponentName.salesPriceAndTaxHintPopover }
                                        handleSetSalesPriceAndTaxHintIsShown={ this.setSalesPriceAndTaxHintPopoverIsShown }
                                        miscellaneousCostInputIsShown={ isShownByComponentName.miscellaneousCost }
                                        handleSetMiscellaneousCostInputShown={ this.toggleMiscellaneousCostIsShown }
                                    />
                                }
                            </div>
                        </div>
                        <MediaQuery maxWidth={ MAX_MOBILE_WIDTH }>
                            { salesItemPaginationContainer }
                        </MediaQuery>
                    </div>
                }
                { /* Modals */ }
                { isShownByComponentName.unsavedChangesModal &&
                    <UnsavedChangesDialog
                        saveDisabled={ false }
                        onCancelClick={ this.closeUnsavedChangesDialog }
                        onNavigateWithoutSavingClick={ this.onCloseModalWithoutSaving }
                        onSaveAndContinueClick={ this.onSaveAndContinueFromUnsavedChangesDialog }
                    />
                }
                { isShownByComponentName.applyChangesModal && salesItemFormData !== null && (salesItemId instanceof SalesItemId) &&
                    <ApplyChangesDialog
                        salesItemName={ salesItemForm.salesItemInfoForm.salesItemName.value }
                        salesItemId={ salesItemId }
                        isInsideItemLevelReport={ (contextItemLevelReportId !== null) }
                        salesItemsById={ salesItemFormData.salesItemsById }
                        onSaveClick={ this.onApplySaveChanges }
                        onCancelClick={ this.closeApplyChangesDialog }
                        onSelectSaveOption={ this.onSelectSaveOption }
                        selectedSaveOption={ selectedSaveOption }
                        doNotAskMeAgainIsChecked={ isShownByComponentName.doNotAskForSaveOptionsAgain }
                        onSetDoNotAskMeAgain={ this.handleSetDoNotAskForSaveOptionsAgain }
                    />
                }
                { isShownByComponentName.omitItemConfirmationDialog &&
                    <Dialog
                        className="omit-item-confirmation"
                        buttons={ [
                            {
                                onClick: this.closeOmitItemDialog,
                                classes: 'flat',
                                isDisabled: false,
                                isLoading: false,
                                children: 'Cancel',
                            },
                            {
                                onClick: this.onConfirmOmitItem,
                                classes: 'flat danger',
                                isDisabled: false,
                                isLoading: false,
                                children: 'Omit',
                            },
                        ] }
                        headerText={ <span>Omit Item</span> }
                    >
                        <div className="dialog-body">
                            { `Are you sure you want to omit POS ID: ${ salesItemForm.salesItemInfoForm.posId.value } (${ salesItemForm.salesItemInfoForm.salesItemName.value })? This item will not be pulled in to any future reports.` }
                        </div>
                    </Dialog>
                }
                <div className="on-save-snack-bar">
                    <SnackBar
                        isShown={ isShownByComponentName.onSaveSnackBar }
                        message="Save Successful"
                        callToActionLabel={ null }
                        handleCloseButton={ this.handleSnackBarClose }
                    />
                </div>
            </div>
        );
    }

    private readonly handleSnackBarClose = () => {
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('onSaveSnackBar', false));
    }

    private readonly onSaveClick = () => {
        const {
            createOrEditSalesItemState,
            dispatch,
            onSave,
            contextItemLevelReportId,
            onClose
        } = this.props;

        if (createOrEditSalesItemState.salesItemId === null) { // if create mode, just create, no need for a dialog
            this.props.dispatch(CreateOrEditSalesItemActions.onSave(SalesItemSaveOption.ALL_REPORTS, contextItemLevelReportId, this.posInformationByPosItemId))
            .then((oldSalesIdToNewSalesIdMap) => {
                if (oldSalesIdToNewSalesIdMap !== null) {
                    onSave(oldSalesIdToNewSalesIdMap);
                    onClose();
                    dispatch(CreateOrEditSalesItemActions.resetSalesItemForm());
                }
            });
        } else {
            // if save/update mode, open the apply changes modal
            dispatch(CreateOrEditSalesItemActions.setIdToGoToAfterSave(null));
            this.handleOpenApplyChangesDialog();
        }
    }

    private readonly onSaveAndContinueFromUnsavedChangesDialog = () => {
        this.onSaveClick();
        this.closeUnsavedChangesDialog();
    }

    // private readonly onSalesItemFormFieldChange = (fieldName : SalesItemFormFieldName, value : string) => {
    //     this.props.dispatch(CreateOrEditSalesItemActions.onSalesItemFormFieldChange(fieldName, value));
    // }

    private readonly onIngredientInfoFormFieldChange = (fieldName : IngredientFormFieldName, value : string) => {
        this.props.dispatch(CreateOrEditSalesItemActions.onIngredientInfoFormFieldChange(fieldName, value));
    }

    private readonly onProductRowFormFieldChange = (productId : ProductId, fieldName : ProductIngredientRowFormFieldName, value : string) => {
        this.props.dispatch(CreateOrEditSalesItemActions.onProductRowFormFieldChange(productId, fieldName, value));
    }

    private readonly onProductRowFormFieldBlur = (ingredientId : ProductId, fieldName : ProductIngredientRowFormFieldName, value : string) => {
        this.props.dispatch(CreateOrEditSalesItemActions.onIngredientRowFormFieldBlur(ingredientId, fieldName, value));
    }

    private readonly onSalesItemRowFormFieldChange = (salesItemId : SalesItemId, fieldName : SalesItemIngredientRowFormFieldName, value : string) => {
        this.props.dispatch(CreateOrEditSalesItemActions.onSalesItemRowFormFieldChange(salesItemId, fieldName, value));
    }

    private readonly onSalesItemRowFormFieldBlur = (ingredientId : SalesItemId, fieldName : SalesItemIngredientRowFormFieldName, value : string) => {
        this.props.dispatch(CreateOrEditSalesItemActions.onIngredientRowFormFieldBlur(ingredientId, fieldName, value));
    }

    private readonly onAddIngredient = () => {
        const {
            dispatch,
            createOrEditSalesItemState
        } = this.props;

        let slimCreatePromiseIfApplicable : Promise<SalesItemId | null | void>;
        if (createOrEditSalesItemState.isShownByComponentName.createNewSalesItemFields) {
            slimCreatePromiseIfApplicable = dispatch(CreateOrEditSalesItemActions.onSlimCreateNewSalesItemIngredient());
        } else {
            slimCreatePromiseIfApplicable = Promise.resolve();
        }
        const currentNumberOfIngredients = createOrEditSalesItemState.salesItemForm.orderedIngredientItems.length;

        slimCreatePromiseIfApplicable
        .then((response) => {
            if (response !== null) { // null means validation failed.
                dispatch(CreateOrEditSalesItemActions.onAddIngredient());
                this.setIngredientSearchBarTerm(null);
                if (currentNumberOfIngredients === 0) {
                    this.totalUnmappedCount -= 1; // adding 1 = one less unmapped
                }
            }
        });
    }

    private readonly toggleSubRecipeInfoIsShown = () => {
        const toggle = !this.props.createOrEditSalesItemState.isShownByComponentName.subRecipeInfo;
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('subRecipeInfo', toggle));
    }

    private readonly onRemoveIngredientClick = (ingredientId : ProductId | SalesItemId) => {
        const currentNumberOfIngredients = this.props.createOrEditSalesItemState.salesItemForm.orderedIngredientItems.length;
        if (ingredientId instanceof ProductId) {
            this.props.dispatch(CreateOrEditSalesItemActions.setProductIngredientItemsRow(ingredientId, null));
        } else {
            this.props.dispatch(CreateOrEditSalesItemActions.setSalesItemIngredientItemsRow(ingredientId, null));
        }
        this.props.dispatch(CreateOrEditSalesItemActions.onGetAndUpdateTotalCost());
        if (currentNumberOfIngredients === 1) {
            this.totalUnmappedCount += 1; // removing 1 = one more unmapped
        }
    }

    private readonly setSalesPriceAndTaxHintPopoverIsShown = (isShown : boolean) => {
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('salesPriceAndTaxHintPopover', isShown));
    }

    private readonly toggleMiscellaneousCostIsShown = () => {
        const toggle = !this.props.createOrEditSalesItemState.isShownByComponentName.miscellaneousCost;
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('miscellaneousCost', toggle));
    }

    private readonly onSalesInformationFormFieldChange = (fieldName : SalesInformationFormFieldName, value : string) => {
        this.props.dispatch(CreateOrEditSalesItemActions.onSalesInformationFormFieldChange(fieldName, value));
    }

    private readonly onSalesInformationFormFieldBlur = (fieldName : SalesInformationFormFieldName, value : string) => {
        this.props.dispatch(CreateOrEditSalesItemActions.onSalesInformationFormFieldBlur(fieldName, value));
    }

    private readonly handleOnShowConfirmExitDialog = () => {
        const {
            dispatch,
            createOrEditSalesItemState
        } = this.props;

        const formHasChanged = CreateOrEditSalesItemFormUtils.checkFormHasChangedFromInitial(createOrEditSalesItemState);
        if (formHasChanged) {
            dispatch(CreateOrEditSalesItemActions.setComponentIsShown('unsavedChangesModal', true));
        } else {
            this.onCloseModalWithoutSaving();
        }
    }

    private readonly closeUnsavedChangesDialog = () => {
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('unsavedChangesModal', false));
    }

    private readonly onSelectSaveOption = (selectedSaveOption : SalesItemSaveOption) => {
        this.props.dispatch(CreateOrEditSalesItemActions.setSelectedSaveOption(selectedSaveOption));
    }

    private readonly onApplySaveChanges = () => {
        const {
            dispatch,
            onSave,
            contextItemLevelReportId,
            isCustomerSuccessTool
        } = this.props;

        return dispatch(CreateOrEditSalesItemActions.onApplySaveChanges(contextItemLevelReportId, this.contextOrderedIds, !!isCustomerSuccessTool, this.posInformationByPosItemId, onSave))
        .then((newContextOrderedIds) => {
            this.contextOrderedIds = newContextOrderedIds;
            return Promise.resolve();
        });
    }

    private readonly closeApplyChangesDialog = () => {
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('applyChangesModal', false));
        this.props.dispatch(CreateOrEditSalesItemActions.setIdToGoToAfterSave(null));
    }

    private readonly onCloseModalWithoutSaving = () => {
        this.props.onClose();
        this.closeUnsavedChangesDialog();
        this.props.dispatch(CreateOrEditSalesItemActions.resetSalesItemForm());
    }

    private readonly setIngredientSearchBarTerm = (searchTerm : string | null) => {
        this.props.dispatch(CreateOrEditSalesItemActions.onSetIngredientSearchBarTerm(searchTerm));
    }

    private readonly setHighlightedIngredientId = (ingredientId : ProductId | SalesItemId | ProductQuickAdd | null) => {
        this.props.dispatch(CreateOrEditSalesItemActions.setIngredientSearchBarHighlightedIngredientId(ingredientId));
    }

    private readonly setIngredientDropdownIsShown = (isShown : boolean) => {
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('ingredientSearchBarDropdown', isShown));
    }

    private readonly setSearchBarSlimCreateIsShown = (isShown : boolean) => {
        const {
            dispatch,
            createOrEditSalesItemState,
        } = this.props;

        const searchTerm = createOrEditSalesItemState.salesItemForm.ingredientSearchBar.searchBar.searchTerm;

        if (isShown) {
            dispatch(ProductSlimCreateFormActions.onFormFieldChange('name', searchTerm || ''));
        }

        dispatch(CreateOrEditSalesItemActions.setComponentIsShown('searchBarSlimCreate', isShown));
        dispatch(CreateOrEditSalesItemActions.resetAddNewIngredientFormSection());
    }

    private readonly setCreateNewSalesItemFieldsAreShown = (isShown : boolean) => {
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('createNewSalesItemFields', isShown));
    }

    private readonly onSelectIngredient = (ingredientId : ProductId | SalesItemId | ProductQuickAdd | null) => {
        this.props.dispatch(CreateOrEditSalesItemActions.onSelectIngredient(ingredientId));
    }

    private readonly onSlimCreateNewProductIngredient = (productId : ProductId) => {
        const {
            dispatch,
        } = this.props;

        dispatch(CreateOrEditSalesItemActions.onProductCreatedOrEdited(productId, true))
        .then(() => {
            this.onSelectIngredient(productId);
        });
        dispatch(CreateOrEditSalesItemActions.setComponentIsShown('searchBarSlimCreate', false));
        dispatch(ProductSlimCreateFormActions.resetForm());
    }

    private readonly onSalesItemFlagChange = (flag : string | null) => {
        this.props.dispatch(CreateOrEditSalesItemActions.setNeedsAttentionCategory(flag));
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('saveIsDisabled', false));
    }

    private readonly onMarkFlagAsResolvedClick = () => {
        this.props.dispatch(CreateOrEditSalesItemActions.setNeedsAttentionCategory(null));
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('saveIsDisabled', false));
    }

    private readonly setIngredientPopoverIsShownForId = (ingredientId : ProductId | SalesItemId, isShown : boolean) => {
        this.props.dispatch(CreateOrEditSalesItemActions.setPopoverIngredientIdIsShown(new StringValueSet([ingredientId]), isShown));
    }

    private readonly onProductInfoFullDetailsClick = (ingredientId : ProductId) => {
        this.props.dispatch(CreateOrEditSalesItemActions.onEditItemClick(ingredientId));
        this.props.dispatch(CreateOrEditSalesItemActions.setPopoverIngredientIdIsShown(new StringValueSet([ingredientId]), false));
    }

    private readonly handleSetDoNotAskForSaveOptionsAgain = (isChecked : boolean) => {
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('doNotAskForSaveOptionsAgain', isChecked));
    }

    private readonly handleOpenApplyChangesDialog = () : Promise<null | void> => { // return null = no save. void = save
        const {
            dispatch,
            createOrEditSalesItemState
        } = this.props;

        if (!this.onlyShowAllTimeSaveOption && createOrEditSalesItemState.salesItemId instanceof SalesItemId) {
            if (createOrEditSalesItemState.isShownByComponentName.doNotAskForSaveOptionsAgain) {
                return this.onApplySaveChanges(); // use the previously selected save option
            } else {
                dispatch(CreateOrEditSalesItemActions.setComponentIsShown('applyChangesModal', true));
                return Promise.resolve(null);
            }
        } else {
            // on mapping tool/if no POS reporting, don't ask for option - just save for all time.
            this.onSelectSaveOption(SalesItemSaveOption.ALL_REPORTS); // make sure this is chosen (should already be, but just to be safe)
            return this.onApplySaveChanges();
        }

    }

    private readonly onPreviousButtonClick = () => {
        const {
            createOrEditSalesItemState,
            dispatch,
            isCustomerSuccessTool
        } = this.props;

        if (createOrEditSalesItemState.previousSalesItemId === null) {
            throw new RuntimeException('previousSalesItemId unexpectedly null');
        }

        const formHasChanged = CreateOrEditSalesItemFormUtils.checkFormHasChangedFromInitial(createOrEditSalesItemState);
        if (formHasChanged) {
            dispatch(CreateOrEditSalesItemActions.setIdToGoToAfterSave(createOrEditSalesItemState.previousSalesItemId));
            this.handleOpenApplyChangesDialog();
        } else {
            dispatch(CreateOrEditSalesItemActions.initializeFormFromSalesItem(createOrEditSalesItemState.previousSalesItemId, this.contextOrderedIds, !!isCustomerSuccessTool, this.posInformationByPosItemId));
        }
    }

    private readonly onNextButtonClick = () => {
        const {
            createOrEditSalesItemState,
            dispatch,
            isCustomerSuccessTool,
        } = this.props;

        if (createOrEditSalesItemState.nextSalesItemId === null) {
            throw new RuntimeException('nextSalesItemId unexpectedly null');
        }

        const formHasChanged = CreateOrEditSalesItemFormUtils.checkFormHasChangedFromInitial(createOrEditSalesItemState);
        if (formHasChanged) {
            dispatch(CreateOrEditSalesItemActions.setIdToGoToAfterSave(createOrEditSalesItemState.nextSalesItemId));
            this.handleOpenApplyChangesDialog();
        } else {
            dispatch(CreateOrEditSalesItemActions.initializeFormFromSalesItem(createOrEditSalesItemState.nextSalesItemId, this.contextOrderedIds, !!isCustomerSuccessTool, this.posInformationByPosItemId));
        }
    }

    private readonly handleActionButtonClick = () => {
        const {
            createOrEditSalesItemState,
            dispatch,
            onActionButtonClick
         } = this.props;

        // save, then action button callback
        const formHasChanged = CreateOrEditSalesItemFormUtils.checkFormHasChangedFromInitial(createOrEditSalesItemState);
        if (formHasChanged) {
            this.handleOpenApplyChangesDialog()
            .then((result : null | void) => {
                if (result !== null) {
                    onActionButtonClick();
                    dispatch(CreateOrEditSalesItemActions.resetSalesItemForm());
                }
            });
        } else {
            onActionButtonClick();
            dispatch(CreateOrEditSalesItemActions.resetSalesItemForm());
        }
    }

    private readonly setShortcutPopoverIsShown = (isShown : boolean) => {
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('shortcutPopover', isShown));
    }

    private readonly onDocumentKeyUp = (event : KeyboardEvent) => {
        const {
            createOrEditSalesItemState,
        } = this.props;

        const target = event.target as HTMLElement;
        const closestInput = target.closest('input');
        const closestTextArea = target.closest('textarea');
        if ((closestInput && closestInput.value.length !== 0) ||
            (closestTextArea && closestTextArea.value.length !== 0)) {
            return;
        }

        const keyCode = event.which;
        if (actionKeyCodeSet.has(keyCode)) {
            event.preventDefault();
            event.stopPropagation();

            if ((keyCode === ActionKeyCodes.n || keyCode === ActionKeyCodes.RIGHT_ARROW) && createOrEditSalesItemState.nextSalesItemId !== null) {
                this.onNextButtonClick();
            } else if ((keyCode === ActionKeyCodes.p || keyCode === ActionKeyCodes.LEFT_ARROW) && createOrEditSalesItemState.previousSalesItemId !== null) {
                this.onPreviousButtonClick();
            } else if (window.GLOBAL_USER_IS_ADMIN && keyCode === ActionKeyCodes.f) { // flag shortcut only for BevSpot admins
                if (createOrEditSalesItemState.salesItemForm.selectedNeedsAttentionCategory === null) {
                    this.onSalesItemFlagChange(availableSalesItemFlags[0]);
                } else {
                    this.onSalesItemFlagChange(null);
                }
            }
        }
    }

    private readonly onCustomItemSuccessfullyEdited = (event : Event, data : any) : void => {
        const {
            dispatch,
            createOrEditSalesItemState
        } = this.props;

        if (createOrEditSalesItemState.salesItemFormData === null) {
            throw new Error('createOrEditSalesItemState.salesItemFormData unexpectedly null');
        }

        const productId = new ProductId(data.productId);
        const isStatusActive = createOrEditSalesItemState.salesItemFormData.activeProductIds.has(productId);
        dispatch(CreateOrEditSalesItemActions.onProductCreatedOrEdited(productId, isStatusActive))
        .then(() => {
            dispatch(CreateOrEditSalesItemActions.onGetAndUpdateTotalCost());
        });
    }

    private readonly onCustomItemActiveStateChanged = (event : Event, data : any) : void => {
        const {
            dispatch,
        } = this.props;

        const productId = new ProductId(data.productIds[0]);
        dispatch(CreateOrEditSalesItemActions.onProductCreatedOrEdited(productId, false));
    }

    private readonly onOmitItemClick = () => {
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('omitItemConfirmationDialog', true));
    }

    private readonly closeOmitItemDialog = () => {
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('omitItemConfirmationDialog', false));
    }

    private readonly onConfirmOmitItem = () => {
        const {
            dispatch,
            createOrEditSalesItemState,
            onOmitItem,
            isCustomerSuccessTool,
            onClose
        } = this.props;

        if (onOmitItem !== null && createOrEditSalesItemState.salesItemId !== null) {
            onOmitItem(createOrEditSalesItemState.salesItemId);

            const idToGoToNext : SalesInputRowId | null = createOrEditSalesItemState.nextSalesItemId === null ? createOrEditSalesItemState.previousSalesItemId : createOrEditSalesItemState.nextSalesItemId;
            if (idToGoToNext !== null) {
                const oldIndex = this.contextOrderedIds.findIndex((value) => value.equals(createOrEditSalesItemState.salesItemId));
                const newContextOrderedIds = [...this.contextOrderedIds];
                newContextOrderedIds.splice(oldIndex, 1); // remove omitted from the loop

                dispatch(CreateOrEditSalesItemActions.initializeFormFromSalesItem(idToGoToNext, newContextOrderedIds, !!isCustomerSuccessTool, this.posInformationByPosItemId))
                .then(() => this.contextOrderedIds = newContextOrderedIds);
            } else { // if no other items and we just omitted, close
                onClose();
                dispatch(CreateOrEditSalesItemActions.resetSalesItemForm());
            }
            this.closeOmitItemDialog();
        }
    }
}

export interface IStateProps {
    createOrEditSalesItemState : ICreateOrEditSalesItemState;
    productSlimCreateFormState : IProductSlimCreateFormState;
}

const mapStateToProps = (state : IStateProps) : IStateProps => {
    return {
        createOrEditSalesItemState: state.createOrEditSalesItemState,
        productSlimCreateFormState: state.productSlimCreateFormState,
    };
};

// To use this connected component, you MUST do the following (though ofc if you understand why they are needed feel free to improve):
// - In your top-level app combineReducer (usually the index.tsx file), make sure all members of IStateProps exist
// - Add sure all services corresponding to IStateProps are added
// - *MAJOR CATCH* Add ItemCard JS bundle and mount point to the django template
export const ConnectedMapSalesItemView = connect<IStateProps, IMapSalesItemViewProps, object, IStateProps>(mapStateToProps)(MapSalesItemView);
