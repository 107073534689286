import React from 'react';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { StorageArea } from 'api/InventoryCount/model/StorageArea';
import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';
import { CheckBoxTriState } from 'shared/models/CheckBoxTriState';
import { IModalButton } from 'shared/models/Modal';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import { GenericSelect2DropdownMenu } from 'shared/components/Select2Dropdown/GenericSelect2DropdownMenu';
import { OptionsAndLabelNameTuples } from 'shared/components/Select2Dropdown/Select2DropdownMenu';

import { IOption } from 'shared/components/Dropdown/DropdownMenu';

import { DropdownModalOption } from 'shared/components/Dropdown/DropdownModalOption';

import { Button } from 'shared/components/Button';

import 'apps/InventoryCount/css/AddToStorageAreasDropdown.scss';

export interface IAddToStorageAreasDropdownProps {
    readonly sortedStorageAreaIds : ReadonlyArray<StorageAreaId>;
    readonly storageAreasById : StringValueMap<StorageAreaId, StorageArea>;
    readonly savedCheckBoxStatesByStorageAreaId : StringValueMap<StorageAreaId, CheckBoxTriState>;
    readonly toggleDropdownButtonText : string;
    readonly showDropdown : boolean;
    readonly isOffline : boolean;
    readonly onCreateNewStorageArea : (storageAreaName : string) => Promise<StorageAreaId | void>; // void means invalid
    readonly setShowDropdown : (showDropdown : boolean) => void;
    readonly onDropdownCancelClick : () => void;
    readonly onDropdownSaveClick : (addStorageAreaIds : StringValueSet<StorageAreaId>, removeStorageAreaIds : StringValueSet<StorageAreaId>) => void;
    readonly onDropdownBlur : ((addStorageAreaIds : StringValueSet<StorageAreaId>, removeStorageAreaIds : StringValueSet<StorageAreaId>) => void) | null;
}

interface IAddToStorageAreasDropdownState {
    readonly checkBoxStatesByStorageAreaId : StringValueMap<StorageAreaId, CheckBoxTriState>;
    readonly createCustomOptionIsLoading : boolean;
}

export class AddToStorageAreasDropdown extends React.Component<IAddToStorageAreasDropdownProps, IAddToStorageAreasDropdownState> {

    public constructor(props : IAddToStorageAreasDropdownProps) {
        super(props);

        this.state = {
            checkBoxStatesByStorageAreaId: new StringValueMap(props.savedCheckBoxStatesByStorageAreaId),
            createCustomOptionIsLoading: false,
        };
    }

    public componentWillReceiveProps(nextProps : IAddToStorageAreasDropdownProps) {
        this.validateProps(nextProps);

        if (nextProps.savedCheckBoxStatesByStorageAreaId !== this.props.savedCheckBoxStatesByStorageAreaId) {
            if (!this.props.showDropdown) {
                this.setState({
                    checkBoxStatesByStorageAreaId: new StringValueMap(nextProps.savedCheckBoxStatesByStorageAreaId),
                });
            }
        }
    }

    public render() {
        const {
            sortedStorageAreaIds,
            storageAreasById,
            toggleDropdownButtonText,
            showDropdown,
            setShowDropdown,
            isOffline,
        } = this.props;

        const sortedOptionsAndLabelName : OptionsAndLabelNameTuples = [];
        const sortedOptionIds : Array<IOption> = sortedStorageAreaIds.map((storageAreaId : StorageAreaId) => {
            const storageArea = storageAreasById.get(storageAreaId);

            if (typeof storageArea === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            return {
                value: storageAreaId.getValue(),
                label: storageArea.getName(),
                icon: null,
            };
        });
        const groupLabel = null;
        sortedOptionsAndLabelName[0] = [groupLabel, sortedOptionIds];

        const optionValuesById : {[id : string] : string} = {};
        storageAreasById.forEach((storageArea : StorageArea, storageAreaId : StorageAreaId) => {
            optionValuesById[storageAreaId.getValue()] = storageArea.getName();
        });

        return (
            <div
                className="add-to-storage-areas-dropdown"
            >
                <GenericSelect2DropdownMenu
                    isSearchable={ true }
                    selectedOptionHeaderShown={ false }
                    selectedOption={ null } // selected always null bc this is multi-select
                    emptyState={ null }
                    placeholderText={ toggleDropdownButtonText }
                    hasCreateCustomOption={ !isOffline } // no creating storage areas offline
                    buttonShouldDisplaySelectedLabel={ false }
                    sortedOptionsAndLabelName={ sortedOptionsAndLabelName }
                    getOptionElementsForGroup={ this.createOptions }
                    createCustomOption={ this.onCreateNewStorageAreaClick }
                    getSelectedOptionElementForHeader={ this.doNothing } // N/A
                    footerElement={ this.createFooter() }
                    onDropdownBlur={ this.onDropdownBlur }
                    isOpen={ showDropdown }
                    handleDropdownOpenClose={ setShowDropdown }
                    createCustomOptionIsLoading={ this.state.createCustomOptionIsLoading }
                    createCustomOptionButtonLabel={ null }
                    customOptionGroupLabel={ groupLabel }
                />
            </div>
        );
    }

    private readonly doNothing = () => undefined;

    private readonly createOptions = (filteredOptions : Array<IOption>, selectedOption : IOption | null) : Array<JSX.Element> => {
        const checkBoxStatesByOptionId : {[id : string] : CheckBoxTriState} = {};
        this.state.checkBoxStatesByStorageAreaId.forEach((checkBoxState : CheckBoxTriState, storageAreaId : StorageAreaId) => {
            checkBoxStatesByOptionId[storageAreaId.getValue()] = checkBoxState;
        });

        const options : Array<JSX.Element> = filteredOptions.map(
            (option : IOption, index : number) => {
                const checkBoxState : CheckBoxTriState = checkBoxStatesByOptionId[option.value];
                const isDisabled : boolean = false; // disabledOptionIds.has(option.value);

                const onClick = () => this.onStorageAreaOptionClick(option.value);

                return (
                    <DropdownModalOption
                        key={ index }
                        value={ option.label }
                        checkBoxState={ checkBoxState }
                        isDisabled={ isDisabled }
                        onOptionClick={ onClick }
                    />
                );
            }
        );

        return options;
    }

    private readonly createFooter = () : JSX.Element => {
        const footerButtons : ReadonlyArray<IModalButton> = [
            {
                classes: 'flat secondary',
                children: 'Cancel',
                isDisabled: false,
                isLoading: false,
                onClick: this.props.onDropdownCancelClick,
            },
            {
                classes: 'flat primary',
                children: 'Save',
                isDisabled: false,
                isLoading: false,
                onClick: this.onDropdownSaveClick,
            },
        ];

        const className = `dropdown-modal-footer`;

        const buttons : ReadonlyArray<JSX.Element> = footerButtons.map(
            (modalButton : IModalButton, index : number) => {
                return (
                    <Button
                        key={ index }

                        buttonClassName={ modalButton.classes }
                        isDisabled={ modalButton.isDisabled }
                        isLoading={ modalButton.isLoading }
                        onClick={ modalButton.onClick }
                    >
                        { modalButton.children }
                    </Button>
                );
            }
        );

        return (
            <div
                className={ className }
            >
                { buttons }
            </div>
        );
    }

    private readonly onCreateNewStorageAreaClick = (newName : string) : void => {
        // Need to 1: create storage area -- this is permanent.
        // 2: assign product to storage area (but not save)

        let newStorageAreaId : StorageAreaId | null = null;
        this.props.storageAreasById.forEach((storageArea, storageAreaId) => {
            if (storageArea.getName() === newName) {
                newStorageAreaId = storageAreaId;
            }
        });

        if (newStorageAreaId === null) {
            this.setState({
                createCustomOptionIsLoading: true, // for slow internet
            });

            // need to create new
            this.props.onCreateNewStorageArea(newName)
            .then((storageAreaId) => {

                // we should do some thinking around what an invalid state means on a select2 dropdown, this is not a long-term solution
                if (!storageAreaId) {
                    throw new RuntimeException('unexpected invalid storage area name');
                }

                this.setState((state : IAddToStorageAreasDropdownState) => {
                    const newCheckBoxStatesByStorageAreaId = new StringValueMap(state.checkBoxStatesByStorageAreaId);
                    newCheckBoxStatesByStorageAreaId.set(storageAreaId, CheckBoxTriState.Checked);

                    return {
                        checkBoxStatesByStorageAreaId: newCheckBoxStatesByStorageAreaId,
                        createCustomOptionIsLoading: false,
                    };
                });
            });

        } else {
            const storageAreaId : StorageAreaId = newStorageAreaId; // types are stupid

            this.setState((state : IAddToStorageAreasDropdownState) => {
                const newCheckBoxStatesByStorageAreaId = new StringValueMap(state.checkBoxStatesByStorageAreaId);
                newCheckBoxStatesByStorageAreaId.set(storageAreaId, CheckBoxTriState.Checked);

                return {
                    checkBoxStatesByStorageAreaId: newCheckBoxStatesByStorageAreaId,
                };
            });
        }
    }

    private readonly onStorageAreaOptionClick = (optionId : string) : void => {
        const {
            savedCheckBoxStatesByStorageAreaId,
        } = this.props;

        const storageAreaId = new StorageAreaId(optionId);

        const checkBoxState = this.state.checkBoxStatesByStorageAreaId.get(storageAreaId);
        const savedCheckBoxState = savedCheckBoxStatesByStorageAreaId.get(storageAreaId);
        if ((typeof checkBoxState === 'undefined') || (typeof savedCheckBoxState === 'undefined')) {
            throw new RuntimeException('unexpected');
        }

        const checkBoxStateCanBeIndeterminate : boolean = (savedCheckBoxState === CheckBoxTriState.Indeterminate);

        if (typeof checkBoxStateCanBeIndeterminate === 'undefined') {
            throw new RuntimeException('unexpected');
        }

        let newCheckBoxState : CheckBoxTriState;
        switch (checkBoxState) {
            case CheckBoxTriState.Checked:
                newCheckBoxState = CheckBoxTriState.Unchecked;
                break;
            case CheckBoxTriState.Unchecked:
                if (checkBoxStateCanBeIndeterminate) {
                    newCheckBoxState = CheckBoxTriState.Indeterminate;
                } else {
                    newCheckBoxState = CheckBoxTriState.Checked;
                }

                break;
            case CheckBoxTriState.Indeterminate:
                newCheckBoxState = CheckBoxTriState.Checked;
                break;
            default:
                throw new RuntimeException('unexpected value for checkBoxState');
        }

        this.setState((state : IAddToStorageAreasDropdownState) => {
            const newCheckBoxStatesByStorageAreaId = new StringValueMap(state.checkBoxStatesByStorageAreaId);
            newCheckBoxStatesByStorageAreaId.set(storageAreaId, newCheckBoxState);

            return {
                checkBoxStatesByStorageAreaId: newCheckBoxStatesByStorageAreaId,
            };
        });
    }

    private readonly getDropdownStateDiff = () => {
        const {
            sortedStorageAreaIds,
            savedCheckBoxStatesByStorageAreaId,
        } = this.props;

        const checkBoxStatesByStorageAreaId = this.state.checkBoxStatesByStorageAreaId;

        const addStorageAreaIds = new StringValueSet<StorageAreaId>();
        const removeStorageAreaIds = new StringValueSet<StorageAreaId>();

        sortedStorageAreaIds.forEach((storageAreaId : StorageAreaId) => {
            const checkBoxState = checkBoxStatesByStorageAreaId.get(storageAreaId);
            const savedCheckBoxState = savedCheckBoxStatesByStorageAreaId.get(storageAreaId);

            if ((typeof checkBoxState === 'undefined') || (typeof savedCheckBoxState === 'undefined')) {
                throw new RuntimeException('unexpected');
            }

            switch (checkBoxState) {
                case CheckBoxTriState.Checked:
                    if (savedCheckBoxState !== CheckBoxTriState.Checked) {
                        addStorageAreaIds.add(storageAreaId);
                    }
                    break;
                case CheckBoxTriState.Unchecked:
                    if (savedCheckBoxState !== CheckBoxTriState.Unchecked) {
                        removeStorageAreaIds.add(storageAreaId);
                    }
                    break;
                case CheckBoxTriState.Indeterminate:
                    break;
                default:
                    throw new RuntimeException('unexpected value for checkBoxState');
            }
        });

        return {
            addStorageAreaIds,
            removeStorageAreaIds
        };
    }

    private readonly onDropdownSaveClick = () => {
        const {
            addStorageAreaIds,
            removeStorageAreaIds
        } = this.getDropdownStateDiff();

        this.props.onDropdownSaveClick(addStorageAreaIds, removeStorageAreaIds);
    }

    private readonly onDropdownBlur = () => {
        if (this.props.onDropdownBlur) {
            const {
                addStorageAreaIds,
                removeStorageAreaIds
            } = this.getDropdownStateDiff();

            this.props.onDropdownBlur(addStorageAreaIds, removeStorageAreaIds);
        }
    }

    private readonly validateProps = (props : IAddToStorageAreasDropdownProps) : void => {
        const {
            sortedStorageAreaIds,
            storageAreasById,
            savedCheckBoxStatesByStorageAreaId,
        } = props;

        const storageAreaIdSet : StringValueSet<StorageAreaId> = new StringValueSet(sortedStorageAreaIds);

        if (storageAreaIdSet.size !== sortedStorageAreaIds.length) {
            throw new RuntimeException('elements of sortedStorageAreaIds must be unique');
        }

        {
            const storageAreaIds : ReadonlyArray<StorageAreaId> = Array.from(savedCheckBoxStatesByStorageAreaId.keys());

            if (storageAreaIds.length !== sortedStorageAreaIds.length) {
                throw new RuntimeException('must be a checkBoxState for each storageAreaId in sortedStorageAreaIds');
            }

            storageAreaIds.forEach((storageAreaId : StorageAreaId) => {
                if (!storageAreaIdSet.has(storageAreaId)) {
                    throw new RuntimeException('checkBoxStatesByStorageAreaId ids must be in sortedStorageAreaIds');
                }
            });
        }

        {
            const storageAreaIds : ReadonlyArray<StorageAreaId> = Array.from(storageAreasById.keys());

            if (storageAreaIds.length !== sortedStorageAreaIds.length) {
                throw new RuntimeException('storageAreasById must be same length as sortedStorageAreaIds');
            }

            storageAreaIds.forEach((storageAreaId : StorageAreaId) => {
                if (!storageAreaIdSet.has(storageAreaId)) {
                    throw new RuntimeException('storageAreasById must have ids in sortedStorageAreaIds');
                }
            });
        }
    }
}
