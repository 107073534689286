//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';



var PublicContentExceptions = {};
export default PublicContentExceptions;
PublicContentExceptions.InvalidArgumentsToCreateViewEventException = function(args) {
};
Thrift.inherits(PublicContentExceptions.InvalidArgumentsToCreateViewEventException, Thrift.TException);
PublicContentExceptions.InvalidArgumentsToCreateViewEventException.prototype.name = 'InvalidArgumentsToCreateViewEventException';
PublicContentExceptions.InvalidArgumentsToCreateViewEventException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PublicContentExceptions.InvalidArgumentsToCreateViewEventException.prototype.write = function(output) {
  output.writeStructBegin('InvalidArgumentsToCreateViewEventException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PublicContentExceptions.InvalidPublicContentTypeArgumentException = function(args) {
};
Thrift.inherits(PublicContentExceptions.InvalidPublicContentTypeArgumentException, Thrift.TException);
PublicContentExceptions.InvalidPublicContentTypeArgumentException.prototype.name = 'InvalidPublicContentTypeArgumentException';
PublicContentExceptions.InvalidPublicContentTypeArgumentException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PublicContentExceptions.InvalidPublicContentTypeArgumentException.prototype.write = function(output) {
  output.writeStructBegin('InvalidPublicContentTypeArgumentException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

