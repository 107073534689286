import React from 'react';

import './ProgressBar.scss';

import { IBoundingBox } from 'shared/models/Charts/IBoundingBox';
import { HexColor } from 'shared/models/HexColor';

import { utils } from 'shared/components/Charts/utils';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export interface IProgressBarProps {
    readonly barColor : HexColor;
    readonly backgroundColor : HexColor;
    readonly barPercent : number;
    readonly boundingBox : IBoundingBox;
}

export class ProgressBar extends React.Component<IProgressBarProps, object> {
    private readonly classNamePrefix = 'progress-bar';

    public render() {
        const {
            barPercent,
            boundingBox,
        } = this.props;

        utils.ensureBoundingBoxIsValid(boundingBox);

        if (barPercent < 0 || barPercent > 1) {
            throw new RuntimeException('barPercent must be a valid percent value');
        }

        const className : string = `${ this.classNamePrefix }-container`;

        return (
            <svg
                className={ className }
                height={ boundingBox.heightInPixels }
                width={ boundingBox.widthInPixels }
            >
                { this.createBackground() }
                { this.createBar() }
            </svg>
        );
    }

    private readonly createBackground = () : JSX.Element => {
        const {
            backgroundColor,
            boundingBox,
        } = this.props;

        const className : string = `${ this.classNamePrefix }-background`;

        return (
            <rect
                className={ className }
                height={ boundingBox.heightInPixels }
                width={ boundingBox.widthInPixels }
                fill={ backgroundColor.getValue() }
            />
        );
    }

    private createBar = () : JSX.Element => {
        const {
            barColor,
            barPercent,
            boundingBox,
        } = this.props;

        const className : string = `${ this.classNamePrefix }-bar`;
        const widthInPixels : number = boundingBox.widthInPixels * barPercent;

        return (
            <rect
                className={ className }
                height={ boundingBox.heightInPixels }
                width={ widthInPixels }
                fill={ barColor.getValue() }
            />
        );
    }
}
