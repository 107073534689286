//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';
import IntegrationModel from './integration_Model_types';



var IntegrationExceptions = {};
export default IntegrationExceptions;
IntegrationExceptions.LocationDoesNotHaveIntegrationException = function(args) {
};
Thrift.inherits(IntegrationExceptions.LocationDoesNotHaveIntegrationException, Thrift.TException);
IntegrationExceptions.LocationDoesNotHaveIntegrationException.prototype.name = 'LocationDoesNotHaveIntegrationException';
IntegrationExceptions.LocationDoesNotHaveIntegrationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationExceptions.LocationDoesNotHaveIntegrationException.prototype.write = function(output) {
  output.writeStructBegin('LocationDoesNotHaveIntegrationException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationExceptions.LocationAlreadyHasIntegrationException = function(args) {
};
Thrift.inherits(IntegrationExceptions.LocationAlreadyHasIntegrationException, Thrift.TException);
IntegrationExceptions.LocationAlreadyHasIntegrationException.prototype.name = 'LocationAlreadyHasIntegrationException';
IntegrationExceptions.LocationAlreadyHasIntegrationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationExceptions.LocationAlreadyHasIntegrationException.prototype.write = function(output) {
  output.writeStructBegin('LocationAlreadyHasIntegrationException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationExceptions.FailedToExchangeOAuthToken = function(args) {
};
Thrift.inherits(IntegrationExceptions.FailedToExchangeOAuthToken, Thrift.TException);
IntegrationExceptions.FailedToExchangeOAuthToken.prototype.name = 'FailedToExchangeOAuthToken';
IntegrationExceptions.FailedToExchangeOAuthToken.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationExceptions.FailedToExchangeOAuthToken.prototype.write = function(output) {
  output.writeStructBegin('FailedToExchangeOAuthToken');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationExceptions.IntegrationNotAccessibleException = function(args) {
};
Thrift.inherits(IntegrationExceptions.IntegrationNotAccessibleException, Thrift.TException);
IntegrationExceptions.IntegrationNotAccessibleException.prototype.name = 'IntegrationNotAccessibleException';
IntegrationExceptions.IntegrationNotAccessibleException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationExceptions.IntegrationNotAccessibleException.prototype.write = function(output) {
  output.writeStructBegin('IntegrationNotAccessibleException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationExceptions.UnsupportedPosIntegrationTypeException = function(args) {
  this.type = null;
  if (args) {
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field type is unset!');
    }
  }
};
Thrift.inherits(IntegrationExceptions.UnsupportedPosIntegrationTypeException, Thrift.TException);
IntegrationExceptions.UnsupportedPosIntegrationTypeException.prototype.name = 'UnsupportedPosIntegrationTypeException';
IntegrationExceptions.UnsupportedPosIntegrationTypeException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.type = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationExceptions.UnsupportedPosIntegrationTypeException.prototype.write = function(output) {
  output.writeStructBegin('UnsupportedPosIntegrationTypeException');
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.I32, 1);
    output.writeI32(this.type);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationExceptions.PosHistoryLimitExceededException = function(args) {
};
Thrift.inherits(IntegrationExceptions.PosHistoryLimitExceededException, Thrift.TException);
IntegrationExceptions.PosHistoryLimitExceededException.prototype.name = 'PosHistoryLimitExceededException';
IntegrationExceptions.PosHistoryLimitExceededException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationExceptions.PosHistoryLimitExceededException.prototype.write = function(output) {
  output.writeStructBegin('PosHistoryLimitExceededException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationExceptions.InvalidAuthorizationCodeException = function(args) {
};
Thrift.inherits(IntegrationExceptions.InvalidAuthorizationCodeException, Thrift.TException);
IntegrationExceptions.InvalidAuthorizationCodeException.prototype.name = 'InvalidAuthorizationCodeException';
IntegrationExceptions.InvalidAuthorizationCodeException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationExceptions.InvalidAuthorizationCodeException.prototype.write = function(output) {
  output.writeStructBegin('InvalidAuthorizationCodeException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

