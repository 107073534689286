import React from 'react';

import { IBoundingBox } from 'shared/models/Charts/IBoundingBox';
import { HexColor } from 'shared/models/HexColor';
import { ProgressBar } from './ProgressBar';

import { utils } from 'shared/components/Charts/utils';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export interface IAutoSizingProgressBarProps {
    readonly barColor : HexColor;
    readonly backgroundColor : HexColor;
    readonly barPercent : number;
    readonly heightInPixels : number;
}

export class AutoSizingProgressBar extends React.Component<IAutoSizingProgressBarProps, object> {
    private readonly classNamePrefix = 'auto-sizing-progress-bar';

    private resizeTimeoutId : number = -1;
    private containerElement : HTMLDivElement | undefined;

    public componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);
        this.onWindowResize();
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
        window.clearTimeout(this.resizeTimeoutId);
    }

    public render() {
        const {
            barPercent,
        } = this.props;

        if (barPercent < 0 || barPercent > 1) {
            throw new RuntimeException('barPercent must be a valid percent value');
        }

        return (
            <div
                className={ `${ this.classNamePrefix }-container` }
                ref={ this.containerElementRefFunction }
            >
                { this.createProgressBar() }
            </div>
        );
    }

    private readonly containerElementRefFunction = (containerElement : HTMLDivElement) : void => {
        this.containerElement = containerElement;
    }

    private readonly onWindowResize = () : void => {
        // This causes a rerender on resize
        window.clearTimeout(this.resizeTimeoutId);
        this.resizeTimeoutId = window.setTimeout(() => {
            this.setState({});
        }, 250);
    }

    private readonly createProgressBar = () : JSX.Element | undefined  => {
        const {
            barColor,
            backgroundColor,
            barPercent,
            heightInPixels,
        } = this.props;

        if (!this.containerElement) {
            return (
                  <div style={ { height: heightInPixels, width : '100%', backgroundColor : backgroundColor.getValue() } }/>
            );
        }

        const boundingBox : IBoundingBox = {
            heightInPixels,
            widthInPixels: (this.containerElement.clientWidth !== 0) ? this.containerElement.clientWidth : 1,
        };

        utils.ensureBoundingBoxIsValid(boundingBox);

        return (
            <ProgressBar
                barColor={ barColor }
                backgroundColor={ backgroundColor }
                barPercent={ barPercent }
                boundingBox={ boundingBox }
            />
        );
    }
}
