import { StringValueMap } from 'api/Core/StringValueMap';
import { Distributor } from 'api/Distributor/model/Distributor';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { AccountType } from 'api/Location/model/AccountType';
import { Product } from 'api/Product/model/Product';
import { Category } from 'api/Product/model/Category';
import { CategoryId } from 'api/Product/model/CategoryId';
import { ProductId } from 'api/Product/model/ProductId';
import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { OptionsAndLabelNameTuples } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { StringUtils } from 'shared/utils/stringUtils';

export namespace CategoryUtils {

    export enum defaultBeverageCategories {
        'Accessory' = 'Accessory',
        'Bar Goods' = 'Bar Goods',
        'Beer' = 'Beer',
        'Champagne' = 'Champagne',
        'Cider' = 'Cider',
        'Cocktail' = 'Cocktail',
        'Draught Beer' = 'Draught Beer',
        'Food' = 'Food',
        'Kitchen' = 'Kitchen',
        'Mixer' = 'Mixer',
        'Other' = 'Other', // Is this still a thing??
        'Sake' = 'Sake',
        'Spirit' = 'Spirit',
        'Wine' = 'Wine',
        'Wine By The Glass' = 'Wine By The Glass',
    }

    export enum defaultFoodCategories {
        'Dairy' = 'Dairy',
        'Dry Goods' = 'Dry Goods',
        'Food' = 'Food',
        'Frozen' = 'Frozen',
        'Meat' = 'Meat',
        'Poultry' = 'Poultry',
        'Produce' = 'Produce',
        'Seafood' = 'Seafood',
    }

    export const getDefaultCategories = () : Array<string> => {

        const defaultProductCategories : { [t in AccountType]: Array<string> } = {
            beverage: Array.from(Object.keys(defaultBeverageCategories)),
            food: Array.from(Object.keys(defaultFoodCategories)),
        };

        let accountType = window.GLOBAL_RETAILER_ACCOUNT_TYPE;
        if (typeof accountType === 'undefined') {
            accountType = 'beverage';
        }

        return defaultProductCategories[accountType];
    };

    const colorsByCategoryName : {[c in defaultBeverageCategories | defaultFoodCategories] : string} = {
        Dairy: '#FD8C51',
        'Dry Goods': '#0ADAC5',
        Food: '#FBB048',
        Frozen: '#2678B2',
        Meat: '#FC5648',
        Poultry: '#FFE168',
        Produce: '#74D376',
        Seafood: '#33A4C4',

        Spirit: '#81bab0',
        Wine: '#8b566b',
        'Wine By The Glass': '#6f3564',
        Champagne: '#f7e7cE',
        Beer: '#fbb048',
        'Draught Beer': '#fd8d48',
        Cider: '#fb5648',
        Sake: '#584ea1',
        Cocktail: '#00a094',
        Mixer: '#3a99af',
        'Bar Goods': '#77989f',
        Kitchen: '#d9ebd9',
        Accessory: '#6d757c',

        Other: '#c2cccb',
    };

    const otherColors : Array<string> = [
        '#6F3464',
        '#183C79',
        '#A3E4F7',
        '#A46B9B',
        '#F9A08A',
        '#FFFBC6',
        '#DFF0D3',
        '#FAE1AC',
        '#ADBAC4',
        '#484448',
        '#6D757C',
        '#B05F2D',
    ];

    export const getColorsForCategories = (categories : IterableIterator<string>) : Map<string, string> => {
        const result = new Map<string, string>();
        let otherColorIndex : number = 0;

        normalizeProductCategories(categories).forEach((category : string) => {

            let color = colorsByCategoryName[category as defaultBeverageCategories | defaultFoodCategories];

            if (typeof color === 'undefined') {

                if (otherColorIndex < otherColors.length) {
                    color = otherColors[otherColorIndex];

                } else {
                    color = colorsByCategoryName.Other;
                }

                otherColorIndex++;
            }

            result.set(category, color);
        });

        return result;
    };

    export const getCategoriesFromProductsAndDefaults = (products : IterableIterator<Product>) : Array<string> => {
        const categorySet = new Set<string>(getDefaultCategories());

        const productCategories = getCategoriesFromProducts(products);
        if (productCategories) {
            productCategories.forEach((category) => categorySet.add(category));
        }

        return categorySort(Array.from(categorySet));
    };

    export const getCategorySortedOptionsAndLabelNames = (categoriesById : StringValueMap<CategoryId, Category>, group : string | null) : OptionsAndLabelNameTuples => {
        const categoryInformation : Array<{categoryId : CategoryId, category : Category}> = [];
        categoriesById.forEach((category, categoryId) => {
            if (!category.getIsDeleted()) {
                categoryInformation.push({
                    categoryId,
                    category
                });
            }
        });
        const categoryOptionsAndLabelNames = categoryInformation.map(makeCategoryOptionFromValue);
        return [
            [group, categoryOptionsAndLabelNames.sort((a : IOption, b : IOption) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1)],
        ];
    };

    export const createCategoryLabelName = (category : Category) => {
        const glCode = category.getGlCode();
        return category.getName() + (glCode.trim().length > 0 ? ` (${glCode.trim()})` : '');
    };

    export const makeCategoryOptionFromValue = (categoryInformation : {categoryId : CategoryId, category : Category}) : IOption => {

        return {
            value : categoryInformation.categoryId.getValue(),
            label: createCategoryLabelName(categoryInformation.category),
            icon: null,
        };
    };

    export const getCategoriesFromProducts = (products : IterableIterator<Product>) : Array<string> => {
        const categorySet = new Set<string>();
        for (const product of products) {
            categorySet.add(StringUtils.normalizeToTitleCase(product.getProductCategoryId()));
        }

        return categorySort(Array.from(categorySet));
    };

    export const getProductsWithCategoryById = (categoryId : CategoryId, productsById : StringValueMap<ProductId, Product>) => {
        const productsWithCategoryById = new StringValueMap<ProductId, Product>();
        productsById.forEach((product, productId) => {
            if (categoryId.equals(product.getNewProductCategoryId())) {
                productsWithCategoryById.set(productId, product);
            }
        });
        return productsWithCategoryById;
    };


    export const getDistributorAdjustmentDependencyNumber = (
        categoryId : CategoryId,
        distributorsById : StringValueMap<DistributorId, Distributor>,
    ) : number => {
        let distributorAdjustmentDependencies = 0;
        distributorsById.forEach((distributor) => {
            distributor.getExtraDistributorProperties().adjustments.forEach((adjustment) => {
                if (new CategoryId(adjustment.categoryId).equals(categoryId)) {
                    distributorAdjustmentDependencies++;
                }
            });
        });
        return distributorAdjustmentDependencies;
    };

    export const normalizeProductCategories = (categories : IterableIterator<string>) : Array<string> => {
        const categorySet = new Set<string>();

        for (const category of categories) {
            if (typeof category !== 'undefined') {
                categorySet.add(StringUtils.normalizeToTitleCase(category));
            }
        }

        return categorySort(Array.from(categorySet));
    };

    export const getCategoryIdFromName = (categoriesById : StringValueMap<CategoryId, Category>, name : string, includeDeleted : boolean = false) : CategoryId | null => {
        let resultCategoryId = null;
        categoriesById.forEach((category, categoryId) => {
            if ((includeDeleted || !category.getIsDeleted()) && category.getName().trim().toLowerCase() === name.trim().toLowerCase()) {
                resultCategoryId = categoryId;
            }
        });
        return resultCategoryId;
    };

    export const isDuplicateCategoryName = (
        name : string,
        categoryId : CategoryId | null,
        categoriesById : StringValueMap<CategoryId, Category>,
    ) : boolean => {
        let isDuplicate = false;
        categoriesById.forEach((category, comparisonCategoryId) => {
            if (!comparisonCategoryId.equals(categoryId) && !category.getIsDeleted() && category.getName().toLowerCase().trim() === name.toLowerCase().trim()) {
                isDuplicate = true;
            }
        });
        return isDuplicate;
    };

    export const categoryCompareFunction = (a : string, b : string) => {
        return a.localeCompare(b);
    };

    export const categorySort = (categories : Array<string>) : Array<string> => {
        return categories.sort(categoryCompareFunction);
    };
}
