import React from 'react';

import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { StringValueMap } from 'api/Core/StringValueMap';
import { Distributor } from 'api/Distributor/model/Distributor';
import { DistributorId } from 'api/Distributor/model/DistributorId';

import { IPricedProductPackageRowId, IProductPackageRowInfo } from '../reducers/addItemReducers';
import { serializeIProductPackageRowInfoAttributes } from '../utils';
import { ProductPackageRow } from './ProductPackageRow';

import '../css/CollapsableProductRow.scss';

export interface ICollapsableProductRowProps {
    readonly productId : ProductId;
    readonly product : Product;
    readonly productPackages : Array<IProductPackageRowInfo>;
    readonly isExpanded : boolean;
    readonly distributorsById : StringValueMap<DistributorId, Distributor>;
    readonly onToggleExpand : (productId : ProductId, isExpanded : boolean) => void;
    readonly onToggleSelect : (selectedProductId : IPricedProductPackageRowId, isSelected : boolean) => void;
}

export class CollapsableProductRow extends React.Component<ICollapsableProductRowProps, object> {
    private readonly handleOnExpandChange : () => void;
    private readonly handleOnSelectChange : (productPackageRowInfoAttribute : string, isSelected : boolean) => void;

    constructor(props : ICollapsableProductRowProps) {
        super(props);

        this.handleOnExpandChange = this.handleOnExpandChangeGenerator();
        this.handleOnSelectChange = this.handleOnSelectChangeGenerator();
    }

    public render() {
        const {
            product,
            productPackages,
            isExpanded,
            distributorsById
        } = this.props;

        const sortedProductPackages = productPackages.slice();
        sortedProductPackages.sort((a, b) => {
            const packagingContentA = a.packaging.getContent();
            const packagingContentB = b.packaging.getContent();

            const packageDepthA = packagingContentA ? (packagingContentA.getContent() ? 3 : 2) : 1;
            const packageDepthB = packagingContentB ? (packagingContentB.getContent() ? 3 : 2) : 1;

            if (a.isDisabled !== b.isDisabled) {
                if (a.isDisabled) {
                    return -1;
                } else {
                    return 1;
                }
            }

            // sort by package depth
            if (packageDepthA > packageDepthB) {
                return -1;
            } else if (packageDepthA < packageDepthB) {
                return 1;
            } else {
                // then sort by container
                const nameA = a.packaging.getName();
                const nameB = b.packaging.getName();

                if (nameA !== nameB) {
                    if (nameA === null) {
                        return 1;
                    } else if (nameB === null) {
                        return -1;
                    } else if (nameA === 'case') { // cases should always be first
                        return -1;
                    } else if (nameB === 'case') {
                        return 1;
                    } else {
                        return nameA.localeCompare(nameB);
                    }
                } else {
                    // TODO Cheezy
                    // // sort by case content
                    // if (a.productPackage.content !== null && b.productPackage.content !== null) {
                    //     unitA = a.productPackage.content.unit;
                    //     unitB = b.productPackage.content.unit;

                    //     if (unitA !== unitB) {
                    //         return ProductAmountModel.Unit[unitA].localeCompare(ProductAmountModel.Unit[unitB]);
                    //     } else {
                    //         if (a.productPackage.content.content !== null && b.productPackage.content.content !== null) {
                    //             unitA = a.productPackage.content.content.unit;
                    //             unitB = b.productPackage.content.content.unit;

                    //             if (unitA !== unitB) {
                    //                 return ProductAmountModel.Unit[unitA].localeCompare(ProductAmountModel.Unit[unitB]);
                    //             }
                    //         } else if (a.productPackage.content.content !== b.productPackage.content.content) {
                    //             if (a.productPackage.content.content === null) {
                    //                 return 1;
                    //             } else {
                    //                 return -1;
                    //             }
                    //         }
                    //     }
                    // } else {
                    //     if (a.productPackage.content !== b.productPackage.content) {
                    //         if (a.productPackage.content === null) {
                    //             return 1;
                    //         } else {
                    //             return -1;
                    //         }
                    //     }
                    // }

                    // then sort by distributor
                    if (a.distributorId !== b.distributorId) {
                        if (a.distributorId === null) {
                            return 1;
                        } else if (b.distributorId === null) {
                            return -1;
                        } else {
                            let distributorNameA : string = '-';
                            let distributorNameB : string = '-';
                            const distributorA = distributorsById.get(a.distributorId);
                            const distributorB = distributorsById.get(b.distributorId);

                            if (typeof distributorA !== 'undefined') {
                                distributorNameA = distributorA.getShortName();
                            }

                            if (typeof distributorB !== 'undefined') {
                                distributorNameB = distributorB.getShortName();
                            }

                            if (distributorNameA !== distributorNameB) {
                                return distributorNameA.localeCompare(distributorNameB);
                            }
                        }
                    }
                    // then sort by price
                    return (a.price || 0) - (b.price || 0);     // should be both null (catalog item) or both non-null (product)
                }
            }
        });

        let productPackageRows : Array<JSX.Element>;
        if (isExpanded) {
            productPackageRows = sortedProductPackages.map((productPackageInfo) => this.buildProductPackageRow(productPackageInfo));
        } else {
            productPackageRows = [this.buildProductPackageRow(sortedProductPackages[0])];
        }

        return (
            <div className="collapsable-product-row cell-ptb">
                <div className="col-row">
                    <div className="ctable-row-content">
                        <div className="cell col-sm-cell-clear col-xs-12 col-sm-5">
                            <div className="col-row">
                                <div className="cell col-xs-8 cell-ptb">
                                    <span className="product-name-and-brand-container">
                                        <span className="product-brand">{ product.getBrand() }</span>
                                        <span className="product-name">{ product.getName() }</span>
                                    </span>
                                </div>
                                <div
                                    className="cell cell-ptb col-xs-4 text-right cell-plr-sm"
                                    onClick={ productPackages.length > 1 ? this.handleOnExpandChange : undefined }
                                >
                                    { productPackages.length > 1 &&
                                        <span className="expand-options">
                                            <span className={ `bevicon bevico-arrow_drop_down ${!isExpanded ? 'rotate-270' : ''}` } />
                                            <span className="package-option-count">{ productPackages.length } options</span>
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="package-rows cell col-sm-cell-clear col-xs-12 col-sm-7">
                            { productPackageRows }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private buildProductPackageRow(productPackageInfo : IProductPackageRowInfo) {
        const {
            distributorsById
        } = this.props;
        const productPackageRowInfoAttribute = serializeIProductPackageRowInfoAttributes(productPackageInfo);

        let distributorName : string = '-';
        if (productPackageInfo.distributorId !== null) {
            const distributor = distributorsById.get(productPackageInfo.distributorId);
            if (typeof distributor !== 'undefined') {
                distributorName = distributor.getShortName();
            }
        }

        return (
            <div
                key={ distributorName + productPackageRowInfoAttribute + productPackageInfo.price }
                className="product-package-row-wrapper"
            >
                <ProductPackageRow
                    productPackageRowInfoAttribute={ productPackageRowInfoAttribute }
                    packaging={ productPackageInfo.packaging }
                    productId={ this.props.productId }
                    distributorName={ distributorName }
                    price={ productPackageInfo.price }
                    productLength={ this.props.productPackages.length }
                    isDisabled={ productPackageInfo.isDisabled }
                    isExpanded={ this.props.isExpanded }
                    isSelected={ productPackageInfo.isSelected }
                    onToggleSelect={ this.handleOnSelectChange }
                    onToggleExpand={ this.handleOnExpandChange }
                />
            </div>
        );
    }

    private handleOnExpandChangeGenerator() {
        return () => {
            this.props.onToggleExpand(this.props.productId, !this.props.isExpanded);
        };
    }

    private handleOnSelectChangeGenerator() {
        return (productPackageRowInfoAttribute : string, isSelected : boolean) => {
            this.props.onToggleSelect({
                pricedProductIdValue: this.props.productId.getValue(),
                productPackageRowInfoAttribute
            }, isSelected);
        };
    }
}
