import React from 'react';
import MediaQuery from 'react-responsive';

import { SalesEntry } from 'api/Reports/model/SalesEntry';
import { PosItemId } from 'api/SalesData/model/PosItemId';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { MappedStatus } from 'apps/SalesItemMapper/appTypes';
import { Button } from 'shared/components/Button';
import { CheckBox } from 'shared/components/CheckBox';
import { Flex } from 'shared/components/FlexLayout/Flex';
import { MAX_MOBILE_WIDTH, MIN_TABLET_WIDTH, NOTIFICATION_TIMEOUT_IN_MILLISECONDS } from 'shared/constants';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { CheckBoxTriState } from 'shared/models/CheckBoxTriState';
import { getCurrencySymbol } from 'shared/models/Currency';
import { FormatMonetaryValueWithCents } from 'shared/utils/FormatMonetaryValue';
import { Validation } from 'shared/validators/validators';
import { InlineEditComponentSaveState, InlineEditWrapper } from '../InlineEditWrapper/InlineEditWrapper';
import { IValidationInputData, ValidationInput, ValidationInputTheme } from '../ValidationInput';

export type SalesEntryKey = 'price' | 'quantity' | 'quantityAdjustment' | 'salesAdjustment';
export type SalesInputRowId = SalesItemId | PosItemId;
interface ISalesInputRowProps {
    className : string;
    rowId : SalesInputRowId;
    name : string;
    posId : string;
    salesPrice : number; // note: separate from entry because sometimes these are different, and this is always required here
    mappedStatus : MappedStatus;
    isFlagged : boolean;

    hasBulkSelect : boolean;
    salesEntry : SalesEntry | undefined; // for consideration: what to do if no entry yet?
    isSelected : boolean;

    onSetRowCheckboxSelected : (rowId : SalesInputRowId, isSelected : boolean) => void;
    onEditItemClick : (rowId : SalesInputRowId) => void;
    onUpdateSalesEntryForRowId : ((rowId : SalesInputRowId, key : SalesEntryKey, value : number | null) => Promise<void>) | null; // only for non-readonly
}

interface ISalesInputRowState {
    validationInputByEntryKey : {[key in SalesEntryKey]: IValidationInputData | null };
    saveStateByEntryKey : {[key in SalesEntryKey] : InlineEditComponentSaveState | null };
}

export class SalesInputRow extends React.Component<ISalesInputRowProps, ISalesInputRowState> {
    private readonly currencySymbol = getCurrencySymbol();
    constructor(props : ISalesInputRowProps) {
        super(props);

        // TODO performance? can we be better about only initializing when we need it?
        this.state = {
            validationInputByEntryKey: {
                quantity: null,
                quantityAdjustment: null,
                salesAdjustment: null,
                price: null
            },
            saveStateByEntryKey: {
                quantity: null,
                quantityAdjustment: null,
                salesAdjustment: null,
                price: null
            },
        };
    }

    public render() {
        const {
            mappedStatus,
            name,
            posId,
            salesPrice,
            isSelected,
            isFlagged,
            className,
            hasBulkSelect,
            onUpdateSalesEntryForRowId,
            salesEntry,
        } = this.props;

        let iconClassName : string = '';
        let editButtonText : string = '';
        let statusClassName : string = 'mapping-status';
        switch (mappedStatus) {
            case MappedStatus.DELETED:
                iconClassName = 'bevico-delete';
                editButtonText = 'Edit';
                statusClassName += ' danger-action-text';
                break;
            case MappedStatus.UNMAPPED:
                iconClassName = 'bevico-unmapped';
                editButtonText = 'Unmapped';
                statusClassName += ' danger-action-text';
                break;
            case MappedStatus.MAPPED:
                iconClassName = 'bevico-check_circle';
                editButtonText = 'Mapped';
                statusClassName += ' success-action-text';
                break;
        }

        const checkbox = (
            <CheckBox
                checkBoxTriState={ isSelected ? CheckBoxTriState.Checked : CheckBoxTriState.Unchecked }
                isDisabled={ false }
                label={ null }
                onClick={ this.onCheckboxClick }
            />
        );

        let totalSold : number | null = null;
        if (salesEntry && (salesEntry.getQuantity() !== null || salesEntry.getQuantityAdjustment() !== null)) {
            totalSold = (salesEntry.getQuantity() || 0) + (salesEntry.getQuantityAdjustment() || 0);
        }

        const salesDataIsReadonly = (onUpdateSalesEntryForRowId === null);

        return (
            <Flex direction="row" className={ `sales-input-row light-table-row ${ className }` }>
                <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                    <Flex direction="row" align="stretch">
                        <Flex direction="row" grow={ 1 } align="center">
                            <Flex direction="column" grow={ 1 } align="end">
                                { isFlagged && <span className="bevicon bevico-flag error-text"/> }
                            </Flex>
                            <Flex direction="column" grow={ 1 } align="center">
                                { hasBulkSelect && checkbox }
                            </Flex>
                        </Flex>
                        <Flex direction="column" className="flex-py-1 flex-px-2" grow={ 6 } justify="center">
                            <Flex direction="row" className="flex-pb-1 sales-input-item-name ellipsis-out">
                                { name }
                            </Flex>
                            <Flex direction="row" className="row-subsection sales-input-item-name ellipsis-out">
                                <span className="sub-label">POS ID:</span> { posId }
                            </Flex>
                        </Flex>
                        <Flex direction="column" grow={ 2 } justify="center" align="center" className="flex-px-2">
                            { salesDataIsReadonly && this.getSalesEntryNumberDisplayForKey('price', true) }
                            { !salesDataIsReadonly && this.makeInlineEditWrapper('price', false) }
                        </Flex>
                        <Flex direction="column" grow={ 2 } justify="center" align="center" className="flex-px-2 section-start">
                            { salesDataIsReadonly && this.getSalesEntryNumberDisplayForKey('quantity') }
                            { !salesDataIsReadonly && this.makeInlineEditWrapper('quantity', false) }
                        </Flex>
                        <Flex direction="column" grow={ 2 } justify="center" align="center" className="flex-px-2">
                            { salesDataIsReadonly && this.getSalesEntryNumberDisplayForKey('quantityAdjustment') }
                            { !salesDataIsReadonly && this.makeInlineEditWrapper('quantityAdjustment', false) }
                        </Flex>
                        <Flex direction="column" grow={ 2 } justify="center" align="center" className="flex-px-2 section-end">
                            { this.getNumberDisplay(totalSold) }
                        </Flex>
                        <Flex direction="column" grow={ 2 } justify="center" align="center" className="flex-px-2">
                            { salesDataIsReadonly && this.getSalesEntryNumberDisplayForKey('salesAdjustment') }
                            { !salesDataIsReadonly && this.makeInlineEditWrapper('salesAdjustment', false) }
                        </Flex>
                        <Flex direction="column" grow={ 4 } justify="center" align="center" className="flex-px-2">
                            <Button
                                buttonClassName={ `flat normal ${ statusClassName }` }
                                isDisabled={ false }
                                isLoading={ false }
                                onClick={ this.handleOnEditClick }
                            >
                                <span className={ `bevicon ${ iconClassName }` }/>&nbsp;{ `${ editButtonText } ` }<span className="forward-arrow">&nbsp;&rsaquo;</span>
                            </Button>
                        </Flex>
                    </Flex>
                </MediaQuery>
                <MediaQuery maxWidth={ MAX_MOBILE_WIDTH }>
                    <Flex direction="column" className={ `flex-py-2` } justify="center">
                        <Flex direction="row">
                            <Flex direction="column" className="row-start-container" grow={ 1 } align="center" justify="center">
                                { isFlagged && <span className="bevicon bevico-flag error-text"/> }
                                { hasBulkSelect && checkbox }
                            </Flex>
                            <Flex direction="column" className="flex-pr-2" grow={ 5 } justify="center">
                                <Flex direction="row" className="ellipsis-out" align="center">
                                    { name }
                                </Flex>
                            </Flex>
                            <Flex direction="column" grow={ 2 } justify="center" align="end">
                                <Button
                                    buttonClassName={ `flat normal row-subsection ${ statusClassName }` }
                                    isDisabled={ false }
                                    isLoading={ false }
                                    onClick={ this.handleOnEditClick }
                                >
                                    <span className={ `bevicon ${ iconClassName }` }/><span className="forward-arrow">&nbsp;&rsaquo;</span>
                                </Button>
                            </Flex>
                        </Flex>
                        <Flex direction="row" className="row-subsection indented-row" justify="center">
                            <Flex direction="column">
                                <Flex direction="row" className="ellipsis-out"><span className="sub-label">{ `POS ID: ` }</span>{ posId }</Flex>
                            </Flex>
                            <Flex direction="row" className="row-subsection flex-pb-1 flex-pl-1"><span className="sub-label">{ `Sales Price: ` }</span>{ FormatMonetaryValueWithCents(salesPrice) }</Flex>
                        </Flex>
                        <Flex direction="row" className="indented-row mobile-value-headers flex-pr-5">
                            <Flex direction="column" align="start">Total Sold</Flex>
                            <Flex direction="column" align="center">Base Sold</Flex>
                            <Flex direction="column" align="center">Unit Adj.</Flex>
                            <Flex direction="column" align="center">{ this.currencySymbol } Adj.</Flex>
                        </Flex>
                        <Flex direction="row" className={ `indented-row flex-pr-5${ salesDataIsReadonly ? '' : ' flex-pt-1'}` } align="center">
                            <Flex direction="column" className={ `flex-px-1${ salesDataIsReadonly ? '' : ' total-sold-value-display'}` } align="start">
                                { this.getNumberDisplay(totalSold) }
                            </Flex>
                            <Flex direction="column" className="flex-px-1" align="center">
                                { salesDataIsReadonly && this.getSalesEntryNumberDisplayForKey('quantity') }
                                { !salesDataIsReadonly && this.makeInlineEditWrapper('quantity', true) }
                            </Flex>
                            <Flex direction="column" className="flex-px-1" align="center">
                                { salesDataIsReadonly && this.getSalesEntryNumberDisplayForKey('quantityAdjustment') }
                                { !salesDataIsReadonly && this.makeInlineEditWrapper('quantityAdjustment', true) }
                            </Flex>
                            <Flex direction="column" className="flex-px-1" align="center">
                                { salesDataIsReadonly && this.getSalesEntryNumberDisplayForKey('salesAdjustment') }
                                { !salesDataIsReadonly && this.makeInlineEditWrapper('salesAdjustment', true) }
                            </Flex>
                        </Flex>
                    </Flex>
                </MediaQuery>
            </Flex>
        );
    }

    private readonly onCheckboxClick = () => {
        this.props.onSetRowCheckboxSelected(this.props.rowId, !this.props.isSelected);
    }

    private readonly handleOnEditClick = () => {
        this.props.onEditItemClick(this.props.rowId);
    }

    private readonly getNumberDisplay = (numberValue : number | null) : string => {
        if (numberValue === null) {
            return '';
        }

        return Number.isInteger(numberValue) ? numberValue.toString() : numberValue.toFixed(2);
    }

    /*
    *  INLINE EDITING
    */
    private readonly makeInlineEditWrapper = (entryKey : SalesEntryKey, isMobile : boolean) => {
        const {
            saveStateByEntryKey,
            validationInputByEntryKey
        } = this.state;

        const handleOpenEditor = () => this.onOpenEditor(entryKey);
        const handleCloseEdtior = () => this.onCloseEditor(entryKey);
        const handleChange = (event : React.ChangeEvent<HTMLInputElement>) => this.onEditorFieldChange(entryKey, event.target.value);

        let validationForEntryKey = validationInputByEntryKey[entryKey];
        if (validationForEntryKey === null && isMobile) {
            // on mobile for now always display the validation input.
            // we could change this in the future to behave more like desktop (input opens when you click on it)
            validationForEntryKey = {
                isValid: true,
                errorMessage: '',
                value: this.getSalesEntryNumberDisplayForKey(entryKey)
            };
        }

        return (
            <InlineEditWrapper
                className="flex-child"
                inlineEditComponentName={ entryKey }
                inlineEditSaveState={ saveStateByEntryKey[entryKey] }
                inlineEditingComponentIsShown={ validationInputByEntryKey[entryKey] !== null } // note: not the variable but use what's actually on the state
                handleOnEditClick={ handleOpenEditor }
            >
                { validationForEntryKey !== null &&
                    <ValidationInput
                        key={ entryKey }
                        inputClassName=""
                        type="text"
                        autoFocus={ !isMobile } // on mobile since we always show validation input, don't auto focus
                        autoComplete={ null }
                        value={ validationForEntryKey.value }
                        errorMessage={ validationForEntryKey.errorMessage }
                        isValid={ validationForEntryKey.isValid }
                        handleBlur={ handleCloseEdtior }
                        handleFocus={ null }
                        handleChange={ handleChange }
                        handleEnterClick={ handleCloseEdtior }
                        label={ null }
                        hintText={ null }
                        isDisabled={ false }
                        isCurrencyInput={ entryKey === 'price' }
                        theme={ isMobile ? ValidationInputTheme.Basic : ValidationInputTheme.Default }
                    />
                }
                { validationForEntryKey === null &&
                    <Flex direction="column" justify="center" align="center" className="static-view">
                        { this.getSalesEntryNumberDisplayForKey(entryKey, true) }
                    </Flex>
                }
            </InlineEditWrapper>
        );
    }

    private readonly onOpenEditor = (entryKey : SalesEntryKey) => {
        this.setState({
            validationInputByEntryKey: {
                ...this.state.validationInputByEntryKey,
                [entryKey]: {
                    value: this.getSalesEntryNumberDisplayForKey(entryKey),
                    isValid: true,
                    errorMessage: '',
                }
            }
        });
    }

    private readonly onCloseEditor = (entryKey : SalesEntryKey) => {
        const {
            validationInputByEntryKey
        } = this.state;

        const {
            rowId,
            onUpdateSalesEntryForRowId
        } = this.props;

        if (onUpdateSalesEntryForRowId === null) {
            throw new RuntimeException('Editor should not be available if no update is defined');
        }

        const currentValidationInput = validationInputByEntryKey[entryKey];
        if (currentValidationInput === null) {
            return; // nothing to do if no validation input info
        }

        if (!currentValidationInput.isValid) {
            // close editor without saving - this will revert to the old value in the SalesEntry object
            this.setState({
                validationInputByEntryKey: {
                    ...this.state.validationInputByEntryKey,
                    [entryKey]: null,
                },
            });
        } else {
            const numberOrNullValue = currentValidationInput.value === '' ? null : parseFloat(currentValidationInput.value);
            if (numberOrNullValue === this.getSalesEntryValueForKey(entryKey)) {
                // no changes = just close the editor
                this.setState({
                    validationInputByEntryKey: {
                        ...this.state.validationInputByEntryKey,
                        [entryKey]: null, // close editor
                    },
                });
            } else {
                this.setState({
                    validationInputByEntryKey: {
                        ...this.state.validationInputByEntryKey,
                        [entryKey]: null, // close editor
                    },
                    saveStateByEntryKey: {
                        ...this.state.saveStateByEntryKey,
                        [entryKey]: InlineEditComponentSaveState.SAVE_IN_PROGRESS
                    }
                });

                onUpdateSalesEntryForRowId(rowId, entryKey, numberOrNullValue)
                .then(() => {
                    this.onSaveSuccessful(entryKey);
                }); // promise resolution = success
            }
        }
    }

    // NOTE: if validation changes (e.g. allowing floats for quantity) MUST change api endpoint/form validation
    private readonly validateField = (entryKey : SalesEntryKey, value : string) => {
        let isValid : boolean;
        let errorMessage : string = '';
        if (entryKey === 'price') {
            isValid = Validation.validateNonNegativeNumber(value);
            if (!isValid) {
                errorMessage = 'Invalid price';
            }
        } else if (entryKey === 'salesAdjustment') { // sales adjustment = any number
            isValid = value === '' ? true : Validation.validateNumber(value);
            if (!isValid) {
                errorMessage = 'Invalid number';
            }
        } else if (entryKey === 'quantity') { // quantity = non-negative integer
            isValid = value === '' ? true : Validation.validateNonNegativeNumber(value) && Number.isInteger(parseFloat(value));
            if (!isValid) {
                errorMessage = 'Must be integer >= 0';
            }
        } else { // quantity adjustment = any integer
            isValid = value === '' ? true : Validation.validateNumber(value) && Number.isInteger(parseFloat(value));
            if (!isValid) {
                errorMessage = 'Must be an integer';
            }
        }

        return {
            value,
            isValid,
            errorMessage
        };
    }

    private readonly onEditorFieldChange = (entryKey : SalesEntryKey, value : string) => {
        this.setState({
            validationInputByEntryKey: {
                ...this.state.validationInputByEntryKey,
                [entryKey]: this.validateField(entryKey, value),
            }
        });
    }

    private readonly getSalesEntryValueForKey = (entryKey : SalesEntryKey) => {
        const salesEntry = this.props.salesEntry;
        if (!salesEntry) {
            return null;
        }

        switch (entryKey) {
            case 'quantity':
                return salesEntry.getQuantity();
            case 'quantityAdjustment':
                return salesEntry.getQuantityAdjustment();
            case 'salesAdjustment':
                return salesEntry.getSalesAdjustment();
            case 'price':
                return this.props.salesPrice;
            default:
                throw new RuntimeException('unexpected key value');
        }
    }

    private readonly getSalesEntryNumberDisplayForKey = (entryKey : SalesEntryKey, monetaryFormatForPrice? : boolean) => {
        const numberOrNullValue = this.getSalesEntryValueForKey(entryKey);

        switch (entryKey) {
            case 'quantity':
            case 'quantityAdjustment':
            case 'salesAdjustment':
                return this.getNumberDisplay(numberOrNullValue);
            case 'price':
                if (monetaryFormatForPrice) {
                    return FormatMonetaryValueWithCents(numberOrNullValue);
                }
                return this.getNumberDisplay(numberOrNullValue);
            default:
                throw new RuntimeException('unexpected key value');
        }
    }

    private readonly onSaveSuccessful = (entryKey : SalesEntryKey) => {
        this.setState({
            saveStateByEntryKey: {
                ...this.state.saveStateByEntryKey,
                [entryKey]: InlineEditComponentSaveState.FINISHED_SAVING
            }
        });
        setTimeout(() => {
            this.setState({
                saveStateByEntryKey: {
                    ...this.state.saveStateByEntryKey,
                    [entryKey]: null
                }
            });
        }, NOTIFICATION_TIMEOUT_IN_MILLISECONDS);
    }
}
