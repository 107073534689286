export interface IJSONDistributorAdjustment {
    name? : string;
    category_id? : string;
}
export class DistributorAdjustment {

    public static createFromJSONObject(JSONObject : IJSONDistributorAdjustment) : DistributorAdjustment {
        const result = new DistributorAdjustment(
            DistributorAdjustment.procDefault(JSONObject.name, ''),
            DistributorAdjustment.procDefault(JSONObject.category_id, ''),
        );

        return result;
    }

    private static procDefault<T>(value : T | undefined, defaultValue : T) : T {
        if (typeof value === 'undefined' || value === null) {
            return defaultValue;
        } else {
            return value;
        }
    }

    constructor(
        readonly name : string, // Required
        readonly categoryId : string, // Required
    ) { }

    public getJSONObject = () : IJSONDistributorAdjustment => ({
        name: this.name,
        category_id: this.categoryId,
    })

    public getRestFormData = (formData : FormData) : FormData => {
        formData.append('name', this.name);
        formData.append('category_id', this.categoryId);
        return formData;
    }
}
