import React from 'react';

import { Button } from 'shared/components/Button';
import { Dialog } from 'shared/components/Dialog';
import { IModalButton } from 'shared/models/Modal';

export interface IUnsavedChangesDialogProps {
    readonly saveDisabled : boolean;
    readonly onSaveAndContinueClick : () => void;
    readonly onNavigateWithoutSavingClick : () => void;
    readonly onCancelClick : () => void;
}

// this is very similar to a dialog on order detail, but the copy is a little different (this one is more generic?)
// can we make this a shared component and have order detail use this one?
export class UnsavedChangesDialog extends React.Component<IUnsavedChangesDialogProps, object> {
    public render() {
        const {
            saveDisabled,
            onSaveAndContinueClick,
            onNavigateWithoutSavingClick,
            onCancelClick,
        } = this.props;

        const buttons : Array<IModalButton> = [
            {
                onClick: onNavigateWithoutSavingClick,
                classes: 'flat',
                isDisabled: false,
                isLoading: false,
                children: 'Discard Changes',
            },
            {
                onClick: onSaveAndContinueClick,
                classes: 'flat primary',
                isDisabled: saveDisabled,
                isLoading: false,
                children: 'Save',
            },
        ];

        return (
            <Dialog
                className="unsaved-changes-dialog"
                buttons={ buttons }
                headerText={
                    <span>
                        <span>Wait! You have unsaved changes.</span>
                        <Button
                            buttonClassName="bevicon bevico-close close-dialog flat"
                            isDisabled={ false }
                            isLoading={ false }
                            onClick={ onCancelClick }
                        />
                    </span>
                }
            >
                <p>
                    You have entered new data on this page. If you navigate away without saving, your changes will be lost.
                </p>
            </Dialog>
        );
    }
}
