import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { IColumnSorting } from 'shared/components/SortableColumnHeader';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export interface ISortedFilteredAndGroupedResult<T> {
    sortedRowIdsToDisplayByGroupName : {[groupName : string] : Array<T>};
    sortedGroupNamesToDisplay : Array<string>;
    panelIsOpenByGroupNameFromFiltering : {[groupName : string] : boolean};
    unfilteredSortedRowIdsByGroupName : {[groupName : string] : Array<T>};
}

interface IGroupByResult<T> {
    sortedGroupNames : Array<string>;
    groupNamesByRowId : Map<T, string>;
}

export class SortingFilteringAndGroupingUtil<T, E, G> {
    private lastArgumentsAndResults : undefined | {
        rowIds : Array<T>;
        columnSort : IColumnSorting | null;
        filterTerm : string | null;
        groupByOption : G;
        extraArgs : E;
        sortResult : Array<T>;
        filterResult : Set<T>;
        groupByResult : IGroupByResult<T>;
    };

    constructor(
        private readonly rowIdComparator : (rowId1 : T, rowId2 : T, columnSort : IColumnSorting, extraArgs : E)  => number,
        private readonly rowIdFilterFunction : (rowId : T, filterTerm : string | null, extraArgs : E) => boolean,
        private readonly getGroupNameForRowId : (rowId : T, groupByOption : G, extraArgs : E) => string,
        private readonly groupNameComparator : (groupName1 : string, groupName2 : string, groupByOption : G, extraArgs : E) => number,
        private readonly intermediateResultCacheIsValid : (rowIds : Array<T>, lastRowIds : Array<T>, extraArgs : E, lastExtraArgs : E) => boolean,
    ) {}

    public getSortedFilteredAndGroupedResult(
        rowIds : Array<T>,
        columnSort : IColumnSorting | null,
        filterTerm : string | null,
        groupByOption : G,
        extraArgs : E,
    ) : Promise<ISortedFilteredAndGroupedResult<T>> {
        let sortPromise;
        let filterPromise;
        let groupByPromise;

        const lastArgumentsAndResults = this.lastArgumentsAndResults;

        if ((typeof lastArgumentsAndResults !== 'undefined') && this.intermediateResultCacheIsValid(rowIds, lastArgumentsAndResults.rowIds, extraArgs, lastArgumentsAndResults.extraArgs)) {
            if (columnSort !== null && lastArgumentsAndResults.columnSort !== null && columnSort.sortedBy === lastArgumentsAndResults.columnSort.sortedBy) {
                if (columnSort.direction === lastArgumentsAndResults.columnSort.direction) {
                    sortPromise = Promise.resolve(lastArgumentsAndResults.sortResult);
                } else {
                    sortPromise = Promise.resolve(lastArgumentsAndResults.sortResult.slice().reverse());
                }
            }

            if (filterTerm === lastArgumentsAndResults.filterTerm) {
                filterPromise = Promise.resolve(lastArgumentsAndResults.filterResult);
            }

            if (groupByOption === lastArgumentsAndResults.groupByOption) {
                groupByPromise = Promise.resolve(lastArgumentsAndResults.groupByResult);
            }
        }

        if (columnSort === null) {
            sortPromise = Promise.resolve(rowIds);
        } else if (typeof sortPromise === 'undefined') {
            if ((typeof lastArgumentsAndResults !== 'undefined') && (rowIds === lastArgumentsAndResults.rowIds) && (extraArgs === lastArgumentsAndResults.extraArgs)) {
                sortPromise = this.asyncSort(lastArgumentsAndResults.sortResult, (rowId1 : T, rowId2 : T) => this.rowIdComparator(rowId1, rowId2, columnSort, extraArgs));
            } else {
                sortPromise = this.asyncSort(rowIds, (rowId1 : T, rowId2 : T) => this.rowIdComparator(rowId1, rowId2, columnSort, extraArgs));
            }
        }

        if (typeof filterPromise === 'undefined') {
            // previously calling this cleanPhrase was up to the implementation of the rowId filter function - that makes it more flexible but
            // in practice we always called cleanPhrase. And when called in the filter function, we did it for literally every row. more performant to
            // do this one time, here.
            const cleanedFilterTerm = (filterTerm === null || filterTerm === '') ? filterTerm : cleanPhrase(filterTerm);
            filterPromise = this.asyncFilter(rowIds, (rowId : T) => this.rowIdFilterFunction(rowId, cleanedFilterTerm, extraArgs));
        }

        if (typeof groupByPromise === 'undefined') {
            groupByPromise = this.asyncGroupBy(
                rowIds,
                (rowId : T) => this.getGroupNameForRowId(rowId, groupByOption, extraArgs),
                (groupName1 : string, groupName2 : string) => this.groupNameComparator(groupName1, groupName2, groupByOption, extraArgs)
            );
        }

        return Promise.all([sortPromise, filterPromise, groupByPromise])
        .then((result : [Array<T>, Set<T>, IGroupByResult<T>]) => {
            const sortResult = result[0];
            const filterResult = result[1];
            const groupByResult = result[2];

            this.lastArgumentsAndResults = {
                rowIds,
                columnSort,
                filterTerm,
                groupByOption,
                extraArgs,
                sortResult,
                filterResult,
                groupByResult,
            };

            const sortedRowIdsToDisplayByGroupName : {[groupName : string] : Array<T>} = {};
            const unfilteredSortedRowIdsByGroupName : {[groupName : string] : Array<T>} = {};
            sortResult.forEach((rowId : T) => {
                const groupName = groupByResult.groupNamesByRowId.get(rowId);

                if (typeof groupName === 'undefined') {
                    throw new RuntimeException('unexpected');
                }

                const unfilteredSortedRowIds = unfilteredSortedRowIdsByGroupName[groupName] || [];
                unfilteredSortedRowIds.push(rowId);
                unfilteredSortedRowIdsByGroupName[groupName] = unfilteredSortedRowIds;

                if (filterResult.has(rowId)) {
                    const groupRowIds = sortedRowIdsToDisplayByGroupName[groupName];
                    if (groupRowIds) {
                        groupRowIds.push(rowId);
                    } else {
                        sortedRowIdsToDisplayByGroupName[groupName] = [rowId];
                    }
                }
            });

            const sortedGroupNamesToDisplay : Array<string> = [];
            groupByResult.sortedGroupNames.forEach((groupName : string) => {
                if (typeof sortedRowIdsToDisplayByGroupName[groupName] !== 'undefined') {
                    sortedGroupNamesToDisplay.push(groupName);
                }
            });

            const panelIsOpenByGroupNameFromFiltering : {[groupName : string] : boolean} = {};
            if (filterTerm) {
                if ((filterTerm.length >= 4) || (filterResult.size <= 15 && sortedGroupNamesToDisplay.length <= 6) || (filterResult.size <= 25 && sortedGroupNamesToDisplay.length <= 2)) {
                    sortedGroupNamesToDisplay.forEach((groupName : string) => {
                        panelIsOpenByGroupNameFromFiltering[groupName] = true;
                    });
                }
            }

            return Promise.resolve({
                sortedRowIdsToDisplayByGroupName,
                sortedGroupNamesToDisplay,
                panelIsOpenByGroupNameFromFiltering,
                unfilteredSortedRowIdsByGroupName
            });
        });
    }

    // Sorting
    private asyncSort(rowIds : Array<T>, rowIdComparator : (rowId1 : T, rowId2 : T)  => number) : Promise<Array<T>> {
        return Promise.resolve(rowIds.slice().sort(rowIdComparator));
    }

    // Filtering/Searching
    private asyncFilter(rowIds : Array<T>, rowIdFilterFunction : (rowId : T) => boolean) : Promise<Set<T>> {
        return Promise.resolve(new Set<T>(rowIds.slice().filter(rowIdFilterFunction)));
    }

    // Grouping
    private asyncGroupBy(
        rowIds : Array<T>,
        getGroupNameForRowId : (rowId : T) => string,
        groupNameComparator : (groupName1 : string, groupName2 : string) => number,
    ) : Promise<{sortedGroupNames : Array<string>, groupNamesByRowId : Map<T, string>}> {

        const groupNames = new Set<string>();
        const groupNamesByRowId = new Map<T, string>();
        rowIds.forEach((rowId) => {
            const groupName : string = getGroupNameForRowId(rowId);
            groupNames.add(groupName);
            groupNamesByRowId.set(rowId, groupName);
        });

        // TODO maybe async sort this. Definitely overkill for the number of groups we currently expect
        const sortedGroupNames : Array<string> = Array.from(groupNames.keys()).sort(groupNameComparator);

        return Promise.resolve({
            sortedGroupNames,
            groupNamesByRowId
        });
    }
}

// cleanPhrase noticeably slows down iteration through very large arrays. should be very deliberate about when to use it
// right now: use during filter (we cache filter strings for products and sales items which helps)
export const cleanPhrase = (phrase : string) : string => {
    const trimmedPhrase = phrase.trim();
    const cleanedTokens : Array<string> = trimmedPhrase.split(/\s+/g).map(cleanToken);
    return cleanedTokens.join(' ');
};

// Adapted from python _clean_token for elasticsearch
const cleanToken = (token : string) : string => {
    // token = token.replace(/^\s+|\s+$/g, ''); // strip whitespace
    // token = token.replace(/[^\w|\.]/g, ''); // strip special characters

    // note that period is not a special character here... this matches previous behavior
    // change from previous behavior: this allows non ascii characters to remain, like ö or å
    token = token.replace(/\s+|[&\/\\#,+()$~%'":;*\^?<>{}\[\]]/g, ''); // strip whitespace and special characters

    return token.toLowerCase();
};

export const compareNumbers = (number1 : number | undefined, number2 : number | undefined) : number => {
    if (typeof number1 === 'undefined') {
        return -1;
    }

    if (typeof number2 === 'undefined') {
        return 1;
    }

    if (number1 === number2) {
        return 0;
    }

    return (number1 > number2) ? 1 : -1;
};

export const compareQuantityInUnitQuantities = (quantityInUnit1 : QuantityInUnit<any> | undefined, quantityInUnit2 : QuantityInUnit<any> | undefined) => {
    return compareNumbers(quantityInUnit1 ? quantityInUnit1.getQuantity() : undefined, quantityInUnit2 ? quantityInUnit2.getQuantity() : undefined);
};

export const getIsOpenByGroupName = (
    displayedGroupNames : Array<string>,
    panelIsOpenByGroupNameFromFiltering : {[groupName : string] : boolean},
    panelIsOpenByGroupNameFromUserInput : {[groupName : string] : boolean},
    defaultPanelIsOpen : boolean,
) => {
    const isOpenByGroupName : {[groupName : string] : boolean} = {};
    displayedGroupNames.forEach((groupName : string) => {
        let panelIsOpenFromUserInput = panelIsOpenByGroupNameFromUserInput[groupName];
        if (typeof panelIsOpenFromUserInput === 'undefined') {
            panelIsOpenFromUserInput = defaultPanelIsOpen;
        }
        isOpenByGroupName[groupName] = panelIsOpenByGroupNameFromFiltering[ groupName ] || panelIsOpenFromUserInput;
    });
    return isOpenByGroupName;
};

export const getClickWillCollapseAll = (isOpenByGroupName : {[groupName : string] : boolean}) => {
    return Object.keys(isOpenByGroupName).reduce((nextClickWillCollapseAll : boolean, groupName : string) => nextClickWillCollapseAll || isOpenByGroupName[groupName], false);
};
