import React from 'react';

import { LegendItem } from 'shared/components/Charts/LegendItem';
import { ILegendItem } from 'shared/models/Charts/ILegendItem';

export interface ILegendProps {
    readonly legendItems : ReadonlyArray<ILegendItem>;
}

export class Legend extends React.Component<ILegendProps, object> {
    private readonly classNamePrefix = 'chart-legend';

    public render() {
        const className = this.classNamePrefix + '-container';

        return (
            <div
                className={ className }
            >
                { this.createItems() }
            </div>
        );
    }

    private createItems = () : ReadonlyArray<JSX.Element> | undefined => {
        const {
            legendItems,
        } = this.props;

        if (legendItems.length === 0) {
            return;
        }

        return legendItems.map(
            (legendItem : ILegendItem, index : number) => {
                return (
                    <LegendItem
                        key={ index }

                        name={ legendItem.name }
                        shape={ legendItem.shape }
                        color={ legendItem.color }
                    />
                );
            }
        );
    }
}
