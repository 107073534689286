//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';



var UnauthenticatedUserSessionExceptions = {};
export default UnauthenticatedUserSessionExceptions;
UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException = function(args) {
};
Thrift.inherits(UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException, Thrift.TException);
UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException.prototype.name = 'UnknownUnauthenticatedUserSessionIdException';
UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException.prototype.write = function(output) {
  output.writeStructBegin('UnknownUnauthenticatedUserSessionIdException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.CanvasDataInvalidStringLengthException = function(args) {
};
Thrift.inherits(UnauthenticatedUserSessionExceptions.CanvasDataInvalidStringLengthException, Thrift.TException);
UnauthenticatedUserSessionExceptions.CanvasDataInvalidStringLengthException.prototype.name = 'CanvasDataInvalidStringLengthException';
UnauthenticatedUserSessionExceptions.CanvasDataInvalidStringLengthException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.CanvasDataInvalidStringLengthException.prototype.write = function(output) {
  output.writeStructBegin('CanvasDataInvalidStringLengthException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.WebGLVendorInvalidStringLengthException = function(args) {
};
Thrift.inherits(UnauthenticatedUserSessionExceptions.WebGLVendorInvalidStringLengthException, Thrift.TException);
UnauthenticatedUserSessionExceptions.WebGLVendorInvalidStringLengthException.prototype.name = 'WebGLVendorInvalidStringLengthException';
UnauthenticatedUserSessionExceptions.WebGLVendorInvalidStringLengthException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.WebGLVendorInvalidStringLengthException.prototype.write = function(output) {
  output.writeStructBegin('WebGLVendorInvalidStringLengthException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.WebGLRendererInvalidStringLengthException = function(args) {
};
Thrift.inherits(UnauthenticatedUserSessionExceptions.WebGLRendererInvalidStringLengthException, Thrift.TException);
UnauthenticatedUserSessionExceptions.WebGLRendererInvalidStringLengthException.prototype.name = 'WebGLRendererInvalidStringLengthException';
UnauthenticatedUserSessionExceptions.WebGLRendererInvalidStringLengthException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.WebGLRendererInvalidStringLengthException.prototype.write = function(output) {
  output.writeStructBegin('WebGLRendererInvalidStringLengthException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.PluginsInvalidStringLengthException = function(args) {
};
Thrift.inherits(UnauthenticatedUserSessionExceptions.PluginsInvalidStringLengthException, Thrift.TException);
UnauthenticatedUserSessionExceptions.PluginsInvalidStringLengthException.prototype.name = 'PluginsInvalidStringLengthException';
UnauthenticatedUserSessionExceptions.PluginsInvalidStringLengthException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.PluginsInvalidStringLengthException.prototype.write = function(output) {
  output.writeStructBegin('PluginsInvalidStringLengthException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.LocalStorageAllowedInvalidStringLengthException = function(args) {
};
Thrift.inherits(UnauthenticatedUserSessionExceptions.LocalStorageAllowedInvalidStringLengthException, Thrift.TException);
UnauthenticatedUserSessionExceptions.LocalStorageAllowedInvalidStringLengthException.prototype.name = 'LocalStorageAllowedInvalidStringLengthException';
UnauthenticatedUserSessionExceptions.LocalStorageAllowedInvalidStringLengthException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.LocalStorageAllowedInvalidStringLengthException.prototype.write = function(output) {
  output.writeStructBegin('LocalStorageAllowedInvalidStringLengthException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.SessionStorageAllowedInvalidStringLengthException = function(args) {
};
Thrift.inherits(UnauthenticatedUserSessionExceptions.SessionStorageAllowedInvalidStringLengthException, Thrift.TException);
UnauthenticatedUserSessionExceptions.SessionStorageAllowedInvalidStringLengthException.prototype.name = 'SessionStorageAllowedInvalidStringLengthException';
UnauthenticatedUserSessionExceptions.SessionStorageAllowedInvalidStringLengthException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.SessionStorageAllowedInvalidStringLengthException.prototype.write = function(output) {
  output.writeStructBegin('SessionStorageAllowedInvalidStringLengthException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.CookieAllowedInvalidStringLengthException = function(args) {
};
Thrift.inherits(UnauthenticatedUserSessionExceptions.CookieAllowedInvalidStringLengthException, Thrift.TException);
UnauthenticatedUserSessionExceptions.CookieAllowedInvalidStringLengthException.prototype.name = 'CookieAllowedInvalidStringLengthException';
UnauthenticatedUserSessionExceptions.CookieAllowedInvalidStringLengthException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.CookieAllowedInvalidStringLengthException.prototype.write = function(output) {
  output.writeStructBegin('CookieAllowedInvalidStringLengthException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.PlatformInvalidStringLengthException = function(args) {
};
Thrift.inherits(UnauthenticatedUserSessionExceptions.PlatformInvalidStringLengthException, Thrift.TException);
UnauthenticatedUserSessionExceptions.PlatformInvalidStringLengthException.prototype.name = 'PlatformInvalidStringLengthException';
UnauthenticatedUserSessionExceptions.PlatformInvalidStringLengthException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.PlatformInvalidStringLengthException.prototype.write = function(output) {
  output.writeStructBegin('PlatformInvalidStringLengthException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.DoNotTrackInvalidStringLengthException = function(args) {
};
Thrift.inherits(UnauthenticatedUserSessionExceptions.DoNotTrackInvalidStringLengthException, Thrift.TException);
UnauthenticatedUserSessionExceptions.DoNotTrackInvalidStringLengthException.prototype.name = 'DoNotTrackInvalidStringLengthException';
UnauthenticatedUserSessionExceptions.DoNotTrackInvalidStringLengthException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.DoNotTrackInvalidStringLengthException.prototype.write = function(output) {
  output.writeStructBegin('DoNotTrackInvalidStringLengthException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.TimezoneInvalidStringLengthException = function(args) {
};
Thrift.inherits(UnauthenticatedUserSessionExceptions.TimezoneInvalidStringLengthException, Thrift.TException);
UnauthenticatedUserSessionExceptions.TimezoneInvalidStringLengthException.prototype.name = 'TimezoneInvalidStringLengthException';
UnauthenticatedUserSessionExceptions.TimezoneInvalidStringLengthException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.TimezoneInvalidStringLengthException.prototype.write = function(output) {
  output.writeStructBegin('TimezoneInvalidStringLengthException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.ResolutionInvalidStringLengthException = function(args) {
};
Thrift.inherits(UnauthenticatedUserSessionExceptions.ResolutionInvalidStringLengthException, Thrift.TException);
UnauthenticatedUserSessionExceptions.ResolutionInvalidStringLengthException.prototype.name = 'ResolutionInvalidStringLengthException';
UnauthenticatedUserSessionExceptions.ResolutionInvalidStringLengthException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.ResolutionInvalidStringLengthException.prototype.write = function(output) {
  output.writeStructBegin('ResolutionInvalidStringLengthException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.ReferrerInvalidStringLengthException = function(args) {
};
Thrift.inherits(UnauthenticatedUserSessionExceptions.ReferrerInvalidStringLengthException, Thrift.TException);
UnauthenticatedUserSessionExceptions.ReferrerInvalidStringLengthException.prototype.name = 'ReferrerInvalidStringLengthException';
UnauthenticatedUserSessionExceptions.ReferrerInvalidStringLengthException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UnauthenticatedUserSessionExceptions.ReferrerInvalidStringLengthException.prototype.write = function(output) {
  output.writeStructBegin('ReferrerInvalidStringLengthException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

