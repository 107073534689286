//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';
import CoreTimeModel from './core_time_Model_types';



var PublicContentModel = {};
export default PublicContentModel;
PublicContentModel.PublicContentViewEventType = {
  '0' : 'PAGE_VIEW',
  'PAGE_VIEW' : 0,
  '1' : 'DOWNLOAD',
  'DOWNLOAD' : 1
};
PublicContentModel.PublicContentType = {
  '0' : 'POST',
  'POST' : 0,
  '1' : 'RESOURCE',
  'RESOURCE' : 1
};
PublicContentModel.PublicContentUrl = function(args) {
  this.hostname = null;
  this.path = null;
  if (args) {
    if (args.hostname !== undefined && args.hostname !== null) {
      this.hostname = args.hostname;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field hostname is unset!');
    }
    if (args.path !== undefined && args.path !== null) {
      this.path = args.path;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field path is unset!');
    }
  }
};
PublicContentModel.PublicContentUrl.prototype = {};
PublicContentModel.PublicContentUrl.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.hostname = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.path = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PublicContentModel.PublicContentUrl.prototype.write = function(output) {
  output.writeStructBegin('PublicContentUrl');
  if (this.hostname !== null && this.hostname !== undefined) {
    output.writeFieldBegin('hostname', Thrift.Type.STRING, 1);
    output.writeString(this.hostname);
    output.writeFieldEnd();
  }
  if (this.path !== null && this.path !== undefined) {
    output.writeFieldBegin('path', Thrift.Type.STRING, 2);
    output.writeString(this.path);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PublicContentModel.PublicContentViewEvent = function(args) {
  this.eventTimestamp = null;
  this.referrer = null;
  this.eventType = null;
  this.publicContentUrlParameters = null;
  this.publicContentUrl = null;
  if (args) {
    if (args.eventTimestamp !== undefined && args.eventTimestamp !== null) {
      this.eventTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision(args.eventTimestamp);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field eventTimestamp is unset!');
    }
    if (args.referrer !== undefined && args.referrer !== null) {
      this.referrer = args.referrer;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field referrer is unset!');
    }
    if (args.eventType !== undefined && args.eventType !== null) {
      this.eventType = args.eventType;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field eventType is unset!');
    }
    if (args.publicContentUrlParameters !== undefined && args.publicContentUrlParameters !== null) {
      this.publicContentUrlParameters = Thrift.copyMap(args.publicContentUrlParameters, [Thrift.copyList, null]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field publicContentUrlParameters is unset!');
    }
    if (args.publicContentUrl !== undefined && args.publicContentUrl !== null) {
      this.publicContentUrl = new PublicContentModel.PublicContentUrl(args.publicContentUrl);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field publicContentUrl is unset!');
    }
  }
};
PublicContentModel.PublicContentViewEvent.prototype = {};
PublicContentModel.PublicContentViewEvent.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.eventTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.eventTimestamp.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.referrer = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.eventType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.MAP) {
        var _size0 = 0;
        var _rtmp34;
        this.publicContentUrlParameters = {};
        var _ktype1 = 0;
        var _vtype2 = 0;
        _rtmp34 = input.readMapBegin();
        _ktype1 = _rtmp34.ktype;
        _vtype2 = _rtmp34.vtype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          if (_i5 > 0 ) {
            if (input.rstack.length > input.rpos[input.rpos.length -1] + 1) {
              input.rstack.pop();
            }
          }
          var key6 = null;
          var val7 = null;
          key6 = input.readString().value;
          var _size8 = 0;
          var _rtmp312;
          val7 = [];
          var _etype11 = 0;
          _rtmp312 = input.readListBegin();
          _etype11 = _rtmp312.etype;
          _size8 = _rtmp312.size;
          for (var _i13 = 0; _i13 < _size8; ++_i13)
          {
            var elem14 = null;
            elem14 = input.readString().value;
            val7.push(elem14);
          }
          input.readListEnd();
          this.publicContentUrlParameters[key6] = val7;
        }
        input.readMapEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.publicContentUrl = new PublicContentModel.PublicContentUrl();
        this.publicContentUrl.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PublicContentModel.PublicContentViewEvent.prototype.write = function(output) {
  output.writeStructBegin('PublicContentViewEvent');
  if (this.eventTimestamp !== null && this.eventTimestamp !== undefined) {
    output.writeFieldBegin('eventTimestamp', Thrift.Type.STRUCT, 1);
    this.eventTimestamp.write(output);
    output.writeFieldEnd();
  }
  if (this.referrer !== null && this.referrer !== undefined) {
    output.writeFieldBegin('referrer', Thrift.Type.STRING, 2);
    output.writeString(this.referrer);
    output.writeFieldEnd();
  }
  if (this.eventType !== null && this.eventType !== undefined) {
    output.writeFieldBegin('eventType', Thrift.Type.I32, 3);
    output.writeI32(this.eventType);
    output.writeFieldEnd();
  }
  if (this.publicContentUrlParameters !== null && this.publicContentUrlParameters !== undefined) {
    output.writeFieldBegin('publicContentUrlParameters', Thrift.Type.MAP, 4);
    output.writeMapBegin(Thrift.Type.STRING, Thrift.Type.LIST, Thrift.objectLength(this.publicContentUrlParameters));
    for (var kiter15 in this.publicContentUrlParameters)
    {
      if (this.publicContentUrlParameters.hasOwnProperty(kiter15))
      {
        var viter16 = this.publicContentUrlParameters[kiter15];
        output.writeString(kiter15);
        output.writeListBegin(Thrift.Type.STRING, viter16.length);
        for (var iter17 in viter16)
        {
          if (viter16.hasOwnProperty(iter17))
          {
            iter17 = viter16[iter17];
            output.writeString(iter17);
          }
        }
        output.writeListEnd();
      }
    }
    output.writeMapEnd();
    output.writeFieldEnd();
  }
  if (this.publicContentUrl !== null && this.publicContentUrl !== undefined) {
    output.writeFieldBegin('publicContentUrl', Thrift.Type.STRUCT, 5);
    this.publicContentUrl.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PublicContentModel.PublicContent = function(args) {
  this.publicContentUrl = null;
  this.publicContentType = null;
  this.title = null;
  this.excerpt = null;
  this.thumbnailUrl = null;
  this.publicationDate = null;
  if (args) {
    if (args.publicContentUrl !== undefined && args.publicContentUrl !== null) {
      this.publicContentUrl = new PublicContentModel.PublicContentUrl(args.publicContentUrl);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field publicContentUrl is unset!');
    }
    if (args.publicContentType !== undefined && args.publicContentType !== null) {
      this.publicContentType = args.publicContentType;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field publicContentType is unset!');
    }
    if (args.title !== undefined && args.title !== null) {
      this.title = args.title;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field title is unset!');
    }
    if (args.excerpt !== undefined && args.excerpt !== null) {
      this.excerpt = args.excerpt;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field excerpt is unset!');
    }
    if (args.thumbnailUrl !== undefined && args.thumbnailUrl !== null) {
      this.thumbnailUrl = args.thumbnailUrl;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field thumbnailUrl is unset!');
    }
    if (args.publicationDate !== undefined && args.publicationDate !== null) {
      this.publicationDate = new CoreTimeModel.TimestampWithSecondPrecision(args.publicationDate);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field publicationDate is unset!');
    }
  }
};
PublicContentModel.PublicContent.prototype = {};
PublicContentModel.PublicContent.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.publicContentUrl = new PublicContentModel.PublicContentUrl();
        this.publicContentUrl.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.publicContentType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.title = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.excerpt = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.thumbnailUrl = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.publicationDate = new CoreTimeModel.TimestampWithSecondPrecision();
        this.publicationDate.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PublicContentModel.PublicContent.prototype.write = function(output) {
  output.writeStructBegin('PublicContent');
  if (this.publicContentUrl !== null && this.publicContentUrl !== undefined) {
    output.writeFieldBegin('publicContentUrl', Thrift.Type.STRUCT, 1);
    this.publicContentUrl.write(output);
    output.writeFieldEnd();
  }
  if (this.publicContentType !== null && this.publicContentType !== undefined) {
    output.writeFieldBegin('publicContentType', Thrift.Type.I32, 2);
    output.writeI32(this.publicContentType);
    output.writeFieldEnd();
  }
  if (this.title !== null && this.title !== undefined) {
    output.writeFieldBegin('title', Thrift.Type.STRING, 3);
    output.writeString(this.title);
    output.writeFieldEnd();
  }
  if (this.excerpt !== null && this.excerpt !== undefined) {
    output.writeFieldBegin('excerpt', Thrift.Type.STRING, 4);
    output.writeString(this.excerpt);
    output.writeFieldEnd();
  }
  if (this.thumbnailUrl !== null && this.thumbnailUrl !== undefined) {
    output.writeFieldBegin('thumbnailUrl', Thrift.Type.STRING, 5);
    output.writeString(this.thumbnailUrl);
    output.writeFieldEnd();
  }
  if (this.publicationDate !== null && this.publicationDate !== undefined) {
    output.writeFieldBegin('publicationDate', Thrift.Type.STRUCT, 6);
    this.publicationDate.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PublicContentModel.PublicContentAndPublicContentViewEvent = function(args) {
  this.publicContent = null;
  this.publicContentViewEvent = null;
  if (args) {
    if (args.publicContent !== undefined && args.publicContent !== null) {
      this.publicContent = new PublicContentModel.PublicContent(args.publicContent);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field publicContent is unset!');
    }
    if (args.publicContentViewEvent !== undefined && args.publicContentViewEvent !== null) {
      this.publicContentViewEvent = new PublicContentModel.PublicContentViewEvent(args.publicContentViewEvent);
    }
  }
};
PublicContentModel.PublicContentAndPublicContentViewEvent.prototype = {};
PublicContentModel.PublicContentAndPublicContentViewEvent.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.publicContent = new PublicContentModel.PublicContent();
        this.publicContent.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.publicContentViewEvent = new PublicContentModel.PublicContentViewEvent();
        this.publicContentViewEvent.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PublicContentModel.PublicContentAndPublicContentViewEvent.prototype.write = function(output) {
  output.writeStructBegin('PublicContentAndPublicContentViewEvent');
  if (this.publicContent !== null && this.publicContent !== undefined) {
    output.writeFieldBegin('publicContent', Thrift.Type.STRUCT, 1);
    this.publicContent.write(output);
    output.writeFieldEnd();
  }
  if (this.publicContentViewEvent !== null && this.publicContentViewEvent !== undefined) {
    output.writeFieldBegin('publicContentViewEvent', Thrift.Type.STRUCT, 2);
    this.publicContentViewEvent.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PublicContentModel.UserPublicContent = function(args) {
  this.recentlyViewed = null;
  this.recommended = null;
  if (args) {
    if (args.recentlyViewed !== undefined && args.recentlyViewed !== null) {
      this.recentlyViewed = Thrift.copyList(args.recentlyViewed, [PublicContentModel.PublicContentAndPublicContentViewEvent]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field recentlyViewed is unset!');
    }
    if (args.recommended !== undefined && args.recommended !== null) {
      this.recommended = Thrift.copyList(args.recommended, [PublicContentModel.PublicContentAndPublicContentViewEvent]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field recommended is unset!');
    }
  }
};
PublicContentModel.UserPublicContent.prototype = {};
PublicContentModel.UserPublicContent.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.SET) {
        var _size18 = 0;
        var _rtmp322;
        this.recentlyViewed = [];
        var _etype21 = 0;
        _rtmp322 = input.readSetBegin();
        _etype21 = _rtmp322.etype;
        _size18 = _rtmp322.size;
        for (var _i23 = 0; _i23 < _size18; ++_i23)
        {
          var elem24 = null;
          elem24 = new PublicContentModel.PublicContentAndPublicContentViewEvent();
          elem24.read(input);
          this.recentlyViewed.push(elem24);
        }
        input.readSetEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.SET) {
        var _size25 = 0;
        var _rtmp329;
        this.recommended = [];
        var _etype28 = 0;
        _rtmp329 = input.readSetBegin();
        _etype28 = _rtmp329.etype;
        _size25 = _rtmp329.size;
        for (var _i30 = 0; _i30 < _size25; ++_i30)
        {
          var elem31 = null;
          elem31 = new PublicContentModel.PublicContentAndPublicContentViewEvent();
          elem31.read(input);
          this.recommended.push(elem31);
        }
        input.readSetEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PublicContentModel.UserPublicContent.prototype.write = function(output) {
  output.writeStructBegin('UserPublicContent');
  if (this.recentlyViewed !== null && this.recentlyViewed !== undefined) {
    output.writeFieldBegin('recentlyViewed', Thrift.Type.SET, 1);
    output.writeSetBegin(Thrift.Type.STRUCT, this.recentlyViewed.length);
    for (var iter32 in this.recentlyViewed)
    {
      if (this.recentlyViewed.hasOwnProperty(iter32))
      {
        iter32 = this.recentlyViewed[iter32];
        iter32.write(output);
      }
    }
    output.writeSetEnd();
    output.writeFieldEnd();
  }
  if (this.recommended !== null && this.recommended !== undefined) {
    output.writeFieldBegin('recommended', Thrift.Type.SET, 2);
    output.writeSetBegin(Thrift.Type.STRUCT, this.recommended.length);
    for (var iter33 in this.recommended)
    {
      if (this.recommended.hasOwnProperty(iter33))
      {
        iter33 = this.recommended[iter33];
        iter33.write(output);
      }
    }
    output.writeSetEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

