import { StringValueMap } from 'api/Core/StringValueMap';

import { InventoryConfiguration } from 'api/InventoryCount/model/InventoryConfiguration';
import { ProductCountEvent } from 'api/InventoryCount/model/ProductCountEvent';
import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';
import { ProductId } from 'api/Product/model/ProductId';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export class InventoryCount {

    constructor (
        private readonly inventoryConfiguration : InventoryConfiguration,
        private readonly productCountEventsByProductIdByStorageAreaId : StringValueMap<StorageAreaId, StringValueMap<ProductId, ProductCountEvent>>,
    ) {
        inventoryConfiguration.getSortedProductIdListsByStorageAreaId().forEach((sortedProductIdList, storageAreaId) => {
            const productCountEventsByProductId = productCountEventsByProductIdByStorageAreaId.get(storageAreaId);
            if (typeof productCountEventsByProductId === 'undefined') {
                throw new RuntimeException(`no product count events for storageAreaId: '${ storageAreaId.getValue() }' in productCountEventsByProductIdByStorageAreaId`);
            }

            let matchCount : number = 0;
            sortedProductIdList.forEach((productId) => {
                if (productCountEventsByProductId.has(productId)) {
                    matchCount += 1;
                }
            });

            if (matchCount !== productCountEventsByProductId.size) {
                throw new RuntimeException(`productCountEvents exist for products that are not in the sortedProductIdList for storageAreaId: '${ storageAreaId.getValue() }'`);
            }
        });
    }

    public getInventoryConfiguration() : InventoryConfiguration {
        return this.inventoryConfiguration;
    }

    public getProductCountEventsByProductIdByStorageAreaId() : StringValueMap<StorageAreaId, StringValueMap<ProductId, ProductCountEvent>> {
        return this.productCountEventsByProductIdByStorageAreaId;
    }
}
