import PublicContentModel from 'gen-thrift/public_content_Model_types';

import { PublicContent } from '../model/PublicContent';
import { PublicContentAndPublicContentViewEvent } from '../model/PublicContentAndPublicContentViewEvent';
import { PublicContentTypeEnum } from '../model/PublicContentTypeEnum';
import { PublicContentUrl } from '../model/PublicContentUrl';
import { PublicContentViewEvent } from '../model/PublicContentViewEvent';
import { PublicContentViewEventTypeEnum } from '../model/PublicContentViewEventTypeEnum';
import { UserPublicContent } from '../model/UserPublicContent';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export class PublicContentThriftToObjectSerializer {

    public getPublicContent(
        publicContent : PublicContentModel.PublicContent
    ) : PublicContent {
        return new PublicContent(
            this.getPublicContentUrl(publicContent.publicContentUrl),
            this.getPublicContentTypeEnum(publicContent.publicContentType),
            publicContent.title,
            publicContent.excerpt,
            publicContent.thumbnailUrl,
            publicContent.publicationDate
        );
    }

    public getPublicContentAndPublicContentViewEvent(
        publicContentAndPublicContentViewEvent : PublicContentModel.PublicContentAndPublicContentViewEvent
    ) : PublicContentAndPublicContentViewEvent {
        return new PublicContentAndPublicContentViewEvent(
            this.getPublicContent(publicContentAndPublicContentViewEvent.publicContent),
            (publicContentAndPublicContentViewEvent.publicContentViewEvent === null) ? null : this.getPublicContentViewEvent(publicContentAndPublicContentViewEvent.publicContentViewEvent),
        );
    }

    public getPublicContentTypeEnum(
        publicContentType : PublicContentModel.PublicContentType
    ) : PublicContentTypeEnum {
        switch (publicContentType) {
            case PublicContentModel.PublicContentType.POST:
                return PublicContentTypeEnum.POST;
            case PublicContentModel.PublicContentType.RESOURCE:
                return PublicContentTypeEnum.RESOURCE;
            default:
                throw new RuntimeException('Not implemented for publicContentType: ' + publicContentType);
        }
    }

    public getPublicContentUrl(
        publicContentUrl : PublicContentModel.PublicContentUrl
    ) : PublicContentUrl {
        return new PublicContentUrl(
            publicContentUrl.hostname,
            publicContentUrl.path,
        );
    }

    public getPublicContentViewEvent(
        publicContentViewEvent : PublicContentModel.PublicContentViewEvent
    ) : PublicContentViewEvent {
        return new PublicContentViewEvent(
            publicContentViewEvent.eventTimestamp,
            publicContentViewEvent.referrer,
            this.getPublicContentViewEventTypeEnum(publicContentViewEvent.eventType),
            publicContentViewEvent.publicContentUrlParameters,
            this.getPublicContentUrl(publicContentViewEvent.publicContentUrl)
        );
    }

    public getPublicContentViewEventTypeEnum(
        publicContentViewEventType : PublicContentModel.PublicContentViewEventType
    ) : PublicContentViewEventTypeEnum {
        switch (publicContentViewEventType) {
            case PublicContentModel.PublicContentViewEventType.PAGE_VIEW:
                return PublicContentViewEventTypeEnum.PAGE_VIEW;
            case PublicContentModel.PublicContentViewEventType.DOWNLOAD:
                return PublicContentViewEventTypeEnum.DOWNLOAD;
            default:
                throw new RuntimeException('Not implemented for publicContentType: ' + publicContentViewEventType);
        }
    }

    public getUserPublicContent(
        userPublicContent : PublicContentModel.UserPublicContent
    ) : UserPublicContent {

        const recentlyViewed : Array<PublicContentAndPublicContentViewEvent> = [];
        userPublicContent.recentlyViewed.forEach((publicContentAndPublicContentViewEvent : PublicContentModel.PublicContentAndPublicContentViewEvent) => {
            recentlyViewed.push(this.getPublicContentAndPublicContentViewEvent(publicContentAndPublicContentViewEvent));
        });

        const recommended : Array<PublicContentAndPublicContentViewEvent> = [];
        userPublicContent.recommended.forEach((publicContentAndPublicContentViewEvent : PublicContentModel.PublicContentAndPublicContentViewEvent) => {
            recommended.push(this.getPublicContentAndPublicContentViewEvent(publicContentAndPublicContentViewEvent));
        });

        return new UserPublicContent(
            recentlyViewed,
            recommended,
        );
    }
}
