import * as d3 from 'd3';

export class OrdinalBandScale {
    public scaleOrdinal : d3.ScaleBand<string>;

    constructor(
        scaleOrdinal : d3.ScaleBand<string>
    ) {
        this.scaleOrdinal = scaleOrdinal;
    }

    public getValueFromKey(keyName : string) : number | undefined {
        return this.scaleOrdinal(keyName);
    }

    public getKeys() : Array<string> {
        return this.scaleOrdinal.domain();
    }

    public getBandwidth() : number {
        return this.scaleOrdinal.bandwidth();
    }
}
