import * as _ from 'lodash';

export interface IItemByListElementId<T> {
    [listElementId : string] : T;
}

export class OrderedItemList<T> {
    public listElementIds : Array<string>;
    public itemByListElementId : IItemByListElementId<T>;

    constructor(args : {
        listElementIds : Array<string>;
        itemByListElementId : IItemByListElementId<T>;
    }) {
        this.listElementIds = args.listElementIds;
        this.itemByListElementId = args.itemByListElementId;
    }

    public length() : number {
        return this.listElementIds.length;
    }

    public clone() : OrderedItemList<T> {
        return new OrderedItemList<T>({
            itemByListElementId: _.cloneDeep(this.itemByListElementId),
            listElementIds: this.listElementIds.slice(0),
        });
    }

    public getListElementIds() : Array<string> {
        return this.listElementIds;
    }

    public getItemByListElementId() : IItemByListElementId<T> {
        return this.itemByListElementId;
    }

    public getOrderedElements() : Array<T> {
        return this.listElementIds.map((id) => {
            return this.itemByListElementId[id];
        });
    }

    public getListElementById(listElementId : string) : T | null {
        return this.itemByListElementId[listElementId] || null;
    }

    public removeListElement(listElementIdToRemove : string) : void {
        const listElementIds = this.listElementIds;
        const elementIndex = listElementIds.indexOf(listElementIdToRemove);
        if (elementIndex === -1) {
            throw new Error('listElementId does not exist in listElementIds');
        }

        this.listElementIds = [
            ...listElementIds.slice(0, elementIndex),
            ...listElementIds.slice(elementIndex + 1, listElementIds.length),
        ];
        delete this.itemByListElementId[listElementIdToRemove];
    }

    public addListElement(item : T) : string {
        const newListElementId = _.uniqueId();
        this.listElementIds.push(newListElementId);
        this.itemByListElementId[newListElementId] = item;
        return newListElementId;
    }
}
