//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';



var ProductExceptions = {};
export default ProductExceptions;
ProductExceptions.UnknownProductIdException = function(args) {
};
Thrift.inherits(ProductExceptions.UnknownProductIdException, Thrift.TException);
ProductExceptions.UnknownProductIdException.prototype.name = 'UnknownProductIdException';
ProductExceptions.UnknownProductIdException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductExceptions.UnknownProductIdException.prototype.write = function(output) {
  output.writeStructBegin('UnknownProductIdException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductExceptions.DuplicateProductIdException = function(args) {
};
Thrift.inherits(ProductExceptions.DuplicateProductIdException, Thrift.TException);
ProductExceptions.DuplicateProductIdException.prototype.name = 'DuplicateProductIdException';
ProductExceptions.DuplicateProductIdException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductExceptions.DuplicateProductIdException.prototype.write = function(output) {
  output.writeStructBegin('DuplicateProductIdException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductExceptions.AccountDoesNotHaveAccessToProductException = function(args) {
};
Thrift.inherits(ProductExceptions.AccountDoesNotHaveAccessToProductException, Thrift.TException);
ProductExceptions.AccountDoesNotHaveAccessToProductException.prototype.name = 'AccountDoesNotHaveAccessToProductException';
ProductExceptions.AccountDoesNotHaveAccessToProductException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductExceptions.AccountDoesNotHaveAccessToProductException.prototype.write = function(output) {
  output.writeStructBegin('AccountDoesNotHaveAccessToProductException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductExceptions.LocationAssociatedWithProductIdDoesNotHaveAccessToDistributorException = function(args) {
};
Thrift.inherits(ProductExceptions.LocationAssociatedWithProductIdDoesNotHaveAccessToDistributorException, Thrift.TException);
ProductExceptions.LocationAssociatedWithProductIdDoesNotHaveAccessToDistributorException.prototype.name = 'LocationAssociatedWithProductIdDoesNotHaveAccessToDistributorException';
ProductExceptions.LocationAssociatedWithProductIdDoesNotHaveAccessToDistributorException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductExceptions.LocationAssociatedWithProductIdDoesNotHaveAccessToDistributorException.prototype.write = function(output) {
  output.writeStructBegin('LocationAssociatedWithProductIdDoesNotHaveAccessToDistributorException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductExceptions.ProductIdAlreadyHasAssociationWithThisDistributorIdException = function(args) {
};
Thrift.inherits(ProductExceptions.ProductIdAlreadyHasAssociationWithThisDistributorIdException, Thrift.TException);
ProductExceptions.ProductIdAlreadyHasAssociationWithThisDistributorIdException.prototype.name = 'ProductIdAlreadyHasAssociationWithThisDistributorIdException';
ProductExceptions.ProductIdAlreadyHasAssociationWithThisDistributorIdException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductExceptions.ProductIdAlreadyHasAssociationWithThisDistributorIdException.prototype.write = function(output) {
  output.writeStructBegin('ProductIdAlreadyHasAssociationWithThisDistributorIdException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductExceptions.ProductIdDoesNotHaveAssociationWithThisDistributorIdException = function(args) {
};
Thrift.inherits(ProductExceptions.ProductIdDoesNotHaveAssociationWithThisDistributorIdException, Thrift.TException);
ProductExceptions.ProductIdDoesNotHaveAssociationWithThisDistributorIdException.prototype.name = 'ProductIdDoesNotHaveAssociationWithThisDistributorIdException';
ProductExceptions.ProductIdDoesNotHaveAssociationWithThisDistributorIdException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductExceptions.ProductIdDoesNotHaveAssociationWithThisDistributorIdException.prototype.write = function(output) {
  output.writeStructBegin('ProductIdDoesNotHaveAssociationWithThisDistributorIdException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

