import { IDelinquentPaymentStatusService } from 'api/Billing/interfaces/IDelinquentPaymentStatusService';
import { LocationId } from 'api/Location/model/LocationId';
import { IDelinquentPaymentStatus } from 'apps/Billing/reducers/PlansAndBillingReducers';
import moment from 'moment-timezone';
import { AjaxUtils } from 'shared/utils/ajaxUtils';

export class DelinquentPaymentStatusService implements IDelinquentPaymentStatusService {
    public getDelinquentStatus(locationId : LocationId) : Promise<IDelinquentPaymentStatus> {
        return AjaxUtils.ajaxGet(url('account:get_delinquent_payment_status', null, locationId.getValue(), null))
        .then((response) => {
            return {
                gracePeriodEndDate: response.grace_period_end_date === null ? null : moment.utc(response.grace_period_end_date),
                hasDelinquentPayment: response.has_delinquent_payment,
                shouldOverrideStripe: response.should_override_stripe,
            };
        });
    }
}
