import React from 'react';

import { SvgPattern } from 'shared/models/StackedBarChart/SvgPattern';

/**
 * SVG defs should take a pattern enum, and if it is there return the pattern and the mask
 * (mask should be set for an element via css)
 */

export interface ISvgDefs {
    svgPattern : SvgPattern;
}

export class SvgPatternDefinitions extends React.Component<ISvgDefs, object> {

    public render() {
        const {
            svgPattern,
        } = this.props;

        switch (svgPattern) {

            case SvgPattern.DIAGONAL_STRIPE_TOP_RIGHT_BOTTOM_LEFT_SMALL:
                return (
                    <defs>
                        <pattern
                            id="pattern-stripe"
                            width={ 4 }
                            height={ 4 }
                            patternUnits="userSpaceOnUse"
                        >
                            <path
                                d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
                                style={ { stroke: 'white',  strokeWidth: 2 } }
                            />
                        </pattern>
                        <mask id="mask-stripe">
                            { /* TODO (Anna Lee Barber 3/2/17): apparently Microsoft Edge treats "100%" as 100% of the pattern box, not 100% of the parent, so may need to reconsider how to do this */ }
                            <rect x="0" y="0" width={ 1000 } height={ 1000 } fill="url(#pattern-stripe)" />
                        </mask>
                    </defs>
                );

            default:
                return (
                    <defs/>
                );
        }
    }

}
