import React from 'react';

import './HubSpotChat.scss';

interface IHubSpotChatProps {

}

export class HubSpotChat extends React.Component<IHubSpotChatProps, object> {
    public render() {

        window.hsConversationsSettings = {
            loadImmediately: false,
            inlineEmbedSelector: '#bevspot-hs-chat'
        };

        if (window._elev) {
            window._elev.events = {
                moduleOpened: (id: number) => {
                    if (id === 27 && window.HubSpotConversations) {
                        window.HubSpotConversations.widget.load({ widgetOpen: true });
                    }
                },
            };
        }

        return (
            <div id="bevspot-hs-chat">
                <span className="close-chat bevicon bevico-close" onClick={ this.onCloseLiveChatClick }/>
            </div>
        );
        
    }

    private readonly onCloseLiveChatClick = () => {
        if (window.HubSpotConversations) {
            window.HubSpotConversations.widget.remove();
        }
    }
}
