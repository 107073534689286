import React from 'react';

import '../css/SnackBar.scss';

export interface ISnackBar {
    /** Whether or not the SnackBar is visible on the page */
    isShown : boolean;
    /** The message to appear in the SnackBar */
    message : string;
    /** The text to show on the close button */
    callToActionLabel : string | null;
    /** Callback used on click of the dismiss button */
    handleCloseButton() : void;
}

/**
 * @example ../../docs/SnackBar.example.md
 */
export class SnackBar extends React.Component<ISnackBar, object> {
    public render() {
        const {
            isShown,
            message,
            callToActionLabel,
            handleCloseButton,
        } = this.props;

        let className = 'snack-bar';
        if (!isShown) {
            className += ' snack-bar-hidden';
        }
        if (callToActionLabel !== null) {
            className += ' snack-bar-close-button-text';
        }

        return (
            <div className={ className }>
                <a className="snack-bar-close" onClick={ handleCloseButton }>
                    { callToActionLabel === null &&
                        <span className="bevicon bevico-close"/>
                    }
                    { callToActionLabel !== null &&
                        <span className="snack-bar-close-text">{ callToActionLabel }</span>
                    }
                </a>
                <p>{ message }</p>
            </div>
        );
    }
}
