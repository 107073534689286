import { StringValueMap } from 'api/Core/StringValueMap';
import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductId } from 'api/Product/model/ProductId';
import { ReportMetadata } from 'api/Transfer/model/ReportMetadata';
import { TransferReportData } from 'api/Transfer/model/TransferReportData';

export class TransferReport {

    constructor (
        private readonly transferReportData : TransferReportData,
        private readonly reportMetaData : ReportMetadata,
        private readonly productCostsByProductId : StringValueMap<ProductId, ProductCost>
    ) {}

    public getTransferReportData() : TransferReportData {
        return this.transferReportData;
    }

    public getReportMetaData() : ReportMetadata {
        return this.reportMetaData;
    }

    public getProductCostsByProductId() : StringValueMap<ProductId, ProductCost> {
        return this.productCostsByProductId;
    }
}
