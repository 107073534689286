
import { StringValueMap } from 'api/Core/StringValueMap';
import React from 'react';

import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { StringValueSet } from 'api/Core/StringValueSet';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export interface ItemCardHistoryMergeToProps {
    product : Product;
    productId : ProductId;
    mergedToProductId : StringValueSet<ProductId>;
    productsById : StringValueMap<ProductId, Product>;
}

export class ItemCardHistoryMergeTo extends React.Component<ItemCardHistoryMergeToProps, object> {

    public render() {
        const {
            mergedToProductId,
            productsById,
        } = this.props;

        if (mergedToProductId.size === 0) {
            throw new RuntimeException("unexpected");
        }

        const mergedToProductIdValues = Array.from(mergedToProductId.values());
        const productId = mergedToProductIdValues[0];
        const product = productsById.getRequired(productId);

        return (
            <div className="activity-item item-history-merge-from">
                <div className="top-row">
                    <span className="bevicon bevico-swap-vert" />
                    <div className="title">
                        This product was merged into&nbsp;<a className="link" onClick={this.showProductItemCard}>{product.getName()}</a>.
                    </div>
                </div>
            </div>
        );
    }

    public showProductItemCard = () => {
        const mergedToProductIdsValues = Array.from(this.props.mergedToProductId.values());
        $(document).trigger('loadEditItemModal', {
            detail: {
                productId: mergedToProductIdsValues[0].getValue(),
            }
        });
    }
}
