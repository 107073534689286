import { LocationId } from 'api/Location/model/LocationId';
import { MassUnit } from 'api/Product/model/MassUnit';
import { Category } from 'api/Product/model/Category';
import { ProductMergeEvent } from 'api/Product/model/ProductMergeEvent';
import { OldPackaging } from 'api/Product/model/OldPackaging';
import { Packaging } from 'api/Product/model/Packaging';
import { PackagingId } from 'api/Product/model/PackagingId';
import { Product } from 'api/Product/model/Product';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import {
    IConversionsJSONObject,
    IMassJSON,
    IMergeEventJsonObject,
    IOldPackagingJSONObject,
    IPackagingDataJSONObject,
    IPackagingJSONObject, ICategoryJsonObject,
    IProductJSONObject,
    IWeightsByPackagingIdJSONObject, IQuantityInUnitJSONObject
} from 'api/Product/serializer/IProductJSONObject';
import { UserAccountUtils } from 'api/UserAccount/utils/UserAccountUtils';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export class ProductObjectToJSONSerializer {

    public getJSONProductFromProduct(
        product : Product,
        locationId : LocationId
    ) : IProductJSONObject {
        const packagingsAndMappings = product.getPackagingsAndMappings();        
        const conversions = packagingsAndMappings.getConversions();

        const conversionsJSONObject : IConversionsJSONObject = {};
        conversions.getPackagingIdConversions().forEach((value, packagingId) => {
            conversionsJSONObject[this.getProductQuantityUnit(packagingId)] = value;
        });

        conversions.getUnitOfMeasureConversions().forEach((value, unitOfMeasure) => {
            conversionsJSONObject[this.getProductQuantityUnit(unitOfMeasure)] = value;
        });

        const packagingDataJSONObject : IPackagingDataJSONObject = packagingsAndMappings.getPackagingData().map((packagingData) => {
            return {
                deleted: packagingData.deleted,
                is_active: packagingData.isActive,
                package: this.getPackagingJSON(packagingData.packaging),
            };
        });

        const weightsByPackagingIdJSONObject : IWeightsByPackagingIdJSONObject = {};
        product.getWeightsByPackagingId().forEach((packagingWeight, packagingId) => {
            weightsByPackagingIdJSONObject[packagingId.getValue()] = {
                empty_weight: this.getMassJSON(packagingWeight.getEmptyWeight()),
                full_weight: this.getMassJSON(packagingWeight.getFullWeight()),
            };
        });

        const categoryId = product.getNewProductCategoryId();
        const packageIdMappings : { [packagingIdValue : string] : IQuantityInUnitJSONObject } = {};
        const unitOfMeasureMappings : { [unitOfMeasureValue : string] : IQuantityInUnitJSONObject } = {};

        packagingsAndMappings.getMappings().getPackagingIdMappings().forEach((quantityInUnit, packagingId) => {
            packageIdMappings[packagingId.getValue()] = {
                quantity : quantityInUnit.getQuantity(),
                product_quantity_unit : this.getProductQuantityUnit(quantityInUnit.getUnit()),
            };
        });

        packagingsAndMappings.getMappings().getUnitOfMeasureMappings().forEach((quantityInUnit, unit) => {
            unitOfMeasureMappings[unit] = {
                quantity : quantityInUnit.getQuantity(),
                product_quantity_unit : this.getProductQuantityUnit(quantityInUnit.getUnit()),
            };
        });
        return {
            brand: product.getBrand(),
            name: product.getName(),
            conversions: conversionsJSONObject,
            packages: packagingDataJSONObject,
            package_mappings : {
                package_id_mappings: packageIdMappings,
                unit_of_measure_mappings: unitOfMeasureMappings
            },
            preferred_base_unit: this.getProductQuantityUnit(conversions.getBaseUnit()),
            preferred_reporting_unit: this.getProductQuantityUnit(product.getPreferredReportingUnit()),
            weights_by_package_id: weightsByPackagingIdJSONObject,
            product_category_id: product.getProductCategoryId(),
            new_product_category_id: categoryId ? categoryId.getValue() : null,
            product_type: product.getProductType(),
            price: {
                iso_4217_currency_code : 'USD',
                value : product.getUnitPrice().getDollarValue()
            },
            price_unit: product.getUnitPrice().getUnit(),
            deposit: {
                iso_4217_currency_code : 'USD',
                value : product.getDepositInDollars()
            },
            sku: product.getSku(),
            note: product.getNote(),
            gl_code: product.getGLCode(),
            retailer_id: locationId.getValue(),
            last_update_event: UserAccountUtils.getUserAccountIdAndTimestampJSONFromObject(product.getLastUpdateEvent()),
        };
    }

    public getJSONPackagingFromPackaging(
        packaging : OldPackaging
    ) : IOldPackagingJSONObject {
        const content = packaging.getContent();
        return {
            content: content === null ? null : this.getJSONPackagingFromPackaging(content),
            quantity_of_content: packaging.getQuantityOfContent(),
            unit: packaging.getUnit()
        };
    }

    public getPackagingJSON(
        packaging : Packaging
    ) : IPackagingJSONObject {
        const packageId = packaging.getPackagingId();
        const content = packaging.getContent();
        
        return {
            package_id: packageId === null ? null : this.getProductQuantityUnit(packageId),
            name: packaging.getName(),
            quantity_of_content: packaging.getQuantityOfContent(),
            content: content === null ? null : this.getPackagingJSON(content),
            unit: packaging.getUnit()
        };
    }

    public getMassJSON(
        mass : QuantityInUnit<MassUnit> | null
    ) : IMassJSON | null {
        if (mass === null) {
            return null;
        }

        return {
            unit_of_mass: mass.getUnit(),
            quantity: mass.getQuantity(),
        };
    }

    public getMergeEventJSON(
        mergeEvent : ProductMergeEvent | null
    ) : IMergeEventJsonObject | null {
        if (mergeEvent === null) {
            return null;
        }

        const mergeFromProductIds = mergeEvent.getMergedFromProductIds();
        const storageAreasAffected = mergeEvent.getAffectedStorageAreaIds();
        const salesItemsAffected = mergeEvent.getAffectedSalesItemIds();
        const cartItemsAffected = mergeEvent.getAffectedCartItems();

        const mergeFromProductIdsArray : Array<string> = [];
        const storageAreasAffectedArray : Array<string> = [];
        const salesItemAffectedArray : Array<string> = [];
        const cartItemAffectedArray : Array<string> = [];
        mergeFromProductIds.forEach((productId) => {
            mergeFromProductIdsArray.push(productId.getValue());
        });
        storageAreasAffected.forEach((storageAreaId) => {
            storageAreasAffectedArray.push(storageAreaId.getValue());
        });
        salesItemsAffected.forEach((salesItemId) => {
            salesItemAffectedArray.push(salesItemId.getValue());
        });
        cartItemsAffected.forEach((productId) => {
            cartItemAffectedArray.push(productId.getValue());
        });

        return {
            user_account_id_and_timestamp: UserAccountUtils.getUserAccountIdAndTimestampJSONFromObject(mergeEvent.getUserAccountIdAndTimestamp()),
            merged_to_product_id: mergeEvent.getMergedToProductId().getValue(),
            merged_from_product_ids: mergeFromProductIdsArray,
            sales_items_affected: salesItemAffectedArray,
            storage_areas_affected: storageAreasAffectedArray,
            cart_items_affected: cartItemAffectedArray,
        };
    }

    public getCategoryJson(
        category : Category | null
    ) : ICategoryJsonObject | null {
        if (category === null) {
            return null;
        }
        return {
            name: category.getName(),
            gl_code: category.getGlCode(),
            category_hash: category.getHash(),
            is_deleted: category.getIsDeleted(),
        };
    }

    public getProductQuantityUnit(
        productQuantityUnit : ProductQuantityUnit
    ) : string {
        if (productQuantityUnit instanceof PackagingId) {
            return productQuantityUnit.getValue();
        } else if (MassUnit.isMassUnit(productQuantityUnit) || VolumeUnit.isVolumeUnit(productQuantityUnit)) {
            return productQuantityUnit;
        }

        throw new RuntimeException('Unknown productQuantityUnit ' + productQuantityUnit);
    }
}
