import React from 'react';
import TransitionGroup from 'react-transition-group/TransitionGroup';

import { RenderOnlyFirstChild } from 'shared/components/RenderUtils/RenderOnlyFirstChild';
import { HeightTransitionGroupAnimationDiv } from 'shared/components/RowOptimizationWrapper/HeightTransitionGroupAnimationDiv';

// Note: Expects all rows within the wrapper to be the same height at any given time
// Might cause weird page jumping if this cosntraint is not followed
interface IRowOptimizationWrapperProps {
    numRows : number;
    rowFactory : (i : number) => JSX.Element;
    getRowContainer : () => (HTMLDivElement | undefined); // whichever element that's scrollable - if unsure, see windowScrollEventListener event.target
    isOpen : boolean;
}

export class RowOptimizationWrapper extends React.Component<IRowOptimizationWrapperProps, object> {

    private hasPendingRender : boolean;
    private watchForContainerHeightChanges : boolean;
    private computeParametersOnNextRender : boolean;
    private forceRerender : boolean;
    private rowHeight : null | number; // Expects all rows within the wrapper to be the same height at any given time
    private maxVisibleScreenSize : number;
    private maxNumToShow : number;
    private bufferSizeInPx : number;
    private boundingRectScrollOffset : number;
    private mostRecentlyCalculatedStartOffset : number;
    private renderedStartOffset : number;
    private startIndex : number;
    private endIndex : number;
    private divWrapper : HTMLDivElement | undefined;
    private currentRowContainerHeight : number;
    private currentDocumentBodyHeight : number;
    private heightCheckTimeoutId : number;

    public constructor(props : IRowOptimizationWrapperProps) {
        super(props);

        const defaultRowIndexesToRender = this.getDefaultRowIndexesToRender(props.isOpen, props.numRows);
        this.startIndex = defaultRowIndexesToRender.startIndex;
        this.endIndex = defaultRowIndexesToRender.endIndex;

        this.hasPendingRender = false;

        this.watchForContainerHeightChanges = true;
        this.computeParametersOnNextRender = true;
        this.forceRerender = false;
        this.rowHeight = null;
        this.maxVisibleScreenSize = document.documentElement.clientHeight;
        this.mostRecentlyCalculatedStartOffset = window.pageYOffset;
        this.renderedStartOffset = window.pageYOffset;
        this.maxNumToShow = 25;
        this.bufferSizeInPx = 50;
        this.boundingRectScrollOffset = 0;
        this.currentRowContainerHeight = 0;
        this.currentDocumentBodyHeight = document.body.clientHeight;
        this.heightCheckTimeoutId = -1;
    }

    public componentDidMount() {
        window.addEventListener('scroll', this.windowScrollEventListener, true);
        window.addEventListener('resize', this.windowResizeEventListener);

        const checkContainerHeights = () => {
            if (this.watchForContainerHeightChanges && !this.hasPendingRender) {
                try {
                    const rowContainer = this.props.getRowContainer();
                    if (rowContainer) {
                        const newRowContainerHeight = rowContainer.scrollHeight;
                        const newDocumentBodyHeight = document.body.clientHeight;

                        if ((newRowContainerHeight !== this.currentRowContainerHeight) || (newDocumentBodyHeight !== this.currentDocumentBodyHeight)) {
                            this.currentRowContainerHeight = newRowContainerHeight;
                            this.currentDocumentBodyHeight = newDocumentBodyHeight;

                            this.computeParametersOnNextRender = true;
                            this.forceRerender = true;

                            // Preventing component from rendering multiple times during animation of height changes
                            window.clearTimeout(this.heightCheckTimeoutId);
                            this.heightCheckTimeoutId = window.setTimeout(() => {
                                window.requestAnimationFrame(() => {
                                    if (this.watchForContainerHeightChanges) {
                                        this.rerenderIfNeeded();
                                    }
                                });
                            }, 200);
                        }
                    }
                } catch (error) {
                    // Do nothing. The parent has just not mounted yet
                }
            }

            if (this.watchForContainerHeightChanges) {
                window.setTimeout(() => {
                    window.requestAnimationFrame(checkContainerHeights);
                }, 300);
            }
        };

        checkContainerHeights();
    }

    public componentWillUnmount() {
        window.clearTimeout(this.heightCheckTimeoutId);
        window.removeEventListener('scroll', this.windowScrollEventListener, true);
        window.removeEventListener('resize', this.windowResizeEventListener);
        this.watchForContainerHeightChanges = false;
    }

    public componentWillReceiveProps(nextProps : IRowOptimizationWrapperProps) {
        if (nextProps.isOpen && (
                (nextProps.numRows !== this.props.numRows) ||
                (nextProps.rowFactory !== this.props.rowFactory) ||
                (nextProps.getRowContainer !== this.props.getRowContainer) ||
                !this.props.isOpen
            )
        ) {
            this.forceRerender = true;
            this.computeParametersOnNextRender = true;
        }

        if (nextProps.isOpen !== this.props.isOpen) {
            this.forceRerender = true;
        }
    }

    public shouldComponentUpdate(nextProps : IRowOptimizationWrapperProps) {
        if (this.computeParametersOnNextRender || (!this.rowHeight && this.props.isOpen)) {
            if (this.divWrapper && this.divWrapper.children.length) {
                const heightCount = new Map<number, number>();
                let maxCount = 0;

                for (let i = 0; i < this.divWrapper.children.length; i++) {
                    const childElement = this.divWrapper.children.item(i);

                    if (childElement) {
                        const childHeight = Math.max((childElement as HTMLElement).offsetHeight, 1);

                        const heightNewCount = (heightCount.get(childHeight) || 0) + 1;
                        heightCount.set(childHeight, heightNewCount);

                        if (heightNewCount > maxCount) {
                            maxCount = heightNewCount;
                        }
                    }
                }

                const modeHeights : Array<number> = [];
                heightCount.forEach((count, height) => {
                    if (count === maxCount) {
                        modeHeights.push(height);
                    }
                });

                this.rowHeight = Math.max(...modeHeights);
                this.maxVisibleScreenSize = document.documentElement.clientHeight;
            }
        }

        const rowContainer = nextProps.getRowContainer();
        if (rowContainer && this.rowHeight && this.divWrapper) {
            const windowPageYOffset = window.pageYOffset;

            if (this.computeParametersOnNextRender) {
                this.boundingRectScrollOffset = rowContainer.getBoundingClientRect().top + windowPageYOffset;
                this.bufferSizeInPx = this.rowHeight * 2;
                this.maxNumToShow = Math.ceil((this.maxVisibleScreenSize + (2 * this.bufferSizeInPx)) / this.rowHeight) + 1;

                const divWrapperTopOffset = this.divWrapper.getBoundingClientRect().top + rowContainer.scrollTop - rowContainer.getBoundingClientRect().top;
                this.bufferSizeInPx = this.bufferSizeInPx + divWrapperTopOffset;

                this.computeParametersOnNextRender = false;
            }

            const boundingClientRectTop = this.boundingRectScrollOffset - windowPageYOffset;
            this.mostRecentlyCalculatedStartOffset = rowContainer.scrollTop - this.bufferSizeInPx - boundingClientRectTop;

            if (!this.forceRerender) {
                const offsetDifference = this.mostRecentlyCalculatedStartOffset - this.renderedStartOffset;
                const rowIndexesToRender = this.getRowIndexesToRender(this.mostRecentlyCalculatedStartOffset, nextProps.numRows, nextProps.isOpen);
                if ((Math.abs(offsetDifference) <= this.rowHeight) || ((rowIndexesToRender.startIndex === this.startIndex) && (rowIndexesToRender.endIndex === this.endIndex))) {
                    this.hasPendingRender = false;
                    return false;
                }
            }
        }

        return true;
    }

    public render() {
        const {
            numRows,
            rowFactory,
            isOpen,
        } = this.props;

        this.renderedStartOffset = this.mostRecentlyCalculatedStartOffset;
        const rowIndexesToRender = this.getRowIndexesToRender(this.mostRecentlyCalculatedStartOffset, numRows, isOpen);
        this.startIndex = rowIndexesToRender.startIndex;
        this.endIndex = rowIndexesToRender.endIndex;

        const renderedRows : Array<JSX.Element> = [];
        for (let i = this.startIndex; i < this.endIndex; i++) {
            renderedRows.push(rowFactory(i));
        }

        this.forceRerender = false;

        if (!this.rowHeight) {
            if (isOpen) {
                this.forceRerender = true;
            }

            return (
                <div
                    ref={ this.divWrapperRef }
                    style={ isOpen ? undefined : { overflow : 'hidden' } }
                >
                    { renderedRows }
                </div>
            );
        }

        // TODO Cheezy : Removed scroll speed estimate logic
        // TODO Cheezy : Figure out why "transform: translateZ(0);" hardware acceleration isn't working on Edge
        return (
            <TransitionGroup component={ RenderOnlyFirstChild }>
                { isOpen &&
                    <HeightTransitionGroupAnimationDiv
                        divWrapperRef={ this.divWrapperRef }
                        style={ { height : `${this.rowHeight * numRows}px`, paddingTop : `${this.rowHeight * this.startIndex}px` } }
                    >
                        { renderedRows }
                    </HeightTransitionGroupAnimationDiv>
                }
            </TransitionGroup>
        );
    }

    public componentDidUpdate() {
        if (this.forceRerender) {
            this.rerenderIfNeeded();
        } else {
            this.hasPendingRender = false;
        }
    }

    private rerenderIfNeeded = () => {
        if (!this.divWrapper) {
            return;
        }

        if (!this.hasPendingRender || this.forceRerender) {
            window.requestAnimationFrame(() => {
                if (!this.divWrapper) {
                    return;
                }

                this.setState({});
            });
            this.hasPendingRender = true;
        }
    }

    private windowScrollEventListener = (event : Event) => {
        if ((event.target === document) || (event.target === document.body) || (event.target === this.props.getRowContainer())) {
            this.rerenderIfNeeded();
        }
    }

    private windowResizeEventListener = () => {
        this.forceRerender = true;
        this.computeParametersOnNextRender = true;
        this.rerenderIfNeeded();
    }

    private divWrapperRef = (divWrapper : HTMLDivElement) => {
        this.divWrapper = divWrapper;
    }

    private getRowIndexesToRender = (startOffset : number, numRows : number, isOpen : boolean) : { startIndex : number, endIndex : number } => {
        if (!isOpen) {
            return {
                startIndex: 0,
                endIndex: 0,
            };
        }

        if (this.rowHeight) {
            const baseIndex = Math.min(Math.floor((startOffset * 1.0) / this.rowHeight), numRows);

            return {
                startIndex: Math.max(baseIndex, 0),
                endIndex: Math.min(Math.max(baseIndex + this.maxNumToShow, 0), numRows),
            };
        }

        return this.getDefaultRowIndexesToRender(isOpen, numRows);
    }

    private getDefaultRowIndexesToRender = (isOpen : boolean, numRows : number) : { startIndex : number, endIndex : number } => {
        return {
            startIndex: 0,
            endIndex: isOpen ? Math.min(25, numRows) : 0,
        };
    }
}
