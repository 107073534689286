import React from 'react';

import { ICoordinate } from 'shared/models/Charts/ICoordinate';
import { Orientation } from 'shared/models/Charts/Orientation';
import { Shape } from 'shared/models/Charts/Shape';
import { ITooltipItem } from 'shared/models/Charts/Tooltip/ITooltipItem';
import { ITooltipItemShape } from 'shared/models/Charts/Tooltip/ITooltipItemShape';

import { TooltipItem } from 'shared/components/Charts/Tooltip/TooltipItem';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export interface ITooltipProps {
    readonly position : ICoordinate;
    readonly orientation : Orientation;
    readonly onDismissal : (() => void) | null;
    readonly headerValue : string;
    readonly body : ReadonlyArray<ITooltipItem>;
    readonly footer : ITooltipItem | null;
    readonly shape : Shape;
    readonly referenceFunction : (element : HTMLDivElement) => void;
}

export class Tooltip extends React.Component<ITooltipProps, object> {
    private readonly classNamePrefix = 'chart-tooltip';

    public render() {
        const {
            position,
            orientation,
            onDismissal,
            referenceFunction,
        } = this.props;

        let className = this.classNamePrefix;
        switch (orientation) {
            case Orientation.HORIZONTAL: {
                className += ` horizontal-${ this.classNamePrefix }`;
                break;
            }
            case Orientation.VERTICAL: {
                className += ` vertical-${ this.classNamePrefix }`;
                break;
            }
            default: {
                throw new RuntimeException('unexpected orientation value');
            }
        }

        const style : any = {
            left: `${ position.x }px`,
            top: `${ position.y }px`,
            pointerEvents: 'none',
        };

        if (onDismissal !== null) {
            style.pointerEvents = 'auto';
        }

        return (
            <div
                className={ className }
                style={ style }
                ref={ referenceFunction }
            >
                { this.createDismissButton() }
                { this.createHeader() }
                { this.createBody() }
                { this.createFooter() }
            </div>
        );
    }

    private readonly createDismissButton = () : JSX.Element | void => {
        const {
            onDismissal,
        } = this.props;

        if (onDismissal === null) {
            return;
        }

        const className : string = `${ this.classNamePrefix }-dismiss-button`;

        return (
            <span onClick={ onDismissal } className={ className + ' bevicon bevico-close' } />
        );
    }

    private readonly createHeader = () : JSX.Element | void => {
        const {
            headerValue,
        } = this.props;

        if (headerValue.length === 0) {
            return;
        }

        const className : string = this.classNamePrefix + '-header';

        return (
            <div className={ className }>
                { headerValue }
            </div>
        );
    }

    private readonly createBody = () : JSX.Element | void => {
        const {
            body,
            shape,
        } = this.props;

        if (body.length === 0) {
            return;
        }

        const className : string = this.classNamePrefix + '-body';

        const tooltipItems : ReadonlyArray<JSX.Element> = body.map(
            (tooltipItem : ITooltipItem, index : number) => {
                let tooltipItemShape : ITooltipItemShape | null;
                if (tooltipItem.color === null) {
                    tooltipItemShape = null;
                } else {
                    tooltipItemShape = {
                        shape,
                        color: tooltipItem.color,
                    };
                }

                return (
                    <TooltipItem
                        key={ index }

                        name={ tooltipItem.name }
                        value={ tooltipItem.value }
                        hasFocus={ tooltipItem.hasFocus }
                        tooltipItemShape={ tooltipItemShape }
                    />
                );
            }
        );

        return (
            <div className={ className }>
                { tooltipItems }
            </div>
        );
    }

    private readonly createFooter = () : JSX.Element | void => {
        const {
            footer,
            shape,
        } = this.props;

        if (footer === null) {
            return;
        }

        const className : string = this.classNamePrefix + '-footer';

        let tooltipItemShape : ITooltipItemShape | null;
        if (footer.color === null) {
            tooltipItemShape = null;
        } else {
            tooltipItemShape = {
                shape,
                color: footer.color,
            };
        }

        return (
            <div className={ className }>
                <TooltipItem
                    name={ footer.name }
                    value={ footer.value }
                    hasFocus={ footer.hasFocus }
                    tooltipItemShape={ tooltipItemShape }
                />
            </div>
        );
    }
}
