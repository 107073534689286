import React from 'react';

import { Button } from 'shared/components/Button';

import './SlimCreate.scss';

export interface ISlimCreateProps {
    header : string;
    content : React.ReactNode;
    hasMoreOptionsButton : boolean;
    saveInProgress : boolean;
    handleSaveButton : () => void;
    handleCancelButton : () => void;
    handleMoreOptionsButton : () => void;
}

export class SlimCreate extends React.Component<ISlimCreateProps, object> {

    public render() {
        const {
            header,
            content,
            handleSaveButton,
            handleCancelButton,
            handleMoreOptionsButton,
            hasMoreOptionsButton,
            saveInProgress,
        } = this.props;

        return (
            <div
                className="slim-create"
            >
                <div className="slim-create-scroll-container">
                    <h5 className="reset-dark-styles slim-create-header">{ header }</h5>
                    <div className="slim-create-body">{ content }</div>
                </div>
                <div className="slim-create-footer">
                    <div className="slim-create-footer-right">
                        { hasMoreOptionsButton &&
                            <Button
                                buttonClassName="reset-dark-styles flat primary"
                                isDisabled={ saveInProgress }
                                isLoading={ false }
                                onClick={ handleMoreOptionsButton }
                            >
                                    More Options...
                            </Button>
                        }
                    </div>
                    <div className="slim-create-footer-left">
                        <Button
                            buttonClassName="reset-dark-styles flat"
                            isDisabled={ saveInProgress }
                            isLoading={ false }
                            onClick={ handleCancelButton }
                        >
                                Cancel
                        </Button>
                        <Button
                            buttonClassName="reset-dark-styles primary"
                            isDisabled={ false }
                            isLoading={ saveInProgress }
                            onClick={ handleSaveButton }
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
