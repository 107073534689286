import * as React from 'react';

import { Dialog } from 'shared/components/Dialog';

export interface IProcessInvoiceUploadConfirmationDialogProps {
    readonly onConfirmProcessInvoiceUploadClick : () => void;
    readonly onCancelClick : () => void;
    readonly isSubmitting : boolean;
}

export class ProcessInvoiceUploadConfirmationDialog extends React.Component<IProcessInvoiceUploadConfirmationDialogProps, object> {
    public render() {
        const {
            onConfirmProcessInvoiceUploadClick,
            onCancelClick,
            isSubmitting,
        } = this.props;

        return (
            <Dialog
                className="process-invoice-upload-confirmation-dialog"
                buttons={ [
                    {
                        onClick: onCancelClick,
                        classes: 'flat',
                        isDisabled: false,
                        isLoading: false,
                        children: 'Cancel',
                    },
                    {
                        onClick: onConfirmProcessInvoiceUploadClick,
                        classes: 'primary flat',
                        isDisabled: isSubmitting,
                        isLoading: isSubmitting,
                        children: 'Process Invoice',
                    },
                ] }
                headerText="Process Invoice"
            >
                <p>
                    Are you sure you want us to process this invoice?
                </p>
            </Dialog>
        );
    }
}
