import React from 'react';

import { Product } from 'api/Product/model/Product';
import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductId } from 'api/Product/model/ProductId';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';

import { Button } from 'shared/components/Button';
import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { InfoPopover } from 'shared/components/Product/InfoPopover';
import { Select2DropdownMenuTheme } from 'shared/components/Select2Dropdown/GenericSelect2DropdownMenu';
import { Select2DropdownMenu } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { ValidationInput, ValidationInputTheme } from 'shared/components/ValidationInput';
import { FormatMonetaryValueWithCents } from 'shared/utils/FormatMonetaryValue';

import { IProductIngredientRowInfo, ProductIngredientRowFormFieldName } from '../reducers/reducers';

import '../css/AddedIngredients.scss';
import { productCostUtils } from 'api/Product/utils/productCostUtils';
import { StringValueMap } from 'api/Core/StringValueMap';

export interface IProductIngredientRowProps {
    product : Product;
    productCost : ProductCost;
    ingredientRowInfo : IProductIngredientRowInfo;
    handleOnRemoveIngredientClick : (ingredientId : ProductId) => void;
    onFormFieldChange : (productId : ProductId, formField : ProductIngredientRowFormFieldName, value : string) => void;
    onFormFieldBlur : (productId : ProductId, fieldName : ProductIngredientRowFormFieldName, value : string) => void;
    ingredientCostPerQuantity : number | null;
    productInfoIsShown : boolean;
    handleSetProductInfoPopoverIsShown : (isShown : boolean) => void;
    onProductInfoFullDetailsClick : ((productId : ProductId) => void) | null;
    productCostsByProductId : StringValueMap<ProductId, ProductCost>;
    productId : ProductId;
}
export class ProductIngredientRow extends React.Component<IProductIngredientRowProps, object> {
    public render() {
        const {
            product,
            ingredientRowInfo,
            ingredientCostPerQuantity,
            productInfoIsShown,
            productCost,
            handleSetProductInfoPopoverIsShown,
            onProductInfoFullDetailsClick,
            productCostsByProductId,
            productId
        } = this.props;

        const unitsForDropdown = PackagingUtils.getAvailableUnitOptionsAndLabelNames(product.getPackagingsAndMappings(), true);
        let ingredientQuantityUnitSelectedOption : IOption | null = null;

        if (ingredientRowInfo.formInfo.unit.value !== '') {
            unitsForDropdown.forEach((unitOptionsAndLabelName) => {
                unitOptionsAndLabelName[1].forEach((unitOption : IOption) => {
                    if (unitOption.value === ingredientRowInfo.formInfo.unit.value) {
                        ingredientQuantityUnitSelectedOption = unitOption;
                    }
                });
            });
        }

        const baseUnit = product.getPackagingsAndMappings().getConversions().getBaseUnit();
        const costDisplayInfo = productCostUtils.getCostInUnit(productCost, baseUnit, product.getPackagingsAndMappings());
        const productQuantityUnitDisplayText = PackagingUtils.getPackagingDisplayTextForProductQuantityUnit(product.getPackagingsAndMappings(), baseUnit, false);
        const ingredientCost = `${ FormatMonetaryValueWithCents(costDisplayInfo) } / ${ productQuantityUnitDisplayText }`;
        return (
            <li className="product-ingredient-row ingredient-row">
                <Button
                    buttonClassName="icon flat remove-ingredient-btn"
                    isDisabled={ false }
                    isLoading={ false }
                    onClick={ this.handleOnRemoveIngredientClick }
                >
                    <span className="bevicon bevico-remove-circle-outline"/>
                </Button>
                <div className="ingredient-information-container">
                    <div className="ingredient-information">
                        <InfoPopover
                            vendorIsHidden={ true } // not using this as a future pattern. Only being used as a temporary solution for hiding vendor.
                            product={ product }
                            distributor={ null }
                            productInfoIsShown={ productInfoIsShown }
                            setProductInfoIsShown={ handleSetProductInfoPopoverIsShown }
                            onFullDetailsClick={ onProductInfoFullDetailsClick !== null ? this.onProductInfoFullDetailsClick : null }
                            productCostsByProductId={ productCostsByProductId }
                            productId={ productId }
                        >
                            <div className="product-ingredient-brand-and-name">
                                <span className="product-ingredient-brand">{ product.getBrand() } </span><strong className="product-ingredient-name">{ product.getName() }</strong>
                            </div>
                        </InfoPopover>
                        <small className="ingredient-details">
                            <span>Ingredient</span>
                            <span>&nbsp;&bull;&nbsp;</span>
                            <span>{ ingredientCost }</span>
                        </small>
                    </div>
                    <div className="ingredient-quantity">
                        <div className="ingredient-quantity-input-group input-group">
                            <ValidationInput
                                theme={ ValidationInputTheme.BasicInverted }
                                type="text"
                                step="any"
                                label={ null }
                                hintText=""
                                value={ ingredientRowInfo.formInfo.quantity.value }
                                autoFocus={ false }
                                autoComplete={ null }
                                isValid={ ingredientRowInfo.formInfo.quantity.isValid }
                                isDisabled={ false }
                                errorMessage={ ingredientRowInfo.formInfo.quantity.errorMessage }
                                inputClassName=""
                                handleEnterClick={ this.onProductRowQuantityAmountEnterOrBlur }
                                handleChange={ this.onProductRowQuantityAmountChange }
                                handleBlur={ this.onProductRowQuantityAmountEnterOrBlur }
                                handleFocus={ this.doNothing }
                            />
                            <Select2DropdownMenu
                                theme={ Select2DropdownMenuTheme.BasicInverted }
                                onOptionSelect={ this.handleOnSelectProductRowUnit }
                                sortedOptionsAndLabelName={ unitsForDropdown }
                                selectedOption={ ingredientQuantityUnitSelectedOption }
                                placeholderText="Select Unit"
                                hasCreateCustomOption={ false }
                                createCustomOption={ this.doNothing }
                                buttonShouldDisplaySelectedLabel={ true }
                                emptyState={ null }
                                isSearchable={ false }
                                selectedOptionHeaderShown={ false }
                                customOptionGroupLabel={ null }
                                createCustomOptionButtonLabel={ null }
                            />
                        </div>
                        <span className="unit-cost">Cost: { FormatMonetaryValueWithCents(ingredientCostPerQuantity) }</span>
                    </div>
                </div>
            </li>
        );
    }

    private readonly doNothing = () => {
        // pass
    }

    private readonly handleOnRemoveIngredientClick = () => {
        const {
            ingredientRowInfo,
            handleOnRemoveIngredientClick,
        } = this.props;

        handleOnRemoveIngredientClick(ingredientRowInfo.ingredientId);
    }

    private readonly onProductRowQuantityAmountChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const {
            onFormFieldChange,
            ingredientRowInfo,
        } = this.props;

        onFormFieldChange(ingredientRowInfo.ingredientId, 'quantity', event.target.value);
    }

    private readonly onProductRowQuantityAmountEnterOrBlur = (event : React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
        const {
            onFormFieldBlur,
            ingredientRowInfo,
        } = this.props;

        onFormFieldBlur(ingredientRowInfo.ingredientId, 'quantity', event.currentTarget.value);
    }

    private readonly handleOnSelectProductRowUnit = (selectedUnitValue : string) => {
        const {
            onFormFieldBlur,
            ingredientRowInfo,
        } = this.props;

        onFormFieldBlur(ingredientRowInfo.ingredientId, 'unit', selectedUnitValue);
    }

    private readonly onProductInfoFullDetailsClick = () => {
        if (this.props.onProductInfoFullDetailsClick !== null) {
            this.props.onProductInfoFullDetailsClick(this.props.ingredientRowInfo.ingredientId);
        }
    }
}
