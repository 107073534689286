import { HexColor } from 'shared/models/HexColor';

// GridLine
export const gridLineColor = new HexColor('#000000');
export const gridLineOpacity = 0.3;

// LegendItem
export const legendItemSquareLengthInPixels = 16;

// MouseLine
export const mouseLineLabelBackgroundColor = new HexColor('#eeeeee');
export const mouseLineLabelPaddingInPixels = 5;

export const mouseLineColor = new HexColor('#000000');
export const mouseLineOpacity = 1;
export const mouseLineStrokeDasharray = '8, 5';

// TooltipItem
export const tooltipItemSquareLengthInPixels = 16;

// utils
export const idealNumberOfGridLines = 10;
export const minTooltipDistanceFromScreenEdgeInPixels = 10;
