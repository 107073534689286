//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';
import CorePrimitivesModel from './core_primitives_Model_types';
import CoreTimeModel from './core_time_Model_types';



var AccountsModel = {};
export default AccountsModel;
AccountsModel.AdminPermission = {
  '0' : 'CREATE_OWN_SESSION',
  'CREATE_OWN_SESSION' : 0,
  '1' : 'READ_OWN_ACCOUNT',
  'READ_OWN_ACCOUNT' : 1,
  '2' : 'WRITE_OWN_ACCOUNT',
  'WRITE_OWN_ACCOUNT' : 2,
  '3' : 'WRITE_OWN_PASSWORD',
  'WRITE_OWN_PASSWORD' : 3,
  '4' : 'READ_ADMIN_ACCOUNT',
  'READ_ADMIN_ACCOUNT' : 4,
  '5' : 'WRITE_ADMIN_ACCOUNT',
  'WRITE_ADMIN_ACCOUNT' : 5,
  '6' : 'READ_USER_ACCOUNT',
  'READ_USER_ACCOUNT' : 6,
  '7' : 'WRITE_USER_ACCOUNT',
  'WRITE_USER_ACCOUNT' : 7
};
AccountsModel.UserPermission = {
  '0' : 'CREATE_OWN_SESSION',
  'CREATE_OWN_SESSION' : 0,
  '1' : 'READ_OWN_ACCOUNT',
  'READ_OWN_ACCOUNT' : 1,
  '2' : 'WRITE_OWN_ACCOUNT',
  'WRITE_OWN_ACCOUNT' : 2,
  '3' : 'WRITE_OWN_PASSWORD',
  'WRITE_OWN_PASSWORD' : 3
};
AccountsModel.OptionalPhoneNumber = function(args) {
  this.phoneNumber = null;
  if (args) {
    if (args.phoneNumber !== undefined && args.phoneNumber !== null) {
      this.phoneNumber = args.phoneNumber;
    }
  }
};
AccountsModel.OptionalPhoneNumber.prototype = {};
AccountsModel.OptionalPhoneNumber.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.phoneNumber = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AccountsModel.OptionalPhoneNumber.prototype.write = function(output) {
  output.writeStructBegin('OptionalPhoneNumber');
  if (this.phoneNumber !== null && this.phoneNumber !== undefined) {
    output.writeFieldBegin('phoneNumber', Thrift.Type.STRING, 1);
    output.writeString(this.phoneNumber);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

