import moment from 'moment-timezone';

import { StringValueMap } from 'api/Core/StringValueMap';
import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { UserAccountIdAndTimestamp } from 'api/UserAccount/model/UserAccountIdAndTimestamp';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export class PrepEvent {
    constructor (
        private readonly prepEventTime : moment.Moment,
        private readonly creationMetadata : UserAccountIdAndTimestamp,
        private readonly lastEditedMetadata : UserAccountIdAndTimestamp | null,
        private readonly deletionMetadata : UserAccountIdAndTimestamp | null,
        private readonly note : string,
        private readonly type : string,
        private readonly inputQuantityOfProductByProductId : StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>>,
        private readonly outputQuantityOfProductByProductId : StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>>,
        private readonly outputPriceByProductId : StringValueMap<ProductId, ProductCost>,
    ) {
        outputQuantityOfProductByProductId.forEach((quantityOfProduct, productId) => {
            if (!outputPriceByProductId.has(productId)) {
                throw new RuntimeException(`outputQuantityOfProductByProductId has productId with no price: '${ productId.getValue() }'`);
            }
        });

        if (outputPriceByProductId.size !== outputQuantityOfProductByProductId.size) {
            throw new RuntimeException(`outputPriceByProductId has products that do not exist in outputQuantityOfProductByProductId`);
        }
    }

    public getPrepEventTime() : moment.Moment {
        return this.prepEventTime;
    }

    public getCreationMetadata() : UserAccountIdAndTimestamp {
        return this.creationMetadata;
    }

    public getLastEditedMetadata() : UserAccountIdAndTimestamp | null {
        return this.lastEditedMetadata;
    }

    public getDeletionMetadata() : UserAccountIdAndTimestamp | null {
        return this.deletionMetadata;
    }

    public getNote() : string {
        return this.note;
    }

    public getType() : string {
        return this.type;
    }

    public getInputQuantityOfProductByProductId() : StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>> {
        return this.inputQuantityOfProductByProductId;
    }

    public getOutputQuantityOfProductByProductId() : StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>> {
        return this.outputQuantityOfProductByProductId;
    }

    public getOutputPriceByProductId() : StringValueMap<ProductId, ProductCost> {
        return this.outputPriceByProductId;
    }
}
