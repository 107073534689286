//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';
import CoreTimeModel from './core_time_Model_types';
import LocationModel from './location_Model_types';
import ProductModel from './product_Model_types';
import UserAccountsModel from './accounts_user_Model_types';



var PrepEventModel = {};
export default PrepEventModel;
PrepEventModel.PrepEventId = function(args) {
  this.value = null;
  if (args) {
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field value is unset!');
    }
  }
};
PrepEventModel.PrepEventId.prototype = {};
PrepEventModel.PrepEventId.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PrepEventModel.PrepEventId.prototype.write = function(output) {
  output.writeStructBegin('PrepEventId');
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 1);
    output.writeString(this.value);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PrepEventModel.PrepEvent = function(args) {
  this.prepEventTime = null;
  this.locationId = null;
  this.creationMetadata = null;
  this.lastEditedMetadata = null;
  this.deletionMetadata = null;
  this.note = null;
  this.type = null;
  this.inputQuantityOfProductByProductId = null;
  this.outputQuantityOfProductByProductId = null;
  this.outputPriceByProductId = null;
  if (args) {
    if (args.prepEventTime !== undefined && args.prepEventTime !== null) {
      this.prepEventTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.prepEventTime);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field prepEventTime is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.creationMetadata !== undefined && args.creationMetadata !== null) {
      this.creationMetadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp(args.creationMetadata);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field creationMetadata is unset!');
    }
    if (args.lastEditedMetadata !== undefined && args.lastEditedMetadata !== null) {
      this.lastEditedMetadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp(args.lastEditedMetadata);
    }
    if (args.deletionMetadata !== undefined && args.deletionMetadata !== null) {
      this.deletionMetadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp(args.deletionMetadata);
    }
    if (args.note !== undefined && args.note !== null) {
      this.note = args.note;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field note is unset!');
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field type is unset!');
    }
    if (args.inputQuantityOfProductByProductId !== undefined && args.inputQuantityOfProductByProductId !== null) {
      this.inputQuantityOfProductByProductId = Thrift.copyList(args.inputQuantityOfProductByProductId, [ProductModel.ProductIdAndQuantityOfProduct]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inputQuantityOfProductByProductId is unset!');
    }
    if (args.outputQuantityOfProductByProductId !== undefined && args.outputQuantityOfProductByProductId !== null) {
      this.outputQuantityOfProductByProductId = Thrift.copyList(args.outputQuantityOfProductByProductId, [ProductModel.ProductIdAndQuantityOfProduct]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field outputQuantityOfProductByProductId is unset!');
    }
    if (args.outputPriceByProductId !== undefined && args.outputPriceByProductId !== null) {
      this.outputPriceByProductId = Thrift.copyList(args.outputPriceByProductId, [ProductModel.ProductIdAndCost]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field outputPriceByProductId is unset!');
    }
  }
};
PrepEventModel.PrepEvent.prototype = {};
PrepEventModel.PrepEvent.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.prepEventTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.prepEventTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.creationMetadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp();
        this.creationMetadata.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.lastEditedMetadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp();
        this.lastEditedMetadata.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.deletionMetadata = new UserAccountsModel.UserAccountIdentifierAndTimestamp();
        this.deletionMetadata.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.note = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.type = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.inputQuantityOfProductByProductId = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new ProductModel.ProductIdAndQuantityOfProduct();
          elem6.read(input);
          this.inputQuantityOfProductByProductId.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.LIST) {
        var _size7 = 0;
        var _rtmp311;
        this.outputQuantityOfProductByProductId = [];
        var _etype10 = 0;
        _rtmp311 = input.readListBegin();
        _etype10 = _rtmp311.etype;
        _size7 = _rtmp311.size;
        for (var _i12 = 0; _i12 < _size7; ++_i12)
        {
          var elem13 = null;
          elem13 = new ProductModel.ProductIdAndQuantityOfProduct();
          elem13.read(input);
          this.outputQuantityOfProductByProductId.push(elem13);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.LIST) {
        var _size14 = 0;
        var _rtmp318;
        this.outputPriceByProductId = [];
        var _etype17 = 0;
        _rtmp318 = input.readListBegin();
        _etype17 = _rtmp318.etype;
        _size14 = _rtmp318.size;
        for (var _i19 = 0; _i19 < _size14; ++_i19)
        {
          var elem20 = null;
          elem20 = new ProductModel.ProductIdAndCost();
          elem20.read(input);
          this.outputPriceByProductId.push(elem20);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PrepEventModel.PrepEvent.prototype.write = function(output) {
  output.writeStructBegin('PrepEvent');
  if (this.prepEventTime !== null && this.prepEventTime !== undefined) {
    output.writeFieldBegin('prepEventTime', Thrift.Type.STRUCT, 1);
    this.prepEventTime.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.creationMetadata !== null && this.creationMetadata !== undefined) {
    output.writeFieldBegin('creationMetadata', Thrift.Type.STRUCT, 3);
    this.creationMetadata.write(output);
    output.writeFieldEnd();
  }
  if (this.lastEditedMetadata !== null && this.lastEditedMetadata !== undefined) {
    output.writeFieldBegin('lastEditedMetadata', Thrift.Type.STRUCT, 4);
    this.lastEditedMetadata.write(output);
    output.writeFieldEnd();
  }
  if (this.deletionMetadata !== null && this.deletionMetadata !== undefined) {
    output.writeFieldBegin('deletionMetadata', Thrift.Type.STRUCT, 5);
    this.deletionMetadata.write(output);
    output.writeFieldEnd();
  }
  if (this.note !== null && this.note !== undefined) {
    output.writeFieldBegin('note', Thrift.Type.STRING, 6);
    output.writeString(this.note);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.STRING, 7);
    output.writeString(this.type);
    output.writeFieldEnd();
  }
  if (this.inputQuantityOfProductByProductId !== null && this.inputQuantityOfProductByProductId !== undefined) {
    output.writeFieldBegin('inputQuantityOfProductByProductId', Thrift.Type.LIST, 8);
    output.writeListBegin(Thrift.Type.STRUCT, this.inputQuantityOfProductByProductId.length);
    for (var iter21 in this.inputQuantityOfProductByProductId)
    {
      if (this.inputQuantityOfProductByProductId.hasOwnProperty(iter21))
      {
        iter21 = this.inputQuantityOfProductByProductId[iter21];
        iter21.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.outputQuantityOfProductByProductId !== null && this.outputQuantityOfProductByProductId !== undefined) {
    output.writeFieldBegin('outputQuantityOfProductByProductId', Thrift.Type.LIST, 9);
    output.writeListBegin(Thrift.Type.STRUCT, this.outputQuantityOfProductByProductId.length);
    for (var iter22 in this.outputQuantityOfProductByProductId)
    {
      if (this.outputQuantityOfProductByProductId.hasOwnProperty(iter22))
      {
        iter22 = this.outputQuantityOfProductByProductId[iter22];
        iter22.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.outputPriceByProductId !== null && this.outputPriceByProductId !== undefined) {
    output.writeFieldBegin('outputPriceByProductId', Thrift.Type.LIST, 10);
    output.writeListBegin(Thrift.Type.STRUCT, this.outputPriceByProductId.length);
    for (var iter23 in this.outputPriceByProductId)
    {
      if (this.outputPriceByProductId.hasOwnProperty(iter23))
      {
        iter23 = this.outputPriceByProductId[iter23];
        iter23.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

PrepEventModel.PrepEventAndId = function(args) {
  this.prepEventId = null;
  this.prepEvent = null;
  if (args) {
    if (args.prepEventId !== undefined && args.prepEventId !== null) {
      this.prepEventId = new PrepEventModel.PrepEventId(args.prepEventId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field prepEventId is unset!');
    }
    if (args.prepEvent !== undefined && args.prepEvent !== null) {
      this.prepEvent = new PrepEventModel.PrepEvent(args.prepEvent);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field prepEvent is unset!');
    }
  }
};
PrepEventModel.PrepEventAndId.prototype = {};
PrepEventModel.PrepEventAndId.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.prepEventId = new PrepEventModel.PrepEventId();
        this.prepEventId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.prepEvent = new PrepEventModel.PrepEvent();
        this.prepEvent.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PrepEventModel.PrepEventAndId.prototype.write = function(output) {
  output.writeStructBegin('PrepEventAndId');
  if (this.prepEventId !== null && this.prepEventId !== undefined) {
    output.writeFieldBegin('prepEventId', Thrift.Type.STRUCT, 1);
    this.prepEventId.write(output);
    output.writeFieldEnd();
  }
  if (this.prepEvent !== null && this.prepEvent !== undefined) {
    output.writeFieldBegin('prepEvent', Thrift.Type.STRUCT, 2);
    this.prepEvent.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

