export const FormatToMaximumTwoDecimalPlaces = (value: number | null): string => {
    return FormatToMaximumDecimalPlaces(value, 2);
};

const FormatToMaximumDecimalPlaces = (value : number | null, decimalPlaces : number) : string => {
    if (value === null) {
        return '-';
    }
    const multiple = Math.pow(10, decimalPlaces);
    return (Math.round(value * multiple) / multiple).toString(); // cap at n dp
};

const isPlural = (value : number) : boolean => {
    const abs = Math.abs(value);
    return abs === 0 || abs > 1;
};

export const FormatToMinumumTwoAndMaximumThreeDecimalPlaces = (value : number) : string => {
    const currentDecimalCount = (value.toString().split('.')[1] || []).length;

    if (currentDecimalCount <= 2) {
        return value.toFixed(2);
    }

    return value.toFixed(3);
};

export const numberUtils = {
    FormatToMaximumTwoDecimalPlaces,
    FormatToMaximumDecimalPlaces,
    isPlural,
    FormatToMinumumTwoAndMaximumThreeDecimalPlaces,
};
