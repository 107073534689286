import React from 'react';

import { HexColor } from 'shared/models/HexColor';
import { LinearScale } from 'shared/models/StackedBarChart/LinearScale';

import { utils } from 'shared/components/StackedBarGraph/utils';

export interface IXAxis {
    /** Linear scale used on the chart for the x-values */
    xScale : LinearScale;
    /** boolean indicating whether or not to show labels */
    showTickLabels : boolean;
    /** boolean indicating whether or not to show vertical grid lines */
    showGridLines : boolean;
    /** function to format the number value of a label (for $ or %) */
    formatTicks : ((value : number) => string | number) | null;
    /** approximate number of ticks to show */
    tickCount : number | null;
    /** height of the grid of the chart this x-axis is associated with */
    chartGridHeight : number;

    /** Possible future work:
     * orientation: either "top" or "bottom", determines if the x axis is on top of the chart or bottom (can handle this by setting y-vals differently and/or using a transform)
     * show x axis line & tick lines: show a line running across the x-axis with tick lines (structure: x-axis-line is parent of tick-line and tick-label)
     * mouse events for the x-axis
     */
}

export const verticalLinePadding : number = 15;
export const additionalTextPadding : number = 15;

/**
 * Form XAxis
 */
export class XAxis extends React.Component<IXAxis, object> {

    public render() {
        const {
            xScale,
            showTickLabels,
            showGridLines,
            formatTicks,
            tickCount,
            chartGridHeight,
        } = this.props;

        const xTicks : Array<number> = xScale.getTicks(tickCount);

        const defaultColor = new HexColor('#000000');

        const gridLine = (xPosition : number) => {
            if (showGridLines) {
                return (
                    <line
                        className="x-axis-grid-line"
                        x1={ xPosition }
                        x2={ xPosition }
                        y2={ chartGridHeight + verticalLinePadding }
                        stroke={ defaultColor.getValue() }
                        opacity={ 0.3 }
                    />
                );
            }
        };

        const drawTicks = xTicks.map((tick, index) => {
            const scaledXValue = utils.scaleValueLinear(tick, xScale);

            const formattedTickValue : string | number = formatTicks ? formatTicks(tick) : tick;

            return (
                <g key={ index } className="x-axis-tick" >

                    { showGridLines &&
                        gridLine(scaledXValue)
                    }

                    { showTickLabels &&
                        <text
                            className="x-axis-tick-label"
                            x={ scaledXValue }
                            y={ chartGridHeight + verticalLinePadding + additionalTextPadding }
                            textAnchor={ (index === 0) ? 'start' : 'middle' } // in order to have the first label display in full, need to have the text-anchor at start (assumes axis at 0)
                        >
                            { formattedTickValue }
                        </text>
                    }
                </g>
            );
        });

        return (
            <g
                className="x-axis"
            >
                { drawTicks }
            </g>
        );
    }

}
