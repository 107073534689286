import PublicContentModel from 'gen-thrift/public_content_Model_types';

import { PublicContent } from '../model/PublicContent';
import { PublicContentAndPublicContentViewEvent } from '../model/PublicContentAndPublicContentViewEvent';
import { PublicContentTypeEnum } from '../model/PublicContentTypeEnum';
import { PublicContentUrl } from '../model/PublicContentUrl';
import { PublicContentViewEvent } from '../model/PublicContentViewEvent';
import { PublicContentViewEventTypeEnum } from '../model/PublicContentViewEventTypeEnum';
import { UserPublicContent } from '../model/UserPublicContent';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export class PublicContentObjectToThriftSerializer {

    public getThriftPublicContent(
        publicContent : PublicContent
    ) : PublicContentModel.PublicContent {
        return new PublicContentModel.PublicContent({
            publicContentUrl: this.getThriftPublicContentUrl(publicContent.getPublicContentUrl()),
            publicContentType: this.getThriftPublicContentType(publicContent.getPublicContentType()),
            title: publicContent.getTitle(),
            excerpt: publicContent.getExcerpt(),
            thumbnailUrl: publicContent.getThumbnailUrl(),
            publicationDate: publicContent.getPublicationDate(),
        });
    }

    public getThriftPublicContentAndPublicContentViewEvent(
        publicContentAndPublicContentViewEvent : PublicContentAndPublicContentViewEvent
    ) : PublicContentModel.PublicContentAndPublicContentViewEvent {
        const publicContentViewEvent = publicContentAndPublicContentViewEvent.getPublicContentViewEvent();
        return new PublicContentModel.PublicContentAndPublicContentViewEvent({
            publicContent: this.getThriftPublicContent(publicContentAndPublicContentViewEvent.getPublicContent()),
            publicContentViewEvent: (publicContentViewEvent === null) ? null : this.getThriftPublicContentViewEvent(publicContentViewEvent),
        });
    }

    public getThriftPublicContentType(
        publicContentTypeEnum : PublicContentTypeEnum
    ) : PublicContentModel.PublicContentType {
        switch (publicContentTypeEnum) {
            case PublicContentTypeEnum.POST:
                return PublicContentModel.PublicContentType.POST;
            case PublicContentTypeEnum.RESOURCE:
                return PublicContentModel.PublicContentType.RESOURCE;
            default:
                throw new RuntimeException('Not implemented for publicContentType: ' + publicContentTypeEnum);
        }
    }

    public getThriftPublicContentUrl(
        publicContentUrl : PublicContentUrl
    ) : PublicContentModel.PublicContentUrl {
        return new PublicContentModel.PublicContentUrl({
            hostname: publicContentUrl.getHostname(),
            path: publicContentUrl.getPath(),
        });
    }

    public getThriftPublicContentViewEvent(
        publicContentViewEvent : PublicContentViewEvent
    ) : PublicContentModel.PublicContentViewEvent {
        return new PublicContentModel.PublicContentViewEvent({
            eventTimestamp: publicContentViewEvent.getEventTime(),
            referrer: publicContentViewEvent.getReferrer(),
            eventType: this.getThriftPublicContentViewEventType(publicContentViewEvent.getEventType()),
            publicContentUrlParameters: publicContentViewEvent.getPublicContentUrlParameters(),
            publicContentUrl: this.getThriftPublicContentUrl(publicContentViewEvent.getPublicContentUrl())
        });
    }

    public getThriftPublicContentViewEventType(
        publicContentViewEventTypeEnum : PublicContentViewEventTypeEnum
    ) : PublicContentModel.PublicContentViewEventType {
        switch (publicContentViewEventTypeEnum) {
            case PublicContentViewEventTypeEnum.PAGE_VIEW:
                return PublicContentModel.PublicContentViewEventType.PAGE_VIEW;
            case PublicContentViewEventTypeEnum.DOWNLOAD:
                return PublicContentModel.PublicContentViewEventType.DOWNLOAD;
            default:
                throw new RuntimeException('Not implemented for publicContentType: ' + publicContentViewEventTypeEnum);
        }
    }

    public getThriftUserPublicContent(
        userPublicContent : UserPublicContent
    ) : PublicContentModel.UserPublicContent {

        const recentlyViewed : Array<PublicContentModel.PublicContentAndPublicContentViewEvent> = [];
        userPublicContent.getRecentlyViewed().forEach((publicContentAndPublicContentViewEvent : PublicContentAndPublicContentViewEvent) => {
            recentlyViewed.push(this.getThriftPublicContentAndPublicContentViewEvent(publicContentAndPublicContentViewEvent));
        });

        const recommended : Array<PublicContentModel.PublicContentAndPublicContentViewEvent> = [];
        userPublicContent.getRecommended().forEach((publicContentAndPublicContentViewEvent : PublicContentAndPublicContentViewEvent) => {
            recommended.push(this.getThriftPublicContentAndPublicContentViewEvent(publicContentAndPublicContentViewEvent));
        });

        return new PublicContentModel.UserPublicContent({
            recentlyViewed,
            recommended,
        });
    }
}
