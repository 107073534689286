import React from 'react';

import { Dialog } from 'shared/components/Dialog';

export interface IEmptyTransferListDialog {
    continueTransfer : () => void;
}

export class EmptyTransferListDialog extends React.PureComponent<IEmptyTransferListDialog, object> {
    public render() {
        const {
            continueTransfer,
        } = this.props;

        return (
            <Dialog
                className="empty-transfer-list-dialog"
                buttons={
                    [
                        {
                            classes: '',
                            children: 'Continue',
                            isDisabled: false,
                            isLoading: false,
                            onClick: continueTransfer,
                        },
                    ]
                }
                headerText="Empty Report List"
            >
                <p>To complete a report you need to have <b>at least one item</b> in your list.</p>
                <p>
                    To add an item to your list, start by searching for an item on the left.
                    Once you’ve found what you are looking for, select the item by clicking on it, enter a quantity, and click <b>Add to List</b>.
                </p>
            </Dialog>
        );
    }
}
