//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


import Thrift from 'thrift';
import InventoryTransferReportModel from './inventory_transfer_report_Model_types';
import InventoryTransferReportExceptions from './inventory_transfer_report_Exceptions_types';
import ProductExceptions from './product_Exceptions_types';
import ProductAmountExceptions from './product_amount_Exceptions_types';
import LocationModel from './location_Model_types';
import LocationExceptions from './location_Exceptions_types';
import CoreTimeModel from './core_time_Model_types';
import UserAccountsModel from './accounts_user_Model_types';
import AccountsExceptions from './accounts_Exceptions_types';
import CoreApiExceptions from './core_api_Exceptions_types';

var InventoryTransferReportService = {};
export default InventoryTransferReportService;
//HELPER FUNCTIONS AND STRUCTURES

InventoryTransferReportService.InventoryTransferReportService_createTransferReport_args = function(args) {
  this.actor = null;
  this.reportData = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.reportData !== undefined && args.reportData !== null) {
      this.reportData = new InventoryTransferReportModel.InventoryTransferReportInfo(args.reportData);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field reportData is unset!');
    }
  }
};
InventoryTransferReportService.InventoryTransferReportService_createTransferReport_args.prototype = {};
InventoryTransferReportService.InventoryTransferReportService_createTransferReport_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.reportData = new InventoryTransferReportModel.InventoryTransferReportInfo();
        this.reportData.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_createTransferReport_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryTransferReportService_createTransferReport_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.reportData !== null && this.reportData !== undefined) {
    output.writeFieldBegin('reportData', Thrift.Type.STRUCT, 2);
    this.reportData.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_createTransferReport_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownActorException = null;
  this.unknownPerspectiveLocationException = null;
  this.actorDoesNotHaveAccessToPerspectiveLocationException = null;
  this.perspectiveLocationCannotEqualPartnerLocationException = null;
  this.unknownPartnerLocationException = null;
  this.actorDoesNotHaveAccessToPartnerLocationException = null;
  this.unknownProductIdException = null;
  this.unitIncompatibleWithProductPackagingException = null;
  this.quantityMustBeNonnegativeException = null;
  this.AtMostOneQuantityIsAllowedForProductAndUnitException = null;
  this.productsHaveNoMappingAtPartnerLocationException = null;
  this.productNotAssociatedWithLocationException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownPerspectiveLocationException) {
    this.unknownPerspectiveLocationException = args;
    return;
  }
  if (args instanceof LocationExceptions.ActorDoesNotHaveAccessToPerspectiveLocationException) {
    this.actorDoesNotHaveAccessToPerspectiveLocationException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.PerspectiveLocationCannotEqualPartnerLocationException) {
    this.perspectiveLocationCannotEqualPartnerLocationException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.UnknownPartnerLocationException) {
    this.unknownPartnerLocationException = args;
    return;
  }
  if (args instanceof LocationExceptions.ActorDoesNotHaveAccessToPartnerLocationException) {
    this.actorDoesNotHaveAccessToPartnerLocationException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof ProductAmountExceptions.UnitIncompatibleWithProductPackagingException) {
    this.unitIncompatibleWithProductPackagingException = args;
    return;
  }
  if (args instanceof ProductAmountExceptions.QuantityMustBeNonnegativeException) {
    this.quantityMustBeNonnegativeException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.AtMostOneQuantityIsAllowedForProductAndUnitException) {
    this.AtMostOneQuantityIsAllowedForProductAndUnitException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.ProductsHaveNoMappingAtPartnerLocationException) {
    this.productsHaveNoMappingAtPartnerLocationException = args;
    return;
  }
  if (args instanceof ProductAmountExceptions.ProductNotAssociatedWithLocationException) {
    this.productNotAssociatedWithLocationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new InventoryTransferReportModel.InventoryTransferReportIdentifier(args.success);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.unknownPerspectiveLocationException !== undefined && args.unknownPerspectiveLocationException !== null) {
      this.unknownPerspectiveLocationException = args.unknownPerspectiveLocationException;
    }
    if (args.actorDoesNotHaveAccessToPerspectiveLocationException !== undefined && args.actorDoesNotHaveAccessToPerspectiveLocationException !== null) {
      this.actorDoesNotHaveAccessToPerspectiveLocationException = args.actorDoesNotHaveAccessToPerspectiveLocationException;
    }
    if (args.perspectiveLocationCannotEqualPartnerLocationException !== undefined && args.perspectiveLocationCannotEqualPartnerLocationException !== null) {
      this.perspectiveLocationCannotEqualPartnerLocationException = args.perspectiveLocationCannotEqualPartnerLocationException;
    }
    if (args.unknownPartnerLocationException !== undefined && args.unknownPartnerLocationException !== null) {
      this.unknownPartnerLocationException = args.unknownPartnerLocationException;
    }
    if (args.actorDoesNotHaveAccessToPartnerLocationException !== undefined && args.actorDoesNotHaveAccessToPartnerLocationException !== null) {
      this.actorDoesNotHaveAccessToPartnerLocationException = args.actorDoesNotHaveAccessToPartnerLocationException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.unitIncompatibleWithProductPackagingException !== undefined && args.unitIncompatibleWithProductPackagingException !== null) {
      this.unitIncompatibleWithProductPackagingException = args.unitIncompatibleWithProductPackagingException;
    }
    if (args.quantityMustBeNonnegativeException !== undefined && args.quantityMustBeNonnegativeException !== null) {
      this.quantityMustBeNonnegativeException = args.quantityMustBeNonnegativeException;
    }
    if (args.AtMostOneQuantityIsAllowedForProductAndUnitException !== undefined && args.AtMostOneQuantityIsAllowedForProductAndUnitException !== null) {
      this.AtMostOneQuantityIsAllowedForProductAndUnitException = args.AtMostOneQuantityIsAllowedForProductAndUnitException;
    }
    if (args.productsHaveNoMappingAtPartnerLocationException !== undefined && args.productsHaveNoMappingAtPartnerLocationException !== null) {
      this.productsHaveNoMappingAtPartnerLocationException = args.productsHaveNoMappingAtPartnerLocationException;
    }
    if (args.productNotAssociatedWithLocationException !== undefined && args.productNotAssociatedWithLocationException !== null) {
      this.productNotAssociatedWithLocationException = args.productNotAssociatedWithLocationException;
    }
  }
};
InventoryTransferReportService.InventoryTransferReportService_createTransferReport_result.prototype = {};
InventoryTransferReportService.InventoryTransferReportService_createTransferReport_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new InventoryTransferReportModel.InventoryTransferReportIdentifier();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownPerspectiveLocationException = new LocationExceptions.UnknownPerspectiveLocationException();
        this.unknownPerspectiveLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToPerspectiveLocationException = new LocationExceptions.ActorDoesNotHaveAccessToPerspectiveLocationException();
        this.actorDoesNotHaveAccessToPerspectiveLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.perspectiveLocationCannotEqualPartnerLocationException = new InventoryTransferReportExceptions.PerspectiveLocationCannotEqualPartnerLocationException();
        this.perspectiveLocationCannotEqualPartnerLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownPartnerLocationException = new InventoryTransferReportExceptions.UnknownPartnerLocationException();
        this.unknownPartnerLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToPartnerLocationException = new LocationExceptions.ActorDoesNotHaveAccessToPartnerLocationException();
        this.actorDoesNotHaveAccessToPartnerLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.STRUCT) {
        this.unitIncompatibleWithProductPackagingException = new ProductAmountExceptions.UnitIncompatibleWithProductPackagingException();
        this.unitIncompatibleWithProductPackagingException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.STRUCT) {
        this.quantityMustBeNonnegativeException = new ProductAmountExceptions.QuantityMustBeNonnegativeException();
        this.quantityMustBeNonnegativeException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.STRUCT) {
        this.AtMostOneQuantityIsAllowedForProductAndUnitException = new InventoryTransferReportExceptions.AtMostOneQuantityIsAllowedForProductAndUnitException();
        this.AtMostOneQuantityIsAllowedForProductAndUnitException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 13:
      if (ftype == Thrift.Type.STRUCT) {
        this.productsHaveNoMappingAtPartnerLocationException = new InventoryTransferReportExceptions.ProductsHaveNoMappingAtPartnerLocationException();
        this.productsHaveNoMappingAtPartnerLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 14:
      if (ftype == Thrift.Type.STRUCT) {
        this.productNotAssociatedWithLocationException = new ProductAmountExceptions.ProductNotAssociatedWithLocationException();
        this.productNotAssociatedWithLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_createTransferReport_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryTransferReportService_createTransferReport_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownPerspectiveLocationException !== null && this.unknownPerspectiveLocationException !== undefined) {
    output.writeFieldBegin('unknownPerspectiveLocationException', Thrift.Type.STRUCT, 4);
    this.unknownPerspectiveLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToPerspectiveLocationException !== null && this.actorDoesNotHaveAccessToPerspectiveLocationException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToPerspectiveLocationException', Thrift.Type.STRUCT, 5);
    this.actorDoesNotHaveAccessToPerspectiveLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.perspectiveLocationCannotEqualPartnerLocationException !== null && this.perspectiveLocationCannotEqualPartnerLocationException !== undefined) {
    output.writeFieldBegin('perspectiveLocationCannotEqualPartnerLocationException', Thrift.Type.STRUCT, 6);
    this.perspectiveLocationCannotEqualPartnerLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownPartnerLocationException !== null && this.unknownPartnerLocationException !== undefined) {
    output.writeFieldBegin('unknownPartnerLocationException', Thrift.Type.STRUCT, 7);
    this.unknownPartnerLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToPartnerLocationException !== null && this.actorDoesNotHaveAccessToPartnerLocationException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToPartnerLocationException', Thrift.Type.STRUCT, 8);
    this.actorDoesNotHaveAccessToPartnerLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 9);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.unitIncompatibleWithProductPackagingException !== null && this.unitIncompatibleWithProductPackagingException !== undefined) {
    output.writeFieldBegin('unitIncompatibleWithProductPackagingException', Thrift.Type.STRUCT, 10);
    this.unitIncompatibleWithProductPackagingException.write(output);
    output.writeFieldEnd();
  }
  if (this.quantityMustBeNonnegativeException !== null && this.quantityMustBeNonnegativeException !== undefined) {
    output.writeFieldBegin('quantityMustBeNonnegativeException', Thrift.Type.STRUCT, 11);
    this.quantityMustBeNonnegativeException.write(output);
    output.writeFieldEnd();
  }
  if (this.AtMostOneQuantityIsAllowedForProductAndUnitException !== null && this.AtMostOneQuantityIsAllowedForProductAndUnitException !== undefined) {
    output.writeFieldBegin('AtMostOneQuantityIsAllowedForProductAndUnitException', Thrift.Type.STRUCT, 12);
    this.AtMostOneQuantityIsAllowedForProductAndUnitException.write(output);
    output.writeFieldEnd();
  }
  if (this.productsHaveNoMappingAtPartnerLocationException !== null && this.productsHaveNoMappingAtPartnerLocationException !== undefined) {
    output.writeFieldBegin('productsHaveNoMappingAtPartnerLocationException', Thrift.Type.STRUCT, 13);
    this.productsHaveNoMappingAtPartnerLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.productNotAssociatedWithLocationException !== null && this.productNotAssociatedWithLocationException !== undefined) {
    output.writeFieldBegin('productNotAssociatedWithLocationException', Thrift.Type.STRUCT, 14);
    this.productNotAssociatedWithLocationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_updateTransferReport_args = function(args) {
  this.actor = null;
  this.reportIdentifier = null;
  this.update = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.reportIdentifier !== undefined && args.reportIdentifier !== null) {
      this.reportIdentifier = new InventoryTransferReportModel.InventoryTransferReportIdentifier(args.reportIdentifier);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field reportIdentifier is unset!');
    }
    if (args.update !== undefined && args.update !== null) {
      this.update = new InventoryTransferReportModel.BreakageReportUpdate(args.update);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field update is unset!');
    }
  }
};
InventoryTransferReportService.InventoryTransferReportService_updateTransferReport_args.prototype = {};
InventoryTransferReportService.InventoryTransferReportService_updateTransferReport_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.reportIdentifier = new InventoryTransferReportModel.InventoryTransferReportIdentifier();
        this.reportIdentifier.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.update = new InventoryTransferReportModel.BreakageReportUpdate();
        this.update.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_updateTransferReport_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryTransferReportService_updateTransferReport_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.reportIdentifier !== null && this.reportIdentifier !== undefined) {
    output.writeFieldBegin('reportIdentifier', Thrift.Type.STRUCT, 2);
    this.reportIdentifier.write(output);
    output.writeFieldEnd();
  }
  if (this.update !== null && this.update !== undefined) {
    output.writeFieldBegin('update', Thrift.Type.STRUCT, 3);
    this.update.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_updateTransferReport_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownActorException = null;
  this.unknownReportIdentifierException = null;
  this.actorDoesNotHaveAccessToReportException = null;
  this.unknownProductIdException = null;
  this.unitIncompatibleWithProductPackagingException = null;
  this.quantityMustBeNonnegativeException = null;
  this.quantityOfProductAndUnitDoesNotExistException = null;
  this.atMostOneQuantityIsAllowedForProductAndUnitException = null;
  this.productsHaveNoMappingAtPartnerLocationException = null;
  this.productNotAssociatedWithLocationException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.UnknownReportIdentifierException) {
    this.unknownReportIdentifierException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.ActorDoesNotHaveAccessToReportException) {
    this.actorDoesNotHaveAccessToReportException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof ProductAmountExceptions.UnitIncompatibleWithProductPackagingException) {
    this.unitIncompatibleWithProductPackagingException = args;
    return;
  }
  if (args instanceof ProductAmountExceptions.QuantityMustBeNonnegativeException) {
    this.quantityMustBeNonnegativeException = args;
    return;
  }
  if (args instanceof ProductAmountExceptions.QuantityOfProductAndUnitDoesNotExistException) {
    this.quantityOfProductAndUnitDoesNotExistException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.AtMostOneQuantityIsAllowedForProductAndUnitException) {
    this.atMostOneQuantityIsAllowedForProductAndUnitException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.ProductsHaveNoMappingAtPartnerLocationException) {
    this.productsHaveNoMappingAtPartnerLocationException = args;
    return;
  }
  if (args instanceof ProductAmountExceptions.ProductNotAssociatedWithLocationException) {
    this.productNotAssociatedWithLocationException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.unknownReportIdentifierException !== undefined && args.unknownReportIdentifierException !== null) {
      this.unknownReportIdentifierException = args.unknownReportIdentifierException;
    }
    if (args.actorDoesNotHaveAccessToReportException !== undefined && args.actorDoesNotHaveAccessToReportException !== null) {
      this.actorDoesNotHaveAccessToReportException = args.actorDoesNotHaveAccessToReportException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.unitIncompatibleWithProductPackagingException !== undefined && args.unitIncompatibleWithProductPackagingException !== null) {
      this.unitIncompatibleWithProductPackagingException = args.unitIncompatibleWithProductPackagingException;
    }
    if (args.quantityMustBeNonnegativeException !== undefined && args.quantityMustBeNonnegativeException !== null) {
      this.quantityMustBeNonnegativeException = args.quantityMustBeNonnegativeException;
    }
    if (args.quantityOfProductAndUnitDoesNotExistException !== undefined && args.quantityOfProductAndUnitDoesNotExistException !== null) {
      this.quantityOfProductAndUnitDoesNotExistException = args.quantityOfProductAndUnitDoesNotExistException;
    }
    if (args.atMostOneQuantityIsAllowedForProductAndUnitException !== undefined && args.atMostOneQuantityIsAllowedForProductAndUnitException !== null) {
      this.atMostOneQuantityIsAllowedForProductAndUnitException = args.atMostOneQuantityIsAllowedForProductAndUnitException;
    }
    if (args.productsHaveNoMappingAtPartnerLocationException !== undefined && args.productsHaveNoMappingAtPartnerLocationException !== null) {
      this.productsHaveNoMappingAtPartnerLocationException = args.productsHaveNoMappingAtPartnerLocationException;
    }
    if (args.productNotAssociatedWithLocationException !== undefined && args.productNotAssociatedWithLocationException !== null) {
      this.productNotAssociatedWithLocationException = args.productNotAssociatedWithLocationException;
    }
  }
};
InventoryTransferReportService.InventoryTransferReportService_updateTransferReport_result.prototype = {};
InventoryTransferReportService.InventoryTransferReportService_updateTransferReport_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownReportIdentifierException = new InventoryTransferReportExceptions.UnknownReportIdentifierException();
        this.unknownReportIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToReportException = new InventoryTransferReportExceptions.ActorDoesNotHaveAccessToReportException();
        this.actorDoesNotHaveAccessToReportException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.unitIncompatibleWithProductPackagingException = new ProductAmountExceptions.UnitIncompatibleWithProductPackagingException();
        this.unitIncompatibleWithProductPackagingException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.quantityMustBeNonnegativeException = new ProductAmountExceptions.QuantityMustBeNonnegativeException();
        this.quantityMustBeNonnegativeException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.STRUCT) {
        this.quantityOfProductAndUnitDoesNotExistException = new ProductAmountExceptions.QuantityOfProductAndUnitDoesNotExistException();
        this.quantityOfProductAndUnitDoesNotExistException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.STRUCT) {
        this.atMostOneQuantityIsAllowedForProductAndUnitException = new InventoryTransferReportExceptions.AtMostOneQuantityIsAllowedForProductAndUnitException();
        this.atMostOneQuantityIsAllowedForProductAndUnitException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.STRUCT) {
        this.productsHaveNoMappingAtPartnerLocationException = new InventoryTransferReportExceptions.ProductsHaveNoMappingAtPartnerLocationException();
        this.productsHaveNoMappingAtPartnerLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.STRUCT) {
        this.productNotAssociatedWithLocationException = new ProductAmountExceptions.ProductNotAssociatedWithLocationException();
        this.productNotAssociatedWithLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_updateTransferReport_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryTransferReportService_updateTransferReport_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownReportIdentifierException !== null && this.unknownReportIdentifierException !== undefined) {
    output.writeFieldBegin('unknownReportIdentifierException', Thrift.Type.STRUCT, 4);
    this.unknownReportIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToReportException !== null && this.actorDoesNotHaveAccessToReportException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToReportException', Thrift.Type.STRUCT, 5);
    this.actorDoesNotHaveAccessToReportException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 6);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.unitIncompatibleWithProductPackagingException !== null && this.unitIncompatibleWithProductPackagingException !== undefined) {
    output.writeFieldBegin('unitIncompatibleWithProductPackagingException', Thrift.Type.STRUCT, 7);
    this.unitIncompatibleWithProductPackagingException.write(output);
    output.writeFieldEnd();
  }
  if (this.quantityMustBeNonnegativeException !== null && this.quantityMustBeNonnegativeException !== undefined) {
    output.writeFieldBegin('quantityMustBeNonnegativeException', Thrift.Type.STRUCT, 8);
    this.quantityMustBeNonnegativeException.write(output);
    output.writeFieldEnd();
  }
  if (this.quantityOfProductAndUnitDoesNotExistException !== null && this.quantityOfProductAndUnitDoesNotExistException !== undefined) {
    output.writeFieldBegin('quantityOfProductAndUnitDoesNotExistException', Thrift.Type.STRUCT, 9);
    this.quantityOfProductAndUnitDoesNotExistException.write(output);
    output.writeFieldEnd();
  }
  if (this.atMostOneQuantityIsAllowedForProductAndUnitException !== null && this.atMostOneQuantityIsAllowedForProductAndUnitException !== undefined) {
    output.writeFieldBegin('atMostOneQuantityIsAllowedForProductAndUnitException', Thrift.Type.STRUCT, 10);
    this.atMostOneQuantityIsAllowedForProductAndUnitException.write(output);
    output.writeFieldEnd();
  }
  if (this.productsHaveNoMappingAtPartnerLocationException !== null && this.productsHaveNoMappingAtPartnerLocationException !== undefined) {
    output.writeFieldBegin('productsHaveNoMappingAtPartnerLocationException', Thrift.Type.STRUCT, 11);
    this.productsHaveNoMappingAtPartnerLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.productNotAssociatedWithLocationException !== null && this.productNotAssociatedWithLocationException !== undefined) {
    output.writeFieldBegin('productNotAssociatedWithLocationException', Thrift.Type.STRUCT, 12);
    this.productNotAssociatedWithLocationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_updateTransferReportRequisitionStep_args = function(args) {
  this.actor = null;
  this.reportIdentifier = null;
  this.requisitionStep = null;
  this.cancellationReason = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.reportIdentifier !== undefined && args.reportIdentifier !== null) {
      this.reportIdentifier = new InventoryTransferReportModel.InventoryTransferReportIdentifier(args.reportIdentifier);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field reportIdentifier is unset!');
    }
    if (args.requisitionStep !== undefined && args.requisitionStep !== null) {
      this.requisitionStep = args.requisitionStep;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field requisitionStep is unset!');
    }
    if (args.cancellationReason !== undefined && args.cancellationReason !== null) {
      this.cancellationReason = args.cancellationReason;
    }
  }
};
InventoryTransferReportService.InventoryTransferReportService_updateTransferReportRequisitionStep_args.prototype = {};
InventoryTransferReportService.InventoryTransferReportService_updateTransferReportRequisitionStep_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.reportIdentifier = new InventoryTransferReportModel.InventoryTransferReportIdentifier();
        this.reportIdentifier.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.requisitionStep = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.cancellationReason = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_updateTransferReportRequisitionStep_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryTransferReportService_updateTransferReportRequisitionStep_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.reportIdentifier !== null && this.reportIdentifier !== undefined) {
    output.writeFieldBegin('reportIdentifier', Thrift.Type.STRUCT, 2);
    this.reportIdentifier.write(output);
    output.writeFieldEnd();
  }
  if (this.requisitionStep !== null && this.requisitionStep !== undefined) {
    output.writeFieldBegin('requisitionStep', Thrift.Type.STRING, 3);
    output.writeString(this.requisitionStep);
    output.writeFieldEnd();
  }
  if (this.cancellationReason !== null && this.cancellationReason !== undefined) {
    output.writeFieldBegin('cancellationReason', Thrift.Type.STRING, 4);
    output.writeString(this.cancellationReason);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_updateTransferReportRequisitionStep_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownActorException = null;
  this.unknownReportIdentifierException = null;
  this.actorDoesNotHaveAccessToReportException = null;
  this.unknownProductIdException = null;
  this.unitIncompatibleWithProductPackagingException = null;
  this.quantityMustBeNonnegativeException = null;
  this.quantityOfProductAndUnitDoesNotExistException = null;
  this.atMostOneQuantityIsAllowedForProductAndUnitException = null;
  this.productsHaveNoMappingAtPartnerLocationException = null;
  this.productNotAssociatedWithLocationException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.UnknownReportIdentifierException) {
    this.unknownReportIdentifierException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.ActorDoesNotHaveAccessToReportException) {
    this.actorDoesNotHaveAccessToReportException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof ProductAmountExceptions.UnitIncompatibleWithProductPackagingException) {
    this.unitIncompatibleWithProductPackagingException = args;
    return;
  }
  if (args instanceof ProductAmountExceptions.QuantityMustBeNonnegativeException) {
    this.quantityMustBeNonnegativeException = args;
    return;
  }
  if (args instanceof ProductAmountExceptions.QuantityOfProductAndUnitDoesNotExistException) {
    this.quantityOfProductAndUnitDoesNotExistException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.AtMostOneQuantityIsAllowedForProductAndUnitException) {
    this.atMostOneQuantityIsAllowedForProductAndUnitException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.ProductsHaveNoMappingAtPartnerLocationException) {
    this.productsHaveNoMappingAtPartnerLocationException = args;
    return;
  }
  if (args instanceof ProductAmountExceptions.ProductNotAssociatedWithLocationException) {
    this.productNotAssociatedWithLocationException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.unknownReportIdentifierException !== undefined && args.unknownReportIdentifierException !== null) {
      this.unknownReportIdentifierException = args.unknownReportIdentifierException;
    }
    if (args.actorDoesNotHaveAccessToReportException !== undefined && args.actorDoesNotHaveAccessToReportException !== null) {
      this.actorDoesNotHaveAccessToReportException = args.actorDoesNotHaveAccessToReportException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.unitIncompatibleWithProductPackagingException !== undefined && args.unitIncompatibleWithProductPackagingException !== null) {
      this.unitIncompatibleWithProductPackagingException = args.unitIncompatibleWithProductPackagingException;
    }
    if (args.quantityMustBeNonnegativeException !== undefined && args.quantityMustBeNonnegativeException !== null) {
      this.quantityMustBeNonnegativeException = args.quantityMustBeNonnegativeException;
    }
    if (args.quantityOfProductAndUnitDoesNotExistException !== undefined && args.quantityOfProductAndUnitDoesNotExistException !== null) {
      this.quantityOfProductAndUnitDoesNotExistException = args.quantityOfProductAndUnitDoesNotExistException;
    }
    if (args.atMostOneQuantityIsAllowedForProductAndUnitException !== undefined && args.atMostOneQuantityIsAllowedForProductAndUnitException !== null) {
      this.atMostOneQuantityIsAllowedForProductAndUnitException = args.atMostOneQuantityIsAllowedForProductAndUnitException;
    }
    if (args.productsHaveNoMappingAtPartnerLocationException !== undefined && args.productsHaveNoMappingAtPartnerLocationException !== null) {
      this.productsHaveNoMappingAtPartnerLocationException = args.productsHaveNoMappingAtPartnerLocationException;
    }
    if (args.productNotAssociatedWithLocationException !== undefined && args.productNotAssociatedWithLocationException !== null) {
      this.productNotAssociatedWithLocationException = args.productNotAssociatedWithLocationException;
    }
  }
};
InventoryTransferReportService.InventoryTransferReportService_updateTransferReportRequisitionStep_result.prototype = {};
InventoryTransferReportService.InventoryTransferReportService_updateTransferReportRequisitionStep_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownReportIdentifierException = new InventoryTransferReportExceptions.UnknownReportIdentifierException();
        this.unknownReportIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToReportException = new InventoryTransferReportExceptions.ActorDoesNotHaveAccessToReportException();
        this.actorDoesNotHaveAccessToReportException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.unitIncompatibleWithProductPackagingException = new ProductAmountExceptions.UnitIncompatibleWithProductPackagingException();
        this.unitIncompatibleWithProductPackagingException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.quantityMustBeNonnegativeException = new ProductAmountExceptions.QuantityMustBeNonnegativeException();
        this.quantityMustBeNonnegativeException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.STRUCT) {
        this.quantityOfProductAndUnitDoesNotExistException = new ProductAmountExceptions.QuantityOfProductAndUnitDoesNotExistException();
        this.quantityOfProductAndUnitDoesNotExistException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.STRUCT) {
        this.atMostOneQuantityIsAllowedForProductAndUnitException = new InventoryTransferReportExceptions.AtMostOneQuantityIsAllowedForProductAndUnitException();
        this.atMostOneQuantityIsAllowedForProductAndUnitException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.STRUCT) {
        this.productsHaveNoMappingAtPartnerLocationException = new InventoryTransferReportExceptions.ProductsHaveNoMappingAtPartnerLocationException();
        this.productsHaveNoMappingAtPartnerLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.STRUCT) {
        this.productNotAssociatedWithLocationException = new ProductAmountExceptions.ProductNotAssociatedWithLocationException();
        this.productNotAssociatedWithLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_updateTransferReportRequisitionStep_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryTransferReportService_updateTransferReportRequisitionStep_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownReportIdentifierException !== null && this.unknownReportIdentifierException !== undefined) {
    output.writeFieldBegin('unknownReportIdentifierException', Thrift.Type.STRUCT, 4);
    this.unknownReportIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToReportException !== null && this.actorDoesNotHaveAccessToReportException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToReportException', Thrift.Type.STRUCT, 5);
    this.actorDoesNotHaveAccessToReportException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 6);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.unitIncompatibleWithProductPackagingException !== null && this.unitIncompatibleWithProductPackagingException !== undefined) {
    output.writeFieldBegin('unitIncompatibleWithProductPackagingException', Thrift.Type.STRUCT, 7);
    this.unitIncompatibleWithProductPackagingException.write(output);
    output.writeFieldEnd();
  }
  if (this.quantityMustBeNonnegativeException !== null && this.quantityMustBeNonnegativeException !== undefined) {
    output.writeFieldBegin('quantityMustBeNonnegativeException', Thrift.Type.STRUCT, 8);
    this.quantityMustBeNonnegativeException.write(output);
    output.writeFieldEnd();
  }
  if (this.quantityOfProductAndUnitDoesNotExistException !== null && this.quantityOfProductAndUnitDoesNotExistException !== undefined) {
    output.writeFieldBegin('quantityOfProductAndUnitDoesNotExistException', Thrift.Type.STRUCT, 9);
    this.quantityOfProductAndUnitDoesNotExistException.write(output);
    output.writeFieldEnd();
  }
  if (this.atMostOneQuantityIsAllowedForProductAndUnitException !== null && this.atMostOneQuantityIsAllowedForProductAndUnitException !== undefined) {
    output.writeFieldBegin('atMostOneQuantityIsAllowedForProductAndUnitException', Thrift.Type.STRUCT, 10);
    this.atMostOneQuantityIsAllowedForProductAndUnitException.write(output);
    output.writeFieldEnd();
  }
  if (this.productsHaveNoMappingAtPartnerLocationException !== null && this.productsHaveNoMappingAtPartnerLocationException !== undefined) {
    output.writeFieldBegin('productsHaveNoMappingAtPartnerLocationException', Thrift.Type.STRUCT, 11);
    this.productsHaveNoMappingAtPartnerLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.productNotAssociatedWithLocationException !== null && this.productNotAssociatedWithLocationException !== undefined) {
    output.writeFieldBegin('productNotAssociatedWithLocationException', Thrift.Type.STRUCT, 12);
    this.productNotAssociatedWithLocationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_updateCustomPartnerLocationName_args = function(args) {
  this.actor = null;
  this.reportIdentifier = null;
  this.value = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.reportIdentifier !== undefined && args.reportIdentifier !== null) {
      this.reportIdentifier = new InventoryTransferReportModel.InventoryTransferReportIdentifier(args.reportIdentifier);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field reportIdentifier is unset!');
    }
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field value is unset!');
    }
  }
};
InventoryTransferReportService.InventoryTransferReportService_updateCustomPartnerLocationName_args.prototype = {};
InventoryTransferReportService.InventoryTransferReportService_updateCustomPartnerLocationName_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.reportIdentifier = new InventoryTransferReportModel.InventoryTransferReportIdentifier();
        this.reportIdentifier.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_updateCustomPartnerLocationName_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryTransferReportService_updateCustomPartnerLocationName_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.reportIdentifier !== null && this.reportIdentifier !== undefined) {
    output.writeFieldBegin('reportIdentifier', Thrift.Type.STRUCT, 2);
    this.reportIdentifier.write(output);
    output.writeFieldEnd();
  }
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 3);
    output.writeString(this.value);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_updateCustomPartnerLocationName_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.transferHasRetailerPartnerLocationException = null;
  this.unknownActorException = null;
  this.actorDoesNotHaveAccessToReportException = null;
  this.unknownReportIdentifierException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.TransferHasRetailerPartnerLocationException) {
    this.transferHasRetailerPartnerLocationException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.ActorDoesNotHaveAccessToReportException) {
    this.actorDoesNotHaveAccessToReportException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.UnknownReportIdentifierException) {
    this.unknownReportIdentifierException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.transferHasRetailerPartnerLocationException !== undefined && args.transferHasRetailerPartnerLocationException !== null) {
      this.transferHasRetailerPartnerLocationException = args.transferHasRetailerPartnerLocationException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.actorDoesNotHaveAccessToReportException !== undefined && args.actorDoesNotHaveAccessToReportException !== null) {
      this.actorDoesNotHaveAccessToReportException = args.actorDoesNotHaveAccessToReportException;
    }
    if (args.unknownReportIdentifierException !== undefined && args.unknownReportIdentifierException !== null) {
      this.unknownReportIdentifierException = args.unknownReportIdentifierException;
    }
  }
};
InventoryTransferReportService.InventoryTransferReportService_updateCustomPartnerLocationName_result.prototype = {};
InventoryTransferReportService.InventoryTransferReportService_updateCustomPartnerLocationName_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.transferHasRetailerPartnerLocationException = new InventoryTransferReportExceptions.TransferHasRetailerPartnerLocationException();
        this.transferHasRetailerPartnerLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToReportException = new InventoryTransferReportExceptions.ActorDoesNotHaveAccessToReportException();
        this.actorDoesNotHaveAccessToReportException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownReportIdentifierException = new InventoryTransferReportExceptions.UnknownReportIdentifierException();
        this.unknownReportIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_updateCustomPartnerLocationName_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryTransferReportService_updateCustomPartnerLocationName_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.transferHasRetailerPartnerLocationException !== null && this.transferHasRetailerPartnerLocationException !== undefined) {
    output.writeFieldBegin('transferHasRetailerPartnerLocationException', Thrift.Type.STRUCT, 3);
    this.transferHasRetailerPartnerLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 4);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToReportException !== null && this.actorDoesNotHaveAccessToReportException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToReportException', Thrift.Type.STRUCT, 5);
    this.actorDoesNotHaveAccessToReportException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownReportIdentifierException !== null && this.unknownReportIdentifierException !== undefined) {
    output.writeFieldBegin('unknownReportIdentifierException', Thrift.Type.STRUCT, 6);
    this.unknownReportIdentifierException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_getTransferReportsWithoutCostsById_args = function(args) {
  this.actor = null;
  this.reportIdentifiers = null;
  this.perspective = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.reportIdentifiers !== undefined && args.reportIdentifiers !== null) {
      this.reportIdentifiers = Thrift.copyList(args.reportIdentifiers, [InventoryTransferReportModel.InventoryTransferReportIdentifier]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field reportIdentifiers is unset!');
    }
    if (args.perspective !== undefined && args.perspective !== null) {
      this.perspective = new LocationModel.LocationIdentifier(args.perspective);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field perspective is unset!');
    }
  }
};
InventoryTransferReportService.InventoryTransferReportService_getTransferReportsWithoutCostsById_args.prototype = {};
InventoryTransferReportService.InventoryTransferReportService_getTransferReportsWithoutCostsById_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.reportIdentifiers = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new InventoryTransferReportModel.InventoryTransferReportIdentifier();
          elem6.read(input);
          this.reportIdentifiers.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.perspective = new LocationModel.LocationIdentifier();
        this.perspective.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_getTransferReportsWithoutCostsById_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryTransferReportService_getTransferReportsWithoutCostsById_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.reportIdentifiers !== null && this.reportIdentifiers !== undefined) {
    output.writeFieldBegin('reportIdentifiers', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.reportIdentifiers.length);
    for (var iter7 in this.reportIdentifiers)
    {
      if (this.reportIdentifiers.hasOwnProperty(iter7))
      {
        iter7 = this.reportIdentifiers[iter7];
        iter7.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.perspective !== null && this.perspective !== undefined) {
    output.writeFieldBegin('perspective', Thrift.Type.STRUCT, 3);
    this.perspective.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_getTransferReportsWithoutCostsById_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownActorException = null;
  this.unknownReportIdentifierException = null;
  this.actorDoesNotHaveAccessToPerspectiveLocationException = null;
  this.perspectiveLocationNotInvolvedInReportException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.UnknownReportIdentifierException) {
    this.unknownReportIdentifierException = args;
    return;
  }
  if (args instanceof LocationExceptions.ActorDoesNotHaveAccessToPerspectiveLocationException) {
    this.actorDoesNotHaveAccessToPerspectiveLocationException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.PerspectiveLocationNotInvolvedInReportException) {
    this.perspectiveLocationNotInvolvedInReportException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [InventoryTransferReportModel.InventoryTransferReportAndMetadataWithoutCost]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.unknownReportIdentifierException !== undefined && args.unknownReportIdentifierException !== null) {
      this.unknownReportIdentifierException = args.unknownReportIdentifierException;
    }
    if (args.actorDoesNotHaveAccessToPerspectiveLocationException !== undefined && args.actorDoesNotHaveAccessToPerspectiveLocationException !== null) {
      this.actorDoesNotHaveAccessToPerspectiveLocationException = args.actorDoesNotHaveAccessToPerspectiveLocationException;
    }
    if (args.perspectiveLocationNotInvolvedInReportException !== undefined && args.perspectiveLocationNotInvolvedInReportException !== null) {
      this.perspectiveLocationNotInvolvedInReportException = args.perspectiveLocationNotInvolvedInReportException;
    }
  }
};
InventoryTransferReportService.InventoryTransferReportService_getTransferReportsWithoutCostsById_result.prototype = {};
InventoryTransferReportService.InventoryTransferReportService_getTransferReportsWithoutCostsById_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size8 = 0;
        var _rtmp312;
        this.success = [];
        var _etype11 = 0;
        _rtmp312 = input.readListBegin();
        _etype11 = _rtmp312.etype;
        _size8 = _rtmp312.size;
        for (var _i13 = 0; _i13 < _size8; ++_i13)
        {
          var elem14 = null;
          elem14 = new InventoryTransferReportModel.InventoryTransferReportAndMetadataWithoutCost();
          elem14.read(input);
          this.success.push(elem14);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownReportIdentifierException = new InventoryTransferReportExceptions.UnknownReportIdentifierException();
        this.unknownReportIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToPerspectiveLocationException = new LocationExceptions.ActorDoesNotHaveAccessToPerspectiveLocationException();
        this.actorDoesNotHaveAccessToPerspectiveLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.perspectiveLocationNotInvolvedInReportException = new InventoryTransferReportExceptions.PerspectiveLocationNotInvolvedInReportException();
        this.perspectiveLocationNotInvolvedInReportException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_getTransferReportsWithoutCostsById_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryTransferReportService_getTransferReportsWithoutCostsById_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter15 in this.success)
    {
      if (this.success.hasOwnProperty(iter15))
      {
        iter15 = this.success[iter15];
        iter15.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownReportIdentifierException !== null && this.unknownReportIdentifierException !== undefined) {
    output.writeFieldBegin('unknownReportIdentifierException', Thrift.Type.STRUCT, 4);
    this.unknownReportIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToPerspectiveLocationException !== null && this.actorDoesNotHaveAccessToPerspectiveLocationException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToPerspectiveLocationException', Thrift.Type.STRUCT, 6);
    this.actorDoesNotHaveAccessToPerspectiveLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.perspectiveLocationNotInvolvedInReportException !== null && this.perspectiveLocationNotInvolvedInReportException !== undefined) {
    output.writeFieldBegin('perspectiveLocationNotInvolvedInReportException', Thrift.Type.STRUCT, 7);
    this.perspectiveLocationNotInvolvedInReportException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_getTransferReportsByIds_args = function(args) {
  this.actor = null;
  this.reportIdentifiers = null;
  this.perspective = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.reportIdentifiers !== undefined && args.reportIdentifiers !== null) {
      this.reportIdentifiers = Thrift.copyList(args.reportIdentifiers, [InventoryTransferReportModel.InventoryTransferReportIdentifier]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field reportIdentifiers is unset!');
    }
    if (args.perspective !== undefined && args.perspective !== null) {
      this.perspective = new LocationModel.LocationIdentifier(args.perspective);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field perspective is unset!');
    }
  }
};
InventoryTransferReportService.InventoryTransferReportService_getTransferReportsByIds_args.prototype = {};
InventoryTransferReportService.InventoryTransferReportService_getTransferReportsByIds_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size16 = 0;
        var _rtmp320;
        this.reportIdentifiers = [];
        var _etype19 = 0;
        _rtmp320 = input.readListBegin();
        _etype19 = _rtmp320.etype;
        _size16 = _rtmp320.size;
        for (var _i21 = 0; _i21 < _size16; ++_i21)
        {
          var elem22 = null;
          elem22 = new InventoryTransferReportModel.InventoryTransferReportIdentifier();
          elem22.read(input);
          this.reportIdentifiers.push(elem22);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.perspective = new LocationModel.LocationIdentifier();
        this.perspective.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_getTransferReportsByIds_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryTransferReportService_getTransferReportsByIds_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.reportIdentifiers !== null && this.reportIdentifiers !== undefined) {
    output.writeFieldBegin('reportIdentifiers', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.reportIdentifiers.length);
    for (var iter23 in this.reportIdentifiers)
    {
      if (this.reportIdentifiers.hasOwnProperty(iter23))
      {
        iter23 = this.reportIdentifiers[iter23];
        iter23.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.perspective !== null && this.perspective !== undefined) {
    output.writeFieldBegin('perspective', Thrift.Type.STRUCT, 3);
    this.perspective.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_getTransferReportsByIds_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownActorException = null;
  this.unknownReportIdentifierException = null;
  this.actorDoesNotHaveAccessToPerspectiveLocationException = null;
  this.perspectiveLocationNotInvolvedInReportException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.UnknownReportIdentifierException) {
    this.unknownReportIdentifierException = args;
    return;
  }
  if (args instanceof LocationExceptions.ActorDoesNotHaveAccessToPerspectiveLocationException) {
    this.actorDoesNotHaveAccessToPerspectiveLocationException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.PerspectiveLocationNotInvolvedInReportException) {
    this.perspectiveLocationNotInvolvedInReportException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [InventoryTransferReportModel.InventoryTransferReportAndMetadata]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.unknownReportIdentifierException !== undefined && args.unknownReportIdentifierException !== null) {
      this.unknownReportIdentifierException = args.unknownReportIdentifierException;
    }
    if (args.actorDoesNotHaveAccessToPerspectiveLocationException !== undefined && args.actorDoesNotHaveAccessToPerspectiveLocationException !== null) {
      this.actorDoesNotHaveAccessToPerspectiveLocationException = args.actorDoesNotHaveAccessToPerspectiveLocationException;
    }
    if (args.perspectiveLocationNotInvolvedInReportException !== undefined && args.perspectiveLocationNotInvolvedInReportException !== null) {
      this.perspectiveLocationNotInvolvedInReportException = args.perspectiveLocationNotInvolvedInReportException;
    }
  }
};
InventoryTransferReportService.InventoryTransferReportService_getTransferReportsByIds_result.prototype = {};
InventoryTransferReportService.InventoryTransferReportService_getTransferReportsByIds_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size24 = 0;
        var _rtmp328;
        this.success = [];
        var _etype27 = 0;
        _rtmp328 = input.readListBegin();
        _etype27 = _rtmp328.etype;
        _size24 = _rtmp328.size;
        for (var _i29 = 0; _i29 < _size24; ++_i29)
        {
          var elem30 = null;
          elem30 = new InventoryTransferReportModel.InventoryTransferReportAndMetadata();
          elem30.read(input);
          this.success.push(elem30);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownReportIdentifierException = new InventoryTransferReportExceptions.UnknownReportIdentifierException();
        this.unknownReportIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToPerspectiveLocationException = new LocationExceptions.ActorDoesNotHaveAccessToPerspectiveLocationException();
        this.actorDoesNotHaveAccessToPerspectiveLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.perspectiveLocationNotInvolvedInReportException = new InventoryTransferReportExceptions.PerspectiveLocationNotInvolvedInReportException();
        this.perspectiveLocationNotInvolvedInReportException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_getTransferReportsByIds_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryTransferReportService_getTransferReportsByIds_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter31 in this.success)
    {
      if (this.success.hasOwnProperty(iter31))
      {
        iter31 = this.success[iter31];
        iter31.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownReportIdentifierException !== null && this.unknownReportIdentifierException !== undefined) {
    output.writeFieldBegin('unknownReportIdentifierException', Thrift.Type.STRUCT, 4);
    this.unknownReportIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToPerspectiveLocationException !== null && this.actorDoesNotHaveAccessToPerspectiveLocationException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToPerspectiveLocationException', Thrift.Type.STRUCT, 5);
    this.actorDoesNotHaveAccessToPerspectiveLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.perspectiveLocationNotInvolvedInReportException !== null && this.perspectiveLocationNotInvolvedInReportException !== undefined) {
    output.writeFieldBegin('perspectiveLocationNotInvolvedInReportException', Thrift.Type.STRUCT, 6);
    this.perspectiveLocationNotInvolvedInReportException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_getReportsForLocationInPeriod_args = function(args) {
  this.actor = null;
  this.locationIdentifier = null;
  this.periodStartInclusive = null;
  this.periodEndExclusive = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationIdentifier !== undefined && args.locationIdentifier !== null) {
      this.locationIdentifier = new LocationModel.LocationIdentifier(args.locationIdentifier);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationIdentifier is unset!');
    }
    if (args.periodStartInclusive !== undefined && args.periodStartInclusive !== null) {
      this.periodStartInclusive = new CoreTimeModel.TimestampWithMillisecondPrecision(args.periodStartInclusive);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field periodStartInclusive is unset!');
    }
    if (args.periodEndExclusive !== undefined && args.periodEndExclusive !== null) {
      this.periodEndExclusive = new CoreTimeModel.TimestampWithMillisecondPrecision(args.periodEndExclusive);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field periodEndExclusive is unset!');
    }
  }
};
InventoryTransferReportService.InventoryTransferReportService_getReportsForLocationInPeriod_args.prototype = {};
InventoryTransferReportService.InventoryTransferReportService_getReportsForLocationInPeriod_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationIdentifier = new LocationModel.LocationIdentifier();
        this.locationIdentifier.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.periodStartInclusive = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.periodStartInclusive.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.periodEndExclusive = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.periodEndExclusive.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_getReportsForLocationInPeriod_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryTransferReportService_getReportsForLocationInPeriod_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationIdentifier !== null && this.locationIdentifier !== undefined) {
    output.writeFieldBegin('locationIdentifier', Thrift.Type.STRUCT, 2);
    this.locationIdentifier.write(output);
    output.writeFieldEnd();
  }
  if (this.periodStartInclusive !== null && this.periodStartInclusive !== undefined) {
    output.writeFieldBegin('periodStartInclusive', Thrift.Type.STRUCT, 3);
    this.periodStartInclusive.write(output);
    output.writeFieldEnd();
  }
  if (this.periodEndExclusive !== null && this.periodEndExclusive !== undefined) {
    output.writeFieldBegin('periodEndExclusive', Thrift.Type.STRUCT, 4);
    this.periodEndExclusive.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_getReportsForLocationInPeriod_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.LocationIdentifierIsRequiredException = null;
  this.unknownActorException = null;
  this.unknownLocationIdentifierException = null;
  this.actorDoesNotHaveAccessToLocationException = null;
  this.periodStartIsLaterThanEndException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof LocationExceptions.LocationIdentifierIsRequiredException) {
    this.LocationIdentifierIsRequiredException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof LocationExceptions.ActorDoesNotHaveAccessToLocationException) {
    this.actorDoesNotHaveAccessToLocationException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.PeriodStartIsLaterThanEndException) {
    this.periodStartIsLaterThanEndException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [InventoryTransferReportModel.InventoryTransferReportIdentifier]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.LocationIdentifierIsRequiredException !== undefined && args.LocationIdentifierIsRequiredException !== null) {
      this.LocationIdentifierIsRequiredException = args.LocationIdentifierIsRequiredException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.actorDoesNotHaveAccessToLocationException !== undefined && args.actorDoesNotHaveAccessToLocationException !== null) {
      this.actorDoesNotHaveAccessToLocationException = args.actorDoesNotHaveAccessToLocationException;
    }
    if (args.periodStartIsLaterThanEndException !== undefined && args.periodStartIsLaterThanEndException !== null) {
      this.periodStartIsLaterThanEndException = args.periodStartIsLaterThanEndException;
    }
  }
};
InventoryTransferReportService.InventoryTransferReportService_getReportsForLocationInPeriod_result.prototype = {};
InventoryTransferReportService.InventoryTransferReportService_getReportsForLocationInPeriod_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size32 = 0;
        var _rtmp336;
        this.success = [];
        var _etype35 = 0;
        _rtmp336 = input.readListBegin();
        _etype35 = _rtmp336.etype;
        _size32 = _rtmp336.size;
        for (var _i37 = 0; _i37 < _size32; ++_i37)
        {
          var elem38 = null;
          elem38 = new InventoryTransferReportModel.InventoryTransferReportIdentifier();
          elem38.read(input);
          this.success.push(elem38);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.LocationIdentifierIsRequiredException = new LocationExceptions.LocationIdentifierIsRequiredException();
        this.LocationIdentifierIsRequiredException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToLocationException = new LocationExceptions.ActorDoesNotHaveAccessToLocationException();
        this.actorDoesNotHaveAccessToLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.periodStartIsLaterThanEndException = new InventoryTransferReportExceptions.PeriodStartIsLaterThanEndException();
        this.periodStartIsLaterThanEndException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_getReportsForLocationInPeriod_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryTransferReportService_getReportsForLocationInPeriod_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter39 in this.success)
    {
      if (this.success.hasOwnProperty(iter39))
      {
        iter39 = this.success[iter39];
        iter39.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.LocationIdentifierIsRequiredException !== null && this.LocationIdentifierIsRequiredException !== undefined) {
    output.writeFieldBegin('LocationIdentifierIsRequiredException', Thrift.Type.STRUCT, 4);
    this.LocationIdentifierIsRequiredException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 5);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 6);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToLocationException !== null && this.actorDoesNotHaveAccessToLocationException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToLocationException', Thrift.Type.STRUCT, 7);
    this.actorDoesNotHaveAccessToLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.periodStartIsLaterThanEndException !== null && this.periodStartIsLaterThanEndException !== undefined) {
    output.writeFieldBegin('periodStartIsLaterThanEndException', Thrift.Type.STRUCT, 8);
    this.periodStartIsLaterThanEndException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_deleteReport_args = function(args) {
  this.actor = null;
  this.reportIdentifier = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.reportIdentifier !== undefined && args.reportIdentifier !== null) {
      this.reportIdentifier = new InventoryTransferReportModel.InventoryTransferReportIdentifier(args.reportIdentifier);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field reportIdentifier is unset!');
    }
  }
};
InventoryTransferReportService.InventoryTransferReportService_deleteReport_args.prototype = {};
InventoryTransferReportService.InventoryTransferReportService_deleteReport_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.reportIdentifier = new InventoryTransferReportModel.InventoryTransferReportIdentifier();
        this.reportIdentifier.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_deleteReport_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryTransferReportService_deleteReport_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.reportIdentifier !== null && this.reportIdentifier !== undefined) {
    output.writeFieldBegin('reportIdentifier', Thrift.Type.STRUCT, 2);
    this.reportIdentifier.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_deleteReport_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownActorException = null;
  this.unknownReportIdentifierException = null;
  this.actorDoesNotHaveAccessToReportException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.UnknownReportIdentifierException) {
    this.unknownReportIdentifierException = args;
    return;
  }
  if (args instanceof InventoryTransferReportExceptions.ActorDoesNotHaveAccessToReportException) {
    this.actorDoesNotHaveAccessToReportException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.unknownReportIdentifierException !== undefined && args.unknownReportIdentifierException !== null) {
      this.unknownReportIdentifierException = args.unknownReportIdentifierException;
    }
    if (args.actorDoesNotHaveAccessToReportException !== undefined && args.actorDoesNotHaveAccessToReportException !== null) {
      this.actorDoesNotHaveAccessToReportException = args.actorDoesNotHaveAccessToReportException;
    }
  }
};
InventoryTransferReportService.InventoryTransferReportService_deleteReport_result.prototype = {};
InventoryTransferReportService.InventoryTransferReportService_deleteReport_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownReportIdentifierException = new InventoryTransferReportExceptions.UnknownReportIdentifierException();
        this.unknownReportIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToReportException = new InventoryTransferReportExceptions.ActorDoesNotHaveAccessToReportException();
        this.actorDoesNotHaveAccessToReportException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_deleteReport_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryTransferReportService_deleteReport_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownReportIdentifierException !== null && this.unknownReportIdentifierException !== undefined) {
    output.writeFieldBegin('unknownReportIdentifierException', Thrift.Type.STRUCT, 4);
    this.unknownReportIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToReportException !== null && this.actorDoesNotHaveAccessToReportException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToReportException', Thrift.Type.STRUCT, 5);
    this.actorDoesNotHaveAccessToReportException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_getCustomTransferPartnerLocationNamesForLocation_args = function(args) {
  this.actor = null;
  this.locationIdentifier = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationIdentifier !== undefined && args.locationIdentifier !== null) {
      this.locationIdentifier = new LocationModel.LocationIdentifier(args.locationIdentifier);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationIdentifier is unset!');
    }
  }
};
InventoryTransferReportService.InventoryTransferReportService_getCustomTransferPartnerLocationNamesForLocation_args.prototype = {};
InventoryTransferReportService.InventoryTransferReportService_getCustomTransferPartnerLocationNamesForLocation_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationIdentifier = new LocationModel.LocationIdentifier();
        this.locationIdentifier.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_getCustomTransferPartnerLocationNamesForLocation_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryTransferReportService_getCustomTransferPartnerLocationNamesForLocation_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationIdentifier !== null && this.locationIdentifier !== undefined) {
    output.writeFieldBegin('locationIdentifier', Thrift.Type.STRUCT, 2);
    this.locationIdentifier.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_getCustomTransferPartnerLocationNamesForLocation_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownActorException = null;
  this.unknownLocationIdentifierException = null;
  this.actorDoesNotHaveAccessToLocationException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof LocationExceptions.ActorDoesNotHaveAccessToLocationException) {
    this.actorDoesNotHaveAccessToLocationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [null]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.actorDoesNotHaveAccessToLocationException !== undefined && args.actorDoesNotHaveAccessToLocationException !== null) {
      this.actorDoesNotHaveAccessToLocationException = args.actorDoesNotHaveAccessToLocationException;
    }
  }
};
InventoryTransferReportService.InventoryTransferReportService_getCustomTransferPartnerLocationNamesForLocation_result.prototype = {};
InventoryTransferReportService.InventoryTransferReportService_getCustomTransferPartnerLocationNamesForLocation_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size40 = 0;
        var _rtmp344;
        this.success = [];
        var _etype43 = 0;
        _rtmp344 = input.readListBegin();
        _etype43 = _rtmp344.etype;
        _size40 = _rtmp344.size;
        for (var _i45 = 0; _i45 < _size40; ++_i45)
        {
          var elem46 = null;
          elem46 = input.readString().value;
          this.success.push(elem46);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToLocationException = new LocationExceptions.ActorDoesNotHaveAccessToLocationException();
        this.actorDoesNotHaveAccessToLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportService_getCustomTransferPartnerLocationNamesForLocation_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryTransferReportService_getCustomTransferPartnerLocationNamesForLocation_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRING, this.success.length);
    for (var iter47 in this.success)
    {
      if (this.success.hasOwnProperty(iter47))
      {
        iter47 = this.success[iter47];
        output.writeString(iter47);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 4);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToLocationException !== null && this.actorDoesNotHaveAccessToLocationException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToLocationException', Thrift.Type.STRUCT, 5);
    this.actorDoesNotHaveAccessToLocationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportService.InventoryTransferReportServiceClient = function(input, output) {
    this.input = input;
    this.output = (!output) ? input : output;
    this.seqid = 0;
};
InventoryTransferReportService.InventoryTransferReportServiceClient.prototype = {};
InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.createTransferReport = function(actor, reportData, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_createTransferReport(pendingError, actor, reportData, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_createTransferReport();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.send_createTransferReport = function(pendingError, actor, reportData, callback) {
  this.output.writeMessageBegin('createTransferReport', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryTransferReportService.InventoryTransferReportService_createTransferReport_args();
  args.actor = actor;
  args.reportData = reportData;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_createTransferReport() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.recv_createTransferReport = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryTransferReportService.InventoryTransferReportService_createTransferReport_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.unknownPerspectiveLocationException) {
    throw result.unknownPerspectiveLocationException;
  }
  if (null !== result.actorDoesNotHaveAccessToPerspectiveLocationException) {
    throw result.actorDoesNotHaveAccessToPerspectiveLocationException;
  }
  if (null !== result.perspectiveLocationCannotEqualPartnerLocationException) {
    throw result.perspectiveLocationCannotEqualPartnerLocationException;
  }
  if (null !== result.unknownPartnerLocationException) {
    throw result.unknownPartnerLocationException;
  }
  if (null !== result.actorDoesNotHaveAccessToPartnerLocationException) {
    throw result.actorDoesNotHaveAccessToPartnerLocationException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.unitIncompatibleWithProductPackagingException) {
    throw result.unitIncompatibleWithProductPackagingException;
  }
  if (null !== result.quantityMustBeNonnegativeException) {
    throw result.quantityMustBeNonnegativeException;
  }
  if (null !== result.AtMostOneQuantityIsAllowedForProductAndUnitException) {
    throw result.AtMostOneQuantityIsAllowedForProductAndUnitException;
  }
  if (null !== result.productsHaveNoMappingAtPartnerLocationException) {
    throw result.productsHaveNoMappingAtPartnerLocationException;
  }
  if (null !== result.productNotAssociatedWithLocationException) {
    throw result.productNotAssociatedWithLocationException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'createTransferReport failed: unknown result';
};
InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.updateTransferReport = function(actor, reportIdentifier, update, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_updateTransferReport(pendingError, actor, reportIdentifier, update, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_updateTransferReport();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.send_updateTransferReport = function(pendingError, actor, reportIdentifier, update, callback) {
  this.output.writeMessageBegin('updateTransferReport', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryTransferReportService.InventoryTransferReportService_updateTransferReport_args();
  args.actor = actor;
  args.reportIdentifier = reportIdentifier;
  args.update = update;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_updateTransferReport() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.recv_updateTransferReport = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryTransferReportService.InventoryTransferReportService_updateTransferReport_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.unknownReportIdentifierException) {
    throw result.unknownReportIdentifierException;
  }
  if (null !== result.actorDoesNotHaveAccessToReportException) {
    throw result.actorDoesNotHaveAccessToReportException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.unitIncompatibleWithProductPackagingException) {
    throw result.unitIncompatibleWithProductPackagingException;
  }
  if (null !== result.quantityMustBeNonnegativeException) {
    throw result.quantityMustBeNonnegativeException;
  }
  if (null !== result.quantityOfProductAndUnitDoesNotExistException) {
    throw result.quantityOfProductAndUnitDoesNotExistException;
  }
  if (null !== result.atMostOneQuantityIsAllowedForProductAndUnitException) {
    throw result.atMostOneQuantityIsAllowedForProductAndUnitException;
  }
  if (null !== result.productsHaveNoMappingAtPartnerLocationException) {
    throw result.productsHaveNoMappingAtPartnerLocationException;
  }
  if (null !== result.productNotAssociatedWithLocationException) {
    throw result.productNotAssociatedWithLocationException;
  }
  return;
};
InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.updateTransferReportRequisitionStep = function(actor, reportIdentifier, requisitionStep, cancellationReason, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_updateTransferReportRequisitionStep(pendingError, actor, reportIdentifier, requisitionStep, cancellationReason, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_updateTransferReportRequisitionStep();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.send_updateTransferReportRequisitionStep = function(pendingError, actor, reportIdentifier, requisitionStep, cancellationReason, callback) {
  this.output.writeMessageBegin('updateTransferReportRequisitionStep', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryTransferReportService.InventoryTransferReportService_updateTransferReportRequisitionStep_args();
  args.actor = actor;
  args.reportIdentifier = reportIdentifier;
  args.requisitionStep = requisitionStep;
  args.cancellationReason = cancellationReason;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_updateTransferReportRequisitionStep() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.recv_updateTransferReportRequisitionStep = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryTransferReportService.InventoryTransferReportService_updateTransferReportRequisitionStep_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.unknownReportIdentifierException) {
    throw result.unknownReportIdentifierException;
  }
  if (null !== result.actorDoesNotHaveAccessToReportException) {
    throw result.actorDoesNotHaveAccessToReportException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.unitIncompatibleWithProductPackagingException) {
    throw result.unitIncompatibleWithProductPackagingException;
  }
  if (null !== result.quantityMustBeNonnegativeException) {
    throw result.quantityMustBeNonnegativeException;
  }
  if (null !== result.quantityOfProductAndUnitDoesNotExistException) {
    throw result.quantityOfProductAndUnitDoesNotExistException;
  }
  if (null !== result.atMostOneQuantityIsAllowedForProductAndUnitException) {
    throw result.atMostOneQuantityIsAllowedForProductAndUnitException;
  }
  if (null !== result.productsHaveNoMappingAtPartnerLocationException) {
    throw result.productsHaveNoMappingAtPartnerLocationException;
  }
  if (null !== result.productNotAssociatedWithLocationException) {
    throw result.productNotAssociatedWithLocationException;
  }
  return;
};
InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.updateCustomPartnerLocationName = function(actor, reportIdentifier, value, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_updateCustomPartnerLocationName(pendingError, actor, reportIdentifier, value, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_updateCustomPartnerLocationName();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.send_updateCustomPartnerLocationName = function(pendingError, actor, reportIdentifier, value, callback) {
  this.output.writeMessageBegin('updateCustomPartnerLocationName', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryTransferReportService.InventoryTransferReportService_updateCustomPartnerLocationName_args();
  args.actor = actor;
  args.reportIdentifier = reportIdentifier;
  args.value = value;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_updateCustomPartnerLocationName() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.recv_updateCustomPartnerLocationName = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryTransferReportService.InventoryTransferReportService_updateCustomPartnerLocationName_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.transferHasRetailerPartnerLocationException) {
    throw result.transferHasRetailerPartnerLocationException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.actorDoesNotHaveAccessToReportException) {
    throw result.actorDoesNotHaveAccessToReportException;
  }
  if (null !== result.unknownReportIdentifierException) {
    throw result.unknownReportIdentifierException;
  }
  return;
};
InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.getTransferReportsWithoutCostsById = function(actor, reportIdentifiers, perspective, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getTransferReportsWithoutCostsById(pendingError, actor, reportIdentifiers, perspective, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getTransferReportsWithoutCostsById();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.send_getTransferReportsWithoutCostsById = function(pendingError, actor, reportIdentifiers, perspective, callback) {
  this.output.writeMessageBegin('getTransferReportsWithoutCostsById', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryTransferReportService.InventoryTransferReportService_getTransferReportsWithoutCostsById_args();
  args.actor = actor;
  args.reportIdentifiers = reportIdentifiers;
  args.perspective = perspective;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getTransferReportsWithoutCostsById() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.recv_getTransferReportsWithoutCostsById = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryTransferReportService.InventoryTransferReportService_getTransferReportsWithoutCostsById_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.unknownReportIdentifierException) {
    throw result.unknownReportIdentifierException;
  }
  if (null !== result.actorDoesNotHaveAccessToPerspectiveLocationException) {
    throw result.actorDoesNotHaveAccessToPerspectiveLocationException;
  }
  if (null !== result.perspectiveLocationNotInvolvedInReportException) {
    throw result.perspectiveLocationNotInvolvedInReportException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getTransferReportsWithoutCostsById failed: unknown result';
};
InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.getTransferReportsByIds = function(actor, reportIdentifiers, perspective, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getTransferReportsByIds(pendingError, actor, reportIdentifiers, perspective, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getTransferReportsByIds();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.send_getTransferReportsByIds = function(pendingError, actor, reportIdentifiers, perspective, callback) {
  this.output.writeMessageBegin('getTransferReportsByIds', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryTransferReportService.InventoryTransferReportService_getTransferReportsByIds_args();
  args.actor = actor;
  args.reportIdentifiers = reportIdentifiers;
  args.perspective = perspective;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getTransferReportsByIds() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.recv_getTransferReportsByIds = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryTransferReportService.InventoryTransferReportService_getTransferReportsByIds_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.unknownReportIdentifierException) {
    throw result.unknownReportIdentifierException;
  }
  if (null !== result.actorDoesNotHaveAccessToPerspectiveLocationException) {
    throw result.actorDoesNotHaveAccessToPerspectiveLocationException;
  }
  if (null !== result.perspectiveLocationNotInvolvedInReportException) {
    throw result.perspectiveLocationNotInvolvedInReportException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getTransferReportsByIds failed: unknown result';
};
InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.getReportsForLocationInPeriod = function(actor, locationIdentifier, periodStartInclusive, periodEndExclusive, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getReportsForLocationInPeriod(pendingError, actor, locationIdentifier, periodStartInclusive, periodEndExclusive, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getReportsForLocationInPeriod();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.send_getReportsForLocationInPeriod = function(pendingError, actor, locationIdentifier, periodStartInclusive, periodEndExclusive, callback) {
  this.output.writeMessageBegin('getReportsForLocationInPeriod', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryTransferReportService.InventoryTransferReportService_getReportsForLocationInPeriod_args();
  args.actor = actor;
  args.locationIdentifier = locationIdentifier;
  args.periodStartInclusive = periodStartInclusive;
  args.periodEndExclusive = periodEndExclusive;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getReportsForLocationInPeriod() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.recv_getReportsForLocationInPeriod = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryTransferReportService.InventoryTransferReportService_getReportsForLocationInPeriod_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.LocationIdentifierIsRequiredException) {
    throw result.LocationIdentifierIsRequiredException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.actorDoesNotHaveAccessToLocationException) {
    throw result.actorDoesNotHaveAccessToLocationException;
  }
  if (null !== result.periodStartIsLaterThanEndException) {
    throw result.periodStartIsLaterThanEndException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getReportsForLocationInPeriod failed: unknown result';
};
InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.deleteReport = function(actor, reportIdentifier, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_deleteReport(pendingError, actor, reportIdentifier, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_deleteReport();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.send_deleteReport = function(pendingError, actor, reportIdentifier, callback) {
  this.output.writeMessageBegin('deleteReport', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryTransferReportService.InventoryTransferReportService_deleteReport_args();
  args.actor = actor;
  args.reportIdentifier = reportIdentifier;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_deleteReport() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.recv_deleteReport = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryTransferReportService.InventoryTransferReportService_deleteReport_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.unknownReportIdentifierException) {
    throw result.unknownReportIdentifierException;
  }
  if (null !== result.actorDoesNotHaveAccessToReportException) {
    throw result.actorDoesNotHaveAccessToReportException;
  }
  return;
};
InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.getCustomTransferPartnerLocationNamesForLocation = function(actor, locationIdentifier, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getCustomTransferPartnerLocationNamesForLocation(pendingError, actor, locationIdentifier, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getCustomTransferPartnerLocationNamesForLocation();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.send_getCustomTransferPartnerLocationNamesForLocation = function(pendingError, actor, locationIdentifier, callback) {
  this.output.writeMessageBegin('getCustomTransferPartnerLocationNamesForLocation', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryTransferReportService.InventoryTransferReportService_getCustomTransferPartnerLocationNamesForLocation_args();
  args.actor = actor;
  args.locationIdentifier = locationIdentifier;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getCustomTransferPartnerLocationNamesForLocation() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryTransferReportService.InventoryTransferReportServiceClient.prototype.recv_getCustomTransferPartnerLocationNamesForLocation = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryTransferReportService.InventoryTransferReportService_getCustomTransferPartnerLocationNamesForLocation_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.actorDoesNotHaveAccessToLocationException) {
    throw result.actorDoesNotHaveAccessToLocationException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getCustomTransferPartnerLocationNamesForLocation failed: unknown result';
};
