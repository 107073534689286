//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


import Thrift from 'thrift';
import UserActionLogModel from './user_action_log_Model_types';
import UserAccountsModel from './accounts_user_Model_types';
import LocationModel from './location_Model_types';
import CoreTimeModel from './core_time_Model_types';
import CoreApiExceptions from './core_api_Exceptions_types';
import AccountsExceptions from './accounts_Exceptions_types';
import LocationExceptions from './location_Exceptions_types';
import UserActionLogExceptions from './user_action_log_Exceptions_types';

var UserActionLogService = {};
export default UserActionLogService;
//HELPER FUNCTIONS AND STRUCTURES

UserActionLogService.UserActionLogService_createComment_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.value = null;
  this.isAdmin = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field value is unset!');
    }
    if (args.isAdmin !== undefined && args.isAdmin !== null) {
      this.isAdmin = args.isAdmin;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field isAdmin is unset!');
    }
  }
};
UserActionLogService.UserActionLogService_createComment_args.prototype = {};
UserActionLogService.UserActionLogService_createComment_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.BOOL) {
        this.isAdmin = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserActionLogService.UserActionLogService_createComment_args.prototype.write = function(output) {
  output.writeStructBegin('UserActionLogService_createComment_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 3);
    output.writeString(this.value);
    output.writeFieldEnd();
  }
  if (this.isAdmin !== null && this.isAdmin !== undefined) {
    output.writeFieldBegin('isAdmin', Thrift.Type.BOOL, 4);
    output.writeBool(this.isAdmin);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserActionLogService.UserActionLogService_createComment_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new UserActionLogModel.UserActionLogComment(args.success);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
  }
};
UserActionLogService.UserActionLogService_createComment_result.prototype = {};
UserActionLogService.UserActionLogService_createComment_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new UserActionLogModel.UserActionLogComment();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserActionLogService.UserActionLogService_createComment_result.prototype.write = function(output) {
  output.writeStructBegin('UserActionLogService_createComment_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserActionLogService.UserActionLogService_deleteComment_args = function(args) {
  this.actor = null;
  this.commentId = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.commentId !== undefined && args.commentId !== null) {
      this.commentId = args.commentId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field commentId is unset!');
    }
  }
};
UserActionLogService.UserActionLogService_deleteComment_args.prototype = {};
UserActionLogService.UserActionLogService_deleteComment_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.commentId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserActionLogService.UserActionLogService_deleteComment_args.prototype.write = function(output) {
  output.writeStructBegin('UserActionLogService_deleteComment_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.commentId !== null && this.commentId !== undefined) {
    output.writeFieldBegin('commentId', Thrift.Type.STRING, 2);
    output.writeString(this.commentId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserActionLogService.UserActionLogService_deleteComment_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownUserActionLogCommentIdException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof UserActionLogExceptions.UnknownUserActionLogCommentIdException) {
    this.unknownUserActionLogCommentIdException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownUserActionLogCommentIdException !== undefined && args.unknownUserActionLogCommentIdException !== null) {
      this.unknownUserActionLogCommentIdException = args.unknownUserActionLogCommentIdException;
    }
  }
};
UserActionLogService.UserActionLogService_deleteComment_result.prototype = {};
UserActionLogService.UserActionLogService_deleteComment_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownUserActionLogCommentIdException = new UserActionLogExceptions.UnknownUserActionLogCommentIdException();
        this.unknownUserActionLogCommentIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserActionLogService.UserActionLogService_deleteComment_result.prototype.write = function(output) {
  output.writeStructBegin('UserActionLogService_deleteComment_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownUserActionLogCommentIdException !== null && this.unknownUserActionLogCommentIdException !== undefined) {
    output.writeFieldBegin('unknownUserActionLogCommentIdException', Thrift.Type.STRUCT, 5);
    this.unknownUserActionLogCommentIdException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserActionLogService.UserActionLogService_getCommentsCreatedInTimeRange_args = function(args) {
  this.actor = null;
  this.locationId = null;
  this.inclusiveStartDate = null;
  this.inclusiveEndDate = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.inclusiveStartDate !== undefined && args.inclusiveStartDate !== null) {
      this.inclusiveStartDate = new CoreTimeModel.TimestampWithMillisecondPrecision(args.inclusiveStartDate);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inclusiveStartDate is unset!');
    }
    if (args.inclusiveEndDate !== undefined && args.inclusiveEndDate !== null) {
      this.inclusiveEndDate = new CoreTimeModel.TimestampWithMillisecondPrecision(args.inclusiveEndDate);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inclusiveEndDate is unset!');
    }
  }
};
UserActionLogService.UserActionLogService_getCommentsCreatedInTimeRange_args.prototype = {};
UserActionLogService.UserActionLogService_getCommentsCreatedInTimeRange_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.inclusiveStartDate = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.inclusiveStartDate.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.inclusiveEndDate = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.inclusiveEndDate.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserActionLogService.UserActionLogService_getCommentsCreatedInTimeRange_args.prototype.write = function(output) {
  output.writeStructBegin('UserActionLogService_getCommentsCreatedInTimeRange_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.inclusiveStartDate !== null && this.inclusiveStartDate !== undefined) {
    output.writeFieldBegin('inclusiveStartDate', Thrift.Type.STRUCT, 3);
    this.inclusiveStartDate.write(output);
    output.writeFieldEnd();
  }
  if (this.inclusiveEndDate !== null && this.inclusiveEndDate !== undefined) {
    output.writeFieldBegin('inclusiveEndDate', Thrift.Type.STRUCT, 4);
    this.inclusiveEndDate.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserActionLogService.UserActionLogService_getCommentsCreatedInTimeRange_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.unknownLocationIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [UserActionLogModel.UserActionLogComment]);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
  }
};
UserActionLogService.UserActionLogService_getCommentsCreatedInTimeRange_result.prototype = {};
UserActionLogService.UserActionLogService_getCommentsCreatedInTimeRange_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.success = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new UserActionLogModel.UserActionLogComment();
          elem6.read(input);
          this.success.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserActionLogService.UserActionLogService_getCommentsCreatedInTimeRange_result.prototype.write = function(output) {
  output.writeStructBegin('UserActionLogService_getCommentsCreatedInTimeRange_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter7 in this.success)
    {
      if (this.success.hasOwnProperty(iter7))
      {
        iter7 = this.success[iter7];
        iter7.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 4);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 5);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserActionLogService.UserActionLogServiceClient = function(input, output) {
    this.input = input;
    this.output = (!output) ? input : output;
    this.seqid = 0;
};
UserActionLogService.UserActionLogServiceClient.prototype = {};
UserActionLogService.UserActionLogServiceClient.prototype.createComment = function(actor, locationId, value, isAdmin, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_createComment(pendingError, actor, locationId, value, isAdmin, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_createComment();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

UserActionLogService.UserActionLogServiceClient.prototype.send_createComment = function(pendingError, actor, locationId, value, isAdmin, callback) {
  this.output.writeMessageBegin('createComment', Thrift.MessageType.CALL, this.seqid);
  var args = new UserActionLogService.UserActionLogService_createComment_args();
  args.actor = actor;
  args.locationId = locationId;
  args.value = value;
  args.isAdmin = isAdmin;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_createComment() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

UserActionLogService.UserActionLogServiceClient.prototype.recv_createComment = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new UserActionLogService.UserActionLogService_createComment_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'createComment failed: unknown result';
};
UserActionLogService.UserActionLogServiceClient.prototype.deleteComment = function(actor, commentId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_deleteComment(pendingError, actor, commentId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_deleteComment();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

UserActionLogService.UserActionLogServiceClient.prototype.send_deleteComment = function(pendingError, actor, commentId, callback) {
  this.output.writeMessageBegin('deleteComment', Thrift.MessageType.CALL, this.seqid);
  var args = new UserActionLogService.UserActionLogService_deleteComment_args();
  args.actor = actor;
  args.commentId = commentId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_deleteComment() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

UserActionLogService.UserActionLogServiceClient.prototype.recv_deleteComment = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new UserActionLogService.UserActionLogService_deleteComment_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownUserActionLogCommentIdException) {
    throw result.unknownUserActionLogCommentIdException;
  }
  return;
};
UserActionLogService.UserActionLogServiceClient.prototype.getCommentsCreatedInTimeRange = function(actor, locationId, inclusiveStartDate, inclusiveEndDate, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getCommentsCreatedInTimeRange(pendingError, actor, locationId, inclusiveStartDate, inclusiveEndDate, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getCommentsCreatedInTimeRange();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

UserActionLogService.UserActionLogServiceClient.prototype.send_getCommentsCreatedInTimeRange = function(pendingError, actor, locationId, inclusiveStartDate, inclusiveEndDate, callback) {
  this.output.writeMessageBegin('getCommentsCreatedInTimeRange', Thrift.MessageType.CALL, this.seqid);
  var args = new UserActionLogService.UserActionLogService_getCommentsCreatedInTimeRange_args();
  args.actor = actor;
  args.locationId = locationId;
  args.inclusiveStartDate = inclusiveStartDate;
  args.inclusiveEndDate = inclusiveEndDate;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getCommentsCreatedInTimeRange() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

UserActionLogService.UserActionLogServiceClient.prototype.recv_getCommentsCreatedInTimeRange = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new UserActionLogService.UserActionLogService_getCommentsCreatedInTimeRange_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getCommentsCreatedInTimeRange failed: unknown result';
};
