import React from 'react';

import { BevSpotDatePickerCalendarInput } from './BevSpotDatePickerCalendarInput';
import { BevSpotDatePickerMonthSelector } from './BevSpotDatePickerMonthSelector';

import { CalendarDay } from '../../models/CalendarDay';
import { CalendarMonth } from '../../models/CalendarMonth';
import { DateTime } from '../../models/DateTime';
import { DayOfWeekName } from '../../models/DayOfWeekName';
import { MonthOfYear } from '../../models/MonthOfYear';

import { BevSpotDatePickerUtils } from './utils';

export interface IBevSpotDatePicker {
    selectedDateTime : DateTime;
    setSelectedDateTime : (dateTime : DateTime) => void;
    setVisibleMonth : (visibleMonth : MonthOfYear) => void;
    setVisibleYear : (visibleYear : number) => void;
    visibleMonth : MonthOfYear;
    visibleYear : number;
}
export class BevSpotDatePicker extends React.Component<IBevSpotDatePicker, object> {
    public render() {
        const {
            selectedDateTime,
            setSelectedDateTime,
            setVisibleMonth,
            setVisibleYear,
            visibleMonth,
            visibleYear,
        } = this.props;

        const numberOfDaysInMonthForGivenYear : number = BevSpotDatePickerUtils.getNumberOfDaysInMonthForGivenYear(visibleMonth, visibleYear);
        const dayOfWeekNameOfFirstDayOfMonthForGivenYear : DayOfWeekName = BevSpotDatePickerUtils.getDayOfWeekNameOfFirstDayInMonthForGivenYear(visibleMonth, visibleYear);
        const firstDayOfMonth : CalendarDay = new CalendarDay({
            dayOfWeekName: dayOfWeekNameOfFirstDayOfMonthForGivenYear,
            numericalDayOfMonth: 1,
        });
        const visibleCalendarMonth : CalendarMonth = new CalendarMonth({
            firstDayOfMonth,
            numberOfDaysInMonth: numberOfDaysInMonthForGivenYear,
        });
        return (
            <div className="bevspot-date-picker">
                <BevSpotDatePickerMonthSelector
                    dateTime={ selectedDateTime }
                    setVisibleMonth={ setVisibleMonth }
                    setVisibleYear={ setVisibleYear }
                    visibleMonth={ visibleMonth }
                    visibleYear={ visibleYear }
                />
                <BevSpotDatePickerCalendarInput
                    calendarMonth={ visibleCalendarMonth }
                    selectedDateTime={ selectedDateTime }
                    setSelectedDateTime={ setSelectedDateTime }
                    visibleMonth={ visibleMonth }
                    visibleYear={ visibleYear }
                />
            </div>
        );
    }
}
