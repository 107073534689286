import React from 'react';

import { Shape as ShapeModel } from 'shared/models/Charts/Shape';
import { HexColor } from 'shared/models/HexColor';

import { Shape } from 'shared/components/Charts/Shape';

import { legendItemSquareLengthInPixels } from 'shared/components/Charts/constants';

export interface ILegendItemProps {
    readonly name : string;
    readonly shape : ShapeModel;
    readonly color : HexColor;
}

export class LegendItem extends React.Component<ILegendItemProps, object> {
    private readonly classNamePrefix = 'chart-legend-item';

    public render() {
        const className : string = this.classNamePrefix + '-container cell chart-legend-cell';

        return (
            <div
                className={ className }
            >
                { this.createShape() }
                { this.createName() }
            </div>
        );
    }

    private createShape = () : JSX.Element => {
        const {
            shape,
            color,
        } = this.props;

        const className : string = this.classNamePrefix + '-shape';

        return (
            <div className={ className }>
                <Shape
                    shape={ shape }
                    color={ color }
                    boundingBox={ {
                        heightInPixels: legendItemSquareLengthInPixels,
                        widthInPixels: legendItemSquareLengthInPixels,
                    } }
                />
            </div>
        );
    }

    private createName = () : JSX.Element => {
        const {
            name,
        } = this.props;

        const className : string = this.classNamePrefix + '-name';

        return (
            <div className={ className }>
                { name }
            </div>
        );
    }
}
