//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';
import CoreMathModel from './core_math_Model_types';
import CoreTimeModel from './core_time_Model_types';
import SalesReportModel from './sales_report_model_types';
import LocationModel from './location_Model_types';



var IntegrationModel = {};
export default IntegrationModel;
IntegrationModel.PosIntegrationType = {
  '0' : 'LIGHTSPEED',
  'LIGHTSPEED' : 0,
  '1' : 'SQUARE',
  'SQUARE' : 1,
  '2' : 'BREADCRUMB',
  'BREADCRUMB' : 2,
  '3' : 'TOAST',
  'TOAST' : 3,
  '4' : 'OMNIVORE',
  'OMNIVORE' : 4,
  '5' : 'CLOVER',
  'CLOVER' : 5,
  '6' : 'K_SERIES',
  'K_SERIES' : 6,
  '7' : 'SPOTON',
  'SPOTON' : 7,
  '8' : 'HEARTLAND',
  'HEARTLAND' : 8
};
IntegrationModel.ExternalMerchantId = function(args) {
  this.value = null;
  if (args) {
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field value is unset!');
    }
  }
};
IntegrationModel.ExternalMerchantId.prototype = {};
IntegrationModel.ExternalMerchantId.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationModel.ExternalMerchantId.prototype.write = function(output) {
  output.writeStructBegin('ExternalMerchantId');
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 1);
    output.writeString(this.value);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationModel.IntegrationState = function(args) {
  this.externalMerchantId = null;
  this.isAccessible = null;
  this.cacheDataEffectiveTime = null;
  if (args) {
    if (args.externalMerchantId !== undefined && args.externalMerchantId !== null) {
      this.externalMerchantId = new IntegrationModel.ExternalMerchantId(args.externalMerchantId);
    }
    if (args.isAccessible !== undefined && args.isAccessible !== null) {
      this.isAccessible = args.isAccessible;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field isAccessible is unset!');
    }
    if (args.cacheDataEffectiveTime !== undefined && args.cacheDataEffectiveTime !== null) {
      this.cacheDataEffectiveTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.cacheDataEffectiveTime);
    }
  }
};
IntegrationModel.IntegrationState.prototype = {};
IntegrationModel.IntegrationState.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.externalMerchantId = new IntegrationModel.ExternalMerchantId();
        this.externalMerchantId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.BOOL) {
        this.isAccessible = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.cacheDataEffectiveTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.cacheDataEffectiveTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationModel.IntegrationState.prototype.write = function(output) {
  output.writeStructBegin('IntegrationState');
  if (this.externalMerchantId !== null && this.externalMerchantId !== undefined) {
    output.writeFieldBegin('externalMerchantId', Thrift.Type.STRUCT, 1);
    this.externalMerchantId.write(output);
    output.writeFieldEnd();
  }
  if (this.isAccessible !== null && this.isAccessible !== undefined) {
    output.writeFieldBegin('isAccessible', Thrift.Type.BOOL, 2);
    output.writeBool(this.isAccessible);
    output.writeFieldEnd();
  }
  if (this.cacheDataEffectiveTime !== null && this.cacheDataEffectiveTime !== undefined) {
    output.writeFieldBegin('cacheDataEffectiveTime', Thrift.Type.STRUCT, 3);
    this.cacheDataEffectiveTime.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationModel.OmnivoreIntegrationState = function(args) {
  this.externalMerchantId = null;
  this.posType = null;
  this.isAccessible = null;
  this.cacheDataEffectiveTime = null;
  if (args) {
    if (args.externalMerchantId !== undefined && args.externalMerchantId !== null) {
      this.externalMerchantId = new IntegrationModel.ExternalMerchantId(args.externalMerchantId);
    }
    if (args.posType !== undefined && args.posType !== null) {
      this.posType = args.posType;
    }
    if (args.isAccessible !== undefined && args.isAccessible !== null) {
      this.isAccessible = args.isAccessible;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field isAccessible is unset!');
    }
    if (args.cacheDataEffectiveTime !== undefined && args.cacheDataEffectiveTime !== null) {
      this.cacheDataEffectiveTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.cacheDataEffectiveTime);
    }
  }
};
IntegrationModel.OmnivoreIntegrationState.prototype = {};
IntegrationModel.OmnivoreIntegrationState.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.externalMerchantId = new IntegrationModel.ExternalMerchantId();
        this.externalMerchantId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.posType = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.BOOL) {
        this.isAccessible = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.cacheDataEffectiveTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.cacheDataEffectiveTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationModel.OmnivoreIntegrationState.prototype.write = function(output) {
  output.writeStructBegin('OmnivoreIntegrationState');
  if (this.externalMerchantId !== null && this.externalMerchantId !== undefined) {
    output.writeFieldBegin('externalMerchantId', Thrift.Type.STRUCT, 1);
    this.externalMerchantId.write(output);
    output.writeFieldEnd();
  }
  if (this.posType !== null && this.posType !== undefined) {
    output.writeFieldBegin('posType', Thrift.Type.STRING, 2);
    output.writeString(this.posType);
    output.writeFieldEnd();
  }
  if (this.isAccessible !== null && this.isAccessible !== undefined) {
    output.writeFieldBegin('isAccessible', Thrift.Type.BOOL, 3);
    output.writeBool(this.isAccessible);
    output.writeFieldEnd();
  }
  if (this.cacheDataEffectiveTime !== null && this.cacheDataEffectiveTime !== undefined) {
    output.writeFieldBegin('cacheDataEffectiveTime', Thrift.Type.STRUCT, 4);
    this.cacheDataEffectiveTime.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationModel.PosItem = function(args) {
  this.posItemId = null;
  this.name = null;
  this.menuGroup = null;
  if (args) {
    if (args.posItemId !== undefined && args.posItemId !== null) {
      this.posItemId = args.posItemId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field posItemId is unset!');
    }
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field name is unset!');
    }
    if (args.menuGroup !== undefined && args.menuGroup !== null) {
      this.menuGroup = args.menuGroup;
    }
  }
};
IntegrationModel.PosItem.prototype = {};
IntegrationModel.PosItem.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.posItemId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.menuGroup = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationModel.PosItem.prototype.write = function(output) {
  output.writeStructBegin('PosItem');
  if (this.posItemId !== null && this.posItemId !== undefined) {
    output.writeFieldBegin('posItemId', Thrift.Type.STRING, 1);
    output.writeString(this.posItemId);
    output.writeFieldEnd();
  }
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 2);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.menuGroup !== null && this.menuGroup !== undefined) {
    output.writeFieldBegin('menuGroup', Thrift.Type.STRING, 3);
    output.writeString(this.menuGroup);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationModel.PosItemAndSalesEntry = function(args) {
  this.posItem = null;
  this.salesEntry = null;
  if (args) {
    if (args.posItem !== undefined && args.posItem !== null) {
      this.posItem = new IntegrationModel.PosItem(args.posItem);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field posItem is unset!');
    }
    if (args.salesEntry !== undefined && args.salesEntry !== null) {
      this.salesEntry = new SalesReportModel.SalesEntry(args.salesEntry);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field salesEntry is unset!');
    }
  }
};
IntegrationModel.PosItemAndSalesEntry.prototype = {};
IntegrationModel.PosItemAndSalesEntry.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.posItem = new IntegrationModel.PosItem();
        this.posItem.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.salesEntry = new SalesReportModel.SalesEntry();
        this.salesEntry.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationModel.PosItemAndSalesEntry.prototype.write = function(output) {
  output.writeStructBegin('PosItemAndSalesEntry');
  if (this.posItem !== null && this.posItem !== undefined) {
    output.writeFieldBegin('posItem', Thrift.Type.STRUCT, 1);
    this.posItem.write(output);
    output.writeFieldEnd();
  }
  if (this.salesEntry !== null && this.salesEntry !== undefined) {
    output.writeFieldBegin('salesEntry', Thrift.Type.STRUCT, 2);
    this.salesEntry.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationModel.PmixItem = function(args) {
  this.posItemId = null;
  this.posName = null;
  this.type = null;
  this.price = null;
  this.quantitySold = null;
  this.quantityComped = null;
  this.valueComped = null;
  if (args) {
    if (args.posItemId !== undefined && args.posItemId !== null) {
      this.posItemId = args.posItemId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field posItemId is unset!');
    }
    if (args.posName !== undefined && args.posName !== null) {
      this.posName = args.posName;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field posName is unset!');
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field type is unset!');
    }
    if (args.price !== undefined && args.price !== null) {
      this.price = new CoreMathModel.Decimal(args.price);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field price is unset!');
    }
    if (args.quantitySold !== undefined && args.quantitySold !== null) {
      this.quantitySold = new CoreMathModel.Decimal(args.quantitySold);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field quantitySold is unset!');
    }
    if (args.quantityComped !== undefined && args.quantityComped !== null) {
      this.quantityComped = new CoreMathModel.Decimal(args.quantityComped);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field quantityComped is unset!');
    }
    if (args.valueComped !== undefined && args.valueComped !== null) {
      this.valueComped = new CoreMathModel.Decimal(args.valueComped);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field valueComped is unset!');
    }
  }
};
IntegrationModel.PmixItem.prototype = {};
IntegrationModel.PmixItem.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.posItemId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.posName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.type = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.price = new CoreMathModel.Decimal();
        this.price.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.quantitySold = new CoreMathModel.Decimal();
        this.quantitySold.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.quantityComped = new CoreMathModel.Decimal();
        this.quantityComped.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.valueComped = new CoreMathModel.Decimal();
        this.valueComped.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationModel.PmixItem.prototype.write = function(output) {
  output.writeStructBegin('PmixItem');
  if (this.posItemId !== null && this.posItemId !== undefined) {
    output.writeFieldBegin('posItemId', Thrift.Type.STRING, 1);
    output.writeString(this.posItemId);
    output.writeFieldEnd();
  }
  if (this.posName !== null && this.posName !== undefined) {
    output.writeFieldBegin('posName', Thrift.Type.STRING, 2);
    output.writeString(this.posName);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.STRING, 3);
    output.writeString(this.type);
    output.writeFieldEnd();
  }
  if (this.price !== null && this.price !== undefined) {
    output.writeFieldBegin('price', Thrift.Type.STRUCT, 4);
    this.price.write(output);
    output.writeFieldEnd();
  }
  if (this.quantitySold !== null && this.quantitySold !== undefined) {
    output.writeFieldBegin('quantitySold', Thrift.Type.STRUCT, 5);
    this.quantitySold.write(output);
    output.writeFieldEnd();
  }
  if (this.quantityComped !== null && this.quantityComped !== undefined) {
    output.writeFieldBegin('quantityComped', Thrift.Type.STRUCT, 6);
    this.quantityComped.write(output);
    output.writeFieldEnd();
  }
  if (this.valueComped !== null && this.valueComped !== undefined) {
    output.writeFieldBegin('valueComped', Thrift.Type.STRUCT, 7);
    this.valueComped.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationModel.SalesImportResult = function(args) {
  this.dataEffectiveTime = null;
  this.salesEntries = null;
  this.unmappedPosItemAndSalesEntries = null;
  this.ignoredPosItemAndSalesEntries = null;
  if (args) {
    if (args.dataEffectiveTime !== undefined && args.dataEffectiveTime !== null) {
      this.dataEffectiveTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.dataEffectiveTime);
    }
    if (args.salesEntries !== undefined && args.salesEntries !== null) {
      this.salesEntries = Thrift.copyList(args.salesEntries, [SalesReportModel.SalesItemIdAndSalesEntry]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field salesEntries is unset!');
    }
    if (args.unmappedPosItemAndSalesEntries !== undefined && args.unmappedPosItemAndSalesEntries !== null) {
      this.unmappedPosItemAndSalesEntries = Thrift.copyList(args.unmappedPosItemAndSalesEntries, [IntegrationModel.PosItemAndSalesEntry]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field unmappedPosItemAndSalesEntries is unset!');
    }
    if (args.ignoredPosItemAndSalesEntries !== undefined && args.ignoredPosItemAndSalesEntries !== null) {
      this.ignoredPosItemAndSalesEntries = Thrift.copyList(args.ignoredPosItemAndSalesEntries, [IntegrationModel.PosItemAndSalesEntry]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field ignoredPosItemAndSalesEntries is unset!');
    }
  }
};
IntegrationModel.SalesImportResult.prototype = {};
IntegrationModel.SalesImportResult.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.dataEffectiveTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.dataEffectiveTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.salesEntries = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new SalesReportModel.SalesItemIdAndSalesEntry();
          elem6.read(input);
          this.salesEntries.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        var _size7 = 0;
        var _rtmp311;
        this.unmappedPosItemAndSalesEntries = [];
        var _etype10 = 0;
        _rtmp311 = input.readListBegin();
        _etype10 = _rtmp311.etype;
        _size7 = _rtmp311.size;
        for (var _i12 = 0; _i12 < _size7; ++_i12)
        {
          var elem13 = null;
          elem13 = new IntegrationModel.PosItemAndSalesEntry();
          elem13.read(input);
          this.unmappedPosItemAndSalesEntries.push(elem13);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.LIST) {
        var _size14 = 0;
        var _rtmp318;
        this.ignoredPosItemAndSalesEntries = [];
        var _etype17 = 0;
        _rtmp318 = input.readListBegin();
        _etype17 = _rtmp318.etype;
        _size14 = _rtmp318.size;
        for (var _i19 = 0; _i19 < _size14; ++_i19)
        {
          var elem20 = null;
          elem20 = new IntegrationModel.PosItemAndSalesEntry();
          elem20.read(input);
          this.ignoredPosItemAndSalesEntries.push(elem20);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationModel.SalesImportResult.prototype.write = function(output) {
  output.writeStructBegin('SalesImportResult');
  if (this.dataEffectiveTime !== null && this.dataEffectiveTime !== undefined) {
    output.writeFieldBegin('dataEffectiveTime', Thrift.Type.STRUCT, 1);
    this.dataEffectiveTime.write(output);
    output.writeFieldEnd();
  }
  if (this.salesEntries !== null && this.salesEntries !== undefined) {
    output.writeFieldBegin('salesEntries', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.salesEntries.length);
    for (var iter21 in this.salesEntries)
    {
      if (this.salesEntries.hasOwnProperty(iter21))
      {
        iter21 = this.salesEntries[iter21];
        iter21.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.unmappedPosItemAndSalesEntries !== null && this.unmappedPosItemAndSalesEntries !== undefined) {
    output.writeFieldBegin('unmappedPosItemAndSalesEntries', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRUCT, this.unmappedPosItemAndSalesEntries.length);
    for (var iter22 in this.unmappedPosItemAndSalesEntries)
    {
      if (this.unmappedPosItemAndSalesEntries.hasOwnProperty(iter22))
      {
        iter22 = this.unmappedPosItemAndSalesEntries[iter22];
        iter22.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.ignoredPosItemAndSalesEntries !== null && this.ignoredPosItemAndSalesEntries !== undefined) {
    output.writeFieldBegin('ignoredPosItemAndSalesEntries', Thrift.Type.LIST, 4);
    output.writeListBegin(Thrift.Type.STRUCT, this.ignoredPosItemAndSalesEntries.length);
    for (var iter23 in this.ignoredPosItemAndSalesEntries)
    {
      if (this.ignoredPosItemAndSalesEntries.hasOwnProperty(iter23))
      {
        iter23 = this.ignoredPosItemAndSalesEntries[iter23];
        iter23.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationModel.PosLocationMapping = function(args) {
  this.locationId = null;
  this.posLocationId = null;
  if (args) {
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.posLocationId !== undefined && args.posLocationId !== null) {
      this.posLocationId = args.posLocationId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field posLocationId is unset!');
    }
  }
};
IntegrationModel.PosLocationMapping.prototype = {};
IntegrationModel.PosLocationMapping.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.posLocationId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationModel.PosLocationMapping.prototype.write = function(output) {
  output.writeStructBegin('PosLocationMapping');
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 1);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.posLocationId !== null && this.posLocationId !== undefined) {
    output.writeFieldBegin('posLocationId', Thrift.Type.STRING, 2);
    output.writeString(this.posLocationId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationModel.PosLocation = function(args) {
  this.id = null;
  this.name = null;
  this.posHistoryStart = null;
  this.cacheDataEffectiveTime = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field id is unset!');
    }
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field name is unset!');
    }
    if (args.posHistoryStart !== undefined && args.posHistoryStart !== null) {
      this.posHistoryStart = new CoreTimeModel.TimestampWithMillisecondPrecision(args.posHistoryStart);
    }
    if (args.cacheDataEffectiveTime !== undefined && args.cacheDataEffectiveTime !== null) {
      this.cacheDataEffectiveTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.cacheDataEffectiveTime);
    }
  }
};
IntegrationModel.PosLocation.prototype = {};
IntegrationModel.PosLocation.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.posHistoryStart = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.posHistoryStart.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.cacheDataEffectiveTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.cacheDataEffectiveTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationModel.PosLocation.prototype.write = function(output) {
  output.writeStructBegin('PosLocation');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 2);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.posHistoryStart !== null && this.posHistoryStart !== undefined) {
    output.writeFieldBegin('posHistoryStart', Thrift.Type.STRUCT, 3);
    this.posHistoryStart.write(output);
    output.writeFieldEnd();
  }
  if (this.cacheDataEffectiveTime !== null && this.cacheDataEffectiveTime !== undefined) {
    output.writeFieldBegin('cacheDataEffectiveTime', Thrift.Type.STRUCT, 4);
    this.cacheDataEffectiveTime.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

IntegrationModel.ListIntegrationResult = function(args) {
  this.posLocationConnections = null;
  this.accessibleIntegrationLocationMetadata = null;
  if (args) {
    if (args.posLocationConnections !== undefined && args.posLocationConnections !== null) {
      this.posLocationConnections = Thrift.copyList(args.posLocationConnections, [IntegrationModel.PosLocationMapping]);
    }
    if (args.accessibleIntegrationLocationMetadata !== undefined && args.accessibleIntegrationLocationMetadata !== null) {
      this.accessibleIntegrationLocationMetadata = Thrift.copyList(args.accessibleIntegrationLocationMetadata, [IntegrationModel.PosLocation]);
    }
  }
};
IntegrationModel.ListIntegrationResult.prototype = {};
IntegrationModel.ListIntegrationResult.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size24 = 0;
        var _rtmp328;
        this.posLocationConnections = [];
        var _etype27 = 0;
        _rtmp328 = input.readListBegin();
        _etype27 = _rtmp328.etype;
        _size24 = _rtmp328.size;
        for (var _i29 = 0; _i29 < _size24; ++_i29)
        {
          var elem30 = null;
          elem30 = new IntegrationModel.PosLocationMapping();
          elem30.read(input);
          this.posLocationConnections.push(elem30);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size31 = 0;
        var _rtmp335;
        this.accessibleIntegrationLocationMetadata = [];
        var _etype34 = 0;
        _rtmp335 = input.readListBegin();
        _etype34 = _rtmp335.etype;
        _size31 = _rtmp335.size;
        for (var _i36 = 0; _i36 < _size31; ++_i36)
        {
          var elem37 = null;
          elem37 = new IntegrationModel.PosLocation();
          elem37.read(input);
          this.accessibleIntegrationLocationMetadata.push(elem37);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntegrationModel.ListIntegrationResult.prototype.write = function(output) {
  output.writeStructBegin('ListIntegrationResult');
  if (this.posLocationConnections !== null && this.posLocationConnections !== undefined) {
    output.writeFieldBegin('posLocationConnections', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.posLocationConnections.length);
    for (var iter38 in this.posLocationConnections)
    {
      if (this.posLocationConnections.hasOwnProperty(iter38))
      {
        iter38 = this.posLocationConnections[iter38];
        iter38.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.accessibleIntegrationLocationMetadata !== null && this.accessibleIntegrationLocationMetadata !== undefined) {
    output.writeFieldBegin('accessibleIntegrationLocationMetadata', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.accessibleIntegrationLocationMetadata.length);
    for (var iter39 in this.accessibleIntegrationLocationMetadata)
    {
      if (this.accessibleIntegrationLocationMetadata.hasOwnProperty(iter39))
      {
        iter39 = this.accessibleIntegrationLocationMetadata[iter39];
        iter39.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

