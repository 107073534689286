export class FieldSpec<FieldNameTypes, InputTypeTypes, FieldTypes> {
    constructor(
        readonly name : FieldNameTypes,
        readonly type : InputTypeTypes,
        readonly displayName : string,
        readonly errorMessage : string,
        readonly subField : boolean,
        readonly onChangeVerifyFunction : ((value : FieldTypes) => boolean) | null,
        readonly onBlurVerifyFunction : ((value : FieldTypes) => boolean) | null,
    ) { }

    public onChangeVerify = (value : FieldTypes) : boolean =>
        (this.onChangeVerifyFunction === null || this.onChangeVerifyFunction(value))

    public onBlurVerify = (value : FieldTypes) : boolean =>
        (this.onBlurVerifyFunction === null || this.onBlurVerifyFunction(value))
}
