import React from 'react';

import { SalesItemUtils } from 'api/SalesItem/utils/SalesItemUtils';

import { CreateOrEditSalesItemFormUtils, NOT_CALCULABLE_FIELD_VALUE } from '../utils/CreateOrEditSalesItemFormUtils';

import { Select2DropdownMenuTheme } from 'shared/components/Select2Dropdown/GenericSelect2DropdownMenu';
import { OptionsAndLabelNameTuples, Select2DropdownMenu } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { ValidationInput, ValidationInputTheme } from 'shared/components/ValidationInput';
import { FormatMonetaryValueWithCents } from 'shared/utils/FormatMonetaryValue';

import { IngredientFormFieldName, ISalesItemForm, ISalesItemFormData } from '../reducers/reducers';

import '../css/SubRecipeInfo.scss';

interface ISubRecipeInfoProps {
    salesItemForm : ISalesItemForm;
    salesItemFormData : ISalesItemFormData;
    onIngredientInfoFormFieldChange : (fieldName : IngredientFormFieldName, value : string) => void;
    salesItemCostOnForm : string;
    salesItemPriceOnForm : string;
}

export class SubRecipeInfo extends React.Component<ISubRecipeInfoProps, object> {
    private servingSizeAndYieldUnitDropdownOptions : OptionsAndLabelNameTuples;

    constructor(props : ISubRecipeInfoProps) {
        super(props);

        this.servingSizeAndYieldUnitDropdownOptions = CreateOrEditSalesItemFormUtils.getServingSizeAndYieldDropdownOptions();
    }

    public render() {
        const {
            salesItemCostOnForm,
            salesItemPriceOnForm,
        } = this.props;

        const validationInputDataByFieldName = this.props.salesItemForm.ingredientForm;

        const yieldUnitSelectedOption = this.servingSizeAndYieldUnitDropdownOptions[0][1].find((option) => {
            return validationInputDataByFieldName.yieldUnit.value === option.value;
        }) || null;
        const servingSizeUnitSelectedOption = this.servingSizeAndYieldUnitDropdownOptions[0][1].find((option) => {
            return validationInputDataByFieldName.servingSizeUnit.value === option.value;
        }) || null;

        let salesItemCostPerServing : number | null = null;
        let salesItemPricePerServing : number | null = null;
        const salesCostOnFormIsValid = salesItemCostOnForm !== NOT_CALCULABLE_FIELD_VALUE;
        const yieldAndServingSizeFieldsAreValid =
            validationInputDataByFieldName.yieldAmount.isValid &&
            validationInputDataByFieldName.yieldUnit.isValid &&
            validationInputDataByFieldName.servingSizeAmount.isValid &&
            validationInputDataByFieldName.servingSizeUnit.isValid;

        if (salesCostOnFormIsValid && yieldAndServingSizeFieldsAreValid) {
            const yieldAmount = validationInputDataByFieldName.yieldAmount.value;
            const yieldUnit = validationInputDataByFieldName.yieldUnit.value;
            const servingSizeAmount = validationInputDataByFieldName.servingSizeAmount.value;
            const servingSizeUnit = validationInputDataByFieldName.servingSizeUnit.value;

            const yieldAndServingSizeFromForm = CreateOrEditSalesItemFormUtils.getYieldAndServingSizeInfoFromForm(yieldAmount, yieldUnit, servingSizeAmount, servingSizeUnit);

            // ALB/CAS Note: This is not a great pattern to use but it's the most straightforward way to avoid breaking the page if two incompatible yield and serving size units are selected
            try {
                const servingsPerYield = SalesItemUtils.getServingsPerYield(yieldAndServingSizeFromForm.servingSize, yieldAndServingSizeFromForm.itemYield);
                salesItemCostPerServing = parseFloat(salesItemCostOnForm) / servingsPerYield;
                salesItemPricePerServing = parseFloat(salesItemPriceOnForm) / servingsPerYield;
                if (isNaN(salesItemPricePerServing)) {
                    salesItemPricePerServing = 0;
                }
            } catch (error) {
                salesItemCostPerServing = null;
                salesItemPricePerServing = null;
            }
        }

        return (
            <div className="sub-recipe-info sales-item-form-subsection">
                <div className="yield-and-serving-size">
                    <div className="yield">
                        <div className="label">Yield</div>
                        <div className="yield-and-serving-size-input-fields input-group">
                            <ValidationInput
                                theme={ ValidationInputTheme.Basic }
                                type="text"
                                step="any"
                                label={ null }
                                hintText=""
                                value={ validationInputDataByFieldName.yieldAmount.value }
                                autoFocus={ false }
                                autoComplete={ null }
                                isValid={ validationInputDataByFieldName.yieldAmount.isValid }
                                isDisabled={ false }
                                errorMessage={ validationInputDataByFieldName.yieldAmount.errorMessage }
                                inputClassName=""
                                handleEnterClick={ null }
                                handleChange={ this.onYieldAmountChange }
                                handleBlur={ null }
                                handleFocus={ null }
                            />
                            <Select2DropdownMenu
                                theme={ Select2DropdownMenuTheme.Basic }
                                onOptionSelect={ this.handleOnSelectYieldUnit }
                                sortedOptionsAndLabelName={ this.servingSizeAndYieldUnitDropdownOptions }
                                selectedOption={ yieldUnitSelectedOption }
                                placeholderText="Select Unit"
                                hasCreateCustomOption={ false }
                                createCustomOption={ null }
                                buttonShouldDisplaySelectedLabel={ true }
                                emptyState={ null }
                                isSearchable={ false }
                                selectedOptionHeaderShown={ false }
                                customOptionGroupLabel={ null }
                                createCustomOptionButtonLabel={ null }
                            />
                            { !validationInputDataByFieldName.yieldUnit.isValid &&
                                <div className="error-text">
                                    { validationInputDataByFieldName.yieldUnit.errorMessage }
                                </div>
                            }
                        </div>
                    </div>
                    <div className="serving-size">
                        <div className="label">Serving Size</div>
                        <div className="yield-and-serving-size-input-fields input-group">
                            <ValidationInput
                                theme={ ValidationInputTheme.Basic }
                                type="text"
                                step="any"
                                label={ null }
                                hintText=""
                                value={ validationInputDataByFieldName.servingSizeAmount.value }
                                autoFocus={ false }
                                autoComplete={ null }
                                isValid={ validationInputDataByFieldName.servingSizeAmount.isValid }
                                isDisabled={ false }
                                errorMessage={ validationInputDataByFieldName.yieldAmount.errorMessage }
                                inputClassName=""
                                handleEnterClick={ null }
                                handleChange={ this.onServingSizeAmountChange }
                                handleBlur={ null }
                                handleFocus={ null }
                            />
                            <Select2DropdownMenu
                                theme={ Select2DropdownMenuTheme.Basic }
                                onOptionSelect={ this.handleOnSelectServingSizeUnit }
                                sortedOptionsAndLabelName={ this.servingSizeAndYieldUnitDropdownOptions }
                                selectedOption={ servingSizeUnitSelectedOption }
                                placeholderText="Select Unit"
                                hasCreateCustomOption={ false }
                                createCustomOption={ null }
                                buttonShouldDisplaySelectedLabel={ true }
                                emptyState={ null }
                                isSearchable={ false }
                                selectedOptionHeaderShown={ false }
                                customOptionGroupLabel={ null }
                                createCustomOptionButtonLabel={ null }
                            />
                            { !validationInputDataByFieldName.servingSizeUnit.isValid &&
                                <div className="error-text">
                                    { validationInputDataByFieldName.servingSizeUnit.errorMessage }
                                </div>
                            }
                        </div>
                    </div>
                    <small>
                        Cost per serving: { FormatMonetaryValueWithCents(salesItemCostPerServing) }
                    </small>
                    <small>
                        Price per serving: { FormatMonetaryValueWithCents(salesItemPricePerServing) }
                    </small>
                </div>
            </div>
        );
    }
    private readonly onYieldAmountChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.onIngredientInfoFormFieldChange('yieldAmount', event.currentTarget.value);
    }

    private readonly handleOnSelectYieldUnit = (selectedUnitValue : string) => {
        this.props.onIngredientInfoFormFieldChange('yieldUnit', selectedUnitValue);
    }

    private readonly onServingSizeAmountChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.onIngredientInfoFormFieldChange('servingSizeAmount', event.currentTarget.value);
    }

    private readonly handleOnSelectServingSizeUnit = (selectedUnitValue : string) => {
        this.props.onIngredientInfoFormFieldChange('servingSizeUnit', selectedUnitValue);
    }
}
