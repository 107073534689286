import { object } from 'prop-types';
import React from 'react';
import * as Redux from 'redux';
import thunk from 'redux-thunk';

import { enableBatching } from 'shared/models/IAction';

// Based on http://www.mattgreer.org/articles/typescript-react-and-redux/

interface IProvider<P> {
    appReducers? : Redux.Reducer<P>;
    store? : any;
    services : object;
}

export interface IExtraArguments {
    services : object;
}

export class Provider<P> extends React.Component<IProvider<P>, any> {
    public static childContextTypes = {
        store: object.isRequired,
    };

    public static createStore<T>(appReducers : Redux.Reducer<T>, services : object) : Redux.Store<T> {
        const extraArguments : IExtraArguments = {
            services,
        };
        return Redux.createStore(
            enableBatching(appReducers),
            Redux.applyMiddleware(
                thunk.withExtraArgument(extraArguments)
            )
        );
    }

    public store : Redux.Store<P>;
    public target : React.ReactNode;

    constructor(props : IProvider<P>) {
        super(props);

        if (typeof this.props.store !== 'undefined') {
            this.store = this.props.store;
        } else if (typeof this.props.appReducers !== 'undefined') {
            this.store = Provider.createStore(this.props.appReducers, this.props.services);
        } else {
            throw new Error('Provider requires appReducers or store');
        }
        this.target = this.props.children;
    }

    public getChildContext() {
        return {
            store: this.store,
        };
    }

    public render() {
        return this.target as JSX.Element;
    }
}
