import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { PrepEvent } from 'api/PrepEvent/model/PrepEvent';
import { PrepEventId } from 'api/PrepEvent/model/PrepEventId';
import { PrepEventUtils } from 'api/PrepEvent/utils/prepEventUtils';
import { PackagingsAndMappings } from 'api/Product/model/PackagingsAndMappings';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { numberUtils } from 'shared/utils/numberUtils';

interface IPrepEventLabels {
    preparedItems : string;
    yield : string;
    // ingredients : string;
}

// cached so we don't have to redo all the calculation/packaging logic again
let lastPrepEventsById : StringValueMap<PrepEventId, PrepEvent> | undefined;
let lastProductsById : StringValueMap<ProductId, Product> | undefined;
const prepEventLabelStringsCache = new StringValueMap<PrepEventId, IPrepEventLabels>();
const getLabelsForPrepEventIds = (prepEventIds : StringValueSet<PrepEventId>, prepEventsById : StringValueMap<PrepEventId, PrepEvent>, productsById : StringValueMap<ProductId, Product>) => {
    if (prepEventsById !== lastPrepEventsById || productsById !== lastProductsById) {
        prepEventLabelStringsCache.clear();
        lastPrepEventsById = prepEventsById;
        lastProductsById = productsById;
    }

    const labelsByPrepEventId = new StringValueMap<PrepEventId, IPrepEventLabels>();
    prepEventIds.forEach((prepEventId) => {
        const cachedValue = prepEventLabelStringsCache.get(prepEventId);
        if (cachedValue) {
            labelsByPrepEventId.set(prepEventId, cachedValue);
        } else {
            const prepEvent = prepEventsById.get(prepEventId);
            if (typeof prepEvent === 'undefined') {
                throw new RuntimeException('unexpected');
            }
            const labels = getPreparedItemsAndYieldLabels(prepEvent, productsById);
            labelsByPrepEventId.set(prepEventId, labels);
            prepEventLabelStringsCache.set(prepEventId, labels);
        }
    });

    return labelsByPrepEventId;
};

const getPreparedItemsAndYieldLabels = (prepEvent : PrepEvent, productsById : StringValueMap<ProductId, Product>) : IPrepEventLabels => {
    const outputProductIds = Array.from(prepEvent.getOutputQuantityOfProductByProductId().keys());

    let highestCostOutputProductId : ProductId;
    let highestCostOutputYield : QuantityInUnit<ProductQuantityUnit>;
    if (outputProductIds.length === 1) {
        highestCostOutputProductId = outputProductIds[0];
        const quantityOutput = prepEvent.getOutputQuantityOfProductByProductId().get(highestCostOutputProductId);
        if (typeof quantityOutput === 'undefined') {
            throw new RuntimeException('unexpected');
        }
        highestCostOutputYield = quantityOutput;
    } else {
        let highestCostTotalAndQuantityYieldAndProductId : [ProductId, number, QuantityInUnit<ProductQuantityUnit>] | undefined;
        outputProductIds.forEach((a) => {
            const productA = productsById.get(a);
            const outputQuantityA = prepEvent.getOutputQuantityOfProductByProductId().get(a);

            if (typeof productA === 'undefined' ||
                typeof outputQuantityA === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            const totalCostA = PrepEventUtils.getTotalCostOfProductInOutput(prepEvent, a, productA);

            if (!highestCostTotalAndQuantityYieldAndProductId || highestCostTotalAndQuantityYieldAndProductId[1] < totalCostA) {
                highestCostTotalAndQuantityYieldAndProductId = [a, totalCostA, outputQuantityA];
            }
        });
        if (typeof highestCostTotalAndQuantityYieldAndProductId === 'undefined') {
            throw new RuntimeException('unexpected');
        }
        highestCostOutputProductId = highestCostTotalAndQuantityYieldAndProductId[0];
        highestCostOutputYield = highestCostTotalAndQuantityYieldAndProductId[2];
    }

    const productWithHighestCost = productsById.get(highestCostOutputProductId);
    if (typeof productWithHighestCost === 'undefined') {
        throw new RuntimeException('unexpected');
    }

    let outputProductsLabel : string = getLineItemProductLabel(productWithHighestCost);
    let outputYieldsLabel : string = getLineItemQuantityLabel(highestCostOutputYield, productWithHighestCost.getPackagingsAndMappings());

    if (outputProductIds.length > 1) {
        outputProductsLabel = `${ outputProductsLabel } + ${ outputProductIds.length - 1 } more`;
        outputYieldsLabel = `${ outputYieldsLabel } + ${ outputProductIds.length - 1 } more`;
    }

    return {
        preparedItems: outputProductsLabel,
        yield: outputYieldsLabel,
    };
};

const getLineItemProductLabel = (product : Product) : string => {
    return `${ product.getBrand() }${ product.getBrand() ? ' ' : ''}${ product.getName() }`;
};

// TODOfuture this should really be a shared util
const getLineItemQuantityLabel = (quantityInUnit : QuantityInUnit<ProductQuantityUnit>, packagingsAndMappings : PackagingsAndMappings, isPlural? : boolean) => {
    return `${ numberUtils.FormatToMaximumTwoDecimalPlaces(quantityInUnit.getQuantity()) } ${ PackagingUtils.getDisplayTextForProductQuantityUnit(packagingsAndMappings, quantityInUnit.getUnit(), isPlural || false) }`;
};

const getIngredientsLabel = (prepEvent : PrepEvent) : string => {
    const numberOfIngredients = prepEvent.getInputQuantityOfProductByProductId().size;
    return `${ numberOfIngredients } ingredient${ numberOfIngredients > 1 ? 's' : '' }`;
};

export const PrepEventDisplayUtils = {
    getIngredientsLabel,
    getPreparedItemsAndYieldLabels, // needed for inventory history
    getLabelsForPrepEventIds,
    getLineItemProductLabel,
    getLineItemQuantityLabel,
};
