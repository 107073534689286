import { Distributor } from 'api/Distributor/model/Distributor';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { IDistributorNameMappingJSONObject } from 'api/Distributor/serializer/IDistributorNameMappingJSONObject';
import { Scope, ScopeType } from 'api/Location/model/Scope';
import { DistributorNameMapping } from 'api/Ordering/model/DistributorNameMapping';

export class DistributorObjectToJSONSerializer {
    public static getCreateDistributorEventJSON(
        distributor : Distributor,
        distributorId : DistributorId,
    ) {
        return {
            distributor_id : distributorId.getValue(),
            name : distributor.getName(),
            shortName : distributor.getShortName(),
            owner : this.getScopeJSON(distributor.getOwner()),
        };
    }

    public static getScopeJSON(
        scope : Scope
    ) {
        const id = scope.getId();
        return {
            type : ScopeType[scope.getType()],
            id : id === null ? null : id.getValue(),
        };
    }

    public getMappingJSON(distributorNameMapping : DistributorNameMapping) : IDistributorNameMappingJSONObject {
        return {
            distributor_id : distributorNameMapping.getDistributorId().getValue(),
            mapped_distributor_name : distributorNameMapping.getMappedDistributorName(),
            retailer_id : distributorNameMapping.getRetailerId().getValue(),
        };
    }
}
