
import { StringValueMap } from 'api/Core/StringValueMap';
import React from 'react';

import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { StringValueSet } from 'api/Core/StringValueSet';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export interface IItemCardHistoryMergeFromProps {
    product : Product;
    productId : ProductId;
    mergedFromProductIds : StringValueSet<ProductId>;
    productsById : StringValueMap<ProductId, Product>;
}

export class ItemCardHistoryMergeFrom extends React.Component<IItemCardHistoryMergeFromProps, object> {

    public render() {
        const {
            mergedFromProductIds,
            productsById,
        } = this.props;

        if (mergedFromProductIds.size <= 1) {
            throw new RuntimeException("unexpected");
        }

        const mergedFromProductIdsValues = Array.from(mergedFromProductIds.values());
        const productId1 = mergedFromProductIdsValues[0];
        const productId2 = mergedFromProductIdsValues[1];
        const product1 = productsById.getRequired(productId1);
        const product2 = productsById.getRequired(productId2);
        const product1Click = () => this.showProductItemCard(productId1);
        const product2Click = () => this.showProductItemCard(productId2);

        return (
            <div className="activity-item item-history-merge-from">
                <div className="top-row">
                    <span className="bevicon bevico-swap-vert" />
                    <div className="title">
                        <a className="link" onClick={ product1Click }>{product1.getName()}</a>&nbsp;merged with&nbsp;<a className="link" onClick={ product2Click }>{product2.getName()}</a>&nbsp;to create this product.
                    </div>
                </div>
            </div>
        );
    }

    public showProductItemCard = (productId : ProductId) => {
        $(document).trigger('loadEditItemModal', {
            detail: {
                productId: productId.getValue(),
            }
        });
    }
}
