import { LocationId } from 'api/Location/model/LocationId';

export const DEFAULT_PRICE_PER_INVOICE = 1.50; // TODO Cheezy Default price for now. Should figure out if this also needs to be in the backend
export type BillingAddOn = 'itemLevelSales' | 'invoiceProcessing';

export interface IBillingAddOnService {
    getPricePerInvoiceForInvoiceProcessingForRetailer(locationId : LocationId) : Promise<number>;

    enableAddOnsForRetailer(
        locationId : LocationId,
        addOnsToEnable : Set<BillingAddOn>,
        pricePerInvoice : number,
    ) : Promise<void>;
}
