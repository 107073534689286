export type ValidationFunction = (value : string) => boolean;

export const Validation = {
    validateRequired: (value : any) : boolean => {
        return ((value !== null) && (value !== ''));
    },

    validateEmailAddress: (value : string) : boolean => {
        // Lesman 6/13/2017
        // adapted from and tested against the following
        // http://www.regular-expressions.info/email.html
        // https://support.google.com/a/answer/33386?hl=en
        // https://blogs.msdn.microsoft.com/testing123/2009/02/06/email-address-test-cases/
        return /^(?=.{4,255}$)(?=[^@]{1,63}@)[a-zA-Z0-9_\"\'+-](\.?[a-zA-Z0-9_\"\'+-])*@((?=[a-zA-Z0-9-]{1,63}\.)[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.){1,8}[a-zA-Z]{2,63}$/.test(value);
    },

    // future work: can grab regex for country based on country code, if we want to store/maintain this map
    // https://stackoverflow.com/questions/578406/what-is-the-ultimate-postal-code-and-zip-regex
    validateUSZipCode: (value : string) : boolean => {
        return /^\d{5}([\- ]?\d{4})?$/.test(value);
    },

    validateZipCode: (value : string, countryCode : string | null) : boolean => {
        if (countryCode === 'US') {
            return Validation.validateUSZipCode(value);
        }
        // TODO later: can specifically handle other countries if need be

        return true;
    },

    validateUSPhoneNumber: (value : string) : boolean => {
        return /^(\+1)?([()/\.\-\s]*\d){10}\s*((ext|x)\s*\d+)*$/.test(value);
    },

    // Allowing null for cases where you want some generic phone number validation without the context of a country (Like when we are creating user accounts)
    validatePhoneNumber: (value : string, countryCode : string | null) : boolean => {
        let isValid = Validation.validateUSPhoneNumber(value);

        if (!isValid && countryCode !== 'US') {
            // If it failed US validation and this is not US, then treat as a international number
            isValid = ((value !== null) && (value !== '')) && /([+]\d{1,2}[.-\s]?)(\d{3}[.-\s]?){2}(\d{4})/g.test(value);
        }
        return isValid;
    },

    validateUSD: (value : string) : boolean => {
        return /^\$?[0-9,]+\.?[0-9]*$/.test(value) || /^\$?[0-9,]*\.?[0-9]+$/.test(value);
    },

    validatePercentage: (value : string) : boolean => {
        return /^[0-9,]+\.?[0-9]*%?$/.test(value) || /^[0-9,]*\.?[0-9]+%?$/.test(value);
    },

    validateNonNegativeNumber: (value : string) : boolean => {
        return /^[0-9]+\.?[0-9]*$/.test(value) || /^[0-9]*\.?[0-9]+$/.test(value);
    },

    validateNumber: (value : string) : boolean => {
        return /^-?[0-9]+\.?[0-9]*$/.test(value) || /^-?[0-9]*\.?[0-9]+$/.test(value);
    },

    validateGreaterThanZero: (value: string): boolean => {
        return /^(0*\.0*[1-9]\d*|[1-9]\d*(\.\d+)?)$/.test(value); 
    },
    
    validateExpirationDate : (value : string) : boolean => {
        return /^(0[1-9]|1[0-2])\/([0-9]{2})$/.test(value);
    },

    validateShortText : (value : string) : boolean => {
        return /^.{0,250}$/.test(value);
    },

    validateLongText : (value : string) : boolean => {
        return /^.{0,4096}$/.test(value); // this number was picked arbitrarily as an upper limit, can be adjusted
    },

    makeMaxLen: (maxLen : number) : ValidationFunction => {
        return (value : string) => value.length <= maxLen;
    },

    empty: (value : string) : boolean => value.length === 0,

    short: (value : string) : boolean => value.length <= 250,

    long: (value : string) : boolean => value.length <= 4096,

    and: (a : ValidationFunction, b : ValidationFunction) : ValidationFunction =>
        (value : string) => a(value) && b(value),

    or: (a : ValidationFunction, b : ValidationFunction) : ValidationFunction =>
        (value : string) => a(value) || b(value),

    validateNoWhitepace: (value : string) : boolean => {
        return !/\s+/.test(value);
    }
};

export interface IValidationResult {
    isValid : boolean;
    errorMessage : string;
}
