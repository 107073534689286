//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


import Thrift from 'thrift';
import UserAccountsModel from './accounts_user_Model_types';
import AccountsExceptions from './accounts_Exceptions_types';
import LocationModel from './location_Model_types';
import ProductExceptions from './product_Exceptions_types';
import ProductAmountExceptions from './product_amount_Exceptions_types';
import ProductMappingModel from './product_mapping_Model_types';
import LocationExceptions from './location_Exceptions_types';
import ProductMappingExceptions from './product_mapping_Exceptions_types';
import InventoryTransferReportExceptions from './inventory_transfer_report_Exceptions_types';
import CoreApiExceptions from './core_api_Exceptions_types';

var ProductMappingService = {};
export default ProductMappingService;
//HELPER FUNCTIONS AND STRUCTURES

ProductMappingService.InterLocationProductMappingService_setProductQuantityUnitMappings_args = function(args) {
  this.actor = null;
  this.mappings = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.mappings !== undefined && args.mappings !== null) {
      this.mappings = Thrift.copyList(args.mappings, [ProductMappingModel.InterLocationProductQuantityUnitMapping]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field mappings is unset!');
    }
  }
};
ProductMappingService.InterLocationProductMappingService_setProductQuantityUnitMappings_args.prototype = {};
ProductMappingService.InterLocationProductMappingService_setProductQuantityUnitMappings_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.mappings = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new ProductMappingModel.InterLocationProductQuantityUnitMapping();
          elem6.read(input);
          this.mappings.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingService_setProductQuantityUnitMappings_args.prototype.write = function(output) {
  output.writeStructBegin('InterLocationProductMappingService_setProductQuantityUnitMappings_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.mappings !== null && this.mappings !== undefined) {
    output.writeFieldBegin('mappings', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.mappings.length);
    for (var iter7 in this.mappings)
    {
      if (this.mappings.hasOwnProperty(iter7))
      {
        iter7 = this.mappings[iter7];
        iter7.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingService_setProductQuantityUnitMappings_result = function(args) {
  this.serviceTemporarilyUnavailableException = null;
  this.rateLimitExceededException = null;
  this.unknownActorException = null;
  this.unknownLocationIdentifierException = null;
  this.actorDoesNotHaveAccessToLocationException = null;
  this.sourceLocationCannotEqualTargetLocationException = null;
  this.unknownProductIdException = null;
  this.sourceAndTargetProductsHaveDifferentPackagingsException = null;
  this.quantityMustBeNonnegativeException = null;
  this.productNotAssociatedWithLocationException = null;
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof LocationExceptions.ActorDoesNotHaveAccessToLocationException) {
    this.actorDoesNotHaveAccessToLocationException = args;
    return;
  }
  if (args instanceof ProductMappingExceptions.SourceLocationCannotEqualTargetLocationException) {
    this.sourceLocationCannotEqualTargetLocationException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof ProductMappingExceptions.SourceAndTargetProductsHaveDifferentPackagingsException) {
    this.sourceAndTargetProductsHaveDifferentPackagingsException = args;
    return;
  }
  if (args instanceof ProductAmountExceptions.QuantityMustBeNonnegativeException) {
    this.quantityMustBeNonnegativeException = args;
    return;
  }
  if (args instanceof ProductAmountExceptions.ProductNotAssociatedWithLocationException) {
    this.productNotAssociatedWithLocationException = args;
    return;
  }
  if (args) {
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.actorDoesNotHaveAccessToLocationException !== undefined && args.actorDoesNotHaveAccessToLocationException !== null) {
      this.actorDoesNotHaveAccessToLocationException = args.actorDoesNotHaveAccessToLocationException;
    }
    if (args.sourceLocationCannotEqualTargetLocationException !== undefined && args.sourceLocationCannotEqualTargetLocationException !== null) {
      this.sourceLocationCannotEqualTargetLocationException = args.sourceLocationCannotEqualTargetLocationException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.sourceAndTargetProductsHaveDifferentPackagingsException !== undefined && args.sourceAndTargetProductsHaveDifferentPackagingsException !== null) {
      this.sourceAndTargetProductsHaveDifferentPackagingsException = args.sourceAndTargetProductsHaveDifferentPackagingsException;
    }
    if (args.quantityMustBeNonnegativeException !== undefined && args.quantityMustBeNonnegativeException !== null) {
      this.quantityMustBeNonnegativeException = args.quantityMustBeNonnegativeException;
    }
    if (args.productNotAssociatedWithLocationException !== undefined && args.productNotAssociatedWithLocationException !== null) {
      this.productNotAssociatedWithLocationException = args.productNotAssociatedWithLocationException;
    }
  }
};
ProductMappingService.InterLocationProductMappingService_setProductQuantityUnitMappings_result.prototype = {};
ProductMappingService.InterLocationProductMappingService_setProductQuantityUnitMappings_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToLocationException = new LocationExceptions.ActorDoesNotHaveAccessToLocationException();
        this.actorDoesNotHaveAccessToLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.sourceLocationCannotEqualTargetLocationException = new ProductMappingExceptions.SourceLocationCannotEqualTargetLocationException();
        this.sourceLocationCannotEqualTargetLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.sourceAndTargetProductsHaveDifferentPackagingsException = new ProductMappingExceptions.SourceAndTargetProductsHaveDifferentPackagingsException();
        this.sourceAndTargetProductsHaveDifferentPackagingsException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.STRUCT) {
        this.quantityMustBeNonnegativeException = new ProductAmountExceptions.QuantityMustBeNonnegativeException();
        this.quantityMustBeNonnegativeException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.STRUCT) {
        this.productNotAssociatedWithLocationException = new ProductAmountExceptions.ProductNotAssociatedWithLocationException();
        this.productNotAssociatedWithLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingService_setProductQuantityUnitMappings_result.prototype.write = function(output) {
  output.writeStructBegin('InterLocationProductMappingService_setProductQuantityUnitMappings_result');
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 1);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 2);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 4);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToLocationException !== null && this.actorDoesNotHaveAccessToLocationException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToLocationException', Thrift.Type.STRUCT, 5);
    this.actorDoesNotHaveAccessToLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.sourceLocationCannotEqualTargetLocationException !== null && this.sourceLocationCannotEqualTargetLocationException !== undefined) {
    output.writeFieldBegin('sourceLocationCannotEqualTargetLocationException', Thrift.Type.STRUCT, 6);
    this.sourceLocationCannotEqualTargetLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 7);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.sourceAndTargetProductsHaveDifferentPackagingsException !== null && this.sourceAndTargetProductsHaveDifferentPackagingsException !== undefined) {
    output.writeFieldBegin('sourceAndTargetProductsHaveDifferentPackagingsException', Thrift.Type.STRUCT, 8);
    this.sourceAndTargetProductsHaveDifferentPackagingsException.write(output);
    output.writeFieldEnd();
  }
  if (this.quantityMustBeNonnegativeException !== null && this.quantityMustBeNonnegativeException !== undefined) {
    output.writeFieldBegin('quantityMustBeNonnegativeException', Thrift.Type.STRUCT, 9);
    this.quantityMustBeNonnegativeException.write(output);
    output.writeFieldEnd();
  }
  if (this.productNotAssociatedWithLocationException !== null && this.productNotAssociatedWithLocationException !== undefined) {
    output.writeFieldBegin('productNotAssociatedWithLocationException', Thrift.Type.STRUCT, 10);
    this.productNotAssociatedWithLocationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingService_getProductQuantityUnitMappingsBetweenLocations_args = function(args) {
  this.actor = null;
  this.sourceLocation = null;
  this.targetLocation = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.sourceLocation !== undefined && args.sourceLocation !== null) {
      this.sourceLocation = new LocationModel.LocationIdentifier(args.sourceLocation);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sourceLocation is unset!');
    }
    if (args.targetLocation !== undefined && args.targetLocation !== null) {
      this.targetLocation = new LocationModel.LocationIdentifier(args.targetLocation);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field targetLocation is unset!');
    }
  }
};
ProductMappingService.InterLocationProductMappingService_getProductQuantityUnitMappingsBetweenLocations_args.prototype = {};
ProductMappingService.InterLocationProductMappingService_getProductQuantityUnitMappingsBetweenLocations_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.sourceLocation = new LocationModel.LocationIdentifier();
        this.sourceLocation.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.targetLocation = new LocationModel.LocationIdentifier();
        this.targetLocation.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingService_getProductQuantityUnitMappingsBetweenLocations_args.prototype.write = function(output) {
  output.writeStructBegin('InterLocationProductMappingService_getProductQuantityUnitMappingsBetweenLocations_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.sourceLocation !== null && this.sourceLocation !== undefined) {
    output.writeFieldBegin('sourceLocation', Thrift.Type.STRUCT, 2);
    this.sourceLocation.write(output);
    output.writeFieldEnd();
  }
  if (this.targetLocation !== null && this.targetLocation !== undefined) {
    output.writeFieldBegin('targetLocation', Thrift.Type.STRUCT, 3);
    this.targetLocation.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingService_getProductQuantityUnitMappingsBetweenLocations_result = function(args) {
  this.success = null;
  this.serviceTemporarilyUnavailableException = null;
  this.rateLimitExceededException = null;
  this.unknownActorException = null;
  this.sourceLocationCannotEqualTargetLocationException = null;
  this.unknownLocationIdentifierException = null;
  this.actorDoesNotHaveAccessToLocationException = null;
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof ProductMappingExceptions.SourceLocationCannotEqualTargetLocationException) {
    this.sourceLocationCannotEqualTargetLocationException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof LocationExceptions.ActorDoesNotHaveAccessToLocationException) {
    this.actorDoesNotHaveAccessToLocationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [ProductMappingModel.ProductQuantityUnitMappingUpdateEvent]);
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.sourceLocationCannotEqualTargetLocationException !== undefined && args.sourceLocationCannotEqualTargetLocationException !== null) {
      this.sourceLocationCannotEqualTargetLocationException = args.sourceLocationCannotEqualTargetLocationException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.actorDoesNotHaveAccessToLocationException !== undefined && args.actorDoesNotHaveAccessToLocationException !== null) {
      this.actorDoesNotHaveAccessToLocationException = args.actorDoesNotHaveAccessToLocationException;
    }
  }
};
ProductMappingService.InterLocationProductMappingService_getProductQuantityUnitMappingsBetweenLocations_result.prototype = {};
ProductMappingService.InterLocationProductMappingService_getProductQuantityUnitMappingsBetweenLocations_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size8 = 0;
        var _rtmp312;
        this.success = [];
        var _etype11 = 0;
        _rtmp312 = input.readListBegin();
        _etype11 = _rtmp312.etype;
        _size8 = _rtmp312.size;
        for (var _i13 = 0; _i13 < _size8; ++_i13)
        {
          var elem14 = null;
          elem14 = new ProductMappingModel.ProductQuantityUnitMappingUpdateEvent();
          elem14.read(input);
          this.success.push(elem14);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.sourceLocationCannotEqualTargetLocationException = new ProductMappingExceptions.SourceLocationCannotEqualTargetLocationException();
        this.sourceLocationCannotEqualTargetLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToLocationException = new LocationExceptions.ActorDoesNotHaveAccessToLocationException();
        this.actorDoesNotHaveAccessToLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingService_getProductQuantityUnitMappingsBetweenLocations_result.prototype.write = function(output) {
  output.writeStructBegin('InterLocationProductMappingService_getProductQuantityUnitMappingsBetweenLocations_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter15 in this.success)
    {
      if (this.success.hasOwnProperty(iter15))
      {
        iter15 = this.success[iter15];
        iter15.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 1);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 2);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.sourceLocationCannotEqualTargetLocationException !== null && this.sourceLocationCannotEqualTargetLocationException !== undefined) {
    output.writeFieldBegin('sourceLocationCannotEqualTargetLocationException', Thrift.Type.STRUCT, 4);
    this.sourceLocationCannotEqualTargetLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToLocationException !== null && this.actorDoesNotHaveAccessToLocationException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToLocationException', Thrift.Type.STRUCT, 6);
    this.actorDoesNotHaveAccessToLocationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingService_deleteProductQuantityUnitMapping_args = function(args) {
  this.actor = null;
  this.mapping = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.mapping !== undefined && args.mapping !== null) {
      this.mapping = new ProductMappingModel.InterLocationProductQuantityUnitMapping(args.mapping);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field mapping is unset!');
    }
  }
};
ProductMappingService.InterLocationProductMappingService_deleteProductQuantityUnitMapping_args.prototype = {};
ProductMappingService.InterLocationProductMappingService_deleteProductQuantityUnitMapping_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.mapping = new ProductMappingModel.InterLocationProductQuantityUnitMapping();
        this.mapping.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingService_deleteProductQuantityUnitMapping_args.prototype.write = function(output) {
  output.writeStructBegin('InterLocationProductMappingService_deleteProductQuantityUnitMapping_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.mapping !== null && this.mapping !== undefined) {
    output.writeFieldBegin('mapping', Thrift.Type.STRUCT, 2);
    this.mapping.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingService_deleteProductQuantityUnitMapping_result = function(args) {
  this.serviceTemporarilyUnavailableException = null;
  this.rateLimitExceededException = null;
  this.unknownActorException = null;
  this.unknownLocationIdentifierException = null;
  this.actorDoesNotHaveAccessToLocationException = null;
  this.sourceLocationCannotEqualTargetLocationException = null;
  this.unknownProductIdException = null;
  this.mappingIsInvolvedInTransferException = null;
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof LocationExceptions.ActorDoesNotHaveAccessToLocationException) {
    this.actorDoesNotHaveAccessToLocationException = args;
    return;
  }
  if (args instanceof ProductMappingExceptions.SourceLocationCannotEqualTargetLocationException) {
    this.sourceLocationCannotEqualTargetLocationException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof ProductMappingExceptions.MappingIsInvolvedInTransferException) {
    this.mappingIsInvolvedInTransferException = args;
    return;
  }
  if (args) {
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.actorDoesNotHaveAccessToLocationException !== undefined && args.actorDoesNotHaveAccessToLocationException !== null) {
      this.actorDoesNotHaveAccessToLocationException = args.actorDoesNotHaveAccessToLocationException;
    }
    if (args.sourceLocationCannotEqualTargetLocationException !== undefined && args.sourceLocationCannotEqualTargetLocationException !== null) {
      this.sourceLocationCannotEqualTargetLocationException = args.sourceLocationCannotEqualTargetLocationException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.mappingIsInvolvedInTransferException !== undefined && args.mappingIsInvolvedInTransferException !== null) {
      this.mappingIsInvolvedInTransferException = args.mappingIsInvolvedInTransferException;
    }
  }
};
ProductMappingService.InterLocationProductMappingService_deleteProductQuantityUnitMapping_result.prototype = {};
ProductMappingService.InterLocationProductMappingService_deleteProductQuantityUnitMapping_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToLocationException = new LocationExceptions.ActorDoesNotHaveAccessToLocationException();
        this.actorDoesNotHaveAccessToLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.sourceLocationCannotEqualTargetLocationException = new ProductMappingExceptions.SourceLocationCannotEqualTargetLocationException();
        this.sourceLocationCannotEqualTargetLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.mappingIsInvolvedInTransferException = new ProductMappingExceptions.MappingIsInvolvedInTransferException();
        this.mappingIsInvolvedInTransferException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingService_deleteProductQuantityUnitMapping_result.prototype.write = function(output) {
  output.writeStructBegin('InterLocationProductMappingService_deleteProductQuantityUnitMapping_result');
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 1);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 2);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 4);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToLocationException !== null && this.actorDoesNotHaveAccessToLocationException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToLocationException', Thrift.Type.STRUCT, 5);
    this.actorDoesNotHaveAccessToLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.sourceLocationCannotEqualTargetLocationException !== null && this.sourceLocationCannotEqualTargetLocationException !== undefined) {
    output.writeFieldBegin('sourceLocationCannotEqualTargetLocationException', Thrift.Type.STRUCT, 6);
    this.sourceLocationCannotEqualTargetLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 7);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.mappingIsInvolvedInTransferException !== null && this.mappingIsInvolvedInTransferException !== undefined) {
    output.writeFieldBegin('mappingIsInvolvedInTransferException', Thrift.Type.STRUCT, 8);
    this.mappingIsInvolvedInTransferException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingService_setMappings_args = function(args) {
  this.actor = null;
  this.mappings = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.mappings !== undefined && args.mappings !== null) {
      this.mappings = Thrift.copyList(args.mappings, [ProductMappingModel.InterLocationProductMapping]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field mappings is unset!');
    }
  }
};
ProductMappingService.InterLocationProductMappingService_setMappings_args.prototype = {};
ProductMappingService.InterLocationProductMappingService_setMappings_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size16 = 0;
        var _rtmp320;
        this.mappings = [];
        var _etype19 = 0;
        _rtmp320 = input.readListBegin();
        _etype19 = _rtmp320.etype;
        _size16 = _rtmp320.size;
        for (var _i21 = 0; _i21 < _size16; ++_i21)
        {
          var elem22 = null;
          elem22 = new ProductMappingModel.InterLocationProductMapping();
          elem22.read(input);
          this.mappings.push(elem22);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingService_setMappings_args.prototype.write = function(output) {
  output.writeStructBegin('InterLocationProductMappingService_setMappings_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.mappings !== null && this.mappings !== undefined) {
    output.writeFieldBegin('mappings', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.mappings.length);
    for (var iter23 in this.mappings)
    {
      if (this.mappings.hasOwnProperty(iter23))
      {
        iter23 = this.mappings[iter23];
        iter23.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingService_setMappings_result = function(args) {
  this.serviceTemporarilyUnavailableException = null;
  this.rateLimitExceededException = null;
  this.unknownActorException = null;
  this.unknownLocationIdentifierException = null;
  this.actorDoesNotHaveAccessToLocationException = null;
  this.sourceLocationCannotEqualTargetLocationException = null;
  this.unknownProductIdException = null;
  this.sourceAndTargetProductsHaveDifferentPackagingsException = null;
  this.sourceProductIsMappedToMultipleTargetsAtSameLocationException = null;
  this.productNotAssociatedWithLocationException = null;
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof LocationExceptions.ActorDoesNotHaveAccessToLocationException) {
    this.actorDoesNotHaveAccessToLocationException = args;
    return;
  }
  if (args instanceof ProductMappingExceptions.SourceLocationCannotEqualTargetLocationException) {
    this.sourceLocationCannotEqualTargetLocationException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof ProductMappingExceptions.SourceAndTargetProductsHaveDifferentPackagingsException) {
    this.sourceAndTargetProductsHaveDifferentPackagingsException = args;
    return;
  }
  if (args instanceof ProductMappingExceptions.SourceProductIsMappedToMultipleTargetsAtSameLocationException) {
    this.sourceProductIsMappedToMultipleTargetsAtSameLocationException = args;
    return;
  }
  if (args instanceof ProductAmountExceptions.ProductNotAssociatedWithLocationException) {
    this.productNotAssociatedWithLocationException = args;
    return;
  }
  if (args) {
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.actorDoesNotHaveAccessToLocationException !== undefined && args.actorDoesNotHaveAccessToLocationException !== null) {
      this.actorDoesNotHaveAccessToLocationException = args.actorDoesNotHaveAccessToLocationException;
    }
    if (args.sourceLocationCannotEqualTargetLocationException !== undefined && args.sourceLocationCannotEqualTargetLocationException !== null) {
      this.sourceLocationCannotEqualTargetLocationException = args.sourceLocationCannotEqualTargetLocationException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.sourceAndTargetProductsHaveDifferentPackagingsException !== undefined && args.sourceAndTargetProductsHaveDifferentPackagingsException !== null) {
      this.sourceAndTargetProductsHaveDifferentPackagingsException = args.sourceAndTargetProductsHaveDifferentPackagingsException;
    }
    if (args.sourceProductIsMappedToMultipleTargetsAtSameLocationException !== undefined && args.sourceProductIsMappedToMultipleTargetsAtSameLocationException !== null) {
      this.sourceProductIsMappedToMultipleTargetsAtSameLocationException = args.sourceProductIsMappedToMultipleTargetsAtSameLocationException;
    }
    if (args.productNotAssociatedWithLocationException !== undefined && args.productNotAssociatedWithLocationException !== null) {
      this.productNotAssociatedWithLocationException = args.productNotAssociatedWithLocationException;
    }
  }
};
ProductMappingService.InterLocationProductMappingService_setMappings_result.prototype = {};
ProductMappingService.InterLocationProductMappingService_setMappings_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToLocationException = new LocationExceptions.ActorDoesNotHaveAccessToLocationException();
        this.actorDoesNotHaveAccessToLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.sourceLocationCannotEqualTargetLocationException = new ProductMappingExceptions.SourceLocationCannotEqualTargetLocationException();
        this.sourceLocationCannotEqualTargetLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.sourceAndTargetProductsHaveDifferentPackagingsException = new ProductMappingExceptions.SourceAndTargetProductsHaveDifferentPackagingsException();
        this.sourceAndTargetProductsHaveDifferentPackagingsException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.STRUCT) {
        this.sourceProductIsMappedToMultipleTargetsAtSameLocationException = new ProductMappingExceptions.SourceProductIsMappedToMultipleTargetsAtSameLocationException();
        this.sourceProductIsMappedToMultipleTargetsAtSameLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.STRUCT) {
        this.productNotAssociatedWithLocationException = new ProductAmountExceptions.ProductNotAssociatedWithLocationException();
        this.productNotAssociatedWithLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingService_setMappings_result.prototype.write = function(output) {
  output.writeStructBegin('InterLocationProductMappingService_setMappings_result');
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 1);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 2);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 4);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToLocationException !== null && this.actorDoesNotHaveAccessToLocationException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToLocationException', Thrift.Type.STRUCT, 5);
    this.actorDoesNotHaveAccessToLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.sourceLocationCannotEqualTargetLocationException !== null && this.sourceLocationCannotEqualTargetLocationException !== undefined) {
    output.writeFieldBegin('sourceLocationCannotEqualTargetLocationException', Thrift.Type.STRUCT, 6);
    this.sourceLocationCannotEqualTargetLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 7);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.sourceAndTargetProductsHaveDifferentPackagingsException !== null && this.sourceAndTargetProductsHaveDifferentPackagingsException !== undefined) {
    output.writeFieldBegin('sourceAndTargetProductsHaveDifferentPackagingsException', Thrift.Type.STRUCT, 8);
    this.sourceAndTargetProductsHaveDifferentPackagingsException.write(output);
    output.writeFieldEnd();
  }
  if (this.sourceProductIsMappedToMultipleTargetsAtSameLocationException !== null && this.sourceProductIsMappedToMultipleTargetsAtSameLocationException !== undefined) {
    output.writeFieldBegin('sourceProductIsMappedToMultipleTargetsAtSameLocationException', Thrift.Type.STRUCT, 9);
    this.sourceProductIsMappedToMultipleTargetsAtSameLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.productNotAssociatedWithLocationException !== null && this.productNotAssociatedWithLocationException !== undefined) {
    output.writeFieldBegin('productNotAssociatedWithLocationException', Thrift.Type.STRUCT, 10);
    this.productNotAssociatedWithLocationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingService_getMappingsBetweenLocations_args = function(args) {
  this.actor = null;
  this.sourceLocation = null;
  this.targetLocation = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.sourceLocation !== undefined && args.sourceLocation !== null) {
      this.sourceLocation = new LocationModel.LocationIdentifier(args.sourceLocation);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sourceLocation is unset!');
    }
    if (args.targetLocation !== undefined && args.targetLocation !== null) {
      this.targetLocation = new LocationModel.LocationIdentifier(args.targetLocation);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field targetLocation is unset!');
    }
  }
};
ProductMappingService.InterLocationProductMappingService_getMappingsBetweenLocations_args.prototype = {};
ProductMappingService.InterLocationProductMappingService_getMappingsBetweenLocations_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.sourceLocation = new LocationModel.LocationIdentifier();
        this.sourceLocation.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.targetLocation = new LocationModel.LocationIdentifier();
        this.targetLocation.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingService_getMappingsBetweenLocations_args.prototype.write = function(output) {
  output.writeStructBegin('InterLocationProductMappingService_getMappingsBetweenLocations_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.sourceLocation !== null && this.sourceLocation !== undefined) {
    output.writeFieldBegin('sourceLocation', Thrift.Type.STRUCT, 2);
    this.sourceLocation.write(output);
    output.writeFieldEnd();
  }
  if (this.targetLocation !== null && this.targetLocation !== undefined) {
    output.writeFieldBegin('targetLocation', Thrift.Type.STRUCT, 3);
    this.targetLocation.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingService_getMappingsBetweenLocations_result = function(args) {
  this.success = null;
  this.serviceTemporarilyUnavailableException = null;
  this.rateLimitExceededException = null;
  this.unknownActorException = null;
  this.sourceLocationCannotEqualTargetLocationException = null;
  this.unknownLocationIdentifierException = null;
  this.actorDoesNotHaveAccessToLocationException = null;
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof ProductMappingExceptions.SourceLocationCannotEqualTargetLocationException) {
    this.sourceLocationCannotEqualTargetLocationException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof LocationExceptions.ActorDoesNotHaveAccessToLocationException) {
    this.actorDoesNotHaveAccessToLocationException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = Thrift.copyList(args.success, [ProductMappingModel.MappingUpdateEvent]);
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.sourceLocationCannotEqualTargetLocationException !== undefined && args.sourceLocationCannotEqualTargetLocationException !== null) {
      this.sourceLocationCannotEqualTargetLocationException = args.sourceLocationCannotEqualTargetLocationException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.actorDoesNotHaveAccessToLocationException !== undefined && args.actorDoesNotHaveAccessToLocationException !== null) {
      this.actorDoesNotHaveAccessToLocationException = args.actorDoesNotHaveAccessToLocationException;
    }
  }
};
ProductMappingService.InterLocationProductMappingService_getMappingsBetweenLocations_result.prototype = {};
ProductMappingService.InterLocationProductMappingService_getMappingsBetweenLocations_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.LIST) {
        var _size24 = 0;
        var _rtmp328;
        this.success = [];
        var _etype27 = 0;
        _rtmp328 = input.readListBegin();
        _etype27 = _rtmp328.etype;
        _size24 = _rtmp328.size;
        for (var _i29 = 0; _i29 < _size24; ++_i29)
        {
          var elem30 = null;
          elem30 = new ProductMappingModel.MappingUpdateEvent();
          elem30.read(input);
          this.success.push(elem30);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.sourceLocationCannotEqualTargetLocationException = new ProductMappingExceptions.SourceLocationCannotEqualTargetLocationException();
        this.sourceLocationCannotEqualTargetLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToLocationException = new LocationExceptions.ActorDoesNotHaveAccessToLocationException();
        this.actorDoesNotHaveAccessToLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingService_getMappingsBetweenLocations_result.prototype.write = function(output) {
  output.writeStructBegin('InterLocationProductMappingService_getMappingsBetweenLocations_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.LIST, 0);
    output.writeListBegin(Thrift.Type.STRUCT, this.success.length);
    for (var iter31 in this.success)
    {
      if (this.success.hasOwnProperty(iter31))
      {
        iter31 = this.success[iter31];
        iter31.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 1);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 2);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.sourceLocationCannotEqualTargetLocationException !== null && this.sourceLocationCannotEqualTargetLocationException !== undefined) {
    output.writeFieldBegin('sourceLocationCannotEqualTargetLocationException', Thrift.Type.STRUCT, 4);
    this.sourceLocationCannotEqualTargetLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToLocationException !== null && this.actorDoesNotHaveAccessToLocationException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToLocationException', Thrift.Type.STRUCT, 6);
    this.actorDoesNotHaveAccessToLocationException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingService_deleteMapping_args = function(args) {
  this.actor = null;
  this.mapping = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.mapping !== undefined && args.mapping !== null) {
      this.mapping = new ProductMappingModel.InterLocationProductMapping(args.mapping);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field mapping is unset!');
    }
  }
};
ProductMappingService.InterLocationProductMappingService_deleteMapping_args.prototype = {};
ProductMappingService.InterLocationProductMappingService_deleteMapping_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.mapping = new ProductMappingModel.InterLocationProductMapping();
        this.mapping.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingService_deleteMapping_args.prototype.write = function(output) {
  output.writeStructBegin('InterLocationProductMappingService_deleteMapping_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.mapping !== null && this.mapping !== undefined) {
    output.writeFieldBegin('mapping', Thrift.Type.STRUCT, 2);
    this.mapping.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingService_deleteMapping_result = function(args) {
  this.serviceTemporarilyUnavailableException = null;
  this.rateLimitExceededException = null;
  this.unknownActorException = null;
  this.unknownLocationIdentifierException = null;
  this.actorDoesNotHaveAccessToLocationException = null;
  this.sourceLocationCannotEqualTargetLocationException = null;
  this.unknownProductIdException = null;
  this.mappingIsInvolvedInTransferException = null;
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownActorException) {
    this.unknownActorException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args instanceof LocationExceptions.ActorDoesNotHaveAccessToLocationException) {
    this.actorDoesNotHaveAccessToLocationException = args;
    return;
  }
  if (args instanceof ProductMappingExceptions.SourceLocationCannotEqualTargetLocationException) {
    this.sourceLocationCannotEqualTargetLocationException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof ProductMappingExceptions.MappingIsInvolvedInTransferException) {
    this.mappingIsInvolvedInTransferException = args;
    return;
  }
  if (args) {
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.unknownActorException !== undefined && args.unknownActorException !== null) {
      this.unknownActorException = args.unknownActorException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
    if (args.actorDoesNotHaveAccessToLocationException !== undefined && args.actorDoesNotHaveAccessToLocationException !== null) {
      this.actorDoesNotHaveAccessToLocationException = args.actorDoesNotHaveAccessToLocationException;
    }
    if (args.sourceLocationCannotEqualTargetLocationException !== undefined && args.sourceLocationCannotEqualTargetLocationException !== null) {
      this.sourceLocationCannotEqualTargetLocationException = args.sourceLocationCannotEqualTargetLocationException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.mappingIsInvolvedInTransferException !== undefined && args.mappingIsInvolvedInTransferException !== null) {
      this.mappingIsInvolvedInTransferException = args.mappingIsInvolvedInTransferException;
    }
  }
};
ProductMappingService.InterLocationProductMappingService_deleteMapping_result.prototype = {};
ProductMappingService.InterLocationProductMappingService_deleteMapping_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownActorException = new AccountsExceptions.UnknownActorException();
        this.unknownActorException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.actorDoesNotHaveAccessToLocationException = new LocationExceptions.ActorDoesNotHaveAccessToLocationException();
        this.actorDoesNotHaveAccessToLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.sourceLocationCannotEqualTargetLocationException = new ProductMappingExceptions.SourceLocationCannotEqualTargetLocationException();
        this.sourceLocationCannotEqualTargetLocationException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.mappingIsInvolvedInTransferException = new ProductMappingExceptions.MappingIsInvolvedInTransferException();
        this.mappingIsInvolvedInTransferException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingService_deleteMapping_result.prototype.write = function(output) {
  output.writeStructBegin('InterLocationProductMappingService_deleteMapping_result');
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 1);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 2);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownActorException !== null && this.unknownActorException !== undefined) {
    output.writeFieldBegin('unknownActorException', Thrift.Type.STRUCT, 3);
    this.unknownActorException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 4);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.actorDoesNotHaveAccessToLocationException !== null && this.actorDoesNotHaveAccessToLocationException !== undefined) {
    output.writeFieldBegin('actorDoesNotHaveAccessToLocationException', Thrift.Type.STRUCT, 5);
    this.actorDoesNotHaveAccessToLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.sourceLocationCannotEqualTargetLocationException !== null && this.sourceLocationCannotEqualTargetLocationException !== undefined) {
    output.writeFieldBegin('sourceLocationCannotEqualTargetLocationException', Thrift.Type.STRUCT, 6);
    this.sourceLocationCannotEqualTargetLocationException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 7);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.mappingIsInvolvedInTransferException !== null && this.mappingIsInvolvedInTransferException !== undefined) {
    output.writeFieldBegin('mappingIsInvolvedInTransferException', Thrift.Type.STRUCT, 8);
    this.mappingIsInvolvedInTransferException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ProductMappingService.InterLocationProductMappingServiceClient = function(input, output) {
    this.input = input;
    this.output = (!output) ? input : output;
    this.seqid = 0;
};
ProductMappingService.InterLocationProductMappingServiceClient.prototype = {};
ProductMappingService.InterLocationProductMappingServiceClient.prototype.setProductQuantityUnitMappings = function(actor, mappings, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_setProductQuantityUnitMappings(pendingError, actor, mappings, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_setProductQuantityUnitMappings();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

ProductMappingService.InterLocationProductMappingServiceClient.prototype.send_setProductQuantityUnitMappings = function(pendingError, actor, mappings, callback) {
  this.output.writeMessageBegin('setProductQuantityUnitMappings', Thrift.MessageType.CALL, this.seqid);
  var args = new ProductMappingService.InterLocationProductMappingService_setProductQuantityUnitMappings_args();
  args.actor = actor;
  args.mappings = mappings;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_setProductQuantityUnitMappings() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

ProductMappingService.InterLocationProductMappingServiceClient.prototype.recv_setProductQuantityUnitMappings = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new ProductMappingService.InterLocationProductMappingService_setProductQuantityUnitMappings_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.actorDoesNotHaveAccessToLocationException) {
    throw result.actorDoesNotHaveAccessToLocationException;
  }
  if (null !== result.sourceLocationCannotEqualTargetLocationException) {
    throw result.sourceLocationCannotEqualTargetLocationException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.sourceAndTargetProductsHaveDifferentPackagingsException) {
    throw result.sourceAndTargetProductsHaveDifferentPackagingsException;
  }
  if (null !== result.quantityMustBeNonnegativeException) {
    throw result.quantityMustBeNonnegativeException;
  }
  if (null !== result.productNotAssociatedWithLocationException) {
    throw result.productNotAssociatedWithLocationException;
  }
  return;
};
ProductMappingService.InterLocationProductMappingServiceClient.prototype.getProductQuantityUnitMappingsBetweenLocations = function(actor, sourceLocation, targetLocation, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getProductQuantityUnitMappingsBetweenLocations(pendingError, actor, sourceLocation, targetLocation, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getProductQuantityUnitMappingsBetweenLocations();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

ProductMappingService.InterLocationProductMappingServiceClient.prototype.send_getProductQuantityUnitMappingsBetweenLocations = function(pendingError, actor, sourceLocation, targetLocation, callback) {
  this.output.writeMessageBegin('getProductQuantityUnitMappingsBetweenLocations', Thrift.MessageType.CALL, this.seqid);
  var args = new ProductMappingService.InterLocationProductMappingService_getProductQuantityUnitMappingsBetweenLocations_args();
  args.actor = actor;
  args.sourceLocation = sourceLocation;
  args.targetLocation = targetLocation;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getProductQuantityUnitMappingsBetweenLocations() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

ProductMappingService.InterLocationProductMappingServiceClient.prototype.recv_getProductQuantityUnitMappingsBetweenLocations = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new ProductMappingService.InterLocationProductMappingService_getProductQuantityUnitMappingsBetweenLocations_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.sourceLocationCannotEqualTargetLocationException) {
    throw result.sourceLocationCannotEqualTargetLocationException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.actorDoesNotHaveAccessToLocationException) {
    throw result.actorDoesNotHaveAccessToLocationException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getProductQuantityUnitMappingsBetweenLocations failed: unknown result';
};
ProductMappingService.InterLocationProductMappingServiceClient.prototype.deleteProductQuantityUnitMapping = function(actor, mapping, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_deleteProductQuantityUnitMapping(pendingError, actor, mapping, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_deleteProductQuantityUnitMapping();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

ProductMappingService.InterLocationProductMappingServiceClient.prototype.send_deleteProductQuantityUnitMapping = function(pendingError, actor, mapping, callback) {
  this.output.writeMessageBegin('deleteProductQuantityUnitMapping', Thrift.MessageType.CALL, this.seqid);
  var args = new ProductMappingService.InterLocationProductMappingService_deleteProductQuantityUnitMapping_args();
  args.actor = actor;
  args.mapping = mapping;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_deleteProductQuantityUnitMapping() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

ProductMappingService.InterLocationProductMappingServiceClient.prototype.recv_deleteProductQuantityUnitMapping = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new ProductMappingService.InterLocationProductMappingService_deleteProductQuantityUnitMapping_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.actorDoesNotHaveAccessToLocationException) {
    throw result.actorDoesNotHaveAccessToLocationException;
  }
  if (null !== result.sourceLocationCannotEqualTargetLocationException) {
    throw result.sourceLocationCannotEqualTargetLocationException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.mappingIsInvolvedInTransferException) {
    throw result.mappingIsInvolvedInTransferException;
  }
  return;
};
ProductMappingService.InterLocationProductMappingServiceClient.prototype.setMappings = function(actor, mappings, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_setMappings(pendingError, actor, mappings, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_setMappings();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

ProductMappingService.InterLocationProductMappingServiceClient.prototype.send_setMappings = function(pendingError, actor, mappings, callback) {
  this.output.writeMessageBegin('setMappings', Thrift.MessageType.CALL, this.seqid);
  var args = new ProductMappingService.InterLocationProductMappingService_setMappings_args();
  args.actor = actor;
  args.mappings = mappings;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_setMappings() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

ProductMappingService.InterLocationProductMappingServiceClient.prototype.recv_setMappings = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new ProductMappingService.InterLocationProductMappingService_setMappings_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.actorDoesNotHaveAccessToLocationException) {
    throw result.actorDoesNotHaveAccessToLocationException;
  }
  if (null !== result.sourceLocationCannotEqualTargetLocationException) {
    throw result.sourceLocationCannotEqualTargetLocationException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.sourceAndTargetProductsHaveDifferentPackagingsException) {
    throw result.sourceAndTargetProductsHaveDifferentPackagingsException;
  }
  if (null !== result.sourceProductIsMappedToMultipleTargetsAtSameLocationException) {
    throw result.sourceProductIsMappedToMultipleTargetsAtSameLocationException;
  }
  if (null !== result.productNotAssociatedWithLocationException) {
    throw result.productNotAssociatedWithLocationException;
  }
  return;
};
ProductMappingService.InterLocationProductMappingServiceClient.prototype.getMappingsBetweenLocations = function(actor, sourceLocation, targetLocation, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getMappingsBetweenLocations(pendingError, actor, sourceLocation, targetLocation, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getMappingsBetweenLocations();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

ProductMappingService.InterLocationProductMappingServiceClient.prototype.send_getMappingsBetweenLocations = function(pendingError, actor, sourceLocation, targetLocation, callback) {
  this.output.writeMessageBegin('getMappingsBetweenLocations', Thrift.MessageType.CALL, this.seqid);
  var args = new ProductMappingService.InterLocationProductMappingService_getMappingsBetweenLocations_args();
  args.actor = actor;
  args.sourceLocation = sourceLocation;
  args.targetLocation = targetLocation;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getMappingsBetweenLocations() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

ProductMappingService.InterLocationProductMappingServiceClient.prototype.recv_getMappingsBetweenLocations = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new ProductMappingService.InterLocationProductMappingService_getMappingsBetweenLocations_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.sourceLocationCannotEqualTargetLocationException) {
    throw result.sourceLocationCannotEqualTargetLocationException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.actorDoesNotHaveAccessToLocationException) {
    throw result.actorDoesNotHaveAccessToLocationException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getMappingsBetweenLocations failed: unknown result';
};
ProductMappingService.InterLocationProductMappingServiceClient.prototype.deleteMapping = function(actor, mapping, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_deleteMapping(pendingError, actor, mapping, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_deleteMapping();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

ProductMappingService.InterLocationProductMappingServiceClient.prototype.send_deleteMapping = function(pendingError, actor, mapping, callback) {
  this.output.writeMessageBegin('deleteMapping', Thrift.MessageType.CALL, this.seqid);
  var args = new ProductMappingService.InterLocationProductMappingService_deleteMapping_args();
  args.actor = actor;
  args.mapping = mapping;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_deleteMapping() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

ProductMappingService.InterLocationProductMappingServiceClient.prototype.recv_deleteMapping = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new ProductMappingService.InterLocationProductMappingService_deleteMapping_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.unknownActorException) {
    throw result.unknownActorException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.actorDoesNotHaveAccessToLocationException) {
    throw result.actorDoesNotHaveAccessToLocationException;
  }
  if (null !== result.sourceLocationCannotEqualTargetLocationException) {
    throw result.sourceLocationCannotEqualTargetLocationException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.mappingIsInvolvedInTransferException) {
    throw result.mappingIsInvolvedInTransferException;
  }
  return;
};
