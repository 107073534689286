import moment from 'moment-timezone';

import { StringValueSet } from 'api/Core/StringValueSet';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { IRetailerPopulationFromInvoiceUpload } from 'api/Onboarding/model/IRetailerPopulationFromInvoiceUpload';
import { ProductUploadJSONToObjectSerializer } from 'api/Onboarding/serializer/ProductUploadJSONToObjectSerializer';
import { DeliveryId } from 'api/Ordering/model/DeliveryId';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';

export interface IRetailerPopulationFromInvoiceUploadJSONObject {
    date : string;
    creation_user_id : string;
    result : {
        defaults_by_product_id : {[ productIdValue : string] : object};
        delivery_ids : Array<string>;
        distributor_ids : Array<string>;
    } | null;
}

export class RetailerPopulationFromInvoiceUploadJSONToObjectSerializer {
    constructor(
        private readonly productUploadJSONToObjectSerializer : ProductUploadJSONToObjectSerializer,
    ) {}

    public getFromJSON(
        objectJSON : IRetailerPopulationFromInvoiceUploadJSONObject
    ) : IRetailerPopulationFromInvoiceUpload {
        return {
            date: moment.utc(objectJSON.date),
            creationUserId: new UserAccountId(objectJSON.creation_user_id),
            result: objectJSON.result ? {
                defaultsByProductId: this.productUploadJSONToObjectSerializer.getDefaultsByProductIdFromJSON(objectJSON.result.defaults_by_product_id),
                deliveryIds: new StringValueSet(objectJSON.result.delivery_ids.map((deliveryIdValue) => {
                    return new DeliveryId(deliveryIdValue);
                })),
                distributorIds : new StringValueSet(objectJSON.result.distributor_ids.map((distributorIdValue) => {
                    return new DistributorId(distributorIdValue);
                })),
            } : null,
        };
    }
}
