import React from 'react';

import { ISalesItemForm } from 'apps/CreateOrEditSalesItem/reducers/reducers';
import { Button } from 'shared/components/Button';
import { FormatMonetaryValueWithCents } from 'shared/utils/FormatMonetaryValue';

import '../../css/InformationFromPOS.scss';

export interface IInformationFromPOSProps {
    salesItemFormInfo : ISalesItemForm;
    onOmitClick : (() => void) | null; // null = not allowed in this context
}

// This is a silly name but can't think of anything better. it's just non-editable from this view because it theoretically
// came directly from the POS system.
// TODO sales price: if it does not match the sales item's sales price, what to show???
export class InformationFromPOS extends React.Component<IInformationFromPOSProps, object> {
    public render() {
        const {
            salesItemFormInfo,
            onOmitClick
        } = this.props;

        let salesPrice : number | null = parseFloat(salesItemFormInfo.salesInformationForm.salesPrice.value);
        if (isNaN(salesPrice)) {
            salesPrice = null; // hmm this should not happen rn because this is not editable.
        }

        return (
            <div className="information-from-pos-container">
                <span className="imported-from-text">IMPORTED FROM POS</span>
                <div className="sales-item-info-row-container">
                    <span className="sales-item-name-text">{ salesItemFormInfo.salesItemInfoForm.salesItemName.value }</span>
                    { onOmitClick !== null &&
                        <span className="action-buttons">
                            <Button
                                buttonClassName="flat danger with-icon normal"
                                isDisabled={ false }
                                isLoading={ false }
                                onClick={ onOmitClick }
                            >
                                <span className="bevicon bevico-remove-circle"/>&nbsp;Omit
                            </Button>
                        </span>
                    }
                </div>
                <div className="sales-item-info-row-container">
                    <span className="sales-item-info-label">POS ID: </span>
                    <span className="sales-item-info-value">{ salesItemFormInfo.salesItemInfoForm.posId.value }</span>
                </div>
                <div className="sales-item-info-row-container">
                    <span className="sales-item-info-label">Menu Group: </span>
                    <span className="sales-item-info-value">{ salesItemFormInfo.salesItemInfoForm.menuGroup.value }</span>
                </div>
                <div className="sales-item-info-row-container">
                    <span className="sales-item-info-label">Sales Price: </span>
                    <span className="sales-item-info-value">{ FormatMonetaryValueWithCents(salesPrice) }</span>
                </div>

                { salesItemFormInfo.salesItemInfoForm.note.value !== '' &&
                    <div className="sales-item-note-row-container">
                        <span className="sales-item-note-label">Note:</span>
                        <span className="sales-item-note-value">{ salesItemFormInfo.salesItemInfoForm.note.value }</span>
                    </div>
                }
            </div>
        );
    }
}
