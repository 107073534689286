import React from 'react';

import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductName } from 'shared/components/ProductName/ProductName';

interface ISearchResultListRow {
    productId : ProductId;
    product : Product;
    errorMessage : string | null;
    selected : boolean;
    setSelectedProduct(productId : ProductId | null) : void;
}

export class SearchResultListRow extends React.Component<ISearchResultListRow, object> {

    constructor(props : ISearchResultListRow) {
        super(props);

        this.isProductSelected = this.isProductSelected.bind(this);
        this.setSelectedProduct = this.setSelectedProduct.bind(this);
    }

    public shouldComponentUpdate(nextProps : ISearchResultListRow) {
        return nextProps.selected !== this.props.selected;
    }

    public render() {
        const {
            product,
            errorMessage,
        } = this.props;

        const rowStateClass = this.isProductSelected() ? ' selected' : '';

        let errorMessageElement : JSX.Element | null = null;
        if (errorMessage !== null) {
            errorMessageElement = (
                <span>{ errorMessage }</span>
            );
        }

        return (
            <div
                className={ 'search-result-list-row' + rowStateClass }
                onClick={ this.setSelectedProduct }
            >
                <div className="col-row">
                    <div className="cell cell-ptb-md cell-plr col-xs-8">
                        <ProductName
                            product={ product }
                        />
                        <span className="item-information-error-message">{ errorMessageElement }</span>
                    </div>
                    <div className="cell col-xs-4 cell-plr">
                        <button className="pull-right">
                            <div className="search-result-add-item">
                                <span className="bevicon bevico-add" />
                                <span className="bevicon bevico-check" />
                            </div>
                        </button>
                        <span className="search-result-list-row-add-message text-right">Select Product</span>
                    </div>
                </div>
            </div>
        );
    }

    private isProductSelected() {
        return this.props.errorMessage === null && this.props.selected;
    }

    private setSelectedProduct() {
        // if `disabled` is true, override `selected` option
        const productToSetSelected = this.isProductSelected() ?
            null :
            this.props.productId;

        this.props.setSelectedProduct(productToSetSelected);
    }
}
