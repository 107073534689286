import { Unit } from 'api/Product/model/Unit';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export enum VolumeUnit {
    LITER = 'L',
    MILLILITER = 'ml',
    CENTILITER = 'cl',
    GALLON = 'gal',
    QUART = 'qt',
    PINT = 'pt',
    OUNCE = 'oz',
    BAR_SPOON = 'bsp', // not supported in thrift VolumeUnit
    DASH = 'dash', // not supported in thrift VolumeUnit
}

const unitByValue = new Map<string, VolumeUnit>();
Object.keys(VolumeUnit).forEach((key) => {
    unitByValue.set(VolumeUnit[key as any], VolumeUnit[key as any] as VolumeUnit);
});

export namespace VolumeUnit {
    export function isVolumeUnit(unit : Unit) : unit is VolumeUnit {
        return (typeof unitByValue.get(unit) !== 'undefined');
    }

    export function isVolumeUnitValue(unitValue : string) : boolean {
        return (typeof unitByValue.get(unitValue) !== 'undefined');
    }

    export function getByVolumeUnitValue(unitValue : string) : VolumeUnit {
        const unit = unitByValue.get(unitValue);

        if (typeof unit === 'undefined') {
            throw new RuntimeException('unexpected value: ' + unitValue);
        }

        return unit;
    }

    export function getUnits() : Array<VolumeUnit> {
        return Array.from(unitByValue.values());
    }
}
