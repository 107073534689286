export class StediLocationReference {
    constructor(
        private readonly locationId : string | null,
        private readonly vendorId : string | null,
        private readonly groupId : string | null
    ) {
    }

    public getLocationId() : string | null {
        return this.locationId;
    }

    public getVendorId() : string | null {
        return this.vendorId;
    }

    public getGroupId() : string | null {
        return this.groupId;
    }

}
