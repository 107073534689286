import { StringValueMap } from 'api/Core/StringValueMap';
import { IProductUploadDefaults } from 'api/Onboarding/model/IProductUploadDefaults';
import { ProductId } from 'api/Product/model/ProductId';

import { ProductJSONToObjectSerializer } from 'api/Product/serializer/ProductJSONToObjectSerializer';

export class ProductUploadJSONToObjectSerializer {
    constructor(
        private readonly productJSONToObjectSerializer : ProductJSONToObjectSerializer,
    ) {}

    public getDefaultsByProductIdFromJSON(
        defaultsByProductIdJSON : any
    ) : StringValueMap<ProductId, IProductUploadDefaults> {

        const defaultsByProductId = new StringValueMap<ProductId, IProductUploadDefaults>();
        Object.keys(defaultsByProductIdJSON).forEach((key : string) => {
            const productId = new ProductId(key);
            const defaultsObject = defaultsByProductIdJSON[key];

            const uploadDefaults : IProductUploadDefaults = {};
            // 4 keys right now: 'product_category_id', 'new_package', 'deposit', 'price'
            Object.keys(defaultsObject).forEach((productFieldKey) => {
                const originalValue = defaultsObject[productFieldKey].original_value;
                const defaultValue : any = defaultsObject[productFieldKey].default;

                switch (productFieldKey) {
                    case 'product_category_id':
                        uploadDefaults.productCategory = {
                            originalValue,
                            default: defaultValue,
                        };
                        break;
                    case 'new_package':
                        uploadDefaults.package = {
                            originalValue,
                            default: this.productJSONToObjectSerializer.getPackaging(defaultValue)
                        };
                        break;
                    case 'deposit':
                        uploadDefaults.deposit = {
                            originalValue,
                            default: defaultValue.value,
                        };
                        break;
                    case 'price':
                        uploadDefaults.price = {
                            originalValue,
                            default: this.productJSONToObjectSerializer.getPrice(defaultValue.price, defaultValue.price_unit),
                        };
                        break;
                }
            });

            defaultsByProductId.set(productId, uploadDefaults);
        });

        return defaultsByProductId;
    }
}
