import { IProductCostSettings } from 'api/Location/model/IProductCostSettings';
import { Category } from 'api/Product/model/Category';
import { CategoryId } from 'api/Product/model/CategoryId';
import { ProductMergeEvent } from 'api/Product/model/ProductMergeEvent';
import { CategoryUtils } from 'api/Product/utils/categoryUtils';
import { IUserName } from 'api/UserAccount/model/IUserName';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { Action } from 'redux';

import { BreakageId } from 'api/Breakage/model/BreakageId';
import { BreakageReport } from 'api/Breakage/model/BreakageReport';
import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { Distributor } from 'api/Distributor/model/Distributor';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { InventoryCount } from 'api/InventoryCount/model/InventoryCount';
import { InventoryCountId } from 'api/InventoryCount/model/InventoryCountId';
import { InventoryCountMetadata } from 'api/InventoryCount/model/InventoryCountMetadata';
import { LocationId } from 'api/Location/model/LocationId';
import { Delivery } from 'api/Ordering/model/Delivery';
import { DeliveryId } from 'api/Ordering/model/DeliveryId';
import { PrepEvent } from 'api/PrepEvent/model/PrepEvent';
import { PrepEventId } from 'api/PrepEvent/model/PrepEventId';
import { MassUnit } from 'api/Product/model/MassUnit';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { TransferId } from 'api/Transfer/model/TransferId';
import { TransferReport } from 'api/Transfer/model/TransferReport';

import { ActionInterfaces, ActionTypes } from '../actions/ItemCardActions';
import { ProductDistributorAssociationFormId } from '../model/ProductDistributorAssociationFormId';
import { ProductFormUtils } from '../utils/ProductFormUtils';

import { makeOptionFromValue, OptionsAndLabelNameTuples } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { IValidationInputData } from 'shared/components/ValidationInput';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { ProductDistributorAssociation } from 'api/Product/model/ProductDistributorAssociation';
import { ProductDistributorAssociationIdentifier } from 'api/Product/model/ProductDistributorAssociationIdentifier';

export type ItemCardView = 'editProduct' | 'productHistory' | 'createProduct';
export type ComponentName = 'itemCardModal' | 'productModificationConfirmationDialog' | 'deleteProductConfirmationDialog';

export const DEFAULT_PRICE_VALUE = '0.00';

export type ProductFormFieldName = 'name' | 'brand' | 'distributorId' | 'baseUnit' | 'preferredReportingUnit' | 'priceAmount' | 'priceUnit' | 'categoryId' | 'type' | 'sku' | 'unitDeposit' | 'note' | 'glCode' | 'useCustomWeights' | 'costAmount' | 'costUnit' | 'parCount' | 'parUnit';
export type PackagingWeightFormFieldName = 'emptyWeightQuantity' | 'emptyWeightUnit' | 'fullWeightQuantity' | 'fullWeightUnit';
export type PackagingWeightFormValidationInputDataByFieldName = { [fieldName in PackagingWeightFormFieldName] : IValidationInputData };
export type ProductDistributorAssociationFormFieldName = 'unit' | 'distributorId' | 'sku' | 'priceAmount' | 'priceUnit' | 'depositAmount' | 'depositUnit';
export type ProductDistributorAssociationFormValidationInputDataByFieldName = { [fieldName in ProductDistributorAssociationFormFieldName] : IValidationInputData };
export interface IProductDistributorAssociationForm {
    validationInputDataByFieldName : ProductDistributorAssociationFormValidationInputDataByFieldName;
    isValid : boolean;
    errorMessage : string;
    packagingIndex : number | null; // Null means we should decide the index based on the unit in the validationInputDataByFieldName. Gets updated to a value when edited
}

export type ProductFormValidationInputDataByFieldName = { [fieldName in ProductFormFieldName] : IValidationInputData } & {
    conversions : { [productQuantityUnitValue : string] : IValidationInputData },
    packagingWeights : { [productQuantityUnitValue : string] : PackagingWeightFormValidationInputDataByFieldName },
    productDistributorAssociations : StringValueMap<ProductDistributorAssociationFormId, IProductDistributorAssociationForm>
};
// TODO Dirty use custom weights is a boolean but is treated as string where '' is false and 'true' is true

export interface IItemCardData {
    productsById : StringValueMap<ProductId, Product>;
    categoriesById : StringValueMap<CategoryId, Category>;
    activeProductIds : StringValueSet<ProductId>;
    namesByUserAccountId : StringValueMap<UserAccountId, IUserName>;
    distributorIdsByProductId : StringValueMap<ProductId, DistributorId | null>;
    productDistributorAssociationsByProductId : StringValueMap<ProductId, StringValueMap<ProductDistributorAssociationIdentifier, ProductDistributorAssociation>>;
    distributorsByDistributorId : StringValueMap<DistributorId, Distributor>;
    distributorIdsWithRelationship : StringValueSet<DistributorId>;
    productCostSettings : IProductCostSettings;
}

export interface IProductHistoryData {
    inventoryMetadataByInventoryId : StringValueMap<InventoryCountId, InventoryCountMetadata>;
    inventoryCountsByInventoryId : StringValueMap<InventoryCountId, InventoryCount>;
    deliveriesByDeliveryId : StringValueMap<DeliveryId, Delivery>;
    transferReportsByTransferId : StringValueMap<TransferId, TransferReport>;
    breakageReportsByBreakageId : StringValueMap<BreakageId, BreakageReport>;
    prepEventsByPrepEventId : StringValueMap<PrepEventId, PrepEvent>;
    locationNamesByLocationId : StringValueMap<LocationId, string>;
    productMergeEventsByProductId : StringValueMap<ProductId, Array<ProductMergeEvent>>;
    numMonthsOfData : number;
}

export interface IProductFormData {
    distributorSortedOptionsAndLabelNames : OptionsAndLabelNameTuples; // TODO Cleanup after everyone is using multiVendor
    glCodeSortedOptionsAndLabelNames : OptionsAndLabelNameTuples;
    categorySortedOptionsAndLabelNames : OptionsAndLabelNameTuples;
    productTypeSortedOptionsAndLabelNames : OptionsAndLabelNameTuples;
}

export interface IItemCardState {
    productId : ProductId | null;
    productHash : string | null;
    selectedItemCardView : ItemCardView;
    itemCardData : IItemCardData | null;
    productHistoryData : IProductHistoryData | null;
    productFormData : IProductFormData;
    productForm : {
        validationInputDataByFieldName : ProductFormValidationInputDataByFieldName;
        isValid : boolean;
        errorMessage : string;
        isSubmitting : boolean;
    };
    lastEditedAccountInfo : IUserName;
    isShownByComponentName : { [componentName in ComponentName] : boolean };
    focusedField : ProductFormFieldName | PackagingWeightFormFieldName | null;
}

export const CREATE_PRODUCT_FORM_INITIAL_VALIDATION_INPUT_DATA_BY_FIELD_NAME : ProductFormValidationInputDataByFieldName = {
    name: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    brand: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    distributorId: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    baseUnit: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    preferredReportingUnit: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    priceAmount: {
        value: DEFAULT_PRICE_VALUE,
        isValid: true,
        errorMessage: '',
    },
    priceUnit: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    categoryId: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    type: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    sku: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    unitDeposit: {
        value: DEFAULT_PRICE_VALUE,
        isValid: true,
        errorMessage: '',
    },
    note: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    glCode: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    useCustomWeights: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    costAmount: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    costUnit: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    parCount: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    parUnit: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    conversions: {},
    packagingWeights: {},
    productDistributorAssociations: new StringValueMap(),
};

export const PACKAGING_WEIGHT_FORM_INITIAL_VALIDATION_INPUT_DATA_BY_FIELD_NAME : PackagingWeightFormValidationInputDataByFieldName = {
    emptyWeightQuantity: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    emptyWeightUnit: {
        value: MassUnit.DRY_OUNCE,
        isValid: true,
        errorMessage: '',
    },
    fullWeightQuantity: {
        value: '',
        isValid: true,
        errorMessage: '',
    },
    fullWeightUnit: {
        value: MassUnit.DRY_OUNCE,
        isValid: true,
        errorMessage: '',
    },
};

export const initialState : IItemCardState = {
    productId: null,
    productHash: null,
    selectedItemCardView: 'createProduct',
    itemCardData: null,
    productHistoryData: null,
    productFormData: {
        distributorSortedOptionsAndLabelNames: [],
        glCodeSortedOptionsAndLabelNames: [],
        categorySortedOptionsAndLabelNames:
            [[null, CategoryUtils.getDefaultCategories().map(makeOptionFromValue)]],
        productTypeSortedOptionsAndLabelNames: [],
    },
    productForm: {
        validationInputDataByFieldName: CREATE_PRODUCT_FORM_INITIAL_VALIDATION_INPUT_DATA_BY_FIELD_NAME,
        isValid: true,
        errorMessage: '',
        isSubmitting: false,
    },
    isShownByComponentName: {
        itemCardModal: false,
        productModificationConfirmationDialog: false,
        deleteProductConfirmationDialog: false,
    },
    lastEditedAccountInfo: {
        firstName: '',
        lastName: ''
    },
    focusedField : null
};

const reduceSetProductId = (
    state : IItemCardState,
    action : ActionInterfaces.ISetProductId
) : IItemCardState => {
    return {
        ...state,
        productId: action.payload.productId,
        productHash: action.payload.productHash,
    };
};

const reduceSetSelectedItemCardView = (
    state : IItemCardState,
    action : ActionInterfaces.ISetSelectedItemCardView
) : IItemCardState => {
    return {
        ...state,
        selectedItemCardView: action.payload.selectedItemCardView,
    };
};

const reduceSetItemCardData = (
    state : IItemCardState,
    action : ActionInterfaces.ISetItemCardData
) : IItemCardState => {
    return {
        ...state,
        itemCardData: action.payload.itemCardData,
    };
};

const reduceSetProductHistoryData = (
    state : IItemCardState,
    action : ActionInterfaces.ISetProductHistoryData
) : IItemCardState => {
    return {
        ...state,
        productHistoryData: action.payload.productHistoryData,
    };
};

const reduceUpdateProductInfo = (
    state : IItemCardState,
    action : ActionInterfaces.IUpdateProductInfo
) : IItemCardState => {
    const itemCardData = state.itemCardData;
    if (itemCardData === null) {
        throw new RuntimeException('unexpected');
    }

    const {
        productsById,
        distributorIdsByProductId,
        productDistributorAssociationsByProductId,
    } = action.payload;

    const newProductsById = new StringValueMap(itemCardData.productsById);
    productsById.forEach((product, productId) => {
        newProductsById.set(productId, product);
    });

    const newDistributorIdsByProductId = new StringValueMap<ProductId, DistributorId | null>(itemCardData.distributorIdsByProductId);
    distributorIdsByProductId.forEach((distributorId, productId) => {
        newDistributorIdsByProductId.set(productId, distributorId);
    });

    const newProductDistributorAssociationsByProductId = new StringValueMap(itemCardData.productDistributorAssociationsByProductId);
    productDistributorAssociationsByProductId.forEach((productDistributorAssociations, productId) => {
        newProductDistributorAssociationsByProductId.set(productId, productDistributorAssociations);
    });

    // Maybe update productFormData

    return {
        ...state,
        itemCardData: {
            ...itemCardData,
            productsById: newProductsById,
            distributorIdsByProductId: newDistributorIdsByProductId,
            productDistributorAssociationsByProductId: newProductDistributorAssociationsByProductId
        },
    };
};

const reduceUpdateDistributorsByDistributorId = (
    state : IItemCardState,
    action : ActionInterfaces.IUpdateDistributorsByDistributorId
) : IItemCardState => {
    const itemCardData = state.itemCardData;
    if (itemCardData === null) {
        throw new RuntimeException('unexpected');
    }

    const newDistributorsByDistributorId = new StringValueMap(itemCardData.distributorsByDistributorId);
    const newDistributorIdsWithRelationship  = new StringValueSet(itemCardData.distributorIdsWithRelationship);
    action.payload.distributorsByDistributorId.forEach((distributor, distributorId) => {
        newDistributorsByDistributorId.set(distributorId, distributor);
        newDistributorIdsWithRelationship.add(distributorId); // Should maybe not do it with every distributor
    });

    const newDistributorSortedOptionsAndLabelNames = ProductFormUtils.getDistributorSortedOptionsAndLabelNames(newDistributorsByDistributorId, newDistributorIdsWithRelationship, true);

    return {
        ...state,
        itemCardData: {
            ...itemCardData,
            distributorsByDistributorId: newDistributorsByDistributorId,
            distributorIdsWithRelationship: newDistributorIdsWithRelationship
        },
        productFormData: {
            ...state.productFormData,
            distributorSortedOptionsAndLabelNames: newDistributorSortedOptionsAndLabelNames,
        }
    };
};

const reduceUpdateNamesByUserAccountId = (
    state : IItemCardState,
    action : ActionInterfaces.IUpdateNamesByUserAccountId
) : IItemCardState => {
    const itemCardData = state.itemCardData;
    if (itemCardData === null) {
        throw new RuntimeException('unexpected');
    }

    const {
        namesByUserAccountId
    } = action.payload;

    const newNamesByUserAccountId = new StringValueMap(itemCardData.namesByUserAccountId);
    namesByUserAccountId.forEach((name, userAccountId) => {
        newNamesByUserAccountId.set(userAccountId, name);
    });

    return {
        ...state,
        itemCardData: {
            ...itemCardData,
            namesByUserAccountId: newNamesByUserAccountId
        },
    };
};

const reduceUpdateCategoriesById = (
    state : IItemCardState,
    action : ActionInterfaces.IUpdateCategoriesById
) : IItemCardState => {
    const itemCardData = state.itemCardData;
    if (itemCardData === null) {
        throw new RuntimeException('unexpected');
    }

    const {
        categoriesById
    } = action.payload;

    const newCategoriesById = new StringValueMap(itemCardData.categoriesById);
    categoriesById.forEach((category, categoryId) => {
        newCategoriesById.set(categoryId, category);
    });

    return {
        ...state,
        itemCardData: {
            ...itemCardData,
            categoriesById: newCategoriesById
        },
    };
};

const reduceSetFormFieldValidationData = (
    state : IItemCardState,
    action : ActionInterfaces.ISetFormFieldValidationData
) : IItemCardState => {
    return {
        ...state,
        productForm: {
            ...state.productForm,
            validationInputDataByFieldName: {
                ...state.productForm.validationInputDataByFieldName,
                [action.payload.field]: {
                    value : action.payload.value,
                    errorMessage : action.payload.errorMessage,
                    isValid : action.payload.isValid,
                },
            },
        }
    };
};

const reduceSetFormValidationData = (
    state : IItemCardState,
    action : ActionInterfaces.ISetFormValidationData
) : IItemCardState => {
    return {
        ...state,
        productForm: {
            ...state.productForm,
            isValid: action.payload.isValid,
            errorMessage: action.payload.errorMessage,
        }
    };
};

const reduceSetIsSubmitting = (
    state : IItemCardState,
    action : ActionInterfaces.ISetIsSubmitting
) : IItemCardState => {
    return {
        ...state,
        productForm: {
            ...state.productForm,
            isSubmitting: action.payload.isSubmitting,
        }
    };
};

const reduceSetComponentIsShown = (
    state : IItemCardState,
    action : ActionInterfaces.ISetComponentIsShown
) : IItemCardState => {
    return {
        ...state,
        isShownByComponentName: {
            ...state.isShownByComponentName,
            [action.payload.componentName]: action.payload.isShown,
        },
    };
};

const reduceSetProductFormData = (
    state : IItemCardState,
    action : ActionInterfaces.ISetProductFormData
) : IItemCardState => {
    return {
        ...state,
        productFormData: action.payload.productFormData,
    };
};

const reduceSetProductFormValidationInputDataByFieldName = (
    state : IItemCardState,
    action : ActionInterfaces.ISetProductFormValidationInputDataByFieldName
) : IItemCardState => {
    return {
        ...state,
        productForm: {
            ...state.productForm,
            validationInputDataByFieldName: action.payload.validationInputDataByFieldName,
        },
    };
};

const reduceSetUnitsWithConversionForms = (
    state : IItemCardState,
    action : ActionInterfaces.ISetUnitsWithConversionForms
) : IItemCardState => {
    const newConversions : { [productQuantityUnit : string] : IValidationInputData } = {};
    const conversions = state.productForm.validationInputDataByFieldName.conversions;

    action.payload.conversionUnits.forEach((unit) => {
        newConversions[unit] = conversions[unit] || {
            value: '',
            isValid: true,
            errorMessage: '',
        };
    });

    return {
        ...state,
        productForm: {
            ...state.productForm,
            validationInputDataByFieldName: {
                ...state.productForm.validationInputDataByFieldName,
                conversions: newConversions,
            },
        }
    };
};

const reduceSetConversionFieldValidationData = (
    state : IItemCardState,
    action : ActionInterfaces.ISetConversionFieldValidationData
) : IItemCardState => {
    return {
        ...state,
        productForm: {
            ...state.productForm,
            validationInputDataByFieldName: {
                ...state.productForm.validationInputDataByFieldName,
                conversions: {
                    ...state.productForm.validationInputDataByFieldName.conversions,
                    [action.payload.unit]: {
                        value : action.payload.value,
                        errorMessage : action.payload.errorMessage,
                        isValid : action.payload.isValid,
                    },
                }
            },
        }
    };
};

const reduceSetPackagingIdHasPackagingWeightForm = (
    state : IItemCardState,
    action : ActionInterfaces.ISetPackagingIdHasPackagingWeightForm
) : IItemCardState => {
    const newPackagingWeights = { ...state.productForm.validationInputDataByFieldName.packagingWeights };

    const packagingIdValue = action.payload.packagingIdValue;
    if (action.payload.hasPackagingWeightForm) {
        if (!newPackagingWeights[packagingIdValue]) {
            newPackagingWeights[packagingIdValue] = { ...PACKAGING_WEIGHT_FORM_INITIAL_VALIDATION_INPUT_DATA_BY_FIELD_NAME };
        }
    } else {
        delete newPackagingWeights[packagingIdValue];
    }

    return {
        ...state,
        productForm: {
            ...state.productForm,
            validationInputDataByFieldName: {
                ...state.productForm.validationInputDataByFieldName,
                packagingWeights: newPackagingWeights
            },
        }
    };
};

const reduceSetPackagingWeightFieldValidationData = (
    state : IItemCardState,
    action : ActionInterfaces.ISetPackagingWeightFieldValidationData
) : IItemCardState => {
    const newPackagingWeights = { ...state.productForm.validationInputDataByFieldName.packagingWeights };

    newPackagingWeights[action.payload.packagingIdValue][action.payload.field] = {
        value : action.payload.value,
        errorMessage : action.payload.errorMessage,
        isValid : action.payload.isValid,
    };

    return {
        ...state,
        productForm: {
            ...state.productForm,
            validationInputDataByFieldName: {
                ...state.productForm.validationInputDataByFieldName,
                packagingWeights: newPackagingWeights,
            },
        }
    };
};

const reduceSetPackagingWeightFormPackagingIdValue = (
    state : IItemCardState,
    action : ActionInterfaces.ISetPackagingWeightFormPackagingIdValue
) : IItemCardState => {
    const {
        oldPackagingIdValue,
        newPackagingIdValue
    } = action.payload;

    const newPackagingWeights : { [productQuantityUnitValue : string] : PackagingWeightFormValidationInputDataByFieldName } = {};
    Object.keys(state.productForm.validationInputDataByFieldName.packagingWeights).forEach((packagingIdValue) => {
        if (packagingIdValue === oldPackagingIdValue) {
            newPackagingWeights[newPackagingIdValue] = state.productForm.validationInputDataByFieldName.packagingWeights[oldPackagingIdValue];
        } else {
            newPackagingWeights[packagingIdValue] = state.productForm.validationInputDataByFieldName.packagingWeights[packagingIdValue];
        }
    });

    return {
        ...state,
        productForm: {
            ...state.productForm,
            validationInputDataByFieldName: {
                ...state.productForm.validationInputDataByFieldName,
                packagingWeights: newPackagingWeights,
            },
        }
    };
};

const reduceSetProductDistributorAssociationFieldValidationData = (
    state : IItemCardState,
    action : ActionInterfaces.ISetProductDistributorAssociationFieldValidationData
) : IItemCardState => {
    const newProductDistributorAssociations = new StringValueMap(state.productForm.validationInputDataByFieldName.productDistributorAssociations);

    const productDistributorAssociationForm = newProductDistributorAssociations.getRequired(action.payload.formId);
    newProductDistributorAssociations.set(action.payload.formId, {
        ...productDistributorAssociationForm,
        validationInputDataByFieldName: {
            ...productDistributorAssociationForm.validationInputDataByFieldName,
            [action.payload.field]: {
                value : action.payload.value,
                errorMessage : action.payload.errorMessage,
                isValid : action.payload.isValid,
            }
        },
        packagingIndex: (typeof action.payload.packagingIndex !== 'undefined') ? action.payload.packagingIndex : productDistributorAssociationForm.packagingIndex,
    });

    return {
        ...state,
        productForm: {
            ...state.productForm,
            validationInputDataByFieldName: {
                ...state.productForm.validationInputDataByFieldName,
                productDistributorAssociations: newProductDistributorAssociations
            },
        }
    };
};

const reduceAddProductDistributorAssociationForm = (
    state : IItemCardState,
    action : ActionInterfaces.IAddProductDistributorAssociationForm
) : IItemCardState => {
    const newProductDistributorAssociations = new StringValueMap(state.productForm.validationInputDataByFieldName.productDistributorAssociations);
    newProductDistributorAssociations.set(action.payload.formId, action.payload.productDistributorAssociationForm);

    return {
        ...state,
        productForm: {
            ...state.productForm,
            validationInputDataByFieldName: {
                ...state.productForm.validationInputDataByFieldName,
                productDistributorAssociations: newProductDistributorAssociations
            },
        }
    };
};

const reduceRemoveProductDistributorAssociationForms = (
    state : IItemCardState,
    action : ActionInterfaces.IRemoveProductDistributorAssociationForms
) : IItemCardState => {
    const newProductDistributorAssociations = new StringValueMap(state.productForm.validationInputDataByFieldName.productDistributorAssociations);

    action.payload.formIds.forEach((formId) => {
        newProductDistributorAssociations.delete(formId);
    });

    return {
        ...state,
        productForm: {
            ...state.productForm,
            validationInputDataByFieldName: {
                ...state.productForm.validationInputDataByFieldName,
                productDistributorAssociations: newProductDistributorAssociations
            },
        }
    };
};

const reduceSetFocusedField = (
    state : IItemCardState,
    action : ActionInterfaces.ISetFocusedField
) : IItemCardState => {
    return {
        ...state,
        focusedField: action.payload.focusedField
    };
};

export const ItemCardReducers = (
    state : IItemCardState = initialState,
    action : Action,
) : IItemCardState => {
    switch (action.type) {
        case ActionTypes.SET_PRODUCT_ID:
            return reduceSetProductId(state, action as ActionInterfaces.ISetProductId);
        case ActionTypes.SET_FORM_FIELD_VALIDATION_DATA:
            return reduceSetFormFieldValidationData(state, action as ActionInterfaces.ISetFormFieldValidationData);
        case ActionTypes.SET_FORM_VALIDATION_DATA:
            return reduceSetFormValidationData(state, action as ActionInterfaces.ISetFormValidationData);
        case ActionTypes.SET_IS_SUBMITTING:
            return reduceSetIsSubmitting(state, action as ActionInterfaces.ISetIsSubmitting);
        case ActionTypes.SET_COMPONENT_IS_SHOWN:
            return reduceSetComponentIsShown(state, action as ActionInterfaces.ISetComponentIsShown);
        case ActionTypes.SET_SELECTED_ITEM_CARD_VIEW:
            return reduceSetSelectedItemCardView(state, action as ActionInterfaces.ISetSelectedItemCardView);
        case ActionTypes.SET_ITEM_CARD_DATA:
            return reduceSetItemCardData(state, action as ActionInterfaces.ISetItemCardData);
        case ActionTypes.SET_PRODUCT_HISTORY_DATA:
            return reduceSetProductHistoryData(state, action as ActionInterfaces.ISetProductHistoryData);
        case ActionTypes.UPDATE_PRODUCT_INFO:
            return reduceUpdateProductInfo(state, action as ActionInterfaces.IUpdateProductInfo);
        case ActionTypes.UPDATE_DISTRIBUTORS_BY_DISTRIBUTOR_ID:
            return reduceUpdateDistributorsByDistributorId(state, action as ActionInterfaces.IUpdateDistributorsByDistributorId);
        case ActionTypes.UPDATE_NAMES_BY_USER_ACCOUNT_ID:
            return reduceUpdateNamesByUserAccountId(state, action as ActionInterfaces.IUpdateNamesByUserAccountId);
        case ActionTypes.UPDATE_PRODUCT_CATEGORIES_BY_ID:
            return reduceUpdateCategoriesById(state, action as ActionInterfaces.IUpdateCategoriesById);
        case ActionTypes.SET_PRODUCT_FORM_DATA:
            return reduceSetProductFormData(state, action as ActionInterfaces.ISetProductFormData);
        case ActionTypes.SET_PRODUCT_FORM_VALIDATION_INPUT_DATA_BY_FIELD_NAME:
            return reduceSetProductFormValidationInputDataByFieldName(state, action as ActionInterfaces.ISetProductFormValidationInputDataByFieldName);
        case ActionTypes.SET_UNITS_WITH_CONVERSION_FORMS:
            return reduceSetUnitsWithConversionForms(state, action as ActionInterfaces.ISetUnitsWithConversionForms);
        case ActionTypes.SET_CONVERSION_FIELD_VALIDATION_DATA:
            return reduceSetConversionFieldValidationData(state, action as ActionInterfaces.ISetConversionFieldValidationData);
        case ActionTypes.SET_PACKAGING_ID_HAS_PACKAGING_WEIGHT_FORM:
            return reduceSetPackagingIdHasPackagingWeightForm(state, action as ActionInterfaces.ISetPackagingIdHasPackagingWeightForm);
        case ActionTypes.SET_PACKAGING_WEIGHT_FIELD_VALIDATION_DATA:
            return reduceSetPackagingWeightFieldValidationData(state, action as ActionInterfaces.ISetPackagingWeightFieldValidationData);
        case ActionTypes.SET_PACKAGING_WEIGHT_FORM_PACKAGING_ID_VALUE:
            return reduceSetPackagingWeightFormPackagingIdValue(state, action as ActionInterfaces.ISetPackagingWeightFormPackagingIdValue);
        case ActionTypes.SET_PRODUCT_DISTRIBUTOR_ASSOCIATION_FIELD_VALIDATION_DATA:
            return reduceSetProductDistributorAssociationFieldValidationData(state, action as ActionInterfaces.ISetProductDistributorAssociationFieldValidationData);
        case ActionTypes.ADD_PRODUCT_DISTRIBUTOR_ASSOCIATION_FORM:
            return reduceAddProductDistributorAssociationForm(state, action as ActionInterfaces.IAddProductDistributorAssociationForm);
        case ActionTypes.REMOVE_PRODUCT_DISTRIBUTOR_ASSOCIATION_FORMS:
            return reduceRemoveProductDistributorAssociationForms(state, action as ActionInterfaces.IRemoveProductDistributorAssociationForms);
        case ActionTypes.SET_FOCUSED_FIELD:
            return reduceSetFocusedField(state, action as ActionInterfaces.ISetFocusedField);
        default:
            return state;
    }
};
