import React from 'react';

import { ConnectedBevSpotDateTimePicker, IConnectedBevSpotDateTimePicker } from 'shared/components/BevSpotDateTimePicker/BevSpotDateTimePicker';
import { Popover } from 'shared/components/Popover/Popover';
import { PositionablePopupSide } from 'shared/components/PositionablePopup/PositionablePopup';

import 'shared/css/PopupDateTimePicker.scss';
import { POPUP_DATETIME_PICKER_DATETIME_FORMAT } from 'shared/constants';

export interface IPopupDateTimePicker {
    dateTimePickerIsShown : boolean;
    setDateTimePickerIsShown : (value : boolean) => void;
    dateTimePickerProps : IConnectedBevSpotDateTimePicker;
    preferredPositionArray? : Array<PositionablePopupSide>;
    dateTimeFormat? : string;
}

export class PopupDateTimePicker extends React.Component<IPopupDateTimePicker, object> {
    public render() {
        const {
            preferredPositionArray,
            dateTimePickerIsShown,
            dateTimePickerProps,
            setDateTimePickerIsShown,
            dateTimeFormat,
        } = this.props;

        return (
            <Popover
                preferredPositionArray={ preferredPositionArray ? preferredPositionArray : ['below', 'above', 'center'] }
                className={ 'popover-datetime-picker' + (dateTimePickerIsShown ? ' popup-target-shown' : ' popup-target-hidden ') }
                showOnHover={ false }
                popoverContentIsShown={ dateTimePickerIsShown }
                setPopoverContentIsShown={ setDateTimePickerIsShown }
                anchorContent={ (
                    <div className="popup-control">
                        <div className="col-row">
                            <div className="cell col-xs-10 ellipsis-out">
                                { dateTimePickerProps.initialDateTime.getValue(dateTimeFormat || POPUP_DATETIME_PICKER_DATETIME_FORMAT) }
                            </div>
                            <div className="cell col-xs-2 text-right">
                                <span className="bevicon bevico-event" />
                            </div>
                        </div>
                    </div>
                ) }
                popoverContent={ (
                     <div className="popup-target">
                        <ConnectedBevSpotDateTimePicker
                            uniqueId={ dateTimePickerProps.uniqueId }
                            buttons={ dateTimePickerProps.buttons }
                            initialDateTime={ dateTimePickerProps.initialDateTime }
                            setDateTime={ dateTimePickerProps.setDateTime }
                            type={ dateTimePickerProps.type }
                            shouldShowTime={ dateTimePickerProps.shouldShowTime }
                        />
                    </div>
                ) }
            />
        );
    }
}
