//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


import Thrift from 'thrift';
import UnauthenticatedUserSessionModel from './unauthenticated_user_session_Model_types';
import UnauthenticatedUserSessionExceptions from './unauthenticated_user_session_Exceptions_types';
import CoreApiExceptions from './core_api_Exceptions_types';

var UnauthenticatedUserSessionService = {};
export default UnauthenticatedUserSessionService;
//HELPER FUNCTIONS AND STRUCTURES

UnauthenticatedUserSessionService.UnauthenticatedUserSessionService_createSession_args = function(args) {
  this.canvasData = null;
  this.webGLVendor = null;
  this.webGLRenderer = null;
  this.plugins = null;
  this.localStorageAllowed = null;
  this.sessionStorageAllowed = null;
  this.cookieAllowed = null;
  this.platform = null;
  this.doNotTrack = null;
  this.timezone = null;
  this.resolution = null;
  this.referrer = null;
  if (args) {
    if (args.canvasData !== undefined && args.canvasData !== null) {
      this.canvasData = args.canvasData;
    }
    if (args.webGLVendor !== undefined && args.webGLVendor !== null) {
      this.webGLVendor = args.webGLVendor;
    }
    if (args.webGLRenderer !== undefined && args.webGLRenderer !== null) {
      this.webGLRenderer = args.webGLRenderer;
    }
    if (args.plugins !== undefined && args.plugins !== null) {
      this.plugins = args.plugins;
    }
    if (args.localStorageAllowed !== undefined && args.localStorageAllowed !== null) {
      this.localStorageAllowed = args.localStorageAllowed;
    }
    if (args.sessionStorageAllowed !== undefined && args.sessionStorageAllowed !== null) {
      this.sessionStorageAllowed = args.sessionStorageAllowed;
    }
    if (args.cookieAllowed !== undefined && args.cookieAllowed !== null) {
      this.cookieAllowed = args.cookieAllowed;
    }
    if (args.platform !== undefined && args.platform !== null) {
      this.platform = args.platform;
    }
    if (args.doNotTrack !== undefined && args.doNotTrack !== null) {
      this.doNotTrack = args.doNotTrack;
    }
    if (args.timezone !== undefined && args.timezone !== null) {
      this.timezone = args.timezone;
    }
    if (args.resolution !== undefined && args.resolution !== null) {
      this.resolution = args.resolution;
    }
    if (args.referrer !== undefined && args.referrer !== null) {
      this.referrer = args.referrer;
    }
  }
};
UnauthenticatedUserSessionService.UnauthenticatedUserSessionService_createSession_args.prototype = {};
UnauthenticatedUserSessionService.UnauthenticatedUserSessionService_createSession_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.canvasData = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.webGLVendor = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.webGLRenderer = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.plugins = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.localStorageAllowed = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.sessionStorageAllowed = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.cookieAllowed = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.platform = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.STRING) {
        this.doNotTrack = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.STRING) {
        this.timezone = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.STRING) {
        this.resolution = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.STRING) {
        this.referrer = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UnauthenticatedUserSessionService.UnauthenticatedUserSessionService_createSession_args.prototype.write = function(output) {
  output.writeStructBegin('UnauthenticatedUserSessionService_createSession_args');
  if (this.canvasData !== null && this.canvasData !== undefined) {
    output.writeFieldBegin('canvasData', Thrift.Type.STRING, 1);
    output.writeString(this.canvasData);
    output.writeFieldEnd();
  }
  if (this.webGLVendor !== null && this.webGLVendor !== undefined) {
    output.writeFieldBegin('webGLVendor', Thrift.Type.STRING, 2);
    output.writeString(this.webGLVendor);
    output.writeFieldEnd();
  }
  if (this.webGLRenderer !== null && this.webGLRenderer !== undefined) {
    output.writeFieldBegin('webGLRenderer', Thrift.Type.STRING, 3);
    output.writeString(this.webGLRenderer);
    output.writeFieldEnd();
  }
  if (this.plugins !== null && this.plugins !== undefined) {
    output.writeFieldBegin('plugins', Thrift.Type.STRING, 4);
    output.writeString(this.plugins);
    output.writeFieldEnd();
  }
  if (this.localStorageAllowed !== null && this.localStorageAllowed !== undefined) {
    output.writeFieldBegin('localStorageAllowed', Thrift.Type.STRING, 5);
    output.writeString(this.localStorageAllowed);
    output.writeFieldEnd();
  }
  if (this.sessionStorageAllowed !== null && this.sessionStorageAllowed !== undefined) {
    output.writeFieldBegin('sessionStorageAllowed', Thrift.Type.STRING, 6);
    output.writeString(this.sessionStorageAllowed);
    output.writeFieldEnd();
  }
  if (this.cookieAllowed !== null && this.cookieAllowed !== undefined) {
    output.writeFieldBegin('cookieAllowed', Thrift.Type.STRING, 7);
    output.writeString(this.cookieAllowed);
    output.writeFieldEnd();
  }
  if (this.platform !== null && this.platform !== undefined) {
    output.writeFieldBegin('platform', Thrift.Type.STRING, 8);
    output.writeString(this.platform);
    output.writeFieldEnd();
  }
  if (this.doNotTrack !== null && this.doNotTrack !== undefined) {
    output.writeFieldBegin('doNotTrack', Thrift.Type.STRING, 9);
    output.writeString(this.doNotTrack);
    output.writeFieldEnd();
  }
  if (this.timezone !== null && this.timezone !== undefined) {
    output.writeFieldBegin('timezone', Thrift.Type.STRING, 10);
    output.writeString(this.timezone);
    output.writeFieldEnd();
  }
  if (this.resolution !== null && this.resolution !== undefined) {
    output.writeFieldBegin('resolution', Thrift.Type.STRING, 11);
    output.writeString(this.resolution);
    output.writeFieldEnd();
  }
  if (this.referrer !== null && this.referrer !== undefined) {
    output.writeFieldBegin('referrer', Thrift.Type.STRING, 12);
    output.writeString(this.referrer);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UnauthenticatedUserSessionService.UnauthenticatedUserSessionService_createSession_result = function(args) {
  this.success = null;
  this.canvasDataInvalidStringLengthException = null;
  this.webGLVendorInvalidStringLengthException = null;
  this.webGLRendererInvalidStringLengthException = null;
  this.pluginsInvalidStringLengthException = null;
  this.localStorageAllowedInvalidStringLengthException = null;
  this.sessionStorageAllowedInvalidStringLengthException = null;
  this.cookieAllowedInvalidStringLengthException = null;
  this.platformInvalidStringLengthException = null;
  this.doNotTrackInvalidStringLengthException = null;
  this.timeZoneInvalidStringLengthException = null;
  this.resolutionInvalidStringLengthException = null;
  this.referrerInvalidStringLengthException = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  if (args instanceof UnauthenticatedUserSessionExceptions.CanvasDataInvalidStringLengthException) {
    this.canvasDataInvalidStringLengthException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.WebGLVendorInvalidStringLengthException) {
    this.webGLVendorInvalidStringLengthException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.WebGLRendererInvalidStringLengthException) {
    this.webGLRendererInvalidStringLengthException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.PluginsInvalidStringLengthException) {
    this.pluginsInvalidStringLengthException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.LocalStorageAllowedInvalidStringLengthException) {
    this.localStorageAllowedInvalidStringLengthException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.SessionStorageAllowedInvalidStringLengthException) {
    this.sessionStorageAllowedInvalidStringLengthException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.CookieAllowedInvalidStringLengthException) {
    this.cookieAllowedInvalidStringLengthException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.PlatformInvalidStringLengthException) {
    this.platformInvalidStringLengthException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.DoNotTrackInvalidStringLengthException) {
    this.doNotTrackInvalidStringLengthException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.TimezoneInvalidStringLengthException) {
    this.timeZoneInvalidStringLengthException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.ResolutionInvalidStringLengthException) {
    this.resolutionInvalidStringLengthException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.ReferrerInvalidStringLengthException) {
    this.referrerInvalidStringLengthException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId(args.success);
    }
    if (args.canvasDataInvalidStringLengthException !== undefined && args.canvasDataInvalidStringLengthException !== null) {
      this.canvasDataInvalidStringLengthException = args.canvasDataInvalidStringLengthException;
    }
    if (args.webGLVendorInvalidStringLengthException !== undefined && args.webGLVendorInvalidStringLengthException !== null) {
      this.webGLVendorInvalidStringLengthException = args.webGLVendorInvalidStringLengthException;
    }
    if (args.webGLRendererInvalidStringLengthException !== undefined && args.webGLRendererInvalidStringLengthException !== null) {
      this.webGLRendererInvalidStringLengthException = args.webGLRendererInvalidStringLengthException;
    }
    if (args.pluginsInvalidStringLengthException !== undefined && args.pluginsInvalidStringLengthException !== null) {
      this.pluginsInvalidStringLengthException = args.pluginsInvalidStringLengthException;
    }
    if (args.localStorageAllowedInvalidStringLengthException !== undefined && args.localStorageAllowedInvalidStringLengthException !== null) {
      this.localStorageAllowedInvalidStringLengthException = args.localStorageAllowedInvalidStringLengthException;
    }
    if (args.sessionStorageAllowedInvalidStringLengthException !== undefined && args.sessionStorageAllowedInvalidStringLengthException !== null) {
      this.sessionStorageAllowedInvalidStringLengthException = args.sessionStorageAllowedInvalidStringLengthException;
    }
    if (args.cookieAllowedInvalidStringLengthException !== undefined && args.cookieAllowedInvalidStringLengthException !== null) {
      this.cookieAllowedInvalidStringLengthException = args.cookieAllowedInvalidStringLengthException;
    }
    if (args.platformInvalidStringLengthException !== undefined && args.platformInvalidStringLengthException !== null) {
      this.platformInvalidStringLengthException = args.platformInvalidStringLengthException;
    }
    if (args.doNotTrackInvalidStringLengthException !== undefined && args.doNotTrackInvalidStringLengthException !== null) {
      this.doNotTrackInvalidStringLengthException = args.doNotTrackInvalidStringLengthException;
    }
    if (args.timeZoneInvalidStringLengthException !== undefined && args.timeZoneInvalidStringLengthException !== null) {
      this.timeZoneInvalidStringLengthException = args.timeZoneInvalidStringLengthException;
    }
    if (args.resolutionInvalidStringLengthException !== undefined && args.resolutionInvalidStringLengthException !== null) {
      this.resolutionInvalidStringLengthException = args.resolutionInvalidStringLengthException;
    }
    if (args.referrerInvalidStringLengthException !== undefined && args.referrerInvalidStringLengthException !== null) {
      this.referrerInvalidStringLengthException = args.referrerInvalidStringLengthException;
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
  }
};
UnauthenticatedUserSessionService.UnauthenticatedUserSessionService_createSession_result.prototype = {};
UnauthenticatedUserSessionService.UnauthenticatedUserSessionService_createSession_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.canvasDataInvalidStringLengthException = new UnauthenticatedUserSessionExceptions.CanvasDataInvalidStringLengthException();
        this.canvasDataInvalidStringLengthException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.webGLVendorInvalidStringLengthException = new UnauthenticatedUserSessionExceptions.WebGLVendorInvalidStringLengthException();
        this.webGLVendorInvalidStringLengthException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.webGLRendererInvalidStringLengthException = new UnauthenticatedUserSessionExceptions.WebGLRendererInvalidStringLengthException();
        this.webGLRendererInvalidStringLengthException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.pluginsInvalidStringLengthException = new UnauthenticatedUserSessionExceptions.PluginsInvalidStringLengthException();
        this.pluginsInvalidStringLengthException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.localStorageAllowedInvalidStringLengthException = new UnauthenticatedUserSessionExceptions.LocalStorageAllowedInvalidStringLengthException();
        this.localStorageAllowedInvalidStringLengthException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.sessionStorageAllowedInvalidStringLengthException = new UnauthenticatedUserSessionExceptions.SessionStorageAllowedInvalidStringLengthException();
        this.sessionStorageAllowedInvalidStringLengthException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.cookieAllowedInvalidStringLengthException = new UnauthenticatedUserSessionExceptions.CookieAllowedInvalidStringLengthException();
        this.cookieAllowedInvalidStringLengthException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.platformInvalidStringLengthException = new UnauthenticatedUserSessionExceptions.PlatformInvalidStringLengthException();
        this.platformInvalidStringLengthException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.STRUCT) {
        this.doNotTrackInvalidStringLengthException = new UnauthenticatedUserSessionExceptions.DoNotTrackInvalidStringLengthException();
        this.doNotTrackInvalidStringLengthException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.STRUCT) {
        this.timeZoneInvalidStringLengthException = new UnauthenticatedUserSessionExceptions.TimezoneInvalidStringLengthException();
        this.timeZoneInvalidStringLengthException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.STRUCT) {
        this.resolutionInvalidStringLengthException = new UnauthenticatedUserSessionExceptions.ResolutionInvalidStringLengthException();
        this.resolutionInvalidStringLengthException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.STRUCT) {
        this.referrerInvalidStringLengthException = new UnauthenticatedUserSessionExceptions.ReferrerInvalidStringLengthException();
        this.referrerInvalidStringLengthException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 13:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 14:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UnauthenticatedUserSessionService.UnauthenticatedUserSessionService_createSession_result.prototype.write = function(output) {
  output.writeStructBegin('UnauthenticatedUserSessionService_createSession_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.canvasDataInvalidStringLengthException !== null && this.canvasDataInvalidStringLengthException !== undefined) {
    output.writeFieldBegin('canvasDataInvalidStringLengthException', Thrift.Type.STRUCT, 1);
    this.canvasDataInvalidStringLengthException.write(output);
    output.writeFieldEnd();
  }
  if (this.webGLVendorInvalidStringLengthException !== null && this.webGLVendorInvalidStringLengthException !== undefined) {
    output.writeFieldBegin('webGLVendorInvalidStringLengthException', Thrift.Type.STRUCT, 2);
    this.webGLVendorInvalidStringLengthException.write(output);
    output.writeFieldEnd();
  }
  if (this.webGLRendererInvalidStringLengthException !== null && this.webGLRendererInvalidStringLengthException !== undefined) {
    output.writeFieldBegin('webGLRendererInvalidStringLengthException', Thrift.Type.STRUCT, 3);
    this.webGLRendererInvalidStringLengthException.write(output);
    output.writeFieldEnd();
  }
  if (this.pluginsInvalidStringLengthException !== null && this.pluginsInvalidStringLengthException !== undefined) {
    output.writeFieldBegin('pluginsInvalidStringLengthException', Thrift.Type.STRUCT, 4);
    this.pluginsInvalidStringLengthException.write(output);
    output.writeFieldEnd();
  }
  if (this.localStorageAllowedInvalidStringLengthException !== null && this.localStorageAllowedInvalidStringLengthException !== undefined) {
    output.writeFieldBegin('localStorageAllowedInvalidStringLengthException', Thrift.Type.STRUCT, 5);
    this.localStorageAllowedInvalidStringLengthException.write(output);
    output.writeFieldEnd();
  }
  if (this.sessionStorageAllowedInvalidStringLengthException !== null && this.sessionStorageAllowedInvalidStringLengthException !== undefined) {
    output.writeFieldBegin('sessionStorageAllowedInvalidStringLengthException', Thrift.Type.STRUCT, 6);
    this.sessionStorageAllowedInvalidStringLengthException.write(output);
    output.writeFieldEnd();
  }
  if (this.cookieAllowedInvalidStringLengthException !== null && this.cookieAllowedInvalidStringLengthException !== undefined) {
    output.writeFieldBegin('cookieAllowedInvalidStringLengthException', Thrift.Type.STRUCT, 7);
    this.cookieAllowedInvalidStringLengthException.write(output);
    output.writeFieldEnd();
  }
  if (this.platformInvalidStringLengthException !== null && this.platformInvalidStringLengthException !== undefined) {
    output.writeFieldBegin('platformInvalidStringLengthException', Thrift.Type.STRUCT, 8);
    this.platformInvalidStringLengthException.write(output);
    output.writeFieldEnd();
  }
  if (this.doNotTrackInvalidStringLengthException !== null && this.doNotTrackInvalidStringLengthException !== undefined) {
    output.writeFieldBegin('doNotTrackInvalidStringLengthException', Thrift.Type.STRUCT, 9);
    this.doNotTrackInvalidStringLengthException.write(output);
    output.writeFieldEnd();
  }
  if (this.timeZoneInvalidStringLengthException !== null && this.timeZoneInvalidStringLengthException !== undefined) {
    output.writeFieldBegin('timeZoneInvalidStringLengthException', Thrift.Type.STRUCT, 10);
    this.timeZoneInvalidStringLengthException.write(output);
    output.writeFieldEnd();
  }
  if (this.resolutionInvalidStringLengthException !== null && this.resolutionInvalidStringLengthException !== undefined) {
    output.writeFieldBegin('resolutionInvalidStringLengthException', Thrift.Type.STRUCT, 11);
    this.resolutionInvalidStringLengthException.write(output);
    output.writeFieldEnd();
  }
  if (this.referrerInvalidStringLengthException !== null && this.referrerInvalidStringLengthException !== undefined) {
    output.writeFieldBegin('referrerInvalidStringLengthException', Thrift.Type.STRUCT, 12);
    this.referrerInvalidStringLengthException.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 13);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 14);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UnauthenticatedUserSessionService.UnauthenticatedUserSessionServiceClient = function(input, output) {
    this.input = input;
    this.output = (!output) ? input : output;
    this.seqid = 0;
};
UnauthenticatedUserSessionService.UnauthenticatedUserSessionServiceClient.prototype = {};
UnauthenticatedUserSessionService.UnauthenticatedUserSessionServiceClient.prototype.createSession = function(canvasData, webGLVendor, webGLRenderer, plugins, localStorageAllowed, sessionStorageAllowed, cookieAllowed, platform, doNotTrack, timezone, resolution, referrer, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_createSession(pendingError, canvasData, webGLVendor, webGLRenderer, plugins, localStorageAllowed, sessionStorageAllowed, cookieAllowed, platform, doNotTrack, timezone, resolution, referrer, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_createSession();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

UnauthenticatedUserSessionService.UnauthenticatedUserSessionServiceClient.prototype.send_createSession = function(pendingError, canvasData, webGLVendor, webGLRenderer, plugins, localStorageAllowed, sessionStorageAllowed, cookieAllowed, platform, doNotTrack, timezone, resolution, referrer, callback) {
  this.output.writeMessageBegin('createSession', Thrift.MessageType.CALL, this.seqid);
  var args = new UnauthenticatedUserSessionService.UnauthenticatedUserSessionService_createSession_args();
  args.canvasData = canvasData;
  args.webGLVendor = webGLVendor;
  args.webGLRenderer = webGLRenderer;
  args.plugins = plugins;
  args.localStorageAllowed = localStorageAllowed;
  args.sessionStorageAllowed = sessionStorageAllowed;
  args.cookieAllowed = cookieAllowed;
  args.platform = platform;
  args.doNotTrack = doNotTrack;
  args.timezone = timezone;
  args.resolution = resolution;
  args.referrer = referrer;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_createSession() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

UnauthenticatedUserSessionService.UnauthenticatedUserSessionServiceClient.prototype.recv_createSession = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new UnauthenticatedUserSessionService.UnauthenticatedUserSessionService_createSession_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.canvasDataInvalidStringLengthException) {
    throw result.canvasDataInvalidStringLengthException;
  }
  if (null !== result.webGLVendorInvalidStringLengthException) {
    throw result.webGLVendorInvalidStringLengthException;
  }
  if (null !== result.webGLRendererInvalidStringLengthException) {
    throw result.webGLRendererInvalidStringLengthException;
  }
  if (null !== result.pluginsInvalidStringLengthException) {
    throw result.pluginsInvalidStringLengthException;
  }
  if (null !== result.localStorageAllowedInvalidStringLengthException) {
    throw result.localStorageAllowedInvalidStringLengthException;
  }
  if (null !== result.sessionStorageAllowedInvalidStringLengthException) {
    throw result.sessionStorageAllowedInvalidStringLengthException;
  }
  if (null !== result.cookieAllowedInvalidStringLengthException) {
    throw result.cookieAllowedInvalidStringLengthException;
  }
  if (null !== result.platformInvalidStringLengthException) {
    throw result.platformInvalidStringLengthException;
  }
  if (null !== result.doNotTrackInvalidStringLengthException) {
    throw result.doNotTrackInvalidStringLengthException;
  }
  if (null !== result.timeZoneInvalidStringLengthException) {
    throw result.timeZoneInvalidStringLengthException;
  }
  if (null !== result.resolutionInvalidStringLengthException) {
    throw result.resolutionInvalidStringLengthException;
  }
  if (null !== result.referrerInvalidStringLengthException) {
    throw result.referrerInvalidStringLengthException;
  }
  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'createSession failed: unknown result';
};
