import { TransferRequisitionStepType } from 'api/Transfer/model/TransferRequisitionStep';
import moment from 'moment-timezone';

import InventoryTransferReportModel from 'gen-thrift/inventory_transfer_report_Model_types';
import InventoryTransferReportService from 'gen-thrift/InventoryTransferReportService';

import { MomentObjectToThriftSerializer } from 'api/Core/serializer/MomentObjectToThriftSerializer';
import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { LocationId } from 'api/Location/model/LocationId';
import { LocationObjectToThriftSerializer } from 'api/Location/serializer/LocationObjectToThriftSerializer';
import { ProductAmount } from 'api/Product/model/ProductAmount';
import { ITransferService } from 'api/Transfer/interfaces/ITransferService';
import { TransferId } from 'api/Transfer/model/TransferId';
import { TransferReportData } from 'api/Transfer/model/TransferReportData';
import { TransferReport } from 'api/Transfer/model/TransferReport';
import { TransferReportWithoutCost } from 'api/Transfer/model/TransferReportWithoutCost';
import { TransferObjectToThriftSerializer } from 'api/Transfer/serializer/TransferObjectToThriftSerializer';
import { TransferThriftToObjectSerializer } from 'api/Transfer/serializer/TransferThriftToObjectSerializer';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { UserAccountObjectToThriftSerializer } from 'api/UserAccount/serializer/UserAccountObjectToThriftSerializer';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export class TransferServiceImpl implements ITransferService {

    constructor (
        private inventoryTransferReportServiceClient : InventoryTransferReportService.InventoryTransferReportServiceClient,
        private userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer,
        private locationObjectToThriftSerializer : LocationObjectToThriftSerializer,
        private transferObjectToThriftSerializer : TransferObjectToThriftSerializer,
        private transferThriftToObjectSerializer : TransferThriftToObjectSerializer,
        private momentObjectToThriftSerializer : MomentObjectToThriftSerializer,
    ) { }

    public getTransferReportsById(
        userSessionId : UserSessionId,
        transferIds : StringValueSet<TransferId>,
        perspective : LocationId,
    ) : Promise<StringValueMap<TransferId, TransferReport>> {
        const transferIdsList = Array.from(transferIds.values());

        return new Promise<StringValueMap<TransferId, TransferReport>>((resolve, reject) => {
            this.inventoryTransferReportServiceClient.getTransferReportsByIds(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.transferObjectToThriftSerializer.getThriftTransferIds(transferIdsList),
                this.locationObjectToThriftSerializer.getThriftLocationId(perspective),
                (result : Array<InventoryTransferReportModel.InventoryTransferReportAndMetadata> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    const transferReports = this.transferThriftToObjectSerializer.getTransferReports(result);

                    if (transferIdsList.length !== transferReports.length) {
                        throw new RuntimeException('result length did not match number of transferIds');
                    }

                    const reportsById = new StringValueMap<TransferId, TransferReport>();
                    transferIdsList.forEach((transferId, index) => {
                        reportsById.set(transferId, transferReports[index]);
                    });

                    return resolve(reportsById);
                }
            );
        });
    }

    public getTransferReportsWithoutCostsById(
        userSessionId : UserSessionId,
        transferIds : StringValueSet<TransferId>,
        perspective : LocationId,
    ) : Promise<StringValueMap<TransferId, TransferReportWithoutCost>> {
        const transferIdsList = Array.from(transferIds.values());

        return new Promise<StringValueMap<TransferId, TransferReportWithoutCost>>((resolve, reject) => {
            this.inventoryTransferReportServiceClient.getTransferReportsWithoutCostsById(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.transferObjectToThriftSerializer.getThriftTransferIds(transferIdsList),
                this.locationObjectToThriftSerializer.getThriftLocationId(perspective),
                (result : Array<InventoryTransferReportModel.InventoryTransferReportAndMetadataWithoutCost> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    const transferReports = this.transferThriftToObjectSerializer.getTransferReportWithoutCosts(result);

                    if (transferIdsList.length !== transferReports.length) {
                        throw new RuntimeException('result length did not match number of transferIds');
                    }

                    const reportsById = new StringValueMap<TransferId, TransferReportWithoutCost>();
                    transferIdsList.forEach((transferId, index) => {
                        reportsById.set(transferId, transferReports[index]);
                    });

                    return resolve(reportsById);
                }
            );
        });
    }

    public getTransferReportsForLocationInPeriod(
        userSessionId : UserSessionId,
        locationId : LocationId,
        periodStartInclusive : moment.Moment,
        periodEndExclusive : moment.Moment,
    ) : Promise<StringValueSet<TransferId>> {
        return new Promise<StringValueSet<TransferId>>((resolve, reject) => {
            this.inventoryTransferReportServiceClient.getReportsForLocationInPeriod(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                this.momentObjectToThriftSerializer.getThriftTimestampFromMoment(periodStartInclusive),
                this.momentObjectToThriftSerializer.getThriftTimestampFromMoment(periodEndExclusive),
                (result : Array<InventoryTransferReportModel.InventoryTransferReportIdentifier> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    return resolve(this.transferThriftToObjectSerializer.getTransferIds(result));
                }
            );
        });
    }

    public createTransferReport(
        userSessionId : UserSessionId,
        transferReportData : TransferReportData
    ) : Promise<TransferId> {
        return new Promise<TransferId>((resolve, reject) => {
            this.inventoryTransferReportServiceClient.createTransferReport(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.transferObjectToThriftSerializer.getThriftTransferReportInfo(transferReportData),
                (result : InventoryTransferReportModel.InventoryTransferReportIdentifier | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    return resolve(this.transferThriftToObjectSerializer.getTransferId(result));
                }
            );
        });
    }

    public updateTransferReport(
        userSessionId : UserSessionId,
        transferId : TransferId,
        productAmounts : Array<ProductAmount>,
        newReportTime : moment.Moment,
        newNote : string | null
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const productAmountUpdates = this.transferObjectToThriftSerializer.getThriftProductIdsAndQuantityOfProducts(productAmounts);
            const reportUpdate = new InventoryTransferReportModel.BreakageReportUpdate({
                productAmountUpdates,
                newNote: newNote || '',
                newReportTime : this.momentObjectToThriftSerializer.getThriftTimestampFromMoment(newReportTime),
            });

            this.inventoryTransferReportServiceClient.updateTransferReport(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.transferObjectToThriftSerializer.getThriftTransferId(transferId),
                reportUpdate,
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    return resolve();
                }
            );
        });
        
    }

    public updateTransferReportRequisitionStep(
        userSessionId : UserSessionId,
        transferId : TransferId,
        newRequisitionStep : TransferRequisitionStepType,
        cancellationReason : string | null
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.inventoryTransferReportServiceClient.updateTransferReportRequisitionStep(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.transferObjectToThriftSerializer.getThriftTransferId(transferId),
                newRequisitionStep,
                cancellationReason,
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    return resolve();
                }
            );
        });

    }

    public deleteTransferReport(
        userSessionId : UserSessionId,
        transferId : TransferId
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.inventoryTransferReportServiceClient.deleteReport(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.transferObjectToThriftSerializer.getThriftTransferId(transferId),
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    return resolve();
                }
            );
        });
    }

    public getCustomPartnerLocationNames(
        userSessionId : UserSessionId,
        locationId : LocationId,
    ) : Promise<Array<string>> {
        return new Promise<Array<string>>((resolve, reject) => {
            this.inventoryTransferReportServiceClient.getCustomTransferPartnerLocationNamesForLocation(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                (result : Array<string> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    return resolve(result);
                }
            );
        });
    }

    public updateCustomPartnerLocationNameForTransferReport(
        userSessionId : UserSessionId,
        transferId : TransferId,
        value : string,
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.inventoryTransferReportServiceClient.updateCustomPartnerLocationName(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.transferObjectToThriftSerializer.getThriftTransferId(transferId),
                value,
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    return resolve();
                }
            );
        });
    }
}
