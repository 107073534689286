import * as React from 'react';

import { IStringValue } from 'api/Core/IStringValue';
import { StringValueMap } from 'api/Core/StringValueMap';
import { Distributor } from 'api/Distributor/model/Distributor';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { Delivery } from 'api/Ordering/model/Delivery';
import { DeliveryId } from 'api/Ordering/model/DeliveryId';

import { DeliveryOptionRow } from './DeliveryOptionRow';

import { Button } from 'shared/components/Button';
import { ConnectedRecordNewOrderView } from 'shared/components/RecordNewOrder/RecordNewOrderView';
import { SearchBarTheme } from 'shared/components/SearchBar/SearchBar';
import { SearchBarWithSuggestedOptions } from 'shared/components/SearchBar/SearchBarWithSuggestedOptions';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { ISearchBarState } from 'shared/models/ISearchBarState';

import './DeliverySearchBar.scss';

export interface IDeliverySearchBarProps<T> {
    readonly id : T;
    readonly deliveriesById : StringValueMap<DeliveryId, Delivery>;
    readonly distributorsById : StringValueMap<DistributorId, Distributor>;
    readonly searchBarPlaceholderText : string;
    readonly labelNamesAndSortedDeliveryIdsToDisplay : Array<[string, Array<DeliveryId>]>;
    readonly searchBar : ISearchBarState;
    readonly setSearchTerm : (searchTerm : string | null, id : T) => void;
    readonly highlightedDeliveryId : DeliveryId | null;
    readonly setHighlightedDeliveryId : (searchSuggestion : DeliveryId | null, shouldScroll : boolean, id : T) => void;
    readonly dropdownIsShown : boolean;
    readonly setSearchDropdownIsShown : (isShown : boolean, id : T) => void;
    readonly onSelectDelivery : (deliveryId : DeliveryId, id : T) => void;
    readonly recordDeliveryDialogIsShown : boolean;
    readonly setRecordDeliveryDialogIsShown : (isShown : boolean, recordNewOrderViewId : string, id : T) => void;
    readonly onRecordDeliveryClick : ((recordNewOrderViewId : string, id : T) => void) | null;
    readonly recordDeliveryIsSaving : boolean;
}

export class DeliverySearchBar<T> extends React.Component<IDeliverySearchBarProps<T>, object> {

    private readonly recordNewOrderViewId : string;

    constructor(props : IDeliverySearchBarProps<T>) {
        super(props);

        const isIdIStringValue = (id : any) : id is IStringValue => {
            return typeof id.toString === 'function';
        };

        let idValue = '';
        if (props.id && isIdIStringValue(props.id)) {
            idValue = props.id.toString();
        }

        this.recordNewOrderViewId = `deliverySeatchBarRecordDeliveryView-${ idValue }`;
    }

    public render() {
        const {
            searchBar,
            labelNamesAndSortedDeliveryIdsToDisplay,
            highlightedDeliveryId,
            dropdownIsShown,
            searchBarPlaceholderText,
            onRecordDeliveryClick,
            recordDeliveryDialogIsShown,
            recordDeliveryIsSaving,
        } = this.props;

        return (
            <div
                className="delivery-search-bar"
            >
                <SearchBarWithSuggestedOptions
                    searchBar={ searchBar }
                    searchBarTheme={ SearchBarTheme.Boxed }
                    searchBarPlaceholderText={ searchBarPlaceholderText }
                    searchSuggestionListIsShown={ dropdownIsShown }
                    labelNamesAndSearchSuggestions={ labelNamesAndSortedDeliveryIdsToDisplay }
                    highlightedSearchSuggestion={ highlightedDeliveryId }
                    moreOptionsHeader={ onRecordDeliveryClick ? (
                        <div className="delivery-searchbar-footer">
                            <ul className="more-options-list">
                                <li className="more-options-list-item" onClick={ this.onShowRecordDeliveryDialog }>
                                    <div className="btn flat primary with-icon no-pad">
                                        <div className="button-text-container">
                                            <span className="bevicon bevico-add main-icon-left"/>
                                            <span data-tooltip='Record New Order for deliveries not placed through BevSpot, or find the previously communicated order for this invoice.'>Record New Order</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    ) : null }
                    moreOptionsFooter={ null }
                    searchBarIsMobileComponent={ false } // even on mobile we show normal search bar here
                    setSearchSuggestionListIsShown={ this.setDropdownIsShown }
                    createSuggestionOptionElement={ this.createDeliveryOptionRow }
                    onSearchBarEnterClick={ this.onSearchBarEnterClick }
                    onSearchTermValueChange={ this.onSearchBarTermChange }
                    onClearSearchTerm={ this.onClearSearchBar }
                    onOptionClick={ this.onOptionClick }
                    setHighlightedSearchSuggestionKeyboard={ this.setHighlightedOptionKeyboard }
                    setHighlightedSearchSuggestionMouse={ this.setHighlightedOptionMouse }
                    closeMobileSearchBar={ this.doNothing }
                    dropdownRowClassName=""
                    dropdownContainerClassName="dropdown-inner-container"
                />
                { recordDeliveryDialogIsShown &&
                    <div className="record-delivery-dialog">
                        <ConnectedRecordNewOrderView
                            id={ this.recordNewOrderViewId }
                            hideOrderHasBeenDeliveredField={ true }
                            hideInvoiceUploadField={ true }
                        />
                        <Button
                            buttonClassName="primary flat right"
                            isDisabled={ recordDeliveryIsSaving }
                            isLoading={ recordDeliveryIsSaving }
                            onClick={ this.onRecordDeliveryClick }
                        >
                            Record
                        </Button>
                        <Button
                            buttonClassName="right flat"
                            isDisabled={ recordDeliveryIsSaving }
                            isLoading={ false }
                            onClick={ this.onCancelRecordDeliveryClick }
                        >
                            Cancel
                        </Button>
                    </div>
                }
            </div>
        );
    }

    private readonly doNothing = () => {
        // Do Nothing
    }

    private readonly setHighlightedOptionKeyboard = (deliveryId : DeliveryId | null) => {
        this.props.setHighlightedDeliveryId(deliveryId, true, this.props.id);
    }

    private readonly setHighlightedOptionMouse = (deliveryId : DeliveryId | null) => {
        this.props.setHighlightedDeliveryId(deliveryId, false, this.props.id);
    }

    private readonly setDropdownIsShown = (isShown : boolean) => {
        this.props.setSearchDropdownIsShown(isShown, this.props.id);
    }

    private readonly onSearchBarTermChange = (searchTerm : string) => {
        this.props.setSearchDropdownIsShown(true, this.props.id);
        this.props.setSearchTerm(searchTerm, this.props.id);
    }

    private readonly onClearSearchBar = () => {
        this.props.setSearchTerm(null, this.props.id);
    }

    private readonly onSearchBarEnterClick = (searchTerm : string) => {
        const highlightedDeliveryId = this.props.highlightedDeliveryId;
        if (highlightedDeliveryId !== null) {
            this.props.onSelectDelivery(highlightedDeliveryId, this.props.id);
        }
    }

    private readonly onOptionClick = (deliveryId : DeliveryId) => {
        this.props.onSelectDelivery(deliveryId, this.props.id);
    }

    private readonly onShowRecordDeliveryDialog = () : void => {
        this.props.setSearchDropdownIsShown(false, this.props.id);
        this.props.setRecordDeliveryDialogIsShown(true, this.recordNewOrderViewId, this.props.id);
    }

    private readonly onCancelRecordDeliveryClick = () : void => {
        this.props.setRecordDeliveryDialogIsShown(false, this.recordNewOrderViewId, this.props.id);
    }

    private readonly onRecordDeliveryClick = () : void => {
        if (this.props.onRecordDeliveryClick === null) {
            throw new RuntimeException('unexpected');
        }

        this.props.onRecordDeliveryClick(this.recordNewOrderViewId, this.props.id);
    }

    private readonly createDeliveryOptionRow = (deliveryId : DeliveryId) => {
        const {
            highlightedDeliveryId,
            deliveriesById,
            distributorsById,
        } = this.props;

        const delivery = deliveriesById.get(deliveryId);

        if (typeof delivery === 'undefined') {
            throw new RuntimeException('unexpected');
        }

        const isHighlighted = (highlightedDeliveryId !== null) && highlightedDeliveryId.equals(deliveryId);

        return (
            <DeliveryOptionRow
                key={ deliveryId.getValue() }
                delivery={ delivery }
                distributorsById={ distributorsById }
                isHighlighted={ isHighlighted }
            />
        );
    }
}
