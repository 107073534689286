export enum IntegrationStatus {
    UNCONNECTED,
    CONNECTED,
    NEEDS_ATTENTION,    // cannot access POS location with the saved connection information, either because the external pos location id is incorrect, or that user has not given (or has revoked) BevSpot's permission to access their POS
}

// should be kept in sync with integration_service/models/PosIntegrationType.py
// and Integration/model/IntegrationType.ts
export type PosIntegrationType = 'breadcrumb' | 'toast' | 'lightspeed' | 'square' | 'omnivore' | 'clover' | 'kSeries' | 'spotOn' | 'heartland';

export const stediTradingPartners = ['usfoods', 'sysco'];
export type StediTradingPartner = typeof stediTradingPartners[number];
export type VendorIntegrationType = 'fintech' | StediTradingPartner;

export type IntegrationType = PosIntegrationType | VendorIntegrationType;
