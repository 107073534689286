import moment from 'moment-timezone';
import React from 'react';

import { DateTime } from '../../models/DateTime';

export interface IBevspotDateTimePickerHeader {
    selectedDateTime : DateTime;
}
export class BevspotDateTimePickerHeader extends React.Component<IBevspotDateTimePickerHeader, object> {
    public render() {
        const {
            selectedDateTime,
        } = this.props;

        const dateTimeAsMoment : moment.Moment = selectedDateTime.toMoment();
        const dayOfWeek : string = dateTimeAsMoment.format('dddd'); // ex: 'Monday'
        const timeOfDay : string = dateTimeAsMoment.format('h:mm A'); // ex: '2:10 AM'
        const calendarDate : string = dateTimeAsMoment.format('MMMM D, YYYY'); // ex: 'December 5, 2016'
        const timezone : string = dateTimeAsMoment.format(' (z)');
        return (
            <div className="bevspot-datetime-picker-header dark">
                <div className="datetime-row">
                    <div className="calendar-date">{ calendarDate }</div>
                    <div className="time">
                        { timeOfDay }
                        <span className="timezone">
                            { timezone }
                        </span>
                    </div>
                </div>
                <div className="day">{ dayOfWeek }</div>
            </div>
        );
    }
}
