import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { ILocationService } from 'api/Location/interfaces/ILocationService';
import { AccountType } from 'api/Location/model/AccountType';
import { ILocationMetaData } from 'api/Location/model/ILocationMetaData';
import { LocationId } from 'api/Location/model/LocationId';
import { RetailerGettingStartedType } from 'api/Location/model/RetailerGettingStartedType';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { AjaxUtils } from 'shared/utils/ajaxUtils';
import { PathUtils } from 'shared/utils/pathUtils';

export class LocationServiceImpl implements ILocationService {

    public getLocationNamesById(
        locationIds : StringValueSet<LocationId>
    ) : Promise<StringValueMap<LocationId, string>> {
        const postBody = {
            retailer_ids: Array.from(locationIds.values()).map((locationId) => locationId.getValue()),
        };
        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:retailer'), postBody)
            .then((response : { [key : string] : { name : string } }) => {
                const locationNamesByLocationId = new StringValueMap<LocationId, string>();
                for (const locationIdStr of Object.keys(response)) {
                    locationNamesByLocationId.set(new LocationId(locationIdStr), response[locationIdStr].name);
                }
                return Promise.resolve(locationNamesByLocationId);
            });
    }

    public createLocations(
        userFirstName : string,
        userLastName : string,
        userId : string,
        userEmail : string,
        userPhone : string,
        beverageRetailerName : string | null,
        foodRetailerName : string | null,
        addressLine1 : string,
        addressLine2 : string,
        city : string,
        state : string,
        country : string,
        zipcode : string,
        timezone : string, // TODO type timezones if possible
        isPayingByCard : boolean,
        metaData : ILocationMetaData | null,
        gettingStartedType : RetailerGettingStartedType | null,
    ) {
        if ((beverageRetailerName === null) && (foodRetailerName === null)) {
            throw new RuntimeException('beverageRetailerName and foodRetailerName cannot both be null');
        }

        const formData : FormData = new FormData();
        formData.append('user_first_name', userFirstName);
        formData.append('user_last_name', userLastName);
        formData.append('user_id', userId);
        formData.append('user_email', userEmail);
        formData.append('user_phone', userPhone);
        formData.append('retailer_address_line1', addressLine1);
        formData.append('retailer_address_line2', addressLine2);
        formData.append('retailer_city', city);
        formData.append('retailer_state', state);
        formData.append('retailer_country', country);
        formData.append('retailer_zipcode', zipcode);
        formData.append('retailer_timezone', timezone);
        formData.append('is_paying_by_card', '' + isPayingByCard); // NOTE: FormData turns booleans into strings, so `true` becomes `'true'` and false` becomes `'false'`

        if (metaData && metaData.freeTrialSource) {
            formData.append('free_trial_source', metaData.freeTrialSource);
        }

        if (beverageRetailerName) {
            formData.append('beverage_retailer_name', beverageRetailerName);
        }

        if (foodRetailerName) {
            formData.append('food_retailer_name', foodRetailerName);
        }

        if (gettingStartedType) {
            formData.append('retailer_getting_started_type', gettingStartedType);
        }

        return AjaxUtils.ajaxPostForm(PathUtils.getAbsolutePathForRequest('/user/create_retailer/'), formData)
        .then((response : { beverage_retailer_id : string | null, food_retailer_id : string | null }) => {
            const {
                beverage_retailer_id,
                food_retailer_id,
            } = response;

            return {
                beverageLocationId: beverage_retailer_id === null ? null : new LocationId(beverage_retailer_id),
                foodLocationId: food_retailer_id === null ? null : new LocationId(food_retailer_id),
            };
        });
    }

    public createSandboxLocationForUser(userId : UserAccountId, accountType : AccountType) : Promise<LocationId> {
        const queryParameters = {
            user_id: userId.getValue(),
            retailer_account_type: accountType.valueOf()
        };

        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:sandbox_retailer'), {}, queryParameters)
        .then((response : { sandbox_retailer_id : string }) => {
            return Promise.resolve(new LocationId(response.sandbox_retailer_id));
        });
    }

    public getNumberOfUsersForLocation(locationId : LocationId) : Promise<number> {
        return AjaxUtils.ajaxGet(url('account:get_number_of_users', null, locationId.getValue(), null))
        .then((response) => {
            return response.number_of_users;
        });
    }
}
