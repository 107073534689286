import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { LocationId } from 'api/Location/model/LocationId';
import { ICategoryService } from 'api/Product/interfaces/ICategoryService';
import { Category } from 'api/Product/model/Category';
import { CategoryId } from 'api/Product/model/CategoryId';
import { ProductJSONToObjectSerializer } from 'api/Product/serializer/ProductJSONToObjectSerializer';
import { ProductObjectToJSONSerializer } from 'api/Product/serializer/ProductObjectToJSONSerializer';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { AjaxUtils } from 'shared/utils/ajaxUtils';

export class CategoryServiceImpl implements ICategoryService {
    constructor (
        private readonly productJSONToObjectSerializer : ProductJSONToObjectSerializer,
        private readonly productObjectToJSONSerializer : ProductObjectToJSONSerializer,
    ) { }

    public createCategory(
        userSessionId: UserSessionId,
        locationId : LocationId,
        category: Category
    ): Promise<{category_id: CategoryId, category_hash: string}> {
        const productCategoryJson = this.productObjectToJSONSerializer.getCategoryJson(category);
        const queryParameters = {
            retailer_id: locationId.getValue(),
        };
        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:category'), productCategoryJson, queryParameters)
        .then((response) => {
            return Promise.resolve({
                category_id : new CategoryId(response.category_id),
                category_hash : response.category_hash
            });
        })
        .catch((error : Error) => {
            return Promise.reject(error);
        });
    }

    public editCategoryById(
        userSessionId: UserSessionId,
        locationId : LocationId,
        category: Category,
        categoryId: CategoryId,
    ): Promise<{category_id: CategoryId, category_hash: string}> {
        const categoryJson = this.productObjectToJSONSerializer.getCategoryJson(category);
        const queryParameters = {
            retailer_id: locationId.getValue(),
            category_id: categoryId.getValue()
        };
        return AjaxUtils.ajaxPut(urlWithoutRetailerId('api:category'), categoryJson, queryParameters)
        .then((response) => {
            return Promise.resolve({
                category_id : new CategoryId(response.category_id),
                category_hash : response.category_hash
            });
        })
        .catch((error : Error) => {
            return Promise.reject(error);
        });
    }

    public getCategoriesById(
        userSessionId : UserSessionId,
        locationId : LocationId,
        categoryIds : StringValueSet<CategoryId>
    ): Promise<StringValueMap<CategoryId, Category>> {
        if (categoryIds.size === 0) {
            return Promise.resolve(new StringValueMap());
        }

        const categoryIdValues : Array<string> = [];
        categoryIds.forEach((categoryId : CategoryId) => {
            categoryIdValues.push(categoryId.getValue());
        });
        const queryParameters = {
            retailer_id: locationId.getValue(),
        };

        return AjaxUtils.ajaxPost(urlWithoutRetailerId('api:batch_category'), categoryIdValues, queryParameters)
        .then((response) => {
            return Promise.resolve(this.productJSONToObjectSerializer.getCategoriesById(response));
        });
    }


    public getCategoriesForRetailer(userSessionId: UserSessionId, locationId: LocationId): Promise<StringValueMap<CategoryId, Category>> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:category'), queryParameters)
        .then((response) => {
            return Promise.resolve(this.productJSONToObjectSerializer.getCategoriesById(response));
        });
    }

    public deleteCategoryById(
        userSessionId : UserSessionId,
        locationId : LocationId,
        categoryId : CategoryId,
        categoryHash : string
    ): Promise<void> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
        };
        const body  = {
            category_hash : categoryHash,
            category_id : categoryId.getValue(),
        };
        return AjaxUtils.ajaxDelete(urlWithoutRetailerId('api:category'), queryParameters, body)
        .then((response) => {
            return Promise.resolve();
        })
        .catch((error : Error) => {
            return Promise.reject(error);
        });
    }
}
