//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';



var UserActionLogExceptions = {};
export default UserActionLogExceptions;
UserActionLogExceptions.UnknownUserActionLogCommentIdException = function(args) {
};
Thrift.inherits(UserActionLogExceptions.UnknownUserActionLogCommentIdException, Thrift.TException);
UserActionLogExceptions.UnknownUserActionLogCommentIdException.prototype.name = 'UnknownUserActionLogCommentIdException';
UserActionLogExceptions.UnknownUserActionLogCommentIdException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserActionLogExceptions.UnknownUserActionLogCommentIdException.prototype.write = function(output) {
  output.writeStructBegin('UnknownUserActionLogCommentIdException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

