import React from 'react';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { Product } from 'api/Product/model/Product';
import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductId } from 'api/Product/model/ProductId';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemWithMetadata } from 'api/SalesItem/model/SalesItemWithMetadata';
import { SalesItemUtils } from 'api/SalesItem/utils/SalesItemUtils';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { productJSONToObjectSerializer } from 'shared/lib/manager';

import { SalesItemDisplayUtils } from 'api/SalesItem/utils/SalesItemDisplayUtils';
import { getCachedSalesItemCostsForIds } from 'apps/SalesItemManager/utils/CachedSalesItemCostUtils';

import { IProductIngredientRowInfo, ISalesItemIngredientRowInfo, ProductIngredientRowFormFieldName, SalesItemIngredientRowFormFieldName } from '../reducers/reducers';
import { ProductIngredientRow } from './ProductIngredientRow';
import { SalesItemIngredientRow } from './SalesItemIngredientRow';

import '../css/AddedIngredients.scss';

export interface IAddedIngredientsProps {
    orderedIngredientItems : Array<ProductId | SalesItemId>;
    productIngredientItemsById : StringValueMap<ProductId, IProductIngredientRowInfo>;
    salesItemIngredientItemsById : StringValueMap<SalesItemId, ISalesItemIngredientRowInfo>;
    productsById : StringValueMap<ProductId, Product>;
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>;
    handleOnRemoveIngredientClick : (ingredientId : ProductId | SalesItemId) => void;
    onProductRowFormFieldChange : (productId : ProductId, formField : ProductIngredientRowFormFieldName, value : string) => void;
    onProductFormFieldBlur : (productId : ProductId, formField : ProductIngredientRowFormFieldName, value : string) => void;
    onSalesItemRowFormFieldChange : (salesItemId : SalesItemId, formField : SalesItemIngredientRowFormFieldName, value : string) => void;
    onSalesItemRowFormFieldBlur : (salesItemId : SalesItemId, formField : SalesItemIngredientRowFormFieldName, value : string) => void;
    costByProductId : StringValueMap<ProductId, ProductCost>;
    subrecipeCostsById : StringValueMap<SalesItemId, number | null>;
    popoverIngredientIdIsShown : (ingredientId : ProductId | SalesItemId, isShown : boolean) => void;
    onProductInfoFullDetailsClick : ((ingredientId : ProductId) => void) | null;
    popoverIngredientIds : StringValueSet<ProductId | SalesItemId>;
    productCostsByProductId : StringValueMap<ProductId, ProductCost>;
}
export class AddedIngredients extends React.Component<IAddedIngredientsProps, object> {
    public render() {
        const {
            orderedIngredientItems,
            salesItemsById,
            productsById,
            productCostsByProductId,
        } = this.props;

        const costsBySalesItemId = getCachedSalesItemCostsForIds(
            new StringValueSet(Array.from(salesItemsById.keys())),
            salesItemsById,
            productsById,
            productCostsByProductId
        );

        const ingredientRows = orderedIngredientItems.map((ingredientId) => {
            return this.createIngredientRows(costsBySalesItemId, ingredientId);
        });

        return(
            <ul className="added-ingredients">
                { ingredientRows }
            </ul>
        );
    }

    private readonly createIngredientRows = (costsBySalesItemId : StringValueMap<SalesItemId, number | null>, ingredientId : ProductId | SalesItemId) => {
        const {
            productsById,
            productIngredientItemsById,
            salesItemsById,
            salesItemIngredientItemsById,
            handleOnRemoveIngredientClick,
            onProductRowFormFieldChange,
            onProductFormFieldBlur,
            onSalesItemRowFormFieldChange,
            onSalesItemRowFormFieldBlur,
            costByProductId,
            subrecipeCostsById,
            popoverIngredientIdIsShown,
            onProductInfoFullDetailsClick,
            popoverIngredientIds,
            productCostsByProductId,
        } = this.props;

        const handlePopoverIngredientIdIsShown = (isShown : boolean) => {
            popoverIngredientIdIsShown(ingredientId, isShown);
        };

        let ingredientCostPerQuantity : number | null;
        if (ingredientId instanceof ProductId) {
            const ingredientRow = productIngredientItemsById.get(ingredientId);

            if (typeof ingredientRow === 'undefined') {
                throw new RuntimeException('unexpected ingredient row not found');
            }

            const product = productsById.get(ingredientRow.ingredientId);
            const productCost = costByProductId.get(ingredientRow.ingredientId);
            if (typeof product === 'undefined' || typeof productCost === 'undefined') {
                throw new RuntimeException('unexpected ingredient row not found');
            }

            const ingredientFormInfo = ingredientRow.formInfo;
            const quantityOnForm = new QuantityInUnit(
                parseFloat(ingredientFormInfo.quantity.value),
                productJSONToObjectSerializer.getProductQuantityUnit(ingredientFormInfo.unit.value),
            );
            if (ingredientFormInfo.quantity.isValid) {
                ingredientCostPerQuantity = SalesItemUtils.getCostOfProductComponentInSalesItem(ingredientId, quantityOnForm, productsById, costByProductId);
            } else {
                ingredientCostPerQuantity = null;
            }
            return (
                <ProductIngredientRow
                    key={ ingredientId.getValue() }
                    handleOnRemoveIngredientClick={ handleOnRemoveIngredientClick }
                    product={ product }
                    productCost={ productCost }
                    ingredientRowInfo={ ingredientRow }
                    onFormFieldChange={ onProductRowFormFieldChange }
                    onFormFieldBlur={ onProductFormFieldBlur }
                    ingredientCostPerQuantity={ ingredientCostPerQuantity }
                    productInfoIsShown={ popoverIngredientIds.has(ingredientId) }
                    handleSetProductInfoPopoverIsShown={ handlePopoverIngredientIdIsShown }
                    onProductInfoFullDetailsClick={ onProductInfoFullDetailsClick }
                    productId={ ingredientId }
                    productCostsByProductId={ productCostsByProductId }
                />
            );
        } else {
            const salesItemWithMetadata = salesItemsById.get(ingredientId);
            const ingredientRow = salesItemIngredientItemsById.get(ingredientId);

            if (typeof ingredientRow === 'undefined' || typeof salesItemWithMetadata === 'undefined') {
                throw new RuntimeException('unexpected ingredient row not found');
            }
            const salesItem = salesItemWithMetadata.getSalesItem();

            const salesItemIngredients = SalesItemDisplayUtils.getIngredientsDisplayInformationForSalesItem(salesItem, salesItemsById, productsById, productCostsByProductId, costsBySalesItemId);
            const totalCost = costsBySalesItemId.get(ingredientId);

            if (typeof totalCost === 'undefined') {
                throw new RuntimeException('unexpected');
            }

            return (
                <SalesItemIngredientRow
                    key={ ingredientId.getValue() }
                    handleOnRemoveIngredientClick={ handleOnRemoveIngredientClick }
                    salesItemId={ ingredientId }
                    salesItem={ salesItem }
                    onFormFieldChange={ onSalesItemRowFormFieldChange }
                    onFormFieldBlur={ onSalesItemRowFormFieldBlur }
                    ingredientRowInfo={ ingredientRow }
                    subrecipeCostsById={ subrecipeCostsById }
                    salesItemInfoIsShown={ popoverIngredientIds.has(ingredientId) }
                    handleSetSalesItemInfoIsShown={ handlePopoverIngredientIdIsShown }
                    salesItemIngredients={ salesItemIngredients }
                    salesItemTotalCost={ totalCost }
                />
            );
        }
    }
}
