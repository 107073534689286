import { StringValueSet } from 'api/Core/StringValueSet';
import { InventoryCountId } from 'api/InventoryCount/model/InventoryCountId';
import { LocationId } from 'api/Location/model/LocationId';
import { IProductService } from 'api/Product/interfaces/IProductService';
import { ProductId } from 'api/Product/model/ProductId';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { UsageData } from '../model/UsageData';
import { UsageDataSerializer } from '../serializer/UsageDataSerializer';

import { IUsageDataService } from '../interfaces/IUsageDataService';

import { AjaxUtils } from 'shared/utils/ajaxUtils';

export class UsageDataServiceImpl implements IUsageDataService {

    private readonly usageDataSerializer : UsageDataSerializer;
    private readonly productService : IProductService;

    constructor (
        usageDataSerializer : UsageDataSerializer,
        productService : IProductService,
    ) {
        this.usageDataSerializer = usageDataSerializer;
        this.productService = productService;
    }

    public getUsageData(
        userSessionId : UserSessionId,
        locationId : LocationId,
        startInventoryId : InventoryCountId,
        endInventoryId : InventoryCountId,
    ) : Promise<UsageData> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
            start_inventory_id: startInventoryId.getValue(),
            end_inventory_id: endInventoryId.getValue()
        };
        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:usage_data'), queryParameters)
        .then((response : {usage_by_product_id : object, usage_calculation_parameters : object }) => {
            const productIds = new StringValueSet<ProductId>();
            const usageCalculationParametersObject : any = response.usage_calculation_parameters;
            Object.keys(response.usage_by_product_id).map((productIdString : string) => {
                productIds.add(new ProductId(productIdString));
            });
            Object.keys(usageCalculationParametersObject.bottle_count_by_product_id_by_location_id_for_starting_inventory).map((productIdString : string) => {
                productIds.add(new ProductId(productIdString));
            });
            Object.keys(usageCalculationParametersObject.bottle_count_by_product_id_by_location_id_for_ending_inventory).map((productIdString : string) => {
                productIds.add(new ProductId(productIdString));
            });
            Object.keys(usageCalculationParametersObject.bottle_count_by_product_id_by_delivery_id).map((productIdString : string) => {
                productIds.add(new ProductId(productIdString));
            });
            Object.keys(usageCalculationParametersObject.bottle_count_by_product_id_by_transfer_id).map((productIdString : string) => {
                productIds.add(new ProductId(productIdString));
            });
            Object.keys(usageCalculationParametersObject.input_bottle_count_by_product_id_by_prep_event_id).map((productIdString : string) => {
                productIds.add(new ProductId(productIdString));
            });
            Object.keys(usageCalculationParametersObject.output_bottle_count_by_product_id_by_prep_event_id).map((productIdString : string) => {
                productIds.add(new ProductId(productIdString));
            });
            Object.keys(usageCalculationParametersObject.bottle_cost_by_product_id).map((productIdString : string) => {
                productIds.add(new ProductId(productIdString));
            });

            return this.productService.getProductsById(userSessionId, locationId, productIds).then((productResponse) => {
                return Promise.resolve(this.usageDataSerializer.getUsageData(response, productResponse.productsById));
            });
        });
    }
}
