//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';
import CoreTimeModel from './core_time_Model_types';
import LocationModel from './location_Model_types';
import ProductModel from './product_Model_types';
import CoreMathModel from './core_math_Model_types';
import MoneyModel from './money_Model_types';
import UserAccountsModel from './accounts_user_Model_types';



var InventoryTransferReportModel = {};
export default InventoryTransferReportModel;
InventoryTransferReportModel.TransferDirection = {
  '0' : 'FROM_PARTNER_TO_PERSPECTIVE_LOCATION',
  'FROM_PARTNER_TO_PERSPECTIVE_LOCATION' : 0,
  '1' : 'FROM_PERSPECTIVE_TO_PARTNER_LOCATION',
  'FROM_PERSPECTIVE_TO_PARTNER_LOCATION' : 1
};
InventoryTransferReportModel.InventoryTransferReportIdentifier = function(args) {
  this.value = null;
  if (args) {
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field value is unset!');
    }
  }
};
InventoryTransferReportModel.InventoryTransferReportIdentifier.prototype = {};
InventoryTransferReportModel.InventoryTransferReportIdentifier.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportModel.InventoryTransferReportIdentifier.prototype.write = function(output) {
  output.writeStructBegin('InventoryTransferReportIdentifier');
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 1);
    output.writeString(this.value);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportModel.InventoryTransferReportInfo = function(args) {
  this.perspectiveLocation = null;
  this.productAmounts = null;
  this.partnerLocation = null;
  this.customPartnerLocationName = null;
  this.direction = null;
  this.transferTime = null;
  this.note = null;
  this.requestedByUser = null;
  this.requestedTime = null;
  this.sentByUser = null;
  this.sentTime = null;
  this.receivedByUser = null;
  this.receivedTime = null;
  this.cancelledByUser = null;
  this.cancellationTime = null;
  this.cancellationReason = null;
  if (args) {
    if (args.perspectiveLocation !== undefined && args.perspectiveLocation !== null) {
      this.perspectiveLocation = new LocationModel.LocationIdentifier(args.perspectiveLocation);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field perspectiveLocation is unset!');
    }
    if (args.productAmounts !== undefined && args.productAmounts !== null) {
      this.productAmounts = Thrift.copyList(args.productAmounts, [ProductModel.ProductIdAndQuantityOfProduct]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productAmounts is unset!');
    }
    if (args.partnerLocation !== undefined && args.partnerLocation !== null) {
      this.partnerLocation = new LocationModel.LocationIdentifier(args.partnerLocation);
    }
    if (args.customPartnerLocationName !== undefined && args.customPartnerLocationName !== null) {
      this.customPartnerLocationName = args.customPartnerLocationName;
    }
    if (args.direction !== undefined && args.direction !== null) {
      this.direction = args.direction;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field direction is unset!');
    }
    if (args.transferTime !== undefined && args.transferTime !== null) {
      this.transferTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.transferTime);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field transferTime is unset!');
    }
    if (args.note !== undefined && args.note !== null) {
      this.note = args.note;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field note is unset!');
    }
    if (args.requestedByUser !== undefined && args.requestedByUser !== null) {
      this.requestedByUser = new UserAccountsModel.UserAccountIdentifier(args.requestedByUser);
    }
    if (args.requestedTime !== undefined && args.requestedTime !== null) {
      this.requestedTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.requestedTime);
    }
    if (args.sentByUser !== undefined && args.sentByUser !== null) {
      this.sentByUser = new UserAccountsModel.UserAccountIdentifier(args.sentByUser);
    }
    if (args.sentTime !== undefined && args.sentTime !== null) {
      this.sentTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.sentTime);
    }
    if (args.receivedByUser !== undefined && args.receivedByUser !== null) {
      this.receivedByUser = new UserAccountsModel.UserAccountIdentifier(args.receivedByUser);
    }
    if (args.receivedTime !== undefined && args.receivedTime !== null) {
      this.receivedTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.receivedTime);
    }
    if (args.cancelledByUser !== undefined && args.cancelledByUser !== null) {
      this.cancelledByUser = new UserAccountsModel.UserAccountIdentifier(args.cancelledByUser);
    }
    if (args.cancellationTime !== undefined && args.cancellationTime !== null) {
      this.cancellationTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.cancellationTime);
    }
    if (args.cancellationReason !== undefined && args.cancellationReason !== null) {
      this.cancellationReason = args.cancellationReason;
    }
  }
};
InventoryTransferReportModel.InventoryTransferReportInfo.prototype = {};
InventoryTransferReportModel.InventoryTransferReportInfo.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.perspectiveLocation = new LocationModel.LocationIdentifier();
        this.perspectiveLocation.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.productAmounts = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new ProductModel.ProductIdAndQuantityOfProduct();
          elem6.read(input);
          this.productAmounts.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.partnerLocation = new LocationModel.LocationIdentifier();
        this.partnerLocation.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.customPartnerLocationName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I32) {
        this.direction = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.transferTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.transferTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.note = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.requestedByUser = new UserAccountsModel.UserAccountIdentifier();
        this.requestedByUser.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.STRUCT) {
        this.requestedTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.requestedTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.STRUCT) {
        this.sentByUser = new UserAccountsModel.UserAccountIdentifier();
        this.sentByUser.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.STRUCT) {
        this.sentTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.sentTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.STRUCT) {
        this.receivedByUser = new UserAccountsModel.UserAccountIdentifier();
        this.receivedByUser.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 13:
      if (ftype == Thrift.Type.STRUCT) {
        this.receivedTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.receivedTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 14:
      if (ftype == Thrift.Type.STRUCT) {
        this.cancelledByUser = new UserAccountsModel.UserAccountIdentifier();
        this.cancelledByUser.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 15:
      if (ftype == Thrift.Type.STRUCT) {
        this.cancellationTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.cancellationTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 16:
      if (ftype == Thrift.Type.STRING) {
        this.cancellationReason = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportModel.InventoryTransferReportInfo.prototype.write = function(output) {
  output.writeStructBegin('InventoryTransferReportInfo');
  if (this.perspectiveLocation !== null && this.perspectiveLocation !== undefined) {
    output.writeFieldBegin('perspectiveLocation', Thrift.Type.STRUCT, 1);
    this.perspectiveLocation.write(output);
    output.writeFieldEnd();
  }
  if (this.productAmounts !== null && this.productAmounts !== undefined) {
    output.writeFieldBegin('productAmounts', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.productAmounts.length);
    for (var iter7 in this.productAmounts)
    {
      if (this.productAmounts.hasOwnProperty(iter7))
      {
        iter7 = this.productAmounts[iter7];
        iter7.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.partnerLocation !== null && this.partnerLocation !== undefined) {
    output.writeFieldBegin('partnerLocation', Thrift.Type.STRUCT, 3);
    this.partnerLocation.write(output);
    output.writeFieldEnd();
  }
  if (this.customPartnerLocationName !== null && this.customPartnerLocationName !== undefined) {
    output.writeFieldBegin('customPartnerLocationName', Thrift.Type.STRING, 4);
    output.writeString(this.customPartnerLocationName);
    output.writeFieldEnd();
  }
  if (this.direction !== null && this.direction !== undefined) {
    output.writeFieldBegin('direction', Thrift.Type.I32, 5);
    output.writeI32(this.direction);
    output.writeFieldEnd();
  }
  if (this.transferTime !== null && this.transferTime !== undefined) {
    output.writeFieldBegin('transferTime', Thrift.Type.STRUCT, 6);
    this.transferTime.write(output);
    output.writeFieldEnd();
  }
  if (this.note !== null && this.note !== undefined) {
    output.writeFieldBegin('note', Thrift.Type.STRING, 7);
    output.writeString(this.note);
    output.writeFieldEnd();
  }
  if (this.requestedByUser !== null && this.requestedByUser !== undefined) {
    output.writeFieldBegin('requestedByUser', Thrift.Type.STRUCT, 8);
    this.requestedByUser.write(output);
    output.writeFieldEnd();
  }
  if (this.requestedTime !== null && this.requestedTime !== undefined) {
    output.writeFieldBegin('requestedTime', Thrift.Type.STRUCT, 9);
    this.requestedTime.write(output);
    output.writeFieldEnd();
  }
  if (this.sentByUser !== null && this.sentByUser !== undefined) {
    output.writeFieldBegin('sentByUser', Thrift.Type.STRUCT, 10);
    this.sentByUser.write(output);
    output.writeFieldEnd();
  }
  if (this.sentTime !== null && this.sentTime !== undefined) {
    output.writeFieldBegin('sentTime', Thrift.Type.STRUCT, 11);
    this.sentTime.write(output);
    output.writeFieldEnd();
  }
  if (this.receivedByUser !== null && this.receivedByUser !== undefined) {
    output.writeFieldBegin('receivedByUser', Thrift.Type.STRUCT, 12);
    this.receivedByUser.write(output);
    output.writeFieldEnd();
  }
  if (this.receivedTime !== null && this.receivedTime !== undefined) {
    output.writeFieldBegin('receivedTime', Thrift.Type.STRUCT, 13);
    this.receivedTime.write(output);
    output.writeFieldEnd();
  }
  if (this.cancelledByUser !== null && this.cancelledByUser !== undefined) {
    output.writeFieldBegin('cancelledByUser', Thrift.Type.STRUCT, 14);
    this.cancelledByUser.write(output);
    output.writeFieldEnd();
  }
  if (this.cancellationTime !== null && this.cancellationTime !== undefined) {
    output.writeFieldBegin('cancellationTime', Thrift.Type.STRUCT, 15);
    this.cancellationTime.write(output);
    output.writeFieldEnd();
  }
  if (this.cancellationReason !== null && this.cancellationReason !== undefined) {
    output.writeFieldBegin('cancellationReason', Thrift.Type.STRING, 16);
    output.writeString(this.cancellationReason);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportModel.ReportMetaData = function(args) {
  this.creator = null;
  this.creationTime = null;
  this.lastUpdater = null;
  this.lastUpdateTime = null;
  this.perspectiveIsOriginator = null;
  if (args) {
    if (args.creator !== undefined && args.creator !== null) {
      this.creator = new UserAccountsModel.UserAccountIdentifier(args.creator);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field creator is unset!');
    }
    if (args.creationTime !== undefined && args.creationTime !== null) {
      this.creationTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.creationTime);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field creationTime is unset!');
    }
    if (args.lastUpdater !== undefined && args.lastUpdater !== null) {
      this.lastUpdater = new UserAccountsModel.UserAccountIdentifier(args.lastUpdater);
    }
    if (args.lastUpdateTime !== undefined && args.lastUpdateTime !== null) {
      this.lastUpdateTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.lastUpdateTime);
    }
    if (args.perspectiveIsOriginator !== undefined && args.perspectiveIsOriginator !== null) {
      this.perspectiveIsOriginator = args.perspectiveIsOriginator;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field perspectiveIsOriginator is unset!');
    }
  }
};
InventoryTransferReportModel.ReportMetaData.prototype = {};
InventoryTransferReportModel.ReportMetaData.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.creator = new UserAccountsModel.UserAccountIdentifier();
        this.creator.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.creationTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.creationTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.lastUpdater = new UserAccountsModel.UserAccountIdentifier();
        this.lastUpdater.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.lastUpdateTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.lastUpdateTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.BOOL) {
        this.perspectiveIsOriginator = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportModel.ReportMetaData.prototype.write = function(output) {
  output.writeStructBegin('ReportMetaData');
  if (this.creator !== null && this.creator !== undefined) {
    output.writeFieldBegin('creator', Thrift.Type.STRUCT, 1);
    this.creator.write(output);
    output.writeFieldEnd();
  }
  if (this.creationTime !== null && this.creationTime !== undefined) {
    output.writeFieldBegin('creationTime', Thrift.Type.STRUCT, 2);
    this.creationTime.write(output);
    output.writeFieldEnd();
  }
  if (this.lastUpdater !== null && this.lastUpdater !== undefined) {
    output.writeFieldBegin('lastUpdater', Thrift.Type.STRUCT, 3);
    this.lastUpdater.write(output);
    output.writeFieldEnd();
  }
  if (this.lastUpdateTime !== null && this.lastUpdateTime !== undefined) {
    output.writeFieldBegin('lastUpdateTime', Thrift.Type.STRUCT, 4);
    this.lastUpdateTime.write(output);
    output.writeFieldEnd();
  }
  if (this.perspectiveIsOriginator !== null && this.perspectiveIsOriginator !== undefined) {
    output.writeFieldBegin('perspectiveIsOriginator', Thrift.Type.BOOL, 5);
    output.writeBool(this.perspectiveIsOriginator);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportModel.InventoryTransferReportAndMetadataWithoutCost = function(args) {
  this.data = null;
  this.metaData = null;
  if (args) {
    if (args.data !== undefined && args.data !== null) {
      this.data = new InventoryTransferReportModel.InventoryTransferReportInfo(args.data);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field data is unset!');
    }
    if (args.metaData !== undefined && args.metaData !== null) {
      this.metaData = new InventoryTransferReportModel.ReportMetaData(args.metaData);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field metaData is unset!');
    }
  }
};
InventoryTransferReportModel.InventoryTransferReportAndMetadataWithoutCost.prototype = {};
InventoryTransferReportModel.InventoryTransferReportAndMetadataWithoutCost.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.data = new InventoryTransferReportModel.InventoryTransferReportInfo();
        this.data.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.metaData = new InventoryTransferReportModel.ReportMetaData();
        this.metaData.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportModel.InventoryTransferReportAndMetadataWithoutCost.prototype.write = function(output) {
  output.writeStructBegin('InventoryTransferReportAndMetadataWithoutCost');
  if (this.data !== null && this.data !== undefined) {
    output.writeFieldBegin('data', Thrift.Type.STRUCT, 1);
    this.data.write(output);
    output.writeFieldEnd();
  }
  if (this.metaData !== null && this.metaData !== undefined) {
    output.writeFieldBegin('metaData', Thrift.Type.STRUCT, 2);
    this.metaData.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportModel.InventoryTransferReportAndMetadata = function(args) {
  this.data = null;
  this.metaData = null;
  this.productCostsByProductId = null;
  if (args) {
    if (args.data !== undefined && args.data !== null) {
      this.data = new InventoryTransferReportModel.InventoryTransferReportInfo(args.data);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field data is unset!');
    }
    if (args.metaData !== undefined && args.metaData !== null) {
      this.metaData = new InventoryTransferReportModel.ReportMetaData(args.metaData);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field metaData is unset!');
    }
    if (args.productCostsByProductId !== undefined && args.productCostsByProductId !== null) {
      this.productCostsByProductId = Thrift.copyList(args.productCostsByProductId, [ProductModel.ProductIdAndCost]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productCostsByProductId is unset!');
    }
  }
};
InventoryTransferReportModel.InventoryTransferReportAndMetadata.prototype = {};
InventoryTransferReportModel.InventoryTransferReportAndMetadata.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.data = new InventoryTransferReportModel.InventoryTransferReportInfo();
        this.data.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.metaData = new InventoryTransferReportModel.ReportMetaData();
        this.metaData.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        var _size8 = 0;
        var _rtmp312;
        this.productCostsByProductId = [];
        var _etype11 = 0;
        _rtmp312 = input.readListBegin();
        _etype11 = _rtmp312.etype;
        _size8 = _rtmp312.size;
        for (var _i13 = 0; _i13 < _size8; ++_i13)
        {
          var elem14 = null;
          elem14 = new ProductModel.ProductIdAndCost();
          elem14.read(input);
          this.productCostsByProductId.push(elem14);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportModel.InventoryTransferReportAndMetadata.prototype.write = function(output) {
  output.writeStructBegin('InventoryTransferReportAndMetadata');
  if (this.data !== null && this.data !== undefined) {
    output.writeFieldBegin('data', Thrift.Type.STRUCT, 1);
    this.data.write(output);
    output.writeFieldEnd();
  }
  if (this.metaData !== null && this.metaData !== undefined) {
    output.writeFieldBegin('metaData', Thrift.Type.STRUCT, 2);
    this.metaData.write(output);
    output.writeFieldEnd();
  }
  if (this.productCostsByProductId !== null && this.productCostsByProductId !== undefined) {
    output.writeFieldBegin('productCostsByProductId', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRUCT, this.productCostsByProductId.length);
    for (var iter15 in this.productCostsByProductId)
    {
      if (this.productCostsByProductId.hasOwnProperty(iter15))
      {
        iter15 = this.productCostsByProductId[iter15];
        iter15.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportModel.BreakageReportIdentifier = function(args) {
  this.value = null;
  if (args) {
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field value is unset!');
    }
  }
};
InventoryTransferReportModel.BreakageReportIdentifier.prototype = {};
InventoryTransferReportModel.BreakageReportIdentifier.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportModel.BreakageReportIdentifier.prototype.write = function(output) {
  output.writeStructBegin('BreakageReportIdentifier');
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 1);
    output.writeString(this.value);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportModel.BreakageReportInfo = function(args) {
  this.perspectiveLocation = null;
  this.productAmounts = null;
  this.breakageTime = null;
  this.note = null;
  this.type = null;
  if (args) {
    if (args.perspectiveLocation !== undefined && args.perspectiveLocation !== null) {
      this.perspectiveLocation = new LocationModel.LocationIdentifier(args.perspectiveLocation);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field perspectiveLocation is unset!');
    }
    if (args.productAmounts !== undefined && args.productAmounts !== null) {
      this.productAmounts = Thrift.copyList(args.productAmounts, [ProductModel.ProductIdAndQuantityOfProduct]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productAmounts is unset!');
    }
    if (args.breakageTime !== undefined && args.breakageTime !== null) {
      this.breakageTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.breakageTime);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field breakageTime is unset!');
    }
    if (args.note !== undefined && args.note !== null) {
      this.note = args.note;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field note is unset!');
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
  }
};
InventoryTransferReportModel.BreakageReportInfo.prototype = {};
InventoryTransferReportModel.BreakageReportInfo.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.perspectiveLocation = new LocationModel.LocationIdentifier();
        this.perspectiveLocation.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size16 = 0;
        var _rtmp320;
        this.productAmounts = [];
        var _etype19 = 0;
        _rtmp320 = input.readListBegin();
        _etype19 = _rtmp320.etype;
        _size16 = _rtmp320.size;
        for (var _i21 = 0; _i21 < _size16; ++_i21)
        {
          var elem22 = null;
          elem22 = new ProductModel.ProductIdAndQuantityOfProduct();
          elem22.read(input);
          this.productAmounts.push(elem22);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.breakageTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.breakageTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.note = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.type = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportModel.BreakageReportInfo.prototype.write = function(output) {
  output.writeStructBegin('BreakageReportInfo');
  if (this.perspectiveLocation !== null && this.perspectiveLocation !== undefined) {
    output.writeFieldBegin('perspectiveLocation', Thrift.Type.STRUCT, 1);
    this.perspectiveLocation.write(output);
    output.writeFieldEnd();
  }
  if (this.productAmounts !== null && this.productAmounts !== undefined) {
    output.writeFieldBegin('productAmounts', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.productAmounts.length);
    for (var iter23 in this.productAmounts)
    {
      if (this.productAmounts.hasOwnProperty(iter23))
      {
        iter23 = this.productAmounts[iter23];
        iter23.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.breakageTime !== null && this.breakageTime !== undefined) {
    output.writeFieldBegin('breakageTime', Thrift.Type.STRUCT, 3);
    this.breakageTime.write(output);
    output.writeFieldEnd();
  }
  if (this.note !== null && this.note !== undefined) {
    output.writeFieldBegin('note', Thrift.Type.STRING, 4);
    output.writeString(this.note);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.STRING, 5);
    output.writeString(this.type);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportModel.BreakageReportAndMetadata = function(args) {
  this.data = null;
  this.metaData = null;
  if (args) {
    if (args.data !== undefined && args.data !== null) {
      this.data = new InventoryTransferReportModel.BreakageReportInfo(args.data);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field data is unset!');
    }
    if (args.metaData !== undefined && args.metaData !== null) {
      this.metaData = new InventoryTransferReportModel.ReportMetaData(args.metaData);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field metaData is unset!');
    }
  }
};
InventoryTransferReportModel.BreakageReportAndMetadata.prototype = {};
InventoryTransferReportModel.BreakageReportAndMetadata.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.data = new InventoryTransferReportModel.BreakageReportInfo();
        this.data.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.metaData = new InventoryTransferReportModel.ReportMetaData();
        this.metaData.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportModel.BreakageReportAndMetadata.prototype.write = function(output) {
  output.writeStructBegin('BreakageReportAndMetadata');
  if (this.data !== null && this.data !== undefined) {
    output.writeFieldBegin('data', Thrift.Type.STRUCT, 1);
    this.data.write(output);
    output.writeFieldEnd();
  }
  if (this.metaData !== null && this.metaData !== undefined) {
    output.writeFieldBegin('metaData', Thrift.Type.STRUCT, 2);
    this.metaData.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryTransferReportModel.BreakageReportUpdate = function(args) {
  this.productAmountUpdates = null;
  this.newReportTime = null;
  this.newNote = null;
  if (args) {
    if (args.productAmountUpdates !== undefined && args.productAmountUpdates !== null) {
      this.productAmountUpdates = Thrift.copyList(args.productAmountUpdates, [ProductModel.ProductIdAndQuantityOfProduct]);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productAmountUpdates is unset!');
    }
    if (args.newReportTime !== undefined && args.newReportTime !== null) {
      this.newReportTime = new CoreTimeModel.TimestampWithMillisecondPrecision(args.newReportTime);
    }
    if (args.newNote !== undefined && args.newNote !== null) {
      this.newNote = args.newNote;
    }
  }
};
InventoryTransferReportModel.BreakageReportUpdate.prototype = {};
InventoryTransferReportModel.BreakageReportUpdate.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size24 = 0;
        var _rtmp328;
        this.productAmountUpdates = [];
        var _etype27 = 0;
        _rtmp328 = input.readListBegin();
        _etype27 = _rtmp328.etype;
        _size24 = _rtmp328.size;
        for (var _i29 = 0; _i29 < _size24; ++_i29)
        {
          var elem30 = null;
          elem30 = new ProductModel.ProductIdAndQuantityOfProduct();
          elem30.read(input);
          this.productAmountUpdates.push(elem30);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.newReportTime = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.newReportTime.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.newNote = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryTransferReportModel.BreakageReportUpdate.prototype.write = function(output) {
  output.writeStructBegin('BreakageReportUpdate');
  if (this.productAmountUpdates !== null && this.productAmountUpdates !== undefined) {
    output.writeFieldBegin('productAmountUpdates', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.productAmountUpdates.length);
    for (var iter31 in this.productAmountUpdates)
    {
      if (this.productAmountUpdates.hasOwnProperty(iter31))
      {
        iter31 = this.productAmountUpdates[iter31];
        iter31.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.newReportTime !== null && this.newReportTime !== undefined) {
    output.writeFieldBegin('newReportTime', Thrift.Type.STRUCT, 2);
    this.newReportTime.write(output);
    output.writeFieldEnd();
  }
  if (this.newNote !== null && this.newNote !== undefined) {
    output.writeFieldBegin('newNote', Thrift.Type.STRING, 3);
    output.writeString(this.newNote);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

