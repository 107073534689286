//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';
import CoreTimeModel from './core_time_Model_types';



var CoreApiExceptions = {};
export default CoreApiExceptions;
CoreApiExceptions.ServiceTemporarilyUnavailableException = function(args) {
};
Thrift.inherits(CoreApiExceptions.ServiceTemporarilyUnavailableException, Thrift.TException);
CoreApiExceptions.ServiceTemporarilyUnavailableException.prototype.name = 'ServiceTemporarilyUnavailableException';
CoreApiExceptions.ServiceTemporarilyUnavailableException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CoreApiExceptions.ServiceTemporarilyUnavailableException.prototype.write = function(output) {
  output.writeStructBegin('ServiceTemporarilyUnavailableException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

CoreApiExceptions.RateLimitExceededException = function(args) {
};
Thrift.inherits(CoreApiExceptions.RateLimitExceededException, Thrift.TException);
CoreApiExceptions.RateLimitExceededException.prototype.name = 'RateLimitExceededException';
CoreApiExceptions.RateLimitExceededException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CoreApiExceptions.RateLimitExceededException.prototype.write = function(output) {
  output.writeStructBegin('RateLimitExceededException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

