import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { UserAccountIdAndTimestamp } from 'api/UserAccount/model/UserAccountIdAndTimestamp';

import { ProductId } from './ProductId';
import { StringValueSet } from 'api/Core/StringValueSet';

export class ProductMergeEvent {
    constructor(
        private readonly userAccountIdAndTimestamp : UserAccountIdAndTimestamp,
        private readonly mergedToProductId : ProductId,
        private readonly mergedFromProductIds : StringValueSet<ProductId>,
        private readonly storageAreasAffected : StringValueSet<StorageAreaId>,
        private readonly salesItemsAffected : StringValueSet<SalesItemId>,
        private readonly cartItemsAffected : StringValueSet<ProductId>,
    ) {}

    public getUserAccountIdAndTimestamp() {
        return this.userAccountIdAndTimestamp;
    }
    public getMergedToProductId() {
        return this.mergedToProductId;
    }
    public getMergedFromProductIds() {
        return this.mergedFromProductIds;
    }
    public getAffectedStorageAreaIds() {
        return this.storageAreasAffected;
    }
    public getAffectedSalesItemIds() {
        return this.salesItemsAffected;
    }
    public getAffectedCartItems() {
        return this.cartItemsAffected;
    }
    public equals(other : any) : boolean {
        if (!(other instanceof ProductMergeEvent)) {
            return false;
        }

        if (
            !this.getUserAccountIdAndTimestamp().equals(other.getUserAccountIdAndTimestamp()) ||
            !this.getMergedToProductId().equals(other.getMergedToProductId()) ||
            !this.getMergedFromProductIds().equals(other.getMergedFromProductIds()) ||
            !this.getAffectedStorageAreaIds().equals(other.getAffectedStorageAreaIds()) ||
            !this.getAffectedSalesItemIds().equals(other.getAffectedSalesItemIds()) ||
            !this.getAffectedCartItems().equals(other.getAffectedCartItems())
        ) {
            return false;
        }

        return true;
    }
}
