import PrepEventModel from 'gen-thrift/prep_event_Model_types';
import PrepEventService from 'gen-thrift/PrepEventService';
import ProductModel from 'gen-thrift/product_Model_types';

import moment from 'moment-timezone';

import { IPrepEventService } from 'api/PrepEvent/interfaces/IPrepEventService';

import { MomentObjectToThriftSerializer } from 'api/Core/serializer/MomentObjectToThriftSerializer';
import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { LocationId } from 'api/Location/model/LocationId';
import { LocationObjectToThriftSerializer } from 'api/Location/serializer/LocationObjectToThriftSerializer';
import { PrepEvent } from 'api/PrepEvent/model/PrepEvent';
import { PrepEventId } from 'api/PrepEvent/model/PrepEventId';
import { PrepEventObjectToThriftSerializer } from 'api/PrepEvent/serializer/PrepEventObjectToThriftSerializer';
import { PrepEventThriftToObjectSerializer } from 'api/PrepEvent/serializer/PrepEventThriftToObjectSerilaizer';
import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { ProductObjectToThriftSerializer } from 'api/Product/serializer/ProductObjectToThriftSerializer';
import { ProductThriftToObjectSerializer } from 'api/Product/serializer/ProductThriftToObjectSerializer';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { UserAccountObjectToThriftSerializer } from 'api/UserAccount/serializer/UserAccountObjectToThriftSerializer';

export class PrepEventServiceImpl implements IPrepEventService {
    constructor (
        private prepEventServiceClient : PrepEventService.PrepEventServiceClient,
        private userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer,
        private locationObjectToThriftSerializer : LocationObjectToThriftSerializer,
        private productThriftToObjectSerializer : ProductThriftToObjectSerializer,
        private productObjectToThriftSerializer : ProductObjectToThriftSerializer,
        private momentObjectToThriftSerializer : MomentObjectToThriftSerializer,
        private prepEventObjectToThriftSerializer : PrepEventObjectToThriftSerializer,
        private prepEventThriftToObjectSerializer : PrepEventThriftToObjectSerializer
    ) {}

    ///////////////////////////
    // GETTERS
    ///////////////////////////
    public getPrepEventsById(
        userSessionId : UserSessionId,
        prepEventIds : StringValueSet<PrepEventId>
    ) : Promise<StringValueMap<PrepEventId, PrepEvent>> {
        return new Promise<StringValueMap<PrepEventId, PrepEvent>>((resolve, reject) => {
            return this.prepEventServiceClient.getPrepEventsById(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.prepEventObjectToThriftSerializer.getThriftPrepEventIds(prepEventIds),
                (result : Array<PrepEventModel.PrepEventAndId> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    const prepEventsById = this.prepEventThriftToObjectSerializer.getMapFromPrepEventAndIds(result);
                    return resolve(prepEventsById);
                }
            );
        });
    }

    public getPrepEventIdsForLocationInPeriod(
        userSessionId : UserSessionId,
        locationId : LocationId,
        periodStartInclusive : moment.Moment,
        periodEndExclusive : moment.Moment
    ) : Promise<StringValueSet<PrepEventId>> {
        return new Promise<StringValueSet<PrepEventId>>((resolve, reject) => {
            return this.prepEventServiceClient.getPrepEventIdsForLocationInPeriod(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                this.momentObjectToThriftSerializer.getThriftTimestampFromMoment(periodStartInclusive),
                this.momentObjectToThriftSerializer.getThriftTimestampFromMoment(periodEndExclusive),
                (result : Array<PrepEventModel.PrepEventId> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    const prepEventIds = new StringValueSet<PrepEventId>();
                    result.forEach((prepEventId) => {
                        prepEventIds.add(this.prepEventThriftToObjectSerializer.getPrepEventId(prepEventId));
                    });
                    return resolve(prepEventIds);
                }
            );
        });
    }

    public getPrepEventOutputProductIdsForLocation(
        userSessionId : UserSessionId,
        locationId : LocationId
    ) : Promise<StringValueSet<ProductId>> {
        return new Promise<StringValueSet<ProductId>>((resolve, reject) => {
            return this.prepEventServiceClient.getPrepEventOutputProductIdsForLocation(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                (result : Array<ProductModel.ProductId> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    const productIdSet = new StringValueSet(this.productThriftToObjectSerializer.getProductIds(result));
                    return resolve(productIdSet);
                }
            );
        });
    }

    ///////////////////////////
    // UPDATERS
    ///////////////////////////
    public createPrepEvent(
        userSessionId : UserSessionId,
        locationId : LocationId,
        prepEventTime : moment.Moment,
        note : string,
        type : string,
        inputQuantityOfProductByProductId : StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>>,
        outputQuantityOfProductByProductId : StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>>,
        outputPriceByProductId : StringValueMap<ProductId, ProductCost>
    ) : Promise<PrepEventId> {
        return new Promise<PrepEventId>((resolve, reject) => {
            return this.prepEventServiceClient.createPrepEvent(
                this.momentObjectToThriftSerializer.getThriftTimestampFromMoment(prepEventTime),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                note,
                type,
                this.productObjectToThriftSerializer.getThriftProductIdAndQuantityOfProducts(inputQuantityOfProductByProductId),
                this.productObjectToThriftSerializer.getThriftProductIdAndQuantityOfProducts(outputQuantityOfProductByProductId),
                this.productObjectToThriftSerializer.getThriftProductIdAndCosts(outputPriceByProductId),
                (result : PrepEventModel.PrepEventId | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    const prepEventId = this.prepEventThriftToObjectSerializer.getPrepEventId(result);
                    return resolve(prepEventId);
                }
            );
        });
    }

    public updatePrepEvent(
        userSessionId : UserSessionId,
        prepEventId : PrepEventId,
        prepEventTime : moment.Moment,
        note : string,
        type : string,
        inputQuantityOfProductByProductId : StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>>,
        outputQuantityOfProductByProductId : StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>>,
        outputPriceByProductId : StringValueMap<ProductId, ProductCost>
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            return this.prepEventServiceClient.updatePrepEvent(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.prepEventObjectToThriftSerializer.getThriftPrepEventId(prepEventId),
                this.momentObjectToThriftSerializer.getThriftTimestampFromMoment(prepEventTime),
                note,
                type,
                this.productObjectToThriftSerializer.getThriftProductIdAndQuantityOfProducts(inputQuantityOfProductByProductId),
                this.productObjectToThriftSerializer.getThriftProductIdAndQuantityOfProducts(outputQuantityOfProductByProductId),
                this.productObjectToThriftSerializer.getThriftProductIdAndCosts(outputPriceByProductId),
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve();
                }
            );
        });
    }

    public deletePrepEvent(
        userSessionId : UserSessionId,
        prepEventId : PrepEventId
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            return this.prepEventServiceClient.deletePrepEvent(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.prepEventObjectToThriftSerializer.getThriftPrepEventId(prepEventId),
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }
                    return resolve();
                }
            );
        });
    }
}
