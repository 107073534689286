import { Category } from 'api/Product/model/Category';
import { CategoryId } from 'api/Product/model/CategoryId';
import { Action } from 'redux';

import { ActionInterfaces, ActionTypes } from '../actions/addItemActions';

import { StringValueMap } from 'api/Core/StringValueMap';
import { Distributor } from 'api/Distributor/model/Distributor';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { CatalogItem } from 'api/Search/model/CatalogItem';
import { CatalogItemId } from 'api/Search/model/CatalogItemId';
import { ICatalogItemOption } from 'api/Search/model/ICatalogItemOption';

import { AddItemModalContextType } from '../models/AddItemModalContextType';

import { utils } from 'shared/components/AddItem/utils';
import { ISetShownAction } from 'shared/models/IAction';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { StringValueSet } from 'api/Core/StringValueSet';
import { Packaging } from 'api/Product/model/Packaging';

export type AddItemModalComponent = 'searchSuggestionList' | 'emptyState' | 'mobileFilterPanel' | 'collapsibleProductSearchResults' | 'unsavedChangesDialog';
export type CatalogItemComponent = 'optionDropdown' | 'packagingForm';

export interface IPricedProductInfoByProductIdValue {
    [productIdValue : string] : {
        productPackageRowInfoByAttribute : IProductPackageRowInfoByAttribute;
    };
}

export interface IProductPackageRowInfoByAttribute {
    [productPackageRowInfoByAttribute : string] : IProductPackageRowInfo;
}

export interface IProductPackageRowInfo {
    productId : ProductId;
    isDisabled : boolean;
    isSelected : boolean;
    packaging : Packaging;
    distributorId : DistributorId | null;
    price : number;
}

export interface IPricedProductPackageRowId {
    pricedProductIdValue : string;
    productPackageRowInfoAttribute : string;
}

export interface ICatalogItemOptionRowId {
    catalogItemId : CatalogItemId;
    optionIndex : number;
}

export interface IAddItemData {
    distributorsById : StringValueMap<DistributorId, Distributor>;
    productsById : StringValueMap<ProductId, Product>;
    categoriesById : StringValueMap<CategoryId, Category>;
}

export interface IAddItemState {
    // static data
    addItemData : null | IAddItemData;
    contextType : AddItemModalContextType | null;

    // search result data (updated upon every search)
    searchSuggestions : Array<string>;
    pricedProductInfoByProductIdValue : IPricedProductInfoByProductIdValue;     // also contains UI state
    catalogItemsById : StringValueMap<CatalogItemId, CatalogItem>;
    totalNumberOfProducts : number;
    totalNumberOfCatalogItems : number;

    // search input
    searchTerm : string | null;
    activeSearchTerm : string | null;
    resultsPerPage : number;
    pageOffset : number;

    // UI
    isLoadingSearchResults : boolean;
    isLoading : boolean;
    displayedProductIds : Array<ProductId>;
    displayedCatalogItemIds : Array<CatalogItemId>;
    isShownByComponentName : {[component in AddItemModalComponent] : boolean};  // old "isShown" component states are not refactored
    isShownByCatalogItemIdByComponentName : {[component in CatalogItemComponent] : StringValueMap<CatalogItemId, boolean>};
    isSearchBarFocused : boolean;
    highlightedSearchSuggestion : string | null;
    isItemSelectedMenuExpanded : boolean;
    addItemsToEntityButtonText : string;
    productsAddedSnackbar : {
        numberEdited : number;
        isShown : boolean;
    };
    expandedProductIdRows : StringValueSet<ProductId>;

    // select & add
    customOptionsByCatalogItemId : StringValueMap<CatalogItemId, Array<ICatalogItemOption>>;
    selections : Array<IPricedProductPackageRowId | ICatalogItemOptionRowId>;    // need a mixed-type array to persist order of selecting
    productIdsInEntity : Array<IPricedProductPackageRowId>;
    addedCatalogItemSelections : Array<ICatalogItemOptionRowId>;  // for preventing catalog item from being added again
    productIdsLastAddedToEntity : Array<IPricedProductPackageRowId>;    // only reset after another addition (for purpose of other apps adding the products without an api call)

    // Saved context props
    onGetInitialDataFromContext? : () => StringValueMap<ProductId, Product>;
}

const initialState : IAddItemState = {
    addItemData: null,
    isShownByComponentName: {
        searchSuggestionList: false,
        emptyState: false,
        mobileFilterPanel: false,
        collapsibleProductSearchResults: true,
        unsavedChangesDialog: false,
    },
    activeSearchTerm: null,
    searchTerm: null,
    searchSuggestions: [],
    highlightedSearchSuggestion: null,
    contextType: null,
    addItemsToEntityButtonText: 'Add Item',
    isItemSelectedMenuExpanded: false,
    pageOffset: 0,
    resultsPerPage: 0,
    totalNumberOfCatalogItems: 0,
    totalNumberOfProducts: 0,
    displayedProductIds: [],
    displayedCatalogItemIds: [],
    productIdsInEntity: [],
    pricedProductInfoByProductIdValue: {},
    catalogItemsById : new StringValueMap(),
    selections: [],
    isLoadingSearchResults: false,
    isLoading: false,
    isSearchBarFocused: false,
    productsAddedSnackbar : {
        numberEdited: 0,
        isShown: false,
    },
    expandedProductIdRows: new StringValueSet(),
    productIdsLastAddedToEntity : [],
    addedCatalogItemSelections: [],
    isShownByCatalogItemIdByComponentName : {
        optionDropdown: new StringValueMap(),
        packagingForm : new StringValueMap(),
    },
    customOptionsByCatalogItemId: new StringValueMap(),
};

const reduceSetComponentIsShown = (
    state : IAddItemState,
    action : ActionInterfaces.ISetComponentIsShownAction,
) : IAddItemState => {
    const newState = { ...state };
    newState.isShownByComponentName[action.payload.component] = action.payload.isShown;
    return newState;
};

const reduceSetDisplayedProductIdsActon = (
    state : IAddItemState,
    action : ActionInterfaces.ISetDisplayedProductIdsAction,
) : IAddItemState => {
    const newState = { ...state };
    newState.displayedProductIds = action.payload.productIds;
    return newState;
};

const reduceSetDisplayedCatalogItemIds = (
    state : IAddItemState,
    action : ActionInterfaces.ISetDisplayedCatalogItemIds,
) : IAddItemState => {
    const newState = { ...state };
    newState.displayedCatalogItemIds = action.payload.catalogItemIds;
    return newState;
};

const reduceAddProductRowInfoByPricedProductId = (
    state : IAddItemState,
    action : ActionInterfaces.IAddProductRowInfoByPricedProductIdAction,
) : IAddItemState => {
    const newState = { ...state };
    newState.pricedProductInfoByProductIdValue = { ...newState.pricedProductInfoByProductIdValue };

    const productRowInfoByProductIdToAdd = action.payload.pricedProductInfoByProductIdValue;
    Object.keys(productRowInfoByProductIdToAdd).forEach((productIdValue : string) => {
        const productRowInfo = { ...productRowInfoByProductIdToAdd[productIdValue] };
        productRowInfo.productPackageRowInfoByAttribute = { ...productRowInfo.productPackageRowInfoByAttribute };

        // double check if this product's package rows should be disabled (beacuse it's in the entity)
        Object.keys(productRowInfo.productPackageRowInfoByAttribute).forEach((productPackageRowInfoAttribute) => {
            const isProductInEntity = newState.productIdsInEntity.some((pricedProductPackageRowIdValues) => {
                return pricedProductPackageRowIdValues.pricedProductIdValue === productIdValue &&
                    productPackageRowInfoAttribute === pricedProductPackageRowIdValues.productPackageRowInfoAttribute;
            });
            if (isProductInEntity) {
                productRowInfo.productPackageRowInfoByAttribute[productPackageRowInfoAttribute].isDisabled = true;
            }
        });

        newState.pricedProductInfoByProductIdValue[productIdValue] = productRowInfo;
    });

    return newState;
};

const reduceSetAddItemData = (
    state : IAddItemState,
    action : ActionInterfaces.ISetAddItemData
) : IAddItemState => {
    return {
        ...state,
        addItemData: action.payload.addItemData,
    };
};

const reduceSetLoading = (
    state : IAddItemState,
    action : ISetShownAction
) : IAddItemState => {
    const newState = { ...state };
    newState.isLoading = action.payload.isShown;
    return newState;
};

const reduceSetLoadingSearchResults = (
    state : IAddItemState,
    action : ActionInterfaces.ISetLoading
) : IAddItemState => {
    const newState = { ...state };
    newState.isLoadingSearchResults = action.payload.isLoading;
    return newState;
};

// TODO: write a test
const reduceSetProductRowExpanded = (
    state : IAddItemState,
    action : ActionInterfaces.ISetProductRowExpandedAction
) : IAddItemState => {
    const newExpandedProductIdRows = new StringValueSet(state.expandedProductIdRows);
    if (action.payload.isExpanded) {
        newExpandedProductIdRows.add(action.payload.productId);
    } else {
        newExpandedProductIdRows.delete(action.payload.productId);
    }

    return {
        ...state,
        expandedProductIdRows: newExpandedProductIdRows
    };
};

const reduceSetProductPackageRowSelected = (
    state : IAddItemState,
    action : ActionInterfaces.ISetProductPackageRowSelected
) : IAddItemState => {
    const {
        rowId,
        isSelected,
    } = action.payload;
    const newState = { ...state };
    newState.selections = newState.selections.slice(0);

    const rowAlreadySelectedIndex = newState.selections.findIndex((selection) => {
        return !utils.isCatalogItemOptionRowId(selection)
            && selection.productPackageRowInfoAttribute === rowId.productPackageRowInfoAttribute
            && selection.pricedProductIdValue === rowId.pricedProductIdValue;
    });

    // add newly selected product to selections
    if (isSelected && rowAlreadySelectedIndex < 0) {
        newState.selections.push(rowId);

    // remove newly deselected product from selections
    } else if (!isSelected && rowAlreadySelectedIndex >= 0) {
        newState.selections.splice(rowAlreadySelectedIndex, 1);
    }

    const packageRowAttribute = rowId.productPackageRowInfoAttribute;
    newState.pricedProductInfoByProductIdValue = {
        ...newState.pricedProductInfoByProductIdValue,
        [rowId.pricedProductIdValue] : {
            ...newState.pricedProductInfoByProductIdValue[rowId.pricedProductIdValue],
            productPackageRowInfoByAttribute : {
                ...newState.pricedProductInfoByProductIdValue[rowId.pricedProductIdValue].productPackageRowInfoByAttribute,
                [packageRowAttribute] : {
                    ...newState.pricedProductInfoByProductIdValue[rowId.pricedProductIdValue].productPackageRowInfoByAttribute[packageRowAttribute],
                    isSelected,
                }
            }
        }
    };

    return newState;
};

const reduceSetCatalogOptionRowSelected = (
    state : IAddItemState,
    action : ActionInterfaces.ISetCatalogOptionRowSelected
) : IAddItemState => {
    const {
        rowId,
        isSelected,
    } = action.payload;
    const newState = { ...state };
    newState.selections = newState.selections.slice(0);

    const rowAlreadySelectedIndex = state.selections.findIndex((s) => {
        return utils.isCatalogItemOptionRowId(s)
            && s.catalogItemId.equals(rowId.catalogItemId)
            && s.optionIndex === rowId.optionIndex;
    });

    // add newly selected product to selections
    if (isSelected && rowAlreadySelectedIndex < 0) {
        newState.selections.push(rowId);

        // remove newly deselected product from selections
    } else if (!isSelected && rowAlreadySelectedIndex >= 0) {
        newState.selections.splice(rowAlreadySelectedIndex, 1);
    }

    return newState;
};

const reduceClearSelections = (
    state : IAddItemState,
    action : ActionInterfaces.IClearAllSelections
) : IAddItemState => {
    const pricedProductInfoByProductIdValue = { ...state.pricedProductInfoByProductIdValue };
    Object.keys(pricedProductInfoByProductIdValue).forEach((productIdValue) => {
        const productPackageRowInfoByAttribute = pricedProductInfoByProductIdValue[productIdValue].productPackageRowInfoByAttribute;
        Object.keys(productPackageRowInfoByAttribute).forEach((packageRowId) => {
            productPackageRowInfoByAttribute[packageRowId].isSelected = false;
        });
    });

    return {
        ...state,
        selections: [],
        expandedProductIdRows: new StringValueSet(),
        pricedProductInfoByProductIdValue,
    };
};

const reduceSetItemsSelectedMenuIsOpen = (
    state : IAddItemState,
    action : ISetShownAction
) : IAddItemState => {
    const newState = { ...state };
    newState.isItemSelectedMenuExpanded = action.payload.isShown;
    return newState;
};

const reduceSetSearchTerm = (
    state : IAddItemState,
    action : ActionInterfaces.ISetSearchTermAction
) : IAddItemState => {
    const newState = { ...state };
    newState.searchTerm = action.payload.searchTerm;
    return newState;
};

const reduceSetActiveSearchTerm = (
    state : IAddItemState,
    action : ActionInterfaces.ISetActiveSearchTermAction
) : IAddItemState => {
    const newState = { ...state };
    newState.activeSearchTerm = action.payload.activeSearchTerm;
    return newState;
};

const reduceSetAddItemToEntityButtonText = (
    state : IAddItemState,
    action : ActionInterfaces.ISetAddItemToEntityButtonTextAction
) : IAddItemState => {
    const newState = { ...state };
    newState.addItemsToEntityButtonText = action.payload.value;
    return newState;
};

const reduceSetContextType = (
    state : IAddItemState,
    action : ActionInterfaces.ISetContextTypeAction
) : IAddItemState => {
    const newState = { ...state };
    newState.contextType = action.payload.contextType;
    return newState;
};

const syncProductRowInfoByPricedProductIdIsDisabled = (state : IAddItemState) => {
    state.pricedProductInfoByProductIdValue = { ...state.pricedProductInfoByProductIdValue };

    // keep track of product ids so we don't do redundant copying
    const productIdsHash : { [key : string] : { [key : string] : boolean } } = {};

    // mark the appropriate rows in pricedProductInfoByProductIdValue as disabled
    state.productIdsInEntity.forEach((productIdValueAndPackage) => {
        if (typeof productIdsHash[productIdValueAndPackage.pricedProductIdValue] === 'undefined') {
            productIdsHash[productIdValueAndPackage.pricedProductIdValue] = {};
        }
        productIdsHash[productIdValueAndPackage.pricedProductIdValue][productIdValueAndPackage.productPackageRowInfoAttribute] = true;

        if (typeof state.pricedProductInfoByProductIdValue[productIdValueAndPackage.pricedProductIdValue] !== 'undefined') {
            const productRowInfo = { ...state.pricedProductInfoByProductIdValue[productIdValueAndPackage.pricedProductIdValue] };
            const productPackageRowInfoAttributes = Object.keys(productRowInfo.productPackageRowInfoByAttribute);
            productPackageRowInfoAttributes.some((productPackageRowInfoAttribute) => {
                if (productPackageRowInfoAttribute === productIdValueAndPackage.productPackageRowInfoAttribute) {
                    const productPackageRowInfoByAttribute = { ...productRowInfo.productPackageRowInfoByAttribute };
                    const packageRowInfo = { ...productPackageRowInfoByAttribute[productPackageRowInfoAttribute] };
                    packageRowInfo.isDisabled = true;
                    productRowInfo.productPackageRowInfoByAttribute = productPackageRowInfoByAttribute;
                    productRowInfo.productPackageRowInfoByAttribute[productPackageRowInfoAttribute] = packageRowInfo;
                    state.pricedProductInfoByProductIdValue[productIdValueAndPackage.pricedProductIdValue] = productRowInfo;
                    return true;
                }
                return false;
            });
        }
    });

    // re-enable products that were not in `productIdsInEntity`
    Object.keys(state.pricedProductInfoByProductIdValue).forEach((productIdValue) => {
        const productRowInfo = { ...state.pricedProductInfoByProductIdValue[productIdValue] };
        productRowInfo.productPackageRowInfoByAttribute = { ...productRowInfo.productPackageRowInfoByAttribute };

        Object.keys(productRowInfo.productPackageRowInfoByAttribute).forEach((packageRowInfoAttribute) => {
            let shouldBeEnabled : boolean = typeof productIdsHash[productIdValue] === 'undefined';
            if (!shouldBeEnabled) {
                shouldBeEnabled = productIdsHash[productIdValue][packageRowInfoAttribute] !== true;
            }
            // this product id wasn't covered by products in the entity, so make sure it's not disabled
            if (shouldBeEnabled) {
                const packageRowInfo = { ...productRowInfo.productPackageRowInfoByAttribute[packageRowInfoAttribute] };
                packageRowInfo.isDisabled = false;
                productRowInfo.productPackageRowInfoByAttribute[packageRowInfoAttribute] = packageRowInfo;
            }
        });

        state.pricedProductInfoByProductIdValue[productIdValue] = productRowInfo;
    });

    return state;
};

const reduceAddProductIdsToProductIdsInEntity = (
    state : IAddItemState,
    action : ActionInterfaces.IAddProductIdsToProductIdsInEntityAction
) : IAddItemState => {
    const newState = { ...state };
    newState.productIdsInEntity = newState.productIdsInEntity.slice(0);

    action.payload.pricedProductPackageRowIdValues.forEach((pricedProductPackageRowIdValue) => {
        const isProductInEntity = newState.productIdsInEntity.some((productIdsInEntity) => {
            return pricedProductPackageRowIdValue.pricedProductIdValue === productIdsInEntity.pricedProductIdValue &&
                pricedProductPackageRowIdValue.productPackageRowInfoAttribute === productIdsInEntity.productPackageRowInfoAttribute;
        });
        if (!isProductInEntity) {
            newState.productIdsInEntity.push(pricedProductPackageRowIdValue);
        }
    });

    return syncProductRowInfoByPricedProductIdIsDisabled(newState);
};

const reduceRemoveProductIdsFromProductIdsInEntity = (
    state : IAddItemState,
    action : ActionInterfaces.IRemoveProductIdsFromProductIdsInEntityAction
) : IAddItemState => {
    const newState = { ...state };
    newState.productIdsInEntity = newState.productIdsInEntity.slice(0);

    action.payload.pricedProductPackageRowIdValues.forEach((pricedProductPackageRowIdValue) => {
        let productIdInEntityIndex : number = 0;
        const isProductInEntity = newState.productIdsInEntity.some((productIdsInEntity, index) => {
            productIdInEntityIndex = index;
            return pricedProductPackageRowIdValue.pricedProductIdValue === productIdsInEntity.pricedProductIdValue &&
                pricedProductPackageRowIdValue.productPackageRowInfoAttribute === productIdsInEntity.productPackageRowInfoAttribute;
        });
        if (isProductInEntity) {
            newState.productIdsInEntity.splice(productIdInEntityIndex, 1);
        }
    });

    return syncProductRowInfoByPricedProductIdIsDisabled(newState);
};

const reduceClearProductIdsFromEntity = (
    state : IAddItemState,
    action : ActionInterfaces.IClearProductIdsInEntityAction
) : IAddItemState => {
    const newState = { ...state };
    newState.productIdsInEntity = [];
    newState.addedCatalogItemSelections = [];
    return syncProductRowInfoByPricedProductIdIsDisabled(newState);
};

const reduceSetPageOffset = (
    state : IAddItemState,
    action : ActionInterfaces.ISetPageOffsetAction
) : IAddItemState => {
    const newState = { ...state };
    newState.pageOffset = action.payload.offset;
    return newState;
};

const reduceSetTotalNumberOfCatalogProducts = (
    state : IAddItemState,
    action : ActionInterfaces.ISetTotalNumberOfCatalogItemsAction
) : IAddItemState => {
    const newState = { ...state };
    newState.totalNumberOfCatalogItems = action.payload.total;
    return newState;
};

const reduceSetTotalNumberOfMyItemsProducts = (
    state : IAddItemState,
    action : ActionInterfaces.ISetTotalNumberOfProducts
) : IAddItemState => {
    const newState = { ...state };
    newState.totalNumberOfProducts = action.payload.total;
    return newState;
};

const reduceSetResultsPerPage = (
    state : IAddItemState,
    action : ActionInterfaces.ISetResultsPerPageAction
) : IAddItemState => {
    const newState = { ...state };
    newState.resultsPerPage = action.payload.resultsPerPage;
    return newState;
};

const reduceSetSelectedProductIds = (
    state : IAddItemState,
    action : ActionInterfaces.ISetSelectedProductIdsAction
) : IAddItemState => {
    const newState = { ...state };
    newState.selections = action.payload.pricedProductPackageRowIdValues;
    return newState;
};

const reduceSetNumberProductsAdded = (
    state : IAddItemState,
    action : ActionInterfaces.ISetNumberProductsAddedAction
) : IAddItemState => {
    const newState = { ...state };
    newState.productsAddedSnackbar.numberEdited = action.payload.numberAdded;
    return newState;
};

const reduceSetAddedProductsNotificationShown = (
    state : IAddItemState,
    action : ActionInterfaces.ISetProductsAddedNotificationShownAction
) : IAddItemState => {
    const newState = { ...state };
    newState.productsAddedSnackbar.isShown = action.payload.isShown;
    return newState;
};

const reduceSetProductIdsLastAddedToEntity = (
    state : IAddItemState,
    action : ActionInterfaces.ISetProductIdsLastAddedToEntityAction
) : IAddItemState => {
    const newState = { ...state };
    newState.productIdsLastAddedToEntity = action.payload.productIdsAdded;
    return newState;
};

const reduceSetSearchBarIsFocused = (
    state : IAddItemState,
    action : ActionInterfaces.ISetSearchBarIsFocusedAction
) : IAddItemState => {
    const newState = { ...state };
    newState.isSearchBarFocused = action.payload.isFocused;
    return newState;
};

const reduceSetSearchSuggestions = (
    state : IAddItemState,
    action : ActionInterfaces.ISetSearchSuggestions
) : IAddItemState => {
    const newState = { ...state };
    newState.searchSuggestions = action.payload.searchSuggestions;
    return newState;
};

const reduceSetHighlightedSearchSuggestion = (
    state : IAddItemState,
    action : ActionInterfaces.ISetHighlightedSearchSuggestion
) : IAddItemState => {
    const newState = { ...state };
    newState.highlightedSearchSuggestion = action.payload.searchSuggestion;
    return newState;
};

const reduceAddCatalogItemsInEntity = (
    state : IAddItemState,
    action : ActionInterfaces.IAddCatalogItemsInEntity
) : IAddItemState => {
    return {
        ...state,
        addedCatalogItemSelections : state.addedCatalogItemSelections.concat(action.payload.catalogItemSelections),
    };
};

const reduceAddCustomCatalogItemOption = (
    state : IAddItemState,
    action : ActionInterfaces.IAddCustomCatalogItemOption,
) : IAddItemState => {
    const {
        catalogItemId,
        catalogItemOption,
    } = action.payload;
    const customOptionsByCatalogItemId = new StringValueMap(state.customOptionsByCatalogItemId);
    const customOptions = state.customOptionsByCatalogItemId.get(catalogItemId) || [];  // ok if key doesn't exist yet
    customOptionsByCatalogItemId.set(catalogItemId, customOptions.concat(catalogItemOption));

    const catalogItemsById = new StringValueMap(state.catalogItemsById);
    const catalogItem = catalogItemsById.get(catalogItemId);
    if (typeof catalogItem === 'undefined') {
        throw new RuntimeException('unknown catalog item id: ' + catalogItemId.getValue());
    }
    catalogItemsById.set(
        catalogItemId,
        new CatalogItem(
            catalogItem.getBrand(),
            catalogItem.getName(),
            catalogItem.getProductCategoryId(),
            catalogItem.getProductType(),
            catalogItem.getOptions().concat(catalogItemOption)
        )
    );

    return {
        ...state,
        catalogItemsById,
        customOptionsByCatalogItemId,
    };
};

const reduceSetComponentIsShownForCatalogItem = (
    state : IAddItemState,
    action : ActionInterfaces.ISetCatalogItemComponentIsShown
) : IAddItemState => {

    // For each displayed catalog item, there is the possibility of showing one or more components.
    // A component is referred to by name and must be one of the items in the CatalogItemComponent type definition.
    // The state object isShownByCatalogItemIdByComponentName is a two-level map that says which components for
    // each catalog item are shown.

    // The incoming action sets whether or not a component referred to by name for a given category item is to be shown.
    // This reducer executes with the following logic:
    //   - The boolean in state.isShownByCatalogItemIdByComponentName given by component name and catalogItemId is
    //     set to the value in the action: action.payload.isShown.
    //   - Since only one instance of a component can be shown at a time, if the action is setting isShown to true
    //     for a component then all other instances of that component must be set to false in the state's map.

    // Fixme: note that currently there is only one component in CatalogItemComponent making this logic actually
    //        unnecessary. Previously there was more than one component and it was easier to not take the logic out.
    //        Also we may decide to add more in the future.

    const isShownByCatalogItemId = new StringValueMap(state.isShownByCatalogItemIdByComponentName[action.payload.component]);

    if (action.payload.isShown) {
        state.isShownByCatalogItemIdByComponentName[action.payload.component].forEach(((value, categoryItemId) => {
            isShownByCatalogItemId.set(categoryItemId, false);
        }));
    }

    isShownByCatalogItemId.set(action.payload.catalogItemId, action.payload.isShown);

    return {
        ...state,
        isShownByCatalogItemIdByComponentName: {
            ...state.isShownByCatalogItemIdByComponentName,
            [action.payload.component] : isShownByCatalogItemId,
        },
    };
};

const reduceSetCatalogItemsById = (
    state : IAddItemState,
    action : ActionInterfaces.ISetCatalogItemsById
) : IAddItemState => {
    return {
        ...state,
        catalogItemsById : action.payload.catalogItemsById,
    };
};

const reduceSetOnGetInitialDataFromContext = (
    state : IAddItemState,
    action : ActionInterfaces.ISetOnGetInitialDataFromContext
) : IAddItemState => {
    return {
        ...state,
        onGetInitialDataFromContext: action.payload.onGetInitialDataFromContext,
    };
};

export const AddItemReducers = (
    state : IAddItemState = initialState,
    action : Action
) : IAddItemState => {
    switch (action.type) {
        case ActionTypes.SET_COMPONENT_IS_SHOWN:
            return reduceSetComponentIsShown(state, action as ActionInterfaces.ISetComponentIsShownAction);
        case ActionTypes.SET_LOADING:
            return reduceSetLoading(
                state,
                action as ISetShownAction
            );
        case ActionTypes.SET_LOADING_SEARCH_RESULTS:
            return reduceSetLoadingSearchResults(
                state,
                action as ActionInterfaces.ISetLoading
            );
        case ActionTypes.SET_DISPLAYED_PRODUCT_IDS:
            return reduceSetDisplayedProductIdsActon(
                state,
                action as ActionInterfaces.ISetDisplayedProductIdsAction
            );
        case ActionTypes.SET_DISPLAYED_CATALOG_ITEM_IDS:
            return reduceSetDisplayedCatalogItemIds(
                state,
                action as ActionInterfaces.ISetDisplayedCatalogItemIds
            );
        case ActionTypes.ADD_PRODUCT_ROW_INFO_BY_PRICED_PRODUCT_ID:
            return reduceAddProductRowInfoByPricedProductId(
                state,
                action as ActionInterfaces.IAddProductRowInfoByPricedProductIdAction
            );
        case ActionTypes.SET_ADD_ITEM_DATA:
            return reduceSetAddItemData(
                state,
                action as ActionInterfaces.ISetAddItemData,
            );
        case ActionTypes.SET_PRODUCT_ROW_EXPANDED:
            return reduceSetProductRowExpanded(
                state,
                action as ActionInterfaces.ISetProductRowExpandedAction
            );
        case ActionTypes.SET_PRODUCT_ROW_SELECTED:
            return reduceSetProductPackageRowSelected(
                state,
                action as ActionInterfaces.ISetProductPackageRowSelected
            );
        case ActionTypes.SET_CATALOG_ROW_SELECTED:
            return reduceSetCatalogOptionRowSelected(
                state,
                action as ActionInterfaces.ISetCatalogOptionRowSelected
            );
        // case ActionTypes.SET_SELECTED_CATALOG_OPTIONS:
        //     return reduceSetSelectedCatalogOptions(
        //         state,
        //         action as ActionInterfaces.ISetSelectedCatalogOptions
        //     );
        case ActionTypes.SET_CLEAR_ALL_SELECTIONS:
            return reduceClearSelections (
                state,
                action as ActionInterfaces.IClearAllSelections
            );
        case ActionTypes.SET_ITEMS_SELECTED_MENU_IS_OPEN:
            return reduceSetItemsSelectedMenuIsOpen(
                state,
                action as ISetShownAction
            );
        case ActionTypes.SET_SEARCH_TERM:
            return reduceSetSearchTerm(
                state,
                action as ActionInterfaces.ISetSearchTermAction
            );
        case ActionTypes.SET_ACTIVE_SEARCH_TERM:
            return reduceSetActiveSearchTerm(
                state,
                action as ActionInterfaces.ISetActiveSearchTermAction
            );
        case ActionTypes.SET_ADD_ITEM_TO_ENTITY_BUTON_TEXT:
            return reduceSetAddItemToEntityButtonText(
                state,
                action as ActionInterfaces.ISetAddItemToEntityButtonTextAction
            );
        case ActionTypes.SET_CONTEXT_TYPE:
            return reduceSetContextType(
                state,
                action as ActionInterfaces.ISetContextTypeAction
            );
        case ActionTypes.ADD_PRODUCT_IDS_TO_PRODUCT_IDS_IN_ENTITY:
            return reduceAddProductIdsToProductIdsInEntity(
                state,
                action as ActionInterfaces.IAddProductIdsToProductIdsInEntityAction
            );
        case ActionTypes.REMOVE_PRODUCT_IDS_FROM_PRODUCT_IDS_IN_ENTITY:
            return reduceRemoveProductIdsFromProductIdsInEntity(
                state,
                action as ActionInterfaces.IRemoveProductIdsFromProductIdsInEntityAction
            );
        case ActionTypes.ADD_CATALOG_ITEMS_IN_ENTITY:
            return reduceAddCatalogItemsInEntity(state, action as ActionInterfaces.IAddCatalogItemsInEntity);
        case ActionTypes.SET_PAGE_OFFSET:
            return reduceSetPageOffset(
                state,
                action as ActionInterfaces.ISetPageOffsetAction
            );
        case ActionTypes.SET_RESULTS_PER_PAGE:
            return reduceSetResultsPerPage(
                state,
                action as ActionInterfaces.ISetResultsPerPageAction
            );
        case ActionTypes.SET_TOTAL_NUMBER_OF_CATALOG_ITEMS:
            return reduceSetTotalNumberOfCatalogProducts(
                state,
                action as ActionInterfaces.ISetTotalNumberOfCatalogItemsAction
            );
        case ActionTypes.SET_TOTAL_NUMBER_OF_PRODUCTS:
            return reduceSetTotalNumberOfMyItemsProducts(
                state,
                action as ActionInterfaces.ISetTotalNumberOfProducts
            );
        case ActionTypes.SET_SELECTED_PRODUCT_IDS:
            return reduceSetSelectedProductIds(
                state,
                action as ActionInterfaces.ISetSelectedProductIdsAction
            );
        case ActionTypes.SET_NUMBER_PRODUCTS_ADDED:
            return reduceSetNumberProductsAdded(
                state,
                action as ActionInterfaces.ISetNumberProductsAddedAction
            );
        case ActionTypes.SET_ADDED_PRODUCTS_NOTIFICATION_SHOWN:
            return reduceSetAddedProductsNotificationShown(
                state,
                action as ActionInterfaces.ISetProductsAddedNotificationShownAction
            );
        case ActionTypes.SET_PRODUCT_IDS_LAST_ADDED_TO_ENTITY:
            return reduceSetProductIdsLastAddedToEntity(
                state,
                action as ActionInterfaces.ISetProductIdsLastAddedToEntityAction
            );
        case ActionTypes.CLEAR_PRODUCT_IDS_IN_ENTITY:
            return reduceClearProductIdsFromEntity(
                state,
                action as ActionInterfaces.IClearProductIdsInEntityAction
            );
        case ActionTypes.SET_SEARCH_BAR_FOCUS:
            return reduceSetSearchBarIsFocused(
                state,
                action as ActionInterfaces.ISetSearchBarIsFocusedAction
            );
        case ActionTypes.SET_SEARCH_SUGGESTIONS:
            return reduceSetSearchSuggestions(state, action as ActionInterfaces.ISetSearchSuggestions);
        case ActionTypes.SET_HIGHLIGHTED_SEARCH_SUGGESTION:
            return reduceSetHighlightedSearchSuggestion(state, action as ActionInterfaces.ISetHighlightedSearchSuggestion);
        case ActionTypes.ADD_CUSTOM_CATALOG_ITEM_OPTION:
            return reduceAddCustomCatalogItemOption(state, action as ActionInterfaces.IAddCustomCatalogItemOption);
        case ActionTypes.SET_CATALOG_ITEM_COMPONENT_IS_SHOWN:
            return reduceSetComponentIsShownForCatalogItem(state, action as ActionInterfaces.ISetCatalogItemComponentIsShown);
        case ActionTypes.SET_CATALOG_ITEMS_BY_ID:
            return reduceSetCatalogItemsById(state, action as ActionInterfaces.ISetCatalogItemsById);
        case ActionTypes.SET_ON_GET_INITIAL_DATA_FROM_CONTEXT:
            return reduceSetOnGetInitialDataFromContext(state, action as ActionInterfaces.ISetOnGetInitialDataFromContext);
        default:
            return state;
    }
};
