import React from 'react';

import { ISearchBarState } from 'shared/models/ISearchBarState';

import { StringValueMap } from 'api/Core/StringValueMap';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';

import { SearchBar, SearchBarTheme } from 'shared/components/SearchBar/SearchBar';
import { SearchResultListTable } from './SearchResultListTable';
import { UnmatchedProductPanel } from './UnmatchedProductPanel';

import { productJSONToObjectSerializer, productObjectToJSONSerializer } from 'shared/lib/manager';
import { OptionsAndLabelNameTuples, Select2DropdownMenu } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { ValidationInput } from 'shared/components/ValidationInput';
import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { Validation } from 'shared/validators/validators';

interface IUnmatchedProductMapperProps {
    readonly locationName : string;
    readonly partnerLocationName : string;
    readonly itemNumber : number;
    readonly count : number;
    readonly currentProductForm : {
        productId : ProductId;
        productQuantityUnit : ProductQuantityUnit;
    };
    readonly productsById : StringValueMap<ProductId, Product>;
    readonly searchBar : ISearchBarState;
    readonly searchBarActions : {
        handleOnChange(searchTerm : string) : void,
        handleBlur(searchTerm : string) : void,
        handleEnterClick(searchTerm : string) : void,
    };
    readonly searchResults : Array<ProductId>;
    readonly selectedProductForm : {
        productId : ProductId | null;
        quantity : {
            value : string;
            isValid : boolean;
            errorMessage : string;
        };
        productQuantityUnit : ProductQuantityUnit;
    };
    readonly addProductToPartnerLocation : ((productId : ProductId) => Promise<any>) | null;
    readonly clearSearchBar : () => void;
    readonly setSelectedProductForm : (productId : ProductId | null, quantity : string, productQuantityUnit : ProductQuantityUnit) => void;
}

export class UnmatchedProductMapper extends React.Component<IUnmatchedProductMapperProps, object> {

    public render() {
        const {
            locationName,
            partnerLocationName,
            itemNumber,
            count,
            clearSearchBar,
            searchBar,
            searchBarActions,
            searchResults,
            currentProductForm,
            selectedProductForm,
            addProductToPartnerLocation,
            productsById,
        } = this.props;

        let selectedProductFormIsValid = false; // TODO Maybe write a validator
        let selectedProduct : Product | null = null;
        const currentProduct = productsById.getRequired(currentProductForm.productId);

        if (selectedProductForm.productId !== null) {
            selectedProduct = productsById.getRequired(selectedProductForm.productId);
            const selectedProductPackaingText = PackagingUtils.getDisplayTextForProductQuantityUnit(selectedProduct.getPackagingsAndMappings(), selectedProductForm.productQuantityUnit, false);
            const currentProductPackagingText = PackagingUtils.getDisplayTextForProductQuantityUnit(currentProduct.getPackagingsAndMappings(), currentProductForm.productQuantityUnit, false);
            
            if (Validation.validateNonNegativeNumber(selectedProductForm.quantity.value) && (parseFloat(selectedProductForm.quantity.value) > 0)) {
                if (PackagingUtils.isProductQuantityUnitCompatibleWithPackagings(selectedProduct.getPackagingsAndMappings(), currentProductForm.productQuantityUnit)) {
                    selectedProductFormIsValid = true;
                }  else if (selectedProductPackaingText === currentProductPackagingText) {
                    selectedProductFormIsValid = true;
                }
            }
        }

        let onProductToPartnerLocationClick : (() => Promise<any>) | null = null;
        if (addProductToPartnerLocation !== null) {
            onProductToPartnerLocationClick = () => {
                return addProductToPartnerLocation(currentProductForm.productId);
            };
        }

        let selectedProductFormUnitSortedOptionsAndLabelName : OptionsAndLabelNameTuples = [];
        let selectedProductFormUnitSelectedIOption : IOption | null = null;

        if (selectedProduct) {
            selectedProductFormUnitSortedOptionsAndLabelName = PackagingUtils.getAvailableUnitOptionsAndLabelNames(selectedProduct.getPackagingsAndMappings(), true);

            const selectedUnit = selectedProductForm.productQuantityUnit;
            if (selectedUnit) {
                selectedProductFormUnitSortedOptionsAndLabelName.forEach((unitOptionsAndLabelName) => {
                    unitOptionsAndLabelName[1].forEach((unitOption : IOption) => {
                        if (unitOption.value === productObjectToJSONSerializer.getProductQuantityUnit(selectedUnit)) {
                            selectedProductFormUnitSelectedIOption = unitOption;
                        }
                    });
                });
            }
        }

        return (
            <div className="ctable unmatched-product-mapper">
                <div>
                    <div className="unmatched-product-mapper-location-bar cell col-xs-6 ellipsis-out"><span><strong>{ locationName }</strong></span></div>
                    <div className="unmatched-product-mapper-location-bar cell col-xs-6 mapper-location-bar-right"><span>{ partnerLocationName }</span></div>
                </div>
                <div className="col-row">
                    <UnmatchedProductPanel
                        currentIndex={ itemNumber }
                        totalProductCount={ count }
                        product={ currentProduct }
                        partnerLocationName={ partnerLocationName }
                        selectedProductFormIsValid={ selectedProductFormIsValid }
                        currentProductForm={ currentProductForm }
                    />
                    <div className="unmatched-product-search cell col-xs-12 col-sm-12 col-md-6">
                        <SearchBar
                            theme={ SearchBarTheme.Default }
                            clearSearchBar={ clearSearchBar }
                            placeholderText={`Search ${partnerLocationName}'s inventory`}
                            value={ searchBar.searchTerm }
                            isDisabled={ searchBar.isDisabled }
                            isFocused={ searchBar.isFocused }
                            handleOnChange={ searchBarActions.handleOnChange }
                            handleBlur={ searchBarActions.handleBlur }
                            handleFocus={ null }
                            handleEnterClick={ searchBarActions.handleEnterClick }
                        />
                        <SearchResultListTable
                            shouldUpdate={ true }
                            matchingProduct={ currentProductForm.productId }
                            addProductToPartnerLocation={ onProductToPartnerLocationClick }
                            addedProductAmounts={ null }
                            sortedProductIds={ searchResults }
                            selectedProduct={ selectedProductForm.productId }
                            selectedProductInvalidMessage={ null }
                            setSelectedProduct={ this.onSetSelectedProduct }
                            searchTerm={ searchBar.searchTerm }
                            productsById={ productsById }
                        />
                        { selectedProduct && !selectedProductFormIsValid &&
                        <>
                            <div className="add-to-list-bar">
                            <p className="warning-message">Mismatched packaging unit types. Please provide a unit.</p>
                                <div className="add-to-list-bar-wrapper">
                                    <div className="col-row">
                                        <div className="cell col-xs-2 cell-pr-sm">
                                            <ValidationInput
                                                key="quantity"
                                                inputClassName=""
                                                type="text"
                                                autoFocus={ false }
                                                autoComplete={ null }
                                                errorMessage={ selectedProductForm.quantity.errorMessage || '' }
                                                isValid={ selectedProductForm.quantity.isValid }
                                                handleBlur={ this.onSelectedProductFormQuantityBlur }
                                                handleFocus={ null }
                                                handleChange={ this.onSelectedProductFormQuantityChange }
                                                handleEnterClick={ this.doNothing }
                                                label="Qty"
                                                hintText={ null }
                                                value={ selectedProductForm.quantity.value }
                                                isDisabled={ selectedProductForm.productId === null }
                                            />
                                        </div>
                                        <div className="cell col-xs-7">
                                            <Select2DropdownMenu
                                                isSearchable={ false }
                                                buttonShouldDisplaySelectedLabel={ true }
                                                selectedOptionHeaderShown={ false }
                                                sortedOptionsAndLabelName={ selectedProductFormUnitSortedOptionsAndLabelName }
                                                selectedOption={ selectedProductFormUnitSelectedIOption }
                                                onOptionSelect={ this.onSelectedProductFormUnitSelect }
                                                placeholderText="Select Unit"
                                                emptyState={ null }
                                                hasCreateCustomOption={ false }
                                                createCustomOption={ this.doNothing }
                                                customOptionGroupLabel={ null }
                                                isDisabled={ selectedProductForm.productId === null }
                                                openDirection="up"
                                                createCustomOptionButtonLabel={ null }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        }
                    </div>
                </div>
            </div>
        );
    }

    private readonly onSetSelectedProduct = (productId : ProductId | null) => {
        const {
            productsById,
            currentProductForm,
            setSelectedProductForm
        } = this.props;

        let newProductQuantityUnit = currentProductForm.productQuantityUnit;

        if (productId) {
            const product = productsById.getRequired(productId);
            if (!PackagingUtils.isProductQuantityUnitCompatibleWithPackagings(product.getPackagingsAndMappings(), newProductQuantityUnit)) {
                newProductQuantityUnit = product.getPackagingsAndMappings().getConversions().getBaseUnit();
            }
        }

        setSelectedProductForm(productId, "1", newProductQuantityUnit);
    }

    private readonly onSelectedProductFormQuantityChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const {
            selectedProductForm,
            setSelectedProductForm
        } = this.props;

        setSelectedProductForm(selectedProductForm.productId, event.currentTarget.value, selectedProductForm.productQuantityUnit);
    }

    private readonly onSelectedProductFormQuantityBlur = (event : React.FocusEvent<HTMLInputElement>) => {
        const {
            selectedProductForm,
            setSelectedProductForm
        } = this.props;

        setSelectedProductForm(selectedProductForm.productId, event.currentTarget.value, selectedProductForm.productQuantityUnit);
    }

    private readonly onSelectedProductFormUnitSelect = (optionValue : string) => {
        const {
            selectedProductForm,
            setSelectedProductForm
        } = this.props;

        const productQuantityUnit = productJSONToObjectSerializer.getProductQuantityUnit(optionValue);
        setSelectedProductForm(selectedProductForm.productId, selectedProductForm.quantity.value, productQuantityUnit);
    }

    private readonly doNothing = () => {
        // Do Nothing
    }
}
