export interface IValidationResult {
    isValid : boolean;
    errorMessage : string | null;
}
export interface IHourInputValidatorArgs {
    value : string;
}
export interface IMinuteInputValidatorArgs {
    value : string;
}
export interface INumberInputValidatorArgs {
    max : number | null;
    min : number | null;
    precision : number | null;
    value : string | null;
}
export const InputValidators = {
    hourInputValidator: (args : IHourInputValidatorArgs) : IValidationResult => {
        const numberValidatorArgs : INumberInputValidatorArgs = {
            max: null,
            min: null,
            precision: 0,
            value: args.value,
        };
        const valueIsNumber : IValidationResult = InputValidators.numberInputValidator(numberValidatorArgs);
        if (!valueIsNumber.isValid) {
            return {
                errorMessage: 'Invalid value',
                isValid: false,
            };
        }
        const valueAsNumber = parseInt(args.value, 10);
        if (valueAsNumber > 12 || valueAsNumber < 1) {
            return {
                errorMessage: 'Invalid value',
                isValid: false,
            };
        }
        return {
            errorMessage: null,
            isValid: true,
        };
    },
    minuteInputValidator: (args : IMinuteInputValidatorArgs) : IValidationResult => {
        const numberValidatorArgs : INumberInputValidatorArgs = {
            max: null,
            min: null,
            precision: 0,
            value: args.value,
        };
        const valueIsNumber : IValidationResult = InputValidators.numberInputValidator(numberValidatorArgs);
        if (!valueIsNumber.isValid) {
            return {
                errorMessage: 'Invalid value',
                isValid: false,
            };
        }
        const valueAsNumber = parseInt(args.value, 10);
        if (valueAsNumber > 59 || valueAsNumber < 0) {
            return {
                errorMessage: 'Invalid value',
                isValid: false,
            };
        }
        return {
            errorMessage: null,
            isValid: true,
        };
    },
    numberInputValidator: (args : INumberInputValidatorArgs) : IValidationResult => {
        const {
            value,
            min,
            max,
            precision,
        } = args;
        if (value === null) {
            throw new Error('Quantity cannot be null');
        }
        if (precision !== null && precision < 0) {
            throw new Error('NumberInput precision must be greater than zero');
        }

        const valueAsNumber = parseFloat(value);
        // Makes sure that only digits and a decimal point exist in value
        const containsOnlyDigitsAndDecimal = /^\d*\.?\d*$/.test(value);
        if (isNaN(valueAsNumber) || !containsOnlyDigitsAndDecimal) {
            return {
                errorMessage: 'Quantity must be a number',
                isValid: false,
            };
        }
        if (min !== null && valueAsNumber < min) {
            return {
                errorMessage: 'Quantity cannot be less than ' + min + '',
                isValid: false,
            };
        }
        if (max !== null && valueAsNumber > max) {
            return {
                errorMessage: 'Quantity cannot be greater than ' + max + '',
                isValid: false,
            };
        }
        if (precision !== null) {
            // TODO: better precision validation?
            const splitValue = value.split('.');
            if (splitValue.length === 2) {
                if (splitValue[1].length > precision) {
                    return {
                        errorMessage: 'Quantity must be to the ' + precision + ' decimal place',
                        isValid: false,
                    };
                }
                if (splitValue[1] === '') {
                    return {
                        errorMessage: 'Quantity must be a number',
                        isValid: false,
                    };
                }
            }
        }
        return {
            errorMessage: null,
            isValid: true,
        };
    },
};
