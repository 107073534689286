import { StringValueMap } from 'api/Core/StringValueMap';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { SalesEntry } from 'api/Reports/model/SalesEntry';
import { getSalesItemReportUnitPrice } from 'api/Reports/salesReportingUtils';
import { PosItem } from 'api/SalesData/model/PosItem';
import { PosItemId } from 'api/SalesData/model/PosItemId';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemWithMetadata } from 'api/SalesItem/model/SalesItemWithMetadata';
import { getNumberForUndefinedOrNullValue } from 'apps/ExpectedInventoryReport/utils/ExpectedInventoryReportUtils';
import { MappedStatus, SalesItemMapperGroupByOptionValue } from 'apps/SalesItemMapper/appTypes';
import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { SalesInputRowId } from 'shared/components/SalesInputTable/SalesInputRow';
import { IColumnSorting } from 'shared/components/SortableColumnHeader';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { ALL_ITEMS_GROUP_NAME } from 'shared/models/GroupByOption';
import { SortDirection } from 'shared/models/SortDirection';
import { DEFAULT_UNASSIGNED_MENU_GROUP, defaultSalesItemRowFilter } from 'shared/utils/salesItemSortingFilteringAndGroupingUtils';
import { cleanPhrase, compareNumbers, SortingFilteringAndGroupingUtil } from 'shared/utils/sortingFilteringAndGroupingUtils';

const UNMAPPED_STATUS_GROUP_NAME = 'Unmapped';
const MAPPED_STATUS_GROUP_NAME = 'Mapped';
const DELETED_STATUS_GROUP_NAME = 'Deleted (ignored in future uploads)';
const mappedStatusGroupSortOrder = [
    UNMAPPED_STATUS_GROUP_NAME,
    MAPPED_STATUS_GROUP_NAME,
    DELETED_STATUS_GROUP_NAME
];

interface ISalesItemMapperSortingUtilExtraArgs {
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>;
    salesBySalesItemId : StringValueMap<SalesItemId, SalesEntry>;
    unmappedSalesByPosItemId : StringValueMap<PosItemId, SalesEntry>;
    posItemsByPosItemId : StringValueMap<PosItemId, PosItem>;
    productsById : StringValueMap<ProductId, Product>;
    isCustomerSuccessTool : boolean; // deleted items are separated out here
}

// these filter functions should ideally behave almost exactly like sales items
// TODOfuture: can we enforce this with code?
export const createPosItemStringForFilter = (posItem : PosItem, posItemId : PosItemId) : string => {
    const name = cleanPhrase(posItem.getName());
    const posId = cleanPhrase(posItemId.getValue());

    const filterStrings : Array<string> = [name, posId];
    return filterStrings.join(' ');
};

const posItemFilterStringCache = new StringValueMap<PosItemId, string>();
let lastPosItemsById = new StringValueMap<PosItemId, PosItem>();

const cachedCreatePosItemStringForFilter = (
    posItemId : PosItemId,
    posItemsById : StringValueMap<PosItemId, PosItem>,
) : string => {

    if (posItemsById !== lastPosItemsById) {
        lastPosItemsById = posItemsById;
        posItemFilterStringCache.clear();
    }

    if (posItemFilterStringCache.has(posItemId)) {
        return posItemFilterStringCache.getRequired(posItemId);
    }

    const posItem = posItemsById.getRequired(posItemId);

    const posItemStringForFilter =  createPosItemStringForFilter(posItem, posItemId);
    posItemFilterStringCache.set(posItemId, posItemStringForFilter);

    return posItemStringForFilter;
};

const defaultPosItemRowFilter = (
    posItemId : PosItemId,
    cleanedFilterTerm : string | null,
    posItemsById : StringValueMap<PosItemId, PosItem>,
) : boolean => {
    if (cleanedFilterTerm === null || cleanedFilterTerm === '') {
        return true;
    }

    const salesItemStringForSearch = cachedCreatePosItemStringForFilter(posItemId, posItemsById);
    const filterTokens = cleanedFilterTerm.split(' ');

    for (let i = 0; i < filterTokens.length; i++) {
        if (salesItemStringForSearch.indexOf(filterTokens[i]) < 0) {
            return false;
        }
    }

    return true;
};

export const salesItemRowFilter = (rowId : SalesInputRowId, filterTerm : string | null, extraArgs : ISalesItemMapperSortingUtilExtraArgs) => {
    if (rowId instanceof SalesItemId) {
        return defaultSalesItemRowFilter(rowId, filterTerm, extraArgs.salesItemsById, extraArgs.productsById);
    } else {
        return defaultPosItemRowFilter(rowId, filterTerm, extraArgs.posItemsByPosItemId);
    }
};

export const salesItemGroupNameComparator = (groupName1 : string, groupName2 : string, groupByOption : SalesItemMapperGroupByOptionValue, extraArgs : ISalesItemMapperSortingUtilExtraArgs) => {
    switch (groupByOption) {
        case 'All Items':
            // should not matter
            return 0;
        case 'Menu Group':
            return cleanPhrase(groupName1).localeCompare(cleanPhrase(groupName2));
        case 'Mapping Status':
            const sortOrder1 = mappedStatusGroupSortOrder.indexOf(groupName1);
            const sortOrder2 = mappedStatusGroupSortOrder.indexOf(groupName2);
            return sortOrder1 - sortOrder2;
        default:
            throw new RuntimeException('unexpected groupByOption');
    }
};

export const salesItemGroupNameGetter = (rowId : SalesInputRowId, groupByOption : SalesItemMapperGroupByOptionValue, extraArgs : ISalesItemMapperSortingUtilExtraArgs) => {
    switch (groupByOption) {
        case 'All Items':
            return ALL_ITEMS_GROUP_NAME;
        case 'Menu Group':
            const menuGroup = getInfoFromSalesInputRowId(
                rowId,
                extraArgs.salesItemsById,
                extraArgs.salesBySalesItemId,
                extraArgs.unmappedSalesByPosItemId,
                extraArgs.posItemsByPosItemId).menuGroup;
            return menuGroup === '' ? DEFAULT_UNASSIGNED_MENU_GROUP : menuGroup;
        case 'Mapping Status':
            const mappedStatus = getInfoFromSalesInputRowId(
                rowId,
                extraArgs.salesItemsById,
                extraArgs.salesBySalesItemId,
                extraArgs.unmappedSalesByPosItemId,
                extraArgs.posItemsByPosItemId,
                extraArgs.isCustomerSuccessTool).mappedStatus;
            switch (mappedStatus) {
                case MappedStatus.MAPPED:
                    return MAPPED_STATUS_GROUP_NAME;
                case MappedStatus.UNMAPPED:
                    return UNMAPPED_STATUS_GROUP_NAME;
                case MappedStatus.DELETED:
                    return DELETED_STATUS_GROUP_NAME;
            }
        default:
            throw new RuntimeException('unexpected groupByOption');
    }
};

export const salesItemRowIdComparator = (rowId1 : SalesInputRowId, rowId2 : SalesInputRowId, columnSort : IColumnSorting, extraArgs : ISalesItemMapperSortingUtilExtraArgs) : number => {
    const item1 = getInfoFromSalesInputRowId(
        rowId1,
        extraArgs.salesItemsById,
        extraArgs.salesBySalesItemId,
        extraArgs.unmappedSalesByPosItemId,
        extraArgs.posItemsByPosItemId);

    const item2 = getInfoFromSalesInputRowId(
        rowId2,
        extraArgs.salesItemsById,
        extraArgs.salesBySalesItemId,
        extraArgs.unmappedSalesByPosItemId,
        extraArgs.posItemsByPosItemId);

    const direction = columnSort.direction;
    const sortedBy = columnSort.sortedBy;

    let comparisonResult : number;
    switch (sortedBy) {
        case 'name':
            // use POS id if two of same name
            const nameString1 = item1.name + ' ' + item1.posId;
            const nameString2 = item2.name + ' ' + item2.posId;
            // comparisonResult = cleanPhrase(nameString1).localeCompare(cleanPhrase(nameString2));
            comparisonResult = nameString1.localeCompare(nameString2); // not using clean phrase speeds this up noticeably...
            break;
        case 'sales_price':
            comparisonResult = compareNumbers(item1.salesPrice, item2.salesPrice);
            break;
        case 'quantity_sold':
            comparisonResult = compareNumbers(
                getNumberForUndefinedOrNullValue(item1.salesEntry ? item1.salesEntry.getQuantity() : null),
                getNumberForUndefinedOrNullValue(item2.salesEntry ? item2.salesEntry.getQuantity() : null),
            );
            break;
        case 'quantity_adjustment':
            comparisonResult = compareNumbers(
                getNumberForUndefinedOrNullValue(item1.salesEntry ? item1.salesEntry.getQuantityAdjustment() : null),
                getNumberForUndefinedOrNullValue(item2.salesEntry ? item2.salesEntry.getQuantityAdjustment() : null),
            );
            break;
        case 'total_sold':
            const totalSold1 = item1.salesEntry ? (item1.salesEntry.getQuantity() || 0) + (item1.salesEntry.getQuantityAdjustment() || 0) : 0;
            const totalSold2 = item2.salesEntry ? (item2.salesEntry.getQuantity() || 0) + (item2.salesEntry.getQuantityAdjustment() || 0) : 0;
            comparisonResult = compareNumbers(totalSold1, totalSold2);
            break;
        case 'dollar_adjustment':
            comparisonResult = compareNumbers(
                getNumberForUndefinedOrNullValue(item1.salesEntry ? item1.salesEntry.getSalesAdjustment() : null),
                getNumberForUndefinedOrNullValue(item2.salesEntry ? item2.salesEntry.getSalesAdjustment() : null),
            );
            break;
        default:
            throw new RuntimeException('unhandled sort field');
    }

    if (direction === SortDirection.DESCENDING) {
        return comparisonResult * -1;
    }

    return comparisonResult;
};

const intermediateResultCacheIsValid = (rowIds : Array<SalesInputRowId>, lastRowIds : Array<SalesInputRowId>, extraArgs : ISalesItemMapperSortingUtilExtraArgs, lastExtraArgs : ISalesItemMapperSortingUtilExtraArgs) : boolean => {
    return (extraArgs.salesItemsById === lastExtraArgs.salesItemsById) &&
            (extraArgs.productsById === lastExtraArgs.productsById) &&
            (extraArgs.salesBySalesItemId === lastExtraArgs.salesBySalesItemId);
};
export const salesItemMapperSortingUtil = new SortingFilteringAndGroupingUtil(
    salesItemRowIdComparator,
    salesItemRowFilter,
    salesItemGroupNameGetter,
    salesItemGroupNameComparator,
    intermediateResultCacheIsValid
);

const getSalesItemMapperGroupByOption = (
    value : SalesItemMapperGroupByOptionValue
) : IOption => ({ value, label: value, icon: null });

const getMappedStatusForSalesItem = (salesItemWithMetadata : SalesItemWithMetadata, separateDeletedItems? : boolean) => {
    if (separateDeletedItems && salesItemWithMetadata.getDeletionMetadata() !== null) {
       return MappedStatus.DELETED; // TODO when omit is implemented: get rid of this from enum
    }

    if (salesItemWithMetadata.getSalesItem().getComponentQuantityOfProductByProductId().size > 0 || salesItemWithMetadata.getSalesItem().getComponentServingsBySalesItemId().size > 0) {
        // any recipe = "mapped"
        return MappedStatus.MAPPED;
    } else {
        return MappedStatus.UNMAPPED;
    }
};

const getInfoFromSalesInputRowId = (
    rowId : SalesInputRowId,
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>,
    salesBySalesItemId : StringValueMap<SalesItemId, SalesEntry>,
    unmappedSalesByPosItemId : StringValueMap<PosItemId, SalesEntry>,
    posItemsByPosItemId : StringValueMap<PosItemId, PosItem>,
    separateDeletedItems? : boolean, // ONLY FOR CS TOOL - separate deleted from mapped/unmapped
) => {
    let salesEntry : SalesEntry | undefined;
    let name : string;
    let posId : string;
    let isFlagged : boolean;
    let mappedStatus : MappedStatus;
    let salesPrice : number;
    let menuGroup : string;
    if (rowId instanceof SalesItemId) {
        const salesItemInfo = salesItemsById.getRequired(rowId);
        const salesItem = salesItemInfo.getSalesItem();
        name = salesItem.getName();
        posId = salesItem.getPOSId();
        menuGroup = salesItem.getMenuGroup();
        isFlagged = salesItem.getNeedsAttentionCategory() ? true : false;
        mappedStatus = SalesItemMapperUtils.getMappedStatusForSalesItem(salesItemInfo, separateDeletedItems);

        salesEntry = salesBySalesItemId.get(rowId);
        salesPrice = getSalesItemReportUnitPrice(salesItem, salesEntry);
    } else {
        const posItem = posItemsByPosItemId.getRequired(rowId);
        name = posItem.getName();
        posId = rowId.getValue();
        menuGroup = posItem.getMenuGroup();
        isFlagged = false;
        mappedStatus = MappedStatus.UNMAPPED;

        salesEntry = unmappedSalesByPosItemId.get(rowId);
        const salesEntryPrice = salesEntry ? salesEntry.getPrice() : null;
        salesPrice = salesEntryPrice === null ? 0 : salesEntryPrice;
    }

    return {
        salesEntry,
        name,
        posId,
        isFlagged,
        mappedStatus,
        salesPrice,
        menuGroup
    };
};

export const SalesItemMapperUtils = {
    getMappedStatusForSalesItem,
    getSalesItemMapperGroupByOption,
    getInfoFromSalesInputRowId,
};
