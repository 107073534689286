import { BreakageReportData } from 'api/Breakage/model/BreakageReportData';
import { ReportMetadata } from 'api/Breakage/model/ReportMetadata';

export class BreakageReport {

    constructor (
        private readonly breakageReportData : BreakageReportData,
        private readonly reportMetaData : ReportMetadata,
    ) {}

    public getBreakageReportData() : BreakageReportData {
        return this.breakageReportData;
    }

    public getReportMetaData() : ReportMetadata {
        return this.reportMetaData;
    }
}
