import React from 'react';

import { IBoundingBox } from 'shared/models/Charts/IBoundingBox';
import { IGridLine } from 'shared/models/Charts/IGridLine';
import { Orientation } from 'shared/models/Charts/Orientation';

import { GridLine } from 'shared/components/Charts/GridLine';

import { utils } from 'shared/components/Charts/utils';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export interface IGridProps {
    readonly horizontalGridLines : ReadonlyArray<IGridLine>;
    readonly verticalGridLines : ReadonlyArray<IGridLine>;
    readonly gapOrientation : Orientation | null;
    readonly boundingBox : IBoundingBox;
}

export class Grid extends React.Component<IGridProps, object> {
    public render() {
        const {
            horizontalGridLines,
            verticalGridLines,
            boundingBox,
        } = this.props;

        utils.ensureBoundingBoxIsValid(boundingBox);

        const horizontalLinesMustBeDrawnFirst : boolean = horizontalGridLines.some(
            (gridLine : IGridLine) => {
                return gridLine.fillColor !== null;
            }
        );

        const verticalLinesMustBeDrawnFirst : boolean = verticalGridLines.some(
            (gridLine : IGridLine) => {
                return gridLine.fillColor !== null;
            }
        );

        if (horizontalLinesMustBeDrawnFirst && verticalLinesMustBeDrawnFirst) {
            throw new RuntimeException('the fillColor from one direction will overlay the gridlines for the other direction');
        }

        return (
            <g
                className="grid-container"
                style={ {
                    pointerEvents: 'none',
                } }
            >
                { this.createGridLines(verticalLinesMustBeDrawnFirst) }
            </g>
        );
    }

    private createGridLines = (
        verticalLinesMustBeDrawnFirst : boolean,
    ) : ReadonlyArray<JSX.Element> | undefined => {
        const {
            horizontalGridLines,
            verticalGridLines,
        } = this.props;

        if (horizontalGridLines.length === 0 && verticalGridLines.length === 0) {
            return;
        }

        let verticalGridLineElements : Array<JSX.Element>;
        if (verticalGridLines.length === 0) {
            verticalGridLineElements = [];
        } else {
            verticalGridLineElements = this.createGridLinesForOrientation(
                verticalGridLines,
                Orientation.VERTICAL);
        }

        let horizontalGridLineElements : Array<JSX.Element>;
        if (horizontalGridLines.length === 0) {
            horizontalGridLineElements = [];
        } else {
            horizontalGridLineElements = this.createGridLinesForOrientation(
                horizontalGridLines,
                Orientation.HORIZONTAL);
        }

        let result : Array<JSX.Element>;
        if (verticalLinesMustBeDrawnFirst) {
            result = verticalGridLineElements.concat(horizontalGridLineElements);
        } else {
            result = horizontalGridLineElements.concat(verticalGridLineElements);
        }

        return result;
    }

    private createGridLinesForOrientation = (
        gridLines : ReadonlyArray<IGridLine>,
        orientation : Orientation,
    ) : Array<JSX.Element> => {
        const {
            gapOrientation,
            boundingBox,
        } = this.props;

        const numberOfGridLines = gridLines.length;

        if (numberOfGridLines === 0) {
            return [];
        }

        const divisor : number = (2 * numberOfGridLines) + 1;

        let heightAdjusmentInPixels : number;
        let widthAdjustmentInPixels : number;
        switch (gapOrientation) {
            case Orientation.HORIZONTAL: {
                heightAdjusmentInPixels = boundingBox.heightInPixels / divisor;
                widthAdjustmentInPixels = 0;

                break;
            }
            case Orientation.VERTICAL: {
                heightAdjusmentInPixels = 0;
                widthAdjustmentInPixels = boundingBox.widthInPixels / divisor;

                break;
            }
            case null: {
                heightAdjusmentInPixels = 0;
                widthAdjustmentInPixels = 0;

                break;
            }
            default: {
                throw new RuntimeException('gapOrientation is unexpected value');
            }
        }

        const keyPrefix = (orientation === Orientation.HORIZONTAL) ? 'horizontal-' : 'vertical-';

        return gridLines.map((gridLine : IGridLine, index : number) => {
            let heightInPixels : number;
            let widthInPixels : number;
            let x : number;
            let y : number;
            switch (orientation) {
                case Orientation.HORIZONTAL: {
                    heightInPixels = (boundingBox.heightInPixels - heightAdjusmentInPixels) / numberOfGridLines;
                    widthInPixels = boundingBox.widthInPixels;
                    x = 0;
                    y = (boundingBox.heightInPixels - heightAdjusmentInPixels) - (heightInPixels * (index + 1));

                    if (index - 1 >= 0) {
                        const previousGridLine : IGridLine = gridLines[index - 1];

                        if (gridLine.fillColor !== null && previousGridLine.fillColor !== null) {
                            if (gridLine.fillColor.getValue() === previousGridLine.fillColor.getValue()) {
                                heightInPixels += 2;
                            }
                        }
                    }

                    break;
                }
                case Orientation.VERTICAL: {
                    heightInPixels = boundingBox.heightInPixels;
                    widthInPixels = (boundingBox.widthInPixels - widthAdjustmentInPixels) / numberOfGridLines;
                    x = (widthInPixels * index) + widthAdjustmentInPixels;
                    y = 0;

                    if (index + 1 < gridLines.length) {
                        const nextGridLine : IGridLine = gridLines[index + 1];

                        if (gridLine.fillColor !== null && nextGridLine.fillColor !== null) {
                            if (gridLine.fillColor.getValue() === nextGridLine.fillColor.getValue()) {
                                widthInPixels += 2;
                            }
                        }
                    }

                    break;
                }
                default: {
                    throw new RuntimeException('orientation is unexpected value');
                }
            }

            return (
                <svg
                    key={ keyPrefix + index }

                    x={ x }
                    y={ y }
                    height={ heightInPixels }
                    width={ widthInPixels }
                >
                    <GridLine
                        orientation={ orientation }
                        fillColor={ gridLine.fillColor }
                        lineType={ gridLine.lineType }
                        boundingBox={ {
                            heightInPixels,
                            widthInPixels,
                        } }
                    />
                </svg>
            );
        });
    }
}
