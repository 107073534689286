import { LocationId } from 'api/Location/model/LocationId';
import { IProductAndCatalogSearchService } from 'api/Search/interfaces/IProductAndCatalogSearchService';
import { ICatalogSearchResult } from 'api/Search/model/ICatalogSearchResult';
import { CatalogJSONToObjectSerializer } from 'api/Search/serializer/CatalogJSONToObjectSerializer';
import { AjaxUtils } from 'shared/utils/ajaxUtils';

export class ProductAndCatalogSearchService implements IProductAndCatalogSearchService {
    constructor (
        private readonly catalogJSONToObjectSerializer : CatalogJSONToObjectSerializer,
    ) {}

    public getSearchSuggestions (locationId : LocationId, searchTerm : string, maxResultCount : number) : Promise<Array<string>> {
        const queryParameters = {
            retailer_id: locationId.getValue(),
            max_result_count: maxResultCount,
            search_term: searchTerm,
        };

        return AjaxUtils.ajaxGet(urlWithoutRetailerId('api:catalog_entry_and_product_search_suggestion'), queryParameters)
        .then((response) => {
            return response.suggestions;
        })
        .catch((error : Error) => {
            return Promise.reject(error);
        });
    }

    public searchCatalogItems(
        maxResultCount : number,
        inclusiveStartIndex : number,
        searchTerm : string,
        filterTermsById : { [filterId : string] : string; }
    ) : Promise<ICatalogSearchResult> {
        const query = {
            max_result_count: maxResultCount,
            inclusive_start_index: inclusiveStartIndex,
            search_term: searchTerm,
            filter_terms_by_id: JSON.stringify(filterTermsById),
        };
        return AjaxUtils.ajaxGet(
            urlWithoutRetailerId('api:catalog_entry'),
            query)
        .then((response) => {
            return this.catalogJSONToObjectSerializer.getCatalogSearchResult(response);
        })
        .catch((error) => Promise.reject(error));
    }
}
