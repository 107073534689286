import { IStringValue } from 'api/Core/IStringValue';

export class InvoiceId implements IStringValue {
    constructor (private readonly value : string) {}

    public getValue() : string {
        return this.value;
    }

    public equals(other : InvoiceId) : boolean {
        return this.getValue() === other.getValue();
    }
}
