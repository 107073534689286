import { Action } from 'redux';

import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';

import { ActionInterfaces, ActionTypes } from './actions';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';

export interface IEditParDialogState {
    count : number | null;
    productQuantityUnit : ProductQuantityUnit;
}

export const initialState : IEditParDialogState = {
    count: null,
    productQuantityUnit: VolumeUnit.MILLILITER,
};

const reduceSetCount = (
    state : IEditParDialogState,
    action : ActionInterfaces.ISetCount
) : IEditParDialogState => {
    return {
        ...state,
        count: action.payload.count,
    };
};

const reduceSetProductQuantityUnit = (
    state : IEditParDialogState,
    action : ActionInterfaces.ISetProductQuantityUnit
) : IEditParDialogState => {
    return {
        ...state,
        productQuantityUnit: action.payload.productQuantityUnit,
    };
};

export const EditParDialogReducers = (
    state : IEditParDialogState = initialState,
    action : Action,
) : IEditParDialogState => {
    switch (action.type) {
        case ActionTypes.SET_COUNT:
            return reduceSetCount(state, action as ActionInterfaces.ISetCount);
        case ActionTypes.SET_PRODUCT_QUANTITY_UNIT:
            return reduceSetProductQuantityUnit(state, action as ActionInterfaces.ISetProductQuantityUnit);
        default:
            return state;
    }
};
