import React from 'react';
import MediaQuery from 'react-responsive';

import { StringValueMap } from 'api/Core/StringValueMap';
import { Distributor } from 'api/Distributor/model/Distributor';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { ProductId } from 'api/Product/model/ProductId';
import { Product } from 'api/Product/model/Product';
import { oldPackagingUtils } from 'api/Product/utils/oldPackagingUtils';
import { CatalogItem } from 'api/Search/model/CatalogItem';
import { CatalogItemId } from 'api/Search/model/CatalogItemId';
import { ICatalogItemOptionRowId } from 'shared/components/AddItem/reducers/addItemReducers';
import { utils } from 'shared/components/AddItem/utils';
import { MAX_TABLET_WIDTH, MIN_DESKTOP_WIDTH } from 'shared/constants';
import { getCurrencySymbol } from 'shared/models/Currency';
import { IPricedProductInfoByProductIdValue, IPricedProductPackageRowId } from '../reducers/addItemReducers';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import '../css/ItemsSelectedMenu.scss';
import { Packaging } from 'api/Product/model/Packaging';

export interface IItemsSelectedMenuProps {
    readonly selections : Array<IPricedProductPackageRowId | ICatalogItemOptionRowId>;
    readonly pricedProductInfoByProductIdValue : IPricedProductInfoByProductIdValue;
    readonly productsById : StringValueMap<ProductId, Product>;
    readonly catalogItemsById : StringValueMap<CatalogItemId, CatalogItem>;
    readonly distributorsById : StringValueMap<DistributorId, Distributor>;
    readonly isExpanded : boolean;
    readonly onToggleMenuExpanded : (isMenuExpanded : boolean) => void;
    readonly removeItem : (selection : IPricedProductPackageRowId | ICatalogItemOptionRowId) => void;
}

export class ItemsSelectedMenu extends React.Component<IItemsSelectedMenuProps, object> {
    private readonly currencySymbol = getCurrencySymbol();

    constructor(props : IItemsSelectedMenuProps) {
        super(props);

        this.handleOnToggleMenuExpanded = this.handleOnToggleMenuExpanded.bind(this);
    }

    public render() {
        const {
            selections,
            isExpanded,
        } = this.props;

        const mobileMenuIsOpen = isExpanded && selections.length > 0;

        return (
            <div className={ `items-selected-menu${selections.length > 0 ? '' : ' empty'}` }>
                <div
                    className="items-selected-menu-header"
                    onClick={ this.handleOnToggleMenuExpanded }
                >
                    <h5 className="items-selected-menu-count col-row">
                        <span className="cell">Selected Items</span>
                        { selections.length > 0 &&
                            <span className="items-selected-menu-number right dark cell">
                                { selections.length }
                            </span>
                        }
                    </h5>
                    <MediaQuery maxWidth={ MAX_TABLET_WIDTH }>
                        <span className={ 'bevicon bevico-keyboard-arrow-' + (mobileMenuIsOpen ? 'up' : 'down') } />
                    </MediaQuery>
                </div>
                <MediaQuery minWidth={ MIN_DESKTOP_WIDTH }>
                    { selections.length === 0 &&
                        <div className="items-selected-menu-row-container-empty col-xs-12">
                            <div className="items-selected-menu-no-items-selected">
                                <span className="bevicon bevico-bottle" />
                                <span className="bevicon bevico-bag" />
                            </div>
                            <p>No items have been selected yet</p>
                            <p>Find items you stock at your bar or restaurant in the Catalog and select them to add them to your account</p>
                        </div>
                    }
                    { selections.length > 0 &&
                        <div className="items-selected-menu-row-container">
                            { this.getItemRows() }
                        </div>
                    }
                </MediaQuery>
                <MediaQuery maxWidth={ MAX_TABLET_WIDTH }>
                    { mobileMenuIsOpen &&
                        <div className="items-selected-menu-row-container">
                            { this.getItemRows() }
                        </div>
                    }
                </MediaQuery>
            </div>
        );
    }

    private readonly getItemRows = () => {
        const {
            selections,
            pricedProductInfoByProductIdValue,
            productsById,
            catalogItemsById,
            distributorsById,
            removeItem,
        } = this.props;

        return selections.map((selection) => {
            let key : string;
            let brand : string;
            let name : string;
            let packaging : Packaging;
            let price : number;
            let distributor : Distributor | null;

            if (utils.isCatalogItemOptionRowId(selection)) {
                key = selection.catalogItemId.getValue() + '-' + selection.optionIndex;
                const catalogItem = catalogItemsById.get(selection.catalogItemId);
                if (typeof catalogItem === 'undefined') {
                    throw new RuntimeException('unknown catalog item id: ' + selection.catalogItemId.getValue());
                }

                brand = catalogItem.getBrand();
                name = catalogItem.getName();
                packaging = catalogItem.getOptions()[selection.optionIndex].packaging;
                distributor = null;
                price = 0;
            } else {
                key = selection.pricedProductIdValue + '-' + selection.productPackageRowInfoAttribute;
                const pricedProductInfo = pricedProductInfoByProductIdValue[selection.pricedProductIdValue];
                if (typeof pricedProductInfo === 'undefined') {
                    throw new RuntimeException('priced product info not found in pricedProductInfoByProductIdValue: ' + selection);
                }
                const productPackageRowInfo = pricedProductInfo.productPackageRowInfoByAttribute[selection.productPackageRowInfoAttribute];
                if (typeof productPackageRowInfo === 'undefined') {
                    throw new RuntimeException('product package row info not found in productPackageRowInfoByAttribute: ' + selection);
                }

                const productId = new ProductId(selection.pricedProductIdValue);
                const product = productsById.getRequired(productId);

                brand = product.getBrand();
                name = product.getName();
                packaging = product.getPackagingsAndMappings().getPackaging();

                if (productPackageRowInfo.distributorId !== null) {
                    distributor = distributorsById.get(productPackageRowInfo.distributorId) || null;
                } else {
                    distributor = null;
                }

                price = productPackageRowInfo.price;
            }

            const onRemoveItem = () => removeItem(selection);

            return (
                <div key={ key } className="items-selected-menu-row">
                    <div className="ellipsis-out">
                        <div className="product-brand cell-ptb-xs">{ brand }</div>
                        <strong className="product-name cell-ptb-xs">{ name }</strong>
                    </div>
                    <div className="product-package-info cell-ptb-xs">
                        <small className="ellipsis-out">{ oldPackagingUtils.getDisplayTextForPackaging(oldPackagingUtils.getOldPackagingFromPackaging(packaging), true) }</small>
                        { !utils.isCatalogItemOptionRowId(selection) && (
                            <div>
                                <div className="distributor-info distributor-price ellipsis-out">{ this.currencySymbol + price.toFixed(2) }</div>
                                <div className="distributor-info distributor-name ellipsis-out">{ distributor !== null ? distributor.getName() : 'Other' }</div>
                            </div>
                        ) }
                    </div>
                    <button className="btn flat" onClick={ onRemoveItem }>
                        <span className="bevicon bevico-remove-circle-outline"/>
                    </button>
                </div>
            );
        });
    }

    private handleOnToggleMenuExpanded(event : React.FormEvent<HTMLDivElement>) {
        this.props.onToggleMenuExpanded(!this.props.isExpanded);
    }
}
