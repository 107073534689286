import { LocationId } from 'api/Location/model/LocationId';
import LocationModel from 'gen-thrift/location_Model_types';

export class LocationObjectToThriftSerializer {

    ////////////////////////////////////
    // CORE MODELS
    ////////////////////////////////////
    public getThriftLocationId(
        locationId : LocationId,
    ) : LocationModel.LocationIdentifier {
        return new LocationModel.LocationIdentifier({
            value: locationId.getValue(),
        });
    }

    ////////////////////////////////////
    // MISC SERIALIZERS
    ////////////////////////////////////
}
