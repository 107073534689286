//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


import Thrift from 'thrift';
import InventoryModel from './inventory_Model_types';
import InventoryExceptions from './inventory_Exceptions_types';
import ProductModel from './product_Model_types';
import ProductExceptions from './product_Exceptions_types';
import ProductAmountExceptions from './product_amount_Exceptions_types';
import LocationModel from './location_Model_types';
import LocationExceptions from './location_Exceptions_types';
import CoreTimeModel from './core_time_Model_types';
import CoreMathModel from './core_math_Model_types';
import UserAccountsModel from './accounts_user_Model_types';
import AccountsExceptions from './accounts_Exceptions_types';
import CoreApiExceptions from './core_api_Exceptions_types';
import MoneyModel from './money_Model_types';

var InventoryCurrentConfigurationService = {};
export default InventoryCurrentConfigurationService;
//HELPER FUNCTIONS AND STRUCTURES

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_getCurrentInventoryConfigurationForLocation_args = function(args) {
  this.actor = null;
  this.locationId = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
  }
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_getCurrentInventoryConfigurationForLocation_args.prototype = {};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_getCurrentInventoryConfigurationForLocation_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_getCurrentInventoryConfigurationForLocation_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCurrentConfigurationService_getCurrentInventoryConfigurationForLocation_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 2);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_getCurrentInventoryConfigurationForLocation_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new InventoryModel.InventoryConfiguration(args.success);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
  }
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_getCurrentInventoryConfigurationForLocation_result.prototype = {};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_getCurrentInventoryConfigurationForLocation_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new InventoryModel.InventoryConfiguration();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_getCurrentInventoryConfigurationForLocation_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCurrentConfigurationService_getCurrentInventoryConfigurationForLocation_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_updateStorageArea_args = function(args) {
  this.actor = null;
  this.storageAreaId = null;
  this.storageAreaName = null;
  this.storageAreaNote = null;
  this.clientTimestamp = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.storageAreaId !== undefined && args.storageAreaId !== null) {
      this.storageAreaId = new InventoryModel.StorageAreaId(args.storageAreaId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field storageAreaId is unset!');
    }
    if (args.storageAreaName !== undefined && args.storageAreaName !== null) {
      this.storageAreaName = args.storageAreaName;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field storageAreaName is unset!');
    }
    if (args.storageAreaNote !== undefined && args.storageAreaNote !== null) {
      this.storageAreaNote = args.storageAreaNote;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field storageAreaNote is unset!');
    }
    if (args.clientTimestamp !== undefined && args.clientTimestamp !== null) {
      this.clientTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision(args.clientTimestamp);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field clientTimestamp is unset!');
    }
  }
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_updateStorageArea_args.prototype = {};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_updateStorageArea_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.storageAreaId = new InventoryModel.StorageAreaId();
        this.storageAreaId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.storageAreaName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.storageAreaNote = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.clientTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.clientTimestamp.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_updateStorageArea_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCurrentConfigurationService_updateStorageArea_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.storageAreaId !== null && this.storageAreaId !== undefined) {
    output.writeFieldBegin('storageAreaId', Thrift.Type.STRUCT, 2);
    this.storageAreaId.write(output);
    output.writeFieldEnd();
  }
  if (this.storageAreaName !== null && this.storageAreaName !== undefined) {
    output.writeFieldBegin('storageAreaName', Thrift.Type.STRING, 3);
    output.writeString(this.storageAreaName);
    output.writeFieldEnd();
  }
  if (this.storageAreaNote !== null && this.storageAreaNote !== undefined) {
    output.writeFieldBegin('storageAreaNote', Thrift.Type.STRING, 4);
    output.writeString(this.storageAreaNote);
    output.writeFieldEnd();
  }
  if (this.clientTimestamp !== null && this.clientTimestamp !== undefined) {
    output.writeFieldBegin('clientTimestamp', Thrift.Type.STRUCT, 5);
    this.clientTimestamp.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_updateStorageArea_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownStorageAreaIdException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof InventoryExceptions.UnknownStorageAreaIdException) {
    this.unknownStorageAreaIdException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownStorageAreaIdException !== undefined && args.unknownStorageAreaIdException !== null) {
      this.unknownStorageAreaIdException = args.unknownStorageAreaIdException;
    }
  }
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_updateStorageArea_result.prototype = {};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_updateStorageArea_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownStorageAreaIdException = new InventoryExceptions.UnknownStorageAreaIdException();
        this.unknownStorageAreaIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_updateStorageArea_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCurrentConfigurationService_updateStorageArea_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownStorageAreaIdException !== null && this.unknownStorageAreaIdException !== undefined) {
    output.writeFieldBegin('unknownStorageAreaIdException', Thrift.Type.STRUCT, 5);
    this.unknownStorageAreaIdException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_createAndAddStorageArea_args = function(args) {
  this.actor = null;
  this.storageAreaName = null;
  this.storageAreaNote = null;
  this.locationId = null;
  this.clientTimestamp = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.storageAreaName !== undefined && args.storageAreaName !== null) {
      this.storageAreaName = args.storageAreaName;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field storageAreaName is unset!');
    }
    if (args.storageAreaNote !== undefined && args.storageAreaNote !== null) {
      this.storageAreaNote = args.storageAreaNote;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field storageAreaNote is unset!');
    }
    if (args.locationId !== undefined && args.locationId !== null) {
      this.locationId = new LocationModel.LocationIdentifier(args.locationId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field locationId is unset!');
    }
    if (args.clientTimestamp !== undefined && args.clientTimestamp !== null) {
      this.clientTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision(args.clientTimestamp);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field clientTimestamp is unset!');
    }
  }
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_createAndAddStorageArea_args.prototype = {};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_createAndAddStorageArea_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.storageAreaName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.storageAreaNote = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.locationId = new LocationModel.LocationIdentifier();
        this.locationId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.clientTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.clientTimestamp.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_createAndAddStorageArea_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCurrentConfigurationService_createAndAddStorageArea_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.storageAreaName !== null && this.storageAreaName !== undefined) {
    output.writeFieldBegin('storageAreaName', Thrift.Type.STRING, 2);
    output.writeString(this.storageAreaName);
    output.writeFieldEnd();
  }
  if (this.storageAreaNote !== null && this.storageAreaNote !== undefined) {
    output.writeFieldBegin('storageAreaNote', Thrift.Type.STRING, 3);
    output.writeString(this.storageAreaNote);
    output.writeFieldEnd();
  }
  if (this.locationId !== null && this.locationId !== undefined) {
    output.writeFieldBegin('locationId', Thrift.Type.STRUCT, 4);
    this.locationId.write(output);
    output.writeFieldEnd();
  }
  if (this.clientTimestamp !== null && this.clientTimestamp !== undefined) {
    output.writeFieldBegin('clientTimestamp', Thrift.Type.STRUCT, 5);
    this.clientTimestamp.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_createAndAddStorageArea_result = function(args) {
  this.success = null;
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownLocationIdentifierException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof LocationExceptions.UnknownLocationIdentifierException) {
    this.unknownLocationIdentifierException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new InventoryModel.StorageAreaId(args.success);
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownLocationIdentifierException !== undefined && args.unknownLocationIdentifierException !== null) {
      this.unknownLocationIdentifierException = args.unknownLocationIdentifierException;
    }
  }
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_createAndAddStorageArea_result.prototype = {};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_createAndAddStorageArea_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new InventoryModel.StorageAreaId();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownLocationIdentifierException = new LocationExceptions.UnknownLocationIdentifierException();
        this.unknownLocationIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_createAndAddStorageArea_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCurrentConfigurationService_createAndAddStorageArea_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownLocationIdentifierException !== null && this.unknownLocationIdentifierException !== undefined) {
    output.writeFieldBegin('unknownLocationIdentifierException', Thrift.Type.STRUCT, 5);
    this.unknownLocationIdentifierException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_removeStorageArea_args = function(args) {
  this.actor = null;
  this.storageAreaId = null;
  this.clientTimestamp = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.storageAreaId !== undefined && args.storageAreaId !== null) {
      this.storageAreaId = new InventoryModel.StorageAreaId(args.storageAreaId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field storageAreaId is unset!');
    }
    if (args.clientTimestamp !== undefined && args.clientTimestamp !== null) {
      this.clientTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision(args.clientTimestamp);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field clientTimestamp is unset!');
    }
  }
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_removeStorageArea_args.prototype = {};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_removeStorageArea_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.storageAreaId = new InventoryModel.StorageAreaId();
        this.storageAreaId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.clientTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.clientTimestamp.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_removeStorageArea_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCurrentConfigurationService_removeStorageArea_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.storageAreaId !== null && this.storageAreaId !== undefined) {
    output.writeFieldBegin('storageAreaId', Thrift.Type.STRUCT, 2);
    this.storageAreaId.write(output);
    output.writeFieldEnd();
  }
  if (this.clientTimestamp !== null && this.clientTimestamp !== undefined) {
    output.writeFieldBegin('clientTimestamp', Thrift.Type.STRUCT, 3);
    this.clientTimestamp.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_removeStorageArea_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownStorageAreaIdException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof InventoryExceptions.UnknownStorageAreaIdException) {
    this.unknownStorageAreaIdException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownStorageAreaIdException !== undefined && args.unknownStorageAreaIdException !== null) {
      this.unknownStorageAreaIdException = args.unknownStorageAreaIdException;
    }
  }
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_removeStorageArea_result.prototype = {};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_removeStorageArea_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownStorageAreaIdException = new InventoryExceptions.UnknownStorageAreaIdException();
        this.unknownStorageAreaIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_removeStorageArea_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCurrentConfigurationService_removeStorageArea_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownStorageAreaIdException !== null && this.unknownStorageAreaIdException !== undefined) {
    output.writeFieldBegin('unknownStorageAreaIdException', Thrift.Type.STRUCT, 5);
    this.unknownStorageAreaIdException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_addProductToStorageArea_args = function(args) {
  this.actor = null;
  this.productId = null;
  this.storageAreaId = null;
  this.clientTimestamp = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.productId !== undefined && args.productId !== null) {
      this.productId = new ProductModel.ProductId(args.productId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productId is unset!');
    }
    if (args.storageAreaId !== undefined && args.storageAreaId !== null) {
      this.storageAreaId = new InventoryModel.StorageAreaId(args.storageAreaId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field storageAreaId is unset!');
    }
    if (args.clientTimestamp !== undefined && args.clientTimestamp !== null) {
      this.clientTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision(args.clientTimestamp);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field clientTimestamp is unset!');
    }
  }
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_addProductToStorageArea_args.prototype = {};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_addProductToStorageArea_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.productId = new ProductModel.ProductId();
        this.productId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.storageAreaId = new InventoryModel.StorageAreaId();
        this.storageAreaId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.clientTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.clientTimestamp.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_addProductToStorageArea_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCurrentConfigurationService_addProductToStorageArea_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.productId !== null && this.productId !== undefined) {
    output.writeFieldBegin('productId', Thrift.Type.STRUCT, 2);
    this.productId.write(output);
    output.writeFieldEnd();
  }
  if (this.storageAreaId !== null && this.storageAreaId !== undefined) {
    output.writeFieldBegin('storageAreaId', Thrift.Type.STRUCT, 3);
    this.storageAreaId.write(output);
    output.writeFieldEnd();
  }
  if (this.clientTimestamp !== null && this.clientTimestamp !== undefined) {
    output.writeFieldBegin('clientTimestamp', Thrift.Type.STRUCT, 5);
    this.clientTimestamp.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_addProductToStorageArea_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownProductIdException = null;
  this.unknownStorageAreaIdException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof InventoryExceptions.UnknownStorageAreaIdException) {
    this.unknownStorageAreaIdException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.unknownStorageAreaIdException !== undefined && args.unknownStorageAreaIdException !== null) {
      this.unknownStorageAreaIdException = args.unknownStorageAreaIdException;
    }
  }
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_addProductToStorageArea_result.prototype = {};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_addProductToStorageArea_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownStorageAreaIdException = new InventoryExceptions.UnknownStorageAreaIdException();
        this.unknownStorageAreaIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_addProductToStorageArea_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCurrentConfigurationService_addProductToStorageArea_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 5);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownStorageAreaIdException !== null && this.unknownStorageAreaIdException !== undefined) {
    output.writeFieldBegin('unknownStorageAreaIdException', Thrift.Type.STRUCT, 6);
    this.unknownStorageAreaIdException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_removeProductFromStorageArea_args = function(args) {
  this.actor = null;
  this.productId = null;
  this.storageAreaId = null;
  this.clientTimestamp = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.productId !== undefined && args.productId !== null) {
      this.productId = new ProductModel.ProductId(args.productId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productId is unset!');
    }
    if (args.storageAreaId !== undefined && args.storageAreaId !== null) {
      this.storageAreaId = new InventoryModel.StorageAreaId(args.storageAreaId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field storageAreaId is unset!');
    }
    if (args.clientTimestamp !== undefined && args.clientTimestamp !== null) {
      this.clientTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision(args.clientTimestamp);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field clientTimestamp is unset!');
    }
  }
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_removeProductFromStorageArea_args.prototype = {};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_removeProductFromStorageArea_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.productId = new ProductModel.ProductId();
        this.productId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.storageAreaId = new InventoryModel.StorageAreaId();
        this.storageAreaId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.clientTimestamp = new CoreTimeModel.TimestampWithMillisecondPrecision();
        this.clientTimestamp.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_removeProductFromStorageArea_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCurrentConfigurationService_removeProductFromStorageArea_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.productId !== null && this.productId !== undefined) {
    output.writeFieldBegin('productId', Thrift.Type.STRUCT, 2);
    this.productId.write(output);
    output.writeFieldEnd();
  }
  if (this.storageAreaId !== null && this.storageAreaId !== undefined) {
    output.writeFieldBegin('storageAreaId', Thrift.Type.STRUCT, 3);
    this.storageAreaId.write(output);
    output.writeFieldEnd();
  }
  if (this.clientTimestamp !== null && this.clientTimestamp !== undefined) {
    output.writeFieldBegin('clientTimestamp', Thrift.Type.STRUCT, 5);
    this.clientTimestamp.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_removeProductFromStorageArea_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownProductIdException = null;
  this.unknownStorageAreaIdException = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof InventoryExceptions.UnknownStorageAreaIdException) {
    this.unknownStorageAreaIdException = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.unknownStorageAreaIdException !== undefined && args.unknownStorageAreaIdException !== null) {
      this.unknownStorageAreaIdException = args.unknownStorageAreaIdException;
    }
  }
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_removeProductFromStorageArea_result.prototype = {};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_removeProductFromStorageArea_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownStorageAreaIdException = new InventoryExceptions.UnknownStorageAreaIdException();
        this.unknownStorageAreaIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_removeProductFromStorageArea_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCurrentConfigurationService_removeProductFromStorageArea_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 5);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownStorageAreaIdException !== null && this.unknownStorageAreaIdException !== undefined) {
    output.writeFieldBegin('unknownStorageAreaIdException', Thrift.Type.STRUCT, 6);
    this.unknownStorageAreaIdException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_setStorageAreaIndexAfter_args = function(args) {
  this.actor = null;
  this.storageAreaId = null;
  this.storageAreaIdPreceeding = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.storageAreaId !== undefined && args.storageAreaId !== null) {
      this.storageAreaId = new InventoryModel.StorageAreaId(args.storageAreaId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field storageAreaId is unset!');
    }
    if (args.storageAreaIdPreceeding !== undefined && args.storageAreaIdPreceeding !== null) {
      this.storageAreaIdPreceeding = new InventoryModel.StorageAreaId(args.storageAreaIdPreceeding);
    }
  }
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_setStorageAreaIndexAfter_args.prototype = {};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_setStorageAreaIndexAfter_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.storageAreaId = new InventoryModel.StorageAreaId();
        this.storageAreaId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.storageAreaIdPreceeding = new InventoryModel.StorageAreaId();
        this.storageAreaIdPreceeding.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_setStorageAreaIndexAfter_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCurrentConfigurationService_setStorageAreaIndexAfter_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.storageAreaId !== null && this.storageAreaId !== undefined) {
    output.writeFieldBegin('storageAreaId', Thrift.Type.STRUCT, 2);
    this.storageAreaId.write(output);
    output.writeFieldEnd();
  }
  if (this.storageAreaIdPreceeding !== null && this.storageAreaIdPreceeding !== undefined) {
    output.writeFieldBegin('storageAreaIdPreceeding', Thrift.Type.STRUCT, 3);
    this.storageAreaIdPreceeding.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_setStorageAreaIndexAfter_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownStorageAreaIdExceptions = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof InventoryExceptions.UnknownStorageAreaIdException) {
    this.unknownStorageAreaIdExceptions = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownStorageAreaIdExceptions !== undefined && args.unknownStorageAreaIdExceptions !== null) {
      this.unknownStorageAreaIdExceptions = args.unknownStorageAreaIdExceptions;
    }
  }
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_setStorageAreaIndexAfter_result.prototype = {};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_setStorageAreaIndexAfter_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownStorageAreaIdExceptions = new InventoryExceptions.UnknownStorageAreaIdException();
        this.unknownStorageAreaIdExceptions.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_setStorageAreaIndexAfter_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCurrentConfigurationService_setStorageAreaIndexAfter_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownStorageAreaIdExceptions !== null && this.unknownStorageAreaIdExceptions !== undefined) {
    output.writeFieldBegin('unknownStorageAreaIdExceptions', Thrift.Type.STRUCT, 5);
    this.unknownStorageAreaIdExceptions.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_setProductAtStorageAreaIndexAfter_args = function(args) {
  this.actor = null;
  this.productId = null;
  this.productIdPreceeding = null;
  this.storageAreaId = null;
  this.inventoryCountId = null;
  if (args) {
    if (args.actor !== undefined && args.actor !== null) {
      this.actor = new UserAccountsModel.UserSessionIdentifier(args.actor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field actor is unset!');
    }
    if (args.productId !== undefined && args.productId !== null) {
      this.productId = new ProductModel.ProductId(args.productId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field productId is unset!');
    }
    if (args.productIdPreceeding !== undefined && args.productIdPreceeding !== null) {
      this.productIdPreceeding = new ProductModel.ProductId(args.productIdPreceeding);
    }
    if (args.storageAreaId !== undefined && args.storageAreaId !== null) {
      this.storageAreaId = new InventoryModel.StorageAreaId(args.storageAreaId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field storageAreaId is unset!');
    }
    if (args.inventoryCountId !== undefined && args.inventoryCountId !== null) {
      this.inventoryCountId = new InventoryModel.InventoryCountId(args.inventoryCountId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field inventoryCountId is unset!');
    }
  }
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_setProductAtStorageAreaIndexAfter_args.prototype = {};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_setProductAtStorageAreaIndexAfter_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.actor = new UserAccountsModel.UserSessionIdentifier();
        this.actor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.productId = new ProductModel.ProductId();
        this.productId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.productIdPreceeding = new ProductModel.ProductId();
        this.productIdPreceeding.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.storageAreaId = new InventoryModel.StorageAreaId();
        this.storageAreaId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.inventoryCountId = new InventoryModel.InventoryCountId();
        this.inventoryCountId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_setProductAtStorageAreaIndexAfter_args.prototype.write = function(output) {
  output.writeStructBegin('InventoryCurrentConfigurationService_setProductAtStorageAreaIndexAfter_args');
  if (this.actor !== null && this.actor !== undefined) {
    output.writeFieldBegin('actor', Thrift.Type.STRUCT, 1);
    this.actor.write(output);
    output.writeFieldEnd();
  }
  if (this.productId !== null && this.productId !== undefined) {
    output.writeFieldBegin('productId', Thrift.Type.STRUCT, 2);
    this.productId.write(output);
    output.writeFieldEnd();
  }
  if (this.productIdPreceeding !== null && this.productIdPreceeding !== undefined) {
    output.writeFieldBegin('productIdPreceeding', Thrift.Type.STRUCT, 3);
    this.productIdPreceeding.write(output);
    output.writeFieldEnd();
  }
  if (this.storageAreaId !== null && this.storageAreaId !== undefined) {
    output.writeFieldBegin('storageAreaId', Thrift.Type.STRUCT, 4);
    this.storageAreaId.write(output);
    output.writeFieldEnd();
  }
  if (this.inventoryCountId !== null && this.inventoryCountId !== undefined) {
    output.writeFieldBegin('inventoryCountId', Thrift.Type.STRUCT, 5);
    this.inventoryCountId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_setProductAtStorageAreaIndexAfter_result = function(args) {
  this.rateLimitExceededException = null;
  this.serviceTemporarilyUnavailableException = null;
  this.unknownSessionIdentifierException = null;
  this.accountDoesNotHavePermissionException = null;
  this.unknownProductIdException = null;
  this.unknownStorageAreaIdExceptions = null;
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownSessionIdentifierException) {
    this.unknownSessionIdentifierException = args;
    return;
  }
  if (args instanceof AccountsExceptions.AccountDoesNotHavePermissionException) {
    this.accountDoesNotHavePermissionException = args;
    return;
  }
  if (args instanceof ProductExceptions.UnknownProductIdException) {
    this.unknownProductIdException = args;
    return;
  }
  if (args instanceof InventoryExceptions.UnknownStorageAreaIdException) {
    this.unknownStorageAreaIdExceptions = args;
    return;
  }
  if (args) {
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.unknownSessionIdentifierException !== undefined && args.unknownSessionIdentifierException !== null) {
      this.unknownSessionIdentifierException = args.unknownSessionIdentifierException;
    }
    if (args.accountDoesNotHavePermissionException !== undefined && args.accountDoesNotHavePermissionException !== null) {
      this.accountDoesNotHavePermissionException = args.accountDoesNotHavePermissionException;
    }
    if (args.unknownProductIdException !== undefined && args.unknownProductIdException !== null) {
      this.unknownProductIdException = args.unknownProductIdException;
    }
    if (args.unknownStorageAreaIdExceptions !== undefined && args.unknownStorageAreaIdExceptions !== null) {
      this.unknownStorageAreaIdExceptions = args.unknownStorageAreaIdExceptions;
    }
  }
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_setProductAtStorageAreaIndexAfter_result.prototype = {};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_setProductAtStorageAreaIndexAfter_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownSessionIdentifierException = new AccountsExceptions.UnknownSessionIdentifierException();
        this.unknownSessionIdentifierException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.accountDoesNotHavePermissionException = new AccountsExceptions.AccountDoesNotHavePermissionException();
        this.accountDoesNotHavePermissionException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownProductIdException = new ProductExceptions.UnknownProductIdException();
        this.unknownProductIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownStorageAreaIdExceptions = new InventoryExceptions.UnknownStorageAreaIdException();
        this.unknownStorageAreaIdExceptions.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_setProductAtStorageAreaIndexAfter_result.prototype.write = function(output) {
  output.writeStructBegin('InventoryCurrentConfigurationService_setProductAtStorageAreaIndexAfter_result');
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 1);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 2);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownSessionIdentifierException !== null && this.unknownSessionIdentifierException !== undefined) {
    output.writeFieldBegin('unknownSessionIdentifierException', Thrift.Type.STRUCT, 3);
    this.unknownSessionIdentifierException.write(output);
    output.writeFieldEnd();
  }
  if (this.accountDoesNotHavePermissionException !== null && this.accountDoesNotHavePermissionException !== undefined) {
    output.writeFieldBegin('accountDoesNotHavePermissionException', Thrift.Type.STRUCT, 4);
    this.accountDoesNotHavePermissionException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownProductIdException !== null && this.unknownProductIdException !== undefined) {
    output.writeFieldBegin('unknownProductIdException', Thrift.Type.STRUCT, 5);
    this.unknownProductIdException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownStorageAreaIdExceptions !== null && this.unknownStorageAreaIdExceptions !== undefined) {
    output.writeFieldBegin('unknownStorageAreaIdExceptions', Thrift.Type.STRUCT, 6);
    this.unknownStorageAreaIdExceptions.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient = function(input, output) {
    this.input = input;
    this.output = (!output) ? input : output;
    this.seqid = 0;
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype = {};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.getCurrentInventoryConfigurationForLocation = function(actor, locationId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_getCurrentInventoryConfigurationForLocation(pendingError, actor, locationId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_getCurrentInventoryConfigurationForLocation();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.send_getCurrentInventoryConfigurationForLocation = function(pendingError, actor, locationId, callback) {
  this.output.writeMessageBegin('getCurrentInventoryConfigurationForLocation', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_getCurrentInventoryConfigurationForLocation_args();
  args.actor = actor;
  args.locationId = locationId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_getCurrentInventoryConfigurationForLocation() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.recv_getCurrentInventoryConfigurationForLocation = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_getCurrentInventoryConfigurationForLocation_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'getCurrentInventoryConfigurationForLocation failed: unknown result';
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.updateStorageArea = function(actor, storageAreaId, storageAreaName, storageAreaNote, clientTimestamp, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_updateStorageArea(pendingError, actor, storageAreaId, storageAreaName, storageAreaNote, clientTimestamp, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_updateStorageArea();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.send_updateStorageArea = function(pendingError, actor, storageAreaId, storageAreaName, storageAreaNote, clientTimestamp, callback) {
  this.output.writeMessageBegin('updateStorageArea', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_updateStorageArea_args();
  args.actor = actor;
  args.storageAreaId = storageAreaId;
  args.storageAreaName = storageAreaName;
  args.storageAreaNote = storageAreaNote;
  args.clientTimestamp = clientTimestamp;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_updateStorageArea() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.recv_updateStorageArea = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_updateStorageArea_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownStorageAreaIdException) {
    throw result.unknownStorageAreaIdException;
  }
  return;
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.createAndAddStorageArea = function(actor, storageAreaName, storageAreaNote, locationId, clientTimestamp, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_createAndAddStorageArea(pendingError, actor, storageAreaName, storageAreaNote, locationId, clientTimestamp, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_createAndAddStorageArea();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.send_createAndAddStorageArea = function(pendingError, actor, storageAreaName, storageAreaNote, locationId, clientTimestamp, callback) {
  this.output.writeMessageBegin('createAndAddStorageArea', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_createAndAddStorageArea_args();
  args.actor = actor;
  args.storageAreaName = storageAreaName;
  args.storageAreaNote = storageAreaNote;
  args.locationId = locationId;
  args.clientTimestamp = clientTimestamp;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_createAndAddStorageArea() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.recv_createAndAddStorageArea = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_createAndAddStorageArea_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownLocationIdentifierException) {
    throw result.unknownLocationIdentifierException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'createAndAddStorageArea failed: unknown result';
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.removeStorageArea = function(actor, storageAreaId, clientTimestamp, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_removeStorageArea(pendingError, actor, storageAreaId, clientTimestamp, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_removeStorageArea();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.send_removeStorageArea = function(pendingError, actor, storageAreaId, clientTimestamp, callback) {
  this.output.writeMessageBegin('removeStorageArea', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_removeStorageArea_args();
  args.actor = actor;
  args.storageAreaId = storageAreaId;
  args.clientTimestamp = clientTimestamp;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_removeStorageArea() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.recv_removeStorageArea = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_removeStorageArea_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownStorageAreaIdException) {
    throw result.unknownStorageAreaIdException;
  }
  return;
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.addProductToStorageArea = function(actor, productId, storageAreaId, clientTimestamp, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_addProductToStorageArea(pendingError, actor, productId, storageAreaId, clientTimestamp, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_addProductToStorageArea();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.send_addProductToStorageArea = function(pendingError, actor, productId, storageAreaId, clientTimestamp, callback) {
  this.output.writeMessageBegin('addProductToStorageArea', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_addProductToStorageArea_args();
  args.actor = actor;
  args.productId = productId;
  args.storageAreaId = storageAreaId;
  args.clientTimestamp = clientTimestamp;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_addProductToStorageArea() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.recv_addProductToStorageArea = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_addProductToStorageArea_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.unknownStorageAreaIdException) {
    throw result.unknownStorageAreaIdException;
  }
  return;
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.removeProductFromStorageArea = function(actor, productId, storageAreaId, clientTimestamp, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_removeProductFromStorageArea(pendingError, actor, productId, storageAreaId, clientTimestamp, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_removeProductFromStorageArea();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.send_removeProductFromStorageArea = function(pendingError, actor, productId, storageAreaId, clientTimestamp, callback) {
  this.output.writeMessageBegin('removeProductFromStorageArea', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_removeProductFromStorageArea_args();
  args.actor = actor;
  args.productId = productId;
  args.storageAreaId = storageAreaId;
  args.clientTimestamp = clientTimestamp;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_removeProductFromStorageArea() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.recv_removeProductFromStorageArea = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_removeProductFromStorageArea_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.unknownStorageAreaIdException) {
    throw result.unknownStorageAreaIdException;
  }
  return;
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.setStorageAreaIndexAfter = function(actor, storageAreaId, storageAreaIdPreceeding, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_setStorageAreaIndexAfter(pendingError, actor, storageAreaId, storageAreaIdPreceeding, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_setStorageAreaIndexAfter();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.send_setStorageAreaIndexAfter = function(pendingError, actor, storageAreaId, storageAreaIdPreceeding, callback) {
  this.output.writeMessageBegin('setStorageAreaIndexAfter', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_setStorageAreaIndexAfter_args();
  args.actor = actor;
  args.storageAreaId = storageAreaId;
  args.storageAreaIdPreceeding = storageAreaIdPreceeding;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_setStorageAreaIndexAfter() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.recv_setStorageAreaIndexAfter = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_setStorageAreaIndexAfter_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownStorageAreaIdExceptions) {
    throw result.unknownStorageAreaIdExceptions;
  }
  return;
};
InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.setProductAtStorageAreaIndexAfter = function(actor, productId, productIdPreceeding, storageAreaId, inventoryCountId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_setProductAtStorageAreaIndexAfter(pendingError, actor, productId, productIdPreceeding, storageAreaId, inventoryCountId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_setProductAtStorageAreaIndexAfter();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.send_setProductAtStorageAreaIndexAfter = function(pendingError, actor, productId, productIdPreceeding, storageAreaId, inventoryCountId, callback) {
  this.output.writeMessageBegin('setProductAtStorageAreaIndexAfter', Thrift.MessageType.CALL, this.seqid);
  var args = new InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_setProductAtStorageAreaIndexAfter_args();
  args.actor = actor;
  args.productId = productId;
  args.productIdPreceeding = productIdPreceeding;
  args.storageAreaId = storageAreaId;
  args.inventoryCountId = inventoryCountId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_setProductAtStorageAreaIndexAfter() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient.prototype.recv_setProductAtStorageAreaIndexAfter = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new InventoryCurrentConfigurationService.InventoryCurrentConfigurationService_setProductAtStorageAreaIndexAfter_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.unknownSessionIdentifierException) {
    throw result.unknownSessionIdentifierException;
  }
  if (null !== result.accountDoesNotHavePermissionException) {
    throw result.accountDoesNotHavePermissionException;
  }
  if (null !== result.unknownProductIdException) {
    throw result.unknownProductIdException;
  }
  if (null !== result.unknownStorageAreaIdExceptions) {
    throw result.unknownStorageAreaIdExceptions;
  }
  return;
};
