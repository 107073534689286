import { MassUnit } from 'api/Product/model/MassUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';

export class PackagingWeight {
    constructor(
        private readonly emptyWeight : QuantityInUnit<MassUnit> | null,
        private readonly fullWeight : QuantityInUnit<MassUnit> | null,
    ) {}

    public getEmptyWeight() {
        return this.emptyWeight;
    }

    public getFullWeight() {
        return this.fullWeight;
    }

    public equals(other : any) : boolean {
        if (!(other instanceof PackagingWeight)) {
            return false;
        }

        if (((this.emptyWeight === other.getEmptyWeight()) || (this.emptyWeight && other.getEmptyWeight() && this.emptyWeight.equals(other.getEmptyWeight()))) &&
            ((this.fullWeight === other.getFullWeight()) || (this.fullWeight && other.getFullWeight() && this.fullWeight.equals(other.getFullWeight())))
        ) {
            return true;
        }

        return false;
    }
}
