export enum GroupByOption {
    ALL_ITEMS = 'ALL_ITEMS',
    DISTRIBUTOR = 'DISTRIBUTOR',
    CATEGORY = 'CATEGORY',
    ITEM_TYPE = 'ITEM_TYPE',
    LAST_EDITED = 'LAST_EDITED',
    GL_CODE = 'GL_CODE',
    MENU_GROUP = 'MENU_GROUP'
}

export const ALL_ITEMS_GROUP_NAME = 'All Items';
