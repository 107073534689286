import { LocationId } from 'api/Location/model/LocationId';
import { ProductQuickAddJSONToObjectSerializer } from 'api/Onboarding/serializer/ProductQuickAddJSONToObjectSerializer';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';

import { IProductQuickAddService } from '../interfaces/IProductQuickAddService';
import { ProductQuickAdd } from '../model/ProductQuickAdd';

import { AjaxUtils } from 'shared/utils/ajaxUtils';

export class ProductQuickAddServiceImpl implements IProductQuickAddService {

    constructor (
        private readonly productQuickAddJsonToObjectSerializer : ProductQuickAddJSONToObjectSerializer
    ) { }

    public getProductQuickAddData (
        userSessionId : UserSessionId,
    ) : Promise<Array<ProductQuickAdd>> {
        const urlValue = url('account:get_product_quick_add_data', null, null, null);
        return AjaxUtils.ajaxGet(urlValue)
        .then((response) => {
            return Promise.resolve(this.productQuickAddJsonToObjectSerializer.getProductQuickAddData(response.quick_add_data));
        });
    }

    public setProductQuickAddCompleted(
        userSessionId : UserSessionId,
        locationId : LocationId,
    ) : Promise<void> {
        return AjaxUtils.ajaxPost(url('account:set_product_quick_add_completed', null, locationId.getValue(), null), {})
        .then((response) => {
            return Promise.resolve();
        });
    }

    public getHasCompletedProductQuickAdd(
        userSessionId : UserSessionId,
        locationId : LocationId,
    ) : Promise<boolean>  {
        return AjaxUtils.ajaxPost(url('account:get_has_completed_product_quick_add', null, locationId.getValue(), null), {})
        .then((response) => {
            return Promise.resolve(response.has_completed_product_quick_add);
        });
    }
}
