import PrepEventModel from 'gen-thrift/prep_event_Model_types';

import { StringValueSet } from 'api/Core/StringValueSet';
import { PrepEventId } from 'api/PrepEvent/model/PrepEventId';

export class PrepEventObjectToThriftSerializer {

    ////////////////////////////////////
    // MODELS
    ////////////////////////////////////

    public getThriftPrepEventId(
        prepEventId : PrepEventId,
    ) : PrepEventModel.PrepEventId {
        return new PrepEventModel.PrepEventId({ value : prepEventId.getValue() });
    }

    ////////////////////////////////////
    // COLLECTIONS
    ////////////////////////////////////

    public getThriftPrepEventIds(
        prepEventIds : StringValueSet<PrepEventId>
    ) : Array<PrepEventModel.PrepEventId> {
        return Array.from(prepEventIds.values()).map((prepEventId) => {
            return this.getThriftPrepEventId(prepEventId);
        });
    }
}
