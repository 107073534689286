import { DayOfWeekName } from './DayOfWeekName';

export interface ICalendarDay {
    numericalDayOfMonth : number;
    dayOfWeekName : DayOfWeekName;
}

export class CalendarDay {
    private numericalDayOfMonth : number;
    private dayOfWeekName : DayOfWeekName;
    constructor(args : ICalendarDay) {
        this.numericalDayOfMonth = args.numericalDayOfMonth;
        this.dayOfWeekName = args.dayOfWeekName;
    }
    public getNumericalDayOfMonth = () : number => {
        return this.numericalDayOfMonth;
    }
    public getDayOfWeekName = () : DayOfWeekName => {
        return this.dayOfWeekName;
    }
}
