//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';



var CoreTimeModel = {};
export default CoreTimeModel;
CoreTimeModel.Milliseconds = function(args) {
  this.value = null;
  if (args) {
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field value is unset!');
    }
  }
};
CoreTimeModel.Milliseconds.prototype = {};
CoreTimeModel.Milliseconds.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.value = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CoreTimeModel.Milliseconds.prototype.write = function(output) {
  output.writeStructBegin('Milliseconds');
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.I64, 1);
    output.writeI64(this.value);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

CoreTimeModel.Seconds = function(args) {
  this.value = null;
  if (args) {
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field value is unset!');
    }
  }
};
CoreTimeModel.Seconds.prototype = {};
CoreTimeModel.Seconds.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.value = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CoreTimeModel.Seconds.prototype.write = function(output) {
  output.writeStructBegin('Seconds');
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.I64, 1);
    output.writeI64(this.value);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

CoreTimeModel.TimestampWithMillisecondPrecision = function(args) {
  this.timeSinceUnixEpoch = null;
  if (args) {
    if (args.timeSinceUnixEpoch !== undefined && args.timeSinceUnixEpoch !== null) {
      this.timeSinceUnixEpoch = new CoreTimeModel.Milliseconds(args.timeSinceUnixEpoch);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field timeSinceUnixEpoch is unset!');
    }
  }
};
CoreTimeModel.TimestampWithMillisecondPrecision.prototype = {};
CoreTimeModel.TimestampWithMillisecondPrecision.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.timeSinceUnixEpoch = new CoreTimeModel.Milliseconds();
        this.timeSinceUnixEpoch.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CoreTimeModel.TimestampWithMillisecondPrecision.prototype.write = function(output) {
  output.writeStructBegin('TimestampWithMillisecondPrecision');
  if (this.timeSinceUnixEpoch !== null && this.timeSinceUnixEpoch !== undefined) {
    output.writeFieldBegin('timeSinceUnixEpoch', Thrift.Type.STRUCT, 1);
    this.timeSinceUnixEpoch.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

CoreTimeModel.TimestampWithSecondPrecision = function(args) {
  this.timeSinceUnixEpoch = null;
  if (args) {
    if (args.timeSinceUnixEpoch !== undefined && args.timeSinceUnixEpoch !== null) {
      this.timeSinceUnixEpoch = new CoreTimeModel.Seconds(args.timeSinceUnixEpoch);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field timeSinceUnixEpoch is unset!');
    }
  }
};
CoreTimeModel.TimestampWithSecondPrecision.prototype = {};
CoreTimeModel.TimestampWithSecondPrecision.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.timeSinceUnixEpoch = new CoreTimeModel.Seconds();
        this.timeSinceUnixEpoch.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CoreTimeModel.TimestampWithSecondPrecision.prototype.write = function(output) {
  output.writeStructBegin('TimestampWithSecondPrecision');
  if (this.timeSinceUnixEpoch !== null && this.timeSinceUnixEpoch !== undefined) {
    output.writeFieldBegin('timeSinceUnixEpoch', Thrift.Type.STRUCT, 1);
    this.timeSinceUnixEpoch.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

