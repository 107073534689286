import { StringValueMap } from 'api/Core/StringValueMap';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { SalesItem } from 'api/SalesItem/model/SalesItem';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesItemWithMetadata } from 'api/SalesItem/model/SalesItemWithMetadata';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { ALL_ITEMS_GROUP_NAME, GroupByOption } from 'shared/models/GroupByOption';
import { cleanPhrase } from 'shared/utils/sortingFilteringAndGroupingUtils';
import { getDefaultTimestampGroupName, getTimestampGroupComparator } from 'shared/utils/timestampSortingFilteringAndGroupingUtils';

export enum SalesItemGroupByOption {
    ALL_ITEMS = 'ALL_ITEMS',
    MENU_GROUP = 'MENU_GROUP',
    LAST_EDITED = 'LAST_EDITED'
}

export const DEFAULT_UNASSIGNED_MENU_GROUP = 'Unassigned';

/*
Commonly used filtering/grouping/sorting logic for sales item tables. Has similarities to the product util.
 */
export const createSalesItemStringForFilter = (salesItem : SalesItem, salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>, productsById : StringValueMap<ProductId, Product>) : string => {
    // TODO product decision: legacy filter is name, pos id, all ingredients -- do we want to change this?

    const name = cleanPhrase(salesItem.getName());
    const posId = cleanPhrase(salesItem.getPOSId());

    const filterStrings : Array<string> = [name, posId];

    salesItem.getComponentQuantityOfProductByProductId().forEach((quantityInUnit, productId) => {
        const product = productsById.get(productId);
        if (typeof product === 'undefined') {
            throw new RuntimeException('unexpected');
        }
        const productBrandAndName = cleanPhrase(product.getBrand() + ' ' + product.getName());
        filterStrings.push(productBrandAndName);
    });

    salesItem.getComponentServingsBySalesItemId().forEach((quantity, salesItemId) => {
        const salesItemIngredient = salesItemsById.get(salesItemId);
        if (typeof salesItemIngredient === 'undefined') {
            throw new RuntimeException('unexpected');
        }
        const ingredientName = cleanPhrase(salesItemIngredient.getSalesItem().getName());
        filterStrings.push(ingredientName);
    });

    return filterStrings.join(' ');
};

const salesItemFilterStringCache = new StringValueMap<SalesItemId, string>();
let lastSalesItemsById = new StringValueMap<SalesItemId, SalesItemWithMetadata>();
let lastProductsById = new StringValueMap<ProductId, Product>();

const cachedCreateSalesItemStringForFilter = (
    salesItemId : SalesItemId,
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>,
    productsById : StringValueMap<ProductId, Product>,
) : string => {

    if (productsById !== lastProductsById) {
        lastProductsById = productsById;
        salesItemFilterStringCache.clear();
    }
    if (salesItemsById !== lastSalesItemsById) {
        // performance: only clear the cache of items that have changed. if a linked item has changed
        // this may not quite update the search, but speeds up performance in a significant way.
        salesItemsById.forEach((newSalesItemWithMetadata, newSalesItemId) => {
            if (lastSalesItemsById.get(newSalesItemId) !== salesItemsById.get(newSalesItemId)) {
                salesItemFilterStringCache.delete(newSalesItemId);
            }
        });
        lastSalesItemsById = salesItemsById;
    }

    if (salesItemFilterStringCache.has(salesItemId)) {
        const cacheValue  = salesItemFilterStringCache.get(salesItemId);

        if (typeof cacheValue === 'undefined') {
            throw new RuntimeException('unexpected');
        }

        return cacheValue;
    }

    const salesItemWithMetadata = salesItemsById.get(salesItemId);
    if (typeof salesItemWithMetadata === 'undefined') {
        throw new RuntimeException('unexpected');
    }

    const salesItemStringForFilter = createSalesItemStringForFilter(salesItemWithMetadata.getSalesItem(), salesItemsById, productsById);
    salesItemFilterStringCache.set(salesItemId, salesItemStringForFilter);

    return salesItemStringForFilter;
};

export const defaultSalesItemRowFilter = (
    salesItemId : SalesItemId,
    cleanedFilterTerm : string | null,
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>,
    productsById : StringValueMap<ProductId, Product>,
) : boolean => {
    if (cleanedFilterTerm === null || cleanedFilterTerm === '') {
        return true;
    }

    const salesItemStringForSearch = cachedCreateSalesItemStringForFilter(salesItemId, salesItemsById, productsById);
    const filterTokens = cleanedFilterTerm.split(' ');

    for (let i = 0; i < filterTokens.length; i++) {
        if (salesItemStringForSearch.indexOf(filterTokens[i]) < 0) {
            return false;
        }
    }

    return true;
};

// group name based on SalesItemGroupByOption
export const getDefaultGroupNameForSalesItemRow = (
    salesItemId : SalesItemId,
    salesItemsById : StringValueMap<SalesItemId, SalesItemWithMetadata>,
    productsById : StringValueMap<ProductId, Product>,
    groupByOption : GroupByOption
) : string => {
    const salesItemWithMetadata = salesItemsById.get(salesItemId);
    if (typeof salesItemWithMetadata === 'undefined') {
        throw new RuntimeException('unexpected');
    }

    switch (groupByOption) {
        case GroupByOption.ALL_ITEMS:
            return ALL_ITEMS_GROUP_NAME;
        case GroupByOption.MENU_GROUP:
            const menuGroup = salesItemWithMetadata.getSalesItem().getMenuGroup();
            return menuGroup === '' ? DEFAULT_UNASSIGNED_MENU_GROUP : menuGroup;
        case GroupByOption.LAST_EDITED: // TODO think about caching category and subcategory...
            const lastEditedMetadata = salesItemWithMetadata.getLastEditedMetadata();
            const lastEditedTimestamp = lastEditedMetadata ? lastEditedMetadata.getTimestamp() : salesItemWithMetadata.getCreationMetadata().getTimestamp();

            return getDefaultTimestampGroupName(lastEditedTimestamp);
        default:
            throw new RuntimeException('unexpected groupByOption');
    }
};

// group name comparator based on SalesItemGroupByOption
export const defaultGroupNameComparator = (groupName1 : string, groupName2 : string, groupByOption : GroupByOption) : number => {
    switch (groupByOption) {
        case GroupByOption.ALL_ITEMS:
            // should not matter
            return 0;
        case GroupByOption.MENU_GROUP:
            return cleanPhrase(groupName1).localeCompare(cleanPhrase(groupName2));
        case GroupByOption.LAST_EDITED:
            return getTimestampGroupComparator(groupName1, groupName2);
        default:
            throw new RuntimeException('unexpected groupByOption');
    }
};
