import moment from 'moment-timezone';
import React from 'react';

import { BevSpotDateTimePickerType } from 'shared/components/BevSpotDateTimePicker/BevSpotDateTimePicker';
import { Button } from 'shared/components/Button';
import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { OutsideEventListenerContainer } from 'shared/components/OutsideEventListenerContainer';
import { OptionsAndLabelNameTuples, Select2DropdownMenu } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { Timezone } from 'shared/models/DateTime';
import { getDateTimeFromMoment } from 'shared/utils/dateTimeUtils';
import {
    getEndOfDayForRetailerInUTC,
    getStartDateFromDateRangeOptionForRetailerInUTC,
    getStartOfDayForRetailerInUTC
} from './DateRangeDropdownUtils';
import { ConnectedDateRangePicker } from './DateRangePicker';

import './DateRangeDropdown.scss';

export enum DateRangeDropdownOption {
    LAST_7_DAYS = 'LAST_7_DAYS',
    LAST_4_WEEKS = 'LAST_4_WEEKS',
    LAST_8_WEEKS = 'LAST_8_WEEKS',
    LAST_MONTH = 'LAST_MONTH',
    LAST_3_MONTHS = 'LAST_3_MONTHS',
    LAST_6_MONTHS = 'LAST_6_MONTHS',
    LAST_YEAR = 'LAST_YEAR',
    CUSTOM = 'CUSTOM',
}

export const DefaultDateRangeOptionsForReports = [
    DateRangeDropdownOption.LAST_7_DAYS,
    DateRangeDropdownOption.LAST_4_WEEKS,
    DateRangeDropdownOption.LAST_8_WEEKS,
    DateRangeDropdownOption.CUSTOM,
];

const defaultDropdownOptionsByValue : { [key in DateRangeDropdownOption] : IOption } = {
    LAST_7_DAYS: {
        icon : null,
        label : 'Last 7 days',
        value : DateRangeDropdownOption.LAST_7_DAYS,
    },
    LAST_4_WEEKS: {
        icon : null,
        label : 'Last 4 weeks',
        value : DateRangeDropdownOption.LAST_4_WEEKS,
    },
    LAST_8_WEEKS: {
        icon : null,
        label : 'Last 8 weeks',
        value : DateRangeDropdownOption.LAST_8_WEEKS,
    },
    LAST_MONTH: {
        icon : null,
        label : 'Last Month',
        value : DateRangeDropdownOption.LAST_MONTH,
    },
    LAST_3_MONTHS: {
        icon : null,
        label : 'Last 3 Months',
        value : DateRangeDropdownOption.LAST_3_MONTHS,
    },
    LAST_6_MONTHS: {
        icon : null,
        label : 'Last 6 Months',
        value : DateRangeDropdownOption.LAST_6_MONTHS,
    },
    LAST_YEAR: {
        icon : null,
        label : 'Last Year',
        value : DateRangeDropdownOption.LAST_YEAR,
    },
    CUSTOM: {
        icon : 'bevico-arrow-forward',
        label : 'Choose Dates',
        value : DateRangeDropdownOption.CUSTOM,
    }
};

const CURRENT_CUSTOM_OPTION = 'CURRENT_CUSTOM_OPTION';

export interface IDateRangeDropdownProps {
    options : Array<DateRangeDropdownOption>;   // can be a sub-set of all possible options
    setDropdownOptionsShown : (isShown : boolean) => void;
    setSelectedOption : (groupByOption : DateRangeDropdownOption, startDate : moment.Moment, endDate : moment.Moment) => void;
    dropdownOptionsShown : boolean;
    selectedOptionValue : DateRangeDropdownOption;
    onSetDateRangePickerShown : (isShown : boolean) => void;
    dateRangeUpperBoundForNonCustomOption : moment.Moment;

    // date range picker
    dateRangePickerIsOpen : boolean;
    shouldShowTime : boolean;
    initialStartDate : moment.Moment;
    startDatePickerId : string;
    initialEndDate : moment.Moment;
    endDatePickerId : string;
    dropdownStyle : string;
}

export class DateRangeDropdown extends React.Component<IDateRangeDropdownProps, object> {
    private readonly timezone = parseInt(Timezone[window.GLOBAL_RETAILER_TIME_ZONE as any], 10) as Timezone;

    public render() {
        const {
            options,
            selectedOptionValue,
            dateRangePickerIsOpen,
            startDatePickerId,
            endDatePickerId,
            initialStartDate,
            initialEndDate,
            shouldShowTime,
            dropdownStyle
        } = this.props;

        const dropdownOptions : Array<IOption> = [];
        let selectedOption : IOption | undefined;
        options.forEach((optionValue) => {
            let optionToAdd : IOption;
            if (optionValue === DateRangeDropdownOption.CUSTOM && selectedOptionValue === DateRangeDropdownOption.CUSTOM) {
                const currentOptionLabel = `${ initialStartDate.tz(window.GLOBAL_RETAILER_TIME_ZONE).format('MM/DD/YYYY') }-${ initialEndDate.tz(window.GLOBAL_RETAILER_TIME_ZONE).format('MM/DD/YYYY') }`;
                const extraOptionForCurrentRange = {
                    icon : null,
                    label : currentOptionLabel,
                    value : CURRENT_CUSTOM_OPTION,
                };
                dropdownOptions.push(extraOptionForCurrentRange);
                selectedOption = extraOptionForCurrentRange;
                dropdownOptions.push(defaultDropdownOptionsByValue[DateRangeDropdownOption.CUSTOM]);
            } else {
                optionToAdd = defaultDropdownOptionsByValue[optionValue];
                if (optionValue === selectedOptionValue) {
                    selectedOption = optionToAdd;
                }
                dropdownOptions.push(optionToAdd);
            }
        });
        if (typeof selectedOption === 'undefined') {
            throw new RuntimeException('unexpected dropdown option: ' + selectedOptionValue);
        }

        const sortedOptionsAndLabelName : OptionsAndLabelNameTuples = [[null, dropdownOptions]];

        const dateRangePickerHeader = (
            <div className="date-range-picker-header">
                <Button
                    onClick={ this.handleOnExitDateRangeSelector }
                    buttonClassName="flat with-icon"
                    isDisabled={ false }
                    isLoading={ false }
                >
                    <span className="main-icon-left bevicon bevico-arrow-back"/>
                    <span>Close date range</span>
                </Button>
            </div>
        );

        return (
            <div className={ `${ dropdownStyle } date-range-dropdown` }>
                <Select2DropdownMenu
                    onOptionSelect={ this.onOptionClick }
                    selectedOption={ selectedOption }
                    isSearchable={ false }
                    selectedOptionHeaderShown={ false }
                    sortedOptionsAndLabelName={ sortedOptionsAndLabelName }
                    placeholderText={ null }
                    emptyState={ null }
                    buttonShouldDisplaySelectedLabel={ true }
                    hasCreateCustomOption={ false }
                    createCustomOption={ this.doNothingFunction }
                    customOptionGroupLabel={ null }
                    createCustomOptionButtonLabel={ null }
                />
                { dateRangePickerIsOpen &&
                    <OutsideEventListenerContainer
                        onOutsideClick={ this.handleOnExitDateRangeSelector }
                    >
                        <ConnectedDateRangePicker
                            startDatePickerProps={ {
                                buttons: [],
                                initialDateTime: getDateTimeFromMoment(initialStartDate, this.timezone),
                                setDateTime: this.doNothingFunction,
                                type: BevSpotDateTimePickerType.EMBEDDED,
                                uniqueId: startDatePickerId,
                                shouldShowTime,
                            } }
                            endDatePickerProps={ {
                                buttons: [],
                                initialDateTime: getDateTimeFromMoment(initialEndDate, this.timezone),
                                setDateTime: this.doNothingFunction,
                                type: BevSpotDateTimePickerType.EMBEDDED,
                                uniqueId: endDatePickerId,
                                shouldShowTime,
                            } }
                            onConfirmDateRange={ this.handleOnConfirmDateRange }
                            onExitDateRangeSelector={ this.handleOnExitDateRangeSelector }
                            headerElement={ dateRangePickerHeader }
                            bodyElement={ null }
                            confirmButtonText="Confirm"
                        />
                    </OutsideEventListenerContainer>
                }
            </div>
        );
    }

    private readonly doNothingFunction = () => undefined;

    private readonly onOptionClick = (optionValue : string) => {
        const {
            setDropdownOptionsShown,
            setSelectedOption,
            onSetDateRangePickerShown,
            dateRangeUpperBoundForNonCustomOption,
        } = this.props;
        if (optionValue === CURRENT_CUSTOM_OPTION) {
            return; // this is not a valid option (so don't want to select it) but we want it on a different line from "choose dates"
        }

        const selectedGroupByOption = optionValue as DateRangeDropdownOption;

        if (selectedGroupByOption === DateRangeDropdownOption.CUSTOM) {
            onSetDateRangePickerShown(true);
        } else {
            const startDate = getStartDateFromDateRangeOptionForRetailerInUTC(selectedGroupByOption, moment.utc(), window.GLOBAL_RETAILER_TIME_ZONE);
            setSelectedOption(selectedGroupByOption, startDate, dateRangeUpperBoundForNonCustomOption);
        }

        setDropdownOptionsShown(false);
    }

    private readonly handleOnConfirmDateRange = (startDate : moment.Moment, endDate : moment.Moment) => {
        const {
            shouldShowTime,
            onSetDateRangePickerShown,
            setSelectedOption,
        } = this.props;
        onSetDateRangePickerShown(false);
        if (shouldShowTime) {
            setSelectedOption(DateRangeDropdownOption.CUSTOM, startDate, endDate);
        } else {
            setSelectedOption(DateRangeDropdownOption.CUSTOM, getStartOfDayForRetailerInUTC(startDate, window.GLOBAL_RETAILER_TIME_ZONE), getEndOfDayForRetailerInUTC(endDate, window.GLOBAL_RETAILER_TIME_ZONE)
            );
        }
    }

    private readonly handleOnExitDateRangeSelector = () => {
        this.props.setDropdownOptionsShown(true);
        this.props.onSetDateRangePickerShown(false);
    }
}
