import React from 'react';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import { ProductAmount } from 'api/Product/model/ProductAmount';
import { StringValueMap } from 'api/Core/StringValueMap';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { IFormValue } from 'shared/models/FormValue';
import { OrderedProductAmountList } from '../models/OrderedProductAmountList';
import { TransferListRow } from './TransferListRow';

interface ITransferListTable {
    orderedProductAmountList : OrderedProductAmountList;
    productsById : StringValueMap<ProductId, Product>;
    orderedProductAmountQuantityByListElementIdForm : { [listElementId : string] : IFormValue<string> };
    validateQuantity(addedItemId : string, quantity : string) : void;
    setQuantity(addedItemId : string, quantity : string) : void;
    removeRow(addedItemId : string, productId : ProductId) : void;
}
export class TransferListTable extends React.Component<ITransferListTable, object> {
    public render() {
        const {
            productsById,
            orderedProductAmountList,
            orderedProductAmountQuantityByListElementIdForm,
        } = this.props;

        const listElementIds = orderedProductAmountList ? orderedProductAmountList.getListElementIds() : [];
        const productAmountsByListElementId = orderedProductAmountList ? orderedProductAmountList.getItemByListElementId() : {};

        const tableRows : Array<JSX.Element> = [];
        listElementIds.forEach((listElementId : string) => {
            const productAmount : ProductAmount = productAmountsByListElementId[listElementId];

            // TODO alb verify that backend changes mean we don't need to do this.
            // // skip any product amounts with a null quantity
            // // this just means we're deleting a PCA that was originally in a report
            // if (productAmount.quantity === null || orderedProductAmountQuantityByListElementIdForm[listElementId] === null) {
            //     return;
            // }

            const product = productsById.get(productAmount.getProductId());
            if (typeof product === 'undefined') {
                throw new RuntimeException('product not found: ' + productAmount.getProductId());
            }
            if (typeof orderedProductAmountQuantityByListElementIdForm[listElementId] === 'undefined') {
                throw new RuntimeException('orderedProductAmountQuantityByListElementIdForm unexpectedly missing ' + listElementId);
            }

            const resolvedQuantityInUnit = PackagingUtils.resolveProductQuantityUnit(productAmount.getQuantityInUnit(), product.getPackagingsAndMappings().getMappings());

            tableRows.push(
                <TransferListRow
                    key={ listElementId }
                    product={ product }
                    unit={ resolvedQuantityInUnit.getUnit() }
                    quantity={ orderedProductAmountQuantityByListElementIdForm[listElementId].value } // TODO alb verify ok???
                    isValid={ orderedProductAmountQuantityByListElementIdForm[listElementId].isValid }
                    errorMessage={ orderedProductAmountQuantityByListElementIdForm[listElementId].errorMessage }
                    validateQuantity={ this.handleValidateQuantity(listElementId) }
                    setQuantity={ this.handleSetQuantity(listElementId) }
                    removeRow={ this.handleRemoveRow(listElementId, productAmount.getProductId()) }
                />
            );
        });

        return (
            <div className="transfer-list-table">
                <div className="ctable">
                    <div className="ctable-head col-row cell-ptb-md">
                        <div className="cell col-xs-2">Qty</div>
                        <div className="cell col-xs-8">Item</div>
                    </div>
                    <div className="transfer-list-rows">
                        <div className="transfer-list-scroll">
                            { tableRows }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    private handleValidateQuantity = (listElementId : string) => (quantity : string) => this.props.validateQuantity(listElementId, quantity);
    private handleSetQuantity = (listElementId : string) => (quantity : string) => this.props.setQuantity(listElementId, quantity);
    private handleRemoveRow = (listElementId : string, productId : ProductId) => () => this.props.removeRow(listElementId, productId);
}
