import { IStringValue } from 'api/Core/IStringValue';

export class TransferId implements IStringValue {
    constructor (private readonly value : string) {}

    public getValue() : string {
        return this.value;
    }

    public equals(other : any) : boolean {
        if (other instanceof TransferId) {
            return this.getValue() === other.getValue();
        }

        return false;
    }
}
