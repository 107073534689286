import { LocationId } from 'api/Location/model/LocationId';
import InventoryModel from 'gen-thrift/inventory_Model_types';

import { StringValueMap } from 'api/Core/StringValueMap';
import { InventoryConfiguration } from 'api/InventoryCount/model/InventoryConfiguration';
import { InventoryCount } from 'api/InventoryCount/model/InventoryCount';
import { InventoryCountId } from 'api/InventoryCount/model/InventoryCountId';
import { InventoryCountMetadata } from 'api/InventoryCount/model/InventoryCountMetadata';
import { InventoryCountType } from 'api/InventoryCount/model/InventoryCountType';
import { ProductCount } from 'api/InventoryCount/model/ProductCount';
import { ProductCountEvent } from 'api/InventoryCount/model/ProductCountEvent';
import { StorageArea } from 'api/InventoryCount/model/StorageArea';
import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductThriftToObjectSerializer } from 'api/Product/serializer/ProductThriftToObjectSerializer';
import { UserAccountThriftToObjectSerializer } from 'api/UserAccount/serializer/UserAccountThriftToObjectSerializer';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export class InventoryCountThriftToObjectSerializer {

    constructor(
        private userAccountThriftToObjectSerializer : UserAccountThriftToObjectSerializer,
        private productThriftToObjectSerializer : ProductThriftToObjectSerializer
    ) {}

    ////////////////////////////////////
    // MODELS
    ////////////////////////////////////

    public getStorageAreaId(
        storageAreaId : InventoryModel.StorageAreaId
    ) : StorageAreaId {
        return new StorageAreaId(storageAreaId.value);
    }

    public getInventoryCountId(
        inventoryCountId : InventoryModel.InventoryCountId
    ) : InventoryCountId {
        return new InventoryCountId(inventoryCountId.value);
    }

    public getInventoryCountType(
        inventoryCountType : InventoryModel.InventoryCountType
    ) : InventoryCountType {
        switch (inventoryCountType) {
            case InventoryModel.InventoryCountType.COMPLETE:
                return InventoryCountType.COMPLETE;
            case InventoryModel.InventoryCountType.PARTIAL:
                return InventoryCountType.PARTIAL;
            default:
                throw new RuntimeException('Unknown inventory count type');
        }
    }

    public getInventoryMetadata(
        metadata : InventoryModel.InventoryCountMetadata
    ) : InventoryCountMetadata {
        const finalizationMetadata = metadata.finalizationMetadata ? this.userAccountThriftToObjectSerializer.getUserAccountIdentifierAndTimestamp(metadata.finalizationMetadata) : null;
        const deletionMetadata = metadata.deletionMetadata ? this.userAccountThriftToObjectSerializer.getUserAccountIdentifierAndTimestamp(metadata.deletionMetadata) : null;
        return new InventoryCountMetadata(
            metadata.inventoryCountTime,
            this.getInventoryCountType(metadata.inventoryCountType),
            this.userAccountThriftToObjectSerializer.getUserAccountIdentifierAndTimestamp(metadata.creationMetadata),
            finalizationMetadata,
            deletionMetadata,
            metadata.name,
            metadata.note,
            new LocationId(metadata.locationId.value),
        );
    }

    public getStorageArea(
        storageArea : InventoryModel.StorageArea
    ) : StorageArea {
        return new StorageArea(
            storageArea.name,
            storageArea.note
        );
    }

    public getInventoryConfiguration(
        inventoryConfiguration : InventoryModel.InventoryConfiguration
    ) : InventoryConfiguration {
        return new InventoryConfiguration(
            this.getStorageAreaIds(inventoryConfiguration.sortedStorageAreaIds),
            this.getMapFromStorageAreaIdAndStorageArea(inventoryConfiguration.storageAreasById),
            this.getMapFromStorageAreaIdAndSortedProductIdList(inventoryConfiguration.sortedProductIdListsByStorageAreaId),
        );
    }

    public getProductCount(
        productCount : InventoryModel.ProductCount
    ) : ProductCount {
        return new ProductCount(
            productCount.count,
            this.productThriftToObjectSerializer.getProductQuantityUnit(productCount.productQuantityUnit)
        );
    }

    public getProductCountEvent(
        productCountEvent : InventoryModel.ProductCountEvent
    ) : ProductCountEvent {
        return new ProductCountEvent(
            this.getProductCount(productCountEvent.productCount),
            this.userAccountThriftToObjectSerializer.getUserAccountIdentifierAndTimestamp(productCountEvent.metadata),
        );
    }

    public getInventoryCount(
        inventoryCount : InventoryModel.InventoryCount
    ) : InventoryCount {
        const inventoryConfiguration = this.getInventoryConfiguration(inventoryCount.inventoryConfiguration);
        const productCountEventsByProductIdByStorageAreaId = this.getMapFromStorageAreaIdAndProductIdAndCountEvent(inventoryCount.productCountEventsByProductIdByStorageAreaId);

        inventoryConfiguration.getSortedStorageAreaIds().forEach((storageAreaId) => {
            const productCountEventsByProductId = productCountEventsByProductIdByStorageAreaId.get(storageAreaId);
            if (typeof productCountEventsByProductId === 'undefined') {
                productCountEventsByProductIdByStorageAreaId.set(storageAreaId, new StringValueMap());
            }
        });

        return new InventoryCount(inventoryConfiguration, productCountEventsByProductIdByStorageAreaId);
    }

    ////////////////////////////////////
    // COLLECTIONS
    ////////////////////////////////////

    public getMapFromInventoryCountIdsAndMetadata(
        thriftInventoryCountIdsAndMetadata : Array<InventoryModel.InventoryCountIdAndMetadata>
    ) : StringValueMap<InventoryCountId, InventoryCountMetadata> {
        const iInventoryCountMetadatasByInventoryCountId : StringValueMap<InventoryCountId, InventoryCountMetadata> = new StringValueMap();
        thriftInventoryCountIdsAndMetadata.map((countIdAndMetadata : InventoryModel.InventoryCountIdAndMetadata) => {
            const inventoryCountId = this.getInventoryCountId(countIdAndMetadata.inventoryCountId);
            const inventoryMetadata = this.getInventoryMetadata(countIdAndMetadata.inventoryMetadata);
            iInventoryCountMetadatasByInventoryCountId.set(inventoryCountId, inventoryMetadata);
        });
        return iInventoryCountMetadatasByInventoryCountId;
    }

    public getStorageAreaIds(
        storageAreaIds : Array<InventoryModel.StorageAreaId>
    ) : Array<StorageAreaId> {
        return storageAreaIds.map((storageAreaId) => {
            return this.getStorageAreaId(storageAreaId);
        });
    }

    public getInventoryCountIds(
        inventoryCountIds : Array<InventoryModel.InventoryCountId>
    ) : Array<InventoryCountId> {
        return inventoryCountIds.map((inventoryCountId) => {
            return this.getInventoryCountId(inventoryCountId);
        });
    }

    public getMapFromStorageAreaIdAndStorageArea(
        storageAreasById : Array<InventoryModel.StorageAreaIdAndStorageArea>
    ) : StringValueMap<StorageAreaId, StorageArea> {
        const iStorageAreasByStorageAreaId : StringValueMap<StorageAreaId, StorageArea> = new StringValueMap();
        storageAreasById.map((storageAreaIdAndStorageArea : InventoryModel.StorageAreaIdAndStorageArea) => {
            const storageAreaId = this.getStorageAreaId(storageAreaIdAndStorageArea.storageAreaId);
            const storageArea = this.getStorageArea(storageAreaIdAndStorageArea.storageArea);
            iStorageAreasByStorageAreaId.set(storageAreaId, storageArea);
        });
        return iStorageAreasByStorageAreaId;
    }

    public getMapFromStorageAreaIdAndSortedProductIdList(
        storageAreaIdAndSortedProductIdLists : Array<InventoryModel.StorageAreaIdAndSortedProductIdList>
    ) : StringValueMap<StorageAreaId, Array<ProductId>> {
        const iProductIdArrayByStorageAreaId : StringValueMap<StorageAreaId, Array<ProductId>> = new StringValueMap();
        storageAreaIdAndSortedProductIdLists.map((storageAreaIdAndStortedProductIdList : InventoryModel.StorageAreaIdAndSortedProductIdList) => {
            const storageAreaId = this.getStorageAreaId(storageAreaIdAndStortedProductIdList.storageAreaId);
            const sortedProductIdList = this.productThriftToObjectSerializer.getProductIds(storageAreaIdAndStortedProductIdList.sortedProductIdList);
            iProductIdArrayByStorageAreaId.set(storageAreaId, sortedProductIdList);
        });
        return iProductIdArrayByStorageAreaId;
    }

    public getMapFromStorageAreaIdAndProductIdAndCountEvent(
        storageAreaIdAndProductIdAndCountEvents : Array<InventoryModel.StorageAreaIdAndProductIdAndCountEvent>
    ) : StringValueMap<StorageAreaId, StringValueMap<ProductId, ProductCountEvent>> {
        const iProductCountEventByProductIdByStorageId : StringValueMap<StorageAreaId, StringValueMap<ProductId, ProductCountEvent>> = new StringValueMap();
        storageAreaIdAndProductIdAndCountEvents.map((storageAreaIdAndProductIdAndCountEvent : InventoryModel.StorageAreaIdAndProductIdAndCountEvent) => {
            const storageAreaId = this.getStorageAreaId(storageAreaIdAndProductIdAndCountEvent.storageAreaId);
            let productCountEventById = iProductCountEventByProductIdByStorageId.get(storageAreaId);
            if (!productCountEventById) {
                productCountEventById = new StringValueMap();
                iProductCountEventByProductIdByStorageId.set(storageAreaId, productCountEventById);
            }
            const productId = this.productThriftToObjectSerializer.getProductId(storageAreaIdAndProductIdAndCountEvent.productId);
            const productCountEvent = this.getProductCountEvent(storageAreaIdAndProductIdAndCountEvent.productCountEvent);
            productCountEventById.set(productId, productCountEvent);
        });
        return iProductCountEventByProductIdByStorageId;
    }

    public getMapFromInventoryCountIdsAndInventoryCount(
        thriftInventoryCountIdsAndInventoryCount : Array<InventoryModel.InventoryCountIdAndInventoryCount>
    ) : StringValueMap<InventoryCountId, InventoryCount> {
        const iInventoryCountByInventoryCountId : StringValueMap<InventoryCountId, InventoryCount> = new StringValueMap();

        thriftInventoryCountIdsAndInventoryCount.map((inventoryCountIdAndInventoryCount : InventoryModel.InventoryCountIdAndInventoryCount) => {
            const inventoryCountId = this.getInventoryCountId(inventoryCountIdAndInventoryCount.inventoryCountId);
            const inventoryMetadata = this.getInventoryCount(inventoryCountIdAndInventoryCount.inventoryCount);
            iInventoryCountByInventoryCountId.set(inventoryCountId, inventoryMetadata);
        });

        return iInventoryCountByInventoryCountId;
    }

    public getMapFromInventoryCountIdAndMetadatas(
        inventoryCountAndMetadatas : Array<InventoryModel.InventoryCountIdAndMetadata>
    ) : StringValueMap<InventoryCountId, InventoryCountMetadata> {
        const iInventoryCountMetadataByInventoryCountId : StringValueMap<InventoryCountId, InventoryCountMetadata> = new StringValueMap();
        inventoryCountAndMetadatas.map((inventoryCountIdAndMetadata : InventoryModel.InventoryCountIdAndMetadata) => {
            const inventoryCountId = this.getInventoryCountId(inventoryCountIdAndMetadata.inventoryCountId);
            const inventoryMetadata = this.getInventoryMetadata(inventoryCountIdAndMetadata.inventoryMetadata);
            iInventoryCountMetadataByInventoryCountId.set(inventoryCountId, inventoryMetadata);
        });
        return iInventoryCountMetadataByInventoryCountId;
    }
}
