import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { InventoryConfiguration } from 'api/InventoryCount/model/InventoryConfiguration';
import { InventoryCount } from 'api/InventoryCount/model/InventoryCount';
import { InventoryCountId } from 'api/InventoryCount/model/InventoryCountId';
import { InventoryCountType } from 'api/InventoryCount/model/InventoryCountType';
import { ProductCount } from 'api/InventoryCount/model/ProductCount';
import { ProductCountEvent } from 'api/InventoryCount/model/ProductCountEvent';
import { StorageArea } from 'api/InventoryCount/model/StorageArea';
import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductObjectToThriftSerializer } from 'api/Product/serializer/ProductObjectToThriftSerializer';
import { UserAccountObjectToThriftSerializer } from 'api/UserAccount/serializer/UserAccountObjectToThriftSerializer';
import InventoryModel from 'gen-thrift/inventory_Model_types';

export class InventoryCountObjectToThriftSerializer {

    constructor(
        private userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer,
        private productObjectToThriftSerializer : ProductObjectToThriftSerializer
    ) {}

    ////////////////////////////////////
    // MODELS
    ////////////////////////////////////

    public getThriftInventoryCountId(
        inventoryCountId : InventoryCountId
    ) : InventoryModel.InventoryCountId {
        return new InventoryModel.InventoryCountId({
            value: inventoryCountId.getValue(),
        });
    }

    public getThriftStorageAreaId(
        storageAreaId : StorageAreaId
    ) : InventoryModel.StorageAreaId {
        return new InventoryModel.StorageAreaId({
            value: storageAreaId.getValue(),
        });
    }

    public getThriftStorageArea(
        storageArea : StorageArea
    ) : InventoryModel.StorageArea {
        return new InventoryModel.StorageArea({
            name: storageArea.getName(),
            note: storageArea.getNote()
        });
    }

    public getThriftInventoryCountType(
        inventoryCountType : InventoryCountType
    ) : InventoryModel.InventoryCountType {
        switch (inventoryCountType) {
            case InventoryCountType.PARTIAL:
                return InventoryModel.InventoryCountType.PARTIAL;
            case InventoryCountType.COMPLETE:
            default:
                return InventoryModel.InventoryCountType.COMPLETE;
        }
    }

    public getThriftInventoryConfiguration(
        inventoryConfiguration : InventoryConfiguration
    ) : InventoryModel.InventoryConfiguration {
        const storageAreaIds = this.getThriftStorageAreaIds(inventoryConfiguration.getSortedStorageAreaIds());
        const storageAreaIdAndStorageAreas = this.getThriftStorageAreaIdAndStorageArea(inventoryConfiguration.getStorageAreasById());
        const storageAreaIdAndSortedProductIdLists = this.getThriftStorageAreaIdAndSortedProductIdLists(inventoryConfiguration.getSortedProductIdListsByStorageAreaId());
        return new InventoryModel.InventoryConfiguration({
            sortedStorageAreaIds: storageAreaIds,
            storageAreasById: storageAreaIdAndStorageAreas,
            sortedProductIdListsByStorageAreaId: storageAreaIdAndSortedProductIdLists
        });
    }

    public getThriftProductCount(
        productCount : ProductCount
    ) : InventoryModel.ProductCount {
        return new InventoryModel.ProductCount({
            productQuantityUnit: this.productObjectToThriftSerializer.getThriftProductQuantityUnit(productCount.getProductQuantityUnit()),
            count: productCount.getCount()
        });
    }

    public getThriftProductCountEvent(
        productCountEvent : ProductCountEvent
    ) : InventoryModel.ProductCountEvent {
        return new InventoryModel.ProductCountEvent({
            productCount: this.getThriftProductCount(productCountEvent.getProductCount()),
            metadata: this.userAccountObjectToThriftSerializer.getThriftUserAccountIdentifierAndTimestamp(productCountEvent.getUserAccountIdAndTimestamp())
        });
    }

    public getThriftInventoryCount(
        inventoryCount : InventoryCount
    ) : InventoryModel.InventoryCount {
        return new InventoryModel.InventoryCount({
            inventoryConfiguration: this.getThriftInventoryConfiguration(inventoryCount.getInventoryConfiguration()),
            productCountEventsByProductIdByStorageAreaId: this.getThriftProductCountEventsByProductIdByStorageAreaId(inventoryCount.getProductCountEventsByProductIdByStorageAreaId())
        });
    }

    ////////////////////////////////////
    // COLLECTIONS
    ////////////////////////////////////

    public getThriftInventoryCountIds(
        inventoryCountIds : StringValueSet<InventoryCountId>
    ) : Array<InventoryModel.InventoryCountId> {
        return Array.from(inventoryCountIds.values()).map((inventoryCountId : InventoryCountId) => {
            return this.getThriftInventoryCountId(inventoryCountId);
        });
    }

    public getThriftStorageAreaIds(storageAreaIds : Array<StorageAreaId>) : Array<InventoryModel.StorageAreaId> {
        return storageAreaIds.map((storageAreaId : StorageAreaId) => {
            return this.getThriftStorageAreaId(storageAreaId);
        });
    }

    public getThriftStorageAreaIdAndStorageArea(
        storageAreasById : StringValueMap<StorageAreaId, StorageArea>
    ) : Array<InventoryModel.StorageAreaIdAndStorageArea> {
        const storageAreaIdAndStorageAreas = new Array<InventoryModel.StorageAreaIdAndStorageArea>();
        storageAreasById.forEach((value : StorageArea, key : StorageAreaId) => {
            storageAreaIdAndStorageAreas.push(
                new InventoryModel.StorageAreaIdAndStorageArea({
                    storageAreaId: this.getThriftStorageAreaId(key),
                    storageArea: this.getThriftStorageArea(value)
                })
            );
        });
        return storageAreaIdAndStorageAreas;
    }

    public getThriftStorageAreaIdAndSortedProductIdLists(
        sortedProductIdListByStorageAreaId : StringValueMap<StorageAreaId, Array<ProductId>>
    ) : Array<InventoryModel.StorageAreaIdAndSortedProductIdList> {
        const storageAreaIdAndSortedProductIdList = new Array<InventoryModel.StorageAreaIdAndSortedProductIdList>();
        sortedProductIdListByStorageAreaId.forEach((value : Array<ProductId>, key : StorageAreaId) => {
            storageAreaIdAndSortedProductIdList.push(new InventoryModel.StorageAreaIdAndSortedProductIdList({
                storageAreaId: this.getThriftStorageAreaId(key),
                sortedProductIdList: this.productObjectToThriftSerializer.getThriftProductIdsFromArray(value)
            }));
        });
        return storageAreaIdAndSortedProductIdList;
    }

    public getThriftProductCountEventsByProductIdByStorageAreaId(
        productCountEventByProductIdByStorageAreaId : StringValueMap<StorageAreaId, StringValueMap<ProductId, ProductCountEvent>>
    ) : Array<InventoryModel.StorageAreaIdAndProductIdAndCountEvent> {
        const storageAreaIdAndProductIdCountEvents = new Array<InventoryModel.StorageAreaIdAndProductIdAndCountEvent>();
        productCountEventByProductIdByStorageAreaId.forEach((productCountEventsByProductId : StringValueMap<ProductId, ProductCountEvent>, storageAreaId : StorageAreaId) => {
            productCountEventsByProductId.forEach((productCountEvent : ProductCountEvent, productId : ProductId) => {
                storageAreaIdAndProductIdCountEvents.push({
                    storageAreaId: this.getThriftStorageAreaId(storageAreaId),
                    productId: this.productObjectToThriftSerializer.getThriftProductId(productId),
                    productCountEvent: this.getThriftProductCountEvent(productCountEvent)
                });
            });
        });
        return storageAreaIdAndProductIdCountEvents;
    }
}
