import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import ProductAmountModel from 'gen-thrift/product_amount_Model_types';

import { ILocationProductService } from 'api/Location/interfaces/ILocationProductService';
import { LocationId } from 'api/Location/model/LocationId';
import { ProductId } from 'api/Product/model/ProductId';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { IAccountSessionReader } from 'shared/lib/account/interfaces/IAccountSessionReader';

import { IAddItemModalState } from 'shared/components/AddItem/reducers/addItemModalReducers';

import { AddItemActions, IAddItemProps } from './addItemActions';

import { IExtraArguments } from 'shared/components/Provider';

export const ActionTypes = {
    SET_IS_SHOWN: 'ADD_ITEM_MODAL/SET_IS_SHOWN',
};

export interface IAddItemModalProps extends IAddItemProps {
    addItemModalState : IAddItemModalState;
}

export namespace ActionInterfaces {
    export type IAddProductToListActionCreator = (
        productId : string,
        selectedContainerType : ProductAmountModel.Unit | null
    ) => ThunkAction<Promise<void>, IAddItemModalProps, ActionInterfaces.IThunkServices>;

    export interface IServices {
        userSessionReader : IAccountSessionReader<UserSessionId, UserAccountId>;
        locationProductService : ILocationProductService;
    }

    export interface IThunkServices extends IExtraArguments {
        services : IServices;
    }

    export type ISetShownThunkActionCreator = (
        isShown : boolean,
    ) => ThunkAction<void, IAddItemModalProps, ActionInterfaces.IThunkServices>;
}

const setIsShown : ActionInterfaces.ISetShownThunkActionCreator = (
    isShown : boolean
) : ThunkAction<void, IAddItemModalProps, ActionInterfaces.IThunkServices> => {
    return (dispatch : Dispatch<IAddItemModalProps>, getState : () => IAddItemModalProps, extraArguments : ActionInterfaces.IThunkServices) => {
        dispatch({ payload: { isShown }, type: ActionTypes.SET_IS_SHOWN });
        if (isShown && !getState().addItemModalState.isShown) {
            dispatch(AddItemActions.getInitialData());
        }
    };
};

const addToInventoryList : ActionInterfaces.IAddProductToListActionCreator = (
    productIdValue : string,
    selectedContainerType : ProductAmountModel.Unit | null
) : ThunkAction<Promise<void>, IAddItemModalProps, ActionInterfaces.IThunkServices> => {
    return (dispatch : Dispatch<IAddItemModalProps>, getState : () => IAddItemProps, extraArguments : ActionInterfaces.IThunkServices) : Promise<void> => {
        const locationId = new LocationId(window.GLOBAL_RETAILER_ID);
        const userSessionId = extraArguments.services.userSessionReader.getSessionId();

        const productId = new ProductId(productIdValue);
        return extraArguments.services.locationProductService.setProductAsActive(userSessionId, locationId, productId)
        .then(() => {
            const productIdPayload = [{ productId, isCase: selectedContainerType === ProductAmountModel.Unit.CASE }];
            $(document).trigger('ajaxSearchActionClicked', productIdPayload);
        });
    };
};

export const AddItemModalActions = {
    setIsShown,
    addToInventoryList
};
