import React from 'react';

interface ITransferListPanelMobileHeaderProps {
    title : string;
    count : number;
    panelIsOpen : boolean;
    setPanelIsOpen(open : boolean) : void;
}
export class TransferListPanelMobileHeader extends React.Component<ITransferListPanelMobileHeaderProps, object> {

    constructor(props : ITransferListPanelMobileHeaderProps) {
        super(props);

        this.handleExpandPanel = this.handleExpandPanel.bind(this);
    }

    public render() {
        const {
            title,
            count,
            panelIsOpen,
        } = this.props;

        const arrowDirection : string = panelIsOpen ? 'bevico-arrow_drop_up' : 'bevico-arrow_drop_down' ;
        const panelClasses : Array<string> = ['current-transfer-list-mobile'];
        if (panelIsOpen) {
            panelClasses.push('panel-expanded');
        } else {
            panelClasses.push('panel-collapsed');
        }
        if (count > 0) {
            panelClasses.push('at-least-one-item');
        }

        return (
            <div
                className={ panelClasses.join(' ') }
                onClick={ this.handleExpandPanel }
            >
                <h1>{ title } <strong className="transfer-list-count">{ count }</strong>
                    <span className={ `bevicon ${ arrowDirection }` } />
                </h1>
            </div>
        );
    }

    private handleExpandPanel() : void {
        return this.props.setPanelIsOpen(!this.props.panelIsOpen);
    }
}
