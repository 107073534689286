
import React from 'react';

// Components
import { Button } from 'shared/components/Button';
import { Flex } from 'shared/components/FlexLayout/Flex';
import { Popover } from 'shared/components/Popover/Popover';
import { ValidationInput, ValidationInputTheme } from 'shared/components/ValidationInput';

// Types
import { SalesInformationFormValidationByFieldName } from 'apps/CreateOrEditSalesItem/reducers/reducers';

// Utilities
import { NOT_CALCULABLE_FIELD_VALUE } from 'apps/CreateOrEditSalesItem/utils/CreateOrEditSalesItemFormUtils';
import { SalesItemManagerUtils } from 'apps/SalesItemManager/utils/SalesItemManagerUtils';
import { FormatMonetaryValueWithCents } from 'shared/utils/FormatMonetaryValue';

import '../../css/SalesInformation.scss';

export interface IMapViewSalesInformationProps {
    validationInputDataByFieldName : SalesInformationFormValidationByFieldName;
    retailerTaxPercentage : number | null;
    miscellaneousCostInputIsShown : boolean;
    salesPriceAndTaxHintShown : boolean;
    handleSetMiscellaneousCostInputShown : (isShown : boolean) => void;
    handleSetSalesPriceAndTaxHintIsShown : (isShown : boolean) => void;
    onSalesInformationFormFieldChange : (fieldName : 'miscCost', value : string) => void;
    onSalesInformationFormFieldBlur : (fieldName : 'miscCost', value : string) => void;
}

const INPUT_DISPLAY_FOR_NOT_CALCULABLE = 'N/A';

// was going to use SalesInformation and just optionally make fields disabled, but all the fields have to be in a different order :(
// so just making a new component. keeping validation inputs for spacing and also because this may become editable at some point.
export class MapViewSalesInformation extends React.Component<IMapViewSalesInformationProps, object> {
    public render() {
        const {
            validationInputDataByFieldName,
            retailerTaxPercentage,
            salesPriceAndTaxHintShown,
            miscellaneousCostInputIsShown,
            handleSetSalesPriceAndTaxHintIsShown,
        } = this.props;
        const { totalCost, costPercentage, salesProfit, priceAndTax } = validationInputDataByFieldName;

        const formattedRetailerTaxPercentage = SalesItemManagerUtils.formatPercent(retailerTaxPercentage);

        // sales price not shown here (unlike in SalesInformation.tsx)
        const totalCostValue = totalCost.value === NOT_CALCULABLE_FIELD_VALUE ? INPUT_DISPLAY_FOR_NOT_CALCULABLE : FormatMonetaryValueWithCents(Number(totalCost.value));
        const costPercentageValue = costPercentage.value === NOT_CALCULABLE_FIELD_VALUE ? INPUT_DISPLAY_FOR_NOT_CALCULABLE : costPercentage.value;
        const salesProfitValue = salesProfit.value === NOT_CALCULABLE_FIELD_VALUE ? INPUT_DISPLAY_FOR_NOT_CALCULABLE : FormatMonetaryValueWithCents(Number(salesProfit.value));
        const priceAndTaxValue = priceAndTax.value === NOT_CALCULABLE_FIELD_VALUE ? INPUT_DISPLAY_FOR_NOT_CALCULABLE : FormatMonetaryValueWithCents(Number(priceAndTax.value));

        return (
            <div className="sales-information-container">
                <div className="sales-information sales-item-form-subsection mapped-sales-item-form-subsection">
                    <Flex direction="column">
                        <Flex direction="row" className="mapped-sales-items-row">
                            <div className="cost-percentage mapped-sales-item">
                                <Flex direction="column">
                                    <span className="label">Cost %</span>
                                    <span>{ `${costPercentageValue} %` }</span>
                                </Flex>
                            </div>
                            <div className="total-cost mapped-sales-item">
                                <Flex direction="column">
                                    <span className="label">Total Cost</span>
                                    <span>{ totalCostValue }</span>
                                </Flex>
                            </div>
                        </Flex>
                        <Flex direction="row" className="mapped-sales-items-row">
                            { retailerTaxPercentage !== null &&
                                <div className="price-tax mapped-sales-item">
                                    <Flex direction="column">
                                        <Flex direction="row" align="center">
                                            <span className="label">Price + Tax</span>
                                            <Popover
                                                className="sales-price-and-tax-popover"
                                                preferredPositionArray={ ['right', 'above', 'left', 'below'] }
                                                showOnHover={ true }
                                                popoverContentIsShown={ salesPriceAndTaxHintShown }
                                                setPopoverContentIsShown={ handleSetSalesPriceAndTaxHintIsShown }
                                                anchorContent={ (
                                                    <span className="bevicon bevico-help label icon-right" />
                                                ) }
                                                popoverContent={ (
                                                    <p>
                                                        Calculated at tax rate of { formattedRetailerTaxPercentage }. You can update this in
                                                        <a href={ `/sales/items/sales_item_manager/r/${window.GLOBAL_RETAILER_ID}` } target="_blank"> Recipes</a>.
                                                    </p>
                                                ) }
                                            />
                                        </Flex>
                                        <span>{ priceAndTaxValue }</span>
                                    </Flex>
                                </div>
                            }
                             <div className={ retailerTaxPercentage !== null ? 'sales-profit mapped-sales-item' : 'mapped-sales-item' }>
                                <Flex direction="column">
                                    <span className="label">Sales Profit</span>
                                    <span>{ salesProfitValue }</span>
                                </Flex>
                            </div>
                        </Flex>
                        <Flex direction="row">
                        <div className="misc-cost">
                            { miscellaneousCostInputIsShown &&
                                <React.Fragment>
                                    <div className="label">Misc. Cost</div>
                                    <ValidationInput
                                        theme={ ValidationInputTheme.BasicInverted }
                                        type="text"
                                        step="any"
                                        label={ null }
                                        hintText="Optional"
                                        value={ validationInputDataByFieldName.miscCost.value }
                                        autoFocus={ false }
                                        autoComplete={ null }
                                        isValid={ validationInputDataByFieldName.miscCost.isValid }
                                        isDisabled={ false }
                                        errorMessage={ validationInputDataByFieldName.miscCost.errorMessage }
                                        inputClassName=""
                                        handleEnterClick={ this.handleOnMiscCostEnterOrBlur }
                                        handleChange={ this.handleOnMiscCostChange }
                                        handleBlur={ this.handleOnMiscCostEnterOrBlur }
                                        handleFocus={ null }
                                        isCurrencyInput={ true }
                                    />
                                </React.Fragment>
                            }
                            { !miscellaneousCostInputIsShown &&
                                <Button
                                    buttonClassName="primary flat link medium"
                                    isDisabled={ false }
                                    isLoading={ false }
                                    onClick={ this.handleShowMiscellaneousCost }
                                >
                                    Add Misc. Cost
                                </Button>
                            }
                        </div>

                        </Flex>
                    </Flex>

                </div>
            </div>
        );
    }

    private readonly handleOnMiscCostEnterOrBlur = (event : React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
        if (event.currentTarget.value === '' || parseFloat(event.currentTarget.value) === 0) {
            this.props.handleSetMiscellaneousCostInputShown(false);
            this.props.onSalesInformationFormFieldBlur('miscCost', '0');
        } else {
            this.props.onSalesInformationFormFieldBlur('miscCost', event.currentTarget.value);
        }
    }

    private readonly handleOnMiscCostChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.onSalesInformationFormFieldChange('miscCost', event.currentTarget.value);
    }

    private readonly handleShowMiscellaneousCost = () => {
        this.props.handleSetMiscellaneousCostInputShown(true);
    }
}
