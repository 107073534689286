import InterLocationProductMappingService from 'gen-thrift/InterLocationProductMappingService';
import ProductMappingModel from 'gen-thrift/product_mapping_Model_types';

import { StringValueMap } from 'api/Core/StringValueMap';
import { LocationId } from 'api/Location/model/LocationId';
import { LocationObjectToThriftSerializer } from 'api/Location/serializer/LocationObjectToThriftSerializer';
import { ProductId } from 'api/Product/model/ProductId';
import { IProductMappingService } from 'api/Transfer/interfaces/IProductMappingService';
import { InterLocationProductMapping } from 'api/Transfer/model/InterLocationProductMapping';
import { ProductMappingObjectToThriftSerializer } from 'api/Transfer/serializer/ProductMappingObjectToThriftSerializer';
import { ProductMappingThriftToObjectSerializer } from 'api/Transfer/serializer/ProductMappingThriftToObjectSerializer';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { UserAccountObjectToThriftSerializer } from 'api/UserAccount/serializer/UserAccountObjectToThriftSerializer';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export class ProductMappingServiceImpl implements IProductMappingService {
    constructor (
        private thriftClient : InterLocationProductMappingService.InterLocationProductMappingServiceClient,
        private userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer,
        private locationObjectToThriftSerializer : LocationObjectToThriftSerializer,
        private productMappingObjectToThriftSerializer : ProductMappingObjectToThriftSerializer,
        private productMappingThriftToObjectSerializer : ProductMappingThriftToObjectSerializer,
    ) { }

    public getMappingsBetweenLocations(
        session : UserSessionId,
        sourceLocation : LocationId,
        targetLocation : LocationId
    ) : Promise<StringValueMap<ProductId, ProductId>> {
        return new Promise<StringValueMap<ProductId, ProductId>>(((resolve, reject) => {
            this.thriftClient.getMappingsBetweenLocations(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(session),
                this.locationObjectToThriftSerializer.getThriftLocationId(sourceLocation),
                this.locationObjectToThriftSerializer.getThriftLocationId(targetLocation),
                (result : Array<ProductMappingModel.MappingUpdateEvent> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    const productIdMap = new StringValueMap<ProductId, ProductId>();

                    result.forEach((mappingUpdateEvent) => {
                        const source = new ProductId(mappingUpdateEvent.sourceProductId.value);
                        const target = new ProductId(mappingUpdateEvent.targetProductId.value);
                        if (productIdMap.has(source)) {
                            throw new RuntimeException(sourceLocation.getValue() + ' ' + source.getValue() + ' mapped to multiple products in ' + targetLocation.getValue());
                        }
                        productIdMap.set(source, target);
                    });

                    return resolve(productIdMap);
                }
            );
        }));
    }

    public getProductQuantityUnitMappingsBetweenLocations(
        userSessionId : UserSessionId,
        sourceLocation : LocationId,
        targetLocation : LocationId
    ) : Promise<StringValueMap<ProductId, InterLocationProductMapping>> {
        return new Promise<StringValueMap<ProductId, InterLocationProductMapping>>((resolve, reject) => {
            this.thriftClient.getProductQuantityUnitMappingsBetweenLocations(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(sourceLocation),
                this.locationObjectToThriftSerializer.getThriftLocationId(targetLocation),
                (result : Array<ProductMappingModel.ProductQuantityUnitMappingUpdateEvent> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    const productQuantityUnitMappings = new StringValueMap<ProductId, InterLocationProductMapping>();

                    result.forEach((productQuantityUnitMappingUpdateEvent) => {
                        const interLocationProductMapping = this.productMappingThriftToObjectSerializer.getInterLocationProductMappingFromUpdateEvent(
                            sourceLocation, targetLocation, productQuantityUnitMappingUpdateEvent
                        );

                        const sourceProductId = interLocationProductMapping.getSourceProductId();

                        if (productQuantityUnitMappings.has(sourceProductId)) {
                            throw new RuntimeException(sourceLocation.getValue() + ' ' + sourceProductId.getValue() + ' mapped to multiple products in ' + targetLocation.getValue());
                        }

                        productQuantityUnitMappings.set(sourceProductId, interLocationProductMapping);
                    });

                    return resolve(productQuantityUnitMappings);
                }
            );
        });
    }

    public setProductQuantityUnitMappings(
        userSessionId : UserSessionId,
        interLocationProductMappings : Array<InterLocationProductMapping>
    ) : Promise<void> {
        const thriftIinterLocationProductMappings = interLocationProductMappings.map((interLocationProductMapping) => {
            return this.productMappingObjectToThriftSerializer.getThriftInterLocationProductMapping(interLocationProductMapping);
        });

        return new Promise<void>((resolve, reject) => {
            this.thriftClient.setProductQuantityUnitMappings(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                thriftIinterLocationProductMappings,
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    return resolve();
                }
            );
        });
    }

    public deleteProductQuantityUnitMapping(
        userSessionId : UserSessionId,
        interLocationProductMapping : InterLocationProductMapping,
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.thriftClient.deleteProductQuantityUnitMapping(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.productMappingObjectToThriftSerializer.getThriftInterLocationProductMapping(interLocationProductMapping),
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    }

                    return resolve();
                }
            );
        });
    }
}
