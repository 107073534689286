import React from 'react';

import { ISignInFormState, signInFormFieldName } from './reducers';

import { Button } from '../../Button';
import { IValidationInputData, ValidationInput } from '../../ValidationInput';

import { PathUtils } from 'shared/utils/pathUtils';

export interface ISignInFormProps {
    signInFormState : ISignInFormState;
    onFormFieldChange : (field : signInFormFieldName, value : string) => void;
    onFormFieldBlur : (field : signInFormFieldName, value : string) => void;
    onSubmit : () => void;
}

export class SignInForm extends React.Component<ISignInFormProps, object> {
    public render() {
        const {
            signInFormState,
            onFormFieldChange,
            onFormFieldBlur,
            onSubmit,
        } = this.props;

        const makeValidationInput = (fieldName : signInFormFieldName, fieldData : IValidationInputData, label : string) : JSX.Element => {
            const onChange = (event : React.ChangeEvent<HTMLInputElement>) => onFormFieldChange(fieldName, event.currentTarget.value);
            const onBlur = (event : React.FocusEvent<HTMLInputElement>) => onFormFieldBlur(fieldName, event.currentTarget.value);

            return (
                <ValidationInput
                    key={ fieldName }
                    inputClassName=""
                    type={ fieldName === 'password' ? 'password' : 'text' }
                    autoFocus={ false }
                    autoComplete={ null }
                    errorMessage={ fieldData.errorMessage }
                    isValid={ fieldData.isValid }
                    handleBlur={ onBlur }
                    handleFocus={ null }
                    handleChange={ onChange }
                    handleEnterClick={ onSubmit }
                    label={ label }
                    hintText={ null }
                    value={ fieldData.value }
                />
            );
        };

        return (
            <div className="sign-in-form">
                <h5>Already have an account?</h5>
                <p>Sign in to your personal account or Bar &amp; Restaurant Dashboard here.</p>

                { makeValidationInput('emailAddressOrAccountId', signInFormState.validationInputDataByFieldName.emailAddressOrAccountId, 'Email Address (or Username)') }

                { makeValidationInput('password', signInFormState.validationInputDataByFieldName.password, 'Password') }

                <Button
                    buttonClassName="modal-sign-in-button primary"
                    isDisabled={ signInFormState.isSubmitting }
                    isLoading={ signInFormState.isSubmitting }
                    onClick={ onSubmit }
                >
                    Log In
                </Button>
                { !signInFormState.isValid && signInFormState.errorMessage &&
                    <p className="sign-in-error">{ signInFormState.errorMessage }</p>
                }

                <a className="inline" href={ PathUtils.getAbsolutePathForRequest('/forgotten_password/') }>
                    Forgot your password?
                </a>
            </div>
        );
    }
}
