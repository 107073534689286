import React from 'react';

import { Popover } from 'shared/components/Popover/Popover';
import { ValidationInput, ValidationInputTheme } from 'shared/components/ValidationInput';

import { SalesItemManagerUtils } from '../../SalesItemManager/utils/SalesItemManagerUtils';
import { SalesInformationFormFieldName, SalesInformationFormValidationByFieldName } from '../reducers/reducers';
import { NOT_CALCULABLE_FIELD_VALUE } from '../utils/CreateOrEditSalesItemFormUtils';

import '../css/SalesInformation.scss';

export interface ISalesInformationProps {
    validationInputDataByFieldName : SalesInformationFormValidationByFieldName;
    onSalesInformationFormFieldChange : (fieldName : SalesInformationFormFieldName, value : string) => void;
    onSalesInformationFormFieldBlur : (fieldName : SalesInformationFormFieldName, value : string) => void;
    retailerTaxPercentage : number | null;
    salesPriceAndTaxHintShown : boolean;
    handleSetSalesPriceAndTaxHintIsShown : (isShown : boolean) => void;
}

const INPUT_DISPLAY_FOR_NOT_CALCULABLE = 'N/A';

export class SalesInformation extends React.Component<ISalesInformationProps, object> {
    public render() {
        const {
            validationInputDataByFieldName,
            retailerTaxPercentage,
            salesPriceAndTaxHintShown,
        } = this.props;

        const formattedRetailerTaxPercentage = SalesItemManagerUtils.formatPercent(retailerTaxPercentage);

        const totalCostValue = validationInputDataByFieldName.totalCost.value ===  NOT_CALCULABLE_FIELD_VALUE ? INPUT_DISPLAY_FOR_NOT_CALCULABLE : validationInputDataByFieldName.totalCost.value;
        const salesPriceValue = validationInputDataByFieldName.salesPrice.value === NOT_CALCULABLE_FIELD_VALUE ? INPUT_DISPLAY_FOR_NOT_CALCULABLE : validationInputDataByFieldName.salesPrice.value;
        const costPercentageValue = validationInputDataByFieldName.costPercentage.value === NOT_CALCULABLE_FIELD_VALUE ? INPUT_DISPLAY_FOR_NOT_CALCULABLE : validationInputDataByFieldName.costPercentage.value;
        const salesProfitValue = validationInputDataByFieldName.salesProfit.value === NOT_CALCULABLE_FIELD_VALUE ? INPUT_DISPLAY_FOR_NOT_CALCULABLE : validationInputDataByFieldName.salesProfit.value;

        return (
            <div className="sales-information-container">
                <div className="sales-information sales-item-form-subsection">
                    <div className="sales-price input-container">
                        <div className="label">Sales Price</div>
                        <ValidationInput
                            theme={ ValidationInputTheme.BasicInverted }
                            type="text"
                            step="any"
                            label={ null }
                            hintText=""
                            value={ salesPriceValue }
                            autoFocus={ false }
                            autoComplete={ null }
                            isValid={ validationInputDataByFieldName.salesPrice.isValid }
                            isDisabled={ false }
                            errorMessage={ validationInputDataByFieldName.salesPrice.errorMessage }
                            inputClassName=""
                            handleEnterClick={ this.handleOnSalesPriceEnterOrBlur }
                            handleChange={ this.handleOnSalesPriceChange }
                            handleBlur={ this.handleOnSalesPriceEnterOrBlur }
                            handleFocus={ null }
                            isCurrencyInput={ true }
                        />
                    </div>
                    <div className="cost-percentage input-container">
                        <div className="label">Cost %</div>
                        <ValidationInput
                            theme={ ValidationInputTheme.BasicInverted }
                            type="text"
                            step="any"
                            label={ null }
                            hintText=""
                            value={ costPercentageValue }
                            autoFocus={ false }
                            autoComplete={ null }
                            isPercentInput={ true }
                            isValid={ validationInputDataByFieldName.costPercentage.isValid }
                            isDisabled={ costPercentageValue === NOT_CALCULABLE_FIELD_VALUE }
                            errorMessage={ validationInputDataByFieldName.costPercentage.errorMessage }
                            inputClassName=""
                            handleEnterClick={ this.handleOnCostPercentageEnterOrBlur }
                            handleChange={ this.handleOnCostPercentageChange }
                            handleBlur={ this.handleOnCostPercentageEnterOrBlur }
                            handleFocus={ null }
                        />
                    </div>
                    { retailerTaxPercentage !== null &&
                        <div className="sales-price-and-tax input-container">
                            <div className="flex-text-with-icon middle">
                                <div className="label">Price + Tax</div>
                                <Popover
                                    className="sales-price-and-tax-popover"
                                    preferredPositionArray={ ['right', 'above', 'left', 'below'] }
                                    showOnHover={ true }
                                    popoverContentIsShown={ salesPriceAndTaxHintShown }
                                    setPopoverContentIsShown={ this.handleSetSalesPriceAndTaxHintIsShown }
                                    anchorContent={ (
                                        <span className="bevicon bevico-help label icon-right"/>
                                    ) }
                                    popoverContent={ (
                                        <p>
                                            Calculated at tax rate of { formattedRetailerTaxPercentage }. You can update this in
                                            <a href={ `/sales/items/sales_item_manager/r/${ window.GLOBAL_RETAILER_ID }` } target="_blank"> Recipes</a>.
                                        </p>
                                    ) }
                                />
                            </div>
                            <ValidationInput
                                theme={ ValidationInputTheme.BasicInverted }
                                type="text"
                                step="any"
                                label={ null }
                                hintText=""
                                value={ validationInputDataByFieldName.priceAndTax.value }
                                autoFocus={ false }
                                autoComplete={ null }
                                isValid={ validationInputDataByFieldName.priceAndTax.isValid }
                                isDisabled={ false }
                                errorMessage={ validationInputDataByFieldName.priceAndTax.errorMessage }
                                inputClassName=""
                                handleEnterClick={ this.handleOnPriceAndTaxEnterOrBlur }
                                handleChange={ this.handleOnPriceAndTaxChange }
                                handleBlur={ this.handleOnPriceAndTaxEnterOrBlur }
                                handleFocus={ this.doNothing }
                                isCurrencyInput={ true }
                            />
                        </div>
                    }
                    <div className="sales-profit input-container">
                        <div className="label">Sales Profit</div>
                        <ValidationInput
                            theme={ ValidationInputTheme.BasicInverted }
                            type="text"
                            step="any"
                            label={ null }
                            hintText=""
                            value={ salesProfitValue }
                            autoFocus={ false }
                            autoComplete={ null }
                            isValid={ validationInputDataByFieldName.salesProfit.isValid }
                            isDisabled={ salesProfitValue === NOT_CALCULABLE_FIELD_VALUE }
                            errorMessage={ validationInputDataByFieldName.salesProfit.errorMessage }
                            inputClassName=""
                            handleEnterClick={ this.handleOnSalesProfitEnterOrBlur }
                            handleChange={ this.handleOnSalesProfitChange }
                            handleBlur={ this.handleOnSalesProfitEnterOrBlur }
                            handleFocus={ null }
                            isCurrencyInput={ true }
                        />
                    </div>
                    { retailerTaxPercentage === null && <div className="input-container" /> } { /* for spacing reasons so misc and total cost are on the same line */}
                    <div className="misc-cost input-container">
                        <div className="label">Misc. Cost</div>
                        <ValidationInput
                            theme={ ValidationInputTheme.BasicInverted }
                            type="text"
                            step="any"
                            label={ null }
                            hintText="Optional"
                            value={ validationInputDataByFieldName.miscCost.value }
                            autoFocus={ false }
                            autoComplete={ null }
                            isValid={ validationInputDataByFieldName.miscCost.isValid }
                            isDisabled={ false }
                            errorMessage={ validationInputDataByFieldName.miscCost.errorMessage }
                            inputClassName=""
                            handleEnterClick={ this.handleOnMiscCostEnterOrBlur }
                            handleChange={ this.handleOnMiscCostChange }
                            handleBlur={ this.handleOnMiscCostEnterOrBlur }
                            handleFocus={ null }
                            isCurrencyInput={ true }
                        />
                    </div>
                    <div className="total-cost input-container">
                        <div className="label">Total Cost</div>
                        <ValidationInput
                            theme={ ValidationInputTheme.BasicInverted }
                            type="text"
                            step="any"
                            label={ null }
                            hintText=""
                            value={ totalCostValue }
                            autoFocus={ false }
                            autoComplete={ null }
                            isValid={ validationInputDataByFieldName.totalCost.isValid }
                            isDisabled={ true }
                            errorMessage={ validationInputDataByFieldName.totalCost.errorMessage }
                            inputClassName=""
                            handleEnterClick={ null }
                            handleChange={ this.doNothing }
                            handleBlur={ null }
                            handleFocus={ null }
                            isCurrencyInput={ true }
                        />
                    </div>
                </div>
            </div>
        );
    }

    private readonly doNothing = () => {
        // pass
    }

    private readonly handleOnSalesPriceEnterOrBlur = (event : React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
        this.props.onSalesInformationFormFieldBlur('salesPrice', event.currentTarget.value);
    }

    private readonly handleOnSalesPriceChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.onSalesInformationFormFieldChange('salesPrice', event.currentTarget.value);
    }

    private readonly handleOnCostPercentageEnterOrBlur = (event : React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
        this.props.onSalesInformationFormFieldBlur('costPercentage', event.currentTarget.value);
    }

    private readonly handleOnCostPercentageChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.onSalesInformationFormFieldChange('costPercentage', event.currentTarget.value);
    }

    private readonly handleOnSalesProfitEnterOrBlur = (event : React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
        this.props.onSalesInformationFormFieldBlur('salesProfit', event.currentTarget.value);
    }

    private readonly handleOnSalesProfitChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.onSalesInformationFormFieldChange('salesProfit', event.currentTarget.value);
    }

    private readonly handleOnPriceAndTaxEnterOrBlur = (event : React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
        this.props.onSalesInformationFormFieldBlur('priceAndTax', event.currentTarget.value);
    }

    private readonly handleSetSalesPriceAndTaxHintIsShown = (isShown : boolean) => {
        this.props.handleSetSalesPriceAndTaxHintIsShown(isShown);
    }

    private readonly handleOnPriceAndTaxChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.onSalesInformationFormFieldChange('priceAndTax', event.currentTarget.value);
    }

    private readonly handleOnMiscCostEnterOrBlur = (event : React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
        this.props.onSalesInformationFormFieldBlur('miscCost', event.currentTarget.value);
    }

    private readonly handleOnMiscCostChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.onSalesInformationFormFieldChange('miscCost', event.currentTarget.value);
    }
}
