import { StringValueMap } from 'api/Core/StringValueMap';
import { LocationId } from 'api/Location/model/LocationId';
import { MassUnit } from 'api/Product/model/MassUnit';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';
import { SalesQuantityAndUnit } from 'api/SalesItem/model/SalesQuantityAndUnit';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

// NOTE: this is the new sales item model (from thrift/java/postgres), does not support the old model (from python)
export class SalesItem {
    constructor(
        private readonly name : string,
        private readonly locationId : LocationId, // TODO do we want this in the front end model?
        private readonly menuGroup : string,
        private readonly posId : string,
        private readonly note : string,
        private readonly needsAttentionCategory : string | null,
        private readonly salesPrice : number,
        private readonly miscellaneousCost : number,
        private readonly componentQuantityOfProductByProductId : StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>>,
        private readonly componentServingsBySalesItemId : StringValueMap<SalesItemId, number>,
        private readonly itemYield : SalesQuantityAndUnit,
        private readonly servingSize : SalesQuantityAndUnit,
        private readonly salesItemCustomUnitName : string | null
    ) {
        // item yield/serving size/custom unit name check.
        if (salesItemCustomUnitName !== null) {
            if (itemYield.getUnit() !== null || servingSize.getUnit() !== null) {
                throw new RuntimeException('Item yield and serving size must not have unit if a custom unit is defined');
            }
        } else {
            const itemYieldUnit = itemYield.getUnit();
            const servingSizeUnit = servingSize.getUnit();
            if (itemYieldUnit === null || servingSizeUnit === null) {
                throw new RuntimeException('Both yield and serving size must have measurable unit if custom unit is null');
            } else if ((MassUnit.isMassUnit(itemYieldUnit) && !MassUnit.isMassUnit(servingSizeUnit)) || (VolumeUnit.isVolumeUnit(itemYieldUnit) && !VolumeUnit.isVolumeUnit(servingSizeUnit))) {
                throw new RuntimeException('Both yield and serving size should have compatible units');
            }

        }
    }

    public getName() : string {
        return this.name;
    }

    public getLocationId() : LocationId {
        return this.locationId;
    }

    public getMenuGroup() : string {
        return this.menuGroup;
    }

    public getPOSId() : string {
        return this.posId;
    }

    public getNote() : string {
        return this.note;
    }

    public getNeedsAttentionCategory() : string | null {
        return this.needsAttentionCategory;
    }

    public getSalesPrice() : number {
        return this.salesPrice;
    }

    public getMiscellaneousCost() : number {
        return this.miscellaneousCost;
    }

    public getComponentQuantityOfProductByProductId() : StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>> {
        return this.componentQuantityOfProductByProductId;
    }

    public getComponentServingsBySalesItemId() : StringValueMap<SalesItemId, number> {
        return this.componentServingsBySalesItemId;
    }

    public getItemYield() : SalesQuantityAndUnit {
        return this.itemYield;
    }

    public getServingSize() : SalesQuantityAndUnit {
        return this.servingSize;
    }

    public getSalesItemCustomUnitName() : string | null {
        return this.salesItemCustomUnitName;
    }
}
