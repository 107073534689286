import React from 'react';

import './PageTitle.scss';

interface IPageTitleProps {
    heading : string;
    secondaryHeading? : string | JSX.Element;
    darkTheme : boolean;
}

export class PageTitle extends React.Component<IPageTitleProps, object> {
    public render() {
        const {
            heading,
            secondaryHeading,
            darkTheme,
        } = this.props;

        const darkThemeClass = darkTheme ? 'dark' : '';

        return (
            <div className="page-title">
                <div className="page-title-wrapper">
                    <div className={ `${ darkThemeClass } account-title secondary-regular` }>
                        { window.GLOBAL_RETAILER_NAME }
                    </div>
                    <div className="page-name">
                        <strong className={ darkThemeClass }>{ heading }</strong>
                        { secondaryHeading &&
                            <small className={ `${ darkThemeClass } page-details` }>
                                { secondaryHeading }
                            </small>
                        }
                    </div>
                </div>
                { /* Needs to accept children until all of the page headers have the date range dropdowns/searchbars next to the page title removed */ }
                <div className="page-title-children">
                    { this.props.children }
                </div>
            </div>
        );
    }
}
