import { IAction, IActionCreatorsMapObject } from 'shared/models/IAction';

export const ActionTypes = {
    SET_FILE : 'FILE_UPLOAD/SET_FILE',
    RESET_FILE_LIST : 'FILE_UPLOAD/RESET_FILE_LIST',
};

export namespace FileUploadActionInterfaces {
    export interface ISetFile extends IAction {
        payload : {
            id : string,
            index : number,
            file : File | null;
        };
    }

    export interface IResetFileList extends IAction {
        payload : {
            id : string,
        };
    }

    export interface IFileUploadActionCreators extends IActionCreatorsMapObject {
        setFile : (id : string, index : number, file : File | null) => ISetFile;
        resetFileList : (id : string) => IResetFileList; // TODO: this name is misleading. it's really delete file list by id
    }

    export interface IServices {

    }
}

const setFile = (
    id : string,
    index : number,
    file : File | null,
) : FileUploadActionInterfaces.ISetFile => ({
    payload : {
        id,
        index,
        file,
    },
    type : ActionTypes.SET_FILE,
});

const resetFileList = (
    id : string,
) : FileUploadActionInterfaces.IResetFileList => ({
    payload : {
        id,
    },
    type : ActionTypes.RESET_FILE_LIST,
});

export const FileUploadActions : FileUploadActionInterfaces.IFileUploadActionCreators = {
    setFile,
    resetFileList,
};
