import { IAdminService } from 'api/Admin/interfaces/IAdminService';
import { LocationId } from 'api/Location/model/LocationId';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { AjaxUtils } from 'shared/utils/ajaxUtils';

export class AdminServiceImpl implements IAdminService {
    public createLocations(
        userFirstName : string,
        userLastName : string,
        userId : string,
        userEmail : string,
        userPhone : string,
        beverageRetailerName : string | null,
        foodRetailerName : string | null,
        addressLine1 : string,
        addressLine2 : string,
        city : string,
        state : string,
        country : string,
        zipcode : string,
        timezone : string, // TODO type timezones if possible
    ) {
        if ((beverageRetailerName === null) && (foodRetailerName === null)) {
            throw new RuntimeException('beverageRetailerName and foodRetailerName cannot both be null');
        }

        const formData : FormData = new FormData();
        formData.append('first', userFirstName);
        formData.append('last', userLastName);
        formData.append('creation_user_id', userId);
        formData.append('email', userEmail);
        formData.append('phone', userPhone);
        formData.append('line1', addressLine1);
        formData.append('line2', addressLine2);
        formData.append('city', city);
        formData.append('state', state);
        formData.append('country', country);
        formData.append('zipcode', zipcode);
        formData.append('timezone', timezone);

        if (beverageRetailerName) {
            formData.append('beverage_retailer_name', beverageRetailerName);
        }

        if (foodRetailerName) {
            formData.append('food_retailer_name', foodRetailerName);
        }

        return AjaxUtils.ajaxPostForm(urlWithoutRetailerId('admin_tools:manage:create_retailer_for_user'), formData)
        .then((response : { beverage_retailer_id : string | null, food_retailer_id : string | null }) => {
            const {
                beverage_retailer_id,
                food_retailer_id,
            } = response;

            return {
                beverageLocationId: beverage_retailer_id === null ? null : new LocationId(beverage_retailer_id),
                foodLocationId: food_retailer_id === null ? null : new LocationId(food_retailer_id),
            };
        });
    }
}
