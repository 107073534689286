import InventoryTransferReportModel from 'gen-thrift/inventory_transfer_report_Model_types';
import ProductModel from 'gen-thrift/product_Model_types';
import LocationModel from 'gen-thrift/location_Model_types';

import { LocationObjectToThriftSerializer } from 'api/Location/serializer/LocationObjectToThriftSerializer';
import { ProductAmount } from 'api/Product/model/ProductAmount';
import { ProductObjectToThriftSerializer } from 'api/Product/serializer/ProductObjectToThriftSerializer';
import { TransferDirection } from 'api/Transfer/model/TransferDirection';
import { TransferReportData } from 'api/Transfer/model/TransferReportData';
import { TransferId } from 'api/Transfer/model/TransferId';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { LocationId } from 'api/Location/model/LocationId';

export class TransferObjectToThriftSerializer {

    constructor(
        private locationObjectToThriftSerializer : LocationObjectToThriftSerializer,
        private productObjectToThriftSerializer : ProductObjectToThriftSerializer
    ) {}

    ////////////////////////////////////
    // MODELS
    ////////////////////////////////////

    public getThriftTransferId(
        transferId : TransferId,
    ) : InventoryTransferReportModel.InventoryTransferReportIdentifier {
        return new InventoryTransferReportModel.InventoryTransferReportIdentifier({ value : transferId.getValue() });
    }

    public getThriftTransferDirection(
        transferDirection : TransferDirection,
    ) : InventoryTransferReportModel.TransferDirection {
        switch (transferDirection) {
            case TransferDirection.FROM_PARTNER_TO_PERSPECTIVE_LOCATION:
                return InventoryTransferReportModel.TransferDirection.FROM_PARTNER_TO_PERSPECTIVE_LOCATION;
            case TransferDirection.FROM_PERSPECTIVE_TO_PARTNER_LOCATION:
                return InventoryTransferReportModel.TransferDirection.FROM_PERSPECTIVE_TO_PARTNER_LOCATION;
            default:
                throw new RuntimeException('Unknown transfer direction');
        }
    }

    public getThriftTransferReportInfo(
        transferReportData : TransferReportData,
    ) : InventoryTransferReportModel.InventoryTransferReportInfo {
        const partnerLocation = transferReportData.getPartnerLocation();
        const thriftProductIdsAndQuantityOfProducts = this.getThriftProductIdsAndQuantityOfProducts(transferReportData.getProductAmounts());

        let thriftPartnerLocation : LocationModel.LocationIdentifier | null = null;
        let customPartnerLocationName : string | null = null;
        if (partnerLocation instanceof LocationId) {
            thriftPartnerLocation = this.locationObjectToThriftSerializer.getThriftLocationId(partnerLocation);
        } else {
            customPartnerLocationName = partnerLocation;
        }

        return new InventoryTransferReportModel.InventoryTransferReportInfo({
            perspectiveLocation: this.locationObjectToThriftSerializer.getThriftLocationId(transferReportData.getPerspectiveLocation()),
            productAmounts: thriftProductIdsAndQuantityOfProducts,
            partnerLocation: thriftPartnerLocation,
            customPartnerLocationName,
            direction: this.getThriftTransferDirection(transferReportData.getDirection()),
            transferTime: transferReportData.getTime(),
            note: transferReportData.getNote(),
            requestedByUser: null,
            requestedTime: null,
            sentByUser: null,
            sentTime: null,
            receivedByUser: null,
            receivedTime: null,
            cancellationTime: null,
            cancelledByUser: null,
            cancellationReason: null,
        });
    }

    ////////////////////////////////////
    // COLLECTIONS
    ////////////////////////////////////

    public getThriftTransferIds(
        transferIds : Array<TransferId>
    ) : Array<InventoryTransferReportModel.InventoryTransferReportIdentifier> {
        return transferIds.map((transferId) => {
            return this.getThriftTransferId(transferId);
        });
    }

    public getThriftProductIdsAndQuantityOfProducts(
        productAmounts : Array<ProductAmount>
    ) : Array<ProductModel.ProductIdAndQuantityOfProduct> {
        return productAmounts.map((productAmount) => {
            return this.productObjectToThriftSerializer.getThriftProductIdAndQuantityOfProduct(productAmount);
        });
    }
}
