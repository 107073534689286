import CoreTimeModel from 'gen-thrift/core_time_Model_types';

import { PublicContentTypeEnum } from './PublicContentTypeEnum';
import { PublicContentUrl } from './PublicContentUrl';

export class PublicContent {
    private publicContentUrl : PublicContentUrl;
    private publicContentType : PublicContentTypeEnum;
    private title : string;
    private excerpt : string;
    private thumbnailUrl : string;
    private publicationDate : CoreTimeModel.TimestampWithSecondPrecision;

    constructor(
        publicContentUrl : PublicContentUrl,
        publicContentType : PublicContentTypeEnum,
        title : string,
        excerpt : string,
        thumbnailUrl : string,
        publicationDate : CoreTimeModel.TimestampWithSecondPrecision,
    ) {
        this.publicContentUrl = publicContentUrl;
        this.publicContentType = publicContentType;
        this.title = title;
        this.excerpt = excerpt;
        this.thumbnailUrl = thumbnailUrl;
        this.publicationDate = publicationDate;
    }

    public getPublicContentUrl() : PublicContentUrl {
        return this.publicContentUrl;
    }

    public getPublicContentType() : PublicContentTypeEnum {
        return this.publicContentType;
    }

    public getTitle() : string {
        return this.title;
    }

    public getExcerpt() : string {
        return this.excerpt;
    }

    public getThumbnailUrl() : string {
        return this.thumbnailUrl;
    }

    public getPublicationDate() : CoreTimeModel.TimestampWithSecondPrecision {
        return this.publicationDate;
    }
}
