import { StringValueMap } from 'api/Core/StringValueMap';
import { IDistributorService } from 'api/Distributor/interfaces/IDistributorService';
import ProductModel from 'gen-thrift/product_Model_types';
import ThriftProductService from 'gen-thrift/ProductService';

import { DistributorId } from 'api/Distributor/model/DistributorId';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { ProductId } from '../model/ProductId';

import { DistributorObjectToThriftSerializer } from 'api/Distributor/serializer/DistributorObjectToThriftSerializer';
import { DistributorThriftToObjectSerializer } from 'api/Distributor/serializer/DistributorThriftToObjectSerializer';
import { IProductDistributorService } from 'api/Product/interfaces/IProductDistributorService';
import { ProductObjectToThriftSerializer } from 'api/Product/serializer/ProductObjectToThriftSerializer';
import { ProductThriftToObjectSerializer } from 'api/Product/serializer/ProductThriftToObjectSerializer';
import { UserAccountObjectToThriftSerializer } from 'api/UserAccount/serializer/UserAccountObjectToThriftSerializer';

export class ProductDistributorServiceImpl implements IProductDistributorService {

    constructor(
        private readonly thriftProductClient : ThriftProductService.ProductServiceClient,
        private readonly productObjectToThriftSerializer : ProductObjectToThriftSerializer,
        private readonly productThriftToObjectSerializer : ProductThriftToObjectSerializer,
        private readonly distributorObjectToThriftSerializer : DistributorObjectToThriftSerializer,
        private readonly distributorThriftToObjectSerializer : DistributorThriftToObjectSerializer,
        private readonly userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer,
        private readonly distributorService : IDistributorService,
    ) { }

    public retrieveDistributorIdsByProductIdForProductIds (
        userSessionId : UserSessionId,
        productIds : Array<ProductId>
    ) : Promise<StringValueMap<ProductId, DistributorId | null>> {
        return new Promise<StringValueMap<ProductId, DistributorId | null>>((resolve, reject) => {
            this.thriftProductClient.retrieveDistributorIdsAssociatedWithProductIds(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                productIds.map((productId : ProductId) => {
                    return this.productObjectToThriftSerializer.getThriftProductId(productId);
                }),
                (result : Array<ProductModel.ProductIdAndDistributorId> | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    } else {
                        const distributorIdsByProductId : StringValueMap<ProductId, DistributorId | null> = new StringValueMap();
                        result.forEach((distributorIdAndProductId : ProductModel.ProductIdAndDistributorId) => {
                            let distributorId : DistributorId | null = null;
                            if (distributorIdAndProductId.distributorId !== null) {
                                distributorId = this.distributorThriftToObjectSerializer.getDistributorId(distributorIdAndProductId.distributorId);
                            }

                            distributorIdsByProductId.set(
                                this.productThriftToObjectSerializer.getProductId(distributorIdAndProductId.productId),
                                distributorId,
                            );
                        });

                        return resolve(distributorIdsByProductId);
                    }
                }
            );
        });
    }

    public async associateDistributorIdWithProductId (
        userSessionId : UserSessionId,
        productId : ProductId,
        distributorId : DistributorId
    ) : Promise<void> {
        await this.distributorService.addDistributorRelationshipForCurrentRetailer(distributorId);

        return new Promise<void>((resolve, reject) => {
            this.thriftProductClient.associateDistributorIdWithProductId(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.productObjectToThriftSerializer.getThriftProductId(productId),
                this.distributorObjectToThriftSerializer.getThriftDistributorId(distributorId),
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    } else {
                        return resolve();
                    }
                }
            );
        });
    }

    public disassociateDistributorIdFromProductId (
        userSessionId : UserSessionId,
        productId : ProductId,
        distributorId : DistributorId
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.thriftProductClient.disassociateDistributorIdFromProductId(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.productObjectToThriftSerializer.getThriftProductId(productId),
                this.distributorObjectToThriftSerializer.getThriftDistributorId(distributorId),
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    } else {
                        return resolve();
                    }
                }
            );
        });
    }

    public async associateDistributorIdWithProductIds (
        userSessionId : UserSessionId,
        productIds : Array<ProductId>,
        distributorId : DistributorId
    ) : Promise<void> {
        await this.distributorService.addDistributorRelationshipForCurrentRetailer(distributorId);

        return new Promise<void>((resolve, reject) => {
            this.thriftProductClient.associateDistributorIdWithProductIds(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                productIds.map((productId : ProductId) => {
                    return this.productObjectToThriftSerializer.getThriftProductId(productId);
                }),
                this.distributorObjectToThriftSerializer.getThriftDistributorId(distributorId),
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    } else {
                        return resolve();
                    }
                }
            );
        });
    }

    public disassociateDistributorIdFromProductIds (
        userSessionId : UserSessionId,
        productIds : Array<ProductId>,
    ) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.thriftProductClient.disassociateDistributorIdFromProductIds(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                productIds.map((productId : ProductId) => {
                    return this.productObjectToThriftSerializer.getThriftProductId(productId);
                }),
                (result : void | Error) => {
                    if (result instanceof Error) {
                        return reject(result);
                    } else {
                        return resolve();
                    }
                }
            );
        });
    }
}
