// Types
import { IColumnSorting } from 'shared/components/SortableColumnHeader';
import { DefaultPourInfoByFormFieldName } from '../reducers';

// Utilities
import { SortingFilteringAndGroupingUtil } from 'shared/utils/sortingFilteringAndGroupingUtils';

const rowComparator = (rowId1 : DefaultPourInfoByFormFieldName, rowId2 : DefaultPourInfoByFormFieldName, columnSort : IColumnSorting) : number => {
    // "Other" category is always going to be last
    if (rowId1.category.value === 'Other') {
        return 1;
    }
    if (rowId2.category.value === 'Other') {
        return -1;
    }
    return rowId1.category.value.localeCompare(rowId2.category.value);
};

const rowFilter = (rowId : DefaultPourInfoByFormFieldName, filterTerm : string | null) : boolean => {
    return true;
};

const groupNameGetter = () : string => {
    return 'sortedDefaultPourCategories';
};

const groupNameComparator = () : number => {
    return 0;
};

const intermediateResultCacheIsValid = (rowIds : Array<DefaultPourInfoByFormFieldName>, lastRowIds : Array<DefaultPourInfoByFormFieldName>) => {
    return (rowIds === lastRowIds);
};

export const defaultPourCategoriesSortingGroupingFilteringUtil = new SortingFilteringAndGroupingUtil<DefaultPourInfoByFormFieldName, null, string>(
    rowComparator,
    rowFilter,
    groupNameGetter,
    groupNameComparator,
    intermediateResultCacheIsValid
);
