import React from 'react';

import { CalendarWeek } from '../../models/CalendarWeek';
import { DateTime } from '../../models/DateTime';
import { DayOfWeekName } from '../../models/DayOfWeekName';
import { MonthOfYear } from '../../models/MonthOfYear';

import { BevSpotDatePickerUtils } from './utils';

export interface IBevSpotDatePickerCalendarInputRow {
    calendarWeek : CalendarWeek;
    selectedDateTime : DateTime;
    setSelectedDateTime : (dateTime : DateTime) => void;
    visibleMonth : MonthOfYear;
    visibleYear : number;
}

export class BevSpotDatePickerCalendarInputRow extends React.Component<IBevSpotDatePickerCalendarInputRow, object> {
    public render() {
        const selectedDateTimeYear : number = BevSpotDatePickerUtils.getYearAsNumberFromDateTime(this.props.selectedDateTime);
        const selectedDateTimeNumericalDayOfMonth : number = BevSpotDatePickerUtils.getNumericalDayOfMonthForDateTime(this.props.selectedDateTime);
        const selectedDateTimeMonthAsNumber : number = BevSpotDatePickerUtils.getMonthAsNumberFromDateTime(this.props.selectedDateTime);
        const selectedDateTimeIsVisible : boolean = (selectedDateTimeYear === this.props.visibleYear) && (selectedDateTimeMonthAsNumber === this.props.visibleMonth);
        const dayOfWeekNames : Array<DayOfWeekName> = BevSpotDatePickerUtils.getDayOfWeekNames();
        const dayOfWeekElements = dayOfWeekNames.map((dayOfWeekName : DayOfWeekName, index : number) => {
            const numericalDayOfMonth : number | null = this.props.calendarWeek.getNumericalDayOfMonthFromDayOfWeekName(dayOfWeekName);
            if (numericalDayOfMonth !== null) {
                const isSelected = selectedDateTimeIsVisible && selectedDateTimeNumericalDayOfMonth === numericalDayOfMonth;
                return (
                    <div
                        key={ index }
                        className={ isSelected ? 'day-of-month selected' : 'day-of-month' }
                        onClick={ this.handleSetSelectedDateTime(numericalDayOfMonth, isSelected) }
                    >
                        <span className="day-of-month-number">{ numericalDayOfMonth }</span>
                    </div>
                );
            }
            return (
                <div key={ index } className="day-of-month empty">
                    <span />
                </div>
            );
        });
        return (
            <div className="bevspot-datepicker-calendar-input-row">
                { dayOfWeekElements }
            </div>
        );
    }
    private handleSetSelectedDateTime = (day : number, isSelected : boolean) => {
        if (!isSelected) {
            return () => {
                const updatedDateTime : DateTime = BevSpotDatePickerUtils.updateDateTimeWithYearMonthAndDay(
                    this.props.selectedDateTime,
                    day,
                    this.props.visibleMonth,
                    this.props.visibleYear);
                this.props.setSelectedDateTime(updatedDateTime);
            };
        }
    }
}
