export class NormalizedLineItem {
    constructor (
        private readonly id : string,
        private readonly brand : string,
        private readonly name : string,
        private readonly sku : string,
        private readonly category : string,
        // private readonly tags : object, TODO not sure what this is so leaving it off for now
    ) {}

    public getId() : string {
        return this.id;
    }

    public getBrand() : string {
        return this.brand;
    }

    public getName() : string {
        return this.name;
    }

    public getSku() : string {
        return this.sku;
    }

    public getCategory() : string {
        return this.category;
    }
}
