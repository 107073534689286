import { InvalidCookieNameException } from '../exceptions/InvalidCookieNameException';
import { UnknownCookieException } from '../exceptions/UnknownCookieException';

import { ICookieDeleter } from '../interfaces/ICookieDeleter';
import { ICookieReader } from '../interfaces/ICookieReader';
import { ICookieSetter } from '../interfaces/ICookieSetter';

export class CookieManagerImpl implements
        ICookieDeleter, ICookieReader, ICookieSetter {

    constructor() {
        // TODO Do we need this
    }

    public getCookie(name : string) : string {
        if (!name.length) {
            throw new InvalidCookieNameException();
        }

        // Retool API will set window.userSessionIdentifier for use in Python API calls
        if (
            name === 'userSessionIdentifier' &&
            typeof window.userSessionIdentifier !== 'undefined' &&
            window.userSessionIdentifier
        ) {
            return window.userSessionIdentifier;
        }

        const token = name + '=';
        const cookieList = document.cookie.split(';');

        for (let i = 0; i < cookieList.length; i++) {
            let cookie = cookieList[i];

            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(token) === 0) {
                const value = cookie.substring(token.length, cookie.length);

                if (value === 'undefined' || value === 'null') {
                    throw new UnknownCookieException(value + ' cookie value');
                }

                // Part of migration
                if ((document.domain === 'app.bevspot.com') || (document.domain === 'usertest.bevspot.net')) {
                    this.setCookie(name, value);
                }

                return value;
            }
        }

        throw new UnknownCookieException('no cookies found');
    }

    public setCookie(name : string, value : string, expiresInSeconds? : number) : void {
        if (!name.length) {
            throw new InvalidCookieNameException();
        }
        const date = new Date();
        date.setTime(date.getTime() + ((expiresInSeconds || 100 * 365 * 24 * 60 * 60) * 1000)); // default to 100 years in milliseconds

        this.documentCookieSetter(name, value, date);
    }

    public deleteCookie(name : string) : void {
        if (!name.length) {
            throw new InvalidCookieNameException();
        }

        this.documentCookieSetter(name, '', new Date(1970, 1, 1, 0, 0, 1));
    }

    // duplicates logic from src/main/web/user_auth/src/ui/lib/Utils.js setCookie
    private documentCookieSetter(name : string, value : string , expirationDate : Date) : void {
        const firstSecondOfEpoch : Date = new Date(1970, 1, 1, 0, 0, 1); // Part of migration

        let cookieDomain = document.domain;
        if (cookieDomain.slice(-12) === '.bevspot.com') {
            document.cookie = name + '=; Path=/; Domain=' + cookieDomain + '; expires=' + firstSecondOfEpoch.toUTCString(); // Part of migration
            cookieDomain = '.bevspot.com';
        } else if (cookieDomain.slice(-12) === '.bevspot.net') {
            document.cookie = name + '=; Path=/; Domain=' + cookieDomain + '; expires=' + firstSecondOfEpoch.toUTCString(); // Part of migration
            cookieDomain = '.bevspot.net';
        }

        const expires = 'expires=' + expirationDate.toUTCString();
        document.cookie = name + '=' + value + '; Path=/; Domain=' + cookieDomain + '; ' + expires;
    }
}
