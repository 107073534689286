//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';



var DistributorModel = {};
export default DistributorModel;
DistributorModel.ScopeType = {
  '0' : 'RETAILER',
  'RETAILER' : 0,
  '1' : 'GROUP',
  'GROUP' : 1,
  '2' : 'GLOBAL',
  'GLOBAL' : 2
};
DistributorModel.Scope = function(args) {
  this.type = null;
  this.id = null;
  if (args) {
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field type is unset!');
    }
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
  }
};
DistributorModel.Scope.prototype = {};
DistributorModel.Scope.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.type = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorModel.Scope.prototype.write = function(output) {
  output.writeStructBegin('Scope');
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.I32, 1);
    output.writeI32(this.type);
    output.writeFieldEnd();
  }
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 2);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorModel.DistributorId = function(args) {
  this.value = null;
  if (args) {
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field value is unset!');
    }
  }
};
DistributorModel.DistributorId.prototype = {};
DistributorModel.DistributorId.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorModel.DistributorId.prototype.write = function(output) {
  output.writeStructBegin('DistributorId');
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 1);
    output.writeString(this.value);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorModel.Distributor = function(args) {
  this.name = null;
  this.shortName = null;
  this.owner = null;
  if (args) {
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field name is unset!');
    }
    if (args.shortName !== undefined && args.shortName !== null) {
      this.shortName = args.shortName;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field shortName is unset!');
    }
    if (args.owner !== undefined && args.owner !== null) {
      this.owner = new DistributorModel.Scope(args.owner);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field owner is unset!');
    }
  }
};
DistributorModel.Distributor.prototype = {};
DistributorModel.Distributor.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.shortName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.owner = new DistributorModel.Scope();
        this.owner.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorModel.Distributor.prototype.write = function(output) {
  output.writeStructBegin('Distributor');
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 1);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.shortName !== null && this.shortName !== undefined) {
    output.writeFieldBegin('shortName', Thrift.Type.STRING, 2);
    output.writeString(this.shortName);
    output.writeFieldEnd();
  }
  if (this.owner !== null && this.owner !== undefined) {
    output.writeFieldBegin('owner', Thrift.Type.STRUCT, 3);
    this.owner.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorModel.DistributorRegionId = function(args) {
  this.value = null;
  if (args) {
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field value is unset!');
    }
  }
};
DistributorModel.DistributorRegionId.prototype = {};
DistributorModel.DistributorRegionId.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorModel.DistributorRegionId.prototype.write = function(output) {
  output.writeStructBegin('DistributorRegionId');
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 1);
    output.writeString(this.value);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorModel.DistributorIdAndDistributor = function(args) {
  this.distributorId = null;
  this.distributor = null;
  if (args) {
    if (args.distributorId !== undefined && args.distributorId !== null) {
      this.distributorId = new DistributorModel.DistributorId(args.distributorId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field distributorId is unset!');
    }
    if (args.distributor !== undefined && args.distributor !== null) {
      this.distributor = new DistributorModel.Distributor(args.distributor);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field distributor is unset!');
    }
  }
};
DistributorModel.DistributorIdAndDistributor.prototype = {};
DistributorModel.DistributorIdAndDistributor.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.distributorId = new DistributorModel.DistributorId();
        this.distributorId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.distributor = new DistributorModel.Distributor();
        this.distributor.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorModel.DistributorIdAndDistributor.prototype.write = function(output) {
  output.writeStructBegin('DistributorIdAndDistributor');
  if (this.distributorId !== null && this.distributorId !== undefined) {
    output.writeFieldBegin('distributorId', Thrift.Type.STRUCT, 1);
    this.distributorId.write(output);
    output.writeFieldEnd();
  }
  if (this.distributor !== null && this.distributor !== undefined) {
    output.writeFieldBegin('distributor', Thrift.Type.STRUCT, 2);
    this.distributor.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DistributorModel.DistributorIdAndRegionId = function(args) {
  this.distributorId = null;
  this.regionId = null;
  if (args) {
    if (args.distributorId !== undefined && args.distributorId !== null) {
      this.distributorId = new DistributorModel.DistributorId(args.distributorId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field distributorId is unset!');
    }
    if (args.regionId !== undefined && args.regionId !== null) {
      this.regionId = new DistributorModel.DistributorRegionId(args.regionId);
    }
  }
};
DistributorModel.DistributorIdAndRegionId.prototype = {};
DistributorModel.DistributorIdAndRegionId.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.distributorId = new DistributorModel.DistributorId();
        this.distributorId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.regionId = new DistributorModel.DistributorRegionId();
        this.regionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DistributorModel.DistributorIdAndRegionId.prototype.write = function(output) {
  output.writeStructBegin('DistributorIdAndRegionId');
  if (this.distributorId !== null && this.distributorId !== undefined) {
    output.writeFieldBegin('distributorId', Thrift.Type.STRUCT, 1);
    this.distributorId.write(output);
    output.writeFieldEnd();
  }
  if (this.regionId !== null && this.regionId !== undefined) {
    output.writeFieldBegin('regionId', Thrift.Type.STRUCT, 2);
    this.regionId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

