import React from 'react';

import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { IOption } from 'shared/components/Dropdown/DropdownMenu';
import { Select2DropdownMenu } from 'shared/components/Select2Dropdown/Select2DropdownMenu';
import { ValidationInput } from 'shared/components/ValidationInput';
import { productJSONToObjectSerializer, productObjectToJSONSerializer } from 'shared/lib/manager';

import './ParInput.scss';

export interface IParInputProps {
    readonly product : Product;
    readonly productId : ProductId; 
    readonly parCount : number | null;
    readonly parProductQuantityUnit : ProductQuantityUnit;
    readonly onProductQuantityUnitSelect : (productQuantityUnit : ProductQuantityUnit) => void;
    readonly handleValueChange : (parCount : number | null) => void;
}

export class ParInput extends React.Component<IParInputProps, object> {
    private readonly classNamePrefix = 'total-count-par-input';

    public render() {
        const {
            product,
            parCount,
            parProductQuantityUnit,
        } = this.props;

        const unitSortedOptionsAndLabelName = PackagingUtils.getAvailableUnitOptionsAndLabelNames(product.getPackagingsAndMappings(), false);
        let unitSelectedIOption : IOption | null = null;
        unitSortedOptionsAndLabelName.forEach((unitOptionsAndLabelName) => {
            unitOptionsAndLabelName[1].forEach((unitOption : IOption) => {
                if (unitOption.value === productObjectToJSONSerializer.getProductQuantityUnit(parProductQuantityUnit)) {
                    unitSelectedIOption = unitOption;
                }
            });
        });
        
        const value : string = (parCount === null) ? '' : parCount.toString();

        return (
            <div
                className={ `${ this.classNamePrefix }-container` }
            >
                <ValidationInput
                    type="number"
                    step="any"
                    min={ 0 }
                    label={ null }
                    hintText={ null }
                    value={ value }
                    autoFocus={ false }
                    autoComplete={ null }
                    isValid={ true }
                    isDisabled={ false }
                    errorMessage=""
                    inputClassName=""
                    handleEnterClick={ this.doNothing }
                    handleChange={ this.handleChange }
                    handleBlur={ this.doNothing }
                    handleFocus={ null }
                />
                <Select2DropdownMenu
                    isSearchable={ false }
                    buttonShouldDisplaySelectedLabel={ true }
                    selectedOptionHeaderShown={ false }
                    sortedOptionsAndLabelName={ unitSortedOptionsAndLabelName }
                    selectedOption={ unitSelectedIOption }
                    onOptionSelect={ this.onUnitSelect }
                    placeholderText="Select Unit"
                    emptyState={ null }
                    hasCreateCustomOption={ false }
                    createCustomOption={ this.doNothing }
                    customOptionGroupLabel={ null }
                    createCustomOptionButtonLabel={ null }
                />
            </div>
        );
    }

    private readonly handleChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const {
            handleValueChange,
        } = this.props;

        const value : string = event.currentTarget.value;
        if (value) {
            handleValueChange(parseFloat(value));
        } else {
            handleValueChange(null);
        }
    }

    private readonly onUnitSelect = (optionValue : string) => {
        const productQuantityUnit = productJSONToObjectSerializer.getProductQuantityUnit(optionValue);

        this.props.onProductQuantityUnitSelect(productQuantityUnit);
    }

    private readonly doNothing = () => null;
}
