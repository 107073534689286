import CoreTimeModel from 'gen-thrift/core_time_Model_types';
import InventoryModel from 'gen-thrift/inventory_Model_types';
import InventoryCurrentConfigurationService from 'gen-thrift/InventoryCurrentConfigurationService';

import { IOfflineQueueManager } from 'api/Core/OfflineQueueManager/interfaces/IOfflineQueueManager';
import { ICurrentInventoryConfigurationService } from 'api/InventoryCount/interfaces/ICurrentInventoryConfigurationService';
import { InventoryConfiguration } from 'api/InventoryCount/model/InventoryConfiguration';
import { InventoryCountId } from 'api/InventoryCount/model/InventoryCountId';
import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';
import { InventoryCountObjectToThriftSerializer } from 'api/InventoryCount/serializer/InventoryCountObjectToThriftSerializer';
import { InventoryCountThriftToObjectSerializer } from 'api/InventoryCount/serializer/InventoryCountThriftToObjectSerializer';
import { LocationId } from 'api/Location/model/LocationId';
import { LocationObjectToThriftSerializer } from 'api/Location/serializer/LocationObjectToThriftSerializer';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductObjectToThriftSerializer } from 'api/Product/serializer/ProductObjectToThriftSerializer';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { UserAccountObjectToThriftSerializer } from 'api/UserAccount/serializer/UserAccountObjectToThriftSerializer';
import {ThriftApiUtils} from "api/ApiUtils";

export class CurrentInventoryConfigurationServiceImpl implements ICurrentInventoryConfigurationService {

    constructor (
        private inventoryCurrentCountServiceClient : InventoryCurrentConfigurationService.InventoryCurrentConfigurationServiceClient,
        private offlineQueueManager : IOfflineQueueManager,
        private userAccountObjectToThriftSerializer : UserAccountObjectToThriftSerializer,
        private locationObjectToThriftSerializer : LocationObjectToThriftSerializer,
        private productObjectToThriftSerializer : ProductObjectToThriftSerializer,
        private inventoryCountObjectToThriftSerializer : InventoryCountObjectToThriftSerializer,
        private inventoryCountThriftToObjectSerializer : InventoryCountThriftToObjectSerializer,
    ) {}

    public getCurrentInventoryConfigurationForLocation (
        userSessionId : UserSessionId,
        locationId : LocationId,
    ) : Promise<InventoryConfiguration> {
        return new Promise<InventoryConfiguration>((resolve, reject) => {
            this.inventoryCurrentCountServiceClient.getCurrentInventoryConfigurationForLocation(
                this.userAccountObjectToThriftSerializer.getThriftUserSessionId(userSessionId),
                this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                (result : InventoryModel.InventoryConfiguration | Error) => {
                     if (result instanceof Error) {
                         return reject(result);
                     }
                     return resolve(this.inventoryCountThriftToObjectSerializer.getInventoryConfiguration(result));
                 }
            );
        });
    }

    public updateStorageArea (
        userSessionId : UserSessionId,
        storageAreaId : StorageAreaId,
        storageAreaName : string,
        storageAreaNote : string,
        clientTimestamp : CoreTimeModel.TimestampWithMillisecondPrecision,
    ) : Promise<void> {
        return ThriftApiUtils.sessionSafe(
            (sessionId) =>
                new Promise<void>((resolve, reject) => {
                    this.inventoryCurrentCountServiceClient.updateStorageArea(
                        this.userAccountObjectToThriftSerializer.getThriftUserSessionId(sessionId),
                        this.inventoryCountObjectToThriftSerializer.getThriftStorageAreaId(storageAreaId),
                        storageAreaName,
                        storageAreaNote,
                        clientTimestamp,
                        (result : InventoryModel.StorageAreaId | Error) => {
                            if (result instanceof Error) {
                                return reject(result);
                            }
                            return resolve();
                        }
                    );
                })
        );
    }

    public createAndAddStorageArea (
        userSessionId : UserSessionId,
        locationId : LocationId,
        storageAreaName : string,
        storageAreaNote : string,
        clientTimestamp : CoreTimeModel.TimestampWithMillisecondPrecision,
    ) : Promise<StorageAreaId> {
        return ThriftApiUtils.sessionSafe(
            (sessionId) =>
                new Promise<StorageAreaId>((resolve, reject) => {
                    this.inventoryCurrentCountServiceClient.createAndAddStorageArea(
                        this.userAccountObjectToThriftSerializer.getThriftUserSessionId(sessionId),
                        storageAreaName,
                        storageAreaNote,
                        this.locationObjectToThriftSerializer.getThriftLocationId(locationId),
                        clientTimestamp,
                        (result : InventoryModel.StorageAreaId | Error) => {
                            if (result instanceof Error) {
                                return reject(result);
                            }
                            return resolve(this.inventoryCountThriftToObjectSerializer.getStorageAreaId(result));
                        }
                    );
                })
        );
    }

    public removeStorageArea (
        userSessionId : UserSessionId,
        storageAreaId : StorageAreaId,
        clientTimestamp : CoreTimeModel.TimestampWithMillisecondPrecision,
    ) : Promise<void> {
        return ThriftApiUtils.sessionSafe(
            (sessionId) =>
                new Promise<void>((resolve, reject) => {
                    this.inventoryCurrentCountServiceClient.removeStorageArea(
                        this.userAccountObjectToThriftSerializer.getThriftUserSessionId(sessionId),
                        this.inventoryCountObjectToThriftSerializer.getThriftStorageAreaId(storageAreaId),
                        clientTimestamp,
                        (result : void | Error) => {
                            if (result instanceof Error) {
                                return reject(result);
                            }
                            return resolve();
                        }
                    );
                })

        );
    }

    public addProductToStorageAreaAndSetAsLastForCustomSort (
        userSessionId : UserSessionId,
        productId : ProductId,
        storageAreaId : StorageAreaId,
        clientTimestamp : CoreTimeModel.TimestampWithMillisecondPrecision,
    ) : Promise<void> {
        return ThriftApiUtils.sessionSafe(
            (sessionId) =>
                new Promise<void>((resolve, reject) => {
                    this.inventoryCurrentCountServiceClient.addProductToStorageArea(
                        this.userAccountObjectToThriftSerializer.getThriftUserSessionId(sessionId),
                        this.productObjectToThriftSerializer.getThriftProductId(productId),
                        this.inventoryCountObjectToThriftSerializer.getThriftStorageAreaId(storageAreaId),
                        clientTimestamp,
                        (result : void | Error) => {
                            if (result instanceof Error) {
                                return reject(result);
                            }
                            return resolve();
                        }
                    );
                })
        );
    }

    public removeProductFromStorageArea (
        userSessionId : UserSessionId,
        productId : ProductId,
        storageAreaId : StorageAreaId,
        clientTimestamp : CoreTimeModel.TimestampWithMillisecondPrecision,
    ) : Promise<void> {
        return ThriftApiUtils.sessionSafe(
            (sessionId) =>
                new Promise<void>((resolve, reject) => {
                    this.inventoryCurrentCountServiceClient.removeProductFromStorageArea(
                        this.userAccountObjectToThriftSerializer.getThriftUserSessionId(sessionId),
                        this.productObjectToThriftSerializer.getThriftProductId(productId),
                        this.inventoryCountObjectToThriftSerializer.getThriftStorageAreaId(storageAreaId),
                        clientTimestamp,
                        (result : void | Error) => {
                            if (result instanceof Error) {
                                return reject(result);
                            }
                            return resolve();
                        }
                    );
                })

        );
    }

    public setStorageAreaIndexAfter (
        userSessionId : UserSessionId,
        storageAreaId : StorageAreaId,
        storageAreaIdPreceeding : StorageAreaId | null,
        clientTimestamp : CoreTimeModel.TimestampWithMillisecondPrecision,
    ) : Promise<void> {
        return this.offlineQueueManager.enqueueServiceCall(this, 'setStorageAreaIndexAfter', arguments, () => {
            return ThriftApiUtils.sessionSafe(
                (sessionId) =>
                    new Promise<void>((resolve, reject) => {
                        this.inventoryCurrentCountServiceClient.setStorageAreaIndexAfter(
                            this.userAccountObjectToThriftSerializer.getThriftUserSessionId(sessionId),
                            this.inventoryCountObjectToThriftSerializer.getThriftStorageAreaId(storageAreaId),
                            (storageAreaIdPreceeding) ? this.inventoryCountObjectToThriftSerializer.getThriftStorageAreaId(storageAreaIdPreceeding) : null,
                            (result : void | Error) => {
                                if (result instanceof Error) {
                                    return reject(result);
                                }
                                return resolve();
                            }
                        );
                    })
            );
        });
    }

    public setProductAtStorageAreaIndexAfter (
        userSessionId : UserSessionId,
        productId : ProductId,
        productIdPreceeding : ProductId | null,
        storageAreaId : StorageAreaId,
        inventoryCountId : InventoryCountId,
        clientTimestamp : CoreTimeModel.TimestampWithMillisecondPrecision,
    ) : Promise<void> {
        return this.offlineQueueManager.enqueueServiceCall(this, 'setProductIndexAtStorageArea', arguments, () => {
            return ThriftApiUtils.sessionSafe(
                (sessionId) =>
                    new Promise<void>((resolve, reject) => {
                        this.inventoryCurrentCountServiceClient.setProductAtStorageAreaIndexAfter(
                            this.userAccountObjectToThriftSerializer.getThriftUserSessionId(sessionId),
                            this.productObjectToThriftSerializer.getThriftProductId(productId),
                            (productIdPreceeding) ? this.productObjectToThriftSerializer.getThriftProductId(productIdPreceeding) : null,
                            this.inventoryCountObjectToThriftSerializer.getThriftStorageAreaId(storageAreaId),
                            this.inventoryCountObjectToThriftSerializer.getThriftInventoryCountId(inventoryCountId),
                            (result : void | Error) => {
                                if (result instanceof Error) {
                                    return reject(result);
                                }
                                return resolve();
                            }
                        );
                    })
            );
        });
    }
}
