import * as _ from 'lodash';
import React from 'react';

import { IModalButton } from '../models/Modal';

import { PreventPageScrolling } from 'shared/components/PreventPageScrolling';
import { Button } from './Button';

import 'shared/css/Dialog.scss';

export interface IDialogProps {
    className : string;
    buttons : Array<IModalButton> | null;
    headerText : JSX.Element | string | null;
    children? : any;
}

export class Dialog extends React.Component<IDialogProps, object> {
    public render() {
        const {
            className,
            buttons,
            headerText,
        } = this.props;

        const buttonElements = buttons !== null ? _.map(buttons, (button, index) => (
            <Button
                key={ index }
                buttonClassName={ button.classes }
                isDisabled={ button.isDisabled }
                isLoading={ button.isLoading }
                onClick={ button.onClick }
            >
                { button.children }
            </Button>
        )) : null;

        return (
            <div className={ `bevspot-dialog ${ className }` }>
                <PreventPageScrolling
                    preventWindowTouchMove={ true }
                    addModalOpenClass={ true }
                />
                <div className="content">
                    <div className={ `header cell-pt-xl cell-plr` }>
                        <h5 className="title">{ headerText }</h5>
                    </div>
                    <div className={ `body cell-ptb-sm cell-plr` }>
                        { this.props.children }
                    </div>
                    <div className={ `footer cell-ptb cell-plr clearfix text-right` }>
                        { buttonElements }
                    </div>
                </div>
            </div>
        );
    }
}
