import { StringValueMap } from 'api/Core/StringValueMap';
import { IUserName } from 'api/UserAccount/model/IUserName';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserAccountUtils } from 'api/UserAccount/utils/UserAccountUtils';
import React from 'react';

import { Product } from 'api/Product/model/Product';

import '../css/ItemCardHeader.scss';

export interface IItemCardHeaderProps {
    product : Product;
    namesByUserAccountId : StringValueMap<UserAccountId, IUserName>;
}

export class ItemCardHeader extends React.Component<IItemCardHeaderProps, object> {
    public render() {
        const {
            product,
            namesByUserAccountId
        } = this.props;

        return (
            <div className="item-card-header dark">
                <div className="header-top-row">
                    <div className="header-name">
                        <div className="header-brand">{ product.getBrand() }</div>
                        <div className="header-item-name">{ product.getName() }</div>
                    </div>
                </div>
                <div className="header-bottom-row">
                    <div className="header-item-type">
                        <span>{ product.getProductCategoryId() }</span>
                        { product.getProductType() && <span className="chevron">»</span> }
                        <span>{ product.getProductType() }</span>
                    </div>
                </div>
                <div className="header-bottom-row">
                    <div className="contents">
                        <span>{ `Last Edited ${ UserAccountUtils.getUserAccountIdAndTimestampString(product.getLastUpdateEvent(), new UserAccountId(window.GLOBAL_USER_ID), namesByUserAccountId) }` }</span>
                    </div>
                </div>
            </div>
        );
    }
}
