import moment from 'moment-timezone';

export class IntegrationState {
    constructor (
        public readonly externalMerchantId : string | null,
        public readonly isAccessible : boolean,
        public readonly cacheDataEffectiveDate : moment.Moment | null,
    ) { }

    public getExternalMerchantId() : string | null {
        return this.externalMerchantId;
    }

    public getIsAccessible() : boolean {
        return this.isAccessible;
    }

    public getCacheDataEffectiveDate() : moment.Moment | null {
        return this.cacheDataEffectiveDate;
    }
}
