import { HexColor } from 'shared/models/HexColor';

// AxisTick
export const axisTickLineLengthInPixels = 15;
export const axisTickLineColor = new HexColor('#000000');

export const axisTickLabelDistanceFromLineInPixels = 10;
export const axisTickLabelHeightInPixels = 10;

// Axis
export const axisLabelDistanceFromEdgeInPixels = 15;
export const axisLineColor = new HexColor('#555555');
export const axisLineWidthInPixels = 2;

// utils
export const axisLabelLengthInPixels = 50;
export const maxAxisLengthInPixels = 150;
