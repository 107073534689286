import DistributorModel from 'gen-thrift/distributor_Model_types';

import { StringValueSet } from 'api/Core/StringValueSet';
import { Distributor } from 'api/Distributor/model/Distributor';
import { DistributorId } from 'api/Distributor/model/DistributorId';
import { DistributorRegionId } from 'api/Distributor/model/DistributorRegionId';
import { ScopeObjectToThriftSerializer } from 'api/Location/serializer/ScopeObjectToThriftSerializer';

export class DistributorObjectToThriftSerializer {

    constructor(
        private readonly scopeSerializer : ScopeObjectToThriftSerializer
    ) {}

    ////////////////////////////////////
    // CORE MODELS
    ////////////////////////////////////

    public getThriftDistributorId(
        distributorId : DistributorId
    ) : DistributorModel.DistributorId {
        return new DistributorModel.DistributorId({
            value: distributorId.getValue()
        });
    }

    public getThriftDistributor(
        distributor : Distributor
    ) : DistributorModel.Distributor {
        return new DistributorModel.Distributor({
            name: distributor.getName(),
            shortName: distributor.getShortName(),
            owner : this.scopeSerializer.getThriftScope(distributor.getOwner())
        });
    }

    public getThriftDistributorRegionId(
        regionId : DistributorRegionId
    ) : DistributorModel.DistributorRegionId {
        return new DistributorModel.DistributorRegionId({
            value: regionId.getValue()
        });
    }

    ////////////////////////////////////
    // MISC SERIALIZERS
    ////////////////////////////////////

    public getThriftDistributorIds(
        distributorIds : StringValueSet<DistributorId>
    ) : Array<DistributorModel.DistributorId> {
        return Array.from(distributorIds.values()).map((distributorId : DistributorId) => {
            return this.getThriftDistributorId(distributorId);
        });
    }
}
