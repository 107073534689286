
import React from 'react';

import { Delivery } from 'api/Ordering/model/Delivery';
import { DeliveryId } from 'api/Ordering/model/DeliveryId';
import { DeliveryLineItem } from 'api/Ordering/model/DeliveryLineItem';
import { DeliveryUtils } from 'api/Ordering/utils/DeliveryUtils';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';
import { FormatMonetaryValueWithCents } from 'shared/utils/FormatMonetaryValue';

export interface IItemCardHistoryOrder {
    productId : ProductId;
    product : Product;
    deliveryId : DeliveryId;
    delivery : Delivery;
    distributorName : string | null;
}

export class ItemCardHistoryOrder extends React.Component<IItemCardHistoryOrder, object> {
    public render() {
        const {
            delivery,
            deliveryId,
            productId,
            product,
            distributorName,
        } = this.props;

        const orderStatus = 'Placed';
        const orderLink = `/ordering/record/detail/r/${ window.GLOBAL_RETAILER_ID }/#/order/${ deliveryId.getValue() }`;

        const productLineItems = new Array<DeliveryLineItem>();
        for (const lineItem of delivery.getDeliveryLineItems()) {
            if (lineItem.getProductId().equals(productId)) {
                productLineItems.push(lineItem);
            }
        }

        const distributorNameOrOther = distributorName ? distributorName : 'Other';

        return (
            <div className="activity-item item-history-order" key={ deliveryId.getValue() }>
                <div className="top-row">
                    <span className="bevicon bevico-shopping-cart" />
                    <div className="title ellipsis">
                        <a className="link" href={ orderLink }>
                            Order
                        </a>
                        <span className="activity-item-detail"> from { distributorNameOrOther }</span>
                    </div>
                    <div className="activity-status">{ orderStatus }</div>
                </div>
                <div className="line-item-row line-item-row-header">
                    <div className="qty">Qty</div>
                    <div className="unit-price">Unit Price</div>
                    <div className="spacer"/>
                    <div className="total">Total</div>
                </div>
                { productLineItems.map((lineItem, index, array) => {
                    const quantityInUnit = new QuantityInUnit(lineItem.getQuantityInUnits(), lineItem.getProductQuantityUnit());
                    const resolvedQuantityInUnit = PackagingUtils.resolveProductQuantityUnit(quantityInUnit, product.getPackagingsAndMappings().getMappings());
                    const resolvedUnitPrice = (quantityInUnit.getQuantity() / resolvedQuantityInUnit.getQuantity()) * lineItem.getPricePerUnit();
                    const resolvedUnitPriceInMonetaryValue = FormatMonetaryValueWithCents(resolvedUnitPrice);
                    const quantityString = PackagingUtils.getDisplayTextForProductQuantityInUnit(product.getPackagingsAndMappings(), resolvedQuantityInUnit);

                    const totalPrice = DeliveryUtils.getTotalCostOfDeliveryLineItem(lineItem);
                    const totalPriceInMonetaryValue = FormatMonetaryValueWithCents(totalPrice);

                    return (
                        <div key={ index } className={ 'line-item-row' + (array.length > 1 ? ' multiple' : '') }>
                            <div className="qty">{ quantityString }</div>
                            <div className="unit-price">{ resolvedUnitPriceInMonetaryValue }</div>
                            <div className="spacer"/>
                            <div className="total">{ totalPriceInMonetaryValue }</div>
                        </div>
                    );
                }) }
            </div>
        );
    }
}
