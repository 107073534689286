import { ICatalogItemOption } from 'api/Search/model/ICatalogItemOption';

export class CatalogItem {
    constructor (
        private readonly brand : string,
        private readonly name : string,
        private readonly productCategoryId : string,
        private readonly productType : string,
        private readonly options : Array<ICatalogItemOption>,
    ) {}

    public getBrand() : string {
        return this.brand;
    }

    public getName() : string {
        return this.name;
    }

    public getProductCategoryId() : string {
        return this.productCategoryId;
    }

    public getProductType() : string {
        return this.productType;
    }

    public getOptions() : Array<ICatalogItemOption> {
        return this.options;
    }
}
