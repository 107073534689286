import { OldPackaging } from 'api/Product/model/OldPackaging';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export interface IProductQuickAddOption {
    packaging : OldPackaging;
    suggestedCasePriceInDollars : number;
    suggestedDepositInDollars : number;
}

export class ProductQuickAdd {
    constructor(
        private readonly brand : string,
        private readonly name : string,
        private readonly options : Array<IProductQuickAddOption>,

        private readonly productCategoryId : string,
        private readonly productType : string,
        private readonly sku : string,
        private readonly note : string,
    ) {
        if (name.length === 0) {
            throw new RuntimeException('name must not be an empty string');
        }
    }

    public getBrand() : string {
        return this.brand;
    }

    public getName() : string {
        return this.name;
    }

    public getOptions() : Array<IProductQuickAddOption> {
        return this.options;
    }

    public getProductCategoryId() : string {
        return this.productCategoryId;
    }

    public getProductType() : string {
        return this.productType;
    }

    public getSku() : string {
        return this.sku;
    }

    public getValue() : string {
        return `${this.brand}_${this.name}`;
    }

    public getNote() : string {
        return this.note;
    }

    public equals(other : any) : boolean {
        if (!(other instanceof ProductQuickAdd)) {
            return false;
        }

        return this.getValue() === other.getValue();
    }
}
