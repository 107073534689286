import { IGettingStartedGuideService } from 'api/Location/interfaces/IGettingStartedGuideService';
import { IWelcomeQuestionResponse } from 'api/Location/model/IWelcomeQuestionResponse';
import { LocationId } from 'api/Location/model/LocationId';
import { WelcomeQuestionnaireResponseObjectToJsonSerializer } from 'api/Location/serializer/WelcomeQuestionnaireResponseObjectToJsonSerializer';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';
import { AjaxUtils } from 'shared/utils/ajaxUtils';
import { WelcomeQuestion } from 'src/main/web/typescript/api/Location/model/WelcomeQuestion';

export class GettingStartedGuideService implements IGettingStartedGuideService {
    constructor (
        private readonly welcomeQuestionnaireObjectToJsonSerializer : WelcomeQuestionnaireResponseObjectToJsonSerializer,
    ) {}

    public getGettingStartedTaskIsCompletedByTaskNameForAllTasks(userSessionId : UserSessionId, locationId : LocationId) : Promise<{[taskName : string] : boolean}> {
        return AjaxUtils.ajaxGet(
            url('account:getting_started_task_status', null, locationId.getValue(), null),
        ).then((response : {[taskName : string] : boolean}) => {
            return response;
        });
    }

    public setGettingStartedTaskIsCompleted(userSessionId : UserSessionId, locationId : LocationId, taskName : string) : Promise<void> {
        return AjaxUtils.ajaxPut(
            url('account:getting_started_task_status', null, locationId.getValue(), null),
            {},
            { task_name : taskName },
        );
    }

    public setWelcomeQuestionnaireResponse(userSessionId : UserSessionId, locationId : LocationId, responseData : {[question in WelcomeQuestion]: IWelcomeQuestionResponse}) : Promise<void> {
        return AjaxUtils.ajaxPost(
            url('account:set_welcome_questionnaire_response', null, locationId.getValue(), null),
            this.welcomeQuestionnaireObjectToJsonSerializer.getWelcomeQuestionnaireResponseJson(responseData)
        );
    }
}
