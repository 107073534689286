import * as XLSX from 'xlsx';

import { IWorkSheetRowJSON } from 'apps/ProductUploader/reducers';

const getParsedSpreadsheetDataFromFile = (file : File) : Promise<Array<IWorkSheetRowJSON>> => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString; // cannot read as binary string in IE

    return new Promise((resolve, reject) => {
        reader.onload = (event) => {
            try {
                const dataFile = event.target as FileReader;
                const result = dataFile.result;
                if (result === null) {
                    // https://developer.mozilla.org/en-US/docs/Web/API/FileReader/result#Value
                    throw new Error('reading file was unsuccessful');
                }

                const data = rABS ? result : new Uint8Array(result as any); // FIXME typescript 3.5.1 isn't typed to accept a "binary" string

                return resolve(getParsedSpreadsheetData(data));
            } catch (error) {
                return reject(error);
            }
        };

        if (rABS) {
            reader.readAsBinaryString(file);
        } else {
            reader.readAsArrayBuffer(file);
        }
    });
};

const getBinaryStringFromFile = (file: File) : Promise<string> => {
    const fileReader = new FileReader();

    if (!!fileReader.readAsBinaryString) {
        return new Promise(((resolve, reject) => {
            fileReader.onload = ((event : ProgressEvent) => {
                const reader = event.target as FileReader;
                if (!reader) {
                    return reject();
                }
                return resolve(reader.result as string);
            });
            fileReader.readAsBinaryString(file);
        }));
    }

    return new Promise<string>(((resolve, reject) => {
        // IE treatment based on https://stackoverflow.com/questions/31391207/javascript-readasbinarystring-function-on-e11
        fileReader.onload =  (e : ProgressEvent) => {
            let result = "";
            if (!!fileReader.result) {
                const bytes = new Uint8Array(fileReader.result as any);
                bytes.forEach((b: number) => result += String.fromCharCode(b));
            }
            return resolve(result);
        };
        fileReader.readAsArrayBuffer(file);
    }));
};

const getParsedSpreadsheetData = (data : string | ArrayBuffer) => {
    const wb = XLSX.read(data, { type: typeof data === 'string' ? 'binary' : 'array' });

    // SheetJS currently includes all hidden and very hidden sheets in the WB output, so should find the first non-hidden
    // see: https://github.com/SheetJS/js-xlsx/issues/464 (hidden states: 0 = visible, 1 = hidden, 2 = very hidden)
    // and: https://github.com/SheetJS/js-xlsx/issues/123 (including all hidden / very hidden)
    let sheet : XLSX.Sheet | null = null;
    wb.SheetNames.forEach((sheetName : string, index : number) => {
        if (sheet === null) {
            const worksheet = wb.Sheets[sheetName];
            if (wb.Workbook && wb.Workbook.Sheets) {
                if (wb.Workbook.Sheets[index].Hidden === 0) {
                    sheet = worksheet;
                }
            } else { // if there's no additional metadata stored in wb.Workbook (for example, if it's a csv), just take the first sheet
                sheet = worksheet;
            }
        }
    });

    if (sheet === null) {
        throw new Error('could not find a non-hidden sheet');
    }

    // header: 'A' creates a dict of column key: column value (represented by IWorksheetRowJSON),
    // blankrows: false skips any blank rows
    // defval: ''  ensures that for the range of the sheet, in each row each column has a value of empty string if no present value (rather than skipping columns that the row doesn't have)
    return XLSX.utils.sheet_to_json<IWorkSheetRowJSON>(sheet, { header: 'A', blankrows: false, defval: '' });
};

const getFileExtensionFromPath = (filePath : string) : null | string => {
    const fileEnding = filePath ? filePath.split(".").pop() : null;
    const fileExtension = !!fileEnding ? fileEnding.split("?").shift() : null;
    if (typeof fileExtension === 'undefined') {
        return null;
    }
    return fileExtension;
};

export const fileReaderUtils = {
    getParsedSpreadsheetDataFromFile,
    getParsedSpreadsheetData,
    getBinaryStringFromFile,
    getFileExtensionFromPath,
};
