import pkceChallenge from "pkce-challenge";
import {userSessionManager} from "shared/lib/manager";

// PKCE for Authorization Code Grant Flow: https://developer.okta.com/blog/2018/12/13/oauth-2-for-native-and-mobile-apps
// Conceptually, “verifier” = some random value X you generate, “challenge” = the hash of X. You request authorization code with hash
// (which comes as a *redirect*), and then request access (and refresh) token with X and the returned auth code.
// Purpose of this is that other apps/scripts on the user’s device can’t intercept the redirect and get the access token for themselves.
// Currently we store client ID on the server side, so this is less essential.
const generatePKCEChallenge = (length?: number) => {
    const {
        code_challenge,
        code_verifier,
    } = pkceChallenge(length);

    // technically not the most secure storage, but sufficient for the current setup (client secret is stored on server side)
    // other examples: https://github.com/auth0/spa-pkce
    sessionStorage.setItem('pkce_code_verifier', code_verifier);
    sessionStorage.setItem('pkce_code_challenge', code_challenge);  // for debugging
    return code_challenge;
};

const getPKCEVerifier = () : string | null => {
    return sessionStorage.getItem('pkce_code_verifier');
};

// "state" parameter in Oauth flows - must be unique and unguessable
// Guards against CSRF and (more likely) redirect url configuration mistakes i.e. if an Oauth flow is initiated on staging
// but the client redirect url is configured to production, this ensure the flow does not "pollute" production.
// WILL NOT WORK if session token refreshed during OAuth flow.
const getOauthRedirectState = () => userSessionManager.getSessionId().getValue();

export const oauthUtils = {
    generatePKCEChallenge,
    getPKCEVerifier,
    getOauthRedirectState,
};
