import { Validation } from './validators';

// can make this more robust in the future (check that name !== any other name?)
// if this validation changes, need to make sure that select2 assign to storage areas dropdown works as expected
export const validateNewStorageAreaName = (name : string) : {isValid : boolean, errorMessage : string} => {
    const isValid = Validation.validateRequired(name);
    const errorMessage = isValid ? '' : 'Invalid storage area name';
    return {
        isValid,
        errorMessage
    };
};
