//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


import Thrift from 'thrift';
import CorePrimitivesModel from './core_primitives_Model_types';
import CoreApiExceptions from './core_api_Exceptions_types';
import AccountsModel from './accounts_Model_types';
import UserAccountsModel from './accounts_user_Model_types';
import AccountsExceptions from './accounts_Exceptions_types';
import UnauthenticatedUserSessionModel from './unauthenticated_user_session_Model_types';
import UnauthenticatedUserSessionExceptions from './unauthenticated_user_session_Exceptions_types';
import UserInfoExceptions from './user_info_Exceptions_types';

var UserAccountService = {};
export default UserAccountService;
//HELPER FUNCTIONS AND STRUCTURES

UserAccountService.UserPasswordService_sendPasswordSettingCredentialToEmailAddress_args = function(args) {
  this.emailAddress = null;
  this.unauthenticatedUserSessionId = null;
  if (args) {
    if (args.emailAddress !== undefined && args.emailAddress !== null) {
      this.emailAddress = new CorePrimitivesModel.EmailAddress(args.emailAddress);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field emailAddress is unset!');
    }
    if (args.unauthenticatedUserSessionId !== undefined && args.unauthenticatedUserSessionId !== null) {
      this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId(args.unauthenticatedUserSessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field unauthenticatedUserSessionId is unset!');
    }
  }
};
UserAccountService.UserPasswordService_sendPasswordSettingCredentialToEmailAddress_args.prototype = {};
UserAccountService.UserPasswordService_sendPasswordSettingCredentialToEmailAddress_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.emailAddress = new CorePrimitivesModel.EmailAddress();
        this.emailAddress.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId();
        this.unauthenticatedUserSessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserAccountService.UserPasswordService_sendPasswordSettingCredentialToEmailAddress_args.prototype.write = function(output) {
  output.writeStructBegin('UserPasswordService_sendPasswordSettingCredentialToEmailAddress_args');
  if (this.emailAddress !== null && this.emailAddress !== undefined) {
    output.writeFieldBegin('emailAddress', Thrift.Type.STRUCT, 1);
    this.emailAddress.write(output);
    output.writeFieldEnd();
  }
  if (this.unauthenticatedUserSessionId !== null && this.unauthenticatedUserSessionId !== undefined) {
    output.writeFieldBegin('unauthenticatedUserSessionId', Thrift.Type.STRUCT, 2);
    this.unauthenticatedUserSessionId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserAccountService.UserPasswordService_sendPasswordSettingCredentialToEmailAddress_result = function(args) {
  this.serviceTemporarilyUnavailableException = null;
  this.rateLimitExceededException = null;
  this.unknownUnauthenticatedUserSessionIdException = null;
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException) {
    this.unknownUnauthenticatedUserSessionIdException = args;
    return;
  }
  if (args) {
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.unknownUnauthenticatedUserSessionIdException !== undefined && args.unknownUnauthenticatedUserSessionIdException !== null) {
      this.unknownUnauthenticatedUserSessionIdException = args.unknownUnauthenticatedUserSessionIdException;
    }
  }
};
UserAccountService.UserPasswordService_sendPasswordSettingCredentialToEmailAddress_result.prototype = {};
UserAccountService.UserPasswordService_sendPasswordSettingCredentialToEmailAddress_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownUnauthenticatedUserSessionIdException = new UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException();
        this.unknownUnauthenticatedUserSessionIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserAccountService.UserPasswordService_sendPasswordSettingCredentialToEmailAddress_result.prototype.write = function(output) {
  output.writeStructBegin('UserPasswordService_sendPasswordSettingCredentialToEmailAddress_result');
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 1);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 2);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownUnauthenticatedUserSessionIdException !== null && this.unknownUnauthenticatedUserSessionIdException !== undefined) {
    output.writeFieldBegin('unknownUnauthenticatedUserSessionIdException', Thrift.Type.STRUCT, 3);
    this.unknownUnauthenticatedUserSessionIdException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserAccountService.UserPasswordService_ensurePasswordSettingCredentialIsValid_args = function(args) {
  this.userPasswordSettingCredential = null;
  if (args) {
    if (args.userPasswordSettingCredential !== undefined && args.userPasswordSettingCredential !== null) {
      this.userPasswordSettingCredential = new UserAccountsModel.UserPasswordSettingCredential(args.userPasswordSettingCredential);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field userPasswordSettingCredential is unset!');
    }
  }
};
UserAccountService.UserPasswordService_ensurePasswordSettingCredentialIsValid_args.prototype = {};
UserAccountService.UserPasswordService_ensurePasswordSettingCredentialIsValid_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.userPasswordSettingCredential = new UserAccountsModel.UserPasswordSettingCredential();
        this.userPasswordSettingCredential.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserAccountService.UserPasswordService_ensurePasswordSettingCredentialIsValid_args.prototype.write = function(output) {
  output.writeStructBegin('UserPasswordService_ensurePasswordSettingCredentialIsValid_args');
  if (this.userPasswordSettingCredential !== null && this.userPasswordSettingCredential !== undefined) {
    output.writeFieldBegin('userPasswordSettingCredential', Thrift.Type.STRUCT, 1);
    this.userPasswordSettingCredential.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserAccountService.UserPasswordService_ensurePasswordSettingCredentialIsValid_result = function(args) {
  this.serviceTemporarilyUnavailableException = null;
  this.rateLimitExceededException = null;
  this.unknownPasswordSettingCredentialException = null;
  this.passwordSettingCredentialExpiredException = null;
  this.passwordSettingCredentialAlreadyUsedException = null;
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownPasswordSettingCredentialException) {
    this.unknownPasswordSettingCredentialException = args;
    return;
  }
  if (args instanceof AccountsExceptions.PasswordSettingCredentialExpiredException) {
    this.passwordSettingCredentialExpiredException = args;
    return;
  }
  if (args instanceof AccountsExceptions.PasswordSettingCredentialAlreadyUsedException) {
    this.passwordSettingCredentialAlreadyUsedException = args;
    return;
  }
  if (args) {
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.unknownPasswordSettingCredentialException !== undefined && args.unknownPasswordSettingCredentialException !== null) {
      this.unknownPasswordSettingCredentialException = args.unknownPasswordSettingCredentialException;
    }
    if (args.passwordSettingCredentialExpiredException !== undefined && args.passwordSettingCredentialExpiredException !== null) {
      this.passwordSettingCredentialExpiredException = args.passwordSettingCredentialExpiredException;
    }
    if (args.passwordSettingCredentialAlreadyUsedException !== undefined && args.passwordSettingCredentialAlreadyUsedException !== null) {
      this.passwordSettingCredentialAlreadyUsedException = args.passwordSettingCredentialAlreadyUsedException;
    }
  }
};
UserAccountService.UserPasswordService_ensurePasswordSettingCredentialIsValid_result.prototype = {};
UserAccountService.UserPasswordService_ensurePasswordSettingCredentialIsValid_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownPasswordSettingCredentialException = new AccountsExceptions.UnknownPasswordSettingCredentialException();
        this.unknownPasswordSettingCredentialException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.passwordSettingCredentialExpiredException = new AccountsExceptions.PasswordSettingCredentialExpiredException();
        this.passwordSettingCredentialExpiredException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.passwordSettingCredentialAlreadyUsedException = new AccountsExceptions.PasswordSettingCredentialAlreadyUsedException();
        this.passwordSettingCredentialAlreadyUsedException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserAccountService.UserPasswordService_ensurePasswordSettingCredentialIsValid_result.prototype.write = function(output) {
  output.writeStructBegin('UserPasswordService_ensurePasswordSettingCredentialIsValid_result');
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 1);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 2);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownPasswordSettingCredentialException !== null && this.unknownPasswordSettingCredentialException !== undefined) {
    output.writeFieldBegin('unknownPasswordSettingCredentialException', Thrift.Type.STRUCT, 3);
    this.unknownPasswordSettingCredentialException.write(output);
    output.writeFieldEnd();
  }
  if (this.passwordSettingCredentialExpiredException !== null && this.passwordSettingCredentialExpiredException !== undefined) {
    output.writeFieldBegin('passwordSettingCredentialExpiredException', Thrift.Type.STRUCT, 4);
    this.passwordSettingCredentialExpiredException.write(output);
    output.writeFieldEnd();
  }
  if (this.passwordSettingCredentialAlreadyUsedException !== null && this.passwordSettingCredentialAlreadyUsedException !== undefined) {
    output.writeFieldBegin('passwordSettingCredentialAlreadyUsedException', Thrift.Type.STRUCT, 5);
    this.passwordSettingCredentialAlreadyUsedException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserAccountService.UserPasswordService_setAccountPasswordAndCreateSession_args = function(args) {
  this.userPasswordSettingCredential = null;
  this.newPassword = null;
  this.unauthenticatedUserSessionId = null;
  if (args) {
    if (args.userPasswordSettingCredential !== undefined && args.userPasswordSettingCredential !== null) {
      this.userPasswordSettingCredential = new UserAccountsModel.UserPasswordSettingCredential(args.userPasswordSettingCredential);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field userPasswordSettingCredential is unset!');
    }
    if (args.newPassword !== undefined && args.newPassword !== null) {
      this.newPassword = new CorePrimitivesModel.UnencryptedPassword(args.newPassword);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field newPassword is unset!');
    }
    if (args.unauthenticatedUserSessionId !== undefined && args.unauthenticatedUserSessionId !== null) {
      this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId(args.unauthenticatedUserSessionId);
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field unauthenticatedUserSessionId is unset!');
    }
  }
};
UserAccountService.UserPasswordService_setAccountPasswordAndCreateSession_args.prototype = {};
UserAccountService.UserPasswordService_setAccountPasswordAndCreateSession_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.userPasswordSettingCredential = new UserAccountsModel.UserPasswordSettingCredential();
        this.userPasswordSettingCredential.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.newPassword = new CorePrimitivesModel.UnencryptedPassword();
        this.newPassword.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unauthenticatedUserSessionId = new UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId();
        this.unauthenticatedUserSessionId.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserAccountService.UserPasswordService_setAccountPasswordAndCreateSession_args.prototype.write = function(output) {
  output.writeStructBegin('UserPasswordService_setAccountPasswordAndCreateSession_args');
  if (this.userPasswordSettingCredential !== null && this.userPasswordSettingCredential !== undefined) {
    output.writeFieldBegin('userPasswordSettingCredential', Thrift.Type.STRUCT, 1);
    this.userPasswordSettingCredential.write(output);
    output.writeFieldEnd();
  }
  if (this.newPassword !== null && this.newPassword !== undefined) {
    output.writeFieldBegin('newPassword', Thrift.Type.STRUCT, 2);
    this.newPassword.write(output);
    output.writeFieldEnd();
  }
  if (this.unauthenticatedUserSessionId !== null && this.unauthenticatedUserSessionId !== undefined) {
    output.writeFieldBegin('unauthenticatedUserSessionId', Thrift.Type.STRUCT, 3);
    this.unauthenticatedUserSessionId.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserAccountService.UserPasswordService_setAccountPasswordAndCreateSession_result = function(args) {
  this.success = null;
  this.serviceTemporarilyUnavailableException = null;
  this.rateLimitExceededException = null;
  this.unknownPasswordSettingCredentialException = null;
  this.passwordSettingCredentialExpiredException = null;
  this.passwordSettingCredentialAlreadyUsedException = null;
  this.passwordDoesNotMeetRequirementsException = null;
  this.unknownUnauthenticatedUserSessionIdException = null;
  if (args instanceof CoreApiExceptions.ServiceTemporarilyUnavailableException) {
    this.serviceTemporarilyUnavailableException = args;
    return;
  }
  if (args instanceof CoreApiExceptions.RateLimitExceededException) {
    this.rateLimitExceededException = args;
    return;
  }
  if (args instanceof AccountsExceptions.UnknownPasswordSettingCredentialException) {
    this.unknownPasswordSettingCredentialException = args;
    return;
  }
  if (args instanceof AccountsExceptions.PasswordSettingCredentialExpiredException) {
    this.passwordSettingCredentialExpiredException = args;
    return;
  }
  if (args instanceof AccountsExceptions.PasswordSettingCredentialAlreadyUsedException) {
    this.passwordSettingCredentialAlreadyUsedException = args;
    return;
  }
  if (args instanceof AccountsExceptions.PasswordDoesNotMeetRequirementsException) {
    this.passwordDoesNotMeetRequirementsException = args;
    return;
  }
  if (args instanceof UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException) {
    this.unknownUnauthenticatedUserSessionIdException = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new UserAccountsModel.UserSessionIdentifier(args.success);
    }
    if (args.serviceTemporarilyUnavailableException !== undefined && args.serviceTemporarilyUnavailableException !== null) {
      this.serviceTemporarilyUnavailableException = args.serviceTemporarilyUnavailableException;
    }
    if (args.rateLimitExceededException !== undefined && args.rateLimitExceededException !== null) {
      this.rateLimitExceededException = args.rateLimitExceededException;
    }
    if (args.unknownPasswordSettingCredentialException !== undefined && args.unknownPasswordSettingCredentialException !== null) {
      this.unknownPasswordSettingCredentialException = args.unknownPasswordSettingCredentialException;
    }
    if (args.passwordSettingCredentialExpiredException !== undefined && args.passwordSettingCredentialExpiredException !== null) {
      this.passwordSettingCredentialExpiredException = args.passwordSettingCredentialExpiredException;
    }
    if (args.passwordSettingCredentialAlreadyUsedException !== undefined && args.passwordSettingCredentialAlreadyUsedException !== null) {
      this.passwordSettingCredentialAlreadyUsedException = args.passwordSettingCredentialAlreadyUsedException;
    }
    if (args.passwordDoesNotMeetRequirementsException !== undefined && args.passwordDoesNotMeetRequirementsException !== null) {
      this.passwordDoesNotMeetRequirementsException = args.passwordDoesNotMeetRequirementsException;
    }
    if (args.unknownUnauthenticatedUserSessionIdException !== undefined && args.unknownUnauthenticatedUserSessionIdException !== null) {
      this.unknownUnauthenticatedUserSessionIdException = args.unknownUnauthenticatedUserSessionIdException;
    }
  }
};
UserAccountService.UserPasswordService_setAccountPasswordAndCreateSession_result.prototype = {};
UserAccountService.UserPasswordService_setAccountPasswordAndCreateSession_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new UserAccountsModel.UserSessionIdentifier();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.serviceTemporarilyUnavailableException = new CoreApiExceptions.ServiceTemporarilyUnavailableException();
        this.serviceTemporarilyUnavailableException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.rateLimitExceededException = new CoreApiExceptions.RateLimitExceededException();
        this.rateLimitExceededException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownPasswordSettingCredentialException = new AccountsExceptions.UnknownPasswordSettingCredentialException();
        this.unknownPasswordSettingCredentialException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.passwordSettingCredentialExpiredException = new AccountsExceptions.PasswordSettingCredentialExpiredException();
        this.passwordSettingCredentialExpiredException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.passwordSettingCredentialAlreadyUsedException = new AccountsExceptions.PasswordSettingCredentialAlreadyUsedException();
        this.passwordSettingCredentialAlreadyUsedException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.passwordDoesNotMeetRequirementsException = new AccountsExceptions.PasswordDoesNotMeetRequirementsException();
        this.passwordDoesNotMeetRequirementsException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.unknownUnauthenticatedUserSessionIdException = new UnauthenticatedUserSessionExceptions.UnknownUnauthenticatedUserSessionIdException();
        this.unknownUnauthenticatedUserSessionIdException.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserAccountService.UserPasswordService_setAccountPasswordAndCreateSession_result.prototype.write = function(output) {
  output.writeStructBegin('UserPasswordService_setAccountPasswordAndCreateSession_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.serviceTemporarilyUnavailableException !== null && this.serviceTemporarilyUnavailableException !== undefined) {
    output.writeFieldBegin('serviceTemporarilyUnavailableException', Thrift.Type.STRUCT, 1);
    this.serviceTemporarilyUnavailableException.write(output);
    output.writeFieldEnd();
  }
  if (this.rateLimitExceededException !== null && this.rateLimitExceededException !== undefined) {
    output.writeFieldBegin('rateLimitExceededException', Thrift.Type.STRUCT, 2);
    this.rateLimitExceededException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownPasswordSettingCredentialException !== null && this.unknownPasswordSettingCredentialException !== undefined) {
    output.writeFieldBegin('unknownPasswordSettingCredentialException', Thrift.Type.STRUCT, 3);
    this.unknownPasswordSettingCredentialException.write(output);
    output.writeFieldEnd();
  }
  if (this.passwordSettingCredentialExpiredException !== null && this.passwordSettingCredentialExpiredException !== undefined) {
    output.writeFieldBegin('passwordSettingCredentialExpiredException', Thrift.Type.STRUCT, 4);
    this.passwordSettingCredentialExpiredException.write(output);
    output.writeFieldEnd();
  }
  if (this.passwordSettingCredentialAlreadyUsedException !== null && this.passwordSettingCredentialAlreadyUsedException !== undefined) {
    output.writeFieldBegin('passwordSettingCredentialAlreadyUsedException', Thrift.Type.STRUCT, 5);
    this.passwordSettingCredentialAlreadyUsedException.write(output);
    output.writeFieldEnd();
  }
  if (this.passwordDoesNotMeetRequirementsException !== null && this.passwordDoesNotMeetRequirementsException !== undefined) {
    output.writeFieldBegin('passwordDoesNotMeetRequirementsException', Thrift.Type.STRUCT, 6);
    this.passwordDoesNotMeetRequirementsException.write(output);
    output.writeFieldEnd();
  }
  if (this.unknownUnauthenticatedUserSessionIdException !== null && this.unknownUnauthenticatedUserSessionIdException !== undefined) {
    output.writeFieldBegin('unknownUnauthenticatedUserSessionIdException', Thrift.Type.STRUCT, 7);
    this.unknownUnauthenticatedUserSessionIdException.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UserAccountService.UserPasswordServiceClient = function(input, output) {
    this.input = input;
    this.output = (!output) ? input : output;
    this.seqid = 0;
};
UserAccountService.UserPasswordServiceClient.prototype = {};
UserAccountService.UserPasswordServiceClient.prototype.sendPasswordSettingCredentialToEmailAddress = function(emailAddress, unauthenticatedUserSessionId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_sendPasswordSettingCredentialToEmailAddress(pendingError, emailAddress, unauthenticatedUserSessionId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_sendPasswordSettingCredentialToEmailAddress();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

UserAccountService.UserPasswordServiceClient.prototype.send_sendPasswordSettingCredentialToEmailAddress = function(pendingError, emailAddress, unauthenticatedUserSessionId, callback) {
  this.output.writeMessageBegin('sendPasswordSettingCredentialToEmailAddress', Thrift.MessageType.CALL, this.seqid);
  var args = new UserAccountService.UserPasswordService_sendPasswordSettingCredentialToEmailAddress_args();
  args.emailAddress = emailAddress;
  args.unauthenticatedUserSessionId = unauthenticatedUserSessionId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_sendPasswordSettingCredentialToEmailAddress() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

UserAccountService.UserPasswordServiceClient.prototype.recv_sendPasswordSettingCredentialToEmailAddress = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new UserAccountService.UserPasswordService_sendPasswordSettingCredentialToEmailAddress_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.unknownUnauthenticatedUserSessionIdException) {
    throw result.unknownUnauthenticatedUserSessionIdException;
  }
  return;
};
UserAccountService.UserPasswordServiceClient.prototype.ensurePasswordSettingCredentialIsValid = function(userPasswordSettingCredential, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_ensurePasswordSettingCredentialIsValid(pendingError, userPasswordSettingCredential, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_ensurePasswordSettingCredentialIsValid();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
  }
};

UserAccountService.UserPasswordServiceClient.prototype.send_ensurePasswordSettingCredentialIsValid = function(pendingError, userPasswordSettingCredential, callback) {
  this.output.writeMessageBegin('ensurePasswordSettingCredentialIsValid', Thrift.MessageType.CALL, this.seqid);
  var args = new UserAccountService.UserPasswordService_ensurePasswordSettingCredentialIsValid_args();
  args.userPasswordSettingCredential = userPasswordSettingCredential;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_ensurePasswordSettingCredentialIsValid() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

UserAccountService.UserPasswordServiceClient.prototype.recv_ensurePasswordSettingCredentialIsValid = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new UserAccountService.UserPasswordService_ensurePasswordSettingCredentialIsValid_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.unknownPasswordSettingCredentialException) {
    throw result.unknownPasswordSettingCredentialException;
  }
  if (null !== result.passwordSettingCredentialExpiredException) {
    throw result.passwordSettingCredentialExpiredException;
  }
  if (null !== result.passwordSettingCredentialAlreadyUsedException) {
    throw result.passwordSettingCredentialAlreadyUsedException;
  }
  return;
};
UserAccountService.UserPasswordServiceClient.prototype.setAccountPasswordAndCreateSession = function(userPasswordSettingCredential, newPassword, unauthenticatedUserSessionId, callback) {
  var pendingError = new Error('Thrift Exception');
  this.send_setAccountPasswordAndCreateSession(pendingError, userPasswordSettingCredential, newPassword, unauthenticatedUserSessionId, callback); 
  if (!callback) {
    var result = null;
    try {
      result = this.recv_setAccountPasswordAndCreateSession();
    } catch (e) {
      result = e;
      result.message = pendingError.message;
      result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      throw result;
    }
    return result;
  }
};

UserAccountService.UserPasswordServiceClient.prototype.send_setAccountPasswordAndCreateSession = function(pendingError, userPasswordSettingCredential, newPassword, unauthenticatedUserSessionId, callback) {
  this.output.writeMessageBegin('setAccountPasswordAndCreateSession', Thrift.MessageType.CALL, this.seqid);
  var args = new UserAccountService.UserPasswordService_setAccountPasswordAndCreateSession_args();
  args.userPasswordSettingCredential = userPasswordSettingCredential;
  args.newPassword = newPassword;
  args.unauthenticatedUserSessionId = unauthenticatedUserSessionId;
  args.write(this.output);
  this.output.writeMessageEnd();
  if (callback) {
    var self = this;
    this.output.getTransport().flush(true, function(xhrError) {
      var result = null;
      try {
        result = self.recv_setAccountPasswordAndCreateSession() || xhrError;
      } catch (e) {
        result = e;
        result.message = pendingError.message;
        result.stack = pendingError ? pendingError.stack || pendingError.stacktrace || pendingError : null;
      }
      callback(result);
    });
  } else {
    return this.output.getTransport().flush();
  }
};

UserAccountService.UserPasswordServiceClient.prototype.recv_setAccountPasswordAndCreateSession = function() {
  var ret = this.input.readMessageBegin();
  var fname = ret.fname;
  var mtype = ret.mtype;
  var rseqid = ret.rseqid;
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(this.input);
    this.input.readMessageEnd();
    throw x;
  }
  var result = new UserAccountService.UserPasswordService_setAccountPasswordAndCreateSession_result();
  result.read(this.input);
  this.input.readMessageEnd();

  if (null !== result.serviceTemporarilyUnavailableException) {
    throw result.serviceTemporarilyUnavailableException;
  }
  if (null !== result.rateLimitExceededException) {
    throw result.rateLimitExceededException;
  }
  if (null !== result.unknownPasswordSettingCredentialException) {
    throw result.unknownPasswordSettingCredentialException;
  }
  if (null !== result.passwordSettingCredentialExpiredException) {
    throw result.passwordSettingCredentialExpiredException;
  }
  if (null !== result.passwordSettingCredentialAlreadyUsedException) {
    throw result.passwordSettingCredentialAlreadyUsedException;
  }
  if (null !== result.passwordDoesNotMeetRequirementsException) {
    throw result.passwordDoesNotMeetRequirementsException;
  }
  if (null !== result.unknownUnauthenticatedUserSessionIdException) {
    throw result.unknownUnauthenticatedUserSessionIdException;
  }
  if (null !== result.success) {
    return result.success;
  }
  throw 'setAccountPasswordAndCreateSession failed: unknown result';
};
