import { RecipePdfRenderer } from 'apps/CreateOrEditSalesItem/components/RecipePdfRenderer';
import React from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Dispatch } from 'redux';

import { CreateOrEditSalesItemActions, ICreateOrEditSalesItemStore } from 'apps/CreateOrEditSalesItem/actions/actions';

import { StringValueSet } from 'api/Core/StringValueSet';
import { ProductQuickAdd } from 'api/Onboarding/model/ProductQuickAdd';
import { ProductId } from 'api/Product/model/ProductId';
import { SalesItemId } from 'api/SalesItem/model/SalesItemId';

import { StringValueMap } from 'api/Core/StringValueMap';
import { ItemLevelSalesReportConfigurationId } from 'api/Reports/model/ItemLevelSalesReportConfigurationId';
import { FileUpload } from 'shared/components/FileUpload/FileUpload';
import { IFileUploadState } from 'shared/components/FileUpload/reducers';
import { Observer } from 'shared/utils/observer';
import { ApplyChangesDialog } from './components/ApplyChangesDialog';
import { IngredientInfo } from './components/IngredientInfo';
import { LastEditedInformation } from './components/LastEditedInformation';
import { SalesInformation } from './components/SalesInformation';
import { SalesItemFlagAlert } from './components/SalesItemFlagAlert';
import { availableSalesItemFlags, SalesItemFlagSelector } from './components/SalesItemFlagSelector';
import { SalesItemInformation } from './components/SalesItemInformation';
import { UnsavedChangesDialog } from './components/UnsavedChangesDialog';
import { ICreateOrEditSalesItemState, IngredientFormFieldName, ProductIngredientRowFormFieldName, SalesInformationFormFieldName, SalesItemFormFieldName, SalesItemIngredientRowFormFieldName, SalesItemSaveOption } from './reducers/reducers';
import { CreateOrEditSalesItemFormUtils } from './utils/CreateOrEditSalesItemFormUtils';

import { Button } from 'shared/components/Button';
import { LoadingCover } from 'shared/components/LoadingCover';
import { PageHeader, PageHeaderTheme } from 'shared/components/PageHeader/PageHeader';
import { PageTitle } from 'shared/components/PageTitle/PageTitle';
import { IProductSlimCreateFormStore, ProductSlimCreateFormActions } from 'shared/components/SlimCreate/ProductSlimCreateForm/ProductSlimCreateFormActions';
import { IProductSlimCreateFormState } from 'shared/components/SlimCreate/ProductSlimCreateForm/ProductSlimCreateFormReducers';
import { SnackBar } from 'shared/components/SnackBar';

import { MAX_MOBILE_WIDTH, MIN_TABLET_WIDTH } from 'shared/constants';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import { FreeTrialStatus } from 'api/Location/model/FreeTrialStatus';
import { SalesInputRowId } from 'shared/components/SalesInputTable/SalesInputRow';
import './css/CreateOrEditSalesItemApp.scss';
import { ActionKeyCodes, actionKeyCodeSet } from './MapSalesItemView';

export interface ICreateOrEditSalesItemProps {
    initialSalesItemId : SalesItemId | null; // not available from item-level sales reports... need to get those from custom actions
    initialContextOrderedIds : Array<SalesItemId>;
    contextItemLevelReportId : ItemLevelSalesReportConfigurationId | null;
    preventProductIngredientEditing? : boolean;

    onClose : () => void;
    onSave : (updatedSalesItemIdMap : StringValueMap<SalesInputRowId, SalesItemId>) => void;
    productSlimCreateFormState : IProductSlimCreateFormState;

    // This flag indicates whether or not the user can create a new sales item if they are on
    // a free trial.
    freeTrialStatus : FreeTrialStatus;

    shouldShowPdfRenderer : boolean;
}

export interface IConnectedCreateOrEditSalesItem extends ICreateOrEditSalesItemProps {
    dispatch : Dispatch<ICreateOrEditSalesItemStore & IProductSlimCreateFormStore>;
    createOrEditSalesItemState : ICreateOrEditSalesItemState;
    fileUploadState : IFileUploadState;
}
class CreateOrEditSalesItem extends React.Component<IConnectedCreateOrEditSalesItem, object> {
    private userIsAdmin : boolean;
    private contextOrderedIds : Array<SalesItemId>;
    private onlyShowAllTimeSaveOption : boolean;

    constructor(props : IConnectedCreateOrEditSalesItem) {
        super(props);

        this.userIsAdmin = window.GLOBAL_USER_IS_ADMIN;
        this.contextOrderedIds = this.props.initialContextOrderedIds;
        this.onlyShowAllTimeSaveOption = !window.GLOBAL_FEATURE_ACCESS.item_level_sales_reporting;
    }

    public componentWillMount() {
        const {
            dispatch,
            initialSalesItemId,
            initialContextOrderedIds,
            createOrEditSalesItemState,
        } = this.props;

        // only need to fetch data if it's not already set
        let initialDataPromise : Promise<void>;
        if (createOrEditSalesItemState.salesItemFormData === null) {
            initialDataPromise = dispatch(CreateOrEditSalesItemActions.fetchInitialData());
        } else {
            initialDataPromise = Promise.resolve();
        }

        this.contextOrderedIds = initialContextOrderedIds;

        initialDataPromise
        .then(() => {
            if (initialSalesItemId) {
                dispatch(CreateOrEditSalesItemActions.initializeFormFromSalesItem(initialSalesItemId, initialContextOrderedIds, false, new StringValueMap()));
            } else {
                dispatch(CreateOrEditSalesItemActions.setSalesItemId(null));
                dispatch(CreateOrEditSalesItemActions.setNextAndPreviousItemIds(null, null));
            }
        });

    }

    public componentDidMount() {
        document.addEventListener('keyup', this.onDocumentKeyUp);
        $(document).on('customItemSuccessfullyCreated', this.onCustomItemSuccessfullyCreated);
        $(document).on('customItemSuccessfullyEdited', this.onCustomItemSuccessfullyEdited);
        $(document).on('customItemDeleted', this.onCustomItemActiveStateChanged);
        $(document).on('customItemActiveStateChanged', this.onCustomItemActiveStateChanged);
        // $(document).on('editItemModalClosed', this.onEditItemModalClosed);
    }

    public componentWillUnmount() {
        document.removeEventListener('keyup', this.onDocumentKeyUp);
        $(document).on('customItemSuccessfullyCreated', this.onCustomItemSuccessfullyCreated);
        $(document).off('customItemSuccessfullyEdited', this.onCustomItemSuccessfullyEdited);
        $(document).off('customItemDeleted', this.onCustomItemActiveStateChanged);
        $(document).off('customItemActiveStateChanged', this.onCustomItemActiveStateChanged);
        // $(document).off('editItemModalClosed', this.onEditItemModalClosed);

        this.props.dispatch(CreateOrEditSalesItemActions.updateSalesItemIdInURL(null));
    }

    public render() {
        const {
            createOrEditSalesItemState,
            contextItemLevelReportId,
            preventProductIngredientEditing,
            freeTrialStatus,
            shouldShowPdfRenderer,
        } = this.props;

        const {
            isShownByComponentName,
            salesItemForm,
            salesItemFormData,
            linkedItems,
            selectedSaveOption,
            salesItemId,
            nextSalesItemId,
            previousSalesItemId,
            popoverIngredientIdIsShown,
        } = createOrEditSalesItemState;

        const saveIsDisabled : boolean =
            isShownByComponentName.saveIsDisabled ||
            freeTrialStatus.getIsExpiredByAction().salesItems ||
            !window.GLOBAL_FEATURE_ACCESS.sales;

        const salesItemPaginationContainer = (
            <div className="pagination-container">
                <div className="pagination-button-wrapper">
                    { salesItemId !== null && // in create mode, no buttons. otherwise, show
                        <React.Fragment>
                            <Button
                                isDisabled={ isShownByComponentName.saveInProgress || previousSalesItemId === null }
                                buttonClassName="primary capitalize flat with-icon"
                                isLoading={ false }
                                onClick={ this.onPreviousButtonClick }
                            >
                                <span className="bevicon bevico-keyboard-arrow-left main-icon-left" />
                                <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                                    <span>Prev</span>
                                </MediaQuery>
                            </Button>
                            <Button
                                isDisabled={ isShownByComponentName.saveInProgress || nextSalesItemId === null }
                                buttonClassName="primary capitalize flat with-icon"
                                isLoading={ false }
                                onClick={ this.onNextButtonClick }
                            >
                                <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                                    <span>Next</span>
                                </MediaQuery>
                                <span className="bevicon bevico-keyboard-arrow-right main-icon-right" />
                            </Button>
                        </React.Fragment>
                    }
                </div>
                <MediaQuery maxWidth={ MAX_MOBILE_WIDTH }>
                    <Button
                        buttonClassName="primary large save-sales-item-btn"
                        isDisabled={ saveIsDisabled }
                        isLoading={ isShownByComponentName.saveInProgress }
                        onClick={ this.onSaveClick }
                    >
                        Save
                    </Button>
                </MediaQuery>
            </div>
        );

        return (
            <div className="create-or-edit-sales-item">
                <PageHeader
                    theme={ PageHeaderTheme.Dark }
                >
                    <Button
                        buttonClassName="icon flat bevicon bevico-arrow-back exit-view"
                        isDisabled={ isShownByComponentName.saveInProgress }
                        isLoading={ false }
                        onClick={ this.handleOnShowConfirmExitDialog }
                    />
                    <PageTitle
                        heading={ `${salesItemId ? 'Edit' : 'Create'} Recipe` }
                        darkTheme={ true }
                    />
                    <div className="page-header-secondary">
                        <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                            { salesItemPaginationContainer }
                            { (salesItemId instanceof SalesItemId) && window.GLOBAL_EXPORT_ENABLED && // Edit mode
                                <span>
                                    <Button
                                        buttonClassName="primary flat with-icon"
                                        isDisabled={ false }
                                        isLoading={ false }
                                        onClick={ this.onExportToExcel }
                                    >
                                        <span className="main-icon-left bevicon bevico-file-download"/>
                                        <span>Export</span>
                                    </Button>
                                    {/*Disabled until we can remove or replace the existing PDF generation library.*/}
                                    {/*{ shouldShowPdfRenderer &&*/}
                                    {/*    <Button*/}
                                    {/*        buttonClassName="primary flat with-icon"*/}
                                    {/*        isDisabled={ !salesItemFormData || !salesItemId }*/}
                                    {/*        isLoading={ !salesItemFormData }*/}
                                    {/*        onClick={ this.togglePdfRenderer }*/}
                                    {/*    >*/}
                                    {/*        <span className={ `main-icon-left bevicon bevico-${isShownByComponentName.recipePdfRenderer ? 'close' : 'search'}` }/>*/}
                                    {/*        <span>{ isShownByComponentName.recipePdfRenderer ? 'Close Pdf' : 'View Pdf' }</span>*/}
                                    {/*    </Button>*/}
                                    {/*}*/}
                                </span>
                            }
                            { !this.props.createOrEditSalesItemState.salesItemImageUploadUrl &&
                                <span>
                                    <FileUpload
                                        id={ 'recipe-image-' + salesItemId }
                                        onFileInputChange={ this.onSalesItemImageUploadFileInputChange }
                                        fileUploadState={ this.props.fileUploadState['recipe-image-' + salesItemId] || [] }
                                        maxFileUploadCount={ 1 }
                                        buttonText={ (
                                            <span>
                                                <span className="bevicon bevico-file-upload main-icon-left"/>
                                                Upload Image
                                            </span>
                                        )}
                                        buttonClassName="flat with-icon primary"
                                    />
                                </span>
                            }
                            </MediaQuery>
                    </div>
                </PageHeader>
                <MediaQuery maxWidth={ MAX_MOBILE_WIDTH }>
                    <div className="mobile-header-button-container">
                        { (salesItemId instanceof SalesItemId) && window.GLOBAL_EXPORT_ENABLED && // Edit mode
                            <span>
                                <Button
                                    buttonClassName="primary flat with-icon"
                                    isDisabled={ false }
                                    isLoading={ false }
                                    onClick={ this.onExportToExcel }
                                >
                                    <span className="main-icon-left bevicon bevico-file-download"/>
                                    <span>Export</span>
                                </Button>
                                {/*Disabled until we can remove or replace the existing PDF generation library.*/}
                                {/*{ shouldShowPdfRenderer &&*/}
                                {/*    <Button*/}
                                {/*        buttonClassName="primary flat with-icon"*/}
                                {/*        isDisabled={ !salesItemFormData || !salesItemId }*/}
                                {/*        isLoading={ !salesItemFormData }*/}
                                {/*        onClick={ this.togglePdfRenderer }*/}
                                {/*    >*/}
                                {/*        <span className={ `main-icon-left bevicon bevico-${isShownByComponentName.recipePdfRenderer ? 'close' : 'search'}` }/>*/}
                                {/*        <span>{ isShownByComponentName.recipePdfRenderer ? 'Close Pdf' : 'View Pdf' }</span>*/}
                                {/*    </Button>*/}
                                {/*}*/}
                            </span>
                        }
                        { !this.props.createOrEditSalesItemState.salesItemImageUploadUrl &&
                            <span>
                                <FileUpload
                                    id={ 'recipe-image-' + salesItemId }
                                    onFileInputChange={ this.onSalesItemImageUploadFileInputChange }
                                    fileUploadState={ this.props.fileUploadState['recipe-image-' + salesItemId] || [] }
                                    maxFileUploadCount={ 1 }
                                    buttonText={ (
                                        <span>
                                            <span className="bevicon bevico-file-upload main-icon-left"/>
                                            Upload Image
                                        </span>
                                    )}
                                    buttonClassName="flat with-icon primary"
                                />
                            </span>
                        }
                    </div>
                </MediaQuery>

                { salesItemFormData === null &&
                    <LoadingCover
                        className="overlay-light"
                        hasLoadingIcon={ true }
                    />
                }

                { salesItemFormData !== null &&
                    <div className="main-container container">
                        { this.userIsAdmin &&
                            <SalesItemFlagSelector
                                selectedFlagValue={ salesItemForm.selectedNeedsAttentionCategory }
                                onFlagChange={ this.onSalesItemFlagChange }
                            />
                        }
                        { !this.userIsAdmin && salesItemForm.selectedNeedsAttentionCategory !== null &&
                            <SalesItemFlagAlert
                                selectedFlagValue={ salesItemForm.selectedNeedsAttentionCategory }
                                onMarkAsResolvedClick={ this.onMarkFlagAsResolvedClick }
                            />
                        }
                        <MediaQuery maxWidth={ MAX_MOBILE_WIDTH }>
                            <LastEditedInformation
                                salesItemFormData={ salesItemFormData }
                                salesItemFormInfo={ salesItemForm }
                            />
                        </MediaQuery>
                        <div className="main-container-column">
                            <SalesItemInformation
                                showOrHideSalesInformationFields={ this.toggleSalesItemInformationIsShown }
                                onSalesItemInfoFormFieldChange={ this.onSalesItemFormFieldChange }
                                onSalesItemInfoFormFieldEnterOrBlur={ this.doNothing }
                                validationInputDataByFieldName={ salesItemForm.salesItemInfoForm }
                                menuGroupInput={ {
                                    sortedOptions: salesItemForm.menuGroupOptions,
                                    onOptionSelect: this.onSelectMenuGroupOption,
                                    onCreateCustomOption: this.onCreateCustomMenuGroupOption,
                                } }
                                salesItemFormInfo={ salesItemForm }
                                salesItemInformationFieldsAreShown={ isShownByComponentName.salesItemInformation }
                                linkedItems={ linkedItems }
                                linkedItemsPopoverShown={ isShownByComponentName.linkedItemsPopover }
                                setLinkedItemsPopoverIsShown={ this.handleSetLinkedItemsPopoverIsShown }
                                salesItemsById={ salesItemFormData.salesItemsById }
                            />
                            <IngredientInfo
                                salesItemForm={ salesItemForm }
                                salesItemFormData={ salesItemFormData }
                                onIngredientInfoFormFieldChange={ this.onIngredientInfoFormFieldChange }
                                onProductRowFormFieldChange={ this.onProductRowFormFieldChange }
                                onProductRowFormFieldBlur={ this.onProductRowFormFieldBlur }
                                onSalesItemRowFormFieldChange={ this.onSalesItemRowFormFieldChange }
                                onSalesItemRowFormFieldBlur={ this.onSalesItemRowFormFieldBlur }
                                onSalesInformationFormFieldBlur={ this.onSalesInformationFormFieldBlur }
                                onSalesInformationFormFieldChange={ this.onSalesInformationFormFieldChange }
                                onAddIngredient={ this.onAddIngredient }
                                handleOnRemoveIngredientClick={ this.onRemoveIngredientClick }
                                productsById={ salesItemFormData.productsById }
                                salesItemsById={ salesItemFormData.salesItemsById }
                                setSearchTerm={ this.setIngredientSearchBarTerm }
                                setHighlightedIngredientId={ this.setHighlightedIngredientId }
                                ingredientSearchBarDropdownIsShown={ isShownByComponentName.ingredientSearchBarDropdown }
                                setIngredientDropdownIsShown={ this.setIngredientDropdownIsShown }
                                slimProductCreateIsShown={ isShownByComponentName.searchBarSlimCreate }
                                createNewSalesItemFieldsAreShown={ isShownByComponentName.createNewSalesItemFields }
                                setSlimCreateIsShown={ this.setSearchBarSlimCreateIsShown }
                                setCreateNewSalesItemFieldsAreShown={ this.setCreateNewSalesItemFieldsAreShown }
                                onSelectIngredient={ this.onSelectIngredient }
                                popoverIngredientIdIsShown={ this.setIngredientPopoverIsShownForId }
                                onProductInfoFullDetailsClick={ preventProductIngredientEditing ? null : this.onProductInfoFullDetailsClick }
                                popoverIngredientIds={ popoverIngredientIdIsShown }
                                productCostsByProductId={ salesItemFormData.productCostsByProductId }
                                onSlimCreate={ this.onSlimCreateNewProductIngredient }
                                salesItemCostOnForm={ salesItemForm.salesInformationForm.totalCost.value }
                                showOrHideSubRecipeInfoFields={ this.toggleSubRecipeInfoIsShown }
                                subRecipeInfoFieldsAreShown={ isShownByComponentName.subRecipeInfo }
                                showIngredientPriceInDropdown={ true }
                                showProTipForEmptyIngredients={ false }
                                isItemMapping={ false }
                            />
                        </div>
                        <div className="main-container-column secondary-information">
                            <div>
                                <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                                    <div className="sales-item-form-subsection">
                                        <Button
                                            buttonClassName="primary large save-sales-item-btn"
                                            isDisabled={ saveIsDisabled }
                                            isLoading={ isShownByComponentName.saveInProgress }
                                            onClick={ this.onSaveClick }
                                        >
                                            Save
                                        </Button>
                                        <LastEditedInformation
                                            salesItemFormData={ salesItemFormData }
                                            salesItemFormInfo={ salesItemForm }
                                        />
                                    </div>
                                </MediaQuery>
                                <SalesInformation
                                    onSalesInformationFormFieldChange={ this.onSalesInformationFormFieldChange }
                                    onSalesInformationFormFieldBlur={ this.onSalesInformationFormFieldBlur }
                                    validationInputDataByFieldName={ salesItemForm.salesInformationForm }
                                    retailerTaxPercentage={ salesItemFormData.retailerTaxPercentage }
                                    salesPriceAndTaxHintShown={ isShownByComponentName.salesPriceAndTaxHintPopover }
                                    handleSetSalesPriceAndTaxHintIsShown={ this.setSalesPriceAndTaxHintPopoverIsShown }
                                />
                                { this.props.createOrEditSalesItemState.salesItemImageUploadUrl &&
                                    <div className="sales-item-form image-buttons">
                                        <Button
                                            isDisabled={ false }
                                            buttonClassName="primary capitalize flat with-icon"
                                            isLoading={ false }
                                            onClick={ this.openImageInNewTab }
                                        >
                                            <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                                                <span>View Image</span>
                                            </MediaQuery>
                                        </Button>
                                        <Button
                                            isDisabled={ false }
                                            buttonClassName="primary capitalize flat with-icon"
                                            isLoading={ false }
                                            onClick={ this.deleteSalesItemImageUpload }
                                        >
                                            <MediaQuery minWidth={ MIN_TABLET_WIDTH }>
                                                <span>Delete Image</span>
                                            </MediaQuery>
                                        </Button>
                                    </div>
                                }
                            </div>
                        </div>
                        <MediaQuery maxWidth={ MAX_MOBILE_WIDTH }>
                            { salesItemPaginationContainer }
                        </MediaQuery>
                    </div>
                }
                { /* Modals */}
                { isShownByComponentName.unsavedChangesModal &&
                    <UnsavedChangesDialog
                        saveDisabled={ saveIsDisabled }
                        onCancelClick={ this.closeUnsavedChangesDialog }
                        onNavigateWithoutSavingClick={ this.onCloseModalWithoutSaving }
                        onSaveAndContinueClick={ this.onSaveAndContinueFromUnsavedChangesDialog }
                    />
                }
                { isShownByComponentName.applyChangesModal && salesItemFormData !== null && (salesItemId instanceof SalesItemId) &&
                    <ApplyChangesDialog
                        salesItemName={ salesItemForm.salesItemInfoForm.salesItemName.value }
                        salesItemId={ salesItemId }
                        isInsideItemLevelReport={ (contextItemLevelReportId !== null) }
                        salesItemsById={ salesItemFormData.salesItemsById }
                        onSaveClick={ this.onApplySaveChanges }
                        onCancelClick={ this.closeApplyChangesDialog }
                        onSelectSaveOption={ this.onSelectSaveOption }
                        selectedSaveOption={ selectedSaveOption }
                        doNotAskMeAgainIsChecked={ isShownByComponentName.doNotAskForSaveOptionsAgain }
                        onSetDoNotAskMeAgain={ this.handleSetDoNotAskForSaveOptionsAgain }
                    />
                }
                <div className="on-save-snack-bar">
                    <SnackBar
                        isShown={ isShownByComponentName.onSaveSnackBar }
                        message="Save Successful"
                        callToActionLabel={ null }
                        handleCloseButton={ this.handleSnackBarClose }
                    />
                </div>
                {
                    this.getRecipePdfRenderer()
                }
            </div>
        );
    }

    private readonly doNothing = () => {
        // do nothing
    }

    private readonly openImageInNewTab = () => {
        const imageWindow = window.open("", "_blank");
        if (imageWindow) {
                imageWindow.document.write(`
              <html lang="">
                <head>
                <title>Uploaded Image</title></head>
                <body>
                  <img src=${this.props.createOrEditSalesItemState.salesItemImageUploadUrl} alt="">
                </body>
              </html>
            `);
        }
    }


    private readonly getRecipePdfRenderer = () => {
        if (this.props.shouldShowPdfRenderer) {
            const {
                isShownByComponentName,
                salesItemFormData,
                salesItemId,
                salesItemImageUploadUrl
            } = this.props.createOrEditSalesItemState;

            const fileUploadId = 'recipe-image-' + salesItemId;
            const salesItemImageUploadButton = (
                <FileUpload
                    id={ fileUploadId }
                    onFileInputChange={ this.onSalesItemImageUploadFileInputChange }
                    fileUploadState={ this.props.fileUploadState[fileUploadId] || [] }
                    maxFileUploadCount={ 1 }
                    buttonText={ (
                        <span>
                        <span className="bevicon bevico-file-upload main-icon-left"/>
                        Select Image
                    </span>
                    ) }
                    buttonClassName="flat with-icon primary"
                />
            );
            return (
                <RecipePdfRenderer
                    isHidden={ !isShownByComponentName.recipePdfRenderer }
                    salesItemId={ salesItemId }
                    productsById={ salesItemFormData ? salesItemFormData.productsById : null }
                    salesItemsById={ salesItemFormData ? salesItemFormData.salesItemsById : null }
                    onHide={ this.hidePdfRenderer }
                    imageUploadButton={ salesItemImageUploadButton }
                    imageUploadUrl={ salesItemImageUploadUrl }
                    isLoading={ isShownByComponentName.recipePdfIsLoading }
                    onReset={ this.deleteSalesItemImageUpload }
                />
            );
        }
    }

    private readonly deleteSalesItemImageUpload = () => {
        if (this.props.createOrEditSalesItemState.salesItemId instanceof SalesItemId) {
            this.props.dispatch(CreateOrEditSalesItemActions.deleteSalesItemImage(this.props.createOrEditSalesItemState.salesItemId));
        }
    }

    private readonly onSalesItemImageUploadFileInputChange = (id : string, index : number, file : File | null) => {
        // this.props.dispatch(FileUploadActions.setFile(id, index, file));
        if (this.props.createOrEditSalesItemState.salesItemId instanceof SalesItemId) {
            if (!!file) {
                Observer.observeAction(
                    'recipe_card_select_image_clicked',
                    {
                        retailer_id: window.GLOBAL_RETAILER_ID,
                        user_id: window.GLOBAL_USER_ID,
                        sales_item_id: this.props.createOrEditSalesItemState.salesItemId.getValue()
                    });
                this.props.dispatch(CreateOrEditSalesItemActions.saveSalesItemImage(this.props.createOrEditSalesItemState.salesItemId, file));
            } else {
                Observer.observeAction(
                    'recipe_card_remove_image_clicked',
                    {
                        retailer_id: window.GLOBAL_RETAILER_ID,
                        user_id: window.GLOBAL_USER_ID,
                        sales_item_id: this.props.createOrEditSalesItemState.salesItemId.getValue()
                    });
                this.props.dispatch(CreateOrEditSalesItemActions.deleteSalesItemImage(this.props.createOrEditSalesItemState.salesItemId));
            }
        }
    }

    private readonly handleSnackBarClose = () => {
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('onSaveSnackBar', false));
    }

    private readonly onSaveClick = () => {
        const {
            createOrEditSalesItemState,
            dispatch,
            onSave,
            contextItemLevelReportId,
            onClose
        } = this.props;

        if (createOrEditSalesItemState.salesItemId === null) { // if create mode, just create, no need for a dialog
            this.props.dispatch(CreateOrEditSalesItemActions.onSave(SalesItemSaveOption.ALL_REPORTS, contextItemLevelReportId, new StringValueMap()))
            .then((oldSalesIdToNewSalesIdMap) => {
                if (oldSalesIdToNewSalesIdMap !== null) {
                    onSave(oldSalesIdToNewSalesIdMap);
                    onClose();
                    dispatch(CreateOrEditSalesItemActions.resetSalesItemForm());
                }
            });
        } else {
            // if save/update mode, open the apply changes modal
            dispatch(CreateOrEditSalesItemActions.setIdToGoToAfterSave(null));
            this.handleOpenApplyChangesDialog();
        }
    }

    private readonly onSaveAndContinueFromUnsavedChangesDialog = () => {
        this.onSaveClick();
        this.closeUnsavedChangesDialog();
    }

    private readonly onSalesItemFormFieldChange = (fieldName : SalesItemFormFieldName, value : string) => {
        this.props.dispatch(CreateOrEditSalesItemActions.onSalesItemFormFieldChange(fieldName, value));
    }

    private readonly onIngredientInfoFormFieldChange = (fieldName : IngredientFormFieldName, value : string) => {
        this.props.dispatch(CreateOrEditSalesItemActions.onIngredientInfoFormFieldChange(fieldName, value));
    }

    private readonly onProductRowFormFieldChange = (productId : ProductId, fieldName : ProductIngredientRowFormFieldName, value : string) => {
        this.props.dispatch(CreateOrEditSalesItemActions.onProductRowFormFieldChange(productId, fieldName, value));
    }

    private readonly onProductRowFormFieldBlur = (ingredientId : ProductId, fieldName : ProductIngredientRowFormFieldName, value : string) => {
        this.props.dispatch(CreateOrEditSalesItemActions.onIngredientRowFormFieldBlur(ingredientId, fieldName, value));
    }

    private readonly onSalesItemRowFormFieldChange = (salesItemId : SalesItemId, fieldName : SalesItemIngredientRowFormFieldName, value : string) => {
        this.props.dispatch(CreateOrEditSalesItemActions.onSalesItemRowFormFieldChange(salesItemId, fieldName, value));
    }

    private readonly onSalesItemRowFormFieldBlur = (ingredientId : SalesItemId, fieldName : SalesItemIngredientRowFormFieldName, value : string) => {
        this.props.dispatch(CreateOrEditSalesItemActions.onIngredientRowFormFieldBlur(ingredientId, fieldName, value));
    }

    private readonly onAddIngredient = () => {
        const {
            dispatch,
            createOrEditSalesItemState
        } = this.props;

        let slimCreatePromiseIfApplicable : Promise<SalesItemId | null | void>;
        if (createOrEditSalesItemState.isShownByComponentName.createNewSalesItemFields) {
            slimCreatePromiseIfApplicable = dispatch(CreateOrEditSalesItemActions.onSlimCreateNewSalesItemIngredient());
        } else {
            slimCreatePromiseIfApplicable = Promise.resolve();
        }

        slimCreatePromiseIfApplicable
            .then((response) => {
                if (response !== null) { // null means validation failed.
                    dispatch(CreateOrEditSalesItemActions.onAddIngredient());
                    this.setIngredientSearchBarTerm(null);
                }
            });
    }

    // Removing for now until we do the revealing of the form?
    // private readonly onSalesItemFormFieldEnterOrBlur = (fieldName : SalesItemFormFieldName, value : string) => {
    //     this.props.dispatch(CreateOrEditSalesItemActions.onSalesItemFormFieldBlur(fieldName, value));
    // }

    private readonly onSelectMenuGroupOption = (optionValue : string) => {
        this.props.dispatch(CreateOrEditSalesItemActions.updateMenuGroup(optionValue));
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('saveIsDisabled', false));
    }

    private readonly onCreateCustomMenuGroupOption = (optionValue : string) => {
        this.props.dispatch(CreateOrEditSalesItemActions.createMenuGroupOption(optionValue));
    }

    private readonly toggleSalesItemInformationIsShown = () => {
        const toggle = !this.props.createOrEditSalesItemState.isShownByComponentName.salesItemInformation;
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('salesItemInformation', toggle));
    }

    private readonly toggleSubRecipeInfoIsShown = () => {
        const toggle = !this.props.createOrEditSalesItemState.isShownByComponentName.subRecipeInfo;
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('subRecipeInfo', toggle));
    }

    private readonly onRemoveIngredientClick = (ingredientId : ProductId | SalesItemId) => {
        if (ingredientId instanceof ProductId) {
            this.props.dispatch(CreateOrEditSalesItemActions.setProductIngredientItemsRow(ingredientId, null));
        } else {
            this.props.dispatch(CreateOrEditSalesItemActions.setSalesItemIngredientItemsRow(ingredientId, null));
        }
        this.props.dispatch(CreateOrEditSalesItemActions.onGetAndUpdateTotalCost());
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('saveIsDisabled', false));
    }

    private readonly setSalesPriceAndTaxHintPopoverIsShown = (isShown : boolean) => {
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('salesPriceAndTaxHintPopover', isShown));
    }

    private readonly onSalesInformationFormFieldChange = (fieldName : SalesInformationFormFieldName, value : string) => {
        this.props.dispatch(CreateOrEditSalesItemActions.onSalesInformationFormFieldChange(fieldName, value));
    }

    private readonly onSalesInformationFormFieldBlur = (fieldName : SalesInformationFormFieldName, value : string) => {
        this.props.dispatch(CreateOrEditSalesItemActions.onSalesInformationFormFieldBlur(fieldName, value));
    }

    private readonly handleOnShowConfirmExitDialog = () => {
        const {
            dispatch,
            createOrEditSalesItemState
        } = this.props;

        const formHasChanged = CreateOrEditSalesItemFormUtils.checkFormHasChangedFromInitial(createOrEditSalesItemState);
        if (formHasChanged) {
            dispatch(CreateOrEditSalesItemActions.setComponentIsShown('unsavedChangesModal', true));
        } else {
            this.onCloseModalWithoutSaving();
        }
    }

    private readonly closeUnsavedChangesDialog = () => {
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('unsavedChangesModal', false));
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('recipePdfRenderer', false));
    }

    private readonly onSelectSaveOption = (selectedSaveOption : SalesItemSaveOption) => {
        this.props.dispatch(CreateOrEditSalesItemActions.setSelectedSaveOption(selectedSaveOption));
    }

    private readonly onApplySaveChanges = () => {
        const {
            dispatch,
            onSave,
            contextItemLevelReportId,
        } = this.props;

        dispatch(CreateOrEditSalesItemActions.onApplySaveChanges(contextItemLevelReportId, this.contextOrderedIds, false, new StringValueMap(), onSave))
        .then((newContextOrderedIds) => {
            this.contextOrderedIds = newContextOrderedIds as Array<SalesItemId>; // gross, but we know for now in this context that we only have SalesItemIds
        });
    }

    private readonly closeApplyChangesDialog = () => {
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('applyChangesModal', false));
        this.props.dispatch(CreateOrEditSalesItemActions.setIdToGoToAfterSave(null));
    }

    private readonly onCloseModalWithoutSaving = () => {
        this.props.onClose();
        this.closeUnsavedChangesDialog();
        this.props.dispatch(CreateOrEditSalesItemActions.resetSalesItemForm());
    }

    private readonly setIngredientSearchBarTerm = (searchTerm : string | null) => {
        this.props.dispatch(CreateOrEditSalesItemActions.onSetIngredientSearchBarTerm(searchTerm));
    }

    private readonly setHighlightedIngredientId = (ingredientId : ProductId | SalesItemId | ProductQuickAdd | null) => {
        this.props.dispatch(CreateOrEditSalesItemActions.setIngredientSearchBarHighlightedIngredientId(ingredientId));
    }

    private readonly setIngredientDropdownIsShown = (isShown : boolean) => {
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('ingredientSearchBarDropdown', isShown));
    }

    private readonly setSearchBarSlimCreateIsShown = (isShown : boolean) => {
        const {
            dispatch,
            createOrEditSalesItemState,
        } = this.props;

        const searchTerm = createOrEditSalesItemState.salesItemForm.ingredientSearchBar.searchBar.searchTerm;

        if (isShown) {
            dispatch(ProductSlimCreateFormActions.onFormFieldChange('name', searchTerm || ''));
        }

        dispatch(CreateOrEditSalesItemActions.setComponentIsShown('searchBarSlimCreate', isShown));
        dispatch(CreateOrEditSalesItemActions.resetAddNewIngredientFormSection());
    }

    private readonly setCreateNewSalesItemFieldsAreShown = (isShown : boolean) => {
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('createNewSalesItemFields', isShown));
    }

    private readonly onSelectIngredient = (ingredientId : ProductId | SalesItemId | ProductQuickAdd | null) => {
        this.props.dispatch(CreateOrEditSalesItemActions.onSelectIngredient(ingredientId));
    }

    private readonly onSlimCreateNewProductIngredient = (productId : ProductId) => {
        const {
            dispatch,
        } = this.props;

        dispatch(CreateOrEditSalesItemActions.onProductCreatedOrEdited(productId, true))
            .then(() => {
                this.onSelectIngredient(productId);
            });
        dispatch(CreateOrEditSalesItemActions.setComponentIsShown('searchBarSlimCreate', false));
        dispatch(ProductSlimCreateFormActions.resetForm());
    }

    private readonly onSalesItemFlagChange = (flag : string | null) => {
        this.props.dispatch(CreateOrEditSalesItemActions.setNeedsAttentionCategory(flag));
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('saveIsDisabled', false));
    }

    private readonly onMarkFlagAsResolvedClick = () => {
        this.props.dispatch(CreateOrEditSalesItemActions.setNeedsAttentionCategory(null));
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('saveIsDisabled', false));
    }

    private readonly setIngredientPopoverIsShownForId = (ingredientId : ProductId | SalesItemId, isShown : boolean) => {
        this.props.dispatch(CreateOrEditSalesItemActions.setPopoverIngredientIdIsShown(new StringValueSet([ingredientId]), isShown));
    }

    private readonly onProductInfoFullDetailsClick = (ingredientId : ProductId) => {
        this.props.dispatch(CreateOrEditSalesItemActions.onEditItemClick(ingredientId));
        this.props.dispatch(CreateOrEditSalesItemActions.setPopoverIngredientIdIsShown(new StringValueSet([ingredientId]), false));
    }

    private readonly handleSetLinkedItemsPopoverIsShown = (isShown : boolean) => {
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('linkedItemsPopover', isShown));
    }

    private readonly handleSetDoNotAskForSaveOptionsAgain = (isChecked : boolean) => {
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('doNotAskForSaveOptionsAgain', isChecked));
    }

    private readonly handleOpenApplyChangesDialog = () => {
        const {
            dispatch,
            createOrEditSalesItemState
        } = this.props;

        if (!this.onlyShowAllTimeSaveOption) {
            if (createOrEditSalesItemState.isShownByComponentName.doNotAskForSaveOptionsAgain) {
                this.onApplySaveChanges(); // use the previously selected save option
            } else {
                dispatch(CreateOrEditSalesItemActions.setComponentIsShown('applyChangesModal', true));
            }
        } else {
            // if no POS reporting, don't ask for option - just save for all time.
            this.onSelectSaveOption(SalesItemSaveOption.ALL_REPORTS); // make sure this is chosen (should already be, but just to be safe)
            this.onApplySaveChanges();
        }

    }

    private readonly onPreviousButtonClick = () => {
        const {
            createOrEditSalesItemState,
            dispatch
        } = this.props;

        if (createOrEditSalesItemState.previousSalesItemId === null) {
            throw new RuntimeException('previousSalesItemId unexpectedly null');
        }

        const formHasChanged = CreateOrEditSalesItemFormUtils.checkFormHasChangedFromInitial(createOrEditSalesItemState);
        if (formHasChanged) {
            dispatch(CreateOrEditSalesItemActions.setIdToGoToAfterSave(createOrEditSalesItemState.previousSalesItemId));
            this.handleOpenApplyChangesDialog();
        } else {
            dispatch(CreateOrEditSalesItemActions.initializeFormFromSalesItem(createOrEditSalesItemState.previousSalesItemId, this.contextOrderedIds, false, new StringValueMap()));
        }
    }

    private readonly onNextButtonClick = () => {
        const {
            createOrEditSalesItemState,
            dispatch,
        } = this.props;

        if (createOrEditSalesItemState.nextSalesItemId === null) {
            throw new RuntimeException('nextSalesItemId unexpectedly null');
        }

        const formHasChanged = CreateOrEditSalesItemFormUtils.checkFormHasChangedFromInitial(createOrEditSalesItemState);
        if (formHasChanged) {
            dispatch(CreateOrEditSalesItemActions.setIdToGoToAfterSave(createOrEditSalesItemState.nextSalesItemId));
            this.handleOpenApplyChangesDialog();
        } else {
            dispatch(CreateOrEditSalesItemActions.initializeFormFromSalesItem(createOrEditSalesItemState.nextSalesItemId, this.contextOrderedIds, false, new StringValueMap()));
        }
    }

    private readonly onDocumentKeyUp = (event : KeyboardEvent) => {
        const {
            createOrEditSalesItemState,
        } = this.props;

        const target = event.target as HTMLElement;
        const closestInput = target.closest('input');
        const closestTextArea = target.closest('textarea');
        if ((closestInput && closestInput.value.length !== 0) ||
            (closestTextArea && closestTextArea.value.length !== 0)) {
            return;
        }

        // key codes to take action on:
        // 78 (n), 39 (>) = Next
        // 80 (p), 37 (<) = Previous
        // 70 (f) = Un/flag
        const keyCode = event.which;
        if (actionKeyCodeSet.has(keyCode)) {
            event.preventDefault();
            event.stopPropagation();

            if ((keyCode === ActionKeyCodes.n || keyCode === ActionKeyCodes.RIGHT_ARROW) && createOrEditSalesItemState.nextSalesItemId !== null) {
                this.onNextButtonClick();
            } else if ((keyCode === ActionKeyCodes.p || keyCode === ActionKeyCodes.LEFT_ARROW) && createOrEditSalesItemState.previousSalesItemId !== null) {
                this.onPreviousButtonClick();
            } else if (window.GLOBAL_USER_IS_ADMIN && keyCode === ActionKeyCodes.f) { // flag shortcut only for BevSpot admins
                if (createOrEditSalesItemState.salesItemForm.selectedNeedsAttentionCategory === null) {
                    this.onSalesItemFlagChange(availableSalesItemFlags[0]);
                } else {
                    this.onSalesItemFlagChange(null);
                }
            }
        }
    }

    private readonly onCustomItemSuccessfullyEdited = (event : Event, data : any) : void => {
        const {
            dispatch,
            createOrEditSalesItemState
        } = this.props;

        if (createOrEditSalesItemState.salesItemFormData === null) {
            throw new Error('createOrEditSalesItemState.salesItemFormData unexpectedly null');
        }

        const productId = new ProductId(data.productId);
        const isStatusActive = createOrEditSalesItemState.salesItemFormData.activeProductIds.has(productId);
        dispatch(CreateOrEditSalesItemActions.onProductCreatedOrEdited(productId, isStatusActive))
            .then(() => {
                dispatch(CreateOrEditSalesItemActions.onGetAndUpdateTotalCost());
            });
    }

    private readonly onCustomItemSuccessfullyCreated = (event : Event, productIdValue : string) => {
        this.onSlimCreateNewProductIngredient(new ProductId(productIdValue));
    }

    private readonly onCustomItemActiveStateChanged = (event : Event, data : any) : void => {
        const {
            dispatch,
        } = this.props;

        const productId = new ProductId(data.productIds[0]);
        dispatch(CreateOrEditSalesItemActions.onProductCreatedOrEdited(productId, false));
    }

    private readonly onExportToExcel = () => {
        const {
            dispatch,
            createOrEditSalesItemState,
        } = this.props;

        if (!(createOrEditSalesItemState.salesItemId instanceof SalesItemId)) {
            throw new RuntimeException('salesItemId unexpectedly not a SalesItemId');
        }

        dispatch(CreateOrEditSalesItemActions.exportSalesItemToExcel(createOrEditSalesItemState.salesItemId));
    }

    private readonly togglePdfRenderer = async () => {
        const salesItemId = this.props.createOrEditSalesItemState.salesItemId;

        if (salesItemId) {
            Observer.observeAction(
                'recipe_card_view_pdf_clicked',
                {
                    retailer_id: window.GLOBAL_RETAILER_ID,
                    user_id: window.GLOBAL_USER_ID,
                    sales_item_id: salesItemId.getValue(),
                    status: this.props.createOrEditSalesItemState.isShownByComponentName.recipePdfRenderer ? 'closing' : 'opening'
                });
        }

        this.props.dispatch(
            CreateOrEditSalesItemActions.setComponentIsShown(
                'recipePdfRenderer',
                !this.props.createOrEditSalesItemState.isShownByComponentName.recipePdfRenderer
            )
        );
    }

    private readonly hidePdfRenderer = () => {
        this.props.dispatch(CreateOrEditSalesItemActions.setComponentIsShown('recipePdfRenderer', false));
    }

    // private readonly onEditItemModalClosed = (event : Event, productId : string) : void => {
    //     this.doNothing();
    // }
}

export interface IStateProps {
    createOrEditSalesItemState : ICreateOrEditSalesItemState;
    productSlimCreateFormState : IProductSlimCreateFormState;
    fileUploadState: IFileUploadState;
}

const mapStateToProps = (state : IStateProps) : IStateProps => {
    return {
        createOrEditSalesItemState: state.createOrEditSalesItemState,
        productSlimCreateFormState: state.productSlimCreateFormState,
        fileUploadState: state.fileUploadState,
    };
};

export const ConnectedCreateOrEditSalesItem = connect<IStateProps, ICreateOrEditSalesItemProps, object, IStateProps>(mapStateToProps)(CreateOrEditSalesItem);
