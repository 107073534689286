import moment from 'moment-timezone';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { LocationId } from 'api/Location/model/LocationId';
import { CategoryId } from 'api/Product/model/CategoryId';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';

import { IProductService } from '../interfaces/IProductService';

const CACHE_VALUE_TIME_TO_LIVE_SECONDS = 5;
const MIN_GET_COUNT_TO_CACHE = 2;

export class CachedProductServiceImpl implements IProductService {
    private cachedGetProductsById : null | {
        requestTimestamp : moment.Moment,
        userSessionId : UserSessionId,
        locationId : LocationId,
        productIds : StringValueSet<ProductId>,
        result : Promise<{ productsById : StringValueMap<ProductId, Product>, productHashesById : StringValueMap<ProductId, string> }>,
    } = null;

    constructor (
        private readonly productService : IProductService,
    ) { }

    public getProductAndProductHash (
        userSessionId : UserSessionId,
        productId : ProductId,
    ) : Promise<{ product : Product, productHash : string }> {
        // TODO Cheezy probably safe to have this read from cache based on TTL and invalidation on writes
        return this.productService.getProductAndProductHash(userSessionId, productId);
    }

    public createProduct (
        userSessionId : UserSessionId,
        locationId : LocationId,
        product : Product,
    ) : Promise<ProductId> {
        this.clearCache();
        return this.productService.createProduct(userSessionId, locationId, product);
    }

    public createProducts (
        userSessionId : UserSessionId,
        locationId : LocationId,
        products : Array<Product>,
    ) : Promise<Array<ProductId>> {
        this.clearCache();
        return this.productService.createProducts(userSessionId, locationId, products);
    }

    public editProduct (
        userSessionId : UserSessionId,
        locationId : LocationId,
        productId : ProductId,
        product : Product,
        productHash : string
    ) : Promise<void> {
        this.clearCache();
        return this.productService.editProduct(userSessionId, locationId, productId, product, productHash);
    }

    public getProductsById (
        userSessionId : UserSessionId,
        locationId : LocationId,
        productIds : StringValueSet<ProductId>,
    ) : Promise<{ productsById : StringValueMap<ProductId, Product>, productHashesById : StringValueMap<ProductId, string> }> {
        if (productIds.size < MIN_GET_COUNT_TO_CACHE) {
            return this.productService.getProductsById(userSessionId, locationId, productIds);
        }

        const requestTimestamp = moment();

        if (this.cachedGetProductsById && locationId.equals(this.cachedGetProductsById.locationId) && userSessionId.equals(this.cachedGetProductsById.userSessionId) &&
            (requestTimestamp.diff(this.cachedGetProductsById.requestTimestamp, 'seconds') < CACHE_VALUE_TIME_TO_LIVE_SECONDS)) {
            if (productIds.isSubsetOf(this.cachedGetProductsById.productIds)) {
                if (productIds.equals(this.cachedGetProductsById.productIds)) {
                    return this.cachedGetProductsById.result;
                }

                return this.cachedGetProductsById.result
                .then((cachedResult) => {
                    const cachedResultProductsById = cachedResult.productsById;
                    const cachedResultProductHashesById = cachedResult.productHashesById;

                    const productsById = new StringValueMap<ProductId, Product>();
                    const productHashesById = new StringValueMap<ProductId, string>();

                    productIds.forEach((productId) => {
                        productsById.set(productId, cachedResultProductsById.getRequired(productId));
                        productHashesById.set(productId, cachedResultProductHashesById.getRequired(productId));
                    });

                    return {
                        productsById,
                        productHashesById
                    };
                });
            }
        }

        this.cachedGetProductsById = {
            requestTimestamp,
            userSessionId,
            locationId,
            productIds,
            result: this.productService.getProductsById(userSessionId, locationId, productIds),
        };

        return this.cachedGetProductsById.result;
    }

    public getProductsWithCategoryIds (
        userSessionId : UserSessionId,
        locationId : LocationId,
        categoryIds : StringValueSet<CategoryId>,
    ) : Promise<{ productsById : StringValueMap<ProductId, Product>, productHashesById : StringValueMap<ProductId, string>, productIsDeletedById : StringValueMap<ProductId, boolean> }> {
        return this.productService.getProductsWithCategoryIds(userSessionId, locationId, categoryIds);
    }

    public bulkUpdateProductsById (
        userSessionId : UserSessionId,
        locationId : LocationId,
        productUpdateArgsById : StringValueMap<ProductId, { product : Product, productHash : string }>,
    ) : Promise<StringValueMap<ProductId, string>> {
        this.clearCache();
        return this.productService.bulkUpdateProductsById(userSessionId, locationId, productUpdateArgsById);
    }

    private clearCache () {
        this.cachedGetProductsById = null;
    }
}
