//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import Thrift from 'thrift';



var LocationExceptions = {};
export default LocationExceptions;
LocationExceptions.UnknownLocationIdentifierException = function(args) {
};
Thrift.inherits(LocationExceptions.UnknownLocationIdentifierException, Thrift.TException);
LocationExceptions.UnknownLocationIdentifierException.prototype.name = 'UnknownLocationIdentifierException';
LocationExceptions.UnknownLocationIdentifierException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

LocationExceptions.UnknownLocationIdentifierException.prototype.write = function(output) {
  output.writeStructBegin('UnknownLocationIdentifierException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

LocationExceptions.UnknownPerspectiveLocationException = function(args) {
};
Thrift.inherits(LocationExceptions.UnknownPerspectiveLocationException, Thrift.TException);
LocationExceptions.UnknownPerspectiveLocationException.prototype.name = 'UnknownPerspectiveLocationException';
LocationExceptions.UnknownPerspectiveLocationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

LocationExceptions.UnknownPerspectiveLocationException.prototype.write = function(output) {
  output.writeStructBegin('UnknownPerspectiveLocationException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

LocationExceptions.LocationIdentifierIsRequiredException = function(args) {
};
Thrift.inherits(LocationExceptions.LocationIdentifierIsRequiredException, Thrift.TException);
LocationExceptions.LocationIdentifierIsRequiredException.prototype.name = 'LocationIdentifierIsRequiredException';
LocationExceptions.LocationIdentifierIsRequiredException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

LocationExceptions.LocationIdentifierIsRequiredException.prototype.write = function(output) {
  output.writeStructBegin('LocationIdentifierIsRequiredException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

LocationExceptions.ActorDoesNotHaveAccessToPartnerLocationException = function(args) {
};
Thrift.inherits(LocationExceptions.ActorDoesNotHaveAccessToPartnerLocationException, Thrift.TException);
LocationExceptions.ActorDoesNotHaveAccessToPartnerLocationException.prototype.name = 'ActorDoesNotHaveAccessToPartnerLocationException';
LocationExceptions.ActorDoesNotHaveAccessToPartnerLocationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

LocationExceptions.ActorDoesNotHaveAccessToPartnerLocationException.prototype.write = function(output) {
  output.writeStructBegin('ActorDoesNotHaveAccessToPartnerLocationException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

LocationExceptions.ActorDoesNotHaveAccessToLocationException = function(args) {
};
Thrift.inherits(LocationExceptions.ActorDoesNotHaveAccessToLocationException, Thrift.TException);
LocationExceptions.ActorDoesNotHaveAccessToLocationException.prototype.name = 'ActorDoesNotHaveAccessToLocationException';
LocationExceptions.ActorDoesNotHaveAccessToLocationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

LocationExceptions.ActorDoesNotHaveAccessToLocationException.prototype.write = function(output) {
  output.writeStructBegin('ActorDoesNotHaveAccessToLocationException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

LocationExceptions.ActorDoesNotHaveAccessToPerspectiveLocationException = function(args) {
};
Thrift.inherits(LocationExceptions.ActorDoesNotHaveAccessToPerspectiveLocationException, Thrift.TException);
LocationExceptions.ActorDoesNotHaveAccessToPerspectiveLocationException.prototype.name = 'ActorDoesNotHaveAccessToPerspectiveLocationException';
LocationExceptions.ActorDoesNotHaveAccessToPerspectiveLocationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

LocationExceptions.ActorDoesNotHaveAccessToPerspectiveLocationException.prototype.write = function(output) {
  output.writeStructBegin('ActorDoesNotHaveAccessToPerspectiveLocationException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

LocationExceptions.ActorDoesNotHaveAccessToSourceLocationException = function(args) {
};
Thrift.inherits(LocationExceptions.ActorDoesNotHaveAccessToSourceLocationException, Thrift.TException);
LocationExceptions.ActorDoesNotHaveAccessToSourceLocationException.prototype.name = 'ActorDoesNotHaveAccessToSourceLocationException';
LocationExceptions.ActorDoesNotHaveAccessToSourceLocationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

LocationExceptions.ActorDoesNotHaveAccessToSourceLocationException.prototype.write = function(output) {
  output.writeStructBegin('ActorDoesNotHaveAccessToSourceLocationException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

LocationExceptions.ActorDoesNotHaveAccessToTargetLocationException = function(args) {
};
Thrift.inherits(LocationExceptions.ActorDoesNotHaveAccessToTargetLocationException, Thrift.TException);
LocationExceptions.ActorDoesNotHaveAccessToTargetLocationException.prototype.name = 'ActorDoesNotHaveAccessToTargetLocationException';
LocationExceptions.ActorDoesNotHaveAccessToTargetLocationException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

LocationExceptions.ActorDoesNotHaveAccessToTargetLocationException.prototype.write = function(output) {
  output.writeStructBegin('ActorDoesNotHaveAccessToTargetLocationException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

LocationExceptions.LocationAlreadyExistsException = function(args) {
};
Thrift.inherits(LocationExceptions.LocationAlreadyExistsException, Thrift.TException);
LocationExceptions.LocationAlreadyExistsException.prototype.name = 'LocationAlreadyExistsException';
LocationExceptions.LocationAlreadyExistsException.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

LocationExceptions.LocationAlreadyExistsException.prototype.write = function(output) {
  output.writeStructBegin('LocationAlreadyExistsException');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

