import ProductMappingModel from 'gen-thrift/product_mapping_Model_types';

import { LocationObjectToThriftSerializer } from 'api/Location/serializer/LocationObjectToThriftSerializer';
import { ProductObjectToThriftSerializer } from 'api/Product/serializer/ProductObjectToThriftSerializer';
import { InterLocationProductMapping } from 'api/Transfer/model/InterLocationProductMapping';

export class ProductMappingObjectToThriftSerializer {

    constructor(
        private locationObjectToThriftSerializer : LocationObjectToThriftSerializer,
        private productObjectToThriftSerializer : ProductObjectToThriftSerializer
    ) {}

    ////////////////////////////////////
    // MODELS
    ////////////////////////////////////

    public getThriftInterLocationProductMapping(
        interLocationProductMapping : InterLocationProductMapping,
    ) : ProductMappingModel.InterLocationProductQuantityUnitMapping {
        return new ProductMappingModel.InterLocationProductQuantityUnitMapping({
            sourceLocationIdentifier: this.locationObjectToThriftSerializer.getThriftLocationId(interLocationProductMapping.getSourceLocationId()),
            sourceProductId: this.productObjectToThriftSerializer.getThriftProductId(interLocationProductMapping.getSourceProductId()),
            sourceQuantityOfProduct: this.productObjectToThriftSerializer.getThriftQuantityOfProduct(interLocationProductMapping.getSourceQuantityOfProduct()),
            targetLocationIdentifier: this.locationObjectToThriftSerializer.getThriftLocationId(interLocationProductMapping.getTargetLocationId()),
            targetProductId: this.productObjectToThriftSerializer.getThriftProductId(interLocationProductMapping.getTargetProductId()),
            targetQuantityOfProduct: this.productObjectToThriftSerializer.getThriftQuantityOfProduct(interLocationProductMapping.getTargetQuantityOfProduct()),
        });
    }
}
