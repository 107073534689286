import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IExtraArguments } from 'shared/components/Provider';
import { IAction, ISetShownAction } from 'shared/models/IAction';

import { IToastIntegrationService } from 'api/Integration/interfaces/IToastIntegrationService';
import { ToastIntegrationState } from 'api/Integration/model/ToastIntegrationState';
import { LocationId } from 'api/Location/model/LocationId';
import { IToastIntegrationBlockState } from './ToastIntegrationBlockReducers';

export interface IToastIntegrationBlockStore {
    toastIntegrationBlockState : IToastIntegrationBlockState;
}

export const ActionTypes = {
    SET_MODAL_SHOWN: 'TOAST_INTEGRATION_BLOCK/SET_MODAL_SHOWN',
    SET_EXISTING_INTEGRATION_STATE: 'TOAST_INTEGRATION_BLOCK/SET_EXISTING_INTEGRATION_STATE',
    SET_IS_LOADING: 'TOAST_INTEGRATION_BLOCK/SET_IS_LOADING',
    SET_MODAL_ACCESS_TOKEN: 'TOAST_INTEGRATION_BLOCK/SET_MODAL_ACCESS_TOKEN',

};

export namespace ToastIntegrationActionInterfaces {
    export interface ISetModalShown extends ISetShownAction {
    }

    export interface ISetIsLoading extends IAction {
        payload : {
            isLoading : boolean
        };
    }

    export interface ISetModalAccessToken extends IAction {
        payload : {
            accessToken : string | null
        };
    }

    export interface ISetExistingIntegrationState extends IAction {
        payload : {
            toastIntegrationState : ToastIntegrationState
        };
    }

    export interface IServices {
        toastIntegrationService : IToastIntegrationService;
    }
    export interface IThunkServices extends IExtraArguments {
        services : IServices;
    }
}

const setModalShown = (
    isShown : boolean
) : ToastIntegrationActionInterfaces.ISetModalShown => ({
    payload : {
        isShown,
    },
    type : ActionTypes.SET_MODAL_SHOWN
});

const setIsLoading = (
    isLoading : boolean
) : ToastIntegrationActionInterfaces.ISetIsLoading => ({
    payload : {
        isLoading
    },
    type: ActionTypes.SET_IS_LOADING,
});

const setModalAccessToken = (
    accessToken : string | null
) : ToastIntegrationActionInterfaces.ISetModalAccessToken => ({
    payload : {
        accessToken,
    },
    type : ActionTypes.SET_MODAL_ACCESS_TOKEN
});

const setExistingCredentialState = (
    toastIntegrationState : ToastIntegrationState
) : ToastIntegrationActionInterfaces.ISetExistingIntegrationState => ({
    payload : {
        toastIntegrationState,
    },
    type: ActionTypes.SET_EXISTING_INTEGRATION_STATE,
});

const fetchExistingIntegration = (
    locationId : LocationId
) : ThunkAction<Promise<ToastIntegrationState>, IToastIntegrationBlockStore, ToastIntegrationActionInterfaces.IThunkServices> => {
    return (dispatch : Dispatch<IToastIntegrationBlockStore>, getState : () => IToastIntegrationBlockStore, extraArguments : ToastIntegrationActionInterfaces.IThunkServices) : Promise<ToastIntegrationState> => {
        dispatch(setIsLoading(true));
        return extraArguments.services.toastIntegrationService.getToastIntegrationState(locationId)
        .then((data : ToastIntegrationState) => {
            dispatch(setExistingCredentialState(data));
            dispatch(setIsLoading(false));
            return data;
        }).catch((e) => {
            dispatch(setIsLoading(false));
            throw e;
        });
    };
};

const retrieveAccessToken = (
    locationId : LocationId,
) : ThunkAction<Promise<void>, IToastIntegrationBlockStore, ToastIntegrationActionInterfaces.IThunkServices> => {
    return (dispatch : Dispatch<IToastIntegrationBlockStore>, getState : () => IToastIntegrationBlockStore, extraArguments : ToastIntegrationActionInterfaces.IThunkServices) : Promise<void> => {
        return extraArguments.services.toastIntegrationService.getToastAccessToken(
            locationId,
        ).then((response : any) => {
            const key = 'accessToken';
            let accessToken = response[key];

            if (accessToken == null || accessToken === "") {
                accessToken = "Access token not found.";
            }

            dispatch(setModalAccessToken(accessToken));
        }).catch((error) => {
            throw error;
        });
    };
};

export const ToastIntegrationBlockActions = {
    setModalShown,
    fetchExistingIntegration,
    setModalAccessToken,
    retrieveAccessToken
};
