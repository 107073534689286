import { DistributorId } from 'api/Distributor/model/DistributorId';
import { DeliveryRepInfo } from 'api/Ordering/model/DeliveryRepInfo';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import moment from 'moment-timezone';

export class DeliveryMetadata {
    constructor (
        private readonly datePlacedUTC : moment.Moment,
        private readonly dateDeliveredUTC : moment.Moment,
        private readonly distributorId : DistributorId | null,
        private readonly invoiceNumber : string | null,
        private readonly noteToSelf : string | null,
        private readonly recordedBy : UserAccountId | null,
        private readonly dateRecordedUTC : moment.Moment,
        private readonly deliveryConfirmationEventTimeUTC : moment.Moment | null,
        private readonly deliveryConfirmationUserId : UserAccountId | null,
        private readonly noteToRep : string | null,
        private readonly distributorReps : Array<DeliveryRepInfo>,
    ) {}

    public getDatePlacedUTC() : moment.Moment {
        return this.datePlacedUTC;
    }

    public getDateDeliveredUTC() : moment.Moment {
        return this.dateDeliveredUTC;
    }

    public getDistributorId() : DistributorId | null {
        return this.distributorId;
    }

    public getInvoiceNumber() : string | null {
        return this.invoiceNumber;
    }

    public getNoteToSelf() : string | null {
        return this.noteToSelf;
    }

    public getDateRecordedUTC() : moment.Moment {
        return this.dateRecordedUTC;
    }

    public getRecordedBy() : UserAccountId | null {
        return this.recordedBy;
    }

    public getDeliveryConfirmationEventTimeUTC() : moment.Moment | null {
        return this.deliveryConfirmationEventTimeUTC;
    }

    public getDeliveryConfirmationUserId() : UserAccountId | null {
        return this.deliveryConfirmationUserId;
    }

    public getNoteToRep() : string | null {
        return this.noteToRep;
    }

    public getDistributorReps() : Array<DeliveryRepInfo> {
        return this.distributorReps;
    }

}
